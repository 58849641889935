import { Pipe, PipeTransform } from '@angular/core';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '../../types/list-container.types';

@Pipe({ name: 'getDataTypeFromDataTypeId' })
export class GetDataTypeFromDataTypeIdPipe implements PipeTransform {
  transform(id: string, dataTypes: ListContainer<NewDataType>): NewDataType {
    return dataTypes.listMap[id];
  }
}
