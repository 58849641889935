<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div>
      <div class="p-inputgroup">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox binary="true" id="isDeleted" [(ngModel)]="m.tenant.deleted" [disabled]="true"></p-checkbox>
            <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate }}</label>
          </span>
        </span>
      </div>
    </div>

    <div>
      <button
        *ngIf="m.tenant && m.tenant.id"
        type="button"
        class="p-button p-button-danger mr-1"
        pButton
        [label]="'Delete' | translate"
        (click)="s.deleteWithConfirmation()"
      ></button>

      <a [routerLink]="['/system', 'tenant-list']">
        <button pButton [label]="'Cancel' | translate" type="button" class="p-button p-button-warning"></button>
      </a>

      <button pButton [label]="'Save' | translate" type="button" class="p-button p-button-success" (click)="s.save()">
        <i *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>

  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label">
      <input type="text" id="name" pInputText [(ngModel)]="m.tenant.name" />
      <label class="dynamic-label-text" for="name" translate>Name</label>
    </span>
  </div>
  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label">
      <input type="text" id="domain" pInputText [(ngModel)]="m.tenant.domain" />
      <label class="dynamic-label-text" for="domain" translate>Domain</label>
    </span>
  </div>
  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton
          name="systemTenantIsPrivate"
          [value]="false"
          [(ngModel)]="m.tenant.isPublic"
          inputId="systemTenantIsPrivateRadio1"
          (onClick)="onClickIsPublic($event)"
        >
        </p-radioButton>
        <label [for]="'systemTenantIsPrivateRadio1'" translate>Private</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton
          name="systemTenantIsPrivate"
          [value]="true"
          [(ngModel)]="m.tenant.isPublic"
          inputId="systemTenantIsPrivateRadio2"
          (onClick)="onClickIsPublic($event)"
        >
        </p-radioButton>
        <label [for]="'systemTenantIsPrivateRadio2'" translate>Public</label>
      </span>
      <label class="dynamic-label-text" translate>Public tenant</label>
    </span>
  </div>
  <div *ngIf="m.tenant.isPublic === true" class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="systemTenantIsPrivate" [value]="false" [(ngModel)]="m.tenant.registrationAllowed" inputId="systemTenantRegistrationAllowedRadio1">
        </p-radioButton>
        <label [for]="'systemTenantRegistrationAllowedRadio1'" translate>Disallowed</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="systemTenantIsPrivate" [value]="true" [(ngModel)]="m.tenant.registrationAllowed" inputId="systemTenantRegistrationAllowedRadio2">
        </p-radioButton>
        <label [for]="'systemTenantRegistrationAllowedRadio2'" translate>Allowed</label>
      </span>
      <label class="dynamic-label-text" translate>Registration allowed</label>
    </span>
  </div>
</div>
