import { Component, OnInit } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { CardWidgetService } from '@widgets/card-widget/services/card-widget.service';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';

@Component({
  selector: 'app-card-widget-settings',
  templateUrl: './card-widget-settings.component.html',
  styleUrls: ['./card-widget-settings.component.scss'],
})
export class CardWidgetSettingsComponent implements OnInit {
  m: CardWidgetModel;

  widgetOptions: WidgetOption<any>[];

  draggedItem: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection> | null = null;
  draggedItemIndex: number | null = null;
  clickActionSettingsOptions: ClickActionSettingsOptions;

  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  protected readonly ContentType: typeof ContentType = ContentType;
  protected readonly CardWidgetMode: typeof CardWidgetMode = CardWidgetMode;

  constructor(private readonly s: CardWidgetService) {
    this.m = this.s.m;
    this.widgetOptions = this.s.getWidgetOptions();
  }

  get connectionIds(): string[] {
    return this.m.areas.map((area: GenericArea<CardWidgetAreaContent>, index: number) => this.m.idPrefix + 'cardArea' + index);
  }

  get areaAttributeSelectionAvailable(): boolean {
    return !!this.m.settings.artifactTypeId && this.m.options.areLoaded;
  }

  get isArtifactSelectionAvailable(): boolean {
    return this.m.settings.widgetMode === CardWidgetMode.staticArtifact && !!this.m.settings.artifactTypeId;
  }

  get isArtifactIdUrlParameterKeyChangeAvailable(): boolean {
    return this.m.settings.widgetMode === CardWidgetMode.dynamicArtifact;
  }

  get clickActionCanBeHandled(): boolean {
    return this.m.settings.widgetMode !== CardWidgetMode.listItem;
  }

  ngOnInit(): void {
    this.clickActionSettingsOptions = {
      attributes: [...this.m.options.attributes.list, ...this.m.options.systemAttributes.list],
      pages: this.m.options.pages.toSelectOptions('name', 'id'),
      dataTypes: this.m.options.dataTypes.list,
      users: this.m.options.users,
    };
  }

  async onWidgetModeChange(): Promise<void> {
    await this.s.onWidgetModeChange();
  }

  async onArtifactTypeChange(): Promise<void> {
    await this.s.onArtifactTypeChange();
  }

  async onSelectedArtifactChange(): Promise<void> {
    await this.s.onSelectedArtifactChange();
  }

  onAttributeDragExited(attribute: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>, index: number): void {
    this.draggedItem = attribute;
    this.draggedItemIndex = index;
  }

  resetDraggedItemData(): void {
    this.draggedItem = null;
    this.draggedItemIndex = null;
  }

  /** @deprecated */
  onResponsivenessChange(): void {
    this.s.onResponsivenessChange();
  }
}
