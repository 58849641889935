<p-table
  #table
  *ngIf="this.applicationSwitcherService.selectedApplication?.id"
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  [filters]="{ applicationId: [{ value: [this.applicationSwitcherService.selectedApplication.id], matchMode: 'in', operator: 'and' }] }"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
        <p-columnFilter
          *ngIf="isColumnMultiSelectable(col.key)"
          [field]="col.key"
          matchMode="in"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
        >
          <ng-template pTemplate="header">
            <div class="px-3 pt-3 pb-0">
              <span class="p-text-bold">{{ col.key }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="col.key | getDataTypeFilterOptions : dataTypeListService.m"
              (onChange)="filter($event.value)"
              placeholder="Any"
            >
              <ng-template let-option pTemplate="item">
                <div>
                  <span class="ml-1">{{ option.label | translate }}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
        <p-columnFilter *ngIf="!isColumnMultiSelectable(col.key)" type="text" [field]="col.key" matchMode="contains" display="menu"></p-columnFilter>
      </th>
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-dataType let-columns="columns">
    <tr [ngClass]="{ 'deleted-row-color': dataType.deleted }">
      <td *ngFor="let col of columns">
        <div class="p-text-truncate">
          {{ dataType | printDataTypeToTable : col.key : applications }}
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/admin/data-type', dataType.id]">
            <button pButton class="p-button p-button-warning" type="button" label="{{ 'Edit' | translate }}" icon="pi pi-pencil" iconPos="left"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="dataTypeListService.m.columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
