import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TemplatesComponent } from '@shared/components/templates/templates.component';
import { Template, TemplatesCloseResult, TemplatesCloseResultRole, TemplateType } from '@shared/components/templates/types/templates.types';
import { TemplateService } from '@shared/services/page-management/template.service';
import { ArtifactTypeDefaultTemplates, NewArtifactType } from '@shared/types/artifact-type.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-artifact-type-default-widgets-form',
  templateUrl: './artifact-type-default-widgets-form.component.html',
  styleUrls: ['./artifact-type-default-widgets-form.component.scss'],
})
export class ArtifactTypeDefaultWidgetsFormComponent implements OnChanges {
  @Input() artifactType: NewArtifactType;

  WidgetType = WidgetType;
  defaultWidgets = new ArtifactTypeDefaultTemplates();

  constructor(private readonly templateService: TemplateService, private readonly dialogService: DialogService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.artifactType?.currentValue?.defaultWidgets) {
      this.defaultWidgets = await this.templateService.getDefaultArtifactTypeTemplates(changes.artifactType.currentValue);
    }
  }

  async setTemplate(widgetType: WidgetType): Promise<void> {
    const { templates } = await this.openTemplateDialog(widgetType);
    if (!Array.isArray(templates) || !templates.length) return;

    this.setTemplateByWidgetType(widgetType, templates);
  }

  private openTemplateDialog(widgetType: WidgetType): Promise<TemplatesCloseResult> {
    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(TemplatesComponent, {
        showHeader: false,
        width: '95vw',
        height: '100%',
        style: {
          boxShadow: 'none',
          justifyContent: 'center',
        },
        contentStyle: {
          position: 'relative',
          paddingTop: '70px',
        },
        data: {
          templateType: TemplateType.widget,
          hideTemplateTypePicker: true,
          useSingleButtonToPickTemplate: true,
          widgetType,
        },
      });

      dialogRef.onClose
        .pipe(
          take(1),
          filter((close: TemplatesCloseResult) => close.role !== TemplatesCloseResultRole.close),
          tap((result: TemplatesCloseResult) => {
            resolve(result);
          }),
        )
        .subscribe();
    });
  }

  private setTemplateByWidgetType(widgetType: WidgetType, templates: TemplateResponseDto[]): void {
    if (widgetType === WidgetType.artifact) this.setArtifactWidgetTemplate(templates[0]);
    if (widgetType === WidgetType.card) this.setCardWidgetTemplate(templates[0]);
  }

  private setArtifactWidgetTemplate(template: TemplateResponseDto | null): void {
    this.artifactType.defaultWidgets.artifactWidgetTemplateId = template?.id || null;
    this.defaultWidgets.artifactWidgetTemplate = template ? Template.fromDto(template) : null;
  }

  private setCardWidgetTemplate(template: TemplateResponseDto | null): void {
    this.artifactType.defaultWidgets.cardWidgetTemplateId = template?.id || null;
    this.defaultWidgets.cardWidgetTemplate = template ? Template.fromDto(template) : null;
  }
}
