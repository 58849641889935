<div class="grid">
  <div class="col-12">
    <div class="header">
      <h4 translate>Link restrictions</h4>
      <button
        pButton
        label="{{ 'Add restriction' | translate }}"
        type="button"
        class="p-button"
        icon="pi pi-plus"
        iconPos="left"
        (click)="addRestriction()"
      ></button>
    </div>
    <p-table
      [columns]="m.options.column"
      [value]="linkType?.restrictions"
      responsive="true"
      paginatorPosition="bottom"
      sortMode="multiple"
      sortField="id"
      appThirdClickUndoSort
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngClass]="{ minWidthCol: column.meta?.minWidthCol === true }"
              *ngFor="let column of columns; let colI = index">
            {{ column.label | translate }}
            <p-sortIcon *ngIf="colI === 0" [pSortableColumn]="column.key" [field]="column.key"></p-sortIcon>
          </th>
          <th>
            {{ 'Actions' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-restriction let-ri="rowIndex">
        <tr>
          <td>
            <p-dropdown
              [filter]="true"
              [autoDisplayFirst]="false"
              [style]="{ width: '100%' }"
              [options]="linkValidationType"
              [virtualScroll]="true"
              [virtualScrollItemSize]="20"
              [placeholder]="'Choose a link validation'"
              appendTo="body"
              optionLabel="label"
              [(ngModel)]="restriction.isLinkRequired"
            >
            </p-dropdown>
          </td>
          <td>
            <app-type-system-element-dropdown
              (onSelect)="onSourceOrDestinationChange(restriction.sourceArtifactTypeId, false, restriction)"
              [(ngModel)]="restriction.sourceArtifactTypeId"
              [applications]="applications"
              [disabledElements]="(disabledArtifactTypes$ | async)?.get(restriction)?.source || []"
              [placeholder]="'Choose an artifact type'"
              [typeSystemElements]="artifactTypes">
            </app-type-system-element-dropdown>
          </td>
          <td>
            <p-checkbox [(ngModel)]="restriction.notifySource" [disabled]="restriction.notifyDestination"
                        [binary]="true" inputId="binary"></p-checkbox>
          </td>
          <td>
            <p-dropdown
              [autoDisplayFirst]="false"
              [style]="{ width: '100%' }"
              [options]="m.options.relationTypes"
              optionLabel="label"
              optionValue="value"
              [ngModel]="convertRelationValue(restriction.singleSource, restriction.singleDestination)"
              (onChange)="onRelationChange($event.value, restriction)"
              (onShow)="onRelationShow(restriction)"
              appendTo="body"
              [placeholder]="'Choose relation type'"
            >
            </p-dropdown>
          </td>
          <td>
            <app-type-system-element-dropdown
              (onSelect)="onSourceOrDestinationChange(restriction.destinationArtifactTypeId, false, restriction)"
              [(ngModel)]="restriction.destinationArtifactTypeId"
              [applications]="applications"
              [disabledElements]="(disabledArtifactTypes$ | async)?.get(restriction)?.destination || []"
              [placeholder]="'Choose an artifact type'"
              [typeSystemElements]="artifactTypes">
            </app-type-system-element-dropdown>
          </td>
          <td>
            <p-checkbox [(ngModel)]="restriction.notifyDestination" [disabled]="restriction.notifySource"
                        [binary]="true" inputId="binary"></p-checkbox>
          </td>
          <td>
            <div class="table_buttons">
              <button pButton label="{{ 'Remove' | translate }}" type="button" class="p-button-danger"
                      (click)="deleteRestriction(ri)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="m.options.column.length + 1" translate>No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
