import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ScreenSize, ScreenState } from '@shared/types/screen.types';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private readonly resizeSubject: Subject<void> = new Subject<void>();
  private readonly resizeObserver: ResizeObserver = new ResizeObserver(() => {
    this.zone.run(() => {
      this.resizeSubject.next();
    });
  });
  resize$: Observable<void> = this.resizeSubject.asObservable();

  private resize: ResizeObserver;
  private el = window.document.body;
  private prevClass: string;

  constructor(private readonly zone: NgZone) {
    this.resizeObserver.observe(window.document.body);
  }

  check(): void {
    const width = this.el.offsetWidth;
    const { pc, tablet, mobile } = ScreenState;

    let className = pc;
    width < ScreenSize.pc && width > ScreenSize.mobile && (className = tablet);
    width < ScreenSize.mobile && (className = mobile);

    if (this.prevClass === className) {
      return;
    }

    this.el.classList.remove(pc, tablet, mobile);
    this.el.classList.add(className);
  }

  initResizeObserver(): void {
    this.resize = new (window as any).ResizeObserver(() => this.check());
    this.resize.observe(this.el);
  }
}
