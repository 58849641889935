import { WidgetModel } from '../../shared/types/widget-model.interface';
import { ChartWidgetSettings, ChartSettingsDto } from './chart-widget-settings.types';
import { ChartWidgetOptions } from '@widgets/chart-widget/types/chart-widget-options.types';
import { ChartWidgetSelected, ChartWidgetSelectedDto, FitMode } from '@widgets/chart-widget/types/chart-widget-selected.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';

export class ChartWidgetValue {
  constructor(public model: ChartWidgetModel = new ChartWidgetModel()) {}
}

export class ChartWidgetModel implements WidgetModel<ChartWidgetModelDto> {
  selected = new ChartWidgetSelected();
  settings = new ChartWidgetSettings();
  options = new ChartWidgetOptions();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  valuesMap: Record<string, any> = {};
  width: string;
  updateTotal: boolean;
  prevFit: FitMode;
  prevTitle: string;
  resizeObserver: ResizeObserver;

  constructor(dto?: ChartWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ChartWidgetModelDto {
    return {
      settings: this.settings.toServer(),
      selected: this.selected.toServer(),
    };
  }

  fromDto(dto: ChartWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new ChartWidgetSettings(dto.settings));
      dto.selected && (this.selected = new ChartWidgetSelected(dto.selected));
    }
  }
}

export interface ChartWidgetModelDto {
  settings: ChartSettingsDto;
  selected: ChartWidgetSelectedDto;
}
