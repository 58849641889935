import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'booleanWordValue' })
export class BooleanWordValuePipe implements PipeTransform {
  transform(value: boolean, labelWhenTrue: string, labelWhenFalse: string, labelWhenEmpty = ''): string {
    if (value) {
      return labelWhenTrue;
    }
    return value === false ? labelWhenFalse : labelWhenEmpty;
  }
}
