import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';

@Pipe({
  name: 'getCreateLinkButtonLabel',
})
export class GetCreateLinkButtonLabelPipe implements PipeTransform {
  transform(targetArtifact: NewArtifact | NewArtifact[], isMultipleSelectionMode: boolean): string {
    if (isMultipleSelectionMode) return targetArtifact && (targetArtifact as NewArtifact[]).length ? 'Create links' : 'Select rows';
    else return targetArtifact ? 'Create link' : 'Select row';
  }
}
