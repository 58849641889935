import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({
  name: 'getWorkflowCalculationActionAttributes',
})
export class GetWorkflowCalculationActionAttributesPipe implements PipeTransform {
  transform(
    attributes: NewAttribute[],
    selectedAttributeId: string,
    selectedAttributeIds: string[],
    dataTypes: ListContainer<NewDataType>,
    supportedDataTypes: BaseDataType[] | null,
    supportedDataKinds: DataTypeKind[] | null,
  ): NewAttribute[] {
    const result = attributes.filter(attr => {
      if (selectedAttributeIds.includes(attr.id) && selectedAttributeId !== attr.id) {
        return false;
      }
      const dataType = dataTypes.listMap[attr.dataTypeId];
      if (supportedDataTypes && !supportedDataTypes.includes(dataType.baseDataType as BaseDataType)) {
        return false;
      }
      return !supportedDataKinds || supportedDataKinds.includes(dataType.kind);
    });
    return result;
  }
}
