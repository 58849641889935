import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PrintApplicationToTablePipe } from '@private/pages/application-management/application-list/pipes/print-application-to-table.pipe';
import { ApplicationListService } from '@private/pages/application-management/application-list/services/application-list.service';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ApplicationListComponent } from './application-list/application-list.component';
import { ApplicationListTableComponent } from './application-list/components/application-list-table/application-list-table.component';
import { ApplicationComponent } from './application/application.component';

const declarations = [ApplicationListComponent, ApplicationComponent, ApplicationListTableComponent, PrintApplicationToTablePipe];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    TableModule,
    TooltipModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    LoaderModule,
    ElvisSharedModule,
  ],
  providers: [ApplicationListService],
  exports: [...declarations],
})
export class ApplicationManagementModule {}
