import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { GetRowsPerPageHeaderPipeModule } from '@widgets/shared/pipes/get-rows-per-page-header.pipe';
import { IsGroupingByDatePipeModule } from '@widgets/shared/pipes/is-grouping-by-date.pipe';
import { ShouldShowCustomRangeCalendarOptionPipeModule } from '@widgets/shared/pipes/should-show-custom-range-calendar-option.pipe';
import { ShouldShowDateOffsetOptionPipeModule } from '@widgets/shared/pipes/should-show-date-offset-option.pipe';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ArtifactLinkDialogComponent } from './artifact-link-dialog.component';
import { LinkPopupTableSettingsComponent } from './components/link-popup-table-settings/link-popup-table-settings.component';
import { GetCreateLinkButtonLabelPipe } from './pipes/get-create-link-button-label.pipe';
import { ArtifactListWidgetTableModule } from '@widgets/shared/components/artifact-list-table/artifact-list-widget-table.module';

@NgModule({
  declarations: [ArtifactLinkDialogComponent, LinkPopupTableSettingsComponent, GetCreateLinkButtonLabelPipe],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TableModule,
    DialogModule,
    CalendarModule,
    TriStateCheckboxModule,
    MultiSelectModule,
    TranslateModule,
    RouterModule,
    RippleModule,
    InputTextModule,
    DisplayArtifactValueToTableModule,
    ElvisSharedModule,
    WidgetContainerModule,
    CdkDrag,
    SidebarModule,
    BadgeModule,
    CdkDragHandle,
    CdkDropList,
    OverlayPanelModule,
    PaginatorModule,
    TooltipModule,
    FolderWidgetModule,
    CheckboxModule,
    RadioButtonModule,
    StyleFormModule,
    ShouldShowDateOffsetOptionPipeModule,
    ShouldShowCustomRangeCalendarOptionPipeModule,
    IsGroupingByDatePipeModule,
    GetRowsPerPageHeaderPipeModule,
    ArtifactListWidgetTableModule,
  ],
  exports: [ArtifactLinkDialogComponent, LinkPopupTableSettingsComponent, GetCreateLinkButtonLabelPipe],
})
export class NewLinkPopupModule {}
