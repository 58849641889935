/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SystemTenantResponseDto } from '../../models/system-tenant-response-dto';
import { SystemTenantUpdateRequestDto } from '../../models/system-tenant-update-request-dto';

export interface SystemTenantControllerUpdate$Params {
      body: SystemTenantUpdateRequestDto
}

export function systemTenantControllerUpdate(http: HttpClient, rootUrl: string, params: SystemTenantControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantResponseDto>> {
  const rb = new RequestBuilder(rootUrl, systemTenantControllerUpdate.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SystemTenantResponseDto>;
    })
  );
}

systemTenantControllerUpdate.PATH = '/api/system/tenant';
