<h5>Divider style</h5>

<div class="row">
  <div class="col-4">
    <h6> {{'Width'| translate}} </h6>
    <input id="width" class="w-100" [(ngModel)]="dividerStyle.width" pInputText type="text"/>
  </div>

  <div class="col-4">
    <h6> {{'Position'| translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.menuItemHorizontalAlign" [(ngModel)]="dividerStyle.justifyContent"
                id="justifyContent"></p-dropdown>
  </div>

  <div class="col-4">
    <h6> {{'Margin top'| translate}} </h6>
    <input id="marginTop" class="w-100" [(ngModel)]="dividerStyle.marginTop" pInputText type="text"/>
  </div>
</div>

<div class="row">
  <div class="col-4">
    <h6> {{'Margin bottom'| translate}} </h6>
    <input id="marginBottom" class="w-100" [(ngModel)]="dividerStyle.marginBottom" pInputText type="text"/>
  </div>

  <div class="col-4 mb-0">
    <app-color-picker [(color)]="dividerStyle.borderBottomColor" label="{{'Color'| translate}}"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <h6> {{'Style'|translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.borderStyles" [(ngModel)]="dividerStyle.borderBottomStyle" id="borderBottomStyle"></p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-4 mb-0">
    <h6> {{'Thickness'| translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.borderWidth" [(ngModel)]="dividerStyle.borderBottomWidth" id="borderBottomWidth"></p-dropdown>
  </div>
</div>
