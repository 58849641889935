import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarButtonFormComponent } from './components/sidebar-button-form/sidebar-button-form.component';
import { SidebarWidgetSettingsComponent } from './components/sidebar-widget-settings/sidebar-widget-settings.component';
import { SidebarWidgetComponent } from './sidebar-widget.component';

@NgModule({
  declarations: [SidebarWidgetComponent, SidebarWidgetSettingsComponent, SidebarButtonFormComponent],
  imports: [
    CommonModule,
    DragDropModule,
    // PrimeNG
    SidebarModule,
    ButtonModule,
    RippleModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    ColorPickerModule,
    ToggleButtonModule,
    PortalModule,
    TooltipModule,
    TranslateModule,
    ElvisSharedModule,
    WidgetContainerModule,
    StyleFormModule,
    OverlayPanelModule,
    SliderModule,
    AccordionModule,
  ],
  exports: [SidebarWidgetComponent],
})
export class SidebarWidgetModule {}
