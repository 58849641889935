import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class BaseEnumFilterService extends AbstractBaseTypeFilterService {
  constructor(filterUtil: FilterUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null {
    if (!value) {
      return null;
    }
    const enumValues = Array.isArray(value) ? value : [value];
    const filterKey = this.filterUtil.getAttributesDbFilterKey(attributeKey);
    return this.getResultQuery(filterKey, enumValues, filterRule);
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    return super.getQueryFromRawTextMulti(attributeKey, dataType, value, delimiter, filterRule);
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    switch (filterRule) {
      case CoreListFilterEnum.in:
      case CoreListFilterEnum.isNot:
        return true;
      default:
        return false;
    }
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.in;
  }
}
