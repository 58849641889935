import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getEnumOptionsFromDataTypeValues' })
export class GetEnumOptionsFromDataTypeValuesPipe implements PipeTransform {
  transform(dataTypeValues: DataTypeValueResponseDto[]): SelectOption<string, any>[] {
    return dataTypeValues.map(item => new SelectOption(item.label, item.value));
  }
}
