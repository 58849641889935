import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewDataType } from '@shared/types/data-type.types';
import { DATBooleanEditVariant } from '@shared/types/display-at-types';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';

@Component({
  selector: 'app-display-at-boolean',
  templateUrl: './display-at-boolean.component.html',
  styleUrls: ['display-at-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtBooleanComponent extends AbstractDisplayAtComponent {
  @Input() dataType: NewDataType;

  handleValueChange() {
    const enumKey = this.selectedEditVariant as keyof typeof DATBooleanEditVariant;
    if (DATBooleanEditVariant[enumKey] !== DATBooleanEditVariant.DEFAULT) {
      const currentValue = this.artifact.attributes[this.attributeId].value;
      const emptyAfterLast = DATBooleanEditVariant[enumKey] === DATBooleanEditVariant.TOGLE_3_STATE;
      const nextValue = this.dataTypeHelper.getNextBooleanValue(currentValue, emptyAfterLast);
      this.displayAtControlService.doUpdateValueSelection(this.ownerId, this.artifact, this.attributeId, nextValue);
      if (this.doInternalUpdateOfValue) {
        this.artifact.attributes[this.attributeId].value = nextValue;
        this.cdr.markForCheck();
      }
    }
  }
}
