<div
  *ngIf="attr"
  [attr.style]="formatSettings.value.fieldStyles | fieldStyles"
  (focusin)="changeFormFocus?.()"
  class="attribute-form-field">
  <span class="dynamic-label p-float-label">
    <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>

    <input
      type="text"
      (blur)="onBlurCb?.()"
      [(ngModel)]="attr.value"
      (ngModelChange)="onChangeCb?.()"
      [id]="'username' + index"
      [placeholder]="placeholder"
      [disabled]="disabled"
      pInputText
    />
  <app-artifact-attribute-form-field-label-with-icon [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'username' + index"
                                                     [canContainPlaceholder]="true"
                                                     [labelBehaviour]="labelBehaviour"
                                                     [placeholder]="placeholder">
  </app-artifact-attribute-form-field-label-with-icon>
  </span>
</div>
