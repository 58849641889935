import { SidebarModalPosition } from './sidebar-modal.types';
import { SelectOption } from '@shared/types/shared.types';
import { TranslateUtil } from '@shared/utils/translateUtil';

export class SidebarModalOptions {
  position: string[];
  inside: SelectOption<string, boolean>[];
  fixed: SelectOption<string, boolean>[];

  async initOptions(translateUtil: TranslateUtil) {
    this.position = Object.keys(SidebarModalPosition);
    const [onTop, inside, dynamic, fixed] = await translateUtil.getAll(['OnTop', 'Inside', 'Dynamic', 'Fixed']);
    this.inside = [new SelectOption(onTop, false), new SelectOption(inside, true)];
    this.fixed = [new SelectOption(dynamic, false), new SelectOption(fixed, true)];
  }
}
