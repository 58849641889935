import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';

export class ArtifactIdExtractor implements ArtifactValueExtractor {
  static readonly artifactIdAttributeId = '6172746966616374735f6964';

  static isIdAttribute(attributeId: string): boolean {
    return attributeId === ArtifactIdExtractor.artifactIdAttributeId;
  }

  getValue(artifactDto: ArtifactResponseDto): string {
    return artifactDto.id;
  }
}
