import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import {
  RuntimeStateNotification,
  RuntimeStateNotificationEnum
} from '@widgets/shared/types/runtime-state-notification.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrl: './upload-progress.component.scss'
})
export class UploadProgressComponent implements OnDestroy {
  @Input() fileName: string;

  counter = 0;
  private eventsSubscription: Subscription;


  constructor(private readonly runtimeStateNotificationService: RuntimeStateNotificationService, protected readonly cdr: ChangeDetectorRef,) {
    this.init();
  }

  ngOnDestroy(): void {
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.uploadProgress && event.data.name === this.fileName) {
        this.counter = event.data.percent;
        this.cdr.markForCheck();
      }
    });
  }
}

@NgModule({
  declarations: [UploadProgressComponent],
  imports: [CommonModule, ProgressBarModule],
  exports: [UploadProgressComponent],
})
export class UploadProgressModule {}
