import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DisplayHyperlinkIconComponent } from './display-hyperlink-icon.component';

@NgModule({
  declarations: [DisplayHyperlinkIconComponent],
  imports: [CommonModule, TranslateModule, OverlayPanelModule],
  exports: [DisplayHyperlinkIconComponent],
})
export class DisplayHyperlinkIconModule {}
