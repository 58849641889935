import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ButtonView } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button.types';

@Injectable()
export class ButtonCopyPastingService {
  private copiedButton: ButtonView;

  get isCopiedButtonStyles(): boolean {
    return !!this.copiedButton;
  }

  copyContent(button: ButtonView): void {
    this.copiedButton = cloneDeep(button);
  }

  pasteContent(targetButton: ButtonView): void {
    targetButton.label = this.copiedButton.label;
    targetButton.subLabel = this.copiedButton.subLabel;
    targetButton.chip = this.copiedButton.chip;
    targetButton.icon = this.copiedButton.icon;
  }

  pasteStyles(targetButton: ButtonView): void {
    Object.assign(targetButton.borderStyles, cloneDeep(this.copiedButton.borderStyles));
    Object.assign(targetButton.generalStyles, cloneDeep(this.copiedButton.generalStyles));
    Object.assign(targetButton.labelStyle, cloneDeep(this.copiedButton.labelStyle));
    Object.assign(targetButton.subLabelStyle, cloneDeep(this.copiedButton.subLabelStyle));
    Object.assign(targetButton.iconStyle, cloneDeep(this.copiedButton.iconStyle));
    Object.assign(targetButton.chipStyle, cloneDeep(this.copiedButton.chipStyle));
  }
}
