import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainModule } from '@private/main.module';
import { AdminTopBarModule } from '@shared/components/admin-topbar/admin-top-bar.component';
import { SidebarMenuModule } from '@shared/components/sidebar-menu/sidebar-menu.component';
import { SystemUserManagementModule } from './pages/system-user-management/system-user-management.module';
import { SystemComponent } from './system.component';

@NgModule({
  declarations: [SystemComponent],
  imports: [CommonModule, RouterModule, AdminTopBarModule, MainModule, SidebarMenuModule, SystemUserManagementModule],
  exports: [SystemComponent],
})
export class SystemModule {}
