import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { GetConditionOperationTypesPipe } from '@workflows/components/rule/condition/pipes/get-condition-operation-types.pipe';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { DYNAMIC_ATTRIBUTE_PREFIX, DYNAMIC_ATTRIBUTE_SUFFIX, RuleConditionOperatorType, WorkflowTrigger, WorkflowType } from '@workflows/types';
import { RuleCondition, RuleConditionValue } from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { take } from 'rxjs';

@Component({
  selector: 'app-workflows-rule-condition',
  templateUrl: './workflow-rule-condition.component.html',
  styleUrls: ['./workflow-rule-condition.component.scss'],
})
export class WorkflowRuleConditionComponent extends AbstractWorkflowRuleItemComponent implements OnInit, AfterViewInit {
  @Input() group: RuleConditionGroup;
  @Input() depth: number;
  @Input() allAttributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() users: ListContainer<NewUser> = new ListContainer();
  @Input() triggers: WorkflowTrigger[];
  @Input() workflowType?: WorkflowType;

  @ViewChildren('conditionGroup') conditionGroups: QueryList<WorkflowRuleConditionComponent>;

  operatorTypes: RuleConditionOperatorType[];
  selectedAttributes: NewAttribute[] = [];
  protected readonly WorkflowType = WorkflowType;

  constructor(
    private readonly cache: NewCacheService,
    protected translateService: TranslateService,
    private readonly conditionConverter: RuleConditionValueConverterService,
  ) {
    super(translateService);
    this.operatorTypes = Object.values(RuleConditionOperatorType);
  }

  ngOnInit(): void {
    this.updateAvailableAttributes();
  }

  ngAfterViewInit(): void {
    this.initializeManualSources(this.group);
  }

  onAddCondition(): void {
    this.group.conditions.push(RuleConditionGroup.defaultCondition());
  }

  onAddGroup(): void {
    this.group.groups ??= [];
    this.group.groups.push(RuleConditionGroup.defaultGroup(this.conditionConverter));
  }

  onRemoveGroup(index: number): void {
    if (this.group.groups) {
      this.group.groups.splice(index, 1);
    }
  }

  onRemoveCondition(index: number): void {
    this.group.conditions.splice(index, 1);
  }

  onIsConditionManuallySetChange(source: RuleConditionValue, destination: RuleConditionValue): void {
    source.manual = !source.manual;
    destination.value = '';

    const attributeId = SharedMethods.getMongoIdFromString(source.value);
    !source.manual && this.onManualSetFalse(source, destination, attributeId);
    source.manual && this.onManualSetTrue(source, destination, attributeId);
  }

  onManualSetFalse(source: RuleConditionValue, destination: RuleConditionValue, attributeId: string[] | null): void {
    if (attributeId && this.defaultArtifactType.attributes[attributeId[0]]) {
      source.value = attributeId[0];
    } else {
      source.value = '';
    }
    destination.value = null;
    destination.isDynamic = false;
  }

  onManualSetTrue(source: RuleConditionValue, destination: RuleConditionValue, attributeId: string[] | null): void {
    if (attributeId && this.defaultArtifactType.attributes[attributeId[0]]) {
      source.value = `${DYNAMIC_ATTRIBUTE_PREFIX}${source.value}${DYNAMIC_ATTRIBUTE_SUFFIX}`;
    } else {
      source.value = '';
    }
    destination.isDynamic = true;
  }

  onAttributeChange(attributeId: string, condition: RuleCondition): void {
    condition.source.value = attributeId;
    condition.destination.value = null;
    condition.operationType = new GetConditionOperationTypesPipe().transform(
      condition.source.value,
      this.selectedAttributes,
      this.dataTypes,
      condition.source.manual,
    )[0];
  }

  onIsConditionDestinationDynamicChange(condition: RuleCondition): void {
    condition.destination.value = null;
  }

  updateAvailableAttributes(): void {
    if (!this.defaultArtifactType) {
      return;
    }

    const linkedArtifactTypeIds = this.triggers.filter(trigger => trigger.type === 'LINK_ADDED').map(trigger => trigger.artifactTypeId);
    const linkedArtifactSet = new Set<NewAttribute>();
    linkedArtifactTypeIds.forEach(artifactTypeId => this.getArtifactTypeAttributes(artifactTypeId!).forEach(attr => linkedArtifactSet.add(attr)));

    this.selectedAttributes = [...this.getArtifactTypeAttributes(this.defaultArtifactType.id), ...Array.from(linkedArtifactSet.values())];

    if (this.conditionGroups) {
      this.conditionGroups.forEach(group => group.updateAvailableAttributes());
    }
  }

  private initializeManualSources(group: RuleConditionGroup): void {
    const init = () => {
      group.conditions.forEach(condition => {
        if (condition.source.value) {
          const isDynamicNotation = SharedMethods.isDynamicAttributeNotation(condition.source.value);
          const attributeId = SharedMethods.getMongoIdFromString(condition.source.value);
          const valueIsId = attributeId && condition.source.value === attributeId[0];

          condition.source.manual = (!isDynamicNotation && !valueIsId) || !attributeId || !this.defaultArtifactType.attributes[attributeId[0]];
        } else if (this.defaultArtifactType === undefined) {
          condition.source.manual = true;
        }
      });

      group.groups.forEach(group => this.initializeManualSources(group));
    };
    if (this.cache.isLoaded === true) {
      init();
    } else {
      this.cache.isLoaded$.pipe(take(1)).subscribe(init);
    }
  }
}
