import { FetchDataActionWorkflowSettingsDto } from '@api/models/fetch-data-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum HttpRequestMethodsEnum {
  get = 'GET',
  head = 'HEAD',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
  connect = 'CONNECT',
  options = 'OPTIONS',
  trace = 'TRACE',
  patch = 'PATCH',
}

export enum HttpRequestSchemesEnum {
  http = 'http',
  https = 'https',
}

export enum FetchDataResponseTypeEnum {
  json = 'json',
  xml = 'xml',
  string = 'string',
}

export type FetchDataRequestType = `${FetchDataRequestTypeEnum}`;

export enum FetchDataRequestTypeEnum {
  json = 'json',
  xml = 'xml',
  txt = 'txt',
  none = 'none',
}

export enum FetchDataKeys {
  scheme = 'scheme',
  url = 'url',
  method = 'method',
  queryParams = 'queryParams',
  additionalHeaders = 'additionalHeaders',
  body = 'body',
  bodyRequestType = 'bodyRequestType',
  expectedBodyResponseType = 'expectedBodyResponseType',
  expectedResponseCodes = 'expectedResponseCodes',
  storeResultsPath = 'storeResultsPath',
}

export interface FetchDataParam {
  id: string;
  value: string;
}

export class WorkflowActionFetchDataDto implements FetchDataActionWorkflowSettingsDto {
  scheme: FetchDataActionWorkflowSettingsDto[FetchDataKeys.scheme] = { value: HttpRequestSchemesEnum.http, isDynamic: false };
  url: FetchDataActionWorkflowSettingsDto[FetchDataKeys.url] = { value: '', isDynamic: false };
  method: FetchDataActionWorkflowSettingsDto[FetchDataKeys.method] = { value: HttpRequestMethodsEnum.get, isDynamic: false };
  queryParams: FetchDataActionWorkflowSettingsDto[FetchDataKeys.queryParams] = { value: {}, isDynamic: false };
  additionalHeaders: FetchDataActionWorkflowSettingsDto[FetchDataKeys.additionalHeaders] = { value: {}, isDynamic: false };
  body: FetchDataActionWorkflowSettingsDto[FetchDataKeys.body] = { value: '', isDynamic: false };
  bodyRequestType: FetchDataActionWorkflowSettingsDto[FetchDataKeys.bodyRequestType] = { value: FetchDataRequestTypeEnum.txt, isDynamic: false };
  expectedBodyResponseType: FetchDataActionWorkflowSettingsDto[FetchDataKeys.expectedBodyResponseType] = {
    value: FetchDataResponseTypeEnum.json,
    isDynamic: false,
  };
  expectedResponseCodes: FetchDataActionWorkflowSettingsDto[FetchDataKeys.expectedResponseCodes] = { value: [''], isDynamic: false };
  storeResultsPath: FetchDataActionWorkflowSettingsDto[FetchDataKeys.storeResultsPath] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionFetchDataDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionFetchData extends AbstractWorkflowAction<WorkflowActionFetchDataDto> {
  queryParams: FetchDataParam[] = [];
  additionalHeaders: FetchDataParam[] = [];
  body: FetchDataParam[] = [];
  bodyRequestType: FetchDataRequestType = FetchDataRequestTypeEnum.none;

  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionFetchDataDto, type: WorkflowActionType.FETCH_DATA, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      const queryParamsDto = (dto.actionSettings as FetchDataActionWorkflowSettingsDto).queryParams.value || {};
      this.queryParams = Object.entries(queryParamsDto).map(([key, value]) => ({ id: key, value }));

      const additionalHeaders = (dto.actionSettings as FetchDataActionWorkflowSettingsDto).additionalHeaders.value || {};
      this.additionalHeaders = Object.entries(additionalHeaders).map(([key, value]) => ({ id: key, value }));

      if (this.actionSettings.bodyRequestType.value !== FetchDataRequestTypeEnum.txt) {
        const bodyDto = (dto.actionSettings as FetchDataActionWorkflowSettingsDto).body.value || {};
        this.body = Object.entries(bodyDto).map(([key, value]) => ({ id: key, value }));
      }

      const bodyRequestType = (dto.actionSettings as FetchDataActionWorkflowSettingsDto).bodyRequestType.value;
      this.bodyRequestType = bodyRequestType === null ? FetchDataRequestTypeEnum.none : bodyRequestType;
    }
  }

  toServer(): WorkflowActionDto {
    const queryParams: Record<string, string> = this.queryParams.reduce((queryParamsMap: Record<string, string>, queryParam: FetchDataParam) => {
      return {
        ...queryParamsMap,
        [queryParam.id.replace(/ /g, '_')]: queryParam.value,
      };
    }, {});

    const additionalHeaders: Record<string, string> = this.additionalHeaders.reduce(
      (additionalHeadersMap: Record<string, string>, additionalHeader: FetchDataParam) => {
        return {
          ...additionalHeadersMap,
          [additionalHeader.id.replace(/ /g, '_')]: additionalHeader.value,
        };
      },
      {},
    );

    let body: Record<string, string> = {};
    if (this.bodyRequestType === FetchDataRequestTypeEnum.json || this.bodyRequestType === FetchDataRequestTypeEnum.xml) {
      body = this.body.reduce((bodyMap: Record<string, string>, body: FetchDataParam) => {
        return {
          ...bodyMap,
          [body.id.replace(/ /g, '_')]: body.value,
        };
      }, {});
    }

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [FetchDataKeys.queryParams]: {
          isDynamic: this.actionSettings.queryParams.isDynamic,
          value: this.queryParams.length ? queryParams : null,
        },
        [FetchDataKeys.additionalHeaders]: {
          isDynamic: this.actionSettings.additionalHeaders.isDynamic,
          value: this.additionalHeaders.length ? additionalHeaders : null,
        },
        [FetchDataKeys.body]: {
          isDynamic: this.actionSettings.body.isDynamic,
          value: this.bodyRequestType === FetchDataRequestTypeEnum.txt ? this.actionSettings.body.value || null : Object.keys(body).length ? body : null,
        },
        [FetchDataKeys.bodyRequestType]: {
          isDynamic: false,
          value: this.bodyRequestType === FetchDataRequestTypeEnum.none ? null : this.bodyRequestType,
        },
      },
    };
  }
}
