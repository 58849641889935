import { Component, Input } from '@angular/core';
import { TenantSsoGoogleDataDto, TenantSsoOpenidDataDto, TenantTenantResponseDto } from '@api/models';
import { SupportedSsoServices, TenantSsoModel } from '@private/pages/current-tenant/components/tenant-sso/types/tenant-sso.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-tenant-sso',
  templateUrl: './tenant-sso.component.html',
  styleUrls: ['./tenant-sso.component.scss'],
})
export class TenantSsoComponent {
  @Input() tenant: TenantTenantResponseDto;

  protected m: TenantSsoModel = new TenantSsoModel();

  constructor(
    private readonly elvisUtil: ElvisUtil,
    private readonly announcement: AnnouncementService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  deleteSsoService(ssoService: string): void {
    const ssoDataEntries = Object.entries(this.tenant.ssoData);
    const filteredSsoDataEntries = ssoDataEntries.filter(([key]) => key !== ssoService);
    this.tenant.ssoData = Object.fromEntries(filteredSsoDataEntries);
  }

  prependNewSsoService(): void {
    const { ssoService, data } = this.m.newSsoService;
    if (ssoService === SupportedSsoServices.google) {
      const ssoData = data as Partial<TenantSsoGoogleDataDto>;
      if (!ssoData.clientId || !ssoData.clientSecret) {
        this.announcement.error('Missing required fields');
        return;
      }

      this.tenant.ssoData[ssoService] = {
        clientId: ssoData.clientId,
        clientSecret: ssoData.clientSecret,
      };
    } else if (ssoService === SupportedSsoServices.openid) {
      const ssoData = data as Partial<TenantSsoOpenidDataDto>;
      if (!ssoData.clientId || !ssoData.authorizeUrl || !ssoData.issuer || !ssoData.tokenUrl || !ssoData.revokeTokenUrl) {
        this.announcement.error('Missing required fields');
        return;
      }

      this.tenant.ssoData[ssoService] = {
        clientId: ssoData.clientId,
        authorizeUrl: ssoData.authorizeUrl,
        issuer: ssoData.issuer,
        tokenUrl: ssoData.tokenUrl,
        revokeTokenUrl: ssoData.revokeTokenUrl,
      };
    } else {
      this.announcement.error('Select a SSO service');
      return;
    }

    this.m.newSsoService = {
      ssoService: null,
      data: {},
    };
    this.m.addNewMode = false;
  }

  filterNullSsoServices(): TenantTenantResponseDto['ssoData'] {
    const ssoDataEntries = Object.entries(this.tenant.ssoData);
    const filteredSsoDataEntries = ssoDataEntries.filter(([key, value]) => {
      key;
      return value !== null;
    });
    return Object.fromEntries(filteredSsoDataEntries);
  }

  openDialogDeleteSsoService(ssoService: string): void {
    this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete SSO service', `Are you sure, you want to delete '${ssoService}' SSO service ?`, 'Yes', 'No'),
      this.deleteSsoService.bind(this, ssoService),
    );
  }

  protected readonly SupportedSsoServices = SupportedSsoServices;
}
