<p-dialog
  [header]="headerTitle"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="this.hideModal()"
>
  <div class="file-upload-wrapper">
    <p-fileUpload
      #importField
      mode="basic"
      chooseLabel="{{ 'Select Elwis export file' | translate }}"
      accept=".yaml"
      maxFileSize="100000000"
      [disabled]="importedFile !== undefined"
      [auto]="true"
      [fileLimit]="1"
      [customUpload]="true"
      (uploadHandler)="parseFile($event, importField)"
    ></p-fileUpload>
    <p>{{ importedFile?.name }}</p>
  </div>
  <app-parsed-import *ngIf="parsedFile" [parsedFile]="parsedFile"></app-parsed-import>
  <ng-template pTemplate="footer">
    <div class="footer-container">
      <p-tag
        class="mr-2"
        *ngIf="parsedFile && inputTypeCheck && getBaseTemplateType(parsedFile.type) !== inputTypeCheck"
        severity="danger"
        rounded="true"
        [value]="'`' + parsedFile.type + '` external template found. `' + inputTypeCheck + '` external template is required.'"
      ></p-tag>
      <button pButton [label]="'Cancel' | translate" (click)="this.hideModal()" class="p-button-warning"></button>
      <button
        *ngIf="(parsedFile && !inputTypeCheck) || (parsedFile && inputTypeCheck && getBaseTemplateType(parsedFile.type) === inputTypeCheck)"
        pButton
        [label]="'Import' | translate"
        icon="pi pi-cloud-upload"
        (click)="importFile()"
        class="p-button"
      ></button>
    </div>
  </ng-template>
</p-dialog>
