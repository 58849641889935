<div class="login-body dynamic-static-label">
  <div class="login-wrapper">
    <div class="login-panel">
      <div class="login-panel-header">
        <img src="../../../../assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout" />
        <app-language-switcher class="language-switcher"></app-language-switcher>
      </div>
      <div class="login-form">
        <h2 translate>Recover password</h2>
        <p></p>
        <div class="p-inputgroup login-item">
          <span class="p-inputgroup-addon">
            <span class="pi pi-user"></span>
          </span>
          <span class="dynamic-label">
            <input [disabled]="params.token" type="text" id="email" pInputText [(ngModel)]="params.email" />
            <label class="dynamic-label-text" for="email" translate>Email</label>
          </span>
        </div>
        <div *ngIf="params.token && params.email" class="p-inputgroup login-item">
          <span class="p-inputgroup-addon">
            <span class="pi pi-key"></span>
          </span>
          <span class="dynamic-label">
            <input id="new-password" autocomplete="false" pPassword type="password" [feedback]="false"
                   [(ngModel)]="newPassword" />
            <label class="dynamic-label-text" for="new-password" translate>New Password</label>
          </span>
        </div>
        <div *ngIf="params.token && params.email" class="p-inputgroup login-item">
          <span class="p-inputgroup-addon">
            <span class="pi pi-key"></span>
          </span>
          <span class="dynamic-label">
            <input id="confirm-password" autocomplete="false" pPassword type="password" [feedback]="false"
                   [(ngModel)]="confirmPassword" />
            <label class="dynamic-label-text" for="confirm-password" translate>Confirm Password</label>
          </span>
        </div>
        <!--        <div class="p-inputgroup login-item">-->
        <!--          <p-captcha siteKey="{{ captchaKey }}" language="{{ language }}" (onResponse)="validateCaptcha(true)" (onExpire)="validateCaptcha(false)"></p-captcha>-->
        <!--        </div>-->
        <button pButton label="{{ 'Recover password' | translate }}" type="button"
                (click)="params.token ? tokenPasswordChange() : recoverPassword()">
          <span *ngIf="inProgress" class="pi pi-spin pi-spinner"></span>
        </button>
        <button pButton label="{{ 'Cancel' | translate }}" type="button" (click)="cancel()">
          <span *ngIf="inProgress" class="pi pi-spin pi-spinner"></span>
        </button>
      </div>
    </div>
    <div class="login-image">
      <div class="login-image-content">
        <h1 translate>Recover your</h1>
        <h1 translate>Elvis</h1>
        <h1 translate>Account</h1>
      </div>
      <div class="image-footer"></div>
    </div>
  </div>
</div>
