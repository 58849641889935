import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { DisplayAtOverlayComponent } from './display-at-overlay.component';

@NgModule({
  declarations: [DisplayAtOverlayComponent],
  imports: [CommonModule, RouterModule, TranslateModule, DisplayArtifactValueToTableModule, CommonPipesModule, OverlayPanelModule],
  exports: [DisplayAtOverlayComponent],
})
export class DisplayAtOverlayModule {}
