import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';

export class DateTimeFilterOptionsTypes {
  matchType = new ListContainer<DateTimeFilterMatchType>();
  ruleType: SelectOption<string, DateRangeFilterEnum | DateFilterEnum | TimeFilterRuleType>[] = [];

  constructor(isTime?: boolean) {
    this.matchType.setList(Object.keys(DateTimeFilterMatchType).map(key => DateTimeFilterMatchType[key as keyof typeof DateTimeFilterMatchType]));
    if (isTime)
      this.ruleType = Object.keys(TimeFilterRuleType).map(option => new SelectOption(option, TimeFilterRuleType[option as keyof typeof TimeFilterRuleType]));
    else
      this.ruleType = [
        ...Object.keys(DateFilterEnum).map(option => new SelectOption(option, DateFilterEnum[option as keyof typeof DateFilterEnum])),
        ...Object.keys(DateRangeFilterEnum)
          .filter(ruleType => ruleType !== DateRangeFilterEnum.custom)
          .map(option => new SelectOption(option, DateRangeFilterEnum[option as keyof typeof DateRangeFilterEnum])),
      ];
  }
}

export enum DateTimeFilterMatchType {
  matchAll = 'Match all',
  matchAny = 'Match any',
}

export enum DateTimeFilterRuleType {
  dateIs = 'Date is',
  dateIsNot = 'Date is not',
  dateIsBefore = 'Date is before',
  dateIsAfter = 'Date is after',
}

export enum TimeFilterRuleType {
  timeIs = 'Time is',
  timeIsNot = 'Time is not',
  timeIsBefore = 'Time is before',
  timeIsAfter = 'Time is after',
}
