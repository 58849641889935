import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum } from '@shared/types/filter.types';

@Pipe({
  name: 'isRegularDateFilterType',
})
export class IsRegularDateFilterTypePipe implements PipeTransform {
  transform(matchMode: DateFilterEnum): boolean {
    const { isEmpty, isNotEmpty } = DateFilterEnum;
    return matchMode !== isNotEmpty && matchMode !== isEmpty;
  }
}
