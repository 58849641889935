import { Injectable } from '@angular/core';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageComponent } from '@shared/components/page/page.component';
import { PageModel } from '@shared/components/page/types/page.types';
import { CoreService } from '@shared/core/services/core.service';
import { SharedMethods } from '@shared/methods/shared.methods';
import { ContentStyles, FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { SidebarButtonView } from '@widgets/sidebar-widget/types/sidebar-widget-button.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SidebarWidgetCopyPasterService extends CoreService<PageComponent, PageModel> {
  copiedBlockPart: PageBlockPart | null = null;
  copiedContentStyles: ContentStyles | FontStyles | null = null;
  copiedIconStyles: ContentStyles | FontStyles | null = null;
  private runtimeStateNotificationHandlers: Record<string, (event: RuntimeStateNotification) => void>;
  private eventFromSidebar = 'sidebar';

  constructor(private readonly runtimeStateNotificationService: RuntimeStateNotificationService) {
    super();
    this.initRuntimeStateNotificationHandlers();
    this.initSubscriptions();
  }

  copyLayout(blockPart: PageBlockPart, fireEvent = true): void {
    const copy = cloneDeep(blockPart);
    copy.removeIdsForReuse();
    this.copiedBlockPart = copy;
    this.setBlockPartToServerValue(this.copiedBlockPart);
    fireEvent &&
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<PageBlockPart>(RuntimeStateNotificationEnum.copyWidget, blockPart as PageBlockPart, null, this.eventFromSidebar),
      );
  }

  pasteLayout(partToReplace: PageBlockPart): void {
    Object.assign(partToReplace, cloneDeep(this.copiedBlockPart));
    partToReplace.hash = SharedMethods.getUniqueId();
    partToReplace.widget && (partToReplace.widget.id = null);
  }

  copyContentStyles(styleToCopy: ContentStyles | FontStyles): void {
    this.copiedContentStyles = cloneDeep(styleToCopy);
  }

  pasteStyleIntoContent(styles: any): void {
    const styleToPaste = cloneDeep(this.copiedContentStyles);
    Object.assign(styles, styleToPaste);
  }

  copyIconStyles(styleToCopy: ContentStyles | FontStyles): void {
    this.copiedIconStyles = cloneDeep(styleToCopy);
  }

  pasteStyleIntoIcon(styles: any): void {
    const styleToPaste = cloneDeep(this.copiedIconStyles);
    Object.assign(styles, styleToPaste);
  }

  pasteAll(isContentStyle: boolean, views: SidebarButtonView): void {
    const type = isContentStyle ? 'contentStyle' : 'iconStyle';
    const method = isContentStyle ? 'pasteStyleIntoContent' : 'pasteStyleIntoIcon';
    Object.values(views).forEach(view => {
      this[method](view[type]);
    });
  }

  private setBlockPartToServerValue(blockPart: PageBlockPart): void {
    if (!blockPart.widget?.value?.model) {
      return;
    }

    if (blockPart.widget.value.model.copy) {
      const { settings, ...model } = blockPart.widget.value.model.copy();
      blockPart.widget.code === WidgetType.sidebar && (model.settings = settings);
      blockPart.widget.value.model = model;
    } else {
      blockPart.widget.value.model = blockPart.widget.value.model.toServer();
    }
  }

  private initRuntimeStateNotificationHandlers(): void {
    this.runtimeStateNotificationHandlers = {};

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.copyWidget] = (event: RuntimeStateNotification<string>) => {
      event.extras !== this.eventFromSidebar && this.copyLayout(event.data as any, false);
    };
  }

  private initSubscriptions(): void {
    this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<unknown>) => {
      if (this.runtimeStateNotificationHandlers[event.type]) {
        this.runtimeStateNotificationHandlers[event.type](event);
      }
    });
  }
}
