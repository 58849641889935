<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'Error' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.error.isDynamic" id="isDynamicError" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.error.value"
      [placeholder]="action.actionSettings.error.isDynamic ? 'Enter dynamic error' : ('Enter error' | translate)"
      pInputText
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Property' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.property.isDynamic" id="isDynamicProperty" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.property.value"
      [placeholder]="action.actionSettings.property.isDynamic ? 'Enter dynamic property' : ('Enter property' | translate)"
      pInputText
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Response code' | translate }}
  </div>
  <div class="col-2">
    <p-triStateCheckbox [ngModel]="false" id="isResponseCodeDynamic" [disabled]="true"></p-triStateCheckbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.responseCode.value"
      pInputText
      type="text"
      class="w-100"
      [placeholder]="('Enter response code' | translate) + '(400-499)'"
    />
  </div>
</div>
