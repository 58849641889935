// import { RuleConditionOperationType } from './condition.types';

import { NewArtifact } from '@shared/types/artifact.types';
import { WorkflowAttributeActionEventType } from '@workflows/types';

export interface AttributeBasedEvent {
  artifactTypeId: string;
  attributeId: string;
}

export interface ShowHideAttributeEvent extends AttributeBasedEvent {
  showAttribute: boolean;
}

export interface SetAttributeMandatoryEvent extends AttributeBasedEvent {
  mandatory: boolean;
}

export interface SetAttributeValueEvent extends AttributeBasedEvent {
  value: any;
}

export interface SetAttributeValueEventWrapper {
  event?: SetAttributeValueEvent;
  eventMap: Record<string, SetAttributeValueEvent>;
}

export interface ModifyAttributeValueEvent extends AttributeBasedEvent {
  operation: ModifyAttributeValueOperation;
  value?: any;
  copyFromAttributeId?: string;
}

export interface ModifyAttributeValueEventWrapper {
  event?: ModifyAttributeValueEvent;
  eventMap: Record<string, ModifyAttributeValueEvent>;
}

export interface SetLinkValueEvent {
  defaultArtifactTypeId: string;
  linkedArtifact: NewArtifact;
  linkArtifactTypeId: string;
  sourceLinkTypeId: string;
  targetLinkTypeId: string;
  targetLinkArtifactTypeId: string;
}

export type FormatAttributeEvent = AttributeBasedEvent;

export interface PageAttributeChangeEvent {
  pageId: string;
  eventType: WorkflowAttributeActionEventType;
  // TODO - check why widgets here is as array
  widgets?: string[];
  event: AttributeBasedEvent;
}

export enum ModifyAttributeValueOperation {
  add = 'add',
  remove = 'remove',
  replace = 'replace',
}
