import { Component, Type } from '@angular/core';
import { TenantSystemPageResponseDto } from '@api/models/tenant-system-page-response-dto';
import { AppAccessDeniedComponent } from '@public/pages/access-denied/app.access-denied.component';
import { LoginComponent } from '@public/pages/login/login.component';
import { AppNotfoundComponent } from '@public/pages/not-found/app.notfound.component';
import { RecoverPasswordComponent } from '@public/pages/recover-password/recover-password.component';
import { PageComponent } from '@shared/components/page/page.component';
import { LocalStorageMasterState, StateKey } from '@shared/types/local-storage.types';
import { SystemPublicPageType } from '@shared/types/system-public-pages.types';

export function getSystemPublicPageComponent(type: SystemPublicPageType): Type<Component> {
  if (type === SystemPublicPageType.login) return getLoginComponent();
  if (type === SystemPublicPageType.access) return getAccessComponent();
  if (type === SystemPublicPageType.notFound) return getNotFoundComponent();
  if (type === SystemPublicPageType.recoverPassword) return getRecoverPasswordComponent();

  throw Error('Unknown system public page type');
}

export function getSystemPublicPagesFromMasterState(): TenantSystemPageResponseDto | null {
  const storedState: string | null = localStorage.getItem(StateKey.master);
  const masterState: LocalStorageMasterState | null = storedState ? JSON.parse(storedState) : null;

  if (!masterState) {
    window.location.reload();
    return null;
  }

  return (masterState.user?.tenant as any)?.systemPages as TenantSystemPageResponseDto;
}

export function getDefaultSystemPublicPages(): TenantSystemPageResponseDto {
  return { accessPageId: null, loginPageId: null, notFoundPageId: null, recoverPasswordPageId: null };
}

function getLoginComponent(): Type<Component> {
  if (getSystemPublicPagesFromMasterState()?.loginPageId) return PageComponent as any;
  return <Type<Component>>LoginComponent;
}

function getAccessComponent(): Type<Component> {
  if (getSystemPublicPagesFromMasterState()?.accessPageId) return PageComponent as any;
  return <Type<Component>>AppAccessDeniedComponent;
}

function getNotFoundComponent(): Type<Component> {
  if (getSystemPublicPagesFromMasterState()?.notFoundPageId) return PageComponent as any;
  return <Type<Component>>AppNotfoundComponent;
}

function getRecoverPasswordComponent(): Type<Component> {
  if (getSystemPublicPagesFromMasterState()?.recoverPasswordPageId) return PageComponent as any;
  return <Type<Component>>RecoverPasswordComponent;
}
