<div *ngIf="formatSettings" [@fadeAnimation] class="grid format-options">
  <p-accordion class="col-12 mt-1">
    <p-accordionTab header="{{ 'Label styles' | translate }}">
      <app-link-type-label-styles-form
        [changeLabelStyleReference]="changeLabelStyleReference"
        [formatSettings]="formatSettings"
        [onLabelPositionChange]="onLabelPositionChange"
      >
      </app-link-type-label-styles-form>
    </p-accordionTab>

    <p-accordionTab header="{{ 'Value styles' | translate }}">
      <app-link-type-value-styles-form [formatSettings]="formatSettings"></app-link-type-value-styles-form>
    </p-accordionTab>

    <p-accordionTab header="{{ 'Shared styles (label and value)' | translate }}">
      <app-artifact-widget-item-format-settings [formatSettings]="formatSettings"></app-artifact-widget-item-format-settings>
    </p-accordionTab>

    <p-accordionTab *ngIf="linkDisplayVariantOptions && formatSettings.value.displayMetadata" [header]="'Display variant' | translate">
      <p-dropdown
        id="displayVariant"
        [(ngModel)]="formatSettings.value.displayMetadata.selectedVariantCode"
        [autoDisplayFirst]="false"
        [options]="linkDisplayVariantOptions"
        (onChange)="onSelectedItemChange($event.value)"
      >
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
        </ng-template>

        <ng-template #displayVariant let-item>
          <div>{{ item.label | translate }}</div>
        </ng-template>
      </p-dropdown>
    </p-accordionTab>
  </p-accordion>
</div>
