import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidebarActions, SidebarWidgetService } from '@shared/services/sidebar-widget.service';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent implements OnInit {
  @Input() widgetId: string;
  @Input() showPositionControls = true;
  @Input() isPageSettings = false;
  @Input() set name(data: string) {
    if (!data) return;

    const string = data.toLowerCase();
    this.title = string.charAt(0).toUpperCase() + string.slice(1) + ' widget settings';
  }
  @Output() onChangePosition: EventEmitter<boolean> = new EventEmitter<boolean>();

  title = 'Widget settings';

  constructor(public readonly widgetService: SidebarWidgetService) {}

  ngOnInit(): void {
    if (this.isPageSettings) {
      this.title = 'Page settings';
    }
  }

  togglePanelPosition(toFullScreen?: boolean): void {
    const method = toFullScreen ? SidebarActions.toggleFullscreen : SidebarActions.togglePosition;
    this.widgetService[method](this.widgetId);
    this.onChangePosition.emit(toFullScreen);
  }
}
