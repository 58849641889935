<div class="grid">
  <!--    <div class="col-12 md:col-3">-->
  <!--      <div class="p-inputgroup">-->
  <!--        <span class="p-inputgroup-addon">-->
  <!--          <span class="pi pi-user-edit"></span>-->
  <!--        </span>-->
  <!--        <span class="dynamic-label">-->
  <!--          <input [(ngModel)]="m.user.name" id="name" pInputText type="text"/>-->
  <!--          <label class="dynamic-label-text" for="name">{{'Name' | translate}}</label>-->
  <!--        </span>-->
  <!--      </div>-->
  <!--    </div>-->

  <div class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <span class="pi pi-envelope"></span>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.user.email" id="email" pInputText autocomplete="off" type="text" />
        <label class="dynamic-label-text" for="email">{{ EMAIL_LABEL | translate }}</label>
      </span>
    </div>
  </div>

  <div class="col-12 md:col-3">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="m.user.isSystemAdmin" binary="true" id="isSystemAdmin"></p-checkbox>
        <label for="isSystemAdmin">{{ IS_SYSTEM_ADMIN_LABEL | translate }}</label>
      </span>
    </span>
  </div>

  <!--  <div class="col-12 md:col-3"></div>-->

  <div class="col-12 md:col-3">
    <span class="dynamic-label">
      <p-dropdown
        [(ngModel)]="m.selectedTenantId"
        [autoDisplayFirst]="false"
        [disabled]="!!m.user.id"
        [filter]="true"
        [options]="!!m.user.id ? m.tenantOptions : m.tenantOptionsWithoutDeleted"
        id="tenantId"
        inputId="tenantId"
      >
      </p-dropdown>
      <label class="dynamic-label-text" for="tenantId">{{ TENANT_LABEL | translate }}</label>
    </span>
  </div>

  <div class="col-12 md:col-3">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="m.isTenantAdmin" binary="true" id="isTenantAdmin"></p-checkbox>
        <label for="isTenantAdmin">{{ IS_TENANT_ADMIN_LABEL | translate }}</label>
      </span>
    </span>
  </div>

  <div *ngIf="!m.generatePassword" class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <span class="pi pi-key"></span>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.password" id="password" autocomplete="new-password" pPassword type="password" />
        <label class="dynamic-label-text" for="password">{{ 'Password' | translate }}</label>
      </span>
    </div>
  </div>

  <div *ngIf="!m.generatePassword" class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <span class="pi pi-key"></span>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.confirmPassword" id="confirmPassword" autocomplete="new-password" pPassword type="password" />
        <label class="dynamic-label-text" for="confirmPassword">{{ 'Confirm Password' | translate }}</label>
      </span>
    </div>
  </div>
</div>
