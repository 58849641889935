import { ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { fromEvent, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CoreRxSubscriptionsComponent } from '@shared/core/components/core-rx-subscriptions.component';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-display-hyperlink-icon',
  templateUrl: './display-hyperlink-icon.component.html',
  styleUrls: ['./display-hyperlink-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayHyperlinkIconComponent extends CoreRxSubscriptionsComponent implements OnInit {
  @Input() attribute: NewAttribute;
  @Input() ownerId: string;
  @Input() set attr(attr: NewClientAttribute) {
    this.attrValue = attr.value;
    if (!this.isMultiple) return;

    this.attributeMultipleValue = Array.isArray(attr.value) ? attr.value : !attr.value ? [] : attr.value.split(',');

    setTimeout(() => {
      const container = document.getElementById(this.hash);
      if (!container) return;
      const elements: HTMLElement[] = container.getElementsByClassName('link-icon') as unknown as HTMLElement[];
      for (const elem of elements) {
        this.initObservers(elem);
      }
    });
  }

  @ViewChild('linkOp') overlayPanel: OverlayPanel;

  @ViewChild('iconClick') set iconClick(content: ElementRef) {
    if (content) {
      const contentNative = content.nativeElement;
      this.initObservers(contentNative);
    }
  }

  @ViewChild('iconContainer') set iconClickM(content: ElementRef) {
    if (content) {
      const elements: HTMLElement[] = content.nativeElement.getElementsByClassName('link-icon');
      for (const elem of elements) {
        this.initObservers(elem);
      }
    }
  }

  get isMultiple(): boolean {
    return this.attribute?.multipleValues;
  }

  attrValue: string | string[] = '';
  currentLink: string | null;
  attributeMultipleValue: string[] | null;
  hash: string = ElvisUtil.makeHash(20);
  event: Event;
  doOpen: boolean;

  constructor(private readonly zone: NgZone) {
    super();
  }

  ngOnInit(): void {
    this.isMultiple && (this.attributeMultipleValue = Array.isArray(this.attrValue) ? this.attrValue : this.attrValue.split(',').filter(val => val));
  }

  onIconClick(url: string): void {
    this.overlayPanel.hide();
    url && window.open(url, '_blank');
  }

  onOverlayHide(): void {
    if (this.doOpen) {
      setTimeout(() => {
        this.overlayPanel.show(this.event);
      });
      this.doOpen = false;
    }
  }

  private initObservers(nativeElement: HTMLElement) {
    if (!nativeElement) return;
    this.zone.runOutsideAngular(() => {
      const mouseEnter$ = fromEvent(nativeElement, 'mouseenter').pipe(
        tap(event =>
          this.zone.run(() => {
            const id = nativeElement.getAttribute('id');
            if (!id) return;
            const index = parseInt(id, 10);
            const value = this.isMultiple ? this.attributeMultipleValue : this.attrValue;
            this.currentLink = Array.isArray(value) ? value[index] : value;
            this.event = event;

            if (this.overlayPanel.isOverlayAnimationInProgress) {
              this.doOpen = true;
            } else {
              this.overlayPanel.show(this.event);
            }
          }),
        ),
      );

      const mouseOut$ = fromEvent(nativeElement, 'mouseout').pipe(
        tap(() =>
          this.zone.run(() => {
            this.doOpen = false;
            this.overlayPanel.hide();
          }),
        ),
      );

      this.registerSubscriptions([merge(mouseEnter$, mouseOut$).subscribe()]);
    });
  }
}
