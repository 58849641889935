import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { BaseDataType, DataTypeKind } from '../../data-type/components/data-type-form/types/data-type-form.types';

export class DataTypeListModel {
  constructor(
    public rowsPerPage: number | null = null,
    public rowsPerPageOptions: number[] = [],
    public columns: TableColumn[] = [],
    public applications = new ListContainer<ApplicationResponseDto>(),
    public baseDataTypeOptions: SelectOption<BaseDataType, BaseDataType>[] = [],
    public kindOptions: SelectOption<DataTypeKind, DataTypeKind>[] = [],
  ) {}
}
