import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ApplicationResponseDto,
  ArtifactAttributeRequestDto,
  ArtifactResponseDto,
  SelfUserResponseDto,
  SystemUserTenantResponseDto,
  TemplateResponseDto,
} from '@api/models';
import { TenantArtifactService, TenantTemplateService } from '@api/services';
import { TemplateMeta } from '@private/pages/page-management/page-builder-graphical/types/template-meta';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { IconsService } from '@shared/services/icons.service';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { FileUpload } from 'primeng/fileupload';
import { of, Subscription, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

const initialMeta: TemplateMeta = {
  name: '',
  applicationId: '',
  description: '',
  icon: null,
  categories: [],
  thumbnailFileArtifactId: null,
};

@Component({
  selector: 'app-template-meta-form',
  templateUrl: './template-meta-form.component.html',
  styleUrls: ['./template-meta-form.component.scss'],
})
export class TemplateMetaFormComponent implements OnInit, OnDestroy {
  @Input() template: TemplateResponseDto | null = null;
  @Output() saveTemplate: EventEmitter<TemplateMeta> = new EventEmitter<TemplateMeta>();

  visible = false;
  processing = false;
  meta: TemplateMeta = { ...initialMeta };
  iconOptions: SelectOption<string, string>[] = [];
  selectedCategoryOptions: SelectOption<string, string>[] = [];
  filteredCategoryOptions: SelectOption<string, string>[] = [];
  applicationOptions = new ListContainer<NewApplication>();
  header: string;
  nameLabel: string;
  templateType: TemplateType;
  thumbnailArtifact: ArtifactResponseDto | null = null;
  newArtifactFile: Blob | null = null;
  private categoryOptions: SelectOption<string, string>[] = [];
  private currentUserTenant: SystemUserTenantResponseDto | null = null;
  private subscribtions: Subscription[] = [];

  constructor(
    iconsService: IconsService,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly cache: NewCacheService,
    private readonly announcement: AnnouncementService,
  ) {
    this.subscribtions.push(iconsService.icons$.subscribe((icons: SelectOption<string, string>[]) => (this.iconOptions = icons)));
  }

  ngOnInit(): void {
    this.currentUserTenant = (this.cache.user.value as SelfUserResponseDto).tenant;
    this.subscribtions.push(
      this.cache.data.applications.subscribe(applications => {
        this.applicationOptions.setList((applications as ApplicationResponseDto[])!.map(dto => new NewApplication(dto as ApplicationResponseDto)));
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(subscribtion => subscribtion.unsubscribe());
  }

  clearThumbnail(): void {
    this.thumbnailArtifact = null;
    this.newArtifactFile = null;
  }

  async onDialogShow(): Promise<void> {
    if (this.template?.thumbnailFileArtifactId) {
      this.thumbnailArtifact = await this.cache.data.artifacts.getAsync(this.template!.thumbnailFileArtifactId);
    }

    this.tenantTemplateService.templateControllerGroupby({ groupBy: 'categories' }).subscribe(groupByResult => {
      this.categoryOptions = groupByResult.data.map(category => {
        const categoryOption = new SelectOption<string, string>(category.value);
        if (this.template?.categories.includes(category.value)) this.selectedCategoryOptions.push(categoryOption);
        return categoryOption;
      });
      this.selectedCategoryOptions = [...this.selectedCategoryOptions];
    });
  }

  onDialogHide(): void {
    this.resetModal();
  }

  show(templateType: TemplateType): void {
    if (this.template !== null) {
      this.meta = {
        applicationId: this.template.applicationId,
        name: this.template.name,
        description: this.template.description,
        icon: this.template.icon,
        categories: this.template.categories,
        thumbnailFileArtifactId: this.template.thumbnailFileArtifactId,
      };
    }
    this.templateType = templateType;
    this.setFormText();
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  save(): void {
    this.processing = true;
    const getThumbnailResponse = this.newArtifactFile
      ? this.cache.data.artifactTypes.get$(this.currentUserTenant!.fileArtifactTypeId!).pipe(
          switchMap(fileArtifactType =>
            this.tenantArtifactService
              .artifactControllerUpload({
                notify: false,
                body: {
                  artifactTypeId: this.currentUserTenant!.fileArtifactTypeId!,
                  attributes: Object.keys(fileArtifactType.attributes).reduce((output, attributeId) => {
                    output[attributeId] = { value: '' };
                    return output;
                  }, {} as Record<string, ArtifactAttributeRequestDto>),
                  file: this.newArtifactFile!,
                  parentFolderId: 'null',
                },
              })
              .pipe(tap(dto => this.cache.data.artifacts.setItem(dto))),
          ),
        )
      : of(null as ArtifactResponseDto | null);

    getThumbnailResponse.subscribe((thumbnailArtifactResponse: ArtifactResponseDto | null) => {
      // to delete thumbnail
      if (this.meta.thumbnailFileArtifactId && this.thumbnailArtifact === null) this.meta.thumbnailFileArtifactId = null;
      // to set new thumbnail
      if (thumbnailArtifactResponse) {
        this.cache.data.artifacts.setItem(thumbnailArtifactResponse);
        this.meta.thumbnailFileArtifactId = thumbnailArtifactResponse.id;
      }

      this.meta.categories = this.selectedCategoryOptions.map(selectedCategoryOption => selectedCategoryOption.value);
      this.saveTemplate.emit(this.meta);
    });
  }

  resetModal(): void {
    this.meta = { ...initialMeta };
    this.categoryOptions = [];
    this.filteredCategoryOptions = [];
    this.selectedCategoryOptions = [];
    this.thumbnailArtifact = null;
    this.newArtifactFile = null;
    this.processing = false;
  }

  filterCategories(primengEvent: Record<string, any>) {
    if (primengEvent.query === '') {
      // [suggestions] field on p-autoComplete requires new array reference on every update
      this.filteredCategoryOptions = [...this.categoryOptions];
      return;
    }
    this.filteredCategoryOptions = this.categoryOptions.filter(categoryOption => RegExp(primengEvent.query).test(categoryOption.value));
  }

  addCategory(event: KeyboardEvent): void {
    const value = `${(event.target as any)?.value}`;
    if (event.key === 'Enter' && value !== '') {
      const newCategoryOptions = new SelectOption<string, string>(value);
      this.categoryOptions.push(newCategoryOptions);
      this.selectedCategoryOptions = [...this.selectedCategoryOptions, newCategoryOptions];
      this.filteredCategoryOptions = this.categoryOptions;
      if ((event.target as any)?.value) {
        (event.target as any).value = '';
      }
    }
  }

  onSelectFile(event: { files: File[] }, fileUploadComponent: FileUpload): void {
    if (event.files.length === 0) return;
    const file = event.files[0];
    this.newArtifactFile = file as Blob;
    fileUploadComponent.clear();
  }

  onSelect(event: { currentFiles: File[] }): void {
    if (event.currentFiles.length === 0) {
      this.announcement.error('Unable to select file');
    }
  }

  private setFormText(): void {
    switch (this.templateType) {
      case TemplateType.section:
        this.header = this.template ? 'Edit Section Template' : 'Save Section as Template';
        this.nameLabel = this.template ? 'Edit a (unique) name of the section' : 'Give a (unique) name for the section';
        break;
      case TemplateType.widget:
        this.header = this.template ? 'Edit Widget Template' : 'Save Widget as Template';
        this.nameLabel = this.template ? 'Edit a (unique) name of the widget' : 'Give a (unique) name for the widget';
        break;
    }
  }
}
