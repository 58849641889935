<div class="mt-2">
  <p-dropdown
    (onChange)="onMatchModeChange()"
    [(ngModel)]="filterValue.matchMode"
    [optionValue]="'value.value'"
    [options]="matchModes | transformArrayToSelectOptions: 'label'">
  </p-dropdown>
</div>
<div class="mt-2" *ngIf="filterValue.matchMode | isRegularEnumFilterType">
  <p-multiSelect
    (onChange)="onSelectedOptionsChange()"
    [optionValue]="'value.value'"
    [(ngModel)]="filterValue.selectedEnumValues"
    [options]="enumValues | transformArrayToSelectOptions: 'label'"
    defaultLabel="Filter by attribute value"
    optionLabel="label">
  </p-multiSelect>
</div>
