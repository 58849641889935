import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonFeTrigger } from '@workflows/types/triggers/common-fe-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerAttributeValueChange extends AbstractWorkflowTrigger<CommonFeTrigger> {
  artifactTypeId: string;
  attributeId: string;

  constructor(artifactTypeId?: string, attributeId?: string) {
    super(CommonFeTrigger);
    this.artifactTypeId = artifactTypeId || this.artifactTypeId;
    this.attributeId = attributeId || this.attributeId;
    this.type = RuleTriggerType.ATTRIBUTE_VALUE_CHANGE;
    this.artifactTypeRequired = true;
    this.attributeRequired = true;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    if (this.type === workflowTrigger.type) {
      const trigger = workflowTrigger as WorkflowTriggerAttributeValueChange;
      return this.artifactTypeId === trigger.artifactTypeId && this.attributeId === trigger.attributeId;
    }
    return false;
  }

  isValid(): boolean {
    return !!(this.artifactTypeId && this.attributeId);
  }
}
