import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarModalSettings } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-settings.types';
import { SidebarModalOptions } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-options.types';
import { SidebarModalPosition } from '../../types/sidebar-modal.types';
import { StyleOptions } from '@widgets/shared/types/style.types';
import { SidebarModalStyles } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-styles.types';
import { ButtonCopyPastingService } from '@widgets/sidebar-modal-widget/services/copy-paste.service';
import { SidebarModalWidgetService } from '@widgets/sidebar-modal-widget/services/sidebar-modal.service';

@Component({
  selector: 'app-sidebar-modal-widget-settings',
  templateUrl: './sidebar-modal-widget-settings.component.html',
  styleUrls: ['./sidebar-modal-widget-settings.component.scss'],
  providers: [ButtonCopyPastingService],
})
export class SidebarModalWidgetSettingsComponent {
  @Input() settings: SidebarModalSettings;
  @Input() options: SidebarModalOptions;
  @Input() styles: SidebarModalStyles;
  @Input() s: SidebarModalWidgetService;
  @Output() onChangeSettings = new EventEmitter<boolean>();
  position = SidebarModalPosition;
  styleOptions: StyleOptions = new StyleOptions();

  constructor(public readonly copyService: ButtonCopyPastingService) {}

  onChange(isChangePosition = false): void {
    this.onChangeSettings.emit(isChangePosition);
  }

  stopEventPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
