import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserListTableComponent } from '@private/pages/user-management/user-list/components/user-list-table/user-list-table.component';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { UserListComponent } from './user-list/user-list.component';
import { AdminUserFormComponent } from './user/components/admin-user-form/admin-user-form.component';
import { UserComponent } from './user/user.component';
import { TenantAdminUserFormComponent } from './user/components/tenant-admin-user-form/tenant-admin-user-form.component';
import { ApplicationAdminUserFormComponent } from './user/components/app-admin-user-form/application-admin-user-form.component';
import { TooltipModule } from 'primeng/tooltip';

const declarations = [UserListComponent, UserComponent, UserListTableComponent];

@NgModule({
  declarations: [...declarations, AdminUserFormComponent, TenantAdminUserFormComponent, ApplicationAdminUserFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FormsModule,
    CheckboxModule,
    DropdownModule,
    PickListModule,
    PasswordModule,
    InputTextModule,
    MultiSelectModule,
    RippleModule,
    LoaderModule,
    ElvisSharedModule,
    CardModule,
    TriStateCheckboxModule,
  ],
  exports: [...declarations],
})
export class UserManagementModule {}
