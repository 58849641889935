import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetRelevantLinks } from './get-relevant-links.pipe';
import { GetLinkedArtifactsByLinksDataPipe } from './get-linked-artifacts-by-links-data.pipe';
import { GetLinkAttributeIdPipe } from './get-link-attribute-id.pipe';
import { ToLinksPerArtifactPipe } from './to-links-per-artifact.pipe';

const pipes = [GetRelevantLinks, GetLinkedArtifactsByLinksDataPipe, GetLinkAttributeIdPipe, ToLinksPerArtifactPipe];

// TODO move other link pipes here into this module and update references
@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class LinkPipesModule {}
