import { EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { ConvertStringOrDateToDate, ConvertToClientDate, GetAllDaysInRangeMap } from '@shared/methods/date.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SortTypeValueEnum } from '@shared/types/sort.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterMetadata } from 'primeng/api';
import { GroupAttributeItem, GroupItem, GroupingSettings } from '../../types/list-widget-grouping.types';
import { AbstractGroupingHandler } from './abstract-grouping-handler';

export class DateGroupingHandler extends AbstractGroupingHandler {
  constructor(private readonly filterMetadataUtil: FilterMetadataUtil) {
    super();
  }

  getAllGroups(groupingSettings: GroupingSettings): Record<string, GroupItem> {
    const groupMap: Record<string, GroupItem> = {};
    const { filter, customSettings } = groupingSettings.groupByDate;
    const startEndDate = this.filterMetadataUtil.transformDateRangeEnumToDateRange(filter, customSettings);

    const groups = GetAllDaysInRangeMap(startEndDate);
    Object.keys(groups).forEach((stringDate, index) => {
      groupMap[stringDate] = {
        id: stringDate,
        artifacts: [],
        metaData: { isDisplayed: true, count: 0, header: stringDate, toggled: false },
        isDefaultGroup: false,
        sortOrder: index,
        total: 0,
      };
    });

    return groupMap;
  }

  getGroupingHeader(groupId?: string): string {
    return groupId || '';
  }

  getGroupingValue(): string {
    return '';
  }

  getGroupId(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem): string {
    if (NonAttributeKeys.isDateSpecificAttributeKey(groupAttributeItem.id)) {
      const groupValue = SharedMethods.getValueFromPath(groupAttributeItem.id, artifact);
      return ConvertToClientDate(ConvertStringOrDateToDate(groupValue));
    }
    const groupValue = artifact.attributes[groupAttributeItem.id].value;
    if (!groupValue) return EMPTY_GROUP_VALUE;
    return ConvertToClientDate(ConvertStringOrDateToDate(groupValue));
  }

  sortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[] {
    const { sortSettings } = groupingSettings;
    const { sortType } = sortSettings;
    const isAsc = sortType === SortTypeValueEnum.ASC;

    const emptyGroupArray = groupItems.filter(item => item.id === EMPTY_GROUP_VALUE);
    const normalGroupItems = groupItems.filter(item => item.id !== EMPTY_GROUP_VALUE);

    const sortedGroupItems = this.sortGroupItems(normalGroupItems);
    const resultItems = isAsc ? [...emptyGroupArray, ...sortedGroupItems] : [...sortedGroupItems.reverse(), ...emptyGroupArray];
    resultItems.forEach((item, index) => (item.sortOrder = index));
    return resultItems;
  }

  getFilterMetadataForGroupItem(groupItem: GroupItem): FilterMetadata {
    const startEndDate = this.filterMetadataUtil.getStartEndDateFromDate(groupItem.metaData?.date || new Date());
    return this.filterMetadataUtil.getFilterMetadataFromStartEndDate(startEndDate) as FilterMetadata;
  }

  protected sortGroupItems(groupItems: GroupItem[]): GroupItem[] {
    return groupItems.sort((a, b) => {
      const firstDate = a.id.split('.').reverse().join('');
      const secondDate = b.id.split('.').reverse().join('');
      return firstDate.localeCompare(secondDate);
    });
  }
}
