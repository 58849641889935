<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div>
      <div *ngIf="m.linkType && m.linkType.id">
        <div class="p-inputgroup">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox binary="true" inputId="isDeleted" id="isDeleted" [(ngModel)]="m.isDeleted"
                          [disabled]="true"></p-checkbox>
              <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate}}</label>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <button *ngIf="m.linkType && m.linkType.id && !m.linkType.deleted" [label]="'Delete' | translate"
              (click)="s.deleteWithConfirmation(m.linkType)"
              class="p-button p-button-danger" pButton>
      </button>
      <button pButton pRipple type="button" label="{{'Cancel' | translate}}" class="p-button p-button-warning"
              (click)="s.cancel()"></button>
      <button pButton pRipple type="button" label="{{'Save' | translate}}" class="p-button p-button-success"
              (click)="s.save()"></button>
    </div>
  </div>

  <div class="col-12 link-type-form">
    <app-link-type-form [applications]="m.applications.list" [linkType]="m.linkType"></app-link-type-form>
  </div>

  <div class="col-12 link-type-form">
    <app-link-type-restrictions-form *ngIf="m.linkType"
                                     [applications]="m.applications.list"
                                     [linkType]="m.linkType"
                                     [artifactTypes]="m.artifactTypes"></app-link-type-restrictions-form>
  </div>
</div>
