import { Component, Input } from '@angular/core';
import { AttributeFormatSettings, LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';

@Component({
  selector: 'app-artifact-widget-item-format-settings',
  templateUrl: './artifact-widget-item-format-settings.component.html',
  styleUrls: ['./artifact-widget-item-format-settings.component.scss'],
})
export class ArtifactWidgetItemFormatSettingsComponent {
  @Input() formatSettings: AttributeFormatSettings | LinkTypeFormatSettings;
}
