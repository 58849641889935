import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ListMetaData } from '@shared/core/types/core.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewDataType } from '@shared/types/data-type.types';
import { CustomDateSettings, DateRangeFilterEnum } from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { SortTypeValueEnum } from '@shared/types/sort.types';
import { Observable } from 'rxjs';

export class GroupingSettings {
  groupingAttributes: SelectOption<string, GroupAttributeItem>[] = [];
  pagination: PaginationSettingEnum = PaginationSettingEnum.perTable;
  groupCollapse: GroupCollapseEnum = GroupCollapseEnum.expandAll;
  showEmptyGroups = false;
  sortSettings: GroupingSortSettings = new GroupingSortSettings();
  groupByDate: GroupByDateSettings = new GroupByDateSettings();

  constructor(settings?: Partial<GroupingSettings>) {
    settings && Object.assign(this, settings);
  }
}

export class GroupingSortSettings {
  // for simplicity reason, the only one attribute will be used for sorting (in future there can be many)
  sortAttribute = 'sortOrder';
  sortType: SortTypeValueEnum = SortTypeValueEnum.ASC;
  order: GroupOrderEnum = GroupOrderEnum.orderFirst;

  constructor(sortOptions?: Partial<GroupingSortSettings>) {
    sortOptions && Object.assign(this, sortOptions);
  }
}

export enum PaginationSettingEnum {
  perTable = 'perTable',
  perGroup = 'perGroup',
}

export enum GroupCollapseEnum {
  collapseAll = 'collapseAll',
  expandAll = 'expandAll',
  expandFirst = 'expandFirst',
}

export enum GroupOrderEnum {
  orderFirst = 'orderFirst',
  groupFirst = 'groupFirst',
}

export interface GroupMetaDataItem {
  index: number;
  size: number;
  toggled: boolean;
}

export interface GroupMetaDataTableItem {
  isDisplayed: boolean;
  header: string;
  count: number;
  toggled: boolean;
  date?: Date;
}

export interface GroupMetaItem {
  groupItem: GroupItem;
  listMetadata: ListMetaData;
  data$?: Observable<NewArtifact[]>;
}

export interface GroupAttributeItem {
  /** (non) attribute id */
  id: string;
  name?: string;
  kind: DataTypeKind;
  baseDataType: BaseDataType;
  multipleValues: boolean;
  dataType?: NewDataType;
  dataTypeId?: string | null;
  isNonAttribute?: boolean;
}

export interface GroupItem {
  id: string;
  metaData?: GroupMetaDataTableItem;
  artifacts: NewArtifact[];
  total?: number;
  isDefaultGroup?: boolean;
  sortOrder: number;
}

export interface GroupItemArtifactObject {
  groupItem: GroupItem;
  artifact: NewArtifact;
}

export class GroupByDateSettings {
  filter: DateRangeFilterEnum = DateRangeFilterEnum.currentMonth;
  customSettings = new CustomDateSettings();

  constructor(dateSettings?: Partial<GroupByDateSettings>) {
    dateSettings && Object.assign(this, dateSettings);
  }
}

export interface GroupByCountMeta {
  filter?: string;
  groupBy: string;
  dateFormat?: 'YEAR' | 'MONTH' | 'DAY' | 'WEEK' | 'HOUR' | 'MINUTE';
}
