import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonFeTrigger } from '@workflows/types/triggers/common-fe-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerButtonClick extends AbstractWorkflowTrigger<CommonFeTrigger> {
  elementId: string;

  constructor(elementId?: string) {
    super(CommonFeTrigger);
    this.elementId = elementId || this.elementId;
    this.type = RuleTriggerType.BUTTON_CLICK;
    this.elementRequired = true;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    if (this.type === workflowTrigger.type) {
      const trigger = workflowTrigger as WorkflowTriggerButtonClick;
      return this.elementId === trigger.elementId;
    }
    return false;
  }

  isValid(): boolean {
    return !!this.elementId;
  }
}
