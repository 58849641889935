import { CoreListFilterEnum } from '@shared/core/types/core.types';
import {
  ConvertToServerDate,
  ConvertToServerDatetime,
  GetDateMinusDays,
  GetDatePlusDays,
  GetEndOfTheDay,
  GetFirstDayOfCurrentMonth,
  GetFirstDayOfCurrentYear,
  GetFirstDayOfLastMonth,
  GetFirstDayOfLastYear,
  GetFirstDayOfNextMonth,
  GetFirstDayOfNextYear,
  GetLastDayOfCurrentMonth,
  GetLastDayOfCurrentYear,
  GetLastDayOfLastMonth,
  GetLastDayOfLastYear,
  GetLastDayOfMonthAfterMonths,
  GetLastDayOfNextMonth,
  GetLastDayOfNextYear,
  GetLastMondayDate,
  GetLastSundayDate,
  GetMondayOfCurrentWeekDate,
  GetNextMondayDate,
  GetNextSundayDate,
  GetStartOfTheDay,
  GetSundayOfCurrentWeekDate,
  GetToday,
  GetTomorrow,
  GetYesterday,
} from '@shared/methods/date.methods';
import { NewDataType } from '@shared/types/data-type.types';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';

type FilterType = DateRangeFilterEnum | DateFilterEnum | CoreListFilterEnum;

type SystemDateComparisonDbUnit = {
  $date: string;
};
type DateAttributeComparisonDbUnit = string;
type ComparisonDbUnit = SystemDateComparisonDbUnit | DateAttributeComparisonDbUnit;

type LogicalOperator = '$and' | '$or';
type ComparisonOperator = '$lt' | '$gt' | '$lte' | '$gte';

type Comparison = { [comparisonOperator in ComparisonOperator]?: Date };
type Comparisons = Comparison[];

type FormattedComparison = { [comparisonOperator in ComparisonOperator]?: ComparisonDbUnit };

type Condition = {
  [fieldName: string]: FormattedComparison;
};

type Query = { [operator in LogicalOperator]?: Condition[] };

export class DateFilter {
  private readonly start: Date;
  private readonly end: Date | null;
  private readonly dateFormatter: (date: Date) => string;
  private readonly offsetInDays: number | null;

  constructor(
    protected readonly filterType: FilterType,
    protected readonly fieldName: string,
    protected readonly rawValue: string[],
    private readonly dataType: NewDataType | null,
  ) {
    const [start, end] = this.rawValue;
    this.start = new Date(start);
    this.end = end ? new Date(end) : null;
    this.offsetInDays = isNaN(+start) ? null : +start;

    if (this.isSystemAttribute) {
      this.dateFormatter = (date: Date) => date.toString();
    }

    if (dataType?.isDate) {
      this.dateFormatter = (date: Date) => ConvertToServerDate(date);
    }

    if (dataType?.isDateTime) {
      this.dateFormatter = (date: Date) => ConvertToServerDatetime(date);
    }
  }

  get query(): Query {
    const logicOperator = this.filterType === DateFilterEnum.dateIsNot ? '$or' : '$and';
    const conditions = this.comparisons.reduce((acc: Condition[], comparison: Comparison) => {
      return [...acc, { [this.fieldName]: this.getFormattedComparison(comparison) }];
    }, []);

    return { [logicOperator]: conditions };
  }

  private get isSystemAttribute(): boolean {
    return !this.dataType;
  }

  private get comparisons(): Comparisons {
    switch (this.filterType) {
      case DateRangeFilterEnum.dateBetween:
        return this.getDateBetween();
      case DateRangeFilterEnum.dueInDays:
        return this.getDueInDays();
      case DateRangeFilterEnum.dueInDaysOrLess:
        return this.getDueInDaysOrLess();
      case DateRangeFilterEnum.dueInDaysOrMore:
        return this.getDueInDaysOrMore();
      case DateRangeFilterEnum.ageInDays:
        return this.getAgeInDays();
      case DateRangeFilterEnum.ageInDaysOrLess:
        return this.getAgeInDaysOrLess();
      case DateRangeFilterEnum.ageInDaysOrMore:
        return this.getAgeInDaysOrMore();
      case DateRangeFilterEnum.yesterday:
        return this.getYesterday();
      case DateRangeFilterEnum.today:
        return this.getToday();
      case DateRangeFilterEnum.tomorrow:
        return this.getTomorrow();
      case DateRangeFilterEnum.afterToday:
        return this.getAfterToday();
      case DateRangeFilterEnum.beforeToday:
        return this.getBeforeToday();
      case DateRangeFilterEnum.todayOrAfter:
        return this.getTodayOrAfter();
      case DateRangeFilterEnum.todayOrBefore:
        return this.getTodayOrBefore();
      case DateRangeFilterEnum.lastWeek:
        return this.getLastWeek();
      case DateRangeFilterEnum.currentWeek:
        return this.getCurrentWeek();
      case DateRangeFilterEnum.nextWeek:
        return this.getNextWeek();
      case DateRangeFilterEnum.lastMonth:
        return this.getLastMonth();
      case DateRangeFilterEnum.currentMonth:
        return this.getCurrentMonth();
      case DateRangeFilterEnum.nextMonth:
        return this.getNextMonth();
      case DateRangeFilterEnum.lastYear:
        return this.getLastYear();
      case DateRangeFilterEnum.currentYear:
        return this.getCurrentYear();
      case DateRangeFilterEnum.nextYear:
        return this.getNextYear();
      case DateRangeFilterEnum.last7Days:
        return this.getLastDays(7);
      case DateRangeFilterEnum.last30Days:
        return this.getLastDays(30);
      case DateRangeFilterEnum.last60Days:
        return this.getLastDays(60);
      case DateRangeFilterEnum.last90Days:
        return this.getLastDays(90);
      case DateRangeFilterEnum.last120Days:
        return this.getLastDays(120);
      case DateRangeFilterEnum.next6Months:
        return this.getNextMonths(6);
      case DateRangeFilterEnum.next12Months:
        return this.getNextMonths(12);

      case DateFilterEnum.dateIs:
        return this.getDateIs();
      case DateFilterEnum.dateIsNot:
        return this.getDateIsNot();
      case DateFilterEnum.dateBefore:
        return this.getDateBefore();
      case DateFilterEnum.dateBeforeOrEqualTo:
        return this.getDateBeforeOrEqualTo();
      case DateFilterEnum.dateAfter:
        return this.getDateAfter();
      case DateFilterEnum.dateAfterOrEqualTo:
        return this.getDateAfterOrEqualTo();
      default:
        return this.getToday();
    }
  }

  private getDateIs(): Comparisons {
    const startDate = GetEndOfTheDay(GetDateMinusDays(new Date(this.start), 1));
    const endDate = GetStartOfTheDay(GetDatePlusDays(new Date(this.start), 1));

    return [{ $gt: startDate }, { $lt: endDate }];
  }

  private getDateIsNot(): Comparisons {
    const startDate = GetStartOfTheDay(new Date(this.start));
    const endDate = GetEndOfTheDay(new Date(this.start));

    return [{ $lt: startDate }, { $gt: endDate }];
  }

  private getDateBefore(): Comparisons {
    const endDate = GetStartOfTheDay(new Date(this.start));

    return [{ $lt: endDate }];
  }

  private getDateBeforeOrEqualTo(): Comparisons {
    const endDate = GetEndOfTheDay(new Date(this.start));

    return [{ $lte: endDate }];
  }

  private getDateAfter(): Comparisons {
    const startDate = GetEndOfTheDay(new Date(this.start));

    return [{ $gt: startDate }];
  }

  private getDateAfterOrEqualTo(): Comparisons {
    const startDate = GetStartOfTheDay(new Date(this.start));

    return [{ $gte: startDate }];
  }

  private getDateBetween(): Comparisons {
    const { start, end } = this;
    const startDate = GetStartOfTheDay(new Date(start));
    const endDate = GetEndOfTheDay(new Date(end!));

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getDueInDays(): Comparisons {
    const dueDate = GetDatePlusDays(GetToday(), this.offsetInDays!);
    const startDate = GetStartOfTheDay(dueDate);
    const endDate = GetEndOfTheDay(dueDate);

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getDueInDaysOrLess(): Comparisons {
    return [{ $gte: GetStartOfTheDay(GetToday()) }, { $lte: GetEndOfTheDay(GetDatePlusDays(GetToday(), this.offsetInDays!)) }];
  }

  private getDueInDaysOrMore(): Comparisons {
    return [{ $gte: GetStartOfTheDay(GetDatePlusDays(GetToday(), this.offsetInDays!)) }];
  }

  private getAgeInDays(): Comparisons {
    const date = GetDateMinusDays(GetToday(), this.offsetInDays!);
    const startDate = GetStartOfTheDay(date);
    const endDate = GetEndOfTheDay(date);

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getAgeInDaysOrLess(): Comparisons {
    const startDate = GetStartOfTheDay(GetDateMinusDays(GetToday(), this.offsetInDays!));

    return [{ $gte: startDate }];
  }

  private getAgeInDaysOrMore(): Comparisons {
    const endDate = GetEndOfTheDay(GetDateMinusDays(GetToday(), this.offsetInDays!));

    return [{ $lte: endDate }];
  }

  private getYesterday(): Comparisons {
    const yesterday = GetYesterday();
    const startDate = GetStartOfTheDay(yesterday);
    const endDate = GetEndOfTheDay(yesterday);

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getToday(): Comparisons {
    const today = GetToday();
    const startDate = GetStartOfTheDay(today);
    const endDate = GetEndOfTheDay(today);

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getTomorrow(): Comparisons {
    const tomorrow = GetTomorrow();
    const startDate = GetStartOfTheDay(tomorrow);
    const endDate = GetEndOfTheDay(tomorrow);

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getAfterToday(): Comparisons {
    const startDate = GetStartOfTheDay(GetTomorrow());

    return [{ $gte: startDate }];
  }

  private getBeforeToday(): Comparisons {
    const endDate = GetEndOfTheDay(GetYesterday());

    return [{ $lte: endDate }];
  }

  private getTodayOrAfter(): Comparisons {
    const startDate = GetStartOfTheDay(GetToday());

    return [{ $gte: startDate }];
  }

  private getTodayOrBefore(): Comparisons {
    const endDate = GetEndOfTheDay(GetToday());

    return [{ $lte: endDate }];
  }

  private getLastWeek(): Comparisons {
    const startDate = GetStartOfTheDay(GetLastMondayDate());
    const endDate = GetEndOfTheDay(GetLastSundayDate());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getCurrentWeek(): Comparisons {
    const startDate = GetStartOfTheDay(GetMondayOfCurrentWeekDate());
    const endDate = GetEndOfTheDay(GetSundayOfCurrentWeekDate());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getNextWeek(): Comparisons {
    const startDate = GetStartOfTheDay(GetNextMondayDate());
    const endDate = GetEndOfTheDay(GetNextSundayDate());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getLastMonth(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfLastMonth());
    const endDate = GetEndOfTheDay(GetLastDayOfLastMonth());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getCurrentMonth(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfCurrentMonth());
    const endDate = GetEndOfTheDay(GetLastDayOfCurrentMonth());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getNextMonth(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfNextMonth());
    const endDate = GetEndOfTheDay(GetLastDayOfNextMonth());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getLastYear(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfLastYear());
    const endDate = GetEndOfTheDay(GetLastDayOfLastYear());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getCurrentYear(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfCurrentYear());
    const endDate = GetEndOfTheDay(GetLastDayOfCurrentYear());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getNextYear(): Comparisons {
    const startDate = GetStartOfTheDay(GetFirstDayOfNextYear());
    const endDate = GetEndOfTheDay(GetLastDayOfNextYear());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getLastDays(numberOfDays: number): Comparisons {
    const startDate = GetStartOfTheDay(GetDateMinusDays(GetToday(), numberOfDays - 1));
    const endDate = GetEndOfTheDay(GetToday());

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getNextMonths(numberOfMonths: number): Comparisons {
    const startDate = GetStartOfTheDay(GetToday());
    const endDate = GetEndOfTheDay(GetLastDayOfMonthAfterMonths(numberOfMonths));

    return [{ $gte: startDate }, { $lte: endDate }];
  }

  private getFormattedComparison(comparison: Comparison): FormattedComparison {
    const [[comparisonOperator, date]] = Object.entries(comparison) as [[ComparisonOperator, Date]];
    const dateString = this.dateFormatter(date);
    const comparisonDbUnit = this.isSystemAttribute ? { $date: dateString } : dateString;

    return { [comparisonOperator]: comparisonDbUnit };
  }
}
