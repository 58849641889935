import { Injectable } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ConvertStringOrDateToDate, GetStartOfTheDay, GetStartOfTheMinute } from '@shared/methods/date.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { arrayOfStringOrNumber, stringOrNumber } from '@workflows/types/conditions/includes.condition';
import { RuleConditionValue } from '@workflows/types/conditions/rule-condition';
import moment from 'moment';
import { BaseDataType, DataTypeKind } from '../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

@Injectable({ providedIn: 'root' })
export class RuleConditionsUtilService {
  constructor(private readonly cache: NewCacheService) {
  }

  // isConditionValid(condition: RuleCondition): boolean {
  //   const { source, operationType } = condition;
  //   const definition = RuleConditionTypeDefinitions.getDefinition(operationType);
  //   const baseDataType = this.getBaseDataTypeFromSource(source);
  //   const dataKind = this.getDataKindFromSource(source);
  //   return (
  //     (!definition.supportedTypes.length || definition.supportedTypes.includes(baseDataType)) &&
  //     (!definition.supportedKinds.length || definition.supportedKinds.includes(dataKind))
  //   );
  // }

  // getValidDefinitions(attributeId: string): RuleConditionTypeDefinitions[] {
  //   const baseDataType = this.getBaseDataTypeAttribute(attributeId);
  //   const dataKind = this.getDataKindAttribute(attributeId);
  //   return RuleConditionTypeDefinitions.getAllDefinitions().filter(
  //     definition =>
  //       !definition.supportedTypes.length ||
  //       (definition.supportedTypes.includes(baseDataType) && (!definition.supportedKinds.length || definition.supportedKinds.includes(dataKind))),
  //   );
  // }

  formatValue(value: any, attributeId: string): any {
    const attribute = this.cache.data.attributes.get(attributeId);
    const dataType = this.cache.data.dataTypes.get(attribute!.dataTypeId);

    const baseDataType = dataType!.baseDataType as BaseDataType;
    const kind = dataType!.kind;

    if (kind === DataTypeKind.enumerated) {
      return this.formatEnum(value);
    }

    if (baseDataType === BaseDataType.user) {
      return this.formatUser(value);
    }

    if (baseDataType === BaseDataType.boolean) {
      return this.formatBoolean(value);
    }

    if (baseDataType === BaseDataType.date || baseDataType === BaseDataType.dateTime || baseDataType === BaseDataType.time) {
      return this.formatDate(value, baseDataType);
    }

    if (baseDataType === BaseDataType.integer || baseDataType === BaseDataType.decimal) {
      return this.formatNumber(value);
    }

    return value;
  }

  private formatUser(users: SelectOption<string, string>[] | null): string[] {
    if (!users || !users[0]) return [];
    return users.map(user => {
      if (user.value === NonAttributeKeys.CURRENT_USER_ID) {
        return (this.cache.user.value as SelfUserResponseDto).id;
      }
      return user.value;
    });
  }

  private formatEnum(value: DataTypeValueResponseDto | DataTypeValueResponseDto[] | null): string | string[] {
    if (!value) return '';
    return Array.isArray(value) ? value.map(val => val.value) : value.value;
  }

  private formatBoolean(value: boolean | string | null): boolean | null {
    if (value === '') {
      return null;
    }

    if (typeof value === 'string') {
      return value === 'true';
    }

    return value;
  }

  private formatDate(value: Date | string, baseDataType: BaseDataType): string {
    if (baseDataType === BaseDataType.date) {
      return moment(GetStartOfTheDay(ConvertStringOrDateToDate(value))).format();
    }

    if (baseDataType === BaseDataType.dateTime) {
      return moment(GetStartOfTheMinute(ConvertStringOrDateToDate(value))).format();
    }

    return moment(ConvertStringOrDateToDate(value)).format('HH:mm');
  }

  private formatNumber(value: stringOrNumber | arrayOfStringOrNumber): number | number[] {
    if (Array.isArray(value)) {
      return value.map(value => Number(value));
    }
    return Number(value);
  }

  // getSupportedOperationTypes(dataType: NewDataType): WorkflowConditionType {}

  private getBaseDataTypeFromSource(source: RuleConditionValue): BaseDataType {
    return this.getBaseDataTypeAttribute(source.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getBaseDataTypeAttribute(attributeId: string): BaseDataType {
    // TODO: some logic comes here
    return BaseDataType.decimal;
  }

  private getDataKindFromSource(source: RuleConditionValue): DataTypeKind {
    return this.getDataKindAttribute(source.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getDataKindAttribute(attributeId: string): DataTypeKind {
    // TODO: some logic comes here
    return DataTypeKind.simple;
  }
}
