import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsHtml } from '../../methods/data-type.methods';

@Pipe({ name: 'isHtml' })
export class IsHtmlPipe implements PipeTransform {
  transform(baseDataType: BaseDataType): boolean {
    return IsHtml(baseDataType);
  }
}
