import { Directive, HostBinding, Input } from '@angular/core';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { INITIAL_GRID_ROW_SIZE } from '@shared/constants/constants';
import { GridUtil } from '@shared/utils/grid.util';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';

@Directive({
  selector: '[appCardAreaMinHeight]',
})
export class AreaMinHeightDirective {
  @Input() area: GenericArea<CardWidgetAreaContent>;
  @Input() gridTemplateRows: string;
  @Input() isAdvancedModeOpen: boolean;

  @HostBinding('style.min-height') get minHeight(): string | null {
    return this.isAdvancedModeOpen && this.isEmptyArea() && this.hasAutoOrRelativeHeight() ? INITIAL_GRID_ROW_SIZE : null;
  }

  private hasAutoOrRelativeHeight(): boolean {
    return this.getOccupiedRowsHeightStyles().every(GridUtil.isAutoOrRelativeTrack);
  }

  private getOccupiedRowsHeightStyles(): string[] {
    if (!this.area) {
      return [];
    }

    const startIndex = +this.area.gridRowStart - 1;
    const endIndex = +this.area.gridRowEnd - 1;

    return this.gridTemplateRows.split(' ').splice(startIndex, endIndex);
  }

  private isEmptyArea(): boolean {
    return !this.area?.content.items.length;
  }
}
