export const SidebarModalConstants = Object.freeze({
  DEFAULT_BACKGROUND_COLOR: '#ffffff',
  DEFAULT_WIDTH: '25%',
  DEFAULT_HEIGHT: '25%',
  FULL_SIZE: '100%',
  PREVIEW_PENCIL_SELECTOR: '.utility-buttons',
  PENCIL_PADDING: '5px',
  DEFAULT_UNIT: 'px',
  DEFAULT_BLUR: '5px',
  MOBILE_BREAK_POINT: 576,
  DEFAULT_CENTER_WIDTH: '256px',
  DEFAULT_CENTER_HEIGHT: '256px',
});
