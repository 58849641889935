<p-dropdown
  id="application"
  [(ngModel)]="selectedApplication"
  [options]="applications.list"
  [filter]="true"
  filterBy="name"
  placeholder="{{ 'Select application' | translate }}"
  optionLabel="name"
  optionKey="id"
  (onChange)="onApplicationSelection($event.value)"
>
</p-dropdown>

<ng-container *ngIf="!selectedApplication && externalFilterApplied">
  <div class="external-filter-applied">{{ 'External filter applied' | translate }}</div>
</ng-container>

<ng-container *ngIf="selectedApplication">
  <p-tree
    #folderTree
    *ngIf="selectedApplication"
    [value]="folders$ | async"
    [(selection)]="selectedFolders"
    selectionMode="multiple"
    scrollHeight="200px"
    (onNodeSelect)="folderNodeSelect($event.originalEvent, $event.node)"
    (onNodeExpand)="folderNodeExpand($event.node)"
  >
    <ng-template let-node pTemplate="default">
      <span [id]="node.id" class="folder-tree-entry" [ngStyle]="node.styles">
        {{ node.label }}
      </span>
    </ng-template>
  </p-tree>

  <div class="folder-include-subfolders">
    <p-checkbox
      binary="true"
      inputId="includeSubfoldersId"
      id="includeSubfoldersId"
      [(ngModel)]="includeSubfolders"
      [disabled]="selectedFolders.length > 1"
    ></p-checkbox>
    <label for="includeSubfoldersId" translate>{{ 'Include subfolders' | translate }}</label>
  </div>
</ng-container>

<div *ngIf="showControlButtons" class="{{ controlButtonsStyleClass }}">
  <p-button label="Clear" styleClass="p-button-outlined" (onClick)="onClearClick()"></p-button>
  <p-button label="Apply" [disabled]="!selectedApplication" (onClick)="onApplyClick()"></p-button>
</div>
