import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ArtifactAttributeFormFieldLabelWithIconComponent, InputTextareaModule, ChipsModule],
  selector: 'app-artifact-attribute-form-field-numeric',
  templateUrl: './artifact-attribute-form-field-numeric.component.html',
  styleUrls: ['./artifact-attribute-form-field-numeric.component.scss'],
})
export class ArtifactAttributeFormFieldNumericComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;

  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: (value: any) => Promise<void>;
  @Input() onBlur: () => void;

  labelBehaviourEnum = LabelBehaviourEnum;

  constructor(private readonly elvisUtil: ElvisUtil) {}

  async checkNumFormat(event: string, id: string, targetObject: any, key: string): Promise<void> {
    this.elvisUtil.checkNumFormat(event, id, targetObject, key, this.dataType.isInteger, this.dataType.isDecimal);

    await this.onChange(this.attr.value);
  }

  async checkNumFormatMultiple(): Promise<void> {
    const checkNum = (startValue: string): string => {
      const decimal = /^[0-9]{0,}([.][0-9]{0,})?$/g;
      const integer = /^[0-9]{0,}$/g;
      let value = startValue;

      if (this.dataType.isInteger && !integer.test(value)) {
        value = value.substr(0, value.length - 1);
        return checkNum(value);
      } else {
        if (this.dataType.isDecimal && !decimal.test(value)) {
          value = value.substr(0, value.length - 1);
          return checkNum(value);
        }
      }
      return value;
    };
    this.attr.value = this.attr.value.map((value: any) => checkNum(value)).filter((item: any) => !!item);
    await this.onChange(this.attr.value);
  }

  async onNumericMultipleFieldChange(event: string, index: number, attribute: NewClientAttribute): Promise<void> {
    await this.checkNumFormat(event, 'intField' + index, attribute, 'initialValue');
    await this.onChange(this.attr.value);
  }

  async onNumericFieldChange(): Promise<void> {
    await this.checkNumFormatMultiple();
    await this.onChange(this.attr.value);
  }
}
