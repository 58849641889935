import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getFileSize' })
export class GetFileSizePipe implements PipeTransform {
  transform(fileSize: string): string {
    return this.transformFileSize(fileSize);
  }

  private transformFileSize(fileSize: string): string {
    const fileSizeNumber = Number(fileSize);
    const i = fileSizeNumber === 0 ? 0 : Math.floor(Math.log(fileSizeNumber) / Math.log(1024));
    return String((fileSizeNumber / Math.pow(1024, i)).toFixed(i === 0 ? 0 : 2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]);
  }
}
