import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { LinkType } from '@shared/types/link-type.types';
import { filter, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttributeActionHandlerService } from '../services/attribute-action-handler.service';
import { AttributeLinkSettable, SetLinkValueEvent } from '../types';
import { AbstractRuleDirective } from './abstract-rule.directive';

@Directive({
  selector: '[appSetLinkValue]',
})
export class SetLinkValueDirective extends AbstractRuleDirective implements OnInit, OnChanges, OnDestroy {
  @Input() linkOwner: AttributeLinkSettable;
  @Input() linkAttributeId: string;
  @Input() linkType: LinkType;

  protected key: string;
  private initSubscription: Subscription;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.initSubscription = this.actionAttributeHandler.setLinkValueEventMap$
      .pipe(
        filter(() => this.actionAttributeHandler.containsSetLinkValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getSetLinkValueEvent(this.key)),
      )
      .subscribe(setLinkValue => {
        this.setLinkValue(setLinkValue);
        this.actionAttributeHandler.removeSetLinkValueEventFromMap(this.key, true);
      });
  }

  ngOnChanges(): void {
    if (!this.key && this.linkAttributeId) {
      this.key = this.linkAttributeId.split('_')[0];
    }
  }

  ngOnDestroy(): void {
    this.initSubscription?.unsubscribe();
  }

  protected isMatchingAttributeData(data: SetLinkValueEvent): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [linkAttributeId, direction] = this.linkAttributeId.split('_');
    return linkAttributeId === data.targetLinkTypeId;
  }

  private setLinkValue(setLinkValue: SetLinkValueEvent) {
    this.linkOwner.setLinkValue(setLinkValue);
  }
}
