export enum BaseDataType {
    integer='INTEGER',
    decimal='DECIMAL',
    boolean='BOOLEAN',
    text='TEXT',
    date='DATE',
    time='TIME',
    datetime='DATETIME',
    user='USER',
    html='HTML',
    hyperlink='HYPERLINK',
    file='FILE'
}

export enum KindOfValue {
    simple='SIMPLE',
    enumerated='ENUMERATED',
    bounded='BOUNDED',
    counter='COUNTER'
}

export enum NumberOfValues {
    one,
    multiple
}

export enum PrivateAttribute {
    public,
    private
}

export enum ArtifactTypeFormat {
    text='TEXT',
    file='FILE',
    module='MODULE'
}