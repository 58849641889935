import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NAME_LABEL } from '@shared/constants/constants';

import { CoreComponent } from '@shared/core/components/core.component';
import { ApplicationService } from './services/application.service';
import { ApplicationModel } from './types/application.types';

import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  providers: [ApplicationService],
})
export class ApplicationComponent extends CoreComponent<ApplicationService, ApplicationModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: ApplicationService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new ApplicationModel(), service, announcement);
  }

  NAME_LABEL = NAME_LABEL;

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Application' }]);
  }
}
