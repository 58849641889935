import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

@Directive({ selector: '[appPictureWidgetResizeObserver]' })
export class PictureWidgetResizeObserverDirective implements OnDestroy {
  @Output()
  resizeEvent = new EventEmitter();

  entriesMap: WeakMap<object, any>;
  ro: ResizeObserver;

  constructor(private el: ElementRef) {
    this.entriesMap = new WeakMap();
    this.ro = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (this.entriesMap.has(entry.target)) {
          const comp = this.entriesMap.get(entry.target);
          comp._resizeCallback(entry);
        }
      }
    });

    const target = this.el.nativeElement;
    this.entriesMap.set(target, this);
    this.ro.observe(target);
  }

  _resizeCallback(entry: ResizeObserverEntry) {
    this.resizeEvent.emit(entry);
  }

  ngOnDestroy() {
    const target = this.el.nativeElement;
    this.ro.unobserve(target);
    this.entriesMap.delete(target);
  }
}
