import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevelopmentComponent } from './development.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [DevelopmentComponent],
  imports: [CommonModule, ButtonModule, RippleModule, TranslateModule, FileUploadModule],
  exports: [DevelopmentComponent],
})
export class DevelopmentModule {}
