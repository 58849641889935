<!-- menu overlay panel -->
<p-overlayPanel #menuOp appOverlayPanelShowFix appendTo="body" styleClass="table-header-menu cop-no-padding" [style]="{ 'max-width': '380px' }">
  <ng-template pTemplate>
    <p-accordion styleClass="accordion-no-highlight accordion-menu-style" collapseIcon="pi pi-fw pi-minus" expandIcon="pi pi-fw pi-plus">
      <p-accordionTab header="Header">
        <!-- (labelPositionChange)="onLabelPositionChange($event, formatSettings)"
          (labelStyleChange)="changeLabelStyleReference(formatSettings)" -->
        <app-attribute-format-label-styles-form
          [showLabelOption]="false"
          [showFontBackgroundSettings]="true"
          [enableLabelPositioning]="false"
          [labelFormatSettings]="columnFormat"
          (labelStyleChange)="onColumnFormatChange()"
          (labelPositionChange)="onColumnFormatChange()"
          [isLink]="column.meta.isLink"
        >
        </app-attribute-format-label-styles-form>
        <!-- <p-menu [model]="headerMenu"></p-menu> -->
      </p-accordionTab>
      <p-accordionTab header="Content styles" *ngIf="showStyling">
        <app-attribute-format-label-styles-form
          [showLabelOption]="false"
          [showFontBackgroundSettings]="true"
          [enableLabelPositioning]="false"
          [showPrefixSuffixSettings]="
            (column.meta.attributeMetadata?.dataType.baseDataType | isInteger)
            || (column.meta.attributeMetadata?.dataType.baseDataType | isDecimal)
          "
          [showDecimalPlacesSettings]="column.meta.attributeMetadata?.dataType.baseDataType | isDecimal"
          [isContentStyles]="true"
          [labelFormatSettings]="contentColumnFormat"
          (labelStyleChange)="onColumnFormatChange()"
          (labelPositionChange)="onColumnFormatChange()"
        >
        </app-attribute-format-label-styles-form>
      </p-accordionTab>
      <p-accordionTab header="Edit mode">
        <p-dropdown
          [autoDisplayFirst]="false"
          (onChange)="onEditModeChange($event.value)"
          [options]="displayAtEditDropdownItems"
          [(ngModel)]="selectedEditItem"
          id="artifactType"
        >
          <ng-template pTemplate="selectedItem">
            <div>{{ selectedEditItem.label | translate }}</div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>{{ item.label | translate }}</div>
          </ng-template>
        </p-dropdown>
      </p-accordionTab>

      <p-accordionTab header="Display variant">
        <p-dropdown
          [autoDisplayFirst]="false"
          (onChange)="onSelectedItemChange($event.value)"
          [options]="displayAtDropdownItems"
          [(ngModel)]="selectedItem"
          id="artifactType"
        >
          <ng-template pTemplate="selectedItem">
            <div>{{ selectedItem.label | translate }}</div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>{{ item.label | translate }}</div>
          </ng-template>
        </p-dropdown>
      </p-accordionTab>
      <!-- <p-accordionTab header="Parameter filter">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="m.artifact.deleted" binary="true" id="enableParamFiltering" inputId="enableParamFiltering"></p-checkbox>
          <label for="enableParamFiltering" translate>Enable parameter filtering</label>
        </span>
        <span class="dynamic-label">
          <input type="text" id="name" pInputText [(ngModel)]="m.artifactType.name"/>
          <label class="dynamic-label-text" for="name" translate>Attribute name</label>
        </span>
      </p-accordionTab> -->
    </p-accordion>
  </ng-template>
</p-overlayPanel>

<!-- column header settings -->
<p-overlayPanel #headerFontStyleOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <p-tabView [activeIndex]="0">
      <p-tabPanel *ngFor="let styleType of styleTypes" [header]="styleType | translate">
        <h5>{{ 'Label styles' | translate }}</h5>
        <app-font-style-form-op
          [fontStyles]="columnFormat"
          [hideFontFamilySettings]="false"
          [hideBackgroundColorSetting]="false"
          [hideHorizontalAlignSettings]="true"
          (onChange)="onColumnFormatChange()"
        ></app-font-style-form-op>
      </p-tabPanel>
    </p-tabView>
  </ng-template>
</p-overlayPanel>
<!-- column padding settings -->
<p-overlayPanel #headerPaddingFormOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-padding-form [paddingStyles]="padding"></app-padding-form>
  </ng-template>
</p-overlayPanel>
<!-- column alignment settings -->
<p-overlayPanel #headerAlignmentFormOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-flex-content-alignment-form [styles]="columnFormat" [showVertical]="false" (onChange)="onColumnFormatChange()"></app-flex-content-alignment-form>
  </ng-template>
</p-overlayPanel>
