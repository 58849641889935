import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styles: [
    `
      .copyright a {
        color: inherit;
      }
    `,
  ],
})
export class AppFooterComponent {}
