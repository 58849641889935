/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttributeAclResponseDto } from '../models/attribute-acl-response-dto';
import { attributeControllerAclInsert } from '../fn/tenant-attribute/attribute-controller-acl-insert';
import { AttributeControllerAclInsert$Params } from '../fn/tenant-attribute/attribute-controller-acl-insert';
import { attributeControllerAclRemove } from '../fn/tenant-attribute/attribute-controller-acl-remove';
import { AttributeControllerAclRemove$Params } from '../fn/tenant-attribute/attribute-controller-acl-remove';
import { attributeControllerCount } from '../fn/tenant-attribute/attribute-controller-count';
import { AttributeControllerCount$Params } from '../fn/tenant-attribute/attribute-controller-count';
import { attributeControllerCreate } from '../fn/tenant-attribute/attribute-controller-create';
import { AttributeControllerCreate$Params } from '../fn/tenant-attribute/attribute-controller-create';
import { attributeControllerDelete } from '../fn/tenant-attribute/attribute-controller-delete';
import { AttributeControllerDelete$Params } from '../fn/tenant-attribute/attribute-controller-delete';
import { attributeControllerGet } from '../fn/tenant-attribute/attribute-controller-get';
import { AttributeControllerGet$Params } from '../fn/tenant-attribute/attribute-controller-get';
import { attributeControllerGetAcl } from '../fn/tenant-attribute/attribute-controller-get-acl';
import { AttributeControllerGetAcl$Params } from '../fn/tenant-attribute/attribute-controller-get-acl';
import { attributeControllerList } from '../fn/tenant-attribute/attribute-controller-list';
import { AttributeControllerList$Params } from '../fn/tenant-attribute/attribute-controller-list';
import { attributeControllerUpdate } from '../fn/tenant-attribute/attribute-controller-update';
import { AttributeControllerUpdate$Params } from '../fn/tenant-attribute/attribute-controller-update';
import { AttributeListResponseDto } from '../models/attribute-list-response-dto';
import { AttributeResponseDto } from '../models/attribute-response-dto';
import { BaseCountResponseDto } from '../models/base-count-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantAttributeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `attributeControllerCount()` */
  static readonly AttributeControllerCountPath = '/api/tenant/attribute/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerCount$Response(params?: AttributeControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return attributeControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerCount(params?: AttributeControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.attributeControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerGetAcl()` */
  static readonly AttributeControllerGetAclPath = '/api/tenant/attribute/acl/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerGetAcl()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerGetAcl$Response(params: AttributeControllerGetAcl$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeAclResponseDto>> {
    return attributeControllerGetAcl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerGetAcl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerGetAcl(params: AttributeControllerGetAcl$Params, context?: HttpContext): Observable<AttributeAclResponseDto> {
    return this.attributeControllerGetAcl$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeAclResponseDto>): AttributeAclResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerGet()` */
  static readonly AttributeControllerGetPath = '/api/tenant/attribute/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerGet$Response(params: AttributeControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeResponseDto>> {
    return attributeControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerGet(params: AttributeControllerGet$Params, context?: HttpContext): Observable<AttributeResponseDto> {
    return this.attributeControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeResponseDto>): AttributeResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerDelete()` */
  static readonly AttributeControllerDeletePath = '/api/tenant/attribute/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerDelete$Response(params: AttributeControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeResponseDto>> {
    return attributeControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerDelete(params: AttributeControllerDelete$Params, context?: HttpContext): Observable<AttributeResponseDto> {
    return this.attributeControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeResponseDto>): AttributeResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerList()` */
  static readonly AttributeControllerListPath = '/api/tenant/attribute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerList$Response(params?: AttributeControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeListResponseDto>> {
    return attributeControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attributeControllerList(params?: AttributeControllerList$Params, context?: HttpContext): Observable<AttributeListResponseDto> {
    return this.attributeControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeListResponseDto>): AttributeListResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerUpdate()` */
  static readonly AttributeControllerUpdatePath = '/api/tenant/attribute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerUpdate$Response(params: AttributeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeResponseDto>> {
    return attributeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerUpdate(params: AttributeControllerUpdate$Params, context?: HttpContext): Observable<AttributeResponseDto> {
    return this.attributeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeResponseDto>): AttributeResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerCreate()` */
  static readonly AttributeControllerCreatePath = '/api/tenant/attribute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerCreate$Response(params: AttributeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeResponseDto>> {
    return attributeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerCreate(params: AttributeControllerCreate$Params, context?: HttpContext): Observable<AttributeResponseDto> {
    return this.attributeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeResponseDto>): AttributeResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerAclInsert()` */
  static readonly AttributeControllerAclInsertPath = '/api/tenant/attribute/acl/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerAclInsert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerAclInsert$Response(params: AttributeControllerAclInsert$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeAclResponseDto>> {
    return attributeControllerAclInsert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerAclInsert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerAclInsert(params: AttributeControllerAclInsert$Params, context?: HttpContext): Observable<AttributeAclResponseDto> {
    return this.attributeControllerAclInsert$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeAclResponseDto>): AttributeAclResponseDto => r.body)
    );
  }

  /** Path part for operation `attributeControllerAclRemove()` */
  static readonly AttributeControllerAclRemovePath = '/api/tenant/attribute/acl/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attributeControllerAclRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerAclRemove$Response(params: AttributeControllerAclRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<AttributeAclResponseDto>> {
    return attributeControllerAclRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attributeControllerAclRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attributeControllerAclRemove(params: AttributeControllerAclRemove$Params, context?: HttpContext): Observable<AttributeAclResponseDto> {
    return this.attributeControllerAclRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttributeAclResponseDto>): AttributeAclResponseDto => r.body)
    );
  }

}
