import { TenantSsoGoogleDataDto, TenantSsoOpenidDataDto } from '@api/models';

interface SsoServiceCreateDto {
  ssoService: SupportedSsoServices | null;
  data: Partial<TenantSsoGoogleDataDto> | Partial<TenantSsoOpenidDataDto>;
}

export enum SupportedSsoServices {
  google = 'GOOGLE',
  openid = 'OPENID',
}

export class TenantSsoModel {
  addNewMode = false;
  ssoServices: SupportedSsoServices[] = Object.values(SupportedSsoServices);
  newSsoService: SsoServiceCreateDto = {
    ssoService: null,
    data: {},
  };
}
