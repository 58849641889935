import { Injectable } from '@angular/core';
import { ArtifactAttributeResponseDto } from '@api/models';
import { NewDataType } from '@shared/types/data-type.types';

@Injectable({ providedIn: 'root' })
export class DataTypeHelper {
  getNextEnumValue(dataType: NewDataType, currentValue: ArtifactAttributeResponseDto, emptyAfterLast = false): ArtifactAttributeResponseDto | null {
    if (!dataType.isEnum || !dataType.values) {
      return null;
    }
    const values = dataType.values;
    const indexMatch = currentValue ? values?.findIndex(value => value.value === currentValue.value) : -1;
    if (indexMatch === values.length - 1 && emptyAfterLast) {
      return null;
    }
    const resultIndex = (indexMatch + 1) % values.length;
    return { value: values[resultIndex].value };
  }

  getPrevEnumValue(dataType: NewDataType, currentValue: ArtifactAttributeResponseDto, emptyAfterLast = false): ArtifactAttributeResponseDto | null {
    if (!dataType.isEnum || !dataType.values) {
      return null;
    }
    const values = dataType.values;
    const indexMatch = currentValue ? values?.findIndex(value => value.value === currentValue.value) : -1;
    if (indexMatch) {
      return { value: values[indexMatch - 1].value };
    }
    return emptyAfterLast ? null : { value: values[values.length - 1].value };
  }

  getNextBooleanValue(currentValue: boolean, emptyAfterLast = false): boolean | null {
    switch (currentValue) {
      case false:
        return true;
      case true:
        return emptyAfterLast ? null : false;
      default:
        return false;
    }
  }
}
