<div class="chunk" *ngIf="selected">
    <p-dropdown
            (onChange)="onChange()"
            [(ngModel)]="selected.matchType"
            [options]="options.matchType.list | transformArrayToSelectOptions"
    ></p-dropdown>
    <ng-container *ngFor="let rule of selected.ruleTypes; let i = index">
        <p-dropdown
                (onChange)="onChange()"
                [(ngModel)]="rule.ruleType"
                [options]="options.ruleType.list | transformArrayToSelectOptions"
        ></p-dropdown>
        <input *ngIf="rule.ruleType | isRegularTextFilterType" type="text" class="p-inputtext" pInputText placeholder="Search string" (blur)="onChange()" [(ngModel)]="rule.value">
        <p *ngIf="selected?.ruleTypes.length > 1" class="rule rule-rm" (click)="removeRule(i)">Remove rule</p>
    </ng-container>


</div>
<p *ngIf="selected?.ruleTypes?.length < 2" class="rule" (click)="addRule()">+ Add rule</p>
