<p-dropdown
  class="workflow-element-dropdown"
  styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
  placeholder="{{ 'WORKFLOWS.PLACEHOLDER.ATTRIBUTE' | translate }}"
  appendTo="body"
  optionValue="id"
  [filter]="true"
  filterBy="name"
  [options]="attributes"
  [ngModel]="selectedAttributeId"
  [readonly]="!editable"
  (onChange)="onAttributeChange($event.value)"
>
  <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
  <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
</p-dropdown>
