import { Component, Input } from '@angular/core';
import { DeleteArtifactsKeys, WorkflowActionDeleteArtifacts } from '@workflows/types/actions/action-delete-artifacts';

@Component({
  selector: 'app-action-delete-artifacts',
  templateUrl: './action-delete-artifacts.component.html',
  styleUrls: ['./action-delete-artifacts.component.scss'],
})
export class ActionDeleteArtifactsComponent {
  @Input() action: WorkflowActionDeleteArtifacts;

  protected readonly RuleKeys = DeleteArtifactsKeys;

  onIsDynamicIdChange(isDynamic: boolean): void {
    this.action.actionSettings.ids.value = isDynamic ? ('' as any) : [''];
  }

  onAddId(index: number): void {
    (this.action.actionSettings[DeleteArtifactsKeys.ids].value as string[]).splice(index + 1, 0, '');
  }

  onRemoveId(index: number): void {
    (this.action.actionSettings[DeleteArtifactsKeys.ids].value as string[]).splice(index, 1);
  }

  idsToTrackByFn(index: number): number {
    return index;
  }
}
