import { ArtifactResponseDto } from '@api/models';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewClientAttribute } from '@shared/types/attribute.types';

export class FileUploadDetails {
  constructor(public total: number = 0, public loaded: number = 0, public progress: number = 0) {}
}

export class FileUploadParams {
  constructor(public folderId: string, public file: File) {}
}

export class NewFileParams {
  constructor(
    public file: any,
    public attr: NewClientAttribute | NewArtifactTypeClientAttribute,
    public folderId: string,
    public index: number,
    public multipleValues: boolean,
    public onFileCreateCb?: (fileArtifact: ArtifactResponseDto) => void,
    public saveValueAsId?: boolean,
  ) {}
}

export enum FilePathTypeEnum {
  upload = 'UPLOAD',
  download = 'DOWNLOAD',
  get = 'GET',
  list = 'LIST',
  setup = 'SETUP',
  export = 'EXPORT',
}

export enum ImageExtensions {
  'jpg',
  'jpeg',
  'gif',
  'png',
  'svg',
  'webp',
  'ico',
}
