<div class="col-12">
  <h4 class="mb-0">Default widgets</h4>
  <div class="row form-header-row">
    <div class="col-5 form-header-column">
      <h6>Widget type</h6>
    </div>
    <div class="col-5 form-header-column">
      <h6>Selected template</h6>
    </div>
    <div class="col-2 form-header-column">
      <h6>Actions</h6>
    </div>
  </div>

  <div class="row form-row">
    <div class="col-5 form-column">
      Card widget template
    </div>
    <div class="col-5 form-column">
      <button *ngIf="!defaultWidgets.cardWidgetTemplate"
              class="p-button-info p-button-rounded p-button-outlined" icon="pi pi-plus" pButton type="button"
              [pTooltip]="'Set card template' | translate"
              (click)="setTemplate(WidgetType.card)">
      </button>
      <span *ngIf="defaultWidgets.cardWidgetTemplate" class="template-name">{{ defaultWidgets.cardWidgetTemplate.name }}</span>
    </div>
    <div class="col-2 form-column">
      <button [disabled]="!defaultWidgets.cardWidgetTemplate"
              class="p-button-warning p-button-rounded" icon="pi pi-pencil" pButton type="button" (click)="setTemplate(WidgetType.card)"></button>
    </div>
  </div>

  <div class="row form-row">
    <div class="col-5 form-column">
      Artifact widget template
    </div>
    <div class="col-5 form-column">
      <button *ngIf="!defaultWidgets.artifactWidgetTemplate"
              class="p-button-info p-button-rounded p-button-outlined" icon="pi pi-plus" pButton type="button"
              [pTooltip]="'Set artifact template' | translate"
              (click)="setTemplate(WidgetType.artifact)">
      </button>
      <span *ngIf="defaultWidgets.artifactWidgetTemplate" class="template-name">{{ defaultWidgets.artifactWidgetTemplate.name }}</span>
    </div>
    <div class="col-2 form-column">
      <button [disabled]="!defaultWidgets.artifactWidgetTemplate"
              class="p-button-warning p-button-rounded" icon="pi pi-pencil" pButton type="button" (click)="setTemplate(WidgetType.artifact)"></button>
    </div>
  </div>

</div>
