import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SortAttributeObject, SortTypeEnum, SortTypeValueEnum, SortUrlChangeEvent } from '@shared/types/sort.types';
import { SortMeta } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SortMainControlService {
  public static readonly SORT_URL_ATTRIBUTE_NAME = 'sort';
  public static readonly SORT_URL_TYPE_SEPARATOR = '!';
  public static readonly SORT_URL_ENTRY_SEPARATOR = ',';

  private currentUrlChangeEventMap: Map<string, SortUrlChangeEvent> = new Map();
  private sortUpdateSubject: Subject<SortUrlChangeEvent> = new Subject();
  private sortUpdate$: Observable<SortUrlChangeEvent> = this.sortUpdateSubject.asObservable();
  /** subject related to resetting internal sorts, e.g. previously activated sorts */
  private resetInternalSortsSubject: Subject<string> = new Subject();
  private resetInternalSorts$: Observable<string> = this.resetInternalSortsSubject.asObservable();

  getSortUpdate$(): Observable<SortUrlChangeEvent> {
    return this.sortUpdate$;
  }

  getResetInternalSorts$(): Observable<string> {
    return this.resetInternalSorts$;
  }

  doNotifyResetInternalSorts(ownerId: string) {
    this.resetInternalSortsSubject.next(ownerId);
  }

  doNotifySortUpdateChange(ownerId: string, payload: SortAttributeObject | null, storeCurrentUrlEvent?: boolean) {
    const sortChangeEvent: SortUrlChangeEvent = { ownerId, payload };
    this.sortUpdateSubject.next(sortChangeEvent);

    if (storeCurrentUrlEvent) {
      this.currentUrlChangeEventMap.set(ownerId, sortChangeEvent);
    }
  }

  getQueryFromUrlString(rawSortParamValue: string): Record<string, any> | null {
    if (!rawSortParamValue) {
      return null;
    }
    const sortAttributeObject = this.decodeFromUrl(rawSortParamValue) as SortAttributeObject;
    return this.getQueryFromSortAttribute(sortAttributeObject);
  }

  getQueryFromSortAttribute(sortAttributeObject: SortAttributeObject): Record<string, any> | null {
    sortAttributeObject;
    return {};
  }

  getSortAttributeObject(queryParams: Params): SortAttributeObject | null {
    if (!queryParams || !Object.prototype.hasOwnProperty.call(queryParams, SortMainControlService.SORT_URL_ATTRIBUTE_NAME)) {
      return null;
    }
    const rawSortParamValue = queryParams[SortMainControlService.SORT_URL_ATTRIBUTE_NAME];
    return this.decodeFromUrl(rawSortParamValue);
  }

  decodeFromUrl(rawSortParamValue: string): SortAttributeObject | null {
    if (!rawSortParamValue.includes(SortMainControlService.SORT_URL_TYPE_SEPARATOR)) {
      return { attributeName: rawSortParamValue, sortType: SortTypeEnum.ASC };
    }
    const [attributeName, sortType] = rawSortParamValue.split(SortMainControlService.SORT_URL_TYPE_SEPARATOR);
    return { attributeName, sortType };
  }

  /**
   * Returns sort url entry addon for given attribute name and sort type.
   * @param attributeName attribute name
   * @param sortType sort type
   * @returns sort url entry addon
   */
  encodeSingleAttrIntoUrl(attributeName: string, sortType: string = SortTypeEnum.ASC): string {
    return attributeName + SortMainControlService.SORT_URL_TYPE_SEPARATOR + sortType;
  }

  getRawSortParamValue(queryParams: Params): string {
    return queryParams[SortMainControlService.SORT_URL_ATTRIBUTE_NAME];
  }

  getSortMeta(attributeId: string, sortType: SortTypeEnum): SortMeta {
    return { field: attributeId, order: this.getSortTypeValue(sortType) };
  }

  getSortTypeValue(sortType: SortTypeEnum): number {
    return sortType === SortTypeEnum.ASC ? SortTypeValueEnum.ASC : SortTypeValueEnum.DESC;
  }

  hasActiveUrlChangeEventForOwner(ownerId: string): boolean {
    return this.currentUrlChangeEventMap.has(ownerId);
  }

  hasActiveUrlChangeEventForOwnerAndAttribute(ownerId: string, rawAttributeName: string): boolean {
    return this.currentUrlChangeEventMap.get(ownerId)?.payload?.attributeName === rawAttributeName;
  }

  clearCurrentUrlChangeEvent(ownerId: string): void {
    this.currentUrlChangeEventMap.delete(ownerId);
  }
}
