import { Injectable } from '@angular/core';
import { SystemTenantService } from '@api/services/system-tenant.service';
import {
  DELETED_LABEL,
  DELETED_KEY,
  EMAIL_LABEL,
  EMAIL_KEY,
  IS_SYSTEM_ADMIN_LABEL,
  IS_SYSTEM_ADMIN_KEY,
  IS_TENANT_ADMIN_LABEL,
  IS_TENANT_ADMIN_KEY,
  TENANT_LABEL,
  TENANT_KEY,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { lastValueFrom } from 'rxjs';
import { SystemUserListModel } from '../types/system-user-list.types';

@Injectable()
export class SystemUserListService extends CoreService<any, SystemUserListModel> {
  constructor(private readonly systemTenantService: SystemTenantService) {
    super();
  }

  async init(context: any, model: SystemUserListModel): Promise<void> {
    await super.init(context, model);
    this.m.columns = [
      new TableColumn(EMAIL_LABEL, EMAIL_KEY),
      new TableColumn(TENANT_LABEL, TENANT_KEY),
      new TableColumn(IS_SYSTEM_ADMIN_LABEL, IS_SYSTEM_ADMIN_KEY),
      new TableColumn(IS_TENANT_ADMIN_LABEL, IS_TENANT_ADMIN_KEY),
      new TableColumn(DELETED_LABEL, DELETED_KEY),
    ];
    await lastValueFrom(this.systemTenantService.systemTenantControllerList()).then(res => this.m.tenants.setList(res.data, 'id'));
  }
}
