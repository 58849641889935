import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';

@Pipe({ name: 'getLinkAttributeId' })
export class GetLinkAttributeIdPipe implements PipeTransform {
  transform(linkType: LinkType, linkDirection: LinkDirection): string {
    return linkType.id + '_' + linkDirection;
  }
}
