import { CommonFeTrigger, RuleTriggerType, WorkflowTrigger, WorkflowTriggerWidgetLoad } from '@workflows/types';
import { AbstractWorkflowTrigger } from '@workflows/types/triggers/abstract/abstract-workflow-trigger';

export class WorkflowTriggerWidgetDataLoad extends AbstractWorkflowTrigger<CommonFeTrigger> {
  widgetId: string;

  constructor(widgetId?: string) {
    super(CommonFeTrigger);
    this.widgetId = widgetId || this.widgetId;
    this.type = RuleTriggerType.WIDGET_LOAD;
    this.widgetRequired = true;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    if (this.type === workflowTrigger.type) {
      const trigger = workflowTrigger as WorkflowTriggerWidgetLoad;
      return this.widgetId === trigger.widgetId;
    }
    return false;
  }

  isValid(): boolean {
    return !!this.widgetId;
  }
}
