import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { StickinessPosition } from '@private/pages/page-management/page-builder-graphical/types/stickiness-position';
import { StickyPageElementService } from '@shared/components/page/services/sticky-page-element.service';
import { StickyPositioningSettings } from '@shared/types/sticky-positioning-settings';

@Directive()
export abstract class StickyElement implements OnInit, OnDestroy {
  @Input() stickyPositioningSettings: StickyPositioningSettings;

  private resizeObserver: any;

  protected constructor(
    public readonly elRef: ElementRef<HTMLElement>,
    protected readonly renderer: Renderer2,
    protected readonly stickySectionService: StickyPageElementService,
  ) {}

  @HostBinding('class.sticky')
  get isSticky(): boolean {
    return this.stickyPositioningSettings?.isSticky || false;
  }

  @HostBinding('class.sticky--top')
  get isStickyToTop(): boolean {
    return this.isSticky && this.stickyPositioningSettings?.position === StickinessPosition.top;
  }

  @HostBinding('class.sticky--bottom')
  get isStickyToBottom(): boolean {
    return this.isSticky && this.stickyPositioningSettings?.position === StickinessPosition.bottom;
  }

  get offsetHeight(): number {
    return this.elRef.nativeElement.offsetHeight;
  }

  set positionTop(offset: number) {
    this.renderer.setStyle(this.elRef.nativeElement, 'top', offset + 'px');
  }

  set positionBottom(offset: number) {
    this.renderer.setStyle(this.elRef.nativeElement, 'bottom', offset + 'px');
  }

  ngOnInit(): void {
    if (!this.isSticky) {
      return;
    }

    this.renderer.setStyle(this.elRef.nativeElement, 'position', 'sticky');
    this.resizeObserver = new (window as any).ResizeObserver(() => this.stickySectionService.onResize());
    this.resizeObserver.observe(this.elRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.elRef.nativeElement);
  }
}
