import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppFooterComponent } from '@private/../shared/components/footer/app.footer.component';
import { AppRightmenuComponent } from '@private/../shared/components/right-menu/app.rightmenu.component';
import { AppSearchComponent } from '@private/../shared/components/search/app.search.component';
import { SidebarMenuModule } from '@private/../shared/components/sidebar-menu/sidebar-menu.component';
import { TenantManagementModule } from '@private/../system/pages/tenant-management/tenant-management.module';
import { AdminMainComponent } from '@private/admin-main.component';
import { ApplicationManagementModule } from '@private/pages/application-management/application-management.module';
import { ArtifactManagementModule } from '@private/pages/artifact-management/artifact-management.module';
import { ArtifactTypeManagementModule } from '@private/pages/artifact-type-management/artifact-type-management.module';
import { CurrentTenantModule } from '@private/pages/current-tenant/current-tenant.module';
import { DashboardModule } from '@private/pages/dashboard/dashboard.module';
import { JobsManagementModule } from '@private/pages/jobs-management/jobs-management.module';
import { PageManagementModule } from '@private/pages/page-management/page-management.module';
import { ProfileModule } from '@private/pages/profile/profile.module';
import { TeamManagementModule } from '@private/pages/team-management/team-management.module';
import { TemplateManagementModule } from '@private/pages/template-management/template-management.module';
import { UserManagementModule } from '@private/pages/user-management/user-management.module';
import { WorkflowManagementModule } from '@private/pages/workflow-management/workflow-management.module';
import { PublicModule } from '@public/public.module';
import { AdminTopBarModule } from '@shared/components/admin-topbar/admin-top-bar.component';
import { PageModule } from '@shared/components/page/page.module';
import { SidebarMenuItemModule } from '@shared/components/sidebar-menu/components/sidebar-menu-item.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';

const declarations = [AdminMainComponent, AppRightmenuComponent, AppFooterComponent, AppSearchComponent];

const modules = [
  ApplicationManagementModule,
  ArtifactManagementModule,
  ArtifactTypeManagementModule,
  DashboardModule,
  PageManagementModule,
  ProfileModule,
  TenantManagementModule,
  UserManagementModule,
  TeamManagementModule,
  PageModule,
  TemplateManagementModule,
  CurrentTenantModule,
  JobsManagementModule,
  WorkflowManagementModule,
];

@NgModule({
  declarations: [...declarations],
  imports: [
    PublicModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    RippleModule,
    AdminTopBarModule,
    SidebarMenuItemModule,
    SidebarMenuModule,
    ElvisSharedModule,
    ...modules,
  ],
  exports: [...declarations, ...modules],
})
export class MainModule {}
