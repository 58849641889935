import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { DisplayAtEnumModule } from '@shared/components/common-display-at';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { TagWidgetSettingsComponent } from './components/tag-widget-settings/tag-widget-settings.component';
import { TagWidgetComponent } from './tag-widget.component';

@NgModule({
  declarations: [TagWidgetComponent, TagWidgetSettingsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ElvisSharedModule,
    FormsModule,
    InputTextModule,
    TagModule,
    DisplayAtEnumModule,
    TranslateModule,
    ButtonModule,
    CheckboxModule,
    ArtifactFiltersModule,
  ],
  exports: [TagWidgetComponent],
})
export class TagWidgetModule {}
