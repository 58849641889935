import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ArtifactAttributeResponseDto, ArtifactResponseDto, DataTypeValueResponseDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DisplayAtControlService } from '@shared/components/common-display-at';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATEnumEditVariant, DATEnumLayoutVariant } from '@shared/types/display-at-types';
import { TableColumnFormat } from '@shared/types/table.types';
import { FilterWidgetSelectTag } from '@widgets/filter-widget/types/filter-widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { Subscription } from 'rxjs';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';

@Component({
  selector: 'app-display-at-enum',
  templateUrl: './display-at-enum.component.html',
  styleUrls: ['display-at-enum.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtEnumComponent extends AbstractDisplayAtComponent implements OnInit, OnChanges {
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() tooltipEnabled: boolean;
  @Input() enumOptions: DataTypeValueResponseDto[];
  @Input() isPointer = false;
  @Input() isShowActive: boolean;
  @Input() activeIndexes: number[];
  @Input() artifactDto: ArtifactResponseDto;
  @Input() placeholder: string;
  @Input() contentColumnFormat: TableColumnFormat;

  @Output() onSelect = new EventEmitter<FilterWidgetSelectTag>();

  isMulti: boolean;
  dropDownModel: any[] = [];
  layoutVariant: Record<keyof typeof DATEnumLayoutVariant, keyof typeof DATEnumLayoutVariant> = Object.keys(DATEnumLayoutVariant).reduce((res, key: any) => {
    res[key as keyof typeof DATEnumLayoutVariant] = key;
    return res;
  }, {} as Record<keyof typeof DATEnumLayoutVariant, keyof typeof DATEnumLayoutVariant>);
  private eventsSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
    this.init();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.artifact && this.attribute) {
      this.isMulti = Boolean(this.attribute.multipleValues);

      if (this.artifact.attributes[this.attributeId] && this.dataType.kind === DataTypeKind.enumerated) {
        this.setEnumeratedEnumOptions();
      } else if (
        this.artifact.attributes[this.attributeId] &&
        this.dataType.kind === DataTypeKind.simple &&
        this.dataType.baseDataType === BaseDataType.boolean
      ) {
        this.enumOptions = this.dataType.values!.filter(v => v.value === (this.artifact.attributes[this.attributeId].value?.toString() || ''));
      } else {
        this.enumOptions = [];
      }
    }

    if (this.selectedVariant === 'DROPDOWN') {
      this.dropDownModel = [];
      this.activeIndexes?.forEach(index => {
        this.dropDownModel.push(this.enumOptions[index].value);
      });
    }
  }

  setEnumeratedEnumOptions(): void {
    const value = this.artifact.attributes[this.attributeId].value;

    if (this.isMulti) {
      this.enumOptions = value && typeof value[0] === 'string' ? value.map((code: string) => this.dataType.values?.find(v => v.value === code)) : value;
    } else {
      this.enumOptions = typeof value === 'string' ? this.dataType.values?.filter(v => v.value === value) || [] : [value as DataTypeValueResponseDto];
    }
  }

  onDropdownChange(): void {
    const currentIndexes: number[] = [];
    const enumMap = this.enumOptions.map(en => en.value);
    this.dropDownModel.forEach(s => {
      currentIndexes.push(enumMap.indexOf(s));
    });

    this.onSelect.emit({ currentIndexes } as FilterWidgetSelectTag);
  }

  selectItem(index: number, e?: any): void {
    const isCtrl = e.ctrlKey || e.metaKey;
    this.onSelect.emit({ index, isCtrl });
    const enumKey = this.selectedEditVariant as keyof typeof DATEnumEditVariant;

    if (!this.isMulti && DATEnumEditVariant[enumKey] === DATEnumEditVariant.CLICK_TO_SELECT_NEXT) {
      e.stopPropagation();
      const currentValue = this.artifact.attributes[this.attributeId].value as ArtifactAttributeResponseDto;
      const nextValue = isCtrl
        ? this.dataTypeHelper.getPrevEnumValue(this.dataType, currentValue)
        : this.dataTypeHelper.getNextEnumValue(this.dataType, currentValue);
      this.displayAtControlService.doUpdateValueSelection(this.ownerId, this.artifact, this.attributeId, nextValue);

      if (this.doInternalUpdateOfValue) {
        this.enumOptions = [nextValue as DataTypeValueResponseDto];
        this.cdr.markForCheck();
      }
    }
  }

  isActive(index: number): boolean {
    return this.isShowActive && this.activeIndexes.includes(index);
  }

  isValueClickable(): boolean {
    return this.isPointer || super.isValueClickable();
  }

  private init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === NonAttributeKeys.ARTIFACT_TYPE_ID && this.cdr.markForCheck();
      }
    });
  }
}
