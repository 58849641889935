import { Pipe, PipeTransform } from '@angular/core';
import { NumberFilterRuleType } from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter-options.types';

@Pipe({
  name: 'isRegularNumberFilterType',
})
export class IsRegularNumberFilterTypePipe implements PipeTransform {
  transform(filterType: NumberFilterRuleType): boolean {
    const { between, isEmpty, isNotEmpty } = NumberFilterRuleType;
    return filterType !== between && filterType !== isNotEmpty && filterType !== isEmpty;
  }
}
