<div *ngIf="s?.applications.list.length">
  <p-dropdown inputId="application"
              [options]="s.applications.list | transformArrayToSelectOptions : NAME_VALUE"
              [autoDisplayFirst]="false"
              [style]="{'width':'100%'}"
              appendTo="body"
              [(ngModel)]="s.selectedApplication"
              (ngModelChange)="s.changeSelectedApplication($event)"
              [filter]="true">
  </p-dropdown>
</div>
