import {
  PICTURE_FORMATS,
  PICTURE_POSITIONS,
  PICTURE_REPEAT,
  PICTURE_SIZES,
  PictureFormatOption,
  PicturePositionOption,
  PictureRepeatOption,
  PictureSizeOption,
} from './picture-widget.types';
import { SelectOption } from '@shared/types/shared.types';
import { SidebarListItem } from '../../sidebar-widget/types/sidebar-widget.types';

export class PictureWidgetOptions {
  pictureFormatOptions: PictureFormatOption[] = PICTURE_FORMATS;
  pictureSizeOptions: PictureSizeOption[] = PICTURE_SIZES;
  pictureRepeatOptions: PictureRepeatOption[] = PICTURE_REPEAT;
  picturePositionOptions: PicturePositionOption[] = PICTURE_POSITIONS;
  pageOptions: SelectOption<string, string>[];
  sidebarList: SidebarListItem[];
}
