import { Pipe, PipeTransform } from '@angular/core';
import { AttributeLabelFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';

@Pipe({ name: 'getAttributeLabel' })
export class GetAttributeLabelPipe implements PipeTransform {
  transform(customLabel: string, label: string, labelFormatSettings: AttributeLabelFormatSettings): string {
    if (!labelFormatSettings.visible) return '';
    else if (!customLabel) return label;
    else return customLabel;
  }
}
