<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'Scheme' | translate }}
  </div>
  <div class="col-8">
    <div class="w-100">
      <p-dropdown [(ngModel)]="action.actionSettings.scheme.value" [options]="requestSchemes" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Url' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.url.isDynamic" id="isDynamicUrl" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.url.value"
      [placeholder]="action.actionSettings.url.isDynamic ? 'Enter dynamic url' : ('Enter url' | translate)"
      pInputText
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Method' | translate }}
  </div>
  <div class="col-2"></div>
  <div class="col-8">
    <p-dropdown [(ngModel)]="action.actionSettings.method.value" [options]="requestMethods" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Query params' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.queryParams.isDynamic" id="isQueryParamsDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let param of action.queryParams; let index = index">
      <div class="record-row">
        <div>
          <input [id]="'queryParamId' + index" [(ngModel)]="param.id" pInputText type="text" class="w-100" [placeholder]="'Enter id ' + (index + 1)" />
        </div>
        <div>
          <input [(ngModel)]="param.value" [placeholder]="'Enter value' | translate" pInputText class="w-100" />
        </div>
        <div>
          <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveQueryParam(index)"></button>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddQueryParam()" label="Add attribute"></button>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Additional headers' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.additionalHeaders.isDynamic" id="isAdditionalHeadersDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let header of action.additionalHeaders; let index = index">
      <div class="record-row">
        <div>
          <input [id]="'headerId' + index" [(ngModel)]="header.id" pInputText type="text" class="w-100" [placeholder]="'Enter id ' + (index + 1)" />
        </div>
        <div>
          <input [(ngModel)]="header.value" [placeholder]="'Enter value' | translate" pInputText class="w-100" />
        </div>
        <div>
          <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveHeader(index)"></button>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddHeader()" label="Add header"></button>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Body request type' | translate }}
  </div>
  <div class="col-8">
    <p-dropdown [(ngModel)]="action.bodyRequestType" [options]="requestTypes" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
  </div>
</div>

<div *ngIf="action.bodyRequestType !== RequestTypes.none" class="row">
  <div class="col-2 text-bold">
    {{ 'Body' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.body.isDynamic" id="isBodyDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <ng-container *ngIf="action.bodyRequestType === RequestTypes.txt; else bodyAsObject">
      <textarea
        [(ngModel)]="action.actionSettings.body.value"
        [placeholder]="action.actionSettings.body.isDynamic ? 'Enter dynamic body' : ('Enter body' | translate)"
        rows="10"
        pInputTextarea
        class="w-100"
        id="codeInput"
      >
      </textarea>
    </ng-container>

    <ng-template #bodyAsObject>
      <div *ngFor="let bodyParam of action.body; let index = index">
        <div class="record-row">
          <div>
            <input [id]="'headerId' + index" [(ngModel)]="bodyParam.id" pInputText type="text" class="w-100" [placeholder]="'Enter id ' + (index + 1)" />
          </div>
          <div>
            <input [(ngModel)]="bodyParam.value" [placeholder]="'Enter value' | translate" pInputText class="w-100" />
          </div>
          <div>
            <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveBodyParam(index)"></button>
          </div>
        </div>
      </div>
      <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddBodyParam()" label="Add body parameter"></button>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Expected response type' | translate }}
  </div>
  <div class="col-2"></div>
  <div class="col-8">
    <p-dropdown
      [(ngModel)]="action.actionSettings.expectedBodyResponseType.value"
      [options]="responseTypes"
      [autoDisplayFirst]="false"
      appendTo="body"
    ></p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Expected response codes' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let code of action.actionSettings.expectedResponseCodes.value; let index = index; trackBy: responseCodesToTrackByFn" class="mb-1 d-flex">
      <input
        [(ngModel)]="action.actionSettings.expectedResponseCodes.value![index]"
        pInputText
        type="text"
        class="w-100"
        [placeholder]="'Enter code ' + (index + 1)"
      />
      <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddResponseCode(index)"></button>
      <button
        class="p-button p-button-text p-button-danger"
        pButton
        type="button"
        icon="pi pi-minus"
        (click)="onRemoveResponseCode(index)"
        [ngClass]="{ 'visibility-hidden': action.actionSettings[RuleKeys.expectedResponseCodes].value.length <= 1 }"
      ></button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.storeResultsPath.value"
      [placeholder]="'Enter store results path' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>
