<div class="grid" style="max-width: 100%">
  <div class="col-6">
    {{ 'Sidebar position' | translate }}
  </div>
  <div class="col-6" style="font-size: 16px">
    <p-dropdown
      (onChange)="changeVisibleStyles()"
      [options]="options.position"
      [(ngModel)]="settings.position"
    ></p-dropdown>
  </div>
  <div class="col-6">
    {{ 'Position' | translate }}
  </div>
  <div class="col-6" style="font-size: 16px">
    <p-dropdown
      (onChange)="changeVisibleStyles()"
      [options]="options.inside"
      [(ngModel)]="settings.isInside"
    ></p-dropdown>
  </div>
  <div class="col-6">
    {{ 'Behavior' | translate }}
  </div>
  <div class="col-6" style="font-size: 16px">
    <p-dropdown
      (onChange)="changeVisibleStyles()"
      [options]="options.fixed"
      [(ngModel)]="settings.isFixed"
    ></p-dropdown>
  </div>

  <p-accordion class="col-12">
    <p-accordionTab header="Sidebar Design">
      <div class="grid">
        <div class="col-6">
          {{ 'Background color' | translate }}
        </div>
        <div class="col-6" right>
          <app-color-picker [(color)]="settings.bgColor" (colorChange)="changeVisibleStyles()"></app-color-picker>
        </div>
        <div class="col-6">
          {{ 'Width' | translate }}
        </div>
        <div class="col-6" right>
          <input type="text" pInputText [(ngModel)]="settings.width" (blur)="changeVisibleStyles()"
                 style="width: 100%" />
        </div>
        <div class="col-6">
          {{ 'Border' | translate }}
        </div>
        <div class="col-2">
          <p-toggleButton (click)="changeVisibleStyles()" [(ngModel)]="settings.styles.isBorder" offIcon="pi pi-times"
                          onIcon="pi pi-check"></p-toggleButton>
        </div>
        <div class="col-4">
          <button (click)="sectionInnerBorderOverlayPanel.toggle($event)"
                  *ngIf="settings.styles.isBorder"
                  icon="bi bi-border-style"
                  label="{{ 'Borders' | translate }}"
                  pButton></button>
        </div>
        <ng-container *ngIf="!settings.isFixed">
          <div class="col-6">
            {{ 'Close sidebar by overlay click' | translate }}
          </div>
          <div class="col-6" right>
            <p-toggleButton [(ngModel)]="settings.closeEvents.outside" offIcon="pi pi-times"
                            onIcon="pi pi-check" [disabled]="true"></p-toggleButton>
          </div>
          <div class="col-6">
            {{ 'Show close button X' | translate }}
          </div>
          <div class="col-6" right>
            <p-toggleButton [(ngModel)]="settings.closeEvents.cancelX" offIcon="pi pi-times"
                            onIcon="pi pi-check"></p-toggleButton>
          </div>
        </ng-container>
      </div>
    </p-accordionTab>
    <p-accordionTab *ngIf="!settings.isFixed" header="Dynamic Sidebar Open/Close">
      <div class="grid">
        <div class="col-6" [ngClass]="settings.layoutBtn.display ? 'active': ''">
          {{ 'Button' | translate }}
        </div>
        <div class="col-5" [ngClass]="settings.layoutBtn.display ? 'active': ''" right>
          <p-checkbox binary="true" [(ngModel)]="settings.layoutBtn.display"
                      (ngModelChange)="settings.updateButtonContent(true)"></p-checkbox>
        </div>
        <ng-container *ngIf="settings.layoutBtn.display">
          <div class="col-1" [ngClass]="settings.layoutBtn.display ? 'active': ''"
               (click)="visibleButtonSettings = !visibleButtonSettings">
            <i class="pi" [ngClass]="visibleButtonSettings ? 'pi-angle-down': 'pi-angle-right'" pointer></i>
          </div>
          <!--todo refactor this after accept design-->
          <ng-container *ngIf="visibleButtonSettings">
            <div class="col-6">
              {{ 'Align' | translate }}
            </div>
            <div class="col-6" style="font-size: 16px">
              <p-dropdown [options]="options.buttonAlign" [(ngModel)]="settings.layoutBtn.align"></p-dropdown>
            </div>
            <!-- Common border styles -->
            <!--<div class="col-6">
              Border
            </div>
            <div class="col-6" right>
              <button (click)="layoutButtonBorderOverlayPanel.toggle($event)"
                      icon="bi bi-border-style"
                      label="{{ 'Borders' | translate }}"
                      pButton></button>
            </div>-->
            <div class="col-12">
              <app-sidebar-button-form
                label="Close - no hover"
                [buttonView]="settings.layoutBtn.buttonView.closeNoHover"
                [allViews]="settings.layoutBtn.buttonView"
                (updateAll)="settings.layoutBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.layoutBtn.buttonView.closeNoHover = $event; settings.updateButtonContent(true)"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Close - hover"
                [buttonView]="settings.layoutBtn.buttonView.closeHover"
                [allViews]="settings.layoutBtn.buttonView"
                (updateAll)="settings.layoutBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.layoutBtn.buttonView.closeHover = $event; settings.updateButtonContent(true)"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Open - no hover"
                [buttonView]="settings.layoutBtn.buttonView.openNoHover"
                [allViews]="settings.layoutBtn.buttonView"
                (updateAll)="settings.layoutBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.layoutBtn.buttonView.openNoHover = $event; settings.updateButtonContent(true)"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Open - hover"
                [buttonView]="settings.layoutBtn.buttonView.openHover"
                [allViews]="settings.layoutBtn.buttonView"
                (updateAll)="settings.layoutBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.layoutBtn.buttonView.openHover = $event; settings.updateButtonContent(true)"
              ></app-sidebar-button-form>
            </div>
          </ng-container>
        </ng-container>

        <div class="col-6" [ngClass]="settings.arrowBtn.display ? 'active': ''">
          Sidebar Button
        </div>
        <div class="col-5" [ngClass]="settings.arrowBtn.display ? 'active': ''" right>
          <p-checkbox binary="true" [(ngModel)]="settings.arrowBtn.display"
                      (ngModelChange)="settings.updateButtonContent()"></p-checkbox>
        </div>
        <ng-container *ngIf="settings.arrowBtn.display">
          <div class="col-1" [ngClass]="settings.arrowBtn.display ? 'active': ''"
               (click)="visibleSidebarButtonSettings = !visibleSidebarButtonSettings">
            <i class="pi" [ngClass]="visibleSidebarButtonSettings ? 'pi-angle-down': 'pi-angle-right'" pointer></i>
          </div>
          <ng-container *ngIf="visibleSidebarButtonSettings">
            <!-- Common border styles -->
            <!--<div class="col-6">
              Border
            </div>
            <div class="col-6" right>
              <button (click)="arrowButtonBorderOverlayPanel.toggle($event)"
                      icon="bi bi-border-style"
                      label="{{ 'Borders' | translate }}"
                      pButton></button>
            </div>-->
            <div class="col-12">
              <app-sidebar-button-form
                label="Close - no hover"
                [buttonView]="settings.arrowBtn.buttonView.closeNoHover"
                [allViews]="settings.arrowBtn.buttonView"
                (updateAll)="settings.arrowBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.arrowBtn.buttonView.closeNoHover = $event; settings.updateButtonContent()"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Close - hover"
                [buttonView]="settings.arrowBtn.buttonView.closeHover"
                [allViews]="settings.arrowBtn.buttonView"
                (updateAll)="settings.arrowBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.arrowBtn.buttonView.closeHover = $event; settings.updateButtonContent()"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Open - no hover"
                [buttonView]="settings.arrowBtn.buttonView.openNoHover"
                [allViews]="settings.arrowBtn.buttonView"
                (updateAll)="settings.arrowBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.arrowBtn.buttonView.openNoHover = $event; settings.updateButtonContent()"
              ></app-sidebar-button-form>
            </div>
            <div class="col-12">
              <app-sidebar-button-form
                label="Open - hover"
                [buttonView]="settings.arrowBtn.buttonView.openHover"
                [allViews]="settings.arrowBtn.buttonView"
                (updateAll)="settings.arrowBtn.buttonView = $event; settings.updateButtonContent()"
                (onChange)="settings.arrowBtn.buttonView.openHover = $event; settings.updateButtonContent()"
              ></app-sidebar-button-form>
            </div>

            <ng-container *ngIf="!settings.isFixed">
              <div class="col-6">
                Full height button
              </div>
              <div class="col-6">
                <p-toggleButton (click)="changeVisibleStyles()" [(ngModel)]="settings.arrowBtn.isFullLength"
                                offIcon="pi pi-times" onIcon="pi pi-check"></p-toggleButton>
              </div>

              <ng-container *ngIf="!settings.arrowBtn.isFullLength">
                <div class="col-6">
                  Button position
                </div>
                <div class="col-6">
                  <p-inputNumber (onBlur)="changeVisibleStyles()" [(ngModel)]="settings.arrowBtn.padding"
                                 mode="decimal" styleClass="inputNumber"></p-inputNumber>
                  <p-dropdown (onChange)="changeVisibleStyles()" [(ngModel)]="settings.arrowBtn.type"
                              [options]="options.widthTypes"></p-dropdown>
                </div>
                <div class="col-12" style="margin-bottom: 26px">
                  <p-slider
                    (onChange)="changeVisibleStyles()"
                    [(ngModel)]="settings.arrowBtn.padding"
                    [min]="settings.arrowBtn.range.min"
                    [max]="settings.arrowBtn.range.max"></p-slider>
                </div>
              </ng-container>

              <ng-container *ngIf="settings.arrowBtn.isFullLength">
                <div class="col-6">
                  Button wide
                </div>
                <div class="col-6">
                  <input type="text" pInputText [(ngModel)]="settings.arrowBtn.buttonWide"
                         (change)="changeVisibleStyles()" style="width: 100%" />
                </div>
                <div class="col-6">
                  Rotate
                </div>
                <div class="col-6">
                  <p-toggleButton (click)="changeVisibleStyles()" [(ngModel)]="settings.arrowBtn.isRotate"
                                  offIcon="pi pi-times" onIcon="pi pi-check"></p-toggleButton>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <div class="col-6" [ngClass]="settings.eventOpen ? 'active': ''">
          Event
        </div>
        <div class="col-5" [ngClass]="settings.eventOpen ? 'active': ''" right>
          <p-checkbox binary="true" [(ngModel)]="settings.eventOpen"></p-checkbox>
        </div>

        <ng-container *ngIf="settings.eventOpen">
          <div class="col-1" [ngClass]="settings.eventOpen ? 'active': ''"
               (click)="visibleEventSettings = !visibleEventSettings">
            <i class="pi" [ngClass]="visibleEventSettings ? 'pi-angle-down': 'pi-angle-right'" pointer></i>
          </div>
          <ng-container *ngIf="visibleEventSettings">
            <div class="col-6">
              Sidebar name
            </div>
            <div class="col-6">
              <input type="text" pInputText [(ngModel)]="settings.sidebarName" style="width: 100%" />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<p-overlayPanel #sectionInnerBorderOverlayPanel [style]="{ 'max-width': '500px' }" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [allowShadowInput]="true"
                     [onlyShadow]="true"
                     (change)="changeVisibleStyles()"
                     [styles]="settings.styles"></app-border-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #layoutButtonBorderOverlayPanel [style]="{ 'max-width': '500px' }" appendTo="body">
  <ng-template pTemplate>
    <app-border-form (change)="changeVisibleStyles()"
                     [styles]="settings.layoutBtn.commonBorderStyles"></app-border-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #arrowButtonBorderOverlayPanel [style]="{ 'max-width': '500px' }" appendTo="body">
  <ng-template pTemplate>
    <app-border-form (change)="changeVisibleStyles()" [styles]="settings.arrowBtn.commonBorderStyles"></app-border-form>
  </ng-template>
</p-overlayPanel>
