<h5>{{'Content alignment' | translate}}</h5>
<div class="grid">
  <div *ngIf="showVertical" class="col-6" [class.col-12]="!showHorizontal">
    <h6>{{alignItemsName | translate}}</h6>
    <p-dropdown [(ngModel)]="styles.alignItems" [options]="options.alignItems" (onChange)="onValueChange()"></p-dropdown>
  </div>

  <div *ngIf="showHorizontal" class="col-6" [class.col-12]="!showVertical">
    <h6>{{justifyContentName | translate}}</h6>
    <p-dropdown [(ngModel)]="styles.justifyContent" [options]="options.justifyContent" (onChange)="onValueChange()"></p-dropdown>
  </div>
</div>
