import { Injectable } from '@angular/core';
import { WorkflowActionDto } from '@api/models';
import { CacheDataHolderService } from '@shared/cache/cache-data-holder.service';
import { AttributeActionHandlerService } from '@workflows/shared';
import {
  BaseWorkflowAction,
  WorkflowAction,
  WorkflowActionCalculateAttributeValues,
  WorkflowActionCopyLinkValueFromLink,
  WorkflowActionSetAttributeValueFromLink,
  WorkflowActionShowHideAttribute,
  WorkflowActionType,
  WorkflowActionUseCustomAttributeValues,
} from '@workflows/types';
import { WorkflowActionCopyAttributeValue } from '@workflows/types/actions/action-copy-attribute-value';
import { WorkflowActionCreateArtifacts } from '@workflows/types/actions/action-create-artifacts';
import { WorkflowActionDeleteArtifacts } from '@workflows/types/actions/action-delete-artifacts';
import { WorkflowActionExecCustomJavascript } from '@workflows/types/actions/action-exec-custom-javascript';
import { WorkflowActionFetchData } from '@workflows/types/actions/action-fetch-data';
import { WorkflowActionGetArtifacts } from '@workflows/types/actions/action-get-artifacts';
import { WorkflowActionResetAttributeValues } from '@workflows/types/actions/action-reset-attribute-values';
import { WorkflowActionScheduleWorkflow } from '@workflows/types/actions/action-schedule-workflow';
import { WorkflowActionSendEmail } from '@workflows/types/actions/action-send-email';
import { WorkflowActionSetAttributeValue } from '@workflows/types/actions/action-set-attribute-value';
import { WorkflowActionSetAttributeMandatory } from '@workflows/types/actions/action-set-field-mandatory';
import { WorkflowActionUpdateArtifacts } from '@workflows/types/actions/action-update-artifacts';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';

@Injectable({ providedIn: 'root' })
export class WorkflowActionFactory {
  constructor(private readonly actionAttributeHandler: AttributeActionHandlerService, private readonly dataHolder: CacheDataHolderService) {}

  getWorkflowAction(type: WorkflowActionType, dto?: WorkflowActionDto): WorkflowAction {
    switch (type) {
      case WorkflowActionType.SHOW:
      case WorkflowActionType.HIDE:
        return new WorkflowActionShowHideAttribute(type, dto, this.actionAttributeHandler);
      case WorkflowActionType.SET_MANDATORY:
      case WorkflowActionType.SET_OPTIONAL:
        return new WorkflowActionSetAttributeMandatory(type, dto, this.actionAttributeHandler);
      case WorkflowActionType.AGGREGATE:
      case WorkflowActionType.MULTIPLY:
      case WorkflowActionType.SUBTRACT:
      case WorkflowActionType.DIVIDE:
      case WorkflowActionType.CALCULATE:
        return new WorkflowActionCalculateAttributeValues(type, dto, this.actionAttributeHandler);
      case WorkflowActionType.SET_VALUE:
        return new WorkflowActionSetAttributeValue(dto, this.actionAttributeHandler, this.dataHolder);
      case WorkflowActionType.COPY_VALUE:
        return new WorkflowActionCopyAttributeValue(dto, this.actionAttributeHandler);
      case WorkflowActionType.SET_VALUE_FROM_LINK:
        return new WorkflowActionSetAttributeValueFromLink(dto, this.actionAttributeHandler);
      case WorkflowActionType.COPY_LINK_FROM_LINK:
        return new WorkflowActionCopyLinkValueFromLink(dto, this.actionAttributeHandler);
      case WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES:
        return new WorkflowActionUseCustomAttributeValues(dto, this.actionAttributeHandler);
      case WorkflowActionType.RESET_ATTRIBUTE_VALUES:
        return new WorkflowActionResetAttributeValues(dto, this.actionAttributeHandler);
      case WorkflowActionType.SEND_EMAIL:
        return new WorkflowActionSendEmail(dto);
      case WorkflowActionType.SCHEDULE_WORKFLOW:
        return new WorkflowActionScheduleWorkflow(dto);
      case WorkflowActionType.GET_ARTIFACTS:
        return new WorkflowActionGetArtifacts(dto);
      case WorkflowActionType.CREATE_ARTIFACTS:
        return new WorkflowActionCreateArtifacts(dto);
      case WorkflowActionType.UPDATE_ARTIFACTS:
        return new WorkflowActionUpdateArtifacts(dto);
      case WorkflowActionType.DELETE_ARTIFACTS:
        return new WorkflowActionDeleteArtifacts(dto);
      case WorkflowActionType.EXEC_CUSTOM_JAVASCRIPT:
        return new WorkflowActionExecCustomJavascript(dto);
      case WorkflowActionType.FETCH_DATA:
        return new WorkflowActionFetchData(dto);
      default:
        return this.getBaseWorkflowAction();
    }
  }

  getBaseWorkflowAction(): WorkflowAction {
    return new BaseWorkflowAction(CommonFeAction);
  }
}
