/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { oslcControllerGetSelectionDialog } from '../fn/tenant-oslc/oslc-controller-get-selection-dialog';
import { OslcControllerGetSelectionDialog$Params } from '../fn/tenant-oslc/oslc-controller-get-selection-dialog';
import { oslcControllerGetServiceProvider } from '../fn/tenant-oslc/oslc-controller-get-service-provider';
import { OslcControllerGetServiceProvider$Params } from '../fn/tenant-oslc/oslc-controller-get-service-provider';
import { oslcControllerUserAuth } from '../fn/tenant-oslc/oslc-controller-user-auth';
import { OslcControllerUserAuth$Params } from '../fn/tenant-oslc/oslc-controller-user-auth';
import { oslcPublicControllerAccessAuth } from '../fn/tenant-oslc/oslc-public-controller-access-auth';
import { OslcPublicControllerAccessAuth$Params } from '../fn/tenant-oslc/oslc-public-controller-access-auth';
import { OslcSelectionDialogResponseDto } from '../models/oslc-selection-dialog-response-dto';
import { OslcServiceProviderListResponseDto } from '../models/oslc-service-provider-list-response-dto';
import { OslcUserAuthResponseDto } from '../models/oslc-user-auth-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantOslcService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `oslcControllerUserAuth()` */
  static readonly OslcControllerUserAuthPath = '/api/tenant/oslc/oauth-auth-url';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oslcControllerUserAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerUserAuth$Response(params: OslcControllerUserAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<OslcUserAuthResponseDto>> {
    return oslcControllerUserAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oslcControllerUserAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerUserAuth(params: OslcControllerUserAuth$Params, context?: HttpContext): Observable<OslcUserAuthResponseDto> {
    return this.oslcControllerUserAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<OslcUserAuthResponseDto>): OslcUserAuthResponseDto => r.body)
    );
  }

  /** Path part for operation `oslcControllerGetServiceProvider()` */
  static readonly OslcControllerGetServiceProviderPath = '/api/tenant/oslc/service-provider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oslcControllerGetServiceProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerGetServiceProvider$Response(params: OslcControllerGetServiceProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<OslcServiceProviderListResponseDto>> {
    return oslcControllerGetServiceProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oslcControllerGetServiceProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerGetServiceProvider(params: OslcControllerGetServiceProvider$Params, context?: HttpContext): Observable<OslcServiceProviderListResponseDto> {
    return this.oslcControllerGetServiceProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<OslcServiceProviderListResponseDto>): OslcServiceProviderListResponseDto => r.body)
    );
  }

  /** Path part for operation `oslcControllerGetSelectionDialog()` */
  static readonly OslcControllerGetSelectionDialogPath = '/api/tenant/oslc/selection-dialog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oslcControllerGetSelectionDialog()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerGetSelectionDialog$Response(params: OslcControllerGetSelectionDialog$Params, context?: HttpContext): Observable<StrictHttpResponse<OslcSelectionDialogResponseDto>> {
    return oslcControllerGetSelectionDialog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oslcControllerGetSelectionDialog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcControllerGetSelectionDialog(params: OslcControllerGetSelectionDialog$Params, context?: HttpContext): Observable<OslcSelectionDialogResponseDto> {
    return this.oslcControllerGetSelectionDialog$Response(params, context).pipe(
      map((r: StrictHttpResponse<OslcSelectionDialogResponseDto>): OslcSelectionDialogResponseDto => r.body)
    );
  }

  /** Path part for operation `oslcPublicControllerAccessAuth()` */
  static readonly OslcPublicControllerAccessAuthPath = '/api/tenant/oslc/oauth-callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oslcPublicControllerAccessAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcPublicControllerAccessAuth$Response(params: OslcPublicControllerAccessAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return oslcPublicControllerAccessAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oslcPublicControllerAccessAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  oslcPublicControllerAccessAuth(params: OslcPublicControllerAccessAuth$Params, context?: HttpContext): Observable<void> {
    return this.oslcPublicControllerAccessAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
