import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsResponseDto } from '@api/models';
import { SystemJobsService } from '@api/services/system-jobs.service';
import { TranslateService } from '@ngx-translate/core';
import { JobsTableComponent } from '@private/pages/jobs-management/jobs/components/jobs-table/jobs-table.component';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { lastValueFrom } from 'rxjs';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { JobsPageService } from './services/jobs-page.service';
import { JobsPageModel } from './types/jobs-page.model';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [JobsPageService],
})
export class JobsComponent extends CoreComponent<JobsPageService, JobsPageModel> {
  @ViewChild(JobsTableComponent) private table: JobsTableComponent;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: JobsPageService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly systemJobsService: SystemJobsService,
  ) {
    super(route, router, translate, new JobsPageModel(), service, announcement);
  }

  loadData() {
    return (meta?: Record<string, string>) => {
      const filterAnd = (JSON.parse(meta?.filter || '{}')?.$and || []) as any[];
      const resultFilterIndex = filterAnd.findIndex((filterField: any) => filterField.result);
      if (resultFilterIndex !== -1) {
        const resultFilter = filterAnd[resultFilterIndex].result;
        filterAnd[resultFilterIndex] = { $or: [{ result: resultFilter }, { error: resultFilter }] };
        meta!.filter = JSON.stringify({ $and: filterAnd });
      }

      return lastValueFrom(this.systemJobsService.jobsControllerList(meta));
    };
  }

  reloadTable() {
    this.table.table.filter(null, null as any, null as any);
  }

  async setDetailJob(job: JobsResponseDto | null): Promise<void> {
    this.m.detailJob = job;
  }

  async runAgainJob(job: JobsResponseDto): Promise<void> {
    try {
      await this.s.runAgain(job);
    } catch (e) {
      console.log(e);
      await this.announcement.error(`Something went wrong during 'Run again' job action`);
    }
  }

  async deleteJob(job: JobsResponseDto): Promise<void> {
    try {
      await this.s.delete(job);
    } catch (e) {
      console.log(e);
      await this.announcement.error(`Something went wrong during 'Delete' job action`);
    }
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Jobs' }]);
  }
}
