import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherModule } from '@shared/components/language-switcher/language-switcher.component';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { Ripple } from 'primeng/ripple';
import { AppAccessDeniedComponent } from './pages/access-denied/app.access-denied.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { LoginComponent } from './pages/login/login.component';
import { AppNotfoundComponent } from './pages/not-found/app.notfound.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';

const declarations = [LoginComponent, AppNotfoundComponent, AppErrorComponent, AppAccessDeniedComponent, RecoverPasswordComponent];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    PasswordModule,
    InputTextModule,
    ButtonModule,
    LanguageSwitcherModule,
    Ripple,
  ],
  exports: [...declarations],
})
export class PublicModule {}
