import { AfterViewInit, Component, Input } from '@angular/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import {
  CreateArtifactsKeys,
  WorkflowActionCreateArtifacts,
  WorkflowCreateAttributeMap,
  WorkflowCreateAttributeMapValue,
} from '@workflows/types/actions/action-create-artifacts';
import { isArray } from 'lodash';

@Component({
  selector: 'app-action-create-artifacts',
  templateUrl: './action-create-artifacts.component.html',
  styleUrls: ['./action-create-artifacts.component.scss'],
})
export class ActionCreateArtifactsComponent implements AfterViewInit {
  @Input() action: WorkflowActionCreateArtifacts;
  @Input() artifactTypes: NewArtifactType[];
  @Input() attributes: ListContainer<NewAttribute>;

  protected readonly RuleKeys = CreateArtifactsKeys;
  useValues: boolean[] = [];

  ngAfterViewInit(): void {
    this.generateUseValues();
  }

  onIsArtifactTypeDynamicChange(): void {
    this.action.actionSettings[CreateArtifactsKeys.artifactTypeId].value = null as any;
  }

  onArtifactTypeChange(artifactTypeId: string): void {
    this.generateAttributesMap(artifactTypeId);
    this.setAllUseValuesToTrue();
  }

  onUseValueChange(key: string, value: WorkflowCreateAttributeMapValue, useValue: boolean): void {
    const attributesMap = this.action.actionSettings[CreateArtifactsKeys.attributesMap];
    if (!useValue) {
      (attributesMap.value as WorkflowCreateAttributeMap)[key] = isArray(value) ? [] : (null as any);
    } else {
      (attributesMap.value as WorkflowCreateAttributeMap)[key] = isArray(value) ? [''] : '';
    }
  }

  onAddAttributeValue(key: string): void {
    ((this.action.actionSettings[CreateArtifactsKeys.attributesMap].value as WorkflowCreateAttributeMap)[key] as string[]).push('');
  }

  onRemoveAttributeValue(key: string, index: number): void {
    ((this.action.actionSettings[CreateArtifactsKeys.attributesMap].value as WorkflowCreateAttributeMap)[key] as string[]).splice(index, 1);
  }

  attributesTrackByFn(index: number): number {
    return index;
  }

  attrValuesToTrackByFn(index: number): number {
    return index;
  }

  generateAttributesMap(artifactTypeId: string = this.action.actionSettings[CreateArtifactsKeys.artifactTypeId].value as string): void {
    const artifactType = this.artifactTypes.find(at => at.id === artifactTypeId);
    if (!artifactType) return;

    this.action.actionSettings[CreateArtifactsKeys.attributesMap].value = Object.values(artifactType.attributes).reduce((attributesMap, attr) => {
      return {
        ...attributesMap,
        [attr.id]: !this.action.actionSettings[CreateArtifactsKeys.attributesMap].isDynamic && this.attributes.listMap[attr.id].multipleValues ? [''] : '',
      };
    }, {});
  }

  generateUseValues(): void {
    this.useValues = Object.values(this.action.actionSettings[CreateArtifactsKeys.attributesMap].value || {}).map(() => true) || [];
  }

  setAllUseValuesToTrue(): void {
    this.useValues = Object.values(this.action.actionSettings[CreateArtifactsKeys.attributesMap].value || {}).map(() => true);
  }
}
