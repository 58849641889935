import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { StateKey } from '@shared/types/local-storage.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { lastValueFrom } from 'rxjs';
import { TOKEN_KEY } from '../constants/constants';
import { SharedMethods } from '../methods/shared.methods';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class ServerSideEventService {
  hash = SharedMethods.getUniqueId();
  runtimeKey = 'sseService';

  eventSource: EventSource | null;
  sseDataTypeEventHandlers: Record<SseDataTypeEnum, SseDataTypeEventHandlers>;

  constructor(
    public readonly apiConfiguration: ApiConfiguration,
    public readonly localStorageService: LocalStorageService,
    public readonly artifactService: TenantArtifactService,
    public readonly linkService: TenantLinkService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    this.initHandlers();
  }

  setEventSource(): void {
    this.destroyEventSource();
    const eventSourceUrl = this.makeEventSourceUrl();
    this.eventSource = new EventSource(eventSourceUrl);

    this.eventSource.onmessage = message => this.handleMessage(JSON.parse(message.data));
    this.eventSource.onerror = e => {
      console.warn('SSE logging: Event source on error', e);
      return;
    };

    console.log('Event source initialized:', this.eventSource);
  }

  destroyEventSource(): void {
    if (this.eventSource) this.eventSource.close();
    this.eventSource = null;
  }

  async linkUpdateHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const link = await lastValueFrom(this.linkService.linkControllerGet({ id: message.payload?.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<string[]>(
          RuntimeStateNotificationEnum.updateLinks,
          [link.sourceArtifactId, link.destinationArtifactId],
          this.runtimeKey,
          link.linkTypeId,
        ),
      );
    }
  }

  async linkDeleteHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const link = await lastValueFrom(this.linkService.linkControllerGet({ id: message.payload?.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<string[]>(
          RuntimeStateNotificationEnum.deleteLink,
          [link.sourceArtifactId, link.destinationArtifactId],
          this.runtimeKey,
          link.linkTypeId,
        ),
      );
    }
  }

  private makeEventSourceUrl(): string {
    const token = this.localStorageService.getFromState(StateKey.session, TOKEN_KEY);
    return `${this.apiConfiguration.rootUrl}/api/tenant/sse/subscribe?authorization=Bearer ${token}&tab-session=${this.hash}`;
  }

  private handleMessage(message: SseMessage): void {
    if (message.payload) {
      const { operationType, dataType } = message.payload;
      if (dataType && operationType && this.sseDataTypeEventHandlers && this.sseDataTypeEventHandlers?.[dataType])
        (this.sseDataTypeEventHandlers as any)[dataType][operationType].bind(this)(message);
    }
  }

  private initHandlers(): void {
    this.sseDataTypeEventHandlers = {
      [SseDataTypeEnum.artifact]: new SseDataTypeEventHandlers(this.artifactInsertHandler, this.artifactUpdateHandler, this.artifactDeleteHandler),
      [SseDataTypeEnum.artifactType]: new SseDataTypeEventHandlers(null, null, null),
      [SseDataTypeEnum.link]: new SseDataTypeEventHandlers(this.linkInsertHandler, this.linkUpdateHandler, this.linkDeleteHandler),
    };
  }

  private async artifactInsertHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const artifact = await lastValueFrom(this.artifactService.artifactControllerGet({ id: message.payload.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<ArtifactResponseDto>(RuntimeStateNotificationEnum.createArtifact, artifact, this.runtimeKey),
      );
    }
  }

  private async artifactUpdateHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const artifact = await lastValueFrom(this.artifactService.artifactControllerGet({ id: message.payload?.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<ArtifactResponseDto>(RuntimeStateNotificationEnum.updateArtifact, artifact, this.runtimeKey),
      );
    }
  }

  private async artifactDeleteHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const artifact = await lastValueFrom(this.artifactService.artifactControllerGet({ id: message.payload.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<ArtifactResponseDto>(RuntimeStateNotificationEnum.deleteArtifact, artifact, this.runtimeKey),
      );
    }
  }

  private async linkInsertHandler(message: SseMessage): Promise<void> {
    if (message.payload?.id) {
      const link = await lastValueFrom(this.linkService.linkControllerGet({ id: message.payload?.id }));
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<string[]>(
          RuntimeStateNotificationEnum.createLink,
          [link.sourceArtifactId, link.destinationArtifactId],
          this.runtimeKey,
          link.linkTypeId,
        ),
      );
    }
  }
}

export interface SseMessage {
  type?: string;
  payload?: {
    dataType?: SseDataTypeEnum;
    operationType?: SseOperationTypeEnum;
    id?: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SseDataTypeEventHandlers {
  constructor(
    insertCb: ((message: SseMessage) => Promise<void>) | null,
    updateCb: ((message: SseMessage) => Promise<void>) | null,
    deleteCb: ((message: SseMessage) => Promise<void>) | null,
  ) {
    Object.assign(this, { [SseOperationTypeEnum.insert]: insertCb, [SseOperationTypeEnum.update]: updateCb, [SseOperationTypeEnum.delete]: deleteCb });
  }
}

export enum SseOperationTypeEnum {
  insert = 'insert',
  update = 'update',
  delete = 'delete',
}

export enum SseDataTypeEnum {
  artifact = 'artifact',
  artifactType = 'artifact-type',
  link = 'link',
}
