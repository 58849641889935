import { Pipe, PipeTransform } from '@angular/core';
import { GetAttributeFromClientAttribute, GetDataTypeFromDataTypeId } from '@shared/methods/artifact.methods';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '../../types/list-container.types';

@Pipe({ name: 'getDataTypeFromClientAttribute' })
export class GetDataTypeFromClientAttributePipe implements PipeTransform {
  transform(
    clientAttribute: NewArtifactTypeClientAttribute | NewClientAttribute,
    attributes: ListContainer<NewAttribute>,
    dataTypes: ListContainer<NewDataType>,
  ): NewDataType | null {
    if (!clientAttribute) return null;

    const attribute = GetAttributeFromClientAttribute(clientAttribute, attributes.listMap);
    if (attribute) return GetDataTypeFromDataTypeId(attribute.dataTypeId, dataTypes.listMap);
    else return null;
  }
}
