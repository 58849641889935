<p-table
  (onLazyLoad)="onLazyLoad($event)"
  [columns]="columns"
  [filters]="{ deleted: [{ value: null, matchMode: 'equals', operator: 'and' }] }"
  [lazy]="true"
  [loading]="loading"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rowsPerPageOptions]="meta.limitOptions"
  [rows]="meta.limit"
  [totalRecords]="meta.totalCount"
  [value]="data"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template let-columns pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columns">
        <th class="p-text-truncate" pResizableColumn>
          {{ col.label }}
          <p-sortIcon *ngIf="col.key === NAME_VALUE" [field]="col.key" [pSortableColumn]="col.key"></p-sortIcon>
          <p-columnFilter *ngIf="col.key === NAME_VALUE" [field]="col.key" display="menu" matchMode="contains" type="text"> </p-columnFilter>
        </th>
      </ng-container>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-team pTemplate="body">
    <tr>
      <ng-container *ngFor="let col of columns">
        <td>
          <div class="p-text-truncate">
            <span [ngClass]="{ red: !!team.deleted, green: !team.deleted }">{{ team | getValueFromPath : col.key }}</span>
          </div>
        </td>
      </ng-container>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/admin/team', team.id]">
            <button class="p-button p-button-warning" icon="pi pi-pencil" iconPos="left" label="{{ 'Edit' | translate }}" pButton type="button"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1">{{ 'No records found' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
