import { Component, OnInit } from '@angular/core';
import { getSystemPublicPagesFromMasterState } from '@shared/methods/system-public.pages.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent implements OnInit {
  constructor(private readonly authorizationService: AuthorizationService) {}

  async ngOnInit(): Promise<void> {
    const systemPages = getSystemPublicPagesFromMasterState();
    if (!systemPages) {
      await this.authorizationService.anonymousLogin();
      window.location.reload();
    }
  }
}
