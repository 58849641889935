<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
<p-calendar (onClose)="onBlur()"
    [(ngModel)]="attr.value"
    (ngModelChange)="onChange()"
    [dateFormat]="dateFormat"
    [firstDayOfWeek]="firstDayOfWeek"
    [inputId]="'dateField' + index"
    [placeholder]="placeholder"
    [readonlyInput]="true"
    [selectionMode]="attribute.multipleValues ? 'multiple' : 'single'"
    [showTime]="showTime"
    [timeOnly]="dataType.isTime"
    [firstDayOfWeek]="firstDayOfWeek"
    appendTo="body">
</p-calendar>
<app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'dateField' + index"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder">
</app-artifact-attribute-form-field-label-with-icon>
