import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { ClientData } from '@shared/types/local-storage.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { DateTimeCalendarSettingsTypes } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-calendar-settings.types';
import {
  DateTimeFilterOptionsTypes,
  TimeFilterRuleType,
} from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';
import { DateTimeFilter, DateTimeFilterOption } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrls: ['./date-time-filter.component.scss'],
})
export class DateTimeFilterComponent implements OnInit {
  @Input() m: ArtifactFilter;
  @Input() placeholder = 'Select date';
  @Output() onFilterChange = new EventEmitter<any>();
  options = new DateTimeFilterOptionsTypes();
  selected: DateTimeFilter;
  settings = new DateTimeCalendarSettingsTypes();
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;

  constructor(private readonly cache: NewCacheService, private readonly filterUtil: FilterMetadataUtil) {}

  @Input() set dataType(type: BaseDataType) {
    this.settings = new DateTimeCalendarSettingsTypes(type);
    if (type === BaseDataType.time) {
      this.options = new DateTimeFilterOptionsTypes(true);
    }
  }

  ngOnInit(): void {
    !this.m.value?.ruleTypes?.length && (this.m.value = new DateTimeFilter());
    this.selected = this.m.value;
    (this.m.value.ruleTypes as DateTimeFilterOption[]).forEach(rule => {
      rule.value && this.isCalendarType(rule.ruleType) && (rule.value = new Date(rule.value as Date));
    });
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  isCalendarType(filterType: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo].includes(filterType as DateFilterEnum);
  }

  addRule(): void {
    this.selected.ruleTypes.push(new DateTimeFilterOption());
  }

  removeRule(index: number): void {
    this.selected.ruleTypes.splice(index, 1);
  }

  onClear(index: number): void {
    const val = this.selected.ruleTypes[index].ruleType === DateRangeFilterEnum.dateBetween ? [] : null;
    this.selected.ruleTypes[index].value = val;
    this.onChange(true);
  }

  onChangeRange(rule: DateTimeFilterOption, isChangeFrom = false): void {
    if (isChangeFrom) {
      const fromTime = new Date((rule as any).value[0]).getTime();
      const toTime = new Date((rule as any).value[1]).getTime();

      if (fromTime > toTime) {
        (rule as any).value[1] = null;
        this.onChange(true);
      }
    }

    if (Array.isArray(rule.value) && !!rule.value[0] && !!rule.value[1]) {
      this.onFilterChange.emit();
    }
  }

  onChange(isClear?: boolean): void {
    setTimeout(() => {
      this.onFilterChange.emit(isClear);
    }, 10);
  }

  onRuleTypeChange(ruleType: any, index: number): void {
    if (this.filterUtil.isDateRangeAutomaticallyComputed(ruleType)) this.onChange();
    else this.selected.ruleTypes[index].value = null;

    ruleType === DateRangeFilterEnum.dateBetween && (this.selected.ruleTypes[index].value = []);
  }
}
