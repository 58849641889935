import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { IS_DELETED_LABEL, TENANT_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { TenantService } from './services/tenant.service';
import { TenantModel } from './types/tenant.types';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
  providers: [TenantService],
})
export class TenantComponent extends CoreComponent<TenantService, TenantModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: TenantService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new TenantModel(), service, announcement);
  }

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: TENANT_LABEL }]);
  }

  onClickIsPublic(event: { originalEvent: Event; value: any }): void {
    event.value === false && (this.m.tenant.registrationAllowed = false);
  }
}
