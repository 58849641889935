import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { DisplayAtControlService, DisplayAtOverlayControlService } from '@shared/components/common-display-at';
import { AbstractDisplayAtComponent } from '@shared/components/common-display-at/components/abstract-display-at.component';
import { Constants } from '@shared/constants/constants';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { ConvertToClientDatetime, ConvertToDateByFormat } from '@shared/methods/date.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewDataType } from '@shared/types/data-type.types';
import { TableColumnFormat } from '@shared/types/table.types';
import { ArtifactWidgetFormatsMap } from '@widgets/artifact-widget/types/artifact-widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-display-at-system-date',
  templateUrl: './display-at-system-date.component.html',
  styleUrls: ['./display-at-system-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtSystemDateComponent extends AbstractDisplayAtComponent {
  @Input() dataType: NewDataType;
  @Input() code: string;
  @Input() attrId: string;
  @Input() formatStyles: FormatStyles;
  @Input() contentColumnFormat: TableColumnFormat;
  @Input() formatsMap: ArtifactWidgetFormatsMap;
  value: string;
  private eventsSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly datOverlayControlService: DisplayAtOverlayControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
    this.init();
  }

  @Input() set artifactNew(art: NewArtifact) {
    const attr = Constants.artifactCustomAttributes.find(
      attribute => attribute.value === this.dataType?.id || attribute.value === this.attrId || attribute.code === this.code,
    );
    const code = attr?.code;

    if (attr && code) {
      const displayVariant = this.formatsMap
        ? (attr.value && this.formatsMap.attribute[attr.value].value.displayMetadata?.selectedVariantCode) || ''
        : this.selectedVariant;
      const dateStr = get(art, code);
      if (!dateStr) {
        this.value = '';
        return;
      }

      const date = new Date(dateStr);
      this.value = displayVariant ? ConvertToDateByFormat(date, displayVariant) : ConvertToClientDatetime(date);
    }
  }

  ngOnDestroy(): void {
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  private init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === this.code && this.cdr.markForCheck();
      }
    });
  }
}
