import { BaseDataType, DataTypeKind } from '../../../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowBaseCondition, WorkflowConditionType } from '../../../index';

export abstract class AbstractWorkflowCondition implements WorkflowBaseCondition {
  protected type: WorkflowConditionType;
  protected supportedTypes: BaseDataType[];
  protected supportedKinds: DataTypeKind[];

  abstract isMet(source: any, value?: any): boolean;

  getType(): WorkflowConditionType {
    return this.type;
  }

  getSupportedDataTypes(): BaseDataType[] {
    return this.supportedTypes;
  }

  getSupportedDataKinds(): DataTypeKind[] {
    return this.supportedKinds;
  }
}
