import { Injectable } from '@angular/core';
import { TagWidgetComponent } from '../tag-widget.component';
import { TagWidgetModel, TagWidgetModelDto, TagWidgetValue } from '../types/tag-widget.types';

@Injectable()
export class TagWidgetService {
  c: TagWidgetComponent;
  m: TagWidgetModel;

  init(context: TagWidgetComponent, dto?: TagWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new TagWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new TagWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }
}
