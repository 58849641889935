<p-accordion *ngIf="parsedFile" class="parsed-file-acc">
  <p-accordionTab *ngFor="let itemType of parsedFile.items | keyvalue" [disabled]="parsedFile.items[itemType.key].length === 0">
    <ng-template pTemplate="header">
      <div class="parsed-file-acc-header">
        {{ itemType.key }}
        <p-tag *ngIf="parsedFile.items[itemType.key].length === 0" styleClass="ml-2" value="{{ 'Empty' | translate }}"></p-tag>
        <p-tag *ngIf="willCreateItem(parsedFile.items[itemType.key])" styleClass="ml-2" severity="warning" value="{{ 'New items' | translate }}"></p-tag>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="grid m-0 content-header">
        <div class="col-5">{{ 'In export file' | translate }}</div>
        <div class="col-2">{{ 'Action' | translate }}</div>
        <div class="col-5">{{ 'In current system' | translate }}</div>
      </div>
      <div class="grid m-0" *ngFor="let item of parsedFile.items[itemType.key]">
        <div class="col-5">
          {{ item.additionalImportedData.exportName }}
          <p>
            <small>{{ item.exportId }}</small>
          </p>
        </div>
        <div class="col-2">
          <p-tag *ngIf="item.existingId === null" severity="warning" value="{{ 'Create new' | translate }}"></p-tag>
          <p-tag *ngIf="item.existingId !== null" value="{{ 'Map existing' | translate }}"></p-tag>
        </div>
        <div class="col-5">
          {{ item.additionalImportedData.afterImportName }}
          <p>
            <small>{{ item.existingId ? item.existingId : 'New ID' }}</small>
          </p>
        </div>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
