import { LanguageEnum } from '@shared/components/language-switcher/types/language-switcher.types';

export const Environment = {
  enableInstana: true,
  production: false,
  enablePwa: false,
  reCaptchaSecretKey: '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe',
  reCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  cachedSubjectDefaultNoUpdateTime: 5 * 60 * 1000,

  translations: {
    locales: [LanguageEnum.EN, LanguageEnum.DE],
    defaultLocale: LanguageEnum.EN,
  },

  tableConfig: {
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
  },

  calendarConfig: {
    clientDateFormat: 'dd.mm.yy',
  },
};
