import { inject, Injectable } from '@angular/core';
import { ArtifactUpdateBulkRequestDto } from '@api/models/artifact-update-bulk-request-dto';
import { TenantArtifactService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { BulkArtifactEditPopupOptions } from '@shared/components/bulk-artifact-edit-popup/types/bulk-artifact-edit-popup-options';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { BulkOperationResult } from '@shared/components/bulk-artifact-edit-popup/types/bulk-operation-result';
import { AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class BulkArtifactEditService {
  inProgress$: Observable<boolean>;
  private options: BulkArtifactEditPopupOptions = inject(DynamicDialogConfig<BulkArtifactEditPopupOptions>).data;
  private inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tenantArtifactService: TenantArtifactService = inject(TenantArtifactService);

  constructor(private readonly cache: NewCacheService) {
    this.inProgress$ = this.inProgress.asObservable();
  }

  async updateArtifacts(updatedAttributes: BulkEditingAttribute[], notify = false): Promise<BulkOperationResult> {
    this.inProgress.next(true);
    const result = await this.update(this.getUpdateDto(updatedAttributes), notify);
    this.inProgress.next(false);

    return result;
  }

  private async update(body: ArtifactUpdateBulkRequestDto, notify: boolean): Promise<BulkOperationResult> {
    const { meta, data } = await lastValueFrom(this.tenantArtifactService.artifactControllerUpdateBulk({ body, notify }));
    const failure = meta.errors?.length || 0;
    const success = body.artifacts.length - failure;
    const skip = body.artifacts.length - failure - success;

    data.forEach(dto => this.cache.data.artifacts.setItem(dto));

    return { success, failure, skip };
  }

  private getUpdateDto(updatedAttributes: BulkEditingAttribute[]): ArtifactUpdateBulkRequestDto {
    const { attributes, dataTypes } = this.options;
    const artifacts = this.getChangedArtifacts(updatedAttributes).map((artifact: NewArtifact) => {
      const dto = artifact.toUpdateDto(attributes, dataTypes);

      updatedAttributes.forEach((updatedAttribute: BulkEditingAttribute) => {
        const attributeId = updatedAttribute.clientAttribute.id;

        if (attributeId in artifact.attributes) {
          const attribute = attributes.listMap[attributeId];
          const dataType = dataTypes.listMap[attribute.dataTypeId];
          const value = AttributeValueToServer(dataType, attribute, updatedAttribute.clientAttribute.value);

          dto.attributes![attributeId] = { value };
        }
      });

      return dto;
    });

    return { artifacts };
  }

  private getChangedArtifacts(updatedAttributes: BulkEditingAttribute[]): NewArtifact[] {
    const { artifacts, artifactTypes } = this.options;

    return artifacts.filter((artifact: NewArtifact) => {
      const artifactType = artifactTypes.listMap[artifact.artifactTypeId];

      return updatedAttributes.some(({ id }: BulkEditingAttribute) => artifactType?.hasAttribute(id));
    });
  }
}
