import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportRequestDto, PageExportRequestDto, PagePartDto } from '@api/models';
import { StrictHttpResponse } from '@api/strict-http-response';
import { BlockUiService } from '@shared/services/block-ui.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent {
  @Input() headerTitle = 'Export parts';
  @Input() readonly exportPartsMethod: (params: { body: ExportRequestDto | PageExportRequestDto }) => Observable<StrictHttpResponse<Blob>>;
  @Input() selectedParts: { [key: string]: any; id: string; name?: string; pagePart?: PagePartDto }[];
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  deepExport = false;
  disableTransaction = false;

  constructor(private readonly announcement: AnnouncementService, private readonly blockUiService: BlockUiService) {}

  hideModal(): void {
    this.visibleChange.emit(false);
  }

  getSelectedIds(): string[] {
    return [...new Set(this.selectedParts.map(part => part.id))]; //unique ids list
  }

  getSelectedPageParts(): PagePartDto[] {
    const pageParts: PagePartDto[] = [];
    this.selectedParts.forEach(part => {
      if (part.pagePart) pageParts.push(part.pagePart);
    });
    return pageParts;
  }

  async exportFile(): Promise<void> {
    const exportPartsHandler = async (requestBody: ExportRequestDto | PageExportRequestDto): Promise<void> => {
      this.setUiBlocked(true);
      try {
        this.exportPartsMethod({
          body: requestBody,
        }).subscribe(
          response => {
            const header = response.headers.get('content-disposition') || '';
            saveAs(response.body, !header ? 'export.yaml' : header.slice(header.search('filename="') + 10, -1));
            this.setUiBlocked(false);
          },
          error => {
            console.log(error);
            this.setUiBlocked(false);
          },
        );
      } catch (e) {
        console.log(e);
        await this.announcement.error('Something went wrong during page export');
        this.setUiBlocked(false);
      }
    };

    const ids = this.getSelectedIds();
    if (ids.length === 0) {
      await this.announcement.error('You need to choose parts to export!');
      return;
    }

    const pageParts = this.getSelectedPageParts();
    await exportPartsHandler({
      deepExport: this.deepExport,
      disableTransaction: this.disableTransaction,
      ids: this.getSelectedIds(),
      ...(pageParts.length > 0 ? { pageParts: pageParts } : {}),
    });
  }

  private setUiBlocked(blocked: boolean): void {
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }
}
