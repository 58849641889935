import { __awaiter } from 'tslib';
import * as i3 from '@angular/common';
import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, isDevMode, Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import * as i1 from '@angular/router';
import { NavigationStart, GuardsCheckEnd, NavigationEnd } from '@angular/router';
import { BehaviorSubject, filter, switchMap, NEVER, of, tap, first, map, shareReplay, pluck, firstValueFrom, takeWhile, catchError, ReplaySubject, merge } from 'rxjs';
import * as i2 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
const _c0 = ["*"];
const ScullyDefaultSettings = {
  useTransferState: true,
  alwaysMonitor: false,
  manualIdle: false,
  baseURIForScullyContent: 'http://localhost:1668'
};
const SCULLY_LIB_CONFIG = new InjectionToken('scullyLibConfig', {
  factory: () => ScullyDefaultSettings
});

/**
 * Take a string, preferably resembling an URL, take out the search params, the anchors, and the ending slash
 * @param str
 */
const basePathOnly = str => {
  if (str.includes('#')) {
    str = str.split('#')[0];
  }
  if (str.includes('?')) {
    str = str.split('?')[0];
  }
  const cleanedUpVersion = str.endsWith('/') ? str.slice(0, -1) : str;
  return cleanedUpVersion;
};

// tslint:disable: no-string-literal
const isScullyRunning = () => window && window['ScullyIO'] === 'running';
const isScullyGenerated = () => window && window['ScullyIO'] === 'generated';
function mergePaths(base, path) {
  base = base !== null && base !== void 0 ? base : '';
  if (base.endsWith('/') && path.startsWith('/')) {
    return `${base}${path.substr(1)}`;
  }
  if (!base.endsWith('/') && !path.startsWith('/')) {
    return `${base}/${path}`;
  }
  return `${base}${path}`;
}
const SCULLY_SCRIPT_ID = `ScullyIO-transfer-state`;
const SCULLY_STATE_START = `/** ___SCULLY_STATE_START___ */`;
const SCULLY_STATE_END = `/** ___SCULLY_STATE_END___ */`;
const initialStateDone = '__done__with__Initial__navigation__';
// Adding this dynamic comment to suppress ngc error around Document as a DI token.
// https://github.com/angular/angular/issues/20351#issuecomment-344009887
/** @dynamic */
class TransferStateService {
  constructor(document, router, http) {
    this.document = document;
    this.router = router;
    this.http = http;
    /** parse from index, or load from data.json, according to scullConfig setting */
    this.inlineOnly = false;
    /** set the currentBase to something that it can never be */
    this.currentBaseUrl = '//';
    /** subject to fire off incoming states */
    this.stateBS = new BehaviorSubject({});
    this.state$ = this.stateBS.pipe(filter(state => state !== undefined));
    // emit the next url when routing is complete
    this.nextUrl = this.router.events.pipe(filter(e => e instanceof NavigationStart), switchMap(e => {
      if (basePathOnly(this.initialUrl) === basePathOnly(e.url)) {
        /** don't kick off on initial load to prevent flicker */
        this.initialUrl = initialStateDone;
        return NEVER;
      }
      return of(e);
    }), /** reset the state, so new components will never get stale data */
    tap(() => this.stateBS.next(undefined)), /** prevent emitting before navigation to _this_ URL is done. */
    switchMap(e => this.router.events.pipe(filter(ev => ev instanceof GuardsCheckEnd && ev.url === e.url), first())), map(ev => basePathOnly(ev.urlAfterRedirects || ev.url)), shareReplay(1));
  }
  startMonitoring() {
    if (window && window['ScullyIO-injected'] && window['ScullyIO-injected'].inlineStateOnly) {
      this.inlineOnly = true;
    }
    this.setupEnvForTransferState();
    this.setupStartNavMonitoring();
  }
  setupEnvForTransferState() {
    if (isScullyRunning()) {
      this.injectScript();
      // In Scully puppeteer
      const exposed = window['ScullyIO-exposed'] || {};
      if (exposed.transferState) {
        this.stateBS.next(exposed.transferState);
        this.saveState(exposed.transferState);
      }
    } else {
      // On the client AFTER Scully rendered it. Also store the state in case the user comes from a non-scully page
      this.initialUrl = window.location.pathname || '__no_NO_no__';
      this.initialUrl = this.initialUrl !== '/' && this.initialUrl.endsWith('/') ? this.initialUrl.slice(0, -1) : this.initialUrl;
      /** set the initial state */
      if (isScullyGenerated()) {
        /** only update the initial state when the page is Scully generated */
        this.stateBS.next(window && window[SCULLY_SCRIPT_ID] || {});
      }
    }
  }
  injectScript() {
    this.script = this.document.createElement('script');
    this.script.setAttribute('id', SCULLY_SCRIPT_ID);
    let last = this.document.body.lastChild;
    while (last.previousSibling.nodeName === 'SCRIPT') {
      last = last.previousSibling;
    }
    // console.log(`
    // --------------------------------------------------
    //    Welp! ${this.script}
    // --------------------------------------------------
    // `)
    this.document.body.insertBefore(this.script, last);
  }
  /**
   * Getstate will return an observable that containes the data.
   * It does so right after the navigation for the page has finished.
   * please note, this works SYNC on initial route, preventing a flash of content.
   * @param name The name of the state to
   */
  getState(name) {
    /** start of the fetch for the current active route. */
    this.fetchTransferState();
    return this.state$.pipe(pluck(name)
    // tap((data) => console.log('tss', data))
    );
  }
  /**
   * Read the current state, and see if it has an value for the name.
   * (note the value it containes still can be undefined!)
   */
  stateHasKey(name) {
    return this.stateBS.value && this.stateBS.value.hasOwnProperty(name);
  }
  /**
   * Read the current state, and see if it has an value for the name.
   * ys also if there is actually an value in the state.
   */
  stateKeyHasValue(name) {
    return this.stateBS.value && this.stateBS.value.hasOwnProperty(name) && this.stateBS.value[name] != null;
  }
  /**
   * SetState will update the script in the generated page with data added.
   * @param name
   * @param val
   */
  setState(name, val) {
    const newState = Object.assign(Object.assign({}, this.stateBS.value), {
      [name]: val
    });
    this.stateBS.next(newState);
    this.saveState(newState);
  }
  saveState(newState) {
    if (isScullyRunning()) {
      this.script.textContent = `{window['${SCULLY_SCRIPT_ID}']=_u(String.raw\`${SCULLY_STATE_START}${escapeHtml(JSON.stringify(newState))}${SCULLY_STATE_END}\`);function _u(t){t=t.split('${SCULLY_STATE_START}')[1].split('${SCULLY_STATE_END}')[0];const u={'_~b~': "${'`'}",'_~q~': "'",'_~o~': '$','_~s~': '/','_~l~': '<','_~g~': '>'};return JSON.parse(t.replace(/_~d~/g,'\\\\"').replace(/_~[^]~/g, (s) => u[s]).replace(/\\n/g,'\\\\n').replace(/\\t/g,'\\\\t').replace(/\\r/g,'\\\\r'));}}`;
    }
  }
  /**
   * starts monitoring the router, and keep the url from the last completed navigation handy.
   */
  setupStartNavMonitoring() {
    if (!isScullyGenerated()) {
      return;
    }
    /** start monitoring the routes */
    this.nextUrl.subscribe();
  }
  /**
   * Wraps an observable into scully's transfer state. If data for the provided `name` is
   * available in the state, it gets returned. Otherwise, the `originalState` observable will
   * be returned.
   *
   * On subsequent calls, the data in the state will always be returned. The `originalState` will
   * be returned only once.
   *
   * This is a convenience method which does not require you to use `getState`/`setState` manually.
   *
   * @param name state key
   * @param originalState an observable which yields the desired data
   */
  useScullyTransferState(name, originalState) {
    if (isScullyGenerated()) {
      return this.getState(name);
    }
    return originalState.pipe(tap(state => this.setState(name, state)));
  }
  fetchTransferState() {
    return __awaiter(this, void 0, void 0, function* () {
      /** helper to read the part before the first slash (ignores leading slash) */
      const base = url => url.split('/').filter(part => part.trim() !== '')[0];
      /** put this in the next event cycle so the correct route can be read */
      yield new Promise(r => setTimeout(r, 0));
      /** get the current url */
      const currentUrl = yield firstValueFrom(this.nextUrl);
      const baseUrl = base(currentUrl);
      if (this.currentBaseUrl === baseUrl) {
        /** already monitoring, don't tho a thing */
        return;
      }
      /** keep the baseUrl for later reference */
      this.currentBaseUrl = baseUrl;
      this.nextUrl.pipe( /** keep updating till we move to another route */
      takeWhile(url => base(url) === this.currentBaseUrl),
      // Get the next route's data from the the index or data file
      switchMap(url => this.inlineOnly ? this.readFromIndex(url) : this.readFromJson(url)), catchError(e => {
        // TODO: come up with better error text.
        /** the developer needs to know, but its not fatal, so just return an empty state */
        console.warn('Error while loading of parsing Scully state:', e);
        return of({});
      }), tap(newState => {
        /** and activate the state in the components. on any error it will be empty */
        this.stateBS.next(newState);
      })).subscribe({
        /** when completes (different URL) */
        complete: () => {
          /** reset the currentBaseUrl */
          this.currentBaseUrl = '//';
        }
      });
    });
  }
  readFromJson(url) {
    return firstValueFrom(this.http.get(dropPreSlash(mergePaths(url, '/data.json'))));
  }
  readFromIndex(url) {
    return firstValueFrom(this.http.get(dropPreSlash(mergePaths(url, '/index.html')), {
      responseType: 'text'
    })).then(html => {
      const newStateStr = html.split(SCULLY_STATE_START)[1].split(SCULLY_STATE_END)[0];
      return JSON.parse(unescapeHtml(newStateStr));
    });
  }
}
/** @nocollapse */
TransferStateService.ɵfac = function TransferStateService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TransferStateService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient));
};
/** @nocollapse */
TransferStateService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TransferStateService,
  factory: TransferStateService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TransferStateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i1.Router
    }, {
      type: i2.HttpClient
    }];
  }, null);
})();
function dropPreSlash(string) {
  return string.startsWith('/') ? string.slice(1) : string;
}
/**
 * we need to escape our HTML to prevent XXS,
 * It needs to be custom, because the content can already contain html-escaped sequences
 **/
function escapeHtml(text) {
  const escapedText = {
    "'": '_~q~',
    $: '_~o~',
    '`': '_~b~',
    '/': '_~s~',
    '<': '_~l~',
    '>': '_~g~'
  };
  return text
  /** escape the json */.replace(/[\$`'<>\/]/g, s => escapedText[s])
  /** replace escaped double-quotes with single */.replace(/\\\"/g, `_~d~`);
}
/**
 * Unescape our custom escaped texts
 * @param text
 */
function unescapeHtml(text) {
  const unescapedText = {
    '_~q~': "'",
    '_~b~': '`',
    '_~o~': '$',
    '_~s~': '/',
    '_~l~': '<',
    '_~g~': '>'
  };
  return text
  /** put back escaped double quotes to make valid json again */.replace(/_~d~/g, `\\"`)
  /** replace the custom escapes */.replace(/_~[^]~/g, s => unescapedText[s])
  /** restore newlines+cr */.replace(/\n/g, '\\n').replace(/\r/g, '\\r');
}

// if (window) {
//   window.addEventListener('AngularReady', ev => {
//     console.log('appReady fired', ev);
//   });
// }
// Adding this dynamic comment to suppress ngc error around Document as a DI token.
// https://github.com/angular/angular/issues/20351#issuecomment-344009887
/** @dynamic */
class IdleMonitorService {
  constructor(zone, router, conf, document, tss) {
    this.zone = zone;
    this.router = router;
    this.document = document;
    /** store the 'landing' url so we can skip it in idle-check. */
    this.initialUrl = dropEndingSlash(window && window.location && window.location.pathname) || '';
    this.imState = new BehaviorSubject({
      idle: false,
      timeOut: 5 * 1000 // 5 seconds timeout as default
    });
    this.idle$ = this.imState.pipe(pluck('idle'));
    this.initApp = new Event('AngularInitialized', {
      bubbles: true,
      cancelable: false
    });
    this.appReady = new Event('AngularReady', {
      bubbles: true,
      cancelable: false
    });
    this.appTimeout = new Event('AngularTimeout', {
      bubbles: true,
      cancelable: false
    });
    /** provide the default for missing conf paramter */
    this.scullyLibConfig = Object.assign({}, ScullyDefaultSettings, conf);
    const exposed = window['ScullyIO-exposed'] || {};
    const manualIdle = !!exposed.manualIdle;
    if (!this.scullyLibConfig.manualIdle && window && (this.scullyLibConfig.alwaysMonitor || isScullyRunning())) {
      this.document.dispatchEvent(this.initApp);
      this.router.events.pipe(filter(ev => ev instanceof NavigationEnd && ev.urlAfterRedirects !== undefined), /** don't check the page that has this setting. event is only importand on page load */
      filter(ev => manualIdle ? ev.urlAfterRedirects !== this.initialUrl : true), tap(() => this.zoneIdleCheck())).subscribe();
    }
    if (this.scullyLibConfig.manualIdle) {
      /** we still need the init event. */
      this.document.dispatchEvent(this.initApp);
    }
    if (this.scullyLibConfig.useTransferState) {
      /** don't start monitoring if people don't use the transferState */
      tss.startMonitoring();
    }
  }
  fireManualMyAppReadyEvent() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.document.dispatchEvent(this.appReady);
    });
  }
  init() {
    return firstValueFrom(this.idle$);
  }
  zoneIdleCheck() {
    return __awaiter(this, void 0, void 0, function* () {
      if (Zone === undefined) {
        return this.simpleTimeout();
      }
      const taskTrackingZone = Zone.current.get('TaskTrackingZone');
      if (taskTrackingZone === undefined) {
        return this.simpleTimeout();
      }
      if (this.imState.value.idle) {
        yield this.setState('idle', false);
      }
      /** run the actual check for 'idle' outsides zone, otherwise it will never come to an end. */
      this.zone.runOutsideAngular(() => {
        let tCancel;
        let count = 0;
        const startTime = Date.now();
        const monitor = () => {
          clearTimeout(tCancel);
          // console.table(taskTrackingZone.macroTasks);
          if (Date.now() - startTime > 30 * 1000) {
            /** bail out after 30 seconds. */
            this.document.dispatchEvent(this.appTimeout);
            return;
          }
          if (taskTrackingZone.macroTasks.length > 0 && taskTrackingZone.macroTasks.find(z => z.source.includes('XMLHttpRequest')) !== undefined || count < 1 // make sure it runs at least once!
          ) {
            tCancel = setTimeout(() => {
              count += 1;
              monitor();
            }, 50);
            return;
          }
          this.zone.run(() => {
            /** run this inside the zone, and give the app 250Ms to wrap up, before scraping starts */
            setTimeout(() => {
              this.document.dispatchEvent(this.appReady);
              this.setState('idle', true);
            }, 250);
          });
        };
        monitor();
      });
    });
  }
  simpleTimeout() {
    return __awaiter(this, void 0, void 0, function* () {
      /** zone not available, use a timeout instead. */
      console.warn('Scully is using timeouts, add the needed polyfills instead!');
      yield new Promise(r => setTimeout(r, this.imState.value.timeOut));
      this.document.dispatchEvent(this.appReady);
    });
  }
  setPupeteerTimeoutValue(milliseconds) {
    this.imState.next(Object.assign(Object.assign({}, this.imState.value), {
      timeOut: milliseconds
    }));
  }
  setState(key, value) {
    this.imState.next(Object.assign(Object.assign({}, this.imState.value), {
      [key]: value
    }));
  }
}
/** @nocollapse */
IdleMonitorService.ɵfac = function IdleMonitorService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IdleMonitorService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.Router), i0.ɵɵinject(SCULLY_LIB_CONFIG), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(TransferStateService));
};
/** @nocollapse */
IdleMonitorService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: IdleMonitorService,
  factory: IdleMonitorService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdleMonitorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i1.Router
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [SCULLY_LIB_CONFIG]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: TransferStateService
    }];
  }, null);
})();
function dropEndingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}
class ScullyRoutesService {
  constructor(router, http) {
    this.router = router;
    this.http = http;
    this.refresh = new ReplaySubject(1);
    /**
     * An observable with all routes, published and unpublished alike
     */
    this.allRoutes$ = this.refresh.pipe(switchMap(() => this.http.get('assets/scully-routes.json')), catchError(() => {
      console.warn('Scully routes file not found, are you running the Scully generated version of your site?');
      return of([]);
    }), /** filter out all non-array results */
    filter(routes => Array.isArray(routes)), map(this.cleanDups), shareReplay({
      refCount: false,
      bufferSize: 1
    }));
    /**
     * An observable with available routes (all published routes)
     */
    this.available$ = this.allRoutes$.pipe(map(list => list.filter(r => r.hasOwnProperty('published') ? r.published !== false : true)), shareReplay({
      refCount: false,
      bufferSize: 1
    }));
    /**
     * an observable with all unpublished routes
     */
    this.unPublished$ = this.allRoutes$.pipe(map(list => list.filter(r => r.hasOwnProperty('published') ? r.published === false : false)), shareReplay({
      refCount: false,
      bufferSize: 1
    }));
    /**
     * An observable with the top-level off all published routes.
     * (in an urls it would be `http://www.sample.org/__thisPart__/subroutes`)
     */
    this.topLevel$ = this.available$.pipe(map(routes => routes.filter(r => !r.route.slice(1).includes('/'))), shareReplay({
      refCount: false,
      bufferSize: 1
    }));
    /** kick off first cycle */
    this.reload();
  }
  /**
   * returns an observable that returns the route information for the
   * route currently selected. subscribes to route-events to update when needed
   */
  getCurrent() {
    if (!location) {
      /** probably not in a browser, no current location available */
      return of();
    }
    /** fire off at start, and when navigation is done. */
    return merge(of(new NavigationEnd(0, '', '')), this.router.events).pipe(filter(e => e instanceof NavigationEnd), switchMap(() => this.available$), map(list => {
      const curLocation = basePathOnly(encodeURI(location.pathname).trim());
      return list.find(r => curLocation === basePathOnly(r.route.trim()) || r.slugs && Array.isArray(r.slugs) && r.slugs.find(slug => curLocation.endsWith(basePathOnly(slug.trim()))));
    }));
  }
  /**
   * internal, as routes can have multiple slugs, and so occur multiple times
   * this util function collapses all slugs back into 1 route.
   */
  cleanDups(routes) {
    const m = new Map();
    /** check for duplicates by comparing all, include route in comparison if its the only thing, or the only thing with only the tile  */
    routes.forEach(r => m.set(JSON.stringify(Object.assign(Object.assign({}, r), {
      route: hasOtherprops(r) ? '' : r.route
    })), r));
    return [...m.values()];
  }
  /** an utility that will force a reload of the `scully-routes.json` file */
  reload() {
    this.refresh.next();
  }
}
/** @nocollapse */
ScullyRoutesService.ɵfac = function ScullyRoutesService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScullyRoutesService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient));
};
/** @nocollapse */
ScullyRoutesService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ScullyRoutesService,
  factory: ScullyRoutesService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScullyRoutesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: i2.HttpClient
    }];
  }, null);
})();
function hasOtherprops(obj) {
  const keys = Object.keys(obj);
  if (keys.length === 1 && keys.includes('route')) {
    return false;
  }
  if (keys.length === 2 && keys.includes('route') && keys.includes('title')) {
    return false;
  }
  return true;
}

/**
 * Returns an array of nodes coninting all the html comments in the element.
 * When a searchText is given this is narrowed down to only comments that contian this text
 * @param rootElem Element to search nto
 * @param searchText optional string that needs to be in a HTML comment
 */
function findComments(rootElem, searchText) {
  const comments = [];
  // Fourth argument, which is actually obsolete according to the DOM4 standard, seems required in IE 11
  const iterator = document.createNodeIterator(rootElem, NodeFilter.SHOW_COMMENT, {
    acceptNode: node => {
      // Logic to determine whether to accept, reject or skip node
      // In this case, only accept nodes that have content
      // that is containing our searchText, by rejecting any other nodes.
      if (searchText && node.nodeValue && !node.nodeValue.includes(searchText)) {
        return NodeFilter.FILTER_REJECT;
      }
      return NodeFilter.FILTER_ACCEPT;
    }
  }
  // , false // IE-11 support requires this parameter.
  );
  let curNode;
  // tslint:disable-next-line: no-conditional-assignment
  while (curNode = iterator.nextNode()) {
    comments.push(curNode);
  }
  return comments;
}

/** this is needed, because otherwise the CLI borks while building */
const scullyBegin = '<!--scullyContent-begin-->';
const scullyEnd = '<!--scullyContent-end-->';
/** use the module's closure to keep a system-wide check for the last handled URL. */
let lastHandled;
// Adding this dynamic comment to suppress ngc error around Document as a DI token.
// https://github.com/angular/angular/issues/20351#issuecomment-344009887
/** @dynamic */
class ScullyContentComponent {
  constructor(elmRef, srs, router, location, http, document, conf) {
    this.elmRef = elmRef;
    this.srs = srs;
    this.router = router;
    this.location = location;
    this.http = http;
    this.document = document;
    this.conf = conf;
    this.baseUrl = this.conf.useTransferState || ScullyDefaultSettings.useTransferState;
    this.elm = this.elmRef.nativeElement;
    /** pull in all  available routes into an eager promise */
    this.routes = firstValueFrom(this.srs.allRoutes$);
    /** monitor the router, so we can update while navigating in the same 'page' see #311 */
    this.routeUpdates$ = this.router.events.pipe(filter(ev => ev instanceof NavigationEnd), /** don't replace if we are already there */
    filter(ev => lastHandled && !lastHandled.endsWith(basePathOnly(ev.urlAfterRedirects))), tap(r => this.replaceContent()));
    this.routeSub = this.routeUpdates$.subscribe();
    /** do this from constructor, so it runs ASAP */
  }
  ngOnInit() {
    if (this.elm) {
      /** this will only fire in a browser environment */
      this.handlePage();
    }
  }
  /**
   * Loads the static content from scully into the view
   * Will fetch the content from sibling links with xmlHTTPrequest
   */
  handlePage() {
    return __awaiter(this, void 0, void 0, function* () {
      const curPage = basePathOnly(location.href);
      if (lastHandled === curPage) {
        /**
         * Due to the fix we needed for #311
         * it might happen that this routine is called
         * twice for the same page.
         * this code will make sure the second one is ignored.
         */
        return;
      }
      lastHandled = curPage;
      const template = this.document.createElement('template');
      const currentCssId = this.getCSSId(this.elm);
      if (window.scullyContent) {
        /** upgrade existing static content */
        const htmlString = window.scullyContent.html;
        if (currentCssId !== window.scullyContent.cssId) {
          /** replace the angular cssId */
          template.innerHTML = htmlString.split(window.scullyContent.cssId).join(currentCssId);
        } else {
          template.innerHTML = htmlString;
        }
      } else {
        /**
         *   NOTE
         * when updateting the texts for the errors, make sure you leave the
         *  `id="___scully-parsing-error___"`
         * in there. That way users can detect rendering errors in their CI
         * on a reliable way.
         */
        if (isScullyRunning()) {
          /**
           * we don't need to fetch the content, as it is already in the window
           */
          return;
        }
        yield firstValueFrom(this.http.get(curPage + '/index.html', {
          responseType: 'text'
        })).catch(e => {
          if (isDevMode()) {
            /** in devmode (usually in `ng serve`) check the scully server for the content too */
            const uri = new URL(location.href);
            const url = `${this.conf.baseURIForScullyContent}/${basePathOnly(uri.pathname)}/index.html`;
            return firstValueFrom(this.http.get(url, {
              responseType: 'text'
            }));
          } else {
            return Promise.reject(e);
          }
        }).then(html => {
          try {
            const htmlString = html.split(scullyBegin)[1].split(scullyEnd)[0];
            if (htmlString.includes('_ngcontent')) {
              /** update the angular cssId */
              const atr = '_ngcontent' + htmlString.split('_ngcontent')[1].split('=')[0];
              template.innerHTML = htmlString.split(atr).join(currentCssId);
            } else {
              template.innerHTML = htmlString;
            }
          } catch (e) {
            template.innerHTML = `<h2 id="___scully-parsing-error___">Sorry, could not parse static page content</h2>
            <p>This might happen if you are not using the static generated pages.</p>`;
          }
        }).catch(e => {
          template.innerHTML = '<h2 id="___scully-parsing-error___">Sorry, could not load static page content</h2>';
          console.error('problem during loading static scully content', e);
        });
      }
      /** insert the whole thing just before the `<scully-content>` element */
      const parent = this.elm.parentElement || this.document.body;
      const begin = this.document.createComment('scullyContent-begin');
      const end = this.document.createComment('scullyContent-end');
      parent.insertBefore(begin, this.elm);
      parent.insertBefore(template.content, this.elm);
      parent.insertBefore(end, this.elm);
      /** upgrade all hrefs to simulated routelinks (in next microtask) */
      setTimeout(() => this.document.querySelectorAll('[href]').forEach(this.upgradeToRoutelink.bind(this)), 10);
      // document.querySelectorAll('[href]').forEach(this.upgradeToRoutelink.bind(this));
    });
  }
  /**
   * upgrade a **href** attributes to links that respect the Angular router
   * and don't do a full page reload. Only works on links that are found in the
   * Scully route config file.
   * @param elm the element containing the **hrefs**
   */
  upgradeToRoutelink(elm) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      if (!['A', 'BUTTON'].includes(elm.tagName)) {
        return;
      }
      const hash = (_a = elm.dataset) === null || _a === void 0 ? void 0 : _a.hash;
      if (hash) {
        elm.setAttribute('href', '#' + hash);
        elm.setAttribute('onclick', '');
        elm.onclick = ev => {
          ev.preventDefault();
          const destination = document.getElementById(hash);
          if (destination) {
            const url = new URL(window.location.href);
            url.hash = hash;
            history.replaceState('', '', url.toString());
            destination.scrollIntoView();
          }
        };
        return;
      }
      const routes = yield this.routes;
      const href = elm.getAttribute('href');
      const lnk = basePathOnly(href.toLowerCase());
      const route = routes.find(r => basePathOnly(r.route.toLowerCase()) === lnk);
      /** only upgrade routes known by scully. */
      if (lnk && route && !lnk.startsWith('#')) {
        elm.onclick = ev => __awaiter(this, void 0, void 0, function* () {
          const splitRoute = route.route.split(`/`);
          const curSplit = location.pathname.split('/');
          // loose last "part" of route
          curSplit.pop();
          ev.preventDefault();
          const routed = yield this.router.navigate(splitRoute).catch(e => {
            console.error('routing error', e);
            return false;
          });
          if (!routed) {
            return;
          }
          /** check for the same route with different "data", and NOT a 1 level higher (length), and is not a fragment of th same page */
          if (curSplit.every((part, i) => splitRoute[i] === part) && splitRoute.length !== curSplit.length + 1) {
            setTimeout(() => this.replaceContent(), 10); // a small delay, so we are sure the angular parts in the page are settled enough
          }
        });
      }
    });
  }
  replaceContent() {
    /**
     * as Angular doesn't destroy the component if we stay on the same page,
     * we have to manually delete old content. Also we need to kick of loading
     * the new content. handlePage() takes care of that.
     */
    /** delete the content, as it is now out of date! */
    window.scullyContent = undefined;
    const parent = this.elm.parentElement;
    let cur = findComments(parent, 'scullyContent-begin')[0];
    while (cur && cur !== this.elm) {
      const next = cur.nextSibling;
      parent.removeChild(cur);
      cur = next;
    }
    this.handlePage();
  }
  getCSSId(elm) {
    return elm.getAttributeNames().find(a => a.startsWith('_ngcontent')) || '';
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
    /** reset the lastused */
    lastHandled = '//';
  }
}
/** @nocollapse */
ScullyContentComponent.ɵfac = function ScullyContentComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScullyContentComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ScullyRoutesService), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i3.Location), i0.ɵɵdirectiveInject(i2.HttpClient), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(SCULLY_LIB_CONFIG));
};
/** @nocollapse */
ScullyContentComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ScullyContentComponent,
  selectors: [["scully-content"]],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function ScullyContentComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["\n      :host {\n        display: none;\n      }\n      scully-content {\n        display: none;\n      }\n    "],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScullyContentComponent, [{
    type: Component,
    args: [{
      // tslint:disable-next-line: component-selector
      selector: 'scully-content',
      template: '<ng-content></ng-content>',
      styles: [`
      :host {
        display: none;
      }
      scully-content {
        display: none;
      }
    `],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      preserveWhitespaces: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: ScullyRoutesService
    }, {
      type: i1.Router
    }, {
      type: i3.Location
    }, {
      type: i2.HttpClient
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [SCULLY_LIB_CONFIG]
      }]
    }];
  }, null);
})();
class ScullyContentModule {}
/** @nocollapse */
ScullyContentModule.ɵfac = function ScullyContentModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScullyContentModule)();
};
/** @nocollapse */
ScullyContentModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ScullyContentModule
});
/** @nocollapse */
ScullyContentModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScullyContentModule, [{
    type: NgModule,
    args: [{
      declarations: [ScullyContentComponent],
      exports: [ScullyContentComponent]
    }]
  }], null, null);
})();
class ScullyLibModule {
  constructor(idle) {
    this.idle = idle;
  }
  /**
   * We use a little trick to get a working idle-service.
   * First, we separate out the component in a separate module to prevent a circulair injection
   * second we create a constuctor that activates the IdleMonitorService. as that is provided for 'root'
   * there will be only 1 instance in our app.
   */
  static forRoot(config = ScullyDefaultSettings) {
    config = Object.assign({}, ScullyDefaultSettings, config);
    return {
      ngModule: ScullyLibModule,
      providers: [{
        provide: SCULLY_LIB_CONFIG,
        useValue: config
      }]
    };
  }
}
/** @nocollapse */
ScullyLibModule.ɵfac = function ScullyLibModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScullyLibModule)(i0.ɵɵinject(IdleMonitorService));
};
/** @nocollapse */
ScullyLibModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ScullyLibModule
});
/** @nocollapse */
ScullyLibModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[ScullyContentModule, HttpClientModule], ScullyContentModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScullyLibModule, [{
    type: NgModule,
    args: [{
      imports: [ScullyContentModule, HttpClientModule],
      exports: [ScullyContentModule]
    }]
  }], function () {
    return [{
      type: IdleMonitorService
    }];
  }, null);
})();

/*
 * Public API Surface of ng-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { IdleMonitorService, ScullyContentComponent, ScullyContentModule, ScullyLibModule, ScullyRoutesService, TransferStateService, dropEndingSlash, isScullyGenerated, isScullyRunning };
