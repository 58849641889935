import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PictureWidgetComponent } from './picture-widget.component';
import { PicturePositionPipe } from '@widgets/picture-widget/pipes/get-content-position.pipe';
import { PictureWidgetResizeObserverDirective } from './directives/picture-widget.resize.directive';
import { GetSizeInPixelsPipe } from './pipes/get-size-in-pixels.pipe';

@NgModule({
  declarations: [PictureWidgetComponent, PicturePositionPipe, PictureWidgetResizeObserverDirective],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    DropdownModule,
    StyleFormModule,
    DialogModule,
    InputNumberModule,
    FileUploadModule,
    CommonPipesModule,
    TranslateModule,
    ElvisSharedModule,
  ],
  exports: [PictureWidgetComponent],
  providers: [GetSizeInPixelsPipe],
})
export class PictureWidgetModule {}
