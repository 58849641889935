import { Injectable } from '@angular/core';
import { WorkflowConditionFactory } from '@workflows/services/factory/workflow-condition-factory';
import { RuleConditionsUtilService } from '@workflows/services/rule-conditions-util.service';
import { RuleConditionOperatorType } from '@workflows/types';
import { RuleCondition, RuleConditionValue } from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { WorkflowTriggerEvent } from '../types';

@Injectable({ providedIn: 'root' })
export class RuleConditionsCheckerService {
  constructor(private readonly conditionFactory: WorkflowConditionFactory, private readonly conditionsUtil: RuleConditionsUtilService) {}

  areConditionsMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent): boolean {
    return group.operator === RuleConditionOperatorType.AND ? this.allAreMet(group, ruleTriggerEvent) : this.someAreMet(group, ruleTriggerEvent);
  }

  private allAreMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent): boolean {
    const conditionsMet = group.conditions.every(condition => this.isConditionMet(condition, ruleTriggerEvent));
    const nestedConditionsMet = group.groups ? group.groups.every(group => this.areConditionsMet(group, ruleTriggerEvent)) : true;
    return conditionsMet && nestedConditionsMet;
  }

  private someAreMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent): boolean {
    const conditionsMet = group.conditions.some(condition => this.isConditionMet(condition, ruleTriggerEvent));
    const nestedConditionsMet = group.groups ? group.groups.some(group => this.areConditionsMet(group, ruleTriggerEvent)) : true;
    return conditionsMet || nestedConditionsMet;
  }

  private isConditionMet(condition: RuleCondition, ruleTriggerEvent?: WorkflowTriggerEvent): boolean {
    const { source, operationType } = condition;
    const workflowCondition = this.conditionFactory.getWorkflowCondition(operationType);

    let sourceValue = this.getConditionSourceValue(source, ruleTriggerEvent);
    sourceValue = this.conditionsUtil.formatValue(sourceValue, source.value);

    let value = this.getConditionValue(condition);
    value = this.conditionsUtil.formatValue(value, source.value);

    const isMet = workflowCondition.isMet(sourceValue, value);
    return isMet;
  }

  private getConditionSourceValue(source: RuleConditionValue, ruleTriggerEvent?: WorkflowTriggerEvent): any {
    if (ruleTriggerEvent?.payload?.getAttributeValueFn) {
      return ruleTriggerEvent.payload.getAttributeValueFn(source.value);
    }

    // const artifact = ruleTriggerEvent?.payload?.artifact;
    // if (ruleTriggerEvent?.definition.type === 'ATTRIBUTE_VALUE_CHANGE') {
    //   return ruleTriggerEvent.payload;
    // }
    // if (ruleTriggerEvent?.definition.type === 'WIDGET_LOAD') {
    //   const artifact = ruleTriggerEvent.payload as NewArtifact;
    //   return artifact.attributes[source.attributeId].value;
    // }
    // console.log('to log: do some magic and get the value...');
    return null;
  }

  private getConditionValue(condition: RuleCondition): any {
    if (condition.destination.isDynamic) {
      return 'dynamic value';
    }
    return condition.destination.value;
  }
}
