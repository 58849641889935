<div class="list">
  <div *ngIf="!isLayoutMode && m" class="list_top">
    <ng-container *ngIf="!m.isFirstLoad; else loading">
      <div class="grid list_top__filters">
        <div class="col-6">
          <label for="listType" translate> List type </label>
          <p-dropdown
            [(ngModel)]="widget.value.model.listType"
            [filter]="true"
            [options]="m.options.listTypes.list | transformArrayToSelectOptions"
            [showClear]="true"
            autoDisplayFirst="false"
            filterBy="label"
            placeholder="Select an entity type"
            id="listType"
            (onChange)="helper.onListTypeChange($event.value)"
          >
          </p-dropdown>
        </div>

        <div class="col-6">
          <label for="loadMode" translate> Load mode </label>
          <p-dropdown
            [(ngModel)]="m.settings.loadMode"
            [filter]="true"
            [options]="m.options.loadModes"
            [showClear]="true"
            autoDisplayFirst="false"
            filterBy="label"
            placeholder="Where to load artifacts"
            id="loadMode"
            (onChange)="onLoadModeChange()"
          >
            <ng-template pTemplate="selectedItem" let-selected>{{ selected.label | translate }}</ng-template>
            <ng-template let-option pTemplate="item">{{ option.label | translate }}</ng-template>
          </p-dropdown>
        </div>

        <ng-container *ngIf="(widget.value.model.listType | isArtifactListType) && (m.settings.loadMode | isLoadByArtifactType)">
          <div class="col-6">
            <label for="application" translate> Application </label>
            <p-multiSelect
              [(ngModel)]="m.selected.applications"
              [options]="m.options.applications.list | transformArrayToSelectOptions : NAME_VALUE"
              [selectionLimit]="1"
              defaultLabel="Select application"
              optionLabel="label"
              id="application"
              (onChange)="s.setArtifactTypesByApplication($event.value)"
            >
            </p-multiSelect>
          </div>

          <div *ngIf="m.selected.applications?.length" class="col-6">
            <label for="artifactType" translate> Artifact Type </label>
            <p-multiSelect
              [(ngModel)]="m.selected.artifactTypes"
              [options]="m.options.artifactTypesByApplication.list | transformArrayToSelectOptions : NAME_VALUE"
              defaultLabel="Select Artifact Type(s)"
              optionLabel="label"
              id="artifactType"
              (onChange)="onArtifactTypeChange()"
            >
            </p-multiSelect>
          </div>
        </ng-container>

        <div *ngIf="m.settings.loadMode | isLoadByFolder" class="col-12">
          <span class="cursor-pointer" style="padding-top: 5px">
            <span *ngIf="!!m.settings.folderPath && m.settings.folderId" (click)="showPopupFolder()">Folder: {{ m.settings.folderPath }}</span>
            <div *ngIf="!m.settings.folderPath" class="col-6 p-pl-0">
              <button
                label="Select folder"
                type="button"
                pButton
                pRipple
                icon="pi pi-folder"
                class="p-button-primary p-button-outlined"
                (click)="showPopupFolder()"
              ></button>
            </div>
          </span>
        </div>

        <div *ngIf="m | shouldShowColumnOptions : applicableColumns : m.settings.folderPath" class="col-6">
          <label for="columns" translate> Columns</label>
          <p-multiSelect
            [ngModel]="m.selected.columns"
            [options]="applicableColumns"
            defaultLabel="Select columns"
            optionLabel="label"
            dataKey="key"
            id="columns"
            (onChange)="onSelectedColumnsChange($event.value)"
          >
            <ng-template let-option pTemplate="item">
              <span class="link-label">{{ option.label }}</span>
              <app-link-option-with-arrow [direction]="option.meta.linkDirection"></app-link-option-with-arrow>
            </ng-template>
          </p-multiSelect>
        </div>

        <div
          *ngIf="(m.settings.loadMode | shouldShowSaveToFolderIdOption) && (m | shouldShowColumnOptions : applicableColumns : m.settings.folderPath)"
          class="col-6"
        >
          <label for="urlFolderId" translate>Key for folder id param</label>
          <input
            [(ngModel)]="m.settings.urlKeys.emittingKeys.saveToFolderId"
            type="text"
            pInputText
            placeholder="url param for folder id"
            id="urlFolderId"
            (ngModelChange)="onFolderParamKeyChange()"
          />
        </div>
      </div>

      <app-list-widget-table-settings
        [listType]="widget.value.model.listType"
        [settings]="m.settings"
        [options]="m.options"
        [selected]="m.selected"
        [pages]="m.options.pages"
        [onGroupAttributeChangeCb]="onGroupAttributeChangeCb.bind(this)"
        (multiselectChange)="onMultiselectChange()"
      >
      </app-list-widget-table-settings>
    </ng-container>
  </div>

  <!-- {{log(m, (widget.value.model.listType | isArtifactListType),  m.selected.artifactTypes.length, isLayoutMode, m.settings.showTable)}} -->

  <ng-container *ngIf="isLayoutMode && m">
    <app-artifact-list-widget-table
      *ngIf="
        (widget.value.model.listType | isArtifactListType) &&
        ((m.settings.loadMode | isLoadByModule) || m.selected.artifactTypes.length) &&
        m.settings.showTable
      "
      [addButtonItems]="m.addButtonItems"
      [ids]="ids"
      [linkingPopupDtoMap]="m.linkingPopupDtoMap"
      [loadDataMethod]="helper.getLoadDataMethod()"
      [options]="m.options"
      [selected]="m.selected"
      [settings]="m.settings"
      [state]="m.state"
      [restrictions]="m.restrictions"
      [hash]="hash"
      [sortByAttribute]="true"
      [queryParams]="queryParams"
      (artifactSelect)="onArtifactSelect($event)"
      (artifactUnselect)="onArtifactUnselect()"
      (onLinkDialogOpen)="onLinkDialogOpen($event)"
    >
    </app-artifact-list-widget-table>

    <app-link-popup #linkDialog [applicationId]="ids.applicationId" [loadDataMethod]="helper.getLoadDataMethod()" [dtoMap]="m.linkingPopupDtoMap">
    </app-link-popup>

    <div *ngIf="(m.settings.loadMode | isLoadByFolder) && !m.settings.folderId">Please select which folder you want to load into list</div>
  </ng-container>
</div>

<app-folder-picker #folderPicker [setLayoutMode]="true" [showTenant]="true" [showConfirmation]="true" (changeFolder)="onFolderPathChange($event)"></app-folder-picker>

<ng-template #loading>
  <div>loading...</div>
</ng-template>
