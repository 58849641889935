import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { FadeAnimation, SlideInOutAnimation } from '@shared/animations/animations';
import { MenuItem, MenuWidgetModel } from '../../../../types/menu-widget.types';

@Component({
  selector: 'app-sub-menu-container',
  templateUrl: './sub-menu-container.component.html',
  styleUrls: ['./sub-menu-container.component.scss'],
  animations: [SlideInOutAnimation, FadeAnimation],
})
export class SubMenuContainerComponent {
  @Input() item: MenuItem;
  @Input() m: MenuWidgetModel;
  @Input() queryParams: Params;
  @Input() subMenuDomRectangle?: DOMRect;
}
