import { WorkflowConditionType } from '@workflows/types';

export class RuleCondition {
  source = new RuleConditionValue();
  destination = new RuleConditionValue();
  operationType = WorkflowConditionType.CONTAINS;

  constructor(condition?: Partial<RuleCondition>) {
    condition && Object.assign(this, condition);
  }

  toServer(): any {
    return {
      source: this.source.toServer(),
      destination: this.destination.toServer(),
      operationType: this.operationType,
    };
  }
}

export class RuleConditionValue {
  isDynamic = false;
  value: any = '';
  private _manual = false;

  constructor(conditionValue?: Partial<RuleConditionValue>) {
    conditionValue && Object.assign(this, conditionValue);
  }

  get manual(): boolean {
    return this._manual;
  }

  set manual(newManual: boolean) {
    if (newManual === true) this.isDynamic = true;
    this._manual = newManual;
  }

  toServer(): any {
    return {
      value: this.value,
      isDynamic: this.isDynamic,
    };
  }
}
