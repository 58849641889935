<div *ngIf="!!formatSettings && visible" [ngClass]="formatSettings.label | labelClass : labelBehaviour : true"
     class="readonly-attribute-wrapper">
  <span *ngIf="attr.isMandatory && formatSettings?.label.visible" class="mandatory-field"></span>
  <label [ngStyle]="formatSettings?.label?.styles" class="readonly-label">
    <span *ngIf="formatSettings.label.showIcon && attribute?.icon" [class]="attribute.icon" class="mr-1"></span>
    <span
      *ngIf="item?.attribute?.value && formatSettings?.label.visible && labelBehaviour !== labelBehaviourEnum.placeholder">
      {{ formatSettings.label.customLabel | getAttributeLabel : attribute.name : formatSettings.label }}
    </span>
  </label>
  <div class="readonly-attribute">
    <ng-container *ngIf="dataType.isUser">
      <div class="flex">
        <span *ngIf="formatSettings.value.showIcon && attribute?.icon" [class]="attribute.icon"
              class="mr-2 attribute-icon"></span>
        <app-form-readonly-field-user
          [ownerId]="ownerId"
          [item]="item"
          [attribute]="attribute"
          [formatSettings]="formatSettings"
          [model]="model"
          [selected]="selected"
        >
        </app-form-readonly-field-user>
      </div>
    </ng-container>

    <ng-container *ngIf="isRedirectIcon">
      <app-display-hyperlink-icon
        [attr]="attr"
        [attribute]="attribute"
        [ownerId]="attr.id"
      ></app-display-hyperlink-icon>
    </ng-container>

    <ng-container *ngIf="isBoolean">
      <app-display-at-boolean
        class="block-container full-size-container"
        [ownerId]="ownerId"
        [attributeId]="attribute.id"
        [artifact]="artifactDto"
        [dataType]="dataType"
        [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
        [doInternalUpdateOfValue]="true"
      >
      </app-display-at-boolean>
    </ng-container>

    <ng-container *ngIf="isEnum">
      <app-display-at-enum
        style="display: block; width: fit-content;"
        [ownerId]="ownerId"
        [attributeId]="attribute.id"
        [attribute]="attribute"
        [artifact]="artifactDto"
        [artifactDto]="artifactDto"
        [dataType]="dataType"
        [enumOptions]="dataType.values"
        [contentColumnFormat]="formatSettings?.value"
        [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
        [doInternalUpdateOfValue]="true"
      >
      </app-display-at-enum>
    </ng-container>

    <ng-container *ngIf="dataType.isDateTime || dataType.isDate">
      <app-display-at-datetime
        [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
        [dataType]="dataType"
        [attributeNew]="attr"
        [attrId]="attr.id"
        [formatStyles]="formatSettings?.value?.styles"
      ></app-display-at-datetime>
    </ng-container>

    <ng-container
      *ngIf="!dataType.isUser && !isEnum && !isBoolean && !isRedirectIcon && !dataType.isDateTime && !dataType.isDate">
      <div [ngClass]="{'flex': !dataType.isEnum}">
        <span *ngIf="formatSettings.value.showIcon && attribute?.icon && !dataType.isEnum" [class]="attribute.icon"
              class="mr-2 attribute-icon"></span>
        <span *ngIf="item?.attribute?.value?.value" class="prefix"> {{ formatSettings.value.styles.prefix }} </span>
        <div
          *ngIf="!dataType.isHtml; else htmlEditor"
          [innerHTML]="item.attribute?.value | getAttributeReadonlyValue : attributes : dataTypes : formatSettings.value.showIcon
                       | useDecimalPlaces : formatSettings.value.styles.minDecimalPlaces : formatSettings.value.styles.maxDecimalPlaces
                       | replaceRuntimeVariables : artifactDto | async"
          [ngStyle]="formatSettings?.value?.styles"
          class="readonly-value"
        ></div>

        <ng-template #htmlEditor>
          <div
            [innerHTML]="item?.attribute?.value | getAttributeReadonlyValue : attributes : dataTypes | replaceRuntimeVariables : artifactDto | async | safeHtml"
            class="mce-content-body mce-content-readonly"
          ></div>
        </ng-template>

        <span *ngIf="item?.attribute?.value?.value" class="suffix"> {{ formatSettings.value.styles.suffix }} </span>
      </div>
    </ng-container>
  </div>
</div>

<app-display-at-overlay [ownerId]="attr.id"></app-display-at-overlay>
