import { BorderStyles, BorderStylesEnum } from '../../shared/types/style.types';

export class SidebarModalButtonBorderStyles extends BorderStyles {
  backgroundColor = 'transparent';
  borderRightStyle = BorderStylesEnum.none;
  borderTopStyle = BorderStylesEnum.none;
  borderLeftStyle = BorderStylesEnum.none;
  borderBottomStyle = BorderStylesEnum.none;
  borderTopLeftRadius = '0px';
  borderBottomRightRadius = '0px';
  borderBottomLeftRadius = '0px';
  borderTopRightRadius = '0px';

  constructor(styles?: Partial<SidebarModalButtonBorderStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}

export class SidebarModalButtonStyles {
  marginTop = '0px';
  marginBottom = '0px';
  marginLeft = '1px';
  marginRight = '1px';
  paddingTop = '0.85rem';
  paddingBottom = '0.85rem';
  paddingLeft = '0.85rem';
  paddingRight = '0.85rem';

  constructor(styles?: Partial<SidebarModalButtonStyles>) {
    styles && Object.assign(this, styles);
  }
}
