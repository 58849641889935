import { MetaListResponseDto } from '@api/models/meta-list-response-dto';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

export interface NewCachedSubjectLocalStorageMeta {
  localStorageService: LocalStorageService;
  key: string;
  stateKey?: string;
  storeOnlyValue?: boolean;
}

export interface NewCachedSubjectBaseArguments<T> {
  callUpdateOnInit?: boolean;
  onUpdate?: (entity: T | Array<T> | null) => void;
  localStorageMeta?: NewCachedSubjectLocalStorageMeta;
  noUpdateTimeMs?: number;
  initialValue?: T | Array<T>;
}

export interface NewCachedSubjectGeneralArguments<T> extends NewCachedSubjectBaseArguments<T> {
  updateFn: () => Observable<T>;
}

export interface NewCachedSubjectLocalStorageData<T> {
  lastUpdateTime: number;
  lastValue: T | null;
}

export interface NewCachedSubjectUpdateFunctions<T> {
  get: (id: string) => Observable<T>;
  list: (filter?: string) => Observable<Array<T>>;
}

export interface CacheListResponseDto<T> {
  data: Array<T>;
  meta: MetaListResponseDto;
}

export interface CacheListArgs {
  filter?: string;
}

export interface IdRecord {
  id: string;
}

export interface DbEntityCachedDataArguments<T> extends NewCachedSubjectBaseArguments<T> {
  updateFns: NewCachedSubjectUpdateFunctions<T>;
  initialValue?: Array<T>;
  itemsCountLimit?: number;
  additionalKeysForIndexing?: Array<string>;
}

export class CachedUserMeta {
  isSystemAdmin = false;
  isTenantAdmin = false;
  isApplicationAdmin = false;
}
