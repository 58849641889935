import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextareaModule, ArtifactAttributeFormFieldLabelWithIconComponent, EditorComponent, ChipsModule],
  selector: 'app-artifact-attribute-form-field-text',
  templateUrl: './artifact-attribute-form-field-text.component.html',
  styleUrls: ['./artifact-attribute-form-field-text.component.scss'],
})
export class ArtifactAttributeFormFieldTextComponent implements OnInit {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: () => Promise<void>;
  @Input() onBlur: () => void;
  @Input() folderId: string;

  settings: any;
  labelBehaviourEnum = LabelBehaviourEnum;
  uniqueId = '';

  constructor(private readonly tinyMceHelper: TinyMceHelper) {}

  ngOnInit(): void {
    this.uniqueId = SharedMethods.getUniqueId();
    if (this.dataType.isSimple && this.dataType.isHtml && !this.attribute.multipleValues) {
      this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
      this.settings.inline = false;
      this.settings.height = '400px';
      this.labelBehaviour !== this.labelBehaviourEnum.float && !this.attr.value && (this.settings.placeholder = this.placeholder);
    }
  }
}
