import { Component, Input, OnInit } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewDataType } from '@shared/types/data-type.types';
import { ClientData } from '@shared/types/local-storage.types';
import { ElvisUtil } from '@shared/utils/elvis.util';

@Component({
  selector: 'app-bounded-range-details',
  templateUrl: './bounded-range-details.component.html',
  styleUrls: ['./bounded-range-details.component.scss'],
})
export class BoundedRangeDetailsComponent implements OnInit {
  @Input() dataType: NewDataType;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;

  constructor(public readonly elvisUtil: ElvisUtil, private readonly cache: NewCacheService) {}

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  isNumRange(): boolean {
    return this.dataType.baseDataType === BaseDataType.integer || this.dataType.baseDataType === BaseDataType.decimal;
  }

  isDateRange(): boolean {
    return this.dataType.baseDataType === BaseDataType.date;
  }

  isTimeRange(): boolean {
    return this.dataType.baseDataType === BaseDataType.time;
  }

  isDatetimeRange(): boolean {
    return this.dataType.baseDataType === BaseDataType.dateTime;
  }

  checkNumFormat(event: string, id: string, targetObject: any, key: string): void {
    this.elvisUtil.checkNumFormat(
      event,
      id,
      targetObject,
      key,
      this.dataType.baseDataType === BaseDataType.integer,
      this.dataType.baseDataType === BaseDataType.decimal,
    );
  }
}
