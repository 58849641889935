import { Component, Input } from '@angular/core';
import {
  Background,
  Border,
  BoxShadow,
  ContainerClass,
  Dimension,
  Margin,
  Overflow,
  Padding,
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundTypeEnum, BorderStylesEnum, ShadowStylesEnum, StyleOptions } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-page-element-styler',
  templateUrl: './page-element-styler.component.html',
  styleUrls: ['./page-element-styler.component.scss'],
})
export class PageElementStylerComponent {
  @Input() styles: ContainerClass & Dimension & Border & BoxShadow & Background & Margin & Padding & Overflow;
  @Input() element: HTMLElement;
  @Input() isContainerClassEditable = false;
  @Input() isMarginEditable = true;
  @Input() showHeightSettings = false;

  readonly backgroundType: typeof BackgroundTypeEnum = BackgroundTypeEnum;
  readonly styleOptions: StyleOptions = new StyleOptions();

  resetBorderAndShadowStylesIfDisabled(event: any): void {
    if (!event.checked) {
      this.styles.borderLeftStyle = BorderStylesEnum.none;
      this.styles.borderRightStyle = BorderStylesEnum.none;
      this.styles.borderTopStyle = BorderStylesEnum.none;
      this.styles.borderBottomStyle = BorderStylesEnum.none;
      this.styles.boxShadow = ShadowStylesEnum.none;
      this.styles.borderTopLeftRadius = '0px';
      this.styles.borderTopRightRadius = '0px';
      this.styles.borderBottomLeftRadius = '0px';
      this.styles.borderBottomRightRadius = '0px';
      this.styles.borderTopColor = undefined;
      this.styles.borderBottomColor = undefined;
      this.styles.borderLeftColor = undefined;
      this.styles.borderRightColor = undefined;
    }
  }

  onBgTypeChange(): void {
    delete this.styles.background;
    this.styles.backgroundImage = '';
    this.styles.backgroundColor = '';
  }
}
