import { Component, Input } from '@angular/core';
import { MenuItem, MenuWidgetModel } from '../../../../types/menu-widget.types';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @Input() m: MenuWidgetModel;
  @Input() item: MenuItem;
  @Input() isItemHorizontallyOrdered: boolean;
}
