import { Pipe, PipeTransform } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

@Pipe({ name: 'isWidgetOfType' })
export class IsWidgetOfTypePipe implements PipeTransform {
  transform(widget: BlockPartWidget | null, type: WidgetType): boolean {
    return !!widget && widget.code === type;
  }
}

@Pipe({ name: 'underscoreToDash' })
export class UnderscoreToDashPipe implements PipeTransform {
  transform(val: string): string {
    return val ? val.replace(/_/g, '-') : val;
  }
}
