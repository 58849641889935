import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { TextWidgetComponent } from './text-widget.component';

@NgModule({
  declarations: [TextWidgetComponent],
  imports: [CommonModule, FormsModule, EditorModule, ElvisSharedModule, ReplaceRuntimeVariablesModule],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
  exports: [TextWidgetComponent],
})
export class TextWidgetModule {}
