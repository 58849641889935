<p-card>
  <div class="sso-providers-wrapper mb-3">
    <div>
      <h4 class="m-0">{{ 'Available SSO providers' | translate }}</h4>
    </div>
    <div>
      <button *ngIf="m.addNewMode === false" pButton pRipple type="button" label="{{ 'Add' | translate }}" class="mr-2"
              (click)="m.addNewMode = true"></button>
      <button
          *ngIf="m.addNewMode === true"
          pButton
          pRipple
          type="button"
          label="{{ 'Cancel' | translate }}"
          class="mr-2 p-button p-button-warning"
          (click)="m.addNewMode = false"
      ></button>
    </div>
  </div>

  <!-- *********************************************************************** -->

  <div *ngIf="m.addNewMode === true" class="mb-5 pt-2">
    <p-divider></p-divider>
    <span class="dynamic-label mt-4">
      <p-dropdown id="selectSsoService" [autoDisplayFirst]="false" [options]="m.ssoServices"
                  [(ngModel)]="m.newSsoService.ssoService"></p-dropdown>
      <label class="dynamic-label-text" for="selectSsoService" translate>Select a SSO service</label>
    </span>
    <ng-container
        *ngTemplateOutlet="ssoServiceFields; context: { data: { index: 'new', refSsoService: m.newSsoService.data, ssoService: m.newSsoService.ssoService } }"></ng-container>
    <button pButton pRipple type="button" label="{{ 'Confirm' | translate }}" class="mb-4"
            (click)="prependNewSsoService()"></button>
    <p-divider></p-divider>
  </div>

  <!-- *********************************************************************** -->

  <ng-container *ngIf="tenant && (filterNullSsoServices() | keyvalue).length > 0; else emptySsoServices">
    <p-accordion>
      <ng-container *ngFor="let ssoService of filterNullSsoServices() | keyvalue; let i = index">
        <p-accordionTab *ngIf="ssoService.value !== null">
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span class="vertical-align-middle">{{ ssoService.key }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <ng-container
                *ngTemplateOutlet="ssoServiceFields; context: { data: { index: i, refSsoService: ssoService.value, ssoService: ssoService.key } }"></ng-container>
            <button
                pButton
                pRipple
                label="{{ 'Delete' | translate }}"
                class="p-button p-button-danger"
                (click)="openDialogDeleteSsoService(ssoService.key)"
            ></button>
          </ng-template>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </ng-container>
  <ng-template #emptySsoServices>
    <p class="m-0">{{ 'No SSO services are configured' | translate }}</p>
  </ng-template>
</p-card>

<!-- *********************************************************************** -->

<ng-template #ssoServiceFields let-data="data">
  <ng-container *ngIf="data.ssoService === SupportedSsoServices.google">
    <span class="dynamic-label mt-2">
      <input type="text" id="clientId_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.clientId"/>
      <label class="dynamic-label-text" for="clientId_{{ data.index }}" translate>SSO client ID</label>
    </span>
    <!-- TODO: workaround, because p-password doesn't support autocomplete passing to HTML input field -->
    <form class="mb-4" autocomplete="off">
    <span class="dynamic-label">
      <p-password
          name="clientSecret_{{ data.index }}"
          id="clientSecret_{{ data.index }}"
          [(ngModel)]="data.refSsoService.clientSecret"
          [toggleMask]="true"
          [feedback]="false"
      ></p-password>
      <label class="dynamic-label-text" for="clientSecret_{{ data.index }}" translate>SSO client secret</label>
    </span>
    </form>
  </ng-container>
  <ng-container *ngIf="data.ssoService === SupportedSsoServices.openid">
    <span class="dynamic-label mt-2">
      <input type="text" id="clientId_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.clientId"/>
      <label class="dynamic-label-text" for="clientId_{{ data.index }}" translate>OpenID client ID</label>
    </span>
    <span class="dynamic-label mt-2">
      <input type="text" id="issuer_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.issuer"/>
      <label class="dynamic-label-text" for="issuer_{{ data.index }}" translate>OpenID issuer</label>
    </span>
    <span class="dynamic-label mt-2">
      <input type="text" id="authorizeUrl_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.authorizeUrl"/>
      <label class="dynamic-label-text" for="authorizeUrl_{{ data.index }}" translate>OpenID authorization URL</label>
    </span>
    <span class="dynamic-label mt-2">
      <input type="text" id="tokenUrl_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.tokenUrl"/>
      <label class="dynamic-label-text" for="tokenUrl_{{ data.index }}" translate>OpenID token URL</label>
    </span>
    <span class="dynamic-label mt-2">
      <input type="text" id="revokeTokenUrl_{{ data.index }}" pInputText [(ngModel)]="data.refSsoService.revokeTokenUrl"/>
      <label class="dynamic-label-text" for="revokeTokenUrl_{{ data.index }}" translate>OpenID revoke token URL</label>
    </span>
  </ng-container>
</ng-template>
