import { Component, Input } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';

@Component({
  selector: 'app-link-option-with-arrow',
  templateUrl: './link-option-with-arrow.component.html',
  styleUrls: ['./link-option-with-arrow.component.scss'],
})
export class LinkOptionWithArrowComponent {
  @Input() label: string;
  @Input() direction: LinkDirection | 'BOTH';
}
