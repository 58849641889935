import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { EnumFilterValue } from '@widgets/shared/components/artifact-filters/components/enum-filter/types/enum-filter.value';
import { ArtifactFilter } from '../../../types/artifact-filter.types';
import { NewDataType } from '@shared/types/data-type.types';

@Injectable({
  providedIn: 'root',
})
export class EnumFilterService {
  getQuery({ value: filter, attributeId }: ArtifactFilter): any {
    const isRegularFilter = this.isRegularFilter(filter.matchMode);

    if (!filter.selectedEnumValues?.length && isRegularFilter) {
      return null;
    }

    const attributeKey = `attributes.${attributeId}.value`;
    const matchModeKey = this.getMatchModeKey(filter);

    const value = isRegularFilter ? { [matchModeKey]: filter.selectedEnumValues } : matchModeKey;

    return {
      $or: [{ [attributeKey]: value }],
    };
  }

  getFilterValueFromString(str: string, dataType: NewDataType): EnumFilterValue | null {
    if (!dataType.values) return null;
    const matchMode = CoreListFilterEnum.in;
    const selectedEnumValues: string[] = str.split(',');

    if (!selectedEnumValues.length || !matchMode) return null;

    return { matchMode, selectedEnumValues } as EnumFilterValue;
  }

  private getMatchModeKey({ matchMode }: EnumFilterValue): '$in' | '$nin' {
    return {
      [CoreListFilterEnum.in]: '$in',
      [CoreListFilterEnum.notIn]: '$nin',
      [CoreListFilterEnum.isEmpty]: { $eq: null },
      [CoreListFilterEnum.isNotEmpty]: { $not: { $eq: null } },
    }[matchMode] as any;
  }

  private isRegularFilter(matchMode: CoreListFilterEnum): boolean {
    const { isEmpty, isNotEmpty } = CoreListFilterEnum;
    return matchMode !== isNotEmpty && matchMode !== isEmpty;
  }
}
