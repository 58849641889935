import { Component, Input } from '@angular/core';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-artifact-widget-password-field',
  templateUrl: './artifact-widget-password-field.component.html',
  styleUrls: ['./artifact-widget-password-field.component.scss'],
})
export class ArtifactWidgetPasswordFieldComponent {
  @Input() item: ArtifactWidgetFormItem;
  @Input() attr: NewClientAttribute;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() index: number;
  @Input() onChangeCb: () => void;
  @Input() onBlurCb: () => void;
  @Input() changeFormFocus: () => void;

  labelBehaviourEnum = LabelBehaviourEnum;
}
