<ng-container *ngIf="!groupItem.isDefaultGroup">
  <tr (click)="toggleGroup()" class="row-group">
    <td [attr.colspan]="settings.editableRow ? columns.length + 2 : columns.length + 1">
      <h5 class="row-group-header">
        <button [icon]="groupItem.metaData?.toggled ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                class="p-button-text p-button-rounded p-button-plain mr-2" pButton pRipple type="button">
        </button>
        <span class="row-group-header__value">{{ groupItem.metaData?.header }}</span>
        <p-badge [value]="groupItem.artifacts.length.toString()" size="medium"></p-badge>
      </h5>
    </td>
  </tr>
</ng-container>

<ng-container *ngFor="let artifact of groupItem.artifacts; let index = index">
  <tr *ngIf="groupItem.isDefaultGroup || groupItem.metaData?.toggled" [pEditableRow]="artifact" [pSelectableRow]="artifact" cdkDrag class="item-draggable">
    <div *cdkDragPlaceholder
         [ngClass]="{'item-placeholder__thinner': !settings.editableRow, 'item-placeholder__thicker': settings.editableRow}"
         class="item-placeholder">
    </div>
    <td class="td-center-content">
      <span cdkDragHandle class="bi bi-arrows-move"></span>
    </td>
    <td *ngFor="let col of columns">
      <div class="table-cell-content">
        <!-- TODO check and remove this commented code -->
        <!-- <span [ngClass]="{'p-text-truncate': settings.textWrap === 'nowrap', 'p-text-wrap': settings.textWrap === 'wrap'}" class="p-column-title">
            {{col.label}}
        </span> -->

        <p-cellEditor *ngIf="col.key | isInStringArray : m.columns.editableKeys; else simple" class="full-width-container">
          <ng-template pTemplate="input">
            <ng-container [ngTemplateOutlet]="template"
                          [ngTemplateOutletContext]="{col: col, artifact: artifact, index: index, editMode: true, isMandatory: isMandatoryField(artifact, col.key)}">
            </ng-container>
          </ng-template>

          <ng-template pTemplate="output">
            <ng-container [ngTemplateOutlet]="template"
                          [ngTemplateOutletContext]="{col: col, artifact: artifact, index: index}">
            </ng-container>
          </ng-template>
        </p-cellEditor>

        <ng-template #simple>
          <ng-container [ngTemplateOutlet]="template"
                        [ngTemplateOutletContext]="{col: col, artifact: artifact, index: index, simple: true}">
          </ng-container>
        </ng-template>
      </div>
    </td>
    <td *ngIf="settings.editableRow" class="utility-buttons">
      <button (click)="helper.onRowDeleteClick(artifact)"
              *ngIf="!editing"
              class="p-button-rounded p-button-text p-button-danger"
              icon="pi pi-trash"
              pButton
              pRipple
              type="button">
      </button>
      <button (click)="onRowEditInit(artifact)"
              *ngIf="!editing"
              [id]="'editingButton'+index"
              class="p-button-rounded p-button-text"
              icon="pi pi-pencil"
              pButton
              pInitEditableRow
              pRipple type="button">
      </button>
      <button (click)="onNavigateToArtifact(artifact)"
              *ngIf="!editing"
              class="p-button-rounded p-button-text"
              icon="pi pi-eye"
              pButton
              pRipple type="button"
              [pTooltip]="'Edit complete entry' | translate">
      </button>
      <button (click)="onRowEditSave(artifact, index)"
              *ngIf="editing"
              class="p-button-rounded p-button-text p-button-success"
              icon="pi pi-check"
              pButton
              pRipple
              pSaveEditableRow
              type="button">
      </button>
      <button (click)="helper.onRowEditCancel(artifact, index)"
              *ngIf="editing"
              class="p-button-rounded p-button-text p-button-danger"
              icon="pi pi-times"
              pButton
              pCancelEditableRow pRipple
              type="button">
      </button>
    </td>
  </tr>
</ng-container>
