import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsUser } from '@shared/methods/data-type.methods';
import { GetDataTypeByAttributeId } from '@shared/methods/attribute.methods';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Pipe({ name: 'isUserColumn' })
export class IsUserColumnPipe implements PipeTransform {
  transform(attributeId: string, options: ListWidgetOptions): boolean {
    return IsUser(GetDataTypeByAttributeId(attributeId, options.attributes.listMap, options.dataTypes.listMap)?.baseDataType as BaseDataType);
  }
}
