<div class="col-12">
  <div class="header">
    <h4 translate>Artifact Visual Representation</h4>
  </div>
  <p-accordion>
    <ng-container *ngFor="let avrTypeMapper of m.artifactTypeAvrMapper | keyvalue">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">{{ avrServices[avrTypeMapper.key].readableName }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <p-table class="avrMapper-table" [columns]="m.avrColumns" [value]="m.artifactTypeAvrMapper[avrTypeMapper.key].mappableFields | keyvalue">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let column of columns" [ngClass]="{ 'min-width-col': column.meta?.minWidthCol === true }">
                  {{ column.label | translate }}
                </th>
              </tr>
              <ng-container *ngIf="m.injectorsForAvrMapper[avrTypeMapper.key]">
                <ng-container *ngComponentOutlet="avrNonMappableFields[avrTypeMapper.key]; injector: m.injectorsForAvrMapper[avrTypeMapper.key]"></ng-container>
              </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-avrMapper>
              <tr>
                <td>{{ avrMapper.value.readableName }}</td>
                <td>
                  <p-triStateCheckbox [ngModel]="!avrMapper.value.isOptional" disabled="true"></p-triStateCheckbox>
                </td>
                <td>
                  <span>
                    <i class="pi pi-info-circle ml-1" [pTooltip]="avrMapper.value.description" tooltipPosition="top" [escape]="false"></i>
                    <p-dropdown
                      [options]="filterAttributes()"
                      [(ngModel)]="avrMapper.value.value"
                      [showClear]="avrMapper.value.isOptional"
                      (onClear)="avrMapper.value.value = ''"
                      optionValue="value.id"
                      [placeholder]="'Choose an attribute' | translate"
                      optionDisabled="none"
                      filter="true"
                      ngClass="full-width-container"
                      appendTo="body"
                    >
                      <ng-template pTemplate="selectedItem" let-selected>
                        {{ selected | getAttributeLabelWithApplication : model.options.applications }}
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        {{ option | getAttributeLabelWithApplication : model.options.applications }}
                      </ng-template>
                    </p-dropdown>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage"></ng-template>
          </p-table>
        </ng-template>
      </p-accordionTab>
    </ng-container>
    <ng-container *ngIf="(m.artifactTypeAvrMapper | keyvalue).length === 0">
      <p translate>No selected Artifact Visual Representation</p>
    </ng-container>
  </p-accordion>
</div>

<ng-template></ng-template>
