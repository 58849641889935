import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSsoService } from '@public/pages/login/sso/base.sso.service';
import { GoogleSsoService } from '@public/pages/login/sso/google.sso.service';
import { OpenIdSsoService } from '@public/pages/login/sso/openid.sso.service';
import { Constants } from '@shared/constants/constants';
import { getSystemPublicPagesFromMasterState } from '@shared/methods/system-public.pages.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { StateKey } from '@shared/types/local-storage.types';
import { NewSystemUser } from '@shared/types/user.types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [GoogleSsoService, OpenIdSsoService],
})
export class LoginComponent implements OnInit {
  @ViewChild('pass') passEl: ElementRef;

  username = '';
  inProgress = false;
  ssoServices: BaseSsoService[] = [];
  authChecked = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private blockUiService: BlockUiService,
    private localStorageService: LocalStorageService,
    googleSso: GoogleSsoService,
    openIdSso: OpenIdSsoService,
  ) {
    this.ssoServices.push(googleSso);
    this.ssoServices.push(openIdSso);
    for (const ssoService of this.ssoServices) {
      ssoService.onInit(this.redirectIfLogged.bind(this));
    }
  }

  async ngOnInit(): Promise<void> {
    const systemPages = getSystemPublicPagesFromMasterState();
    if (!systemPages)
      await this.authorizationService.anonymousLogin().then(res => {
        if (res?.id) window.location.reload();
        else this.authChecked = true;
      });
    else if (!systemPages.loginPageId) this.authChecked = true;
    else if (systemPages.loginPageId) window.location.reload();
  }

  async login(): Promise<void> {
    const password = this.passEl.nativeElement.value;
    if (!password) return;

    try {
      this.inProgress = true;
      this.blockUiService.blockUi();

      await this.authorizationService.login(this.username, password).then(res => {
        if (res && typeof res === 'object') {
          const { user } = res;

          this.authorizationService.resetUserMeta();
          setTimeout(() => {
            if (typeof user === 'object') {
              if (user?.tenant) this.router.navigateByUrl(this.route.snapshot.queryParams['redirectUrl'] || '/application-selection');
              else if (user.isSystemAdmin) this.router.navigateByUrl('/system');
            }
          });
        }
      });
    } finally {
      this.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  async recoverPassword(): Promise<void> {
    await this.router.navigateByUrl('/recover-password');
  }

  private async redirectIfLogged(): Promise<void> {
    if (this.authorizationService.isLoggedIn) {
      const user: NewSystemUser = this.localStorageService.getFromState(StateKey.session, Constants.user);

      if (user?.tenant?.id) await this.router.navigateByUrl('/application-selection');
      else if (user?.isSystemAdmin) await this.router.navigateByUrl('/system');
    }
  }
}
