<div class="form_wrapper">
  <div class="form" *ngIf="attribute && applications">
    <div class="dynamic-label">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label">
            <input type="text" id="name" pInputText [(ngModel)]="attribute.name" />
            <label class="dynamic-label-text" for="name" translate>Name</label>
          </span>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label">
            <p-dropdown
              [(ngModel)]="attribute.applicationId"
              [autoDisplayFirst]="false"
              [filter]="true"
              [options]="applications | transformArrayToSelectOptions : 'name' : 'id'"
              id="application"
            >
            </p-dropdown>
            <label class="dynamic-label-text" for="application" translate>Application</label>
          </span>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label">
            <input [(ngModel)]="attribute.alias" id="attributeAlias" pInputText type="text" />
            <label class="dynamic-label-text" for="attributeAlias">{{ ALIAS_LABEL | translate }}</label>
          </span>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label">
            <app-icon-picker [icon]="attribute.icon" label="Icon" labelClass="dynamic-label-text" (onChangeIcon)="doChangeIcon($event)"></app-icon-picker>
          </span>
        </div>

        <div class="col-12 md:col-6 lg:col-3" *ngIf="attribute.id">
          <span class="dynamic-label">
            <button
              (click)="accessOverlay.show($event)"
              class="p-button"
              icon="pi pi-users"
              [label]="'Access rights' | translate"
              pButton
              type="button"
            ></button>
          </span>
        </div>
      </div>
    </div>

    <span class="dynamic-label">
      <textarea id="description" pInputTextarea rows="5" autoResize="autoResize" [(ngModel)]="attribute.description"></textarea>
      <label class="dynamic-label-text" for="description" translate>Description</label>
    </span>

    <span class="dynamic-label">
      <app-type-system-element-dropdown
        id="dataType"
        (onSelect)="onDataTypeChange()"
        [(ngModel)]="attribute.dataTypeId"
        [applications]="applications"
        [disabled]="!!attribute.id"
        [placeholder]="'Choose a data type'"
        [typeSystemElements]="dataTypes.list">
      </app-type-system-element-dropdown>
    </span>

    <span class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton
          name="numberOfValues"
          [value]="false"
          [(ngModel)]="attribute.multipleValues"
          [inputId]="'numberOfValue1'"
          [disabled]="!!attribute.id || isBoolean || isHtml || isCounter"
        >
        </p-radioButton>
        <label [for]="'numberOfValue1'" translate>One</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton
          name="numberOfValues"
          [value]="true"
          [(ngModel)]="attribute.multipleValues"
          [inputId]="'numberOfValue2'"
          [disabled]="!!attribute.id || isBoolean || isHtml || isCounter"
        >
        </p-radioButton>
        <label [for]="'numberOfValue2'" translate>Multiple</label>
      </span>
      <label class="dynamic-label-text" translate>Number of values</label>
    </span>

    <span *ngIf="isCounter" class="dynamic-label">
      <label for="counterNextValue">Counter next value</label>
      <p-inputNumber inputId="counterNextValue" [(ngModel)]="attribute.counterNextValue"> </p-inputNumber>
      <span> </span>
    </span>

    <span *ngIf="isCounter" class="dynamic-label">
      <button
        class="p-button-text"
        icon="pi pi-info"
        pButton
        pRipple
        type="button"
        [pTooltip]="
          'Available tokens:\n' +
          '${value, format: arabic | roman | hex, minLength: number, offset: number, multiplyBy: number}\n' +
          '${year, format: arabic | roman | hex, minLength: number}\n' +
          '${month, format: arabic | roman | hex | month, minLength: number}\n' +
          '${day, format: arabic | roman | hex, minLength: number}\n' +
          '${weekDay, format: arabic | roman | week | hex, minLength: number}\n' +
          '${hour, format: arabic | roman | hex, minLength: number}\n' +
          '${minutes, format: arabic | roman | hex, minLength: number}\n' +
          '${random, format: arabic | roman | hex, min: number, max: number, minLength: number}\n' +
          '\n' +
          'Example: ISSUE-${month}/${value, minLength: 4, format: arabic} will generate ISSUE-04/0173'
        "
      ></button>
      <input type="text" id="counterFormat" pInputText required [(ngModel)]="attribute.counterFormat" />
      <label class="dynamic-label-text" for="counterFormat">Counter format</label>
    </span>

    <span class="dynamic-label">
      <input type="text" id="uri" pInputText [(ngModel)]="attribute.uri" />
      <label class="dynamic-label-text" for="uri">URI</label>
    </span>

    <span class="dynamic-label dynamic-radiobutton">
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="isPrivate" [value]="false" [(ngModel)]="attribute.private" [inputId]="'isPrivateRadio1'" [disabled]="!!attribute.id">
        </p-radioButton>
        <label [for]="'isPrivateRadio1'" translate>Public</label>
      </span>
      <span class="dynamic-radiobutton-box">
        <p-radioButton name="isPrivate" [value]="true" [(ngModel)]="attribute.private" [inputId]="'isPrivateRadio2'" [disabled]="!!attribute.id">
        </p-radioButton>
        <label [for]="'isPrivateRadio2'" translate>Private</label>
      </span>
      <label class="dynamic-label-text" translate>Private attribute</label>
    </span>
  </div>
</div>

<p-overlayPanel #accessOverlay [style]="{ 'max-width': '380px', width: '361px' }" appendTo="body">
  <ng-template pTemplate>
    <app-acl [attributeId]="attribute.id" (updateAcl)="accessOverlay.hide()"></app-acl>
  </ng-template>
</p-overlayPanel>
