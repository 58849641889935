<label *ngIf="label" class="{{labelClass}}" for="templateIcon" translate>{{label}}</label>
<p-dropdown [(ngModel)]="icon"
            [autoDisplayFirst]="false"
            [filter]="true"
            [virtualScrollItemSize]="20"
            [options]="iconOptions"
            [showClear]="true"
            [style]="{'width':'100%'}"
            [virtualScroll]="true"
            [disabled]="isDisabled"
            (ngModelChange)="onChange()"
            appendTo="body"
            id="templateIcon">
    <ng-template let-selectedIcon pTemplate="selectedItem">
        <div *ngIf="icon" class="p-dropdown-item text-left">
            <i [class]="selectedIcon.value"></i>
            <label class="ml-2">{{selectedIcon.label}}</label>
        </div>
    </ng-template>
    <ng-template let-icon pTemplate="item">
        <div class="p-dropdown-items text-left">
            <i [class]="icon.value"></i>
            <label class="ml-2">{{icon.label}}</label>
        </div>
    </ng-template>
</p-dropdown>
