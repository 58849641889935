import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { AttributeActionHandlerService } from '../../shared';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export type SET_MANDATORY_OR_OPTIONAL_ACTION = WorkflowActionType.SET_MANDATORY | WorkflowActionType.SET_OPTIONAL;

export class WorkflowActionSetAttributeMandatory extends AbstractWorkflowAttributeBasedAction<CommonFeAction> {
  constructor(type: SET_MANDATORY_OR_OPTIONAL_ACTION, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, type, dto, actionAttributeHandler });
  }

  canBeExecuted(): boolean {
    return this.type === WorkflowActionType.SET_MANDATORY || this.type === WorkflowActionType.SET_OPTIONAL;
  }

  execute() {
    const mandatory = this.type === WorkflowActionType.SET_MANDATORY;
    this.actionAttributeHandler.notifySetAttributeMandatoryEvent({ artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, mandatory });
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId);
  }
}
