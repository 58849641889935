import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { LinkFilterTypes } from '@widgets/shared/components/artifact-filters/components/link-filter/types/link-filter.types';
import { cloneDeep } from 'lodash';

export class ArtifactFilter implements ArtifactFilterDto {
  name = 'Filter';
  type: ArtifactFilterType | null = null;
  dataType: NewDataType | null = null;
  attribute: NewAttribute | NewClientAttribute | DirectionalLinkType | null = null;
  value: any;
  systemAttributeCode: string;
  linkDirection: LinkDirection | null = null;

  static initial(): ArtifactFilter {
    return new ArtifactFilter();
  }

  static fromDtoAndOptions(dto: ArtifactFilterDto, options: ArtifactFilterOptions): ArtifactFilter {
    const filter = new ArtifactFilter();

    if (!dto.attributeId) {
      return filter;
    }

    filter.value = dto.value;
    filter.attribute = options.attributes.find(({ id }: NewAttribute | NewClientAttribute) => id === dto.attributeId) || null;
    filter.dataType = options.dataTypes.find(({ id }: NewDataType) => id === dto.dataTypeId) || null;
    filter.name = dto.name;
    filter.type = dto.type;
    filter.linkDirection = null;
    filter.systemAttributeCode = dto.systemAttributeCode;

    if (filter.type == ArtifactFilterType.link) {
      filter.value = new LinkFilterTypes(dto.value);
    }

    if (!filter.attribute && filter.type !== ArtifactFilterType.system) {
      const linkType =
        options.linkTypes?.find((linkType: DirectionalLinkType) => {
          return linkType.id === dto.attributeId && linkType.direction === dto.linkDirection;
        }) || null;
      const direction = linkType?.direction || null;

      if (!linkType) {
        console.error('LinkType not found', dto);

        return filter;
      }

      filter.attribute = Object.assign(cloneDeep(linkType), { direction });
      filter.linkDirection = direction;
      filter.dataType = {} as NewDataType;
    }

    return filter;
  }

  get dataTypeId(): string {
    return this.dataType?.id || '';
  }

  get attributeId(): string {
    return this.attribute?.id || '';
  }

  get isLinkFilter(): boolean {
    return this.type === ArtifactFilterType.link;
  }

  get isOutgoing(): boolean {
    return this.linkDirection === LinkDirection.outgoing;
  }

  get isIncoming(): boolean {
    return this.linkDirection === LinkDirection.incoming;
  }

  get isRecordAuthorFilter(): boolean {
    return [NonAttributeKeys.CREATED_BY_ID, NonAttributeKeys.UPDATED_BY_ID].includes(this.attributeId);
  }

  toServer(): ArtifactFilterDto {
    return {
      value: this.value,
      attributeId: this.attributeId,
      dataTypeId: this.dataTypeId,
      name: this.name,
      type: this.type,
      linkDirection: this.linkDirection,
      systemAttributeCode: this.systemAttributeCode,
    };
  }
}

export interface ArtifactFilterDto {
  name: string;
  type: ArtifactFilterType | null;
  value: any;
  attributeId: string;
  dataTypeId: string;
  linkDirection: LinkDirection | null;
  systemAttributeCode: string;
}

export interface ArtifactFilterOptions {
  attributes: (NewAttribute | NewClientAttribute)[];
  dataTypes: NewDataType[];
  linkTypes?: DirectionalLinkType[];
}

export enum ArtifactFilterType {
  link = 'link',
  system = 'system',
}
