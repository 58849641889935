import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { SliderModule } from 'primeng/slider';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, SliderModule, ArtifactAttributeFormFieldLabelWithIconComponent],
  selector: 'app-artifact-attribute-form-field-numeric-bounded',
  templateUrl: './artifact-attribute-form-field-numeric-bounded.component.html',
  styleUrls: ['./artifact-attribute-form-field-numeric-bounded.component.scss', '../../artifact-attribute-form-field.component.scss'],
})
export class ArtifactAttributeFormFieldNumericBoundedComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;

  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() label: string;
  @Input() onChange: () => Promise<void>;
  @Input() onBlur: () => void;

  labelBehaviourEnum = LabelBehaviourEnum;
}
