import { inject, Injectable } from '@angular/core';
import { ArtifactListResponseDto } from '@api/models/artifact-list-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtifactMovementService {
  private readonly tenantArtifactService: TenantArtifactService = inject(TenantArtifactService);

  async moveArtifact(artifact: NewArtifact, folderId: string): Promise<ArtifactResponseDto> {
    return lastValueFrom(
      this.tenantArtifactService.artifactControllerUpdate({
        body: { id: artifact.id, folderData: { parentId: folderId } },
        notify: false,
        resetSequence: false,
      }),
    );
  }

  async moveArtifacts(artifacts: NewArtifact[], folderId: string): Promise<ArtifactListResponseDto> {
    const body = {
      artifacts: artifacts
        .filter((artifact: NewArtifact) => artifact.folderData.parentId !== folderId)
        .map(({ id }: NewArtifact) => ({ id, folderData: { parentId: folderId } })),
    };

    return lastValueFrom(this.tenantArtifactService.artifactControllerUpdateBulk({ body, notify: false }));
  }
}
