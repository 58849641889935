import { Component, Input } from '@angular/core';
import { Background } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-bg-gradient-settings',
  templateUrl: './bg-gradient-settings.component.html',
  styleUrls: ['./bg-gradient-settings.component.scss'],
})
export class BgGradientSettingsComponent {
  @Input() styles: Background;
  @Input() gradientChangeCb: () => void;

  readonly backgroundType: typeof BackgroundTypeEnum = BackgroundTypeEnum;

  onGradientPaste(e: ClipboardEvent): void {
    const regex = /(?<=t\()(.*)(?=\);)/g;
    const regex2 = /(?<=t\()(.*)(?=\))/g;
    const data = e.clipboardData?.getData('text/plain');
    const parsed = data?.match(regex) || data?.match(regex2);

    if (parsed?.length) {
      setTimeout(() => (this.styles.background = `linear-gradient(${parsed[0]})`));
    }
  }
}
