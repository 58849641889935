import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: Set<string> = new Set();

  load(script: string, additionalAttributes: Record<string, string>, inline = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.scripts.has(script)) {
        resolve({ script: script, loaded: true, status: 'Already Loaded' });
      } else {
        const scriptElement = document.createElement('script');
        if (inline === true) {
          scriptElement.type = 'text/javascript';
          scriptElement.innerHTML = script;
        } else {
          scriptElement.src = script;
        }
        Object.entries(additionalAttributes).forEach(([attributeName, attributeValue]) => {
          scriptElement.setAttribute(attributeName, attributeValue);
        });
        scriptElement.onload = () => {
          this.scripts.add(script);
          resolve({ script: script, loaded: true, status: 'Loaded' });
        };
        scriptElement.onerror = (error: any) => reject({ script: script, loaded: false, status: 'Failed to Load', error });
        document.head.appendChild(scriptElement);
      }
    });
  }
}
