<ng-container *ngIf="file$ | async as file">
  <ng-container *ngIf="isDefaultLayout">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: file }"></ng-container>
  </ng-container>

  <img
    *ngIf="isPictureLayout || isPictureWithNameLayout || isPictureWithLinkLayout"
    [alt]="file.formatData.filename"
    [class.image]="file.isImage"
    [src]="file.isImage ? file.url : nonImageFileIcon"
    [title]="file.formatData.filename"
  />

  <h6 *ngIf="isPictureWithNameLayout" class="m-0">{{ file.formatData.filename }} picture name</h6>

  <ng-container *ngIf="isPictureWithLinkLayout">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: file }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #link let-file>
  <a appFileArtifactViewer [file]="file" class="link"> {{ file.formatData.filename }} [{{ file.formatData.size | getFileSize }}] </a>
</ng-template>
