import { Injectable } from '@angular/core';
import { AbstractAvrWidgetArtifactSourceService } from './abstract.artifact-source.service';
import { TenantArtifactService } from '@api/services';
import { AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';

const EXCEPT_FIELDS_ON_ARTIFACT_SOURCE = ['dataSource.artifactSource'];
const EXCEPT_FIELDS_ON_ARTIFACT_TYPE = [...EXCEPT_FIELDS_ON_ARTIFACT_SOURCE, ...['dataSource.artifactTypeId']];

@Injectable()
export class ListItemAvrWidgetArtifactSourceService extends AbstractAvrWidgetArtifactSourceService {
  constructor(protected readonly tenantArtifactService: TenantArtifactService) {
    super(AvrArtifactSources.listItem, tenantArtifactService);
  }

  async onArtifactSourceChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_SOURCE);
    this.model.settingsStep = 1;
  }

  async onArtifactTypeChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_TYPE);
    this.model.settingsStep = 2;
  }
}
