import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonFeTrigger } from '@workflows/types/triggers/common-fe-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerPageLoad extends AbstractWorkflowTrigger<CommonFeTrigger> {
  constructor() {
    super(CommonFeTrigger);
    this.type = RuleTriggerType.PAGE_LOAD;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    return this.type === workflowTrigger.type;
  }

  isValid(): boolean {
    return true;
  }
}
