<div class="grid layout-config">
  <div class="col-12 md:col-4 lg:col-3" *ngIf="isOnAdminRoute()">
    <h5 translate>Menu Type</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="menuMode" value="static" [(ngModel)]="app.uiConfig.menuMode" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="mode1"></p-radioButton>
        <label for="mode1" translate>Static</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="menuMode" value="overlay" [(ngModel)]="app.uiConfig.menuMode" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="mode2"></p-radioButton>
        <label for="mode2" translate>Overlay</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="menuMode" value="slim" [(ngModel)]="app.uiConfig.menuMode" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="mode3"></p-radioButton>
        <label for="mode3" translate>Slim</label>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>Color Scheme</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="colorScheme" [value]="colorScheme.dark" [(ngModel)]="app.uiConfig.colorScheme" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="theme1" (onClick)="configService.changeColorScheme(colorScheme.dark)"></p-radioButton>
        <label for="theme1" translate>Dark</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="colorScheme" [value]="colorScheme.dim" [(ngModel)]="app.uiConfig.colorScheme" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="theme2" (onClick)="configService.changeColorScheme(colorScheme.dim)"></p-radioButton>
        <label for="theme2" translate>Dim</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="colorScheme" [value]="colorScheme.light" [(ngModel)]="app.uiConfig.colorScheme" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="theme3" (onClick)="configService.changeColorScheme(colorScheme.light)"></p-radioButton>
        <label for="theme3" translate>Light</label>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>Input Style</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="app.uiConfig.inputStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="inputStyle1"></p-radioButton>
        <label for="inputStyle1" translate>Outlined</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="inputStyle" value="filled" [(ngModel)]="app.uiConfig.inputStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="inputStyle2"></p-radioButton>
        <label for="inputStyle2" translate>Filled</label>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>Ripple Effect</h5>
    <p-inputSwitch [(ngModel)]="app.uiConfig.ripple" (ngModelChange)="configService.uiChange(app.uiConfig)"></p-inputSwitch>
  </div>

  <div class="col-12 md:col-4 lg:col-3" *ngIf="isOnAdminRoute()">
    <h5 translate>Menu Themes</h5>
    <div class="layout-themes" *ngIf="app.uiConfig.colorScheme === 'light'">
      <div *ngFor="let theme of menuThemes">
        <a style="cursor: pointer;" (click)="configService.changeMenuTheme(theme.name, theme.logoColor, theme.componentTheme)"
           [ngStyle]="{'background-color': theme.color}"></a>
      </div>
    </div>
    <div *ngIf="app.uiConfig.colorScheme !== 'light'">
      <p translate>Menu themes are only available in light mode by design as large surfaces can emit too much brightness in dark mode.</p>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>Component Themes</h5>
    <div class="layout-themes">
      <div *ngFor="let theme of componentThemes">
        <a style="cursor: pointer" (click)="configService.changeComponentTheme(theme.name)" [ngStyle]="{'background-color': theme.color}"></a>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>Label style</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="labelStyle" value="float" [(ngModel)]="app.uiConfig.labelStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="style1"></p-radioButton>
        <label for="style1" translate>Float</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="labelStyle" value="static" [(ngModel)]="app.uiConfig.labelStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="style2"></p-radioButton>
        <label for="style2" translate>Static</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="labelStyle" value="placeholder" [(ngModel)]="app.uiConfig.labelStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="style3"></p-radioButton>
        <label for="style3" translate>Placeholder</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="labelStyle" value="inlineLeft" [(ngModel)]="app.uiConfig.labelStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="style4"></p-radioButton>
        <label for="style4" translate>Inline left</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="labelStyle" value="inlineRight" [(ngModel)]="app.uiConfig.labelStyle" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="style5"></p-radioButton>
        <label for="style5" translate>Inline right</label>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-4 lg:col-3">
    <h5 translate>First day of week</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="firstDayOfWeek" [value]="0" [(ngModel)]="app.uiConfig.firstDayOfWeek" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="firstDayOfWeek1"></p-radioButton>
        <label for="firstDayOfWeek1" translate>Sunday</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="firstDayOfWeek" [value]="1" [(ngModel)]="app.uiConfig.firstDayOfWeek" (ngModelChange)="configService.uiChange(app.uiConfig)"
                       inputId="firstDayOfWeek2"></p-radioButton>
        <label for="firstDayOfWeek2" translate>Monday</label>
      </div>
    </div>
  </div>
</div>
