import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { EMPTY_GROUP_HEADER_SUFFIX, EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { ConvertToClientDate } from '@shared/methods/date.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem, GroupMetaDataItem, GroupMetaDataTableItem } from '../types/list-widget-grouping.types';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Pipe({ name: 'getGroupMetaDataTableItem' })
export class GetGroupMetaDataTableItemPipe implements PipeTransform {
  transform(
    artifact: NewArtifact,
    groupMetaData: Record<string, GroupMetaDataItem> | null,
    groupByOption: SelectOption<string, GroupAttributeItem>,
    rowIndex: number,
    limit: number,
    options: ListWidgetOptions,
  ): GroupMetaDataTableItem | null {
    // preconditions
    if (!groupMetaData || !groupByOption || !artifact?.clientAttributes || !options) {
      return null;
    }
    const attributeId = groupByOption.value.id;
    const attributeValue = artifact.attributes[attributeId]?.value?.value || artifact.attributes[attributeId]?.value || EMPTY_GROUP_VALUE;
    const clientAttribute = artifact.clientAttributes.find(attr => attr.id === attributeId);

    const isDisplayed = groupMetaData[attributeValue]?.index === rowIndex % limit;
    const header = this.getHeader(artifact, groupByOption.value, clientAttribute as NewClientAttribute);
    const count = groupMetaData[attributeValue].size || 0;
    return { isDisplayed, header, count, toggled: true };
  }

  // TODO: think about moving this logic to handlers, pipe might be irrelevant
  private getHeader(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem, clientAttribute: NewClientAttribute): string {
    const { name, dataType } = groupAttributeItem;
    if (groupAttributeItem.kind === DataTypeKind.enumerated) {
      return (
        dataType?.values?.find(value => value.value === clientAttribute?.value || value.value === clientAttribute?.value?.value)?.label ||
        `${name} - ${EMPTY_GROUP_HEADER_SUFFIX}`
      );
    }
    if (groupAttributeItem.baseDataType === BaseDataType.date || groupAttributeItem.baseDataType === BaseDataType.dateTime) {
      return this.getHeaderForDate(artifact, groupAttributeItem, clientAttribute);
    }
    // simple value case
    return `${name} - ${EMPTY_GROUP_HEADER_SUFFIX}`;
    // return SharedMethods.getValueFromPath(key, artifact);
  }

  private getHeaderForDate(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem, clientAttribute: NewClientAttribute): string {
    if (NonAttributeKeys.isDateSpecificAttributeKey(groupAttributeItem.id)) {
      return ConvertToClientDate(new Date(SharedMethods.getValueFromPath(groupAttributeItem.id, artifact)));
    }
    return ConvertToClientDate(new Date(clientAttribute.value));
  }
}
