import { Injectable } from '@angular/core';
import { BLANK_OPTION_FILTER_URL_VALUE, CURRENT_USER_URL_FILTER_VALUE, EMPTY_FILTER_VALUE_SINGLE } from '@shared/constants/constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { NewTableColumn, TableFilterUrlTypeService } from '@shared/types/table.types';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlUserService implements TableFilterUrlTypeService {
  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void {
    const updatedFilterValues = (filterValue && filterValue.split(',')) || [];
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].matchMode = FilterMatchMode.IN;
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].value = this.getFilterValues(updatedFilterValues);
    columnFilterRef.applyFilter();
  }

  getFilterMetadata(filterValue: string | string[]): FilterMetadata[] {
    const updatedFilterValues = Array.isArray(filterValue) ? filterValue : filterValue?.split(',') || [];
    return [
      {
        matchMode: FilterMatchMode.IN,
        operator: FilterOperator.AND,
        value: this.getFilterValues(updatedFilterValues),
      },
    ];
  }

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string[] {
    return rawQueryAttributeValue?.split(',') || [];
  }

  private getFilterValues(filterValues: string[]): any {
    const processedFilterValues = filterValues.map(id => {
      switch (id) {
        case CURRENT_USER_URL_FILTER_VALUE:
          return NonAttributeKeys.CURRENT_USER_ID;
        case BLANK_OPTION_FILTER_URL_VALUE:
          return EMPTY_FILTER_VALUE_SINGLE;
        default:
          return id;
      }
    });

    return processedFilterValues;
  }
}
