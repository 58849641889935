import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DateRangeFilterEnum } from '../../../shared/types/filter.types';
import { FilterMetadataUtil } from '../../../shared/utils/filter-metadata.util';

@Pipe({ name: 'shouldShowDateOffsetOption' })
export class ShouldShowDateOffsetOptionPipe implements PipeTransform {
  constructor(private readonly filterMetaDataUtil: FilterMetadataUtil) {}

  transform(dateRange: DateRangeFilterEnum): boolean {
    return this.filterMetaDataUtil.isFilterNumeric(dateRange);
  }
}

@NgModule({
  declarations: [ShouldShowDateOffsetOptionPipe],
  exports: [ShouldShowDateOffsetOptionPipe],
})
export class ShouldShowDateOffsetOptionPipeModule {}
