<p-overlayPanel #overlayPanel (onHide)="onHide()" [showCloseIcon]="true" appendTo="body">
  <div class="layout-toolbar">
    <div class="layout-toolbar_prepared">
        <span
          (click)="setLastRowSelectedScheme(row)"
          *ngFor="let row of rowSchemes; last as isLast"
          [ngClass]="{'layout-last-icon': isLast}"
          class="layout-icon"
          pTooltip="{{row.value}}">
          <span *ngFor="let column of row.columns" style="{{'width: ' + column * 10 + '%'}}"></span>
        </span>
    </div>

    <div [formGroup]="form" class="layout-toolbar_generated">
      <div [formArrayName]="'rows'" class="layout-toolbar_generated_inputs">
        <div class="layout-toolbar_generated_heading">
          <span>Custom</span>
          <span (click)="addRow()" class="pi pi-plus-circle" pRipple></span>
        </div>
        <span *ngFor="let row of rows.controls; let rowI = index;" class="field">
            <input [formControlName]="rowI" pInputText type="text">
            <i (click)="deleteRow(rowI)" *ngIf="rows.controls.length > 1" class="pi pi-times-circle"
               pRipple></i>
        </span>
      </div>
      <div class="btn">
        <button (click)="generate()"
                [disabled]="form.invalid"
                class="p-button-success" label="{{'Generate' | translate}}" pButton pRipple></button>
      </div>
    </div>
  </div>
</p-overlayPanel>
