<div class="applications_wrapper">
  <div class="applications_header">
    <div>
      <button pButton icon="pi pi-list" class="p-button-rounded" (click)="m.selectApplications = !m.selectApplications"></button>
    </div>
    <div class="applications_header_buttons">
      <div class="mr">
        <button pButton [label]="'Export' | translate" icon="pi pi-cloud-download" (click)="m.exportModalOpened = true"></button>
      </div>
      <app-export-modal
        headerTitle="Export pages"
        [(visible)]="m.exportModalOpened"
        [selectedParts]="m.selectedApplications"
        [exportPartsMethod]="s.getExportMethod()"
      ></app-export-modal>

      <div class="mr">
        <button pButton [label]="'Import' | translate" icon="pi pi-cloud-upload" (click)="m.importModalOpened = true"></button>
      </div>
      <app-import-modal
        headerTitle="Import pages"
        inputTypeCheck="application"
        [(visible)]="m.importModalOpened"
        [importPartsMethod]="s.getImportMethod()"
      ></app-import-modal>

      <a *ngIf="m.canUserCreateApplication" [routerLink]="['/admin/application']" class="right-buttons">
        <button pButton label="{{ 'Create Application' | translate }}" type="button" class="p-button p-button-success"></button>
      </a>
    </div>
  </div>

  <app-application-list-table [columns]="m.columns" [loadDataMethod]="loadData()" [canUserAccessAdmin]="true" [m]="m"></app-application-list-table>
</div>
