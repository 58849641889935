/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArtifactCreateRequestDto } from '../../models/artifact-create-request-dto';
import { ArtifactResponseDto } from '../../models/artifact-response-dto';

export interface ArtifactControllerCreate$Params {
  notify: boolean;
      body: ArtifactCreateRequestDto

}

export function artifactControllerCreate(http: HttpClient, rootUrl: string, params: ArtifactControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
  const rb = new RequestBuilder(rootUrl, artifactControllerCreate.PATH, 'post');
  if (params) {
    rb.query('notify', params.notify, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArtifactResponseDto>;
    })
  );
}

artifactControllerCreate.PATH = '/api/tenant/artifact';
