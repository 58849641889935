import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom } from 'rxjs';

import { JobsResponseDto, LinkImportRequestDto } from '@api/models';
import { TenantLinkService } from '@api/services';
import { TenantLinkTypeService } from '@api/services/tenant-link-type.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { LinkTypeListService } from './services/link-type-list.service';
import { LinkTypeListModel } from './types/link-type-list.types';

@Component({
  selector: 'app-link-type-list',
  templateUrl: './link-type-list.component.html',
  styleUrls: ['./link-type-list.component.scss'],
})
export class LinkTypeListComponent extends CoreComponent<LinkTypeListService, LinkTypeListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: LinkTypeListService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly tenantLinkTypeService: TenantLinkTypeService,
    private readonly tenantLinkService: TenantLinkService,
    private readonly blockUiService: BlockUiService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super(route, router, translate, new LinkTypeListModel(), service, announcement);
  }

  async import({ files }: { files: Array<File> }, importField: FileUpload): Promise<void> {
    const importLinksHandler = async (requestBody: LinkImportRequestDto): Promise<JobsResponseDto | null> => {
      this.setUiBlocked(true);
      let res = null;
      try {
        res = await lastValueFrom(
          this.tenantLinkService.linkControllerImport({
            body: requestBody,
          }),
        );
      } catch (e) {
        console.log(e);
        await this.announcement.error('Something went wrong during importing file');
      }
      this.setUiBlocked(false);
      return res;
    };

    if (!files || !files.length) {
      await this.announcement.error('You need to choose file first!');
      return;
    }

    const res: JobsResponseDto | null = await importLinksHandler({
      file: files[0],
      applicationId: this.applicationSwitcherService.selectedApplication?.id || '',
    });

    if (res === null) {
      importField!.clear();
      return;
    }

    importField!.clear();
    await this.announcement.success('File has been imported successfully');
  }

  loadData() {
    return (meta?: Partial<ListReqMetaData>) => lastValueFrom(this.tenantLinkTypeService.linkTypeControllerList(meta));
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Link types' }]);
  }

  private setUiBlocked(blocked: boolean): void {
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }
}
