<div class="grid">
  <div class="col-12">
    <div class="header">
      <h4>{{ 'Link restrictions' | translate }}</h4>
      <button
        pButton
        label="{{ 'Add restriction' | translate }}"
        type="button"
        class="p-button"
        icon="pi pi-plus"
        iconPos="left"
        (click)="addRestriction()"
      ></button>
    </div>
    <p-table
      [columns]="m.options.column"
      [value]="artifactTypeLinkRestrictions"
      [loading]="loading"
      paginatorPosition="bottom"
      sortMode="multiple"
      sortField="id"
      appThirdClickUndoSort
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let column of columns; let colI = index">
            {{ column.label | translate }}
            <p-sortIcon *ngIf="colI === 0" [pSortableColumn]="column.key" [field]="column.key"></p-sortIcon>
          </th>
          <th>
            {{ 'Actions' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-restriction let-ri="rowIndex">
        <tr>
          <td>
            <p-dropdown
              [filter]="true"
              [autoDisplayFirst]="false"
              [style]="{ width: '100%' }"
              [options]="linkValidationType"
              [virtualScroll]="true"
              [virtualScrollItemSize]="20"
              [placeholder]="'Choose a link validation'"
              appendTo="body"
              optionLabel="label"
              [(ngModel)]="restriction.isLinkRequired"
              (onChange)="onChangeLinkRequired(ri, $event.value)"
            >
            </p-dropdown>
          </td>
          <td>
            <app-type-system-element-dropdown
              (ngModelChange)="onLinkTypeModelChange($event, restriction)"
              (onSelect)="onRestrictionLinkTypeChange(ri, restriction)"
              [(ngModel)]="restriction.directionalLinkTypeId"
              [applications]="applications.list"
              [class.bidirectional-restriction]="restriction.isBidirectional"
              [placeholder]="'Choose a link type'"
              [typeSystemElements]="m.options.linkTypes.list">
              <ng-template let-node let-placeholder="placeholder">
                <app-link-option-with-arrow
                  *ngIf="!restriction.isEmpty && node; else placeholderTemplate"
                  [direction]="restriction.isBidirectional ? 'BOTH' : $any(restriction.linkType?.meta)"
                  [label]="getLinkTypeLabel(restriction, node)">
                </app-link-option-with-arrow>

                <ng-template #placeholderTemplate>{{placeholder}}</ng-template>
              </ng-template>
            </app-type-system-element-dropdown>
          </td>
          <td>
            <app-type-system-element-dropdown
              (onSelect)="onRestrictionArtifactTypeChange(ri, restriction)"
              [(ngModel)]="restriction.sourceArtifactTypeId"
              [applications]="applications.list"
              [disabledElements]="(disabledArtifactTypes$ | async)?.get(restriction)?.source || []"
              [disabled]="!restriction?.linkType || linkMethods.isOutgoing(restriction?.linkType?.meta)"
              [placeholder]="'Choose an artifact type'"
              [typeSystemElements]="m.options.artifactTypes">
            </app-type-system-element-dropdown>
          </td>
          <td>
            <p-dropdown
              [autoDisplayFirst]="false"
              [style]="{ width: '100%' }"
              [options]="m.options.relationTypes"
              optionLabel="label"
              optionValue="value"
              [ngModel]="convertRelationValue(restriction.singleSource, restriction.singleDestination)"
              (onChange)="onRelationChange($event.value, restriction)"
              (onShow)="onRelationShow(restriction)"
              [disabled]="restriction.sourceArtifactTypeId === restriction.destinationArtifactTypeId"
              [placeholder]="'Choose relation type'"
              appendTo="body"
            >
            </p-dropdown>
          </td>
          <td>
            <app-type-system-element-dropdown
              (onSelect)="onRestrictionArtifactTypeChange(ri, restriction)"
              [(ngModel)]="restriction.destinationArtifactTypeId"
              [applications]="applications.list"
              [disabledElements]="(disabledArtifactTypes$ | async)?.get(restriction)?.destination || []"
              [disabled]="!restriction?.linkType || linkMethods.isIncoming(restriction?.linkType?.meta)"
              [placeholder]="'Choose an artifact type'"
              [typeSystemElements]="m.options.artifactTypes">
            </app-type-system-element-dropdown>
          </td>
          <td>
            <div class="table_buttons">
              <button pButton label="{{ 'Remove' | translate }}" type="button" class="p-button-danger" (click)="deleteRestriction(ri)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="m.options.column.length + 1">{{ 'No records found' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
