import { LinkFilterEnum } from '@shared/types/filter.types';

export class LinkFilterTypes {
  /** @deprecated */
  value = false;
  name = 'artifactId';
  variant: LinkFilterEnum = LinkFilterEnum.containsUrlParamKey;

  constructor(dto?: LinkFilterTypes) {
    dto && Object.assign(this, dto);
  }
}
