import { WidgetModel } from '../../shared/types/widget-model.interface';
import { SelectOption } from '@shared/types/shared.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { DATEnumLayoutVariant, DATTextFilterWidgetLayoutVariant } from '@shared/types/display-at-types';
import { FilterWidgetService } from '../services/filter-widget.service';

export class FilterWidgetSettings implements WidgetModel<FilterWidgetSettingsDto> {
  attribute: SelectOption<string, string> | null;
  currentAttribute: NewAttribute;
  urlKey: string;
  selectedVariant = new SelectOption<string, string>(DATEnumLayoutVariant.DEFAULT, 'DEFAULT');
  textSelectedVariant = new SelectOption<string, string>(DATTextFilterWidgetLayoutVariant.HIDE_BUTTON_ENTER_ENABLE, 'HIDE_BUTTON_ENTER_ENABLE');
  text: string;
  placeholder = '';
  isFilterMode = false;
  isDateMode = false;
  dto: FilterWidgetSettingsDto;

  constructor(dto?: FilterWidgetSettingsDto) {
    dto && this.fromDto(dto);
    dto && (this.dto = dto);
  }

  toServer(): FilterWidgetSettingsDto {
    return {
      attributeId: this.attribute?.value || '',
      urlKey: this.urlKey,
      selectedVariant: this.selectedVariant.value,
      placeholder: this.placeholder,
      textSelectedVariant: this.textSelectedVariant.value,
      isFilterMode: this.isFilterMode,
    };
  }

  fromDto(dto: FilterWidgetSettingsDto): void {
    if (dto) {
      dto.urlKey && (this.urlKey = dto.urlKey);
      dto.placeholder && (this.placeholder = dto.placeholder);
      dto.isFilterMode && (this.isFilterMode = dto.isFilterMode);
      dto.selectedVariant && (this.selectedVariant = new SelectOption<string, string>((DATEnumLayoutVariant as any)[dto.selectedVariant], dto.selectedVariant));
      dto.textSelectedVariant &&
        (this.textSelectedVariant = new SelectOption<string, string>(
          (DATTextFilterWidgetLayoutVariant as any)[dto.textSelectedVariant],
          dto.textSelectedVariant,
        ));
    }
  }

  updateDto(service: FilterWidgetService): void {
    if (!this.dto) return;
    const attr = service.getAttributeById(this.dto.attributeId);
    !this.attribute && attr && (this.attribute = new SelectOption<string, string>(attr.name, attr.id));
  }
}

export class FilterWidgetSettingsDto {
  attributeId: string;
  urlKey: string;
  placeholder: string;
  isFilterMode: boolean;
  selectedVariant: string;
  textSelectedVariant: string;
}
