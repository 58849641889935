import { Component, Input, OnInit } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { FadeAnimation } from '@shared/animations/animations';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { LinkPopupOptions } from '@widgets/link-popup/types/link-popup-options.types';
import { LinkPopupSettings } from '@widgets/link-popup/types/link-popup-settings.types';
import { GroupAttributeItem, GroupCollapseEnum, PaginationSettingEnum } from '../../../shared/components/artifact-list-table/types/list-widget-grouping.types';

@Component({
  selector: 'app-link-popup-table-settings',
  templateUrl: './link-popup-table-settings.component.html',
  styleUrls: ['./link-popup-table-settings.component.scss'],
  animations: [FadeAnimation],
})
export class LinkPopupTableSettingsComponent implements OnInit {
  @Input() settings: LinkPopupSettings;
  @Input() options: LinkPopupOptions;
  @Input() pages: ListContainer<Page>;
  @Input() onGroupAttributeChangeCb: (groupAttribute: GroupAttributeItem | undefined) => void;

  pagination = PaginationSettingEnum;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;

  constructor(private readonly cache: NewCacheService) {}

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  onGroupByChange(groupAttribute: SelectOption<string, GroupAttributeItem>): void {
    this.setExpandAllOptionsDisability();
    this.onGroupAttributeChangeCb && this.onGroupAttributeChangeCb(groupAttribute?.value);
  }

  forceTableReRender(): void {
    this.settings.showTable = false;
    setTimeout(() => (this.settings.showTable = true));
  }

  onPaginationChange(paginationValue: PaginationSettingEnum): void {
    const expandAllOptionEnabled = paginationValue === PaginationSettingEnum.perTable;
    this.setExpandAllOptionsEnabled(expandAllOptionEnabled);
    if (!expandAllOptionEnabled) {
      this.uncheckExpandAll();
    }
  }

  setExpandAllOptionsDisability(): void {
    this.setExpandAllOptionsEnabled(this.settings.grouping.pagination === PaginationSettingEnum.perTable);
  }

  private setExpandAllOptionsEnabled(enabled: boolean): void {
    const expandAllOption = this.options.groupCollapse.find(option => option.value === GroupCollapseEnum.expandAll);
    expandAllOption && (expandAllOption.disabled = !enabled);
  }

  private uncheckExpandAll(): void {
    if (this.settings.grouping.groupCollapse === GroupCollapseEnum.expandAll) {
      this.settings.grouping.groupCollapse = GroupCollapseEnum.collapseAll;
    }
  }
}
