import { Directive, HostBinding, Input } from '@angular/core';
import { RangedGridItem } from '@shared/components/grid-layout-generator/types/ranged-grid-item';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';

@Directive({
  selector: '[appRangedGridItem]',
})
export class RangedGridItemDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('area') item: RangedGridItem;
  @Input() breakpoint: StyleApplicationBreakpoint;

  @HostBinding('style.grid-column-start') get gridColumnStart(): number | null {
    return this.item.gridColumnStart.get(this.breakpoint) || null;
  }

  @HostBinding('style.grid-column-end') get gridColumnEnd(): number | null {
    return this.item.gridColumnEnd.get(this.breakpoint) || null;
  }

  @HostBinding('style.grid-row-start') get gridRowStart(): number | null {
    return this.item.gridRowStart.get(this.breakpoint) || null;
  }

  @HostBinding('style.grid-row-end') get gridRowEnd(): number | null {
    return this.item.gridRowEnd.get(this.breakpoint) || null;
  }

  @HostBinding('style.display') get display(): 'none' | null {
    return this.item.visible.get(this.breakpoint) === false ? 'none' : null;
  }
}
