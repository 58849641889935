<input
  #calculateInput
  pInputText
  appContextVariableAutocomplete
  id="expression"
  type="text"
  class="calculate-expression-input"
  [ownerId]="actionInputOwner"
  [disabled]="!editable"
  [attributes]="
    selectedAttributes | getWorkflowCalculationActionAttributes : '' : [] : dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds()
  "
  [(ngModel)]="action.expression"
/>
<div class="p-error" [class.visible]="errorMessage$ | async">{{ errorMessage$ | async | translate }}</div>
