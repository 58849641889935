import { Injectable } from '@angular/core';
import { BaseBooleanFilterService } from '@shared/services/filter/filter-types/base-boolean-filter.service';
import { NewTableColumn, TableFilterUrlTypeService } from '@shared/types/table.types';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlBooleanService implements TableFilterUrlTypeService {
  constructor(private readonly baseBooleanFilterService: BaseBooleanFilterService) {}

  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void {
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].matchMode = FilterMatchMode.IN;
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].value = this.getUrlQueryAttributeValue(filterValue);
    columnFilterRef.applyFilter();
  }

  getFilterMetadata(filterValue: string | string[]): FilterMetadata[] {
    const finalFilterValue = Array.isArray(filterValue) ? filterValue : this.getUrlQueryAttributeValue(filterValue);
    return [
      {
        matchMode: FilterMatchMode.IN,
        operator: FilterOperator.AND,
        value: finalFilterValue,
      },
    ];
  }

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string[] {
    return rawQueryAttributeValue?.split(',').map(value => this.baseBooleanFilterService.getBooleanValueFromString(value) || '') || [];
  }
}
