import { Injectable } from '@angular/core';
import { NumberWidgetModel } from '@widgets/number-widget/types/number-widget.types';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';

@Injectable({
  providedIn: 'root',
})
export class NumberWidgetHelper {
  isIconOnLeft(m: NumberWidgetModel): boolean {
    return m.settings.iconPosition.value === LabelPositionEnum.left;
  }

  isIconOnRight(m: NumberWidgetModel): boolean {
    return m.settings.iconPosition.value === LabelPositionEnum.right;
  }

  isIconOnTop(m: NumberWidgetModel): boolean {
    return m.settings.iconPosition.value === LabelPositionEnum.top;
  }

  isIconOnBottom(m: NumberWidgetModel): boolean {
    return m.settings.iconPosition.value === LabelPositionEnum.bottom;
  }

  isAllowSetIconAlign(m: NumberWidgetModel): boolean {
    return m.settings.iconPosition.value === LabelPositionEnum.top || m.settings.iconPosition.value === LabelPositionEnum.bottom;
  }
}
