import { Component, Input } from '@angular/core';
import { ContainerClass } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { SelectOption } from '@shared/types/shared.types';
import { ContainerClassEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-container-class',
  templateUrl: './container-class.component.html',
  styleUrls: ['./container-class.component.scss'],
})
export class ContainerClassComponent {
  @Input() styles: ContainerClass;

  readonly options = Object.entries(ContainerClassEnum).map(([key, value]: [string, string]) => new SelectOption(key, value));
}
