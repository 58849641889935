<section
  [style.column-gap]="columnGap"
  [style.grid-template-columns]="gridTemplateColumns"
  [style.grid-template-rows]="gridTemplateRowsWithMinHeight"
  [style.row-gap]="rowGap"
  class="d-grid grid-structure">

  <div *ngFor="let cell of cells; first as isFirst"
       class="grid-cell">
    <span
      *ngIf="cell.verticalLineStart as lineNumber"
      [style.left]="isFirst ? '2px' : 'calc(-10px - 0.5 * ' + columnGap + ' - 1px)'"
      class="line-number line-number--vertical">{{lineNumber}}</span>
    <span
      *ngIf="cell.verticalLineEnd as lineNumber"
      [style.right]="'2px'"
      class="line-number line-number--vertical">{{lineNumber}}</span>

    <span
      *ngIf="cell.horizontalLineStart as lineNumber"
      [style.top]="isFirst ? '2px' : 'calc(-10px - 0.5 * ' + rowGap + ' - 1px)'"
      class="line-number line-number--horizontal">{{lineNumber}}</span>
    <span
      *ngIf="cell.horizontalLineEnd as lineNumber"
      [style.bottom]="'2px'"
      class="line-number line-number--horizontal">{{lineNumber}}</span>
  </div>
</section>

<section
  [style.column-gap]="columnGap"
  [style.grid-template-columns]="gridTemplateColumns"
  [style.grid-template-rows]="gridTemplateRowsWithMinHeight"
  [style.row-gap]="rowGap"
  class="d-grid grid-content">
  <div
    *ngFor="let area of areas; index as areaIndex"
    [class.d-none]="!area.visible.get(activeBreakpoint)"
    [style.border-color]="colors[areaIndex]"
    [style.grid-column-end]="area.gridColumnEnd.get(activeBreakpoint)"
    [style.grid-column-start]="area.gridColumnStart.get(activeBreakpoint)"
    [style.grid-row-end]="area.gridRowEnd.get(activeBreakpoint)"
    [style.grid-row-start]="area.gridRowStart.get(activeBreakpoint)"
    class="grid-area">
    {{area.name}}
  </div>
</section>
