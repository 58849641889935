import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ArtifactCountResponseDto,
  ArtifactListResponseDto,
  ArtifactTypeResponseDto,
  AttributeResponseDto,
  DataTypeResponseDto,
  LinkTypeResponseDto,
  PageResponseDto,
  SelfUserResponseDto,
  UserResponseDto,
} from '@api/models';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY, NO_VALUE } from '@shared/constants/constants';
import { IsBoolean } from '@shared/methods/data-type.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { ServerSendEventMethods } from '@shared/methods/server-send-event.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser, NewUser } from '@shared/types/user.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { NumberWidgetBadgeService } from '@widgets/number-widget/services/number-widget-badge.service';
import { NumberTypes, NumberWidgetOptions } from '@widgets/number-widget/types/number-widget-options.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilter, ArtifactFilterDto, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { lastValueFrom } from 'rxjs';
import { NumberWidgetComponent } from '../number-widget.component';
import { NumberWidgetModel, NumberWidgetModelDto, NumberWidgetValue } from '../types/number-widget.types';
import { take } from 'rxjs/operators';

@Injectable()
export class NumberWidgetService {
  c: NumberWidgetComponent;
  m: NumberWidgetModel;
  options: NumberWidgetOptions = new NumberWidgetOptions();

  constructor(
    private readonly cache: NewCacheService,
    private readonly filterService: ArtifactFiltersService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly badgeService: NumberWidgetBadgeService,
    private attributeUtil: AttributeUtil,
    private route: ActivatedRoute,
    private router: Router,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {}

  async init(context: NumberWidgetComponent, dto?: NumberWidgetModelDto | null): Promise<void> {
    this.c = context;
    await this.loadOptions();

    this.options = context.widget?.value?.model?.options || this.options;

    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new NumberWidgetValue();
    }

    if (context.isLayoutMode && dto) {
      const options: ClickActionSettingsOptions = {
        attributes: this.options.attributes,
        dataTypes: this.options.dataTypes.list,
        pages: this.options.pages.toSelectOptions('name', 'id'),
        users: this.options.users,
      };
      context.widget.value.model = options ? new NumberWidgetModel(dto, options) : new NumberWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    context.m.options = this.options;

    if (!context.isLayoutMode) {
      return;
    }

    context.registerSubscriptions([
      this.cache.user.subscribe(user => (this.m.options.systemUser = new NewSystemUser(user as SelfUserResponseDto))),
      this.runtimeStateNotificationService?.events$.subscribe(async (event: RuntimeStateNotification) => {
        if (ServerSendEventMethods.isArtifactSse(event.type) || ServerSendEventMethods.isLinkSse(event.type)) {
          const artifactTypeId = this.m.selected?.artifactType?.value?.id || '';
          (!artifactTypeId || event.data.artifactTypeId === artifactTypeId) && this.onUpdateArtifact();
        }
      }),
      this.route.queryParams.subscribe(params => {
        this.m.currentParams = params;
        this.urlKeyCheck();
      }),
    ]);

    this.m.selected.fromDto(this.m.options);
    await this.setRestrictions();
    this.onArtifactTypeChange();
    this.urlKeyCheck();
    await this.updateValue();

    setTimeout(() => {
      this.updateBadgeStyles();
    });

    !this.m.badgeId && (this.m.badgeId = 'badge_' + ElvisUtil.makeHash(8));
  }

  updateBadgeStyles(): void {
    this.m.selected.showBadge && this.badgeService.setStyles(this.m.badgeId, this.m.settings.badgeStyles);
  }

  async onUpdateArtifact(): Promise<void> {
    this.updateValue();
  }

  onArtifactTypeChange(): void {
    if (this.m.selected.artifactType?.value) {
      const ids = Object.values(this.m.selected.artifactType?.value.attributes).map(value => value.id);

      this.m.options.artifactAttributeValues.setList(
        [...this.m.options.attributes.filter(attr => ids.includes(attr.id)), ...this.getLinks(), ...this.filterService.getSystemAttributes()],
        ID_KEY,
      );

      this.m.options.artifactCountableAttributeValues.setList(
        this.m.options.attributes.filter(attr => ids.includes(attr.id) && this.isAttributeCountable(attr)),
        ID_KEY,
      );
    } else {
      this.m.selected.attribute = null;
    }

    this.numberTypesUpdateOptions();
  }

  getLinks(): DirectionalLinkType[] {
    return this.m.options.linkTypes.list
      .filter((linkType: DirectionalLinkType) => this.checkLinkRestriction(linkType) && linkType.isLinkingArtifactTypeId(this.m.selected.artifactType.value.id))
      .map(link => {
        link.name = link.direction === LinkDirection.outgoing ? link.outgoingName : link.incomingName;
        return link;
      });
  }

  numberTypesUpdateOptions(): void {
    if (this.m.selected.attribute?.value) {
      this.m.options.numberTypes.setList(Object.keys(NumberTypes).map(key => NumberTypes[key as keyof typeof NumberTypes]));
    } else {
      this.m.selected.numberType = new SelectOption(NumberTypes.count);
      setTimeout(() => {
        this.m.options.numberTypes.setList([NumberTypes.count]);
      });
    }
  }

  isAttributeCountable(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return !!(
      dataType?.kind !== DataTypeKind.enumerated &&
      dataType?.baseDataType &&
      [BaseDataType.integer, BaseDataType.decimal].includes(dataType.baseDataType)
    );
  }

  isAttributeTypeUser(dataTypeId: string): boolean {
    return this.m.options.dataTypes.listMap[dataTypeId]?.baseDataType === BaseDataType.user;
  }

  urlKeyCheck(doUpdate?: boolean): void {
    const params = this.m.currentParams;

    const folderKey = this.m.selected.folderKey;
    this.m.folderId = params[folderKey];

    const artifactKey = this.m.selected.artifactKey;
    this.m.artifactId = params[artifactKey];
    !this.m.selected.listenUrlFilterOption && (this.m.selected.urlFilter = []);

    if (doUpdate || this.m.prevFolderId !== this.m.folderId || this.m.prevArtifactId !== this.m.artifactId) {
      this.updateValue();
    }

    const { listenUrlAttributeId, countableAttributeKey, attribute, listenUrlFilterOption } = this.m.selected;
    if (listenUrlAttributeId && countableAttributeKey && attribute?.value !== this.m.countableAttributeId) {
      this.m.countableAttributeId = params[countableAttributeKey];
      if (!this.m.countableAttributeId) return;
      const attr = this.m.options.artifactCountableAttributeValues.listMap[this.m.countableAttributeId];
      attr && (this.m.selected.attribute = new SelectOption<string, string>(attr.name, attr.id));
      this.updateValue();
    }

    if (listenUrlFilterOption) {
      const keys = Object.keys(params);
      let isChanged = !!(!keys.length && this.m.selected.urlFilter.length);
      this.m.selected.urlFilter = [];

      keys.forEach(name => {
        const attr = this.m.options.artifactAttributeValues.list.find(val => this.attributeUtil.getUrlQueryAttributeName(val.name) === name);
        const filter = attr && this.getFilterFromAttribute(attr, params[name]);
        const dataType = attr && this.m.options.dataTypes.listMap[attr.dataTypeId];

        if (filter?.value || (filter && dataType?.baseDataType && IsBoolean(dataType.baseDataType))) {
          this.m.selected.urlFilter.push(filter);
          isChanged = true;
        }
      });

      isChanged && this.updateValue();
    }

    this.m.prevFolderId = this.m.folderId;
    this.m.prevArtifactId = this.m.artifactId;
  }

  checkAndUpdateUrl(): void {
    const { listenUrlAttributeId, countableAttributeKey, attribute } = this.m.selected;
    if (listenUrlAttributeId && countableAttributeKey && attribute) {
      const queryParams: Record<string, string> = Object.keys(this.route.snapshot.queryParams).reduce<Record<string, string>>((params, key) => {
        params[key] = this.route.snapshot.queryParams[key];
        return params;
      }, {});

      queryParams[countableAttributeKey] && (queryParams[countableAttributeKey] = attribute.value);

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
      });
    }
  }

  checkboxHandler(isFolderListener = false) {
    if (isFolderListener) {
      this.m.selected.listenUrlArtifactId = false;
    } else {
      this.m.selected.listenUrlFolderId = false;
    }
    this.updateValue();
  }

  async updateValue(): Promise<void> {
    if (this.m.selected.showFixedValue) {
      const val = parseInt(this.m.settings.fixedCounter, 10);
      this.m.counter = (this.m.settings.showRoundLabel && !isNaN(val) ? this.roundCounter(val) : this.m.settings.fixedCounter) || '';
      return;
    }

    // show count of artType
    if (!this.m.selected.artifactType) {
      const val = this.m.options?.artifactTypes.list.length || 0;
      this.setCounter(val);
      return;
    }

    const $and = [{ artifactTypeId: { $in: [{ $oid: this.m.selected.artifactType.value.id }] } }, { deleted: { $eq: null } }];

    const promiseArr = this.m.selected.attributesFilter?.filter(af => af.type === ArtifactFilterType.link).map(af => this.filterService.getLinkQuery(af));

    promiseArr.length &&
      (await Promise.all(promiseArr)).forEach(query => {
        query && $and.push(query);
      });

    this.m.selected.attributesFilter?.forEach(af => {
      const query = this.filterService.getQuery(af);
      query && $and.push(query);
    });

    this.m.selected.urlFilter?.forEach(af => {
      const query = this.filterService.getQuery(af);
      query && $and.push(query);
    });

    if (this.m.selected.listenUrlArtifactId && this.m.artifactId) {
      $and.push({ _id: { $oid: this.m.artifactId } } as any);
    }

    if (this.m.selected.listenUrlFolderId && this.m.folderId) {
      const $in = this.m.folderId.split(',').map(id => ({ $oid: id }));
      $and.push({ ['folderData.parentId']: { $in } } as any);
    }

    const params: any = { filter: JSON.stringify({ $and }) };
    const key: string = this.m.selected.numberType.value as string;

    if (key === NumberTypes.first || key === NumberTypes.last) {
      const sort = key === NumberTypes.first ? 1 : -1;
      params.limit = '1';
      params.sort = JSON.stringify({ 'created.on': sort });
      const data: ArtifactListResponseDto = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: params }));
      if (this.m.selected.attribute && data.data.length) {
        const attr = data.data[0]?.attributes[this.m.selected.attribute.value];
        attr && this.setCounter(parseFloat(attr.value.toString()));
      }

      return;
    }

    if (this.m.selected.attribute) {
      params.attributeIds = JSON.stringify([this.m.selected.attribute.value]);
      params.subOperations = ['sum'];
    }

    if (key === NumberTypes.min || key === NumberTypes.max) {
      params.subOperations = params.subOperations?.length ? [...params.subOperations, key] : [key];
    }

    params.subOperations && (params.subOperations = JSON.stringify(params.subOperations));

    const data: ArtifactCountResponseDto = await lastValueFrom(this.tenantArtifactService.artifactControllerCount(params));
    let val = data.count || 0;

    if (this.m.selected.attribute) {
      if (key === NumberTypes.avg) {
        val = (data as any).sum[this.m.selected.attribute.value] / data.count;
      } else if (key !== NumberTypes.count) {
        const record = (data as any)[key] as Record<string, number>;
        record && record[this.m.selected.attribute.value] !== undefined && (val = record[this.m.selected.attribute.value]);
      }
    }

    this.setCounter(val);
  }

  setCounter(val: number): void {
    if (val === Number.MIN_SAFE_INTEGER || val === Number.MAX_SAFE_INTEGER) {
      this.m.counter = NO_VALUE;
      return;
    }

    this.m.counter = '' + (this.m.settings.showRoundLabel ? this.roundCounter(val) : val) || 0;
  }

  getFilterFromAttribute(attr: any, filterOption: string | undefined): ArtifactFilter | null {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    const key = attr?.systemAttributeKey;
    const isSystemAttribute = key && NonAttributeKeys.isUserOrDateSpecificAttributeKey(attr.systemAttributeKey);

    if (!filterOption || !(isSystemAttribute || dataType)) return null;

    const value = this.filterService.getFilterValueFromString(filterOption, dataType, this.m.options.users.list, key);

    if (!value) return null;

    const dto: ArtifactFilterDto = {
      name: attr.name,
      value,
      attributeId: attr.id,
      dataTypeId: dataType?.id,
      type: null,
    } as ArtifactFilterDto;

    if (isSystemAttribute) {
      dto.type = ArtifactFilterType.system;
      dto.systemAttributeCode = key;
    }

    return ArtifactFilter.fromDtoAndOptions(dto, {
      attributes: this.m.options.attributes,
      dataTypes: this.m.options.dataTypes.list,
      linkTypes: this.m.options.linkTypes.list,
    });
  }

  private async setRestrictions(): Promise<void> {
    this.m.restrictions = await LinkMethods.getGroupedLinkRestrictions(this.m.options.artifactTypes.list, this.m.options.linkTypes.list);
  }

  private checkLinkRestriction(linkType: DirectionalLinkType): boolean {
    const restrictions = this.m.restrictions;
    if (!restrictions) return false;

    const { id, direction } = linkType;
    const artifactTypeId = this.m.selected.artifactType.value.id;

    if (artifactTypeId && restrictions[artifactTypeId])
      return restrictions[artifactTypeId].some(({ linkType }) => linkType?.value === id && linkType?.meta === direction);

    return false;
  }

  private roundCounter(val: number): string | number {
    let sign = '';
    if (val > 9999) {
      if (val > 999999) {
        val = Math.round(val / 100000) / 10;
        sign = 'M';
      } else {
        val = Math.round(val / 100) / 10;
        sign = 'K';
      }
    } else {
      val = Math.round(val * 100) / 100;
    }
    return `${val}${sign}`;
  }

  private async loadOptions(): Promise<void> {
    const { dataTypes, artifactTypes, attributes, users, pages, linkTypes } = this.cache.data;

    this.options.artifactTypes.setList((artifactTypes.value as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),'id');
    this.options.attributes = [...(attributes.value as AttributeResponseDto[]).map(dto => new NewAttribute(dto)), ...this.filterService.getSystemAttributes()];
    this.options.dataTypes.setList((dataTypes.value as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),'id');
    this.options.users.setList((users.value as UserResponseDto[]).map(dto => new NewUser(dto)),'id');
    this.options.pages.setList((pages.value as PageResponseDto[]).map(dto => new Page(dto)),'id');

    const directionalLinkTypes: DirectionalLinkType[] = (linkTypes.value as LinkTypeResponseDto[])
        .map(dto => new LinkType(dto))
        .reduce((directionalLinkTypes: DirectionalLinkType[], linkType: LinkType) => {
          return [
            ...directionalLinkTypes,
            new DirectionalLinkType(LinkDirection.outgoing, linkType),
            new DirectionalLinkType(LinkDirection.incoming, linkType),
          ];
        }, []);
    this.options.linkTypes.setList(directionalLinkTypes, 'id');

  }
}
