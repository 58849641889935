<div class="menu-widget_settings container settings">
  <app-menu-item-tree [m]="m" [s]="s" (onSelect)="initActiveState()"></app-menu-item-tree>

  <div class="mt-1" *ngIf="m.canRenderSettings">
    <p-accordion>
      <p-accordionTab header="{{'Menu settings'| translate}}" [(selected)]="activeState[0]">
        <app-menu-settings-form [m]="m" [s]="s"></app-menu-settings-form>
      </p-accordionTab>

      <p-accordionTab header="{{'Sub menu settings'| translate}}" [(selected)]="activeState[1]">
        <app-sub-menu-settings-form [m]="m" [s]="s"></app-sub-menu-settings-form>
      </p-accordionTab>

      <p-accordionTab *ngIf="m.selected.menuItem" header="{{'Menu item settings'| translate}}"
                      [(selected)]="activeState[2]">
        <div class="row settings_form" id="settings_form">
          <app-menu-item-type-form [m]="m" [s]="s"></app-menu-item-type-form>
          <app-menu-item-settings-form *ngIf="!m.selected.treeItem.usesDefaultStyle" [m]="m"
                                       [s]="s"></app-menu-item-settings-form>
          <app-default-style-settings-form *ngIf="m.selected.treeItem.usesDefaultStyle" [m]="m"
                                           [s]="s"></app-default-style-settings-form>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
