<div *ngIf="isPDF(fileName)" class="center pdf-container pb-2">
  <iframe [src]="url | safe" frameborder="0" width="100%" height="100%"></iframe>
</div>
<div *ngIf="isImage(fileName)" class="center preview-container pb-2">
  <img [src]="url" alt="" />
</div>
<div *ngIf="!isImage(fileName) && !isPDF(fileName)" class="center preview-container pb-2">
  {{ 'No preview available' | translate }}
</div>
<div class="my-1" class="center">
  <button
    class="p-button p-button-secondary mr-3"
    pButton
    icon="pi pi-external-link"
    pTooltip="{{ 'Open in new tab' | translate }}"
    (click)="openTab()"
  ></button>
  <button *ngIf="isArtifact" pButton icon="pi pi-cloud-download" pTooltip="{{ 'Download' | translate }}" (click)="download()"></button>
</div>
