import { Component, Input, ViewChild } from '@angular/core';
import { ArtifactTypeListService } from '@private/pages/artifact-type-management/artifact-type-list/services/artifact-type-list.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { APPLICATION_ID_KEY, FORMAT_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-artifact-type-list-table',
  templateUrl: './artifact-type-list-table.component.html',
  styleUrls: ['./artifact-type-list-table.component.scss'],
})
export class ArtifactTypeListTableComponent extends CoreListComponent<NewArtifactType> {
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() applications: ListContainer<NewApplication>;

  APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  FORMAT_VALUE = FORMAT_KEY;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    public readonly artifactTypeListService: ArtifactTypeListService,
  ) {
    super();
  }

  protected onInit(): void {
    super.onInit();
    this.registerSubscription(
      this.applicationSwitcherService.selectedApplication$.subscribe(() => {
        this.artifactTypeListService.doFilter(this.table);
      }),
    );
  }
}
