import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { BaseTypeFilterService } from './base-type-filter.service';

export abstract class AbstractBaseTypeFilterService implements BaseTypeFilterService {
  constructor(protected readonly filterUtil: FilterUtil) {}

  abstract getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null;

  abstract getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string | undefined,
    filterRule?: CoreListFilterEnum | undefined,
  ): Record<string, any> | null;

  abstract getDefaultFilterRule(): CoreListFilterEnum;

  abstract isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean;

  protected getResultQuery(filterKey: string, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null {
    const resultFilterRule = filterRule || this.getDefaultFilterRule();
    const queryValue = this.filterUtil.getMongoFilterByMatchMode(resultFilterRule, value, true);

    return {
      [filterKey]: queryValue,
    };
  }

  protected getQueryFromRawTextSingle(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, string> | null {
    return this.getQuery(attributeKey, dataType, value, filterRule);
  }

  protected getQueryFromRawTextMulti(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter = ',',
    filterRule?: CoreListFilterEnum,
  ): Record<string, string> | null {
    if (!value) {
      return null;
    }
    const multiValues = value.split(delimiter);
    return this.getQuery(attributeKey, dataType, multiValues, filterRule);
  }

  protected toOidValues(values: any[]): any[] {
    return values.map((value: any) => ({ $oid: value }));
  }
}
