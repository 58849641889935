import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FadeAnimation } from '@shared/animations/animations';
import { TextStyleHelper } from '@shared/helpers/text-style.helper';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { CUSTOM_VARIANT_KEY } from '@shared/types/display-at-types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum, LabelPositionEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-attribute-format-settings',
  templateUrl: './attribute-format-settings.component.html',
  styleUrls: ['./attribute-format-settings.component.scss'],
  animations: [FadeAnimation],
})
export class AttributeFormatSettingsComponent extends TextStyleHelper implements OnChanges {
  @Input() options: { attributes: ListContainer<NewAttribute>; dataTypes: ListContainer<NewDataType> };
  @Input() attribute: NewClientAttribute;
  @Input() dataType: NewDataType;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() folderSettings: FolderWidgetSettings;
  @Input() changeLabelStyleReference: (formatSettings: AttributeFormatSettings) => void;
  @Input() onLabelPositionChange: (event: { originalEvent: PointerEvent; value: LabelPositionEnum }, formatSettings: AttributeFormatSettings) => void;

  @Output() editEnabled: EventEmitter<void> = new EventEmitter<void>();
  @Output() editDisabled: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCustomVariantSelection: EventEmitter<string> = new EventEmitter();

  attributeDisplayVariantOptions?: SelectOption<string, string>[];

  constructor(private readonly artifactWidgetHelper: ArtifactWidgetHelper) {
    super();
    setTimeout(() => console.log(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attribute) {
      this.onAttributeChange();
    }
  }

  changeFieldStyleReference(): void {
    this.formatSettings.value.fieldStyles = { ...this.formatSettings.value.fieldStyles };
  }

  onEditEnabled(): void {
    this.editEnabled.emit();
  }

  onEditDisabled(): void {
    this.editDisabled.emit();
  }

  onSelectedItemChange(selectedItem: string) {
    if (selectedItem === CUSTOM_VARIANT_KEY) {
      this.onCustomVariantSelection.emit(this.attribute.id);
    }
  }

  isFolderPath(): boolean {
    return this.attribute.id === NonAttributeKeys.FOLDER_PATH_ID;
  }

  private onAttributeChange() {
    if (
      NonAttributeKeys.isUserSpecificAttributeKeyOrId(this.attribute.id) ||
      NonAttributeKeys.isDateSpecificAttributeId(this.attribute.id) ||
      this.dataType?.isDate ||
      this.dataType?.isDateTime ||
      this.dataType?.isEnum ||
      this.dataType?.isBoolean ||
      this.dataType?.isUser ||
      this.dataType?.isHyperlink
    ) {
      this.attributeDisplayVariantOptions = this.artifactWidgetHelper.setDisplayVariantsMetaData({
        attributeId: this.attribute.id,
        attributes: this.options.attributes,
        dataType: this.dataType,
        isDate: this.dataType?.isDate,
        isDateTime: this.dataType?.isDateTime,
        formatSettings: this.formatSettings,
      });
      return;
    }
  }
}
