<div *ngIf="!!group" class="rule-group-container">
  <div class="rule-group-top">
    <div class="rule-group-operation-type">
      <p-dropdown
        [(ngModel)]="group.operator"
        [options]="operatorTypes"
        appendTo="body"
        styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
      ></p-dropdown>
    </div>
    <div class="rule-group-buttons">
      <button pButton class="p-button-info p-button-text" type="button" (click)="onAddCondition()" [label]="'Add condition' | translate"></button>
      <button pButton class="p-button-info p-button-text" type="button" icon="bi bi-plus-circle-fill" (click)="onAddGroup()"
              [label]="'Add nested condition' | translate"></button>
      <ng-content></ng-content>
    </div>
  </div>

  <div *ngFor="let condition of group.conditions; let i = index" class="rule-condition-row">
    <span>
<!--      <div class="rule-condition-row-item">-->
      <!--      </div>-->
      <div class="rule-condition-row-item">
        <input *ngIf="condition.source.manual" [(ngModel)]="condition.source.value" [placeholder]="'Enter attribute manually' | translate" pInputText
               class="input-large" />
        <app-workflow-attribute-selector
          *ngIf="!condition.source.manual"
          [selectedAttributeId]="condition.source.value"
          [attributes]="selectedAttributes"
          (attributeChange)="onAttributeChange($event, condition)"
          [editable]="true"
        ></app-workflow-attribute-selector>
        <button [pTooltip]="condition.source.manual ? 'Choose existing attribute' : 'Set attribute manually'"
                tooltipPosition="top"
                pButton pRipple class="p-button p-button-text btn-manual" type="button" [icon]="condition.source.manual ? 'bi bi-arrow-repeat' : 'bi bi-pencil'"
                (click)="onIsConditionManuallySetChange(condition.source, condition.destination)"></button>
      </div>
      <div class="rule-condition-row-item">
        <p-dropdown
          *ngIf="condition.source.value"
          [(ngModel)]="condition.operationType"
          [options]="condition.source.value | getConditionOperationTypes : selectedAttributes : dataTypes : condition.source.manual"
          styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
          appendTo="body"
        >
          <ng-template let-option pTemplate="item">{{ 'WORKFLOWS.CONDITION.' + option | translate }}</ng-template>
          <ng-template let-option pTemplate="selectedItem">{{ 'WORKFLOWS.CONDITION.' + option | translate }}</ng-template>
        </p-dropdown>
      </div>
      <ng-container *ngIf="condition.source.value">
        <div class="rule-condition-row-item">
          <app-attribute-value-edit-field
            *ngIf="!condition.destination.isDynamic && !condition.source.manual"
            [(value)]="condition.destination.value"
            [dataType]="condition.source.value | getDataTypeFromAttributeId : allAttributes : dataTypes"
            [disabled]="false"
            [index]="i"
            [multipleValues]="false"
            [users]="users"
            [showCurrentUserOption]="true"
          ></app-attribute-value-edit-field>

          <input *ngIf="condition.destination.isDynamic || condition.source.manual" [(ngModel)]="condition.destination.value" class="input-large"
                 [placeholder]="'Enter dynamic value' | translate"
                 pInputText />
        </div>

        <div *ngIf="workflowType === WorkflowType.be" class="rule-condition-row-item">
          <p-checkbox [(ngModel)]="condition.destination.isDynamic" binary="true"
                      (ngModelChange)="onIsConditionDestinationDynamicChange(condition)"
                      [disabled]="condition.source.manual"></p-checkbox>
          {{ 'dynamic' | translate }}
        </div>
      </ng-container>
    </span>
    <button pButton class="p-button p-button-text p-button-danger btn-remove" type="button" (click)="onRemoveCondition(i)"
            [label]="'Remove' | translate"></button>
  </div>

  <div *ngFor="let group of group.groups; let i = index" class="rule-inner-group">
    <app-workflows-rule-condition
      #conditionGroup
      [group]="group"
      [artifactTypes]="artifactTypes"
      [attributes]="attributes"
      [allAttributes]="allAttributes"
      [dataTypes]="dataTypes"
      [users]="users"
      [triggers]="triggers"
      [defaultArtifactType]="defaultArtifactType"
      [workflowType]="workflowType"
      [depth]="depth + 1"
    >
      <button pButton class="p-button p-button-text p-button-danger" type="button" (click)="onRemoveGroup(i)"
              [label]="'Remove group' | translate"></button>
    </app-workflows-rule-condition>
  </div>
</div>
