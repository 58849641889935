/**
 * Changes '.' to a '___' in the names in the names of the table filters
 */
import { FilterType } from '@shared/types/table.types';

const formatTableFilterNamesToServer = (filters: Record<string, any>): void => {
  for (const key in filters) {
    if (key.includes('.')) {
      filters[key.replace(/\./g, '___')] = filters[key];
      delete filters[key];
    }
  }
};

/**
 * Changes '___' to a '.' in the names in the names of the table filters
 */
const formatTableFilterNamesToClient = (filters: Record<string, any>): void => {
  for (const key in filters) {
    if (key.includes('___')) {
      filters[key.replace(/___/g, '.')] = filters[key];
      delete filters[key];
    }
  }
};

const isFilterTypeDate = (filterType: FilterType | null): boolean => {
  return filterType === FilterType.date;
};

const isFilterTypeDateTime = (filterType: FilterType | null): boolean => {
  return filterType === FilterType.datetime;
};

export const TableMethods = {
  formatTableFilterNamesToServer,
  formatTableFilterNamesToClient,
  isFilterTypeDate,
  isFilterTypeDateTime,
};
