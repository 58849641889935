import { formatDate } from '@angular/common';
import { ArtifactAttributeResponseDto } from '@api/models/artifact-attribute-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { getTimeValueForClient } from '@shared/methods/client-attribute.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';
import { AttributeInfo } from '@widgets/card-widget/types/attribute-info';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

const delimiter = ', ';

type Formatter = (value: string) => string | ArtifactResponseDto;

export class ClientAttributeExtractor implements ArtifactValueExtractor {
  constructor(private readonly options: CardWidgetOptions, private readonly elvisUtil: ElvisUtil, private readonly localeId: string) {}

  private get integerFormatter(): Formatter {
    return String;
  }

  private get decimalFormatter(): Formatter {
    return String;
  }

  private get booleanFormatter(): Formatter {
    return String;
  }

  private get textFormatter(): Formatter {
    return String;
  }

  private get dateFormatter(): Formatter {
    return (value: string) => (value ? formatDate(value, 'dd.MM.yyyy', this.localeId) : '');
  }

  private get timeFormatter(): Formatter {
    return (value: string | Date) => {
      if (!value) return '';

      const date = getTimeValueForClient(value);
      return date ? formatDate(date, 'HH:mm', this.localeId) : '';
    };
  }

  private get dateTimeFormatter(): Formatter {
    return (value: string | Date) => (value ? formatDate(value, 'dd.MM.yyyy HH:mm', this.localeId) : '');
  }

  private get userFormatter(): Formatter {
    return (value: string | SelectOption<string, string>) => {
      return typeof value === 'string' ? this.options.users.listMap[value]?.email || '' : value.label;
    };
  }

  private get hyperlinkFormatter(): Formatter {
    return String;
  }

  private get htmlFormatter(): Formatter {
    return String;
  }

  private get fileFormatter(): Formatter {
    return (value: string) => {
      return this.options.files.loaded ? { ...this.options.files.listMap[value] } : ({} as ArtifactResponseDto);
    };
  }

  static isClientAttribute(artifact: ArtifactResponseDto, attributeId: string, options: CardWidgetOptions): boolean {
    const artifactType = options.artifactTypes.listMap[artifact.artifactTypeId];

    return attributeId in artifactType.attributes;
  }

  getValue(artifactDto: ArtifactResponseDto | NewArtifact, { attributeId }: AttributeInfo): string | ArtifactResponseDto[] {
    const attribute = artifactDto.attributes[attributeId];
    const dataType = this.getDataType(attributeId);
    const artifactAttributeValue = this.getStringifiedValue(attribute, dataType);

    if (!this.isNewClientAttribute(attribute) && (!artifactAttributeValue || !artifactAttributeValue.length || !dataType)) {
      return '';
    }

    const multipleValues = this.options.attributes.listMap[attributeId].multipleValues || Array.isArray(artifactAttributeValue);

    if (multipleValues && artifactAttributeValue === '') {
      return '';
    }

    switch (dataType.kind) {
      case DataTypeKind.simple:
        return this.getSimple(artifactAttributeValue, dataType, multipleValues);
      case DataTypeKind.bounded:
        return this.getBounded(artifactAttributeValue, dataType, multipleValues);
      case DataTypeKind.enumerated:
        return this.getEnumerated(artifactAttributeValue, dataType, multipleValues);
      case DataTypeKind.counter:
        return this.getCounter(artifactAttributeValue, dataType, multipleValues);
      default:
        return '';
    }
  }

  private getStringifiedValue(attribute: ArtifactAttributeResponseDto | NewClientAttribute, dataType: NewDataType): string | string[] {
    if (this.isNewClientAttribute(attribute)) {
      if (Array.isArray(attribute.value)) {
        return attribute.value.map(value => value?.value || value);
      }

      if (dataType.isBoolean) return attribute.value;
      if (dataType.isEnum) return attribute.value?.value || attribute.value || '';
      return attribute.value || '';
    }

    return (attribute?.value || '') as string[] | string;
  }

  private getFormatterForBaseDataType(baseDataType: BaseDataType): Formatter {
    const formatters: { [type in BaseDataType]: Formatter } = {
      [BaseDataType.integer]: this.integerFormatter,
      [BaseDataType.decimal]: this.decimalFormatter,
      [BaseDataType.boolean]: this.booleanFormatter,
      [BaseDataType.text]: this.textFormatter,
      [BaseDataType.date]: this.dateFormatter,
      [BaseDataType.time]: this.timeFormatter,
      [BaseDataType.dateTime]: this.dateTimeFormatter,
      [BaseDataType.user]: this.userFormatter,
      [BaseDataType.hyperlink]: this.hyperlinkFormatter,
      [BaseDataType.html]: this.htmlFormatter,
      [BaseDataType.file]: this.fileFormatter,
    };

    return formatters[baseDataType];
  }

  private getSimple(value: string[] | string | SelectOption<any, any>[], dataType: NewDataType, multiple: boolean): string | ArtifactResponseDto[] {
    if (multiple) {
      const arr = (value as string[]).map((valueItem: string) => this.getFormatterForBaseDataType(dataType.baseDataType!)(valueItem));

      return dataType.baseDataType === BaseDataType.file ? arr : (arr.join(delimiter) as any);
    }

    const singleValue = this.getFormatterForBaseDataType(dataType.baseDataType!)(value as string);

    return dataType.baseDataType === BaseDataType.file ? [singleValue] : (singleValue as any);
  }

  private getEnumerated(value: string[] | string, dataType: NewDataType, multiple: boolean): string {
    if (multiple) {
      return dataType
        .values!.filter((enumValue: DataTypeValueResponseDto) => value.includes(enumValue.value))
        .map(({ label }: DataTypeValueResponseDto) => label)
        .join(delimiter);
    }

    return dataType.values!.find((enumValue: DataTypeValueResponseDto) => value === enumValue.value)?.label || '';
  }

  private getBounded(value: string[] | string, dataType: NewDataType, multiple: boolean): string {
    if (multiple) {
      return (value as string[]).map((valueItem: string) => this.getFormatterForBaseDataType(dataType.baseDataType!)(valueItem as string)).join(delimiter);
    }

    return this.getFormatterForBaseDataType(dataType.baseDataType!)(value as string) as string;
  }

  private getCounter(value: string[] | string, dataType: NewDataType, multiple: boolean): string {
    if (multiple) {
      return (value as string[]).join(delimiter);
    }

    return value as string;
  }

  private getDataType(attributeId: string): NewDataType {
    const attribute = this.options.attributes.listMap[attributeId];

    return this.options.dataTypes.listMap[attribute.dataTypeId];
  }

  private isNewClientAttribute(attribute: ArtifactAttributeResponseDto | NewClientAttribute): boolean {
    return attribute instanceof NewClientAttribute;
  }
}
