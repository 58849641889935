/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { serviceControllerGetEmailConfiguration } from '../fn/service/service-controller-get-email-configuration';
import { ServiceControllerGetEmailConfiguration$Params } from '../fn/service/service-controller-get-email-configuration';
import { serviceControllerReset } from '../fn/service/service-controller-reset';
import { ServiceControllerReset$Params } from '../fn/service/service-controller-reset';
import { serviceControllerSendTestEmail } from '../fn/service/service-controller-send-test-email';
import { ServiceControllerSendTestEmail$Params } from '../fn/service/service-controller-send-test-email';
import { serviceControllerSetEmailConfiguration } from '../fn/service/service-controller-set-email-configuration';
import { ServiceControllerSetEmailConfiguration$Params } from '../fn/service/service-controller-set-email-configuration';
import { serviceControllerSetup } from '../fn/service/service-controller-setup';
import { ServiceControllerSetup$Params } from '../fn/service/service-controller-setup';
import { ServiceResponse } from '../models/service-response';

@Injectable({ providedIn: 'root' })
export class ServiceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `serviceControllerReset()` */
  static readonly ServiceControllerResetPath = '/api/service/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceControllerReset()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerReset$Response(params?: ServiceControllerReset$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceResponse>> {
    return serviceControllerReset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceControllerReset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerReset(params?: ServiceControllerReset$Params, context?: HttpContext): Observable<ServiceResponse> {
    return this.serviceControllerReset$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceResponse>): ServiceResponse => r.body)
    );
  }

  /** Path part for operation `serviceControllerSetup()` */
  static readonly ServiceControllerSetupPath = '/api/service/setup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceControllerSetup()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerSetup$Response(params?: ServiceControllerSetup$Params, context?: HttpContext): Observable<StrictHttpResponse<ServiceResponse>> {
    return serviceControllerSetup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceControllerSetup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerSetup(params?: ServiceControllerSetup$Params, context?: HttpContext): Observable<ServiceResponse> {
    return this.serviceControllerSetup$Response(params, context).pipe(
      map((r: StrictHttpResponse<ServiceResponse>): ServiceResponse => r.body)
    );
  }

  /** Path part for operation `serviceControllerGetEmailConfiguration()` */
  static readonly ServiceControllerGetEmailConfigurationPath = '/api/service/email-configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceControllerGetEmailConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerGetEmailConfiguration$Response(params?: ServiceControllerGetEmailConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return serviceControllerGetEmailConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceControllerGetEmailConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerGetEmailConfiguration(params?: ServiceControllerGetEmailConfiguration$Params, context?: HttpContext): Observable<{
}> {
    return this.serviceControllerGetEmailConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `serviceControllerSetEmailConfiguration()` */
  static readonly ServiceControllerSetEmailConfigurationPath = '/api/service/email-configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceControllerSetEmailConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceControllerSetEmailConfiguration$Response(params: ServiceControllerSetEmailConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return serviceControllerSetEmailConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceControllerSetEmailConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  serviceControllerSetEmailConfiguration(params: ServiceControllerSetEmailConfiguration$Params, context?: HttpContext): Observable<void> {
    return this.serviceControllerSetEmailConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `serviceControllerSendTestEmail()` */
  static readonly ServiceControllerSendTestEmailPath = '/api/service/test-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceControllerSendTestEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerSendTestEmail$Response(params: ServiceControllerSendTestEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return serviceControllerSendTestEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceControllerSendTestEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceControllerSendTestEmail(params: ServiceControllerSendTestEmail$Params, context?: HttpContext): Observable<{
}> {
    return this.serviceControllerSendTestEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
