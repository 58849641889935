<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
<p-calendar (onBlur)="onBlur()"
            [(ngModel)]="attr.value"
            (ngModelChange)="onChange()"
            [dateFormat]="dateFormat"
            [firstDayOfWeek]="firstDayOfWeek"
            [inputId]="'dateField' + index"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [placeholder]="placeholder"
            [readonlyInput]="true"
            [selectionMode]="attribute.multipleValues ? 'range' : 'single'"
            [showTime]="dataType.baseDataType | isDateTime"
            [timeOnly]="dataType.baseDataType | isTime"
            appendTo="body">
</p-calendar>
<app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                [formatSettings]="formatSettings"
                                [label]="label"
                                [fieldHtmlId]="'dateField' + index"
                                [canContainPlaceholder]="false"
                                [labelBehaviour]="labelBehaviour"
                                [placeholder]="placeholder">
</app-artifact-attribute-form-field-label-with-icon>
