import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { DataTypeCreateRequestDto } from '@api/models/data-type-create-request-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { TenantDataTypeService } from '@api/services/tenant-data-type.service';
import {
  BaseDataType,
  BaseDataTypeBoundedRange,
  BaseDataTypeCounter,
  BaseDataTypeEnumerated,
  BaseDataTypeOptionsMap,
  BaseDataTypeSimple,
  DataTypeKind,
  EnumeratedOption,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DataTypeComponent } from '@private/pages/artifact-type-management/data-type/data-type.component';
import { DataTypesModel } from '@private/pages/artifact-type-management/data-type/types/data-type.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreService } from '@shared/core/services/core.service';
import { TransformDecimalToServer } from '@shared/methods/client-attribute.methods';
import { ConvertMinMaxToClient, IsDate, IsDateOrTime, IsDateTime, IsDecimal, IsTime } from '@shared/methods/data-type.methods';
import { ConvertToServerDate, ConvertToServerDatetime, ConvertToServerTime } from '@shared/methods/date.methods';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { cloneDeep, isEqual } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DataTypeService extends CoreService<DataTypeComponent, DataTypesModel> {
  constructor(
    public readonly confirmationService: ConfirmationService,
    public readonly translateUtil: TranslateUtil,
    private readonly tenantDataTypeService: TenantDataTypeService,
    private readonly elvisUtil: ElvisUtil,
    private readonly blockUiService: BlockUiService,
    private readonly cache: NewCacheService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super();
  }

  public async init(context: DataTypeComponent, model: DataTypesModel): Promise<void> {
    super.init(context, model);
    this.setBaseDataTypeOptionsMap();
    this.initSubscriptions();
  }

  async save(): Promise<void> {
    let dataType = null;
    this.blockUiService.blockUi();
    try {
      if (this.m.dataType.id) {
        const { id, baseDataType } = this.m.dataType;
        const changed = this.getChangedDataFromOriginalObject<NewDataType>(this.m.dataType);
        this.transformValuesToServer(changed as Partial<DataTypeCreateRequestDto>, baseDataType);
        dataType = await this.tenantDataTypeService.dataTypeControllerUpdate({ body: { id, ...(changed as Partial<DataTypeCreateRequestDto>) } }).toPromise();
      } else {
        const body = this.dataTypeToCreateRequest();
        dataType = await lastValueFrom(this.tenantDataTypeService.dataTypeControllerCreate({ body }));
      }

      if (dataType) {
        this.cache.data.dataTypes.setItem(dataType);
        await this.cancel();
      }
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl(`/admin/data-type-list`);
  }

  async deleteWithConfirmation(dataType: NewDataType): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete', `Are you sure that you want to delete ${dataType.name}?`, 'Yes', 'No', {
        name: dataType.name,
      }),
      () => this.delete(dataType.id),
    );
  }

  async delete(id: string): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      const success = await lastValueFrom(this.tenantDataTypeService.dataTypeControllerDelete({ id }));
      if (success) {
        await this.cancel();
      }
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  checkIfDataTypeChanged(): boolean {
    const target = this.m.dataType.id ? this.m.dataTypes.listMap[this.m.dataType.id] : new NewDataType();
    return !isEqual(this.m.dataType, target);
  }

  onKindChange(kind: DataTypeKind): void {
    this.m.baseDataTypeOptions = this.m.baseDataTypeOptionsMap[kind];
    let baseDataType = null;
    if (kind === DataTypeKind.enumerated) {
      baseDataType = BaseDataType.integer;
    } else {
      if (kind === DataTypeKind.counter) {
        baseDataType = BaseDataType.text;
      }
    }
    const dto: any = {};
    Object.keys(this.m.dataType)
      .filter(key => !['maximum', 'minimum', 'values'].includes(key))
      .forEach(key => {
        dto[key] = (this.m.dataType as any)[key];
      });
    dto.kind = kind;
    this.m.dataType = new NewDataType(dto as DataTypeResponseDto);
    this.m.dataType.baseDataType = baseDataType;
    this.onBaseDataTypeChange();
  }

  onBaseDataTypeChange(): void {
    let focusOnFirstOption = false;

    if (this.m.dataType.kind === DataTypeKind.enumerated) {
      const newValue = this.m.dataType.baseDataType === BaseDataType.integer ? '1' : '';
      this.m.dataType.values = [new EnumeratedOption('', newValue, '', '')];
      focusOnFirstOption = true;
    }

    if (this.m.dataType.baseDataType === BaseDataType.boolean) {
      this.m.dataType.values = this.getBooleanValues();
      focusOnFirstOption = true;
    }

    if (focusOnFirstOption) {
      setTimeout(() => {
        const label = document.getElementById('label0');
        label && label.focus();
      });
    }
  }

  async checkBeforeNavigation(url: string): Promise<void> {
    if (this.checkIfDataTypeChanged()) {
      await this.elvisUtil.doSomethingWithConfirmation(
        this.confirmationService,
        new DoSomethingWithConfirmationParams('Change data type', "You have unsaved changes. Are you sure you don't want to save them?", 'Yes', 'No'),
        () => {
          this.c.canDeactivate = true;
          this.m.dataType = cloneDeep(this.m.dataTypeCopy);
          this.setOriginalObject<NewDataType>(this.m.dataType);
          this.c.router.navigateByUrl(url);
        },
        () => (this.c.canDeactivate = false),
      );
    } else {
      if (!this.c.canDeactivate) {
        this.c.canDeactivate = true;
        await this.c.router.navigateByUrl(url);
      }
    }
  }

  private initSubscriptions(): void {
    const { applications, dataTypes } = this.cache.data;

    this.c.registerSubscriptions([
      applications.subscribe(applications => {
        this.m.applications.setList(
          applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)),
          'id',
        );
      }),
      dataTypes.subscribe(dataTypes => {
        this.m.dataTypes.setList(
          dataTypes!.map(dto => new NewDataType(dto as DataTypeResponseDto)),
          'id',
        );
        this.initDataType();
      }),
    ]);
  }

  private getBooleanValues(): EnumeratedOption[] {
    return [new EnumeratedOption('TRUE', 'true'), new EnumeratedOption('FALSE', 'false'), new EnumeratedOption('EMPTY', '')];
  }

  private initDataType(): void {
    try {
      this.m.inProgress = true;
      const dataType = this.m.dataTypes.listMap[this.c.urlParams.id];
      console.log(dataType);
      this.m.dataType = new NewDataType(dataType as DataTypeResponseDto);

      if (this.m.dataType.baseDataType && IsDateOrTime(this.m.dataType.baseDataType)) {
        this.m.dataType.minimum && (this.m.dataType.minimum = ConvertMinMaxToClient(dataType.baseDataType as BaseDataType, this.m.dataType.minimum as any));
        this.m.dataType.maximum && (this.m.dataType.maximum = ConvertMinMaxToClient(dataType.baseDataType as BaseDataType, this.m.dataType.maximum as any));
      }

      this.setOriginalObject<NewDataType>(this.m.dataType);
      this.m.baseDataTypeOptions = this.m.baseDataTypeOptionsMap[this.m.dataType.kind];
      this.m.isDeleted = Boolean(dataType?.deleted);

      if (this.m.dataType.isBoolean && !this.m.dataType.values?.length) {
        this.m.dataType.values = this.getBooleanValues();
      }
    } finally {
      this.m.inProgress = false;
    }
  }

  private setBaseDataTypeOptionsMap(): void {
    this.m.baseDataTypeOptionsMap = new BaseDataTypeOptionsMap(
      GetSelectOptionsFromEnum(BaseDataTypeSimple),
      GetSelectOptionsFromEnum(BaseDataTypeEnumerated),
      GetSelectOptionsFromEnum(BaseDataTypeBoundedRange),
      GetSelectOptionsFromEnum(BaseDataTypeCounter),
    );
  }

  private dataTypeToCreateRequest(): DataTypeCreateRequestDto {
    const { baseDataType, description, kind, name, uri, values, minimum, maximum, applicationId } = this.m.dataType;
    const body: DataTypeCreateRequestDto = {
      applicationId: applicationId || this.applicationSwitcherService.selectedApplication?.id || '',
      baseDataType: baseDataType as BaseDataType,
      description,
      kind,
      name,
      uri,
      minimum: minimum as any,
      maximum: maximum as any,
    };
    this.transformValuesToServer(body, baseDataType);
    values && (body.values = values);
    return body;
  }

  private transformValuesToServer(dataType: Partial<DataTypeCreateRequestDto>, baseDataType: BaseDataType | null): any {
    if (!dataType.maximum) {
      delete dataType.maximum;
    }
    if (!dataType.minimum) {
      delete dataType.minimum;
    }

    if (baseDataType && IsDateOrTime(baseDataType) && (dataType.minimum || dataType.maximum)) {
      if (IsDate(baseDataType)) {
        dataType.minimum && (dataType.minimum = ConvertToServerDate(dataType.minimum as any as Date));
        dataType.maximum && (dataType.maximum = ConvertToServerDate(dataType.maximum as any as Date));
      } else {
        if (IsTime(baseDataType)) {
          dataType.minimum && (dataType.minimum = ConvertToServerTime(dataType.minimum as any as Date));
          dataType.maximum && (dataType.maximum = ConvertToServerTime(dataType.maximum as any as Date));
        } else {
          if (IsDateTime(baseDataType)) {
            dataType.minimum && (dataType.minimum = ConvertToServerDatetime(dataType.minimum as any as Date));
            dataType.maximum && (dataType.maximum = ConvertToServerDatetime(dataType.maximum as any as Date));
          }
        }
      }
    }

    if (baseDataType && IsDecimal(baseDataType)) {
      dataType.minimum && (dataType.minimum = TransformDecimalToServer(dataType.minimum));
      dataType.maximum && (dataType.maximum = TransformDecimalToServer(dataType.maximum));
    }

    if (dataType.values) {
      dataType.values.forEach(value => !value.icon && (value.icon = ''));
    }
  }
}
