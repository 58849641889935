import { Pipe, PipeTransform } from '@angular/core';
import { RuleAdministrationType } from '@workflows/shared';
import { WorkflowOwnerType } from '@workflows/types';

@Pipe({ name: 'showUsedIn' })
export class ShowUsedInPipe implements PipeTransform {
  transform(administrationType: RuleAdministrationType, type: WorkflowOwnerType, isGlobal: boolean): boolean {
    return administrationType === RuleAdministrationType.GENERAL && type !== WorkflowOwnerType.CUSTOM && isGlobal;
  }
}
