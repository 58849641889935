import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexContainer } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { SelectOption } from '@shared/types/shared.types';
import { FlexDirection } from '@widgets/shared/types/style.types';

interface DirectionOptions {
  flexDirection: SelectOption<typeof FlexDirection, typeof FlexDirection>[];
}

@Component({
  selector: 'app-flex-direction-form',
  templateUrl: './flex-direction-form.component.html',
  styleUrls: ['./flex-direction-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexDirectionFormComponent {
  @Input() styles: FlexContainer;

  @Output() onChange: EventEmitter<FlexContainer> = new EventEmitter<FlexContainer>();

  readonly options: DirectionOptions = {
    flexDirection: GetSelectOptionsFromEnum(FlexDirection),
  };

  onValueChange(): void {
    this.onChange.emit(this.styles);
  }
}
