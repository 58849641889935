export enum ScreenSize {
  pc = 992,
  mobile = 576,
}

export enum ScreenState {
  pc = 'e-pc',
  tablet = 'e-tab',
  mobile = 'e-mob',
}

export enum HideClasses {
  pc = 'pc-hide',
  tablet = 'tab-hide',
  mobile = 'mob-hide',
}
