import { Component, Input, OnInit } from '@angular/core';
import { NumberWidgetModel } from '@widgets/number-widget/types/number-widget.types';
import { NumberWidgetService } from '@widgets/number-widget/services/number-widget.service';
import { SelectOption } from '@shared/types/shared.types';
import { ContentStyles, MenuStylesPropNamesEnum } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { Subscription } from 'rxjs';
import { IconsService } from '@shared/services/icons.service';
import { NumberWidgetHelper } from '@widgets/number-widget/helpers/number-widget.helper';

@Component({
  selector: 'app-number-widget-content-settings',
  templateUrl: './number-widget-content-settings.component.html',
  styleUrls: ['./number-widget-content-settings.component.scss'],
})
export class NumberWidgetContentSettingsComponent implements OnInit {
  @Input() m: NumberWidgetModel;
  @Input() s: NumberWidgetService;

  counterAlign: SelectOption<string, string>;
  labelAlign: SelectOption<string, string>;
  iconAlign: SelectOption<string, string>;
  menuStylesPropNamesEnum = MenuStylesPropNamesEnum;
  styles: ContentStyles;

  constructor(private readonly iconService: IconsService, public helper: NumberWidgetHelper) {}

  ngOnInit(): void {
    this.counterAlign = new SelectOption(this.m.settings.counterStyles?.textAlign);
    this.labelAlign = new SelectOption(this.m.settings.labelStyles?.textAlign);
    this.iconAlign = new SelectOption(this.m.settings.iconStyles?.textAlign);

    const iconSub: Subscription = this.iconService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => {
      this.m.options.iconList = iconOptions;

      setTimeout(() => {
        iconSub.unsubscribe();
      });
    });
  }

  isChangeCounter(val: SelectOption<string, any>): void {
    this.m.settings.counterStyles.textAlign = val.value.value;
  }

  isChangeLabel(val: SelectOption<string, any>): void {
    this.m.settings.labelStyles.textAlign = val.value.value;
  }

  isChangeIcon(val: SelectOption<string, any>): void {
    this.m.settings.iconStyles.textAlign = val.value.value;
  }
}
