import { ThrowErrorActionWorkflowSettingsDto } from '@api/models/throw-error-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum ThrowErrorKeys {
  error = 'error',
  property = 'property',
  responseCode = 'responseCode',
}

export class WorkflowActionThrowErrorDto implements ThrowErrorActionWorkflowSettingsDto {
  error: ThrowErrorActionWorkflowSettingsDto[ThrowErrorKeys.error] = { value: '', isDynamic: false };
  property: ThrowErrorActionWorkflowSettingsDto[ThrowErrorKeys.property] = { value: '', isDynamic: false };
  responseCode: ThrowErrorActionWorkflowSettingsDto[ThrowErrorKeys.responseCode] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionThrowErrorDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionThrowError extends AbstractWorkflowAction<WorkflowActionThrowErrorDto> {
  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionThrowErrorDto, type: WorkflowActionType.THROW_ERROR, dto });
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }
}
