import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Environment } from '@environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { UiConfig } from '@private/pages/profile/types/user.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  params: { email?: string; token?: string } = {};
  newPassword = '';
  confirmPassword = '';
  inProgress = false;
  uiConfig: UiConfig;

  language = '';

  // TODO needs to be updated when capcha component will be implemented
  // captchaKey = '';
  // reloadCaptcha = true;
  // private captchaValid = true;
  private languageSubscription$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private blockUiService: BlockUiService,
    private announcementService: AnnouncementService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.params = { ...params };
    });

    // this.captchaKey = Environment.reCaptchaSiteKey;
    this.language = this.translateService.currentLang;
    this.languageSubscription$ = this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      // this.reloadCaptcha = false;
      // this.captchaValid = false;
      this.language = langChangeEvent.lang;
      // setTimeout(() => {
      //   this.reloadCaptcha = true;
      // }, 100);
    });
  }

  async recoverPassword(): Promise<void> {
    // if (!this.captchaValid) {
    //   await this.announcementService.error('Solve Captcha first');
    //   return;
    // }
    if (!this.params.email) {
      await this.announcementService.error('Enter email');
      return;
    }
    this.inProgress = true;
    this.blockUiService.blockUi();

    const success = await this.authorizationService.recoverPassword(this.params.email!);
    if (success) {
      await this.announcementService.success('Please check your email and reset the password');
      this.params.email = '';
      await this.router.navigateByUrl('/login');
    }

    this.inProgress = false;
    this.blockUiService.unblockUi();
  }

  async tokenPasswordChange(): Promise<void> {
    // if (!this.captchaValid) {
    //   await this.announcementService.error('Solve Captcha first');
    //   return;
    // }
    if (!this.params.email) {
      await this.announcementService.error('Enter email');
      return;
    }
    if (!this.newPassword || this.newPassword !== this.confirmPassword) {
      await this.announcementService.error("Passwords don't match");
      return;
    }
    this.inProgress = true;
    this.blockUiService.blockUi();

    const success = await this.authorizationService.tokenPasswordChange(this.params.email!, this.newPassword, this.params.token!);
    if (success) {
      await this.announcementService.success('Now you can login with your new password');
      this.params.email = '';
      this.params.token = '';
      this.newPassword = '';
      this.confirmPassword = '';
      await this.router.navigateByUrl('/login');
    }

    this.inProgress = false;
    this.blockUiService.unblockUi();
  }

  async cancel(): Promise<void> {
    await this.router.navigateByUrl('/login');
  }

  // validateCaptcha(isValid: boolean): void {
  //   this.captchaValid = isValid;
  // }

  ngOnDestroy(): void {
    this.languageSubscription$.unsubscribe();
  }
}
