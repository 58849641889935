<header class="p-dialog-header">
  <span *ngIf="m.templateToDetail === null" class="p-dialog-title">{{ header | translate }}</span>
  <button
    (click)="s.onDetail(null)"
    *ngIf="m.templateToDetail !== null"
    class="p-button p-button-secondary"
    icon="pi pi-angle-double-left"
    label="{{ 'Back to template list' | translate }}"
    pButton
  ></button>
  <button
    (click)="toggleGenerator($event)"
    *ngIf="isGenerateSectionButtonVisible"
    class="p-button p-button-success p-component generate-button"
    label="{{ 'Generate section' | translate }}"
    pButton
    pRipple
  ></button>
  <div class="p-dialog-header-icons">
    <button (click)="s.close()" class="p-dialog-header-icon p-dialog-header-maximize p-link">
      <i class="p-dialog-header-close-icon pi pi-times"></i>
    </button>
  </div>
</header>
<div class="app-dialog-content dynamic-float-label" [class.detail]="m.templateToDetail !== null">
  <div>
    <app-templates-list
      *ngIf="applicationSwitcherService.selectedApplication"
      [hidden]="m.templateToDetail !== null"
      [templateType]="type"
      [loadDataMethod]="loadDataMethod"
      [useSingleButtonToPickTemplate]="useSingleButtonToPickTemplate"
      [hideTemplateTypePicker]="hideTemplateTypePicker"
      [widgetType]="widgetType"
    ></app-templates-list>

    <app-template-detail *ngIf="m.templateToDetail !== null" [type]="type"></app-template-detail>
  </div>

  <app-section-generation-form (generateSection)="s.onGenerate($event)"></app-section-generation-form>
</div>
