import { Message } from 'primeng/api';

export class ToastMessage {
  severity: string;
  summary: string;
  detail: string;

  constructor(severity?: string, summary?: string, detail?: string, meta?: Message) {
    meta && Object.assign(this, meta);
    severity && (this.severity = severity);
    summary && (this.summary = summary);
    detail && (this.detail = detail);
  }
}
