import { Injectable } from '@angular/core';
import { LinkRestriction, UngroupedArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { DisabledArtifactTypesByRestriction } from '../../disabled-artifact-types-by.restriction';
import { DisabledArtifactTypes } from '../../disabled-artifact.types';

type Restriction = LinkRestriction | UngroupedArtifactTypeLinkRestriction;

@Injectable({
  providedIn: 'root',
})
export class ArtifactTypeOptionsDisablingService {
  getDisabledArtifactTypes(restrictions: Restriction[]): DisabledArtifactTypesByRestriction {
    return new Map(
      restrictions.reduce((entries: [Restriction, DisabledArtifactTypes][], restriction: Restriction) => {
        return [...entries, [restriction, this.getDisabledArtifactTypesForRestriction(restriction, restrictions)] as [Restriction, DisabledArtifactTypes]];
      }, []),
    );
  }

  private getDisabledArtifactTypesForRestriction(targetRestriction: Restriction, restrictions: Restriction[]): DisabledArtifactTypes {
    return restrictions.reduce(
      (disabled: DisabledArtifactTypes, restriction: Restriction) => {
        if (targetRestriction !== restriction && this.isCompleteRestriction(restriction)) {
          if (this.shouldDisableSourceOfRestrictionInTarget(restriction, targetRestriction)) {
            disabled.source.push({ id: restriction.sourceArtifactTypeId });
          }

          if (this.shouldDisableDestinationOfRestrictionInTarget(restriction, targetRestriction)) {
            disabled.destination.push({ id: restriction.destinationArtifactTypeId });
          }
        }

        return disabled;
      },
      { source: [], destination: [] },
    );
  }

  private isCompleteRestriction({ sourceArtifactTypeId, destinationArtifactTypeId }: Restriction): boolean {
    return !!sourceArtifactTypeId && !!destinationArtifactTypeId;
  }

  private shouldDisableSourceOfRestrictionInTarget(restriction: Restriction, targetRestriction: Restriction): boolean {
    const destinationsMatch = targetRestriction.destinationArtifactTypeId === restriction.destinationArtifactTypeId;
    const sourcesNotMatch = targetRestriction.sourceArtifactTypeId !== restriction.sourceArtifactTypeId;

    return destinationsMatch && sourcesNotMatch;
  }

  private shouldDisableDestinationOfRestrictionInTarget(restriction: Restriction, targetRestriction: Restriction): boolean {
    const sourcesMatch = targetRestriction.sourceArtifactTypeId === restriction.sourceArtifactTypeId;
    const destinationsNotMatch = targetRestriction.destinationArtifactTypeId !== restriction.destinationArtifactTypeId;

    return sourcesMatch && destinationsNotMatch;
  }
}
