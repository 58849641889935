<div class="row pl-3">
  <div class="col-2 text-bold pr-0">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-2 col-2-small"></div>
  <div class="col-4 pl-0 pr-0">
    <input id="forEachPathInput" [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value" pInputText type="text" placeholder="Enter for-each-path"
           class="input-min-width" />
  </div>
</div>

<div class="row pl-3">
  <div class="col-2 text-bold pr-0">
    {{ 'Artifact id' | translate }}
  </div>
  <div class="col-2 col-2-small pl-0 pr-0">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.id].isDynamic" id="isArtifactIdDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-4 pl-0 pr-0">
    <input [(ngModel)]="action.actionSettings[RuleKeys.id].value"
           [placeholder]="action.actionSettings[RuleKeys.id].isDynamic ? 'Enter dynamic id' : 'Enter id' | translate" pInputText class="input-min-width" />
  </div>
</div>

<div class="row pl-3">
  <div class="col-2 text-bold pr-0">
    {{ 'Attributes' | translate }}
  </div>
  <div class="col-2 col-2-small pl-0 pr-0">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].isDynamic" id="isAttributesMapDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8 pl-0 pr-0">
    <div *ngFor="let attribute of action.clientAttributesArray; let index = index">
      <div class="row">
        <div class="col-3 pl-0 pr-0">
          <div class="attribute-settings">
            <div>
              <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveAttribute(index)"></button>
            </div>
            <div>
              <div class="row p-0 m-0">
                <span class="mb-1 p-0">
                  <p-checkbox [(ngModel)]="attribute.useInitialValue" [id]="'useInitialValue' + index" binary="true" class="mr-2 pt-0"
                              (ngModelChange)="onUseInitialValueChange(attribute, $event)"></p-checkbox>
                  {{ 'use initial value' | translate }}
                </span>
              </div>
              <div class="row p-0 m-0">
                <span class="p-0">
                  <p-checkbox [(ngModel)]="attribute.isMulti" [id]="'isMulti' + index" binary="true" class="mr-2 pt-0"
                              (ngModelChange)="onIsMultiChange(attribute)"></p-checkbox>
                  {{ 'is multi' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 pl-2 pr-2">
          <input [id]="'attrId' + index" [(ngModel)]="attribute.id" pInputText type="text" class="input-full" [placeholder]="'Enter id ' + (index + 1)" />
        </div>
        <div class="col-5 pl-0 pr-0">
          <div *ngIf="attribute.isMulti; else singleInput">
            <div *ngFor="let value of attribute.value; let valueIndex = index; trackBy: attrValuesToTrackByFn" class="mb-1">
              <div *ngIf="!attribute.useInitialValue && attribute.value" class="input-multi">
                <input [id]="'attrValue' + valueIndex" [(ngModel)]="attribute.value![valueIndex]" pInputText type="text"
                       [placeholder]="'Enter value ' + (valueIndex + 1)" />
                <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus"
                        [ngClass]="{'hidden': valueIndex !== attribute.value.length - 1 }"
                        (click)="onAddAttributeValue(index)"></button>
                <button *ngIf="attribute.value.length > 1" class="p-button p-button-text p-button-danger" pButton type="button"
                        icon="pi pi-minus" (click)="onRemoveAttributeValue(attribute, valueIndex)"></button>
              </div>
            </div>
          </div>

          <ng-template #singleInput>
            <input *ngIf="!attribute.useInitialValue" [(ngModel)]="attribute.value" [placeholder]="'Enter value' | translate" pInputText class="input-full" />
          </ng-template>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddAttribute()" label="Add attribute"></button>
  </div>
</div>

<div class="row pl-3">
  <div class="col-2 text-bold pr-0">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-2 col-2-small"></div>
  <div class="col-4 pl-0 pr-0">
    <input [(ngModel)]="action.actionSettings[RuleKeys.storeResultsPath].value" [placeholder]="'Enter store results path' | translate" pInputText
           class="input-min-width" id="scopeInput" />
  </div>
</div>


<div class="row pl-3">
  <div class="col-2 text-bold pr-0">
    {{ 'Parent folder id' | translate }}
  </div>
  <div class="col-2 col-2-small">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].isDynamic" id="isParentFolderDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-4 pl-0 pr-0">
    <input [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].value" [placeholder]="'Enter parent folder id' | translate" pInputText
           class="input-min-width" id="parentFolderIdInput" />
  </div>
</div>
