import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileFilter } from '@widgets/shared/components/artifact-filters/components/file-filter/types/file-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-file-filter',
  templateUrl: './file-filter.component.html',
  styleUrls: ['./file-filter.component.scss'],
})
export class FileFilterComponent {
  @Input() set m(data: ArtifactFilter) {
    !data.value && (data.value = new FileFilter());
    this.model = data.value;
  }

  @Output() onFilterChange = new EventEmitter<any>();

  model: FileFilter;

  onChange(): void {
    this.onFilterChange.emit();
  }
}
