import { SelectOption } from '@shared/types/shared.types';
import { ApexNoData } from 'ng-apexcharts/lib/model/apex-types';
import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { NO_DATA_DEFAULT_TEXT } from '@widgets/apex-chart/constants/constants';

export class ApexNoDataTypes {
  text = NO_DATA_DEFAULT_TEXT;
  align: SelectOption<string, ApexNoDataAlign> = new SelectOption(ApexNoDataAlign.center);
  verticalAlign: SelectOption<string, ApexNoDataVerticalAlign> = new SelectOption(ApexNoDataVerticalAlign.middle);
  offsetX: number;
  offsetY: number;
  style: FontStyles = new FontStyles();
  alignOptions = Object.keys(ApexNoDataAlign);
  verticalAlignOptions = Object.keys(ApexNoDataVerticalAlign);

  constructor(dto?: ApexNoDataDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ApexNoDataDto {
    const dto: ApexNoDataDto = {
      align: this.align.value,
      verticalAlign: this.verticalAlign.value,
      style: this.style,
    };

    const { text, offsetX, offsetY } = this;
    this.checkUndefinedAndApply({ text, offsetX, offsetY }, dto);
    return dto;
  }

  fromDto(dto: ApexNoDataDto): void {
    const { text, align, verticalAlign, offsetX, offsetY, style } = dto;
    this.checkUndefinedAndApply({ text, offsetX, offsetY, style }, this);
    align && (this.align = new SelectOption(align));
    verticalAlign && (this.verticalAlign = new SelectOption(verticalAlign));
  }

  isDefined(): boolean {
    return this.text.length > 0;
  }

  toChartOptions(): ApexNoData {
    const data = {
      text: this.text,
      align: this.align.value,
      verticalAlign: this.verticalAlign.value,
      style: this.style,
    } as ApexNoData;

    this.offsetX && (data.offsetX = this.offsetX);
    this.offsetY && (data.offsetY = this.offsetY);

    return data;
  }

  private checkUndefinedAndApply(fields: Record<string, any>, dest: any): void {
    Object.keys(fields).forEach(key => {
      fields[key] && (dest[key] = fields[key]);
    });
  }
}

export enum ApexNoDataAlign {
  'left' = 'left',
  'right' = 'right',
  'center' = 'center',
}

export enum ApexNoDataVerticalAlign {
  'top' = 'top',
  'middle' = 'middle',
  'bottom' = 'bottom',
}

export interface ApexNoDataDto {
  text?: string;
  align?: ApexNoDataAlign;
  verticalAlign?: ApexNoDataVerticalAlign;
  offsetX?: number;
  offsetY?: number;
  style?: FontStyles;
}
