import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactFormatFileDataResponseDto } from '@api/models';

@Pipe({ name: 'fileArtifactDescription' })
export class FileArtifactDescriptionPipe implements PipeTransform {
  transform(artifactFileInformation: Blob | null | ArtifactFormatFileDataResponseDto): string {
    if (artifactFileInformation)
      return `${(artifactFileInformation as File)?.name || (artifactFileInformation as ArtifactFormatFileDataResponseDto).filename}[${
        artifactFileInformation.size
      }B]`;
    return '---';
  }
}
