import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { WorkflowRule } from '@workflows/types';

export class WorkflowModel {
  rule: WorkflowRule;
  isCreateMode = true;
  options = new WorkflowOptions();
  inProgress: boolean;
}

export class WorkflowOptions {
  attributes: ListContainer<NewAttribute> = new ListContainer();
  dataTypes: ListContainer<NewDataType> = new ListContainer();
  artifactTypes: ListContainer<NewArtifactType> = new ListContainer();
  linkTypes: ListContainer<LinkType> = new ListContainer();
  pages: ListContainer<Page> = new ListContainer();
  applications: ListContainer<ApplicationResponseDto> = new ListContainer();
  users: ListContainer<NewUser> = new ListContainer();
}
