"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
});
var e = require("./token");
function t(e, t) {
  for (var n = 0; n < e.length; n++) e[n] += t;
  return e;
}
var n = {
    0: !0,
    1: !0,
    3: !0,
    4: !0,
    6: !0,
    8: !0,
    9: !0,
    12: !0,
    13: !0,
    14: !0
  },
  s = {
    0: !0,
    1: !0,
    2: !0,
    3: !0,
    4: !0,
    5: !0,
    6: !0,
    7: !0,
    8: !0,
    9: !0,
    10: !0,
    11: !0,
    12: !0,
    13: !0
  },
  o = {
    0: !0,
    3: !0,
    4: !0,
    8: !0,
    12: !0,
    13: !0
  },
  p = {},
  r = {
    0: !0,
    1: !0,
    3: !0,
    4: !0,
    6: !0,
    8: !0,
    12: !0,
    13: !0
  },
  T = {
    1: !0
  },
  h = [[], ["1", "2", "3", "7", "8", "9", "4", "5", "6", "+", "-", "*", "/", "(", ")", "^", "!", "P", "C", "e", "0", ".", ",", "n", " ", "&"], ["pi", "ln", "Pi"], ["sin", "cos", "tan", "Del", "int", "Mod", "log", "pow"], ["asin", "acos", "atan", "cosh", "root", "tanh", "sinh"], ["acosh", "atanh", "asinh", "Sigma"]];
function E(e, t, n, s) {
  for (var o = 0; o < s; o++) if (e[n + o] !== t[o]) return !1;
  return !0;
}
function N(e, t) {
  for (var n = 0; n < t.length; n++) if (t[n].token === e) return n;
  return -1;
}
exports.addToken = function (t) {
  for (var n = 0; n < t.length; n++) {
    var s = t[n].token.length,
      o = -1;
    t[n].type === e.tokenTypes.FUNCTION_WITH_N_ARGS && void 0 === t[n].numberOfArguments && (t[n].numberOfArguments = 2), h[s] = h[s] || [];
    for (var p = 0; p < h[s].length; p++) if (t[n].token === h[s][p]) {
      o = N(h[s][p], this.tokens);
      break;
    }
    -1 === o ? (this.tokens.push(t[n]), t[n].precedence = e.preced[t[n].type], h.length <= t[n].token.length && (h[t[n].token.length] = []), h[t[n].token.length].push(t[n].token)) : (this.tokens[o] = t[n], t[n].precedence = e.preced[t[n].type]);
  }
}, exports.lex = function (u, a) {
  var y,
    _ = {
      value: this.math.changeSign,
      type: e.tokenTypes.FUNCTION_WITH_ONE_ARG,
      precedence: 4,
      show: "-"
    },
    i = {
      value: ")",
      show: ")",
      type: e.tokenTypes.CLOSING_PARENTHESIS,
      precedence: 0
    },
    A = {
      value: "(",
      type: e.tokenTypes.OPENING_PARENTHESIS,
      precedence: 0,
      show: "("
    },
    O = [A],
    R = [],
    l = u,
    k = n,
    I = 0,
    f = p,
    P = "";
  void 0 !== a && this.addToken(a);
  var c = function (e, t) {
    for (var n, s, o, p = [], r = t.length, T = 0; T < r; T++) if (!(T < r - 1 && " " === t[T] && " " === t[T + 1])) {
      for (n = "", s = t.length - T > h.length - 2 ? h.length - 1 : t.length - T; s > 0; s--) if (void 0 !== h[s]) for (o = 0; o < h[s].length; o++) E(t, h[s][o], T, s) && (n = h[s][o], o = h[s].length, s = 0);
      if (T += n.length - 1, "" === n) throw new Error("Can't understand after " + t.slice(T));
      p.push(e.tokens[N(n, e.tokens)]);
    }
    return p;
  }(this, l);
  for (y = 0; y < c.length; y++) {
    var C = c[y];
    if (14 !== C.type) {
      var g,
        S = C.token,
        d = C.type,
        v = C.value,
        w = C.precedence,
        U = C.show,
        m = O[O.length - 1];
      for (g = R.length; g-- && 0 === R[g];) if (-1 !== [e.tokenTypes.FUNCTION_WITH_ONE_ARG, e.tokenTypes.BINARY_OPERATOR_HIGH_PRECENDENCE, e.tokenTypes.CONSTANT, e.tokenTypes.OPENING_PARENTHESIS, e.tokenTypes.CLOSING_PARENTHESIS, e.tokenTypes.BINARY_OPERATOR_LOW_PRECENDENCE, e.tokenTypes.BINARY_OPERATOR_PERMUTATION, e.tokenTypes.COMMA, e.tokenTypes.EVALUATED_FUNCTION, e.tokenTypes.EVALUATED_FUNCTION_PARAMETER].indexOf(d)) {
        if (!0 !== k[d]) throw new Error(S + " is not allowed after " + P);
        O.push(i), k = s, f = r, R.pop();
      }
      if (!0 !== k[d]) throw new Error(S + " is not allowed after " + P);
      !0 === f[d] && (d = e.tokenTypes.BINARY_OPERATOR_HIGH_PRECENDENCE, v = this.math.mul, U = "&times;", w = 3, y -= 1);
      var H = {
        value: v,
        type: d,
        precedence: w,
        show: U,
        numberOfArguments: C.numberOfArguments
      };
      if (d === e.tokenTypes.FUNCTION_WITH_ONE_ARG) k = n, f = p, t(R, 2), O.push(H), c[y + 1].type !== e.tokenTypes.OPENING_PARENTHESIS && (O.push(A), R.push(2));else if (d === e.tokenTypes.NUMBER) m.type === e.tokenTypes.NUMBER ? (m.value += v, t(R, 1)) : O.push(H), k = s, f = o;else if (d === e.tokenTypes.BINARY_OPERATOR_HIGH_PRECENDENCE) k = n, f = p, t(R, 2), O.push(H);else if (d === e.tokenTypes.CONSTANT) O.push(H), k = s, f = r;else if (d === e.tokenTypes.OPENING_PARENTHESIS) t(R, 1), I++, k = n, f = p, O.push(H);else if (d === e.tokenTypes.CLOSING_PARENTHESIS) {
        if (!I) throw new Error("Closing parenthesis are more than opening one, wait What!!!");
        I--, k = s, f = r, O.push(H), t(R, 1);
      } else if (d === e.tokenTypes.DECIMAL) {
        if (m.hasDec) throw new Error("Two decimals are not allowed in one number");
        m.type !== e.tokenTypes.NUMBER && (m = {
          show: "0",
          value: 0,
          type: e.tokenTypes.NUMBER,
          precedence: 0
        }, O.push(m)), k = T, t(R, 1), f = p, m.value += v, m.hasDec = !0;
      } else d === e.tokenTypes.POSTFIX_FUNCTION_WITH_ONE_ARG && (k = s, f = r, t(R, 1), O.push(H));
      d === e.tokenTypes.FUNCTION_WITH_N_ARGS ? (k = n, f = p, t(R, C.numberOfArguments + 2), O.push(H), c[y + 1].type !== e.tokenTypes.OPENING_PARENTHESIS && (O.push(A), R.push(C.numberOfArguments + 2))) : d === e.tokenTypes.BINARY_OPERATOR_LOW_PRECENDENCE ? (m.type === e.tokenTypes.BINARY_OPERATOR_LOW_PRECENDENCE ? m.value === this.math.add ? (m.value = v, m.show = U, t(R, 1)) : m.value === this.math.sub && "-" === U && (m.value = this.math.add, m.show = "+", t(R, 1)) : m.type !== e.tokenTypes.CLOSING_PARENTHESIS && m.type !== e.tokenTypes.POSTFIX_FUNCTION_WITH_ONE_ARG && m.type !== e.tokenTypes.NUMBER && m.type !== e.tokenTypes.CONSTANT && m.type !== e.tokenTypes.EVALUATED_FUNCTION_PARAMETER ? "-" === S && (k = n, f = p, t(R, 1).push(2), O.push(_), O.push(A)) : (O.push(H), t(R, 2)), k = n, f = p) : d === e.tokenTypes.BINARY_OPERATOR_PERMUTATION ? (k = n, f = p, t(R, 2), O.push(H)) : d === e.tokenTypes.COMMA ? (k = n, f = p, O.push(H)) : d === e.tokenTypes.EVALUATED_FUNCTION ? (k = n, f = p, t(R, 6), O.push(H), c[y + 1].type !== e.tokenTypes.OPENING_PARENTHESIS && (O.push(A), R.push(6))) : d === e.tokenTypes.EVALUATED_FUNCTION_PARAMETER && (k = s, f = r, O.push(H)), t(R, -1), P = S;
    } else if (y > 0 && y < c.length - 1 && 1 === c[y + 1].type && (1 === c[y - 1].type || 6 === c[y - 1].type)) throw new Error("Unexpected Space");
  }
  for (g = R.length; g--;) O.push(i);
  if (!0 !== k[5]) throw new Error("complete the expression");
  for (; I--;) O.push(i);
  return O.push(i), O;
};