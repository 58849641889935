<div class="pages_wrapper">
  <div class="pages_header">
    <div>
      <button pButton icon="pi pi-list" class="p-button-rounded" (click)="m.selectPages === true ? (m.selectPages = false) : (m.selectPages = true)"></button>
    </div>
    <div class="pages_header_buttons">
      <div class="mr">
        <button pButton [label]="'Export' | translate" icon="pi pi-cloud-download" (click)="m.exportModalOpened = true"></button>
      </div>
      <app-export-modal
        headerTitle="Export pages"
        [(visible)]="m.exportModalOpened"
        [selectedParts]="table?.selectedPages || []"
        [exportPartsMethod]="s.getExportMethod()"
      ></app-export-modal>

      <div class="mr">
        <button pButton [label]="'Import' | translate" icon="pi pi-cloud-upload" (click)="m.importModalOpened = true"></button>
      </div>
      <app-import-modal
        headerTitle="Import pages"
        inputTypeCheck="page"
        [(visible)]="m.importModalOpened"
        [importPartsMethod]="s.getImportMethod()"
      ></app-import-modal>

      <a [routerLink]="['/admin/page-builder']">
        <button pButton label="{{ 'Add' | translate }}" class="p-button" icon="pi pi-plus" iconPos="left"></button>
      </a>
    </div>
  </div>

  <app-pages-table (onCopy)="onCopy($event)" [columns]="m.columns" [applications]="m.applications" [selectPages]="m.selectPages" [loadDataMethod]="loadData()"></app-pages-table>
</div>
