import { Pipe, PipeTransform } from '@angular/core';
import { ListMetaData } from '@shared/core/types/core.types';
import { NewTableColumn } from '@shared/types/table.types';
import { GroupItem } from '../types/list-widget-grouping.types';
import { SummaryItem } from '../types/list-widget-settings.types';

@Pipe({ name: 'getArtifactsSummary' })
export class GetArtifactsSummaryPipe implements PipeTransform {
  transform(
    column: NewTableColumn,
    attributeId: string | undefined,
    summaryItems: Record<string, SummaryItem>,
    meta: ListMetaData,
    isGroupingActive: boolean,
    groupItem: GroupItem,
  ): string {
    if (!attributeId || !summaryItems[attributeId]?.isNumeric) return '';
    if (isGroupingActive) return 'Total: ' + summaryItems[attributeId].groupSummary[groupItem.id];
    return summaryItems[attributeId].totalSummary + '';
  }
}
