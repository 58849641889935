import { UserTenantResponseDto } from '@api/models';
import { RecordDto } from '@api/models/record-dto';
import { SystemUserResponseDto } from '@api/models/system-user-response-dto';
import { SystemUserTenantResponseDto } from '@api/models/system-user-tenant-response-dto';
import { UserFullResponseDto } from '@api/models/user-full-response-dto';
import { UserFullTenantResponseDto } from '@api/models/user-full-tenant-response-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import { ClientData } from '@shared/types/local-storage.types';

export class NewSystemUser implements SystemUserResponseDto {
  id: string;
  email: string;
  isSystemAdmin = false;
  isLoginDisabled = false;
  consecutiveFailedLoginAttempts: number;
  failedLoginAttempts: number;
  lastFailedLoginDate: null | string;
  isGuest: boolean;
  tenant: SystemUserTenantResponseDto;
  clientData: ClientData;
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto;
  teamIds: Array<string>;
  defaultTeamId: string;

  constructor(user?: Partial<SystemUserResponseDto>) {
    user && Object.assign(this, user);
  }
}

export class UserFull implements UserFullResponseDto {
  id: string;
  email: string;
  isLoginDisabled: boolean;
  tenant: UserFullTenantResponseDto;
  clientData: ClientData;
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto = null;
  teamIds: Array<string>;
  defaultTeamId: string;

  constructor(user?: Partial<UserFullResponseDto>) {
    user && Object.assign(this, user);
  }
}

export class NewUser implements UserResponseDto {
  id: string;
  email: string;
  teamIds: Array<string>;
  defaultTeamId: string;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null;
  tenant: UserTenantResponseDto | null;

  constructor(user?: Partial<UserResponseDto | UserFullResponseDto | SystemUserResponseDto>) {
    user && Object.assign(this, user);
  }
}

export class TeamsWithUsers {
  teamsMap: Record<string, Set<NewUser>>;

  constructor(users: NewUser[]) {
    const teamsWithUserMap: Record<string, Set<NewUser>> = {};

    users.forEach(user => {
      user.tenant?.teamIds?.forEach(teamId => {
        !teamsWithUserMap[teamId] && (teamsWithUserMap[teamId] = new Set<NewUser>());
        teamsWithUserMap[teamId].add(user);
      });
    });

    this.teamsMap = teamsWithUserMap;
  }

  getUniqueUsersFromTeams(teamIds: string[]): NewUser[] {
    return Array.from(new Set([...teamIds.map(id => [...this.teamsMap[id]]).flat()]));
  }
}
