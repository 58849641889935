import { Injectable } from '@angular/core';
import { UserResponseDto } from '@api/models/user-response-dto';
import { TenantTeamService, TenantUserService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { Exception } from '@shared/types/exception.types';
import { NewTeam } from '@shared/types/team.types';
import { NewUser } from '@shared/types/user.types';
import { lastValueFrom } from 'rxjs';
import { TeamComponent } from '../team.component';
import { TeamPageModel } from '../types/teams-page.types';

@Injectable()
export class TeamService extends CoreService<TeamComponent, TeamPageModel> {
  constructor(
    private readonly cache: NewCacheService,
    private readonly blockUiService: BlockUiService,
    private readonly tenantTeamService: TenantTeamService,
    private readonly tenantUserService: TenantUserService,
  ) {
    super();
  }

  async init(context: TeamComponent, model: TeamPageModel): Promise<void> {
    super.init(context, model);
    this.c = context;
    this.m = model;

    this.initSubscriptions();

    try {
      this.m.loading = true;
      if (this.c.urlParams.id) {
        await this.fetchTeam(this.c.urlParams.id);
      } else {
        this.initNewTeam();
      }
    } finally {
      this.m.loading = false;
    }
  }

  async tryCatch(fn: () => void, name: string, message: string): Promise<void> {
    this.setUiBlocked(this.m, true);
    try {
      await fn();
      await this.c.router.navigateByUrl('/admin/team-list');
    } catch (e) {
      await this.c.announcement.error(name + message);
      console.error(
        new Exception({
          name,
          message,
          originalEvent: e,
        }),
      );
    } finally {
      this.setUiBlocked(this.m, false);
    }
  }

  async delete(): Promise<void> {
    await this.tryCatch(
      async () => {
        await this.tenantTeamService.teamControllerDelete({ id: this.m.team.id }).toPromise();
        await this.removeUsersFromTeam(true);
      },
      'DeleteTeamException',
      'An error occurred while deleting a team',
    );
  }

  async create(): Promise<void> {
    await this.tryCatch(
      async () => {
        const res = await lastValueFrom(
          this.tenantTeamService.teamControllerCreate({
            body: {
              name: this.m.team.name,
              description: this.m.team.description,
            },
          }),
        );
        this.cache.data.teams.setItem(res);
        this.m.team.id = res.id;
        await this.updateUsers();
      },
      'CreateTeamException',
      'An error occurred while create a team',
    );
  }

  async update(): Promise<void> {
    await this.tryCatch(
      async () => {
        await lastValueFrom(
          this.tenantTeamService.teamControllerUpdate({
            body: {
              id: this.m.team.id,
              name: this.m.team.name,
              description: this.m.team.description,
            },
          }),
        ).then(dto => this.cache.data.teams.setItem(dto));
        await this.updateUsers();
      },
      'UpdateTeamException',
      'An error occurred while updated a team',
    );
  }

  private setUiBlocked(m: TeamPageModel, blocked: boolean): void {
    m.inSavingProgress = blocked;
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }

  private async removeUsersFromTeam(isFromTeammate?: boolean): Promise<void> {
    const userList = isFromTeammate ? this.m.teamUsers : this.m.noTeamUsers;
    const teamId = this.m.team.id;
    const userToRemoveFromTeam: NewUser[] = userList.filter(u => u.tenant?.teamIds.includes(teamId));

    const updateRemoveMeta = userToRemoveFromTeam.map(u => {
      const teamIds: string[] = (u?.tenant?.teamIds || []).filter((id: string) => id !== teamId);
      return { id: u.id, teamIds, applications: u.tenant?.applications || [] };
    });

    await Promise.all(
      updateRemoveMeta.map(meta =>
        lastValueFrom(this.tenantUserService.userControllerUpdateUserByTenantAdmin({ body: { ...meta } })).then(dto => this.cache.data.users.setItem(dto)),
      ),
    );
  }

  private async updateUsers(): Promise<void> {
    const teamId = this.m.team.id;

    const updateAddMeta = this.m.teamUsers
      .filter(u => !this.m.initTeamUserIds.includes(u.id))
      .map((u: any) => {
        const teamIds: string[] = u?.tenant?.teamIds || [];
        !teamIds.includes(teamId) && teamIds.push(teamId);
        return { id: u.id, teamIds };
      });

    await Promise.all(
      updateAddMeta.map(meta =>
        lastValueFrom(this.tenantUserService.userControllerUpdateUserByTenantAdmin({ body: { ...meta } })).then(dto => this.cache.data.users.setItem(dto)),
      ),
    );
    await this.removeUsersFromTeam();
  }

  private async fetchTeam(id: string): Promise<void> {
    const res = await this.cache.data.teams.getAsync(id);
    this.m.team = new NewTeam(res);
    this.sortUsers();
  }

  private initNewTeam(): void {
    this.m.team = new NewTeam();
  }

  private sortUsers(): void {
    if (!this.m.userList) {
      return;
    }

    if (!this.m.team.id) {
      this.m.teamUsers = [];
      this.m.noTeamUsers = this.m.userList.map(u => u);
    } else {
      const ids = this.m.userList.filter(u => u.tenant?.teamIds && u.tenant.teamIds.includes(this.m.team.id)).map(u => u.id);
      this.m.teamUsers = this.m.userList.filter(u => ids.includes(u.id));
      this.m.noTeamUsers = this.m.userList.filter(u => !ids.includes(u.id));
    }

    this.m.initTeamUserIds = this.m.teamUsers.map(u => u.id);
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.data.users.subscribe(users => {
        this.m.userList = users!.map(dto => new NewUser(dto as UserResponseDto));
        this.sortUsers();
      }),
    ]);
  }
}
