import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import {
  URL_KEY_MODULE_INSERT_INTO,
  URL_KEY_MODULE_INSERT_IS_HEADING,
  URL_KEY_MODULE_INSERT_PARENT_ID,
  URL_KEY_VALUE_SAVE_TO_FOLDER_ID,
} from '@shared/constants/constants';
import { NewArtifact } from '@shared/types/artifact.types';
import { EMPTY, Observable } from 'rxjs';
import { GetModuleQueryParametersParams, ModuleParentIdAndIntoParams } from '../types/artifact-list-widget-table.types';
import { ListWidgetTableLoadModeEnum } from '../types/list-widget-settings.types';
import { AddArtifactModulePositionEnum } from '../types/list-widget.types';
import { ArtifactModuleService } from './artifact-module.service';

@Injectable({ providedIn: 'root' })
export class ArtifactModuleHelper {
  constructor(private readonly moduleService: ArtifactModuleService) {}

  shouldUpdateModuleHeading(updatedArtifact: NewArtifact, artifact: NewArtifact): boolean {
    return !!updatedArtifact.moduleData && !!artifact.moduleData && updatedArtifact.moduleData.isHeading !== artifact.moduleData.isHeading;
  }

  isLoadByModule(loadMode: ListWidgetTableLoadModeEnum): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byModule;
  }

  onModuleRowDrop(event: CdkDragDrop<void>, data: NewArtifact[]): Observable<ArtifactResponseDto> {
    const { currentIndex, previousIndex } = event;
    const currentIndexWithDirection = currentIndex < previousIndex ? currentIndex - 1 : currentIndex;

    const artifact = data[previousIndex];
    const previousArtifact = data[currentIndexWithDirection];

    if (!artifact.moduleData || currentIndexWithDirection === previousIndex) return EMPTY;

    return this.moduleService.updateDataAfterDragAndDrop$(artifact, previousArtifact);
  }

  getQueryParamsForArtifactWidget(params: GetModuleQueryParametersParams): Params {
    const { newModuleArtifact, selectedRowArtifact, settings, data } = params;
    if (newModuleArtifact.position === AddArtifactModulePositionEnum.BEFORE) {
      const { id, into } = this.getPreviousParentIdWithSameLevel(selectedRowArtifact.artifact!, selectedRowArtifact.index, data);
      return {
        [settings.urlKeys.emittingKeys.saveToFolderId || URL_KEY_VALUE_SAVE_TO_FOLDER_ID]: settings.folderId,
        [URL_KEY_MODULE_INSERT_IS_HEADING]: newModuleArtifact.isHeading,
        [URL_KEY_MODULE_INSERT_INTO]: into,
        [URL_KEY_MODULE_INSERT_PARENT_ID]: id,
      };
    }

    if (newModuleArtifact.position === AddArtifactModulePositionEnum.INTO) {
      return {
        [settings.urlKeys.emittingKeys.saveToFolderId || URL_KEY_VALUE_SAVE_TO_FOLDER_ID]: settings.folderId,
        [URL_KEY_MODULE_INSERT_IS_HEADING]: newModuleArtifact.isHeading,
        [URL_KEY_MODULE_INSERT_INTO]: true,
        [URL_KEY_MODULE_INSERT_PARENT_ID]: selectedRowArtifact.artifact?.id || null,
      };
    }

    return {
      [settings.urlKeys.emittingKeys.saveToFolderId || URL_KEY_VALUE_SAVE_TO_FOLDER_ID]: settings.folderId,
      [URL_KEY_MODULE_INSERT_IS_HEADING]: newModuleArtifact.isHeading,
      [URL_KEY_MODULE_INSERT_INTO]: false,
      [URL_KEY_MODULE_INSERT_PARENT_ID]: selectedRowArtifact?.artifact?.id || null,
    };
  }

  private getPreviousParentIdWithSameLevel(artifact: NewArtifact, index: number, data: NewArtifact[]): ModuleParentIdAndIntoParams {
    const { isHeading, sequenceInParent, headingArtifactId, level } = artifact.moduleData!;

    return isHeading
      ? this.getPreviousParentForHeading(level, headingArtifactId, index, data)
      : this.getPreviousParentForNonHeading(sequenceInParent, headingArtifactId, index, data);
  }

  private getPreviousParentForNonHeading(
    sequenceInParent: number,
    headingArtifactId: string | null,
    index: number,
    data: NewArtifact[],
  ): { id: string; into: boolean } {
    const id = sequenceInParent > 0 ? data[index - 1].id : headingArtifactId;
    const into = !!id && sequenceInParent === 0;

    return { id: id || 'null', into };
  }

  private getPreviousParentForHeading(level: number[], headingArtifactId: string | null, index: number, data: NewArtifact[]) {
    const previousLevel = level[level.length - 1];

    if (!previousLevel) return { id: data[index - 1]?.id || 'null', into: false };

    const levelLength = level.length;
    const artifacts = data.slice(0, index).reverse();
    const parentArtifact = this.findLatestParentWithSameLevel(artifacts, previousLevel - 1, levelLength - 1);

    if (parentArtifact?.id) {
      return { id: parentArtifact.id, into: false };
    }
    if (headingArtifactId) {
      return { id: headingArtifactId, into: true };
    }
    return { id: 'null', into: false };
  }

  private findLatestParentWithSameLevel(artifacts: NewArtifact[], previousLevel: number, levelLength: number): NewArtifact | null {
    return (
      artifacts.find(
        artifact => artifact.moduleData && artifact.moduleData.level.length === levelLength + 1 && artifact.moduleData?.level[levelLength] === previousLevel,
      ) || null
    );
  }
}
