"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
});
exports.createMathFunctions = function (t) {
  return {
    isDegree: !0,
    acos: function (n) {
      return t.math.isDegree ? 180 / Math.PI * Math.acos(n) : Math.acos(n);
    },
    add: function (t, n) {
      return t + n;
    },
    asin: function (n) {
      return t.math.isDegree ? 180 / Math.PI * Math.asin(n) : Math.asin(n);
    },
    atan: function (n) {
      return t.math.isDegree ? 180 / Math.PI * Math.atan(n) : Math.atan(n);
    },
    acosh: function (t) {
      return Math.log(t + Math.sqrt(t * t - 1));
    },
    asinh: function (t) {
      return Math.log(t + Math.sqrt(t * t + 1));
    },
    atanh: function (t) {
      return Math.log((1 + t) / (1 - t));
    },
    C: function (n, r) {
      var a = 1,
        o = n - r,
        e = r;
      e < o && (e = o, o = r);
      for (var u = e + 1; u <= n; u++) a *= u;
      var i = t.math.fact(o);
      return "NaN" === i ? "NaN" : a / i;
    },
    changeSign: function (t) {
      return -t;
    },
    cos: function (n) {
      return t.math.isDegree && (n = t.math.toRadian(n)), Math.cos(n);
    },
    cosh: function (t) {
      return (Math.pow(Math.E, t) + Math.pow(Math.E, -1 * t)) / 2;
    },
    div: function (t, n) {
      return t / n;
    },
    fact: function (t) {
      if (t % 1 != 0) return "NaN";
      for (var n = 1, r = 2; r <= t; r++) n *= r;
      return n;
    },
    inverse: function (t) {
      return 1 / t;
    },
    log: function (t) {
      return Math.log(t) / Math.log(10);
    },
    mod: function (t, n) {
      return t % n;
    },
    mul: function (t, n) {
      return t * n;
    },
    P: function (t, n) {
      for (var r = 1, a = Math.floor(t) - Math.floor(n) + 1; a <= Math.floor(t); a++) r *= a;
      return r;
    },
    Pi: function (n, r, a) {
      for (var o = 1, e = n; e <= r; e++) o *= Number(t.postfixEval(a, {
        n: e
      }));
      return o;
    },
    pow10x: function (t) {
      for (var n = 1; t--;) n *= 10;
      return n;
    },
    sigma: function (n, r, a) {
      for (var o = 0, e = n; e <= r; e++) o += Number(t.postfixEval(a, {
        n: e
      }));
      return o;
    },
    sin: function (n) {
      return t.math.isDegree && (n = t.math.toRadian(n)), Math.sin(n);
    },
    sinh: function (t) {
      return (Math.pow(Math.E, t) - Math.pow(Math.E, -1 * t)) / 2;
    },
    sub: function (t, n) {
      return t - n;
    },
    tan: function (n) {
      return t.math.isDegree && (n = t.math.toRadian(n)), Math.tan(n);
    },
    tanh: function (n) {
      return t.math.sinh(n) / t.math.cosh(n);
    },
    toRadian: function (t) {
      return t * Math.PI / 180;
    },
    and: function (t, n) {
      return t & n;
    }
  };
};