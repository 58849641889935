import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ChangePasswordResponseDto } from '@api/models/change-password-response-dto';
import { AuthService } from '@api/services/auth.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { UserService } from '../../../user-management/user/services/user.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  providers: [UserService],
})
export class ResetPasswordDialogComponent implements OnInit {
  @Output() onSave = new EventEmitter();

  resetPasswordForm: any;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private announcement: AnnouncementService) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.resetPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(5)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(5)]],
        oldPassword: ['', [Validators.required, Validators.minLength(5)]],
      },
      { validators: [this.checkPasswordsMatch] },
    );
  }

  checkPasswordsMatch: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const passwordControl = group.get('password');
    const confirmPasswordControl = group.get('confirmPassword');

    if (passwordControl?.dirty && confirmPasswordControl?.dirty) {
      return passwordControl.value === confirmPasswordControl.value ? null : { notSame: true };
    }
    return null;
  };

  save(): void {
    const changePasswordRequestPayload = {
      newPassword: this.resetPasswordForm.get('password').value,
      oldPassword: this.resetPasswordForm.get('oldPassword').value,
    };

    this.changePassword(changePasswordRequestPayload).then(() => {
      this.onSave.emit();
    });
  }

  async changePassword(changePasswordRequestPayload: ChangePasswordResponseDto): Promise<void> {
    try {
      const result = await this.authService.authControllerChangePassword({ body: changePasswordRequestPayload }).toPromise();
      await this.announcement.success('Password reset successfully');
      return result;
    } catch (e) {
      console.log(e);
      await this.announcement.error('Password reset failed');
    }
  }
}
