import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getTextArtifactTypes' })
export class GetTextArtifactTypesPipe implements PipeTransform {
  transform(artifactTypes: ListContainer<NewArtifactType>): NewArtifactType[] {
    return artifactTypes.list.filter(artifactType => artifactType.format === ArtifactTypeFormatEnum.text);
  }
}
