import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewLink } from '@shared/types/link.types';
import { ArtifactLinks } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';

@Pipe({ name: 'toLinksPerArtifact' })
export class ToLinksPerArtifactPipe implements PipeTransform {
  transform(links: Record<string, Record<LinkDirection, NewLink[]>>, artifactId: string): Record<string, Record<string, ArtifactLinks>> | undefined {
    if (!artifactId) {
      return undefined;
    }
    const result: Record<string, Record<string, ArtifactLinks>> = {};
    Object.keys(links).forEach(linkTypeId => {
      result[linkTypeId] = {
        [artifactId]: {
          [LinkDirection.incoming]: links[linkTypeId].INCOMING,
          [LinkDirection.outgoing]: links[linkTypeId].OUTGOING,
        },
      };
    });
    return result;
  }
}
