import { Pipe, PipeTransform } from '@angular/core';
import { SystemTenantResponseDto } from '@api/models/system-tenant-response-dto';
import { SystemUserResponseDto } from '@api/models/system-user-response-dto';
import { DELETED_KEY, TENANT_KEY } from '@shared/constants/constants';
import { SharedMethods } from '@shared/methods/shared.methods';

@Pipe({ name: 'printSystemUserToTable' })
export class PrintSystemUserToTablePipe implements PipeTransform {
  transform(user: SystemUserResponseDto, key: string, tenantsMap: Record<string, SystemTenantResponseDto>): string | boolean {
    if (key === TENANT_KEY) return user.tenant ? tenantsMap[user.tenant.id].name : '-----';
    if (key === DELETED_KEY) return !!user.deleted;
    return SharedMethods.getValueFromPath(key, user);
  }
}
