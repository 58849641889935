import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { StateKey } from '@shared/types/local-storage.types';
import { SIDEBAR_MODAL_STATE } from '@shared/constants/constants';

@Injectable()
export class SidebarModalStateService {
  constructor(private localStorageService: LocalStorageService) {}

  setState(id: string, isOpen: boolean): void {
    const data = this.localStorageService.getFromState(StateKey.session, SIDEBAR_MODAL_STATE);
    const modalStateMap = data ? JSON.parse(data) : {};
    !modalStateMap[id] && (modalStateMap[id] = null);
    modalStateMap[id] = isOpen;

    this.localStorageService.setToState(StateKey.session, SIDEBAR_MODAL_STATE, JSON.stringify(modalStateMap));
  }

  getState(id: string): boolean | null {
    const data = this.localStorageService.getFromState(StateKey.session, SIDEBAR_MODAL_STATE);
    if (!data) return null;

    const modalStateMap = JSON.parse(data);
    return modalStateMap[id] !== null ? modalStateMap[id] : null;
  }
}
