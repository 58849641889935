import { DataTypeValueResponseDto } from '@api/models';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATEnumLayoutVariant, DATTextFilterWidgetLayoutVariant } from '@shared/types/display-at-types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';

export class FilterWidgetOptions {
  attributes: NewAttribute[] = [];
  attributeValues = new ListContainer<NewAttribute>();
  dataTypes = new ListContainer<NewDataType>();
  users = new ListContainer<NewUser>();
  tagList = new ListContainer<FilterItem>();
  layoutVariant = new ListContainer<any>();
  textLayoutVariant = new ListContainer<any>();

  constructor() {
    this.layoutVariant.setList(Object.keys(DATEnumLayoutVariant).map(key => new VariantDropdownItem(DATEnumLayoutVariant, key)));
    this.textLayoutVariant.setList(Object.keys(DATTextFilterWidgetLayoutVariant).map(key => new VariantDropdownItem(DATTextFilterWidgetLayoutVariant, key)));
  }
}

export class VariantDropdownItem {
  label: string;
  code: string;

  constructor(enumObj: any, key: string) {
    this.label = enumObj[key];
    this.code = key;
  }
}

export class FilterItem implements DataTypeValueResponseDto {
  backgroundColor: string | null;
  icon: string;
  label: string;
  textColor: string | null;
  iconColor: string | null;
  uri: string;
  value: string;
  styles: any;

  constructor(dto: DataTypeValueResponseDto) {
    dto && Object.assign(this, dto);
  }
}
