<div class="dynamic-label p-float-label">
  <p-dropdown [(ngModel)]="m.settings.widgetType"
              [options]="m.options.widgetTypes.list | transformArrayToSelectOptions"
              (onChange)="onWidgetTypeChange($event.value)"
              id="widgetTypeSelect"
              appendTo="body">
  </p-dropdown>
  <label class="dynamic-label-text" for="widgetTypeSelect" translate> Widget type </label>
</div>

<div class="selection artifact-type-selection"
     *ngIf="!(m.settings.widgetType | isLoginWidgetType) && !(m.settings.widgetType | isRecoverPasswordWidgetType)">
  <div class="dynamic-label p-float-label"
       [ngClass]="{'half-width': (m.settings.widgetType | isArtifactWidgetType) || (m.settings.widgetType | isRegistrationWidgetType)}">
    <p-dropdown (onChange)="onArtifactTypeChange()"
                [(ngModel)]="m.selected.artifactType"
                [options]="m.options.artifactTypes.list | transformArrayToSelectOptions : NAME_VALUE"
                [autoDisplayFirst]="false"
                [filter]="true"
                [disabled]="m.settings.widgetType | isRegistrationWidgetType"
                id="artifactTypeSelect"
                appendTo="body">
    </p-dropdown>
    <label class="dynamic-label-text" for="artifactTypeSelect" translate>
      Artifact type
      <ng-container *ngIf="m.settings.widgetType | isLinkedArtifactWidgetType"> to create</ng-container>
    </label>
  </div>

  <button
    *ngIf="m?.selected?.artifactType && ((m.settings.widgetType | isArtifactWidgetType) || (m.settings.widgetType | isRegistrationWidgetType))"
    (click)="artifactWidgetHelper.addAttribute(m.form)"
    class="p-button" icon="pi pi-plus" iconPos="left" label="{{'Add' | translate }}" pButton>
  </button>
</div>

<div class="selection link-type-selection">
  <div *ngIf="this.m.selected.artifactType && (m.settings.widgetType | isLinkedArtifactWidgetType)"
       [ngClass]="{'half-width': (m.settings.widgetType | isLinkedArtifactWidgetType)}"
       class="dynamic-label p-float-label">
    <p-dropdown [(ngModel)]="m.selected.linkType"
                [options]="m.options.linkedArtifactLinkTypeOptions"
                [filter]="true"
                [autoDisplayFirst]="false"
                id="linkTypeSelect"
                optionLabel="label"
                appendTo="body">
      <ng-template let-option pTemplate="item">
        <app-link-option-with-arrow [label]="option.label" [direction]="option.meta"></app-link-option-with-arrow>
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
        <app-link-option-with-arrow [label]="option.label" [direction]="option.meta"></app-link-option-with-arrow>
      </ng-template>
    </p-dropdown>
    <label class="dynamic-label-text" for="linkTypeSelect" translate> Link type to create </label>
  </div>

  <button *ngIf="m?.selected?.linkType && (m.settings.widgetType | isLinkedArtifactWidgetType)"
          (click)="artifactWidgetHelper.addAttribute(m.form)"
          class="p-button" icon="pi pi-plus" iconPos="left" label="{{'Add' | translate }}" pButton>
  </button>
</div>
