import { DataTypeValueResponseDto } from '@api/models';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { EMPTY_GROUP_HEADER_SUFFIX, EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { GroupItem, GroupingSettings } from '../../types/list-widget-grouping.types';
import { AbstractGroupingHandler } from './abstract-grouping-handler';

export class EnumGroupingHandler extends AbstractGroupingHandler {
  enumeratedValuesMap: Record<string, DataTypeValueResponseDto>;
  enumValuesOrdered: string[];

  constructor(enumeratedValues: DataTypeValueResponseDto[]) {
    super();
    this.enumValuesOrdered = enumeratedValues.map(enumValue => enumValue.value);
    this.enumeratedValuesMap = enumeratedValues.reduce((map: Record<string, DataTypeValueResponseDto>, enumeration) => {
      map[enumeration.value] = enumeration;
      return map;
    }, {});
  }

  getAllGroups(groupingSettings: GroupingSettings): Record<string, GroupItem> {
    groupingSettings;
    const groupMap: Record<string, GroupItem> = {};

    Object.keys(this.enumeratedValuesMap).forEach((enumValue, index) => {
      groupMap[enumValue] = {
        id: enumValue,
        artifacts: [],
        metaData: { isDisplayed: true, count: 0, header: this.getGroupingHeader(enumValue), toggled: false },
        isDefaultGroup: false,
        sortOrder: index,
        total: 0,
      };
    });

    return groupMap;
  }

  getGroupingHeader(enumValue: string): string {
    return this.enumeratedValuesMap[enumValue]?.label || EMPTY_GROUP_HEADER_SUFFIX;
  }

  getGroupingValue(): string {
    return '';
  }

  sortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[] {
    const groupAttributeItem = groupingSettings.groupingAttributes[0].value;
    const numeric = groupAttributeItem.baseDataType === BaseDataType.integer;
    return this.doSortAndSetSortOrderValue(groupItems, groupingSettings, numeric, this.enumValuesOrdered);
  }

  getFilterMetadataForGroupItem(groupItem: GroupItem): FilterMetadata {
    return {
      matchMode: FilterMatchMode.CONTAINS,
      operator: FilterOperator.AND,
      value: [groupItem.id === EMPTY_GROUP_VALUE ? '' : groupItem.id],
    };
  }

  protected sortGroupItems(groupItems: GroupItem[], numeric: boolean, originalOrder: string[]): GroupItem[] {
    return groupItems.sort((a, b) => originalOrder.indexOf(a.id) - originalOrder.indexOf(b.id));
  }
}
