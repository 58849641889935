import { Pipe, PipeTransform } from '@angular/core';
import { TemplatesModel } from '@private/pages/template-management/templates/types/templates.types';
import { APPLICATION_ID_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getTemplateFilterOptions' })
export class GetTemplateFilterOptionsPipe implements PipeTransform {
  transform(columnKey: string, templatesModel: TemplatesModel): SelectOption<string, string>[] {
    if (columnKey === APPLICATION_ID_KEY) return templatesModel.applications.toSelectOptions(NAME_KEY, ID_KEY);
    return [];
  }
}
