import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NumericFilter, NumericFilterType, Query } from '@shared/services/filter/filter-types/numeric-filter';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({
  providedIn: 'root',
})
export class BaseNumericFilterService extends AbstractBaseTypeFilterService {
  constructor(protected readonly filterUtil: FilterUtil) {
    super(filterUtil);
  }

  getDefaultFilterRule(): NumericFilterType {
    return CoreListFilterEnum.equals;
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, values: string[], filterRule: NumericFilterType): Query | null {
    if (!values.length || !dataType) {
      return null;
    }

    const attributesDbFilterKey = this.filterUtil.getAttributesDbFilterKey(attributeKey);
    const filter = new NumericFilter(dataType, filterRule, attributesDbFilterKey, values);

    return filter.query;
  }

  getQueryFromRawText(attributeKey: string, dataType: NewDataType | null, value: any, delimiter?: string): Record<string, any> | null {
    const [filterRuleReadable, ...values] = value.split(delimiter);
    const filterRule = filterRuleReadable;

    return this.getQuery(attributeKey, dataType, values, filterRule);
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    switch (filterRule) {
      case CoreListFilterEnum.equals:
      case CoreListFilterEnum.notEquals:
      case CoreListFilterEnum.lessThan:
      case CoreListFilterEnum.lessThanOrEqualTo:
      case CoreListFilterEnum.greaterThan:
      case CoreListFilterEnum.greaterThanOrEqualTo:
        return true;
      default:
        return false;
    }
  }
}
