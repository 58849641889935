/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ArtifactAclResponseDto } from '../models/artifact-acl-response-dto';
import { artifactControllerAclInsert } from '../fn/tenant-artifact/artifact-controller-acl-insert';
import { ArtifactControllerAclInsert$Params } from '../fn/tenant-artifact/artifact-controller-acl-insert';
import { artifactControllerAclRemove } from '../fn/tenant-artifact/artifact-controller-acl-remove';
import { ArtifactControllerAclRemove$Params } from '../fn/tenant-artifact/artifact-controller-acl-remove';
import { artifactControllerCount } from '../fn/tenant-artifact/artifact-controller-count';
import { ArtifactControllerCount$Params } from '../fn/tenant-artifact/artifact-controller-count';
import { artifactControllerCreate } from '../fn/tenant-artifact/artifact-controller-create';
import { ArtifactControllerCreate$Params } from '../fn/tenant-artifact/artifact-controller-create';
import { artifactControllerCreateWithLink } from '../fn/tenant-artifact/artifact-controller-create-with-link';
import { ArtifactControllerCreateWithLink$Params } from '../fn/tenant-artifact/artifact-controller-create-with-link';
import { artifactControllerDelete } from '../fn/tenant-artifact/artifact-controller-delete';
import { ArtifactControllerDelete$Params } from '../fn/tenant-artifact/artifact-controller-delete';
import { artifactControllerDeleteBulk } from '../fn/tenant-artifact/artifact-controller-delete-bulk';
import { ArtifactControllerDeleteBulk$Params } from '../fn/tenant-artifact/artifact-controller-delete-bulk';
import { artifactControllerDownload } from '../fn/tenant-artifact/artifact-controller-download';
import { ArtifactControllerDownload$Params } from '../fn/tenant-artifact/artifact-controller-download';
import { artifactControllerExport } from '../fn/tenant-artifact/artifact-controller-export';
import { ArtifactControllerExport$Params } from '../fn/tenant-artifact/artifact-controller-export';
import { artifactControllerGet } from '../fn/tenant-artifact/artifact-controller-get';
import { ArtifactControllerGet$Params } from '../fn/tenant-artifact/artifact-controller-get';
import { artifactControllerGetAcl } from '../fn/tenant-artifact/artifact-controller-get-acl';
import { ArtifactControllerGetAcl$Params } from '../fn/tenant-artifact/artifact-controller-get-acl';
import { artifactControllerGetAvr } from '../fn/tenant-artifact/artifact-controller-get-avr';
import { ArtifactControllerGetAvr$Params } from '../fn/tenant-artifact/artifact-controller-get-avr';
import { artifactControllerGroupby } from '../fn/tenant-artifact/artifact-controller-groupby';
import { ArtifactControllerGroupby$Params } from '../fn/tenant-artifact/artifact-controller-groupby';
import { artifactControllerImport } from '../fn/tenant-artifact/artifact-controller-import';
import { ArtifactControllerImport$Params } from '../fn/tenant-artifact/artifact-controller-import';
import { artifactControllerList } from '../fn/tenant-artifact/artifact-controller-list';
import { ArtifactControllerList$Params } from '../fn/tenant-artifact/artifact-controller-list';
import { artifactControllerModuleDemote } from '../fn/tenant-artifact/artifact-controller-module-demote';
import { ArtifactControllerModuleDemote$Params } from '../fn/tenant-artifact/artifact-controller-module-demote';
import { artifactControllerModuleHeading } from '../fn/tenant-artifact/artifact-controller-module-heading';
import { ArtifactControllerModuleHeading$Params } from '../fn/tenant-artifact/artifact-controller-module-heading';
import { artifactControllerModuleInsert } from '../fn/tenant-artifact/artifact-controller-module-insert';
import { ArtifactControllerModuleInsert$Params } from '../fn/tenant-artifact/artifact-controller-module-insert';
import { artifactControllerModulePromote } from '../fn/tenant-artifact/artifact-controller-module-promote';
import { ArtifactControllerModulePromote$Params } from '../fn/tenant-artifact/artifact-controller-module-promote';
import { artifactControllerUpdate } from '../fn/tenant-artifact/artifact-controller-update';
import { ArtifactControllerUpdate$Params } from '../fn/tenant-artifact/artifact-controller-update';
import { artifactControllerUpdateBulk } from '../fn/tenant-artifact/artifact-controller-update-bulk';
import { ArtifactControllerUpdateBulk$Params } from '../fn/tenant-artifact/artifact-controller-update-bulk';
import { artifactControllerUpload } from '../fn/tenant-artifact/artifact-controller-upload';
import { ArtifactControllerUpload$Params } from '../fn/tenant-artifact/artifact-controller-upload';
import { artifactControllerUploadWithLink } from '../fn/tenant-artifact/artifact-controller-upload-with-link';
import { ArtifactControllerUploadWithLink$Params } from '../fn/tenant-artifact/artifact-controller-upload-with-link';
import { ArtifactCountResponseDto } from '../models/artifact-count-response-dto';
import { ArtifactGroupResponseDto } from '../models/artifact-group-response-dto';
import { ArtifactLinkResponseDto } from '../models/artifact-link-response-dto';
import { ArtifactListResponseDto } from '../models/artifact-list-response-dto';
import { ArtifactResponseDto } from '../models/artifact-response-dto';
import { JobsResponseDto } from '../models/jobs-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantArtifactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `artifactControllerGroupby()` */
  static readonly ArtifactControllerGroupbyPath = '/api/tenant/artifact/groupby';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerGroupby()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGroupby$Response(params: ArtifactControllerGroupby$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactGroupResponseDto>> {
    return artifactControllerGroupby(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerGroupby$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGroupby(params: ArtifactControllerGroupby$Params, context?: HttpContext): Observable<ArtifactGroupResponseDto> {
    return this.artifactControllerGroupby$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactGroupResponseDto>): ArtifactGroupResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerCount()` */
  static readonly ArtifactControllerCountPath = '/api/tenant/artifact/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerCount$Response(params?: ArtifactControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactCountResponseDto>> {
    return artifactControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerCount(params?: ArtifactControllerCount$Params, context?: HttpContext): Observable<ArtifactCountResponseDto> {
    return this.artifactControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactCountResponseDto>): ArtifactCountResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerExport()` */
  static readonly ArtifactControllerExportPath = '/api/tenant/artifact/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerExport$Response(params?: ArtifactControllerExport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return artifactControllerExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerExport(params?: ArtifactControllerExport$Params, context?: HttpContext): Observable<Blob> {
    return this.artifactControllerExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `artifactControllerImport()` */
  static readonly ArtifactControllerImportPath = '/api/tenant/artifact/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerImport$Response(params: ArtifactControllerImport$Params, context?: HttpContext): Observable<StrictHttpResponse<JobsResponseDto>> {
    return artifactControllerImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerImport(params: ArtifactControllerImport$Params, context?: HttpContext): Observable<JobsResponseDto> {
    return this.artifactControllerImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<JobsResponseDto>): JobsResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerDownload()` */
  static readonly ArtifactControllerDownloadPath = '/api/tenant/artifact/{id}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDownload$Response(params: ArtifactControllerDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return artifactControllerDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDownload(params: ArtifactControllerDownload$Params, context?: HttpContext): Observable<Blob> {
    return this.artifactControllerDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `artifactControllerGetAcl()` */
  static readonly ArtifactControllerGetAclPath = '/api/tenant/artifact/acl/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerGetAcl()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGetAcl$Response(params: ArtifactControllerGetAcl$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactAclResponseDto>> {
    return artifactControllerGetAcl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerGetAcl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGetAcl(params: ArtifactControllerGetAcl$Params, context?: HttpContext): Observable<ArtifactAclResponseDto> {
    return this.artifactControllerGetAcl$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactAclResponseDto>): ArtifactAclResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerGetAvr()` */
  static readonly ArtifactControllerGetAvrPath = '/api/tenant/artifact/{id}/{avrType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerGetAvr()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGetAvr$Response(params: ArtifactControllerGetAvr$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return artifactControllerGetAvr(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerGetAvr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGetAvr(params: ArtifactControllerGetAvr$Params, context?: HttpContext): Observable<Blob> {
    return this.artifactControllerGetAvr$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `artifactControllerGet()` */
  static readonly ArtifactControllerGetPath = '/api/tenant/artifact/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGet$Response(params: ArtifactControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactLinkResponseDto>> {
    return artifactControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerGet(params: ArtifactControllerGet$Params, context?: HttpContext): Observable<ArtifactLinkResponseDto> {
    return this.artifactControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactLinkResponseDto>): ArtifactLinkResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerDelete()` */
  static readonly ArtifactControllerDeletePath = '/api/tenant/artifact/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDelete$Response(params: ArtifactControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDelete(params: ArtifactControllerDelete$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerList()` */
  static readonly ArtifactControllerListPath = '/api/tenant/artifact/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerList$Response(params: ArtifactControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactListResponseDto>> {
    return artifactControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerList(params: ArtifactControllerList$Params, context?: HttpContext): Observable<ArtifactListResponseDto> {
    return this.artifactControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactListResponseDto>): ArtifactListResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerUpdate()` */
  static readonly ArtifactControllerUpdatePath = '/api/tenant/artifact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerUpdate$Response(params: ArtifactControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerUpdate(params: ArtifactControllerUpdate$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerCreate()` */
  static readonly ArtifactControllerCreatePath = '/api/tenant/artifact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerCreate$Response(params: ArtifactControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerCreate(params: ArtifactControllerCreate$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerCreateWithLink()` */
  static readonly ArtifactControllerCreateWithLinkPath = '/api/tenant/artifact/artifact-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerCreateWithLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerCreateWithLink$Response(params: ArtifactControllerCreateWithLink$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactLinkResponseDto>> {
    return artifactControllerCreateWithLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerCreateWithLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerCreateWithLink(params: ArtifactControllerCreateWithLink$Params, context?: HttpContext): Observable<ArtifactLinkResponseDto> {
    return this.artifactControllerCreateWithLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactLinkResponseDto>): ArtifactLinkResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerUpload()` */
  static readonly ArtifactControllerUploadPath = '/api/tenant/artifact/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerUpload$Response(params: ArtifactControllerUpload$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerUpload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerUpload(params: ArtifactControllerUpload$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerUpload$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerUploadWithLink()` */
  static readonly ArtifactControllerUploadWithLinkPath = '/api/tenant/artifact/upload-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerUploadWithLink()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerUploadWithLink$Response(params: ArtifactControllerUploadWithLink$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactLinkResponseDto>> {
    return artifactControllerUploadWithLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerUploadWithLink$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  artifactControllerUploadWithLink(params: ArtifactControllerUploadWithLink$Params, context?: HttpContext): Observable<ArtifactLinkResponseDto> {
    return this.artifactControllerUploadWithLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactLinkResponseDto>): ArtifactLinkResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerUpdateBulk()` */
  static readonly ArtifactControllerUpdateBulkPath = '/api/tenant/artifact/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerUpdateBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerUpdateBulk$Response(params: ArtifactControllerUpdateBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactListResponseDto>> {
    return artifactControllerUpdateBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerUpdateBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerUpdateBulk(params: ArtifactControllerUpdateBulk$Params, context?: HttpContext): Observable<ArtifactListResponseDto> {
    return this.artifactControllerUpdateBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactListResponseDto>): ArtifactListResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerDeleteBulk()` */
  static readonly ArtifactControllerDeleteBulkPath = '/api/tenant/artifact/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerDeleteBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDeleteBulk$Response(params: ArtifactControllerDeleteBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactListResponseDto>> {
    return artifactControllerDeleteBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerDeleteBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerDeleteBulk(params: ArtifactControllerDeleteBulk$Params, context?: HttpContext): Observable<ArtifactListResponseDto> {
    return this.artifactControllerDeleteBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactListResponseDto>): ArtifactListResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerAclInsert()` */
  static readonly ArtifactControllerAclInsertPath = '/api/tenant/artifact/acl/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerAclInsert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerAclInsert$Response(params: ArtifactControllerAclInsert$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactAclResponseDto>> {
    return artifactControllerAclInsert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerAclInsert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerAclInsert(params: ArtifactControllerAclInsert$Params, context?: HttpContext): Observable<ArtifactAclResponseDto> {
    return this.artifactControllerAclInsert$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactAclResponseDto>): ArtifactAclResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerAclRemove()` */
  static readonly ArtifactControllerAclRemovePath = '/api/tenant/artifact/acl/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerAclRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerAclRemove$Response(params: ArtifactControllerAclRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactAclResponseDto>> {
    return artifactControllerAclRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerAclRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerAclRemove(params: ArtifactControllerAclRemove$Params, context?: HttpContext): Observable<ArtifactAclResponseDto> {
    return this.artifactControllerAclRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactAclResponseDto>): ArtifactAclResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerModuleInsert()` */
  static readonly ArtifactControllerModuleInsertPath = '/api/tenant/artifact/module/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerModuleInsert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerModuleInsert$Response(params: ArtifactControllerModuleInsert$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerModuleInsert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerModuleInsert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerModuleInsert(params: ArtifactControllerModuleInsert$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerModuleInsert$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerModuleHeading()` */
  static readonly ArtifactControllerModuleHeadingPath = '/api/tenant/artifact/module/heading';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerModuleHeading()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerModuleHeading$Response(params: ArtifactControllerModuleHeading$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerModuleHeading(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerModuleHeading$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactControllerModuleHeading(params: ArtifactControllerModuleHeading$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerModuleHeading$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerModulePromote()` */
  static readonly ArtifactControllerModulePromotePath = '/api/tenant/artifact/module/promote/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerModulePromote()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerModulePromote$Response(params: ArtifactControllerModulePromote$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerModulePromote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerModulePromote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerModulePromote(params: ArtifactControllerModulePromote$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerModulePromote$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactControllerModuleDemote()` */
  static readonly ArtifactControllerModuleDemotePath = '/api/tenant/artifact/module/demote/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactControllerModuleDemote()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerModuleDemote$Response(params: ArtifactControllerModuleDemote$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
    return artifactControllerModuleDemote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactControllerModuleDemote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactControllerModuleDemote(params: ArtifactControllerModuleDemote$Params, context?: HttpContext): Observable<ArtifactResponseDto> {
    return this.artifactControllerModuleDemote$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactResponseDto>): ArtifactResponseDto => r.body)
    );
  }

}
