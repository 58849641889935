<div class="grid">
  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="changeLabelStyleReference(formatSettings)" [(ngModel)]="formatSettings.label.visible"
                    binary="true" id="showLabel"></p-checkbox>
        <label for="showLabel" translate> Show label </label>
      </span>
    </span>
  </div>

  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="formatSettings.label.showDirection" binary="true" id="showDirection"></p-checkbox>
        <label for="showDirection" translate> Show direction arrow </label>
      </span>
    </span>
  </div>

  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="onUseCustomLabelChange($event.value)" [(ngModel)]="formatSettings.label.useCustomLabel"
                    binary="true" id="useCustomLabel"></p-checkbox>
        <label for="useCustomLabel" translate> Use custom label </label>
      </span>
    </span>
  </div>

  <div class="col-6 mb-0">
    <input [(ngModel)]="formatSettings.label.customLabel" [disabled]="!formatSettings.label.useCustomLabel"
           class="w-100" id="customLabel" pInputText placeholder="{{'Set custom label'| translate}}" type="text">
  </div>

  <div class="col-3 mb-0">
    <button (click)="labelFontStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="{{'Fonts'|translate}}"
            pButton type="button"></button>
  </div>

  <div class="col-3 mb-0">
    <button (click)="labelPositionOp.toggle($event)" class="w-100" icon="bi bi-align-middle"
            label="{{'Position'| translate}}" pButton type="button"></button>
  </div>
</div>


<p-overlayPanel #labelPositionOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <div class="d-flex flex-column">
      <div class="p-col">
        <h6> {{'Position'| translate}} </h6>
        <p-dropdown (onChange)="onLabelPositionChange($event, formatSettings)"
                    [(ngModel)]="formatSettings.label.position"
                    [autoDisplayFirst]="false"
                    [options]="labelPositionOptions"
                    id="labelPosition">
        </p-dropdown>
      </div>

      <div class="p-col">
        <h6> {{'Horizontal align'| translate}} </h6>
        <p-dropdown (onChange)="changeLabelStyleReference(formatSettings)"
                    [(ngModel)]="formatSettings.label.styles.textAlign"
                    [autoDisplayFirst]="false"
                    [options]="textHorizontalAlignOptions"
                    id="labelTextHorizontalAlign">
        </p-dropdown>
      </div>

      <div *ngIf="isVerticalAlignmentAvailable" class="p-col">
        <h6> {{'Vertical align'| translate}} </h6>
        <p-dropdown (onChange)="changeLabelStyleReference(formatSettings)"
                    [(ngModel)]="formatSettings.label.styles.verticalAlign"
                    [autoDisplayFirst]="false"
                    [options]="textVerticalAlignOptions"
                    id="labelTextVerticalAlign">
        </p-dropdown>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #labelFontStyleOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <app-font-style-form [hideHorizontalAlignSettings]="true"
                         [fontStyles]="formatSettings.label.styles"></app-font-style-form>
  </ng-template>
</p-overlayPanel>
