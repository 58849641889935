import { Injectable } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { AdminMainComponent } from '@private/admin-main.component';
import { AppMainModel } from '@private/app.main.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TENANT_KEY, TENANT_LABEL } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { RuleDataHolderService, RuleEngineService } from '@workflows/services';

@Injectable()
export class AppMainService extends CoreService<AdminMainComponent, AppMainModel> {
  constructor(
    public readonly cache: NewCacheService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly ruleEngineService: RuleEngineService,
    private readonly ruleDataHolderService: RuleDataHolderService,
  ) {
    super();
  }

  async init(context: AdminMainComponent, model: AppMainModel): Promise<void> {
    super.init(context, model);

    this.cache.initCache();
    const cacheSubscription = this.cache.isLoaded$.subscribe(async () => {
      if ((this.cache.user?.value as SelfUserResponseDto)?.isGuest) return;

      cacheSubscription.unsubscribe();
      this.initRuleEngine();
      this.initSubscriptions();
      await this.pushNotificationService.init();

      (this.cache.user.value as SelfUserResponseDto).tenant?.isAdmin &&
        this.c.menuItems.push(...[{ label: TENANT_LABEL, icon: 'pi pi-fw pi-lock-open', routerLink: [TENANT_KEY] }]);
    });
  }

  private initRuleEngine(): void {
    this.ruleEngineService.enableRuleEngine(true);
    this.ruleDataHolderService.initRules();
  }

  private initSubscriptions(): void {
    this.c.registerSubscription(this.cache.user.subscribe(user => user && (this.m.user = user as SelfUserResponseDto)));
  }
}
