import { SendEmailActionWorkflowSettingsDto } from '@api/models/send-email-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum SendEmailKeys {
  emailsTo = 'emailsTo',
  subject = 'subject',
  body = 'body',
}

export class WorkflowActionSendEmailDto implements SendEmailActionWorkflowSettingsDto {
  emailsTo: SendEmailActionWorkflowSettingsDto[SendEmailKeys.emailsTo] = { value: [''], isDynamic: false };
  subject: SendEmailActionWorkflowSettingsDto[SendEmailKeys.subject] = { value: '', isDynamic: false };
  body: SendEmailActionWorkflowSettingsDto[SendEmailKeys.body] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionSendEmailDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionSendEmail extends AbstractWorkflowAction<WorkflowActionSendEmailDto> {
  constructor(dto?: WorkflowActionDto) {
    super(WorkflowActionSendEmailDto, WorkflowActionType.SEND_EMAIL, dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }
}
