import { Component, Input } from '@angular/core';
import { TenantTenantResponseDto } from '@api/models';
import { TENANT_LABEL } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './tenant-settings.component.html',
  styleUrls: ['./tenant-settings.component.scss'],
})
export class TenantSettingsComponent {
  @Input() tenant: TenantTenantResponseDto;
  @Input() publicPageOptions: SelectOption<string, string>[] = [];

  TENANT_LABEL = TENANT_LABEL;
}
