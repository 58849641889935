import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { WidgetModel } from '../../shared/types/widget-model.interface';

export class TextWidgetValue {
  model: TextWidgetModel;

  constructor(tinyMceHelper: TinyMceHelper) {
    this.model = new TextWidgetModel(tinyMceHelper);
  }
}

export class TextWidgetModel implements WidgetModel<TextWidgetModelDto> {
  text = '';

  constructor(private readonly tinyMceHelper: TinyMceHelper, dto?: TextWidgetModelDto) {
    if (dto) {
      this.fromDto(dto);
    }
  }

  fromDto(dto: TextWidgetModelDto): void {
    Object.assign(this, dto);
    this.text = this.tinyMceHelper.addOrRemoveImageAuth(this.text, false);
  }

  toServer(): TextWidgetModelDto {
    return {
      text: this.tinyMceHelper.addOrRemoveImageAuth(this.text, true),
    };
  }
}

export interface TextWidgetModelDto {
  text: string;
}
