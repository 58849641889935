import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { ArtifactTabSettings } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { xor } from 'lodash';

@Pipe({ name: 'shouldShowArtifactTabAttribute' })
export class ShouldShowArtifactTabAttributePipe implements PipeTransform {
  transform(tabSettings: ArtifactTabSettings, queryParams: Params, isNoTabActive: boolean): boolean {
    const { tabKey, tabValue, showWhenNoTabIsActive, alwaysShow } = tabSettings;

    if (alwaysShow) {
      return true;
    }

    if (isNoTabActive) {
      return showWhenNoTabIsActive;
    }

    const tabValueSplit = tabValue
      .replace(/\s/g, '')
      .split('/')
      .map(tabVal => tabVal.split(','));

    return tabValueSplit.some(tabVal => xor(tabVal, queryParams[tabKey]?.split(',')).length === 0);
  }
}
