import { Injectable } from '@angular/core';
import { CommonListParams } from '@shared/types/common-list.types';
import { RuleDataAccessService } from '@workflows/services/rule-data-access.service';
import { Rule, RuleTriggerType, WorkflowOwnerType, WorkflowRule, WorkflowTriggerEvent } from '@workflows/types';
import { Observable, tap } from 'rxjs';

/** Map where key is rule trigger type and values list of assigned rules (their ids) */
export type PageRulesMap = Partial<Record<RuleTriggerType, string[]>>;

@Injectable({ providedIn: 'root' })
export class RuleDataHolderService {
  /** Map containing page rules, where key is page id and value is another map with rule trigger as key and list of rules */
  private pageRules: Record<string, PageRulesMap> = {};
  private rules: Record<string, WorkflowRule> = {};
  private dataLoaded: boolean;

  constructor(private ruleDataAccessService: RuleDataAccessService) {}

  registerRule(rule: WorkflowRule) {
    if (this.rules[rule.id]) {
      return;
    }
    this.rules[rule.id] = rule;
    rule.ownerId && this.registerRuleForOwner(rule, rule.ownerId);
    rule.usedIn && rule.usedIn.forEach(usedInOwner => this.registerRuleForOwner(rule, usedInOwner));
  }

  unregisterRule(rule: WorkflowRule) {
    rule.ownerId && this.unregisterRuleForOwner(rule, rule.ownerId);
    rule.usedIn && rule.usedIn.forEach(usedInOwner => this.unregisterRuleForOwner(rule, usedInOwner));
  }

  initRules(userLoggedIn: boolean) {
    if (this.dataLoaded) {
      return;
    }

    if (userLoggedIn) {
      this.dataLoaded = true;
    }

    this.loadRules();
  }

  loadRules(params?: CommonListParams) {
    if (!params) {
      params = {
        filter: JSON.stringify({ deleted: null }),
        sort: JSON.stringify({ priority: 1 }),
      };
    }
    this.ruleDataAccessService.loadAllRules$(params).subscribe(allRules => allRules.forEach(rule => this.registerRule(rule)));
  }

  saveRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    return this.ruleDataAccessService.saveRule$(rule).pipe(tap(rule => this.registerRule(rule)));
  }

  useRule$(rule: WorkflowRule, ownerId: string): Observable<WorkflowRule> {
    return this.ruleDataAccessService.useRule$(rule, ownerId).pipe(
      tap(rule => {
        this.rules[rule.id] = rule;
        this.registerRuleForOwner(rule, ownerId);
      }),
    );
  }

  unuseRule$(rule: WorkflowRule, ownerId: string): Observable<WorkflowRule> {
    return this.ruleDataAccessService.unuseRule$(rule, ownerId).pipe(
      tap(rule => {
        this.rules[rule.id] = rule;
        this.unregisterRuleForOwner(rule, ownerId);
      }),
    );
  }

  updateRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    return this.ruleDataAccessService.updateRule$(rule).pipe(tap(rule => (this.rules[rule.id] = rule)));
  }

  deleteRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    return this.ruleDataAccessService.deleteRule$(rule).pipe(tap(rule => this.unregisterRule(rule)));
  }

  getRuleById(id: string): Rule {
    return this.rules[id];
  }

  getRulesForPage(pageId: string): Rule[] | undefined {
    if (!this.pageRules[pageId]) {
      return undefined;
    }
    const pageRules = Object.values(this.pageRules[pageId])
      .reduce((acc, current) => [...acc, ...current], [])
      .map(ruleId => this.rules[ruleId])
      .sort((a, b) => a.priority! - b.priority!);

    return [...new Set(pageRules)];
  }

  /**
   * Returns all global rules for page, i.e. all goals which are not owned by given page id.
   * @param pageId page id
   * @returns all global rules for page, i.e. all goals which are not owned by given page id
   */
  getAllGlobalRulesForPage(pageId: string): Rule[] {
    return Object.values(this.rules).filter(rule => rule.global && rule.ownerId !== pageId);
  }

  /**
   * Returns available goals for given page.
   * @param pageId page id
   * @returns returns available goals for given page
   */
  getAvailableGlobalRulesForPage(pageId: string, onlyActive = true): Rule[] {
    const pageRules = this.pageRules[pageId] || {};
    const rulesInPage: Record<string, string> = {};
    Object.values(pageRules).forEach(rules => {
      rules.forEach(ruleId => (rulesInPage[ruleId] = ruleId));
    });
    return this.getAllGlobalRulesForPage(pageId).filter(rule => !rulesInPage[rule.id] && (!onlyActive || rule.active));
  }

  getRulesForPageAndTriggerType(pageId: string, triggerType: RuleTriggerType): WorkflowRule[] | undefined {
    if (this.pageRules[pageId] && this.pageRules[pageId][triggerType]) {
      return this.pageRules[pageId][triggerType]?.map(ruleId => this.rules[ruleId]);
    }
    return undefined;
  }

  getRulesForPageAndTrigger(pageId: string, triggerEvent: WorkflowTriggerEvent): WorkflowRule[] | undefined {
    const rules = this.getRulesForPageAndTriggerType(pageId, triggerEvent.definition.type as RuleTriggerType);
    return rules?.filter(rule => rule.triggers.some(trigger => trigger.isMatchingWorkflowTrigger(triggerEvent.definition)));
  }

  getSchedulableRules(): WorkflowRule[] {
    return Object.values(this.rules).filter(rule => rule.ownerType === WorkflowOwnerType.CUSTOM);
  }

  getAllRules(): WorkflowRule[] {
    return Object.values(this.rules);
  }

  hasRuleForPageAndTrigger(pageId: string, triggerEvent: WorkflowTriggerEvent): boolean {
    const rules = this.getRulesForPageAndTrigger(pageId, triggerEvent);
    return rules?.some(rule => rule.triggers.some(trigger => trigger.isMatchingWorkflowTrigger(triggerEvent.definition))) || false;
  }

  hasRuleForPageAndTriggerType(pageId: string, triggerType: RuleTriggerType): boolean {
    const matchingRules = this.pageRules[pageId] && this.pageRules[pageId][triggerType];
    return (matchingRules && matchingRules.length > 0) || false;
  }

  getRulesForPageWidgetAndTriggerType(pageId: string, widgetId: string, triggerType: RuleTriggerType): WorkflowRule[] | undefined {
    const rules = this.getRulesForPageAndTriggerType(pageId, triggerType);
    return rules?.filter(rule => rule.ownerId === widgetId);
  }

  private registerRuleForOwner(rule: WorkflowRule, ownerId: string) {
    if (!this.pageRules[ownerId]) {
      this.pageRules[ownerId] = {};
    }
    const pageRules = this.pageRules[ownerId];
    rule.triggers.forEach(trigger => {
      const triggerPageRules = pageRules[trigger.type];
      const alreadyIncluded = triggerPageRules?.includes(rule.id);
      if (!alreadyIncluded) {
        pageRules[trigger.type] = (triggerPageRules && [...triggerPageRules, rule.id]) || [rule.id];
      }
    });
  }

  private unregisterRuleForOwner(rule: WorkflowRule, ownerId: string) {
    const { triggers } = rule;
    if (this.pageRules[ownerId]) {
      triggers
        .filter(trigger => this.pageRules[ownerId][trigger.type])
        .forEach(trigger => {
          this.pageRules[ownerId][trigger.type] = this.pageRules[ownerId][trigger.type]?.filter(storedRuleId => storedRuleId !== rule.id);
        });
    }
  }
}
