import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { WidgetsCoreComponent } from '../widgets-core/components/widgets-core.component';
import { MenuWidgetService } from './services/menu-widget.service';
import { MenuWidgetModel, MenuWidgetValue } from './types/menu-widget.types';

@Component({
  selector: 'app-menu-widget',
  templateUrl: './menu-widget.component.html',
  styleUrls: ['./menu-widget.component.scss'],
  providers: [MenuWidgetService],
})
export class MenuWidgetComponent extends WidgetsCoreComponent implements OnInit, OnDestroy {
  m: MenuWidgetModel;

  horizontalMenuType = MenuTypesEnum.horizontal;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<MenuWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: MenuWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.m && (this.m.items.tree = []);
  }
}
