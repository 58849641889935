import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '../../types/list-container.types';

@Pipe({ name: 'getAttributeFromClientAttribute' })
export class GetAttributeFromClientAttributePipe implements PipeTransform {
  transform(attr: NewArtifactTypeClientAttribute | NewClientAttribute, attributes: ListContainer<NewAttribute>): NewAttribute | undefined {
    if (!attr) return undefined;
    return attributes.listMap[attr.id];
  }
}
