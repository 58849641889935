<div class="table-caption" [style.background-color]="settings.customColors ? settings.background.header : null">
  <h5>{{ settings.caption }}</h5>
  <ng-container *ngIf="settings.showExportButton">
    <span *ngIf="selected.exportInProcess">{{ 'wait for download ...' | translate }}</span>
    <button *ngIf="!selected.exportInProcess" (click)="export()" pButton class="p-button" type="button" label="{{ 'Export' | translate }}"></button>
  </ng-container>
  <ng-container *ngIf="settings.showAddButton">
    <a *ngIf="selected.artifactTypes | shouldShowSingleAddArtifactButton : settings.loadMode; else multiAddButton"
       [routerLink]="['/page', selected.artifactTypes[0].value.defaultPageId]"
       [queryParams]="getQueryParams()"
       target="_blank">
      <button class="p-button" icon="pi pi-plus" iconPos="left" label="{{settings.addButtonLabel || 'Add'}}" pButton type="button"></button>
    </a>
    <ng-template #multiAddButton>
      <p-splitButton [model]="addButtonItems"
                     [label]="settings.addButtonLabel || 'Add'"
                     [disabled]="settings.loadMode | isAddButtonDisabled : selected.module"
                     icon="pi pi-plus"
                     appendTo="body"
      ></p-splitButton>
    </ng-template>
  </ng-container>

</div>
