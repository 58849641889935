import { DisplayAtMetadata } from '@shared/types/display-at-types';
import {
  BorderStyles,
  BorderStylesEnum,
  BorderWidthEnum,
  FontFamilyEnum,
  FontStyleEnum,
  FontWeightEnum,
  LabelPositionEnum,
  ListStyleEnum,
  TextDecorationEnum,
  TextHorizontalAlignEnum,
  TextVerticalAlignEnum,
} from '@widgets/shared/types/style.types';

export class LinkTypeFormatSettings {
  label = new LinkTypeLabelFormatSettings();
  value = new LinkTypeValueFormatSettings();
  innerArea = new InnerAreaStyles({
    borderRightStyle: BorderStylesEnum.none,
    borderLeftStyle: BorderStylesEnum.none,
    borderTopStyle: BorderStylesEnum.none,
    borderBottomStyle: BorderStylesEnum.none,
  });
  outerArea = new OuterAreaStyles();

  constructor(settings?: Partial<LinkTypeFormatSettings>) {
    if (settings) {
      settings.label && (this.label = new LinkTypeLabelFormatSettings(settings.label));
      settings.value && (this.value = new LinkTypeValueFormatSettings(settings.value));
      settings.innerArea && (this.innerArea = new InnerAreaStyles(settings.innerArea));
      settings.outerArea && (this.outerArea = new OuterAreaStyles(settings.outerArea));
    }
  }
}

export class AttributeFormatSettings {
  editable = true;
  label = new AttributeLabelFormatSettings();
  value = new AttributeValueFormatSettings();
  innerArea = new InnerAreaStyles({
    borderRightStyle: BorderStylesEnum.none,
    borderLeftStyle: BorderStylesEnum.none,
    borderTopStyle: BorderStylesEnum.none,
    borderBottomStyle: BorderStylesEnum.none,
  });
  outerArea = new OuterAreaStyles();
  initialValue: any = undefined;
  ignoreInitialValue = false;

  constructor(settings?: Partial<AttributeFormatSettings>) {
    if (settings) {
      this.editable = !!settings.editable;
      this.ignoreInitialValue = !!settings.ignoreInitialValue;
      settings.label && (this.label = new AttributeLabelFormatSettings(settings.label));
      settings.value && (this.value = new AttributeValueFormatSettings(settings.value));
      settings.innerArea && (this.innerArea = new InnerAreaStyles(settings.innerArea));
      settings.outerArea && (this.outerArea = new OuterAreaStyles(settings.outerArea));
      settings.initialValue && (this.initialValue = settings.initialValue);
    }
  }
}

export class AttributeLabelFormatSettings {
  visible = true;
  showIcon = false;
  showLink = true;
  position = LabelPositionEnum.top;
  useCustomLabel = false;
  customLabel = '';
  styles = new FormatStyles();

  constructor(settings?: Partial<AttributeLabelFormatSettings>) {
    if (settings) {
      this.visible = !!settings.visible;
      this.showIcon = !!settings.showIcon;
      settings.showLink !== undefined && (this.showLink = settings.showLink);
      this.useCustomLabel = !!settings.useCustomLabel;
      settings.customLabel && (this.customLabel = settings.customLabel);
      settings.position && (this.position = settings.position);
      settings.styles && (this.styles = new FormatStyles(settings.styles));
    }
  }
}

export class AttributeValueFormatSettings {
  showPlaceholder = false;
  placeholder = '';
  showIcon = false;
  styles = new FormatStyles();
  fieldStyles = new FieldStyles();
  displayMetadata?: DisplayAtMetadata;

  constructor(settings?: Partial<AttributeValueFormatSettings>) {
    if (settings) {
      this.showPlaceholder = !!settings.showPlaceholder;
      settings.placeholder && (this.placeholder = settings.placeholder);
      this.showIcon = !!settings.showIcon;
      settings.styles && (this.styles = new FormatStyles(settings.styles));
      settings.fieldStyles && (this.fieldStyles = new FieldStyles(settings.fieldStyles));
      this.displayMetadata = settings.displayMetadata;
    }
  }
}

export class LinkTypeLabelFormatSettings {
  visible = true;
  position = LabelPositionEnum.top;
  showDirection = true;
  useCustomLabel = false;
  customLabel = '';
  styles = new FormatStyles();

  constructor(settings?: Partial<LinkTypeLabelFormatSettings>) {
    if (settings) {
      Object.assign(this, settings);
      settings.styles && (this.styles = new FormatStyles(settings.styles));
    }
  }
}

export class LinkTypeValueFormatSettings {
  showIcon = false;
  paddingLeft: string | null = null;
  listStyle: ListStyleEnum = ListStyleEnum.disc;
  styles = new FormatStyles();
  displayMetadata?: DisplayAtMetadata;

  constructor(settings?: Partial<LinkTypeValueFormatSettings>) {
    if (settings) {
      this.showIcon = !!settings.showIcon;
      settings.styles && (this.styles = new FormatStyles(settings.styles));
      this.displayMetadata = settings.displayMetadata;
    }
  }
}

export class FieldStyles {
  backgroundColor: '#ffffff';
  borderColor = '#ced4da';
  borderRadius = '4px';
  borderStyle = BorderStylesEnum.solid;
  borderWidth = BorderWidthEnum.thin;
  prefix?: string;
  suffix?: string;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;

  constructor(styles?: Partial<FieldStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }
  }
}

export class FormatStyles {
  display = 'table-cell';
  fontWeight = FontWeightEnum.normal;
  fontStyle = FontStyleEnum.normal;
  fontSize = '14px';
  textDecoration = TextDecorationEnum.none;
  textAlign = TextHorizontalAlignEnum.left;
  verticalAlign = TextVerticalAlignEnum.top;
  color?: string;
  backgroundColor?: string;
  fontFamily = FontFamilyEnum.nunito;
  prefix?: string;
  suffix?: string;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
  isBorderEnable?: boolean;
  padding?: string;
  borderRadius?: string;
  borderColor?: string;
  borderWidth?: string;
  borderStyle?: string;

  constructor(styles?: Partial<FormatStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class InnerAreaStyles extends BorderStyles {
  backgroundColor?: string;
  paddingTop = '5px';
  paddingLeft = '15px';
  paddingBottom = '5px';
  paddingRight = '15px';

  constructor(params?: Partial<InnerAreaStyles>) {
    super();
    Object.assign(this, params);
  }
}

export class OuterAreaStyles {
  paddingTop = '0px';
  paddingLeft = '1px';
  paddingBottom = '0px';
  paddingRight = '1px';

  constructor(params?: Partial<OuterAreaStyles>) {
    Object.assign(this, params);
  }
}
