import { Component, OnDestroy } from '@angular/core';
import { AbstractAvrRenderFileTypeComponent } from '../abstract.avr-render-file-type.component';
import { AvrBlobType } from '@widgets/avr-widget/types/avr-widget.types';

@Component({
  selector: 'app-avr-widget-svg-render-file-type',
  templateUrl: './svg.avr-render-file-type.component.html',
  styleUrls: ['./svg.avr-render-file-type.component.scss'],
})
export class AvrSvgRenderFileTypeComponent extends AbstractAvrRenderFileTypeComponent implements OnDestroy {
  blobUrl: string | null = null;

  onSetFile(file: AvrBlobType | null): void {
    this.revokeBlobUrl();
    if (file) {
      this.blobUrl = URL.createObjectURL(file.file);
    } else {
      this.blobUrl = null;
    }
  }

  ngOnDestroy(): void {
    this.revokeBlobUrl();
  }

  private revokeBlobUrl(): void {
    if (this.blobUrl !== null) {
      URL.revokeObjectURL(this.blobUrl);
      this.blobUrl = null;
    }
  }
}
