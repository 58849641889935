import { Component, Input, ViewChild } from '@angular/core';
import { ArtifactResponseDto } from '@api/models';
import { FileService } from '@private/services/file.service';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-simple-kind-file-input',
  templateUrl: './simple-kind-file-input.component.html',
  styleUrls: ['./simple-kind-file-input.component.scss'],
})
export class SimpleKindFileInputComponent {
  @Input() artifactAttribute: NewClientAttribute;
  @Input() index: number;
  @Input() fullAttribute: NewAttribute;
  @Input() fullDataType: NewDataType;
  @ViewChild('fileUpload') fileUpload: FileUpload;

  constructor(public readonly fileService: FileService) {}

  onFilesAdd(event: { files: File[] }): void {
    setTimeout(() => {
      if (!event.files.length) return;
      if (this.fileUpload.multiple) {
        !this.artifactAttribute.value && (this.artifactAttribute.value = []);
        event.files.forEach(file => this.artifactAttribute.value.push(file));
      } else {
        this.artifactAttribute.value = event.files[0];
      }
      this.fileUpload.clear();
    });
  }

  onFileRemove(index = null): void {
    if (this.fileUpload.multiple) {
      this.artifactAttribute.value.splice(index, 1);
    } else {
      this.artifactAttribute.value = null;
    }
  }

  onFileDownload(fileArtifact: ArtifactResponseDto): any {
    if (fileArtifact.id) return this.fileService.downloadFileArtifact(fileArtifact);
  }
}
