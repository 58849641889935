import { Component, Input } from '@angular/core';
import { TagWidgetModel } from '@widgets/tag-widget/types/tag-widget.types';
import { TagWidgetService } from '@widgets/tag-widget/services/tag-widget.service';

@Component({
  selector: 'app-tag-widget-settings',
  templateUrl: './tag-widget-settings.component.html',
  styleUrls: ['./tag-widget-settings.component.scss'],
})
export class TagWidgetSettingsComponent {
  @Input() m: TagWidgetModel;
  @Input() s: TagWidgetService;
}
