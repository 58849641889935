import { Pipe, PipeTransform } from '@angular/core';
import { LinkTypeResponseDto } from '@api/models';
import { TenantLinkTypeService } from '@api/services';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { Observable, map, of, shareReplay } from 'rxjs';

@Pipe({
  name: 'getWorkflowTriggerLinkableArtifactTypes',
})
export class GetWorkflowTriggerLinkableArtifactTypes implements PipeTransform {
  constructor(private readonly tenantLinkTypeService: TenantLinkTypeService) {}

  transform(artifactTypes: NewArtifactType[], defaultArtifactType: NewArtifactType): Observable<NewArtifactType[]> {
    if (!defaultArtifactType) {
      return of(artifactTypes);
    }
    const filter = LinkMethods.getArtifactLinkTypesFilter(defaultArtifactType.id);
    return this.tenantLinkTypeService.linkTypeControllerList({ filter }).pipe(
      shareReplay(),
      map(result => {
        const linkTypesPerArtifact: Record<string, LinkTypeResponseDto> = {};
        const linkTypeResponses = result.data;
        linkTypeResponses.forEach(item => {
          item.restrictions?.forEach(restriction => {
            const artifactTypeId =
              restriction.destinationArtifactTypeId === defaultArtifactType.id ? restriction.sourceArtifactTypeId : restriction.destinationArtifactTypeId;
            linkTypesPerArtifact[artifactTypeId] = item;
          });
        });
        return artifactTypes.filter(item => !!linkTypesPerArtifact[item.id]);
      }),
    );
  }
}
