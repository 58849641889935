import { Component, Input } from '@angular/core';
import { EMAIL_KEY, ID_KEY } from '@shared/constants/constants';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { GetArtifactsKeys, WorkflowActionGetArtifacts } from '@workflows/types/actions/action-get-artifacts';

@Component({
  selector: 'app-action-get-artifacts',
  templateUrl: './action-get-artifacts.component.html',
  styleUrls: ['./action-get-artifacts.component.scss'],
})
export class ActionGetArtifactsComponent {
  @Input() action: WorkflowActionGetArtifacts;
  @Input() users: ListContainer<NewUser>;

  protected readonly RuleKeys = GetArtifactsKeys;
  protected readonly EMAIL_VALUE = EMAIL_KEY;
  protected readonly ID_VALUE = ID_KEY;
}
