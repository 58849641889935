import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { WidgetModel } from '@widgets/shared/types/widget-model.interface';
import { SideBarOptions } from '@widgets/sidebar-widget/types/sidebar-widget-options.types';
import { SidebarWidgetPicker } from '@widgets/sidebar-widget/types/sidebar-widget-picker.types';
import { SideBarSettings, SideBarSettingsDto } from '@widgets/sidebar-widget/types/sidebar-widget-settings.types';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { ElvisUtil } from '@shared/utils/elvis.util';

export class SidebarWidgetValue {
  constructor(public model: SidebarWidgetModel = new SidebarWidgetModel()) {}
}

export class SidebarWidgetModel implements WidgetModel<SidebarWidgetModelDto> {
  options = new SideBarOptions();
  settings = new SideBarSettings();
  widgetPicker = new SidebarWidgetPicker();
  widgetOptions: WidgetOption<any>[] = [];
  parts: PageBlockPart[] = [];
  widgetsToDelete: string[] = [];
  newWidgetTempId = ElvisUtil.makeHash(16);

  constructor(dto?: SidebarWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  copy(): SidebarWidgetModelDto {
    return {
      settings: this.settings.toServer(),
      parts: cloneDeep(this.parts),
    };
  }

  toServer(): SidebarWidgetModelDto {
    return {
      settings: this.settings.toServer(),
      parts: this.parts.map(part => ({
        widgetId: part.widget?.templateId ? null : part.widget?.id,
        templateId: part.widget?.templateId || null,
      })),
    } as SidebarWidgetModelDto;
  }

  fromDto(dto: SidebarWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new SideBarSettings(dto.settings));
      dto.parts && (this.parts = dto.parts as PageBlockPart[]);
    }
  }
}

export interface SidebarWidgetModelDto {
  settings: SideBarSettingsDto;
  parts: SideBarPartsDto[] | PageBlockPart[];
}

export interface SideBarPartsDto {
  templateId: null | string;
  widgetId: null | string;
}

export interface SidebarListItem {
  id: string;
  name?: string;
}
