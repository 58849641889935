<div
  *ngIf="attr"
  [attr.style]="formatSettings.value.fieldStyles | fieldStyles"
  (focusin)="changeFormFocus?.()"
  class="attribute-form-field">
  <span class="dynamic-label p-float-label">
    <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
    <p-password [(ngModel)]="attr.value" [toggleMask]="true" [feedback]="false" [inputId]="'passwordField' + index"
                (onBlur)="onBlurCb?.()"
                (ngModelChange)="onChangeCb?.()"></p-password>
    <label *ngIf="label && !(labelBehaviour === labelBehaviourEnum.placeholder && !!placeholder)"
           [for]="'passwordField' + index" [ngStyle]="formatSettings.label.styles"
           class="dynamic-label-text">
      {{label}}
    </label>
  </span>
</div>
