import { GetLinksActionWorkflowSettingsDto } from '@api/models';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum GetLinksKeys {
  filter = 'filter',
  scopePathToStore = 'scopePathToStore',
}

export class WorkflowActionGetLinksDto implements GetLinksActionWorkflowSettingsDto {
  filter: GetLinksActionWorkflowSettingsDto[GetLinksKeys.filter] = { value: '', isDynamic: false };
  scopePathToStore: GetLinksActionWorkflowSettingsDto[GetLinksKeys.scopePathToStore] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionGetLinksDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionGetLinks extends AbstractWorkflowAction<WorkflowActionGetLinksDto> {
  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionGetLinksDto, type: WorkflowActionType.GET_LINKS, dto });
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      actionSettings: this.actionSettings,
    };
  }
}
