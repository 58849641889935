<div class="row pl-3">
  <div class="col-3 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-4">
    <input id="forEachPathInput" [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value" pInputText type="text" class="input-min-width"
           placeholder="Enter for-each-path" />
  </div>
</div>

<div class="row pl-3">
  <div class="col-1 text-bold">
    {{ 'Date' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.date].isDynamic" (ngModelChange)="onIsDateDynamicChange()" id="isDynamicDate" binary="true"
                class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>

  <div class="col-4">
    <ng-container *ngIf="action.actionSettings[RuleKeys.date].isDynamic">
      <input id="dateInput" [(ngModel)]="action.actionSettings[RuleKeys.date].value" pInputText type="text" class="input-min-width"
             placeholder="Enter dynamic date" />
    </ng-container>
    <ng-container *ngIf="!action.actionSettings[RuleKeys.date].isDynamic">
      <p-calendar [(ngModel)]="action.scheduledDate" [readonlyInput]="true" [showTime]="true" id="dateSelect" appendTo="body"
                  placeholder="Select date"></p-calendar>
    </ng-container>
  </div>
</div>

<div class="row pl-3">
  <div class="col-1 text-bold">
    {{ 'Workflow' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.workflowId].isDynamic" id="isDynamicWorkflow" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-3">
    <p-dropdown [(ngModel)]="action.actionSettings[RuleKeys.workflowId].value"
                (onChange)="onWorkflowIdChange($event.value)"
                [options]="schedulableWorkflows"
                [autoDisplayFirst]="false"
                id="scheduledWorkflowId"
                optionValue="id"
                placeholder="Select workflow"
                appendTo="body">
      <ng-template let-option pTemplate="item">{{ option.name | translate }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.name | translate }}</ng-template>
    </p-dropdown>
  </div>
</div>

<div class="row pl-3">
  <div class="col-1 text-bold">
    {{ 'Inputs' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.inputDataValue].isDynamic" id="isDynamicInputData" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-9">
    <div *ngFor="let inputData of action.workflowClientInputDataArray; let i = index">
      <div class="row">
        <div class="col-3 pb-0 pt-0">
          <input [id]="'key' + i" [(ngModel)]="inputData.key" pInputText type="text" placeholder="Enter key {{ i + 1 }}" />
          <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveKey(i)"></button>
        </div>
        <div class="col-9 pt-0 pb-0">
          <div class="row">
            <div class="col-2 pt-0">
              <p-checkbox [(ngModel)]="inputData.isNull" [id]="'isNull' + i" binary="true" class="mr-2 pt-2"></p-checkbox>
              {{ 'null' | translate }}
            </div>
            <ng-container *ngIf="!inputData.isNull">
              <div class="col-2 pt-0">
                <p-checkbox [(ngModel)]="inputData.isArray" (ngModelChange)="onIsArrayChange($event, i)" [id]="'isArray' + i" binary="true"
                            class="mr-2 pt-2"></p-checkbox>
                {{ 'array' | translate }}
              </div>
              <div class="col-8 pt-0">
                <ng-container *ngIf="!inputData.isArray">
                  <input [id]="'value' + i" [(ngModel)]="inputData.value" pInputText type="text" placeholder="Enter value" class="mb-1 input-min-width" />
                </ng-container>
                <ng-container *ngIf="inputData.isArray">
                  <div *ngFor="let inputDataValue of inputData.value let j = index; trackBy: inputDataTrackByFn">
                    <input [id]="'value' + j" [(ngModel)]="inputData.value![j]" pInputText type="text" placeholder="Enter value {{ j + 1 }}"
                           class="mb-1 input-min-width" />
                    <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus"
                            [ngClass]="{'hidden': !(inputData.isArray && j === inputData.value.length - 1) }"
                            (click)="onAddInputData(i)"></button>
                    <button *ngIf="inputData.isArray && inputData.value.length > 1" class="p-button p-button-text p-button-danger" pButton type="button"
                            icon="pi pi-minus" (click)="onRemoveInputData(i, j)"></button>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddKey()" label="Add key"></button>
  </div>
</div>
