<div
  #datUserContainer
  *ngIf="artifact"
  [ngSwitch]="selectedVariant"
  class="full-size-container user-profile-container with-wrap"
  [class.custom-variant]="selectedVariant === 'CUSTOM'"
>
  <ng-container *ngSwitchCase="'DEFAULT'">
    <span class="container-with-ellipsis" [ngStyle]="valueStyles">
      {{ artifact | toUser : attributeId : usersMap | replaceRuntimeVariables : artifact | async }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'NAME_ONLY'">
    <div *ngFor="let userProfile$ of profileDataItems$" class="user-profile-container container-with-ellipsis">
      <span class="container-with-ellipsis standalone-name" [ngStyle]="valueStyles"> {{ (userProfile$ | async)?.userName }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'ICON_AND_NAME'">
    <div *ngFor="let profileAndPicture$ of profileAndPictureDataItems$" class="user-profile-container container-with-ellipsis">
      <ng-container *ngIf="profileAndPicture$ | async as profileAndPicture">
        <img *ngIf="profileAndPicture.userPicture" [src]="profileAndPicture.userPicture" width="{{ iconSize }}" height="{{ iconSize }}" alt="" />
        <ng-container
          *ngIf="!profileAndPicture.userPicture"
          [ngTemplateOutlet]="initialsElement"
          [ngTemplateOutletContext]="{ width: iconSize, height: iconSize, tooltip: profileAndPicture.userName, userProfile: profileAndPicture }"
        ></ng-container>
        <span class="container-with-ellipsis standalone-name" [ngStyle]="valueStyles">{{ profileAndPicture.userName }}</span>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'ICON_ONLY'">
    <div *ngFor="let profileAndPicture$ of profileAndPictureDataItems$" class="user-profile-container">
      <ng-container *ngIf="profileAndPicture$ | async as profileAndPicture">
        <img *ngIf="profileAndPicture.userPicture" [src]="profileAndPicture.userPicture" width="{{ iconSize }}" height="{{ iconSize }}" alt=""
             pTooltip="{{ profileAndPicture.userName }}" />
        <ng-container
          *ngIf="!profileAndPicture.userPicture"
          [ngTemplateOutlet]="initialsElement"
          [ngTemplateOutletContext]="{ width: iconSize, height: iconSize, tooltip: profileAndPicture.userName, userProfile: profileAndPicture }"
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'CUSTOM'">
    <ng-container *ngIf="customVariantTemplate$ | async as widget">
      <div *ngFor="let userProfile$ of profileDataItems$" class="user-profile-container">
        <ng-container *ngIf="userProfile$ | async as userProfile">
          <app-widget-container
            *ngIf="widget.code === widgetType.card"
            [artifact]="userProfile.dto"
            [isClickable]="false"
            [isLayoutMode]="true"
            [isListMatrixCard]="true"
            [isPreviewMode]="isPreviewMode"
            [disabled]="isPreviewMode"
            [widget]="widget"
            appWidgetContainerStopPropagation
          >
          </app-widget-container>
        </ng-container>
        <!-- <app-widget-container
          *ngIf="widget.code === widgetType.avr"
          [artifact]="userProfile"
          [isLayoutMode]="true"
          [disabled]="isPreviewMode"
          [widget]="widget"
          appWidgetContainerStopPropagation
        >
        </app-widget-container> -->
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #initialsElement let-width="width" let-height="height" let-tooltip="tooltip" let-userProfile="userProfile">
  <div
    class="icon-as-initials"
    [style.min-width]="width + 'px'"
    [style.width]="width + 'px'"
    [style.min-height]="height + 'px'"
    [style.height]="height + 'px'"
    [style.line-height]="height + 'px'"
    pTooltip="{{ tooltip }}"
  >
    {{ userProfile.userName | toUserInitials : userProfile.userEmail }}
  </div>
</ng-template>
