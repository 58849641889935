import { Component, Input, ViewChild } from '@angular/core';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-simple-kind-hyperlink-input',
  templateUrl: './simple-kind-hyperlink-input.component.html',
  styleUrls: ['./simple-kind-hyperlink-input.component.scss'],
})
export class SimpleKindHyperlinkInputComponent {
  @Input() artifactAttribute: NewClientAttribute;
  @Input() index: number;
  @Input() fullAttribute: NewAttribute;
  @Input() fullDataType: NewDataType;
  @ViewChild('fileUpload') fileUpload: FileUpload;

  onHyperlinkChipClickEvent(e: any): void {
    e;
    console.log('// NEEDS TO BE IMPLEMENTED //');
  }
}
