<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-8">
    <input
      id="forEachPathInput"
      [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value"
      pInputText
      type="text"
      placeholder="Enter for-each-path"
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Link type' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.linkTypeId].isDynamic"
      id="isLinkTypeDynamic"
      binary="true"
      class="mr-2 pt-2"
      (ngModelChange)="onIsLinkTypeDynamicChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      *ngIf="action.actionSettings[RuleKeys.linkTypeId].isDynamic"
      id="linkTypeInput"
      [(ngModel)]="action.actionSettings[RuleKeys.linkTypeId].value"
      pInputText
      type="text"
      placeholder="Enter dynamic link-type"
      class="w-100"
    />
    <p-dropdown
      *ngIf="!action.actionSettings[RuleKeys.linkTypeId].isDynamic"
      [(ngModel)]="action.actionSettings[RuleKeys.linkTypeId].value"
      [options]="linkTypes.items | transformArrayToSelectOptions : 'name'"
      [autoDisplayFirst]="false"
      [filter]="true"
      id="linkTypeSelect"
      appendTo="body"
      optionValue="value.id"
    >
    </p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Source artifact' | translate }}
  </div>
  <div class="col-2">
    <p-triStateCheckbox [ngModel]="true" id="isApplicationsDynamic" [disabled]="true"></p-triStateCheckbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.sourceArtifactId].value"
      [placeholder]="'Enter dynamic link source artifact' | translate"
      pInputText
      class="w-100"
      id="sourceArtifactIdInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Destination artifact' | translate }}
  </div>
  <div class="col-2">
    <p-triStateCheckbox [ngModel]="true" id="isApplicationsDynamic" [disabled]="true"></p-triStateCheckbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.destinationArtifactId].value"
      [placeholder]="'Enter dynamic link destination artifact' | translate"
      pInputText
      class="w-100"
      id="destinationArtifactIdInput"
    />
  </div>
</div>
