import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DisplayAtControlService } from '@shared/components/common-display-at/services';
import { TemplateService } from '@shared/services/page-management/template.service';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { CUSTOM_VARIANT_KEY, DisplayAtCustomVariantMetadata, DisplayAtMetadata } from '@shared/types/display-at-types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { AttributeFormatSettings, FormatStyles, LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelPositionEnum, TextDecorationEnum, TextVerticalAlignEnum } from '@widgets/shared/types/style.types';
import { filter, from, map, take } from 'rxjs';

@Component({
  selector: 'app-format-settings',
  templateUrl: './format-settings.component.html',
  styleUrls: ['./format-settings.component.scss'],
})
export class FormatSettingsComponent implements OnChanges {
  @Input() hash: string;
  @Input() model: ArtifactWidgetModel;
  @Input() selectedItem: SelectOption<string, NewClientAttribute | LinkType> | null;

  formatSettings: AttributeFormatSettings | LinkTypeFormatSettings | null;

  constructor(private readonly displayAtControlService: DisplayAtControlService, private templateService: TemplateService) {}

  get getFolderPickerSettings(): any | null {
    const item = this.model.form.find(item => item.attribute?.value.id === this.model.selected.item?.value.id);
    item && (item.folderPickerSettings ??= new FolderWidgetSettings());
    return item ? item.folderPickerSettings : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem) {
      this.formatSettings = this.getFormatSettings();
    }
  }

  onLineThroughClick(styles: FormatStyles): void {
    styles.textDecoration = styles.textDecoration === TextDecorationEnum.lineThrough ? TextDecorationEnum.none : TextDecorationEnum.lineThrough;
  }

  onLabelPositionChange(
    event: { originalEvent: PointerEvent; value: LabelPositionEnum },
    formatSettings: AttributeFormatSettings | LinkTypeFormatSettings,
  ): void {
    const { styles } = formatSettings.label;

    if (event.value === LabelPositionEnum.top) {
      styles.verticalAlign = TextVerticalAlignEnum.top;
    } else {
      if (event.value === LabelPositionEnum.bottom) {
        styles.verticalAlign = TextVerticalAlignEnum.bottom;
      } else {
        styles.verticalAlign = TextVerticalAlignEnum.middle;
      }
    }

    this.changeLabelStyleReference(formatSettings);
  }

  // Creating new reference is important for change detection in label-styles pipe
  changeLabelStyleReference(formatSettings: AttributeFormatSettings | LinkTypeFormatSettings): void {
    formatSettings.label = { ...formatSettings.label };
  }

  onEditEnabled(): void {
    this.model.setNoItemEditableFlag(false);
  }

  onEditDisabled(): void {
    this.model.updateNoAttributeEditableFlag();
  }

  onCustomVariantSelection(attributeId: string) {
    const cardWidgetId = this.formatSettings?.value.displayMetadata?.customVariantMetadata?.cardWidgetId;
    if (cardWidgetId) {
      this.displayAtControlService.doUpdateCustomVariantSelection(this.hash, attributeId, CUSTOM_VARIANT_KEY, cardWidgetId);
      this.displayAtControlService.doUpdateSelection(this.hash, attributeId, CUSTOM_VARIANT_KEY);
    }

    from(this.templateService.pickTemplate())
      .pipe(
        take(1),
        filter(result => Array.isArray(result.templates) && result.templates.length > 0),
        map(result => (result.templates && ((result.templates[0] as any).template as any)).widgetId),
      )
      .subscribe(cardWidgetId => {
        const customVariantMetadata: DisplayAtCustomVariantMetadata = {
          cardWidgetId,
        };
        (this.formatSettings?.value.displayMetadata as DisplayAtMetadata).customVariantMetadata = customVariantMetadata;
        this.displayAtControlService.doUpdateCustomVariantSelection(this.hash, attributeId, CUSTOM_VARIANT_KEY, cardWidgetId);
        this.displayAtControlService.doUpdateSelection(this.hash, attributeId, CUSTOM_VARIANT_KEY);
      });
  }

  private getFormatSettings(): AttributeFormatSettings | LinkTypeFormatSettings | null {
    if (!this.selectedItem) {
      return null;
    }
    return this.model.isAttributeSelected
      ? this.model.formatsMap.attribute[this.selectedItem.value.id]
      : this.model.formatsMap.linkType[this.selectedItem.value.id + '_' + this.selectedItem.meta];
  }
}
