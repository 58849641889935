import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { ApexChartWidgetService } from '@widgets/apex-chart/services/apex-chart-widget.service';
import { ApexChartWidgetModel, ApexChartWidgetModelDto, ApexChartWidgetValue } from '@widgets/apex-chart/types/apex-chart-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart-widget.component.html',
  styleUrls: ['./apex-chart-widget.component.scss'],
  providers: [ApexChartWidgetService],
})
export class ApexChartWidgetComponent extends WidgetsCoreComponent<ApexChartWidgetValue> implements OnInit {
  m: ApexChartWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<ApexChartWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: ApexChartWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    const dto = this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as ApexChartWidgetModelDto) : null;
    this.s.init(this, dto);
  }
}
