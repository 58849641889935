import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@private/pages/team-management/team/services/team.service';
import { TeamPageModel } from '@private/pages/team-management/team/types/teams-page.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { IS_DELETED_LABEL, NAME_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [TeamService],
})
export class TeamComponent extends CoreComponent<TeamService, TeamPageModel> {
  m: TeamPageModel = new TeamPageModel();

  NAME_LABEL = NAME_LABEL;
  IS_DELETED_LABEL = IS_DELETED_LABEL;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    announcement: AnnouncementService,
    public service: TeamService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly confirmationService: ConfirmationService,
    private readonly elvisUtil: ElvisUtil,
  ) {
    super(route, router, translate, new TeamPageModel(), service, announcement);
    this.service.init(this, this.m);
  }

  async ngOnInit(): Promise<void> {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Team' }]);
  }

  async save(): Promise<void> {
    this.m.inSavingProgress = true;
    this.m.team.id ? await this.s.update() : await this.s.create();
    this.m.inSavingProgress = false;
  }

  async deleteWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete', 'Are you sure that you want to delete'),
      this.s.delete.bind(this.s),
    );
  }
}
