import { Directive, Input } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleDataHolderService, RuleTriggerEventHandlerService } from '../services';
import { AbstractAttributeValueChange } from '../shared/types/abstract-attribute-value-change';
import { WorkflowTriggerAttributeValueChange, WorkflowTriggerEvent } from '../types';
import { AbstractRuleEventNotifyDirective } from './abstract-rule-event-notify.directive';

@Directive({
  selector: '[appAttributeChangeValueNotify]',
})
export class RuleAttributeChangeValueNotifyDirective extends AbstractRuleEventNotifyDirective {
  @Input() artifact: NewArtifact;
  @Input() artifactTypeId: string;
  @Input() attributeId: string;
  @Input() widgetId: string;
  @Input() getAttributeValueFn: (attributeId: string) => any;

  constructor(
    protected readonly ruleDataHolder: RuleDataHolderService,
    protected readonly ruleTriggerEventHandler: RuleTriggerEventHandlerService,
    protected readonly host: AbstractAttributeValueChange,
  ) {
    super(ruleDataHolder, ruleTriggerEventHandler);

    // overriding default function from host component
    const hostOnValueChangeFn = host.onValueChange.bind(host);
    host.onValueChange = (newValue: any): void => {
      hostOnValueChangeFn(newValue);
      const ruleTriggerEvent: WorkflowTriggerEvent = {
        pageId: this.pageId,
        definition: new WorkflowTriggerAttributeValueChange(this.artifactTypeId, this.attributeId),
        payload: {
          artifact: this.artifact,
          newValue: newValue,
          getAttributeValueFn: this.getAttributeValueFn,
        },
        widgetId: this.widgetId,
      };
      this.checkAndNotify(ruleTriggerEvent);
    };
  }
}
