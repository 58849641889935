import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DisplayAtHyperlinkComponent } from './display-at-hyperlink.component';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { DisplayHyperlinkIconModule } from '@shared/components/common-display-at/components/display-at-hyperlink/display-hyperlink-icon';

@NgModule({
  declarations: [DisplayAtHyperlinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    BadgeModule,
    TranslateModule,
    DisplayArtifactValueToTableModule,
    CommonPipesModule,
    OverlayPanelModule,
    WidgetContainerModule,
    ReplaceRuntimeVariablesModule,
    DisplayHyperlinkIconModule,
  ],
  exports: [DisplayAtHyperlinkComponent],
})
export class DisplayAtHyperlinkModule {}
