import { Injector, Type } from '@angular/core';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { NewApplication } from '@shared/types/application.types';
import { ArtifactTypeFormatEnum, NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { UngroupedArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { NewSystemUser, NewUser } from '@shared/types/user.types';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { WidgetOption, WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep, isEqual } from 'lodash';

export class ArtifactTypeModel {
  isDeleted = false;
  folderPath = '';
  folderPicker: Type<any> = new WidgetOption<FolderPickerComponent>(WidgetType.folder, 'Folder', FolderPickerComponent, 'bi bi-folder').component;
  isShowFolderPicker: boolean;
  userPrivateFolderId: string;
  userDefaultTeamFolderId: string;
  injectorForWidgetComponent: Injector;
  currentUser: NewSystemUser;
  selectedAvrTypes: AvrTypesType[] = [];

  constructor(
    public inProgress = false,
    public artifactType = new NewArtifactType(),
    public options = new ArtifactTypeOptions(),
    public originalHelpingAttributes = new ArtifactTypeHelpingAttributes(),
    public editableHelpingAttributes = new ArtifactTypeHelpingAttributes(),
    public attributeColumns: TableColumn[] = [],
    public rowsPerPage: number | null = null,
    public rowsPerPageOptions: number[] = [],
  ) {}

  getChangedHelpingAttributes(): Record<string, any> {
    // remove _defaultSortOrder set by TableUnsortDirective
    if (this.originalHelpingAttributes) {
      this.originalHelpingAttributes.linkRestrictions.map(restriction => {
        if ('_defaultSortOrder' in restriction) delete (restriction as any)['_defaultSortOrder'];
      });
      this.originalHelpingAttributes.clientAttributes.map(attribute => {
        if ('_defaultSortOrder' in attribute) delete (attribute as any)['_defaultSortOrder'];
      });
    }
    if (this.editableHelpingAttributes) {
      this.editableHelpingAttributes.linkRestrictions.map(restriction => {
        if ('_defaultSortOrder' in restriction) delete (restriction as any)['_defaultSortOrder'];
        if (!restriction.isLinkRequired.value && restriction.isLinkRequired) {
          restriction.isLinkRequired = new SelectOption(restriction.isLinkRequired, restriction.isLinkRequired) as any;
        }
      });
      this.editableHelpingAttributes.clientAttributes.map(attribute => {
        if ('_defaultSortOrder' in attribute) delete (attribute as any)['_defaultSortOrder'];
      });
    }
    if (!this.originalHelpingAttributes || isEqual(this.originalHelpingAttributes, this.editableHelpingAttributes)) {
      return {};
    }

    const result: Record<string, any> = {};
    for (const typeKey in this.originalHelpingAttributes) {
      if (Object.prototype.hasOwnProperty.call(this.originalHelpingAttributes, typeKey)) {
        result[typeKey] = this.getChangedHelpingAttributesForTypeKey(typeKey);
      }
    }
    return result;
  }

  private getChangedHelpingAttributesForTypeKey(typeKey: string): any {
    const changed: Record<string, any> = {};
    const originalHelpingAttributes = (this.originalHelpingAttributes as any)[typeKey];
    const editableHelpingAttributes = (this.editableHelpingAttributes as any)[typeKey];
    for (const key in editableHelpingAttributes) {
      if (!isEqual(originalHelpingAttributes[key], editableHelpingAttributes[key])) {
        changed[key] = cloneDeep(editableHelpingAttributes[key]);
      }
    }
    return changed;
  }
}

export class ArtifactTypeHelpingAttributes {
  constructor(public linkRestrictions: UngroupedArtifactTypeLinkRestriction[] = [], public clientAttributes: NewArtifactTypeClientAttribute[] = []) {}
}

export class ArtifactTypeOptions {
  constructor(
    public formatOptions: SelectOption<string, ArtifactTypeFormatEnum>[] = [],
    public avrOptions: SelectOption<string, AvrTypesType>[] = [],
    public iconOptions: SelectOption<string, string>[] = [],
    public attributeOptions: SelectOption<string, NewAttribute>[] = [],
    public pageOptions: SelectOption<string, string>[] = [],
    public primaryAttributeOptions: SelectOption<string, NewArtifactTypeClientAttribute>[] = [],
    public textArtifactTypes: SelectOption<string, string>[] = [],
    public dataTypes = new ListContainer<NewDataType>(),
    public attributes = new ListContainer<NewAttribute>(),
    public users = new ListContainer<NewUser>(),
    public artifactTypes = new ListContainer<NewArtifactType>(),
    public applications = new ListContainer<NewApplication>(),
    public linkTypes = new ListContainer<LinkType>(),
  ) {
    this.formatOptions = Object.keys(ArtifactTypeFormatEnum).map(
      key => new SelectOption(key, ArtifactTypeFormatEnum[key as keyof typeof ArtifactTypeFormatEnum]),
    );
  }
}
