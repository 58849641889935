<div class="breakpoints-container">
  <p-tabMenu
    (activeItemChange)="onActiveTabChange($event)"
    [activeItem]="activeTab"
    [model]="breakpointTabs"
    [scrollable]="true"
    class="breakpoints-container__tabs">
    <ng-template let-tab let-defaultBreakpoint="index === 0" let-i="index" pTemplate="item">
      <div
        *ngIf="defaultBreakpoint; else customBreakpoint"
        class="p-inputtext p-inputtext-sm default-breakpoint">
        Default
      </div>

      <ng-template #customBreakpoint>
        <div class="custom-breakpoint">
          <p-inputNumber
            [(ngModel)]="tab.breakpoint.value"
            [min]="100"
            [useGrouping]="false"
            class="custom-breakpoint__value">
          </p-inputNumber>

          <p-button
            (click)="deleteTab(i)"
            class="custom-breakpoint__delete"
            icon="pi pi-trash"
            styleClass="p-button-rounded p-button-outlined p-button-danger p-button-text p-button-sm">
          </p-button>
        </div>
      </ng-template>
    </ng-template>
  </p-tabMenu>

  <div class="d-flex align-items-center ml-auto">
    <i
      [pTooltip]="hint | translate"
      class="pi pi-info-circle mr-2"
      tooltipPosition="top">
    </i>

    <p-button
      (click)="onAddTabClick()"
      icon="pi pi-plus"
      styleClass="p-button-rounded p-button-success p-button-sm">
    </p-button>
  </div>
</div>
