import { Injectable } from '@angular/core';
import { FOLDER_FILTER_KEY } from '@shared/constants/constants';
import { FilterMainControlService } from './filter-main-control.service';

@Injectable({ providedIn: 'root' })
export class FilterUrlControlService extends FilterMainControlService {
  isFolderFilterAttribute(attributeKey: string): boolean {
    return attributeKey === FOLDER_FILTER_KEY;
  }
}
