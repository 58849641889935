import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../services/authorization/authorization.service';

@Injectable({ providedIn: 'root' })
class HasTenantGuard {
  constructor(private readonly router: Router, private readonly authorizationService: AuthorizationService) {}

  async canActivate(): Promise<boolean> {
    return await this.checkAccess();
  }

  async canActivateChild(): Promise<boolean> {
    return await this.checkAccess();
  }

  private async checkAccess(): Promise<boolean> {
    const user = this.authorizationService.getLoggedUser;

    if (user?.tenant) {
      return true;
    }

    await this.router.navigateByUrl('/login');

    return false;
  }
}

export const CanActivateHasTenant: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  state;
  return inject(HasTenantGuard).canActivate();
};
