import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { DateTimeFilterMatchType, TimeFilterRuleType } from './date-time-filter-options.types';

export class DateTimeFilter {
  matchType: DateTimeFilterMatchType = DateTimeFilterMatchType.matchAll;
  ruleTypes: DateTimeFilterOption[] = [new DateTimeFilterOption()];

  constructor(dto?: DateTimeFilter) {
    dto && Object.assign(this, dto);
  }
}

export class DateTimeFilterOption {
  ruleType: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType = DateFilterEnum.dateIs;
  value: Date | Date[] | number | string | null;
}
