<div class="row">
    <div class="col-4 mb-0">
        <button (click)="currentStyle = button.labelStyle;fontStyleOp.toggle($event)"
                class="full-width-container" icon="pi pi-cog" label="Label" pButton>
        </button>
    </div>

    <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input [(ngModel)]="button.label" id="label" pInputText type="text" />
      <label class="dynamic-label-text" for="label" translate>{{'Label'| translate}}</label>
    </span>
    </div>
</div>

<div class="row">
    <div class="col-4 mb-0 ">
        <button class="full-width-container" (click)="currentStyle = button.subLabelStyle;fontStyleOp.toggle($event)"
                icon="pi pi-cog" label="Sub label" pButton></button>
    </div>

    <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input [(ngModel)]="button.subLabel" id="subLabel" pInputText type="text" />
      <label class="dynamic-label-text" for="subLabel" translate>{{'Sub label' | translate}}</label>
    </span>
    </div>
</div>

<div class="row">
    <div class="col-4 mb-0">
        <button (click)="currentStyle = button.chipStyle;fontStyleOp.toggle($event)"
                class="full-width-container" icon="pi pi-cog" label="Chip" pButton>
        </button>
    </div>

    <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input id="chip" [(ngModel)]="button.chip"  pInputText type="text" />
      <label class="dynamic-label-text" for="chip" translate>{{'Chip' | translate}}</label>
    </span>
    </div>
</div>

<div class="row">
    <div class="col-4 mb-0">
        <button (click)="currentStyle = button.iconStyle;fontStyleOp.toggle($event)"
                class="full-width-container" icon="pi pi-cog" label="Icon" pButton>
        </button>
    </div>

    <div class="col-8 mb-0">
    <span class="dynamic-label icon-field">
      <p-dropdown [(ngModel)]="button.icon"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [virtualScrollItemSize]="20"
                  [options]="iconOptions"
                  [showClear]="true"
                  [style]="{'width':'100%'}"
                  [virtualScroll]="true"
                  id="icon">

        <ng-template let-selectedIcon pTemplate="selected.item">
          <div *ngIf="button.icon" class="p-dropdown-item">
            <i [class]="selectedIcon.value"></i>
            <label class="ml-2">{{selectedIcon.label}}</label>
          </div>
        </ng-template>

        <ng-template let-icon pTemplate="item">
          <div class="p-dropdown-items">
            <i [class]="icon.value"></i>
            <label class="ml-2">{{icon.label}}</label>
          </div>
        </ng-template>

      </p-dropdown>
      <label class="dynamic-label-text" for="icon" translate>Icon</label>
    </span>
    </div>
</div>

<hr>

<div class="grid">
    <div class="col-4 mb-0">
        <button (click)="otherOp.toggle($event)"
                class="full-width-container" icon="pi pi-cog" label="{{'Other'| translate}}" pButton type="button">
        </button>
    </div>

    <div class="col-4 mb-0">
        <button (click)="borderOp.toggle($event)"
                class="full-width-container" icon="bi bi-border-style" label="{{'Borders'| translate}}" pButton type="button">
        </button>
    </div>

    <div class="col-4 mb-0">
        <button (click)="colorOp.toggle($event)" class="full-width-container" icon="pi pi-palette" label="{{'Colors'| translate}}" pButton type="button"></button>
    </div>
</div>

<p-overlayPanel #fontStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}" appendTo="body">
    <ng-template pTemplate>
        <app-content-style-form [contentStyle]="currentStyle"></app-content-style-form>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #colorOp [style]="{'max-width':'380px'}" appendTo="body">
    <ng-template pTemplate>
        <h5>{{'Colors'| translate}}</h5>
        <div class="col-12 mb-0">
            <app-color-picker [(color)]="button.generalStyles.backgroundColor"
                              label="{{'Background color'|translate}}"></app-color-picker>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #otherOp [style]="{'max-width':'380px'}" appendTo="body">
    <ng-template pTemplate>

        <h5>{{'Other styles'| translate}}</h5>
        <div class="row">

            <div class="col-6 mb-0">
                <h6> {{'Align button' | translate}}</h6>
                <p-dropdown
                        [(ngModel)]="settings.alignButton"
                        [autoDisplayFirst]="false"
                        [options]="horizontalAlignOptions"
                        id="buttonAlignement">
                </p-dropdown>
            </div>

        </div>
        <hr>
        <app-padding-form [paddingStyles]="button.generalStyles"></app-padding-form>
        <hr>
        <app-margin-form [marginStyles]="button.generalStyles"></app-margin-form>

    </ng-template>
</p-overlayPanel>

<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
    <ng-template pTemplate>
        <app-border-form [styles]="button.borderStyles"></app-border-form>

        <button (click)="styleCopyService.copyBorderStyles(button.borderStyles)"
                class="mr-2" icon="pi pi-copy" pButton pTooltip="{{'Copy style'| translate}}" type="button"></button>
        <button *ngIf="styleCopyService.copiedBorderStyles"
                (click)="styleCopyService.pasteBorderStyle(button.borderStyles)"
                icon="bi bi-clipboard-plus" pButton pTooltip="{{'Paste style'| translate}}" type="button"></button>
    </ng-template>
</p-overlayPanel>
