"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.postfixEval = function (e, a) {
  (a = a || {}).PI = Math.PI, a.E = Math.E;
  for (var p, u, r, l = [], s = void 0 !== a.n, t = 0; t < e.length; t++) if (1 === e[t].type) l.push({
    value: e[t].value,
    type: 1
  });else if (3 === e[t].type) l.push({
    value: a[e[t].value],
    type: 1
  });else if (0 === e[t].type) {
    var v = l[l.length - 1];
    Array.isArray(v) ? v.push(e[t]) : v.value = e[t].value(v.value);
  } else if (7 === e[t].type) {
    var y = l[l.length - 1];
    Array.isArray(y) ? y.push(e[t]) : y.value = e[t].value(y.value);
  } else if (8 === e[t].type) {
    for (var h = [], i = 0; i < e[t].numberOfArguments; i++) {
      var o = l.pop();
      o && h.push(o.value);
    }
    l.push({
      type: 1,
      value: e[t].value.apply(e[t], h.reverse())
    });
  } else if (10 === e[t].type) p = l.pop(), u = l.pop(), Array.isArray(u) ? ((u = u.concat(p)).push(e[t]), l.push(u)) : Array.isArray(p) ? (p.unshift(u), p.push(e[t]), l.push(p)) : l.push({
    type: 1,
    value: e[t].value(u.value, p.value)
  });else if (2 === e[t].type || 9 === e[t].type) p = l.pop(), u = l.pop(), Array.isArray(u) ? ((u = u.concat(p)).push(e[t]), l.push(u)) : Array.isArray(p) ? (p.unshift(u), p.push(e[t]), l.push(p)) : l.push({
    type: 1,
    value: e[t].value(u.value, p.value)
  });else if (12 === e[t].type) {
    p = l.pop();
    var n = void 0;
    n = !Array.isArray(p) && p ? [p] : p || [], u = l.pop(), r = l.pop(), l.push({
      type: 1,
      value: e[t].value(r.value, u.value, n)
    });
  } else 13 === e[t].type && (s ? l.push({
    value: a[e[t].value],
    type: 3
  }) : l.push([e[t]]));
  if (l.length > 1) throw new Error("Uncaught Syntax error");
  return parseFloat(l[0].value.toFixed(15));
};