<div class="grid" style="max-width: 100%">
  <div class="col-6">
    <h5 translate>Sticky header</h5>
    <p-checkbox [(ngModel)]="settings.sticky" binary="true" inputId="sticky" id="sticky"></p-checkbox>
    <label for="sticky" class="settings-checkbox-label">{{ settings.sticky }}</label>
  </div>

  <div class="col-6">
    <h5 translate>Table responsive</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="responsive" value="rebuild" [(ngModel)]="settings.responsive" inputId="rebuild"
                       id="rebuild"></p-radioButton>
        <label for="rebuild" translate>Rebuild</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="responsive" value="scroll" [(ngModel)]="settings.responsive" inputId="scroll"
                       id="scroll"></p-radioButton>
        <label for="scroll" translate>Scroll</label>
      </div>
    </div>
  </div>

  <div class="col-6">
    <h5 translate>Wrapping text in a cell</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="textWrap" value="wrap" [(ngModel)]="settings.textWrap"
                       (ngModelChange)="forceTableReRender()" inputId="wrap" id="wrap"></p-radioButton>
        <label for="wrap" translate>Wrap</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="textWrap" value="nowrap" [(ngModel)]="settings.textWrap"
                       (ngModelChange)="forceTableReRender()" inputId="nowrap" id="nowrap"></p-radioButton>
        <label for="nowrap" translate>Three dots</label>
      </div>
    </div>
  </div>

  <div class="col-6">
    <h5 translate>Show new lines breaks</h5>
    <p-checkbox [(ngModel)]="settings.showLineBreaks" (ngModelChange)="forceTableReRender()" binary="true"
                inputId="showLineBreaks" id="showLineBreaks"></p-checkbox>
    <label for="showLineBreaks" class="settings-checkbox-label">{{ settings.showLineBreaks }}</label>
  </div>

  <div class="col-6">
    <h5 translate>Do filter on url change</h5>
    <p-checkbox [(ngModel)]="settings.doFilterOnUrlChange" binary="true" inputId="filterOnUrl"
                id="filterOnUrl"></p-checkbox>
    <label for="filterOnUrl" class="settings-checkbox-label">{{ settings.doFilterOnUrlChange }}</label>
  </div>

  <div class="col-6">
    <h5 translate>Do sort on url change</h5>
    <p-checkbox [(ngModel)]="settings.doSortOnUrlChange" binary="true" inputId="sortOnUrl"
                id="sortOnUrl"></p-checkbox>
    <label for="sortOnUrl" class="settings-checkbox-label">{{ settings.doSortOnUrlChange }}</label>
  </div>

  <div class="col-12">
    <h5 translate>Column resize mode</h5>
    <p-selectButton
      [(ngModel)]="settings.columnResizeMode"
      [options]="options.columnResizeMode"
      optionLabel="label"
      optionValue="value">
    </p-selectButton>
  </div>

  <ng-container *ngTemplateOutlet="sectionSeparator; context: { name: 'Columns / Rows' }"></ng-container>

  <!-- <div class="col-6">
    <h5 translate>Size of table rows</h5>
    <div class="table-size-buttons-group radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="size" value="none" [(ngModel)]="settings.size" inputId="base" id="base"></p-radioButton>
        <label for="base" translate>Base</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="size" value="sm" [(ngModel)]="settings.size" inputId="small" id="small"></p-radioButton>
        <label for="small" translate>Small</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="size" value="n" [(ngModel)]="settings.size" inputId="normal" id="normal"></p-radioButton>
        <label for="normal" translate>Normal</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="size" value="lg" [(ngModel)]="settings.size" inputId="large" id="large"></p-radioButton>
        <label for="large" translate>Large</label>
      </div>
    </div>
  </div> -->

  <div class="col-12">
    <h5 translate>Table cell paddings</h5>
    <app-padding-form class="table-cell-padding-form" [paddingStyles]="settings.cellPaddings"
                      [showTitle]="false"></app-padding-form>
  </div>

  <div class="col-6">
    <h5 translate>Striped table rows</h5>
    <div class="radio_buttons_group">
      <div class="field-radiobutton">
        <p-radioButton name="striped" [value]="true" [(ngModel)]="settings.rowsStriped" inputId="striped"
                       id="striped"></p-radioButton>
        <label for="striped" translate>Striped</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="striped" [value]="false" [(ngModel)]="settings.rowsStriped" inputId="notStriped"
                       id="notStriped"></p-radioButton>
        <label for="notStriped" translate>Not striped</label>
      </div>
    </div>
  </div>

  <div class="col-6">
    <h5 translate>Use custom color schema</h5>
    <p-checkbox [(ngModel)]="settings.customColors" binary="true" inputId="useCustomColors"
                id="useCustomColors"></p-checkbox>
    <label for="useCustomColors" class="settings-checkbox-label">{{ settings.customColors }}</label>
  </div>


  <ng-container *ngIf="settings.customColors">
    <div class="col-5">
      <h6> {{ 'Header background'| translate }} </h6>
      <app-color-picker [(color)]="settings.background.header"></app-color-picker>
    </div>

    <div class="col-5 p-offset-1">
      <h6> {{ 'Rows background'| translate }} </h6>
      <app-color-picker [(color)]="settings.background.rows"></app-color-picker>
    </div>

    <div class="col-5">
      <h6> {{ 'Footer background'| translate }} </h6>
      <app-color-picker [(color)]="settings.background.footer"></app-color-picker>
    </div>

    <div class="col-5 p-offset-1">
      <ng-container *ngIf="settings.rowsStriped">
        <h6> {{ 'Rows odd'| translate }} </h6>
        <app-color-picker [(color)]="settings.background.rowsOdd"></app-color-picker>
      </ng-container>
    </div>
  </ng-container>

  <!-- <div class="col-4">
    <h6> {{'Footer background'| translate}} </h6>
    <app-color-picker [(color)]="settings.background.footer"></app-color-picker>
  </div> -->

  <ng-container *ngTemplateOutlet="sectionSeparator; context: { name: 'Group settings' }"></ng-container>

  <div class="col-6">
    <h5 translate>Group by</h5>
    <p-multiSelect *ngIf="settings.groupingOptions?.length; else noGroupingColumns"
                   [options]="settings.groupingOptions"
                   [(ngModel)]="settings.grouping.groupingAttributes"
                   [selectionLimit]="1"
                   (onChange)="onGroupByChange($event.value[0])"
                   defaultLabel="Select columns"
                   optionLabel="label">
    </p-multiSelect>
    <ng-template #noGroupingColumns>
      <span class="no-grouping-columns">No available grouping columns found</span>
    </ng-template>
  </div>

  <ng-container *ngIf="settings.grouping.groupingAttributes.length">
    <div class="col-6">
      <h5 translate>Also show empty groups</h5>
      <p-checkbox [(ngModel)]="settings.grouping.showEmptyGroups" binary="true" inputId="showEmptyGroups"
                  id="showEmptyGroups"></p-checkbox>
      <label for="showEmptyGroups" class="settings-checkbox-label">{{ settings.grouping.showEmptyGroups }}</label>
    </div>

    <div class="col-6">
      <h5 translate>Pagination</h5>
      <p-dropdown id="pagination"
                  [options]="options.groupPagination"
                  [(ngModel)]="settings.grouping.pagination"
                  (ngModelChange)="onPaginationChange($event)">
        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.label | translate }}
        </ng-template>
        <ng-template let-option pTemplate="item">
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
    </div>

    <div class="col-6">
      <h5 translate>Collapse</h5>
      <p-dropdown id="collapse"
                  [options]="options.groupCollapse"
                  [(ngModel)]="settings.grouping.groupCollapse">
        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.label | translate }}
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div style="pointer-events: auto" pTooltip="'Pagination per table' must be selected"
               [tooltipDisabled]="!option.disabled">
            {{ option.label | translate }}
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="col-6">
      <h5 translate>Sort</h5>
      <p-dropdown id="sort"
                  [options]="options.groupSort"
                  [(ngModel)]="settings.grouping.sortSettings.sortType">
        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.label | translate }}
        </ng-template>
        <ng-template let-option pTemplate="item">
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
    </div>

    <div class="col-6">
      <h5 translate>Order</h5>
      <p-dropdown id="order"
                  [options]="options.groupOrder"
                  [(ngModel)]="settings.grouping.sortSettings.order">
        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.label | translate }}
        </ng-template>
        <ng-template let-option pTemplate="item">
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
    </div>
  </ng-container>

  <ng-container *ngIf="settings.grouping.groupingAttributes | isGroupingByDate">
    <div class="col-6">
      <h5 translate>Select date range</h5>
      <p-dropdown id="groupByDateRange"
                  inputId="groupByDateRange"
                  [filter]="true"
                  [options]="options.groupByDateRange"
                  [(ngModel)]="settings.grouping.groupByDate.filter">
        <ng-template let-selectedOption pTemplate="selectedItem">
          {{ selectedOption.label | translate }}
        </ng-template>
        <ng-template let-option pTemplate="item">
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-6">
      <ng-container *ngIf="settings.grouping.groupByDate.filter | shouldShowDateOffsetOption">
        <h5 translate>Offset</h5>
        <p-inputNumber id="groupByDateOffset" [(ngModel)]="settings.grouping.groupByDate.customSettings.offsetInDays"
                       [min]="0" [max]="1000"></p-inputNumber>
      </ng-container>
    </div>

    <ng-container *ngIf="settings.grouping.groupByDate.filter | shouldShowCustomRangeCalendarOption">
      <div class="col-6">
        <h5 translate>From</h5>
        <p-calendar [(ngModel)]="settings.grouping.groupByDate.customSettings.start"
                    [firstDayOfWeek]="firstDayOfWeek"
                    [dateFormat]="dateFormat"
                    [showTime]="true">
        </p-calendar>
      </div>
      <div class="col-6">
        <h5 translate>To</h5>
        <p-calendar [(ngModel)]="settings.grouping.groupByDate.customSettings.end"
                    [firstDayOfWeek]="firstDayOfWeek"
                    [dateFormat]="dateFormat"
                    [showTime]="true">
        </p-calendar>
      </div>
    </ng-container>
  </ng-container>

  <div class="col-12" style="text-align: center">
    <button pButton type="button" label="{{'refreshGroups' | translate}}" class="p-button-outlined p-button-info"
            (click)="forceTableReRender()"></button>
  </div>

  <ng-container *ngTemplateOutlet="sectionSeparator; context: { name: 'Other settings' }"></ng-container>

  <div class="col-6">
    <h5 translate>Show sum of numeric attributes</h5>
    <p-checkbox [(ngModel)]="settings.showSum" binary="true" inputId="showSum" id="showSum"></p-checkbox>
    <label for="showSum" class="settings-checkbox-label">{{ settings.showSum }}</label>
  </div>

  <div class="col-6">
    <h5 translate>Show count of entries</h5>
    <p-checkbox [(ngModel)]="settings.showCount" binary="true" inputId="showCount" id="showCount"></p-checkbox>
    <label for="showCount" class="settings-checkbox-label">{{ settings.showCount }}</label>
  </div>

  <ng-container *ngIf="settings.showSum">
    <div class="col-6">
      <h5 translate>Sum - minimal decimal places</h5>
      <p-inputNumber [(ngModel)]="settings.minDecimalPlacesOfSum" (ngModelChange)="onMinDecimalPlacesChange($event)" id="minDecimalPlaces" />
    </div>

    <div class="col-6">
      <h5 translate>Sum - maximal decimal places</h5>
      <p-inputNumber [(ngModel)]="settings.maxDecimalPlacesOfSum" (ngModelChange)="onMaxDecimalPlacesChange($event)" id="maxDecimalPlaces" />
    </div>
  </ng-container>

  <div class="col-6">
    <h5 translate>Caption</h5>
    <input type="text" pInputText [(ngModel)]="settings.caption">
  </div>

  <div class="col-6">
    <h5 translate>Empty message</h5>
    <input type="text" pInputText [(ngModel)]="settings.emptyMessage">
  </div>

  <div class="col-12">
    <h5>{{ settings.grouping.pagination | getRowsPerPageHeader : settings.grouping.groupingAttributes }}</h5>
    <p-inputNumber [(ngModel)]="settings.rowsPerPage" placeholder="50" [min]="1" [max]="1000"></p-inputNumber>
  </div>

  <div *ngIf="shouldShowAddButtonSetting" class="col-6">
    <h5 translate>Show add button</h5>
    <p-checkbox [(ngModel)]="settings.showAddButton" binary="true" inputId="showAdd" id="showAdd"></p-checkbox>
    <label for="showAdd" class="settings-checkbox-label">{{ settings.showAddButton }}</label>
  </div>

  <div class="col-6">
    <ng-container>
      <h5 translate>Add button label</h5>
      <input type="text" placeholder="Add" pInputText [(ngModel)]="settings.addButtonLabel"
             [disabled]="!settings.showAddButton">
    </ng-container>
  </div>

  <div class="col-6">
    <h5 translate>Show export button</h5>
    <p-checkbox [(ngModel)]="settings.showExportButton" binary="true" inputId="showExport" id="showExport"></p-checkbox>
    <label for="showExport" class="settings-checkbox-label">{{ settings.showExportButton }}</label>
  </div>

  <div class="col-6">
    <h5 translate>Expoprt file name</h5>
    <input type="text" placeholder="file name" pInputText [(ngModel)]="settings.exportFileName"
           [disabled]="!settings.showExportButton">
  </div>

  <div *ngIf="settings.loadMode | isLoadByModule" class="col-6">
    <span class="dynamic-label">
      <label translate>key for listening to url change</label>
      <input type="text" pInputText [(ngModel)]="settings.urlKeys.listeningKeys.artifactId"
             placeholder="Input listening key">
    </span>
  </div>

  <div class="col-6">
    <h5 translate>Show editing buttons</h5>
    <div class="field-radiobutton">
      <p-checkbox [(ngModel)]="settings.editableRow" binary="true" id="editableRow" inputId="editableRow"></p-checkbox>
      <label for="editableRow" class="settings-checkbox-label">{{ settings.editableRow }}</label>
    </div>
  </div>

  <div
    [style.padding-bottom.px]="!settings.rowClickHandle ? 300 : !shouldShowNumberOfSelectionSetting ? 100 : null"
    class="col-12">
    <div class="grid">
      <div class="col-12">
        <app-list-item-click-action-settings-form
          (multiselectChange)="multiselectChange.emit($event)"
          [(clickAction)]="settings.rowClickHandleAction"
          [(emittingUrlKey)]="settings.urlKeys.emittingKeys.artifactId"
          [(emittingFolderUrlKey)]="settings.urlKeys.emittingKeys.saveToFolderId"
          [(shouldEmitFolderUrlKey)]="settings.emitFolderUrl"
          [(externalPage)]="settings.externalPage"
          [(handleClick)]="settings.rowClickHandle"
          [(multiselect)]="settings.multiselect"
          [(openPageInNewTab)]="settings.goToPageInNewTab"
          [(selectedPage)]="settings.selectedPage"
          [filtersHolder]="$any(settings)"
          [filtersKey]="'filtersForQueryParams'"
          [filtersDisabled]="true"
          [options]="clickActionSettingsOptions"
          [showFolderEmittingOption]="settings.loadMode | shouldShowFolderEmittingOption : selected.artifactTypes[0]?.value"
          [pageOptions]="options.pages.list | transformArrayToSelectOptions : 'name' : 'id'">
        </app-list-item-click-action-settings-form>
      </div>

      <div *ngIf="shouldShowNumberOfSelectionSetting" class="col-12">
        <h5 translate>Show number of selected</h5>
        <div class="field-radiobutton">
          <p-checkbox
            [(ngModel)]="settings.showNumberOfSelected"
            binary="true"
            inputId="showNumberOfSelected">
          </p-checkbox>
          <label for="showNumberOfSelected" class="settings-checkbox-label">{{ settings.showNumberOfSelected }}</label>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ng templates -->
<ng-template #sectionSeparator let-name="name">
  <div class="col-4">
    <hr>
  </div>
  <div class="col-4 divider-header">{{ name | translate }}</div>
  <div class="col-4">
    <hr>
  </div>
</ng-template>
