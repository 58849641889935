import { inject, Injectable } from '@angular/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { RuntimeVariablesOptions } from '@widgets/shared/types/runtime-variables/runtime-variables-options.types';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { RuntimeVariableKey, RuntimeVariables } from '../types/runtime-variables/replace-runtime-variables.types';

@Injectable({ providedIn: 'root' })
export class ReplaceRuntimeVariablesService {
  readonly options: RuntimeVariablesOptions;
  private readonly runtimeVariableKeys: string[];
  private readonly runtimeVariables: RuntimeVariables;
  private readonly cache = inject(NewCacheService);
  private variableRegExp = new RegExp('{.*?}', 'g');
  private userSubscription: Subscription;

  constructor() {
    this.options = new RuntimeVariablesOptions();
    this.runtimeVariables = new RuntimeVariables();
    this.runtimeVariableKeys = Object.values(RuntimeVariableKey);
  }

  get loaded(): boolean {
    return this.runtimeVariables.loaded;
  }

  async init(): Promise<void> {
    if (!this.loaded) {
      await this.options.init();
      await this.runtimeVariables.init(this.options);

      if (!this.userSubscription) {
        this.userSubscription = this.cache.user.subscribe({
          next: async () => {
            this.runtimeVariables.loaded = false;
            await this.init();
          },
        });
      }
    }
  }

  getValueByPath(path: string): any {
    return get(this.runtimeVariables, path);
  }

  isRuntimeVariable(key: string): boolean {
    return this.runtimeVariableKeys.includes(key);
  }

  getTextVariables(text: string): Set<string> {
    const variables = text?.match(this.variableRegExp)?.map(variable => variable.replace('{', '').replace('}', '')) || [];
    return new Set(variables);
  }
}
