import { Pipe, PipeTransform } from '@angular/core';
import { GroupItem } from '../types/list-widget-grouping.types';

@Pipe({ name: 'isTableEmpty' })
export class IsTableEmptyPipe implements PipeTransform {
  transform(groupItems: GroupItem[]): unknown {
    if (!groupItems) return true;
    return !groupItems.length || (groupItems.length === 1 && !groupItems[0].artifacts.length);
  }
}
