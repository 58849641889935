import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ClientData } from '@shared/types/local-storage.types';
import { AclItem, AclMemberTypes } from '@widgets/shared/components/acl/types/acl.types';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
})
export class UserPickerComponent {
  @Input() label: string;
  @Input() memberType: AclMemberTypes = AclMemberTypes.all;
  @Input() itemList: any;
  @Input() selectedItem: AclItem[];
  @Input() showExpiration = false;
  @Input() expiration: Date;

  @Output() onClose = new EventEmitter();
  @Output() onUpdateList = new EventEmitter();
  @Output() onAddMembers = new EventEmitter<any>();

  memberTypesList: string[] = Object.values(AclMemberTypes);
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  userData: ClientData;

  constructor(private readonly cache: NewCacheService) {
    this.userData = (this.cache.user.value as SelfUserResponseDto).clientData as ClientData;
  }

  closePicker(): void {
    this.onClose.emit();
  }

  updateList(): void {
    this.onUpdateList.emit(this.memberType);
  }

  addMembers(): void {
    this.selectedItem.forEach(item => (item.expirationDate = this.expiration || null));
    this.onAddMembers.emit(this.selectedItem);
  }
}
