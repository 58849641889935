import { Injectable } from '@angular/core';
import { Constants, CREATED_BY_KEY, CREATED_ON_KEY, ID_KEY, UPDATED_BY_KEY, UPDATED_ON_KEY } from '@shared/constants/constants';
import { ApplicableSorts } from '@widgets/shared/components/artifact-sorts/types/applicable-sorts';
import { ArtifactSort } from '@widgets/shared/components/artifact-sorts/types/artifact-sort';

const customAttributeKeys: { [attributeId: string]: string } = {
  '6172746966616374735f6964': ID_KEY,
  '637265617465627975736572': CREATED_BY_KEY,
  '757064617465627975736572': UPDATED_BY_KEY,
  '6372656174656f6e64617465': CREATED_ON_KEY,
  '7570646174656f6e64617465': UPDATED_ON_KEY,
};

@Injectable({
  providedIn: 'root',
})
export class ArtifactSortsService {
  getQuerySort(sorts: ArtifactSort[]): string | undefined {
    const isApplicableSortAbsent = !sorts.some(({ isApplicable }: ArtifactSort) => isApplicable);

    if (isApplicableSortAbsent) {
      return;
    }

    return JSON.stringify(this.getApplicableSorts(sorts));
  }

  private getApplicableSorts(sorts: ArtifactSort[]): ApplicableSorts {
    return sorts.reduce((sortsObject: ApplicableSorts, sort: ArtifactSort) => {
      if (!sort.isApplicable) {
        return sortsObject;
      }

      const isCustomAttribute = Constants.artifactCustomAttributes.some(({ value }: { value: string }) => value === sort.attributeId);
      const field = isCustomAttribute ? customAttributeKeys[sort.attributeId] : `attributes.${sort.attributeId}.value`;

      return { ...sortsObject, [field]: sort.order } as ApplicableSorts;
    }, {});
  }
}
