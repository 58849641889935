/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { SelfUserResponseDto } from '../models/self-user-response-dto';
import { userControllerCount } from '../fn/tenant-user/user-controller-count';
import { UserControllerCount$Params } from '../fn/tenant-user/user-controller-count';
import { userControllerCreate } from '../fn/tenant-user/user-controller-create';
import { UserControllerCreate$Params } from '../fn/tenant-user/user-controller-create';
import { userControllerDelete } from '../fn/tenant-user/user-controller-delete';
import { UserControllerDelete$Params } from '../fn/tenant-user/user-controller-delete';
import { userControllerGet } from '../fn/tenant-user/user-controller-get';
import { UserControllerGet$Params } from '../fn/tenant-user/user-controller-get';
import { userControllerGetInfo } from '../fn/tenant-user/user-controller-get-info';
import { UserControllerGetInfo$Params } from '../fn/tenant-user/user-controller-get-info';
import { userControllerList } from '../fn/tenant-user/user-controller-list';
import { UserControllerList$Params } from '../fn/tenant-user/user-controller-list';
import { userControllerResetPassword } from '../fn/tenant-user/user-controller-reset-password';
import { UserControllerResetPassword$Params } from '../fn/tenant-user/user-controller-reset-password';
import { userControllerUpdateApplicationByAppAdmin } from '../fn/tenant-user/user-controller-update-application-by-app-admin';
import { UserControllerUpdateApplicationByAppAdmin$Params } from '../fn/tenant-user/user-controller-update-application-by-app-admin';
import { userControllerUpdateClientData } from '../fn/tenant-user/user-controller-update-client-data';
import { UserControllerUpdateClientData$Params } from '../fn/tenant-user/user-controller-update-client-data';
import { userControllerUpdateUserByTenantAdmin } from '../fn/tenant-user/user-controller-update-user-by-tenant-admin';
import { UserControllerUpdateUserByTenantAdmin$Params } from '../fn/tenant-user/user-controller-update-user-by-tenant-admin';
import { UserFullResponseDto } from '../models/user-full-response-dto';
import { UserListResponseDto } from '../models/user-list-response-dto';
import { UserResponseDto } from '../models/user-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userControllerGetInfo()` */
  static readonly UserControllerGetInfoPath = '/api/tenant/user/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGetInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetInfo$Response(params?: UserControllerGetInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfUserResponseDto>> {
    return userControllerGetInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerGetInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGetInfo(params?: UserControllerGetInfo$Params, context?: HttpContext): Observable<SelfUserResponseDto> {
    return this.userControllerGetInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfUserResponseDto>): SelfUserResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateClientData()` */
  static readonly UserControllerUpdateClientDataPath = '/api/tenant/user/update-client-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateClientData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateClientData$Response(params: UserControllerUpdateClientData$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerUpdateClientData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateClientData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateClientData(params: UserControllerUpdateClientData$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerUpdateClientData$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateApplicationByAppAdmin()` */
  static readonly UserControllerUpdateApplicationByAppAdminPath = '/api/tenant/user/update-application-by-app-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateApplicationByAppAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateApplicationByAppAdmin$Response(params: UserControllerUpdateApplicationByAppAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerUpdateApplicationByAppAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateApplicationByAppAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateApplicationByAppAdmin(params: UserControllerUpdateApplicationByAppAdmin$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerUpdateApplicationByAppAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerUpdateUserByTenantAdmin()` */
  static readonly UserControllerUpdateUserByTenantAdminPath = '/api/tenant/user/update-user-by-tenant-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateUserByTenantAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateUserByTenantAdmin$Response(params: UserControllerUpdateUserByTenantAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerUpdateUserByTenantAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateUserByTenantAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerUpdateUserByTenantAdmin(params: UserControllerUpdateUserByTenantAdmin$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerUpdateUserByTenantAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerList()` */
  static readonly UserControllerListPath = '/api/tenant/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerList$Response(params?: UserControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<UserListResponseDto>> {
    return userControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerList(params?: UserControllerList$Params, context?: HttpContext): Observable<UserListResponseDto> {
    return this.userControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserListResponseDto>): UserListResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerCreate()` */
  static readonly UserControllerCreatePath = '/api/tenant/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate$Response(params: UserControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerCreate(params: UserControllerCreate$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerCount()` */
  static readonly UserControllerCountPath = '/api/tenant/user/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCount$Response(params?: UserControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return userControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerCount(params?: UserControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.userControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `userControllerGet()` */
  static readonly UserControllerGetPath = '/api/tenant/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGet$Response(params: UserControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerGet(params: UserControllerGet$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerDelete()` */
  static readonly UserControllerDeletePath = '/api/tenant/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDelete$Response(params: UserControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>> {
    return userControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerDelete(params: UserControllerDelete$Params, context?: HttpContext): Observable<(UserResponseDto | UserFullResponseDto)> {
    return this.userControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<(UserResponseDto | UserFullResponseDto)>): (UserResponseDto | UserFullResponseDto) => r.body)
    );
  }

  /** Path part for operation `userControllerResetPassword()` */
  static readonly UserControllerResetPasswordPath = '/api/tenant/user/{id}/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerResetPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerResetPassword$Response(params: UserControllerResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerResetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerResetPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerResetPassword(params: UserControllerResetPassword$Params, context?: HttpContext): Observable<void> {
    return this.userControllerResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
