import { Component, Input } from '@angular/core';
import { SystemTenantResponseDto } from '@api/models/system-tenant-response-dto';
import { NAME_KEY, TENANT_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';

@Component({
  selector: 'app-tenant-list-table',
  templateUrl: './tenant-list-table.component.html',
  styleUrls: ['./tenant-list-table.component.scss'],
})
export class TenantListTableComponent extends CoreListComponent<SystemTenantResponseDto> {
  @Input() declare readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<SystemTenantResponseDto>>;
  @Input() columns: TableColumn[];

  TENANT_VALUE = TENANT_KEY;
  NAME_VALUE = NAME_KEY;

  constructor() {
    super();
  }
}
