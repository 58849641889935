import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { ArtifactTypeModel } from '@private/pages/artifact-type-management/artifact-type/types/artifact.type.types';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';

@Component({
  selector: 'app-artifact-type-attributes-form',
  templateUrl: './artifact-type-attributes-form.component.html',
  styleUrls: ['./artifact-type-attributes-form.component.scss'],
})
export class ArtifactTypeAttributesFormComponent {
  @Input() m: ArtifactTypeModel;
  @Input() updatePrimaryAttributes: () => void;
  @Input() updateAvailableAttributes: () => void;
  @Input() onAttributeChange: (artifactTypeAttribute: NewArtifactTypeClientAttribute, index: number) => void;

  addAttribute(): void {
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes, new NewArtifactTypeClientAttribute()];
  }

  dropItem(event: CdkDragDrop<any, any>): void {
    moveItemInArray(this.m.editableHelpingAttributes.clientAttributes, event.previousIndex, event.currentIndex);
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes];
  }

  removeAttribute(index: number): void {
    this.m.editableHelpingAttributes.clientAttributes = this.m.editableHelpingAttributes.clientAttributes.filter((_, i) => i !== index);
    this.updatePrimaryAttributes();
    this.updateAvailableAttributes();
  }
}
