import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { BaseEnumFilterService } from './base-enum-filter.service';

@Injectable({ providedIn: 'root' })
export class ArtifactTypeEnumFilterService extends BaseEnumFilterService {
  constructor(filterUtil: FilterUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null {
    if (!value) return null;

    const enumValues = this.filterUtil.toArray(value).map(id => ({ $oid: id }));
    return this.getResultQuery(attributeKey, enumValues, filterRule);
  }
}
