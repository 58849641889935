import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable } from 'rxjs';
import { ArtifactListWidgetTableHelper } from '../../services/artifact-list-widget-table-helper.service';
import { ArtifactModuleHelper } from '../../services/artifact-module.helper';
import { NewModuleArtifact, SelectedRowModuleArtifact } from '../../types/artifact-list-widget-table.types';
import { ListWidgetTableSettings } from '../../types/list-widget-settings.types';
import { AddArtifactModulePositionEnum, ArtifactPromotionAction } from '../../types/list-widget.types';

@Component({
  selector: 'app-artifact-list-widget-table-module-utility-buttons',
  templateUrl: './artifact-list-widget-table-module-utility-buttons.component.html',
  styleUrls: ['./artifact-list-widget-table-module-utility-buttons.component.scss'],
})
export class ArtifactListWidgetTableModuleUtilityButtonsComponent {
  @Input() data: NewArtifact[];
  @Input() artifact: NewArtifact;
  @Input() rowIndex: number;
  @Input() settings: ListWidgetTableSettings;
  @Input() addButtonItems: MenuItem[];
  @Input() invokeLazyLoadData: () => Promise<void>;
  @Input() latestLazyLoadEvent: LazyLoadEvent | null;
  @Input() latestProcessedFilters: Record<string, any>;
  @ViewChild('addModuleRowOverlay') addModuleRowOverlay: OverlayPanel;

  newModuleArtifact = new NewModuleArtifact();
  selectedRowArtifact = new SelectedRowModuleArtifact();

  protected readonly AddArtifactModulePositionEnum = AddArtifactModulePositionEnum;
  protected readonly ArtifactPromotionAction = ArtifactPromotionAction;

  constructor(
    private readonly announcement: AnnouncementService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly router: Router,
    private readonly moduleHelper: ArtifactModuleHelper,
    private readonly tableHelper: ArtifactListWidgetTableHelper,
  ) {}

  onPromotionClick(action: ArtifactPromotionAction, id: string): void {
    if (!this.tableHelper.isModuleEditingEnabled(this.latestProcessedFilters, this.latestLazyLoadEvent)) {
      this.announcement.warn('To promote or demote artifact, table must have no active filter and sort must be set by Section (ascending)');
      return;
    }

    const promotion$: Observable<ArtifactResponseDto> =
      action === ArtifactPromotionAction.PROMOTE
        ? this.tenantArtifactService.artifactControllerModulePromote({ id })
        : this.tenantArtifactService.artifactControllerModuleDemote({ id });

    promotion$.subscribe({
      next: () => this.invokeLazyLoadData(),
      error: () => this.announcement.error(`Failed to ${action} artifact`),
    });
  }

  onAddNewModuleArtifactClick(event: Event, position: AddArtifactModulePositionEnum, artifact: NewArtifact, index: number): void {
    if (!this.tableHelper.isModuleEditingEnabled(this.latestProcessedFilters, this.latestLazyLoadEvent)) {
      this.announcement.warn('To add new artifact, table must have no active filter and sort must be set by Section (ascending)');
      return;
    }

    this.selectedRowArtifact = new SelectedRowModuleArtifact({ artifact, index });
    const isHeading = this.shouldNewModuleArtifactBeHeading(position);
    this.newModuleArtifact = new NewModuleArtifact({
      artifactType: this.addButtonItems.find(item => item.id === artifact.artifactTypeId) || null,
      isHeading,
      position,
    });

    this.addModuleRowOverlay.show(event);
  }

  navigateToDefaultPageWithModuleParams(): void {
    const queryParams = this.moduleHelper.getQueryParamsForArtifactWidget({
      newModuleArtifact: this.newModuleArtifact,
      selectedRowArtifact: this.selectedRowArtifact,
      settings: this.settings,
      data: this.data,
    });
    const url = this.router.serializeUrl(this.router.createUrlTree(this.newModuleArtifact.artifactType?.routerLink, { queryParams }));
    window.open(url, '_blank', 'noopener');
  }

  private shouldNewModuleArtifactBeHeading(position: AddArtifactModulePositionEnum): boolean {
    if (position === AddArtifactModulePositionEnum.BEFORE) {
      const previousArtifact = this.data[this.selectedRowArtifact.index - 1];
      return !!previousArtifact && !!previousArtifact.moduleData?.isHeading;
    }
    return !!this.selectedRowArtifact.artifact?.moduleData?.isHeading;
  }
}
