import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-artifact-widget-login-field',
  templateUrl: './artifact-widget-login-field.component.html',
  styleUrls: ['./artifact-widget-login-field.component.scss'],
})
export class ArtifactWidgetLoginFieldComponent implements OnInit {
  @Input() item: ArtifactWidgetFormItem;
  @Input() attr: NewClientAttribute;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() index: number;
  @Input() onChangeCb: () => void;
  @Input() onBlurCb: () => void;
  @Input() changeFormFocus: () => void;

  labelBehaviourEnum = LabelBehaviourEnum;
  disabled: boolean;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    const { token, email } = this.route.snapshot.queryParams;

    if (token) {
      this.disabled = true;
      this.attr.value = email;
    }
  }
}
