import { Pipe, PipeTransform } from '@angular/core';
import { NonAttributeKeys } from '@shared/types/attribute.types';

@Pipe({ name: 'toSystemUserKey' })
export class ToSystemUserKeyPipe implements PipeTransform {
  transform(userKeyOrId: string): string {
    if (NonAttributeKeys.isUserSpecificAttributeId(userKeyOrId)) {
      return userKeyOrId === NonAttributeKeys.CREATED_BY_ID ? NonAttributeKeys.CREATED_BY : NonAttributeKeys.UPDATED_BY;
    }
    return userKeyOrId;
  }
}
