import { Component, Input } from '@angular/core';
import { ALIAS_LABEL } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { AttributeMultipleValuesOrdering, NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-attribute-form',
  templateUrl: './attribute-form.component.html',
  styleUrls: ['./attribute-form.component.scss'],
})
export class AttributeFormComponent {
  @Input() attribute: NewAttribute;
  @Input() applications: NewApplication[];
  @Input() dataTypes: ListContainer<NewDataType>;

  ALIAS_LABEL = ALIAS_LABEL;
  attributeMultipleValuesOrderingOptions = Object.entries(AttributeMultipleValuesOrdering).map(([key, value]) => new SelectOption(key, value));

  get isBoolean(): boolean {
    return !!this.attribute?.dataTypeId && !!this.dataType?.isBoolean;
  }

  get isHtml(): boolean {
    return !!this.attribute?.dataTypeId && !!this.dataType?.isHtml;
  }

  get isCounter(): boolean {
    return !!this.attribute?.dataTypeId && !!this.dataType?.isCounter;
  }

  onDataTypeChange(): void {
    if (this.isHtml || this.isBoolean || this.isCounter) {
      this.attribute.multipleValues = false;
      this.attribute.counterFormat = 'ID-${value, minLength: 4, format: arabic}';
    } else {
      this.attribute.counterFormat = '';
    }
  }

  doChangeIcon(icon: string): void {
    this.attribute.icon = icon || '';
  }

  private get dataType(): NewDataType | null {
    return this.dataTypes.loaded ? this.dataTypes.listMap[this.attribute.dataTypeId] : null;
  }
}
