import { WidgetModel } from '../../shared/types/widget-model.interface';
import { TagWidgetOptions } from './tag-widget-options.types';
import { TagWidgetSettings, TagWidgetSettingsDto } from './tag-widget-settings.types';

export class TagWidgetValue {
  constructor(public model: TagWidgetModel = new TagWidgetModel()) {}
}

export class TagWidgetModel implements WidgetModel<TagWidgetModelDto> {
  settings = new TagWidgetSettings();
  options = new TagWidgetOptions();

  constructor(dto?: TagWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): TagWidgetModelDto {
    return {
      settings: this.settings.toServer(),
    };
  }

  fromDto(dto: TagWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new TagWidgetSettings(dto.settings));
    }
  }
}

export interface TagWidgetModelDto {
  settings: TagWidgetSettingsDto;
}
