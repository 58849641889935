import { Injectable } from '@angular/core';
import { OauthTokenResponseDto, SelfUserResponseDto } from '@api/models';
import { TenantOslcService, TenantUserService } from '@api/services';
import { ProfileComponent } from '@private/pages/profile/profile.component';
import { ProfileModel } from '@private/pages/profile/types/user.types';
import { Constants } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { StateKey } from '@shared/types/local-storage.types';
import { firstValueFrom, take } from 'rxjs';

@Injectable()
export class ProfileService extends CoreService<ProfileComponent, ProfileModel> {
  constructor(private readonly tenantUserService: TenantUserService, private readonly tenantOslcService: TenantOslcService) {
    super();
  }

  async updateLocalStorageUser(): Promise<void> {
    window.addEventListener(
      'message',
      event => {
        // parent is listening message from popup, parses and handled data
        if (event.origin !== window.location.origin) return;
        let data: any;
        try {
          data = JSON.parse(event.data);
        } catch (error) {
          return;
        }
        if (!window.opener && data.oslcLogin === true && data.oslcToken && data.oslcSecret && data.oslcDomain) {
          const user: SelfUserResponseDto = this.c.localStorageService.getFromState(StateKey.session, Constants.user);
          const oslcDataIndex = user.oslcData.findIndex(oslcData => oslcData.domain === data.oslcDomain);
          const newOslcData = {
            domain: data.oslcDomain,
            accessToken: {
              token: data.oslcToken,
              secret: data.oslcSecret,
            },
            userToken: {} as OauthTokenResponseDto,
          };
          oslcDataIndex !== -1 ? (user.oslcData[oslcDataIndex] = newOslcData) : user.oslcData.push(newOslcData);
          this.c.localStorageService.setToState(StateKey.session, Constants.user, user);
          this.m.user = user;
        }
      },
      false,
    );

    this.c.route.queryParams.pipe(take(1)).subscribe(async params => {
      if (params.oslcLogin === 'true') {
        if (window.opener) {
          // popup window only postMessage to parent with query params
          window.opener.postMessage(
            JSON.stringify({
              oslcLogin: true,
              oslcToken: params.oslcToken,
              oslcSecret: params.oslcSecret,
              oslcDomain: params.oslcDomain,
            }),
            '*',
          );
          window.close();
        }
      } else {
        try {
          const user: SelfUserResponseDto = await firstValueFrom(this.tenantUserService.userControllerGetInfo());
          this.c.localStorageService.setToState(StateKey.session, Constants.user, user);
          this.m.user = user;
        } catch (error) {
          this.c.announcement.error('Unable to update user in local storage');
        }
      }
    });
  }

  async getOslcUserAuthUrl(rootService: string): Promise<string | null> {
    try {
      return (await firstValueFrom(this.tenantOslcService.oslcControllerUserAuth({ domain: rootService }))).authUrl;
    } catch (error) {
      console.log(error);
      this.c.announcement.error('Unable to get OSLC login dialog');
    }
    return null;
  }
}
