/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseImportExportResponseDto } from '../models/base-import-export-response-dto';
import { exportImportControllerImport } from '../fn/tenant-export-import/export-import-controller-import';
import { ExportImportControllerImport$Params } from '../fn/tenant-export-import/export-import-controller-import';
import { exportImportControllerParseImport } from '../fn/tenant-export-import/export-import-controller-parse-import';
import { ExportImportControllerParseImport$Params } from '../fn/tenant-export-import/export-import-controller-parse-import';
import { ParsedExportResponseDto } from '../models/parsed-export-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantExportImportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportImportControllerImport()` */
  static readonly ExportImportControllerImportPath = '/api/tenant/export-import/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportImportControllerImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportImportControllerImport$Response(params: ExportImportControllerImport$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BaseImportExportResponseDto>>> {
    return exportImportControllerImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportImportControllerImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportImportControllerImport(params: ExportImportControllerImport$Params, context?: HttpContext): Observable<Array<BaseImportExportResponseDto>> {
    return this.exportImportControllerImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseImportExportResponseDto>>): Array<BaseImportExportResponseDto> => r.body)
    );
  }

  /** Path part for operation `exportImportControllerParseImport()` */
  static readonly ExportImportControllerParseImportPath = '/api/tenant/export-import/parse-export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportImportControllerParseImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportImportControllerParseImport$Response(params: ExportImportControllerParseImport$Params, context?: HttpContext): Observable<StrictHttpResponse<ParsedExportResponseDto>> {
    return exportImportControllerParseImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportImportControllerParseImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  exportImportControllerParseImport(params: ExportImportControllerParseImport$Params, context?: HttpContext): Observable<ParsedExportResponseDto> {
    return this.exportImportControllerParseImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ParsedExportResponseDto>): ParsedExportResponseDto => r.body)
    );
  }

}
