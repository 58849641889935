import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { TransformArrayToSelectOptionsPipe } from '@shared/pipes/common/transform-array-to-select-options.pipe';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartWidgetComponent } from './chart-widget.component';
import { ChartViewComponent } from './components/chart-view/chart-view.component';
import { ChartWidgetSettingsComponent } from './components/chart-widget-settings/chart-widget-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [ChartWidgetComponent, ChartWidgetSettingsComponent, ChartViewComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
    MultiSelectModule,
    CommonPipesModule,
    AccordionModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    OverlayPanelModule,
    ArtifactFiltersModule,
    StyleFormModule,
  ],
  providers: [TransformArrayToSelectOptionsPipe],
  exports: [ChartWidgetComponent],
})
export class ChartWidgetModule {}
