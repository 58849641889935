<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid" *ngIf="m">
  <div class="col-12 top_box">
    <div>
      <div *ngIf="m.artifactType && m.artifactType.id">
        <div class="p-inputgroup">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox binary="true" id="isDeleted" [(ngModel)]="m.isDeleted" [disabled]="true"></p-checkbox>
              <label for="isDeleted">{{ IS_DELETED_LABEL | translate }}</label>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <button
        *ngIf="m.artifactType && m.artifactType.id && !m.artifactType.deleted"
        [label]="'Delete' | translate"
        (click)="s.deleteWithConfirmation(m.artifactType)"
        class="p-button p-button-danger"
        pButton
      ></button>
      <a [routerLink]="['/admin/artifact-type-list']">
        <button pButton label="{{ 'Cancel' | translate }}" type="button" class="p-button p-button-warning"></button>
      </a>
      <button pButton label="{{ 'Save' | translate }}" type="button" class="p-button p-button-success" (click)="save()">
        <i *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <input type="text" id="name" pInputText [(ngModel)]="m.artifactType.name" />
      <label class="dynamic-label-text" for="name" translate>Name</label>
    </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <p-dropdown
        [(ngModel)]="m.artifactType.applicationId"
        [autoDisplayFirst]="false"
        [filter]="true"
        [options]="m.options.applications.list | transformArrayToSelectOptions : 'name' : 'id'"
        id="application">
      </p-dropdown>
      <label class="dynamic-label-text" for="application" translate>Application</label>
      </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <app-icon-picker [icon]="m.artifactType.icon" label="Icon" labelClass="dynamic-label-text" (onChangeIcon)="onChangeIcon($event)"></app-icon-picker>
    </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <p-dropdown
        [disabled]="!!m?.artifactType?.id"
        [(ngModel)]="m.artifactType.format"
        [options]="m.options.formatOptions"
        [autoDisplayFirst]="false"
        id="artifactTypeFormat"
        (onChange)="onArtifactTypeFormatChange($event.value)"
      >
      </p-dropdown>
      <label class="dynamic-label-text" for="artifactTypeFormat" translate>Format</label>
    </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <p-multiSelect id="artifactVisualRepresentation" [options]="m.options.avrOptions" optionValue="value" [(ngModel)]="m.selectedAvrTypes"> </p-multiSelect>
      <label class="dynamic-label-text" for="artifactVisualRepresentation" translate>Artifact Visual Representation</label>
    </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label cursor-pointer" (click)="showPopupFolder()" style="padding-top: 5px"> Path: {{ m.folderPath }} </span>
  </div>

  <div class="col-12 md:col-4">
    <span class="dynamic-label">
      <p-multiSelect [(ngModel)]="m.artifactType.preferredArtifactTypeIds"
                     [options]="m.options.textArtifactTypes"
                     [pTooltip]="'Only available if Format is MODULE' | translate"
                     [tooltipDisabled]="m.artifactType.format === ArtifactTypeFormat.module"
                     [disabled]="m.artifactType.format !== ArtifactTypeFormat.module"
                     id="preferredModuleArtifactTypes"
                     optionValue="value"
      ></p-multiSelect>
      <label class="dynamic-label-text" for="preferredModuleArtifactTypes" translate>Preferred module artifact types</label>
    </span>
  </div>

  <div class="col-12">
    <span class="dynamic-label">
      <textarea id="description" pInputTextarea [rows]="5" [(ngModel)]="m.artifactType.description"></textarea>
      <label class="dynamic-label-text" for="description" translate>Description</label>
    </span>
  </div>

  <div class="col-12 md:col-6 lg:col-3">
    <span class="dynamic-label">
      <p-multiSelect
        id="presentationalAttributes"
        [options]="m.options.primaryAttributeOptions"
        optionValue="value.id"
        [disabled]="!m.editableHelpingAttributes.clientAttributes.length"
        [(ngModel)]="m.artifactType.primaryAttributes"
      >
      </p-multiSelect>
      <label class="dynamic-label-text" for="presentationalAttributes" translate>Presentational attributes</label>
    </span>
  </div>

  <div class="col-12 md:col-6 lg:col-3">
    <span class="dynamic-label">
      <input type="text" id="delimiter" maxlength="5" pInputText [(ngModel)]="m.artifactType.delimiter" />
      <label class="dynamic-label-text" for="delimiter" translate>Delimiter</label>
    </span>
  </div>

  <div class="col-12 md:col-6 lg:col-3" *ngIf="m?.artifactType?.id">
    <span class="dynamic-label">
      <p-dropdown
        [options]="m.options.pageOptions"
        [(ngModel)]="m.artifactType.defaultPageId"
        [filter]="true"
        [autoDisplayFirst]="false"
        id="defaultPageId"
        [virtualScroll]="true"
        [virtualScrollItemSize]="20"
        appNotifyIfSelectedPageDeletedDirective
        [selectedPageId]="m.artifactType.defaultPageId"
      ></p-dropdown>
      <label class="dynamic-label-text" for="defaultPageId" translate>Default page</label>
    </span>
  </div>

  <div class="col-12 md:col-6 lg:col-3" *ngIf="m?.artifactType?.id">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox binary="true" inputId="isLoggingDisabled" [(ngModel)]="m.artifactType.isLoggingDisabled"></p-checkbox>
        <label for="isLoggingDisabled">{{ 'Is Logging Disabled' | translate }}</label>
      </span>
    </span>
  </div>

  <app-artifact-type-attributes-form
    [m]="m"
    [onAttributeChange]="s.onAttributeChange.bind(s)"
    [updateAvailableAttributes]="s.updateAvailableAttributes.bind(s)"
    [updatePrimaryAttributes]="s.updatePrimaryAttributes.bind(s)"
    class="col-12"
  >
  </app-artifact-type-attributes-form>

  <app-artifact-type-restrictions-form
    *ngIf="m.artifactType && m.artifactType.id"
    [linkRestrictions]="m.editableHelpingAttributes.linkRestrictions"
    [artifactTypeId]="m.artifactType.id"
    [applications]="m.options.applications"
    class="col-12"
  >
  </app-artifact-type-restrictions-form>

  <app-artifact-type-avr-form #avrForm [selectedAvrTypes]="m.selectedAvrTypes" [model]="m" class="col-12"> </app-artifact-type-avr-form>

  <app-artifact-type-default-widgets-form [artifactType]="m.artifactType" class="col-12"></app-artifact-type-default-widgets-form>
</div>

<div *ngIf="m.isShowFolderPicker">
  <ng-container *ngComponentOutlet="m.folderPicker; injector: m.injectorForWidgetComponent"></ng-container>
</div>
