import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { StyleCopyPastingService } from '@widgets/menu-widget/services/style-copy-pasting.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';

@Component({
  selector: 'app-menu-item-tree',
  templateUrl: './tree-form.component.html',
  styleUrls: ['./tree-form.component.scss'],
})
export class TreeFormComponent implements OnInit {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  @Output() onSelect = new EventEmitter();

  constructor(public readonly styleCopyService: StyleCopyPastingService, public readonly menuWidgetHelper: MenuWidgetHelper) {}

  ngOnInit(): void {
    this.menuWidgetHelper.selectLastlySelectedItem(this.m);
    this.m.selected.treeItem && this.s.onTreeItemSelect({ node: this.m.selected.treeItem });
    this.s.setBehaviorOptionDisability();
  }

  onItemDrop(): void {
    this.s.setTreeItemsToMenu();
    this.menuWidgetHelper.setTreeItemParentsToTextOnly(this.m.items.tree);
    this.s.setBehaviorOptionDisability();
  }

  onItemUnselect(): void {
    this.menuWidgetHelper.unselectTreeItem(this.m);
    this.s.setBehaviorOptionDisability();
  }

  onSelectItem(): void {
    this.onSelect.emit();
  }
}
