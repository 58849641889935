import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models';
import { AttributeActionHandlerService, AttributeOptionValuesSettable } from '@workflows/shared';
import { filter, map } from 'rxjs';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appSetAttributeOptionValues]',
})
export class SetAttributeOptionValuesDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  @Input() attributeOwner: AttributeOptionValuesSettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.actionAttributeHandler.registerSetAttributeOptionsEventListener(this.key);
    this.initSubscription = this.actionAttributeHandler.setAttributeOptionsEventMap$
      .pipe(
        filter(e => !!e.event && this.isMatchingAttributeData(e.event) && this.actionAttributeHandler.containsSetAttributeOptionsEvent(this.key)),
        map(() => this.actionAttributeHandler.getSetAttributeOptionsEvent(this.key)),
      )
      .subscribe(setAttributeValueEvent => {
        this.setAttributeOptionsValues(setAttributeValueEvent.value);
        this.actionAttributeHandler.removeSetAttributeOptionsEventFromMap(this.key, false);
      });
  }

  private setAttributeOptionsValues(newValue?: DataTypeValueResponseDto[]) {
    this.attributeOwner.setAttributeOptionValues(newValue);
  }
}
