import { DirectionalLinkTypeSystemElement } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-restrictions-form/types/directional-link-type-system-element';
import { LinkConstants } from '@shared/constants/link.constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { RelationOption } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class ArtifactTypeRestrictionsFormModel {
  options = new ArtifactTypeRestrictionsFormOptions();
  generalLinkTypeLabelMap: Record<string, string> = {};
}

export class ArtifactTypeRestrictionsFormOptions {
  artifactType: SelectOption<string, string>[] = [];
  linkType: SelectOption<string, string>[] = [];
  column: TableColumn[] = [];
  relationTypes: RelationOption[] = LinkConstants.relationTypes;
  linkTypes: ListContainer<DirectionalLinkTypeSystemElement> = new ListContainer<DirectionalLinkTypeSystemElement>();
  artifactTypes: NewArtifactType[];
}
