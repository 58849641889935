<div class="grid">
  <div class="col-12">
    <p-dropdown
      [(ngModel)]="selectedEnumValue"
      [autoDisplayFirst]="false"
      [options]="options"
      [placeholder]="'Choose enum option to style' | translate">
    </p-dropdown>
  </div>

  <ng-container *ngIf="selectedEnumValue">
    <div class="col-6">
      Show icon
    </div>
    <div class="col-6">
      <p-checkbox [(ngModel)]="selectedEnumValueSettings.isIconVisible" [binary]="true"></p-checkbox>
    </div>

    <div class="col-4">
      <button
        (click)="borderOverlayPanel.toggle($event)"
        icon="bi bi-border-style"
        label="{{'Borders' | translate }}"
        pButton>
      </button>

      <p-overlayPanel #borderOverlayPanel [style]="{ 'max-width':'500px' }" appendTo="body">
        <ng-template pTemplate>
          <app-border-form [styles]="selectedEnumValueSettings.styles"></app-border-form>
        </ng-template>
      </p-overlayPanel>
    </div>
    <div class="col-8">
      <button
        (click)="paddingOverlayPanel.toggle($event)"
        icon="pi pi-cog"
        label="{{'Padding' | translate}}"
        pButton>
      </button>

      <p-overlayPanel #paddingOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
        <ng-template pTemplate>
          <app-padding-form [paddingStyles]="selectedEnumValueSettings.styles"></app-padding-form>
        </ng-template>
      </p-overlayPanel>
    </div>

    <div class="col-4">
      <app-color-picker [(color)]="selectedEnumValueSettings.styles.color" label="Text color"></app-color-picker>
    </div>

    <div class="col-4">
      <app-color-picker
        [(color)]="selectedEnumValueSettings.styles.backgroundColor"
        label="Background color">
      </app-color-picker>
    </div>
  </ng-container>
</div>
