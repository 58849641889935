import { Injectable } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { ArtifactFilterHelper } from '@widgets/shared/components/artifact-filters/services/artifact-filter.helper';
import { ArtifactFilter, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class FilterTypeDetectionService {
  constructor(private readonly helper: ArtifactFilterHelper) {}

  isEnum(filter: ArtifactFilter): boolean {
    return filter.dataType?.kind === DataTypeKind.enumerated;
  }

  isUser(filter: ArtifactFilter): boolean {
    return (
      filter.dataType?.baseDataType === BaseDataType.user ||
      (filter.type === ArtifactFilterType.system && NonAttributeKeys.isUserSpecificAttributeKey(this.helper.getKeyByLabel(filter.name)))
    );
  }

  isText(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.text && filter.dataType.kind === DataTypeKind.simple;
  }

  isHyperlink(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.hyperlink && filter.dataType.kind === DataTypeKind.simple;
  }

  isInteger(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.integer && filter.dataType.kind === DataTypeKind.simple;
  }

  isDecimal(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.decimal && filter.dataType.kind === DataTypeKind.simple;
  }

  isBoolean(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.boolean;
  }

  isFile(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.file;
  }

  isDateTime(filter: ArtifactFilter): boolean {
    return (
      filter.dataType?.baseDataType === BaseDataType.dateTime ||
      filter.dataType?.baseDataType === BaseDataType.time ||
      filter.dataType?.baseDataType === BaseDataType.date ||
      (filter.type === ArtifactFilterType.system && NonAttributeKeys.isDateSpecificAttributeKey(this.helper.getKeyByLabel(filter.name)))
    );
  }

  isLink(filter: ArtifactFilter): boolean {
    return filter.type === ArtifactFilterType.link;
  }

  isFolderPath(filter: ArtifactFilter): boolean {
    return filter.attribute?.id === NonAttributeKeys.FOLDER_PATH_ID || filter.name === NonAttributeKeys.FOLDER_PATH_LABEL;
  }
}
