import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AclItem, AclMemberTypes, AclTypeModel, AclTypes } from '@widgets/shared/components/acl/types/acl.types';
import { NewUser } from '@shared/types/user.types';
import { NewTeam } from '@shared/types/team.types';
import { AclComponentService } from '@widgets/shared/components/acl/services/acl.service';
import { Environment } from '@environments/environment';
import { ClientData } from '@shared/types/local-storage.types';

@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss'],
  providers: [AclComponentService],
})
export class AclComponent {
  @Input() folderId: string;
  @Input() artifactId: string;
  @Input() attributeId: string;
  @Output() updateAcl = new EventEmitter<null>();

  showUserPicker: boolean;
  memberTypesList: string[];
  memberType: AclMemberTypes = AclMemberTypes.all;
  edit = [false, false, false];
  activeState: boolean[] = [false, false, false];
  users: NewUser[];
  teams: NewTeam[];
  itemList: AclItem[];
  currentAclType: AclTypes;
  selectedItem: AclItem[] | null;
  aclTypes = AclTypes;
  aclTypeList: AclTypes[];
  isChanged: boolean;
  setFolderItemsAcl = true;
  currentDateTime: Date;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  userData: ClientData;
  model: AclTypeModel;

  constructor(public readonly s: AclComponentService) {}

  ngOnInit(): void {
    this.s.init(this);
  }

  removeExpiration(item: AclItem): void {
    item.expirationDate = null;
    this.isChanged = true;
  }

  toggleEdit(e: MouseEvent, type: AclTypes, index: number): void {
    if (this.activeState[index] || this.edit[index]) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.edit[index] = !this.edit[index];
    this.showUserPicker = false;
    this.currentAclType = type;
  }

  openPickUsers(e: MouseEvent, type: AclTypes, index: number): void {
    if (this.activeState[index]) {
      e.stopPropagation();
      e.preventDefault();
    }
    (!this.showUserPicker || this.currentAclType === type) && (this.showUserPicker = !this.showUserPicker);

    if (this.showUserPicker) {
      this.currentAclType = type;
      this.s.updateList();
    }
  }

  onChangeDate(): void {
    this.isChanged = true;
  }
}
