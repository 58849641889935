import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Environment } from '@environments/environment';
import { AttributeListComponent } from '@private/pages/artifact-type-management/attribute-list/attribute-list.component';
import { AttributeListModel } from '@private/pages/artifact-type-management/attribute-list/types/attribute-list.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  ALIAS_KEY,
  ALIAS_LABEL,
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  DESCRIPTION_KEY,
  DESCRIPTION_LABEL,
  NAME_KEY,
  NAME_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class AttributeListService extends CoreService<AttributeListComponent, AttributeListModel> {
  constructor(private readonly cache: NewCacheService) {
    super();
  }

  init(context: AttributeListComponent, model: AttributeListModel): void {
    super.init(context, model);
    this.initOptions();
    this.initSubscriptions();
  }

  doFilter(table: Table): void {
    table && table.filter(null, '', '');
  }

  private initOptions(): void {
    this.m.rowsPerPage = Environment.tableConfig.rowsPerPage;
    this.m.rowsPerPageOptions = Environment.tableConfig.rowsPerPageOptions;
    this.m.columns = [
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(DESCRIPTION_LABEL, DESCRIPTION_KEY),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
      new TableColumn(ALIAS_LABEL, ALIAS_KEY),
    ];
  }

  private initSubscriptions(): void {
    this.c.registerSubscription(
      this.cache.data.applications.subscribe(applications => applications && this.m.applications.setList(applications as ApplicationResponseDto[], 'id')),
    );
  }
}
