import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ObjectUtil } from '@shared/utils/object.util';

@Pipe({ name: 'printApplicationToTable' })
export class PrintApplicationToTablePipe implements PipeTransform {
  constructor(private readonly objectUtil: ObjectUtil) {}

  transform(application: ApplicationResponseDto, key: string): string {
    return this.objectUtil.getValueFromPath(key, application);
  }
}
