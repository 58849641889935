<span class="dynamic-checkbox-box">
  <span *ngIf="attr?.isMandatory" class="form-mandatory-field"></span>

  <p-triStateCheckbox
    [(ngModel)]="attr.value"
    [inputId]="'booleanField' + index">
  </p-triStateCheckbox>

  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'booleanField' + index"
    [canContainPlaceholder]="false">
  </app-artifact-attribute-form-field-label-with-icon>
</span>
