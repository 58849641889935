import { Injectable } from '@angular/core';
import { ChartData } from '@widgets/chart-widget/types/chart-widget-view.types';
import { ChartTooltipItem, ChartTooltipModel } from 'chart.js';

@Injectable({ providedIn: 'root' })
export class ChartViewTooltipService {
  footer(item: ChartTooltipItem[], data: ChartData): string | string[] {
    const val = item[0].value as string;
    const key: number = item[0].index || 0;
    const total = `${data.counter?.totalSumLabel}: ${data.counter.totalSumPerGroup[key] || 0}`;
    return !data.counter?.totalSumLabel ? val : [val, total];
  }

  render(tooltipModel: ChartTooltipModel, chartId: string): void {
    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = 'date here';
      document.body.appendChild(tooltipEl);
    }

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const position = (document.getElementById(chartId) as HTMLCanvasElement).getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.zIndex = '1000';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }
}
