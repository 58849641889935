import { Type } from '@angular/core';
import { WidgetsCoreComponent } from '../components/widgets-core.component';

export class WidgetOption<Component extends WidgetsCoreComponent> {
  constructor(public code: WidgetType, public label: string, public component: Type<Component>, public icon?: string) {}
}

export enum WidgetType {
  text = 'TEXT',
  list = 'LIST',
  listNew = 'LIST-NEW',
  listMatrix = 'LIST_MATRIX',
  linkPopup = 'LINK_POPUP',
  menu = 'MENU',
  artifact = 'ARTIFACT',
  sidebar = 'SIDEBAR',
  sidebarModal = 'SIDEBAR_MODAL',
  picture = 'PICTURE',
  card = 'CARD',
  chart = 'CHART',
  apexChart = 'APEX_CHART',
  number = 'NUMBER',
  folder = 'FOLDER',
  tag = 'TAG',
  filter = 'FILTER',
  auth = 'AUTH',
  avr = 'AVR',
}
