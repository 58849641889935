export enum SidebarWidgetWidth {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

export enum WidthType {
  pixel = 'pixel',
  percent = 'percent',
}
