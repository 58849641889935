import { Injectable } from '@angular/core';
import { ArtifactFilterQuery } from '../../../types/artifact-filter-query.types';
import { ArtifactFilter } from '../../../types/artifact-filter.types';
import { EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';
import { BaseBooleanFilterService } from '@shared/services/filter/filter-types/base-boolean-filter.service';

@Injectable({
  providedIn: 'root',
})
export class BooleanFilterService {
  constructor(protected readonly booleanFilterService: BaseBooleanFilterService) {}

  getQuery(filter: ArtifactFilter): ArtifactFilterQuery | null {
    if (!filter?.value) return null;

    // fix for old widgets with object in value
    if (Object.prototype.hasOwnProperty.call(filter.value, 'isEnable')) {
      filter.value = filter.value?.value + '';
    }

    const value = filter.value.split(',');
    const $in = value.map((val: string) => this.booleanFilterService.getBooleanValueFromString(val));
    value.includes(EMPTY_OPTION_FILTER_URL_VALUE) && $in.push(null);

    return {
      [`attributes.${filter.attributeId}.value`]: { $in },
    };
  }

  getFilterValueFromString(str: string): string | null {
    const value = str.split(',');
    return value.map((val: string) => this.booleanFilterService.getBooleanValueFromString(val) || EMPTY_OPTION_FILTER_URL_VALUE).join(',');
  }
}
