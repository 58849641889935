import { Pipe, PipeTransform } from '@angular/core';
import { GridTrack } from '@shared/components/grid-layout-generator/types/grid-track';

@Pipe({
  name: 'gridTemplateTrackStyles',
})
export class GridTemplateTrackStylesPipe implements PipeTransform {
  transform(tracks: GridTrack[]): string {
    return tracks.reduce((styles: string, { size }: GridTrack): string => `${styles} ${size}`, '');
  }
}
