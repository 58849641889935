import { SelfUserResponseDto } from '@api/models';

export enum MenuMode {
  static = 'static',
  overlay = 'overlay',
  slim = 'slim',
}

export enum ColorScheme {
  dark = 'dark',
  dim = 'dim',
  light = 'light',
}

export enum InputStyle {
  outlined = 'outlined',
  filled = 'filled',
}

export enum LabelStyle {
  float = 'float',
  static = 'static',
  placeholder = 'placeholder',
  inlineLeft = 'inlineLeft',
  inlineRight = 'inlineRight',
}

export class UiConfig {
  menuMode = MenuMode.static;
  colorScheme = ColorScheme.light;
  inputStyle = InputStyle.outlined;
  ripple = false;
  menuTheme = 'layout-sidebar-darkgray';
  componentTheme = 'blue';
  labelStyle = LabelStyle.float;
  logoColor = 'dark';
  firstDayOfWeek = 1;

  constructor(config?: Partial<UiConfig>) {
    config && Object.assign(this, config);
  }
}

export class ProfileModel {
  user: SelfUserResponseDto;
}
