import { ChangeDetectorRef, Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewArtifact } from '@shared/types/artifact.types';
import { CUSTOM_VARIANT_KEY, DEFAULT_VARIANT_KEY, DisplayAtSelectionItem, DisplayAtSelectionTypeEnum } from '@shared/types/display-at-types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';
import { DisplayAtControlService } from '../services';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

@Directive()
export abstract class AbstractDisplayAtComponent implements OnInit, OnDestroy {
  @Input() ownerId: string;
  @Input() artifact: NewArtifact;
  @Input() attributeId: string;
  @Input() selectedVariant: string = DEFAULT_VARIANT_KEY;
  @Input() selectedEditVariant: string = DEFAULT_VARIANT_KEY;
  /** Flag deciding whether the value will be updated on selection change or from outside (via inputs, when server request succeeds) */
  @Input() doInternalUpdateOfValue: boolean;

  customVariantTemplate$: Observable<BlockPartWidget>;

  protected widgetType = WidgetType;
  protected initSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const selectionBase$ = this.displayAtControlService.getSelection$().pipe(
      filter(selection => selection.ownerId === this.ownerId && selection.attributeId === this.attributeId),
      shareReplay(),
    );
    const selectionVariant$ = selectionBase$.pipe(
      filter(selection => selection.selectionType === DisplayAtSelectionTypeEnum.DISPLAY),
      tap(selection => this.onDisplayAtSelectionChange(selection)),
    );
    const selectionEditVariant$ = selectionBase$.pipe(
      filter(selection => selection.selectionType === DisplayAtSelectionTypeEnum.EDIT),
      tap(selection => this.onDisplayAtEditChange(selection)),
    );

    this.initSubscription = merge(selectionVariant$, selectionEditVariant$).subscribe(() => this.cdr.markForCheck());

    if (this.selectedVariant === CUSTOM_VARIANT_KEY) {
      this.customVariantTemplate$ = this.displayAtControlService.getCustomVariantTemplateSelection$(this.ownerId, this.attributeId);
    }
  }

  ngOnDestroy(): void {
    this.initSubscription?.unsubscribe();
  }

  isDefaultEditVariant(): boolean {
    return this.selectedEditVariant === DEFAULT_VARIANT_KEY;
  }

  isValueClickable(): boolean {
    return !this.isDefaultEditVariant();
  }

  protected onDisplayAtSelectionChange(selection: DisplayAtSelectionItem) {
    this.selectedVariant = selection.selectedVariant;
    if (this.selectedVariant === CUSTOM_VARIANT_KEY) {
      this.customVariantTemplate$ = this.displayAtControlService.getCustomVariantTemplateSelection$(this.ownerId, this.attributeId);
    }
  }

  protected onDisplayAtEditChange(selection: DisplayAtSelectionItem) {
    this.selectedEditVariant = selection.selectedVariant;
  }

  protected stopEventPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
