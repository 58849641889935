import { CreateLinksActionWorkflowSettingsDto } from '@api/models';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { WorkflowActionDeleteArtifactsDto } from '@workflows/types/actions/action-delete-artifacts';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum CreateLinksKeys {
  forEachPath = 'forEachPath',
  linkTypeId = 'linkTypeId',
  sourceArtifactId = 'sourceArtifactId',
  destinationArtifactId = 'destinationArtifactId',
}

export type WorkflowCreateAttributeMapValue = string | string[];
export type WorkflowCreateAttributeMap = Record<string, WorkflowCreateAttributeMapValue>;

export class WorkflowActionCreateLinksDto implements CreateLinksActionWorkflowSettingsDto {
  forEachPath: CreateLinksActionWorkflowSettingsDto[CreateLinksKeys.forEachPath] = { value: '', isDynamic: false };
  linkTypeId: CreateLinksActionWorkflowSettingsDto[CreateLinksKeys.linkTypeId] = { value: '', isDynamic: false };
  sourceArtifactId: CreateLinksActionWorkflowSettingsDto[CreateLinksKeys.sourceArtifactId] = { value: '', isDynamic: false };
  destinationArtifactId: CreateLinksActionWorkflowSettingsDto[CreateLinksKeys.destinationArtifactId] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionDeleteArtifactsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionCreateLinks extends AbstractWorkflowAction<CreateLinksActionWorkflowSettingsDto> {
  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionCreateLinksDto, type: WorkflowActionType.CREATE_LINKS, dto });
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);
    }
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [CreateLinksKeys.forEachPath]: {
          isDynamic: false,
          value: this.actionSettings.forEachPath.value || null,
        },
        [CreateLinksKeys.linkTypeId]: {
          isDynamic: this.actionSettings.linkTypeId.isDynamic,
          value: this.actionSettings.linkTypeId.value,
        },
        [CreateLinksKeys.sourceArtifactId]: {
          isDynamic: true,
          value: this.actionSettings.sourceArtifactId.value,
        },
        [CreateLinksKeys.destinationArtifactId]: {
          isDynamic: true,
          value: this.actionSettings.destinationArtifactId.value,
        },
      },
    };
  }
}
