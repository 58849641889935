import { Component, Input } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';
import { AttributeSettings } from '@widgets/card-widget/types/attribute-settings';
import { EnumOptionSettings } from '@widgets/card-widget/types/enum-option-settings';

@Component({
  selector: 'app-chip-design-styler',
  templateUrl: './chip-design-styler.component.html',
  styleUrls: ['./chip-design-styler.component.scss'],
})
export class ChipDesignStylerComponent {
  @Input() attributeSettings: AttributeSettings;
  @Input() options: SelectOption<string, unknown>[];

  selectedEnumValue: string;

  get selectedEnumValueSettings(): EnumOptionSettings {
    return this.attributeSettings.enumAttributeSettings[this.selectedEnumValue];
  }
}
