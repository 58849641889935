import { BaseDataType, DataTypeKind } from '../../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowConditionType } from '../index';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export class WorkflowNotEqualsCondition extends AbstractWorkflowCondition {
  constructor() {
    super();
    this.type = WorkflowConditionType.NOT_EQUALS;
    this.supportedTypes = [
      BaseDataType.integer,
      BaseDataType.decimal,
      BaseDataType.text,
      BaseDataType.html,
      BaseDataType.date,
      BaseDataType.dateTime,
      BaseDataType.time,
    ];
    this.supportedKinds = [DataTypeKind.simple];
  }

  isMet(source: any, value?: any): boolean {
    return source !== value;
  }
}
