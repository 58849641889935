import { WorkflowTriggerDto } from '@api/models';
import { SaveableItem } from '@shared/interfaces/saveable-item';
import { NewArtifact } from '@shared/types/artifact.types';

export enum RuleTriggerType {
  // FE
  ATTRIBUTE_VALUE_CHANGE = 'ATTRIBUTE_VALUE_CHANGE',
  LINK_ADDED = 'LINK_ADDED',
  PAGE_LOAD = 'PAGE_LOAD',
  WIDGET_LOAD = 'WIDGET_LOAD',
  WIDGET_DATA_LOAD = 'WIDGET_DATA_LOAD',
  BUTTON_CLICK = 'BUTTON_CLICK',
  ARTIFACT_WIDGET_CREATE_MODE = 'ARTIFACT_WIDGET_CREATE_MODE',
  // BE
  OWNER_CREATE = 'OWNER_CREATE',
  OWNER_UPDATE = 'OWNER_UPDATE',
  OWNER_DELETE = 'OWNER_DELETE',
  BEFORE_OWNER_UPDATE = 'BEFORE_OWNER_UPDATE',
  BEFORE_OWNER_CREATE = 'BEFORE_OWNER_CREATE',
  BEFORE_OWNER_DELETE = 'BEFORE_OWNER_DELETE',
  MANUAL = 'MANUAL',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jobTypeCheck: [WorkflowTriggerDto['type']] extends [`${RuleTriggerType}`]
  ? `${RuleTriggerType}` extends WorkflowTriggerDto['type']
    ? true
    : false
  : false = true;

export interface WorkflowTrigger extends WorkflowTriggerDto, SaveableItem<WorkflowTriggerDto> {
  isArtifactTypeRequired(): boolean;

  isExternalArtifactTypeRequired(): boolean;

  isAttributeRequired(): boolean;

  isWidgetRequired(): boolean;

  isElementRequired(): boolean;

  isValid(): boolean;

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean;
}

export interface WorkflowTriggerEvent {
  pageId: string;
  definition: WorkflowTrigger;
  payload?: WorkflowTriggerEventPayload;
  widgetId?: string;
}

export interface WorkflowTriggerEventPayload {
  artifact?: NewArtifact;
  linkedArtifact?: NewArtifact; // TODO: move linked artifact here;
  artifacts?: NewArtifact[];
  newValue?: any;
  getAttributeValueFn?: (attributeId: string) => any;
}

export const ImplementedRuleTriggerTypes: Record<RuleTriggerType, boolean> = {
  // FE
  [RuleTriggerType.ATTRIBUTE_VALUE_CHANGE]: true,
  [RuleTriggerType.LINK_ADDED]: true,
  [RuleTriggerType.WIDGET_LOAD]: true,
  [RuleTriggerType.PAGE_LOAD]: true,
  [RuleTriggerType.WIDGET_DATA_LOAD]: true,
  [RuleTriggerType.BUTTON_CLICK]: false,
  [RuleTriggerType.ARTIFACT_WIDGET_CREATE_MODE]: true,
  // BE
  [RuleTriggerType.OWNER_CREATE]: true,
  [RuleTriggerType.OWNER_UPDATE]: true,
  [RuleTriggerType.OWNER_DELETE]: true,
  [RuleTriggerType.BEFORE_OWNER_CREATE]: true,
  [RuleTriggerType.BEFORE_OWNER_UPDATE]: true,
  [RuleTriggerType.BEFORE_OWNER_DELETE]: true,
  [RuleTriggerType.MANUAL]: true,
};
