import { Pipe, PipeTransform } from '@angular/core';
import { PageResponseDto } from '@api/models/page-response-dto';
import { APPLICATION_ID_KEY, ERROR_GETTING_APPLICATION, UPDATED_ON_KEY } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { ConvertToClientDatetime } from '@shared/methods/date.methods';

@Pipe({ name: 'printPageToTable' })
export class PrintPageToTablePipe implements PipeTransform {
  constructor(private readonly objectUtil: ObjectUtil) {}

  transform(page: PageResponseDto, key: string, applications: ListContainer<NewApplication>): string {
    if (key === APPLICATION_ID_KEY) return applications.listMap[page.applicationId]?.name || ERROR_GETTING_APPLICATION;
    if (key === UPDATED_ON_KEY) return ConvertToClientDatetime(new Date(this.objectUtil.getValueFromPath(key, page)));

    return this.objectUtil.getValueFromPath(key, page);
  }
}
