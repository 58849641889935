import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StringUtil {
  formatMultipleTextForDisplay(value: string[]): string {
    return value.map(str => `<span class="table-string-multiple-item">${str}</span>`).join('');
  }

  formatMultipleHyperlinkForDisplay(value: string[]): string {
    return value.map(str => `<span class="table-hyperlink-item"><a href="${str}">${str}</a></span>`).join('');
  }

  static isString(value: any): boolean {
    return typeof value === 'string';
  }
}
