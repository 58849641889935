import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

@Pipe({
  name: 'getAttributeName',
})
export class GetAttributeNamePipe implements PipeTransform {
  transform(attributeId: string, options: CardWidgetOptions): string {
    const option = options.clientAttributeOptions.find(({ value }: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>) => {
      return value.id === attributeId;
    });

    if (!option) {
      return '';
    }

    if (option.meta === LinkDirection.outgoing) {
      return (option.value as LinkType).outgoingName;
    }

    if (option.meta === LinkDirection.incoming) {
      return (option.value as LinkType).incomingName;
    }

    return option.label;
  }
}
