import { ArtifactLinkResponseDto } from '@api/models';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewUser } from '@shared/types/user.types';
import { CardWidgetMode } from './card-widget-mode';

export class CardWidgetOptions {
  constructor(
    public widgetModes: ListContainer<CardWidgetMode> = new ListContainer<CardWidgetMode>(),
    public applications: ListContainer<Application> = new ListContainer<Application>(),
    public users: ListContainer<NewUser> = new ListContainer<NewUser>(),
    public artifactTypes: ListContainer<NewArtifactType> = new ListContainer<NewArtifactType>(),
    public files: ListContainer<ArtifactLinkResponseDto> = new ListContainer<ArtifactLinkResponseDto>(),
    public attributes: ListContainer<NewAttribute> = new ListContainer<NewAttribute>(),
    public systemAttributes: ListContainer<NewAttribute> = new ListContainer<NewAttribute>(),
    public dataTypes: ListContainer<NewDataType> = new ListContainer<NewDataType>(),
    public linkTypes: ListContainer<LinkType> = new ListContainer<LinkType>(),
    public clientAttributeOptions: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>[] = [],
    public artifactOptions: ListContainer<ArtifactResponseDto> = new ListContainer<ArtifactResponseDto>(),
    public outgoingLinks: ListContainer<LinkResponseDto> = new ListContainer<LinkResponseDto>(),
    public incomingLinks: ListContainer<LinkResponseDto> = new ListContainer<LinkResponseDto>(),
    public linkedArtifacts: ListContainer<ArtifactResponseDto> = new ListContainer<ArtifactResponseDto>(),
    public pages: ListContainer<Page> = new ListContainer<Page>(),
  ) {
    this.widgetModes.setList(Object.keys(CardWidgetMode).map((key: string) => CardWidgetMode[key as keyof typeof CardWidgetMode]));
  }

  get areLoaded(): boolean {
    return this.artifactTypes.loaded && this.attributes.loaded && this.linkTypes.loaded;
  }
}
