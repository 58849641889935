import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  FORMAT_KEY,
  FORMAT_LABEL,
  ICON_KEY,
  ICON_LABEL,
  ID_KEY,
  NAME_KEY,
  NAME_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

import { ArtifactTypesListModel } from '../types/artifact-type-list.types';

@Injectable()
export class ArtifactTypeListService extends CoreService<any, ArtifactTypesListModel> {
  constructor(private readonly cache: NewCacheService) {
    super();
  }

  init(context: any, model: ArtifactTypesListModel): void {
    super.init(context, model);
    this.initOptions();
    this.initSubscriptions();
  }

  doFilter(table: Table): void {
    table && table.filter(null, null as any, null as any);
  }

  private initOptions(): void {
    this.m.rowsPerPage = Environment.tableConfig.rowsPerPage;
    this.m.rowsPerPageOptions = Environment.tableConfig.rowsPerPageOptions;
    this.m.columns = [
      new TableColumn(ICON_LABEL, ICON_KEY),
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
      new TableColumn(FORMAT_LABEL, FORMAT_KEY),
    ];
    this.m.artifactTypeFormatOptions = Object.keys(ArtifactTypeFormatEnum).map(
      key => new SelectOption(key, ArtifactTypeFormatEnum[key as keyof typeof ArtifactTypeFormatEnum]),
    );
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.data.applications.subscribe(applications => applications && this.m.applications.setList(applications as ApplicationResponseDto[], ID_KEY)),
    ]);
  }
}
