import { WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { Constructor } from '@shared/types/constructor.types';
import { WorkflowAction, WorkflowActionType, WorkflowActionValueType, WorkflowTriggerEvent } from '@workflows/types';

export abstract class AbstractWorkflowAction<T extends WorkflowActionDto['actionSettings']> implements WorkflowAction {
  id: string;
  type: WorkflowActionType;
  actionSettings: T;
  alias?: string;

  protected value: WorkflowActionValueType | null = null;
  protected supportedAttributeDataTypes: BaseDataType[] | null = null;
  protected supportedAttributeDataKinds: DataTypeKind[] | null = null;

  constructor(actionSettingDto: Constructor<T>, type?: WorkflowActionType, dto?: WorkflowActionDto) {
    this.type = type || this.type;
    this.alias = dto?.alias;
    this.actionSettings = new actionSettingDto(dto?.actionSettings);
  }

  abstract canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean;

  abstract execute(triggerEvent?: WorkflowTriggerEvent): void;

  abstract isValid(): boolean;

  isCalculatedValueType(): boolean {
    return false;
  }

  getCalculatedValue(): number | null {
    return null;
  }

  isArtifactTypeRequired(): boolean {
    return false;
  }

  isAttributeRequired(): boolean {
    return false;
  }

  getSupportedDataTypes(): BaseDataType[] | null {
    return this.supportedAttributeDataTypes;
  }

  getSupportedDataKinds(): DataTypeKind[] | null {
    return this.supportedAttributeDataKinds;
  }

  toServer(): WorkflowActionDto {
    return {
      type: this.type,
      alias: this.alias,
      actionSettings: this.actionSettings,
    };
  }

  fromDto(dto: WorkflowActionDto): void {
    dto && Object.assign(this, dto);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRuleActivation(pageId: string): void {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRuleDeactivation(pageId: string): void {
    // do nothing
  }
}
