<app-workflow-attribute-selector
  [selectedAttributeId]="action.attributeId"
  [attributes]="selectedAttributes"
  (attributeChange)="onAttributeChange($event)"
  [editable]="true"
></app-workflow-attribute-selector>

<div *ngIf="showOperations" class="inline-block padded">
  <p-dropdown
    *ngIf="action.actionSettings[RuleKeys.operation]"
    [(ngModel)]="action.actionSettings[RuleKeys.operation].value"
    [options]="operations"
    class="workflow-element-dropdown"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    placeholder="{{ 'WORKFLOWS.PLACEHOLDER.MODIFY_VALUE_OPERATION' | translate}}"
    appendTo="body"
  ></p-dropdown>
</div>

<div *ngIf="showEditingField && action.attributeId && (!showOperations || action.actionSettings[RuleKeys.operation].value)" class="inline-block">
  <app-attribute-value-edit-field
    [(value)]="action.actionSettings.value.value"
    [dataType]="action.attributeId | getDataTypeFromAttributeId : allAttributes : dataTypes"
    [disabled]="false"
    [multipleValues]="allAttributes.listMap[action.attributeId].multipleValues"
    [users]="users"
    [index]="index"
    [showCurrentUserOption]="true"
  ></app-attribute-value-edit-field>
</div>
