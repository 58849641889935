import { WorkflowActionDto } from '@api/models';
import { AttributeActionHandlerService } from '@workflows/shared';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType } from '../../types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export type SHOW_HIDE_ACTION = WorkflowActionType.SHOW | WorkflowActionType.HIDE;

export class WorkflowActionShowHideAttribute extends AbstractWorkflowAttributeBasedAction<CommonFeAction> {
  constructor(type: SHOW_HIDE_ACTION, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, type, dto, actionAttributeHandler });
  }

  canBeExecuted(): boolean {
    return this.type === WorkflowActionType.SHOW || this.type === WorkflowActionType.HIDE;
  }

  execute(): void {
    const showAttribute = this.type === WorkflowActionType.SHOW;
    this.actionAttributeHandler.notifyShowHideEvent({ artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, showAttribute });
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId);
  }
}
