import { Injectable } from '@angular/core';
import { DataTypeKind } from '../types/data-type-form.types';

@Injectable({
  providedIn: 'root',
})
export class DataTypeFormService {
  public isEnumerated(kind: DataTypeKind): boolean {
    return kind === DataTypeKind.enumerated;
  }

  public isBoundedRange(kind: DataTypeKind): boolean {
    return kind === DataTypeKind.bounded;
  }
}
