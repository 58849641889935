import { Directive, Input } from '@angular/core';
import { NewCoreListComponent } from '@shared/core/components/new-core-list.component';
import { NewArtifact } from '@shared/types/artifact.types';
import { GroupItem } from './types/list-widget-grouping.types';
import { ListWidgetOptions } from './types/list-widget-options.types';
import { ListWidgetTableSettings } from './types/list-widget-settings.types';

@Directive()
export abstract class AbstractArtifactListTableComponent extends NewCoreListComponent<NewArtifact> {
  @Input() hash: string;
  @Input() options: ListWidgetOptions;
  @Input() settings: ListWidgetTableSettings;

  canShowTooltipMap: Record<string, boolean> = {};

  // TODO: how to track groups?
  rowTrackByFn(index: number, item: GroupItem): number {
    return item.sortOrder;
  }

  protected fillTooltipMap(): void {
    const elements: any = document.querySelectorAll('.tooltip-target');
    elements.forEach((el: any) => {
      this.canShowTooltipMap[el.dataset.key] = this.getTdOffsetWidth(el) < el.offsetWidth;
    });
  }

  protected getTdOffsetWidth(element: any): number {
    if (element.parentNode.tagName === 'TD') return element.parentNode.offsetWidth - 14;
    return this.getTdOffsetWidth(element.parentNode);
  }
}
