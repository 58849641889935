import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifactLinks } from '@shared/types/artifact.types';
import { LinkRestrictionParamsBase, NewLink } from '@shared/types/link.types';

@Pipe({ name: 'getRelevantLinks' })
export class GetRelevantLinks implements PipeTransform {
  transform(linkRestrictionParams: LinkRestrictionParamsBase, artifactId: string, links: Record<string, Record<string, NewArtifactLinks>>): NewLink[] | null {
    const { linkTypeId, direction } = linkRestrictionParams;
    if (!linkTypeId || !direction || !artifactId) {
      return null;
    }
    return links[linkTypeId] && links[linkTypeId][artifactId] && links[linkTypeId][artifactId][direction];
  }
}
