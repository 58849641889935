<div class="list_top">
    <div class="grid list_top__filters">
        <div class="col-6 checkbox-cont">
            <label for="isShowAllApps" translate>Show all apps</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.showAllApp"
                    [binary]="true"
                    id="isShowAllApps"
            ></p-checkbox>
        </div>
        <div class="col-6"></div>
        <!-- wait for implementation
        <div class="col-6 checkbox-cont">
            <label for="isShowContent">Show content</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.showContent"
                    [binary]="true"
                    id="isShowContent"
            ></p-checkbox>
        </div>-->
        <div class="col-6 checkbox-cont">
            <label for="isHorizontal" translate>Show horizontal tree</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.isHorizontal"
                    [binary]="true"
                    id="isHorizontal"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="scrollable" translate>Enable scroll</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.scrollable"
                    [binary]="true"
                    id="scrollable"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="isShowUsersFolder" translate>Show users folder</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.showUsersFolder"
                    [binary]="true"
                    id="isShowUsersFolder"
            ></p-checkbox>
        </div>
        <div class="col-6"></div>

        <div class="col-6 checkbox-cont">
            <label for="isShowTeamsFolder" translate>Show teams folder</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.showTeamsFolder"
                    [binary]="true"
                    id="isShowTeamsFolder"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="coloredFolderText" translate>Colored text</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.coloredText"
                    [binary]="true"
                    id="coloredFolderText"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="folderArrow" translate>Show folder arrow</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.showFolderArrow"
                    [binary]="true"
                    id="folderArrow"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="coloredFolderArrow" translate>Colored folder arrow</label>
            <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.coloredFolderArrow"
                    [disabled]="!settings.showFolderArrow"
                    [binary]="true"
                    id="coloredFolderArrow"
            ></p-checkbox>
        </div>

        <div class="col-6 checkbox-cont" translate>
            <label for="enableSearch">Enable Folder Search</label>
            <p-checkbox
                [(ngModel)]="settings.enableSearch"
                [binary]="true"
                id="enableSearch"
            ></p-checkbox>
        </div>
        <div class="col-6 checkbox-cont">
            <label for="searchInPath" translate>Search in path</label>
            <p-checkbox
                [(ngModel)]="settings.searchInPath"
                [binary]="true"
                [disabled]="!settings.enableSearch"
                (onChange)="onChange.emit()"
                id="searchInPath"
            ></p-checkbox>
        </div>

        <ng-container *ngIf="showUrlChoose">
            <div class="col-6 checkbox-cont">
                <label for="enableFolderUrl" translate>Enable folder url</label>
                <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.enableFolderUrl"
                    [binary]="true"
                    id="enableFolderUrl"
                ></p-checkbox>
            </div>
            <div class="col-6">
                <input
                    [(ngModel)]="settings.folderUrlKey"
                    [disabled]="!settings.enableFolderUrl"
                    [placeholder]="'url param for folder id' | translate"
                    (blur)="onChangeUrlKey()"
                    id="folderIdKey"
                    class="w-full"
                    pInputText type="text">
            </div>

            <div class="col-6 checkbox-cont">
                <label for="enableModuleUrl" translate>Enable module url</label>
                <p-checkbox
                    (onChange)="onChange.emit()"
                    [(ngModel)]="settings.enableModuleUrl"
                    [binary]="true"
                    id="enableModuleUrl"
                ></p-checkbox>
            </div>
            <div class="col-6">
                <input
                    [(ngModel)]="settings.moduleUrlKey"
                    [disabled]="!settings.enableModuleUrl"
                    [placeholder]="'url param for module id' | translate"
                    (blur)="onChangeUrlKey()"
                    id="moduleIdKey"
                    class="w-full"
                    pInputText type="text">
            </div>
        </ng-container>
        <div class="col-6">
            Select collapsed icon
        </div>
        <div class="col-6">
            <span class="dynamic-label icon-field">
                <app-icon-picker [icon]="settings.collapsedIcon" (onChangeIcon)="settings.collapsedIcon = $event; updateFolderIcons.emit()"></app-icon-picker>
            </span>
        </div>
        <div class="col-6">
            Select expanded icon
        </div>
        <div class="col-6">
            <span class="dynamic-label icon-field">
                <app-icon-picker [icon]="settings.expandedIcon" (onChangeIcon)="settings.expandedIcon = $event; updateFolderIcons.emit()"></app-icon-picker>
            </span>
        </div>
        <div class="col-6">
            Select empty folder icon
        </div>
        <div class="col-6">
            <span class="dynamic-label icon-field">
                <app-icon-picker [icon]="settings.emptyFolderIcon" (onChangeIcon)="settings.emptyFolderIcon = $event; updateFolderIcons.emit()"></app-icon-picker>
            </span>
        </div>
        <div class="col-6">
            Select module folder icon
        </div>
        <div class="col-6">
            <span class="dynamic-label icon-field">
                <app-icon-picker [icon]="settings.moduleIcon" (onChangeIcon)="settings.moduleIcon = $event; updateFolderIcons.emit()"></app-icon-picker>
            </span>
        </div>
    </div>
</div>
