import { InjectionToken, Injector } from '@angular/core';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { TableColumn } from '@shared/types/table.types';

export const NON_MAPPABLE_FIELDS = new InjectionToken<BaseAvrInputMapperDto<AvrTypesType>['nonMappableFields']>('nonMappableFields');

export class ArtifactTypeAvrFormModel {
  artifactTypeAvrMapper: { [K in AvrTypesType]?: BaseAvrInputMapperDto<K> } = {};
  avrColumns: TableColumn[] = [];
  injectorsForAvrMapper: { [K in AvrTypesType]?: Injector } = {};
}
