<p-card *ngIf="c.m.user">
  <div class="oslc-providers-wrapper mb-3">
    <div>
      <h4 class="m-0">{{ 'OSLC connections' | translate }}</h4>
    </div>
    <div>
      <button *ngIf="m.addNewMode === false" pButton pRipple type="button" label="{{ 'Add' | translate }}" class="mr-2" (click)="m.addNewMode = true"></button>
      <button
        *ngIf="m.addNewMode === true"
        pButton
        pRipple
        type="button"
        label="{{ 'Cancel' | translate }}"
        class="mr-2 p-button p-button-warning"
        (click)="m.addNewMode = false && (m.selectedOslcRootService = null)"
      ></button>
    </div>
  </div>

  <!-- *********************************************************************** -->

  <div *ngIf="m.addNewMode === true" class="mb-5 pt-2">
    <p-divider></p-divider>
    <span class="dynamic-label mt-4">
      <p-dropdown
        id="selectOslcProvider"
        [autoDisplayFirst]="false"
        [options]="c.m.user.tenant.oslcRootServices"
        [(ngModel)]="m.selectedOslcRootService"
        (onChange)="m.windowRef && m.windowRef.close() && (m.windowRef = null)"
      ></p-dropdown>
      <label class="dynamic-label-text" for="selectOslcProvider" translate>Select an OSLC provider</label>
    </span>
    <button pButton pRipple type="button" label="{{ 'Open login dialog' | translate }}" class="mb-4" (click)="openLoginDialog()"></button>
    <p-divider></p-divider>
  </div>

  <!-- *********************************************************************** -->

  <ng-container *ngIf="c.m.user && c.m.user.oslcData.length > 0; else emptyOslcRootServices">
    <p-accordion>
      <p-accordionTab *ngFor="let oslcData of c.m.user.oslcData; let i = index">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">{{ oslcData.domain }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <span class="dynamic-label mt-2">
            <input type="text" id="consumerKey_{{ i }}" pInputText [(ngModel)]="oslcData.accessToken.token" [disabled]="true" />
            <label class="dynamic-label-text" for="consumerKey_{{ i }}" translate>Access token</label>
          </span>
          <span class="dynamic-label">
            <p-password
              id="consumerSecret_{{ i }}"
              [(ngModel)]="oslcData.accessToken.secret"
              [toggleMask]="true"
              [feedback]="false"
              [disabled]="true"
            ></p-password>
            <label class="dynamic-label-text" for="consumerSecret_{{ i }}" translate>Access secret</label>
          </span>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-container>
  <ng-template #emptyOslcRootServices>
    <p class="m-0">{{ 'No OSLC root services are configured' | translate }}</p>
  </ng-template>
</p-card>
