export enum PageBuilderEventType {
  copy,
  paste,
  delete,
  export,

  styleElement,

  widgetAdvancedMode,

  setHtmlId,

  redesignColumns,
  saveAsTemplate,
  addSection,
  addBlockPart,
  addWidget,
  addWidgetToModal,
  addTemplateToModal,

  rowDrop,
  blockPartDrop,
  widgetDrop,
}
