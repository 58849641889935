import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ApiService } from '@api/services/api.service';
import { Environment } from '@environments/environment';
import { Constants } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { WindowResizeService } from '@shared/services/window-resize.service';
import { StateKey } from '@shared/types/local-storage.types';
import { AppModel } from './app.types';

@Injectable()
export class AppService extends CoreService<any, AppModel> {
  constructor(
    public readonly localStorageService: LocalStorageService,
    public readonly apiConfiguration: ApiConfiguration,
    public readonly apiService: ApiService,
    private readonly windowResizeService: WindowResizeService,
    private readonly authorizationService: AuthorizationService,
  ) {
    super();
  }

  public async init(context: any, model: AppModel): Promise<void> {
    super.init(context, model);
    this.windowResizeService.initResizeObserver();

    this.localStorageService.initialize();
    // ModHeader
    const apiUrl = new URL(window.location.href).origin.replace('4200', '3000');

    // Uncomment if you want to test local frontend against e-devtest or e-prod
    // const apiUrl = 'https://e-devtest.softacus.com';
    // const apiUrl = 'https://e-prod.softacus.com';
    // const apiUrl = 'https://e-test.softacus.com';

    this.apiConfiguration.rootUrl = !Environment.production ? apiUrl : new URL(window.location.href).origin;
    await this.checkBuildVersion();

    const anonymousUser = this.localStorageService.getFromState(StateKey.master, Constants.user);
    if (!anonymousUser) {
      await this.authorizationService.anonymousLogin();
      window.location.reload();
    }
    // window.addEventListener('storage', this.handleLocalStorageEvent.bind(this));
  }

  handleLocalStorageEvent(): void {
    setTimeout(() => {
      const loggedIn =
        Boolean(this.localStorageService.get(StateKey.session)) && Boolean(this.localStorageService.getFromState(StateKey.session, Constants.token));
      if (!loggedIn) {
        this.c.router.navigate(['login']);
      }
    }, 300);
  }

  private async checkBuildVersion(): Promise<void> {
    const lastStoredHash = this.localStorageService.getFromState(StateKey.master, Constants.buildHash);
    const serverInfo = await this.apiService.rootControllerInfo().toPromise();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (lastStoredHash !== serverInfo.buildInfo.git.hash) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.localStorageService.setToState(StateKey.master, Constants.buildHash, serverInfo.buildInfo.git.hash);
      window.location.reload();
    }
  }
}
