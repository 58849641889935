import { Component, Input } from '@angular/core';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';

@Component({
  selector: 'app-enumerated-kind-input',
  templateUrl: './enumerated-kind-input.component.html',
  styleUrls: ['./enumerated-kind-input.component.scss'],
})
export class EnumeratedKindInputComponent {
  @Input() index: number;
  @Input() artifactAttribute: NewClientAttribute;
  @Input() fullAttribute: NewAttribute;
  @Input() fullDataType: NewDataType;
}
