import { TypeSystemElement } from '@private/components/type-system-element-dropdown/type-system-element';
import { LinkType } from '@shared/types/link-type.types';
import { LinkDirection } from '../../../../../artifact-management/artifact/types/artifact.types';

export class DirectionalLinkTypeSystemElement extends LinkType implements TypeSystemElement {
  id: `${string}${LinkDirection}`;
  name: string;
  styleClass: string;

  constructor(public direction: LinkDirection, public readonly linkType: LinkType) {
    super(linkType);
    this.id = `${linkType.id}${this.direction}`;
    this.name = this.label;
    this.styleClass = `directional-link-type directional-link-type--${this.direction}`;
  }

  get label(): string {
    return this.direction === LinkDirection.outgoing ? this.outgoingLabel : this.incomingLabel;
  }

  private get outgoingLabel(): string {
    return this.directionalLabel ? this.outgoingName : this.linkType.name || '';
  }

  private get incomingLabel(): string {
    return this.directionalLabel ? this.incomingName : this.linkType.name || '';
  }
}
