import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApexChartWidgetComponent } from './apex-chart-widget.component';
import { ApexChartWidgetSettingsComponent } from './components/apex-chart-widget-settings/apex-chart-widget-settings.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { TransformArrayToSelectOptionsPipe } from '@shared/pipes/common/transform-array-to-select-options.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [ApexChartWidgetComponent, ApexChartWidgetSettingsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    CommonPipesModule,
    TranslateModule,
    AccordionModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    OverlayPanelModule,
    ArtifactFiltersModule,
    NgApexchartsModule,
    StyleFormModule,
  ],
  providers: [TransformArrayToSelectOptionsPipe],
  exports: [ApexChartWidgetComponent],
})
export class ApexChartWidgetModule {}
