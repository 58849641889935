import { MetaListResponseDto } from '@api/models/meta-list-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewApplication } from '@shared/types/application.types';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ListMatrixWidgetModelDto } from '@widgets/list-matrix-widget/types/list-matrix-widget-model-dto';
import { ListMatrixWidgetOptions } from '@widgets/list-matrix-widget/types/list-matrix-widget-options';
import { INITIAL_SETTINGS, ListMatrixWidgetSettings } from '@widgets/list-matrix-widget/types/list-matrix-widget-settings';
import { ArtifactFilter, ArtifactFilterDto, ArtifactFilterOptions } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactSort } from '@widgets/shared/components/artifact-sorts/types/artifact-sort';
import { ArtifactSortDto } from '@widgets/shared/components/artifact-sorts/types/artifact-sort-dto';
import { ArtifactAdditionalData } from '@widgets/shared/types/artifact-additional-data';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

const initialModel: ListMatrixWidgetModelDto = {
  applicationId: '',
  filters: [],
  sorts: [],
  settings: { ...INITIAL_SETTINGS },
};

export class ListMatrixWidgetModel implements ListMatrixWidgetModelDto {
  settings: ListMatrixWidgetSettings;
  application: NewApplication;

  cardWidget: BlockPartWidget<{ model: CardWidgetModel }> | null = null;
  cardArtifactAdditionalData: ArtifactAdditionalData = {
    links: [],
    linkedArtifacts: [],
    files: [],
  };

  listResponseMeta: MetaListResponseDto | null = null;
  paginatorEvent: {
    first: number;
    page: number;
    pageCount: number;
    rows: number;
  } | null = null;

  filters: ArtifactFilter[];
  sorts: ArtifactSort[];

  loading$: Observable<boolean>;

  private _applicationId: string;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private constructor(dto: ListMatrixWidgetModelDto = initialModel, public options: ListMatrixWidgetOptions = new ListMatrixWidgetOptions()) {
    const { settings: settingsDto, filters: filterDtos = [], sorts: sortDtos = [], ...rest } = dto;
    const settings = new ListMatrixWidgetSettings(settingsDto);
    const artifactFilterOptions: ArtifactFilterOptions = {
      attributes: [...options.combinedAttributes, ...options.customAttributes.list],
      dataTypes: options.dataTypes.list,
      linkTypes: options.linkTypes.list,
    };
    settings.listItemClickAction.filtersForQueryParams = settings.listItemClickAction.filtersForQueryParams.map((filterDto: ArtifactFilterDto) =>
      ArtifactFilter.fromDtoAndOptions(filterDto, artifactFilterOptions),
    );
    const filters = filterDtos.map((filterDto: ArtifactFilterDto) => ArtifactFilter.fromDtoAndOptions(filterDto, artifactFilterOptions));
    const sorts = sortDtos.map((sortDto: ArtifactSortDto) => ArtifactSort.fromDtoAndOptions(sortDto, options.combinedAttributes));

    Object.assign(this, cloneDeep(rest), { settings, filters, sorts });

    this.loading$ = this.loading.asObservable();
  }

  get applicationId(): string {
    return this.application?.id || this._applicationId;
  }

  set applicationId(id: string) {
    this._applicationId = id;
  }

  static initial(): ListMatrixWidgetModel {
    return new ListMatrixWidgetModel();
  }

  static fromDto(dto: ListMatrixWidgetModelDto, options: ListMatrixWidgetOptions): ListMatrixWidgetModel {
    return new ListMatrixWidgetModel(dto, options);
  }

  showLoader(): void {
    this.loading.next(true);
  }

  hideLoader(): void {
    this.loading.next(false);
  }

  copy(): ListMatrixWidgetModelDto {
    return {
      ...this.toServer(),
    };
  }

  toServer(): ListMatrixWidgetModelDto {
    return {
      applicationId: this.applicationId,
      filters: this.filters.map((filter: ArtifactFilter) => filter.toServer()),
      sorts: this.sorts.map((sort: ArtifactSort) => sort.toServer()),
      settings: this.settings.toServer(),
    };
  }
}
