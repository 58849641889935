<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-8">
    <input
      id="forEachPathInput"
      [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value"
      pInputText
      type="text"
      class="w-100"
      placeholder="Enter for-each-path"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Date' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.date].isDynamic"
      (ngModelChange)="onIsDateDynamicChange()"
      id="isDynamicDate"
      binary="true"
      class="mr-2"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>

  <div class="col-8">
    <ng-container *ngIf="action.actionSettings[RuleKeys.date].isDynamic">
      <input id="dateInput" [(ngModel)]="action.actionSettings[RuleKeys.date].value" pInputText type="text" class="w-100" placeholder="Enter dynamic date" />
    </ng-container>
    <ng-container *ngIf="!action.actionSettings[RuleKeys.date].isDynamic">
      <p-calendar
        [(ngModel)]="action.scheduledDate"
        [readonlyInput]="true"
        [showTime]="true"
        id="dateSelect"
        appendTo="body"
        placeholder="Select date"
      ></p-calendar>
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Workflow' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.workflowId].isDynamic" id="isDynamicWorkflow" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <p-dropdown
      [(ngModel)]="action.actionSettings[RuleKeys.workflowId].value"
      (onChange)="onWorkflowIdChange($event.value)"
      [options]="schedulableWorkflows"
      [autoDisplayFirst]="false"
      id="scheduledWorkflowId"
      optionValue="id"
      placeholder="Select workflow"
      appendTo="body"
    >
      <ng-template let-option pTemplate="item">{{ option.name | translate }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.name | translate }}</ng-template>
    </p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Inputs' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.inputDataValue].isDynamic" id="isDynamicInputData" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let inputData of action.workflowClientInputDataArray; let i = index">
      <div class="record-row">
        <div class="attribute-settings">
          <div>
            <p-checkbox [(ngModel)]="inputData.isNull" [id]="'isNull' + i" binary="true" class="mr-2 pt-2"></p-checkbox>
            {{ 'null' | translate }}
          </div>
          <div>
            <p-checkbox
              [(ngModel)]="inputData.isArray"
              (ngModelChange)="onIsArrayChange($event, i)"
              [id]="'isArray' + i"
              binary="true"
              class="mr-2 pt-2"
            ></p-checkbox>
            {{ 'array' | translate }}
          </div>
          <div></div>
          <div>
            <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveKey(i)"></button>
          </div>
        </div>
        <div class="inputs">
          <div>
            <input [id]="'key' + i" [(ngModel)]="inputData.key" pInputText type="text" placeholder="Enter key {{ i + 1 }}" class="w-100" />
          </div>
          <div>
            <ng-container *ngIf="!inputData.isNull">
              <ng-container *ngIf="!inputData.isArray">
                <input [id]="'value' + i" [(ngModel)]="inputData.value" pInputText type="text" placeholder="Enter value" class="w-100" />
              </ng-container>
              <ng-container *ngIf="inputData.isArray">
                <div *ngFor="let inputDataValue of inputData.value; let j = index; trackBy: inputDataTrackByFn" class="d-flex">
                  <input [id]="'value' + j" [(ngModel)]="inputData.value![j]" pInputText type="text" placeholder="Enter value {{ j + 1 }}" class="w-100" />
                  <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddInputData(i, j)"></button>
                  <button
                    class="p-button p-button-text p-button-danger"
                    pButton
                    type="button"
                    icon="pi pi-minus"
                    (click)="onRemoveInputData(i, j)"
                    [ngClass]="{ 'visibility-hidden': inputData.value!.length <= 1 }"
                  ></button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddKey()" label="Add key"></button>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Wait for result' | translate }}
  </div>
  <div class="col-8">
    <p-checkbox [(ngModel)]="action.waitForResult" id="waitForResult" binary="true" class="mr-2 pt-2"></p-checkbox>
  </div>
</div>
