"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.toPostfix = function (e) {
  for (var p, t, u, l = [], o = -1, r = -1, s = [{
      value: "(",
      type: 4,
      precedence: 0,
      show: "("
    }], a = 1; a < e.length; a++) if (1 === e[a].type || 3 === e[a].type || 13 === e[a].type) 1 === e[a].type && (e[a].value = Number(e[a].value)), l.push(e[a]);else if (4 === e[a].type) s.push(e[a]);else if (5 === e[a].type) for (; 4 !== (null == (h = t = s.pop()) ? void 0 : h.type);) {
    var h;
    t && l.push(t);
  } else if (11 === e[a].type) {
    for (; 4 !== (null == (v = t = s.pop()) ? void 0 : v.type);) {
      var v;
      t && l.push(t);
    }
    s.push(t);
  } else {
    r = (p = e[a]).precedence, o = (u = s[s.length - 1]).precedence;
    var n = "Math.pow" == u.value && "Math.pow" == p.value;
    if (r > o) s.push(p);else {
      for (; o >= r && !n || n && r < o;) t = s.pop(), u = s[s.length - 1], t && l.push(t), o = u.precedence, n = "Math.pow" == p.value && "Math.pow" == u.value;
      s.push(p);
    }
  }
  return l;
};