<div>
  <ng-container *ngIf="($loading | async) === false; else loadingSkeleton">
    <picture *ngIf="artifactBlobUrl; else missingImage">
      <img [src]="artifactBlobUrl" />
    </picture>
    <ng-template #missingImage>
      <i class="bi bi-card-image"></i>
    </ng-template>
  </ng-container>
  <ng-template #loadingSkeleton>
    <p-skeleton [width]="width" [height]="height" borderRadius="0"></p-skeleton>
  </ng-template>
</div>
