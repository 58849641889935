import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestrictionParamsEnum, NewLink } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'canAddLink' })
export class CanAddLinkPipe implements PipeTransform {
  transform(
    linkMap: Record<string, Record<LinkDirection, NewLink[]>>,
    linkType: SelectOption<string, LinkType, LinkDirection>,
    artifactTypeId: string | null,
    linkedArtifactsMap: Record<string, NewArtifact>,
  ): boolean {
    if (!linkMap[linkType.value.id] || !linkMap[linkType.value.id][linkType.meta]) return true;

    let canAdd = false;
    const restrictionCurrentArtifactType = LinkMethods.isOutgoing(linkType.meta)
      ? LinkRestrictionParamsEnum.sourceArtifactTypeId
      : LinkRestrictionParamsEnum.destinationArtifactTypeId;
    const restrictionTargetArtifactType = !LinkMethods.isOutgoing(linkType.meta)
      ? LinkRestrictionParamsEnum.sourceArtifactTypeId
      : LinkRestrictionParamsEnum.destinationArtifactTypeId;
    const restrictionRelation = LinkMethods.isOutgoing(linkType.meta) ? LinkRestrictionParamsEnum.singleSource : LinkRestrictionParamsEnum.singleDestination;
    linkType.value.restrictions.forEach(restriction => {
      if (
        restriction[restrictionCurrentArtifactType] === artifactTypeId &&
        (!restriction[restrictionRelation] ||
          !this.isArtifactTypeLinked(
            linkMap[linkType.value.id][linkType.meta],
            restriction[restrictionTargetArtifactType],
            linkedArtifactsMap,
            LinkMethods.isOutgoing(linkType.meta),
          ))
      ) {
        canAdd = true;
      }
    });
    return canAdd;
  }

  isArtifactTypeLinked(links: NewLink[], artifactTypeId: string | null, linkedArtifactsMap: Record<string, NewArtifact>, isOutgoing: boolean): boolean {
    let isFound = false;
    links.find(link => {
      const key = isOutgoing ? 'destinationArtifactId' : 'sourceArtifactId';
      const artifactId = link[key];
      if (linkedArtifactsMap[artifactId].artifactTypeId === artifactTypeId) {
        isFound = true;
        return true;
      }
      return false;
    });
    return isFound;
  }
}
