import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipDesignStylerComponent } from './chip-design-styler.component';

@NgModule({
  declarations: [ChipDesignStylerComponent],
  imports: [CommonModule, OverlayPanelModule, StyleFormModule, ButtonModule, TranslateModule, DropdownModule, FormsModule, CheckboxModule],
  exports: [ChipDesignStylerComponent],
})
export class ChipDesignStylerModule {}
