<div *ngIf="page.settings.styles" class="style-block">
  <div class="grid">
    <div class="col-4 flex align-items-center">
      <h6>{{ 'Background' | translate }}</h6>
    </div>
    <div class="col-5">
      <p-dropdown [(ngModel)]="page.settings.styles.backgroundType"
                  (ngModelChange)="stylesTypeChange()"
                  [options]="styleOptions.backgroundType" [autoDisplayFirst]="false"
                  [placeholder]="'Choose bg type'"
                  [showClear]="true">
      </p-dropdown>
    </div>

    <div class="col-3" *ngIf="!(page.settings.styles.backgroundType | isBackgroundOfType: backgroundType.gradient)">
      <button (click)="backgroundColorOverlayPanel.toggle($event)"
              *ngIf="page.settings.styles.backgroundType | isBackgroundOfType: backgroundType.color"
              icon="pi pi-palette"
              label="{{'Color' | translate}}"
              pButton></button>
      <button (click)="backgroundImageOverlayPanel.toggle($event)"
              *ngIf="page.settings.styles.backgroundType | isBackgroundOfType: backgroundType.image"
              icon="pi pi-image"
              label="{{'Image' | translate}}"
              pButton></button>
    </div>

    <div style="height: 63px;" class="col-4 flex align-items-center justify-content-between">
      <h6><label [for]="'autoReload'">{{ 'Auto reload' | translate }}</label></h6>
      <p-checkbox inputId="autoReload" [(ngModel)]="page.settings.autoReload" [binary]="true"></p-checkbox>
    </div>
    <div class="col-3 flex align-items-center" *ngIf="page.settings.autoReload">
      <h6><label for="reloadInterval">{{ 'Interval (ms)' | translate }}</label></h6>
    </div>
    <div class="col-5" *ngIf="page.settings.autoReload">
      <input id="reloadInterval" type="number" pInputText [(ngModel)]="page.settings.reloadInterval">
    </div>

    <div class="col-12" *ngIf="page.settings.styles.backgroundType | isBackgroundOfType: backgroundType.gradient">
      <app-bg-gradient-settings [styles]="page.settings.styles" [gradientChangeCb]="gradientChangeCb">
      </app-bg-gradient-settings>
    </div>

    <!--    Settings for metadata-->
    <div class="col-12">
      <h3>{{ 'Page meta data' }}</h3>
    </div>

    <div class="col-4 flex align-items-center">
      <h6><label [for]="'doNotIndex'">{{ 'Do not index this page' | translate }}</label></h6>
    </div>

    <div class="col-8">
      <p-checkbox inputId="doNotIndex" [(ngModel)]="page.seoFields.doNotIndex" [binary]="true"></p-checkbox>
    </div>

    <div class="col-4 flex align-items-center">
      <h6>{{ 'Title' | translate }}</h6>
    </div>

    <div class="col-8">
      <input type="text" pInputText [(ngModel)]="page.seoFields.ogTitle" [disabled]="page.seoFields.doNotIndex">
    </div>

    <div class="col-4 flex align-items-center">
      <h6>{{ 'Description' | translate }}</h6>
    </div>

    <div class="col-8 description-field">
      <textarea type="text" pInputTextarea
                [(ngModel)]="page.seoFields.description"
                [maxlength]="1000"
                [disabled]="page.seoFields.doNotIndex">
      </textarea>
      <span class="description-field_counter">{{ page.seoFields.description?.length || 0 }} / 1000</span>
    </div>

    <div class="col-4 flex align-items-center">
      <h6>{{ 'Keywords' | translate }}</h6>
    </div>

    <div class="col-8">
      <textarea type="text" pInputTextarea
                [(ngModel)]="page.seoFields.keywords"
                [maxlength]="1000"
                [disabled]="page.seoFields.doNotIndex">
      </textarea>
    </div>

    <div class="col-4 flex align-items-center">
      <h6>{{ 'Image url (sharing)' | translate }}</h6>
    </div>

    <div class="col-8">
      <input type="text" pInputText [(ngModel)]="page.seoFields.ogImage" [disabled]="page.seoFields.doNotIndex">
    </div>
  </div>
</div>

<p-overlayPanel #backgroundColorOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-color-picker [(color)]="page.settings.styles.background" (colorChange)="gradientChangeCb()"
                      label="Background"></app-color-picker>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #backgroundImageOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-background-image [(imageUrl)]="page.settings.styles.background"
                          (imageUrlChange)="gradientChangeCb()"></app-background-image>
  </ng-template>
</p-overlayPanel>
