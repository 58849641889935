import { Injectable } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { SystemTenantAttributesDto } from '@api/models/system-tenant-attributes-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UserProfilePictureService } from '@shared/services/user-profile/user-profile-picture.service';

import { NewUser } from '@shared/types/user.types';
import { filter, Observable, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserProfileAndPictureData, UserProfileData } from '../../types/user-profile.types';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  userProfileDataMap: Record<string, Observable<UserProfileData>> = {};
  userProfilePictureMap: Record<string, Observable<UserProfileAndPictureData>> = {};

  constructor(private readonly userProfilePictureService: UserProfilePictureService, private readonly cache: NewCacheService) {}

  getProfilesData$(userIds: string[], usersMap: Record<string, NewUser>): Observable<UserProfileData>[] {
    return userIds.map(id => this.getProfileData$(id, usersMap));
  }

  getProfilesAndPicturesData$(userIds: string[], usersMap: Record<string, NewUser>): Observable<UserProfileAndPictureData>[] {
    return userIds.map(id => this.getProfileAndPictureData$(id, usersMap));
  }

  private getProfileData$(userId: string, usersMap: Record<string, NewUser>): Observable<UserProfileData> {
    if (!this.userProfileDataMap[userId]) {
      this.userProfileDataMap[userId] = this.cache.user.pipe(
        filter(currentUser => !!(currentUser as SelfUserResponseDto).tenant?.systemAttributes),
        map(currentUser => (currentUser as SelfUserResponseDto).tenant?.systemAttributes),
        switchMap(systemAttributes => {
          return this.cache.data.artifacts
            .get$(usersMap[userId]?.tenant?.profileArtifactId as string)
            .pipe(map(artifact => this.toUserProfileData(artifact, systemAttributes!)));
        }),
        shareReplay(),
      );
    }

    return this.userProfileDataMap[userId];
  }

  private getProfileAndPictureData$(userId: string, usersMap: Record<string, NewUser>): Observable<UserProfileAndPictureData> {
    if (!this.userProfilePictureMap[userId]) {
      this.userProfilePictureMap[userId] = this.getProfileData$(userId, usersMap).pipe(
        switchMap(userProfileData => {
          return this.userProfilePictureService.getUserImage$(userProfileData.dto).pipe(map(userPicture => ({ ...userProfileData, userPicture })));
        }),
        shareReplay(),
      );
    }

    return this.userProfilePictureMap[userId];
  }

  private toUserProfileData(dto: ArtifactResponseDto, systemAttributes: SystemTenantAttributesDto): UserProfileData {
    const { emailAttributeId, nameAttributeId } = systemAttributes;
    return {
      dto,
      userName: dto.attributes[nameAttributeId].value as string,
      userEmail: dto.attributes[emailAttributeId].value as string,
    } as UserProfileData;
  }
}
