<div class="header">
  <a [routerLink]="['/system/user']">
    <button pButton [label]="'Add' | translate" class="p-button" icon="pi pi-plus" iconPos="left"></button>
  </a>
</div>

<app-system-admin-user-list-table *ngIf="m.tenants.loaded"
                                  [columns]="m.columns"
                                  [loadDataMethod]="loadDataMethod"
                                  [tenants]="m.tenants">
</app-system-admin-user-list-table>
