import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { merge, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { DisplayAtOverlayControlService } from './display-at-overlay-control.service';

@Component({
  selector: 'app-display-at-overlay',
  templateUrl: './display-at-overlay.component.html',
  styleUrls: ['display-at-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtOverlayComponent implements OnInit, OnDestroy {
  @Input() ownerId: string;

  @ViewChild('htmlOp') overlayPanel: OverlayPanel;

  event: Event;
  closable: boolean;
  contentTemplate: TemplateRef<any>;

  private isVisible: boolean;
  private initSubscription: Subscription;

  constructor(private readonly overlayControl: DisplayAtOverlayControlService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const showOverlay$ = this.overlayControl.getShowOverlay$(this.ownerId).pipe(
      filter(showEvent => showEvent.closable || !this.isVisible),
      tap(showEvent => {
        this.event = showEvent.event;
        this.contentTemplate = showEvent.contentTemplate;
        this.closable = showEvent.closable;
        this.overlayPanel.show(this.event);
        if (this.isVisible) {
          this.overlayPanel.align();
        }
        this.isVisible = true;
        this.cdr.markForCheck();
      }),
    );

    const hideOverlay$ = this.overlayControl.hideOverlay$.pipe(
      filter(() => !this.closable),
      tap(() => {
        this.overlayPanel.hide();
        this.cdr.markForCheck();
      }),
    );

    this.initSubscription = merge(showOverlay$, hideOverlay$).subscribe();
  }

  ngOnDestroy(): void {
    this.initSubscription.unsubscribe();
  }

  onOverlayHide() {
    this.isVisible = false;
  }
}
