import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { CURRENT_USER_URL_FILTER_VALUE } from '@shared/constants/constants';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { AbstractCardWidgetModeStrategy } from '@widgets/card-widget/services/abstract-card-widget-mode-strategy';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { lastValueFrom, Subject } from 'rxjs';
import { concatMap, filter, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class DynamicArtifactService extends AbstractCardWidgetModeStrategy implements OnDestroy {
  private readonly stopListenToUrl: Subject<void> = new Subject<void>();
  private isLayoutMode: boolean;
  private isPreviewMode: boolean;

  constructor(
    tenantArtifactService: TenantArtifactService,
    tenantLinkService: TenantLinkService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly elvisUtil: ElvisUtil,
  ) {
    super(tenantArtifactService, tenantLinkService);
  }

  ngOnDestroy(): void {
    this.stopListenToUrl.next();
    this.stopListenToUrl.complete();
  }

  async init(context: CardWidgetComponent): Promise<void> {
    super.init(context);
    this.isLayoutMode = context.isLayoutMode;
    this.isPreviewMode = context.isPreviewMode;

    if (this.settings.widgetMode !== CardWidgetMode.dynamicArtifact) {
      return;
    }

    await this.initDataLoading();
  }

  async onModeChange(): Promise<void> {
    if (this.settings.widgetMode !== CardWidgetMode.dynamicArtifact) {
      this.stopListenToUrl.next();
    } else {
      this.model.isReady = false;

      await this.initDataLoading();

      this.model.isReady = true;
    }
  }

  async onArtifactTypeChange(): Promise<void> {
    await this.loadArtifactExample();
    await this.loadLinkedArtifacts();
    await this.loadArtifactFiles();
  }

  private async initDataLoading(): Promise<void> {
    this.subscribeOnArtifactIdChanges();

    if (!this.isPreviewMode && this.settings.isArtifactTypeSelected && !this.model.options.artifactOptions.loaded) {
      await this.onArtifactTypeChange();
    }
  }

  private subscribeOnArtifactIdChanges(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        map((paramMap: ParamMap) => paramMap.get(this.settings.listeningKey) || ''),
        filter<string>(Boolean),
        concatMap(async (artifactId: string) => {
          await this.loadArtifactById(artifactId);
          await this.loadLinkedArtifacts();
          await this.loadArtifactFiles();
        }),
        takeUntil(this.stopListenToUrl),
      )
      .subscribe();
  }

  private async loadArtifactById(id: string): Promise<void> {
    let currentUserProfileArtifactId;

    if (id === CURRENT_USER_URL_FILTER_VALUE) {
      currentUserProfileArtifactId = this.elvisUtil.getCurrentUser().tenant?.profileArtifactId;
    }
    this.model.currentArtifactIdFromUrl = currentUserProfileArtifactId || id;

    const artifact = await lastValueFrom(this.tenantArtifactService.artifactControllerGet({ id: currentUserProfileArtifactId || id })).catch(e =>
      console.error(e),
    );

    if (artifact?.artifactTypeId === this.settings.artifactTypeId) {
      this.options.artifactOptions.setList([artifact], 'id');
    }
  }
}
