import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IS_DELETED_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';

import { LinkTypeService } from './services/link-type.service';
import { LinkTypeModel } from './types/link-type.types';

@Component({
  selector: 'app-link-types',
  templateUrl: './link-type.component.html',
  styleUrls: ['./link-type.component.scss'],
  providers: [LinkTypeService],
})
export class LinkTypeComponent extends CoreComponent<LinkTypeService, LinkTypeModel> {
  public canDeactivate = false;

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: LinkTypeService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new LinkTypeModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Link Types' }]);
  }
}
