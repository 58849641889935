<div class="grid">
  <div class="col-6">
    <h5>{{'Sorting' | translate}}</h5>
  </div>
  <div class="col-6">
    <button
      (click)="addSort()"
      [disabled]="isAddSortButtonDisabled"
      class="d-block ml-auto"
      label="{{'Add sort' | translate}}"
      pButton
      type="button">
    </button>
  </div>

  <div class="col-12">
    <div *ngFor="let sort of sorts" class="grid">
      <div class="col-8">
        <p-dropdown
          (onChange)="onSortAttributeChange(sort)"
          [(ngModel)]="sort.attribute"
          [filter]="true"
          [options]="attributes"
          placeholder="Attribute">
        </p-dropdown>
      </div>
      <div class="col-2">
        <button
          (click)="onSortOrderChange(sort)"
          [disabled]="!sort.attribute"
          [icon]="sort.icon"
          class="p-button-rounded p-button-text"
          pButton
          pRipple
          type="button">
        </button>
      </div>
      <div class="col-2">
        <button
          (click)="removeSort(sort)"
          class="ml-2 p-button-danger p-button-sm"
          icon="pi pi-times"
          pButton
          pRipple
          type="button">
        </button>
      </div>
    </div>
  </div>
</div>
