<div class="add-button-section">
    <button pButton
            pRipple
            type="button"
            [label]="'Add new row' | translate"
            icon="pi pi-plus"
            id="toggleLayoutToolbar"
            class="p-button-success p-button-text"
            (click)="toggleLayoutToolbar($event)">
    </button>
</div>
