/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { artifactTypeControllerCount } from '../fn/tenant-artifact-type/artifact-type-controller-count';
import { ArtifactTypeControllerCount$Params } from '../fn/tenant-artifact-type/artifact-type-controller-count';
import { artifactTypeControllerCreate } from '../fn/tenant-artifact-type/artifact-type-controller-create';
import { ArtifactTypeControllerCreate$Params } from '../fn/tenant-artifact-type/artifact-type-controller-create';
import { artifactTypeControllerDelete } from '../fn/tenant-artifact-type/artifact-type-controller-delete';
import { ArtifactTypeControllerDelete$Params } from '../fn/tenant-artifact-type/artifact-type-controller-delete';
import { artifactTypeControllerGet } from '../fn/tenant-artifact-type/artifact-type-controller-get';
import { ArtifactTypeControllerGet$Params } from '../fn/tenant-artifact-type/artifact-type-controller-get';
import { artifactTypeControllerList } from '../fn/tenant-artifact-type/artifact-type-controller-list';
import { ArtifactTypeControllerList$Params } from '../fn/tenant-artifact-type/artifact-type-controller-list';
import { artifactTypeControllerUpdate } from '../fn/tenant-artifact-type/artifact-type-controller-update';
import { ArtifactTypeControllerUpdate$Params } from '../fn/tenant-artifact-type/artifact-type-controller-update';
import { ArtifactTypeListResponseDto } from '../models/artifact-type-list-response-dto';
import { ArtifactTypeResponseDto } from '../models/artifact-type-response-dto';
import { BaseCountResponseDto } from '../models/base-count-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantArtifactTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `artifactTypeControllerCount()` */
  static readonly ArtifactTypeControllerCountPath = '/api/tenant/artifact-type/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerCount$Response(params?: ArtifactTypeControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return artifactTypeControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerCount(params?: ArtifactTypeControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.artifactTypeControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactTypeControllerGet()` */
  static readonly ArtifactTypeControllerGetPath = '/api/tenant/artifact-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerGet$Response(params: ArtifactTypeControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactTypeResponseDto>> {
    return artifactTypeControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerGet(params: ArtifactTypeControllerGet$Params, context?: HttpContext): Observable<ArtifactTypeResponseDto> {
    return this.artifactTypeControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactTypeResponseDto>): ArtifactTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactTypeControllerDelete()` */
  static readonly ArtifactTypeControllerDeletePath = '/api/tenant/artifact-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerDelete$Response(params: ArtifactTypeControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactTypeResponseDto>> {
    return artifactTypeControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerDelete(params: ArtifactTypeControllerDelete$Params, context?: HttpContext): Observable<ArtifactTypeResponseDto> {
    return this.artifactTypeControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactTypeResponseDto>): ArtifactTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactTypeControllerList()` */
  static readonly ArtifactTypeControllerListPath = '/api/tenant/artifact-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerList$Response(params?: ArtifactTypeControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactTypeListResponseDto>> {
    return artifactTypeControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactTypeControllerList(params?: ArtifactTypeControllerList$Params, context?: HttpContext): Observable<ArtifactTypeListResponseDto> {
    return this.artifactTypeControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactTypeListResponseDto>): ArtifactTypeListResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactTypeControllerUpdate()` */
  static readonly ArtifactTypeControllerUpdatePath = '/api/tenant/artifact-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactTypeControllerUpdate$Response(params: ArtifactTypeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactTypeResponseDto>> {
    return artifactTypeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactTypeControllerUpdate(params: ArtifactTypeControllerUpdate$Params, context?: HttpContext): Observable<ArtifactTypeResponseDto> {
    return this.artifactTypeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactTypeResponseDto>): ArtifactTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactTypeControllerCreate()` */
  static readonly ArtifactTypeControllerCreatePath = '/api/tenant/artifact-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactTypeControllerCreate$Response(params: ArtifactTypeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactTypeResponseDto>> {
    return artifactTypeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artifactTypeControllerCreate(params: ArtifactTypeControllerCreate$Params, context?: HttpContext): Observable<ArtifactTypeResponseDto> {
    return this.artifactTypeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactTypeResponseDto>): ArtifactTypeResponseDto => r.body)
    );
  }

}
