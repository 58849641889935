<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'User profile id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.userProfileId].isDynamic" id="isUserProfileIdDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.userProfileId].value"
      [placeholder]="action.actionSettings[RuleKeys.userProfileId].isDynamic ? 'Enter dynamic id' : ('Enter id' | translate)"
      pInputText
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Applications' | translate }}
  </div>
  <div class="col-2">
    <p-triStateCheckbox [ngModel]="false" id="isApplicationsDynamic" [disabled]="true"></p-triStateCheckbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let application of action.manageUserApplicationsApplications; let index = index">
      <div class="record-row">
        <div class="inputs">
          <div>
            <p-dropdown
              [(ngModel)]="application.applicationId"
              [autoDisplayFirst]="false"
              [filter]="true"
              [options]="applications.list | transformArrayToSelectOptions : 'name' : 'id'"
              [id]="'applicationId' + index"
            ></p-dropdown>
          </div>
          <div>
            <p-dropdown
              [(ngModel)]="application.operation"
              [autoDisplayFirst]="false"
              [options]="applicationOperationOptions"
              [id]="'operation' + index"
            ></p-dropdown>
          </div>
          <div>
            <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveApplication(index)"></button>
          </div>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddApplication()" label="Add application"></button>
  </div>
</div>
