import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { MenuItem } from 'primeng/api';

type BreakpointTab = { breakpoint: StyleApplicationBreakpoint } & MenuItem;

const hint = `Enter the "min-width" breakpoint.
Value is always in px.
Comparison will be made against the Grid container.
`;

@Component({
  selector: 'app-breakpoint-tabs',
  templateUrl: './breakpoint-tabs.component.html',
  styleUrls: ['./breakpoint-tabs.component.scss'],
})
export class BreakpointTabsComponent {
  @Input() set breakpoints(breakpoints: StyleApplicationBreakpoint[]) {
    this.breakpointTabs = breakpoints.map((breakpoint: StyleApplicationBreakpoint) => {
      return { breakpoint, id: ElvisUtil.makeHash(16) };
    });
    this.activeBreakpoint = this._activeBreakpoint;
  }

  @Input() set activeBreakpoint(activeBreakpoint: StyleApplicationBreakpoint) {
    this._activeBreakpoint = activeBreakpoint;
    this.activeTab = this.breakpointTabs.find(({ breakpoint }) => breakpoint === activeBreakpoint)!;
  }

  @Output() addBreakpoint: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteBreakpoint: EventEmitter<number> = new EventEmitter<number>();
  @Output() activeBreakpointIndexChange: EventEmitter<number> = new EventEmitter<number>();

  breakpointTabs: BreakpointTab[] = [];
  activeTab: BreakpointTab;

  private _activeBreakpoint: StyleApplicationBreakpoint;

  readonly hint = hint;

  onAddTabClick(): void {
    this.addBreakpoint.emit();
  }

  deleteTab(index: number): void {
    this.deleteBreakpoint.emit(index);
  }

  onActiveTabChange(activeTab: MenuItem): void {
    const index = this.breakpointTabs.findIndex((tab: BreakpointTab) => tab === activeTab);
    this.activeBreakpointIndexChange.emit(index);
  }
}
