import { SetAttributeValueActionWorkflowSettingsDto } from '@api/models/set-attribute-value-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { CacheDataHolderService } from '@shared/cache/cache-data-holder.service';
import { AttributeValueToClient, AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { AttributeActionHandlerService, ModifyAttributeValueOperation } from '@workflows/shared';
import { WorkflowActionType, WorkflowTriggerEvent } from '@workflows/types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export enum SetAttributeValueKeys {
  value = 'value',
  operation = 'operation',
}

export class WorkflowActionSetAttributeValueActionDto implements SetAttributeValueActionWorkflowSettingsDto {
  value: SetAttributeValueActionWorkflowSettingsDto[SetAttributeValueKeys.value] = { value: '', isDynamic: false };
  operation: SetAttributeValueActionWorkflowSettingsDto[SetAttributeValueKeys.operation] = { value: '' as any, isDynamic: false };

  constructor(action?: WorkflowActionSetAttributeValueActionDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionSetAttributeValue extends AbstractWorkflowAttributeBasedAction<SetAttributeValueActionWorkflowSettingsDto> {
  constructor(dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService, private readonly dataHolder?: CacheDataHolderService) {
    super(WorkflowActionSetAttributeValueActionDto, WorkflowActionType.SET_VALUE, dto, actionAttributeHandler);

    const { dataTypes, attributes, users } = this.dataHolder!;

    this.actionSettings.value.value = AttributeValueToClient({
      dataTypes,
      attributes,
      users: users.list,
      clientAttribute: new NewClientAttribute({ id: this.attributeId, value: null, isMandatory: false }),
      value: (dto?.actionSettings as SetAttributeValueActionWorkflowSettingsDto)?.value?.value,
    });
    this.actionSettings.operation.value = (dto?.actionSettings as SetAttributeValueActionWorkflowSettingsDto).operation.value;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifact;
  }

  execute(): void {
    this.actionAttributeHandler.notifyModifyAttributeValueEvent({
      attributeId: this.attributeId,
      artifactTypeId: this.artifactTypeId,
      value: this.actionSettings.value.value,
      operation: this.actionSettings.operation.value as ModifyAttributeValueOperation,
    });
  }

  isValid(): boolean {
    return super.isValid() && !!(this.artifactTypeId && this.attributeId);
  }

  toServer(): WorkflowActionDto {
    const attribute = this.dataHolder!.attributes.listMap[this.attributeId];
    const dataType = this.dataHolder!.dataTypes.listMap[attribute.dataTypeId];

    return {
      ...super.toServer(),
      actionSettings: {
        value: {
          isDynamic: false,
          value: AttributeValueToServer(dataType, attribute, this.actionSettings.value.value),
        },
        operation: {
          isDynamic: false,
          value: this.actionSettings.operation.value || ModifyAttributeValueOperation.replace,
        },
      },
    };
  }
}
