import { RelationInBoolean, RelationOption, RelationTypeEnum } from '../types/link.types';

const booleanRelationByRelationType: Record<RelationTypeEnum, RelationInBoolean> = Object.freeze({
  [RelationTypeEnum.manyToMany]: { singleSource: false, singleDestination: false },
  [RelationTypeEnum.manyToOne]: { singleSource: true, singleDestination: false },
  [RelationTypeEnum.oneToMany]: { singleSource: false, singleDestination: true },
  [RelationTypeEnum.oneToOne]: { singleSource: true, singleDestination: true },
});

const relationTypes: RelationOption[] = [
  { value: RelationTypeEnum.oneToOne, label: 'One to one', disabled: false },
  { value: RelationTypeEnum.oneToMany, label: 'One to many', disabled: false },
  { value: RelationTypeEnum.manyToOne, label: 'Many to one', disabled: false },
  { value: RelationTypeEnum.manyToMany, label: 'Many to many', disabled: false },
];

export const LinkConstants = { booleanRelationByRelationType, relationTypes };
