<div class="grid" *ngIf="model">
    <div class="col-6">
        <label for="fileFilterEnable">Enable filter</label>
        <p-checkbox (onChange)="onChange()"
                    [(ngModel)]="model.isEnable"
                    binary="true"
                    id="fileFilterEnable">
        </p-checkbox>
    </div>
    <div class="col-6">
        <label for="fileFilter">File exist</label>
        <p-checkbox (onChange)="onChange()"
                    [(ngModel)]="model.value"
                    [disabled]="!model.isEnable"
                    binary="true"
                    id="fileFilter">
        </p-checkbox>
    </div>
</div>
