import { Directive, ElementRef, HostListener, Input, QueryList, Renderer2 } from '@angular/core';
import { CardAreaComponent } from '@widgets/card-widget/components/card-area/card-area.component';

class Cursor {
  readonly x: number;
  readonly y: number;

  constructor({ clientX, clientY }: MouseEvent) {
    this.x = clientX;
    this.y = clientY;
  }
}

const CSS_CLASS = 'overlapped-toolbar-container';

@Directive({
  selector: '[appAreaContentItemsToolbar]',
})
/**
 * This class is intended to solve the issue that
 * you cannot open AdvancedMode or Delete Attribute/LinkType/Widget
 * from the CardArea if it's overlapped by another CardArea
 * due to layout settings and/or content size
 */
export class AreaContentItemsToolbarDirective {
  @Input() cardAreaComponents: QueryList<CardAreaComponent>;
  @Input() isAdvancedModeOpen: boolean;

  constructor(private readonly renderer: Renderer2) {}

  @HostListener('mousemove', ['$event'])
  onMousemove($event: MouseEvent): void {
    if (!this.isAdvancedModeOpen) {
      return;
    }

    this.cardAreaComponents.toArray().forEach((area: CardAreaComponent) => {
      area.contentItemToolbarContainers.toArray().forEach((toolbarContainerElRef: ElementRef<HTMLDivElement>) => {
        this.showToolbarForHoveredContentItem(toolbarContainerElRef.nativeElement, $event);
      });
    });
  }

  @HostListener('mouseleave')
  onMouseleave(): void {
    if (!this.isAdvancedModeOpen) {
      return;
    }

    this.cardAreaComponents.toArray().forEach((area: CardAreaComponent) => {
      area.contentItemToolbarContainers.toArray().forEach((toolbarContainerElRef: ElementRef<HTMLDivElement>) => {
        this.hideToolbar(toolbarContainerElRef.nativeElement);
      });
    });
  }

  private showToolbarForHoveredContentItem(toolbarContainer: HTMLElement, $event: MouseEvent): void {
    const isCursorOverToolbarContainer = this.isCursorOverElement(new Cursor($event), toolbarContainer.getBoundingClientRect());
    this.showOrHideToolbar(isCursorOverToolbarContainer, toolbarContainer);
  }

  private isCursorOverElement(cursor: Cursor, element: DOMRect): boolean {
    const isCursorWithinElementX = cursor.x >= element.left && cursor.x <= element.right;
    const isCursorWithinElementY = cursor.y >= element.top && cursor.y <= element.bottom;

    return isCursorWithinElementX && isCursorWithinElementY;
  }

  private showOrHideToolbar(isCursorOverToolbarContainer: boolean, toolbarContainer: Element): void {
    if (isCursorOverToolbarContainer) {
      this.showToolbar(toolbarContainer);
    } else {
      this.hideToolbar(toolbarContainer);
    }
  }

  private showToolbar(toolbarContainer: Element): void {
    this.renderer.addClass(toolbarContainer, CSS_CLASS);
  }

  private hideToolbar(toolbarContainer: Element): void {
    this.renderer.removeClass(toolbarContainer, CSS_CLASS);
  }
}
