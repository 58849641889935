import { AvrInputMappersType, AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';
import {
  DocumentGenerationAvrInputMapperDto,
  DocumentGenerationAvrType,
  DocumentGenerationOutputType,
} from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';

export class DocumentGenerationAvrService extends BaseAvrAbstractService<DocumentGenerationAvrType, DocumentGenerationAvrInputMapperDto> {
  constructor() {
    super(AvrTypes.documentGeneration as DocumentGenerationAvrType, DocumentGenerationAvrInputMapperDto, 'Document Generation', [
      DocumentGenerationOutputType.docx,
      DocumentGenerationOutputType.pptx,
      DocumentGenerationOutputType.pdf,
    ]);
  }

  fromDto(inputMapperDto: AvrInputMappersType[DocumentGenerationAvrType]): DocumentGenerationAvrInputMapperDto {
    // logic is part of HARDCODED BE WORKFLOW
    const avrMapper = super.fromDto(inputMapperDto);
    if (inputMapperDto.everythingWithinArtifactType === true && inputMapperDto.templates.length === 0) {
      avrMapper.nonMappableFields.templates!.value = [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })];
    }
    return avrMapper;
  }

  toServer(inputMapperDto: DocumentGenerationAvrInputMapperDto): AvrInputMappersType[DocumentGenerationAvrType] {
    // logic is part of HARDCODED BE WORKFLOW
    if (
      inputMapperDto.nonMappableFields.everythingWithinArtifactType!.value === true &&
      inputMapperDto.nonMappableFields.templates!.value.length === 1 &&
      inputMapperDto.nonMappableFields.templates!.value[0].fileArtifactId === ''
    ) {
      inputMapperDto.nonMappableFields.templates!.value = [];
    }
    return super.toServer(inputMapperDto);
  }
}
