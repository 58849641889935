import { Injectable } from '@angular/core';
import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TemplatesComponent } from '@shared/components/templates/templates.component';
import { Template, TemplatesCloseResult, TemplatesCloseResultRole, TemplateType } from '@shared/components/templates/types/templates.types';
import { ArtifactTypeDefaultTemplates, NewArtifactType } from '@shared/types/artifact-type.types';
import { cloneDeep } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private readonly cache: NewCacheService, private readonly dialogService: DialogService) {}

  async loadPageSectionTemplates(page: Page, sectionList?: PageSection[]): Promise<void> {
    const sections = sectionList ? sectionList : page.getSectionTemplatesToLoad();

    if (!sections.length) {
      return;
    }

    const ids: string[] = sections.map(section => section.templateId).filter(Boolean) as string[];
    const templates = await this.cache.data.templates.getManyAsync(ids);

    page.templates = templates.reduce((acc: Record<string, Template>, dto: TemplateResponseDto) => {
      acc[dto.id] = Template.fromDto(dto);
      sections.forEach((section: PageSection) => {
        if (section.templateId === dto.id) {
          section.setTemplateDto(cloneDeep(dto));
        }
      });

      return acc;
    }, {});
  }

  async loadSidebarModalPageSectionTemplates(page: Page): Promise<void> {
    const list = page.getSidebarModalPages();
    const requests: Promise<any>[] = [];

    list.forEach(page => {
      requests.push(
        this.loadPageSectionTemplates(
          page,
          page.sections.filter((section: PageSection) => section.isTemplate || section.templateId),
        ),
      );
    });
    await Promise.all(requests);

    page.updateSidebarModal(list);
  }

  async loadWidgetTemplates(widgets: BlockPartWidget[]): Promise<void> {
    if (!widgets.length) {
      return;
    }

    const ids: string[] = widgets.map(section => section.templateId).filter(Boolean) as string[];
    const templates = await this.cache.data.templates.getManyAsync(ids);

    templates.forEach((dto: TemplateResponseDto) => {
      widgets
        .filter(({ templateId }: BlockPartWidget) => templateId === dto.id)
        .forEach((widget: BlockPartWidget) => {
          widget.id = (dto.template as PageBlockPartWidgetResponseDto).widgetId;
          widget.templateName = dto.name;
        });
    });
  }

  async pickTemplate(templateType = TemplateType.widget): Promise<TemplatesCloseResult> {
    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(TemplatesComponent, {
        showHeader: false,
        width: '95vw',
        height: '100%',
        style: {
          boxShadow: 'none',
          justifyContent: 'center',
        },

        contentStyle: {
          /** @description
           * Temporary solution to create a custom header for DynamicDialog.
           * Look at the issue https://github.com/primefaces/primeng/issues/9235
           */
          position: 'relative',
          paddingTop: '70px',
        },
        data: {
          templateType,
        },
      });

      dialogRef.onClose
        .pipe(
          take(1),
          filter((close: TemplatesCloseResult) => close.role !== TemplatesCloseResultRole.close),
          tap((result: TemplatesCloseResult) => {
            resolve(result);
          }),
        )
        .subscribe();
    });
  }

  async getDefaultArtifactTypeTemplates(artifactType: NewArtifactType): Promise<ArtifactTypeDefaultTemplates> {
    const { artifactWidgetTemplateId, cardWidgetTemplateId } = artifactType.defaultWidgets;
    const artifactWidgetTemplate = artifactWidgetTemplateId ? Template.fromDto(await this.cache.data.templates.getAsync(artifactWidgetTemplateId)) : undefined;
    const cardWidgetTemplate = cardWidgetTemplateId ? Template.fromDto(await this.cache.data.templates.getAsync(cardWidgetTemplateId)) : undefined;

    return new ArtifactTypeDefaultTemplates({ artifactWidgetTemplate, cardWidgetTemplate });
  }
}
