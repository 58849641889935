import { JobsResponseDto } from '@api/models';
import { TableColumn } from '@shared/types/table.types';

export enum JobsType {
  mail = 'MAIL',
  thumbnail = 'THUMBNAIL',
  avrGeneration = 'AVR-GENERATION',
  import = 'IMPORT',
  scheduleWorkflow = 'SCHEDULE-WORKFLOW',
  saveSeoPage = 'SAVE-SEO-PAGE',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jobTypeCheck: [JobsResponseDto['type']] extends [`${JobsType}`] ? (`${JobsType}` extends JobsResponseDto['type'] ? true : false) : false = true;

export enum JobsStatus {
  pending = 'pending',
  processing = 'processing',
  failed = 'failed',
  done = 'done',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jobStatusCheck: [JobsResponseDto['status']] extends [`${JobsStatus}`] ? (`${JobsStatus}` extends JobsResponseDto['status'] ? true : false) : false = true;

export class JobsPageModel {
  columns: TableColumn[] = [];
  detailJob: JobsResponseDto | null = null;
}
