import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';

export class ListWidgetLinkSettingsTypes {
  isLinkPopup = false;
  isMultiselect: boolean;
  applicationId: string;
  linkCombination: LinkCombinationSettings;
}

export interface LinkCombinationSettings {
  linkTypeId?: string;
  linkDirection?: LinkDirection;
  sourceArtifactTypeId?: string;
  targetArtifactTypeId?: string;
  disabledArtifactIds?: string[];
  widgetId?: string;
}
