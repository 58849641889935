import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-button-section',
  templateUrl: './add-button-section.component.html',
  styleUrls: ['./add-button-section.component.scss'],
})
export class AddButtonSectionComponent {
  @Input() toggleLayoutToolbar: (event?: MouseEvent) => void;
}
