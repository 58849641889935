import { Component } from '@angular/core';
import { AbstractAvrRenderFileTypeComponent } from '../abstract.avr-render-file-type.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrBlobType } from '@widgets/avr-widget/types/avr-widget.types';

@Component({
  selector: 'app-avr-widget-pdf-render-file-type',
  templateUrl: './pdf.avr-render-file-type.component.html',
  styleUrls: ['./pdf.avr-render-file-type.component.scss'],
})
export class AvrPdfRenderFileTypeComponent extends AbstractAvrRenderFileTypeComponent {
  pdfUrl: SafeResourceUrl | null = null;
  avrTypes = AvrTypes;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  onSetFile(file: AvrBlobType | null): void {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${reader.result}#toolbar=0&navpanes=0`);
      };
      reader.readAsDataURL(file.file);
    } else {
      this.pdfUrl = null;
    }
  }
}
