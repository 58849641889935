import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PageBuilderSchematicService } from './services/page-builder-schematic.service';
import { PageBuilderModel } from './types/page-builder-schematic.types';

@Component({
  selector: 'app-page-builder-schematic',
  templateUrl: './page-builder-schematic.component.html',
  styleUrls: ['./page-builder-schematic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PageBuilderSchematicService],
})
export class PageBuilderSchematicComponent extends CoreComponent<PageBuilderSchematicService, PageBuilderModel> {
  @ViewChild('layoutToolbar')
  public layoutToolbar: OverlayPanel;

  constructor(route: ActivatedRoute, router: Router, translate: TranslateService, service: PageBuilderSchematicService, announcement: AnnouncementService) {
    super(route, router, translate, new PageBuilderModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.m.canvasList, event.previousIndex, event.currentIndex);
  }

  toggleLayoutToolbar(event: Event): void {
    this.layoutToolbar.toggle(event);
  }

  generateButtonDisabled(): boolean {
    const input: HTMLInputElement = document.getElementById('custom') as HTMLInputElement;
    return !(input.value.split('+').reduce((buffer: number, current: string) => (buffer += +current), 0) % 12 === 0);
  }
}
