import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { ContentType } from './content-type';

export function IsCardContentItemAttribute(item: Partial<CardWidgetAreaContentItem>): boolean {
  return item.type === ContentType.attribute;
}

export function IsCardContentItemLink(item: Partial<CardWidgetAreaContentItem>): boolean {
  return item.type === ContentType.link;
}

export function IsCardContentItemWidget(item: Partial<CardWidgetAreaContentItem>): boolean {
  return item.type === ContentType.widget;
}

export class CardWidgetAreaContentItem {
  constructor(public type: ContentType, public content: string | { id: string } | BlockPartWidget, public linkDirection?: LinkDirection) {}
}
