/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApexChartWidgetTypeResponseDto } from '../models/apex-chart-widget-type-response-dto';
import { ArtifactWidgetTypeResponseDto } from '../models/artifact-widget-type-response-dto';
import { AuthWidgetTypeResponseDto } from '../models/auth-widget-type-response-dto';
import { AvrWidgetTypeResponseDto } from '../models/avr-widget-type-response-dto';
import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { CardWidgetTypeResponseDto } from '../models/card-widget-type-response-dto';
import { ChartWidgetTypeResponseDto } from '../models/chart-widget-type-response-dto';
import { FilterWidgetTypeResponseDto } from '../models/filter-widget-type-response-dto';
import { FolderWidgetTypeResponseDto } from '../models/folder-widget-type-response-dto';
import { ListMatrixWidgetTypeResponseDto } from '../models/list-matrix-widget-type-response-dto';
import { ListWidgetTypeResponseDto } from '../models/list-widget-type-response-dto';
import { MenuWidgetTypeResponseDto } from '../models/menu-widget-type-response-dto';
import { MetaListResponseDto } from '../models/meta-list-response-dto';
import { NumberWidgetTypeResponseDto } from '../models/number-widget-type-response-dto';
import { PictureWidgetTypeResponseDto } from '../models/picture-widget-type-response-dto';
import { SidebarModalWidgetTypeResponseDto } from '../models/sidebar-modal-widget-type-response-dto';
import { SidebarWidgetTypeResponseDto } from '../models/sidebar-widget-type-response-dto';
import { TagWidgetTypeResponseDto } from '../models/tag-widget-type-response-dto';
import { TextWidgetTypeResponseDto } from '../models/text-widget-type-response-dto';
import { widgetControllerCount } from '../fn/tenant-widget/widget-controller-count';
import { WidgetControllerCount$Params } from '../fn/tenant-widget/widget-controller-count';
import { widgetControllerCreate } from '../fn/tenant-widget/widget-controller-create';
import { WidgetControllerCreate$Params } from '../fn/tenant-widget/widget-controller-create';
import { widgetControllerDelete } from '../fn/tenant-widget/widget-controller-delete';
import { WidgetControllerDelete$Params } from '../fn/tenant-widget/widget-controller-delete';
import { widgetControllerGet } from '../fn/tenant-widget/widget-controller-get';
import { WidgetControllerGet$Params } from '../fn/tenant-widget/widget-controller-get';
import { widgetControllerList } from '../fn/tenant-widget/widget-controller-list';
import { WidgetControllerList$Params } from '../fn/tenant-widget/widget-controller-list';
import { widgetControllerUpdate } from '../fn/tenant-widget/widget-controller-update';
import { WidgetControllerUpdate$Params } from '../fn/tenant-widget/widget-controller-update';

@Injectable({ providedIn: 'root' })
export class TenantWidgetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `widgetControllerCount()` */
  static readonly WidgetControllerCountPath = '/api/tenant/widget/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerCount$Response(params?: WidgetControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return widgetControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerCount(params?: WidgetControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.widgetControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `widgetControllerGet()` */
  static readonly WidgetControllerGetPath = '/api/tenant/widget/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerGet$Response(params: WidgetControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
    return widgetControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerGet(params: WidgetControllerGet$Params, context?: HttpContext): Observable<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)> {
    return this.widgetControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>): (TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto) => r.body)
    );
  }

  /** Path part for operation `widgetControllerDelete()` */
  static readonly WidgetControllerDeletePath = '/api/tenant/widget/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerDelete$Response(params: WidgetControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
    return widgetControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerDelete(params: WidgetControllerDelete$Params, context?: HttpContext): Observable<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)> {
    return this.widgetControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>): (TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto) => r.body)
    );
  }

  /** Path part for operation `widgetControllerList()` */
  static readonly WidgetControllerListPath = '/api/tenant/widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerList$Response(params?: WidgetControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data': Array<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
'meta': MetaListResponseDto;
}>> {
    return widgetControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  widgetControllerList(params?: WidgetControllerList$Params, context?: HttpContext): Observable<{
'data': Array<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
'meta': MetaListResponseDto;
}> {
    return this.widgetControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
'meta': MetaListResponseDto;
}>): {
'data': Array<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
'meta': MetaListResponseDto;
} => r.body)
    );
  }

  /** Path part for operation `widgetControllerUpdate()` */
  static readonly WidgetControllerUpdatePath = '/api/tenant/widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  widgetControllerUpdate$Response(params: WidgetControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
    return widgetControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  widgetControllerUpdate(params: WidgetControllerUpdate$Params, context?: HttpContext): Observable<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)> {
    return this.widgetControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>): (TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto) => r.body)
    );
  }

  /** Path part for operation `widgetControllerCreate()` */
  static readonly WidgetControllerCreatePath = '/api/tenant/widget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `widgetControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  widgetControllerCreate$Response(params: WidgetControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
    return widgetControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `widgetControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  widgetControllerCreate(params: WidgetControllerCreate$Params, context?: HttpContext): Observable<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)> {
    return this.widgetControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>): (TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto) => r.body)
    );
  }

}
