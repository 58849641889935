import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';

export class LinkDialogOpenArguments {
  originalArtifact: NewArtifact | null = null;
  linkTypeId: string;
  linkDirection: LinkDirection;
  restriction: ArtifactTypeLinkRestriction | null = null;
  successCb?: (artifacts?: NewArtifact[]) => void | Promise<void>;
  boilerplateCb?: (artifacts: NewArtifact[]) => void | Promise<void>;

  constructor(args: LinkDialogOpenArguments) {
    Object.assign(this, args);
  }
}
