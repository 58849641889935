import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';

@Component({
  standalone: true,
  imports: [TranslateModule, TableModule, InputTextModule, ButtonModule],
  selector: 'app-attributes-selector',
  templateUrl: './attributes-selector.component.html',
  styleUrls: ['./attributes-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesSelectorComponent {
  @Input() attributes: BulkEditingAttribute[] = [];
  @Input() selectedAttributes: BulkEditingAttribute[] = [];
  @Output() selectedAttributesChange: EventEmitter<BulkEditingAttribute[]> = new EventEmitter<BulkEditingAttribute[]>();

  clear(table: Table, searchField: HTMLInputElement): void {
    table.clear();
    searchField.value = '';
  }
}
