{{ 'WORKFLOWS.COMMON.FROM' | translate }}
<app-workflow-attribute-selector
  [selectedAttributeId]="action.actionSettings[RuleKeys.copyFromAttributeId].value"
  [attributes]="selectedAttributes"
  (attributeChange)="onCopyFromAttributeChange($event)"
  [editable]="true"
></app-workflow-attribute-selector>

<div *ngIf="this.action.actionSettings[RuleKeys.copyFromAttributeId].value" class="inline-block">
  {{ 'WORKFLOWS.COMMON.TO' | translate }}
  <app-workflow-attribute-selector
    [selectedAttributeId]="action.attributeId"
    [attributes]="setToAttributeOptions"
    (attributeChange)="onSetToAttributeChange($event)"
    [editable]="true"
  ></app-workflow-attribute-selector>
</div>

<div *ngIf="showOperations" class="inline-block padded">
  <p-dropdown
    *ngIf="action.actionSettings[RuleKeys.operation]"
    [(ngModel)]="action.actionSettings[RuleKeys.operation].value"
    [options]="operations"
    class="workflow-element-dropdown"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    placeholder="{{ 'WORKFLOWS.PLACEHOLDER.MODIFY_VALUE_OPERATION' | translate}}"
    appendTo="body"
  ></p-dropdown>
</div>
