<div class="grid">
  <div class="col-12 md:col-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <span class="pi pi-envelope"></span>
      </span>
      <span class="dynamic-label">
        <input [(ngModel)]="m.user.email" autocomplete="off" id="email" pInputText type="text" />
        <label class="dynamic-label-text" for="email">{{ EMAIL_LABEL | translate }}</label>
      </span>
    </div>
  </div>

  <ng-container *ngIf="m.loggedUserMeta?.isTenantAdmin">
    <!-- <div class="col-12 md:col-3">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <span class="pi pi-user-edit"></span>
        </span>
        <span class="dynamic-label">
          <input [(ngModel)]="m.user.name" id="name" pInputText type="text" />
          <label class="dynamic-label-text" for="name">{{ 'Name' | translate }}</label>
        </span>
      </div>
    </div> -->

    <div class="col-12 md:col-3">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="m.user.isTenantAdmin" binary="true" id="isTenantAdmin"></p-checkbox>
          <label for="isTenantAdmin">{{ IS_TENANT_ADMIN_LABEL | translate }}</label>
        </span>
      </span>
    </div>

    <div class="col-12 md:col-6"></div>

    <div *ngIf="!m.generatePassword" class="col-12 md:col-3">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <span class="pi pi-key"></span>
        </span>
        <span class="dynamic-label">
          <input [(ngModel)]="m.user.password" autocomplete="new-password" feedback id="password" pPassword type="password" />
          <label class="dynamic-label-text" for="password">{{ 'Password' | translate }}</label>
        </span>
      </div>
    </div>

    <div *ngIf="!m.generatePassword" class="col-12 md:col-3">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <span class="pi pi-key"></span>
        </span>
        <span class="dynamic-label">
          <input [(ngModel)]="m.user.confirmPassword" autocomplete="new-password" id="confirmPassword" pPassword type="password" />
          <label class="dynamic-label-text" for="confirmPassword">{{ 'Confirm Password' | translate }}</label>
        </span>
      </div>
    </div>
  </ng-container>
</div>
