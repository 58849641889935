import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NamedGridArea } from '@shared/components/grid-layout-generator/types/named-grid-area';
import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { NAME_LABEL } from '@shared/constants/constants';

@Component({
  selector: 'app-grid-areas-generator',
  templateUrl: './grid-areas-generator.component.html',
  styleUrls: ['./grid-areas-generator.component.scss'],
})
export class GridAreasGeneratorComponent {
  @Input() areas: NamedGridArea[];
  @Input() isAddingDisabled: boolean;
  @Input() breakpoint: StyleApplicationBreakpoint;
  @Input() visibilityToggleDisabled = false;

  @Output() addArea: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteArea: EventEmitter<number> = new EventEmitter<number>();

  NAME_LABEL = NAME_LABEL;

  add(): void {
    this.addArea.emit();
  }

  delete(areaIndex: number): void {
    this.deleteArea.emit(areaIndex);
  }

  toggleAreaVisibility(area: NamedGridArea): void {
    area.visible.set(this.breakpoint, !area.visible.get(this.breakpoint));
  }

  updateStyleValue(styleValue: number, rangedStyleValue: RangedStyleValue<number>): void {
    rangedStyleValue.set(this.breakpoint, styleValue);
  }
}
