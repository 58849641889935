<p-accordion (onClose)="reset()" (onOpen)="reset()">
  <p-accordionTab [header]="'Area styles' | translate">
    <div class="grid">
      <div class="col-12">
        <p-dropdown
          [(ngModel)]="selectedArea"
          [autoDisplayFirst]="false"
          [filter]="true"
          [options]="areas"
          [placeholder]="'Choose card area' | translate"
          appendTo="body"
        >
          <ng-template let-area pTemplate="item">{{ area.name }}</ng-template>
          <ng-template let-selectedArea pTemplate="selectedItem">{{ selectedArea.name }}</ng-template>
        </p-dropdown>
      </div>

      <ng-container *ngIf="selectedArea">
        <div class="col-12 pb-0">
          <app-page-element-styler [isMarginEditable]="false"
                                   [styles]="selectedArea.content.styles"></app-page-element-styler>
        </div>

        <div class="col-4">
          <h6>{{ 'Container direction' | translate }}</h6>
        </div>
        <div class="col-8">
          <button
            icon="bi bi-text-left"
            label="{{ 'Direction' | translate }}"
            pButton
            (click)="containerDirectionOverlayPanel.toggle($event)">
          </button>

          <p-overlayPanel #containerDirectionOverlayPanel [style]="{ 'max-width': '300px' }" appendTo="body">
            <ng-template pTemplate>
              <app-flex-direction-form [styles]="selectedArea.content.styles"></app-flex-direction-form>
            </ng-template>
          </p-overlayPanel>
        </div>

        <div class="col-4">
          <h6>{{ 'Content alignment' | translate }}</h6>
        </div>
        <div class="col-8">
          <button (click)="contentAlignmentOverlayPanel.toggle($event)" icon="bi bi-text-left"
                  label="{{ 'Alignment' | translate }}" pButton></button>

          <p-overlayPanel #contentAlignmentOverlayPanel [style]="{ 'max-width': '300px' }" appendTo="body">
            <ng-template pTemplate>
              <app-flex-content-alignment-form [styles]="selectedArea.content.styles"></app-flex-content-alignment-form>
            </ng-template>
          </p-overlayPanel>
        </div>

        <div class="col-4">
          <h6>{{ 'Text display' | translate }}</h6>
        </div>
        <div class="col-8">
          <p-radioButton
            (onClick)="onWrapClick()"
            [(ngModel)]="selectedArea.content.textDisplay"
            [label]="textDisplay.wrap"
            [value]="textDisplay.wrap"
            class="mr-2"
            name="textDisplay"
          >
          </p-radioButton>
          <p-radioButton
            (onClick)="onEllipsisClick()"
            [(ngModel)]="selectedArea.content.textDisplay"
            [label]="textDisplay.ellipsis"
            [value]="textDisplay.ellipsis"
            class="mr-2"
            name="textDisplay"
          >
          </p-radioButton>
          <p-radioButton
            (onClick)="onScrollClick()"
            [(ngModel)]="selectedArea.content.textDisplay"
            [label]="textDisplay.scroll"
            [value]="textDisplay.scroll"
            name="textDisplay"
          >
          </p-radioButton>
        </div>
      </ng-container>
    </div>
  </p-accordionTab>

  <p-accordionTab [(selected)]="tabState.attributeStyles" [header]="'Attribute styles' | translate">
    <div class="grid">
      <div class="col-12">
        <p-dropdown
          (onChange)="onAttributeChange()"
          [(ngModel)]="selectedAttribute"
          [autoDisplayFirst]="false"
          [filter]="true"
          [options]="usedAttributeOptions"
          [placeholder]="'Choose attribute' | translate"
          appendTo="body"
        >
          <ng-template let-item pTemplate="item">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
          </ng-template>
          <ng-template let-selected pTemplate="selectedItem">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: selected }"></ng-container>
          </ng-template>

          <ng-template #itemTemplate let-item>
            <app-link-option-with-arrow *ngIf="item.meta; else nonLinkAttributeTemplate" [direction]="item.meta"
                                        [label]="item.label" class="link-option">
            </app-link-option-with-arrow>
            <ng-template #nonLinkAttributeTemplate>{{ item.label }}</ng-template>
          </ng-template>
        </p-dropdown>
      </div>

      <div *ngIf="selectedAttribute && attributeSettings[selectedAttribute.id]" class="col-12">
        <p-accordion>
          <p-accordionTab [header]="'Label styles' | translate">
            <app-attribute-format-label-styles-form
              [labelFormatSettings]="attributeSettings[selectedAttribute.id].settings.label"
              [enableVerticalAlignment]="false"
            >
            </app-attribute-format-label-styles-form>
          </p-accordionTab>

          <p-accordionTab [header]="'Value styles' | translate">
            <div class="grid">
              <div class="col-12">
                <app-attribute-format-value-styles-form
                  [areEditableStylesDisabled]="true"
                  [formatSettings]="attributeSettings[selectedAttribute.id].settings"
                  [showPrefixSuffixSettings]="(dataType?.baseDataType | isDecimal) || (dataType?.baseDataType | isInteger)"
                  [showDecimalPlacesSettings]="dataType?.baseDataType | isDecimal"
                >
                </app-attribute-format-value-styles-form>
              </div>
            </div>
          </p-accordionTab>

          <p-accordionTab [header]="'Shared styles (label and value)' | translate">
            <app-artifact-widget-item-format-settings
              [formatSettings]="attributeSettings[selectedAttribute.id].settings">
            </app-artifact-widget-item-format-settings>
          </p-accordionTab>

          <p-accordionTab
            *ngIf="(dataType?.baseDataType | isDate)
                   || (dataType?.baseDataType | isBoolean)
                   || (dataType?.baseDataType | isDateTime)
                   || (dataType?.baseDataType | isUser)
                   || (dataType?.baseDataType | isHyperlink)
                   || (dataType?.kind | isEnumerated)
                   || (selectedAttribute.id | isSystemDate)
                   || (selectedAttribute.id | isSingleFileAttribute : options)
                   || (selectedAttribute.id | isSystemUser)"
            [header]="'Display variant' | translate"
          >
            <p-dropdown
              [(ngModel)]="attributeSettings[selectedAttribute.id].settings.value.displayMetadata.selectedVariantCode"
              [autoDisplayFirst]="false"
              [options]="attributeDisplayVariantOptions"
              (onChange)="onDisplayVariantChange()"
              id="displayVariant"
            >
              <ng-template let-item pTemplate="item">
                <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
              </ng-template>
              <ng-template let-item pTemplate="selectedItem">
                <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
              </ng-template>

              <ng-template #displayVariant let-item>
                <div>{{ item.label | translate }}</div>
              </ng-template>
            </p-dropdown>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
