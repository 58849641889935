<div class="row pl-3 mt-1">
  <div class="col-2">
    {{ 'Filter' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.filter].isDynamic" id="isDynamicFilter" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea [(ngModel)]="action.actionSettings[RuleKeys.filter].value" [placeholder]="'Enter filter' | translate" rows="3"
              pInputTextarea class="input-field" id="filterInput"></textarea>
  </div>
</div>

<div class="row pl-3">
  <div class="col-2">
    {{ 'Sort' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.sort].isDynamic" id="isDynamicSort" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea [(ngModel)]="action.actionSettings[RuleKeys.sort].value" [placeholder]="'Enter sort' | translate" rows="3"
              pInputTextarea class="input-field" id="sortInput"></textarea>
  </div>
</div>

<div class="row pl-3">
  <div class="col-2">
    {{ 'Scope path to store' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].isDynamic" id="isDynamicScope" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].value" [placeholder]="'Enter scope path to store' | translate" pInputText
           class="input-field" id="scopeInput" />
  </div>
</div>

<div class="row pl-3">
  <div class="col-4">
    {{ 'User' | translate }}
  </div>
  <div class="col-8">
    <div class="input-field">
      <p-dropdown [options]="users.list | transformArrayToSelectOptions : EMAIL_VALUE : ID_VALUE"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [showClear]="true"
                  [(ngModel)]="action.actionSettings[RuleKeys.userId].value"
                  id="userInput" optionLabel="label" optionValue="value" appendTo="body" class="user-field">
      </p-dropdown>
    </div>
  </div>
</div>

