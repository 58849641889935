import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';

export class SystemUserPageModel {
  inProgress = false;

  user = new NewSystemUser();
  password: string;
  confirmPassword: string;
  generatePassword = false;
  userProfileUrl: null | { routerLink: string; queryParams: any } = null;
  loggedUser: SelfUserResponseDto;

  tenantOptions: SelectOption<string, string>[] = [];
  tenantOptionsWithoutDeleted: SelectOption<string, string>[] = [];
  selectedTenantId: string;
  isTenantAdmin = false;

  constructor(model?: Partial<SystemUserPageModel>) {
    model && Object.assign(this, model);
  }
}
