<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div *ngIf="m.attribute">
      <div class="p-inputgroup">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox id="isDeleted" binary="true" inputId="isDeleted" [(ngModel)]="m.isDeleted"
                        [disabled]="true"></p-checkbox>
            <label for="isDeleted">{{'Is Deleted' | translate}}</label>
          </span>
        </span>
      </div>
    </div>
    <div>
      <button *ngIf="m.attribute && m.attribute.id && !m.attribute.deleted"
              class="p-button p-button-danger" pButton
              [label]="'Delete' | translate"
              (click)="s.deleteWithConfirmation(m.attribute)">
      </button>
      <button pButton pRipple type="button" label="{{'Cancel' | translate}}" class="p-button p-button-warning"
              (click)="service.cancel()"></button>
      <button pButton pRipple type="button" label="{{'Save' | translate}}" class="p-button p-button-success"
              (click)="service.save()"></button>
    </div>
  </div>
  <div class="col-12 attribute-form">
    <app-attribute-form [dataTypes]="m.dataTypes"
                        [attribute]="m.attribute"
                        [applications]="m.applications">
    </app-attribute-form>
  </div>
</div>
