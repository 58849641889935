import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTypeFormService } from '@private/pages/artifact-type-management/data-type/components/data-type-form/services/data-type-form.service';
import {
  BaseDataTypeBoundedRange,
  BaseDataTypeCounter,
  BaseDataTypeEnumerated,
  BaseDataTypeSimple,
  DataTypeKind,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewApplication } from '@shared/types/application.types';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-data-type-form',
  templateUrl: './data-type-form.component.html',
  styleUrls: ['./data-type-form.component.scss'],
})
export class DataTypeFormComponent implements OnInit {
  @Input()
  baseDataTypeOptions:
    | SelectOption<BaseDataTypeSimple, BaseDataTypeSimple>[]
    | SelectOption<BaseDataTypeEnumerated, BaseDataTypeEnumerated>[]
    | SelectOption<BaseDataTypeBoundedRange, BaseDataTypeBoundedRange>[]
    | SelectOption<BaseDataTypeCounter, BaseDataTypeCounter>[] = [];
  @Input() dataType: NewDataType;
  @Input() onKindChange: (kind: DataTypeKind) => void;
  @Input() onBaseDataTypeChange: () => void;
  @Input() applications: NewApplication[];

  kindOptions: DataTypeKind[] = [];

  constructor(public s: DataTypeFormService, public router: Router) {}

  ngOnInit(): void {
    this.kindOptions = Object.values(DataTypeKind);
  }

  public async cancel(): Promise<void> {
    await this.router.navigateByUrl('/');
  }
}
