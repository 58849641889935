import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewTableColumn, TableFilterUrlTypeService } from '@shared/types/table.types';
import { FilterMetadata, FilterOperator } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlNumberService implements TableFilterUrlTypeService {
  betweenMatchModes = [
    CoreListFilterEnum.lessThan,
    CoreListFilterEnum.lessThanOrEqualTo,
    CoreListFilterEnum.greaterThan,
    CoreListFilterEnum.greaterThanOrEqualTo,
  ];

  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void {
    const processedFilterValue = this.getUrlQueryAttributeValue(filterValue);
    columnFilterRef.dt.filters[column.key] = this.getFilterMetadata(processedFilterValue);
    columnFilterRef.applyFilter();
  }

  getFilterMetadata(filterValue: string[]): FilterMetadata[] {
    return this.isBetweenMatchMode(filterValue) ? this.getFilterMetadataForBetweenMatchMode(filterValue) : this.getClassicFilterMetadata(filterValue);
  }

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string[] {
    return rawQueryAttributeValue?.split(',') || [];
  }

  private isBetweenMatchMode(filterValue: string[]): boolean {
    return (
      filterValue.length === 4 &&
      this.betweenMatchModes.includes(filterValue[0] as CoreListFilterEnum) &&
      this.betweenMatchModes.includes(filterValue[2] as CoreListFilterEnum)
    );
  }

  private getFilterMetadataForBetweenMatchMode(filterValue: string[]): FilterMetadata[] {
    return [
      {
        matchMode: filterValue[0],
        operator: FilterOperator.AND,
        value: filterValue[1],
      },
      {
        matchMode: filterValue[2],
        operator: FilterOperator.AND,
        value: filterValue[3],
      },
    ];
  }

  private getClassicFilterMetadata(filterValue: string[]): FilterMetadata[] {
    return [
      {
        matchMode: filterValue[0],
        operator: FilterOperator.AND,
        value: filterValue[1],
      },
    ];
  }
}
