import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { LinkMethods } from '@shared/methods/link.methods';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getLinkQueryParams' })
export class GetLinkQueryParamsPipe implements PipeTransform {
  transform(link: NewLink, linkType: SelectOption<string, LinkType>): Params {
    const id = LinkMethods.isOutgoing(linkType.meta) ? link.destinationArtifactId : link.sourceArtifactId;
    return { artifactId: id };
  }
}
