import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTypeValueResponseDto } from '@api/models';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule, DropdownModule, ArtifactAttributeFormFieldLabelWithIconComponent],
  selector: 'app-artifact-attribute-form-field-enumerated',
  templateUrl: './artifact-attribute-form-field-enumerated.component.html',
  styleUrls: ['./artifact-attribute-form-field-enumerated.component.scss'],
})
export class ArtifactAttributeFormFieldEnumeratedComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() options: DataTypeValueResponseDto[];
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<void>();
  @Output() fieldBlur: EventEmitter<void> = new EventEmitter<void>();

  labelBehaviourEnum = LabelBehaviourEnum;
}
