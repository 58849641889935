import { Pipe, PipeTransform } from '@angular/core';
import { NewApplication } from '@shared/types/application.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getAttributeLabelWithApplication' })
export class GetAttributeLabelWithApplicationPipe implements PipeTransform {
  transform(option: SelectOption<any, NewAttribute>, applications: ListContainer<NewApplication>): unknown {
    return `${option.label} (${applications.listMap[option.value.applicationId]?.name})`;
  }
}
