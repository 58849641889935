import { Injectable, QueryList } from '@angular/core';
import { BlockLocation } from '@private/pages/page-management/page-builder-graphical/types/block-location';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { StickyBlockPartDirective } from '@shared/components/page/directives/sticky-block-part.directive';
import { StickySectionDirective } from '@shared/components/page/directives/sticky-section.directive';

const topLayerIndex = 499;

@Injectable()
export class StickyPageElementService {
  page: Page;
  sectionDirectives: QueryList<StickySectionDirective>;
  partDirectives: QueryList<StickyBlockPartDirective>;

  onResize(): void {
    this.setStickinessPositions();
    this.setStickySectionLayers();
  }

  private setStickinessPositions(): void {
    this.sectionDirectives.forEach((sectionDirective: StickySectionDirective, index: number) => {
      this.setSectionPositionIfSticky(sectionDirective, index);

      this.partDirectives.forEach((partDirective: StickyBlockPartDirective) => {
        if (partDirective.sectionIndex === sectionDirective.sectionIndex) {
          this.setBlockPartPositionIfSticky(partDirective);
        }
      });
    });
  }

  private getSiblingStickyPartDirectives(blockLocation: BlockLocation): StickyBlockPartDirective[] {
    return this.partDirectives.filter(({ sectionIndex, rowIndex, blockIndex }: StickyBlockPartDirective) => {
      return sectionIndex === blockLocation.sectionIndex && rowIndex === blockLocation.rowIndex && blockIndex === blockLocation.blockIndex;
    });
  }

  private setSectionPositionIfSticky(sectionDirective: StickySectionDirective, index: number): void {
    if (!sectionDirective.isSticky) {
      return;
    }

    if (sectionDirective.isStickyToTop) {
      sectionDirective.positionTop = this.getSectionPositionTop(index);
    }

    if (sectionDirective.isStickyToBottom) {
      sectionDirective.positionBottom = this.getSectionPositionBottom(index);
    }
  }

  private setStickySectionLayers(): void {
    let stickyToTopSectionsIndex = topLayerIndex;

    this.sectionDirectives
      .filter(({ isStickyToTop }: StickySectionDirective) => isStickyToTop)
      .forEach((sectionDirective: StickySectionDirective) => (sectionDirective.zIndex = stickyToTopSectionsIndex--));

    this.sectionDirectives
      .filter(({ isStickyToBottom }: StickySectionDirective) => isStickyToBottom)
      .reverse()
      .forEach((sectionDirective: StickySectionDirective) => (sectionDirective.zIndex = stickyToTopSectionsIndex-- - 1));
  }

  private setBlockPartPositionIfSticky(partDirective: StickyBlockPartDirective): void {
    if (!partDirective.isSticky) {
      return;
    }

    const siblingStickyPartDirectives = this.getSiblingStickyPartDirectives(partDirective);

    if (partDirective.isStickyToTop) {
      partDirective.positionTop = this.getStickyPartPositionTop(partDirective, siblingStickyPartDirectives);
    }

    if (partDirective.isStickyToBottom) {
      partDirective.positionBottom = this.getStickyPartPositionBottom(partDirective, siblingStickyPartDirectives);
    }
  }

  private getStickyPartPositionTop(partDirective: StickyBlockPartDirective, siblingStickyDirectives: StickyBlockPartDirective[]): number {
    const sectionPositionTop = this.getSectionPositionTop(partDirective.sectionIndex);
    const heightOfStickyPartsAbove = siblingStickyDirectives
      .filter(({ isStickyToTop, partIndex }: StickyBlockPartDirective) => isStickyToTop && partIndex < partDirective.partIndex)
      .reduce((height: number, { offsetHeight = 0 }: StickyBlockPartDirective) => height + (offsetHeight || 0), 0);

    return sectionPositionTop + heightOfStickyPartsAbove;
  }

  private getStickyPartPositionBottom(partDirective: StickyBlockPartDirective, siblingStickyDirectives: StickyBlockPartDirective[]): number {
    const sectionPositionBottom = this.getSectionPositionBottom(partDirective.sectionIndex);
    const belowPartsOffset = siblingStickyDirectives
      .filter(({ isStickyToBottom, partIndex }: StickyBlockPartDirective) => isStickyToBottom && partIndex > partDirective.partIndex)
      .reduce((height: number, { offsetHeight = 0 }: StickyBlockPartDirective) => height + offsetHeight, 0);

    return sectionPositionBottom + belowPartsOffset;
  }

  private getSectionPositionTop(targetSectionIndex: number): number {
    return this.sectionDirectives
      .toArray()
      .slice(0, targetSectionIndex)
      .reduce((offset: number, { offsetHeight }: StickySectionDirective, index: number, aboveSections: StickySectionDirective[]) => {
        return offset + (aboveSections[index].isStickyToTop ? offsetHeight : 0);
      }, 0);
  }

  private getSectionPositionBottom(targetSectionIndex: number): number {
    return this.sectionDirectives
      .toArray()
      .slice(targetSectionIndex + 1)
      .reduce((offset: number, { offsetHeight }: StickySectionDirective, index: number, belowSections: StickySectionDirective[]) => {
        return offset + (belowSections[index].isStickyToBottom ? offsetHeight : 0);
      }, 0);
  }
}
