import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextStyleHelper } from '@shared/helpers/text-style.helper';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { FontFamilyEnum, TextDecorationEnum, TextHorizontalAlignEnum } from '../../types/style.types';

@Component({
  selector: 'app-font-style-form',
  templateUrl: './font-style-form.component.html',
  styleUrls: ['./font-style-form.component.scss'],
})
export class FontStyleFormComponent extends TextStyleHelper {
  @Input() fontStyles: FormatStyles;
  @Input() hideFontFamilySettings: boolean;
  @Input() hideTextColorSetting: boolean;
  @Input() hideBackgroundColorSetting = true;
  @Input() hideFontSizeSetting: boolean;
  @Input() hideHorizontalAlignSettings: boolean;
  @Input() hidePrefixSuffix = true;
  @Input() hideDecimalPlacesSettings = true;
  @Input() hideEmphasisSettings = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  textHorizontalAlignOptions = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  fontFamilyOptions = GetSelectOptionsFromEnum(FontFamilyEnum);

  fontFamilyEnum = FontFamilyEnum;
  textDecorationEnum = TextDecorationEnum;

  prevStyles: string;

  isChange(): void {
    const styles = JSON.stringify(this.fontStyles);
    if (styles !== this.prevStyles) {
      this.prevStyles = styles;
      this.onChange.emit(this.fontStyles);
    }
  }

  onMinDecimalPlacesChange(value: any): void {
    if (value === null) this.fontStyles.minDecimalPlaces = undefined;
    this.isChange();
  }

  onMaxDecimalPlacesChange(value: any): void {
    if (value === null) this.fontStyles.maxDecimalPlaces = undefined;
    this.isChange();
  }

  onColorChange(value: string) {
    this.fontStyles.color = value;
    this.isChange();
  }

  onBackgroundColorChange(value: string) {
    this.fontStyles.backgroundColor = value;
    this.isChange();
  }
}
