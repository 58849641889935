import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { AvrWidgetTypeSettings } from '@api/models';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypesType, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  AbstractAvrQuerySettingsComponent,
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
  SettingAvrTypes,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrArtifactSources, AvrArtifactSourcesType } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';

@Component({
  selector: 'app-avr-widget-settings',
  templateUrl: './avr-widget-settings.component.html',
  styleUrls: ['./avr-widget-settings.component.scss'],
})
export class AvrWidgetSettingsComponent implements OnInit {
  @Input() m: AvrWidgetModel;

  public AvrArtifactSources = AvrArtifactSources;
  public settingAvrTypes: [SettingAvrTypes] | null = null;
  public injectorForAvrSettingFields: Injector | null = null;

  constructor(
    @Inject('AvrSettingsFields')
    public readonly avrSettingFields: { [K in SettingAvrTypes]: AbstractAvrQuerySettingsComponent<K, AbstractAvrTypeService<SettingAvrTypes>> },
    @Inject('AvrServices') public readonly avrServices: Record<string, BaseAvrAbstractService<AvrTypesType, BaseAvrInputMapperDto<AvrTypesType>>>,
    private readonly s: AvrWidgetService,
    private readonly injector: Injector,
  ) {
    this.settingAvrTypes = Object.keys(this.avrSettingFields) as [SettingAvrTypes];
  }

  ngOnInit(): void {
    this.injectorForAvrSettingFields = this.getAvrSettingFieldsInjector();
  }

  async onArtifactSourceChange(): Promise<void> {
    await this.s.onArtifactSourceChange();
  }

  async onArtifactTypeChange(): Promise<void> {
    await this.s.onArtifactTypeChange();
  }

  async onSelectedArtifactChange(): Promise<void> {
    await this.s.onSelectedArtifactChange();
  }

  async onArtifactListeningKeyChange(): Promise<void> {
    await this.s.onArtifactListeningKeyChange();
  }

  onAvrTypeChange(): void {
    this.s.onAvrTypeChange();
  }

  isArtifactSource(artifactSource: AvrArtifactSourcesType): boolean {
    return this.s.isArtifactSource(artifactSource);
  }

  isSettingStep(settingStep: number): boolean {
    return this.s.isSettingStep(settingStep);
  }

  private getAvrSettingFieldsInjector(value?: AvrWidgetTypeSettings): Injector {
    return Injector.create({
      providers: [
        {
          provide: AVR_SETTINGS_INJECTOR,
          useValue: value
            ? value
            : ({
                model: this.m,
                avrTypeServices: this.s.avrTypesServices,
              } as AvrSettingsInjectorBody),
        },
      ],
      parent: this.injector,
    });
  }
}
