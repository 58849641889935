<p-treeSelect
  (onNodeSelect)="onNodeSelect($event)"
  [(ngModel)]="selectedNode"
  [disabled]="disabled"
  [filterInputAutoFocus]="true"
  [filter]="true"
  [options]="(nodes$ | async)!"
  [placeholder]="placeholder"
  [propagateSelectionDown]="false"
  [propagateSelectionUp]="false"
  appendTo="body"
  class="w-full"
  containerStyleClass="w-full"
  filterMode="strict"
  scrollHeight="265px">
  <ng-template let-expanded pTemplate="itemtogglericon">
    <i [class]="expanded ? 'pi pi-minus-circle' : 'pi pi-plus-circle'"></i>
  </ng-template>

  <ng-template let-node let-placeholder="placeholder" pTemplate="value">
    <ng-container
      *ngTemplateOutlet="valueTemplate; context: { $implicit: node, placeholder: placeholder }"></ng-container>
  </ng-template>

  <ng-template #defaultValueTemplate let-node let-placeholder="placeholder">
    <span [ngClass]="[node?.styleClass || '', 'dropdown-value']">{{node ? node.longLabel : placeholder}}</span>
  </ng-template>
</p-treeSelect>
