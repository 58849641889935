import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AclItem, AclTypes } from '@widgets/shared/components/acl/types/acl.types';

@Component({
  selector: 'app-acl-item',
  templateUrl: './acl-item.component.html',
  styleUrls: ['./acl-item.component.scss'],
})
export class AclItemComponent {
  @Input() aclItem: AclItem[];
  @Input() aclType: AclTypes;
  @Input() header: string;
  @Output() openMembers = new EventEmitter<AclTypes>();
  @Output() removeItem = new EventEmitter<AclItem>();

  edit: boolean;

  toggleEdit(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
    this.edit = !this.edit;
  }

  remove(item: AclItem): void {
    this.removeItem.emit(item);
  }

  openPickUsers(): void {
    this.openMembers.emit(this.aclType);
  }
}
