<p-pickList [responsive]="true"
            dragdrop="true"
            sourceHeader="{{'Users' | translate}}"
            [sourceStyle]="{'height':'250px'}"
            targetHeader="{{'Team users' | translate}}"
            [targetStyle]="{'height':'250px'}"
            [showSourceControls]="false"
            [showTargetControls]="false"
            [source]="m.noTeamUsers"
            [target]="m.teamUsers">
    <ng-template let-user pTemplate="item">
        <div class="team-users-row">
            <label> {{user.email}} </label>
        </div>
    </ng-template>
</p-pickList>
