import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models';

@Pipe({ name: 'getDataTypeValueResponseById' })
export class GetDataTypeValueResponseByIdPipe implements PipeTransform {
  /**
   * Function returns matched data type as single item array if there is such item, empty array otherwise
   * @param dataTypeValues data type values
   * @param id id by which the search will be done
   * @returns data type value as single item array or empty array
   */
  transform(dataTypeValues: DataTypeValueResponseDto[], id: string): DataTypeValueResponseDto[] {
    const matchedDataType = dataTypeValues?.find(dataTypeValue => dataTypeValue.value === id);
    return matchedDataType ? [matchedDataType] : [];
  }
}
