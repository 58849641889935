import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { ENUM_MATCH_MODE_OPTIONS } from '@shared/types/filter.types';
import { EnumFilterValue } from '@widgets/shared/components/artifact-filters/components/enum-filter/types/enum-filter.value';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss'],
})
export class EnumFilterComponent {
  @Input() set m(filter: ArtifactFilter) {
    this.filterValue = filter.value;
    this.enumValues = filter.dataType?.values || [];
  }

  @Output() onFilterChange: EventEmitter<void> = new EventEmitter<void>();

  filterValue: EnumFilterValue;
  enumValues: DataTypeValueResponseDto[];

  readonly matchModes: { label: string; value: string }[] = ENUM_MATCH_MODE_OPTIONS;

  onSelectedOptionsChange(): void {
    this.onFilterChange.emit();
  }

  onMatchModeChange(): void {
    if (this.filterValue.selectedEnumValues.length) {
      this.onFilterChange.emit();
    }
  }
}
