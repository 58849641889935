import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';

export class DirectionalLinkType extends LinkType {
  idAndDirection: string;

  constructor(public direction: LinkDirection, dto?: Partial<LinkTypeResponseDto>) {
    super(dto);
    this.idAndDirection = this.id + this.direction;
  }

  get label(): string {
    return this.direction === LinkDirection.outgoing ? this.outgoingLabel : this.incomingLabel;
  }

  get isOutgoing(): boolean {
    return this.direction === LinkDirection.outgoing;
  }

  get isIncoming(): boolean {
    return this.direction === LinkDirection.incoming;
  }

  private get outgoingLabel(): string {
    return this.directionalLabel ? this.outgoingName : this.name;
  }

  private get incomingLabel(): string {
    return this.directionalLabel ? this.incomingName : this.name;
  }
}
