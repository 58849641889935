import { ArtifactResponseDto } from '@api/models';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DateRangeFilterEnum, LinkFilterEnum } from '@shared/types/filter.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { SortTypeEnum, SortTypeValueEnum } from '@shared/types/sort.types';
import { NewTableColumn } from '@shared/types/table.types';
import { NewTeam } from '@shared/types/team.types';
import { NewUser } from '@shared/types/user.types';
import { range } from 'lodash';
import { GroupCollapseEnum, GroupOrderEnum, PaginationSettingEnum } from './list-widget-grouping.types';
import { ListWidgetColumnResizeModeEnum, ListWidgetTableLoadModeEnum } from './list-widget-settings.types';
import { ListWidgetType } from './list-widget.types';

export class ListWidgetOptions {
  columns = new ListContainer<NewTableColumn>();
  listTypes = new ListContainer<ListWidgetType>();
  linkTypes = new ListContainer<LinkType>();
  linkTypesOptions: SelectOption<string, LinkType, LinkDirection>[] = [];
  linkFilter: SelectOption<string, LinkFilterEnum>[] = [];
  artifactTypes = new ListContainer<NewArtifactType>();
  artifactTypesByApplication = new ListContainer<NewArtifactType>();
  attributes = new ListContainer<NewAttribute>();
  systemAttributes = new ListContainer<NewAttribute>();
  dataTypes = new ListContainer<NewDataType>();
  users = new ListContainer<NewUser>();
  artifacts = new ListContainer<ArtifactResponseDto>();
  applications = new ListContainer<Application>();
  pages = new ListContainer<Page>();
  teams = new ListContainer<NewTeam>();
  loadModes: SelectOption<string, ListWidgetTableLoadModeEnum>[] = [];
  groupByDateRange: SelectOption<string, DateRangeFilterEnum>[] = [];
  groupByDateOffset: SelectOption<string, number>[] = [];
  groupPagination: SelectOption<typeof PaginationSettingEnum, typeof PaginationSettingEnum>[] = [];
  groupCollapse: SelectOption<string, GroupCollapseEnum>[] = [];
  groupSort: SelectOption<string, SortTypeValueEnum>[] = [];
  groupOrder: SelectOption<string, GroupOrderEnum>[] = [];
  columnResizeMode: SelectOption<ListWidgetColumnResizeModeEnum, ListWidgetColumnResizeModeEnum>[] = GetSelectOptionsFromEnum(ListWidgetColumnResizeModeEnum);

  constructor() {
    this.setOptions();
  }

  setGroupCollapseOptions(paginationSetting: PaginationSettingEnum): void {
    this.groupCollapse = Object.keys(GroupCollapseEnum).map(option => {
      const disabled = option === GroupCollapseEnum.expandAll && paginationSetting === PaginationSettingEnum.perGroup;
      return new SelectOption(option, GroupCollapseEnum[option as keyof typeof GroupCollapseEnum], null, disabled);
    });
  }

  private setOptions(): void {
    this.listTypes.setList(Object.keys(ListWidgetType).map(key => ListWidgetType[key as keyof typeof ListWidgetType]));
    this.loadModes = Object.keys(ListWidgetTableLoadModeEnum).map(
      option => new SelectOption(option, ListWidgetTableLoadModeEnum[option as keyof typeof ListWidgetTableLoadModeEnum]),
    );
    this.linkFilter = Object.keys(LinkFilterEnum).map(
      key => new SelectOption(key, LinkFilterEnum[key as keyof typeof LinkFilterEnum], null, key !== LinkFilterEnum.containsUrlParamKey),
    );
    this.groupByDateOffset = range(-12, 13).map(number => new SelectOption<string, number>('' + number, number));
    this.groupByDateRange = Object.keys(DateRangeFilterEnum).map(
      option => new SelectOption(option, DateRangeFilterEnum[option as keyof typeof DateRangeFilterEnum]),
    );
    this.groupPagination = GetSelectOptionsFromEnum(PaginationSettingEnum);
    this.groupSort = Object.keys(SortTypeEnum).map(
      option => new SelectOption(SortTypeEnum[option as keyof typeof SortTypeEnum], SortTypeValueEnum[option as keyof typeof SortTypeValueEnum]),
    );
    this.groupOrder = Object.keys(GroupOrderEnum).map(option => new SelectOption(option, GroupOrderEnum[option as keyof typeof GroupOrderEnum]));
  }
}

export interface SetOptionsArguments {
  applicationId: string;
  linkTypes: LinkType[];
  artifactTypes: NewArtifactType[];
  applications: Application[];
  pages: Page[];
}
