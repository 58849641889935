import { NgModule } from '@angular/core';
import { ApexChartWidgetModule } from '@widgets/apex-chart/apex-chart-widget.module';
import { AuthWidgetModule } from '@widgets/auth-widget/auth-widget.module';
import { AvrWidgetModule } from '@widgets/avr-widget/avr-widget.module';
import { CardWidgetModule } from '@widgets/card-widget/card-widget.module';
import { ChartWidgetModule } from '@widgets/chart-widget/chart-widget.module';
import { FilterWidgetModule } from '@widgets/filter-widget/filter-widget.module';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { ListMatrixWidgetModule } from '@widgets/list-matrix-widget/list-matrix-widget.module';
import { ListWidgetModule as ListWidgetModuleNew } from '@widgets/list-widget-new/list-widget.module';
import { NumberWidgetModule } from '@widgets/number-widget/number-widget.module';
import { PictureWidgetModule } from '@widgets/picture-widget/picture-widget.module';
import { TagWidgetModule } from '@widgets/tag-widget/tag-widget.module';
import { TextWidgetModule } from '@widgets/text-widget/text-widget.module';
import { ArtifactWidgetModule } from './artifact-widget/artifact-widget.module';
import { MenuWidgetModule } from './menu-widget/menu-widget.module';

const customWidgets = [
  TextWidgetModule,
  ListWidgetModuleNew,
  MenuWidgetModule,
  ArtifactWidgetModule,
  PictureWidgetModule,
  ListMatrixWidgetModule,
  ChartWidgetModule,
  ApexChartWidgetModule,
  CardWidgetModule,
  NumberWidgetModule,
  FolderWidgetModule,
  FilterWidgetModule,
  TagWidgetModule,
  AuthWidgetModule,
  AvrWidgetModule,
];

@NgModule({
  imports: [...customWidgets],
  exports: [...customWidgets],
})
export class WidgetsModule {}
