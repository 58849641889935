import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridTrackType } from '@shared/components/grid-layout-generator/types/grid-track-type';

@Component({
  selector: 'app-grid-gap-field',
  templateUrl: './grid-gap-field.component.html',
  styleUrls: ['./grid-gap-field.component.scss'],
})
export class GridGapFieldComponent {
  @Input() gap: string;
  @Input() readonly trackType: GridTrackType;

  @Output() gapChange: EventEmitter<string> = new EventEmitter<string>();

  onGapChange(gap: string): void {
    this.gapChange.emit(gap);
  }
}
