<h6>{{ 'Background image url' | translate }}</h6>
<div class="grid">
  <div class="col-12">
    <div class="p-input">
      <input #inputElement
             (blur)="onBlur(inputElement.value)"
             [value]="getPath()"
             class="w-100"
             pInputText
             type="text" />
    </div>
  </div>
</div>
