<div class="container-fluid">
  <div class="container">
    <div class="d-flex flex-row header-section">
      <button pButton class="p-button-text p-button-lg" type="button" (click)="hideTable()"
              [class.selected]="!isShowTable">
        <i class="pi pi-table"></i>
      </button>
      <button pButton class="p-button-text p-button-lg" type="button" (click)="showTable()"
              [class.selected]="isShowTable">
        <i class="pi pi-bars"></i>
      </button>
      <a *ngIf="user?.tenant?.isAdmin" [routerLink]="['/admin/application']" class="right-buttons">
        <button pButton label="{{'Create Application' | translate}}" type="button"
                class="p-button p-button-success"></button>
      </a>
    </div>

    <app-loader *ngIf="loading$ | async; else loadedContent" [loading]="true"></app-loader>

    <ng-template #loadedContent>
      <ng-container *ngIf="applications?.length; else empty">
        <app-application-list-table *ngIf="isShowTable; else gridTemplate"
                                    [canUserAccessAdmin]="canUserAccessAdmin"
                                    [columns]="columns"
                                    [loadDataMethod]="loadData()">
        </app-application-list-table>

        <ng-template #gridTemplate>
          <div class="row">
            <div *ngFor="let application of applications" class="col-12 md:col-6 lg:col-3">
              <a *ngIf="application?.defaultPageId" [routerLink]="['/page', application.defaultPageId]" class="card">
                <h2>{{application.name}}</h2>
              </a>
              <div *ngIf="!application?.defaultPageId" class="card"
                   pTooltip="{{'Unfortunately, this app does not have a default page. Contact the administrator about this.' | translate}}">
                <h2>{{application.name}}</h2>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #empty>
        <div class="row">
          <div class="col-12">
            <h3 class="text-center" translate>
              You currently don´t have access to any application.
              <br>
              Please contact the administrator.
            </h3>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
