<div class="main-rules-container full-height-container">
  <div class="rule-list">
    <p-tabView [activeIndex]="activeIndex" styleClass="workflow-tab-view" (onChange)="onTabChange($event.index)">
      <p-tabPanel header="{{ 'WORKFLOWS.COMMON.PAGE_RULES' | translate }}">
        <div class="rule-list-container">
          <div class="clickable single-rule-container single-rule add-rule-element" [class.selected]="selectedRule === rule" (click)="addNewWorkflowRule()">
            <span>
              <i class="bi bi-plus-circle"></i>
              {{ 'WORKFLOWS.COMMON.ADD_NEW_RULE' | translate }}
            </span>
          </div>
          <ng-container *ngIf="pageRules?.length">
            <div
              *ngFor="let rule of pageRules; trackBy: trackByRuleFn"
              class="clickable single-rule-container single-rule"
              [class.selected]="selectedRule?.id === rule.id"
              (click)="selectRule(rule)"
            >
              <span [class.inactive]="!rule.active">
                {{ rule.name }}
              </span>
              <div>
                <span *ngIf="rule | isGlobalWorkflowUsedByPage : pageId"
                      [pTooltip]="'WORKFLOWS.COMMON.GLOBAL_RULE' | translate"
                      class="global-rule-mark">
                  G
                </span>
              </div>
              <span class="priority-rule-mark"> {{ rule.priority }} </span>
            </div>
          </ng-container>
        </div>
      </p-tabPanel>

      <p-tabPanel header="{{ 'WORKFLOWS.COMMON.GLOBAL_RULES' | translate }}">
        <div class="rule-list-container">
          <ng-container *ngIf="globalRules?.length">
            <div
              *ngFor="let rule of globalRules; trackBy: trackByRuleFn"
              class="clickable single-rule-container single-rule"
              [class.selected]="selectedRule?.id === rule.id"
              [class.inactive]="!rule.active"
              (click)="selectRule(rule)"
            >
              <span [class.inactive]="!rule.active">
                {{ rule.name }}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!globalRules?.length">
            <span>{{ 'WORKFLOWS.COMMON.NO_GLOBAL_RULES' | translate }}</span>
          </ng-container>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="rule-detail">
    <app-workflows-rule
      *ngIf="selectedRule"
      [rule]="selectedRule"
      [isNew]="rule === selectedRule"
      [artifactTypes]="artifactTypes?.list"
      [attributes]="attributes"
      [dataTypes]="dataTypes"
      [linkTypes]="linkTypes"
      [applications]="applications"
      [users]="users"
      [pageWidgets]="pageWidgets"
      [editable]="selectedRule.ownerId === pageId"
      [ruleAdministrationType]="RuleAdministrationType.PAGE"
      (onSaveRule)="doSaveRule($event)"
      (onUseRule)="doUseRule($event)"
      (onDeleteRule)="doDeleteRule($event)"
    ></app-workflows-rule>
    <div class="rule-bottom-action-bar">
      <!-- <ng-container *ngIf="editable && hasValidTriggers()"> -->
      <ng-container *ngIf="activeIndex === 0 && selectedRule?.ownerId === pageId">
        <button
          *ngIf="rule === selectedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.SAVE_RULE' | translate }}"
          class="p-button p-button-success workflows-action-btn"
          (click)="doSaveRule(selectedRule)"
        ></button>
        <button
          *ngIf="rule !== selectedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.UPDATE_RULE' | translate }}"
          class="p-button p-button-warning workflows-action-btn"
          (click)="doSaveRule(selectedRule)"
        ></button>
        <button
          pButton
          label="{{ 'WORKFLOWS.COMMON.DELETE_RULE' | translate }}"
          class="p-button p-button-danger workflows-action-btn"
          (click)="doDeleteRule(selectedRule)"
        ></button>
      </ng-container>
      <button
        *ngIf="activeIndex === 1 && selectedRule"
        pButton
        label="{{ 'WORKFLOWS.COMMON.USE_RULE' | translate }}"
        class="p-button p-button-success workflows-action-btn"
        (click)="doUseRule(selectedRule)"
      ></button>
      <button
        *ngIf="selectedRule | isGlobalWorkflowUsedByPage : pageId"
        pButton
        label="{{ 'WORKFLOWS.COMMON.UNUSE_RULE' | translate }}"
        class="p-button p-button-danger workflows-action-btn"
        (click)="doUnuseRule(selectedRule)"
      ></button>
    </div>
  </div>
</div>
