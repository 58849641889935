import { Component, Input, OnInit } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewAttribute } from '@shared/types/attribute.types';
import { UserIconSizeEnum } from '@shared/types/display-at-types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-form-readonly-field-user',
  templateUrl: './form-readonly-field-user.component.html',
  styleUrls: ['./form-readonly-field-user.component.scss'],
})
export class FormReadonlyFieldUserComponent implements OnInit {
  @Input() ownerId: string;
  @Input() item: ArtifactWidgetFormItem;
  @Input() attribute: NewAttribute;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() model: ArtifactWidgetModel;
  @Input() selected: ArtifactWidgetSelectedEntities;

  currentUserSelected$: Observable<boolean>;
  userIconSize: UserIconSizeEnum = UserIconSizeEnum.MEDIUM;

  constructor(private readonly cache: NewCacheService) {}

  ngOnInit() {
    this.currentUserSelected$ = of(false).pipe(
      switchMap(() => this.cache.user),
      map(currentUser => {
        const clientAttribute = this.item.attribute?.value;
        const attributeValueArray = clientAttribute?.value as SelectOption<string, string>[];
        return !!attributeValueArray?.find(item => item.value === (currentUser as SelfUserResponseDto)?.id);
      }),
    );
  }
}
