import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class BaseFolderFilterService extends AbstractBaseTypeFilterService {
  constructor(filterUtil: FilterUtil) {
    super(filterUtil);
  }

  getQuery(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    filterRule?: CoreListFilterEnum,
    isEmptyTypeFilter?: boolean,
  ): Record<string, any> | null {
    if (!value && !isEmptyTypeFilter) {
      return null;
    }
    const resultFilterRule = filterRule || this.getDefaultFilterRule();
    // text mode
    if (this.filterUtil.isTextFilterMatchMode(resultFilterRule)) {
      const filterKey = this.filterUtil.getAttributesFolderPathDbFilterKey();
      const _value = value || null;
      return this.getResultQuery(filterKey, _value, resultFilterRule);
    }
    // default enum mode
    const values = Array.isArray(value) ? value : [value];
    const folderFilterValues = this.toOidValues(values);
    const filterKey = this.filterUtil.getAttributesFolderParentDbFilterKey();
    return this.getResultQuery(filterKey, folderFilterValues, resultFilterRule);
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    const resultFilterRule = filterRule || this.getDefaultFilterRule();
    return this.filterUtil.isTextFilterMatchMode(resultFilterRule)
      ? this.getQueryFromRawTextSingle(attributeKey, dataType, value, delimiter, filterRule)
      : this.getQueryFromRawTextMulti(attributeKey, dataType, value, delimiter, filterRule);
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    return this.filterUtil.isEnumFilterMatchMode(filterRule) || this.filterUtil.isTextFilterMatchMode(filterRule);
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.in;
  }
}
