import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';

export class LinkTypeModel {
  inProgress = false;
  isDeleted = false;
  linkType: LinkType;
  linkTypeCopy: LinkType;
  applications: ListContainer<NewApplication> = new ListContainer<NewApplication>();
  linkTypes: ListContainer<LinkType> = new ListContainer<LinkType>();
  artifactTypes: NewArtifactType[] = [];
}
