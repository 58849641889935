<ng-container *ngIf="clientAttribute">

  <!-- SIMPLE SINGLE INTEGER or DOUBLE -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) && !fullAttribute.multipleValues && ((fullDataType.baseDataType | isInteger) || (fullDataType.baseDataType | isDecimal))">
    <input [disabled]="disabledField"
           type="text"
           [id]="'intField' + index"
           pInputText
           [(ngModel)]="clientAttribute.value"
           (ngModelChange)="checkNumFormat($event, 'intField' + index, clientAttribute, 'value')">
  </span>

  <!-- SIMPLE MULTIPLE INTEGER or DOUBLE -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) && fullAttribute.multipleValues && ((fullDataType.baseDataType | isInteger) || (fullDataType.baseDataType | isDecimal))">
    <p-chips [disabled]="disabledField"
             [inputId]="'intFieldM' + index"
             [(ngModel)]="clientAttribute.value"
             (ngModelChange)="checkNumFormatMultiple()"
             [ngClass]="{'p-chips-filled': clientAttribute.value?.length}">
    </p-chips>
  </span>

  <!-- SIMPLE SINGLE TEXT -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) && !fullAttribute.multipleValues && (fullDataType.baseDataType | isText)">
    <textarea [disabled]="disabledField"
              [id]="'strField' + index"
              rows="1"
              pInputTextarea
              autoResize="autoResize"
              [(ngModel)]="clientAttribute.value">
    </textarea>
  </span>

  <!-- SIMPLE SINGLE HTML -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) && !fullAttribute.multipleValues && (fullDataType.baseDataType | isHtml)">
    <editor [ngClass]="{'editor-enabled': !disabledField}"
            [(ngModel)]="clientAttribute.value"
            [init]="settings"
            [disabled]="disabledField"
            [id]="'htmlField' + uniqueId"
            apiKey="y0en5l9qmetfdo5lld6p5xc7xs39weexnhrzvdtoxzs1a7kr"
            outputFormat="html">
    </editor>
  </span>

  <!-- SIMPLE MULTIPLE TEXT or HTML -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) &&  fullAttribute.multipleValues && ((fullDataType.baseDataType | isText) || (fullDataType.baseDataType | isHtml))">
    <p-chips [disabled]="disabledField"
             [inputId]="'intFieldM' + index"
             [(ngModel)]="clientAttribute.value"
             [ngClass]="{'p-chips-filled': clientAttribute.value?.length}">
    </p-chips>
  </span>

  <!-- SIMPLE DATE or DATETIME -->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isSimple) && ((fullDataType.baseDataType | isDate) || (fullDataType.baseDataType | isDateTime))">
    <p-calendar [(ngModel)]="clientAttribute.value"
                [selectionMode]="fullAttribute.multipleValues ? 'multiple' : 'single'"
                [firstDayOfWeek]="firstDayOfWeek"
                [dateFormat]="dateFormat"
                [showTime]="isDatetime"
                [readonlyInput]="true"
                [inputId]="'dateField' + index"
                appendTo="body">
    </p-calendar>
  </span>

  <!-- SIMPLE BOOLEAN -->
  <span class="dynamic-label" *ngIf="(fullDataType.kind | isSimple) && (fullDataType.baseDataType | isBoolean)">
    <p-checkbox [(ngModel)]="clientAttribute.value" binary="true" [inputId]="'booleanField' + index"></p-checkbox>
  </span>

  <!-- ENUMERATED MULTIPLE-->
  <span class="dynamic-label" *ngIf="(fullDataType.kind | isEnumerated) && fullAttribute.multipleValues">
    <p-multiSelect optionLabel="label"
                   [inputId]="'enumFieldM' + index"
                   [maxSelectedLabels]="5"
                   [filter]="true"
                   [options]="fullDataType.values"
                   [(ngModel)]="clientAttribute.value"
                   [disabled]="disabledField"
                   appendTo="body">
      <ng-template pTemplate="selectedItems">
        <span *ngFor="let option of clientAttribute.value" class="option-with-icon">
          <span [class]="option.icon"></span>
          <span [ngClass]="{'full-indent': !option.icon, 'half-indent': option.icon}">{{option.label}}</span>
        </span>
        <div *ngIf="!clientAttribute.value?.length">
          Select
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span [class]="option.icon"></span>
        <span [ngClass]="{'full-indent': !option.icon, 'half-indent': option.icon}">{{option.label}}</span>
      </ng-template>
    </p-multiSelect>
  </span>

  <!-- ENUMERATED SINGLE -->
  <span class="dynamic-label" *ngIf="(fullDataType.kind | isEnumerated) && !fullAttribute.multipleValues">
    <p-dropdown [options]="options"
                [inputId]="'enumField' + index"
                [(ngModel)]="clientAttribute.value"
                [autoDisplayFirst]="false"
                [disabled]="disabledField"
                [filter]="true"
                [showClear]="true"
                appendTo="body">
      <ng-template let-option pTemplate="item">
          <span [class]="option.icon"></span>
        {{option.label}}
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
          <span [class]="option.icon"></span>
        {{option.label}}
      </ng-template>
    </p-dropdown>
  </span>

  <!--User-->
  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isUser">
    <p-multiSelect optionLabel="label"
                   [inputId]="'userField' + index"
                   [selectionLimit]="fullAttribute.multipleValues ? null : 1"
                   [maxSelectedLabels]="5"
                   [filter]="true"
                   [options]="userOptions"
                   [(ngModel)]="clientAttribute.value"
                   [disabled]="disabledField"
                   appendTo="body">
      <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
      </ng-template>
    </p-multiSelect>
  </span>

  <!-- BOUNDED INTEGER or DECIMAL-->
  <span class="dynamic-label"
        *ngIf="(fullDataType.kind | isBounded) && ((fullDataType.baseDataType | isInteger) || (fullDataType.baseDataType | isDecimal))">
    <span class="dynamic-slider">
      <p *ngIf="fullAttribute.multipleValues">[{{clientAttribute.value[0] + ' - ' + clientAttribute.value[1]}}]</p>
      <p *ngIf="!fullAttribute.multipleValues">{{clientAttribute.value}}</p>
      <p-slider [(ngModel)]="clientAttribute.value"
                [min]="+fullDataType.minimum"
                [max]="+fullDataType.maximum"
                [range]="fullAttribute.multipleValues"
                [step]="isInteger ? 1 : 0.1"
                [disabled]="disabledField"
                [id]="'rangeField' + index"
                class="horizontal-slider">
      </p-slider>
    </span>
  </span>

  <!-- BOUNDED DATE -->
  <span class="dynamic-label" *ngIf="(fullDataType.kind | isBounded) && (fullDataType.baseDataType | isDate)">
    <p-calendar [(ngModel)]="clientAttribute.value"
                [selectionMode]="fullAttribute.multipleValues ? 'range' : 'single'"
                [firstDayOfWeek]="firstDayOfWeek"
                [dateFormat]="dateFormat"
                [readonlyInput]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [inputId]="'dateField' + index"
                appendTo="body">
    </p-calendar>
  </span>
</ng-container>
