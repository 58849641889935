import { inject } from '@angular/core';
import { TenantTenantResponseDto } from '@api/models';
import { TenantPageService } from '@api/services';
import { SelectOption } from '@shared/types/shared.types';
import { lastValueFrom } from 'rxjs';

export type OslcRootServicesCreateDto = TenantTenantResponseDto['oslcRootServices'][0] & { domain: string };

export class CurrentTenantModel {
  tenant: TenantTenantResponseDto;
  publicPageOptions: SelectOption<string, string>[] = [];

  private tenantPageService = inject(TenantPageService);

  constructor() {
    this.setPublicPageOptions();
  }

  private async setPublicPageOptions(): Promise<void> {
    const filter = JSON.stringify({ isPublic: { $eq: true } });

    this.publicPageOptions = await lastValueFrom(this.tenantPageService.pageControllerList({ filter })).then(res =>
      res.data.map(({ name, id }) => new SelectOption(name, id)),
    );
  }
}
