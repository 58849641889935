import { Pipe, PipeTransform } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Constants } from '@shared/constants/constants';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';

@Pipe({ name: 'getHrefForMenuItem' })
export class GetHrefForMenuItemPipe implements PipeTransform {
  constructor(private readonly router: Router) {}

  // TODO: alias pages do not support query params
  transform(item: MenuItem, isLink: boolean): string {
    if (!isLink || !item.url) return '';
    if (!item.alias && item.useAlias) return '';
    if (!item.isPageSelection) return item.url;

    const urlTreeRoute = item.useAlias ? [item.alias] : [Constants.page, item.url];
    const navigationExtra: NavigationExtras = { queryParams: item.queryParamsListToMap().parametersToAdd };
    item.fragmentParam && (navigationExtra.fragment = item.fragmentParam);

    return this.router.createUrlTree(urlTreeRoute, navigationExtra).toString();
  }
}
