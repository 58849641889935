<ng-container *ngIf="(init$ | async) === true">
  <tr>
    <td>{{ nonMappableFields.fileArtifactTypeId!.readableName }}</td>
    <td>
      <p-triStateCheckbox [ngModel]="!nonMappableFields.fileArtifactTypeId!.isOptional" disabled="true"></p-triStateCheckbox>
    </td>
    <td>
      <span>
        <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields.fileArtifactTypeId!.description" tooltipPosition="top" [escape]="false"></i>
        <p-dropdown
          [options]="fileArtifactTypeOptions"
          [(ngModel)]="selectedArtifactType"
          [showClear]="nonMappableFields.fileArtifactTypeId!.isOptional"
          [placeholder]="'Select value' | translate"
          (onChange)="onFileArtifactTypeChange($event.value)"
          ngClass="full-width-container"
          appendTo="body"
        >
        </p-dropdown>
      </span>
    </td>
  </tr>
  <tr>
    <td>{{ nonMappableFields.outputType!.readableName }}</td>
    <td>
      <p-triStateCheckbox [ngModel]="!nonMappableFields.outputType!.isOptional" disabled="true"></p-triStateCheckbox>
    </td>
    <td>
      <span>
        <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields.outputType!.description" tooltipPosition="top" [escape]="false"></i>
        <p-dropdown
          [options]="outputTypeOptions"
          [(ngModel)]="nonMappableFields.outputType!.value"
          [showClear]="nonMappableFields.outputType!.isOptional"
          [placeholder]="'Select value' | translate"
          (onChange)="onOutputTypeChange()"
          ngClass="full-width-container"
          appendTo="body"
        >
        </p-dropdown>
      </span>
    </td>
  </tr>
  <tr>
    <td>{{ nonMappableFields.everythingWithinArtifactType!.readableName }}</td>
    <td>
      <p-triStateCheckbox [ngModel]="!nonMappableFields.everythingWithinArtifactType!.isOptional" disabled="true"></p-triStateCheckbox>
    </td>
    <td>
      <span>
        <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields.everythingWithinArtifactType!.description" tooltipPosition="top" [escape]="false"></i>
        <p-dropdown
        [options]="everythingWithinArtifactTypeOptions"
        [(ngModel)]="nonMappableFields.everythingWithinArtifactType!.value"
        [showClear]="nonMappableFields.everythingWithinArtifactType!.isOptional"
        [placeholder]="'Select value' | translate"
        ngClass="full-width-container"
        appendTo="body"
      >
      </p-dropdown>
      </span>
    </td>
  </tr>
  <ng-container *ngIf="nonMappableFields.everythingWithinArtifactType!.value === false">
    <tr>
      <td colspan="2" translate>Document templates</td>
      <td>
        <span>
          <div pTooltip="{{ 'You can use artifact attribute value in document with following syntax \"{attributes.attribute-alias}\"' | translate }}" tooltipPosition="top">
            <i class="pi pi-info-circle mr-1" [escape]="false"></i>{{ 'Variables' | translate }}
          </div>
          <button
            *ngIf="!selectedArtifactType || !selectedArtifactType.defaultPageId"
            [pTooltip]="(!selectedArtifactType ? 'Select file artifact type first' : 'Missing default artifact type page') | translate"
            [disabled]="true"
            pButton
            icon="pi pi-upload"
            tooltipPosition="top"
          ></button>
          <a
            *ngIf="selectedArtifactType && selectedArtifactType.defaultPageId"
            [href]="'/' + Constants.page + '/' + selectedArtifactType.defaultPageId"
            [pTooltip]="'Upload file artifact' | translate"
            target="_blank"
            pButton
            icon="pi pi-upload"
            tooltipPosition="top"
          ></a>
          <button pButton [pTooltip]="'Add template' | translate" icon="pi pi-plus" tooltipPosition="top" (click)="onAddNewTemplateClick()"></button>
        </span>
      </td>
    </tr>
    <tr *ngFor="let template of templates; index as i">
      <td>{{ nonMappableFields.templates!.readableName }} [{{ i + 1 }}]</td>
      <td>
        <p-triStateCheckbox [ngModel]="!nonMappableFields.templates!.isOptional" disabled="true"></p-triStateCheckbox>
      </td>
      <td>
        <span>
          <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields.templates!.description" tooltipPosition="top" [escape]="false"></i>
          <div class="document-generation-templates-wrap">
            <p-dropdown
              [options]="fileArtifactOptions"
              [ngModel]="template.fileArtifactId"
              [showClear]="nonMappableFields.outputType!.isOptional"
              [placeholder]="'Select value' | translate"
              (onChange)="onArtifactChange($event.value, template)"
              ngClass="full-width-container"
              filter="true"
              optionValue="value.id" 
              appendTo="body"
            >
            </p-dropdown>
            <button [disabled]="templates.length === 1" pButton icon="pi pi-trash" class="p-button-danger" (click)="onDeleteTemplateClick(i)"></button>
          </div>
        </span>
      </td>
    </tr>
  </ng-container>
</ng-container>
