<div
  class="limited-layout-wrapper"
  [ngClass]="{
    'p-input-filled': uiConfig.inputStyle === 'filled',
    'p-ripple-disabled': !uiConfig.ripple,
    'dynamic-float-label': uiConfig.labelStyle === 'float' || !uiConfig || !uiConfig.labelStyle,
    'dynamic-static-label': uiConfig.labelStyle === 'static',
    'dynamic-placeholder-label': uiConfig.labelStyle === 'placeholder',
    'dynamic-inline-label': uiConfig.labelStyle === 'inlineLeft' || uiConfig.labelStyle === 'inlineRight',
    'dynamic-inline-label-right': uiConfig.labelStyle === 'inlineRight',
    'dynamic-inline-label-left': uiConfig.labelStyle === 'inlineLeft'
  }"
  [attr.data-theme]="uiConfig.colorScheme"
  (click)="onLayoutClick()"
  *ngIf="s.cache.isLoaded && uiConfig"
>
  <div class="limited-layout-content-wrapper">
    <app-limited-top-bar
      *ngIf="authorizationService.isLoggedIn && (router.url | isOnPageComponent)"></app-limited-top-bar>
    <router-outlet></router-outlet>
  </div>
</div>
