import { Constructor } from '@shared/types/constructor.types';
import {
  BorderStyles,
  BorderStylesEnum,
  BorderWidthEnum,
  FontFamilyEnum,
  FontStyleEnum,
  FontWeightEnum,
  ShadowStylesEnum,
  TextDecorationEnum,
  TextHorizontalAlignEnum,
  TextVerticalAlignEnum,
} from '../../shared/types/style.types';

export class MenuWidgetAllStyles {
  menu = new MenuStyles();
  subMenu = new SubMenuStyles();
  standardStyles = new MenuItemContentStyles();
  hoverStyles = new MenuItemContentStyles();
  activeStyles = new MenuItemContentStyles();
  defaultFirstLevelItemStyles = new DefaultItemStyles();
  defaultSubMenuItemStyles = new DefaultItemStyles();
  generalMenuItemStyles: Record<string, MenuItemGeneralStyles> = {};
  horizontalDivider: Record<string, HorizontalDividerStyles> = {};
  verticalDivider: Record<string, VerticalDividerStyles> = {};

  constructor(styles?: Partial<MenuWidgetAllStyles>) {
    if (styles) {
      styles.menu && (this.menu = new MenuStyles(styles.menu));
      styles.subMenu && (this.subMenu = new SubMenuStyles(styles.subMenu));
      styles.standardStyles && (this.standardStyles = new MenuItemContentStyles(styles.standardStyles));
      styles.hoverStyles && (this.hoverStyles = new MenuItemContentStyles(styles.hoverStyles));
      styles.activeStyles && (this.activeStyles = new MenuItemContentStyles(styles.activeStyles));
      styles.defaultSubMenuItemStyles && (this.defaultSubMenuItemStyles = new DefaultItemStyles(styles.defaultSubMenuItemStyles));
      styles.defaultFirstLevelItemStyles && (this.defaultFirstLevelItemStyles = new DefaultItemStyles(styles.defaultFirstLevelItemStyles));
      styles.generalMenuItemStyles && this.mapRecordToModel('generalMenuItemStyles', styles.generalMenuItemStyles, MenuItemGeneralStyles);
      styles.horizontalDivider && this.mapRecordToModel('horizontalDivider', styles.horizontalDivider, HorizontalDividerStyles);
      styles.verticalDivider && this.mapRecordToModel('verticalDivider', styles.verticalDivider, VerticalDividerStyles);
    }
  }

  private mapRecordToModel<T>(keyInClass: string, record: Record<string, any>, type: Constructor<T>): void {
    for (const key in record) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[keyInClass][key] = new type(record[key]);
    }
  }
}

export enum MenuStylesPropNamesEnum {
  item = 'item',
  label = 'label',
  subLabel = 'subLabel',
  icon = 'icon',
  chip = 'chip',
}

export class DefaultItemStyles {
  standardMenuItemStyle = new MenuItemDefaultContentStyles();
  hoverMenuItemStyle = new MenuItemDefaultContentStyles();
  activeMenuItemStyle = new MenuItemDefaultContentStyles();
  generalMenuItemStyles = new MenuItemGeneralStyles();
  horizontalDividerStyles = new HorizontalDividerStyles();
  verticalDividerStyles = new VerticalDividerStyles();

  constructor(styles?: Partial<DefaultItemStyles>) {
    if (styles) {
      styles.standardMenuItemStyle && (this.standardMenuItemStyle = new MenuItemDefaultContentStyles(styles.standardMenuItemStyle));
      styles.hoverMenuItemStyle && (this.hoverMenuItemStyle = new MenuItemDefaultContentStyles(styles.hoverMenuItemStyle));
      styles.activeMenuItemStyle && (this.activeMenuItemStyle = new MenuItemDefaultContentStyles(styles.activeMenuItemStyle));
      styles.generalMenuItemStyles && (this.generalMenuItemStyles = new MenuItemGeneralStyles(styles.generalMenuItemStyles));
      styles.horizontalDividerStyles && (this.horizontalDividerStyles = new HorizontalDividerStyles(styles.horizontalDividerStyles));
      styles.verticalDividerStyles && (this.verticalDividerStyles = new VerticalDividerStyles(styles.verticalDividerStyles));
    }
  }
}

export class MenuItemDefaultContentStyles {
  [MenuStylesPropNamesEnum.item] = new MenuItemStyles();
  [MenuStylesPropNamesEnum.label] = new FontStyles();
  [MenuStylesPropNamesEnum.subLabel] = new FontStyles();
  [MenuStylesPropNamesEnum.icon] = new IconStyles();
  [MenuStylesPropNamesEnum.chip] = new ChipStyles();

  constructor(styles?: Partial<MenuItemDefaultContentStyles>) {
    if (styles) {
      styles[MenuStylesPropNamesEnum.item] && (this[MenuStylesPropNamesEnum.item] = new MenuItemStyles(styles[MenuStylesPropNamesEnum.item]));
      styles[MenuStylesPropNamesEnum.label] && (this[MenuStylesPropNamesEnum.label] = new FontStyles(styles[MenuStylesPropNamesEnum.label]));
      styles[MenuStylesPropNamesEnum.subLabel] && (this[MenuStylesPropNamesEnum.subLabel] = new FontStyles(styles[MenuStylesPropNamesEnum.subLabel]));
      styles[MenuStylesPropNamesEnum.icon] && (this[MenuStylesPropNamesEnum.icon] = new IconStyles(styles[MenuStylesPropNamesEnum.icon]));
      styles[MenuStylesPropNamesEnum.chip] && (this[MenuStylesPropNamesEnum.chip] = new ChipStyles(styles[MenuStylesPropNamesEnum.chip]));
    }
  }
}

export class MenuItemContentStyles {
  [MenuStylesPropNamesEnum.item]: Record<string, MenuItemStyles> = {};
  [MenuStylesPropNamesEnum.label]: Record<string, FontStyles> = {};
  [MenuStylesPropNamesEnum.subLabel]: Record<string, FontStyles> = {};
  [MenuStylesPropNamesEnum.icon]: Record<string, IconStyles> = {};
  [MenuStylesPropNamesEnum.chip]: Record<string, ChipStyles> = {};

  constructor(styles?: Partial<MenuItemContentStyles>) {
    if (styles) {
      styles[MenuStylesPropNamesEnum.icon] && this.mapRecordToModel(MenuStylesPropNamesEnum.icon, styles[MenuStylesPropNamesEnum.icon], IconStyles);
      styles[MenuStylesPropNamesEnum.label] && this.mapRecordToModel(MenuStylesPropNamesEnum.label, styles[MenuStylesPropNamesEnum.label], FontStyles);
      styles[MenuStylesPropNamesEnum.subLabel] && this.mapRecordToModel(MenuStylesPropNamesEnum.subLabel, styles[MenuStylesPropNamesEnum.subLabel], FontStyles);
      styles[MenuStylesPropNamesEnum.item] && this.mapRecordToModel(MenuStylesPropNamesEnum.item, styles[MenuStylesPropNamesEnum.item], MenuItemStyles);
      styles[MenuStylesPropNamesEnum.chip] && this.mapRecordToModel(MenuStylesPropNamesEnum.chip, styles[MenuStylesPropNamesEnum.chip], ChipStyles);
    }
  }

  private mapRecordToModel<T>(keyInClass: string, record: Record<string, any> | undefined, type: Constructor<T>): void {
    for (const key in record) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[keyInClass][key] = new type(record[key]);
    }
  }
}

export class MenuStyles extends BorderStyles {
  backgroundColor: string;
  paddingLeft = '0.5rem';
  paddingRight = '0.5rem';
  paddingTop = '0.5rem';
  paddingBottom = '0.5rem';

  constructor(styles?: Partial<MenuStyles>) {
    super();
    this.borderRightStyle = BorderStylesEnum.none;
    this.borderTopStyle = BorderStylesEnum.none;
    this.borderLeftStyle = BorderStylesEnum.none;
    this.borderBottomStyle = BorderStylesEnum.none;

    styles && Object.assign(this, styles);
  }
}

export class SubMenuStyles extends MenuStyles {
  paddingLeft = '0.25rem';
  paddingRight = '0.25rem';
  paddingTop = '0.1rem';
  paddingBottom = '0.1rem';
  boxShadow = ShadowStylesEnum.none;
  position = 'absolute';
  minWidth = '';

  constructor(subMenu?: Partial<SubMenuStyles>) {
    super();
    subMenu && Object.assign(this, subMenu);
  }
}

export class MenuItemGeneralStyles {
  marginTop = '0px';
  marginBottom = '0px';
  marginLeft = '1px';
  marginRight = '1px';
  paddingTop = '0.85rem';
  paddingBottom = '0.85rem';
  paddingLeft = '0.85rem';
  paddingRight = '0.85rem';

  constructor(styles?: Partial<MenuItemGeneralStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class MenuItemStyles extends BorderStyles {
  backgroundColor = 'transparent';
  borderRightStyle = BorderStylesEnum.none;
  borderTopStyle = BorderStylesEnum.none;
  borderLeftStyle = BorderStylesEnum.none;
  borderBottomStyle = BorderStylesEnum.none;
  borderTopLeftRadius = '0px';
  borderBottomRightRadius = '0px';
  borderBottomLeftRadius = '0px';
  borderTopRightRadius = '0px';

  constructor(styles?: Partial<MenuItemStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}

export class HorizontalDividerStyles {
  marginLeft = '1px';
  marginRight = '1px';
  height = '70%';
  borderLeftWidth = BorderWidthEnum.thin;
  borderLeftStyle = BorderStylesEnum.solid;
  borderLeftColor = 'black';
  alignSelf = MenuItemVerticalPosition.center;
  justifyContent = MenuItemHorizontalPosition.center;

  constructor(styles?: Partial<HorizontalDividerStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class VerticalDividerStyles {
  borderBottomWidth = BorderWidthEnum.thin;
  borderBottomStyle = BorderStylesEnum.solid;
  width = '70%';
  borderBottomColor = 'black';
  marginBottom: '1px';
  marginTop = '1px';
  justifyContent = MenuItemHorizontalPosition.center;

  constructor(styles?: Partial<VerticalDividerStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class ContentStyles {
  color = '#495057';
  fontWeight = FontWeightEnum.normal;
  fontStyle = FontStyleEnum.normal;
  textDecoration = TextDecorationEnum.none;
  textAlign = TextHorizontalAlignEnum.center;
  verticalAlign = TextVerticalAlignEnum.middle;
  fontSize = '14px';
  backgroundColor = '';
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;

  constructor(styles?: Partial<ContentStyles | FontStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class IconStyles extends ContentStyles {
  order = '1';
  padding?: string;
  borderRadius?: string;
  borderColor?: string;
  borderWidth?: string;
  borderStyle?: string;

  constructor(styles?: Partial<IconStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}

export class FontStyles extends ContentStyles {
  fontFamily = FontFamilyEnum.arial;

  constructor(styles?: Partial<FontStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}

export class ChipStyles extends FontStyles {
  order = '3';
  backgroundColor = '';
  boxShadow = ShadowStylesEnum.none;

  borderLeftColor = 'lightgrey';
  borderRightColor = 'lightgrey';
  borderBottomColor = 'lightgrey';
  borderTopColor = 'lightgrey';

  borderLeftWidth = BorderWidthEnum.thin;
  borderBottomWidth = BorderWidthEnum.thin;
  borderRightWidth = BorderWidthEnum.thin;
  borderTopWidth = BorderWidthEnum.thin;

  borderRightStyle = BorderStylesEnum.none;
  borderTopStyle = BorderStylesEnum.none;
  borderLeftStyle = BorderStylesEnum.none;
  borderBottomStyle = BorderStylesEnum.none;

  borderTopLeftRadius = '4px';
  borderBottomRightRadius = '4px';
  borderBottomLeftRadius = '4px';
  borderTopRightRadius = '4px';

  constructor(styles?: Partial<ChipStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}

export enum MenuItemHorizontalPosition {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum MenuItemVerticalPosition {
  start = 'start',
  center = 'center',
  end = 'end',
}
