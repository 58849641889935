import { Pipe, PipeTransform } from '@angular/core';
import { GetDataTypeByAttributeId } from '@shared/methods/attribute.methods';
import { IsEnumerated } from '@shared/methods/data-type.methods';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Pipe({ name: 'isEnumColumn' })
export class IsEnumColumnPipe implements PipeTransform {
  transform(attributeId: string, options: ListWidgetOptions): boolean {
    return IsEnumerated(GetDataTypeByAttributeId(attributeId, options.attributes.listMap, options.dataTypes.listMap)?.kind);
  }
}
