import { Injectable } from '@angular/core';
import { ArtifactFormatFileDataResponseDto } from '@api/models';
import { ARTIFACT_TYPE_KEY, ARTIFACTS_ID_KEY, Constants } from '@shared/constants/constants';
import { FileArtifactDescriptionPipe } from '@shared/pipes/artifact-pipes/file-artifact-description.pipe';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';
import { PRIVATE_USER_FOLDER, PRIVATE_USER_FOLDER_LABEL } from '@widgets/shared/constants/widget.constants';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ArtifactWidgetCustomAttributeHelper {
  isCustomAttributes(attributeId: string, artifactTypeFormat?: ArtifactTypeFormatEnum): boolean {
    return (
      Constants.artifactCustomAttributes.some(dataType => dataType.value === attributeId) ||
      (artifactTypeFormat === ArtifactTypeFormatEnum.file && Constants.fileArtifactCustomAttributes.some(dataType => dataType.value === attributeId))
    );
  }

  isPasswordField(id: string): boolean {
    return NonAttributeKeys.PASSWORD_ID === id || NonAttributeKeys.CONFIRM_PASSWORD_ID === id;
  }

  isLoginField(id: string): boolean {
    return NonAttributeKeys.LOGIN_ID === id;
  }

  isRecoverPasswordField(id: string): boolean {
    return NonAttributeKeys.RECOVER_PASSWORD_ID === id;
  }

  getCustomAttributeValue(id: string, selected: ArtifactWidgetSelectedEntities, model: ArtifactWidgetModel): string {
    const code =
      Constants.artifactCustomAttributes.find(attribute => attribute.value === id)?.code ||
      Constants.fileArtifactCustomAttributes.find(attribute => attribute.value === id)?.code;
    const { artifact, artifactType } = selected;

    if (code === NonAttributeKeys.CREATED_BY || code === NonAttributeKeys.UPDATED_BY) {
      const id = (get(artifact, code) as any)?.id || get(artifact, code);
      return model.options.users.listMap[id]?.email;
    }

    if (code === ARTIFACT_TYPE_KEY) return artifactType?.name || '';

    if (code === ARTIFACTS_ID_KEY) return artifact?.id || '';

    if (code === NonAttributeKeys.FOLDER_PATH) {
      if (artifact?.folderData?.parentId === model.userPrivateFolderId) return PRIVATE_USER_FOLDER_LABEL;
      return artifact?.folderData?.path || (model.previousFolderId === PRIVATE_USER_FOLDER ? PRIVATE_USER_FOLDER_LABEL : '\\');
    }

    if (code === NonAttributeKeys.FILE_ARTIFACT_PATH) {
      return new FileArtifactDescriptionPipe().transform(model.newArtifactFile || (selected.artifact?.formatData as ArtifactFormatFileDataResponseDto));
    }

    return id;
  }

  removeCustomAttribute(attributes: Record<string, any>): Record<string, any> {
    Constants.artifactCustomAttributes.forEach(item => delete attributes[item.value]);
    return attributes;
  }

  getCustomAttributeOptions(artifactTypeFormat?: ArtifactTypeFormatEnum): SelectOption<string, NewClientAttribute>[] {
    const customAttributeMap = (item: { code: string; name: string; value: string }) => {
      return new SelectOption(
        item.name,
        new NewClientAttribute({
          id: item.value,
          isMandatory: false,
          value: '',
        }),
      );
    };
    let customAtts = Constants.artifactCustomAttributes;
    if (artifactTypeFormat === ArtifactTypeFormatEnum.file) {
      customAtts = customAtts.concat(Constants.fileArtifactCustomAttributes);
    }
    return customAtts.map(customAttributeMap);
  }
}
