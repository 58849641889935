import { cloneDeep, isEqual } from 'lodash';

export abstract class CoreCopyOriginalValueComponent {
  private copiedEntity: any = null;
  private keysToExclude = ['id', 'created', 'updated'];

  get getOriginalObject(): any {
    return this.copiedEntity;
  }

  setOriginalObject<Entity extends Record<string, any>>(entity: Entity | null): void {
    this.copiedEntity = entity ? cloneDeep(entity) : null;
  }

  addKeysToExcludeWhenComparingToOriginalObject(keys: string[]): void {
    this.keysToExclude = [...this.keysToExclude, ...keys];
  }

  getChangedDataFromOriginalObject<Entity>(newEntity: Entity, specialTransformation: Record<string, (data: any) => any> | null = null): Partial<Entity> {
    if (!this.copiedEntity || isEqual(this.copiedEntity, newEntity)) return {};

    const changed: Partial<Entity> = {};

    for (const key in newEntity) {
      if (!this.keysToExclude.includes(key) && !isEqual(this.copiedEntity[key], newEntity[key])) {
        if (specialTransformation && specialTransformation[key]) changed[key] = specialTransformation[key](newEntity[key]);
        else changed[key] = cloneDeep(newEntity[key]);
      }
    }

    return changed;
  }
}
