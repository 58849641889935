<div>
  <span>
    {{ 'WORKFLOWS.COMMON.THEN' | translate }}
    <p-dropdown
      class="workflow-element-dropdown"
      styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
      appendTo="body"
      placeholder="{{ 'WORKFLOWS.PLACEHOLDER.ACTION_ITEM' | translate }}"
      [options]="actionTypes"
      [ngModel]="action.type"
      [readonly]="!editable"
      [filter]="true"
      filterBy="label"
      (onChange)="onActionTypeChange($event.value)"
    >
      <ng-template let-option pTemplate="item">{{ option.label }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.label }}</ng-template>
    </p-dropdown>

    <ng-container *ngIf="action.isArtifactTypeRequired() && !defaultArtifactType">
      <p-dropdown
        class="workflow-element-dropdown"
        styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
        appendTo="body"
        placeholder="{{ 'WORKFLOWS.PLACEHOLDER.ARTIFACT_TYPE' | translate }}"
        [filter]="true"
        filterBy="name"
        dataKey="id"
        optionValue="id"
        [options]="artifactTypes"
        [ngModel]="action.artifactTypeId"
        [readonly]="!editable"
        (onChange)="onArtifactTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
      </p-dropdown>
    </ng-container>

    <ng-container *ngIf="action.artifactTypeId">
      <ng-container *ngIf="action.isAttributeRequired()">
        <!-- calculated value type -->
        <app-rule-action-calculate
          *ngIf="action.isCalculatedValueType()"
          [action]="action"
          [artifactTypes]="artifactTypes"
          [attributes]="attributes"
          [dataTypes]="dataTypes"
          [defaultArtifactType]="defaultArtifactType"
          [editable]="editable"
        >
        </app-rule-action-calculate>

        <!-- common attribute type -->
        <ng-container *ngIf="action | isActionCommonAttributeType">
          <app-workflow-attribute-selector
            [selectedAttributeId]="action.attributeId"
            [attributes]="selectedAttributes"
            (attributeChange)="onAttributeChange($event)"
            [editable]="editable"
          >
          </app-workflow-attribute-selector>
        </ng-container>

        <ng-container *ngIf="action.type === ActionType.SET_VALUE">
          <app-action-set-attribute-value
            [action]="action"
            [artifactTypes]="artifactTypes"
            [attributes]="attributes"
            [dataTypes]="dataTypes"
            [linkTypes]="linkTypes"
            [users]="users"
            [allAttributes]="allAttributes"
            [defaultArtifactType]="defaultArtifactType"
            [editable]="editable"
            [index]="index"
            [showOperations]="action.attributeId | showModifyValueOperationPicker: allAttributes : dataTypes"
          >
          </app-action-set-attribute-value>
        </ng-container>

        <ng-container *ngIf="action.type === ActionType.COPY_VALUE">
          <app-action-copy-attribute-value
            [action]="action"
            [artifactTypes]="artifactTypes"
            [attributes]="attributes"
            [dataTypes]="dataTypes"
            [linkTypes]="linkTypes"
            [users]="users"
            [allAttributes]="allAttributes"
            [defaultArtifactType]="defaultArtifactType"
            [editable]="editable"
            [showOperations]="action.attributeId | showModifyValueOperationPicker: allAttributes : dataTypes"
            [index]="index"
          >
          </app-action-copy-attribute-value>
        </ng-container>

        <ng-container *ngIf="action.type === ActionType.SET_VALUE_FROM_LINK">
          <app-action-value-from-link
            [action]="action"
            [artifactTypes]="artifactTypes"
            [attributes]="attributes"
            [dataTypes]="dataTypes"
            [linkTypes]="linkTypes"
            [defaultArtifactType]="defaultArtifactType"
            [editable]="editable"
            [linkableArtifactTypes]="linkableArtifactTypes"
            [triggers]="triggers"
          >
          </app-action-value-from-link>
        </ng-container>

        <ng-container *ngIf="action.type === ActionType.COPY_LINK_FROM_LINK">
          <app-action-link-from-link
            [action]="action"
            [artifactTypes]="artifactTypes"
            [attributes]="attributes"
            [dataTypes]="dataTypes"
            [linkTypes]="linkTypes"
            [defaultArtifactType]="defaultArtifactType"
            [editable]="editable"
            [linkableArtifactTypes]="linkableArtifactTypes"
            [triggers]="triggers"
          >
          </app-action-link-from-link>
        </ng-container>

        <ng-container *ngIf="action.type === ActionType.USE_CUSTOM_ATTRIBUTE_VALUES">
          <app-workflow-attribute-selector
            [selectedAttributeId]="action.attributeId"
            [attributes]="selectedAttributes | getWorkflowActionAttributes: dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds()"
            (attributeChange)="onAttributeChange($event)"
            [editable]="editable"
          >
          </app-workflow-attribute-selector>
          <ng-container *ngIf="action.attributeId">
            <p-multiSelect
              class="workflow-element-dropdown"
              styleClass="multiselect-no-border multiselect-label-as-active-link multiselect-no-caret"
              placeholder="select attribute values"
              appendTo="body"
              optionLabel="label"
              [(ngModel)]="action.values"
              [filter]="true"
              [options]="attributeValues"
              [readonly]="!editable"
            >
              <ng-template pTemplate="selectedItems">
                <div *ngIf="action.values?.length">
                  <span *ngFor="let value of action.values | slice: 0 : 2">{{ value.label }}, </span>
                  <ng-container *ngIf="action.values.length > 2"> + {{ action.values.length - 2 }} more </ng-container>
                </div>
                <div *ngIf="!action.values?.length">{{ 'select attribute values' | translate }}</div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <span [class]="option.icon"></span>
                <span [ngClass]="{ 'full-indent': !option.icon, 'half-indent': option.icon }">{{ option.label }}</span>
              </ng-template>
            </p-multiSelect>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!action.isAttributeRequired()">
      <ng-container *ngIf="action.type === ActionType.SCHEDULE_WORKFLOW">
        <app-action-schedule-workflow [action]="action"></app-action-schedule-workflow>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.SEND_EMAIL">
        <app-action-send-email [action]="action"></app-action-send-email>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.GET_ARTIFACTS">
        <app-action-get-artifacts [action]="action" [users]="users"></app-action-get-artifacts>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.CREATE_ARTIFACTS">
        <app-action-create-artifacts [action]="action" [artifactTypes]="artifactTypes" [attributes]="allAttributes"></app-action-create-artifacts>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.UPDATE_ARTIFACTS">
        <app-action-update-artifacts [action]="action"></app-action-update-artifacts>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.DELETE_ARTIFACTS">
        <app-action-delete-artifacts [action]="action"></app-action-delete-artifacts>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.EXEC_CUSTOM_JAVASCRIPT">
        <app-action-exec-custom-javascript [action]="action"></app-action-exec-custom-javascript>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.FETCH_DATA">
        <app-action-fetch-data [action]="action"></app-action-fetch-data>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.GET_LINKS">
        <app-action-get-links [action]="action"></app-action-get-links>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.CREATE_LINKS">
        <app-action-create-links [action]="action" [linkTypes]="linkTypes"></app-action-create-links>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.DELETE_LINKS">
        <app-action-delete-links [action]="action"></app-action-delete-links>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.MANAGE_USER_APPLICATIONS">
        <app-action-manage-user-applications [action]="action" [applications]="applications"></app-action-manage-user-applications>
      </ng-container>
      <ng-container *ngIf="action.type === ActionType.THROW_ERROR">
        <app-action-throw-error [action]="action"></app-action-throw-error>
      </ng-container>
    </ng-container>
  </span>
</div>

<ng-template #attributeSelector let-attributeValue="attributeValue" let-index="index" let-options="options" let-attributeChangeFn="attributeChangeFn">
  <p-dropdown
    class="workflow-element-dropdown"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    optionValue="id"
    placeholder="{{ 'WORKFLOWS.PLACEHOLDER.ATTRIBUTE' | translate }}"
    [options]="options"
    [ngModel]="attributeValue"
    [readonly]="!editable"
    (onChange)="attributeChangeFn($event.value, index)"
  >
    <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
    <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
  </p-dropdown>
</ng-template>
