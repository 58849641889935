/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { systemUserControllerCount } from '../fn/system-user/system-user-controller-count';
import { SystemUserControllerCount$Params } from '../fn/system-user/system-user-controller-count';
import { systemUserControllerCreate } from '../fn/system-user/system-user-controller-create';
import { SystemUserControllerCreate$Params } from '../fn/system-user/system-user-controller-create';
import { systemUserControllerDelete } from '../fn/system-user/system-user-controller-delete';
import { SystemUserControllerDelete$Params } from '../fn/system-user/system-user-controller-delete';
import { systemUserControllerGet } from '../fn/system-user/system-user-controller-get';
import { SystemUserControllerGet$Params } from '../fn/system-user/system-user-controller-get';
import { systemUserControllerList } from '../fn/system-user/system-user-controller-list';
import { SystemUserControllerList$Params } from '../fn/system-user/system-user-controller-list';
import { systemUserControllerUpdate } from '../fn/system-user/system-user-controller-update';
import { SystemUserControllerUpdate$Params } from '../fn/system-user/system-user-controller-update';
import { SystemUserListResponseDto } from '../models/system-user-list-response-dto';
import { SystemUserResponseDto } from '../models/system-user-response-dto';

@Injectable({ providedIn: 'root' })
export class SystemUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `systemUserControllerCount()` */
  static readonly SystemUserControllerCountPath = '/api/system/user/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerCount$Response(params?: SystemUserControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return systemUserControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerCount(params?: SystemUserControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.systemUserControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `systemUserControllerGet()` */
  static readonly SystemUserControllerGetPath = '/api/system/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerGet$Response(params: SystemUserControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemUserResponseDto>> {
    return systemUserControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerGet(params: SystemUserControllerGet$Params, context?: HttpContext): Observable<SystemUserResponseDto> {
    return this.systemUserControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemUserResponseDto>): SystemUserResponseDto => r.body)
    );
  }

  /** Path part for operation `systemUserControllerDelete()` */
  static readonly SystemUserControllerDeletePath = '/api/system/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerDelete$Response(params: SystemUserControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemUserResponseDto>> {
    return systemUserControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerDelete(params: SystemUserControllerDelete$Params, context?: HttpContext): Observable<SystemUserResponseDto> {
    return this.systemUserControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemUserResponseDto>): SystemUserResponseDto => r.body)
    );
  }

  /** Path part for operation `systemUserControllerList()` */
  static readonly SystemUserControllerListPath = '/api/system/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerList$Response(params?: SystemUserControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemUserListResponseDto>> {
    return systemUserControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUserControllerList(params?: SystemUserControllerList$Params, context?: HttpContext): Observable<SystemUserListResponseDto> {
    return this.systemUserControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemUserListResponseDto>): SystemUserListResponseDto => r.body)
    );
  }

  /** Path part for operation `systemUserControllerUpdate()` */
  static readonly SystemUserControllerUpdatePath = '/api/system/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUserControllerUpdate$Response(params: SystemUserControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemUserResponseDto>> {
    return systemUserControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUserControllerUpdate(params: SystemUserControllerUpdate$Params, context?: HttpContext): Observable<SystemUserResponseDto> {
    return this.systemUserControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemUserResponseDto>): SystemUserResponseDto => r.body)
    );
  }

  /** Path part for operation `systemUserControllerCreate()` */
  static readonly SystemUserControllerCreatePath = '/api/system/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUserControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUserControllerCreate$Response(params: SystemUserControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemUserResponseDto>> {
    return systemUserControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUserControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUserControllerCreate(params: SystemUserControllerCreate$Params, context?: HttpContext): Observable<SystemUserResponseDto> {
    return this.systemUserControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemUserResponseDto>): SystemUserResponseDto => r.body)
    );
  }

}
