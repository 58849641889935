import { BaseDataType, DataTypeKind } from '../../../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AbstractWorkflowCondition } from './abstract.condition';

export abstract class AbstractWorkflowComparableCondition extends AbstractWorkflowCondition {
  constructor() {
    super();
    this.supportedTypes = [BaseDataType.text, BaseDataType.decimal, BaseDataType.integer, BaseDataType.date, BaseDataType.dateTime, BaseDataType.time];
    this.supportedKinds = [DataTypeKind.simple, DataTypeKind.bounded];
  }
}
