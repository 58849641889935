<h5>{{'Container direction' | translate}}</h5>
<div class="grid">
  <div class="col">
    <p-dropdown
      [(ngModel)]="styles.flexDirection"
      [options]="options.flexDirection"
      (onChange)="onValueChange()">
    </p-dropdown>
  </div>
</div>
