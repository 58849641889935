import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContainerClass, Dimension } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { ContainerClassEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-max-width',
  templateUrl: './max-width.component.html',
  styleUrls: ['./max-width.component.scss'],
})
export class MaxWidthComponent implements OnInit, OnDestroy {
  @Input() styles: ContainerClass & Dimension;
  @Input() element: HTMLElement;

  ngOnInit(): void {
    if (this.isFixedContainer) {
      this.setInitialMaxWidth();
    }
  }

  ngOnDestroy(): void {
    if (this.isFluidContainer) {
      this.resetMaxWidth();
    }
  }

  private get isFixedContainer(): boolean {
    return this.styles.containerClass === ContainerClassEnum.box;
  }

  private get isFluidContainer(): boolean {
    return this.styles.containerClass === ContainerClassEnum.full;
  }

  private resetMaxWidth(): void {
    this.styles.maxWidth = '';
  }

  private setInitialMaxWidth(): void {
    if (this.styles.maxWidth) {
      return;
    }

    this.styles.maxWidth = getComputedStyle(this.element).getPropertyValue('max-width');
  }
}
