<div [ngClass]="formatSettings?.label | labelClass : model.settings.labelBehaviour : true" class="readonly-attribute-wrapper link-type-wrapper">
  <span *ngIf="isLinkTypeRequired" class="form-mandatory-field"></span>
  <div *ngIf="!!linkType?.value?.name" class="link-name readonly-label">
    <span *ngIf="linkType?.value?.id && formatSettings?.label.visible" [ngStyle]="formatSettings?.label.styles" class="link-name_label">
      <label>
        <span *ngIf="formatSettings?.label.customLabel; else linkTypeLabel">
          {{ formatSettings?.label.customLabel }}
        </span>
        <ng-template #linkTypeLabel> {{ linkType.label }} </ng-template>
      </label>
      <span *ngIf="formatSettings?.label.showDirection" [class]="'pi pi-arrow-circle-' + (linkMethods.isOutgoing(linkType?.meta) ? 'right' : 'left')"></span>
    </span>
  </div>

  <ng-container *ngIf="linkType?.value?.id && model.linkMap[linkType.value.id]">
    <app-display-at-link
      class="block-container full-size-container"
      [attributeId]="linkAttributeId"
      [ownerId]="hash"
      [artifact]="model.artifactDto"
      [artifactTypes]="model.options.artifactTypes"
      [links]="model.linkMap | toLinksPerArtifact : model.artifactId"
      [linkedData]="model.linkedArtifactsMap"
      [linksDto]="model.linksDto"
      [linkRestrictionParams]="linkRestrictionParams"
      [showAddLinkButton]="false"
      [selectedVariant]="formatSettings.value.displayMetadata?.selectedVariantCode || 'DEFAULT'"
      (onAddLinkClick)="onAddLinkClick(linkType)"
      (onDeleteLinkClick)="onDeleteLink($event)"
    >
    </app-display-at-link>
  </ng-container>

  <!-- <ul *ngIf="linkType?.value?.id && model.linkMap[linkType?.value?.id]" [ngStyle]="{ 'list-style': formatSettings?.value.listStyle }" class="links">
    <li
      *ngFor="let link of model.linkMap[linkType?.value?.id][linkType?.meta]; let linkI = index"
      [ngStyle]="{ 'padding-left': formatSettings?.value.paddingLeft }"
      class="link"
    >
      <a
        [queryParams]="link | getLinkQueryParams : linkType"
        [routerLink]="link | getLinkUrl : linkType : model.linkedArtifactsMap : model.options.artifactTypes"
        target="_blank"
      >
        <span
          *ngIf="formatSettings?.value.showIcon"
          [class]="(link | getLinkedArtifactType : linkType : model.options.artifactTypes : model.linkedArtifactsMap)?.icon"
        ></span>
        {{ link | getLinkName : model.selected.artifact : model.options.artifactTypes : model.options.attributes : model.linkedArtifactsMap }}
      </a>
      <button
        *ngIf="model && (!model.settings.initiateEditWithEditButton || model.isEditInProgress)"
        (click)="onDeleteLinkClick(link, linkI, linkType?.value?.id, linkType?.meta)"
        class="p-button-rounded p-button-danger p-button-outlined"
        icon="pi pi-times"
        pButton
        pRipple
        type="button"
      ></button>
    </li>
  </ul> -->

  <ul *ngIf="model.newLinksMap && model.newLinksMap[linkType.value.id] && model.newLinksMap[linkType.value.id][linkType.meta]" class="links">
    <li *ngFor="let link of model.newLinksMap[linkType.value.id][linkType.meta]; let linkI = index" class="link">
      <button
        class="p-button-rounded p-button-text"
        icon="pi pi-info"
        pButton
        pRipple
        type="button"
        [pTooltip]="'This is template of the link, the link will be created after artifact creation' | translate"
      ></button>
      <span>{{ link.linkName }}</span>
      <button
        *ngIf="model && (!model.settings.initiateEditWithEditButton || model.isEditInProgress || !model.artifactId)"
        (click)="deleteLinkBoilerplate(linkI)"
        class="p-button-rounded p-button-danger p-button-outlined"
        icon="pi pi-times"
        pButton
        pRipple
        type="button"
      ></button>
    </li>
  </ul>

  <button
    *ngIf="
      model &&
      model.selected &&
      model.selected.artifact &&
      (model.linkMap | canAddLink : linkType : model.selected.artifactType?.id : model.linkedArtifactsMap)
    "
    (click)="onAddLinkClick(linkType)"
    class="p-button-rounded p-button-success p-button-text add-link-button"
    icon="pi pi-plus"
    pButton
    pRipple
    type="button"
  ></button>
  <button
    *ngIf="model && model.selected && !model.selected.artifact"
    (click)="onAddLinkClick(linkType)"
    class="p-button-rounded p-button-success p-button-text add-link-button"
    icon="pi pi-plus"
    pButton
    pRipple
    type="button"
  ></button>
</div>
