import { WorkflowTriggerDto } from '@api/models/workflow-trigger-dto';
import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonOwnerTrigger } from '@workflows/types/triggers/common-owner-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerCreateUpdateDelete extends AbstractWorkflowTrigger<CommonOwnerTrigger> {
  constructor(type: RuleTriggerType, dto?: WorkflowTriggerDto) {
    super(CommonOwnerTrigger, dto);
    this.type = type;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    return this.type === workflowTrigger.type;
  }

  isValid(): boolean {
    return true;
  }
}
