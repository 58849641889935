import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeSelectModule } from 'primeng/treeselect';
import { TypeSystemElementDropdownComponent } from './type-system-element-dropdown.component';

@NgModule({
  declarations: [TypeSystemElementDropdownComponent],
  imports: [CommonModule, FormsModule, TreeSelectModule],
  exports: [TypeSystemElementDropdownComponent],
})
export class TypeSystemElementDropdownModule {}
