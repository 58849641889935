import { Pipe, PipeTransform } from '@angular/core';

const index = (order: number) => Math.max(0, order - 1);

@Pipe({
  name: 'transpose',
})
export class TransposePipe implements PipeTransform {
  transform(input: any[], numberOfColumns: number): any[] {
    if (!numberOfColumns) {
      return input;
    }

    const arraySize = input.length;
    const numberOfRows = Math.ceil(arraySize / numberOfColumns);
    const matrix = new Array(numberOfRows).fill(null).map(() => new Array(numberOfColumns));

    const emptyCells = numberOfRows * numberOfColumns - arraySize;
    const lastCellColumn = numberOfColumns - emptyCells;

    for (let row = 1; row <= numberOfRows; row++) {
      const isLastRow = row === numberOfRows;

      for (let column = 1; column <= numberOfColumns; column++) {
        const isFirstColumn = column === 1;
        const isPreviousColumnFull = column <= lastCellColumn || column - lastCellColumn === 1;
        const isEmptyColumn = isLastRow && column > lastCellColumn;

        if (isFirstColumn) {
          matrix[index(row)][index(column)] = index(row);
        } else {
          if (!isEmptyColumn) {
            matrix[index(row)][index(column)] = matrix[index(row)][index(column - 1)] + numberOfRows - +!isPreviousColumnFull;
          }
        }
      }
    }

    return matrix.flat().map((index: number) => input[index]);
  }
}
