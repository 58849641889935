import { Injectable } from '@angular/core';
import { Environment } from '@environments/environment';
import { SelectOption } from '../types/shared.types';
import { CachedSubject } from '../utils/cached-subject';

@Injectable()
export class IconsService {
  // for future 'https://raw.githubusercontent.com/primefaces/primeicons/2.0.0/selection.json'
  icons$: CachedSubject<SelectOption<string, string>[]>;

  constructor() {
    this.icons$ = new CachedSubject<SelectOption<string, string>[]>(this.getAllIcons.bind(this), null, Environment.cachedSubjectDefaultNoUpdateTime);
  }

  private get bootstrapIcons(): SelectOption<string, string>[] {
    return [
      '123',
      'alarm-fill',
      'alarm',
      'align-bottom',
      'align-center',
      'align-end',
      'align-middle',
      'align-start',
      'align-top',
      'alt',
      'app-indicator',
      'app',
      'archive-fill',
      'archive',
      'arrow-90deg-down',
      'arrow-90deg-left',
      'arrow-90deg-right',
      'arrow-90deg-up',
      'arrow-bar-down',
      'arrow-bar-left',
      'arrow-bar-right',
      'arrow-bar-up',
      'arrow-clockwise',
      'arrow-counterclockwise',
      'arrow-down-circle-fill',
      'arrow-down-circle',
      'arrow-down-left-circle-fill',
      'arrow-down-left-circle',
      'arrow-down-left-square-fill',
      'arrow-down-left-square',
      'arrow-down-left',
      'arrow-down-right-circle-fill',
      'arrow-down-right-circle',
      'arrow-down-right-square-fill',
      'arrow-down-right-square',
      'arrow-down-right',
      'arrow-down-short',
      'arrow-down-square-fill',
      'arrow-down-square',
      'arrow-down-up',
      'arrow-down',
      'arrow-left-circle-fill',
      'arrow-left-circle',
      'arrow-left-right',
      'arrow-left-short',
      'arrow-left-square-fill',
      'arrow-left-square',
      'arrow-left',
      'arrow-repeat',
      'arrow-return-left',
      'arrow-return-right',
      'arrow-right-circle-fill',
      'arrow-right-circle',
      'arrow-right-short',
      'arrow-right-square-fill',
      'arrow-right-square',
      'arrow-right',
      'arrow-up-circle-fill',
      'arrow-up-circle',
      'arrow-up-left-circle-fill',
      'arrow-up-left-circle',
      'arrow-up-left-square-fill',
      'arrow-up-left-square',
      'arrow-up-left',
      'arrow-up-right-circle-fill',
      'arrow-up-right-circle',
      'arrow-up-right-square-fill',
      'arrow-up-right-square',
      'arrow-up-right',
      'arrow-up-short',
      'arrow-up-square-fill',
      'arrow-up-square',
      'arrow-up',
      'arrows-angle-contract',
      'arrows-angle-expand',
      'arrows-collapse',
      'arrows-expand',
      'arrows-fullscreen',
      'arrows-move',
      'aspect-ratio-fill',
      'aspect-ratio',
      'asterisk',
      'at',
      'award-fill',
      'award',
      'back',
      'backspace-fill',
      'backspace-reverse-fill',
      'backspace-reverse',
      'backspace',
      'badge-3d-fill',
      'badge-3d',
      'badge-4k-fill',
      'badge-4k',
      'badge-8k-fill',
      'badge-8k',
      'badge-ad-fill',
      'badge-ad',
      'badge-ar-fill',
      'badge-ar',
      'badge-cc-fill',
      'badge-cc',
      'badge-hd-fill',
      'badge-hd',
      'badge-tm-fill',
      'badge-tm',
      'badge-vo-fill',
      'badge-vo',
      'badge-vr-fill',
      'badge-vr',
      'badge-wc-fill',
      'badge-wc',
      'bag-check-fill',
      'bag-check',
      'bag-dash-fill',
      'bag-dash',
      'bag-fill',
      'bag-plus-fill',
      'bag-plus',
      'bag-x-fill',
      'bag-x',
      'bag',
      'bar-chart-fill',
      'bar-chart-line-fill',
      'bar-chart-line',
      'bar-chart-steps',
      'bar-chart',
      'basket-fill',
      'basket',
      'basket2-fill',
      'basket2',
      'basket3-fill',
      'basket3',
      'battery-charging',
      'battery-full',
      'battery-half',
      'battery',
      'bell-fill',
      'bell',
      'bezier',
      'bezier2',
      'bicycle',
      'binoculars-fill',
      'binoculars',
      'blockquote-left',
      'blockquote-right',
      'book-fill',
      'book-half',
      'book',
      'bookmark-check-fill',
      'bookmark-check',
      'bookmark-dash-fill',
      'bookmark-dash',
      'bookmark-fill',
      'bookmark-heart-fill',
      'bookmark-heart',
      'bookmark-plus-fill',
      'bookmark-plus',
      'bookmark-star-fill',
      'bookmark-star',
      'bookmark-x-fill',
      'bookmark-x',
      'bookmark',
      'bookmarks-fill',
      'bookmarks',
      'bookshelf',
      'bootstrap-fill',
      'bootstrap-reboot',
      'bootstrap',
      'border-all',
      'border-bottom',
      'border-center',
      'border-inner',
      'border-left',
      'border-middle',
      'border-outer',
      'border-right',
      'border-style',
      'border-top',
      'border-width',
      'border',
      'bounding-box-circles',
      'bounding-box',
      'box-arrow-down-left',
      'box-arrow-down-right',
      'box-arrow-down',
      'box-arrow-in-down-left',
      'box-arrow-in-down-right',
      'box-arrow-in-down',
      'box-arrow-in-left',
      'box-arrow-in-right',
      'box-arrow-in-up-left',
      'box-arrow-in-up-right',
      'box-arrow-in-up',
      'box-arrow-left',
      'box-arrow-right',
      'box-arrow-up-left',
      'box-arrow-up-right',
      'box-arrow-up',
      'box-seam',
      'box',
      'braces',
      'bricks',
      'briefcase-fill',
      'briefcase',
      'brightness-alt-high-fill',
      'brightness-alt-high',
      'brightness-alt-low-fill',
      'brightness-alt-low',
      'brightness-high-fill',
      'brightness-high',
      'brightness-low-fill',
      'brightness-low',
      'broadcast-pin',
      'broadcast',
      'brush-fill',
      'brush',
      'bucket-fill',
      'bucket',
      'bug-fill',
      'bug',
      'building',
      'bullseye',
      'calculator-fill',
      'calculator',
      'calendar-check-fill',
      'calendar-check',
      'calendar-date-fill',
      'calendar-date',
      'calendar-day-fill',
      'calendar-day',
      'calendar-event-fill',
      'calendar-event',
      'calendar-fill',
      'calendar-minus-fill',
      'calendar-minus',
      'calendar-month-fill',
      'calendar-month',
      'calendar-plus-fill',
      'calendar-plus',
      'calendar-range-fill',
      'calendar-range',
      'calendar-week-fill',
      'calendar-week',
      'calendar-x-fill',
      'calendar-x',
      'calendar',
      'calendar2-check-fill',
      'calendar2-check',
      'calendar2-date-fill',
      'calendar2-date',
      'calendar2-day-fill',
      'calendar2-day',
      'calendar2-event-fill',
      'calendar2-event',
      'calendar2-fill',
      'calendar2-minus-fill',
      'calendar2-minus',
      'calendar2-month-fill',
      'calendar2-month',
      'calendar2-plus-fill',
      'calendar2-plus',
      'calendar2-range-fill',
      'calendar2-range',
      'calendar2-week-fill',
      'calendar2-week',
      'calendar2-x-fill',
      'calendar2-x',
      'calendar2',
      'calendar3-event-fill',
      'calendar3-event',
      'calendar3-fill',
      'calendar3-range-fill',
      'calendar3-range',
      'calendar3-week-fill',
      'calendar3-week',
      'calendar3',
      'calendar4-event',
      'calendar4-range',
      'calendar4-week',
      'calendar4',
      'camera-fill',
      'camera-reels-fill',
      'camera-reels',
      'camera-video-fill',
      'camera-video-off-fill',
      'camera-video-off',
      'camera-video',
      'camera',
      'camera2',
      'capslock-fill',
      'capslock',
      'card-checklist',
      'card-heading',
      'card-image',
      'card-list',
      'card-text',
      'caret-down-fill',
      'caret-down-square-fill',
      'caret-down-square',
      'caret-down',
      'caret-left-fill',
      'caret-left-square-fill',
      'caret-left-square',
      'caret-left',
      'caret-right-fill',
      'caret-right-square-fill',
      'caret-right-square',
      'caret-right',
      'caret-up-fill',
      'caret-up-square-fill',
      'caret-up-square',
      'caret-up',
      'cart-check-fill',
      'cart-check',
      'cart-dash-fill',
      'cart-dash',
      'cart-fill',
      'cart-plus-fill',
      'cart-plus',
      'cart-x-fill',
      'cart-x',
      'cart',
      'cart2',
      'cart3',
      'cart4',
      'cash-stack',
      'cash',
      'cast',
      'chat-dots-fill',
      'chat-dots',
      'chat-fill',
      'chat-left-dots-fill',
      'chat-left-dots',
      'chat-left-fill',
      'chat-left-quote-fill',
      'chat-left-quote',
      'chat-left-text-fill',
      'chat-left-text',
      'chat-left',
      'chat-quote-fill',
      'chat-quote',
      'chat-right-dots-fill',
      'chat-right-dots',
      'chat-right-fill',
      'chat-right-quote-fill',
      'chat-right-quote',
      'chat-right-text-fill',
      'chat-right-text',
      'chat-right',
      'chat-square-dots-fill',
      'chat-square-dots',
      'chat-square-fill',
      'chat-square-quote-fill',
      'chat-square-quote',
      'chat-square-text-fill',
      'chat-square-text',
      'chat-square',
      'chat-text-fill',
      'chat-text',
      'chat',
      'check-all',
      'check-circle-fill',
      'check-circle',
      'check-square-fill',
      'check-square',
      'check',
      'check2-all',
      'check2-circle',
      'check2-square',
      'check2',
      'chevron-bar-contract',
      'chevron-bar-down',
      'chevron-bar-expand',
      'chevron-bar-left',
      'chevron-bar-right',
      'chevron-bar-up',
      'chevron-compact-down',
      'chevron-compact-left',
      'chevron-compact-right',
      'chevron-compact-up',
      'chevron-contract',
      'chevron-double-down',
      'chevron-double-left',
      'chevron-double-right',
      'chevron-double-up',
      'chevron-down',
      'chevron-expand',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'circle-fill',
      'circle-half',
      'circle-square',
      'circle',
      'clipboard-check',
      'clipboard-data',
      'clipboard-minus',
      'clipboard-plus',
      'clipboard-x',
      'clipboard',
      'clock-fill',
      'clock-history',
      'clock',
      'cloud-arrow-down-fill',
      'cloud-arrow-down',
      'cloud-arrow-up-fill',
      'cloud-arrow-up',
      'cloud-check-fill',
      'cloud-check',
      'cloud-download-fill',
      'cloud-download',
      'cloud-drizzle-fill',
      'cloud-drizzle',
      'cloud-fill',
      'cloud-fog-fill',
      'cloud-fog',
      'cloud-fog2-fill',
      'cloud-fog2',
      'cloud-hail-fill',
      'cloud-hail',
      'cloud-haze-1',
      'cloud-haze-fill',
      'cloud-haze',
      'cloud-haze2-fill',
      'cloud-lightning-fill',
      'cloud-lightning-rain-fill',
      'cloud-lightning-rain',
      'cloud-lightning',
      'cloud-minus-fill',
      'cloud-minus',
      'cloud-moon-fill',
      'cloud-moon',
      'cloud-plus-fill',
      'cloud-plus',
      'cloud-rain-fill',
      'cloud-rain-heavy-fill',
      'cloud-rain-heavy',
      'cloud-rain',
      'cloud-slash-fill',
      'cloud-slash',
      'cloud-sleet-fill',
      'cloud-sleet',
      'cloud-snow-fill',
      'cloud-snow',
      'cloud-sun-fill',
      'cloud-sun',
      'cloud-upload-fill',
      'cloud-upload',
      'cloud',
      'clouds-fill',
      'clouds',
      'cloudy-fill',
      'cloudy',
      'code-slash',
      'code-square',
      'code',
      'collection-fill',
      'collection-play-fill',
      'collection-play',
      'collection',
      'columns-gap',
      'columns',
      'command',
      'compass-fill',
      'compass',
      'cone-striped',
      'cone',
      'controller',
      'cpu-fill',
      'cpu',
      'credit-card-2-back-fill',
      'credit-card-2-back',
      'credit-card-2-front-fill',
      'credit-card-2-front',
      'credit-card-fill',
      'credit-card',
      'crop',
      'cup-fill',
      'cup-straw',
      'cup',
      'cursor-fill',
      'cursor-text',
      'cursor',
      'dash-circle-dotted',
      'dash-circle-fill',
      'dash-circle',
      'dash-square-dotted',
      'dash-square-fill',
      'dash-square',
      'dash',
      'diagram-2-fill',
      'diagram-2',
      'diagram-3-fill',
      'diagram-3',
      'diamond-fill',
      'diamond-half',
      'diamond',
      'dice-1-fill',
      'dice-1',
      'dice-2-fill',
      'dice-2',
      'dice-3-fill',
      'dice-3',
      'dice-4-fill',
      'dice-4',
      'dice-5-fill',
      'dice-5',
      'dice-6-fill',
      'dice-6',
      'disc-fill',
      'disc',
      'discord',
      'display-fill',
      'display',
      'distribute-horizontal',
      'distribute-vertical',
      'door-closed-fill',
      'door-closed',
      'door-open-fill',
      'door-open',
      'dot',
      'download',
      'droplet-fill',
      'droplet-half',
      'droplet',
      'earbuds',
      'easel-fill',
      'easel',
      'egg-fill',
      'egg-fried',
      'egg',
      'eject-fill',
      'eject',
      'emoji-angry-fill',
      'emoji-angry',
      'emoji-dizzy-fill',
      'emoji-dizzy',
      'emoji-expressionless-fill',
      'emoji-expressionless',
      'emoji-frown-fill',
      'emoji-frown',
      'emoji-heart-eyes-fill',
      'emoji-heart-eyes',
      'emoji-laughing-fill',
      'emoji-laughing',
      'emoji-neutral-fill',
      'emoji-neutral',
      'emoji-smile-fill',
      'emoji-smile-upside-down-fill',
      'emoji-smile-upside-down',
      'emoji-smile',
      'emoji-sunglasses-fill',
      'emoji-sunglasses',
      'emoji-wink-fill',
      'emoji-wink',
      'envelope-fill',
      'envelope-open-fill',
      'envelope-open',
      'envelope',
      'eraser-fill',
      'eraser',
      'exclamation-circle-fill',
      'exclamation-circle',
      'exclamation-diamond-fill',
      'exclamation-diamond',
      'exclamation-octagon-fill',
      'exclamation-octagon',
      'exclamation-square-fill',
      'exclamation-square',
      'exclamation-triangle-fill',
      'exclamation-triangle',
      'exclamation',
      'exclude',
      'eye-fill',
      'eye-slash-fill',
      'eye-slash',
      'eye',
      'eyedropper',
      'eyeglasses',
      'facebook',
      'file-arrow-down-fill',
      'file-arrow-down',
      'file-arrow-up-fill',
      'file-arrow-up',
      'file-bar-graph-fill',
      'file-bar-graph',
      'file-binary-fill',
      'file-binary',
      'file-break-fill',
      'file-break',
      'file-check-fill',
      'file-check',
      'file-code-fill',
      'file-code',
      'file-diff-fill',
      'file-diff',
      'file-earmark-arrow-down-fill',
      'file-earmark-arrow-down',
      'file-earmark-arrow-up-fill',
      'file-earmark-arrow-up',
      'file-earmark-bar-graph-fill',
      'file-earmark-bar-graph',
      'file-earmark-binary-fill',
      'file-earmark-binary',
      'file-earmark-break-fill',
      'file-earmark-break',
      'file-earmark-check-fill',
      'file-earmark-check',
      'file-earmark-code-fill',
      'file-earmark-code',
      'file-earmark-diff-fill',
      'file-earmark-diff',
      'file-earmark-easel-fill',
      'file-earmark-easel',
      'file-earmark-excel-fill',
      'file-earmark-excel',
      'file-earmark-fill',
      'file-earmark-font-fill',
      'file-earmark-font',
      'file-earmark-image-fill',
      'file-earmark-image',
      'file-earmark-lock-fill',
      'file-earmark-lock',
      'file-earmark-lock2-fill',
      'file-earmark-lock2',
      'file-earmark-medical-fill',
      'file-earmark-medical',
      'file-earmark-minus-fill',
      'file-earmark-minus',
      'file-earmark-music-fill',
      'file-earmark-music',
      'file-earmark-person-fill',
      'file-earmark-person',
      'file-earmark-play-fill',
      'file-earmark-play',
      'file-earmark-plus-fill',
      'file-earmark-plus',
      'file-earmark-post-fill',
      'file-earmark-post',
      'file-earmark-ppt-fill',
      'file-earmark-ppt',
      'file-earmark-richtext-fill',
      'file-earmark-richtext',
      'file-earmark-ruled-fill',
      'file-earmark-ruled',
      'file-earmark-slides-fill',
      'file-earmark-slides',
      'file-earmark-spreadsheet-fill',
      'file-earmark-spreadsheet',
      'file-earmark-text-fill',
      'file-earmark-text',
      'file-earmark-word-fill',
      'file-earmark-word',
      'file-earmark-x-fill',
      'file-earmark-x',
      'file-earmark-zip-fill',
      'file-earmark-zip',
      'file-earmark',
      'file-easel-fill',
      'file-easel',
      'file-excel-fill',
      'file-excel',
      'file-fill',
      'file-font-fill',
      'file-font',
      'file-image-fill',
      'file-image',
      'file-lock-fill',
      'file-lock',
      'file-lock2-fill',
      'file-lock2',
      'file-medical-fill',
      'file-medical',
      'file-minus-fill',
      'file-minus',
      'file-music-fill',
      'file-music',
      'file-person-fill',
      'file-person',
      'file-play-fill',
      'file-play',
      'file-plus-fill',
      'file-plus',
      'file-post-fill',
      'file-post',
      'file-ppt-fill',
      'file-ppt',
      'file-richtext-fill',
      'file-richtext',
      'file-ruled-fill',
      'file-ruled',
      'file-slides-fill',
      'file-slides',
      'file-spreadsheet-fill',
      'file-spreadsheet',
      'file-text-fill',
      'file-text',
      'file-word-fill',
      'file-word',
      'file-x-fill',
      'file-x',
      'file-zip-fill',
      'file-zip',
      'file',
      'files-alt',
      'files',
      'film',
      'filter-circle-fill',
      'filter-circle',
      'filter-left',
      'filter-right',
      'filter-square-fill',
      'filter-square',
      'filter',
      'flag-fill',
      'flag',
      'flower1',
      'flower2',
      'flower3',
      'folder-check',
      'folder-fill',
      'folder-minus',
      'folder-plus',
      'folder-symlink-fill',
      'folder-symlink',
      'folder-x',
      'folder',
      'folder2-open',
      'folder2',
      'fonts',
      'forward-fill',
      'forward',
      'front',
      'fullscreen-exit',
      'fullscreen',
      'funnel-fill',
      'funnel',
      'gear-fill',
      'gear-wide-connected',
      'gear-wide',
      'gear',
      'gem',
      'geo-alt-fill',
      'geo-alt',
      'geo-fill',
      'geo',
      'gift-fill',
      'gift',
      'github',
      'globe',
      'globe2',
      'google',
      'graph-down',
      'graph-up',
      'grid-1x2-fill',
      'grid-1x2',
      'grid-3x2-gap-fill',
      'grid-3x2-gap',
      'grid-3x2',
      'grid-3x3-gap-fill',
      'grid-3x3-gap',
      'grid-3x3',
      'grid-fill',
      'grid',
      'grip-horizontal',
      'grip-vertical',
      'hammer',
      'hand-index-fill',
      'hand-index-thumb-fill',
      'hand-index-thumb',
      'hand-index',
      'hand-thumbs-down-fill',
      'hand-thumbs-down',
      'hand-thumbs-up-fill',
      'hand-thumbs-up',
      'handbag-fill',
      'handbag',
      'hash',
      'hdd-fill',
      'hdd-network-fill',
      'hdd-network',
      'hdd-rack-fill',
      'hdd-rack',
      'hdd-stack-fill',
      'hdd-stack',
      'hdd',
      'headphones',
      'headset',
      'heart-fill',
      'heart-half',
      'heart',
      'heptagon-fill',
      'heptagon-half',
      'heptagon',
      'hexagon-fill',
      'hexagon-half',
      'hexagon',
      'hourglass-bottom',
      'hourglass-split',
      'hourglass-top',
      'hourglass',
      'house-door-fill',
      'house-door',
      'house-fill',
      'house',
      'hr',
      'hurricane',
      'image-alt',
      'image-fill',
      'image',
      'images',
      'inbox-fill',
      'inbox',
      'inboxes-fill',
      'inboxes',
      'info-circle-fill',
      'info-circle',
      'info-square-fill',
      'info-square',
      'info',
      'input-cursor-text',
      'input-cursor',
      'instagram',
      'intersect',
      'journal-album',
      'journal-arrow-down',
      'journal-arrow-up',
      'journal-bookmark-fill',
      'journal-bookmark',
      'journal-check',
      'journal-code',
      'journal-medical',
      'journal-minus',
      'journal-plus',
      'journal-richtext',
      'journal-text',
      'journal-x',
      'journal',
      'journals',
      'joystick',
      'justify-left',
      'justify-right',
      'justify',
      'kanban-fill',
      'kanban',
      'key-fill',
      'key',
      'keyboard-fill',
      'keyboard',
      'ladder',
      'lamp-fill',
      'lamp',
      'laptop-fill',
      'laptop',
      'layer-backward',
      'layer-forward',
      'layers-fill',
      'layers-half',
      'layers',
      'layout-sidebar-inset-reverse',
      'layout-sidebar-inset',
      'layout-sidebar-reverse',
      'layout-sidebar',
      'layout-split',
      'layout-text-sidebar-reverse',
      'layout-text-sidebar',
      'layout-text-window-reverse',
      'layout-text-window',
      'layout-three-columns',
      'layout-wtf',
      'life-preserver',
      'lightbulb-fill',
      'lightbulb-off-fill',
      'lightbulb-off',
      'lightbulb',
      'lightning-charge-fill',
      'lightning-charge',
      'lightning-fill',
      'lightning',
      'link-45deg',
      'link',
      'linkedin',
      'list-check',
      'list-nested',
      'list-ol',
      'list-stars',
      'list-task',
      'list-ul',
      'list',
      'lock-fill',
      'lock',
      'mailbox',
      'mailbox2',
      'map-fill',
      'map',
      'markdown-fill',
      'markdown',
      'mask',
      'megaphone-fill',
      'megaphone',
      'menu-app-fill',
      'menu-app',
      'menu-button-fill',
      'menu-button-wide-fill',
      'menu-button-wide',
      'menu-button',
      'menu-down',
      'menu-up',
      'mic-fill',
      'mic-mute-fill',
      'mic-mute',
      'mic',
      'minecart-loaded',
      'minecart',
      'moisture',
      'moon-fill',
      'moon-stars-fill',
      'moon-stars',
      'moon',
      'mouse-fill',
      'mouse',
      'mouse2-fill',
      'mouse2',
      'mouse3-fill',
      'mouse3',
      'music-note-beamed',
      'music-note-list',
      'music-note',
      'music-player-fill',
      'music-player',
      'newspaper',
      'node-minus-fill',
      'node-minus',
      'node-plus-fill',
      'node-plus',
      'nut-fill',
      'nut',
      'octagon-fill',
      'octagon-half',
      'octagon',
      'option',
      'outlet',
      'paint-bucket',
      'palette-fill',
      'palette',
      'palette2',
      'paperclip',
      'paragraph',
      'patch-check-fill',
      'patch-check',
      'patch-exclamation-fill',
      'patch-exclamation',
      'patch-minus-fill',
      'patch-minus',
      'patch-plus-fill',
      'patch-plus',
      'patch-question-fill',
      'patch-question',
      'pause-btn-fill',
      'pause-btn',
      'pause-circle-fill',
      'pause-circle',
      'pause-fill',
      'pause',
      'peace-fill',
      'peace',
      'pen-fill',
      'pen',
      'pencil-fill',
      'pencil-square',
      'pencil',
      'pentagon-fill',
      'pentagon-half',
      'pentagon',
      'people-fill',
      'people',
      'percent',
      'person-badge-fill',
      'person-badge',
      'person-bounding-box',
      'person-check-fill',
      'person-check',
      'person-circle',
      'person-dash-fill',
      'person-dash',
      'person-fill',
      'person-lines-fill',
      'person-plus-fill',
      'person-plus',
      'person-square',
      'person-x-fill',
      'person-x',
      'person',
      'phone-fill',
      'phone-landscape-fill',
      'phone-landscape',
      'phone-vibrate-fill',
      'phone-vibrate',
      'phone',
      'pie-chart-fill',
      'pie-chart',
      'pin-angle-fill',
      'pin-angle',
      'pin-fill',
      'pin',
      'pip-fill',
      'pip',
      'play-btn-fill',
      'play-btn',
      'play-circle-fill',
      'play-circle',
      'play-fill',
      'play',
      'plug-fill',
      'plug',
      'plus-circle-dotted',
      'plus-circle-fill',
      'plus-circle',
      'plus-square-dotted',
      'plus-square-fill',
      'plus-square',
      'plus',
      'power',
      'printer-fill',
      'printer',
      'puzzle-fill',
      'puzzle',
      'question-circle-fill',
      'question-circle',
      'question-diamond-fill',
      'question-diamond',
      'question-octagon-fill',
      'question-octagon',
      'question-square-fill',
      'question-square',
      'question',
      'rainbow',
      'receipt-cutoff',
      'receipt',
      'reception-0',
      'reception-1',
      'reception-2',
      'reception-3',
      'reception-4',
      'record-btn-fill',
      'record-btn',
      'record-circle-fill',
      'record-circle',
      'record-fill',
      'record',
      'record2-fill',
      'record2',
      'reply-all-fill',
      'reply-all',
      'reply-fill',
      'reply',
      'rss-fill',
      'rss',
      'rulers',
      'save-fill',
      'save',
      'save2-fill',
      'save2',
      'scissors',
      'screwdriver',
      'search',
      'segmented-nav',
      'server',
      'share-fill',
      'share',
      'shield-check',
      'shield-exclamation',
      'shield-fill-check',
      'shield-fill-exclamation',
      'shield-fill-minus',
      'shield-fill-plus',
      'shield-fill-x',
      'shield-fill',
      'shield-lock-fill',
      'shield-lock',
      'shield-minus',
      'shield-plus',
      'shield-shaded',
      'shield-slash-fill',
      'shield-slash',
      'shield-x',
      'shield',
      'shift-fill',
      'shift',
      'shop-window',
      'shop',
      'shuffle',
      'signpost-2-fill',
      'signpost-2',
      'signpost-fill',
      'signpost-split-fill',
      'signpost-split',
      'signpost',
      'sim-fill',
      'sim',
      'skip-backward-btn-fill',
      'skip-backward-btn',
      'skip-backward-circle-fill',
      'skip-backward-circle',
      'skip-backward-fill',
      'skip-backward',
      'skip-end-btn-fill',
      'skip-end-btn',
      'skip-end-circle-fill',
      'skip-end-circle',
      'skip-end-fill',
      'skip-end',
      'skip-forward-btn-fill',
      'skip-forward-btn',
      'skip-forward-circle-fill',
      'skip-forward-circle',
      'skip-forward-fill',
      'skip-forward',
      'skip-start-btn-fill',
      'skip-start-btn',
      'skip-start-circle-fill',
      'skip-start-circle',
      'skip-start-fill',
      'skip-start',
      'slack',
      'slash-circle-fill',
      'slash-circle',
      'slash-square-fill',
      'slash-square',
      'slash',
      'sliders',
      'smartwatch',
      'snow',
      'snow2',
      'snow3',
      'sort-alpha-down-alt',
      'sort-alpha-down',
      'sort-alpha-up-alt',
      'sort-alpha-up',
      'sort-down-alt',
      'sort-down',
      'sort-numeric-down-alt',
      'sort-numeric-down',
      'sort-numeric-up-alt',
      'sort-numeric-up',
      'sort-up-alt',
      'sort-up',
      'soundwave',
      'speaker-fill',
      'speaker',
      'speedometer',
      'speedometer2',
      'spellcheck',
      'square-fill',
      'square-half',
      'square',
      'stack',
      'star-fill',
      'star-half',
      'star',
      'stars',
      'stickies-fill',
      'stickies',
      'sticky-fill',
      'sticky',
      'stop-btn-fill',
      'stop-btn',
      'stop-circle-fill',
      'stop-circle',
      'stop-fill',
      'stop',
      'stoplights-fill',
      'stoplights',
      'stopwatch-fill',
      'stopwatch',
      'subtract',
      'suit-club-fill',
      'suit-club',
      'suit-diamond-fill',
      'suit-diamond',
      'suit-heart-fill',
      'suit-heart',
      'suit-spade-fill',
      'suit-spade',
      'sun-fill',
      'sun',
      'sunglasses',
      'sunrise-fill',
      'sunrise',
      'sunset-fill',
      'sunset',
      'symmetry-horizontal',
      'symmetry-vertical',
      'table',
      'tablet-fill',
      'tablet-landscape-fill',
      'tablet-landscape',
      'tablet',
      'tag-fill',
      'tag',
      'tags-fill',
      'tags',
      'telegram',
      'telephone-fill',
      'telephone-forward-fill',
      'telephone-forward',
      'telephone-inbound-fill',
      'telephone-inbound',
      'telephone-minus-fill',
      'telephone-minus',
      'telephone-outbound-fill',
      'telephone-outbound',
      'telephone-plus-fill',
      'telephone-plus',
      'telephone-x-fill',
      'telephone-x',
      'telephone',
      'terminal-fill',
      'terminal',
      'text-center',
      'text-indent-left',
      'text-indent-right',
      'text-left',
      'text-paragraph',
      'text-right',
      'textarea-resize',
      'textarea-t',
      'textarea',
      'thermometer-half',
      'thermometer-high',
      'thermometer-low',
      'thermometer-snow',
      'thermometer-sun',
      'thermometer',
      'three-dots-vertical',
      'three-dots',
      'toggle-off',
      'toggle-on',
      'toggle2-off',
      'toggle2-on',
      'toggles',
      'toggles2',
      'tools',
      'tornado',
      'trash-fill',
      'trash',
      'trash2-fill',
      'trash2',
      'tree-fill',
      'tree',
      'triangle-fill',
      'triangle-half',
      'triangle',
      'trophy-fill',
      'trophy',
      'tropical-storm',
      'truck-flatbed',
      'truck',
      'tsunami',
      'tv-fill',
      'tv',
      'twitch',
      'twitter',
      'type-bold',
      'type-h1',
      'type-h2',
      'type-h3',
      'type-italic',
      'type-strikethrough',
      'type-underline',
      'type',
      'ui-checks-grid',
      'ui-checks',
      'ui-radios-grid',
      'ui-radios',
      'umbrella-fill',
      'umbrella',
      'union',
      'unlock-fill',
      'unlock',
      'upc-scan',
      'upc',
      'upload',
      'vector-pen',
      'view-list',
      'view-stacked',
      'vinyl-fill',
      'vinyl',
      'voicemail',
      'volume-down-fill',
      'volume-down',
      'volume-mute-fill',
      'volume-mute',
      'volume-off-fill',
      'volume-off',
      'volume-up-fill',
      'volume-up',
      'vr',
      'wallet-fill',
      'wallet',
      'wallet2',
      'watch',
      'water',
      'whatsapp',
      'wifi-1',
      'wifi-2',
      'wifi-off',
      'wifi',
      'wind',
      'window-dock',
      'window-sidebar',
      'window',
      'wrench',
      'x-circle-fill',
      'x-circle',
      'x-diamond-fill',
      'x-diamond',
      'x-octagon-fill',
      'x-octagon',
      'x-square-fill',
      'x-square',
      'x',
      'youtube',
      'zoom-in',
      'zoom-out',
      'bank',
      'bank2',
      'bell-slash-fill',
      'bell-slash',
      'cash-coin',
      'check-lg',
      'coin',
      'currency-bitcoin',
      'currency-dollar',
      'currency-euro',
      'currency-exchange',
      'currency-pound',
      'currency-yen',
      'dash-lg',
      'exclamation-lg',
      'file-earmark-pdf-fill',
      'file-earmark-pdf',
      'file-pdf-fill',
      'file-pdf',
      'gender-ambiguous',
      'gender-female',
      'gender-male',
      'gender-trans',
      'headset-vr',
      'info-lg',
      'mastodon',
      'messenger',
      'piggy-bank-fill',
      'piggy-bank',
      'pin-map-fill',
      'pin-map',
      'plus-lg',
      'question-lg',
      'recycle',
      'reddit',
      'safe-fill',
      'safe2-fill',
      'safe2',
      'sd-card-fill',
      'sd-card',
      'skype',
      'slash-lg',
      'translate',
      'x-lg',
      'safe',
      'apple',
      'microsoft',
      'windows',
      'behance',
      'dribbble',
      'line',
      'medium',
      'paypal',
      'pinterest',
      'signal',
      'snapchat',
      'spotify',
      'stack-overflow',
      'strava',
      'wordpress',
      'vimeo',
      'activity',
      'easel2-fill',
      'easel2',
      'easel3-fill',
      'easel3',
      'fan',
      'fingerprint',
      'graph-down-arrow',
      'graph-up-arrow',
      'hypnotize',
      'magic',
      'person-rolodex',
      'person-video',
      'person-video2',
      'person-video3',
      'person-workspace',
      'radioactive',
      'webcam-fill',
      'webcam',
      'yin-yang',
      'bandaid-fill',
      'bandaid',
      'bluetooth',
      'body-text',
      'boombox',
      'boxes',
      'dpad-fill',
      'dpad',
      'ear-fill',
      'ear',
      'envelope-check-1',
      'envelope-check-fill',
      'envelope-check',
      'envelope-dash-1',
      'envelope-dash-fill',
      'envelope-dash',
      'envelope-exclamation-1',
      'envelope-exclamation-fill',
      'envelope-exclamation',
      'envelope-plus-fill',
      'envelope-plus',
      'envelope-slash-1',
      'envelope-slash-fill',
      'envelope-slash',
      'envelope-x-1',
      'envelope-x-fill',
      'envelope-x',
      'explicit-fill',
      'explicit',
      'git',
      'infinity',
      'list-columns-reverse',
      'list-columns',
      'meta',
      'mortorboard-fill',
      'mortorboard',
      'nintendo-switch',
      'pc-display-horizontal',
      'pc-display',
      'pc-horizontal',
      'pc',
      'playstation',
      'plus-slash-minus',
      'projector-fill',
      'projector',
      'qr-code-scan',
      'qr-code',
      'quora',
      'quote',
      'robot',
      'send-check-fill',
      'send-check',
      'send-dash-fill',
      'send-dash',
      'send-exclamation-1',
      'send-exclamation-fill',
      'send-exclamation',
      'send-fill',
      'send-plus-fill',
      'send-plus',
      'send-slash-fill',
      'send-slash',
      'send-x-fill',
      'send-x',
      'send',
      'steam',
      'terminal-dash-1',
      'terminal-dash',
      'terminal-plus',
      'terminal-split',
      'ticket-detailed-fill',
      'ticket-detailed',
      'ticket-fill',
      'ticket-perforated-fill',
      'ticket-perforated',
      'ticket',
      'tiktok',
      'window-dash',
      'window-desktop',
      'window-fullscreen',
      'window-plus',
      'window-split',
      'window-stack',
      'window-x',
      'xbox',
      'ethernet',
      'hdmi-fill',
      'hdmi',
      'usb-c-fill',
      'usb-c',
      'usb-fill',
      'usb-plug-fill',
      'usb-plug',
      'usb-symbol',
      'usb',
      'boombox-fill',
      'displayport-1',
      'displayport',
      'gpu-card',
      'memory',
      'modem-fill',
      'modem',
      'motherboard-fill',
      'motherboard',
      'optical-audio-fill',
      'optical-audio',
      'pci-card',
      'router-fill',
      'router',
      'ssd-fill',
      'ssd',
      'thunderbolt-fill',
      'thunderbolt',
      'usb-drive-fill',
      'usb-drive',
      'usb-micro-fill',
      'usb-micro',
      'usb-mini-fill',
      'usb-mini',
      'cloud-haze2',
      'device-hdd-fill',
      'device-hdd',
      'device-ssd-fill',
      'device-ssd',
      'displayport-fill',
      'mortarboard-fill',
      'mortarboard',
      'terminal-x',
      'arrow-through-heart-fill',
      'arrow-through-heart',
      'badge-sd-fill',
      'badge-sd',
      'bag-heart-fill',
      'bag-heart',
      'balloon-fill',
      'balloon-heart-fill',
      'balloon-heart',
      'balloon',
      'box2-fill',
      'box2-heart-fill',
      'box2-heart',
      'box2',
      'braces-asterisk',
      'calendar-heart-fill',
      'calendar-heart',
      'calendar2-heart-fill',
      'calendar2-heart',
      'chat-heart-fill',
      'chat-heart',
      'chat-left-heart-fill',
      'chat-left-heart',
      'chat-right-heart-fill',
      'chat-right-heart',
      'chat-square-heart-fill',
      'chat-square-heart',
      'clipboard-check-fill',
      'clipboard-data-fill',
      'clipboard-fill',
      'clipboard-heart-fill',
      'clipboard-heart',
      'clipboard-minus-fill',
      'clipboard-plus-fill',
      'clipboard-pulse',
      'clipboard-x-fill',
      'clipboard2-check-fill',
      'clipboard2-check',
      'clipboard2-data-fill',
      'clipboard2-data',
      'clipboard2-fill',
      'clipboard2-heart-fill',
      'clipboard2-heart',
      'clipboard2-minus-fill',
      'clipboard2-minus',
      'clipboard2-plus-fill',
      'clipboard2-plus',
      'clipboard2-pulse-fill',
      'clipboard2-pulse',
      'clipboard2-x-fill',
      'clipboard2-x',
      'clipboard2',
      'emoji-kiss-fill',
      'emoji-kiss',
      'envelope-heart-fill',
      'envelope-heart',
      'envelope-open-heart-fill',
      'envelope-open-heart',
      'envelope-paper-fill',
      'envelope-paper-heart-fill',
      'envelope-paper-heart',
      'envelope-paper',
      'filetype-aac',
      'filetype-ai',
      'filetype-bmp',
      'filetype-cs',
      'filetype-css',
      'filetype-csv',
      'filetype-doc',
      'filetype-docx',
      'filetype-exe',
      'filetype-gif',
      'filetype-heic',
      'filetype-html',
      'filetype-java',
      'filetype-jpg',
      'filetype-js',
      'filetype-jsx',
      'filetype-key',
      'filetype-m4p',
      'filetype-md',
      'filetype-mdx',
      'filetype-mov',
      'filetype-mp3',
      'filetype-mp4',
      'filetype-otf',
      'filetype-pdf',
      'filetype-php',
      'filetype-png',
      'filetype-ppt-1',
      'filetype-ppt',
      'filetype-psd',
      'filetype-py',
      'filetype-raw',
      'filetype-rb',
      'filetype-sass',
      'filetype-scss',
      'filetype-sh',
      'filetype-svg',
      'filetype-tiff',
      'filetype-tsx',
      'filetype-ttf',
      'filetype-txt',
      'filetype-wav',
      'filetype-woff',
      'filetype-xls-1',
      'filetype-xls',
      'filetype-xml',
      'filetype-yml',
      'heart-arrow',
      'heart-pulse-fill',
      'heart-pulse',
      'heartbreak-fill',
      'heartbreak',
      'hearts',
      'hospital-fill',
      'hospital',
      'house-heart-fill',
      'house-heart',
      'incognito',
      'magnet-fill',
      'magnet',
      'person-heart',
      'person-hearts',
      'phone-flip',
      'plugin',
      'postage-fill',
      'postage-heart-fill',
      'postage-heart',
      'postage',
      'postcard-fill',
      'postcard-heart-fill',
      'postcard-heart',
      'postcard',
      'search-heart-fill',
      'search-heart',
      'sliders2-vertical',
      'sliders2',
      'trash3-fill',
      'trash3',
      'valentine',
      'valentine2',
      'wrench-adjustable-circle-fill',
      'wrench-adjustable-circle',
      'wrench-adjustable',
      'filetype-json',
      'filetype-pptx',
      'filetype-xlsx',
    ].map(name => new SelectOption(name, `bi bi-${name}`));
  }

  private get primengIcons(): SelectOption<string, string>[] {
    return [
      'compass',
      'id-card',
      'ticket',
      'file-o',
      'reply',
      'directions-alt',
      'directions',
      'thumbs-up',
      'thumbs-down',
      'sort-numeric-down-alt',
      'sort-numeric-up-alt',
      'sort-alpha-down-alt',
      'sort-alpha-up-alt',
      'sort-numeric-down',
      'sort-numeric-up',
      'sort-alpha-down',
      'sort-alpha-up',
      'sort-alt',
      'sort-amount-up',
      'sort-amount-down',
      'sort-amount-down-alt',
      'sort-amount-up-alt',
      'palette',
      'undo',
      'desktop',
      'sliders-v',
      'sliders-h',
      'search-plus',
      'search-minus',
      'file-excel',
      'file-pdf',
      'check-square',
      'chart-line',
      'user-edit',
      'exclamation-circle',
      'android',
      'google',
      'apple',
      'microsoft',
      'heart',
      'mobile',
      'tablet',
      'key',
      'shopping-cart',
      'comments',
      'comment',
      'briefcase',
      'bell',
      'paperclip',
      'share-alt',
      'envelope',
      'volume-down',
      'volume-up',
      'volume-off',
      'eject',
      'money-bill',
      'images',
      'image',
      'sign-in',
      'sign-out',
      'wifi',
      'sitemap',
      'chart-bar',
      'camera',
      'dollar',
      'lock-open',
      'table',
      'map-marker',
      'list',
      'eye-slash',
      'eye',
      'folder-open',
      'folder',
      'video',
      'inbox',
      'lock',
      'unlock',
      'tags',
      'tag',
      'power-off',
      'save',
      'question-circle',
      'question',
      'copy',
      'file',
      'clone',
      'calendar-times',
      'calendar-minus',
      'calendar-plus',
      'ellipsis-v',
      'ellipsis-h',
      'bookmark',
      'globe',
      'replay',
      'filter',
      'print',
      'align-right',
      'align-left',
      'align-center',
      'align-justify',
      'cog',
      'cloud-download',
      'cloud-upload',
      'cloud',
      'pencil',
      'users',
      'clock',
      'user-minus',
      'user-plus',
      'trash',
      'window-minimize',
      'window-maximize',
      'external-link',
      'refresh',
      'user',
      'exclamation-triangle',
      'calendar',
      'chevron-circle-left',
      'chevron-circle-down',
      'chevron-circle-right',
      'chevron-circle-up',
      'angle-double-down',
      'angle-double-left',
      'angle-double-right',
      'angle-double-up',
      'angle-down',
      'angle-left',
      'angle-right',
      'angle-up',
      'upload',
      'download',
      'ban',
      'star-o',
      'star',
      'chevron-left',
      'chevron-right',
      'chevron-down',
      'chevron-up',
      'caret-left',
      'caret-right',
      'caret-down',
      'caret-up',
      'search',
      'check',
      'check-circle',
      'times',
      'times-circle',
      'plus',
      'plus-circle',
      'minus',
      'minus-circle',
      'circle-on',
      'circle-off',
      'sort-down',
      'sort-up',
      'sort',
      'step-backward',
      'step-forward',
      'th-large',
      'arrow-down',
      'arrow-left',
      'arrow-right',
      'arrow-up',
      'bars',
      'arrow-circle-down',
      'arrow-circle-left',
      'arrow-circle-right',
      'arrow-circle-up',
      'info',
      'info-circle',
      'home',
      'spinner',
    ].map((icon: string) => new SelectOption(icon, 'pi pi-' + icon));
  }

  async getAllIcons(): Promise<SelectOption<string, string>[]> {
    return [...this.primengIcons, ...this.bootstrapIcons];
  }
}
