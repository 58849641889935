import { DataTypeValueCreateRequestDto, RecordDto } from '@api/models';
import { SelectOption } from '@shared/types/shared.types';

export class DataType {
  constructor(
    public name: string = '',
    public description: string = '',
    public kind: any = DataTypeKind.simple,
    public baseDataType: any = null,
    public details: DataTypeDetails = new DataTypeDetails(),
    public uri: string = '',
    public id: string = '',
    public applicationId: string = '',
    public created: RecordDto | null = null,
    public updated: RecordDto | null = null,
    public deleted: RecordDto | null = null,
    public values: EnumeratedOption[] = [],
  ) {}
}

export enum DataTypeKind {
  simple = 'SIMPLE',
  enumerated = 'ENUMERATED',
  bounded = 'BOUNDED',
  counter = 'COUNTER',
}

export class DataTypeDetails {
  constructor(public min: string | Date | null = null, public max: string | Date | null = null, public values: EnumeratedOption[] = []) {}
}

export class EnumeratedOption implements DataTypeValueCreateRequestDto {
  constructor(
    public label = '',
    public value: any = '',
    public icon = '',
    public uri = '',
    public backgroundColor: string | null = null,
    public textColor: string | null = null,
    public iconColor: string | null = null,
  ) {}
}

export enum BaseDataType { // maybe rename to BaseDataType and delete others
  integer = 'INTEGER',
  decimal = 'DECIMAL',
  boolean = 'BOOLEAN',
  text = 'TEXT',
  date = 'DATE',
  time = 'TIME',
  dateTime = 'DATETIME',
  user = 'USER',
  html = 'HTML',
  hyperlink = 'HYPERLINK',
  file = 'FILE',
}

export enum BaseDataTypeSimple { // maybe rename to BaseDataType and delete others
  integer = 'INTEGER',
  decimal = 'DECIMAL',
  boolean = 'BOOLEAN',
  text = 'TEXT',
  date = 'DATE',
  time = 'TIME',
  dateTime = 'DATETIME',
  user = 'USER',
  html = 'HTML',
  hyperlink = 'HYPERLINK',
  file = 'FILE',
}

export enum BaseDataTypeEnumerated {
  integer = 'INTEGER',
  text = 'TEXT',
}

export enum BaseDataTypeBoundedRange {
  integer = 'INTEGER',
  decimal = 'DECIMAL',
  date = 'DATE',
  time = 'TIME',
  dateTime = 'DATETIME',
}

export enum BaseDataTypeCounter {
  text = 'TEXT',
}

export enum LinkValidationType {
  source = 'SOURCE',
  destination = 'DESTINATION',
  none = 'NONE',
}

export class BaseDataTypeOptionsMap {
  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SIMPLE: SelectOption<typeof BaseDataTypeSimple, typeof BaseDataTypeSimple>[],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ENUMERATED: SelectOption<typeof BaseDataTypeEnumerated, typeof BaseDataTypeEnumerated>[],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public BOUNDED: SelectOption<typeof BaseDataTypeBoundedRange, typeof BaseDataTypeBoundedRange>[],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public COUNTER: SelectOption<typeof BaseDataTypeCounter, typeof BaseDataTypeCounter>[],
  ) {}
}
