import { Component, Input } from '@angular/core';
import { TemplatesService } from '@shared/components/templates/services/templates.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
})
export class TemplateDetailComponent {
  @Input() type: TemplateType;

  constructor(public templatesService: TemplatesService) {}
}
