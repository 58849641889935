<app-breakpoint-tabs
  (addBreakpoint)="addBreakpoint()"
  (deleteBreakpoint)="deleteBreakpoint($event)"
  (activeBreakpointIndexChange)="onActiveBreakpointChange($event)"
  [activeBreakpoint]="(layoutHolder.activeBreakpoint$ | async)!"
  [breakpoints]="layoutHolder.breakpoints"
  class="mb-3">
</app-breakpoint-tabs>

<div class="grid">
  <div class="col-6">
    <app-grid-tracks-generator
      (addTrack)="addColumn()"
      (deleteTrack)="deleteColumn($event)"
      (updateTrack)="updateGridTemplateColumns()"
      [trackType]="trackType.column"
      [tracks]="(columns$ | async)!">
    </app-grid-tracks-generator>
  </div>

  <div class="col-6">
    <app-grid-tracks-generator
      (addTrack)="addRow()"
      (deleteTrack)="deleteRow($event)"
      (updateTrack)="updateGridTemplateRows()"
      [trackType]="trackType.row"
      [tracks]="(rows$ | async)!">
    </app-grid-tracks-generator>
  </div>
</div>

<p-divider></p-divider>

<div class="mt-2 grid">
  <div class="col-6">
    <app-grid-gap-field
      (gapChange)="updateColumnGap($event)"
      [gap]="layoutHolder.container.columnGap.get(layoutHolder.activeBreakpoint)!"
      [trackType]="trackType.column">
    </app-grid-gap-field>
  </div>

  <div class="col-6">
    <app-grid-gap-field
      (gapChange)="updateRowGap($event)"
      [gap]="layoutHolder.container.rowGap.get(layoutHolder.activeBreakpoint)!"
      [trackType]="trackType.row">
    </app-grid-gap-field>
  </div>
</div>

<p-divider></p-divider>

<div class="mt-3 grid">
  <div class="py-0 col">
    <app-grid-areas-generator
      (addArea)="addArea()"
      (deleteArea)="deleteArea($event)"
      [areas]="layoutHolder.areas"
      [isAddingDisabled]="(areaAddingDisabled$ | async)!"
      [breakpoint]="layoutHolder.activeBreakpoint"
      [visibilityToggleDisabled]="false">
    </app-grid-areas-generator>
  </div>
</div>
