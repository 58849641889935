import { EMPTY_GROUP_HEADER_SUFFIX, EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { GroupAttributeItem, GroupItem, GroupingSettings } from '../../types/list-widget-grouping.types';
import { AbstractGroupingHandler } from './abstract-grouping-handler';

export class UserGroupingHandler extends AbstractGroupingHandler {
  users: ListContainer<NewUser>;

  constructor(users: ListContainer<NewUser>) {
    super();
    this.users = users;
  }

  getAllGroups(groupingSettings: GroupingSettings): Record<string, GroupItem> {
    groupingSettings;
    const groupMap: Record<string, GroupItem> = {};
    this.users.list.forEach(
      (user, index) =>
        (groupMap[user.id] = {
          id: user.id,
          artifacts: [],
          metaData: { isDisplayed: true, count: 1, header: user.email, toggled: false },
          isDefaultGroup: false,
          sortOrder: index,
          total: 0,
        }),
    );
    return groupMap;
  }

  getGroupingHeader(groupId: string): string {
    if (groupId === EMPTY_GROUP_VALUE) {
      return EMPTY_GROUP_HEADER_SUFFIX;
    }
    return this.users.listMap[groupId]?.email || '';
  }

  getGroupingValue(): string {
    return '';
  }

  getGroupId(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem): string {
    if (NonAttributeKeys.isUserSpecificAttributeKey(groupAttributeItem.id)) {
      return SharedMethods.getValueFromPath(groupAttributeItem.id, artifact);
    }
    const groupValue = artifact.attributes[groupAttributeItem.id].value;
    if (!groupValue || !groupValue.length) return EMPTY_GROUP_VALUE;
    return groupValue[0].value || groupValue[0];
  }

  getFilterMetadataForGroupItem(groupItem: GroupItem): FilterMetadata {
    return {
      matchMode: FilterMatchMode.IN,
      operator: FilterOperator.AND,
      value: [groupItem.id],
    };
  }

  sortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[] {
    return this.doSortAndSetSortOrderValue(groupItems, groupingSettings, false);
  }
}
