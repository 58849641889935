import { Background } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

export class PageStyles implements Background {
  backgroundType: BackgroundTypeEnum;
  backgroundColor: string;
  backgroundImage?: string;
  background: string;

  constructor(styles?: Partial<PageStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }
  }
}
