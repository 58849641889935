import { NgModule } from '@angular/core';
import { RuleAttributeChangeValueNotifyDirective } from '@workflows/directives';
import {
  FormatAttributeDirective,
  ModifyAttributeValueDirective,
  SetAttributeOptionValuesDirective,
  SetAttributeValueDirective,
  SetLinkValueDirective,
  ShowHideAttributeDirective,
} from '@workflows/shared';
import { WorkflowsComponentsModule } from './components';
import { WorkflowsPipesModule } from './pipes';
import { SetAttributeMandatoryDirective } from './shared/directives/set-attribute-mandatory.directive';

const directives = [
  RuleAttributeChangeValueNotifyDirective,
  ShowHideAttributeDirective,
  SetAttributeValueDirective,
  ModifyAttributeValueDirective,
  SetLinkValueDirective,
  SetAttributeOptionValuesDirective,
  FormatAttributeDirective,
  SetAttributeMandatoryDirective,
];

@NgModule({
  declarations: [...directives],
  imports: [WorkflowsComponentsModule, WorkflowsPipesModule],
  exports: [WorkflowsComponentsModule, WorkflowsPipesModule, ...directives],
})
export class WorkflowsModule {}
