import { PageBlockPartRequestDto } from '@api/models/page-block-part-request-dto';
import { PageBlockPartWidgetRequestDto } from '@api/models/page-block-part-widget-request-dto';
import { PageRowBlockRequestDto } from '@api/models/page-row-block-request-dto';
import { PageSectionRequestDto } from '@api/models/page-section-request-dto';
import { PageSectionRowRequestDto } from '@api/models/page-section-row-request-dto';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';

export type TemplateCreateBody =
  | PageSectionRequestDto
  | PageSectionRowRequestDto
  | PageRowBlockRequestDto
  | PageBlockPartRequestDto
  | PageBlockPartWidgetRequestDto;

export type TemplateDownloadBody = SectionDownloadDto | RowDownloadDto | BlockDownloadDto | PartDownloadDto | WidgetDownloadDto;

export interface TemplatablePagePart {
  templateId: string | null;
}

export interface SectionDownloadDto extends TemplatablePagePart {
  rows: RowDownloadDto[];
}

export interface RowDownloadDto extends TemplatablePagePart {
  blocks: BlockDownloadDto[];
  layout: string[];
}

export interface BlockDownloadDto extends TemplatablePagePart {
  parts: PartDownloadDto[];
}

export interface PartDownloadDto extends TemplatablePagePart {
  widget: WidgetDownloadDto | null;
}

export interface WidgetDownloadDto {
  code: string;
  value: any;
  settings?: any;
}

export class TemplatesModel {
  columns: TableColumn[];

  exportModalOpened = false;
  importModalOpened = false;
  selectTemplates = false;
  applicationId: string;
  applications = new ListContainer<NewApplication>();
}
