import {
  NumberFilterMatchType,
  NumberFilterRuleType,
} from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter-options.types';

export class NumberFilter {
  matchType: NumberFilterMatchType;
  ruleTypes: NumberFilterOption[];

  constructor(dto?: NumberFilter) {
    if (dto) {
      Object.assign(this, dto);
      return;
    }
    this.matchType = NumberFilterMatchType.matchAny;
    this.ruleTypes = [new NumberFilterOption()];
  }
}

export class NumberFilterOption {
  ruleType: NumberFilterRuleType;
  value: number | NumberFilterRange | null;

  constructor() {
    this.ruleType = NumberFilterRuleType.equals;
  }
}

export class NumberFilterRange {
  from: number;
  to: number;
  fromIsInclude = false;
  toIsInclude = false;

  constructor(dto?: NumberFilterRange) {
    dto && Object.assign(this, dto);
  }

  getUrlString(): string {
    const signFrom = this.fromIsInclude ? 'gte' : 'gt';
    const signTo = this.toIsInclude ? 'lte' : 'lt';
    return `${signFrom},${this.from},${signTo},${this.to}`;
  }
}
