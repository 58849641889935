<div class="header">
  <div class="mr">
    <button pButton pRipple [label]="'Export' | translate" icon="pi pi-cloud-download" (click)="export()"></button>
  </div>
  <div class="mr">
    <p-fileUpload #importField mode="basic" name="files[]" accept=".xlsm,.xlsx,.xls"
                  (uploadHandler)="import($event, importField)" [fileLimit]="1" [customUpload]="true"
                  uploadIcon="pi pi-upload"
                  chooseIcon="pi pi-cloud-upload"
                  chooseLabel="Import"></p-fileUpload>
  </div>
  <a [routerLink]="['/admin/artifact']">
    <button pButton label="{{'Add' | translate }}" icon="pi pi-plus" iconPos="left"></button>
  </a>
</div>

<div class="grid">
  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [filter]="true"
                  (onChange)="onArtifactTypeChange($event)"
                  [options]="m.artifactTypeOptions"
                  [(ngModel)]="m.artifactType"
                  id="artifactType"></p-dropdown>
      <label class="dynamic-label-text" for="artifactType">{{'Artifact Type' | translate}}</label>
    </span>
  </div>

  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label">
      <p-multiSelect id="attributes" inputId="attributes"
                     (onChange)="s.filterTableColumns()"
                     [maxSelectedLabels]="5"
                     [filter]="true"
                     [options]="m.attributeOptions"
                     [(ngModel)]="m.selectedAttributeIds"
                     [disabled]="!m.artifactType">
      </p-multiSelect>
      <label class="dynamic-label-text" for="attributes">{{'Attributes' | translate}}</label>
    </span>
  </div>
</div>

<app-artifact-list-table #artifactList
                         *ngIf="m.artifactType && m.showTable"
                         [columns]="m.columns"
                         [artifactTypes]="m.artifactTypes"
                         [artifactType]="m.artifactType"
                         [attributes]="m.attributes"
                         [users]="m.users"
                         [dataTypes]="m.dataTypes"
                         [loadDataMethod]="loadDataMethod()"
                         [sortByAttribute]="true">
</app-artifact-list-table>
