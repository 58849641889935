import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DateRangeFilterEnum } from '@shared/types/filter.types';

@Pipe({ name: 'shouldShowCustomRangeCalendarOption' })
export class ShouldShowCustomRangeCalendarOptionPipe implements PipeTransform {
  transform(dateRange: DateRangeFilterEnum): boolean {
    return dateRange === DateRangeFilterEnum.custom;
  }
}

@NgModule({
  declarations: [ShouldShowCustomRangeCalendarOptionPipe],
  exports: [ShouldShowCustomRangeCalendarOptionPipe],
})
export class ShouldShowCustomRangeCalendarOptionPipeModule {}
