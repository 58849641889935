import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { FadeAnimation } from '@shared/animations/animations';
import { Constants } from '@shared/constants/constants';
import { SharedMethods } from '@shared/methods/shared.methods';
import { ElvisActionService } from '@shared/services/elvis-action.service';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuStylesPropNamesEnum } from '../../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../../types/menu-widget.types';
import { LinkQueryParamMap, MenuItemBehaviorEnum, MenuItemCurrentPageBehaviorEnum, TreeItem } from '../../types/tree-types';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [FadeAnimation],
})
export class MenuItemComponent {
  @Input() item: MenuItem | TreeItem;
  @Input() m: MenuWidgetModel;
  @Input() stopPropagation = true;
  @Input() queryParams: Params;

  menuStylesPropNamesEnum = MenuStylesPropNamesEnum;
  menuItemBehavior = MenuItemBehaviorEnum;
  subMenuMouseOvered = false;
  subMenuDomRectangle: DOMRect;

  constructor(private readonly elvisActionService: ElvisActionService, private readonly router: Router, private readonly route: ActivatedRoute) {}

  get mouseOvered(): boolean {
    return this.item.behavior !== MenuItemBehaviorEnum.textOnly && this.m.settings.item[this.item.hash].mouseOvered;
  }

  get onlyIconInsideItem(): boolean {
    return !this.item.subLabel && !this.item.label;
  }

  onMouseEnter(event: MouseEvent, item: MenuItem): void {
    if (item.behavior === MenuItemBehaviorEnum.textOnly) {
      this.m.settings.item[item.hash].mouseOvered = true;
    }
    if (this.shouldComputeSubmenuOffset()) {
      this.subMenuDomRectangle = this.getSubmenuDomRectangle(event.target as Element);
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.openSubMenu();
    }
  }

  onMouseLeave(item: MenuItem): void {
    if (item.behavior === MenuItemBehaviorEnum.textOnly) {
      this.m.settings.item[item.hash].mouseOvered = false;
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.closeSubMenu();
    }
  }

  async navigateWithRouter(item: MenuItem): Promise<any> {
    const urlTreeRoute = item.useAlias ? [item.alias] : [Constants.page, item.url];
    const navigationExtra: NavigationExtras = { queryParams: item.queryParamsListToMap().parametersToAdd };
    item.fragmentParam && (navigationExtra.fragment = item.fragmentParam);

    const urlTree = this.router.createUrlTree(urlTreeRoute, navigationExtra);
    await this.router.navigateByUrl(urlTree);
  }

  async onAnchorClick($event: Event, item: MenuItem | TreeItem, isLink: boolean): Promise<void> {
    $event.preventDefault();
    this.stopPropagation && $event.stopPropagation();

    if (this.isAdvancedMode()) return;
    if (this.shouldOpenSubmenuOnClick()) this.item.expanded = !this.item.expanded;
    if (this.shouldFireElvisAction()) return await this.elvisActionService.fireElvisAction(this.item.defaultElvisAction, this.m.items.menu, this.queryParams);
    if (!this.shouldStayOnSamePage() && this.shouldUseRouterNavigation(item, isLink)) return await this.navigateWithRouter(item);

    if (!this.shouldStayOnSamePage() && this.shouldUseWindowNavigation()) {
      await window.open(this.item.url, '_blank', 'noopener');
      return;
    }

    if (this.shouldStayOnSamePage()) {
      await this.setQueryParamsFromLinkItem();
      if (this.item.fragmentParam) {
        const offset = SharedMethods.computeStickyOffset();
        SharedMethods.scrollToHtmlElement(this.item.fragmentParam, offset);
      }
    }
  }

  private isAdvancedMode(): boolean {
    return this.item instanceof TreeItem;
  }

  private shouldOpenSubmenuOnHover(): boolean {
    return this.m.settings.menu.type !== MenuTypesEnum.panel || this.m.settings.menu.openSubmenuOnHover;
  }

  private shouldOpenSubmenuOnClick(): boolean {
    return !this.shouldOpenSubmenuOnHover();
  }

  private openSubMenu(): void {
    this.item.expanded = true;
  }

  private closeSubMenu(): void {
    setTimeout(() => {
      if (!this.m.settings.item[this.item.hash].mouseOvered && !this.subMenuMouseOvered) {
        this.item.expanded = false;
      }
    }, 300);
  }

  private async setQueryParamsFromLinkItem(): Promise<void> {
    const menuLinkQueryParamsMap = this.item.queryParamsListToMap();
    const queryParams = this.getQueryParameters(menuLinkQueryParamsMap);
    const navigationExtras = this.getNavigationExtras(queryParams);

    await this.router.navigate([], navigationExtras);
  }

  private getQueryParameters(menuLinkQueryParamsMap: LinkQueryParamMap): Params {
    const queryParams = { ...this.queryParams, ...menuLinkQueryParamsMap.parametersToAdd };
    menuLinkQueryParamsMap.parameterKeysToRemove.forEach((paramKey: string) => delete queryParams[paramKey]);
    return queryParams;
  }

  private getNavigationExtras(queryParams: Params): NavigationExtras {
    const navigationExtras: NavigationExtras = { queryParams, relativeTo: this.route, replaceUrl: true };
    this.item.fragmentParam && (navigationExtras.fragment = this.item.fragmentParam);
    return navigationExtras;
  }

  private shouldUseRouterNavigation(item: MenuItem, isLink: boolean): boolean {
    if (!isLink || !item.url) return false;
    if (!item.alias && item.useAlias) return false;
    return item.isPageSelection;
  }

  private shouldUseWindowNavigation(): boolean {
    return !this.item.isPageSelection && !!this.item.url;
  }

  private shouldStayOnSamePage(): boolean {
    return this.item.currentPageBehavior === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage;
  }

  private shouldFireElvisAction(): boolean {
    return this.item.behavior === MenuItemBehaviorEnum.action;
  }

  // Computing offset is done only in horizontal menu, on TOP level, if item has children and is not yet expanded
  private shouldComputeSubmenuOffset(): boolean {
    return (
      !!this.item.children.length &&
      !this.item.expanded &&
      this.m.settings.menu.type === MenuTypesEnum.horizontal &&
      new IsItemInFirstLevelPipe().transform(this.m.items.menu, this.item)
    );
  }

  private getSubmenuDomRectangle(target: Element): DOMRect {
    return target.getBoundingClientRect();
  }
}
