import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarMenuItemCompatible } from '@shared/types/sidebar-menu.types';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SidebarMenuService } from '../services/sidebar-menu.service';
import { SidebarMenuItem } from '../types/sidebar-menu.types';
import { MenuItemAnimation } from './animations/sidebar-menu-item-animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-sidebar-menu-item]',
  templateUrl: 'sidebar-menu-item.component.html',
  animations: [MenuItemAnimation],
})
export class SidebarMenuItemComponent<T extends SidebarMenuItemCompatible> implements OnInit, OnDestroy {
  @Input() app: T;
  @Input() item: SidebarMenuItem;
  @Input() index: number;
  @HostBinding('class.layout-root-menuitem') @Input() root: boolean;
  @HostBinding('class.active-menuitem') activeMenuItem = false;
  @Input() parentKey: string;

  active = false;
  menuSourceSubscription$: Subscription;
  menuResetSubscription$: Subscription;
  key: string;
  slimClick = true;

  constructor(public readonly router: Router, private readonly cd: ChangeDetectorRef, private readonly sidebarMenuService: SidebarMenuService) {
    this.menuSourceSubscription$ = this.sidebarMenuService.menuSource$.subscribe(key => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
    });

    this.menuResetSubscription$ = this.sidebarMenuService.resetSource$.subscribe(() => (this.active = false));

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => (this.app.isSlim() ? (this.active = false) : this.item.routerLink && this.updateActiveStateFromRoute()));
  }

  ngOnInit(): void {
    this.activeMenuItem = (this.active && !this.root) || (this.active && this.app.isSlim());
    !this.app.isSlim() && this.item.routerLink && this.updateActiveStateFromRoute();
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
  }

  updateActiveStateFromRoute(): void {
    this.active = this.router.isActive((Array.isArray(this.item.routerLink) && this.item.routerLink[0]) || '', !this.item.items);
  }

  itemClick(event: Event): boolean | void {
    this.app.isSlim() && (this.slimClick = true);
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }
    // navigate with hover in horizontal mode
    this.root && (this.app.menuHoverActive = !this.app.menuHoverActive);
    // notify other items
    this.sidebarMenuService.onMenuStateChange(this.key);
    // execute command
    this.item.command && this.item.command({ originalEvent: event, item: this.item });
    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;
      this.app.isMobile() && (this.app.staticMenuMobileActive = false);
      // reset horizontal menu
      if (this.app.isSlim()) {
        this.sidebarMenuService.reset();
        this.app.menuHoverActive = false;
      }
    }
  }

  onMouseEnter(): void {
    // activate item on hover
    if (this.root && this.app.isSlim() && this.app.isDesktop()) {
      if (this.app.menuHoverActive) {
        this.sidebarMenuService.onMenuStateChange(this.key);
        this.slimClick = false;
        this.active = true;
      } else {
        this.slimClick = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.menuSourceSubscription$ && this.menuSourceSubscription$.unsubscribe();
    this.menuResetSubscription$ && this.menuResetSubscription$.unsubscribe();
  }
}

@NgModule({
  imports: [TranslateModule, CommonModule, RouterModule],
  declarations: [SidebarMenuItemComponent],
  exports: [SidebarMenuItemComponent],
})
export class SidebarMenuItemModule {}
