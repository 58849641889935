import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewUser } from '@shared/types/user.types';
import { DbEntityCachedData } from '@shared/utils/db-entity-cached-data.subject';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { Subscription } from 'rxjs';

export abstract class CardWidgetOptionsService {
  m: CardWidgetModel;
  protected c: CardWidgetComponent;
  protected options: CardWidgetOptions = new CardWidgetOptions();

  protected constructor(
    protected readonly cache: NewCacheService,
    private readonly customAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    private readonly filtersService: ArtifactFiltersService,
  ) {
    this.options.systemAttributes.setList(this.filtersService.getSystemAttributes(), 'id');
  }

  protected async initAllPossibleOptions(): Promise<void> {
    const { applications, artifactTypes, attributes, dataTypes, users, linkTypes, pages } = this.cache.data;
    const subscriptions = await Promise.all([
      this.getApplicationsSubscription(applications),
      this.getArtifactTypesSubscription(artifactTypes),
      this.getAttributesSubscription(attributes),
      this.getDataTypesSubscription(dataTypes),
      this.getUsersSubscription(users),
      this.getLinkTypesSubscription(linkTypes),
      this.getPagesSubscription(pages),
    ]);

    this.c.registerSubscriptions(subscriptions);
  }

  protected async initConditionalOptions(): Promise<void> {
    if (!this.m.settings.isArtifactTypeSelected) {
      return;
    }

    await this.generateAttributeOptions();
  }

  protected async generateAttributeOptions(): Promise<void> {
    this.c.m.options.clientAttributeOptions = [
      ...this.getArtifactAttributeOptions(),
      ...this.customAttributeHelper.getCustomAttributeOptions(),
      ...(await this.getArtifactLinkTypeOptions()),
    ];
  }

  private getApplicationsSubscription(applications: DbEntityCachedData<ApplicationResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = applications.subscribe(applications => {
        this.options.applications.setList(applications as ApplicationResponseDto[], 'id');
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getArtifactTypesSubscription(artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = artifactTypes.subscribe(artifactTypes => {
        this.options.artifactTypes.setList(
          (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getAttributesSubscription(attributes: DbEntityCachedData<AttributeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = attributes.subscribe(attributes => {
        this.options.attributes.setList(
          (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getDataTypesSubscription(dataTypes: DbEntityCachedData<DataTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = dataTypes.subscribe(dataTypes => {
        this.options.dataTypes.setList(
          (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getUsersSubscription(users: DbEntityCachedData<UserResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = users.subscribe(users => {
        this.options.users.setList(
          (users as UserResponseDto[]).map(dto => new NewUser(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getLinkTypesSubscription(linkTypes: DbEntityCachedData<LinkTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = linkTypes.subscribe(linkTypes => {
        this.options.linkTypes.setList(
          (linkTypes as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getPagesSubscription(pages: DbEntityCachedData<PageResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = pages.subscribe(pages => {
        this.options.pages.setList(
          (pages as PageResponseDto[]).map(dto => new Page(dto)),
          'id',
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getArtifactAttributeOptions(): SelectOption<string, NewClientAttribute>[] {
    const { attributes } = this.options.artifactTypes.listMap[this.m.settings.artifactTypeId];

    return Object.values(attributes).map((attribute: NewArtifactTypeClientAttribute) => {
      return new SelectOption(
        this.options.attributes.listMap[attribute.id].name,
        new NewClientAttribute({
          id: attribute.id,
          isMandatory: attribute.isMandatory,
          value: attribute.initialValue,
        }),
      );
    });
  }

  private async getArtifactLinkTypeOptions(): Promise<SelectOption<any, any>[]> {
    const linkRestrictions = this.c.isListMatrixCard
      ? LinkMethods.getLinkRestrictionsForArtifactType(this.m.settings.artifactTypeId, this.options.linkTypes.list)
      : this.getLinkRestrictionsForArtifactType(this.m.settings.artifactTypeId);
    let options: SelectOption<string, LinkType, LinkDirection>[] = [];

    try {
      options = this.getLinkTypeOptionsForLinkRestrictions(linkRestrictions);
    } catch (e) {
      console.error('Failed to load link types', e);
    }

    return options;
  }

  private getLinkRestrictionsForArtifactType(artifactTypeId: string): ArtifactTypeLinkRestriction[] {
    const linkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(dto =>
      dto?.restrictions?.some(r => r.sourceArtifactTypeId === artifactTypeId || r.destinationArtifactTypeId === artifactTypeId),
    );

    return LinkMethods.getLinkRestrictionsForArtifactType(artifactTypeId, linkTypes);
  }

  private getLinkTypeOptionsForLinkRestrictions(linkRestrictions: ArtifactTypeLinkRestriction[]): SelectOption<string, LinkType, LinkDirection>[] {
    const relevantLinkTypes = LinkMethods.getLinkTypesFromRestrictions(linkRestrictions);
    const usedLinks = new Set();

    return relevantLinkTypes.reduce(
      (options: SelectOption<string, LinkType, LinkDirection>[], relevantLinkType: SelectOption<string, string, LinkDirection>) => {
        const linkType = relevantLinkType.value ? this.options.linkTypes.listMap[relevantLinkType.value] : null;
        const linkName = relevantLinkType.value + '_' + relevantLinkType.meta;

        if (linkType?.id && !usedLinks.has(linkName)) {
          usedLinks.add(linkName);

          return [...options, new SelectOption(relevantLinkType.label || '', linkType, relevantLinkType.meta)];
        }

        return options;
      },
      [],
    );
  }
}
