import { Pipe, PipeTransform } from '@angular/core';
import { MyArtifact } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

@Pipe({ name: 'getAttributeIcon' })
export class GetAttributeIconPipe implements PipeTransform {
  transform(artifactItem: MyArtifact | undefined, attributeId: string, options: CardWidgetOptions): unknown {
    if (artifactItem && artifactItem[attributeId]) return artifactItem[attributeId].icon;
    return options.attributes.listMap[attributeId]?.icon || '';
  }
}
