import { Component, Input, OnDestroy } from '@angular/core';
import { TenantArtifactService } from '@api/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnDestroy {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  artifactBlobUrl: string | null = null;

  @Input() width = '100%';
  @Input() height = '7rem';

  private _artifactId: string | null = null;
  @Input() set artifactId(artifactId: string | null) {
    if (this.artifactBlobUrl) this.clearArtifactBlobUrl();
    this._artifactId = artifactId;
    this.getArtifactBlob();
  }

  get artifactId(): string | null {
    return this._artifactId;
  }

  constructor(private readonly tenantArtifactService: TenantArtifactService) {
  }

  ngOnDestroy(): void {
    this.clearArtifactBlobUrl();
  }

  private getArtifactBlob(): void {
    this.$loading.next(true);
    if (!this.artifactId) {
      this.artifactBlobUrl = null;
      this.$loading.next(false);
      return;
    }
    this.tenantArtifactService.artifactControllerDownload({ id: this.artifactId! }).subscribe((artifactBlob: Blob) => {
      this.artifactBlobUrl = URL.createObjectURL(artifactBlob);
      this.$loading.next(false);
    });
  }

  private clearArtifactBlobUrl(): void {
    if (this.artifactBlobUrl) {
      URL.revokeObjectURL(this.artifactBlobUrl);
      this.artifactBlobUrl = null;
    }
  }
}
