<ng-container *ngIf="action.type !== 'CALCULATE'">
  <div class="action-calculated-attribute-element">
    <div *ngFor="let attributeId of action.calculationAttributeIds; trackBy: item; let index = index">
      <app-workflow-attribute-selector
        [selectedAttributeId]="attributeId"
        [attributes]="
          selectedAttributes
            | getWorkflowCalculationActionAttributes
              : attributeId
              : action.calculationAttributeIds
              : dataTypes
              : action.getSupportedDataTypes()
              : action.getSupportedDataKinds()
        "
        (attributeChange)="onCalculationAttributeChange($event, index)"
        [editable]="editable"
      >
      </app-workflow-attribute-selector>
      <button
        *ngIf="attributeId"
        pButton
        label="{{ action.getCalculationMark() || '+' }}"
        class="p-button p-button-info btn-workflows"
        (click)="addNewCalculationAttribute(index)"
      ></button>
    </div>
  </div>

  <ng-container *ngIf="action.calculationAttributeIds?.length > 1 && action.calculationAttributeIds[1]">
    {{ 'WORKFLOWS.COMMON.SET_INTO' | translate }}
    <app-workflow-attribute-selector
      [selectedAttributeId]="action.attributeId"
      [attributes]="
        selectedAttributes | getWorkflowCalculationActionAttributes : '' : [] : dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds()
      "
      (attributeChange)="onAttributeChange($event)"
      [editable]="editable"
    >
    </app-workflow-attribute-selector>
  </ng-container>
</ng-container>

<ng-container *ngIf="action.type === 'CALCULATE'">
  <div class="action-calculated-attribute-element">
    <app-rule-action-calculate-expression
      [action]="action"
      [dataTypes]="dataTypes"
      [selectedAttributes]="selectedAttributes"
      (expressionValid)="onExpressionValidation($event)"
      [editable]="editable"
    >
    </app-rule-action-calculate-expression>
  </div>
  <ng-container *ngIf="actionValid">
    {{ 'WORKFLOWS.COMMON.SET_INTO' | translate }}
    <app-workflow-attribute-selector
      [selectedAttributeId]="action.attributeId"
      [attributes]="
        selectedAttributes | getWorkflowCalculationActionAttributes : '' : [] : dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds()
      "
      (attributeChange)="onAttributeChange($event)"
      [editable]="editable"
    >
    </app-workflow-attribute-selector>
  </ng-container>
</ng-container>
