import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactResponseDto } from '@api/models';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { NewAttribute } from '@shared/types/attribute.types';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { AttributeVisibleSettable } from '@workflows/shared/types/attribute-visible-settable';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { FileService } from '../../services/file.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { UploadProgressModule } from '@widgets/shared/components/upload-progress/upload-progress.component';

@Component({
  selector: 'app-file-attribute-value-edit-field',
  templateUrl: './file-attribute-value-edit-field.component.html',
  styleUrls: ['./file-attribute-value-edit-field.component.scss'],
})
export class FileAttributeValueEditFieldComponent implements AttributeVisibleSettable {
  @Input() attribute: NewAttribute;
  @Input() isEditMode: boolean;
  @Input() truncate: boolean;
  @Input() label: string;
  @Input() index: number;
  @Input() onChangeCb: (isShowSaveButton?: boolean) => void;
  @Input() isMandatory: boolean;
  @Input() isEnabled = true;
  @Input() fileValue: any;
  @Input() formatSettings?: AttributeFormatSettings;
  @Input() isInSavingProcess: boolean;
  @Output() fileValueChange = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: FileUpload;

  constructor(private fileService: FileService) {}

  visible = true;

  onFilesAdd(event: { files: File[] }): void {
    setTimeout(() => {
      if (!event.files.length) return;
      if (this.fileUpload.multiple) {
        !this.fileValue && (this.fileValue = []);
        event.files.forEach(file => this.fileValue.push(file));
      } else {
        this.fileValue = event.files[0];
      }
      this.fileUpload.clear();
      this.onChange();
      this.fileValueChange.emit(this.fileValue);
    });
  }

  onFileRemove(index = null): void {
    if (this.fileUpload.multiple) {
      this.fileValue.splice(index, 1);
    } else {
      this.fileValue = '';
    }
    this.onChange();
    this.fileValueChange.emit(this.fileValue);
  }

  onFileDownload(fileArtifact: ArtifactResponseDto): any {
    if (fileArtifact.id) return this.fileService.downloadFileArtifact(fileArtifact);
  }

  setAttributeVisible(visible: boolean) {
    this.visible = visible;
  }

  private onChange(): void {
    this.onChangeCb && this.onChangeCb(true);
  }
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ChipsModule,
    MultiSelectModule,
    SliderModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    ProgressBarModule,
    EditorModule,
    FileUploadModule,
    TranslateModule,
    ElvisSharedModule,
    UploadProgressModule,
  ],
  exports: [FileAttributeValueEditFieldComponent],
  declarations: [FileAttributeValueEditFieldComponent],
})
export class FileAttributeValueEditFieldModule {}
