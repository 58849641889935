<div *ngIf="isLayoutMode; else advancedModeTemplate">

  <div [ngStyle]="{ 'textAlign': m.settings.alignButton }" [id]="'cont' + m.id" class="content-container">
    <div *ngIf="isShowButton && currentButton"
         class="btn-container cursor-pointer center"
         (mouseenter)="m.isHover = true"
         (mouseleave)="m.isHover = false"
         (click)="s.toggleModal()"
         [ngStyle]="buttonStyles"
    >
      <span *ngIf="currentButton.icon" [class]="currentButton.icon + ' sm-icon'"
            [ngStyle]="currentButton.iconStyle"></span>
      <span *ngIf="currentButton.chip" [ngStyle]="currentButton.chipStyle" class="chip">{{ currentButton.chip }}</span>
      <span [ngStyle]="currentButton.labelStyle" class="chip">{{ currentButton.label }}</span>
      <br>
      <span *ngIf="currentButton.subLabel" [ngStyle]="currentButton.subLabelStyle"
            class="chip">{{ currentButton.subLabel }}</span>
    </div>
  </div>

  <div *ngIf="(m.settings.isOverlay || m.settings.isCloseByOverlayClick) && m.isOpen" (click)="s.onOverlayClick()" [ngStyle]="m.settings.overlayStyles" [id]="m.id + '-over'" class="s-over"></div>

  <div *ngIf="m.isOpen" class="s-modal" [id]="m.id" [ngStyle]="m.styles">
    <i *ngIf="isShowButton" (click)="s.toggleModal()"
       class="bi bi-x-circle"></i>

    <ng-container *ngIf="isPreviewMode">
      <section
        *ngFor="let section of m.page.sections; let sectionIndex = index"
        [ngClass]="section | sectionClass"
        [ngStyle]="section.styles?.outerContainer"
        [sectionIndex]="sectionIndex"
        class="section"
      >
        <div [ngClass]="section.styles?.innerContainer.containerClass" [ngStyle]="section.styles?.innerContainer"
             [attr.id]="section.htmlId">
          <div *ngFor="let row of section.rows; let rowIndex = index" [ngStyle]="row.styles" class="row g-0">
            <div *ngFor="let block of row.blocks; let blockIndex = index"
                 [class]="'col-md-' + row.layout[blockIndex]">
              <div
                *ngFor="let part of block.parts; let partIndex = index"
                [blockIndex]="blockIndex"
                [ngStyle]="part.styles"
                [partIndex]="partIndex"
                [rowIndex]="rowIndex"
                [sectionIndex]="sectionIndex"
                [attr.id]="part.htmlId"
                class="block-part"
              >
                <div *ngIf="part.widget" [id]="part.hash">
                  <app-widget-container
                    [applicationId]="m.page.applicationId"
                    [pageId]="pageId"
                    [page]="originalPage"
                    [disabled]="true"
                    [hash]="part.hash"
                    [isLayoutMode]="true"
                    [isPreviewMode]="true"
                    [widget]="part.widget"
                    appWidgetContainerStopPropagation
                  ></app-widget-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <ng-container *ngIf="!isPreviewMode">
      <div *ngIf="m.page.sections?.length" class="sm-page">
        <div
            (cdkDropListDropped)="dropSection($event)"
            [cdkDropListData]="{ modalId: m.page.modalId }"
            [cdkDropListAutoScrollStep]="3" cdkDropList
            cdkDropListLockAxis="y" class="sm-page_content"
        >
          <app-page-section
            *ngFor="let section of m.page.sections; index as sectionIndex"
            [sectionIndex]="section.index"
            [section]="section"
            [page]="m.page"
            [modalId]="m.id"
            class="page-section"
          ></app-page-section>
        </div>
      </div>

      <section *ngIf="!m.loading" class="empty-page-section">
        <button
          (click)="s.showSectionTemplates()"
          class="p-button-rounded p-button-info p-button-outlined"
          icon="pi pi-plus"
          pButton
          pRipple
          pTooltip="{{ 'Add new section' | translate }}"
          type="button"
        ></button>
      </section>
    </ng-container>
  </div>
</div>

<ng-template #advancedModeTemplate>
  <app-sidebar-modal-widget-settings [options]="m.options" [s]="s" [styles]="m.settings.styles" [settings]="m.settings"
                                     (onChangeSettings)="onChangeSettings($event)"></app-sidebar-modal-widget-settings>
</ng-template>
