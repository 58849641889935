<p>Please try to go <a href="https://cssgradient.io/" target="_blank">here</a>, make the gradient settings, copy
  and paste inside the text field</p>
<textarea style="width: 100%;"
          rows="1"
          pInputTextarea
          autoResize="autoResize"
          (paste)="onGradientPaste($event)"
          [placeholder]="'Type gradient settings...' | translate"
          (ngModelChange)="gradientChangeCb?.()"
          [(ngModel)]="styles.background">
</textarea>