import { Component, Input } from '@angular/core';
import { StickinessPosition } from '@private/pages/page-management/page-builder-graphical/types/stickiness-position';
import { StickinessScope } from '@private/pages/page-management/page-builder-graphical/types/stickiness-scope';
import { StickyPositioningSettings } from '@shared/types/sticky-positioning-settings';

@Component({
  selector: 'app-sticky-positioning',
  templateUrl: './sticky-positioning.component.html',
  styleUrls: ['./sticky-positioning.component.scss'],
})
export class StickyPositioningComponent {
  @Input() settings: StickyPositioningSettings;

  readonly position: typeof StickinessPosition = StickinessPosition;
  readonly scope: typeof StickinessScope = StickinessScope;
}
