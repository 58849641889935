<app-chart-widget-settings *ngIf="!isLayoutMode" [m]="m" [s]="s"></app-chart-widget-settings>

<div class="chart-cont">
    <div *ngIf="isLayoutMode" [id]="chartId">
        <app-chart-view
                [type]="m.settings.chartType"
                [data]="m.options.chartData"
                [options]="m.settings.chartOptions"
                [selected]="m.selected"
                [isShowLegend]="m.settings.chartOptions.isShowLegend"
                [updateTotalPosition]="m.updateTotal"
        ></app-chart-view>
    </div>
</div>
