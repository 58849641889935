import { OslcRootServicesCreateDto } from '@private/pages/current-tenant/types/current-tenant.types';

export class TenantOslcRootServicesModel {
  addNewMode = false;
  newOslcRootService: OslcRootServicesCreateDto = {
    domain: '',
    consumerKey: '',
    consumerSecret: '',
  };
}
