import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { TemplatesCloseResultRole } from '@shared/components/templates/types/templates.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { TemplateService } from '@shared/services/page-management/template.service';
import { SidebarWidgetService } from '@shared/services/sidebar-widget.service';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { SidebarWidgetCopyPasterService } from '@widgets/sidebar-widget/services/sidebar-widget-copy-paster.service';
import { SidebarService } from '@widgets/sidebar-widget/services/sidebar.service';
import { SidebarWidgetModel, SidebarWidgetModelDto, SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import {
  APPLICATION_ID,
  CURRENT_PAGE,
  DISABLED,
  HASH,
  IS_LAYOUT_MODE,
  IS_PREVIEW_MODE,
  LABEL,
  PAGE_ID,
  WIDGET,
} from '@widgets/widgets-core/constants/widgets-core.constants';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar-widget',
  templateUrl: './sidebar-widget.component.html',
  styleUrls: ['./sidebar-widget.component.scss'],
  providers: [SidebarService],
})
export class SidebarWidgetComponent extends WidgetsCoreComponent<SidebarWidgetValue> implements OnInit {
  blockPartForWidgetPlacement: PageBlockPart | null = null;
  advancedWidget: BlockPartWidget | null;
  m: SidebarWidgetModel;

  private runtimeStateNotificationHandlers: Record<string, (event: RuntimeStateNotification) => void>;
  private notificationHandler$: Subscription;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(PAGE_ID) public pageId: string,
    @Inject(CURRENT_PAGE) public page: Page,
    @Inject(WIDGET) public widget: BlockPartWidget<SidebarWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(HASH) public hash: string,
    @Inject(DISABLED) public disabled: boolean,
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    public readonly s: SidebarService,
    public readonly sidebarCopyPaster: SidebarWidgetCopyPasterService,
    private readonly templateService: TemplateService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    public readonly widgetService: SidebarWidgetService,
  ) {
    super(route, router, announcement, elRef);
    this.initRuntimeStateNotificationHandlers();
    this.initSubscriptions();
  }

  get showWidgetSettings(): boolean {
    return !this.isPreviewMode;
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as any as SidebarWidgetModelDto) : null);
  }

  ngOnDestroy(): void {
    this.notificationHandler$ && this.notificationHandler$.unsubscribe();
    this.m.settings.clearLayoutSidebarPaddings();
    this.m.settings.clearBodyPaddings();
  }

  dropSection(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.m.parts, event.previousIndex, event.currentIndex);
  }

  pasteWidget(): void {
    const blockPart = new PageBlockPart();
    this.sidebarCopyPaster.pasteLayout(blockPart);
    this.m.parts.push(blockPart);
  }

  setWidgetList(): void {
    this.advancedWidget = null;
    this.blockPartForWidgetPlacement = new PageBlockPart();
    this.m.widgetPicker.openSidebar();
  }

  setWidgetToDataModel(code: WidgetType): void {
    this.blockPartForWidgetPlacement!.widget = new BlockPartWidget({ code } as WidgetResponseDto);
    this.m.parts.push(this.blockPartForWidgetPlacement!);
    this.m.widgetPicker.closeSidebar();
  }

  onPageBuilderSidebarHide(): void {
    this.advancedWidget = null;
    this.m.widgetPicker.closeSidebar();
  }

  setWidgetAsAdvancedMode(widget: BlockPartWidget | null): void {
    this.blockPartForWidgetPlacement = null;
    this.advancedWidget = widget;
    this.m.widgetPicker.openSidebar();
  }

  onShowSidebar(): void {
    this.changeVisibleStyles();
  }

  onHideSidebar(): void {
    this.s.storeSidebarState();
    this.m.settings.hideSidebar(this.isLayoutMode);

    setTimeout(() => {
      if (this.m.settings.isFixed) {
        this.m.settings.clearPageBuilderPaddings();
      } else {
        this.changeVisibleStyles();
      }
    });
  }

  togglePanelPosition(): void {
    this.m.widgetPicker.closeSidebar();
    this.m.widgetPicker.openSidebar();
  }

  public changeVisibleStyles(): void {
    this.m.settings.updateStyles();
  }

  async showWidgetTemplates(): Promise<void> {
    this.m.widgetPicker.closeSidebar();

    const { role, templates } = await this.templateService.pickTemplate();
    await this.s.pasteWidgetTemplate(templates!, role === TemplatesCloseResultRole.reuseTemplate);
  }

  private initRuntimeStateNotificationHandlers(): void {
    this.runtimeStateNotificationHandlers = {};

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.openSidebar] = (event: RuntimeStateNotification<string>) => {
      if (event.data === this.widget.id || event.data === this.m.settings.sidebarName) {
        this.m.settings.openByEvent();
      }
    };

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.toggleSystemPanel] = this.handler.bind(this);
    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.togglePageBuilderHeaderVisible] = this.handler.bind(this);
  }

  private handler(): void {
    if (!this.m.settings.display) {
      return;
    }

    setTimeout(() => {
      const id = this.widget.id || this.m.newWidgetTempId;
      this.m.settings.pageBuilderLayoutFix(id);
    });
  }

  private initSubscriptions(): void {
    this.notificationHandler$ = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<unknown>) => {
      if (this.runtimeStateNotificationHandlers[event.type]) {
        this.runtimeStateNotificationHandlers[event.type](event);
      }
    });
  }
}
