import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Border, BoxShadow } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { Constants } from '@shared/constants/constants';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { BorderSideEnum, BorderStylesEnum, BorderWidthEnum, ShadowStylesEnum } from '../../types/style.types';

@Component({
  selector: 'app-border-form',
  templateUrl: './border-form.component.html',
  styleUrls: ['./border-form.component.scss'],
})
export class BorderFormComponent {
  // TODO: check typings in usage places
  @Input() styles: Border & BoxShadow;
  @Input() allowShadowInput: boolean;
  @Input() onlyShadow: boolean;
  @Output() onChange = new EventEmitter();

  readonly boxShadowOptions = GetSelectOptionsFromEnum(ShadowStylesEnum);
  readonly borderStyleOptions = GetSelectOptionsFromEnum(BorderStylesEnum);
  readonly borderWidthOptions = GetSelectOptionsFromEnum(BorderWidthEnum);

  shadowGeneratorLink = Constants.cssShadowGeneratorLink;
  borderSideEnum = BorderSideEnum;

  checkIfColorEmptyMap: Record<BorderSideEnum, () => void> = {
    [BorderSideEnum.top]: () => (this.styles.borderTopStyle = BorderStylesEnum.none),
    [BorderSideEnum.left]: () => (this.styles.borderLeftStyle = BorderStylesEnum.none),
    [BorderSideEnum.right]: () => (this.styles.borderRightStyle = BorderStylesEnum.none),
    [BorderSideEnum.bottom]: () => (this.styles.borderBottomStyle = BorderStylesEnum.none),
  };

  checkIfColorEmpty(color: string, side: BorderSideEnum): void {
    !color && this.checkIfColorEmptyMap[side]();
  }

  isChange(): void {
    this.onChange.emit();
  }
}
