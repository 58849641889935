<div class="grid">
  <div class="col-12 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="formatSettings.value.showIcon" binary="true" id="showIcon"></p-checkbox>
        <label for="showIcon" translate> Show artifact-type icon </label>
      </span>
    </span>
  </div>

  <div class="col-6">
    <h6> {{'Bullets'| translate}} </h6>
    <p-dropdown [(ngModel)]="formatSettings.value.listStyle"
                [autoDisplayFirst]="false"
                [options]="listStyleOptions"
                id="listStyle">
      <ng-template let-option pTemplate="item">
        {{option.label | translate}}
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
        {{option.label | translate}}
      </ng-template>
    </p-dropdown>
  </div>

  <div class="col-6">
    <h6> {{'Left padding'| translate}} </h6>
    <input [(ngModel)]="formatSettings.value.paddingLeft" id="borderColor" pInputText
           placeholder="[ number ] [ px / % / em ]"
           type="text">
  </div>
</div>
