import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { DisplayAtUserComponent } from './display-at-user.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DisplayAtUserComponent],
  imports: [CommonModule, WidgetContainerModule, CommonPipesModule, WidgetContainerModule, ReplaceRuntimeVariablesModule, TooltipModule],
  exports: [DisplayAtUserComponent],
})
export class DisplayAtUserModule {}
