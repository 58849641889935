import { Injectable } from '@angular/core';
import { AttributeResponseDto } from '@api/models';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { FileService } from '@private/services/file.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { lastValueFrom } from 'rxjs';

interface TypeSystemOptions {
  artifactType: NewArtifactType;
  attributes: NewAttribute[];
  dataTypes: NewDataType[];
}

@Injectable({
  providedIn: 'root',
})
// TODO: rename
export class OptimalArtifactService {
  constructor(private readonly tenantArtifactService: TenantArtifactService, private readonly fileService: FileService) {}

  async getLinkedArtifacts(links: LinkResponseDto[]): Promise<ArtifactResponseDto[]> {
    const uniqueIds = new Set(links.reduce((ids: string[], link: LinkResponseDto) => [...ids, link.sourceArtifactId, link.destinationArtifactId], []));

    if (!uniqueIds.size) {
      return [];
    }

    const $in = [...uniqueIds].map(($oid: string) => ({ $oid }));
    const filter = JSON.stringify({ $and: [{ _id: { $in } }] });

    const artifacts$ = this.tenantArtifactService.artifactControllerList({ body: { filter } });
    const { data } = await lastValueFrom(artifacts$);

    return data;
  }

  async getFiles(options: TypeSystemOptions, artifacts: ArtifactResponseDto[]): Promise<ArtifactResponseDto[]> {
    const fileAttributeIds = this.getFileAttributeIds(options);
    const $in = this.getFileIds(artifacts, fileAttributeIds).map(($oid: string) => ({ $oid }));
    const filter = JSON.stringify({ _id: { $in } });

    if (!$in.length) {
      return [];
    }

    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter } }));

    return data;
  }

  private getFileAttributeIds({ artifactType, attributes, dataTypes }: TypeSystemOptions): string[] {
    const artifactTypeAttributes = Object.keys(artifactType.attributes);

    return attributes.reduce((ids: string[], { id, dataTypeId }: AttributeResponseDto) => {
      const isArtifactTypeAttribute = artifactTypeAttributes.includes(id);
      const isFileDataType = !!dataTypes.find(({ id, baseDataType }: NewDataType) => id === dataTypeId && baseDataType === 'FILE');

      return isArtifactTypeAttribute && isFileDataType ? [...ids, id] : ids;
    }, []);
  }

  private getFileIds(artifacts: ArtifactResponseDto[], fileAttributeIds: string[]): string[] {
    const fileIds = new Set<string>();

    artifacts.forEach(({ attributes }: ArtifactResponseDto) => {
      fileAttributeIds.forEach((attributeId: string) => {
        const fileAttribute = attributes[attributeId];

        if (!fileAttribute) {
          return;
        }

        if (Array.isArray(fileAttribute.value)) {
          fileAttribute.value.forEach((id: string) => fileIds.add(id));
        } else {
          fileIds.add(fileAttribute.value as string);
        }
      });
    });

    return [...fileIds].filter(id => Boolean(id));
  }
}
