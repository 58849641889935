<div *ngIf="m.currentArtifact === null; else avrContent"><p translate>No artifact available</p></div>

<ng-template #avrContent>
  <div>
    <ng-container *ngIf="isShowButton()">
      <div class="row">
        <div class="col-12 button-group">
          <button pButton pRipple label="{{ m.settings.button.label }}" type="button" class="p-button p-button-success" (click)="onButtonClick()"></button>
        </div>
      </div>
    </ng-container>

    <div [class.display-none]="!isShowRender()" *ngIf="m.avrBlob" [ngSwitch]="m.currentAvrFileType">
      <app-avr-widget-pdf-render-file-type
        *ngSwitchCase="avrFileTypes.pdf"
        [file]="m.avrBlob"
        [avrType]="m.settings.response.avrType"
      ></app-avr-widget-pdf-render-file-type>
      <app-avr-widget-svg-render-file-type
        *ngSwitchCase="avrFileTypes.svg"
        [file]="m.avrBlob"
        [avrType]="m.settings.response.avrType"
      ></app-avr-widget-svg-render-file-type>
      <app-avr-widget-png-render-file-type
        *ngSwitchCase="avrFileTypes.png"
        [file]="m.avrBlob"
        [avrType]="m.settings.response.avrType"
      ></app-avr-widget-png-render-file-type>
      <p *ngSwitchDefault translate>Output type doesn't support render option</p>
    </div>
    <ng-container *ngIf="m.avrBlob === null && m.avrBlobError">
      {{ m.avrBlobError }}
    </ng-container>
  </div>
</ng-template>
