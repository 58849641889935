import { StickinessPosition } from '@private/pages/page-management/page-builder-graphical/types/stickiness-position';
import { StickinessScope } from '@private/pages/page-management/page-builder-graphical/types/stickiness-scope';
import { StickyPositioningSettings } from '@shared/types/sticky-positioning-settings';
import { cloneDeep } from 'lodash';
import { PageSectionContainerStyles } from './page-section-container-styles';

const defaultStickyPositioningSettings: StickyPositioningSettings = {
  isSticky: false,
  position: StickinessPosition.top,
  scope: StickinessScope.page,
};

const defaultOuterContainerStyles: Partial<PageSectionContainerStyles> & { stickyPositioning: StickyPositioningSettings } = {
  stickyPositioning: cloneDeep(defaultStickyPositioningSettings),
};

export class PageSectionOuterContainerStyles extends PageSectionContainerStyles {
  stickyPositioning: StickyPositioningSettings;

  constructor(dto: Partial<PageSectionContainerStyles> & { stickyPositioning: StickyPositioningSettings } = cloneDeep(defaultOuterContainerStyles)) {
    super(dto);
    this.stickyPositioning = dto?.stickyPositioning || cloneDeep(defaultStickyPositioningSettings);
    this.initBackgroundStyles(dto);
  }
}
