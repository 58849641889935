import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { ObjectUtil } from '@shared/utils/object.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';
import { EMPTY_FILTER_VALUE_SINGLE, EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';

@Injectable({ providedIn: 'root' })
export class BaseBooleanFilterService extends AbstractBaseTypeFilterService {
  constructor(protected readonly filterUtil: FilterUtil, protected readonly objectUtil: ObjectUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule: CoreListFilterEnum): Record<string, any> | null {
    if (this.objectUtil.isNullOrUndefined(value)) {
      return null;
    }
    const filterValues = (Array.isArray(value) ? value : value.split(',')) as string[];
    const processedValues = filterValues.map(value => this.getBooleanValueFromString(value));
    const filterKey = this.filterUtil.getAttributesDbFilterKey(attributeKey);
    return this.getResultQuery(filterKey, processedValues, filterRule);
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    return super.getQueryFromRawTextSingle(attributeKey, dataType, value, delimiter, filterRule);
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.in;
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    return filterRule === CoreListFilterEnum.in;
  }

  getBooleanValueFromString(value: string): string | null {
    if (this.objectUtil.isNullOrUndefined(value)) {
      return null;
    }
    const lowerCaseValue = value;
    if (lowerCaseValue === EMPTY_OPTION_FILTER_URL_VALUE) {
      return EMPTY_FILTER_VALUE_SINGLE;
    }
    if (lowerCaseValue === 'true' || lowerCaseValue === 'false') {
      return lowerCaseValue;
    }
    return null;
  }
}
