<div *ngIf="chartData && chartType" class="grid">
    <div *ngIf="isShowLegend && (isLeft() || isTop())" [id]="legendId" [ngClass]="isLeft() ? 'col-4': 'col-12'" class="chart-legend"></div>

    <div [ngClass]="isWideChart() || !isShowLegend ? 'col-12': 'col-8'" class="chart-cont">
        <canvas [id]="id" width="300" height="300"></canvas>
        <div *ngIf="isTotalPositionIsInside()" [id]="counterId" [ngStyle]="selected.counterStyles" class="counter">
            {{selected.prefix}}{{total}}{{selected.postfix}}
        </div>
    </div>

    <div *ngIf="isShowLegend && (isRight() || isBottom())" [id]="legendId" [ngClass]="isRight() ? 'col-4': 'col-12'" class="chart-legend"></div>

    <div *ngIf="isTotalPositionIsBottom()" class="col-12" [ngStyle]="selected.counterStyles">
        <span *ngIf="options.isShowTotal">Total count: {{selected.prefix}}{{ chartData.counter.total }}{{selected.postfix}}<br></span>
        <span *ngIf="!!chartData.counter.totalSum">Total {{chartData.counter.totalSumLabel}}: {{selected.prefix}}{{ chartData.counter.totalSum }}{{selected.postfix}}</span>
    </div>
</div>
