/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { pageControllerCount } from '../fn/tenant-page/page-controller-count';
import { PageControllerCount$Params } from '../fn/tenant-page/page-controller-count';
import { pageControllerCreate } from '../fn/tenant-page/page-controller-create';
import { PageControllerCreate$Params } from '../fn/tenant-page/page-controller-create';
import { pageControllerDelete } from '../fn/tenant-page/page-controller-delete';
import { PageControllerDelete$Params } from '../fn/tenant-page/page-controller-delete';
import { pageControllerDuplicate } from '../fn/tenant-page/page-controller-duplicate';
import { PageControllerDuplicate$Params } from '../fn/tenant-page/page-controller-duplicate';
import { pageControllerExport } from '../fn/tenant-page/page-controller-export';
import { PageControllerExport$Params } from '../fn/tenant-page/page-controller-export';
import { pageControllerGet } from '../fn/tenant-page/page-controller-get';
import { PageControllerGet$Params } from '../fn/tenant-page/page-controller-get';
import { pageControllerList } from '../fn/tenant-page/page-controller-list';
import { PageControllerList$Params } from '../fn/tenant-page/page-controller-list';
import { pageControllerUpdate } from '../fn/tenant-page/page-controller-update';
import { PageControllerUpdate$Params } from '../fn/tenant-page/page-controller-update';
import { PageListResponseDto } from '../models/page-list-response-dto';
import { PageResponseDto } from '../models/page-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantPageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pageControllerCount()` */
  static readonly PageControllerCountPath = '/api/tenant/page/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerCount$Response(params?: PageControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return pageControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerCount(params?: PageControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.pageControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerExport()` */
  static readonly PageControllerExportPath = '/api/tenant/page/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerExport$Response(params: PageControllerExport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return pageControllerExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerExport(params: PageControllerExport$Params, context?: HttpContext): Observable<Blob> {
    return this.pageControllerExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `pageControllerGet()` */
  static readonly PageControllerGetPath = '/api/tenant/page/{idAlias}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGet$Response(params: PageControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
    return pageControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerGet(params: PageControllerGet$Params, context?: HttpContext): Observable<PageResponseDto> {
    return this.pageControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponseDto>): PageResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerList()` */
  static readonly PageControllerListPath = '/api/tenant/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerList$Response(params?: PageControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<PageListResponseDto>> {
    return pageControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerList(params?: PageControllerList$Params, context?: HttpContext): Observable<PageListResponseDto> {
    return this.pageControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageListResponseDto>): PageListResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerUpdate()` */
  static readonly PageControllerUpdatePath = '/api/tenant/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerUpdate$Response(params: PageControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
    return pageControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerUpdate(params: PageControllerUpdate$Params, context?: HttpContext): Observable<PageResponseDto> {
    return this.pageControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponseDto>): PageResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerCreate()` */
  static readonly PageControllerCreatePath = '/api/tenant/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerCreate$Response(params: PageControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
    return pageControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pageControllerCreate(params: PageControllerCreate$Params, context?: HttpContext): Observable<PageResponseDto> {
    return this.pageControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponseDto>): PageResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerDuplicate()` */
  static readonly PageControllerDuplicatePath = '/api/tenant/page/{id}/duplicate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerDuplicate()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDuplicate$Response(params: PageControllerDuplicate$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
    return pageControllerDuplicate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerDuplicate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDuplicate(params: PageControllerDuplicate$Params, context?: HttpContext): Observable<PageResponseDto> {
    return this.pageControllerDuplicate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponseDto>): PageResponseDto => r.body)
    );
  }

  /** Path part for operation `pageControllerDelete()` */
  static readonly PageControllerDeletePath = '/api/tenant/page/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDelete$Response(params: PageControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
    return pageControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageControllerDelete(params: PageControllerDelete$Params, context?: HttpContext): Observable<PageResponseDto> {
    return this.pageControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageResponseDto>): PageResponseDto => r.body)
    );
  }

}
