import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsText } from '../../methods/data-type.methods';

@Pipe({ name: 'isText' })
export class IsTextPipe implements PipeTransform {
  transform(baseDataType: BaseDataType): boolean {
    return IsText(baseDataType);
  }
}
