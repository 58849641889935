import { Pipe, PipeTransform } from '@angular/core';
import { SystemTenantAttributesDto } from '@api/models/system-tenant-attributes-dto';

@Pipe({ name: 'isAttributeDisabled' })
export class IsAttributeDisabledPipe implements PipeTransform {
  transform(attributeId: string, systemAttributes: SystemTenantAttributesDto | null): boolean {
    if (!systemAttributes) {
      return false;
    }

    return [systemAttributes.nameAttributeId, systemAttributes.sequenceAttributeId].includes(attributeId);
  }
}
