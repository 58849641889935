import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridTrack } from '@shared/components/grid-layout-generator/types/grid-track';
import { GridTrackType } from '@shared/components/grid-layout-generator/types/grid-track-type';

@Component({
  selector: 'app-grid-tracks-generator',
  templateUrl: './grid-tracks-generator.component.html',
  styleUrls: ['./grid-tracks-generator.component.scss'],
})
export class GridTracksGeneratorComponent {
  @Input() tracks: GridTrack[];
  @Input() readonly trackType: GridTrackType;

  @Output() addTrack: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteTrack: EventEmitter<GridTrack> = new EventEmitter<GridTrack>();
  @Output() updateTrack: EventEmitter<void> = new EventEmitter<void>();

  add(): void {
    this.addTrack.emit();
  }

  delete(track: GridTrack): void {
    this.deleteTrack.emit(track);
  }

  update(size: string, track: GridTrack): void {
    track.size = size.trim();
    this.updateTrack.emit();
  }
}
