import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrWidgetSettings as _SETTINGS_DTO } from '@api/models/avr-widget-settings';
import { AvrWidgetTypeSettings, AvrWidgetDataSourceSettings as _DATA_SOURCE_SETTINGS_DTO } from '@api/models';
import { AvrWidgetButtonSettings as _BUTTON_SETTINGS_DTO } from '@api/models';
import { AvrWidgetResponseSettings as _RESPONSE_SETTINGS_DTO } from '@api/models';

export type AvrArtifactSourcesType = _SETTINGS_DTO['dataSource']['artifactSource'];
export const AvrArtifactSources: Record<string, AvrArtifactSourcesType> = Object.freeze({
  listItem: 'LIST_ITEM',
  staticArtifact: 'STATIC_ARTIFACT',
  dynamicArtifact: 'DYNAMIC_ARTIFACT',
});

export type AvrActionTypesType = _SETTINGS_DTO['response']['actionType'];
export const AvrActionTypes: Record<string, AvrActionTypesType> = Object.freeze({
  render: 'RENDER',
  renderOnClick: 'RENDER_ON_CLICK',
  download: 'DOWNLOAD',
});

export class AvrWidgetDataSourceSettings implements _DATA_SOURCE_SETTINGS_DTO {
  artifactTypeId: string | null = null;
  staticArtifactId: string | null = null;
  artifactSource: AvrArtifactSourcesType | null = null;
  artifactListeningKey: string | null = null;
}

export class AvrWidgetButtonSettings implements _BUTTON_SETTINGS_DTO {
  label = 'Get Artifact Visual Representation';
}

export class AvrWidgetResponseSettings implements _RESPONSE_SETTINGS_DTO {
  avrType: AvrTypesType | null = null;
  actionType: AvrActionTypesType = AvrActionTypes.download;
  avrTypeSettings: AvrWidgetTypeSettings = {};
}

export class AvrWidgetSettings implements _SETTINGS_DTO {
  dataSource: AvrWidgetDataSourceSettings = new AvrWidgetDataSourceSettings();
  button: AvrWidgetButtonSettings = new AvrWidgetButtonSettings();
  response: AvrWidgetResponseSettings = new AvrWidgetResponseSettings();

  constructor(settings?: AvrWidgetSettings) {
    settings && Object.assign(this, settings);
  }
}
