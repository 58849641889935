import { AvrInputMappersType, AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';

// this will receive "array" of properties, that is on type level set as optional
type OptionalKeys<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]-?: {} extends { [P in K]: T[K] } ? K : never;
}[keyof T];

// restricts whether can be assigned true / false based on condition whether key is in OptionalKeys "array"
type HasOptionalKey<T, K extends string> = K extends OptionalKeys<T> ? true : false;

type Fields<DTO> = {
  [K in keyof DTO & string]?: {
    isOptional: HasOptionalKey<DTO, K>;
    value: DTO[K];
    readableName: string;
    description: string;
  };
};

export class BaseAvrInputMapperDto<AvrType extends AvrTypesType> {
  // "value" fields will be set to mapped AttributeId of ArtifactType
  private _mappableFields: Fields<AvrInputMappersType[AvrType]>;
  // "value" fields will be set to various values
  private _nonMappableFields: Fields<AvrInputMappersType[AvrType]>;

  constructor(mappableFields: Fields<AvrInputMappersType[AvrType]>, nonMappableFields: Fields<AvrInputMappersType[AvrType]>) {
    Object.assign(this, { _mappableFields: mappableFields, _nonMappableFields: nonMappableFields });
  }

  get mappableFields() {
    return this._mappableFields;
  }

  get nonMappableFields() {
    return this._nonMappableFields;
  }
}
