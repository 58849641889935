<div class="mb-4">
  <p-card *ngIf="m.user">
    <div class="grid profile">
      <div class="col-12 md:col-6 lg:col-3 profile__logo">
        <div class="profile__logo_wrapper">
          <span></span>
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-5 profile__details">
        <div class="profile__details_wrapper" *ngFor="let field of fieldsToShow">
          <span class="profile__details_label">{{ field.label | translate }}:</span>
          <span class="profile__details_value">{{ m.user[field.key] }}</span>
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-4 profile__language-switch">
        <app-language-switcher></app-language-switcher>
        <button
          style="height: fit-content"
          class="p-button p-button-help ml-2"
          pButton
          label="{{ 'Change password' | translate }}"
          (click)="openResetPasswordDialog()"
          type="button"
        ></button>
      </div>
    </div>

    <app-config></app-config>

    <hr />

    <div class="col-12" style="color: red">
      <h1>Fields to demonstrate dynamic change of label styles</h1>
    </div>

    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <span class="dynamic-label">
        <input type="text" id="username" pInputText />
        <label class="dynamic-label-text" for="username" translate>Test field with icon</label>
      </span>
    </div>
    <span class="dynamic-label">
      <input type="text" id="sdfsd" pInputText />
      <label class="dynamic-label-text" for="sdfsd" translate
        >Test field with long text. Test field with long text. Test field with long text. Test field with long text.</label
      >
    </span>
  </p-card>
</div>

<div>
  <app-user-oslc-connections [c]="this"></app-user-oslc-connections>
</div>

<p-dialog
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  header="{{ 'Reset password' | translate }}"
  [(visible)]="changePasswordDialogDisplayed"
>
  <app-reset-password-dialog (saveDone)="hideResetPasswordDialog()"></app-reset-password-dialog>
</p-dialog>
