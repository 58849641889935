import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { SavedAndUnsavedFiles } from '@shared/types/file.types';

@Pipe({ name: 'getFilesForArtifact' })
export class GetFilesForArtifactPipe implements PipeTransform {
  transform(
    clientAttribute: NewClientAttribute,
    artifactId: string,
    fileArtifacts: Record<string, ArtifactResponseDto>,
    unSavedFiles: Record<string, Record<string, File[]>>,
    attributesMap: Record<string, NewAttribute>,
  ): SavedAndUnsavedFiles {
    return {
      files: this.getSavedFiles(clientAttribute, fileArtifacts, attributesMap),
      unSavedFiles: this.getUnsavedFiles(clientAttribute, unSavedFiles, artifactId),
    };
  }

  private getSavedFiles(
    clientAttribute: NewClientAttribute,
    fileArtifacts: Record<string, ArtifactResponseDto>,
    attributesMap: Record<string, NewAttribute>,
  ): ArtifactResponseDto | ArtifactResponseDto[] | null {
    const multipleValues = attributesMap[clientAttribute?.id]?.multipleValues;
    return multipleValues ? this.getSavedFilesMultiple(clientAttribute, fileArtifacts) : this.getSavedFileSingle(clientAttribute, fileArtifacts);
  }

  private getSavedFileSingle(
    clientAttribute: NewClientAttribute,
    fileArtifacts: Record<string, ArtifactResponseDto>,
  ): ArtifactResponseDto | ArtifactResponseDto[] | null {
    if (!fileArtifacts[clientAttribute?.value] || !fileArtifacts[clientAttribute?.value].id) {
      return null;
    }
    return fileArtifacts[clientAttribute.value];
  }

  private getSavedFilesMultiple(
    clientAttribute: NewClientAttribute,
    fileArtifacts: Record<string, ArtifactResponseDto>,
  ): ArtifactResponseDto | ArtifactResponseDto[] | null {
    if (!clientAttribute.value) return null;
    return clientAttribute.value.map((id: string | null) => (id ? fileArtifacts[id] : null));
  }

  private getUnsavedFiles(clientAttribute: NewClientAttribute, unSavedFiles: Record<string, Record<string, File[]>>, artifactId: string): File | File[] | null {
    if (!unSavedFiles[artifactId] || !unSavedFiles[artifactId][clientAttribute.id]?.length) return null;
    return unSavedFiles[artifactId][clientAttribute.id];
  }
}
