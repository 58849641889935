import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsCounter } from '@shared/methods/data-type.methods';

@Pipe({ name: 'isCounter' })
export class IsCounterPipe implements PipeTransform {
  transform(dataTypeKind: DataTypeKind): unknown {
    return IsCounter(dataTypeKind);
  }
}
