/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApexChartWidgetTypeResponseDto } from '../../models/apex-chart-widget-type-response-dto';
import { ApexChartWidgetTypeUpdateRequestDto } from '../../models/apex-chart-widget-type-update-request-dto';
import { ArtifactWidgetTypeResponseDto } from '../../models/artifact-widget-type-response-dto';
import { ArtifactWidgetTypeUpdateRequestDto } from '../../models/artifact-widget-type-update-request-dto';
import { AuthWidgetTypeResponseDto } from '../../models/auth-widget-type-response-dto';
import { AuthWidgetTypeUpdateRequestDto } from '../../models/auth-widget-type-update-request-dto';
import { AvrWidgetTypeResponseDto } from '../../models/avr-widget-type-response-dto';
import { AvrWidgetTypeUpdateRequestDto } from '../../models/avr-widget-type-update-request-dto';
import { CardWidgetTypeResponseDto } from '../../models/card-widget-type-response-dto';
import { CardWidgetTypeUpdateRequestDto } from '../../models/card-widget-type-update-request-dto';
import { ChartWidgetTypeResponseDto } from '../../models/chart-widget-type-response-dto';
import { ChartWidgetTypeUpdateRequestDto } from '../../models/chart-widget-type-update-request-dto';
import { FilterWidgetTypeResponseDto } from '../../models/filter-widget-type-response-dto';
import { FilterWidgetTypeUpdateRequestDto } from '../../models/filter-widget-type-update-request-dto';
import { FolderWidgetTypeResponseDto } from '../../models/folder-widget-type-response-dto';
import { FolderWidgetTypeUpdateRequestDto } from '../../models/folder-widget-type-update-request-dto';
import { ListMatrixWidgetTypeResponseDto } from '../../models/list-matrix-widget-type-response-dto';
import { ListMatrixWidgetTypeUpdateRequestDto } from '../../models/list-matrix-widget-type-update-request-dto';
import { ListWidgetTypeResponseDto } from '../../models/list-widget-type-response-dto';
import { ListWidgetTypeUpdateRequestDto } from '../../models/list-widget-type-update-request-dto';
import { MenuWidgetTypeResponseDto } from '../../models/menu-widget-type-response-dto';
import { MenuWidgetTypeUpdateRequestDto } from '../../models/menu-widget-type-update-request-dto';
import { NumberWidgetTypeResponseDto } from '../../models/number-widget-type-response-dto';
import { NumberWidgetTypeUpdateRequestDto } from '../../models/number-widget-type-update-request-dto';
import { PictureWidgetTypeResponseDto } from '../../models/picture-widget-type-response-dto';
import { PictureWidgetTypeUpdateRequestDto } from '../../models/picture-widget-type-update-request-dto';
import { SidebarModalWidgetTypeResponseDto } from '../../models/sidebar-modal-widget-type-response-dto';
import { SidebarModalWidgetTypeUpdateRequestDto } from '../../models/sidebar-modal-widget-type-update-request-dto';
import { SidebarWidgetTypeResponseDto } from '../../models/sidebar-widget-type-response-dto';
import { SidebarWidgetTypeUpdateRequestDto } from '../../models/sidebar-widget-type-update-request-dto';
import { TagWidgetTypeResponseDto } from '../../models/tag-widget-type-response-dto';
import { TagWidgetTypeUpdateRequestDto } from '../../models/tag-widget-type-update-request-dto';
import { TextWidgetTypeResponseDto } from '../../models/text-widget-type-response-dto';
import { TextWidgetTypeUpdateRequestDto } from '../../models/text-widget-type-update-request-dto';

export interface WidgetControllerUpdate$Params {
      body: (TextWidgetTypeUpdateRequestDto | MenuWidgetTypeUpdateRequestDto | NumberWidgetTypeUpdateRequestDto | ArtifactWidgetTypeUpdateRequestDto | CardWidgetTypeUpdateRequestDto | PictureWidgetTypeUpdateRequestDto | ListMatrixWidgetTypeUpdateRequestDto | SidebarWidgetTypeUpdateRequestDto | ListWidgetTypeUpdateRequestDto | ChartWidgetTypeUpdateRequestDto | ApexChartWidgetTypeUpdateRequestDto | FolderWidgetTypeUpdateRequestDto | TagWidgetTypeUpdateRequestDto | FilterWidgetTypeUpdateRequestDto | AuthWidgetTypeUpdateRequestDto | AvrWidgetTypeUpdateRequestDto | SidebarModalWidgetTypeUpdateRequestDto)

}

export function widgetControllerUpdate(http: HttpClient, rootUrl: string, params: WidgetControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
  const rb = new RequestBuilder(rootUrl, widgetControllerUpdate.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
    })
  );
}

widgetControllerUpdate.PATH = '/api/tenant/widget';
