<div class="grid">
  <div class="col-4">
    <h6><label [for]="'isSticky'">{{'Sticky' | translate}}</label></h6>
  </div>
  <div class="col-8">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox
          [(ngModel)]="settings.isSticky"
          [binary]="true"
          inputId="isSticky">
        </p-checkbox>
      </span>
    </span>
  </div>
  <div *ngIf="settings.isSticky" class="p-offset-4 col-8">
    <div class="grid">
      <div class="col-6">
        <h6>{{'Position' | translate}}</h6>

        <p-radioButton
          [(ngModel)]="settings.position"
          [label]="position.top"
          [value]="position.top"
          class="mr-2"
          name="position">
        </p-radioButton>

        <p-radioButton
          [(ngModel)]="settings.position"
          [label]="position.bottom"
          [value]="position.bottom"
          name="position">
        </p-radioButton>
      </div>
    </div>
  </div>
</div>
