<div class="layout-sidebar" (click)="onMenuClick()">
  <div class="logo layout-picker" *ngIf="hasApplicationSwitcher">
    <div class="layout-picker__select">
      <app-application-switcher></app-application-switcher>
    </div>
  </div>

  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index;">
        <li app-sidebar-menu-item [app]="app" [item]="item" [index]="i" [root]="true"></li>
      </ng-container>
    </ul>
  </div>
</div>
