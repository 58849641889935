import {
  BaseDataType,
  DataTypeKind,
  EnumeratedOption,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { EMAIL_KEY, ID_KEY } from '@shared/constants/constants';
import { AttributeToClientParams } from '../types/attribute-convert.types';
import { NewAttribute } from '../types/attribute.types';
import { NewDataType } from '../types/data-type.types';
import { SelectOption } from '../types/shared.types';
import { NewUser } from '../types/user.types';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from './artifact.methods';
import { IsBoolean, IsDate, IsDateOrTime, IsDateTime, IsDecimal, IsFile, IsInteger, IsTime, IsUser } from './data-type.methods';
import { ConvertToClientDatetime, ConvertToServerDate, ConvertToServerDatetime, ConvertToServerTime } from './date.methods';
import moment from 'moment';

export const AttributeValueToText = (params: AttributeToClientParams): any => {
  const clientValue = AttributeValueToClient(params);
  if (clientValue instanceof Date) return ConvertToClientDatetime(clientValue);
  else if (clientValue?.label) return clientValue.label;
  else if (JSON.stringify(clientValue) === '[]' || clientValue === undefined) return null;
  else if (Array.isArray(clientValue))
    return clientValue.map(clientVal => (clientVal?.label ? clientVal.label : clientVal instanceof Date ? ConvertToClientDatetime(clientVal) : clientVal));
  return clientValue;
};

export const AttributeValueToClient = (params: AttributeToClientParams): any => {
  const { attributes, dataTypes, users, clientAttribute } = params;

  const attribute = GetAttributeFromClientAttribute(clientAttribute, attributes.listMap);
  const dataType = GetDataTypeFromClientAttribute(clientAttribute, attributes.listMap, dataTypes.listMap);
  let value = params.value;

  if (attribute && dataType) {
    const { multipleValues } = attribute;

    if (value?.value) {
      value = value.value;
    } else if (Array.isArray(value) && value.length) {
      if (value[0].value) {
        value = value.map(val => val.value);
      }
    }

    if (multipleValues) {
      switch (dataType?.kind) {
        case DataTypeKind.enumerated:
          return dataType.values?.filter(item => value && value.includes(item.value)) || [];
        case DataTypeKind.bounded:
          if (IsDateOrTime(dataType.baseDataType as BaseDataType)) return value.map((value: any) => new Date(value));
          return value.map((value: any) => Number(value));
        case DataTypeKind.simple:
          value && dataType.baseDataType === BaseDataType.user && (value = Array.isArray(value) ? value : [value]);
          return transformMultipleSimpleToClient(dataType, value, users);
        default:
          return value;
      }
    } else if (!multipleValues) {
      switch (dataType?.kind) {
        case DataTypeKind.enumerated:
          return dataType.values?.find(item => value && value === item.value) || null;
        case DataTypeKind.bounded:
          return convertMinMaxToClient(dataType.baseDataType, value);
        case DataTypeKind.simple:
          return transformSimpleToClient(dataType, value, users);
        default:
          return value;
      }
    }
  }
};

export const AttributeValueToServer = (dataType: NewDataType | null, attribute: NewAttribute | null, value: any): any => {
  if (!dataType || !attribute) return null;
  const { multipleValues } = attribute;

  if (multipleValues) {
    if (!value) return [];

    switch (dataType.kind) {
      case DataTypeKind.bounded:
        return transformMultipleBoundedToServer(dataType, value);
      case DataTypeKind.enumerated:
        return transformMultipleEnum(dataType, value);
      case DataTypeKind.simple:
        return transformMultipleSimpleToServer(dataType, value);
      default:
        return value || [];
    }
  } else if (!multipleValues) {
    if (!value && typeof value !== 'boolean') return '';

    switch (dataType.kind) {
      case DataTypeKind.bounded:
        return transformBoundedToServer(dataType, value);
      case DataTypeKind.enumerated:
        return transformSimpleEnumToServer(value);
      case DataTypeKind.simple:
        return transformSimpleToServer(dataType, value);
      default:
        return value || '';
    }
  }
};

const transformSimpleToClient = (dataType: NewDataType, value: any, users: NewUser[]): any | null => {
  if (!value) return transformSimpleEmptyToClient(dataType);

  const { baseDataType } = dataType;

  if (baseDataType && IsUser(baseDataType)) {
    if (Array.isArray(value)) value = value.map((value: any) => (value.value ? value.value.id : value.id ? value.id : value));
    return transformAnyToSelectOptions(
      users.filter(user => value.includes(user.id)),
      EMAIL_KEY,
      ID_KEY,
    );
  } else if (baseDataType && IsDateOrTime(baseDataType)) {
    return convertMinMaxToClient(baseDataType, value) as Date;
  } else if (baseDataType && IsBoolean(baseDataType)) {
    return value === 'true' || value === true;
  }

  return value;
};

const transformAnyToSelectOptions = (targetArr: any[], labelKey: string | null = null, valueKey: string | null = null): SelectOption<any, any>[] => {
  return targetArr.map(item => new SelectOption(labelKey ? item[labelKey] : item, valueKey ? item[valueKey] : item));
};

const transformMultipleSimpleToClient = (dataType: NewDataType, selectedValues: any[], users: NewUser[]): any[] | null => {
  if (!selectedValues) return null;

  if (dataType.baseDataType && IsUser(dataType.baseDataType)) {
    selectedValues = selectedValues.map(value => (value.value ? value.value.id : value.id ? value.id : value));
    return transformAnyToSelectOptions(
      users.filter(user => selectedValues.includes(user.id)),
      EMAIL_KEY,
      ID_KEY,
    );
  } else if (dataType.baseDataType && IsDateOrTime(dataType.baseDataType)) {
    return selectedValues && selectedValues.length ? selectedValues.map(date => convertMinMaxToClient(dataType.baseDataType, date)) : null;
  }
  return selectedValues;
};

const transformSimpleEmptyToClient = (dataType: NewDataType): boolean | string | null => {
  const { baseDataType } = dataType;

  if (baseDataType && IsBoolean(baseDataType)) return null;
  else if (baseDataType && [BaseDataType.integer, BaseDataType.decimal, BaseDataType.html, BaseDataType.text].includes(baseDataType)) return '';
  else return null;
};

const convertMinMaxToClient = (baseDataType: BaseDataType | null, target: string | Date | null): Date | string | null => {
  if (baseDataType) {
    if (IsInteger(baseDataType) || IsDecimal(baseDataType)) return target;
    if (IsDate(baseDataType)) return target ? moment(target).utc().local().toDate() : null;
    if (IsDateTime(baseDataType)) return target ? new Date(target) : null;
    if (IsTime(baseDataType)) return target ? getTimeValueForClient(target) : null;
  }
  return null;
};

export const getTimeValueForClient = (time: string | Date | null): Date | null => {
  return typeof time === 'string' ? new Date(new Date().toISOString().replace(/\d{2}:\d{2}/, time)) : time;
};

const convertMinMaxToServer = (baseDataType: BaseDataType | null, target: Date | string): string => {
  if (baseDataType) {
    if (IsDate(baseDataType)) return ConvertToServerDate(target as Date);
    if (IsTime(baseDataType)) return ConvertToServerTime(target as Date);
    if (IsDateTime(baseDataType)) return ConvertToServerDatetime(target as Date);
  }
  return String(target);
};

const transformSimpleEnumToServer = (value: any): string => {
  if (!value) return '';
  if (typeof value === 'string') return value;
  if (value.value) return value.value;
  return '';
};

const transformMultipleEnum = (dataType: NewDataType, selectedValues: EnumeratedOption[]): string[] | undefined => {
  if (!selectedValues) return [];
  const values = selectedValues.map(item => item.value);
  return dataType.values?.filter(item => values.includes(item.value)).map(item => item.value);
};

const transformBoundedToServer = (dataType: NewDataType, value: any): string => {
  const { baseDataType } = dataType;
  if (baseDataType && IsDateOrTime(baseDataType)) return convertMinMaxToServer(baseDataType, value);
  if (baseDataType && IsDecimal(baseDataType)) return TransformDecimalToServer(value.toString());
  else return value + '' || '';
};

const transformSimpleToServer = (dataType: NewDataType, value: any): string => {
  if (!value && typeof value !== 'boolean') return '';

  const { baseDataType } = dataType;

  if (baseDataType) {
    if (IsUser(baseDataType)) return value.length ? value[0]?.value || value[0] : '';
    if (IsDateOrTime(baseDataType)) return convertMinMaxToServer(baseDataType, value);
    if (IsFile(baseDataType)) {
      if (Array.isArray(value) && value.length) return value[0].id ? value[0].id : value[0];
      else if (value.id) return value.id;
      else return String(value);
    }
    if (IsDecimal(baseDataType)) return TransformDecimalToServer(value);
  }

  return String(value);
};

const transformMultipleSimpleToServer = (dataType: NewDataType, selectedValues: any[]): string[] => {
  if (!selectedValues) return [];

  const { baseDataType } = dataType;

  if (baseDataType) {
    if (IsUser(baseDataType)) return selectedValues.map(option => option.value || option);
    if (dataType.baseDataType && IsDateOrTime(dataType.baseDataType)) return selectedValues.map(date => convertMinMaxToServer(dataType.baseDataType, date));
    if (IsFile(baseDataType)) return selectedValues.map(value => (value.id ? value.id : value));
    if (IsDecimal(baseDataType)) return selectedValues.map(value => TransformDecimalToServer(value));
  }

  return selectedValues;
};

const transformMultipleBoundedToServer = (dataType: NewDataType, selectedValues: any[]): string[] => {
  const { baseDataType } = dataType;
  if (baseDataType && IsDateOrTime(baseDataType)) return selectedValues.map(value => convertMinMaxToServer(baseDataType, value));
  else return selectedValues.map((value: any) => String(value)) || [];
};

export const TransformDecimalToServer = (value: string): string => {
  if (value && value.length) {
    if (value[0] === '.') value = '0' + value;
    if (value[value.length - 1] === '.') value += '.00';
    if (!value.includes('.')) value += '.00';
  }
  return value;
};
