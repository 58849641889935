import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileArtifactDescriptionPipe } from './file-artifact-description.pipe';
import { GetAttributeFromClientAttributePipe } from './get-attribute-from-client-attribute.pipe';
import { GetDataTypeFromDataTypeIdPipe } from './get-data-type-from-data-type-id.pipe';
import { IsArtifactMapNotEmptyPipe } from './is-artifact-map-not-empty.pipe';

const pipes = [FileArtifactDescriptionPipe, GetAttributeFromClientAttributePipe, GetDataTypeFromDataTypeIdPipe, IsArtifactMapNotEmptyPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class ArtifactPipesModule {}
