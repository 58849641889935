import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { RangedGridContainer, StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { INITIAL_COLUMN_GAP, INITIAL_GRID_COLUMN_SIZE, INITIAL_GRID_ROW_SIZE, INITIAL_ROW_GAP } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { TextDisplayOption } from '@widgets/card-widget/components/card-area-styler/card-area-styler.component';
import { Area } from '@widgets/card-widget/types/area';
import { AreaDto } from '@widgets/card-widget/types/area-dto';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem, IsCardContentItemLink, IsCardContentItemWidget } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetDto } from '@widgets/card-widget/types/card-widget-dto';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { CardWidgetSettings } from '@widgets/card-widget/types/card-widget-settings';
import { CardWidgetStyles } from '@widgets/card-widget/types/card-widget-styles';
import { LinkValue } from '@widgets/card-widget/types/link-value';
import { RangedGridLayoutController } from '@widgets/card-widget/types/ranged-grid-layout-controller';
import { BackgroundTypeEnum, FlexDirection, OverflowEnum } from '@widgets/shared/types/style.types';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

export interface MyArtifactAttribute {
  label: string;
  value: string | LinkValue[];
  icon?: string;
  linkType?: LinkType;
}

export interface MyArtifact {
  [attributeIdOrLinkTypeId: string]: MyArtifactAttribute;
}

export const INITIAL_CARD_WIDGET_AREA: Omit<Area, 'visible' | 'gridColumnStart' | 'gridColumnEnd' | 'gridRowStart' | 'gridRowEnd'> = {
  content: {
    items: [],
    styles: {
      paddingTop: '5px',
      paddingRight: '5px',
      paddingBottom: '5px',
      paddingLeft: '5px',
      backgroundType: BackgroundTypeEnum.color,
      flexDirection: FlexDirection.column,
      overflowX: OverflowEnum.hidden,
      overflowY: OverflowEnum.hidden,
    } as StylesDto,
    textDisplay: TextDisplayOption.wrap,
  },
  name: 'Area 1',
};

export interface CardWidgetModelOptions {
  attributes: ListContainer<NewAttribute>;
  systemAttributes: ListContainer<NewAttribute>;
  dataTypes: ListContainer<NewDataType>;
  users: ListContainer<NewUser>;
  pages: ListContainer<Page>;
}

export class CardWidgetModel extends RangedGridLayoutController {
  isReady: boolean;
  layoutPreviewMode: boolean;
  widgetsToDelete: string[] = [];
  idPrefix: string;
  tabState = {
    widgetSettings: true,
    contentStyles: false,
  };

  options: CardWidgetOptions = new CardWidgetOptions();
  /** @deprecated */
  recalculateResponsiveness: Subject<void> = new Subject<void>();
  advancedModeOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentArtifactIdFromUrl: string;

  private constructor(
    public readonly settings: CardWidgetSettings,
    public areas: Area[],
    public breakpoints: StyleApplicationBreakpoint[],
    public readonly grid: RangedGridContainer,
    public readonly styles: CardWidgetStyles,
    public readonly activeStyles: CardWidgetStyles,
    public readonly hoverStyles: CardWidgetStyles,
  ) {
    super(areas, [], grid, breakpoints);
  }

  get artifact(): ArtifactResponseDto {
    return this.settings.widgetMode === CardWidgetMode.staticArtifact
      ? this.options.artifactOptions.listMap[this.settings.selectedArtifactId]
      : this.options.artifactOptions.list[0];
  }

  get innerWidgets(): BlockPartWidget[] {
    return this.areas.reduce((widgets: BlockPartWidget[], { content: { items } }: GenericArea<CardWidgetAreaContent>) => {
      return [...widgets, ...items.filter(IsCardContentItemWidget).map(({ content }: CardWidgetAreaContentItem) => content as BlockPartWidget)];
    }, []);
  }

  get innerLinkTypeIds(): string[] {
    return Array.from(
      new Set(
        this.areas.reduce((widgets: string[], { content: { items } }: GenericArea<CardWidgetAreaContent>) => {
          return [...widgets, ...items.filter(IsCardContentItemLink).map(({ content }: CardWidgetAreaContentItem) => content as string)];
        }, []),
      ),
    );
  }

  static initial(): CardWidgetModel {
    const breakpoints = [new StyleApplicationBreakpoint(Infinity)];
    const settings = CardWidgetSettings.initial();
    const areas = [CardWidgetModel.initialArea(breakpoints[0])];
    const grid = CardWidgetModel.initialGrid(breakpoints[0]);

    return new CardWidgetModel(settings, areas, breakpoints, grid, new CardWidgetStyles(), new CardWidgetStyles(), new CardWidgetStyles());
  }

  static fromDtoAndOptions(dto: CardWidgetDto, options: CardWidgetModelOptions): CardWidgetModel {
    const settings = CardWidgetSettings.fromDtoAndOptions(dto.settings, options);
    const breakpoints = dto.breakpoints.map((value: string) => new StyleApplicationBreakpoint(Number(value)));
    const areas: Area[] = dto.areas.map((area: AreaDto) => {
      const items = area.content.items.map(
        ({ type, content, linkDirection }: CardWidgetAreaContentItem) => new CardWidgetAreaContentItem(type, content, linkDirection),
      );
      const { overflowX, overflowY } = area.content.styles;
      // fixed for old widgets without this option
      const textDisplay = area.content.textDisplay || TextDisplayOption.wrap;

      return {
        ...area,
        content: { ...area.content, textDisplay, items, styles: { ...area.content.styles, overflow: `${overflowX} ${overflowY}` } },
        visible: RangedStyleValue.fromDtoAndBreakpoints(area.visible, breakpoints),
        gridColumnStart: RangedStyleValue.fromDtoAndBreakpoints(area.gridColumnStart, breakpoints),
        gridColumnEnd: RangedStyleValue.fromDtoAndBreakpoints(area.gridColumnEnd, breakpoints),
        gridRowStart: RangedStyleValue.fromDtoAndBreakpoints(area.gridRowStart, breakpoints),
        gridRowEnd: RangedStyleValue.fromDtoAndBreakpoints(area.gridRowEnd, breakpoints),
      };
    });
    const grid = {
      columnGap: RangedStyleValue.fromDtoAndBreakpoints(dto.grid.columnGap, breakpoints),
      rowGap: RangedStyleValue.fromDtoAndBreakpoints(dto.grid.rowGap, breakpoints),
      gridTemplateColumns: RangedStyleValue.fromDtoAndBreakpoints(dto.grid.gridTemplateColumns, breakpoints),
      gridTemplateRows: RangedStyleValue.fromDtoAndBreakpoints(dto.grid.gridTemplateRows, breakpoints),
    };

    const styles = new CardWidgetStyles(dto.styles);
    const activeStyles = new CardWidgetStyles(dto.activeStyles);
    const hoverStyles = new CardWidgetStyles(dto.hoverStyles);

    return new CardWidgetModel(settings, areas, breakpoints, grid, styles, activeStyles, hoverStyles);
  }

  // TODO: refactor
  static initialGrid(breakpoint: StyleApplicationBreakpoint): RangedGridContainer {
    return {
      columnGap: RangedStyleValue.fromValueAndBreakpoint(INITIAL_COLUMN_GAP, breakpoint),
      rowGap: RangedStyleValue.fromValueAndBreakpoint(INITIAL_ROW_GAP, breakpoint),
      gridTemplateColumns: RangedStyleValue.fromValueAndBreakpoint(INITIAL_GRID_COLUMN_SIZE, breakpoint),
      gridTemplateRows: RangedStyleValue.fromValueAndBreakpoint(INITIAL_GRID_ROW_SIZE, breakpoint),
    };
  }

  // TODO refactor
  static initialArea(breakpoint: StyleApplicationBreakpoint): Area {
    const visible = RangedStyleValue.fromValueAndBreakpoint(true, breakpoint);
    const gridColumnStart = RangedStyleValue.fromValueAndBreakpoint(1, breakpoint);
    const gridColumnEnd = RangedStyleValue.fromValueAndBreakpoint(2, breakpoint);
    const gridRowStart = RangedStyleValue.fromValueAndBreakpoint(1, breakpoint);
    const gridRowEnd = RangedStyleValue.fromValueAndBreakpoint(2, breakpoint);

    return {
      ...cloneDeep(INITIAL_CARD_WIDGET_AREA),
      visible,
      gridColumnEnd,
      gridColumnStart,
      gridRowEnd,
      gridRowStart,
    };
  }

  static initialAreaFromBreakpoints(breakpoints: StyleApplicationBreakpoint[]): Area {
    const visible = RangedStyleValue.fromValueAndBreakpoints(true, breakpoints);
    const gridColumnStart = RangedStyleValue.fromValueAndBreakpoints(1, breakpoints);
    const gridColumnEnd = RangedStyleValue.fromValueAndBreakpoints(2, breakpoints);
    const gridRowStart = RangedStyleValue.fromValueAndBreakpoints(1, breakpoints);
    const gridRowEnd = RangedStyleValue.fromValueAndBreakpoints(2, breakpoints);

    return {
      ...cloneDeep(INITIAL_CARD_WIDGET_AREA),
      visible,
      gridColumnEnd,
      gridColumnStart,
      gridRowEnd,
      gridRowStart,
    };
  }

  copy(): CardWidgetDto {
    return {
      settings: cloneDeep(this.settings),
      styles: { ...this.styles },
      hoverStyles: { ...this.hoverStyles },
      activeStyles: { ...this.activeStyles },
      areas: this.areas.map((area: Area) => {
        return {
          ...area,
          content: {
            ...area.content,
            items: area.content.items.map((item: CardWidgetAreaContentItem) => {
              const content = IsCardContentItemWidget(item) ? cloneDeep(item.content) : item.content;

              if (IsCardContentItemWidget(item)) {
                (content as BlockPartWidget).saveStateBeforeRelocation();
              }

              return new CardWidgetAreaContentItem(item.type, content, item.linkDirection);
            }),
            styles: { ...area.content.styles, overflow: area.content.styles?.overflowX },
          },
          visible: area.visible.toDto(),
          gridColumnStart: area.gridColumnStart.toDto(),
          gridColumnEnd: area.gridColumnEnd.toDto(),
          gridRowStart: area.gridRowStart.toDto(),
          gridRowEnd: area.gridRowEnd.toDto(),
        };
      }),
      grid: {
        columnGap: this.grid.columnGap.toDto(),
        rowGap: this.grid.rowGap.toDto(),
        gridTemplateColumns: this.grid.gridTemplateColumns.toDto(),
        gridTemplateRows: this.grid.gridTemplateRows.toDto(),
      },
      breakpoints: this.breakpoints.map(({ value }: StyleApplicationBreakpoint) => String(value)),
    };
  }

  toServer(): CardWidgetDto {
    return {
      styles: { ...this.styles },
      hoverStyles: { ...this.hoverStyles },
      activeStyles: { ...this.activeStyles },
      areas: this.areas.map((area: Area) => ({
        ...area,
        content: {
          ...area.content,
          items: area.content.items.map((item: CardWidgetAreaContentItem) => {
            return IsCardContentItemWidget(item) ? { ...item, content: { id: (item.content as BlockPartWidget).id } } : item;
          }) as any,
          styles: { ...area.content.styles, overflow: area.content.styles?.overflowX },
        },
        visible: area.visible.toDto(),
        gridColumnStart: area.gridColumnStart.toDto(),
        gridColumnEnd: area.gridColumnEnd.toDto(),
        gridRowStart: area.gridRowStart.toDto(),
        gridRowEnd: area.gridRowEnd.toDto(),
      })),
      settings: this.settings.toServer(),
      grid: {
        columnGap: this.grid.columnGap.toDto(),
        rowGap: this.grid.rowGap.toDto(),
        gridTemplateColumns: this.grid.gridTemplateColumns.toDto(),
        gridTemplateRows: this.grid.gridTemplateRows.toDto(),
      },
      breakpoints: this.breakpoints.map(({ value }: StyleApplicationBreakpoint) => String(value)),
    };
  }
}
