import { Injectable } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';

@Injectable({ providedIn: 'root' })
export class WidgetHelper {
  getWidgetLabel(widget: BlockPartWidget, widgets: WidgetOption<any>[]): string {
    const item = widgets.find(item => item.code === widget.code);
    return (item && item.label) || '';
  }

  isLayoutSumError(errors: any, sectionI: number, rowI: number): boolean {
    return !!(
      errors &&
      errors.fields &&
      errors.fields.sections &&
      errors.fields.sections[sectionI] &&
      errors.fields.sections[sectionI].rows &&
      errors.fields.sections[sectionI].rows[rowI] &&
      errors.fields.sections[sectionI].rows[rowI].layout &&
      errors.fields.sections[sectionI].rows[rowI].layout.sum
    );
  }
}
