import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiModule } from '@api/api.module';
import { Environment } from '@environments/environment';
import { LimitedModule } from '@limited/limited.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DevelopmentModule } from '@private/pages/development/development.module';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { PublicModule } from '@public/public.module';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { SidebarMenuService } from '@shared/components/sidebar-menu/services/sidebar-menu.service';
import { ApiInterceptor } from '@shared/interceptors/api.interceptor';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { IconsService } from '@shared/services/icons.service';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ConfirmationService, MessageService, TreeDragDropService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SystemModule } from './system/system.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const declarations = [AppComponent];
const imports = [
  CommonModule,
  BrowserModule,
  HttpClientModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  ConfirmDialogModule,
  ToastModule,
  RouterModule,
  FormsModule,
  ScullyLibModule,
  PortalModule,
  TranslateModule.forRoot({
    loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
  }),
  BlockUIModule,
  RecaptchaModule,

  // Custom
  PublicModule,
  SystemModule,
  ApiModule,
  DevelopmentModule,
  LimitedModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: Boolean(Environment.enablePwa),
    // enabled: !isDevMode(),
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: Environment.enablePwa ? 'registerWhenStable:30000' : '',
  }),
];

@NgModule({
  imports: [...imports, LoaderModule],
  declarations: [...declarations],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LftngAqAAAAAA_78QSNSbqZCuZea4vmCzUx-OBr' } as RecaptchaSettings },
    AuthorizationService,
    SidebarMenuService,
    BreadcrumbService,
    MessageService,
    IconsService,
    ConfirmationService,
    TreeDragDropService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
