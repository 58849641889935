import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SidebarMenuService } from '@private/../shared/components/sidebar-menu/services/sidebar-menu.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { SidebarMenuItem } from '@shared/components/sidebar-menu/types/sidebar-menu.types';
import { Constants } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { CoreParams } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ServerSideEventService } from '@shared/services/server-side-event.service';
import { AdminTopBarCompatible } from '@shared/types/admin-top-bar.types';
import { HasUiConfig } from '@shared/types/has-ui-config.types';
import { StateKey } from '@shared/types/local-storage.types';
import { AppInterface } from '@shared/types/shared.types';
import { SidebarMenuCompatible, SidebarMenuItemCompatible } from '@shared/types/sidebar-menu.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { PrimeNGConfig } from 'primeng/api';
import { AppMainService } from './app.main.service';
import { AppMainModel } from './app.main.types';
import { ConfigService } from './pages/profile/components/config/services/config.service';
import { UiConfig } from './pages/profile/types/user.types';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['admin-main.component.scss'],
  providers: [AppMainService],
})
export class AdminMainComponent
  extends CoreComponent<AppMainService, AppMainModel>
  implements HasUiConfig, AdminTopBarCompatible, SidebarMenuCompatible, SidebarMenuItemCompatible
{
  uiConfig: UiConfig;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  menuClick: boolean;
  search = false;
  searchClick = false;
  userMenuClick: boolean;
  topBarUserMenuActive: boolean;
  notificationMenuClick: boolean;
  topBarNotificationMenuActive: boolean;
  rightMenuClick: boolean;
  rightMenuActive: boolean;
  configActive: boolean;
  configClick: boolean;
  resetMenu: boolean;
  menuHoverActive = false;
  menuItems: SidebarMenuItem[];
  appInterface = AppInterface;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: AppMainService,
    announcement: AnnouncementService,
    public readonly localStorageService: LocalStorageService,
    public readonly serverSideEventService: ServerSideEventService,
    private readonly cache: NewCacheService,
    private readonly sidebarMenuService: SidebarMenuService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly configService: ConfigService<AdminMainComponent>,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(route, router, translate, new AppMainModel(), service, announcement, new CoreParams({ initOnQueryParamChange: false }));
  }

  onInit(): void {
    super.onInit();

    this.configService.setApp(this);
    this.uiConfigInit();
    this.primengConfig.ripple = true;
    this.serverSideEventService.setEventSource();

    this.menuItems = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['dashboard'] },
      { label: 'Users', icon: 'pi pi-users', routerLink: ['user-list'] },
      { label: 'Teams', icon: 'pi pi-user', routerLink: ['team-list'] },
      {
        label: 'Type System',
        icon: 'pi pi-fw pi-align-justify',
        items: [
          { label: 'Artifact Types', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['artifact-type-list'] },
          { label: 'Link Types', icon: 'pi pi-fw pi-external-link', routerLink: ['link-type-list'] },
          { label: 'Attributes', icon: 'pi pi-fw pi-cog', routerLink: ['attribute-list'] },
          { label: 'Data Types', icon: 'pi pi-fw pi-key', routerLink: ['data-type-list'] },
        ],
      },
      { label: 'Artifacts', icon: 'pi pi-fw pi-ticket', routerLink: ['artifact-list'] },
      { label: 'Applications', icon: 'pi pi-fw pi-sitemap', routerLink: ['application-list'] },
      { label: 'Pages', icon: 'pi pi-fw pi-desktop', routerLink: ['pages'] },
      // { label: 'Development', icon: 'bi bi-cpu', routerLink: ['development'] },
      { label: 'Templates', icon: 'bi bi-columns-gap', routerLink: ['templates'] },
      { label: 'Workflows', icon: 'bi bi-arrow-left-right', routerLink: ['workflow-list'] },
      // { label: 'Export / Import', icon: 'pi pi-cloud-download', routerLink: ['import-export'] },
    ];
  }

  uiConfigInit(): void {
    const config = this.localStorageService.getFromState(StateKey.session, Constants.clientData)[Constants.uiConfig];
    this.uiConfig = config ? new UiConfig(config) : new UiConfig();
    this.configService.initStyleSheets(this.uiConfig);
  }

  onLayoutClick(): void {
    !this.searchClick && (this.search = false);
    !this.userMenuClick && (this.topBarUserMenuActive = false);
    !this.notificationMenuClick && (this.topBarNotificationMenuActive = false);
    !this.rightMenuClick && (this.rightMenuActive = false);

    if (!this.menuClick) {
      this.isSlim() && this.sidebarMenuService.reset();
      (this.overlayMenuActive || this.staticMenuMobileActive) && this.hideOverlayMenu();
      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.configActive && !this.configClick && (this.configActive = false);

    this.searchClick = false;
    this.configClick = false;
    this.userMenuClick = false;
    this.rightMenuClick = false;
    this.notificationMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: MouseEvent): void {
    this.menuClick = true;
    this.topBarUserMenuActive = false;
    this.topBarNotificationMenuActive = false;
    this.rightMenuActive = false;
    this.isOverlay() && (this.overlayMenuActive = !this.overlayMenuActive);

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      this.staticMenuMobileActive ? this.blockBodyScroll() : this.unblockBodyScroll();
    }
    this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification(RuntimeStateNotificationEnum.toggleSystemPanel, null));

    event.preventDefault();
  }

  // onSearchClick(event): void {
  //   this.search = !this.search;
  //   this.searchClick = !this.searchClick;
  // }

  onMenuClick(): void {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopBarUserMenuButtonClick(event: MouseEvent): void {
    this.userMenuClick = true;
    this.topBarUserMenuActive = !this.topBarUserMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopBarNotificationMenuButtonClick(event: MouseEvent): void {
    this.notificationMenuClick = true;
    this.topBarNotificationMenuActive = !this.topBarNotificationMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onRightMenuClick(event: MouseEvent): void {
    this.rightMenuClick = true;
    this.rightMenuActive = !this.rightMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onRippleChange(event: any): void {
    this.uiConfig.ripple = event.checked;
  }

  onConfigClick(): void {
    this.configClick = true;
  }

  isSlim(): boolean {
    return this.uiConfig.menuMode === 'slim';
  }

  isOverlay(): boolean {
    return this.uiConfig.menuMode === 'overlay';
  }

  isDesktop(): boolean {
    return window.innerWidth > 991;
  }

  isMobile(): boolean {
    return window.innerWidth <= 991;
  }

  hideOverlayMenu(): void {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  blockBodyScroll(): void {
    document.body.classList ? document.body.classList.add('blocked-scroll') : (document.body.className += ' blocked-scroll');
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
}
