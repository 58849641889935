import { Pipe, PipeTransform } from '@angular/core';
import { TableMethods } from '@shared/methods/table.methods';
import { SelectOption } from '@shared/types/shared.types';
import { NewTableColumn } from '@shared/types/table.types';
import { GroupAttributeItem } from '../types/list-widget-grouping.types';

@Pipe({ name: 'isColumnFilterDisabled' })
export class IsColumnFilterDisabledPipe implements PipeTransform {
  transform(groupingAttribute: SelectOption<string, GroupAttributeItem> | undefined, column: NewTableColumn): boolean {
    if (!groupingAttribute || (!TableMethods.isFilterTypeDate(column.meta.filterType) && !TableMethods.isFilterTypeDateTime(column.meta.filterType))) {
      return false;
    }

    return groupingAttribute.value.id === column.key;
  }
}
