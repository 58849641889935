export const LAYOUT_SIDEBAR_SELECTOR = '.layout-sidebar';
export const PREVIEW_PENCIL_SELECTOR = '.utility-buttons';
export const PAGE_BUILDER_TOP_BAR = '.layout-topbar';
export const PAGE_BUILDER_HEADER = '.page-builder_header';
export const PAGE_BUILDER_FOOTER = '.layout-footer';
export const PAGE_BUILDER_TOPBAR = '.layout-topbar';
export const LAYOUT_SIDEBAR_INACTIVE_FLAG = '.layout-static-inactive';
export const SIDEBAR_Z_INDEX = 599;

export const BODY = Object.freeze({
  left: 'paddingLeft',
  top: 'paddingTop',
  right: 'paddingRight',
  bottom: 'paddingBottom',
});

export const SETTINGS = Object.freeze({
  defaultBackgroundColor: '#ffffff',
  defaultContentColor: '#000000',
  defaultArrowButtonBackgroundColor: '',
  defaultArrowButtonContentColor: '#ffffff',
  defaultWidth: '35%',
  defaultWidthForSmallScreen: '100%',
  defaultPercentWidth: 50,
  defaultArrowButtonPadding: 20,
  defaultArrowButtonWidth: '100px',
  defaultArrowButtonContent: '',
  shadowSettings: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
});
