import { Pipe, PipeTransform } from '@angular/core';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

@Pipe({
  name: 'isBackgroundOfType',
})
export class IsBackgroundOfTypePipe implements PipeTransform {
  transform(actualType: BackgroundTypeEnum, targetType: BackgroundTypeEnum): boolean {
    return actualType === targetType;
  }
}
