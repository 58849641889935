import { Injectable } from '@angular/core';
import { FolderListResponseDto } from '@api/models/folder-list-response-dto';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import { lastValueFrom } from 'rxjs';
import { FolderTreeNode } from '../types/folder-widget.types';

@Injectable({
  providedIn: 'root',
})
export class FolderWidgetHelper {
  constructor(private folderService: TenantFolderService) {}

  isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  isMultipleSelect(listOrNode: FolderTreeNode[] | FolderTreeNode): boolean {
    return Array.isArray(listOrNode) && listOrNode.length > 1;
  }

  isRootFolder(listOrNode: FolderTreeNode[] | FolderTreeNode): boolean {
    return Array.isArray(listOrNode) ? listOrNode.filter(item => !item.parent).length > 0 : listOrNode && !listOrNode.parent;
  }

  sortFolders(list: FolderTreeNode[]): FolderTreeNode[] {
    return list.sort(({ folderSequence: aa = 0 }, { folderSequence: bb = 0 }) => (aa > bb ? 1 : aa == bb ? 0 : -1));
  }

  sortFoldersPrivateToTop(list: FolderTreeNode[], privateFolderId: string): FolderTreeNode[] {
    return list.sort(({ id: prevId }, { id: nextId }) => {
      if (prevId === privateFolderId) {
        return -1;
      } else if (nextId === privateFolderId) {
        return 1;
      }
      return 0;
    });
  }

  getFolderById(list: FolderTreeNode[], id: string): FolderTreeNode {
    let folder = list.filter(folder => folder.id === id)[0];
    if (!folder) {
      list.forEach(item => {
        const res = this.getFolderById(item.children as FolderTreeNode[], id);
        res && (folder = res);
      });
    }
    return folder;
  }

  setAllFolderColors(list: FolderTreeNode[], folderColor: Record<string, string>, coloringArrow: boolean, coloringText: boolean): void {
    list.forEach(folder => {
      const color = folderColor[folder.id];
      color && this.setFolderColor(folder, color, coloringArrow, coloringText);
      folder.children && this.setAllFolderColors(folder.children, folderColor, coloringArrow, coloringText);
    });
  }

  setFolderColor(folder: FolderTreeNode, color: string, coloringArrow: boolean, coloringText: boolean): void {
    coloringText && (folder.styles.color = color);
    const elem = (document as any).getElementById(folder.id);
    if (!elem) return;
    const rootFolderElement = elem.closest('.p-treenode-content');
    const iconElement = rootFolderElement.querySelector('.p-treenode-icon');
    const arrowElement = rootFolderElement.querySelector('.p-tree-toggler-icon');
    iconElement && (iconElement.style.color = color);
    coloringArrow && arrowElement && (arrowElement.style.color = color);
  }

  updateArrows(anyFolderId: string, isVisible: boolean): void {
    const element = document.getElementById(anyFolderId);
    const treeEl = element && element.closest('p-tree');
    treeEl && Array.from(treeEl.getElementsByClassName('p-tree-toggler')).forEach((el: any) => (el.style.display = isVisible ? 'inline-flex' : 'none'));
  }

  async getChildrenIds(nodeId: string): Promise<string[]> {
    const $and = [{ parentFolderId: { $oid: nodeId } }, { deleted: { $eq: null } }];
    const resp: FolderListResponseDto = await lastValueFrom(
      this.folderService.folderControllerList({
        filter: JSON.stringify({ $and }),
      }),
    );
    return resp.data.map(item => item.id);
  }

  async getAllChildrenIds(nodeId: string): Promise<string[]> {
    let ids: string[] = await this.getChildrenIds(nodeId);

    const promiseArr = ids.map(id => this.getAllChildrenIds(id));
    const childIds = await Promise.all(promiseArr);

    ids = ids.concat(...childIds);
    ids.push(nodeId);

    return [...new Set(ids)];
  }

  getRemoveMessage(selectedFile: FolderTreeNode[]): string {
    return `Are you sure remove ${selectedFile.length > 1 ? 'folders' : 'folder'}?`;
  }
}
