import { Component, Input } from '@angular/core';
import { WorkflowTriggerManual } from '@workflows/types/triggers/trigger-manual';

@Component({
  selector: 'app-trigger-manual',
  templateUrl: './trigger-manual.component.html',
  styleUrls: ['./trigger-manual.component.scss'],
})
export class TriggerManualComponent {
  @Input() trigger: WorkflowTriggerManual;

  onAddInput(): void {
    this.trigger.inputDataClientArray.push({ key: '', isArray: false, isOptional: false });
  }

  onRemoveInput(index: number): void {
    this.trigger.inputDataClientArray.splice(index, 1);
  }
}
