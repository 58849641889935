import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { PageResponseDto } from '@api/models/page-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { CacheDataHolderService } from '@shared/cache/cache-data-holder.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { PageHelper } from '@shared/helpers/page-helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { UrlMethods } from '@shared/methods/url.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TemplateService } from '@shared/services/page-management/template.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { StateKey } from '@shared/types/local-storage.types';
import { RuleDataHolderService, RuleEngineService } from '@workflows/services';
import { take } from 'rxjs/operators';
import { PageModel } from '../types/page.types';

@Injectable()
export class PageService extends CoreService<any, PageModel> {
  private metaTagElements: HTMLMetaElement[] = [];

  constructor(
    private readonly cache: NewCacheService,
    private readonly dataHolder: CacheDataHolderService,
    private readonly widgetService: WidgetService,
    private readonly templateService: TemplateService,
    private readonly authorizationService: AuthorizationService,
    public readonly localStorageService: LocalStorageService,
    public readonly pageHelper: PageHelper,
    private readonly pushNotificationService: PushNotificationService,
    private readonly ruleEngineService: RuleEngineService,
    private readonly ruleDataHolderService: RuleDataHolderService,
    private readonly title: Title,
    private readonly meta: Meta,
  ) {
    super();
  }

  async init(context: any, model: PageModel): Promise<void> {
    super.init(context, model);
    this.pageHelper.isOnPage = true;

    const anonymousUserToken: string = this.localStorageService.getFromState(StateKey.master, Constants.token);

    if (!anonymousUserToken) {
      await this.authorizationService.anonymousLogin();
    }

    await this.cache.initCache();
    this.cache.initCache();

    if (this.cache.isLoaded) await this.postInit();
    else
      this.cache.isLoaded$.pipe(take(1)).subscribe({
        next: () => this.postInit(),
      });
  }

  setPageMetaData(dto?: PageResponseDto): void {
    this.title.setTitle(dto?.name || 'Elwis');

    if (dto && dto.seoFields) {
      // TODO remove this comments when BE will add "keywords" to the API
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { ogTitle, ogImage, description, doNotIndex, keywords } = dto.seoFields;
      const url = new URL(window.location.href);
      const tags: MetaDefinition[] = [
        { property: 'og:title', content: ogTitle || dto.name },
        { property: 'og:url', content: url.origin + url.pathname },
      ];

      if (doNotIndex) tags.push({ name: 'robots', content: 'noindex' });
      if (keywords) tags.push({ name: 'keywords', content: keywords });
      if (ogImage) tags.push({ property: 'og:image', content: ogImage });
      if (description) {
        tags.push({ property: 'og:description', content: description });
        tags.push({ name: 'description', content: description });
      }

      this.metaTagElements = this.meta.addTags(tags);
    }
  }

  removePageMetaElements(): void {
    this.metaTagElements.forEach(element => this.meta.removeTagElement(element));
    this.metaTagElements = [];
  }

  private async postInit(): Promise<void> {
    this.initRules();
    await this.initPage().then(() => this.initSubscriptions());
    await this.pushNotificationService.init();
    window.scroll({ top: 0 });
  }

  private initRules(): void {
    this.ruleEngineService.enableRuleEngine(true);
    this.dataHolder.initData();
    this.dataHolder.isLoaded$.subscribe(loaded => {
      const user = this.dataHolder.user;
      loaded && this.ruleDataHolderService.initRules(!user.isGuest);
    });
  }

  private initSubscriptions(): void {
    this.c.registerSubscription(
      this.cache.user.subscribe(user => {
        this.m.tenantApplication = (user as SelfUserResponseDto).tenant?.applications.find(application => this.m.page.applicationId === application.id);
        this.m.canUserAccessEdit = this.cache.userMeta?.isTenantAdmin || Boolean(this.m.tenantApplication?.isAdmin);
        this.m.user = user as SelfUserResponseDto;
      }),
    );
  }

  private async initPage(): Promise<void> {
    this.localStorageService.initialize();
    await this.loadPageAsLoggedInUser();
    this.pageHelper.setBackgroundToBody(this.m.page.settings.styles);
  }

  private async loadPageAsLoggedInUser(): Promise<void> {
    try {
      // following regexp removes "/page", query params and fragment
      const pathName = UrlMethods.getPageIdOrAliasFromUrl(this.c.url);
      const loggedInUserToken: string = this.localStorageService.getFromState(StateKey.session, Constants.token);

      setTimeout(() => {
        this.m.canUserAccessEdit = this.m.user?.tenant?.isAdmin || Boolean(this.m.tenantApplication?.isAdmin);
      }, 1000);

      if (!loggedInUserToken) throw { status: 401 };

      if (this.c.isFirstCall || (!this.c.isFirstCall && ((this.c.urlParams.id && this.m.pageId !== this.c.urlParams.id) || this.m.page?.alias !== pathName))) {
        this.m.pageId = this.c.urlParams.id;
        this.m.loading = true;
        const dto: PageResponseDto = await this.cache.data.pages.getAsync(this.c.urlParams.id || pathName);
        dto.applicationId && this.localStorageService.setToState(StateKey.session, Constants.selectedApplication, dto.applicationId);
        this.setPageMetaData(dto);
        await this.setPageToModel(dto);
      }
    } catch (e: any) {
      if (e?.status === 404 || e?.status === 401) {
        await this.loadPageAsGuest();
      }

      if (e?.status === 403) {
        await this.c.router.navigateByUrl('/access', { replaceUrl: true });
      }

      console.error('Getting page error', e);
    } finally {
      this.m.loading = false;
    }
  }

  private async loadPageAsGuest(): Promise<void> {
    // following regexp removes "/page", query params and fragment
    const pathName = UrlMethods.getPageIdOrAliasFromUrl(this.c.url);

    try {
      if (this.c.urlParams.id) throw { status: 404 };

      if (!this.localStorageService.get(StateKey.master)) {
        this.localStorageService.initialize();
      }

      const anonymousUserToken: string = this.localStorageService.getFromState(StateKey.master, Constants.token);

      if (!anonymousUserToken) {
        await this.authorizationService.anonymousLogin();
      }

      this.pageHelper.usePublicToken = true;
      this.m.canUserAccessEdit = false;

      const dto: PageResponseDto = await this.cache.data.pages.getAsync(pathName);
      this.setPageMetaData(dto);
      await this.setPageToModel(dto);
    } catch (e: any) {
      if (e?.status === 404) {
        await this.c.router.navigateByUrl(!pathName ? SharedMethods.getRedirectUrlQuery(window.location.href) : '/notfound', { replaceUrl: Boolean(pathName) });
      } else if (e?.status === 403) {
        await this.c.router.navigateByUrl('/access', { replaceUrl: true });
      } else if (e?.status === 401) {
        await this.c.router.navigateByUrl(SharedMethods.getRedirectUrlQuery(window.location.href));
      } else {
        await this.c.router.navigateByUrl(SharedMethods.getRedirectUrlQuery(window.location.href));
      }

      console.error('Public page error ', e);
    }
  }

  private async setPageToModel(dto: PageResponseDto): Promise<void> {
    const page = new Page(dto);

    if (page.pageParameters) {
      const queryParams = page.pageParameters
        .replace('?', '')
        .split('&')
        .reduce<Record<string, string>>((res, params) => {
          const [key, value] = params.split('=');
          res[key] = value;

          return res;
        }, {});
      await this.c.router.navigate([], { queryParams });
    }

    await this.templateService.loadPageSectionTemplates(page);
    await this.templateService.loadWidgetTemplates(page.getWidgetsWithTemplateToLoad());
    await this.widgetService.loadWidgets(page.getPartsWithWidgetToLoad());
    await this.templateService.loadSidebarModalPageSectionTemplates(page);
    await this.templateService.loadWidgetTemplates(page.getWidgetsWithTemplateToLoad(true));
    await this.widgetService.loadWidgets(page.getPartsWithWidgetToLoad(true));
    await this.widgetService.loadInnerWidgetsForPartsWithCard(page.getPartsWithCardWidget());
    await this.widgetService.loadInnerWidgetsForCardsWithinSidebarWidgets(page.getSidebarsWithCards());
    await this.widgetService.loadInnerWidgetsForCardsWithinSidebarModalWidgets(page.getSidebarModalsWithCards());

    this.m.page = page;
    this.reloadPageIfNeeded(page);
  }

  private reloadPageIfNeeded(page: Page): void {
    if (SharedMethods.isInPageBuilder() || !page.settings.autoReload || !page.settings.reloadInterval) return;
    setTimeout(() => window.location.reload(), page.settings.reloadInterval);
  }
}
