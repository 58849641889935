import { Inject, Injectable } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { SidebarPosition, SidebarWidgetService } from '@shared/services/sidebar-widget.service';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { ResizeEvent } from 'angular-resizable-element';
import { merge, Observable, startWith, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CardResizeService {
  resizableCardPositionClass$: Observable<string>;
  cardWidth$: Observable<number>;

  private inputChange: Subject<number> = new Subject<number>();
  private cardResize: Subject<number> = new Subject<number>();

  constructor(
    @Inject(WIDGET) private readonly widget: BlockPartWidget<{ model: CardWidgetModel }>,
    private readonly sidebarWidgetService: SidebarWidgetService,
  ) {
    this.resizableCardPositionClass$ = this.sidebarWidgetService.positionChange$.pipe(
      map(() => this.getResizableCardPositionClass()),
      startWith(this.getResizableCardPositionClass()),
    );
    this.cardWidth$ = merge(this.inputChange, this.cardResize);
  }

  validateResize({ rectangle: { width } }: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX = 100;

    return !width || width >= MIN_DIMENSIONS_PX;
  }

  onSizeInputChange(size: number): void {
    this.inputChange.next(size);
  }

  onCardResize(size: number): void {
    this.cardResize.next(Math.floor(size));
  }

  private getResizableCardPositionClass(): string {
    const { position } = this.sidebarWidgetService.getPositionSettings(this.widget.id || undefined);
    const side = position === SidebarPosition.right ? 'r' : 'l';

    return `m${side}-auto`;
  }
}
