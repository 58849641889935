import { Injectable } from '@angular/core';
import { SystemTenantService } from '@api/services/system-tenant.service';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewSystemTenant } from '@shared/types/tenant.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { TenantModel } from '../types/tenant.types';

@Injectable()
export class TenantService extends CoreService<any, TenantModel> {
  constructor(
    private readonly systemTenantService: SystemTenantService,
    private readonly elvisUtil: ElvisUtil,
    private readonly blockUiService: BlockUiService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
  ) {
    super();
  }

  async init(context: any, model: TenantModel): Promise<void> {
    super.init(context, model);
    await this.loadTenantByIdFromUrl();
  }

  async save(): Promise<void> {
    try {
      this.setUiBlockingLoading(true);

      const tenant = await this.saveTenant();
      if (tenant) {
        await this.cancel();
      }
    } finally {
      this.setUiBlockingLoading(false);
    }
  }

  async deleteWithConfirmation(): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + this.m.tenant.name + '?',
      acceptLabel,
      rejectLabel,
      accept: () => this.delete(this.m.tenant.id).then(() => this.cancel()),
    });
  }

  async delete(id: string): Promise<void> {
    this.setUiBlockingLoading(true);

    try {
      const success = await lastValueFrom(this.systemTenantService.systemTenantControllerDelete({ id }));
      if (success) {
        await this.cancel();
      }
    } finally {
      this.setUiBlockingLoading(false);
    }
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl(`/system/tenant-list`);
  }

  private async loadTenantByIdFromUrl(): Promise<void> {
    try {
      this.setLoading(true);

      if (this.c.urlParams.id) {
        const dto = await lastValueFrom(this.systemTenantService.systemTenantControllerGet({ id: this.c.urlParams.id }));

        if (dto) {
          this.m.tenant = new NewSystemTenant(dto);
          this.setOriginalObject<NewSystemTenant>(this.m.tenant);
        }
      }
    } finally {
      this.setLoading(false);
    }
  }

  private async saveTenant(): Promise<NewSystemTenant> {
    if (this.m.tenant.id) {
      return await lastValueFrom(
        this.systemTenantService.systemTenantControllerUpdate({
          body: { id: this.m.tenant.id, ...(this.getChangedDataFromOriginalObject<NewSystemTenant>(this.m.tenant) as any) },
        }), // TODO: perhaps can be done better than "as any"
      );
    } else {
      const { domain, name, isPublic, registrationAllowed } = this.m.tenant;
      return await lastValueFrom(
        this.systemTenantService.systemTenantControllerCreate({
          origin: window.location.origin,
          body: { domain, name, isPublic, registrationAllowed },
        }),
      );
    }
  }

  private setLoading(loading: boolean): void {
    this.m.inProgress = loading;
  }

  private setUiBlockingLoading(loading: boolean): void {
    this.setLoading(loading);
    if (loading) {
      this.blockUiService.blockUi();
    } else {
      this.blockUiService.unblockUi();
    }
  }
}
