import { LinkTypeResponseDto, LinkTypeRestrictionResponseDto, RecordDto } from '@api/models';
import { LinkRestriction } from './link.types';

export class LinkType implements LinkTypeResponseDto {
  id: string;
  applicationId: string;
  alias = '';
  name = '';
  description = '';
  directionalLabel = false;
  outgoingName = '';
  incomingName = '';
  uri = '';
  restrictions: LinkTypeRestrictionResponseDto[] = [];
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;

  constructor(linkType?: Partial<LinkTypeResponseDto>) {
    if (linkType) {
      Object.assign(this, linkType);
      this.directionalLabel = !(linkType.incomingName === linkType.outgoingName);
    }
  }

  // TODO: checkout the need of this method, if it's correct
  isLinkingArtifactTypeId(artifactTypeId: string): boolean {
    return this.restrictions.some(({ destinationArtifactTypeId, sourceArtifactTypeId }: LinkRestriction) => {
      return [destinationArtifactTypeId, sourceArtifactTypeId].includes(artifactTypeId);
    });
  }

  /**
   * Returns true if the link type is linking both source and destination artifact types, false otherwise.
   * @param sourceAtId source artifact type id
   * @param destinationAtId destination artifact type id
   * @returns true if the link type is linking both source and destination artifact types, false otherwise
   */
  isLinkingBothSourceAndDestination(sourceAtId: string, destinationAtId: string): boolean {
    return this.restrictions.some(({ destinationArtifactTypeId, sourceArtifactTypeId }: LinkRestriction) => {
      return (
        (destinationArtifactTypeId === destinationAtId && sourceArtifactTypeId === sourceAtId) ||
        (destinationArtifactTypeId === sourceAtId && sourceArtifactTypeId === destinationAtId)
      );
    });
  }

  isLinkingFromArtifactType(artifactTypeId: string): boolean {
    return this.restrictions.some(({ sourceArtifactTypeId }: LinkRestriction) => sourceArtifactTypeId === artifactTypeId);
  }

  isLinkingToArtifactType(artifactTypeId: string): boolean {
    return this.restrictions.some(({ destinationArtifactTypeId }: LinkRestriction) => destinationArtifactTypeId === artifactTypeId);
  }
}
