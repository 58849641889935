import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { ContentStyles } from '../../widgets/menu-widget/types/menu-widget-styles.types';
import { FontStyleEnum, FontWeightEnum, TextDecorationEnum } from '../../widgets/shared/types/style.types';

export class TextStyleHelper {
  onBoldClick(styles: FormatStyles | ContentStyles): void {
    styles.fontWeight = styles.fontWeight === FontWeightEnum.normal ? FontWeightEnum.bold : FontWeightEnum.normal;
  }

  onItalicClick(styles: FormatStyles | ContentStyles): void {
    styles.fontStyle = styles.fontStyle === FontStyleEnum.normal ? FontStyleEnum.italic : FontStyleEnum.normal;
  }

  onUnderscoreClick(styles: FormatStyles | ContentStyles): void {
    styles.textDecoration = styles.textDecoration === TextDecorationEnum.underline ? TextDecorationEnum.none : TextDecorationEnum.underline;
  }

  onLineThroughClick(styles: FormatStyles | ContentStyles): void {
    styles.textDecoration = styles.textDecoration === TextDecorationEnum.lineThrough ? TextDecorationEnum.none : TextDecorationEnum.lineThrough;
  }
}
