import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { FolderUrlKeyChange } from '@widgets/folder-widget/types/folder-widget.types';

@Component({
  selector: 'app-folder-widget-settings',
  templateUrl: './folder-widget-settings.component.html',
  styleUrls: ['./folder-widget-settings.component.scss'],
})
export class FolderWidgetSettingsComponent implements OnInit {
  @Input() settings: FolderWidgetSettings;
  @Input() showUrlChoose = true;
  @Output() onChange = new EventEmitter<void>();
  @Output() changeUrlKey = new EventEmitter<FolderUrlKeyChange>();
  @Output() updateFolderIcons = new EventEmitter<void>();

  currentUrlKey: string;

  ngOnInit(): void {
    this.currentUrlKey = this.settings.folderUrlKey;
  }

  onChangeModel(): void {
    this.onChange.emit();
  }

  onChangeUrlKey(): void {
    this.settings.folderUrlKey !== this.currentUrlKey && this.changeUrlKey.emit({ from: this.currentUrlKey, to: this.settings.folderUrlKey });
    this.currentUrlKey = this.settings.folderUrlKey;
  }
}
