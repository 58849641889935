<div class="col-12">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="save()">
    <div class="mb-5" *ngIf="resetPasswordForm.hasError('notSame') || resetPasswordForm.status === 'VALID'">
      <p-message severity="error" text="Passwords do not match"
                 *ngIf="resetPasswordForm.hasError('notSame')">
      </p-message>
      <p-message severity="success" text="Passwords are valid and matching"
                 *ngIf="resetPasswordForm.status === 'VALID'">
      </p-message>
    </div>
    <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-key"></i>
            </span>
      <span class="dynamic-label">
                <input id="oldPassword" pPassword type="password" formControlName="oldPassword" />
                <label class="dynamic-label-text" for="oldPassword" translate>Old password</label>
            </span>
    </div>
    <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-key"></i>
            </span>
      <span class="dynamic-label">
                <input id="password" pPassword type="password" aria-describedby="username-help" formControlName="password" />
                <label class="dynamic-label-text" for="password" translate>New password</label>
            </span>
    </div>

    <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <i class="pi pi-key"></i>
            </span>
      <span class="dynamic-label">
                <input id="confirmPassword" pPassword type="password" formControlName="confirmPassword" />
                <label class="dynamic-label-text" for="confirmPassword" translate>Confirm new password</label>
            </span>
    </div>
    <button
      [disabled]="resetPasswordForm.status === 'INVALID'"
      class="p-button p-button-success" pButton
      [label]="'Save' | translate"
      type="submit"
    >
    </button>
  </form>
</div>
