import { ApexChartWidgetTypeChart } from './apex-chart-widget.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ApexChartWidgetOptions } from '@widgets/apex-chart/types/apex-chart-widget-options.types';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { BorderStyles } from '@widgets/shared/types/style.types';

export class ApexChartType {
  name = 'Chart-1';
  type: ApexChartWidgetTypeChart = ApexChartWidgetTypeChart.bar;
  artifactType: SelectOption<string, NewArtifactType> | null;
  selectedAttribute: NewAttribute | null;
  attributes: NewAttribute[] = [];
  allAttributes: NewAttribute[] = [];
  attributesFilter: ArtifactFilter[] = [];
  axis: string;
  axisId: string;
  isUnderUrlControl = false;
  color: string;
  opacity: string;
  borderStyles = new BorderStyles();

  constructor(dto?: ApexChartDto, options?: ApexChartWidgetOptions) {
    dto && options && this.fromDto(dto, options);
  }

  toServer(): ApexChartDto {
    const dto: ApexChartDto = {
      name: this.name,
      type: this.type,
      artifactTypeId: this.artifactType?.value.id || null,
      selectedAttributeId: this.selectedAttribute?.id || null,
      axis: this.axis,
      axisId: this.axisId,
    };

    this.attributesFilter.length &&
      (dto.attributesFilter = this.attributesFilter.filter(filter => filter.attribute && (filter.dataType || filter.type)).map(filter => filter.toServer()));
    this.isUnderUrlControl && (dto.isUnderUrlControl = this.isUnderUrlControl);
    this.color && (dto.color = this.color);
    this.opacity && (dto.opacity = this.opacity);
    return dto;
  }

  fromDto(dto: ApexChartDto, options: ApexChartWidgetOptions): void {
    const { name, type, artifactTypeId, selectedAttributeId, attributesFilter, axis, axisId, isUnderUrlControl, color, opacity } = dto;
    Object.assign(this, { name, type, axis, axisId, isUnderUrlControl, color, opacity });

    if (artifactTypeId) {
      const artifactType = options.artifactTypes.listMap[artifactTypeId];
      this.artifactType = new SelectOption<string, NewArtifactType>(artifactType.name, artifactType);
    }

    if (selectedAttributeId) {
      this.selectedAttribute = options.attributes.find(attr => attr.id === selectedAttributeId) || null;
    }

    if (!this.attributesFilter.length && attributesFilter?.length) {
      this.attributesFilter = attributesFilter.map(filter =>
        ArtifactFilter.fromDtoAndOptions(filter, {
          attributes: options.attributes,
          dataTypes: options.dataTypes.list,
          linkTypes: options.linkTypes.list,
        }),
      );
    }
  }
}

export class ApexChartDto {
  name: string;
  type: ApexChartWidgetTypeChart;
  artifactTypeId: string | null;
  selectedAttributeId: string | null;
  attributesFilter?: ArtifactFilterDto[];
  axis: string;
  axisId: string;
  isUnderUrlControl?: boolean;
  color?: string;
  opacity?: string;
}
