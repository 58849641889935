import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { NewTableColumn } from '@shared/types/table.types';
import { LinkPopupModelDto } from '@widgets/link-popup/types/link-popup.types';
import { WidgetModel } from '@widgets/shared/types/widget-model.interface';
import { WidgetTableUtil } from '@widgets/shared/utils/table.util';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ListWidgetOptions } from './list-widget-options.types';
import { ListWidgetModelDto, ListWidgetSelected } from './list-widget-selected.types';
import { ListWidgetTableSettings } from './list-widget-settings.types';

export class ListWidgetValue {
  model: ListWidgetModel = new ListWidgetModel();

  constructor(value?: Partial<ListWidgetValue>) {
    value && Object.assign(this, value);
  }
}

export const TABLE_FORMAT_STATE_KEY = 'tableFormatSettings';
export const TABLE_PARAM_FILTER_STATE_KEY = 'tableParamFilterSettings';

export class ListWidgetModel implements WidgetModel<ListWidgetModelDto> {
  isFirstLoad = true;
  hash: string | null = null;
  addButtonItems: MenuItem[] = [];
  selectedColumns$ = new Subject<NewTableColumn[]>();
  options = new ListWidgetOptions();
  selected = new ListWidgetSelected();
  settings = new ListWidgetTableSettings();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  state: Record<string, any> | null = null;
  preferredModuleArtifactTypeIds?: string[] = [];
  applicationId: string;
  listType: ListWidgetType | null = null;
  linkingPopupDtoMap: Record<string, Record<string, LinkPopupModelDto>> = {};

  constructor(model?: Partial<ListWidgetModel>) {
    model && Object.assign(this, model);
  }

  async initModel(modelDto: ListWidgetModelDto, applicationId: string): Promise<void> {
    this.applicationId = applicationId;
    this.fromDto(modelDto);
    this.settings.showTable = false;
  }

  fromDto(dto: ListWidgetModelDto) {
    this.settings.fromDto(dto.settings, this.options);
    this.selected.setFromDto(dto.selected, this.options, this.applicationId);
    this.state = WidgetTableUtil.convertStateFromDto(dto.state, this.options);
    this.listType = dto.listType;
    this.linkingPopupDtoMap = dto.linkingPopupDtoMap || {};
  }

  toServer(): ListWidgetModelDto {
    return {
      listType: this.listType,
      selected: this.selected.toServer(),
      settings: this.settings.toServer(),
      state: WidgetTableUtil.convertStateToServer(this.state),
      linkingPopupDtoMap: this.linkingPopupDtoMap,
    };
  }
}

export enum ListWidgetType {
  // system-user = 'USER',
  // tenant = 'TENANT',
  file = 'File',
  artifact = 'Artifacts',
  // linkedArtifact = 'Linked artifacts',
  // application = 'APPLICATION',
}

export enum ArtifactPromotionAction {
  PROMOTE = 'PROMOTE',
  DEMOTE = 'DEMOTE',
}

export enum AddArtifactModulePositionEnum {
  BEFORE = 'BEFORE',
  INTO = 'INTO',
  AFTER = 'AFTER',
}
