import { Component, Input } from '@angular/core';
import { CanvasItem } from '../../types/page-builder-schematic.types';

@Component({
  selector: 'app-row-item',
  templateUrl: './row-item.component.html',
  styleUrls: ['./row-item.component.scss'],
})
export class RowItemComponent {
  @Input()
  public canvas: CanvasItem;
}
