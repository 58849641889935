import { Injectable } from '@angular/core';
import { WorkflowAction, WorkflowTriggerEvent } from '../types';

@Injectable({ providedIn: 'root' })
export class RuleActionExecutorService {
  executeAction(action: WorkflowAction, triggerEvent?: WorkflowTriggerEvent) {
    if (action.canBeExecuted(triggerEvent)) {
      action.execute(triggerEvent);
    }
  }
}
