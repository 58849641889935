import { Injectable } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { SystemUserCreateRequestDto } from '@api/models/system-user-create-request-dto';
import { SystemUserUpdateRequestDto } from '@api/models/system-user-update-request-dto';
import { TenantArtifactService, TenantArtifactTypeService, TenantUserService } from '@api/services';
import { SystemTenantService } from '@api/services/system-tenant.service';
import { SystemUserService } from '@api/services/system-user.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { DoSomethingWithConfirmationParams, SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { SystemUserPageModel } from '../types/system-user.types';

@Injectable()
export class SystemUserPageService extends CoreService<any, SystemUserPageModel> {
  constructor(
    private readonly systemUserService: SystemUserService,
    private readonly tenantUserService: TenantUserService,
    private readonly systemTenantService: SystemTenantService,
    private readonly confirmationService: ConfirmationService,
    private readonly blockUiService: BlockUiService,
    private readonly elvisUtil: ElvisUtil,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly cache: NewCacheService,
  ) {
    super();
  }

  async init(context: any, model: SystemUserPageModel): Promise<void> {
    super.init(context, model);
    this.initSubscriptions();

    try {
      this.m.inProgress = true;

      const tenantDtos = (await lastValueFrom(this.systemTenantService.systemTenantControllerList())).data;
      this.m.tenantOptions = tenantDtos.map(dto => new SelectOption(dto.name, dto.id));
      this.m.tenantOptionsWithoutDeleted = tenantDtos.filter(dto => !dto.deleted).map(dto => new SelectOption(dto.name, dto.id));

      await this.setUserToModel();
    } finally {
      this.m.inProgress = false;
    }
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();
    try {
      const success = this.m.user.id ? await this.update() : await this.create();
      success && (await this.cancel());
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async deleteWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete', 'Are you sure that you want to delete'),
      this.delete.bind(this),
    );
  }

  async resetPasswordWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Reset password', 'Are you sure that you want to reset password'),
      this.resetUserPassword.bind(this),
    );
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl(`/system/user-list`);
  }

  private async setUserToModel(): Promise<void> {
    if (this.c.urlParams.id) {
      const dto = await lastValueFrom(this.systemUserService.systemUserControllerGet({ id: this.c.urlParams.id }));
      this.m.user = new NewSystemUser(dto);
      this.m.selectedTenantId = dto.tenant?.id || '';
      this.m.isTenantAdmin = !!dto.tenant?.isAdmin;

      //TODO: tenant validation remove when BE will implement system admin correctly
      if (this.m.user.tenant.id === this.m.loggedUser.tenant!.id && (this.m.user.tenant?.profileArtifactId || null) !== null) {
        const userProfileArtifact = await lastValueFrom(
          this.tenantArtifactService.artifactControllerGet({ id: this.m.user.tenant!.profileArtifactId as string }),
        );
        const userProfileArtifactType = await lastValueFrom(
          this.tenantArtifactTypeService.artifactTypeControllerGet({ id: userProfileArtifact.artifactTypeId }),
        );
        this.m.userProfileUrl = {
          routerLink: `/page/${userProfileArtifactType.defaultPageId}`,
          queryParams: {
            artifactId: this.m.user.tenant.profileArtifactId,
          },
        };
      }
    }
  }

  private async create(): Promise<boolean> {
    if (this.m.password !== this.m.confirmPassword && !this.m.generatePassword) {
      await this.c.announcement.error('Passwords do not match');
      return false;
    }

    if (!this.m.selectedTenantId) {
      await this.c.announcement.error('Tenant must be selected');
      return false;
    }

    const tenant = {
      id: this.m.selectedTenantId,
      isAdmin: this.m.isTenantAdmin,
      applications: [],
    };
    const body: SystemUserCreateRequestDto = {
      // name: this.m.user.name as string,
      email: this.m.user.email,
      password: !this.m.generatePassword ? this.m.password || '' : undefined,
      isSystemAdmin: this.m.user.isSystemAdmin,
      isLoginDisabled: Boolean(this.m.user.isLoginDisabled),
      generatePassword: this.m.generatePassword || undefined,
      tenant,
    };
    await lastValueFrom(this.systemUserService.systemUserControllerCreate({ body }));
    return true;
  }

  private async update(): Promise<boolean> {
    const { id, email, isSystemAdmin, tenant, isLoginDisabled } = this.m.user;
    const body: SystemUserUpdateRequestDto = {
      id,
      email,
      // name: this.m.user.name as string,
      isSystemAdmin,
      tenant: {
        id: tenant.id,
        isAdmin: this.m.isTenantAdmin,
      },
      isLoginDisabled: Boolean(isLoginDisabled),
    };

    if (this.m.password) {
      if (this.m.password !== this.m.confirmPassword) {
        await this.c.announcement.error('Passwords do not match');
        return false;
      }

      body.password = this.m.password;
    }

    await lastValueFrom(this.systemUserService.systemUserControllerUpdate({ body }));
    return true;
  }

  private async delete(): Promise<void> {
    this.blockUiService.blockUi();

    try {
      await lastValueFrom(this.systemUserService.systemUserControllerDelete({ id: this.m.user.id }));
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  private async resetUserPassword(): Promise<void> {
    this.blockUiService.blockUi();
    try {
      await lastValueFrom(this.tenantUserService.userControllerResetPassword({ id: this.m.user.id }));
    } catch (e) {
      await this.c.announcement.error('Something went wrong during password reset');
    } finally {
      await this.c.announcement.success('Password is reseted');
      await this.c.router.navigateByUrl('/system/user-list');
      this.blockUiService.unblockUi();
    }
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.user.subscribe(user => {
        user && (this.m.loggedUser = user as SelfUserResponseDto);
      }),
    ]);
  }
}
