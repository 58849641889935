import { Component, Input, OnInit } from '@angular/core';
import { ParsedExportInnerDto, ParsedExportResponseDto } from '@api/models';

@Component({
  selector: 'app-parsed-import',
  templateUrl: './parsed-import.component.html',
  styleUrls: ['./parsed-import.component.scss'],
})
export class ParsedImportComponent implements OnInit {
  @Input() parsedFile: ParsedExportResponseDto;

  ngOnInit(): void {
    if (this.parsedFile) {
      // to have new created items on top
      const sortItems = (items?: ParsedExportInnerDto[]): void => {
        if (items === undefined) return;
        items.sort((a: ParsedExportInnerDto, b: ParsedExportInnerDto) => {
          if (a.existingId === null && b.existingId === null) return 0;
          if (a.existingId === null && b.existingId !== null) return -1;
          return 1;
        });
      };
      for (const itemType of Object.keys(this.parsedFile.items)) {
        sortItems(this.parsedFile.items[itemType as keyof ParsedExportResponseDto['items']]);
      }
    }
  }

  willCreateItem(arr: any[]): boolean {
    return !!arr.find(item => item.existingId === null);
  }
}
