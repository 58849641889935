import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayAtEnumModule } from '@shared/components/common-display-at/index';
import { EnumValueModule } from '@shared/components/enum-value/enum-value.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { AttributeValueComponent } from './attribute-value.component';

@NgModule({
  declarations: [AttributeValueComponent],
  imports: [CommonModule, EnumValueModule, ReplaceRuntimeVariablesModule, CommonPipesModule, DisplayAtEnumModule],
  exports: [AttributeValueComponent],
})
export class AttributeValueModule {}
