import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem, MenuWidgetModel } from '../types/menu-widget.types';
import { IsItemInFirstLevelPipe } from './is-item-in-first-level.pipe';
import { IsMenuHorizontalPipe } from './is-menu-horizontal.pipe';

@Pipe({ name: 'getSubmenuTopMargin' })
export class GetSubMenuTopMarginPipe implements PipeTransform {
  constructor(public isItemInFirstLevelPipe: IsItemInFirstLevelPipe, public isMenuHorizontalPipe: IsMenuHorizontalPipe) {}

  transform(m: MenuWidgetModel, subMenuParentItem: MenuItem): string {
    const isInFirstMenuLevel = this.isItemInFirstLevelPipe.transform(m.items.menu, subMenuParentItem);
    const isMenuHorizontal = this.isMenuHorizontalPipe.transform(m.settings.menu.type);

    if (isInFirstMenuLevel && isMenuHorizontal) return m.styles.menu.paddingBottom;
    if (!isMenuHorizontal) return '-2px';
    else return '';
  }
}
