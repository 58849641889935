import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ApplicationSwitcherModule } from '@shared/components/application-switcher/application-switcher.component';
import { SidebarMenuItemModule } from '@shared/components/sidebar-menu/components/sidebar-menu-item.component';
import { TenantSwitcherModule } from '@shared/components/tenant-switcher/tenant-switcher.component';
import { SidebarMenuCompatible } from '@shared/types/sidebar-menu.types';
import { SidebarMenu, SidebarMenuItem } from './types/sidebar-menu.types';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent<T extends SidebarMenuCompatible> implements OnInit {
  @Input() app: T;
  @Input() hasApplicationSwitcher: boolean;
  @Input() items: SidebarMenuItem[];

  model: SidebarMenu[];

  ngOnInit(): void {
    this.model = [new SidebarMenu('Back-Office', 'pipe pi-home', this.items)];
  }

  onMenuClick(): void {
    this.app.onMenuClick();
  }
}

@NgModule({
  declarations: [SidebarMenuComponent],
  imports: [SidebarMenuItemModule, TenantSwitcherModule, ApplicationSwitcherModule, CommonModule],
  exports: [SidebarMenuComponent],
})
export class SidebarMenuModule {}
