import { ChangeDetectorRef, Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { ArtifactLinkResponseDto, ArtifactResponseDto } from '@api/models';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { FileService } from '@private/services/file.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { CREATED_BY_LABEL, CREATED_ON_LABEL, UPDATED_BY_LABEL, UPDATED_ON_LABEL } from '@shared/constants/constants';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { NewAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestriction } from '@shared/types/link.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { AbstractCardWidgetModeStrategy } from '@widgets/card-widget/services/abstract-card-widget-mode-strategy';
import { CardWidgetOptionsService } from '@widgets/card-widget/services/card-widget-options.service';
import { DynamicArtifactService } from '@widgets/card-widget/services/dynamic-artifact.service';
import { ListItemService } from '@widgets/card-widget/services/list-item.service';
import { StaticArtifactService } from '@widgets/card-widget/services/static-artifact.service';
import { UnknownModeStrategy } from '@widgets/card-widget/services/unknown-mode-strategy';
import { AggregatedLinks } from '@widgets/card-widget/types/aggregated-links';
import { ArtifactIdExtractor } from '@widgets/card-widget/types/artifact-id-extractor';
import { ArtifactTypeExtractor } from '@widgets/card-widget/types/artifact-type-extractor';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetModel, MyArtifactAttribute } from '@widgets/card-widget/types/card-widget-model';
import { ClientAttributeExtractor } from '@widgets/card-widget/types/client-attribute-extractor';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { LinksExtractor } from '@widgets/card-widget/types/links-extractor';
import { RecordAuthorExtractor } from '@widgets/card-widget/types/record-author-extractor';
import { RecordDateExtractor } from '@widgets/card-widget/types/record-date-extractor';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ID_PREFIX } from '@widgets/widgets-core/constants/widgets-core.constants';
import { WidgetOption, WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { lastValueFrom, tap } from 'rxjs';

@Injectable()
export class CardWidgetService extends CardWidgetOptionsService {
  constructor(
    @Inject(ID_PREFIX) private readonly idPrefix: string,
    customAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    filtersService: ArtifactFiltersService,
    protected readonly cache: NewCacheService,
    private readonly tenantLinkService: TenantLinkService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly fileService: FileService,
    private readonly widgetService: WidgetService,
    private readonly listItemService: ListItemService,
    private readonly staticArtifactService: StaticArtifactService,
    private readonly dynamicArtifactService: DynamicArtifactService,
    private readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(cache, customAttributeHelper, filtersService);
  }

  async init(context: CardWidgetComponent): Promise<void> {
    this.c = context;

    if (!this.c.isListMatrixCard) {
      await this.initAllPossibleOptions();

      this.initModel(context);

      await this.initConditionalOptions();

      const dynamicArtifactCardInPreviewMode = this.c.isPreviewMode && this.m.settings.widgetMode === CardWidgetMode.dynamicArtifact;

      if (this.m.settings.isArtifactTypeSelected && !dynamicArtifactCardInPreviewMode) {
        await this.loadArtifactOptions();

        if (!this.options.artifactOptions.loaded || !this.options.artifactOptions.list.length) {
          const artifactType = this.options.artifactTypes.listMap[this.m.settings.artifactTypeId];
          console.error(
            `Artifact type "${artifactType.name}" doesn't have any artifact instance. It's nothing to show as a preview inside the card. Create the artifact!`,
          );
        } else {
          await this.loadLinkedArtifacts();
          await this.loadFiles();
        }
      }

      this.listItemService.init(context);
      this.staticArtifactService.init(context);
      await this.dynamicArtifactService.init(context);
    } else {
      context.m = this.c.widget.value.model;
      this.m = context.m;
      this.options = context.m.options;

      await this.initConditionalOptions();

      const { incoming, outgoing } = await this.getAggregatedLinks();
      const linkedArtifacts = await this.loadLinkedArtifactsFromAggregatedLinks({ outgoing, incoming });

      this.options.incomingLinks.setList(incoming, 'id');
      this.options.outgoingLinks.setList(outgoing, 'id');
      this.options.linkedArtifacts.setList(linkedArtifacts, 'id');
      this.filterFilesFromPageRelatedData();

      await this.initListItemArtifact();

      this.cdr.detectChanges();
    }

    this.m.isReady = true;
    this.c.isFirstCall = false;

    this.cdr.markForCheck();
  }

  async onArtifactTypeChange(): Promise<void> {
    this.resetSelectedAttributes();
    await this.generateAttributeOptions();

    this.getModeStrategyService().onArtifactTypeChange();
  }

  // TODO: fix bug: enum values become empty on changing of selected artifact
  async onSelectedArtifactChange(): Promise<void> {
    this.m.isReady = false;
    await this.loadLinkedArtifacts();
    await this.loadFiles();
    this.m.isReady = true;
  }

  async onWidgetModeChange(): Promise<void> {
    this.m.recalculateResponsiveness.next();
    this.getModeStrategyService().onModeChange();
  }

  downloadFile(file: ArtifactResponseDto): void {
    this.fileService.downloadFileArtifact(file);
  }

  getWidgetOptions(): WidgetOption<any>[] {
    return this.widgetService.getWidgetOptions([
      WidgetType.card,
      WidgetType.artifact,
      WidgetType.list,
      WidgetType.listNew,
      WidgetType.listMatrix,
      WidgetType.sidebar,
      WidgetType.sidebarModal,
    ]);
  }

  async loadArtifactOptions(): Promise<void> {
    if (!this.m.settings.isArtifactTypeSelected) {
      return;
    }

    const filter = JSON.stringify({
      artifactTypeId: { $oid: this.m.settings.artifactTypeId },
      $and: [{ deleted: { $eq: null } }],
    });
    const limit = this.m.settings.widgetMode === CardWidgetMode.listItem ? 1 : undefined;
    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter, limit } }));
    this.options.artifactOptions.setList(data, 'id');
  }

  async loadLinkedArtifacts(): Promise<void> {
    const { outgoing, incoming } = await this.getArtifactExampleLinks();

    const artifacts = await this.loadLinkedArtifactsFromAggregatedLinks({ outgoing, incoming });

    this.options.outgoingLinks.setList(outgoing, 'id');
    this.options.incomingLinks.setList(incoming, 'id');
    this.options.linkedArtifacts.setList(artifacts, 'id');
  }

  onResponsivenessChange(): void {
    this.m.recalculateResponsiveness.next();
  }

  private async loadLinkedArtifactsFromAggregatedLinks(aggregatedLinks: AggregatedLinks): Promise<ArtifactLinkResponseDto[]> {
    const artifactIds = [
      ...new Set([
        ...aggregatedLinks.outgoing.map(({ destinationArtifactId }: LinkResponseDto) => destinationArtifactId),
        ...aggregatedLinks.incoming.map(({ sourceArtifactId }: LinkResponseDto) => sourceArtifactId),
      ]),
    ];

    return await this.cache.data.artifacts.getManyAsync(artifactIds);
  }

  private initModel(context: CardWidgetComponent): void {
    if (context.isLayoutMode) {
      const settings = { ...context.widget.value?.model?.settings };
      const dto = { ...context.widget.value?.model, settings };
      const isEmptyWidget = !context.widget.value || !Object.keys(context.widget.value).length;
      const model: CardWidgetModel = isEmptyWidget ? CardWidgetModel.initial() : CardWidgetModel.fromDtoAndOptions(dto, this.options);

      context.widget.value = { model };
      model.idPrefix = this.idPrefix;
      model.options = this.options;
    }

    context.m = context.widget.value.model;

    this.m = context.m;
  }

  private async initListItemArtifact(): Promise<void> {
    this.c.listItemArtifact = {
      ...this.getArtifactId(),
      ...this.getArtifactType(),
      ...this.getArtifactRecordAuthors(),
      ...this.getArtifactRecordDates(),
      ...this.getArtifactLinks(),
      ...this.getCustomAttributes(),
    };

    if (!this.c.isListMatrixCard) {
      await this.loadFiles(this.c.artifact);
    }
  }

  private getArtifactId(): { [ArtifactIdExtractor.artifactIdAttributeId]: MyArtifactAttribute } {
    return {
      [ArtifactIdExtractor.artifactIdAttributeId]: {
        label: 'Artifact ID',
        value: this.c.artifact.id,
      },
    };
  }

  private getArtifactType(): { [ArtifactTypeExtractor.artifactTypeAttributeId]: MyArtifactAttribute } {
    return {
      [ArtifactTypeExtractor.artifactTypeAttributeId]: {
        label: 'Artifact Type',
        value: new ArtifactTypeExtractor(this.options).getValue(this.c.artifact),
      },
    };
  }

  private getArtifactRecordAuthors(): {
    [RecordAuthorExtractor.createdByAttributeId]: MyArtifactAttribute;
    [RecordAuthorExtractor.updatedByAttributeId]: MyArtifactAttribute;
  } {
    const recordAuthorExtractor = new RecordAuthorExtractor(this.m.options);

    return {
      [RecordAuthorExtractor.createdByAttributeId]: {
        label: CREATED_BY_LABEL,
        value: recordAuthorExtractor.getValue(this.c.artifact, { attributeId: RecordAuthorExtractor.createdByAttributeId }),
      },
      [RecordAuthorExtractor.updatedByAttributeId]: {
        label: UPDATED_BY_LABEL,
        value: recordAuthorExtractor.getValue(this.c.artifact, { attributeId: RecordAuthorExtractor.updatedByAttributeId }),
      },
    };
  }

  private getArtifactRecordDates(): {
    [RecordDateExtractor.createdOnAttributeId]: MyArtifactAttribute;
    [RecordDateExtractor.updatedOnAttributeId]: MyArtifactAttribute;
  } {
    const extractor = new RecordDateExtractor(this.injector.get(LOCALE_ID));

    return {
      [RecordDateExtractor.createdOnAttributeId]: {
        label: CREATED_ON_LABEL,
        value: extractor.getValue(this.c.artifact, { attributeId: RecordDateExtractor.createdOnAttributeId }),
      },
      [RecordDateExtractor.updatedOnAttributeId]: {
        label: UPDATED_ON_LABEL,
        value: extractor.getValue(this.c.artifact, { attributeId: RecordDateExtractor.updatedOnAttributeId }),
      },
    };
  }

  private getArtifactLinks(): { [linkTypeId: string]: MyArtifactAttribute } {
    const result: { [linkTypeId: string]: MyArtifactAttribute } = {};

    const incomingLinkTypes = this.options.linkTypes.list.filter((linkType: LinkType) => {
      return linkType.restrictions.filter((restriction: LinkRestriction) => {
        return restriction.destinationArtifactTypeId === this.c.artifact.artifactTypeId;
      }).length;
    });

    const outgoingLinkTypes = this.options.linkTypes.list.filter((linkType: LinkType) => {
      return linkType.restrictions.filter((restriction: LinkRestriction) => {
        return restriction.sourceArtifactTypeId === this.c.artifact.artifactTypeId;
      }).length;
    });

    incomingLinkTypes.forEach((linkType: LinkType) => {
      result[linkType.id + LinkDirection.incoming] = {
        label: linkType.incomingName,
        linkType,
        value: new LinksExtractor(this.options).getValue(this.c.artifact, {
          linkDirection: LinkDirection.incoming,
          attributeId: linkType.id,
        }),
      };
    });

    outgoingLinkTypes.forEach((linkType: LinkType) => {
      result[linkType.id + LinkDirection.outgoing] = {
        label: linkType.outgoingName,
        linkType,
        value: new LinksExtractor(this.options).getValue(this.c.artifact, {
          linkDirection: LinkDirection.outgoing,
          attributeId: linkType.id,
        }),
      };
    });

    return result;
  }

  private getCustomAttributes(): { [attributeId: string]: MyArtifactAttribute } {
    return Object.keys(this.c.artifact.attributes).reduce((acc: any, attributeId: string) => {
      const value = new ClientAttributeExtractor(this.options, this.injector.get(ElvisUtil), this.injector.get(LOCALE_ID)).getValue(this.c.artifact, {
        attributeId,
      });

      return {
        ...acc,
        [attributeId]: {
          label: this.options.attributes.listMap[attributeId].name,
          value,
          icon: this.options.attributes.listMap[attributeId].icon,
        },
      };
    }, {});
  }

  private getModeStrategyService(): AbstractCardWidgetModeStrategy {
    switch (this.m.settings.widgetMode) {
      case CardWidgetMode.listItem:
        return this.listItemService;
      case CardWidgetMode.staticArtifact:
        return this.staticArtifactService;
      case CardWidgetMode.dynamicArtifact:
        return this.dynamicArtifactService;
      default:
        return new UnknownModeStrategy(this.tenantArtifactService, this.tenantLinkService);
    }
  }

  private resetSelectedAttributes(): void {
    this.m.areas.forEach(({ content }: GenericArea<CardWidgetAreaContent>) => {
      content.items = content.items.filter((item: CardWidgetAreaContentItem) => item.type === ContentType.widget);
    });
  }

  private async loadFiles(artifact: ArtifactResponseDto = this.m.artifact): Promise<void> {
    const $in = this.getFileIds(artifact).map(($oid: string) => ({ $oid }));
    const filter = JSON.stringify({ _id: { $in } });

    if (!$in.length) {
      this.options.files.setList([], 'id');
      return;
    }

    try {
      await lastValueFrom(
        this.tenantArtifactService.artifactControllerList({ body: { filter } }).pipe(
          tap(({ data }) => {
            this.options.files.setList(data, 'id');
          }),
        ),
      );
    } catch (e) {
      console.log('Something went wrong during loading files', e);
      this.options.files.setList([], 'id');
    }
  }

  private getFileIds(artifact: ArtifactResponseDto = this.m.artifact): string[] {
    if (!artifact) {
      return [];
    }

    const attributeIds = Object.keys(this.options.artifactTypes.listMap[artifact.artifactTypeId].attributes);
    const attributes = this.options.attributes.filterByKey('id', attributeIds);
    const fileAttributes = attributes.filter(({ dataTypeId }: NewAttribute) => {
      return this.options.dataTypes.listMap[dataTypeId].baseDataType === BaseDataType.file;
    });

    const fileIds = new Set<string>();

    fileAttributes.forEach((attribute: NewAttribute) => {
      const fileAttribute = artifact.attributes[attribute.id];

      if (!fileAttribute) {
        return;
      }

      if (Array.isArray(fileAttribute.value)) {
        fileAttribute.value.forEach((id: string) => fileIds.add(id));
      } else {
        fileIds.add(fileAttribute.value as string);
      }
    });

    return [...fileIds].filter(Boolean);
  }

  private async getArtifactExampleLinks(): Promise<AggregatedLinks> {
    if (
      !this.options.artifactOptions.loaded ||
      !this.options.artifactOptions.list.length ||
      (this.m.settings.widgetMode === CardWidgetMode.staticArtifact && !this.m.settings.selectedArtifactId)
    ) {
      return { outgoing: [], incoming: [] };
    }
    return this.getAggregatedLinks();
  }

  private async getAggregatedLinks(): Promise<AggregatedLinks> {
    const $oid = this.c.isListMatrixCard ? this.c.artifact.id : this.m.artifact.id;
    const linkTypeIds = this.m.innerLinkTypeIds;

    if (!linkTypeIds.length) {
      return { outgoing: [], incoming: [] };
    }

    const filter = JSON.stringify({
      $and: [
        { $or: [{ destinationArtifactId: { $in: [{ $oid }] } }, { sourceArtifactId: { $in: [{ $oid }] } }] },
        {
          deleted: { $eq: null },
          linkTypeId: { $in: [...linkTypeIds.map(id => ({ $oid: id }))] },
        },
      ],
    });
    const { data } = await lastValueFrom(this.tenantLinkService.linkControllerList({ body: { filter } }));

    return data.reduce(
      ({ outgoing, incoming }: { incoming: LinkResponseDto[]; outgoing: LinkResponseDto[] }, link: LinkResponseDto) => {
        return {
          outgoing: link.sourceArtifactId === $oid ? [...outgoing, link] : outgoing,
          incoming: link.destinationArtifactId === $oid ? [...incoming, link] : incoming,
        };
      },
      { outgoing: [], incoming: [] },
    );
  }

  private aggregateLinksFromPageRelatedData(): void {
    const artifactId = this.c.artifact.id;
    const { outgoing, incoming } = this.c.artifactAdditionalData.links.reduce(
      (acc: { [key: string]: LinkResponseDto[] }, link: LinkResponseDto) => {
        if (link.destinationArtifactId === artifactId) {
          acc.incoming.push(link);
        }

        if (link.sourceArtifactId === artifactId) {
          acc.outgoing.push(link);
        }

        return acc;
      },
      { outgoing: [], incoming: [] },
    );
    this.options.outgoingLinks.setList(outgoing, 'id');
    this.options.incomingLinks.setList(incoming, 'id');
  }

  private filterLinkedArtifactsFromPageRelatedData(): void {
    const linkedArtifactIds = [
      ...this.options.incomingLinks.list.map((link: LinkResponseDto) => link.sourceArtifactId),
      ...this.options.outgoingLinks.list.map((link: LinkResponseDto) => link.destinationArtifactId),
    ];
    const linkedArtifacts: ArtifactResponseDto[] = this.c.artifactAdditionalData.linkedArtifacts.filter(({ id }: ArtifactResponseDto) =>
      linkedArtifactIds.includes(id),
    );
    this.options.linkedArtifacts.setList(linkedArtifacts, 'id');
  }

  private filterFilesFromPageRelatedData(): void {
    this.options.files.setList(this.c.artifactAdditionalData.files, 'id');
  }
}
