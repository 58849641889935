import { Injectable } from '@angular/core';
import {
  ArtifactAttributeRequestDto,
  ArtifactFormatFileDataResponseDto,
  ArtifactResponseDto,
  ArtifactTypeAttributeResponseDto,
  SelfUserResponseDto,
} from '@api/models';
import { TenantArtifactService, TenantArtifactTypeService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { saveAs } from 'file-saver';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';

@Injectable({ providedIn: 'root' })
export class FileService {
  fileName: string;

  constructor(
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly cache: NewCacheService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {}

  uploadFileArtifact(file: File, folderId: string | null = null): Observable<ArtifactResponseDto> {
    return this.cache.user.pipe(
      switchMap(currentUser => this.cache.data.artifactTypes.get$((currentUser as SelfUserResponseDto)!.tenant!.fileArtifactTypeId!)),
      switchMap(fileArtifactType => {
        const attributes = Object.entries(new NewArtifactType(fileArtifactType).attributes).reduce((out, entry) => {
          const [attributeId, attribute] = entry as [string, ArtifactTypeAttributeResponseDto];
          Object.assign(out, { [attributeId]: { value: attribute.initialValue || '' } });
          return out;
        }, {} as Record<string, ArtifactAttributeRequestDto>);

        this.fileName = file.name;

        return this.tenantArtifactService
          .artifactControllerUploadWithLink({
            body: { artifactTypeId: fileArtifactType.id, attributes, file, links: `${null}` as unknown as null, parentFolderId: `${folderId}` },
            notify: false,
            cb: this.progressHandler.bind(this),
          })
          .pipe(tap(dto => this.cache.data.artifacts.setItem(dto)));
      }),
    );
  }

  progressHandler(percent: number): void {
    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.uploadProgress, { name: this.fileName, percent });
  }

  downloadFileArtifact(fileArtifact: ArtifactResponseDto): void {
    this.tenantArtifactService.artifactControllerDownload({ id: fileArtifact.id }).subscribe({
      next: blob => saveAs(blob, (fileArtifact.formatData as ArtifactFormatFileDataResponseDto).filename),
      error: error => console.log(error),
    });
  }
}
