import { ApplicationResponseDto, PageResponseDto } from '@api/models';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class PagesModel {
  exportModalOpened = false;
  importModalOpened = false;
  selectedPages: PageResponseDto[] = [];
  selectPages = false;

  constructor(
    public pages: Page[] = [],
    public columns: TableColumn[] = [],
    public applicationOptions: SelectOption<string, string>[] = [],
    public applications = new ListContainer<ApplicationResponseDto>(),
  ) {}
}
