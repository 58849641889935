<app-display-at-user
  *ngIf="formatSettings.value.displayMetadata?.selectedVariantCode !== 'ADD_TO_LIST'"
  class="block-container full-size-container"
  [defaultIconSize]="userIconSize"
  [ownerId]="ownerId"
  [attributeId]="item?.attribute.value.id"
  [artifact]="selected.artifact"
  [usersMap]="model.options.users.listMap"
  [selectedVariant]="formatSettings.value.displayMetadata?.selectedVariantCode || 'DEFAULT'"
  [valueStyles]="formatSettings.value?.styles"
>
</app-display-at-user>
<span *ngIf="attribute.multipleValues && formatSettings.value.displayMetadata?.selectedVariantCode === 'ADD_TO_LIST'">
  <p-inputSwitch [ngModel]="currentUserSelected$ | async" [readonly]="true" [disabled]="true"></p-inputSwitch>
</span>
