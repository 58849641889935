import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getHyperlinks',
})
export class GetHyperlinksPipe implements PipeTransform {
  transform(value: string): string[] {
    return value ? value.split(', ') : [];
  }
}
