import { Injectable } from '@angular/core';
import { ArtifactLinkResponseDto, ArtifactResponseDto, LinkListResponseDto, LinkResponseDto } from '@api/models';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { APPLICATION_ID_KEY } from '@shared/constants/constants';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { NewTableColumn } from '@shared/types/table.types';
import { cloneDeep } from 'lodash';
import { lastValueFrom, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ArtifactListWidgetTableComponent } from '../artifact-list-widget-table.component';
import { ArtifactLinks } from '../types/artifact-list-widget-table.types';

@Injectable()
export class ArtifactListWidgetTableLoadHelper {
  context: ArtifactListWidgetTableComponent;

  init(context: ArtifactListWidgetTableComponent): void {
    this.context = context;
  }

  addFileToModel(fileArtifact: ArtifactResponseDto): void {
    this.context.m.files[fileArtifact.id] = fileArtifact;
  }

  loadFilesToModel(clientAttributes: NewClientAttribute[], dataTypesMap: Record<string, NewDataType>, attributesMap: Record<string, NewAttribute>): void {
    this.context.m.files = {};

    const relevantClientAttributes = clientAttributes.filter(clientAttribute => {
      const attribute = attributesMap[clientAttribute.id];
      const dataType = dataTypesMap[attribute.dataTypeId];
      return dataType.baseDataType === BaseDataType.file;
    });
    const fileIds = new Set<string>();

    relevantClientAttributes.forEach(clientAttribute => {
      if (Array.isArray(clientAttribute.value)) clientAttribute.value.forEach(id => fileIds.add(id));
      else clientAttribute.value && clientAttribute.value.length && fileIds.add(clientAttribute.value);
    });

    if (fileIds.size) {
      this.context.cache.data.artifacts
        .getMany$([...fileIds])
        .pipe(
          tap(data => {
            const files: Record<string, ArtifactResponseDto> = {};
            data.forEach(fileArtifactRes => (files[fileArtifactRes.id] = fileArtifactRes));
            this.context.m.files = files;
            this.context.m.filesLoaded = true;
          }),
        )
        .subscribe({
          error(err) {
            console.log(err);
          },
        });
    } else {
      this.context.m.filesLoaded = true;
    }
  }

  loadDataLinksWithLinkedArtifactsToModel$(
    data: NewArtifact[],
    linkTypeColumns: NewTableColumn[],
    overwriteExistingLinks: boolean,
  ): Observable<ArtifactLinkResponseDto[]> {
    const dataMongoIds = data.map(item => ({ $oid: item.id }));
    const linkTypeMongoIds = linkTypeColumns.map(column => ({ $oid: column.meta.linkRestrictionParams?.linkTypeId as string }));
    const filter = this.makeLinksFilter(dataMongoIds, linkTypeMongoIds);

    return this.context.tenantLinkService.linkControllerList({ body: { filter } }).pipe(
      tap(value => this.mapLinksToModel(value.data, overwriteExistingLinks)),
      switchMap(value => this.loadDataByLinks$(value.data)),
      tap(value => this.mapLinkedArtifactsToModel(value, overwriteExistingLinks)),
    );
  }

  loadDataLinksWithLinkedArtifactsToModel(data: NewArtifact[], overwriteExistingLinks: boolean): void {
    const dataMongoIds = data.map(item => ({ $oid: item.id }));
    const linkTypeMongoIds = this.context.options.linkTypes
      .filterByKey(APPLICATION_ID_KEY, this.context.ids.applicationId)
      .map(linkType => ({ $oid: linkType.id }));
    const filter = this.makeLinksFilter(dataMongoIds, linkTypeMongoIds);
    lastValueFrom(this.context.tenantLinkService.linkControllerList({ body: { filter } }))
      .then((value: LinkListResponseDto) => {
        this.mapLinksToModel(value.data, overwriteExistingLinks);
        return this.loadDataByLinks(value.data);
      })
      .then((value: ArtifactLinkResponseDto[]) => this.mapLinkedArtifactsToModel(value, overwriteExistingLinks));
  }

  updateLinksWithLinkedArtifacts(linkTypeId: string, artifactIds: string[]): void {
    const filter = this.makeLinksFilter(
      artifactIds.map(id => ({ $oid: id })),
      [{ $oid: linkTypeId }],
    );
    lastValueFrom(this.context.tenantLinkService.linkControllerList({ body: { filter } }))
      .then((value: LinkListResponseDto) => {
        const links = this.getUpdatedLinks(value.data);

        for (const linkTypeKey in links) {
          for (const artifactKey in links[linkTypeKey]) {
            this.context.m.links[linkTypeKey][artifactKey][LinkDirection.outgoing] = [...links[linkTypeKey][artifactKey][LinkDirection.outgoing]];
            this.context.m.links[linkTypeKey][artifactKey][LinkDirection.incoming] = [...links[linkTypeKey][artifactKey][LinkDirection.incoming]];
          }
        }

        return this.loadDataByLinks(value.data);
      })
      .then((value: ArtifactLinkResponseDto[]) => {
        this.context.m.linkedData = { ...this.context.m.linkedData, ...this.getLinkedData(value) };
      });
  }

  private makeLinksFilter(dataMongoIds: { $oid: string }[], linkTypeMongoIds: { $oid: string }[]): string {
    const relevantDataMongoIds = linkTypeMongoIds.length ? dataMongoIds : [];

    return JSON.stringify({
      $and: [
        { $or: [{ destinationArtifactId: { $in: relevantDataMongoIds } }, { sourceArtifactId: { $in: relevantDataMongoIds } }] },
        { deleted: { $eq: null } },
        { linkTypeId: { $in: linkTypeMongoIds } },
      ],
    });
  }

  private getUpdatedLinks(links: LinkResponseDto[]): Record<string, Record<string, ArtifactLinks>> {
    const newLinks: Record<string, Record<string, ArtifactLinks>> = {};
    links.forEach(link => {
      this.checkExistingLinksByLinkType(newLinks, link);
      this.checkExistingLinksByArtifact(newLinks[link.linkTypeId], link);
      this.setLinkToModel(newLinks[link.linkTypeId], link);
    });
    return newLinks;
  }

  private mapLinksToModel(links: LinkResponseDto[], overwriteExistingLinks: boolean): void {
    this.setLinkDtosToModel(links, overwriteExistingLinks);
    this.setLinkMapToModel(links, overwriteExistingLinks);
  }

  private setLinkDtosToModel(links: LinkResponseDto[], overwriteExistingLinks: boolean): void {
    if (overwriteExistingLinks) {
      this.context.m.linksDto = links;
      return;
    }

    const aggregatedLinks = cloneDeep(this.context.m.linksDto);
    links.reduce((links, link: LinkResponseDto) => {
      if (!aggregatedLinks.find(existingLink => existingLink.id === link.id)) {
        links.push(link);
      }
      return links;
    }, aggregatedLinks);

    this.context.m.linksDto = aggregatedLinks;
  }

  private setLinkMapToModel(links: LinkResponseDto[], overwriteExistingLinks: boolean): void {
    if (overwriteExistingLinks) {
      this.context.m.links = {};
    }

    const newLinks: Record<string, Record<string, ArtifactLinks>> = overwriteExistingLinks ? {} : cloneDeep(this.context.m.links);

    links.forEach(link => {
      this.checkExistingLinksByLinkType(newLinks, link);
      this.checkExistingLinksByArtifact(newLinks[link.linkTypeId], link);
      this.setLinkToModel(newLinks[link.linkTypeId], link);
    });

    this.context.m.links = newLinks;
  }

  private loadDataByLinks$(links: LinkResponseDto[]): Observable<ArtifactLinkResponseDto[]> {
    const artifactIds = new Set<string>();

    links.forEach(({ destinationArtifactId, sourceArtifactId }) => {
      artifactIds.add(destinationArtifactId);
      artifactIds.add(sourceArtifactId);
    });

    return !artifactIds.size ? of([]) : this.context.cache.data.artifacts.getMany$([...artifactIds]);
  }

  private loadDataByLinks(links: LinkResponseDto[]): Promise<ArtifactLinkResponseDto[]> {
    return lastValueFrom(this.loadDataByLinks$(links));
  }

  private mapLinkedArtifactsToModel(dtos: ArtifactResponseDto[], overwriteExistingData: boolean): void {
    this.context.m.linkedData = overwriteExistingData ? this.getLinkedData(dtos) : this.aggregateLinkedData(dtos);
  }

  private getLinkedData(dtos: ArtifactResponseDto[]): Record<string, NewArtifact> {
    return dtos.reduce((linkedData: Record<string, NewArtifact>, dto: ArtifactResponseDto) => {
      linkedData[dto.id] = new NewArtifact({ dto, artifactTypesMap: this.context.options.artifactTypes.listMap });
      return linkedData;
    }, {});
  }

  private aggregateLinkedData(dtos: ArtifactResponseDto[]): Record<string, NewArtifact> {
    const aggregatedDtos = cloneDeep(this.context.m.linkedData);

    return dtos.reduce((linkedData: Record<string, NewArtifact>, dto: ArtifactResponseDto) => {
      linkedData[dto.id] = new NewArtifact({ dto, artifactTypesMap: this.context.options.artifactTypes.listMap });
      return linkedData;
    }, aggregatedDtos);
  }

  private checkExistingLinksByLinkType(links: Record<string, Record<string, ArtifactLinks>>, link: LinkResponseDto): void {
    if (!links[link.linkTypeId]) links[link.linkTypeId] = {};
  }

  private checkExistingLinksByArtifact(linksByLinkType: Record<string, ArtifactLinks>, link: LinkResponseDto): void {
    if (!linksByLinkType[link.sourceArtifactId]) linksByLinkType[link.sourceArtifactId] = { [LinkDirection.incoming]: [], [LinkDirection.outgoing]: [] };
    if (!linksByLinkType[link.destinationArtifactId])
      linksByLinkType[link.destinationArtifactId] = { [LinkDirection.incoming]: [], [LinkDirection.outgoing]: [] };
  }

  private setLinkToModel(links: Record<string, ArtifactLinks>, newLink: LinkResponseDto): void {
    const outgoingLinks = links[newLink.sourceArtifactId][LinkDirection.outgoing];
    const incomingLinks = links[newLink.destinationArtifactId][LinkDirection.incoming];

    if (!outgoingLinks.find(existingLink => existingLink.id === newLink.id)) {
      outgoingLinks.push(newLink);
    }

    if (!incomingLinks.find(existingLink => existingLink.id === newLink.id)) {
      incomingLinks.push(newLink);
    }
  }
}
