import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { ListWidgetTableLoadModeEnum } from '../types/list-widget-settings.types';

@Pipe({ name: 'isAddButtonDisabled' })
export class IsAddButtonDisabledPipe implements PipeTransform {
  transform(loadMode: ListWidgetTableLoadModeEnum, selectedModuleArtifact: NewArtifact): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byModule && !selectedModuleArtifact;
  }
}
