<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
<p-multiSelect
  *ngIf="attribute.multipleValues && formatSettings.value.displayMetadata?.selectedVariantCode !== 'ADD_TO_LIST'"
  (onPanelHide)="onBlur(); fillUserOptions()"
  [(ngModel)]="attr.value"
  (onChange)="onChange($event.value)"
  [filter]="true"
  [inputId]="'userField' + index"
  [maxSelectedLabels]="5"
  [options]="userOptions"
  [placeholder]="placeholder"
  [selectionLimit]="attribute.multipleValues ? null : 1"
  appendTo="body"
  optionLabel="label"
>
</p-multiSelect>
<p-dropdown
  *ngIf="!attribute.multipleValues && !this.attr.value"
  [(ngModel)]="this.attr.value"
  (onChange)="onSingleUserChange()"
  [autoDisplayFirst]="false"
  [filter]="true"
  [inputId]="'userField' + index"
  [options]="userOptions"
  [placeholder]="placeholder"
  [showClear]="true"
  appendTo="body"
  optionLabel="label"
  (onHide)="onBlur(); fillUserOptions()"
  (onPanelHide)="onBlur(); fillUserOptions()"
>
</p-dropdown>
<p-dropdown
  *ngIf="!attribute.multipleValues && this.attr.value?.length"
  [(ngModel)]="this.attr.value[0]"
  [autoDisplayFirst]="false"
  [filter]="true"
  [inputId]="'userField' + index"
  [options]="userOptions"
  [placeholder]="placeholder"
  [showClear]="true"
  appendTo="body"
  optionLabel="label"
  (onHide)="onBlur(); fillUserOptions()"
  (onPanelHide)="onBlur(); fillUserOptions()"
  (onChange)="onChange($event.value)"
>
</p-dropdown>
<div *ngIf="attribute.multipleValues && formatSettings.value.displayMetadata?.selectedVariantCode === 'ADD_TO_LIST'">
  <p-inputSwitch [ngModel]="currentUserSelected$ | async" (onChange)="onSubscribeMeToggle($event.checked)"></p-inputSwitch>
</div>
<app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
  [formatSettings]="formatSettings"
  [label]="label"
  [fieldHtmlId]="'userField' + index"
  [canContainPlaceholder]="true"
  [labelBehaviour]="labelBehaviour"
  [placeholder]="placeholder">
</app-artifact-attribute-form-field-label-with-icon>
