import { NumberWidgetOptions } from '@widgets/number-widget/types/number-widget-options.types';
import { NumberWidgetSelected, NumberWidgetSelectedDto } from '@widgets/number-widget/types/number-widget-selected.types';
import { NumberWidgetSettings, NumberWidgetSettingsDto } from '@widgets/number-widget/types/number-widget-settings.types';
import { NumberWidgetStyles } from '@widgets/number-widget/types/number-widget-styles.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { Params } from '@angular/router';

export class NumberWidgetValue {
  constructor(public model: NumberWidgetModel = new NumberWidgetModel()) {}
}

export class NumberWidgetModel {
  options = new NumberWidgetOptions();
  selected = new NumberWidgetSelected();
  settings = new NumberWidgetSettings();
  styles = new NumberWidgetStyles();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  counter: number | string;
  badgeId: string;
  folderId: string | null;
  artifactId: string | null;
  countableAttributeId: string | null;
  prevFolderId: string | null;
  prevArtifactId: string | null;
  currentParams: Params;

  constructor(dto?: NumberWidgetModelDto, options?: ClickActionSettingsOptions) {
    dto && this.fromDto(dto, options!);
  }

  toServer(): NumberWidgetModelDto {
    return {
      selected: this.selected.toServer(),
      settings: this.settings.toServer(),
      styles: { ...this.styles },
    };
  }

  fromDto(dto: NumberWidgetModelDto, options: ClickActionSettingsOptions): void {
    dto.selected && (this.selected = new NumberWidgetSelected(dto.selected));
    dto.settings && (this.settings = new NumberWidgetSettings(dto.settings, options));
    dto.styles && (this.styles = new NumberWidgetStyles(dto.styles));
  }
}

export interface NumberWidgetModelDto {
  selected: NumberWidgetSelectedDto;
  settings: NumberWidgetSettingsDto;
  styles: NumberWidgetStyles;
}
