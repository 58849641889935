import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class QueryParamsUtil {
  /**
   * Function returns query params converted into lower case.
   * @returns query params in lower case
   */
  toLowerCase(queryParams: Params): Params {
    if (!queryParams) {
      return queryParams;
    }
    const lowerCaseParams: Params = {};
    for (const key in queryParams) {
      lowerCaseParams[key.toLowerCase()] = queryParams[key];
    }
    return lowerCaseParams;
  }
}
