import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeValueCreateRequestDto } from '@api/models/data-type-value-create-request-dto';
import { DataTypeKind, EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

@Pipe({
  name: 'getEnumeratedOptions',
})
export class GetEnumeratedOptionsPipe implements PipeTransform {
  transform(attributeId: string, options: CardWidgetOptions, attributeValue: string): EnumeratedOption[] | null {
    const attribute = options.attributes.listMap[attributeId];

    if (!attribute) {
      return null;
    }

    const dataType = options.dataTypes.listMap[attribute.dataTypeId];

    if (dataType.kind !== DataTypeKind.enumerated) {
      return null;
    }

    const values = attributeValue ? attributeValue.split(', ') : [];

    return values.map((attributeValueItem: string) => {
      const { label, value, icon, uri, textColor, backgroundColor } = dataType.values!.find(
        ({ label }: DataTypeValueCreateRequestDto) => label === attributeValueItem,
      )!;

      return new EnumeratedOption(label, value, icon, uri, backgroundColor, textColor);
    });
  }
}
