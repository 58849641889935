import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FieldStyles } from '../../../types/attribute-format-settings.types';

@Pipe({ name: 'fieldStyles' })
export class FieldStylesPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: FieldStyles): SafeStyle {
    const bgColor = `--background-color: ${value.backgroundColor ? value.backgroundColor : 'white'};`;
    const brdColor = `--border-color: ${value.borderColor};`;
    const brdStyle = `--border-style: ${value.borderStyle};`;
    const brdWidth = `--border-width: ${value.borderWidth};`;
    const brdRadius = `--border-radius: ${value.borderRadius};`;

    return this.sanitizer.bypassSecurityTrustStyle(`${bgColor} ${brdColor} ${brdStyle} ${brdWidth} ${brdRadius}`);
  }
}
