import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArtifactFormatFileDataResponseDto, ArtifactLinkResponseDto, SelfUserResponseDto } from '../../../api/models';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({ providedIn: 'root' })
export class UserProfilePictureService {
  constructor(
    private readonly apiConfiguration: ApiConfiguration,
    private readonly authorizationService: AuthorizationService,
    private readonly cache: NewCacheService,
  ) {}

  async getUserImageUrl(profileArtifactId?: string): Promise<string | undefined> {
    if (!profileArtifactId) return;

    const artifact: ArtifactResponseDto | undefined = await this.cache.data.artifacts.getAsync(profileArtifactId).catch(() => undefined);
    if (!artifact) return;

    return this.getUserImageUrlFromUserProfileDto(artifact);
  }

  async getUserImageUrlFromUserProfileDto(userProfileDto: ArtifactResponseDto): Promise<string | undefined> {
    const profilePictureId = await this.getUserImageIdFromUserProfileDto(userProfileDto);
    return profilePictureId && this.getUserImageUrlFromPictureId(profilePictureId);
  }

  async getUserImageIdFromUserProfileDto(userProfileDto: ArtifactResponseDto): Promise<string | undefined> {
    let counter = 0;

    return new Promise(resolve => {
      this.cache.user.subscribe(user => {
        if (user) {
          const { tenant } = user as SelfUserResponseDto;
          const profilePictureAttributeId = tenant?.systemAttributes?.profilePictureAttributeId;

          if (profilePictureAttributeId) {
            resolve(userProfileDto.attributes[profilePictureAttributeId]?.value as string);
            return;
          }
        } else if (counter > 2) {
          resolve(undefined);
          return;
        }

        counter += 1;
      });
    });
  }

  async getUserImageFileObject$(userProfileDto: ArtifactResponseDto): Promise<ArtifactLinkResponseDto | undefined> {
    const profilePictureId = (await this.getUserImageIdFromUserProfileDto(userProfileDto)) as string;
    return profilePictureId ? this.cache.data.artifacts.getAsync(profilePictureId).catch(() => undefined) : undefined;
  }

  getUserImageUrlFromPictureId(pictureId: string): string {
    return `${this.apiConfiguration.rootUrl}/api/tenant/artifact/${pictureId}/download?authorization=Bearer%20${this.authorizationService.getToken}`;
  }

  getUserImage$(userProfileDto: ArtifactResponseDto): Observable<string | undefined> {
    return from(this.getUserImageFileObject$(userProfileDto)).pipe(
      map(profileFileObject => {
        if (profileFileObject) {
          const pictureId = (profileFileObject?.formatData as ArtifactFormatFileDataResponseDto).thumbnails?.small || profileFileObject?.id;
          return this.getUserImageUrlFromPictureId(pictureId);
        }
        return undefined;
      }),
    );
  }
}
