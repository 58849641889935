import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { CoreRouterSubscribeComponent } from '@shared/core/components/core-router-subscribe.component';
import { AnnouncementService } from '@shared/services/announcement.service';

const BLOCK_PART_LAYOUT_MODE_SELECTOR = '.block-part-body';
const BLOCK_PART_PREVIEW_MODE_SELECTOR = '.block-part';

@Directive()
export abstract class WidgetsCoreComponent<Value = any> extends CoreRouterSubscribeComponent {
  abstract widget: BlockPartWidget<Value>;
  abstract label: string;
  abstract isLayoutMode: boolean;

  protected constructor(route: ActivatedRoute, router: Router, announcement: AnnouncementService, protected readonly elRef: ElementRef<HTMLElement>) {
    super(route, router, announcement);
  }

  protected get container(): HTMLElement | null {
    return this.elRef.nativeElement.closest(BLOCK_PART_LAYOUT_MODE_SELECTOR) || this.elRef.nativeElement.closest(BLOCK_PART_PREVIEW_MODE_SELECTOR);
  }
}
