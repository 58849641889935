<ng-container *ngIf="selectedItem">
  <app-attribute-format-settings
    *ngIf="model.isAttributeSelected && selectedItem"
    [options]="model.options"
    [attribute]="selectedItem.value"
    [dataType]="selectedItem.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes"
    [changeLabelStyleReference]="changeLabelStyleReference"
    [formatSettings]="formatSettings"
    [labelBehaviour]="model.settings.labelBehaviour"
    [onLabelPositionChange]="onLabelPositionChange.bind(this)"
    [folderSettings]="getFolderPickerSettings"
    (editDisabled)="onEditDisabled()"
    (editEnabled)="onEditEnabled()"
    (onCustomVariantSelection)="onCustomVariantSelection($event)"
  >
  </app-attribute-format-settings>

  <app-link-type-format-settings
    *ngIf="!model.isAttributeSelected"
    [hash]="hash"
    [attributeId]="selectedItem.value | getLinkAttributeId : selectedItem.meta"
    [changeLabelStyleReference]="changeLabelStyleReference"
    [formatSettings]="formatSettings"
    [onLabelPositionChange]="onLabelPositionChange.bind(this)"
    (onCustomVariantSelection)="onCustomVariantSelection($event)"
  >
  </app-link-type-format-settings>
</ng-container>
