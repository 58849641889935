<div class="grid"
     [style.padding-top]="((model.settings.widgetType | isLoginWidgetType) || (model.settings.widgetType | isRecoverPasswordWidgetType)) ? '23px' : 0">
  <ng-container
    *ngIf="!(model.settings.widgetType | isLoginWidgetType) && !(model.settings.widgetType | isRecoverPasswordWidgetType)">
    <ng-container *ngIf="widgetSettings.widgetType | isArtifactWidgetType">
      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox id="urlChangeAction" binary="true" [(ngModel)]="widgetSettings.urlChangeAction"
                        (onChange)="onListenForUrlParamCheckBoxClick($event.checked)"></p-checkbox>
            <label for="urlChangeAction" translate> Listen for url change </label>
          </span>
        </span>
      </div>

      <div class="col-6 mb-0">
        <input [disabled]="!widgetSettings.urlChangeAction"
               [(ngModel)]="widgetSettings.urlKeys.listeningKeys.artifactId"
               id="artifactIdListeningKey" pInputText type="text" style="width: 100%"
               placeholder="url param for artifact id">
      </div>
    </ng-container>

    <ng-container *ngIf="widgetSettings.widgetType | isLinkedArtifactWidgetType">
      <div class="col-6 mb-0" style="align-self: center"> Listen for url change</div>
      <div class="col-6 mb-0">
        <input [(ngModel)]="widgetSettings.urlKeys.listeningKeys.linkedArtifactId"
               id="linkedArtifactIdListeningKey" pInputText type="text" style="width: 100%"
               placeholder="linked url param">
      </div>
    </ng-container>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox id="listenForFolderUrlKey" binary="true"
                      [(ngModel)]="widgetSettings.listenForFolderUrlParam"
                      [disabled]="widgetSettings.automaticSave">
          </p-checkbox>
          <label for="listenForFolderUrlKey" translate> Listen for folder url key </label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <input [disabled]="!widgetSettings.listenForFolderUrlParam"
             [(ngModel)]="widgetSettings.urlKeys.listeningKeys.saveToFolderId" (ngModelChange)="onChangeKey()"
             id="folderIdListeningKey" pInputText type="text" style="width: 100%" placeholder="url param for folder id">
    </div>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox
            [(ngModel)]="widgetSettings.enableStoreToFolder"
            (ngModelChange)="onChangeEnableStore()"
            id="storeToFolder"
            binary="true"
          >
          </p-checkbox>
          <label for="storeToFolder" translate> Store to folder </label>
        </span>
      </span>
    </div>
    <div (click)="openFolderPicker()" class="col-6 mb-0 cursor-pointer">
      <span class="readonly-value">{{ currentPath }}</span>
    </div>

    <ng-container *ngIf="widgetSettings.widgetType | isArtifactWidgetType">
      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox id="addCreatedArtifactIdToUrlParam" binary="true"
                        [(ngModel)]="widgetSettings.addCreatedArtifactIdToUrlParam"></p-checkbox>
            <label for="addCreatedArtifactIdToUrlParam" translate> Add newly created artifact to url param </label>
          </span>
        </span>
      </div>

      <div class="col-6 mb-0">
        <input [disabled]="!widgetSettings.addCreatedArtifactIdToUrlParam"
               [(ngModel)]="widgetSettings.urlKeys.emittingKeys.moduleId"
               id="artifactIdEmittingKey" pInputText type="text" style="width: 100%"
               placeholder="url param for artifact id">
      </div>

      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox id="addCreatedFolderIdToUrlParam" binary="true"
                        [(ngModel)]="widgetSettings.addCreatedFolderIdToUrlParam"></p-checkbox>
            <label for="addCreatedFolderIdToUrlParam"
                   translate> Add newly created module artifact's folder to url param </label>
          </span>
        </span>
      </div>

      <div class="col-6 mb-0">
        <input [disabled]="!widgetSettings.addCreatedFolderIdToUrlParam"
               [(ngModel)]="widgetSettings.urlKeys.emittingKeys.folderId"
               id="artifactFolderIdEmittingKey" pInputText type="text" style="width: 100%"
               placeholder="url param for artifact id">
      </div>
    </ng-container>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox id="clearFormOnCreation" binary="true"
                      [(ngModel)]="widgetSettings.clearFormOnCreation"></p-checkbox>
          <label for="clearFormOnCreation" translate> Clear form on creation </label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox" *ngIf="widgetSettings.clearFormOnCreation" [@fadeAnimation]>
        <span class="dynamic-checkbox-box">
          <p-checkbox id="clearFormOnBlur" binary="true" [(ngModel)]="widgetSettings.clearFormOnBlur"></p-checkbox>
          <label for="clearFormOnBlur" translate> Clear form on blur </label>
        </span>
      </span>
    </div>

    <div class="col-12 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox id="showHistoryButton" binary="true" [(ngModel)]="widgetSettings.showHistoryButton"></p-checkbox>
          <label for="showHistoryButton" translate> Show button - history of transactions </label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox id="showAclButton" binary="true" [(ngModel)]="widgetSettings.showAclButton"></p-checkbox>
          <label for="showAclButton" translate> Show access control button </label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box"
              pTooltip="'Listen for URL param change' must be enabled"
              [tooltipDisabled]="widgetSettings.urlChangeAction">
          <p-checkbox id="automaticSave" binary="true"
                      [(ngModel)]="widgetSettings.automaticSave"
                      [disabled]="!widgetSettings.urlChangeAction"
                      (onChange)="onAutomaticSaveCheckBoxClick($event.checked)">
          </p-checkbox>
          <label for="automaticSave" [ngClass]="{'line-through': !widgetSettings.urlChangeAction}" translate>
            Automatic save
          </label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box"
              pTooltip="'Automatic save' must be enabled"
              [tooltipDisabled]="widgetSettings.automaticSave">
          <p-checkbox id="notifyOnAutomaticSave" binary="true"
                      [(ngModel)]="widgetSettings.notifyOnAutomaticSave"
                      [disabled]="!widgetSettings.automaticSave">
          </p-checkbox>
          <label for="notifyOnAutomaticSave" [ngClass]="{'line-through': !widgetSettings.urlChangeAction}" translate>
            Notify on automatic save
          </label>
        </span>
      </span>
    </div>

    <div class="col-12 mb-0 pt-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box"
              pTooltip="Automatic save must be turned off"
              [tooltipDisabled]="!widgetSettings.automaticSave">
          <p-checkbox id="initiateWithEditButton" binary="true"
                      [(ngModel)]="widgetSettings.initiateEditWithEditButton"
                      [disabled]="widgetSettings.automaticSave">
          </p-checkbox>
          <label for="initiateWithEditButton" [ngClass]="{'line-through': widgetSettings.automaticSave}" translate>
            Initiate edit with EDIT button
          </label>
        </span>
      </span>
    </div>

    <div class="col-12 mb-0 pt-0"
         *ngIf="(model.settings.widgetType | isRecoverPasswordWidgetType) || (model.settings.widgetType | isRegistrationWidgetType)">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox inputId="useReCaptcha" binary="true" [(ngModel)]="widgetSettings.useReCaptcha"></p-checkbox>
          <label for="useReCaptcha" [ngClass]="{'line-through': widgetSettings.automaticSave}" translate>
            Use ReCaptcha
          </label>
        </span>
      </span>
    </div>

    <div class="col-12 mb-0">
      <div class="dynamic-label p-float-label">
        <p-dropdown [autoDisplayFirst]="false"
                    [options]="saveButtonOptions"
                    [(ngModel)]="widgetSettings.saveButtonVisibility"
                    (onChange)="onSaveButtonVisibilityChange()"
                    id="saveButton">
          <ng-template pTemplate="selectedItem" let-selected>
            {{ selected.label | translate }}
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div style="pointer-events: auto"
                 pTooltip="Either 'Automatic save' must be enabled or at least one of save buttons not hidden"
                 [tooltipDisabled]="!option.disabled">
              <span>{{ option.label | translate }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <label class="dynamic-label-text" for="saveButton" translate> Save button </label>
      </div>
    </div>

    <div class="col-12 mb-0">
      <div class="dynamic-label p-float-label">
        <p-dropdown [autoDisplayFirst]="false"
                    [options]="saveAndNotifyButtonOptions"
                    [(ngModel)]="widgetSettings.saveAndNotifyButtonVisibility"
                    (onChange)="onSaveButtonVisibilityChange()"
                    id="saveAndNotifyButton">
          <ng-template pTemplate="selectedItem" let-selected>
            {{ selected.label | translate }}
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div style="pointer-events: auto"
                 pTooltip="Either 'Automatic save' must be enabled or at least one of save buttons not hidden"
                 [tooltipDisabled]="!option.disabled">
              <span>{{ option.label | translate }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <label class="dynamic-label-text" for="saveAndNotifyButton" translate> Save and notify button </label>
      </div>
    </div>
  </ng-container>

  <div class="col-8 mb-0" *ngIf="model.settings.widgetType | isLoginWidgetType">
    <div class="dynamic-label p-float-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [options]="model.options.actionsAfterLogin"
                  [filter]="true"
                  [(ngModel)]="widgetSettings.actionAfterLogin"
                  id="actionAfterLogin">
      </p-dropdown>
      <label class="dynamic-label-text" for="actionAfterLogin" translate> Redirection after login </label>
    </div>
  </div>

  <div class="col-4 flex align-items-center justify-content-around"
       *ngIf="(model.settings.widgetType | isLoginWidgetType) && ((model.settings.actionAfterLogin | isCustomRedirectionPage)||(model.settings.actionAfterLogin | isDefaultApplicationRedirectionPage))">
    <label [for]="'useAlias'">{{ 'Use alias' | translate }}</label>
    <p-checkbox inputId="useAlias" [(ngModel)]="widgetSettings.useAliasForRedirection" [binary]="true"></p-checkbox>
  </div>

  <div class="col-12 mb-0"
       *ngIf="(model.settings.widgetType | isLoginWidgetType) && (model.settings.actionAfterLogin | isCustomRedirectionPage)">
    <div class="dynamic-label p-float-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [showClear]="true"
                  [options]="pageOptions"
                  [filter]="true"
                  [(ngModel)]="widgetSettings.loginRedirectionPageId"
                  id="redirectionPage">
      </p-dropdown>
      <label class="dynamic-label-text" for="redirectionPage" translate> Page to redirect after login </label>
    </div>
  </div>

  <div class="col-12 mb-0" *ngIf="model.settings.widgetType | isRecoverPasswordWidgetType">
    <div class="dynamic-label p-float-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [showClear]="true"
                  [options]="pageOptions"
                  [filter]="true"
                  [(ngModel)]="widgetSettings.passwordRecoveryRedirectionPageId"
                  id="recoverPasswordRedirectionPage">
      </p-dropdown>
      <label class="dynamic-label-text" for="recoverPasswordRedirectionPage" translate> Redirect page after password
        recovery </label>
    </div>
  </div>

  <div class="col-12 mb-0" *ngIf="model.settings.widgetType | isRegistrationWidgetType">
    <div class="dynamic-label p-float-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [showClear]="true"
                  [options]="pageOptions"
                  [filter]="true"
                  [(ngModel)]="widgetSettings.registrationRedirectionPageId"
                  id="registrationRedirectionPageId">
      </p-dropdown>
      <label class="dynamic-label-text" for="registrationRedirectionPageId" translate> Redirect page after
        registration </label>
    </div>
  </div>

  <div class="col-12 mb-0">
    <div class="dynamic-label p-float-label">
      <p-dropdown [autoDisplayFirst]="false"
                  [options]="model.options.labelBehaviour"
                  [(ngModel)]="widgetSettings.labelBehaviour"
                  id="labelBehaviour">
        <ng-template pTemplate="selectedItem" let-selected>
          {{ selected.label | translate }}
        </ng-template>
        <ng-template pTemplate="item" let-option>
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
      <label class="dynamic-label-text" for="labelBehaviour"> Label behaviour </label>
    </div>
  </div>

  <div class="col-6 flex align-items-center justify-content-around"
       *ngIf="(model.settings.widgetType | isLoginWidgetType)">
    <label [for]="'showGoogleSso'">{{ 'Show Google SSO' | translate }}</label>
    <p-checkbox inputId="showGoogleSso" [(ngModel)]="widgetSettings.showGoogleSso" [binary]="true"></p-checkbox>
  </div>

  <div class="col-6 flex align-items-center justify-content-around"
       *ngIf="(model.settings.widgetType | isLoginWidgetType)">
    <label [for]="'showOpenIdSso'">{{ 'Show OpenID SSO' | translate }}</label>
    <p-checkbox inputId="showOpenIdSso" [(ngModel)]="widgetSettings.showOpenIdSso" [binary]="true"></p-checkbox>
  </div>

  <div class="col-4 mb-0">
    <button class="w-100" (click)="paddingOp.toggle($event)" pButton label="{{'Paddings' | translate }}"
            icon="pi pi-cog"></button>
  </div>

  <div class="col-4 mb-0">
    <button class="w-100" (click)="borderOp.toggle($event)" pButton label="{{'Borders' | translate }}"
            icon="bi bi-border-style"></button>
  </div>

  <div class="col-4 mb-0">
    <button class="w-100" (click)="colorOp.toggle($event)" pButton label="{{'Colors' | translate }}"
            icon="pi pi-palette"></button>
  </div>


  <p-overlayPanel #colorOp appendTo="body" [style]="{'max-width':'380px'}">
    <ng-template pTemplate>
      <app-color-picker [(color)]="widgetSettings.styles.backgroundColor" label="Background"></app-color-picker>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #borderOp appendTo="body" [style]="{'max-width':'500px'}">
    <ng-template pTemplate>
      <app-border-form [styles]="widgetSettings.styles"></app-border-form>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #paddingOp appendTo="body" [style]="{'max-width':'380px'}">
    <ng-template pTemplate>
      <app-padding-form [paddingStyles]="widgetSettings.styles"></app-padding-form>
    </ng-template>
  </p-overlayPanel>

</div>

<app-folder-picker #folderPicker (changeFolder)="onChangeFolder($event)" [setLayoutMode]="true"
                   [showTenant]="true"></app-folder-picker>
