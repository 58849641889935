import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
class IsNotProductionGuard {
  constructor(private router: Router) {}

  async canActivate(): Promise<boolean> {
    if (Environment.production) {
      await this.router.navigateByUrl('/notfound');
      return false;
    }
    return true;
  }
}

export const CanActivateIsNotProduction: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  state;
  return inject(IsNotProductionGuard).canActivate();
};
