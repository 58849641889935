import { Pipe, PipeTransform } from '@angular/core';
import { NewLink } from '@shared/types/link.types';
import { NewTableColumn } from '@shared/types/table.types';
import { ArtifactLinks } from '../types/artifact-list-widget-table.types';

@Pipe({ name: 'getRelevantLinksToTableCell' })
export class GetRelevantLinksToTableCellPipe implements PipeTransform {
  transform(column: NewTableColumn, artifactId: string, links: Record<string, Record<string, ArtifactLinks>>): NewLink[] | null {
    if (!column.meta?.linkRestrictionParams) {
      return null;
    }
    const { linkTypeId, direction } = column.meta?.linkRestrictionParams || {};
    return links[linkTypeId] && links[linkTypeId][artifactId] && links[linkTypeId][artifactId][direction];
  }
}
