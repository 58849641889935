import { Injectable } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ConvertDateValueToServer, ConvertStringOrDateToDate } from '@shared/methods/date.methods';
import { BaseDateFilterService } from '@shared/services/filter/filter-types/base-date-filter.service';
import { CustomDateSettings, DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { ArtifactFilterHelper } from '@widgets/shared/components/artifact-filters/services/artifact-filter.helper';
import { ArtifactFilter, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { DateTimeFilterMatchType, TimeFilterRuleType } from '../types/date-time-filter-options.types';
import { DateTimeFilter, DateTimeFilterOption } from '../types/date-time-filter.types';

@Injectable({
  providedIn: 'root',
})
export class DateTimeFilterService {
  constructor(
    private readonly helper: ArtifactFilterHelper,
    private readonly filterMetadataUtil: FilterMetadataUtil,
    private readonly baseDateFilterService: BaseDateFilterService,
  ) {}

  getQuery(filter: ArtifactFilter): any {
    const value: DateTimeFilter = filter.value as DateTimeFilter;
    const isRegularFilter = this.isRegularFilter(filter.value.ruleTypes[0].ruleType);

    if (!value && isRegularFilter) {
      return null;
    }

    const sign = value.matchType === DateTimeFilterMatchType.matchAny ? '$or' : '$and';
    const ruleTypes = value.ruleTypes?.filter(rule => /*rule.value && */ rule.ruleType);

    if (!ruleTypes?.length) {
      return null;
    }

    const query = [...ruleTypes.map(rule => this.getRule(rule, filter, filter.type === ArtifactFilterType.system)).filter(rule => rule)];
    return query.length ? { [sign]: query } : null;
  }

  getFilterValueFromString(str: string): DateTimeFilter | null {
    const [key, value] = str.split(',');
    const ruleType =
      DateFilterEnum[key as keyof typeof DateFilterEnum] ||
      TimeFilterRuleType[key as keyof typeof TimeFilterRuleType] ||
      DateRangeFilterEnum[key as keyof typeof DateRangeFilterEnum] ||
      null;

    if (!value || !ruleType) return null;

    const filter = new DateTimeFilter();
    filter.ruleTypes[0] = { ruleType, value };
    return filter;
  }

  getRule(rule: DateTimeFilterOption, filter: ArtifactFilter, isSystemAttribute: boolean): any {
    const attributeKey = isSystemAttribute ? filter.systemAttributeCode : `attributes.${filter.attributeId}.value`;

    if (typeof rule.value === 'string' || rule.value instanceof Date) {
      rule.value = ConvertStringOrDateToDate(rule.value);
    }

    if (rule.ruleType === DateRangeFilterEnum.dateBetween) {
      const value = (filter.value as any).ruleTypes[0].value;
      if (!value[0] || !value[1]) return;

      const startEndDate = new CustomDateSettings({ start: new Date(value[0]), end: new Date(value[1]) });
      return this.filterMetadataUtil.transformStartEndDateToMongoQuery(startEndDate, attributeKey, isSystemAttribute);
    }

    if (Object.prototype.hasOwnProperty.call(DateRangeFilterEnum, rule.ruleType)) {
      return this.getMetaDataForDateRangeFilterEnum(rule.ruleType as DateRangeFilterEnum, rule.value as any, attributeKey, isSystemAttribute);
    }

    if (Object.prototype.hasOwnProperty.call(DateFilterEnum, rule.ruleType) || Object.prototype.hasOwnProperty.call(TimeFilterRuleType, rule.ruleType)) {
      return this.getMetaDataForDateFilterEnum(attributeKey, rule, filter, isSystemAttribute);
    }

    if (isSystemAttribute && rule.value) {
      const startEndDate = this.filterMetadataUtil.getStartEndDateOfExactMinute(rule.value as Date);
      return this.filterMetadataUtil.transformStartEndDateToMongoQuery(startEndDate, attributeKey, true);
    }
  }

  private getMetaDataForDateRangeFilterEnum(filterType: DateRangeFilterEnum, value: number, attributeKey: string, useIsoFormat: boolean): any {
    const customSettings = new CustomDateSettings();
    if (this.filterMetadataUtil.isFilterNumeric(filterType)) {
      customSettings.offsetInDays = value;
    }

    const startEndDate = this.filterMetadataUtil.transformDateRangeEnumToDateRange(filterType, customSettings);
    return this.filterMetadataUtil.transformStartEndDateToMongoQuery(startEndDate, attributeKey, useIsoFormat);
  }

  private getMetaDataForDateFilterEnum(attributeKey: string, rule: DateTimeFilterOption, filter: ArtifactFilter, isSystemAttribute: boolean): any {
    return isSystemAttribute && (rule.ruleType === DateFilterEnum.dateIs || rule.ruleType === DateFilterEnum.dateIsNot)
      ? this.getFilterForDateIs(attributeKey, rule.ruleType, ConvertDateValueToServer(rule.value as Date, filter.dataType?.baseDataType as BaseDataType))
      : {
          [attributeKey]: this.getFilterByRuleType(
            rule.ruleType,
            ConvertDateValueToServer(rule.value as Date, filter.dataType?.baseDataType as BaseDataType),
            isSystemAttribute,
          ),
        };
  }

  private getFilterForDateIs(key: string, type: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType, value: string): Record<string, any> {
    return this.baseDateFilterService.getQueryForDateIs(key, type, value);
  }

  private isRegularFilter(matchMode: DateFilterEnum): boolean {
    const { isEmpty, isNotEmpty } = DateFilterEnum;
    return matchMode !== isNotEmpty && matchMode !== isEmpty;
  }

  // TODO: refactor
  private getFilterByRuleType(
    type: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType,
    value: string | { $date: string },
    useIsoDate: boolean,
  ): Record<string, any> {
    switch (type) {
      case DateFilterEnum.dateIs:
        return { $eq: useIsoDate ? { $date: String(value) } : String(value) };
      case DateFilterEnum.dateIsNot:
        return { $not: { $eq: useIsoDate ? { $date: String(value) } : String(value) } };
      case DateFilterEnum.dateBefore:
        return { $lt: useIsoDate ? { $date: String(value) } : String(value) };
      case DateFilterEnum.dateAfter:
        return { $gt: useIsoDate ? { $date: String(value) } : String(value) };
      case DateFilterEnum.dateBeforeOrEqualTo:
        return { $lte: useIsoDate ? { $date: String(value) } : String(value) };
      case DateFilterEnum.dateAfterOrEqualTo:
        return { $gte: useIsoDate ? { $date: String(value) } : String(value) };
      case DateFilterEnum.isEmpty:
        return { $eq: null };
      case DateFilterEnum.isNotEmpty:
        return { $not: { $eq: null } };
      default:
        return { $regex: useIsoDate ? { $date: String(value) } : String(value), $options: 'i' };
    }
  }
}
