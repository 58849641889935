import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { FlexDirection, JustifyContent } from '@widgets/shared/types/style.types';

export class AuthWidgetStyles extends FontStyles {
  justifyContent: JustifyContent = JustifyContent.center;
  flexDirection: FlexDirection = FlexDirection.row;

  constructor(styles?: Partial<AuthWidgetStyles>) {
    super();
    styles && Object.assign(this, styles);
  }
}
