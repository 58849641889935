export class SidebarMenu {
  constructor(public label: string, public icon: string, public items: SidebarMenuItem[]) {
    this.label = label;
    this.icon = icon;
    this.items = items;
  }
}

export interface SidebarMenuItem {
  label: string;
  icon: string;
  routerLink?: string[];
  items?: SidebarMenuItem[];
  command?: (commandParam: SidebarMenuItemCommandParam) => void;
  disabled?: boolean;
}

export interface SidebarMenuItemCommandParam {
  originalEvent: Event;
  item: SidebarMenuItem;
}
