import { Directive, Host, inject, Input, OnInit, Optional, Self } from '@angular/core';
import { PageResponseDto } from '@api/models/page-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { Dropdown } from 'primeng/dropdown';

@Directive({ selector: '[appNotifyIfSelectedPageDeletedDirective]' })
export class NotifyIfSelectedPageDeletedDirective implements OnInit {
  @Input() selectedPageId: string;
  translate: TranslateUtil = inject(TranslateUtil);
  cache: NewCacheService = inject(NewCacheService);

  constructor(@Host() @Self() @Optional() public pDropdown: Dropdown) {}

  async ngOnInit(): Promise<void> {
    const { pages } = this.cache.data;

    const subscription = pages.subscribe(async pageDtos => {
      const selected = this.selectedPageId;

      if (selected && !pageDtos!.some(dto => (dto as PageResponseDto)?.id === selected)) {
        let isFirstChange = true;
        const changeCb = this.pDropdown.onModelChange;
        const span = document.createElement('SPAN');
        span.classList.add('deleted-directive-page-notification');
        span.innerText = await this.translate.get('This Page has been deleted. Please, select other page.');

        this.pDropdown.el.nativeElement.appendChild(span);

        this.pDropdown.onModelChange = (event: any) => {
          if (!isFirstChange || event) {
            span.style.display = 'none';
            changeCb && changeCb(event);
          }

          isFirstChange = false;
        };
      }

      setTimeout(() => subscription && subscription.unsubscribe());
    });
  }
}
