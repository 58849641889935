import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { EditorModule } from '@tinymce/tinymce-angular';
// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
// Components
import { ArtifactListComponent } from './artifact-list/artifact-list.component';
import { ArtifactListTableComponent } from './artifact-list/components/artifact-list-table/artifact-list-table.component';
import { ArtifactComponent } from './artifact/artifact.component';
import { ArtifactFormComponent } from './artifact/components/artifact-form/artifact-form.component';
import { BoundedRangeKindInputComponent } from './artifact/components/bounded-range-kind-input/bounded-range-kind-input.component';
import { EnumeratedKindInputComponent } from './artifact/components/enumerated-kind-input/enumerated-kind-input.component';
import { SimpleKindFileInputComponent } from './artifact/components/simple-kind-file-input/simple-kind-file-input.component';
import { SimpleKindHyperlinkInputComponent } from './artifact/components/simple-kind-hyperlink-input/simple-kind-hyperlink-input.component';
import { SimpleKindInputComponent } from './artifact/components/simple-kind-input/simple-kind-input.component';
import { RippleModule } from 'primeng/ripple';

const declarations = [
  ArtifactListComponent,
  ArtifactComponent,
  ArtifactFormComponent,
  BoundedRangeKindInputComponent,
  EnumeratedKindInputComponent,
  SimpleKindInputComponent,
  ArtifactListTableComponent,
  SimpleKindFileInputComponent,
  SimpleKindHyperlinkInputComponent,
];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    SliderModule,
    ChipsModule,
    LoaderModule,
    CheckboxModule,
    CalendarModule,
    EditorModule,
    FileUploadModule,
    ElvisSharedModule,
    ArtifactPipesModule,
    DisplayArtifactValueToTableModule,
    DataTypePipesModule,
    RippleModule,
  ],
  exports: [...declarations],
})
export class ArtifactManagementModule {}
