import { DataTypeValueResponseDto, WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface WorkflowActionCustomAttributeValuesDto extends WorkflowActionDto {
  dataTypeId: string;
  values: DataTypeValueResponseDto[];
}

export class WorkflowActionUseCustomAttributeValues
  extends AbstractWorkflowAttributeBasedAction<CommonFeAction>
  implements WorkflowActionCustomAttributeValuesDto
{
  dataTypeId: string;
  values: DataTypeValueResponseDto[];

  constructor(dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, type: WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES, dto, actionAttributeHandler });
    this.dataTypeId = (dto as WorkflowActionCustomAttributeValuesDto)?.dataTypeId;
    this.values = (dto as WorkflowActionCustomAttributeValuesDto)?.values;
    this.supportedAttributeDataKinds = [DataTypeKind.enumerated];
    this.supportedAttributeDataTypes = [BaseDataType.integer, BaseDataType.text];
  }

  canBeExecuted(): boolean {
    return this.isValid();
  }

  execute(): void {
    this.actionAttributeHandler.notifySetAttributeOptionsEvent({
      artifactTypeId: this.artifactTypeId,
      attributeId: this.attributeId,
      value: this.values,
    });
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId && this.dataTypeId && this.values?.length);
  }

  toServer(): WorkflowActionCustomAttributeValuesDto {
    return {
      ...super.toServer(),
      dataTypeId: this.dataTypeId,
      values: this.values,
    };
  }
}
