<span class="dynamic-label" *ngIf="fullAttribute">
    <p class="label" *ngIf="fullAttribute.multipleValues">{{fullAttribute.name + ' [' + artifactAttribute.value[0] + ' - ' + artifactAttribute.value[1] + ']'}}</p>
    <p class="label" *ngIf="!fullAttribute.multipleValues">{{fullAttribute.name + ' [' + artifactAttribute.value + ']'}}</p>
    <p-slider [(ngModel)]="artifactAttribute.value"
              [id]="'simpleField' + index"
              [min]="+fullDataType.minimum"
              [max]="+fullDataType.maximum"
              [range]="fullAttribute.multipleValues"
              [step]="isInteger(artifactAttribute) ? 1 : 0.1">
    </p-slider>
</span>
