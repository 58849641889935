import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartWidgetHelper {
  blankSortFn(a: any, b: any): number {
    const isBlank = (val: Array<string> | string): boolean => Array.isArray(val) && !!val.length;
    return isBlank(a.value) ? -1 : isBlank(b.value) ? 1 : 0;
  }
}
