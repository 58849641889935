export interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor?: string[];
  }[];
  counter: {
    total: number;
    totalSum: number | null;
    totalSumLabel: string | null;
    totalSumPerGroup: number[];
  };
}

export enum ChartWidgetTypeChart {
  pie = 'pie',
  doughnut = 'doughnut',
}
