import { Component, Input } from '@angular/core';
import { GetArtifactsKeys, WorkflowActionGetArtifacts } from '@workflows/types/actions/action-get-artifacts';

@Component({
  selector: 'app-action-get-links',
  templateUrl: './action-get-links.component.html',
  styleUrls: ['./action-get-links.component.scss'],
})
export class ActionGetLinksComponent {
  @Input() action: WorkflowActionGetArtifacts;

  protected readonly RuleKeys = GetArtifactsKeys;
}
