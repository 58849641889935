import { Component, Input } from '@angular/core';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelPositionEnum, TextHorizontalAlignEnum, TextVerticalAlignEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-link-type-label-styles-form',
  templateUrl: './link-type-label-styles-form.component.html',
  styleUrls: ['./link-type-label-styles-form.component.scss'],
})
export class LinkTypeLabelStylesFormComponent {
  @Input() formatSettings: LinkTypeFormatSettings;
  @Input() changeLabelStyleReference: (formatSettings: LinkTypeFormatSettings) => void;
  @Input() onLabelPositionChange: (event: { originalEvent: PointerEvent; value: LabelPositionEnum }, formatSettings: LinkTypeFormatSettings) => void;

  readonly labelPositionOptions = GetSelectOptionsFromEnum(LabelPositionEnum);
  readonly textHorizontalAlignOptions = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  readonly textVerticalAlignOptions = GetSelectOptionsFromEnum(TextVerticalAlignEnum);

  get isVerticalAlignmentAvailable(): boolean {
    const { position } = this.formatSettings.label;

    return position === LabelPositionEnum.left || position === LabelPositionEnum.right;
  }

  onUseCustomLabelChange(showCustomLabel: boolean): void {
    !showCustomLabel && (this.formatSettings.label.customLabel = '');
  }
}
