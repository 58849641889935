import { ArtifactResponseDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { NewArtifact } from '@shared/types/artifact.types';
import { AvrWidgetComponent } from '@widgets/avr-widget/avr-widget.component';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrWidgetOptions } from '@widgets/avr-widget/types/avr-widget-options.types';
import { AvrArtifactSourcesType, AvrWidgetSettings } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';
import { cloneDeep, isObject, omit, transform } from 'lodash';

export abstract class AbstractAvrWidgetArtifactSourceService {
  private context: AvrWidgetComponent;
  private _artifactSource: AvrArtifactSourcesType;

  protected constructor(artifactSource: AvrArtifactSourcesType, protected readonly tenantArtifactService: TenantArtifactService) {
    this._artifactSource = artifactSource;
  }

  get artifactSource(): AvrArtifactSourcesType {
    return this._artifactSource;
  }

  async init(context: AvrWidgetComponent): Promise<void> {
    this.context = context;
  }

  async onArtifactSourceChange(): Promise<void> {
    console.warn(this.getWarningMessage('artifact source change'));
  }

  async onSelectedArtifactChange(): Promise<void> {
    console.warn(this.getWarningMessage('selected artifact change'));
  }

  async onArtifactTypeChange(): Promise<void> {
    console.warn(this.getWarningMessage('artifact type change'));
  }

  async onArtifactListeningKeyChange(): Promise<void> {
    console.warn(this.getWarningMessage('artifact listening key change'));
  }

  async onAvrTypeChange(): Promise<void> {
    console.warn(this.getWarningMessage('artifact avr type change'));
  }

  protected get model(): AvrWidgetModel {
    return this.context.m;
  }

  protected get s(): AvrWidgetService {
    return this.context.s;
  }

  protected get options(): AvrWidgetOptions {
    return this.model.options;
  }

  protected get settings(): AvrWidgetSettings {
    return this.model.settings;
  }

  protected resetAll(exceptPaths: string[] = []): void {
    this.s.setCurrentArtifact(null);
    this.model.currentAvrFileType = null;
    const newSettings = { settings: omit(cloneDeep(this.model.initModel).settings, ['button', ...exceptPaths]) };

    this.customMerge(this.model.settings, newSettings.settings);
  }

  protected artifactResponseTransform(artifact: ArtifactResponseDto): NewArtifact {
    return new NewArtifact({
      dto: artifact,
      artifactTypesMap: {
        [artifact.artifactTypeId]: this.model.options.artifactTypesOptions.find(value => value.value.id === artifact.artifactTypeId)!.value,
      },
    });
  }

  private getWarningMessage(event: string): string {
    return `An action for "${event}" event in "${this.artifactSource}" artifact source is empty`;
  }

  private customMerge(obj: any, src: any): any {
    return transform(
      src,
      (result, value, key) => {
        if (isObject(value) && isObject(obj[key])) {
          result[key] = this.customMerge(obj[key], value);
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
          result[key] = value;
        }
      },
      obj,
    );
  }
}
