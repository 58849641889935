import { NewApplication } from '@shared/types/application.types';
import { FolderWidgetOptions } from '@widgets/folder-widget/types/folder-widget-options.types';
import { FolderWidgetSettings, FolderWidgetSettingsDto } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { FOLDER_WIDGET_CONTEXT_MENU_WIDTH } from '@widgets/shared/constants/widget.constants';
import { TreeNode } from 'primeng/api/treenode';
import { OverlayPanel } from 'primeng/overlaypanel';
import { WidgetModel } from '../../shared/types/widget-model.interface';

export class FolderWidgetValue {
  constructor(public model: FolderWidgetModel = new FolderWidgetModel()) {}
}

export class FolderWidgetModel implements WidgetModel<FolderWidgetModelDto> {
  folders: FolderTreeNode[] = [];
  settings = new FolderWidgetSettings();
  options = new FolderWidgetOptions();
  selectedFile: FolderTreeNode[];
  currentApp: NewApplication;
  op: OverlayPanel;
  details: OverlayPanel;
  overlayColor: OverlayPanel;
  overlayAccess: OverlayPanel;
  color: string;
  isCreateFolder: boolean;
  folderName: string;
  selectedFolderIds: string[];
  folderCounter: number;
  folderColor: Record<string, string> = {};
  containerId: string = Date.now() + '';
  currentFolderId: string;
  userPrivateFolderId: string;
  userDefaultTeamFolderId: string;
  usersFolderId: string;
  teamsFolderId: string;
  searchFolder = '';
  isSidebarOnRight = false;
  aclContextStyles: Record<string, string> = { 'max-width': '380px', width: FOLDER_WIDGET_CONTEXT_MENU_WIDTH + 'px' };
  applications: NewApplication[] = [];

  constructor(dto?: FolderWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): FolderWidgetModelDto {
    return {
      settings: this.settings.toServer(),
      folderColor: this.folderColor,
    };
  }

  fromDto(dto: FolderWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new FolderWidgetSettings(dto.settings));
      dto.folderColor && (this.folderColor = dto.folderColor);
    }
  }
}

export interface FolderWidgetModelDto {
  settings: FolderWidgetSettingsDto;
  folderColor: Record<string, string>;
}

export interface FolderColorSettings {
  id: string;
  color: string;
}

export interface FolderTreeNode extends TreeNode {
  id: string;
  parentId?: string;
  folderPath?: string;
  folderSequence?: number;
  styles?: any;
  hasChilds?: boolean;
  children?: FolderTreeNode[];
  parent?: FolderTreeNode;
  moduleId: string | null;
  draggable?: boolean;
  droppable?: boolean;
}

export interface FolderListItem {
  id: string;
  name: string;
  type: FolderListItemType;
}

export interface FolderUrlKeyChange {
  from: string;
  to: string;
}

export enum FolderListItemType {
  user = 'USER',
  team = 'TEAM',
}

export enum FolderAclType {
  read = 'Read',
  edit = 'Edit',
  modify = 'Modify',
}
