import { Pipe, PipeTransform } from '@angular/core';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';

@Pipe({ name: 'getSubMenuOffset' })
export class GetSubMenuOffsetPipe implements PipeTransform {
  transform(m: MenuWidgetModel, offset?: number): string | null {
    if (offset && m.settings.menu.type === MenuTypesEnum.horizontal) {
      return offset + 'px';
    }
    return null;
  }
}
