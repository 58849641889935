import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ListWidgetSelected } from '../../types/list-widget-selected.types';
import { ListWidgetTableSettings } from '../../types/list-widget-settings.types';

@Component({
  selector: 'app-artifact-list-widget-table-caption',
  templateUrl: './artifact-list-widget-table-caption.component.html',
  styleUrls: ['./artifact-list-widget-table-caption.component.scss'],
})
export class ArtifactListWidgetTableCaptionComponent {
  @Input() selected: ListWidgetSelected;
  @Input() settings: ListWidgetTableSettings;
  @Input() addButtonItems: MenuItem[];
  @Output() onExport = new EventEmitter();

  getQueryParams(): any {
    if (this.settings.folderPath && this.settings.folderId) {
      return { saveToFolderId: this.settings.folderId };
    }
    return null;
  }

  export(): void {
    this.onExport.emit();
  }
}
