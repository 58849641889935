import { Component, Input } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { AttributeSettings } from '@widgets/card-widget/types/attribute-settings';
import { AttributeLabelFormatSettings, AttributeValueFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-attribute-value',
  templateUrl: './attribute-value.component.html',
  styleUrls: ['./attribute-value.component.scss'],
})
export class AttributeValueComponent {
  @Input() artifactDto: ArtifactResponseDto;
  @Input() attributeName: string | undefined;
  @Input() attributeValue: string | undefined;
  @Input() attributeIcon: string | undefined;
  @Input() attributeSettings: AttributeSettings;
  @Input() isEllipsis: boolean;

  get label(): string {
    return this.attributeSettings?.settings.label.useCustomLabel ? this.attributeSettings.settings.label.customLabel : this.attributeName || '';
  }

  get isTopLabel(): boolean {
    return this.attributeSettings?.settings.label.visible && this.attributeSettings.settings.label.position === LabelPositionEnum.top;
  }

  get isRightLabel(): boolean {
    return this.attributeSettings?.settings.label.visible && this.attributeSettings.settings.label.position === LabelPositionEnum.right;
  }

  get isBottomLabel(): boolean {
    return this.attributeSettings?.settings.label.visible && this.attributeSettings.settings.label.position === LabelPositionEnum.bottom;
  }

  get isLeftLabel(): boolean {
    return this.attributeSettings?.settings.label.visible && this.attributeSettings.settings.label.position === LabelPositionEnum.left;
  }

  get labelSettings(): AttributeLabelFormatSettings {
    return this.attributeSettings.settings.label;
  }

  get valueSettings(): AttributeValueFormatSettings {
    return this.attributeSettings.settings.value;
  }
}
