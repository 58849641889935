<p-dialog
  [header]="headerTitle"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  (onHide)="this.hideModal()"
  [resizable]="true"
  [contentStyle]="selectedParts.length > 0 ? { height: '350px' } : {}"
>
  <p *ngIf="selectedParts.length === 0">{{ 'Nothing is selected to export' | translate }}</p>
  <div *ngIf="selectedParts.length > 0" style="height: max(calc(100% - 60px), 100px)">
    <p-table [value]="selectedParts" styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-part>
        <tr>
          <td>{{ part.id }}</td>
          <td>{{ part.name || '' }}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex mt-2">
      <div class="p-field-checkbox">
        <p-checkbox [(ngModel)]="deepExport" binary="true" inputId="export-deepExport"></p-checkbox>
        <label for="export-deepExport"
          >{{ 'Deep export' | translate
          }}<i
            class="pi pi-info-circle ml-1"
            pTooltip="{{ 'Whether you want to export all parts of all other depending applications' | translate }}"
            tooltipPosition="top"
            tooltipZIndex="12000"
          ></i
        ></label>
      </div>
      <div class="p-field-checkbox">
        <p-checkbox [(ngModel)]="disableTransaction" binary="true" inputId="export-disableTransaction"></p-checkbox>
        <label for="export-disableTransaction"
          >{{ 'Disable transaction' | translate
          }}<i class="pi pi-info-circle ml-1" pTooltip="{{ 'Disable only during long exports' | translate }}" tooltipPosition="top" tooltipZIndex="12000"></i
        ></label>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton [label]="'Cancel' | translate" (click)="this.hideModal()" class="p-button-warning"></button>
    <button
      *ngIf="selectedParts.length > 0"
      pButton
      [label]="'Export' | translate"
      icon="pi pi-cloud-download"
      (click)="exportFile()"
      class="p-button"
    ></button>
  </ng-template>
</p-dialog>
