import { Component, ElementRef, Input, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DisplayAtHtmlControlShowEvent, DisplayAtOverlayControlService } from '@shared/components/common-display-at';
import { ElvisUtil } from '@shared/utils/elvis.util';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() template: TemplateRef<any>;
  ownerId = ElvisUtil.makeHash(20);

  private clickSubscription: Subscription;

  constructor(protected readonly datOverlayControlService: DisplayAtOverlayControlService, private readonly zone: NgZone) {}

  @ViewChild('iconClick') set iconClick(content: ElementRef) {
    if (content) {
      this.zone.runOutsideAngular(() => {
        const click$ = fromEvent(content.nativeElement, 'click').pipe(
          tap(event =>
            this.zone.run(() => {
              const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, true);
              this.datOverlayControlService.doShowOverlay(showEvent);
            }),
          ),
        );

        const mouseEnter$ = fromEvent(content.nativeElement, 'mouseenter').pipe(
          tap(event =>
            this.zone.run(() => {
              const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, false);
              this.datOverlayControlService.doShowOverlay(showEvent);
            }),
          ),
        );

        const mouseOut$ = fromEvent(content.nativeElement, 'mouseout').pipe(
          tap(() =>
            this.zone.run(() => {
              this.datOverlayControlService.doHideOverlay(this.ownerId);
            }),
          ),
        );

        const preview$ = mouseEnter$.pipe(switchMap(() => mouseOut$.pipe(takeUntil(click$))));

        this.clickSubscription = merge(preview$, click$).subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.clickSubscription && this.clickSubscription.unsubscribe();
  }

  private getDisplayAtHtmlControlShowEvent(event: Event, closable: boolean): DisplayAtHtmlControlShowEvent {
    return {
      ownerId: this.ownerId,
      contentTemplate: this.template,
      event,
      closable,
    };
  }
}
