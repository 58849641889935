import { Component, Input } from '@angular/core';
import { ButtonCopyPastingService } from '@widgets/sidebar-modal-widget/services/copy-paste.service';
import { ButtonView } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button.types';

@Component({
  selector: 'app-state-header',
  templateUrl: './state-header.component.html',
  styleUrls: ['./state-header.component.scss'],
})
export class StateHeaderComponent {
  @Input() label: string;
  @Input() button: ButtonView;

  constructor(public readonly copyService: ButtonCopyPastingService) {}

  stopEventPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
