import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@shared/constants/constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getLinkName' })
export class GetLinkNamePipe implements PipeTransform {
  transform(
    link: NewLink,
    selectedArtifact: NewArtifact | null,
    artifactTypes: ListContainer<NewArtifactType>,
    attributes: ListContainer<NewAttribute>,
    linkedArtifacts: Record<string, NewArtifact>,
  ): string {
    if (!selectedArtifact) return '';

    const targetArtifactId = LinkMethods.isArtifactDestination(selectedArtifact, link) ? link.sourceArtifactId : link.destinationArtifactId;
    const targetArtifact: NewArtifact = new NewArtifact({ dto: linkedArtifacts[targetArtifactId], artifactTypesMap: artifactTypes.listMap });
    const primaryAttributeIds = artifactTypes.listMap[targetArtifact.artifactTypeId].primaryAttributes;
    return primaryAttributeIds
      .map(id => {
        switch (id) {
          case Constants.primaryAttributesDefaultId:
            return targetArtifactId;
          case Constants.primaryAttributesArtifactTypeId:
            return artifactTypes.listMap[targetArtifact.artifactTypeId].name;
          default:
            return targetArtifact.attributes[id].value;
        }
      })
      .join(', ');
  }
}
