import {
  URL_KEY_MODULE_INSERT_INTO,
  URL_KEY_MODULE_INSERT_IS_HEADING,
  URL_KEY_MODULE_INSERT_PARENT_ID,
  URL_KEY_VALUE_ARTIFACT_ID,
  URL_KEY_VALUE_SAVE_TO_FOLDER_ID,
} from '@shared/constants/constants';
import { ArtifactWidgetActionAfterLogin, ArtifactWidgetType } from '@widgets/artifact-widget/types/artifact-widget.types';
import { LabelBehaviourEnum, WidgetSaveButtonVisibilityEnum, WidgetStyles } from '@widgets/shared/types/style.types';

export class ArtifactWidgetSettings {
  widgetType = ArtifactWidgetType.artifact;
  urlChangeAction = true;
  urlKeys = new ArtifactUrlKeys();
  clearFormOnCreation = true;
  clearFormOnBlur = false;
  automaticSave = false;
  notifyOnAutomaticSave = false;
  saveButtonVisibility = WidgetSaveButtonVisibilityEnum.onChange;
  saveAndNotifyButtonVisibility = WidgetSaveButtonVisibilityEnum.onChange;
  labelBehaviour = LabelBehaviourEnum.static;
  initiateEditWithEditButton = false;
  listenForFolderUrlParam = true;
  enableStoreToFolder = false;
  useReCaptcha: boolean | null = null;
  storeToFolderId: string | null;
  actionAfterLogin: ArtifactWidgetActionAfterLogin = ArtifactWidgetActionAfterLogin.applicationSelection;
  loginRedirectionPageId: string | null = null;
  registrationRedirectionPageId: string | null = null;
  passwordRecoveryRedirectionPageId: string | null = null;
  addCreatedArtifactIdToUrlParam = false;
  addCreatedFolderIdToUrlParam = false;
  useAliasForRedirection = false;
  showHistoryButton = true;
  showAclButton = true;
  styles = new WidgetStyles();

  constructor(params: Partial<ArtifactWidgetSettings> | null = null) {
    if (params) {
      params.styles && (params.styles = new WidgetStyles(params.styles));
      params.urlKeys && (params.urlKeys = new ArtifactUrlKeys(params.urlKeys));
      Object.assign(this, params);
    }
  }
}

export class ArtifactUrlKeys {
  listeningKeys = new ArtifactListeningKeys();
  emittingKeys = new ArtifactEmittingKeys();

  constructor(params?: Partial<ArtifactUrlKeys>) {
    if (params) {
      params.listeningKeys && (params.listeningKeys = new ArtifactListeningKeys(params.listeningKeys));
      params.emittingKeys && (params.emittingKeys = new ArtifactEmittingKeys(params.emittingKeys));
      Object.assign(this, params);
    }
  }
}

export class ArtifactListeningKeys {
  artifactId = URL_KEY_VALUE_ARTIFACT_ID;
  linkedArtifactId = URL_KEY_VALUE_ARTIFACT_ID;
  saveToFolderId = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;
  moduleInsertParentId = URL_KEY_MODULE_INSERT_PARENT_ID;
  moduleInsertInto = URL_KEY_MODULE_INSERT_INTO;
  moduleInsertIsHeading = URL_KEY_MODULE_INSERT_IS_HEADING;

  constructor(params?: Partial<ArtifactListeningKeys>) {
    params && Object.assign(this, params);
  }
}

export class ArtifactEmittingKeys {
  moduleId = URL_KEY_VALUE_ARTIFACT_ID;
  folderId = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;

  constructor(params?: Partial<ArtifactEmittingKeys>) {
    params && Object.assign(this, params);
  }
}
