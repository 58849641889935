import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { Environment } from '@environments/environment';
import { Attribute } from '../../attribute/types/attribute.types';

export class AttributeListModel {
  constructor(
    public attributes: Attribute[] = [],
    public columns: TableColumn[] = [],
    public rowsPerPage: number = Environment.tableConfig.rowsPerPage,
    public rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
    public applications = new ListContainer<NewApplication>(),
  ) {}
}
