<p-table
  #table
  [(selection)]="selectedAttributes"
  [value]="attributes"
  [selectionMode]="'multiple'"
  [metaKeySelection]="false"
  [scrollable]="true"
  [scrollHeight]="'flex'"
  [dataKey]="'id'"
  [styleClass]="'p-datatable-sm'"
  [globalFilterFields]="['attribute.name']"
  [tableStyle]="{ width: '100%' }"
  (selectionChange)="selectedAttributesChange.emit($event)">
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col class="icon-column"/>
      <col/>
    </colgroup>
  </ng-template>

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between p-3">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          #searchField
          [placeholder]="'Search keyword' | translate"
          pInputText
          type="text"
          (input)="table.filterGlobal($event.target.value, 'contains')"/>
      </span>
      <button
        [label]="'Clear' | translate"
        pButton
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(table, searchField)">
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="body" let-attribute let-rowIndex="rowIndex">
    <tr [pSelectableRow]="attribute" [pSelectableRowIndex]="rowIndex">
      <td><i class="{{ attribute.attribute.icon }}"></i></td>
      <td>{{ attribute.attribute.name }}</td>
    </tr>
  </ng-template>
</p-table>
