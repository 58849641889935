<p-dialog #modal
          [(visible)]="displayModal"
          (onHide)="onClose()"
          [baseZIndex]="2"
          [draggable]="false"
          [modal]="true"
          [resizable]="false"
          [closeOnEscape]="false"
          [contentStyle]="{minHeight: '250px', overflowY: 'scroll'}"
          [style]="{width: '80vw', position: 'absolute', top: '5rem'}"
          [appendTo]="'body'"
          header="Folders">
          <div *ngIf="parentFolder?.id">
              <button (click)="save()" [label]="'Ok' | translate" class="p-button p-button-success" pButton
                      type="button"></button>
              <span class="mx-3">{{parentFolder.label}}</span>
          </div>
          <app-folder-widget *ngIf="displayModal"
                             [isClone]="true"
                             [artifact]="artifact"
                             [settings]="settings"
                             [setLayoutMode]="isLayoutMode"
                             [isShowUserFolder]="showPrivateFolder | isShowFolder"
                             [isShowUserDefaultTeamFolder]="showUserDefaultTeamFolder | isShowFolder"
                             [isShowTenant]="showTenant"
                             [multipleSelection]="multipleSelection"
                             (updateId)="updateFolderId($event)">
          </app-folder-widget>
</p-dialog>
