import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CoreRouterSubscribeComponent } from '@shared/core/components/core-router-subscribe.component';
import { CoreService } from '@shared/core/services/core.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { CoreParams, TranslatableI } from '../types/core.types';

@Directive()
export abstract class CoreComponent<Service extends CoreService<any, Model>, Model>
  extends CoreRouterSubscribeComponent
  implements OnInit, OnDestroy, TranslatableI
{
  s: Service;
  m: Model;

  protected constructor(
    route: ActivatedRoute,
    router: Router,
    public readonly translate: TranslateService,
    model: Model,
    service: Service,
    public readonly announcement: AnnouncementService,
    public readonly coreParams: CoreParams = new CoreParams(),
  ) {
    super(route, router, announcement, coreParams);

    this.m = model;
    this.s = service;

    this.registerSubscription(translate.onLangChange.subscribe((event: LangChangeEvent) => this.onTranslate(event)));
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this, this.m);
    this.onInit();

    super.ngOnInit();
  }

  onInitCb(): void {
    super.onInitCb();

    if (!this.isFirstCall && this.coreParams.initOnQueryParamChange) {
      this.s.init(this, this.m);
      this.onInit();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onTranslate(event: LangChangeEvent): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onDestroy(): void {}
}
