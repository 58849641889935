import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';

@Component({
  selector: 'app-padding-form',
  templateUrl: './padding-form.component.html',
  styleUrls: ['./padding-form.component.scss'],
})
export class PaddingFormComponent {
  @Input() paddingStyles: Padding;
  @Input() title = 'Padding';
  @Input() showTitle = true;

  @Output() changesEmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
}
