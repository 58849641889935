import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { Constants } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ArtifactIdExtractor } from '@widgets/card-widget/types/artifact-id-extractor';
import { ArtifactTypeExtractor } from '@widgets/card-widget/types/artifact-type-extractor';
import { RecordAuthorExtractor } from '@widgets/card-widget/types/record-author-extractor';
import { RecordDateExtractor } from '@widgets/card-widget/types/record-date-extractor';

@Pipe({
  name: 'primaryAttributeValues',
})
export class PrimaryAttributeValuesPipe implements PipeTransform {
  transform(artifact: ArtifactResponseDto, artifactType: NewArtifactType): string {
    return artifactType.primaryAttributes
      .map((attributeId: string) => {
        if (RecordDateExtractor.createdOnAttributeId === attributeId) {
          return artifact.created.on;
        }

        if (RecordDateExtractor.updatedOnAttributeId === attributeId) {
          return artifact.updated.on;
        }

        if (RecordAuthorExtractor.createdByAttributeId === attributeId) {
          return artifact.created.by;
        }

        if (RecordAuthorExtractor.updatedByAttributeId === attributeId) {
          return artifact.updated.by;
        }

        if (ArtifactTypeExtractor.artifactTypeAttributeId === attributeId) {
          return artifactType.name;
        }

        if (ArtifactIdExtractor.artifactIdAttributeId === attributeId || Constants.primaryAttributesDefaultId === attributeId) {
          return artifact.id;
        }

        return artifact.attributes[attributeId]?.value || '';
      })
      .join(' ');
  }
}
