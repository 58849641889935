import { AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  SwissBillAvrInputMapperDto,
  SwissBillAvrType,
  SwissBillOutputType,
} from '@shared/services/artifact-visual-representation/swiss-bill/dto/swiss-bill.input-mapper.dto';

export class SwissBillAvrService extends BaseAvrAbstractService<SwissBillAvrType, SwissBillAvrInputMapperDto> {
  constructor() {
    super(AvrTypes.swissBill as SwissBillAvrType, SwissBillAvrInputMapperDto, 'Swiss Bill', [SwissBillOutputType.svg, SwissBillOutputType.pdf]);
  }
}
