import { ManualTriggerWorkflowSettingsDto } from '@api/models/manual-trigger-workflow-settings-dto';
import { WorkflowTriggerDto } from '@api/models/workflow-trigger-dto';
import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export type ManualTriggerInputData = Record<string, [ManualTriggerSettingFirst, ManualTriggerSettingSecond]>;
export type ManualTriggerInputDataValue = [ManualTriggerSettingFirst, ManualTriggerSettingSecond];

export interface ManualTriggerClientInputData {
  key: string;
  isOptional: boolean;
  isArray: boolean;
}

export enum ManualTriggerSettingFirst {
  array = 'ARRAY',
  string = 'STRING',
}

export enum ManualTriggerSettingSecond {
  optional = 'OPTIONAL',
  required = 'REQUIRED',
}

export enum ManualTriggerKeys {
  inputData = 'inputData',
}

export class WorkflowTriggerManualDto implements ManualTriggerWorkflowSettingsDto {
  inputData: ManualTriggerWorkflowSettingsDto[ManualTriggerKeys.inputData] = { value: {}, isDynamic: false };

  constructor(trigger?: ManualTriggerWorkflowSettingsDto) {
    trigger && Object.assign(this, trigger);
  }
}

export class WorkflowTriggerManual extends AbstractWorkflowTrigger<WorkflowTriggerManualDto> {
  inputDataClientArray: ManualTriggerClientInputData[] = [];

  constructor(dto?: WorkflowTriggerDto) {
    super(WorkflowTriggerManualDto);
    this.type = RuleTriggerType.MANUAL;
    dto && this.fromDto(dto);
  }

  isValid(): boolean {
    return true;
  }

  isMatchingWorkflowTrigger(workflow: WorkflowTrigger): boolean {
    return workflow.type === this.type;
  }

  toServer(): WorkflowTriggerDto {
    const inputDataMap: ManualTriggerInputData = this.inputDataClientArray.reduce(
      (inputDataMap: ManualTriggerInputData, clientInputData: ManualTriggerClientInputData) => {
        return {
          ...inputDataMap,
          [clientInputData.key.replace(/ /g, '_')]: [
            clientInputData.isArray ? ManualTriggerSettingFirst.array : ManualTriggerSettingFirst.string,
            clientInputData.isOptional ? ManualTriggerSettingSecond.optional : ManualTriggerSettingSecond.required,
          ] as ManualTriggerInputDataValue,
        };
      },
      {},
    );

    const triggerInputData = { isDynamic: false, value: inputDataMap };
    const triggerSettings = { ...this.triggerSettings, [ManualTriggerKeys.inputData]: triggerInputData };

    return {
      ...super.toServer(),
      triggerSettings,
    };
  }

  fromDto(dto: WorkflowTriggerDto) {
    dto && Object.assign(this, dto);

    const data = (dto.triggerSettings as ManualTriggerWorkflowSettingsDto).inputData?.value || ({} as ManualTriggerInputData);
    this.inputDataClientArray = Object.entries(data).map(([key, value]) => ({
      key,
      isArray: value[0] === ManualTriggerSettingFirst.array,
      isOptional: value[1] === ManualTriggerSettingSecond.optional,
    }));
  }
}
