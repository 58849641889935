/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { linkTypeControllerCount } from '../fn/tenant-link-type/link-type-controller-count';
import { LinkTypeControllerCount$Params } from '../fn/tenant-link-type/link-type-controller-count';
import { linkTypeControllerCreate } from '../fn/tenant-link-type/link-type-controller-create';
import { LinkTypeControllerCreate$Params } from '../fn/tenant-link-type/link-type-controller-create';
import { linkTypeControllerDelete } from '../fn/tenant-link-type/link-type-controller-delete';
import { LinkTypeControllerDelete$Params } from '../fn/tenant-link-type/link-type-controller-delete';
import { linkTypeControllerGet } from '../fn/tenant-link-type/link-type-controller-get';
import { LinkTypeControllerGet$Params } from '../fn/tenant-link-type/link-type-controller-get';
import { linkTypeControllerList } from '../fn/tenant-link-type/link-type-controller-list';
import { LinkTypeControllerList$Params } from '../fn/tenant-link-type/link-type-controller-list';
import { linkTypeControllerUpdate } from '../fn/tenant-link-type/link-type-controller-update';
import { LinkTypeControllerUpdate$Params } from '../fn/tenant-link-type/link-type-controller-update';
import { LinkTypeListResponseDto } from '../models/link-type-list-response-dto';
import { LinkTypeResponseDto } from '../models/link-type-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantLinkTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `linkTypeControllerCount()` */
  static readonly LinkTypeControllerCountPath = '/api/tenant/link-type/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerCount$Response(params?: LinkTypeControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return linkTypeControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerCount(params?: LinkTypeControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.linkTypeControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `linkTypeControllerGet()` */
  static readonly LinkTypeControllerGetPath = '/api/tenant/link-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerGet$Response(params: LinkTypeControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkTypeResponseDto>> {
    return linkTypeControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerGet(params: LinkTypeControllerGet$Params, context?: HttpContext): Observable<LinkTypeResponseDto> {
    return this.linkTypeControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkTypeResponseDto>): LinkTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `linkTypeControllerDelete()` */
  static readonly LinkTypeControllerDeletePath = '/api/tenant/link-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerDelete$Response(params: LinkTypeControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkTypeResponseDto>> {
    return linkTypeControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerDelete(params: LinkTypeControllerDelete$Params, context?: HttpContext): Observable<LinkTypeResponseDto> {
    return this.linkTypeControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkTypeResponseDto>): LinkTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `linkTypeControllerList()` */
  static readonly LinkTypeControllerListPath = '/api/tenant/link-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerList$Response(params?: LinkTypeControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkTypeListResponseDto>> {
    return linkTypeControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkTypeControllerList(params?: LinkTypeControllerList$Params, context?: HttpContext): Observable<LinkTypeListResponseDto> {
    return this.linkTypeControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkTypeListResponseDto>): LinkTypeListResponseDto => r.body)
    );
  }

  /** Path part for operation `linkTypeControllerUpdate()` */
  static readonly LinkTypeControllerUpdatePath = '/api/tenant/link-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkTypeControllerUpdate$Response(params: LinkTypeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkTypeResponseDto>> {
    return linkTypeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkTypeControllerUpdate(params: LinkTypeControllerUpdate$Params, context?: HttpContext): Observable<LinkTypeResponseDto> {
    return this.linkTypeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkTypeResponseDto>): LinkTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `linkTypeControllerCreate()` */
  static readonly LinkTypeControllerCreatePath = '/api/tenant/link-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkTypeControllerCreate$Response(params: LinkTypeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkTypeResponseDto>> {
    return linkTypeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkTypeControllerCreate(params: LinkTypeControllerCreate$Params, context?: HttpContext): Observable<LinkTypeResponseDto> {
    return this.linkTypeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkTypeResponseDto>): LinkTypeResponseDto => r.body)
    );
  }

}
