import { Directive } from '@angular/core';
import { SwaggerAvrQueryDto } from '@api/models';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';
import { Observable, map, of, tap } from 'rxjs';

@Directive()
export abstract class AbstractAvrTypeService<K extends AvrTypesType> {
  m: AvrWidgetModel;
  s: AvrWidgetService;
  selectedArtifactType: NewArtifactType | null = null;

  abstract getAdditionalQueryParams(): (K extends keyof SwaggerAvrQueryDto ? Record<K, SwaggerAvrQueryDto[K]> : null) | null;

  init$(m: AvrWidgetModel, s: AvrWidgetService): Observable<boolean> {
    if (m.settings.dataSource.artifactTypeId === null) return of(true);
    return of({ m, s }).pipe(
      tap(({ m, s }) => {
        this.m = m;
        this.s = s;
      }),
      tap(
        () =>
          (this.selectedArtifactType = m.options.artifactTypesOptions.find(
            artifactTypeOption => artifactTypeOption.value.id === m.settings.dataSource.artifactTypeId,
          )!.value),
      ),
      map(() => true),
    );
  }
}
