import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
// PrimeNG
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
// Components
import { CurrentTenantComponent } from './current-tenant.component';
import { TenantOslcRootServicesComponent } from './components/tenant-oslc-root-services/tenant-oslc-root-services.component';
import { TenantSettingsComponent } from './components/tenant-settings/tenant-settings.component';
import { TenantSsoComponent } from './components/tenant-sso/tenant-sso.component';

const declarations = [CurrentTenantComponent, TenantOslcRootServicesComponent, TenantSettingsComponent, TenantSsoComponent];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ElvisSharedModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    AccordionModule,
    OverlayPanelModule,
    DividerModule,
    PasswordModule,
    RadioButtonModule,
    DropdownModule,
  ],
  exports: [...declarations],
})
export class CurrentTenantModule {}
