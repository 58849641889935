import { Injectable } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// TODO: rename
export class OptimalLinkService {
  constructor(private readonly tenantLinkService: TenantLinkService) {}

  async getLinksByArtifacts(artifacts: ArtifactResponseDto[]): Promise<LinkResponseDto[]> {
    const filter = this.getFilter(artifacts);
    const links$ = this.tenantLinkService.linkControllerList({ body: { filter } });
    const { data } = await lastValueFrom(links$);

    return data;
  }

  private getFilter(artifacts: ArtifactResponseDto[]): any {
    const $in = artifacts.map(({ id: $oid }: ArtifactResponseDto) => ({ $oid }));

    return JSON.stringify({
      $and: [{ $or: [{ destinationArtifactId: { $in } }, { sourceArtifactId: { $in } }] }, { deleted: { $eq: null } }],
    });
  }
}
