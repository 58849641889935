<div class="form_wrapper">
  <div class="form" *ngIf="dataType">
    <div class="grid mb-4">
      <div class="col-12 md:col-6 lg:col-3">
        <span class="dynamic-label">
            <input [(ngModel)]="dataType.name" id="name" pInputText type="text" [disabled]="dataType.isProtected">
            <label class="dynamic-label-text" for="name" translate>Name</label>
        </span>
      </div>

      <div class="col-12 md:col-6 lg:col-3">
        <span class="dynamic-label">
            <p-dropdown
              [(ngModel)]="dataType.applicationId"
              [autoDisplayFirst]="false"
              [filter]="true"
              [disabled]="dataType.isProtected"
              [options]="applications | transformArrayToSelectOptions : 'name' : 'id'"
              id="application">
            </p-dropdown>
            <label class="dynamic-label-text" for="application" translate>Application</label>
        </span>
      </div>
    </div>

    <span class="dynamic-label">
            <textarea id="description" rows="5" pInputTextarea autoResize="autoResize"
                      [(ngModel)]="dataType.description" [disabled]="dataType.isProtected"></textarea>
            <label class="dynamic-label-text" for="description" translate>Description</label>
        </span>

    <span class="dynamic-label dynamic-radiobutton">
            <span class="dynamic-radiobutton-box" *ngFor="let kind of kindOptions; let kindI = index;">
                <p-radioButton name="kind" [value]="kind" [(ngModel)]="dataType.kind" [inputId]="'kind' + kindI"
                               (ngModelChange)="onKindChange(kind)" [disabled]="!!dataType.id"></p-radioButton>
                <label [for]="'kind' + kindI" translate>{{ kind }}</label>
            </span>
            <label class="dynamic-label-text" translate>Kind of value</label>
        </span>

    <span class="dynamic-label">
            <p-dropdown inputId="baseDataType"
                        (onChange)="onBaseDataTypeChange()"
                        [options]="baseDataTypeOptions"
                        [(ngModel)]="dataType.baseDataType"
                        [autoDisplayFirst]="false"
                        [disabled]="!!dataType.id">
                <ng-template pTemplate="selectedItem" let-selected>
                    {{ selected.label | translate }}
                </ng-template>
                <ng-template let-option pTemplate="item">
                    {{ option.label | translate }}
                </ng-template>
            </p-dropdown>
            <label class="dynamic-label-text" for="baseDataType" translate>Base data type</label>
        </span>

    <app-bounded-range-details [dataType]="dataType"
                               *ngIf="s.isBoundedRange(dataType.kind)"></app-bounded-range-details>

    <app-enumerated-details [dataType]="dataType"
                            *ngIf="(dataType.isEnum && dataType.baseDataType) || dataType.isBoolean">
    </app-enumerated-details>

    <span class="dynamic-label">
            <input type="text" id="uri" pInputText [(ngModel)]="dataType.uri">
            <label class="dynamic-label-text" for="uri" translate>URI</label>
        </span>
  </div>
</div>
