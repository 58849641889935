import { Injectable } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsBoolean, IsDateOrDateTime, IsEnumerated, IsFile, IsHtml, IsHyperlink, IsText, IsUser } from '@shared/methods/data-type.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import {
  DATBooleanEditVariant,
  DATBooleanLayoutVariant,
  DATEnumEditVariant,
  DATEnumLayoutVariant,
  DATFileLayoutVariant,
  DATHtmlLayoutVariant,
  DATHyperlinkLayoutVariant,
  DATLinkLayoutVariant,
  DATSystemDateLayoutVariant,
  DATTextLayoutVariant,
  DATUserLayoutVariant,
  DisplayAtDropdownItem,
  DisplayAttributeType,
} from '@shared/types/display-at-types';

@Injectable({ providedIn: 'root' })
export class DisplayAtUtilService {
  fromAttributeAndDataType(attribute: NewAttribute, dataType: NewDataType): DisplayAttributeType {
    const baseDataType = dataType.baseDataType as BaseDataType;
    if (IsEnumerated(dataType.kind)) {
      return DisplayAttributeType.enumerated;
    }
    if (IsText(baseDataType)) {
      return DisplayAttributeType.text;
    }
    if (IsHyperlink(baseDataType)) {
      return DisplayAttributeType.hyperlink;
    }
    if (IsHtml(baseDataType)) {
      return DisplayAttributeType.html;
    }
    if (IsFile(baseDataType)) {
      return DisplayAttributeType.file;
    }
    if (IsBoolean(baseDataType)) {
      return DisplayAttributeType.boolean;
    }
    if (IsUser(baseDataType)) {
      return DisplayAttributeType.user;
    }
    if (IsDateOrDateTime(baseDataType)) {
      return DisplayAttributeType.dateTime;
    }
    return DisplayAttributeType.simple;
  }

  getDisplayAtEnumObjectForType(displayAttributeType?: DisplayAttributeType): any {
    switch (displayAttributeType) {
      case DisplayAttributeType.enumerated:
        return DATEnumLayoutVariant;
      case DisplayAttributeType.user:
        return DATUserLayoutVariant;
      case DisplayAttributeType.boolean:
        return Object.assign({}, DATBooleanLayoutVariant, DATEnumLayoutVariant);
      case DisplayAttributeType.link:
        return DATLinkLayoutVariant;
      case DisplayAttributeType.html:
        return DATHtmlLayoutVariant;
      case DisplayAttributeType.text:
        return DATTextLayoutVariant;
      case DisplayAttributeType.file:
        return DATFileLayoutVariant;
      case DisplayAttributeType.hyperlink:
        return DATHyperlinkLayoutVariant;
      case DisplayAttributeType.systemDate:
        return DATSystemDateLayoutVariant;
      case DisplayAttributeType.dateTime:
        return DATSystemDateLayoutVariant;
      default:
        return {};
    }
  }

  getDisplayAtEnumEditObjectForType(displayAttributeType?: DisplayAttributeType): any {
    switch (displayAttributeType) {
      case DisplayAttributeType.enumerated:
        return DATEnumEditVariant;
      case DisplayAttributeType.boolean:
        return DATBooleanEditVariant;
      default:
        return {};
    }
  }

  getDisplayAtDropdownItems(atEnumObject: any): DisplayAtDropdownItem[] {
    const keys = Object.keys(atEnumObject);
    return keys.map(key => ({ code: key, label: atEnumObject[key] }));
  }
}
