<div class="dynamic-label">
  <span [ngClass]="{ 'upload-hidden': !isEditMode || files[artifactId] }" class="dynamic-file-upload">
    <p-fileUpload
      #fileUpload
      (uploadHandler)="uploadHandler($event)"
      [auto]="true"
      [customUpload]="true"
      [multiple]="attributesMap[clientAttribute?.id]?.multipleValues"
      class="file-upload-wrapper"
      mode="basic"
      name="files[]"
    >
    </p-fileUpload>
  </span>

  <div class="file-wrapper">
    <div *ngIf="!attributesMap[clientAttribute?.id]?.multipleValues">
      <div [id]="'file-component' + index" class="file-item">
        <ng-container
          *ngIf="clientAttribute | getFilesForArtifact : artifactId : files : unSavedFiles : attributesMap as filesObject">
          <span
            *ngIf="filesObject.files"
            appFileArtifactViewer
            [file]="files[clientAttribute?.value]"
            [ngClass]="{ 'p-text-truncate': truncate }"
            class="downloadable"
          >
            {{ filesObject.files?.formatData.filename }} [{{ filesObject.files.formatData.size | getFileSize }}]
          </span>
          <span *ngIf="filesObject.unSavedFiles"> {{ filesObject.unSavedFiles[0]?.name }}
            [{{ filesObject.unSavedFiles[0]?.size | getFileSize }}]
            <app-upload-progress *ngIf="isInSavingProcess && filesObject.unSavedFiles[0]" [fileName]="filesObject.unSavedFiles[0]?.name"></app-upload-progress>
          </span>

          <div *ngIf="isEditMode && (filesObject.files || filesObject.unSavedFiles)" class="button-box">
            <button (click)="deleteFile()" class="p-button-danger p-button-text utility-button" icon="pi pi-times"
                    pButton type="button"></button>
          </div>
        </ng-container>

        <ng-container *ngIf="files[artifactId]">
          <span
            appFileArtifactViewer
            [file]="files[artifactId]"
            [ngClass]="{ 'p-text-truncate': truncate }"
            class="downloadable"
          >
            {{ files[artifactId].formatData.filename }} [{{ files[artifactId].formatData.size | getFileSize }}]
          </span>

          <!--          <span *ngIf="filesObject.unSavedFiles"> {{ filesObject.unSavedFiles[0]?.name }} [{{ filesObject.unSavedFiles[0]?.size | getFileSize }}] </span>-->

          <!--          <div *ngIf="isEditMode" class="button-box">-->
          <!--            <button (click)="deleteFile()"-->
          <!--                    class="p-button-danger p-button-text utility-button"-->
          <!--                    icon="pi pi-times"-->
          <!--                    pButton type="button">-->
          <!--            </button>-->
          <!--          </div>-->
        </ng-container>
      </div>
    </div>

    <ul *ngIf="attributesMap[clientAttribute?.id]?.multipleValues" [id]="'file-component' + index" class="file-list">
      <ng-container
        *ngIf="clientAttribute | getFilesForArtifact : artifactId : files : unSavedFiles : attributesMap as filesObject">
        <li *ngFor="let fileId of clientAttribute.value; let i = index">
          <div class="file-item">
            <span appFileArtifactViewer [file]="files[fileId]" [ngClass]="{ 'p-text-truncate': truncate }"
                  class="downloadable">
              {{ files[fileId]?.formatData.filename }} [{{ files[fileId]?.formatData.size | getFileSize }}]
            </span>

            <div *ngIf="isEditMode" class="button-box">
              <button (click)="deleteFile(fileId)" class="p-button-danger p-button-text utility-button"
                      icon="pi pi-times" pButton type="button"></button>
            </div>
          </div>
        </li>

        <li *ngFor="let unsavedFile of filesObject.unSavedFiles">
          <div class="file-item">
            {{ unsavedFile.name }} [{{ unsavedFile.size | getFileSize }}]
            <div *ngIf="isEditMode" class="button-box">
              <button
                (click)="deleteFile(null, unsavedFile)"
                class="p-button-danger p-button-text utility-button"
                icon="pi pi-times"
                pButton
                type="button"
              ></button>
            </div>
          </div>
          <app-upload-progress *ngIf="isInSavingProcess && unsavedFile" [fileName]="unsavedFile.name"></app-upload-progress>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
