import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({
  name: 'getWorkflowActionAttributes',
})
export class GetWorkflowActionAttributesPipe implements PipeTransform {
  transform(
    attributes: NewAttribute[],
    dataTypes: ListContainer<NewDataType>,
    supportedDataTypes: BaseDataType[] | null,
    supportedDataKinds: DataTypeKind[] | null,
  ): NewAttribute[] {
    return attributes.filter(attr => {
      const dataType = dataTypes.listMap[attr.dataTypeId];
      if (!supportedDataTypes || !supportedDataKinds) return true;

      return (
        supportedDataTypes &&
        supportedDataTypes.includes(dataType.baseDataType as BaseDataType) &&
        supportedDataKinds &&
        supportedDataKinds.includes(dataType.kind)
      );
    });
  }
}
