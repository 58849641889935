import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// PrimeNG
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';

import { PublicModule } from '@public/public.module';
import { LanguageSwitcherModule } from '@shared/components/language-switcher/language-switcher.component';
import { ConfigComponent } from './components/config/config.component';
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';
// Components
import { ProfileComponent } from './profile.component';
import { UserOslcConnectionsComponent } from '@private/pages/profile/components/user-oslc-connections/user-oslc-connections.component';

const declarations = [ProfileComponent, ResetPasswordDialogComponent, ConfigComponent, UserOslcConnectionsComponent];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    TranslateModule,
    InputTextModule,
    CardModule,
    RadioButtonModule,
    FormsModule,
    InputSwitchModule,
    PublicModule,
    ButtonModule,
    DialogModule,
    PasswordModule,
    MessageModule,
    ReactiveFormsModule,
    LanguageSwitcherModule,
    DropdownModule,
    DividerModule,
    AccordionModule,
  ],
  exports: [...declarations],
})
export class ProfileModule {}
