import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PageResponseDto } from '@api/models/page-response-dto';
import { TenantPageService } from '@api/services/tenant-page.service';
import { Environment } from '@environments/environment';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { ALIAS_KEY, APPLICATION_ID_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewApplication } from '@shared/types/application.types';
import { Exception } from '@shared/types/exception.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { PagesService } from '../../services/pages.service';

@Component({
  selector: 'app-pages-table',
  templateUrl: './pages-table.component.html',
  styleUrls: ['./pages-table.component.scss'],
})
export class PagesTableComponent extends CoreListComponent<any> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<any>>;
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() applications: ListContainer<NewApplication>;
  @Input() selectPages: boolean;

  @Output() onCopy: EventEmitter<string> = new EventEmitter<string>();

  selectedPages: PageResponseDto[] = [];

  rowsPerPage: number = Environment.tableConfig.rowsPerPage;
  rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions;
  page: PageResponseDto;
  APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;
  ALIAS_VALUE = ALIAS_KEY;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly pagesService: PagesService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly tenantPageService: TenantPageService,
    private readonly announcementService: AnnouncementService,
  ) {
    super();
  }

  openPanel(page: PageResponseDto, op: OverlayPanel, event: MouseEvent): void {
    this.page = page;
    op.toggle(event);
  }

  copyPage(pageId: string, op: OverlayPanel): void {
    op.hide();
    this.onCopy.emit(pageId);
  }

  async deletePage(op: OverlayPanel): Promise<void> {
    await this.tenantPageService
      .pageControllerDelete({ id: this.page.id })
      .toPromise()
      .then(() => {
        this.announcementService.success('The page has been successfully deleted');
        this.pagesService.doFilter(this.table);
      })
      .catch(e => {
        console.log(new Exception({ name: 'DeletePageException', message: 'Something went wrong while deleting the page', originalEvent: e }));
        this.announcementService.error('Something went wrong while deleting the page');
      })
      .finally(() => op.hide());
  }

  protected onInit(): void {
    super.onInit();
    this.applicationSwitcherService.selectedApplication$.subscribe(() => {
      this.pagesService.doFilter(this.table);
    });
  }
}
