import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AttributeActionHandlerService, AttributeValueSettable } from '@workflows/shared';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appSetAttributeValue]',
})
export class SetAttributeValueDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  @Input() attributeOwner: AttributeValueSettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.actionAttributeHandler.registerSetAttributeValueEventListener(this.key);
    this.initSubscription = this.actionAttributeHandler.setAttributeValueEventMap$
      .pipe(
        filter(e => !!e.event && this.isMatchingAttributeData(e.event) && this.actionAttributeHandler.containsSetAttributeValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getSetAttributeValueEvent(this.key)),
      )
      .subscribe(setAttributeValueEvent => {
        this.setAttributeValue(setAttributeValueEvent.value);
        this.actionAttributeHandler.removeSetAttributeValueEventFromMap(this.key, false);
      });
  }

  ngOnDestroy(): void {
    this.actionAttributeHandler.unregisterSetAttributeValueEventListener(this.key);
    super.ngOnDestroy();
  }

  private setAttributeValue(newValue: any) {
    this.attributeOwner.setAttributeValue(newValue);
  }
}
