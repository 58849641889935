<div>
  <span>
    When
    <p-dropdown
      class="workflow-element-dropdown"
      styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
      appendTo="body"
      placeholder="{{ 'WORKFLOWS.PLACEHOLDER.TRIGGER' | translate }}"
      [options]="triggerTypes"
      [ngModel]="trigger.type"
      [readonly]="!editable"
      (onChange)="onTriggerTypeChange($event.value)"
    >
      <ng-template let-option pTemplate="item">{{ 'WORKFLOWS.TRIGGER.' + option | translate }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ 'WORKFLOWS.TRIGGER.' + option | translate }}</ng-template>
    </p-dropdown>

    <ng-container *ngIf="(trigger.isArtifactTypeRequired() && !defaultArtifactType) || trigger.isExternalArtifactTypeRequired()">
      <p-dropdown
        class="workflow-element-dropdown"
        styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
        appendTo="body"
        placeholder="{{ 'WORKFLOWS.PLACEHOLDER.ARTIFACT_TYPE' | translate }}"
        [filter]="true"
        filterBy="name"
        optionValue="id"
        [options]="(trigger | isWorkflowLinkTrigger) ? linkableArtifactTypes || artifactTypes : artifactTypes"
        [ngModel]="trigger.artifactTypeId"
        [readonly]="!editable"
        (onChange)="onArtifactTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
      </p-dropdown>
    </ng-container>

    <ng-container *ngIf="trigger.artifactTypeId && trigger.isAttributeRequired()">
      <app-workflow-attribute-selector
        [selectedAttributeId]="trigger.attributeId"
        [attributes]="selectedAttributes"
        (attributeChange)="onAttributeChange($event)"
        [editable]="editable"
      >
      </app-workflow-attribute-selector>
    </ng-container>

    <ng-container *ngIf="trigger.isWidgetRequired()">
      <p-dropdown
        class="workflow-element-dropdown"
        styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
        appendTo="body"
        placeholder="{{ 'WORKFLOWS.PLACEHOLDER.WIDGET_ITEM' | translate }}"
        optionValue="id"
        [options]="pageWidgets"
        [ngModel]="trigger.widgetId"
        [readonly]="!editable"
        (onChange)="onWidgetChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option.code + ' ' + option.id }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option.code + ' ' + option.id }}</ng-template>
      </p-dropdown>
    </ng-container>
  </span>
</div>
