import { ScreenUtil } from '@shared/utils/screen.util';
import { SETTINGS } from '@widgets/sidebar-widget/constants/sidebar-widget.constants';
import { SidebarWidgetWidth } from '@widgets/sidebar-widget/types/sidebar-widget-type.types';
import { SideBarSettings } from './sidebar-widget-settings.types';

export class SideBarStyles {
  width: string;
  height: string;
  backgroundColor: string;
  color: string;
  isBorder = false;
  textAlign = 'left';
  zIndex: number;
  overflow = 'visible';

  boxShadow: string;
  left: string;
  top: string;

  constructor(settings: SideBarSettings) {
    this.update(settings);
  }

  update(settings: SideBarSettings): void {
    if (settings.position == SidebarWidgetWidth.left || settings.position == SidebarWidgetWidth.right) {
      this.height = '100%';
      this.width = this.getWidthValue(settings);
    } else {
      this.width = '100%';
      this.height = this.getWidthValue(settings);
    }
    this.boxShadow = settings.styles?.isBorder ? settings.styles?.boxShadow : 'none';
    this.isBorder = settings.styles ? settings.styles.isBorder : false;
    this.backgroundColor = settings.bgColor;
    this.color = settings.contentColor;
  }

  private getWidthValue(settings: SideBarSettings): string {
    const leftOrRightSidebar = [SidebarWidgetWidth.left, SidebarWidgetWidth.right].some((position: SidebarWidgetWidth) => position === settings.position);

    if (leftOrRightSidebar && ScreenUtil.isSmallScreen()) {
      return SETTINGS.defaultWidthForSmallScreen;
    }

    return settings.width || SETTINGS.defaultWidth;
  }
}
