import { Pipe, PipeTransform } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { WorkflowConditionType } from '@workflows/types';
import { RuleConditionOperationTypeDefinitions } from '@workflows/types/condition-operation-definitions.types';

@Pipe({ name: 'getConditionOperationTypes' })
export class GetConditionOperationTypesPipe implements PipeTransform {
  transform(attributeId: string, attributes: NewAttribute[], dataTypes: ListContainer<NewDataType>, returnAll: boolean): WorkflowConditionType[] {
    if (returnAll) return Object.values(WorkflowConditionType);

    const attribute = attributes.find(attr => attr.id === attributeId);
    if (!attribute) return [];

    const dataType = dataTypes.listMap[attribute.dataTypeId];
    if (!dataType) return [];

    return RuleConditionOperationTypeDefinitions.getOperationTypes(dataType, attribute.multipleValues);
  }
}
