import { Injectable } from '@angular/core';
import { StartEndDate } from '@shared/types/filter.types';
import { clone } from 'lodash';
import {
  ConvertToClientDate,
  ConvertToClientDatetime,
  ConvertToClientTime,
  ConvertToServerDate,
  ConvertToServerDatetime,
  ConvertToServerTime,
  GetEndOfTheDay,
  GetStartOfTheDay,
} from '../methods/date.methods';

@Injectable({ providedIn: 'root' })
export class DateUtil {
  convertToServerDate(date: Date): string {
    return ConvertToServerDate(date);
  }

  convertToServerTime(date: Date): string {
    return ConvertToServerTime(date);
  }

  convertToServerDatetime(date: Date): string {
    return ConvertToServerDatetime(date);
  }

  convertToClientDate(date: Date): string {
    return ConvertToClientDate(date);
  }

  convertToClientTime(date: Date): string {
    return ConvertToClientTime(date);
  }

  convertToClientDatetime(date: Date): string {
    return ConvertToClientDatetime(date);
  }

  getStartEndDateFromDate(date: Date): StartEndDate {
    return { start: GetStartOfTheDay(date), end: GetEndOfTheDay(date) };
  }

  static getEndOfTheDay(value: Date): Date {
    const newDay = clone(value);
    newDay.setHours(23, 59, 59, 999);
    return newDay;
  }
}
