import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss'],
})
export class BackgroundImageComponent {
  @Input() imageUrl: string | undefined;

  @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();

  onBlur(url: string): void {
    this.imageUrl = this.getPreparedUrl(url);
    this.imageUrlChange.emit(this.imageUrl);
  }

  getPath(): string {
    return this.imageUrl ? this.imageUrl.slice(5, -2).replace(/"/g, '') : '';
  }

  private getPreparedUrl(value: string): string {
    return `url('${value}')`;
  }
}
