import { Pipe, PipeTransform } from '@angular/core';
import { TextFilterRuleType } from '../types/text-filter-options.types';

@Pipe({
  name: 'isRegularTextFilterType',
})
export class IsRegularTextFilterTypePipe implements PipeTransform {
  transform(filterType: TextFilterRuleType): boolean {
    const { isEmpty, isNotEmpty } = TextFilterRuleType;
    return filterType !== isNotEmpty && filterType !== isEmpty;
  }
}
