import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Environment } from '@environments/environment';
import { TableColumn } from '@shared/types/table.types';

export class ApplicationListModel {
  exportModalOpened = false;
  importModalOpened = false;
  selectedApplications: ApplicationResponseDto[] = [];
  selectApplications = false;

  constructor(
    public applications: ApplicationResponseDto[] = [],
    public columns: TableColumn[] = [],
    public rowsPerPage: number = Environment.tableConfig.rowsPerPage,
    public rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
    public canUserCreateApplication: boolean = false,
  ) {}
}
