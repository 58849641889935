import { Component, Input } from '@angular/core';
import {
  CREATED_BY_EMAIL_KEY,
  CREATED_BY_LABEL,
  CREATED_ON_LABEL,
  CREATED_ON_KEY,
  FILENAME_LABEL,
  FILENAME_KEY,
  ID_LABEL,
  ID_KEY,
  SIZE_LABEL,
  SIZE_KEY,
  UPDATED_BY_EMAIL_KEY,
  UPDATED_BY_LABEL,
  UPDATED_ON_LABEL,
  UPDATED_ON_KEY,
} from '@shared/constants/constants';
import { LazyLoadEvent } from 'primeng/api';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { NewUser } from '@shared/types/user.types';
import { ObjectUtil } from '@shared/utils/object.util';

@Component({
  selector: 'app-file-list-table',
  templateUrl: './file-list-table.component.html',
  styleUrls: ['./file-list-table.component.scss'],
})
export class FileListTableComponent extends CoreListComponent<any> {
  @Input() setColumnsMethod: (columns?: TableColumn[], selectedColumns?: TableColumn[]) => void;
  @Input() columns: TableColumn[];
  @Input() users: ListContainer<NewUser>;

  constructor(public readonly objectU: ObjectUtil) {
    super();
  }

  onInit(): void {
    super.onInit();
    this.initColumns();
  }

  initColumns(): void {
    const columns = [
      new TableColumn(ID_LABEL, ID_KEY),
      new TableColumn(FILENAME_LABEL, FILENAME_KEY, 'text'),
      new TableColumn(CREATED_BY_LABEL, CREATED_BY_EMAIL_KEY),
      new TableColumn(CREATED_ON_LABEL, CREATED_ON_KEY),
      new TableColumn(UPDATED_BY_LABEL, UPDATED_BY_EMAIL_KEY),
      new TableColumn(UPDATED_ON_LABEL, UPDATED_ON_KEY),
      new TableColumn(SIZE_LABEL, SIZE_KEY, 'numeric'),
    ];
    const selectedColumns = columns.filter(column =>
      [FILENAME_KEY, CREATED_BY_EMAIL_KEY, CREATED_ON_KEY, UPDATED_BY_EMAIL_KEY, UPDATED_ON_KEY].includes(column.key),
    );
    this.setColumnsMethod(columns, selectedColumns);
  }

  async onLazyLoad(event: LazyLoadEvent | null = null): Promise<void> {
    await super.onLazyLoad(event);
    try {
      this.loading = true;

      const userIds = new Set();
      this.data.forEach(file => {
        userIds.add(file.created.by);
        userIds.add(file.updated.by);
      });

      this.data.forEach(file => {
        file.created.by = this.users.listMap[file.created.by];
        file.updated.by = this.users.listMap[file.updated.by];
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
