import { Pipe, PipeTransform } from '@angular/core';
import { ERROR_GETTING_APPLICATION } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getApplicationName' })
export class GetApplicationNamePipe implements PipeTransform {
  transform(applicationId: string, applications: ListContainer<NewApplication>): string {
    return applications.listMap[applicationId]?.name || ERROR_GETTING_APPLICATION;
  }
}
