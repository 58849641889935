import { Injectable } from '@angular/core';
import { LocalStorageMasterState, LocalStorageSessionState, StateKey } from '@shared/types/local-storage.types';
import { TOKEN_KEY } from '../constants/constants';
import { AnnouncementService } from './announcement.service';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private localStorage: Storage;

  constructor(private readonly announcement: AnnouncementService) {
    this.localStorage = window.localStorage;
  }

  get(key: string): any {
    return JSON.parse(this.localStorage.getItem(key) as any);
  }

  set(key: string, value: any): void {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    this.localStorage.removeItem(key);
  }

  getFromState(state: string, key: string): any | null {
    const cashedState = this.get(state);
    if (cashedState) {
      return cashedState[key];
    } else {
      this.announcement.error('{{state}} was not found in the local storage', { state });
      return null;
    }
  }

  setToState(state: string, key: string, value: any): void {
    const sourceState = this.get(state);
    sourceState && this.set(state, { ...sourceState, [key]: value });
  }

  removeFromState(state: string, key: string): void {
    const sourceState = this.get(state);
    sourceState[key] && delete sourceState[key];
    sourceState && this.set(state, { ...sourceState });
  }

  clear(keysYouWantedToKeep: string[] = []): void {
    const relevantData: Record<string, any> = {};

    keysYouWantedToKeep.forEach(key => {
      const data = this.get(key);
      data && (relevantData[key] = data);
    });

    this.localStorage.clear();

    Object.keys(relevantData).forEach(key => this.set(key, relevantData[key]));
  }

  clearRedundantData(keys: string[] = []): void {
    this.clear([...keys, StateKey.session, StateKey.master, StateKey.workflowsAllRules]);
  }

  initialize(): void {
    if (!this.get(StateKey.session) || !this.getFromState(StateKey.session, TOKEN_KEY)) {
      this.set(StateKey.session, new LocalStorageSessionState());
    }
    if (!this.get(StateKey.master)) {
      this.set(StateKey.master, new LocalStorageMasterState());
    }
  }
}
