import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { BaseFolderFilterService } from '@shared/services/filter/filter-types/base-folder-filter.service';
import { BaseTextFilterService } from '@shared/services/filter/filter-types/base-text-filter.service';
import { BaseTypeFilterService } from '@shared/services/filter/filter-types/base-type-filter.service';
import { MongoFilterOperator } from '@shared/types/filter.types';
import { ArtifactFilter } from '../../../types/artifact-filter.types';
import { TextFilterMatchType, TextFilterRuleType } from '../types/text-filter-options.types';
import { TextFilter } from '../types/text-filter.types';

@Injectable({
  providedIn: 'root',
})
export class TextFilterService {
  constructor(private readonly baseFolderFilterService: BaseFolderFilterService, private readonly baseTextFilterService: BaseTextFilterService) {}

  getQuery(filter: ArtifactFilter, isFolderPath?: boolean): any {
    const value: TextFilter = filter.value as TextFilter;
    if (!value) {
      return null;
    }

    const sign = value.matchType === TextFilterMatchType.matchAny ? MongoFilterOperator.OR : MongoFilterOperator.AND;
    const ruleTypes = value.ruleTypes?.filter(rule => rule.ruleType && (rule.value || this.isEmptyTypeFilter(rule.ruleType)));
    const filterService: BaseTypeFilterService = isFolderPath ? this.baseFolderFilterService : this.baseTextFilterService;

    return ruleTypes?.length
      ? {
          [sign]: [
            ...ruleTypes.map(rule =>
              filterService.getQuery(
                filter.attributeId,
                filter.dataType,
                rule.value,
                this.getFilterMatchMode(rule.ruleType),
                this.isEmptyTypeFilter(rule.ruleType),
              ),
            ),
          ],
        }
      : null;
  }

  getFilterValueFromString(str: string): TextFilter | null {
    const filter = new TextFilter();
    filter.ruleTypes[0] = { ruleType: TextFilterRuleType.contains, value: str };
    return filter;
  }

  private isEmptyTypeFilter(ruleType: TextFilterRuleType): boolean {
    return ruleType === TextFilterRuleType.isEmpty || ruleType === TextFilterRuleType.isNotEmpty;
  }

  private getFilterMatchMode(textFilterRuleType: TextFilterRuleType): CoreListFilterEnum {
    switch (textFilterRuleType) {
      case TextFilterRuleType.equals:
        return CoreListFilterEnum.equals;
      case TextFilterRuleType.notEquals:
        return CoreListFilterEnum.notEquals;
      case TextFilterRuleType.startsWith:
        return CoreListFilterEnum.startsWith;
      case TextFilterRuleType.endsWith:
        return CoreListFilterEnum.endsWith;
      case TextFilterRuleType.contains:
        return CoreListFilterEnum.contains;
      case TextFilterRuleType.notContains:
        return CoreListFilterEnum.notContains;
      case TextFilterRuleType.isEmpty:
        return CoreListFilterEnum.isEmpty;
      case TextFilterRuleType.isNotEmpty:
        return CoreListFilterEnum.isNotEmpty;
      default:
        return CoreListFilterEnum.contains;
    }
  }
}
