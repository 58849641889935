import { Artifact, LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ChartTotalPosition, ChartWidgetOptions } from './chart-widget-options.types';
import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';

export class ChartWidgetSelected {
  artifactType: SelectOption<string, NewArtifactType>;
  linkTypes: SelectOption<string, LinkType, LinkDirection>[] = [];
  groupByAttribute: SelectOption<string, NewAttribute>;
  totalByAttribute: SelectOption<string, NewAttribute> | null = null;
  artifact: Artifact | null = null;
  attributesFilter: ArtifactFilter[] = [];
  chartTotalPosition: ChartTotalPosition = ChartTotalPosition.bottom;
  prefix: string;
  postfix: string;
  fitMode: FitMode = FitMode.horizontal;
  title: string;
  titleStyles = new FontStyles();
  counterStyles = new FontStyles();
  dto: ChartWidgetSelectedDto;

  constructor(dto: ChartWidgetSelectedDto | null = null) {
    // wait for fetch options then update
    dto && (this.dto = dto);
  }

  toServer(): ChartWidgetSelectedDto {
    const dto: ChartWidgetSelectedDto = {
      artifactType: { id: this.artifactType?.value.id },
      linkTypes: this.linkTypes.map(lt => ({ id: lt.value.id, meta: lt.meta })),
      groupByAttributeId: this.groupByAttribute?.value.id,
      totalByAttributeId: this.totalByAttribute?.value.id || null,
      attributesFilter: this.attributesFilter.filter(filter => filter.attribute && (filter.dataType || filter.type)).map(filter => filter.toServer()),
      prefix: this.prefix,
      postfix: this.postfix,
      fitMode: this.fitMode,
      title: this.title,
      counterStyles: this.counterStyles,
    };

    this.chartTotalPosition && (dto.chartTotalPosition = this.chartTotalPosition);
    this.title && (dto.titleStyles = this.titleStyles);

    return dto;
  }

  fromDto(options: ChartWidgetOptions): void {
    if (!this.dto) {
      return;
    }

    const { chartTotalPosition, prefix, postfix, fitMode, title, titleStyles, counterStyles } = this.dto;

    chartTotalPosition && (this.chartTotalPosition = chartTotalPosition);
    prefix && (this.prefix = prefix);
    postfix && (this.postfix = postfix);
    fitMode && (this.fitMode = fitMode);
    counterStyles && (this.counterStyles = counterStyles);
    titleStyles && (this.titleStyles = titleStyles);
    title && (this.title = title);

    if (this.dto.artifactType?.id && options.artifactTypes?.list.length) {
      const at = options.artifactTypes.listMap[this.dto.artifactType.id];
      at && (this.artifactType = new SelectOption<string, NewArtifactType>(at.name, at));
    }

    if (!this.groupByAttribute && this.dto.groupByAttributeId) {
      options.attributes.forEach(attr => {
        if (attr.id === this.dto.groupByAttributeId) {
          this.groupByAttribute = new SelectOption<string, NewAttribute>(attr.name, attr);
        }
      });

      // for system attribute
      if (!this.groupByAttribute) {
        options.systemAttributes.forEach(attr => {
          if (attr.id === this.dto.groupByAttributeId) {
            this.groupByAttribute = new SelectOption<string, NewAttribute>(attr.name, attr);
          }
        });
      }
    }

    if (!this.totalByAttribute && this.dto.totalByAttributeId) {
      options.attributes.forEach(attr => {
        if (attr.id === this.dto.totalByAttributeId) {
          this.totalByAttribute = new SelectOption<string, NewAttribute>(attr.name, attr);
        }
      });
    }

    if (options.dataTypes.list.length && options.attributes.length) {
      !this.attributesFilter.length &&
        (this.attributesFilter =
          this.dto.attributesFilter?.map(filter =>
            ArtifactFilter.fromDtoAndOptions(filter, {
              attributes: options.attributes,
              dataTypes: options.dataTypes.list,
              linkTypes: options.linkTypes.list,
            }),
          ) || []);
    }
  }
}

export interface ChartWidgetSelectedDto {
  artifactType: { id: string };
  linkTypes: { id: string; meta: LinkDirection }[];
  groupByAttributeId: string;
  totalByAttributeId: string | null;
  attributesFilter: ArtifactFilterDto[];
  chartTotalPosition?: ChartTotalPosition;
  prefix?: string;
  postfix?: string;
  fitMode?: FitMode;
  title?: string;
  titleStyles?: FontStyles;
  counterStyles?: FontStyles;
}

export enum FitMode {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
