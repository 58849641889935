import { Environment } from '@environments/environment';
import { CachedUserMeta } from '@shared/types/new-cached-subject.types';
import { TableColumn } from '@shared/types/table.types';

export class UserListModel {
  loggedUserMeta: CachedUserMeta | undefined;
  columns: TableColumn[] = [];
  rowsPerPage: number = Environment.tableConfig.rowsPerPage;
  rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions;

  constructor(model?: Partial<UserListModel>) {
    model && Object.assign(this, model);
  }
}
