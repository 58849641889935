<div class="chunk" *ngIf="selected">
    <p-dropdown
            *ngIf="isShowAddRule"
            (onChange)="onChange()"
            [(ngModel)]="selected.matchType"
            [options]="options.matchType.list | transformArrayToSelectOptions"
    ></p-dropdown>
    <ng-container *ngFor="let rule of selected.ruleTypes; let i = index">
        <p-dropdown
                (onChange)="onRuleTypeChange($event.value, i)"
                [(ngModel)]="rule.ruleType"
                [options]="options.ruleType.list | transformArrayToSelectOptions"
                [appendTo]="'body'"
        ></p-dropdown>

        <div *ngIf="rule.ruleType | isNumberFilterRangeType" class="row">
            <p-inputNumber
                [(ngModel)]="rule.value.from"
                [ngClass]="'col-8 input-number'"
                [placeholder]="placeholder"
                [minFractionDigits]="0"
                [maxFractionDigits]="5"
                (onBlur)="onChange()"
            ></p-inputNumber>
            <div class="col-4">
                <span class="dynamic-label dynamic-checkbox">
                    <span class="dynamic-checkbox-box">
                        <p-checkbox
                            [(ngModel)]="rule.value.fromIsInclude"
                            (onChange)="onChange()"
                            binary="true"
                            inputId="fromIsInclude"
                        ></p-checkbox>
                        <label for="fromIsInclude" translate>Include</label>
                    </span>
                </span>
            </div>

            <p-inputNumber
                [(ngModel)]="rule.value.to"
                [ngClass]="'col-8'"
                [placeholder]="placeholder"
                [minFractionDigits]="0"
                [maxFractionDigits]="5"
                (onBlur)="onChange()"
            ></p-inputNumber>
            <div class="col-4">
                <span class="dynamic-label dynamic-checkbox">
                    <span class="dynamic-checkbox-box">
                        <p-checkbox
                            [(ngModel)]="rule.value.toIsInclude"
                            (onChange)="onChange()"
                            binary="true"
                            inputId="toIsInclude"
                        ></p-checkbox>
                        <label for="toIsInclude" translate>Include</label>
                    </span>
                </span>
            </div>

        </div>

        <div *ngIf="rule.ruleType | isRegularNumberFilterType" class="row">
            <p-inputNumber
                [(ngModel)]="rule.value"
                [minFractionDigits]="0"
                [maxFractionDigits]="5"
                (onBlur)="onChange()"
                mode="decimal"
            ></p-inputNumber>
        </div>

        <p *ngIf="selected?.ruleTypes.length > 1" class="rule rule-rm" (click)="removeRule(i)">Remove rule</p>
    </ng-container>

    <div *ngIf="applyIsEnable" class="btn-container">
        <p-button label="Apply" (onClick)="apply()"></p-button>
        <button pButton label="Clear" class="p-button-warning" type="button" (click)="clear()"></button>
    </div>

</div>
<p *ngIf="isShowAddRule && selected?.ruleTypes?.length < 2" class="rule" (click)="addRule()">+ Add rule</p>
