import { ListContainer } from '@shared/types/list-container.types';
import { ApexAxisPosition, ApexChartWidgetTypeChart, ApexUserDisplay } from './apex-chart-widget.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { UserResponseDto } from '@api/models/user-response-dto';
import { DateFormatEnum } from '@widgets/shared/types/date-format.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';

export class ApexChartWidgetOptions {
  chartTypes = new ListContainer<ApexChartWidgetTypeChart>();
  artifactTypes = new ListContainer<NewArtifactType>();
  linkTypes = new ListContainer<DirectionalLinkType>();
  attributes: NewAttribute[] = [];
  dataTypes = new ListContainer<NewDataType>();
  users = new ListContainer<UserResponseDto>();
  systemAttributes: NewAttribute[] = [];
  dateFormatOptions = new ListContainer<DateFormatEnum>();
  userDisplayOptions = new ListContainer<ApexUserDisplay>();
  usersData: Record<string, ApexUserData> = {};
  gridPosition = new ListContainer<ApexGridPosition>();
  axisPosition = new ListContainer<ApexAxisPosition>();
  axisNames: string[] = [];
  commonAttributes: NewAttribute[] = [];
  filteredAttributes: NewAttribute[] = [];

  constructor() {
    this.chartTypes.setList(Object.keys(ApexChartWidgetTypeChart).map(key => ApexChartWidgetTypeChart[key as keyof typeof ApexChartWidgetTypeChart]));
    this.dateFormatOptions.setList(Object.keys(DateFormatEnum).map(key => DateFormatEnum[key as keyof typeof DateFormatEnum]));
    this.userDisplayOptions.setList(Object.keys(ApexUserDisplay).map(key => ApexUserDisplay[key as keyof typeof ApexUserDisplay]));
    this.axisPosition.setList(Object.keys(ApexAxisPosition).map(key => ApexAxisPosition[key as keyof typeof ApexAxisPosition]));
    this.gridPosition.setList(Object.keys(ApexGridPosition).map(key => ApexGridPosition[key as keyof typeof ApexGridPosition]));
  }
}

export interface ApexUserData {
  email: string | string[];
  name: string | string[];
}

export enum ApexGridPosition {
  front = 'front',
  back = 'back',
}
