import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkPrimaryAttributesToStringPipe } from './link-primary-attributes-to-string.pipe';

const pipes = [LinkPrimaryAttributesToStringPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class AttributePipesModule {}
