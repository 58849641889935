import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';

@Pipe({ name: 'isFilterCalendarType' })
export class IsFilterCalendarTypePipe implements PipeTransform {
  transform(filterType: DateRangeFilterEnum | DateFilterEnum): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateBeforeOrEqualTo, dateAfterOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateBeforeOrEqualTo, dateAfterOrEqualTo].includes(filterType as DateFilterEnum);
  }
}
