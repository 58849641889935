import { Injectable } from '@angular/core';
import { FilterType, NewTableColumn } from '@shared/types/table.types';
import { Observable, Subject } from 'rxjs';
import { TableFilterEvent, TableFilterOperationType } from './table-filter-types';

@Injectable()
export class TableFilterControlService {
  private filterUpdateSubject: Subject<TableFilterEvent> = new Subject();
  private filterUpdate$: Observable<TableFilterEvent> = this.filterUpdateSubject.asObservable();

  getFilterUpdate$(): Observable<TableFilterEvent> {
    return this.filterUpdate$;
  }

  doNotifyFilterUpdateChange(ownerId: string, filterType: FilterType, operationType: TableFilterOperationType, column: NewTableColumn, payload?: any) {
    const tableFilterEvent: TableFilterEvent = { ownerId, filterType, operationType, column, payload };
    this.filterUpdateSubject.next(tableFilterEvent);
  }
}
