<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div *ngIf="m.dataType">
      <div class="p-inputgroup">
        <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
                <p-checkbox binary="true" id="isDeleted" [(ngModel)]="m.isDeleted" [disabled]="true"></p-checkbox>
                <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate}}</label>
            </span>
        </span>
      </div>
    </div>

    <div>
      <button *ngIf="m.dataType && m.dataType.id && !m.dataType.deleted"
              [label]="'Delete' | translate"
              (click)="s.deleteWithConfirmation(m.dataType)"
              class="p-button p-button-danger" pButton>
      </button>
      <button pButton pRipple type="button" label="{{'Cancel' | translate}}" (click)="s.cancel()" class="p-button p-button-warning"></button>
      <button pButton pRipple type="button" label="{{'Save' | translate}}" (click)="s.save()" class="p-button p-button-success"></button>
    </div>
  </div>
  <div class="col-12 data-type-form">
    <app-data-type-form [applications]="m.applications.list"
                        [baseDataTypeOptions]="m.baseDataTypeOptions"
                        [dataType]="m.dataType"
                        [onBaseDataTypeChange]="s.onBaseDataTypeChange.bind(this.s)"
                        [onKindChange]="s.onKindChange.bind(this.s)">
    </app-data-type-form>
  </div>
</div>


