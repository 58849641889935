<p-table
  #table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
  [filters]="{ artifactTypeId: [{ value: [artifactType.id], matchMode: 'in', operator: 'and' }] }"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon *ngIf="col.key !== 'id'" [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
      </th>
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-artifact let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <div class="p-text-truncate">
          <span *ngIf="col.key === 'id'" class="clickable">
            <a [routerLink]="['/admin/artifact', artifact.id]">
              {{ artifact | displayArtifactValueToTable : col.key : attributes.listMap : dataTypes.listMap : users.listMap }}
            </a>
          </span>
          <span *ngIf="col.key !== 'id'" class="truncate">
            {{ artifact | displayArtifactValueToTable : col.key : attributes.listMap : dataTypes.listMap : users.listMap }}
          </span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/admin/artifact', artifact.id]">
            <button pButton type="button" class="p-button-warning p-button" label="{{ 'Edit' | translate }}" icon="pi pi-pencil" iconPos="left"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
