import { Injectable } from '@angular/core';
import { TreeNode } from '@private/components/type-system-element-dropdown/tree-node';
import { TypeSystemElement } from '@private/components/type-system-element-dropdown/type-system-element';
import { INACCESSIBLE_APPLICATION_NAME } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';

const otherApplicationsGroupName = 'Other Applications';
const otherApplicationsGroupKey = 'otherApplicationsGroupKey';

const isSystem = ({ isProtected, name }: NewApplication): boolean => {
  return isProtected && name.toLowerCase() === 'system';
};

@Injectable()
export class TypeSystemElementsDropdownService {
  getTreeNodes(
    applications: NewApplication[],
    selectedApplication: NewApplication,
    elements: TypeSystemElement[],
    disabledElementIds: Set<string>,
  ): TreeNode[] {
    const systemApplication = applications.find(isSystem);
    const systemApplicationNode = systemApplication ? this.buildApplicationNode(systemApplication, elements, disabledElementIds) : null;

    const selectedApplicationNode =
      systemApplicationNode?.key !== selectedApplication.id ? this.buildApplicationNode(selectedApplication, elements, disabledElementIds) : null;

    const otherApplications = applications.filter(({ id }: NewApplication) => id !== selectedApplication.id && id !== systemApplicationNode?.key);
    const otherApplicationElements = elements.filter(
      ({ applicationId }: TypeSystemElement) => applicationId !== selectedApplication.id && applicationId !== systemApplicationNode?.key,
    );
    const otherApplicationsNode = this.buildOtherApplicationsNode(otherApplications, otherApplicationElements, disabledElementIds);

    return [selectedApplicationNode, systemApplicationNode, otherApplicationsNode].filter(Boolean) as TreeNode[];
  }

  findNodeByKey(node: TreeNode, key: string): TreeNode | null {
    if (node.key === key) {
      return node;
    }

    for (const childNode of node.children || []) {
      const foundNode = this.findNodeByKey(childNode, key);

      if (foundNode) {
        return foundNode;
      }
    }

    return null;
  }

  private buildApplicationNode(application: NewApplication, elements: TypeSystemElement[], disabledElementIds: Set<string>): TreeNode {
    const children = elements.reduce((nodes: TreeNode[], child: TypeSystemElement) => {
      if (child.applicationId !== application.id) {
        return nodes;
      }

      const selectable = !disabledElementIds.has(child.id);
      const node: TreeNode = {
        data: child,
        label: child.name,
        longLabel: `${child.name} (${application.name})`,
        key: child.id,
        selectable: selectable,
        styleClass: `${selectable ? '' : 'p-disabled'} ${child.styleClass || ''}`,
      };

      return [...nodes, node];
    }, []);

    return {
      label: application.name,
      children: children,
      selectable: false,
      styleClass: 'group-node',
      expanded: true,
      key: application.id,
    };
  }

  private buildOtherApplicationsNode(applications: NewApplication[], elements: TypeSystemElement[], disabledElementIds: Set<string>): TreeNode {
    const children = elements.reduce((nodes: TreeNode[], child: TypeSystemElement) => {
      const application = applications.find(({ id }: NewApplication) => id === child.applicationId);
      const label = `${child.name} ${application ? `(${application.name})` : INACCESSIBLE_APPLICATION_NAME}`;
      const selectable = !disabledElementIds.has(child.id);
      const node: TreeNode = {
        label: label,
        longLabel: label,
        data: child,
        key: child.id,
        selectable: selectable,
        styleClass: `${selectable ? '' : 'p-disabled'} ${child.styleClass || ''}`,
      };

      return [...nodes, node];
    }, []);

    return {
      label: otherApplicationsGroupName,
      key: otherApplicationsGroupKey,
      children: children,
      selectable: false,
      styleClass: 'group-node',
    };
  }
}
