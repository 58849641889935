import {
  ArtifactAttributeRequestDto,
  ArtifactAttributeResponseDto,
  ArtifactCreateRequestDto,
  ArtifactFormatFileDataResponseDto,
  ArtifactFormatModuleDataResponseDto,
  ArtifactModuleResponseDto,
  ArtifactResponseDto,
  ArtifactStampResponseDto,
  ArtifactUpdateRequestDto,
  ArtifactUploadRequestDto,
  FolderDataResponseDto,
  RecordDto,
} from '@api/models';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewArtifactType } from './artifact-type.types';
import { NewAttribute, NewClientAttribute } from './attribute.types';
import { NewLink } from './link.types';

// todo check and fix whole app format/type and typeData/fileData
export class NewArtifact implements ArtifactResponseDto {
  id = '';
  artifactTypeId: string;
  parentFolderId: string;
  folderPath: string;
  attributes: Record<string, NewClientAttribute> = {};
  clientAttributes: NewClientAttribute[] = [];
  pathRank: number;
  sequence: number;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;
  folderData: FolderDataResponseDto;
  format: 'TEXT' | 'FILE' | 'MODULE';
  typeData: { [key: string]: ArtifactAttributeResponseDto } | null;
  formatData: ArtifactFormatModuleDataResponseDto | (ArtifactFormatFileDataResponseDto & { file: File });
  moduleData: null | ArtifactModuleResponseDto;
  importStamp: null | ArtifactStampResponseDto;

  constructor(args?: NewArtifactArguments) {
    if (args) {
      const { dto, artifactTypesMap } = args;
      this.id = dto.id;
      this.artifactTypeId = dto.artifactTypeId;
      this.parentFolderId = dto?.folderData.parentId || artifactTypesMap[dto.artifactTypeId].defaultFolderId;
      this.folderPath = dto.folderData.path || '';
      this.folderData = dto?.folderData;
      this.format = dto?.format;
      this.formatData = dto.formatData as any;
      this.moduleData = dto.moduleData;
      this.created = dto.created;
      this.updated = dto.updated;
      this.deleted = dto.deleted;
      this.importStamp = dto.importStamp;

      const artifactTypeAttributes = artifactTypesMap[dto.artifactTypeId].attributes;

      for (const attributeId in artifactTypeAttributes) {
        const attribute = new NewClientAttribute({
          id: attributeId,
          value: dto.attributes[attributeId]?.value || null,
          isMandatory: artifactTypeAttributes[attributeId].isMandatory,
        });
        this.attributes[attributeId] = attribute;
        this.clientAttributes.push(attribute);
      }
    }
  }

  static getNewInstance(artifact: NewArtifact): NewArtifact {
    const result = new NewArtifact();
    Object.assign(result, artifact);
    return result;
  }

  toCreateDto(allAttributes: ListContainer<NewAttribute>, allDataTypes: ListContainer<NewDataType>): ArtifactCreateRequestDto {
    return {
      artifactTypeId: this.artifactTypeId,
      parentFolderId: this.parentFolderId,
      attributes: this.formatAttributesToServer(allAttributes, allDataTypes),
    };
  }

  toUploadDto(allAttributes: ListContainer<NewAttribute>, allDataTypes: ListContainer<NewDataType>): ArtifactUploadRequestDto {
    return {
      artifactTypeId: this.artifactTypeId,
      parentFolderId: this.parentFolderId,
      attributes: this.formatAttributesToServer(allAttributes, allDataTypes),
      file: (this.formatData as { file: File }).file,
    };
  }

  toUpdateDto(allAttributes: ListContainer<NewAttribute>, allDataTypes: ListContainer<NewDataType>): ArtifactUpdateRequestDto {
    return {
      id: this.id,
      attributes: this.formatAttributesToServer(allAttributes, allDataTypes),
    };
  }

  private formatAttributesToServer(allAttributes: ListContainer<NewAttribute>, allDataTypes: ListContainer<NewDataType>): Record<string, any> {
    const attributes: Record<string, ArtifactAttributeRequestDto> = {};
    this.clientAttributes.forEach(clientAttribute => {
      const attribute = GetAttributeFromClientAttribute(clientAttribute, allAttributes.listMap);
      const dataType = GetDataTypeFromClientAttribute(clientAttribute, allAttributes.listMap, allDataTypes.listMap);
      attributes[clientAttribute.id] = { value: AttributeValueToServer(dataType, attribute, clientAttribute.value) };
    });
    return attributes;
  }
}

export interface NewArtifactArguments {
  dto: ArtifactResponseDto;
  artifactTypesMap: Record<string, NewArtifactType>;
}

export interface NewArtifactLinks {
  [LinkDirection.outgoing]: NewLink[];
  [LinkDirection.incoming]: NewLink[];
}

/**
 * Data structure combining link and its appertaining linked artifact.
 */
export interface ArtifactLinkPair {
  artifact: NewArtifact;
  link: NewLink;
}
