import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';

export class UnknownAttributeExtractor implements ArtifactValueExtractor {
  constructor(artifact: ArtifactResponseDto, attributeId: string) {
    console.error(`Algorithm for extraction of attribute "${attributeId}" from artifact is not defined. Artifact: `, artifact);
  }

  getValue(): any {
    return '';
  }
}
