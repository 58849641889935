<div (cdkDropListDropped)="dropBlockPart($event)"
     [cdkDropListAutoScrollStep]="3"
     [cdkDropListConnectedTo]="(dragDropService.blockIds$ | async)?.flat"
     [cdkDropListData]="{ parts: block.parts, sectionIndex: location.sectionIndex, rowIndex: rowIndex, blockIndex: blockIndex, modalId: modalId }"
     [cdkDropListEnterPredicate]="dragDropService.dropWidgetIntoBlockPredicate"
     [cdkDropListSortPredicate]="dragDropService.dropWidgetBelowPredicate"
     [id]="(dragDropService.blockIds$ | async)?.tree[sectionIndex][rowIndex][blockIndex]"
     cdkDropList>
  <app-page-block-part
    *ngFor="let part of block.parts; index as partIndex"
    [blockIndex]="blockIndex"
    [partIndex]="partIndex"
    [part]="part"
    [page]="page"
    [modalId]="modalId"
    [rowIndex]="rowIndex"
    [sectionIndex]="sectionIndex">
  </app-page-block-part>
</div>
