import { Pipe, PipeTransform } from '@angular/core';
import { RuleUtilsService } from '@workflows/services';
import { WorkflowTrigger } from '@workflows/types';

@Pipe({
  name: 'isWorkflowLinkTrigger',
})
export class IsWorkflowLinkTrigger implements PipeTransform {
  constructor(private ruleUtilsService: RuleUtilsService) {}

  transform(trigger: WorkflowTrigger) {
    return this.ruleUtilsService.isLinkSpecificTrigger(trigger);
  }
}
