import { Border, BoxShadow } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { FontStyles, IconStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { SETTINGS } from '../constants/sidebar-widget.constants';
import { SidebarWidgetWidth, WidthType } from './sidebar-widget-type.types';

export class ArrowBtnSetting {
  display: boolean;
  position: SidebarWidgetWidth = SidebarWidgetWidth.right;
  class: string | null;
  icon: string | null;
  isRotate: boolean;
  padding: number = SETTINGS.defaultArrowButtonPadding;
  buttonBackgroundColor = SETTINGS.defaultArrowButtonBackgroundColor;
  buttonInnerColor = SETTINGS.defaultArrowButtonContentColor;
  type: WidthType = WidthType.percent;
  styles: Record<string, any> | null;
  commonBorderStyles: Record<string, any> | null = {};
  iconStyles: Record<string, any> | null;
  range = { min: 0, max: 100 };
  isFullLength: boolean;
  buttonWide = SETTINGS.defaultArrowButtonWidth;
  buttonContent: string = SETTINGS.defaultArrowButtonContent;
  buttonView = new SideBarArrowButtonView();

  private lastType: WidthType = WidthType.percent;

  private sign = {
    [WidthType.pixel]: 'px',
    [WidthType.percent]: '%',
  };

  private paddingMap = {
    [SidebarWidgetWidth.left]: 'top',
    [SidebarWidgetWidth.right]: 'top',
    [SidebarWidgetWidth.top]: 'left',
    [SidebarWidgetWidth.bottom]: 'left',
  };

  private positionMap = {
    [SidebarWidgetWidth.left]: 'left',
    [SidebarWidgetWidth.right]: 'right',
    [SidebarWidgetWidth.top]: 'top',
    [SidebarWidgetWidth.bottom]: 'bottom',
  };

  constructor(dto?: SideBarArrowButtonSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): SideBarArrowButtonSettingsDto {
    return new SideBarArrowButtonSettingsDto(this);
  }

  fromDto(dto: SideBarArrowButtonSettingsDto): void {
    const { padding, type, position, buttonBackgroundColor, buttonInnerColor, isFullLength, buttonWide, buttonView, isRotate, commonBorderStyles, display } =
      dto;
    Object.assign(this, {
      padding,
      type,
      position,
      buttonBackgroundColor,
      buttonInnerColor,
      isFullLength,
      buttonWide,
      buttonView,
      isRotate,
      commonBorderStyles,
      display,
    });
    this.lastType = type;
    // todo think about undefined from dto(old created sidebars haven't this settings) and refactoring
    !commonBorderStyles && (this.commonBorderStyles = {});
    Object.values(this.buttonView).forEach(v => {
      !v.contentStyle && (v.contentStyle = new FontStyles());
      !v.iconStyle && (v.iconStyle = new IconStyles());
    });

    this.update(position);
    setTimeout(() => {
      this.changeButtonContent(false);
    }, 10);
  }

  changeButtonContent(isHoverIn?: boolean, isDisplaySidebar?: boolean): void {
    const property = isDisplaySidebar ? (isHoverIn ? 'openHover' : 'openNoHover') : isHoverIn ? 'closeHover' : 'closeNoHover';
    const view: ButtonView = this.buttonView[property];

    this.buttonContent = view.text ? view.text : SETTINGS.defaultArrowButtonContent;
    this.icon = view.icon;
    this.iconStyles = view.iconStyle;
    this.styles = Object.assign({}, this.styles, this.commonBorderStyles, view.contentStyle);
  }

  update(position: SidebarWidgetWidth, sidebarWidth?: string | null): void {
    this.position = position;
    this.setRange(position);
    this.class = `arrow-button-${position}`;

    this.styles = {
      [this.paddingMap[position]]: this.isFullLength ? '0' : `${this.padding}${this.sign[this.type]}`,
      [this.positionMap[position]]: sidebarWidth ? sidebarWidth : '0',
    };

    this.isFullLength && this.setSizeParameters();
  }

  private setSizeParameters(): void {
    const param = this.position === SidebarWidgetWidth.left || this.position === SidebarWidgetWidth.right ? 'height' : 'width';
    const wideParam = param === 'height' ? 'width' : 'height';
    if (this.styles) {
      this.styles[param] = '100%';
      this.styles[wideParam] = this.buttonWide;
    }
    this.class += ' no-round';
  }

  private setRange(position: SidebarWidgetWidth): void {
    const isNeedCorrection = this.lastType !== this.type;

    if (this.type === WidthType.percent) {
      this.range.max = 100;
      isNeedCorrection && (this.padding = Math.round(this.padding / (this.getLength(position) / 100)));
    } else {
      this.range.max = this.getLength(position);
      isNeedCorrection && (this.padding = Math.round(this.padding * (this.getLength(position) / 100)));
    }

    this.lastType = this.type;
  }

  private getLength(position: SidebarWidgetWidth): number {
    return position === SidebarWidgetWidth.left || position === SidebarWidgetWidth.right ? window.innerHeight : window.innerWidth;
  }
}

export class SideBarArrowButtonSettingsDto {
  display: boolean;
  position: SidebarWidgetWidth;
  padding: number;
  type: WidthType;
  buttonBackgroundColor: string;
  buttonInnerColor: string;
  isFullLength: boolean;
  isRotate: boolean;
  buttonWide = SETTINGS.defaultArrowButtonWidth;
  buttonView: SideBarArrowButtonView;
  commonBorderStyles: Record<string, any> | null = {};

  constructor(settings: ArrowBtnSetting) {
    const { padding, type, position, buttonBackgroundColor, buttonInnerColor, isFullLength, buttonWide, buttonView, isRotate, commonBorderStyles, display } =
      settings;

    Object.keys(settings.buttonView).forEach(key => {
      !(settings.buttonView as any)[key].icon && ((settings.buttonView as any)[key].icon = '');
    });

    Object.assign(this, {
      padding,
      type,
      position,
      buttonBackgroundColor,
      buttonInnerColor,
      isFullLength,
      buttonWide,
      buttonView,
      isRotate,
      commonBorderStyles,
      display,
    });
  }
}

export class SideBarArrowButtonView {
  closeNoHover = new ButtonView();
  closeHover = new ButtonView();
  openNoHover = new ButtonView();
  openHover = new ButtonView();
}

export class ButtonView {
  text: string;
  icon: string;
  contentStyle: FontStyles & Partial<Border & BoxShadow> = new FontStyles({ backgroundColor: SETTINGS.defaultArrowButtonBackgroundColor || 'inherit' });
  iconStyle = new IconStyles();
}
