import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

export class ArtifactTypeExtractor implements ArtifactValueExtractor {
  static readonly artifactTypeAttributeId = '617274696661637474797065';

  constructor(private readonly options: CardWidgetOptions) {}

  static isArtifactTypeAttribute(attributeId: string): boolean {
    return attributeId === ArtifactTypeExtractor.artifactTypeAttributeId;
  }

  getValue(artifactDto: ArtifactResponseDto): string {
    const artifactType = this.options.artifactTypes.listMap[artifactDto.artifactTypeId];

    return artifactType ? artifactType.name : '';
  }
}
