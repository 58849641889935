import { Injectable } from '@angular/core';
import { BaseSsoService } from '@public/pages/login/sso/base.sso.service';

@Injectable()
export class OpenIdSsoService extends BaseSsoService {
  tooltip = 'Login with OpenID Account';
  content = '<span class="p-button-icon pi pi-key" aria-hidden="true"></span>';
  windowRef: null | Window = null;

  async onInit(redirectIfLogged?: () => void): Promise<void> {
    redirectIfLogged && redirectIfLogged();
  }

  async onClickHandler(): Promise<void> {
    if (this.windowRef !== null && !this.windowRef.closed) {
      this.windowRef.focus();
    } else {
      this.windowRef = window.open(
        `${this.authService.rootUrl}${(this.authService.constructor as any).AuthControllerAuthOpenIdPath}?domain=${encodeURIComponent(window.location.origin)}`,
        '_blank',
        'location=no,toolbar=no,menubar=no,width=600,height=700',
      );
    }
  }
}
