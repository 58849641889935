import { ArtifactResponseDto, LinkResponseDto, SelfUserResponseDto } from '@api/models';
import { Environment } from '@environments/environment';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewLink } from '@shared/types/link.types';
import { NewTableColumn } from '@shared/types/table.types';
import { MenuItem } from 'primeng/api';
import { ListWidgetTableSettings, SummaryItem } from './list-widget-settings.types';
import { AddArtifactModulePositionEnum } from './list-widget.types';

export class ArtifactListTableModel {
  isFirstLoad = true;
  filesLoaded = false;
  currentUser: SelfUserResponseDto;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  columns = new ArtifactListTableColumns();
  attributeSummaryItems: Record<string, SummaryItem> = {};
  links: Record<string, Record<string, ArtifactLinks>> = {};
  linksDto: LinkResponseDto[] = [];
  files: Record<string, ArtifactResponseDto> = {};
  unSavedFiles: Record<string, Record<string, File[]>> = {};
  linkedData: Record<string, NewArtifact> = {};
  filtersOutsideView: Record<string, any> = {};
  tooltipFiltersOutsideView = '';
  artifactIdForAcl: string;
  sequenceAttributeId: string;
}

export class ArtifactListTableColumns {
  default: NewTableColumn[] = [];
  attribute: NewTableColumn[] = [];
  linkType: NewTableColumn[] = [];
  editableKeys: string[] = [];
  sortableKeys: string[] = [];
}

export class NewModuleArtifact {
  artifactType: MenuItem | null = null;
  isHeading = false;
  position: AddArtifactModulePositionEnum | null = null;

  constructor(params?: Partial<NewModuleArtifact>) {
    params && Object.assign(this, params);
  }
}

export class SelectedRowModuleArtifact {
  artifact: NewArtifact | null = null;
  index: number;

  constructor(params?: Partial<SelectedRowModuleArtifact>) {
    params && Object.assign(this, params);
  }
}

export interface GetModuleQueryParametersParams {
  newModuleArtifact: NewModuleArtifact;
  selectedRowArtifact: SelectedRowModuleArtifact;
  settings: ListWidgetTableSettings;
  data: NewArtifact[];
}

export interface ArtifactLinks {
  [LinkDirection.outgoing]: NewLink[];
  [LinkDirection.incoming]: NewLink[];
}

export interface ArtifactListTableIdentifiers {
  hash: string;
  applicationId: string;
}

export interface AddArtifactTableLinkItem {
  artifact: NewArtifact;
  column: NewTableColumn;
}

export interface DeleteArtifactTableLinkItem {
  artifact: NewArtifact;
  link: NewLink;
}

export interface PaginatorEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

export interface ModuleParentIdAndIntoParams {
  id: string;
  into: boolean;
}
