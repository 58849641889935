import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';

export interface OptionsEnum {
  [id: number]: string;
}

export enum FlexDirectionEnum {
  column = 'column',
  row = 'row',
}

export enum BorderSideEnum {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

export enum LabelBehaviourEnum {
  static = 'static',
  float = 'float',
  placeholder = 'placeholder',
}

export enum LabelPositionEnum {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export enum TextHorizontalAlignEnum {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum TextVerticalAlignEnum {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export enum BorderStylesEnum {
  none = 'none',
  dotted = 'dotted',
  dashed = 'dashed',
  solid = 'solid',
  double = 'double',
}

export enum BorderWidthEnum {
  thin = 'thin',
  medium = 'medium',
  thick = 'thick',
}

export enum FontWeightEnum {
  normal = 'normal',
  bold = 'bold',
}

export enum FontStyleEnum {
  normal = 'normal',
  italic = 'italic',
}

export enum TextDecorationEnum {
  none = 'none',
  underline = 'underline',
  lineThrough = 'line-through',
}

export enum WidgetSaveButtonVisibilityEnum {
  always = 'always',
  onChange = 'onChange',
  onChangeGreen = 'onChangeGreen',
  hide = 'hide',
}

export enum PositionEnum {
  static = 'static',
  absolute = 'absolute',
  fixed = 'fixed',
}

export enum FontFamilyEnum {
  akronim = 'Akronim, cursive',
  arial = 'Arial, sans-serif',
  courierNew = 'Courier New, monospace',
  ephesis = 'Ephesis, cursive',
  gemumuLibre = 'Gemunu Libre, sans-serif',
  notoSansDisplay = 'Noto Sans Display, sans-serif',
  nunito = 'Nunito, sans-serif',
  openSans = 'Open Sans Condensed, sans-serif',
  pacifico = 'Pacifico, cursive',
  roboto = 'Roboto, sans-serif',
  stickNoBills = 'Stick No Bills, sans-serif',
  timesNewRoman = 'Times New Roman, serif',
}

export enum ListStyleEnum {
  none = 'none',
  disc = 'disc',
  circle = 'circle',
  square = 'square',
  decimal = 'decimal',
  decimalLeadingZero = 'decimal-leading-zero',
  lowerAlpha = 'lower-alpha',
  upperAlpha = 'upper-alpha',
  lowerRoman = 'lower-roman',
  upperRoman = 'upper-roman',
  lowerLatin = 'lower-latin',
  upperLatin = 'upper-latin',
  lowerGreek = 'lower-greek',
}

export enum ShadowStylesEnum {
  none = '',
  light = '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
}

export enum ContainerClassEnum {
  box = 'container',
  full = 'container-fluid',
}

export enum BackgroundTypeEnum {
  color = 'color',
  image = 'image',
  gradient = 'gradient',
}

export enum OverflowEnum {
  visible = 'visible',
  hidden = 'hidden',
  scroll = 'scroll',
  auto = 'auto',
}

export enum FlexDirection {
  row = 'row',
  column = 'column',
  rowReverse = 'row-reverse',
  columnReverse = 'column-reverse',
}

export enum JustifyContent {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
  spaceEvenly = 'space-evenly',
}

export enum AlignItems {
  stretch = 'stretch',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  baseline = 'baseline',
}

// TODO: consider moving specific options to a respective component
export class StyleOptions {
  textVerticalAlign = GetSelectOptionsFromEnum(TextVerticalAlignEnum);
  textHorizontalAlign = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  labelBehaviour = GetSelectOptionsFromEnum(LabelBehaviourEnum);
  labelPosition = GetSelectOptionsFromEnum(LabelPositionEnum);
  fontFamily = GetSelectOptionsFromEnum(FontFamilyEnum);
  borderWidth = GetSelectOptionsFromEnum(BorderWidthEnum);
  borderStyles = GetSelectOptionsFromEnum(BorderStylesEnum);
  boxShadows = GetSelectOptionsFromEnum(ShadowStylesEnum);
  containerClass = GetSelectOptionsFromEnum(ContainerClassEnum);
  backgroundType = GetSelectOptionsFromEnum(BackgroundTypeEnum);
  overflow = GetSelectOptionsFromEnum(OverflowEnum);
}

export class MarginStyles {
  marginTop = '0px';
  marginBottom = '0px';
  marginLeft = '1px';
  marginRight = '1px';
}

export class PaddingStyles {
  paddingTop = '0.85rem';
  paddingBottom = '0.85rem';
  paddingLeft = '0.85rem';
  paddingRight = '0.85rem';
}

export class BorderStyles {
  boxShadow = ShadowStylesEnum.none;

  borderLeftColor = 'lightgrey';
  borderRightColor = 'lightgrey';
  borderBottomColor = 'lightgrey';
  borderTopColor = 'lightgrey';

  borderLeftWidth = BorderWidthEnum.thin;
  borderBottomWidth = BorderWidthEnum.thin;
  borderRightWidth = BorderWidthEnum.thin;
  borderTopWidth = BorderWidthEnum.thin;

  borderRightStyle = BorderStylesEnum.solid;
  borderTopStyle = BorderStylesEnum.solid;
  borderLeftStyle = BorderStylesEnum.solid;
  borderBottomStyle = BorderStylesEnum.solid;

  borderTopLeftRadius = '4px';
  borderBottomRightRadius = '4px';
  borderBottomLeftRadius = '4px';
  borderTopRightRadius = '4px';

  constructor(params?: Partial<BorderStyles>) {
    params && Object.assign(this, params);
  }
}

export class WidgetStyles extends BorderStyles {
  backgroundColor: string;
  paddingTop: string;
  paddingLeft: string;
  paddingRight: string;
  paddingBottom: string;

  constructor(params?: Partial<WidgetStyles>) {
    super({
      borderTopStyle: BorderStylesEnum.none,
      borderRightStyle: BorderStylesEnum.none,
      borderBottomStyle: BorderStylesEnum.none,
      borderLeftStyle: BorderStylesEnum.none,
    });
    Object.assign(this, params);
  }
}
