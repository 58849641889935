import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { AvrWidgetComponent } from './avr-widget.component';
import { AvrWidgetSettingsComponent } from './components/avr-widget-settings/avr-widget-settings.component';
import { AvrPdfRenderFileTypeComponent } from './components/avr-widget-view/avr-render-file-type/pdf/pdf.avr-render-file-type.component';
import { AvrSvgRenderFileTypeComponent } from './components/avr-widget-view/avr-render-file-type/svg/svg.avr-render-file-type.component';
import { AvrPngRenderFileTypeComponent } from './components/avr-widget-view/avr-render-file-type/png/png.avr-render-file-type.component';
import { AvrSettingFieldsModule } from './components/avr-widget-settings/avr-types-settings/avr-types-settings.module';
import { AvrWidgetViewComponent } from './components/avr-widget-view/avr-widget-view.component';

@NgModule({
  declarations: [
    AvrWidgetComponent,
    AvrWidgetSettingsComponent,
    AvrWidgetViewComponent,
    AvrSvgRenderFileTypeComponent,
    AvrPdfRenderFileTypeComponent,
    AvrPngRenderFileTypeComponent,
  ],
  imports: [
    AvrSettingFieldsModule,
    ElvisSharedModule,
    CommonModule,
    TranslateModule,
    ButtonModule,
    OverlayPanelModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    InputTextModule,
  ],
  exports: [AvrWidgetComponent],
})
export class AvrWidgetModule {}
