import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ImportDto } from '@api/models/import-dto';
import { RecordDto } from '@api/models/record-dto';
import { NewApplication } from '@shared/types/application.types';
import { SelectOption } from '@shared/types/shared.types';

export class ApplicationModel {
  constructor(public application = new NewApplication(), public inProgress: boolean = false, public pageOptions: SelectOption<string, string>[] = []) {}
}

export class Application implements ApplicationResponseDto {
  id: string;
  name = '';
  isProtected: boolean;
  defaultFolderId: string;
  imported?: ImportDto;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;
  defaultPageId: string | null = null;

  constructor(application?: Partial<ApplicationResponseDto>) {
    application && Object.assign(this, application);
  }
}
