<div class="grid canvas" cdkDrag>
    <span class="canvas-item_drag-button_wrapper" cdkDragHandle>
        <span  class="canvas-item_drag-button"></span>
    </span>
    <div class="canvas-item_placeholder" *cdkDragPlaceholder></div>
    <div [class]="'col-12 md:col-' + item" *ngFor="let item of canvas.items">
        <div class="canvas-item_wrapper">
            <span pRipple
                  class="p-button-success p-button-outlined canvas-item_add">
                <i class="pi pi-plus-circle" pTooltip="{{'Add new addon' | translate}}"></i>
            </span>
        </div>
    </div>
</div>
