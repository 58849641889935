import { CommonFeActionWorkflowSettingsDto, WorkflowActionDto } from '@api/models';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType, WorkflowTriggerEvent } from '../../types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface WorkflowActionAttributeValueFromLinkDto extends WorkflowActionDto {
  linkArtifactTypeId: string;
  linkAttributeId: string;
}

export class WorkflowActionSetAttributeValueFromLink
  extends AbstractWorkflowAttributeBasedAction<CommonFeActionWorkflowSettingsDto>
  implements WorkflowActionAttributeValueFromLinkDto
{
  linkArtifactTypeId: string;
  linkAttributeId: string;

  constructor(dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super(CommonFeAction, WorkflowActionType.SET_VALUE_FROM_LINK, dto, actionAttributeHandler);
    this.linkArtifactTypeId = (dto as WorkflowActionAttributeValueFromLinkDto)?.linkArtifactTypeId;
    this.linkAttributeId = (dto as WorkflowActionAttributeValueFromLinkDto)?.linkAttributeId;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifact;
  }

  execute(triggerEvent?: WorkflowTriggerEvent): void {
    const linkedArtifact = triggerEvent?.payload?.artifact as NewArtifact;
    const linkedAttribute = linkedArtifact.clientAttributes.find(clientAttribute => clientAttribute.id === this.linkAttributeId);
    linkedAttribute &&
      this.actionAttributeHandler.notifySetAttributeValueEvent({
        artifactTypeId: this.artifactTypeId,
        attributeId: this.attributeId,
        value: linkedAttribute.value,
      });
    return;
  }

  isValid(): boolean {
    return super.isValid() && !!(this.artifactTypeId && this.attributeId && this.linkArtifactTypeId && this.linkAttributeId);
  }

  toServer(): WorkflowActionAttributeValueFromLinkDto {
    return {
      ...super.toServer(),
      linkArtifactTypeId: this.linkArtifactTypeId,
      linkAttributeId: this.linkAttributeId,
    };
  }
}
