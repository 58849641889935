<p-overlayPanel
  #htmlOp
  appendTo="body"
  [style]="{ 'max-width': '45%', 'max-height': '45%' }"
  styleClass="cop-no-caret cop-as-flex cop-with-content-overflow"
  [dismissable]="true"
  [showCloseIcon]="closable"
  (onHide)="onOverlayHide()">
  <ng-template pTemplate>
    <div class="at-overlay-content-container">
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>
  </ng-template>
</p-overlayPanel>
