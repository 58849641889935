export class SidebarWidgetPicker {
  constructor(public visible: boolean = false, public position: SidebarPosition = SidebarPosition.left, public modal: boolean = true) {}

  openSidebar(): void {
    this.visible = true;
  }

  closeSidebar(): void {
    this.visible = false;
  }
}

export enum SidebarPosition {
  left = 'left',
  right = 'right',
}
