import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TenantApplicationService, TenantExportImportService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NAME_KEY, NAME_LABEL } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { ApplicationListModel } from '../types/application-list.types';

@Injectable()
export class ApplicationListService extends CoreService<any, ApplicationListModel> {
  constructor(
    private readonly cache: NewCacheService,
    private readonly tenantApplicationService: TenantApplicationService,
    private readonly tenantExportImportService: TenantExportImportService,
  ) {
    super();
  }

  init(context: any, model: ApplicationListModel): void {
    super.init(context, model);

    this.c.registerSubscriptions([
      this.cache.data.applications.subscribe(applications => (this.m.applications = applications as ApplicationResponseDto[])),
      this.cache.user.subscribe(user => (this.m.canUserCreateApplication = !!(user as SelfUserResponseDto)?.tenant?.isAdmin)),
    ]);
    this.m.columns = [new TableColumn(NAME_LABEL, NAME_KEY)];
  }

  getImportMethod(): any {
    return this.tenantExportImportService.exportImportControllerImport$Response.bind(this.tenantExportImportService);
  }

  getExportMethod(): any {
    return this.tenantApplicationService.applicationControllerExport$Response.bind(this.tenantApplicationService);
  }
}
