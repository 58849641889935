import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ArtifactAttributeFormFieldLabelWithIconComponent, TriStateCheckboxModule, CheckboxModule],
  selector: 'app-artifact-attribute-form-field-boolean',
  templateUrl: './artifact-attribute-form-field-boolean.component.html',
  styleUrls: ['./artifact-attribute-form-field-boolean.component.scss'],
})
export class ArtifactAttributeFormFieldBooleanComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() label: string;
  @Input() onChange: () => Promise<void>;
}
