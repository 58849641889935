import { WorkflowTriggerDto } from '@api/models';
import { SaveableItem } from '@shared/interfaces/saveable-item';
import { NewArtifact } from '@shared/types/artifact.types';

export enum RuleTriggerType {
  ATTRIBUTE_VALUE_CHANGE = 'ATTRIBUTE_VALUE_CHANGE',
  LINK_ADDED = 'LINK_ADDED',
  PAGE_LOAD = 'PAGE_LOAD',
  WIDGET_LOAD = 'WIDGET_LOAD',
  WIDGET_DATA_LOAD = 'WIDGET_DATA_LOAD',
  BUTTON_CLICK = 'BUTTON_CLICK',
  OWNER_CREATE = 'OWNER_CREATE',
  OWNER_UPDATE = 'OWNER_UPDATE',
  OWNER_DELETE = 'OWNER_DELETE',
  MANUAL = 'MANUAL',
  ARTIFACT_WIDGET_CREATE_MODE = 'ARTIFACT_WIDGET_CREATE_MODE',
}

export interface WorkflowTrigger extends WorkflowTriggerDto, SaveableItem<WorkflowTriggerDto> {
  isArtifactTypeRequired(): boolean;

  isExternalArtifactTypeRequired(): boolean;

  isAttributeRequired(): boolean;

  isWidgetRequired(): boolean;

  isElementRequired(): boolean;

  isValid(): boolean;

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean;
}

export interface WorkflowTriggerEvent {
  pageId: string;
  definition: WorkflowTrigger;
  payload?: WorkflowTriggerEventPayload;
  widgetId?: string;
}

export interface WorkflowTriggerEventPayload {
  artifact?: NewArtifact;
  linkedArtifact?: NewArtifact; // TODO: move linked artifact here;
  artifacts?: NewArtifact[];
  newValue?: any;
  getAttributeValueFn?: (attributeId: string) => any;
}

export const ImplementedRuleTriggerTypes: Record<RuleTriggerType, boolean> = {
  [RuleTriggerType.ATTRIBUTE_VALUE_CHANGE]: true,
  [RuleTriggerType.LINK_ADDED]: true,
  [RuleTriggerType.WIDGET_LOAD]: true,
  [RuleTriggerType.PAGE_LOAD]: true,
  [RuleTriggerType.WIDGET_DATA_LOAD]: true,
  [RuleTriggerType.BUTTON_CLICK]: false,
  [RuleTriggerType.OWNER_CREATE]: true,
  [RuleTriggerType.OWNER_UPDATE]: true,
  [RuleTriggerType.OWNER_DELETE]: true,
  [RuleTriggerType.MANUAL]: true,
  [RuleTriggerType.ARTIFACT_WIDGET_CREATE_MODE]: true,
};
