import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetWorkflowActionAttributesPipe } from './get-workflow-action-attributes.pipe';
import { GetWorkflowCalculationActionAttributesPipe } from './get-workflow-calculation-action-attributes.pipe';
import { IsGlobalWorkflowUsedByPagePipe } from './is-global-workflow-used-by-page.pipe';
import { GetWorkflowTriggerLinkableArtifactTypes } from './get-workflow-trigger-linkable-artifact-types.pipe';
import { IsWorkflowLinkTrigger } from './is-workflow-link-trigger.pipe';

const pipes = [
  GetWorkflowActionAttributesPipe,
  GetWorkflowCalculationActionAttributesPipe,
  IsGlobalWorkflowUsedByPagePipe,
  GetWorkflowTriggerLinkableArtifactTypes,
  IsWorkflowLinkTrigger,
];

@NgModule({
  imports: [CommonModule],
  exports: [...pipes],
  declarations: [...pipes],
  providers: [],
})
export class WorkflowsPipesModule {}
