import { Pipe, PipeTransform } from '@angular/core';
import { SystemTenantResponseDto } from '@api/models/system-tenant-response-dto';
import { DELETED_KEY } from '@shared/constants/constants';
import { SharedMethods } from '@shared/methods/shared.methods';

@Pipe({ name: 'printTenantToTable' })
export class PrintTenantToTablePipe implements PipeTransform {
  transform(tenant: SystemTenantResponseDto, key: string): string | boolean {
    if (key === DELETED_KEY) return !!tenant.deleted;
    return SharedMethods.getValueFromPath(key, tenant);
  }
}
