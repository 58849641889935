import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { JobsResponseDto } from '@api/models';
import { SystemJobsService } from '@api/services/system-jobs.service';
import {
  ID_KEY,
  ID_LABEL,
  RESULT_KEY,
  RESULT_LABEL,
  STATUS_KEY,
  STATUS_LABEL,
  TYPE_KEY,
  TYPE_LABEL,
  UPDATED_ON_KEY,
  UPDATED_ON_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { TableColumn } from '@shared/types/table.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { JobsComponent } from '../jobs.component';
import { JobsPageModel, JobsStatus } from '../types/jobs-page.model';

@Injectable()
export class JobsPageService extends CoreService<JobsComponent, JobsPageModel> {
  constructor(
    private readonly systemJobsService: SystemJobsService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  async init(context: JobsComponent, model: JobsPageModel): Promise<void> {
    super.init(context, model);
    this.setColumns();
  }

  async runAgain(job: JobsResponseDto): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to run job again', 'Yes', 'No']);
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        header,
        message: `${message} ?`,
        acceptLabel,
        rejectLabel,
        accept: async () => {
          this.blockUiService.blockUi();
          try {
            job.status = JobsStatus.pending;
            await lastValueFrom(this.systemJobsService.jobsControllerPatch({ body: { id: job.id, status: JobsStatus.pending } }));
          } catch (error) {
            reject(error);
          } finally {
            this.blockUiService.unblockUi();
          }
        },
        reject: () => resolve(),
      });
    });
  }

  async delete(job: JobsResponseDto): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        header,
        message: `${message} ?`,
        acceptLabel,
        rejectLabel,
        accept: async () => {
          this.blockUiService.blockUi();
          try {
            await lastValueFrom(this.systemJobsService.jobsControllerDelete({ id: job.id }));
            this.c.reloadTable();
          } catch (error) {
            reject(error);
          } finally {
            this.blockUiService.unblockUi();
          }
        },
        reject: () => resolve(),
      });
    });
  }

  private setColumns(): void {
    this.m.columns = [
      new TableColumn(ID_LABEL, ID_KEY),
      new TableColumn(TYPE_LABEL, TYPE_KEY),
      new TableColumn(RESULT_LABEL, RESULT_KEY),
      new TableColumn(UPDATED_ON_LABEL, UPDATED_ON_KEY),
      new TableColumn(STATUS_LABEL, STATUS_KEY),
    ];
  }
}
