import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { RuleTriggerType, WorkflowActionSetAttributeValueFromLink, WorkflowTrigger } from '@workflows/types';

@Component({
  selector: 'app-action-value-from-link',
  templateUrl: 'action-value-from-link.component.html',
  styleUrls: ['./action-value-from-link.component.scss', '../../../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionValueFromLinkComponent extends AbstractWorkflowRuleItemComponent implements OnInit {
  @Input() action: WorkflowActionSetAttributeValueFromLink;
  @Input() linkableArtifactTypes: NewArtifactType[];
  @Input() triggers: WorkflowTrigger[];

  linkAttributes: NewAttribute[];

  constructor(protected translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.action.linkArtifactTypeId =
      this.triggers?.find(trigger => trigger.type === RuleTriggerType.LINK_ADDED)?.artifactTypeId || this.action.linkArtifactTypeId;
    this.linkAttributes = this.getArtifactTypeAttributes(this.action.linkArtifactTypeId);
  }

  onLinkAttributeChange(attributeId: string) {
    this.action.linkAttributeId = attributeId;
  }

  onAttributeChange(attributeId: string) {
    this.action.attributeId = attributeId;
  }
}
