import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { LinkMethods } from '@shared/methods/link.methods';
import { RuntimeVariablesMethods } from '@widgets/shared/methods/runtime-variables.methods';
import { ReplaceRuntimeVariablesService } from '@widgets/shared/services/replace-runtime-variables.service';
import { RuntimeArtifact } from '@widgets/shared/types/runtime-variables/runtime-artifact.types';
import { get } from 'lodash';
import { lastValueFrom } from 'rxjs';

@Pipe({ name: 'replaceRuntimeVariables' })
export class ReplaceRuntimeVariablesPipe implements PipeTransform {
  private linkedVariables: Record<string, Record<string, any>> = {};
  private contextVariables: RuntimeArtifact | undefined;

  constructor(
    private readonly replaceRuntimeVariablesService: ReplaceRuntimeVariablesService,
    private readonly cache: NewCacheService,
    public readonly tenantLinkService: TenantLinkService,
  ) {}

  async transform(text: string, context?: ArtifactResponseDto): Promise<string> {
    if (!text || typeof text !== 'string') return text;

    let sourceText = text;
    const textVariables: string[] = [...this.replaceRuntimeVariablesService.getTextVariables(sourceText)];

    if (!textVariables.length) return sourceText;

    if (context) {
      await this.fillContextVariables(context);
      await this.fillLinkedVariables(context);
    }

    textVariables.forEach(variable => {
      const splittedVariable = variable.split('.');
      let value;

      if (this.replaceRuntimeVariablesService.isRuntimeVariable(splittedVariable[0])) value = this.replaceRuntimeVariablesService.getValueByPath(variable);
      if (this.contextVariables && this.contextVariables[splittedVariable[0] as keyof RuntimeArtifact]) value = get(this.contextVariables, variable);
      if (this.linkedVariables[splittedVariable[0]]) value = get(this.linkedVariables, variable);

      if (value !== undefined) sourceText = sourceText.replace(new RegExp(`{${variable}}`, 'g'), value);
    });

    return sourceText;
  }

  private async fillLinkedVariables(context?: ArtifactResponseDto): Promise<void> {
    if (!context) return;

    const linkTypes = this.cache.data.linkTypes.getValue() as LinkTypeResponseDto[];
    const linkTypeIdsMap: Record<string, string> = {};
    const relevant = linkTypes.filter(linkType => {
      return linkType.restrictions?.some(({ sourceArtifactTypeId, destinationArtifactTypeId, singleSource, singleDestination }) => {
        return singleSource && singleDestination && (sourceArtifactTypeId === context.artifactTypeId || destinationArtifactTypeId === context.artifactTypeId);
      });
    });

    relevant.forEach(linkType => {
      linkType.restrictions?.forEach(({ sourceArtifactTypeId, destinationArtifactTypeId, singleSource, singleDestination }) => {
        if (singleSource && singleDestination) {
          let name = '';

          if (sourceArtifactTypeId === context.artifactTypeId) name = linkType.outgoingName;
          if (destinationArtifactTypeId === context.artifactTypeId) name = linkType.incomingName;

          if (name) {
            const key = RuntimeVariablesMethods.formatVariableName(name);
            this.linkedVariables[key] = {};
            linkTypeIdsMap[linkType.id] = key;
          }
        }
      });
    });

    const linkNameToArtifactIdMap: Record<string, string> = {};
    const filter = LinkMethods.getLinksFilterForArtifact(context.id, linkTypeIdsMap);
    const links = (await lastValueFrom(this.tenantLinkService.linkControllerList({ body: { filter } }))).data;
    const linkedArtifacts = await LinkMethods.getUniqueArtifactsFromLinks(links, this.cache);

    links.forEach(({ linkTypeId, destinationArtifactId, sourceArtifactId }) => {
      const linkName = sourceArtifactId === context.id ? destinationArtifactId : sourceArtifactId;
      linkNameToArtifactIdMap[linkName] = linkTypeIdsMap[linkTypeId];
    });

    linkedArtifacts.forEach(dto => {
      if (linkNameToArtifactIdMap[dto.id]) {
        this.linkedVariables[linkNameToArtifactIdMap[dto.id]] = new RuntimeArtifact(dto, this.replaceRuntimeVariablesService.options);
      }
    });
  }

  private async fillContextVariables(context: ArtifactResponseDto) {
    this.contextVariables = new RuntimeArtifact(context, this.replaceRuntimeVariablesService.options);
  }
}

@NgModule({
  declarations: [ReplaceRuntimeVariablesPipe],
  exports: [ReplaceRuntimeVariablesPipe],
})
export class ReplaceRuntimeVariablesModule {}
