import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { IS_DELETED_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SystemUserPageService } from './services/system-user.service';
import { SystemUserPageModel } from './types/system-user.types';

@Component({
  selector: 'app-system-user',
  templateUrl: './system-user.component.html',
  styleUrls: ['./system-user.component.scss'],
  providers: [SystemUserPageService, DialogService],
})
export class SystemUserComponent extends CoreComponent<SystemUserPageService, SystemUserPageModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: SystemUserPageService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new SystemUserPageModel(), service, announcement);
  }

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'User' }]);
  }
}
