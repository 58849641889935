import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

/**
 * Methods for determining the kind of data type
 * */
export const IsSimple = (dataTypeKind: DataTypeKind): boolean => dataTypeKind === DataTypeKind.simple;
export const IsBounded = (dataTypeKind: DataTypeKind): boolean => dataTypeKind === DataTypeKind.bounded;
export const IsEnumerated = (dataTypeKind: DataTypeKind): boolean => dataTypeKind === DataTypeKind.enumerated;
export const IsCounter = (dataTypeKind: DataTypeKind): boolean => dataTypeKind === DataTypeKind.counter;

/**
 * Methods for determining the basic data type
 * */
export const IsInteger = (baseDataType: BaseDataType): boolean => baseDataType === BaseDataType.integer;
export const IsDecimal = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.decimal;
export const IsText = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.text;
export const IsBoolean = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.boolean;
export const IsFile = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.file;
export const IsHyperlink = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.hyperlink;
export const IsHtml = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.html;
export const IsDate = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.date;
export const IsTime = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.time;
export const IsDateTime = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.dateTime;
export const IsUser = (baseDataType: BaseDataType | string): boolean => baseDataType === BaseDataType.user;
export const IsDateOrTime = (baseDataType: BaseDataType): boolean => [BaseDataType.date, BaseDataType.time, BaseDataType.dateTime].includes(baseDataType);
export const IsDateOrDateTime = (baseDataType?: BaseDataType | null): boolean =>
  !!baseDataType && [BaseDataType.date, BaseDataType.dateTime].includes(baseDataType);

export const ConvertMinMaxToClient = (baseDataType: BaseDataType, value: string): Date | string => {
  if (IsInteger(baseDataType) || IsDecimal(baseDataType)) return value;
  if (IsDate(baseDataType) || IsDateTime(baseDataType)) return new Date(value);
  if (IsTime(baseDataType)) return new Date(new Date().toISOString().replace(/\d{2}:\d{2}/, value));
  return '';
};
