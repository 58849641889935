<div class="grid" *ngIf="attributes.length">
  <ng-container *ngFor="let artifactAttribute of attributes; let index = index">
    <div *ngIf="isSimple(artifactAttribute)" [ngClass]="isText(artifactAttribute) || isHyperlink(artifactAttribute) ? 'col-12' : 'col-12 md:col-6 lg:col-4'">
      <span class="mandatory-field" *ngIf="artifactAttribute.isMandatory"></span>
      <app-simple-kind-input
        *ngIf="!isFile(artifactAttribute) && !isHyperlink(artifactAttribute)"
        [index]="index"
        [fullDataType]="artifactAttribute | getDataTypeFromClientAttribute: allAttributes:allDataTypes"
        [fullAttribute]="artifactAttribute | getAttributeFromClientAttribute: allAttributes"
        [artifactAttribute]="artifactAttribute"
        [users]="users"
      >
      </app-simple-kind-input>
      <app-simple-kind-file-input
        *ngIf="isFile(artifactAttribute)"
        [index]="index"
        [fullDataType]="artifactAttribute | getDataTypeFromClientAttribute: allAttributes:allDataTypes"
        [fullAttribute]="artifactAttribute | getAttributeFromClientAttribute: allAttributes"
        [artifactAttribute]="artifactAttribute"
      >
      </app-simple-kind-file-input>
      <app-simple-kind-hyperlink-input
        *ngIf="isHyperlink(artifactAttribute)"
        [index]="index"
        [fullDataType]="artifactAttribute | getDataTypeFromClientAttribute: allAttributes:allDataTypes"
        [fullAttribute]="artifactAttribute | getAttributeFromClientAttribute: allAttributes"
        [artifactAttribute]="artifactAttribute"
      >
      </app-simple-kind-hyperlink-input>
    </div>

    <div class="col-12 md:col-6 lg:col-4" *ngIf="isEnumerated(artifactAttribute)">
      <span class="mandatory-field" *ngIf="artifactAttribute.isMandatory"></span>
      <app-enumerated-kind-input
        [index]="index"
        [fullDataType]="artifactAttribute | getDataTypeFromClientAttribute: allAttributes:allDataTypes"
        [fullAttribute]="artifactAttribute | getAttributeFromClientAttribute: allAttributes"
        [artifactAttribute]="artifactAttribute"
      >
      </app-enumerated-kind-input>
    </div>

    <div class="col-12 md:col-6 lg:col-4" *ngIf="isBounded(artifactAttribute)">
      <span class="mandatory-field" *ngIf="artifactAttribute.isMandatory"></span>
      <app-bounded-range-kind-input
        [index]="index"
        [fullDataType]="artifactAttribute | getDataTypeFromClientAttribute: allAttributes:allDataTypes"
        [fullAttribute]="artifactAttribute | getAttributeFromClientAttribute: allAttributes"
        [artifactAttribute]="artifactAttribute"
      >
      </app-bounded-range-kind-input>
    </div>
  </ng-container>
</div>
