import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UrlMethods } from '@shared/methods/url.methods';
import { LinkQueryParam, LinkQueryParamActionEnum, MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';
import { xor } from 'lodash';
import { MenuItem } from '../types/menu-widget.types';

@Injectable({ providedIn: 'root' })
// @Injectable({ providedIn: MenuWidgetModule })
export class ActiveLinkCheckHelper {
  isRouterLinkActive(item: MenuItem, allMenuItems: MenuItem[], queryParams: Params): boolean {
    if (item.url || item.alias) {
      const idOrAlias = UrlMethods.getPageIdOrAliasFromUrl(window.location.pathname);
      return idOrAlias === item.url || idOrAlias === item.alias;
    }

    if (item.currentPageBehavior === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage)
      return this.isItemWithStayOnCurrentPageAcitve(item, allMenuItems, queryParams);
    return false;
  }

  isItemWithStayOnCurrentPageAcitve(item: MenuItem, allMenuItems: MenuItem[], queryParams: Params): boolean {
    const paramsToAdd = item.queryParams.filter(itemQueryParam => itemQueryParam.action === LinkQueryParamActionEnum.addNew);
    if (!paramsToAdd.length) {
      return item.showEmptyParamsAsActive && item.showEmptyParamsAsActive && this.isNoQueryParamFromThisMenuInUse(allMenuItems, queryParams);
    }
    return paramsToAdd.every(itemQueryParam => this.isQueryParamInUse(itemQueryParam, queryParams));
  }

  isNoQueryParamFromThisMenuInUse(allMenuItems: MenuItem[], queryParams: Params): boolean {
    const items = allMenuItems.filter(menuItem => menuItem.currentPageBehavior === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage);
    return items.every(item => !item.getAddParamsThatAreInUse(queryParams).length);
  }

  // XOR is used to compare two arrays
  isQueryParamInUse(itemQueryParam: LinkQueryParam, queryParams: Params): boolean {
    if (!itemQueryParam.value) return false;
    return itemQueryParam.value.split(',').length > 1
      ? xor(itemQueryParam.value.split(','), queryParams[itemQueryParam.key]?.split(',')).length === 0
      : queryParams[itemQueryParam.key] === itemQueryParam.value;
  }
}
