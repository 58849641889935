<div class="header">
  <div class="mr">
    <p-fileUpload
      #importField
      mode="basic"
      name="files[]"
      accept=".xlsm,.xlsx,.xls"
      (uploadHandler)="import($event, importField)"
      [fileLimit]="1"
      [customUpload]="true"
      uploadIcon="pi pi-upload"
      chooseIcon="pi pi-cloud-upload"
      chooseLabel="Import"
    ></p-fileUpload>
  </div>
  <a [routerLink]="['/admin/link-type']">
    <button pButton label="{{ 'Add' | translate }}" class="p-button" icon="pi pi-plus" iconPos="left"></button>
  </a>
</div>

<app-link-type-list-table [columns]="m.columns" [applications]="m.applications" [loadDataMethod]="loadData()"></app-link-type-list-table>
