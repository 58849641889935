import { Component, Input } from '@angular/core';
import { TeamPageModel } from '@private/pages/team-management/team/types/teams-page.types';

@Component({
  selector: 'app-team-user-form',
  templateUrl: './team-user-form.component.html',
  styleUrls: ['./team-user-form.component.scss'],
})
export class TeamUserFormComponent {
  @Input() m: TeamPageModel;
}
