import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayAtDatetimeComponent } from './display-at-datetime.component';

@NgModule({
  declarations: [DisplayAtDatetimeComponent],
  imports: [CommonModule],
  exports: [DisplayAtDatetimeComponent],
})
export class DisplayAtDatetimeModule {}
