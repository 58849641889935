import { Component, Input } from '@angular/core';
import { TenantTenantResponseDto } from '@api/models';
import { TenantOslcRootServicesModel } from '@private/pages/current-tenant/components/tenant-oslc-root-services/types/tenant-oslc-root-services.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-tenant-oslc-root-services',
  templateUrl: './tenant-oslc-root-services.component.html',
  styleUrls: ['./tenant-oslc-root-services.component.scss'],
})
export class TenantOslcRootServicesComponent {
  @Input() tenant: TenantTenantResponseDto;

  protected m: TenantOslcRootServicesModel = new TenantOslcRootServicesModel();

  constructor(
    private readonly elvisUtil: ElvisUtil,
    private readonly announcement: AnnouncementService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  deleteOslcRootService(oslcRootServiceUrl: string): void {
    const oslcRootServiceEntries = Object.entries(this.tenant.oslcRootServices);
    const filteredOslcRootServiceEntries = oslcRootServiceEntries.filter(([key]) => key !== oslcRootServiceUrl);
    this.tenant.oslcRootServices = Object.fromEntries(filteredOslcRootServiceEntries);
  }

  prependNewRootService(): void {
    const { domain, consumerKey, consumerSecret } = this.m.newOslcRootService;
    if (!domain || !consumerKey || !consumerSecret) {
      this.announcement.error('Missing required fields');
      return;
    }
    this.tenant.oslcRootServices[this.m.newOslcRootService.domain] = {
      consumerKey: this.m.newOslcRootService.consumerKey,
      consumerSecret: this.m.newOslcRootService.consumerSecret,
    };
    Object.assign(this.m.newOslcRootService, {
      domain: '',
      consumerKey: '',
      consumerSecret: '',
    });
    this.m.addNewMode = false;
  }

  openDialogDeleteOslcRootService(oslcRootServiceUrl: string): void {
    this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams(
        'Delete OSLC root service',
        `Are you sure, you want to delete '${oslcRootServiceUrl}' OSLC root service ?`,
        'Yes',
        'No',
      ),
      this.deleteOslcRootService.bind(this, oslcRootServiceUrl),
    );
  }
}
