import { Injectable } from '@angular/core';
import { ChartConstants } from '@widgets/chart-widget/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ChartWidgetColorService {
  private colors: { [key: string]: string } = {};
  private colorList = ChartConstants.colorList;

  getColor(hash: string): string {
    !this.colors[hash] && (this.colors[hash] = this.getColorByHash(hash));
    return this.colors[hash];
  }

  getRandomBackgroundColors(length: number): string[] {
    return Array.from({ length }, this.getRandomColor);
  }

  private getColorByHash(hash: string): string {
    const base = this.colorList.length;
    let summ = 0;
    for (let i = 0; i < hash.length; i++) {
      summ += hash.charCodeAt(i);
    }

    return this.colorList[summ % base];
  }

  // private getBackgroundColor(hash: string): string {
  //   const str = [...hash].reduce((acc, char) => {
  //     return char.charCodeAt(0) + ((acc << 5) - acc);
  //   }, 0);
  //   return `hsl(${str % 360}, 95%, 35%)`;
  // }

  private getRandomColor(): string {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
}
