import { Injectable } from '@angular/core';
import { ArtifactCountResponseDto, ArtifactResponseDto, ArtifactUpdateRequestDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { ListMetaData } from '@shared/core/types/core.types';
import { AttributeValueToClient, AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { SummaryItem } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { lastValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Injectable({ providedIn: 'root' })
export class ArtifactListWidgetArtifactService {
  constructor(private tenantArtifactService: TenantArtifactService) {}

  getTableItemByFilters$(
    options: ListWidgetOptions,
    filters: Record<string, string>,
    externalFilters: Record<string, any> = {},
    limit = 1,
  ): Observable<NewArtifact[]> {
    // data loading
    const meta = new ListMetaData(limit, 0, filters);
    const query = meta.toQuery(externalFilters);
    return this.tenantArtifactService.artifactControllerList({ body: query }).pipe(
      tap(result => result.data.forEach(dto => (options.artifacts.listMap[dto.id] = dto))),
      map(result => result.data.map(dto => this.artifactDtoToNewArtifact(dto, options))),
    );
  }

  artifactDtoToNewArtifact(dto: ArtifactResponseDto, options: ListWidgetOptions): NewArtifact {
    const artifact = new NewArtifact({ dto, artifactTypesMap: options.artifactTypes.listMap });
    this.mapArtifactAttributesToClient(artifact, options);
    return artifact;
  }

  mapArtifactAttributesToClient(artifact: NewArtifact, options: ListWidgetOptions): void {
    const { dataTypes, attributes, users } = options;
    artifact.clientAttributes?.map(attribute => {
      attribute.value = AttributeValueToClient(
        new AttributeToClientParams({
          dataTypes,
          attributes,
          users: users.list,
          clientAttribute: attribute,
          value: attribute.value,
        }),
      );
    });
  }

  doUpdateArtifactAttribute$(artifact: NewArtifact, attributeId: string, value: any, options: ListWidgetOptions): Observable<ArtifactResponseDto> {
    const { attributes, dataTypes } = options;
    const attribute = attributes.listMap[attributeId];
    const dataType = dataTypes.listMap[attribute.dataTypeId];
    const body: ArtifactUpdateRequestDto = {
      id: artifact.id,
      attributes: {
        [attributeId]: { value: AttributeValueToServer(dataType, attribute, value) },
      },
    };

    return this.tenantArtifactService.artifactControllerUpdate({ body, notify: false, resetSequence: false });
  }

  async getTotalSum(attributeIds: string[], filter: string, iconMap: any, attributeSummaryItems: Record<string, SummaryItem>): Promise<void> {
    const $and = [JSON.parse(filter)];
    const params: any = { filter: JSON.stringify({ $and }), subOperations: JSON.stringify(['sum']) };

    params.attributeIds = JSON.stringify(attributeIds);
    const data: ArtifactCountResponseDto = await lastValueFrom(this.tenantArtifactService.artifactControllerCount(params));

    Object.keys(data.sum || {}).forEach(key => {
      attributeSummaryItems[key].totalSummary = (data.sum as any)[key];
      attributeSummaryItems[key].attributeIcon = iconMap[key];
    });
  }
}
