import { Injectable } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewArtifact } from '@shared/types/artifact.types';
import {
  DisplayAtCustomVariant,
  DisplayAtSelectionItem,
  DisplayAtSelectionTypeEnum as DisplayAtSelectionType,
  DisplayAtValueSelectionItem,
} from '@shared/types/display-at-types';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisplayAtControlService {
  private customVariantsMap: Record<string, Record<string, BlockPartWidget>> = {};
  private selectionSubject: Subject<DisplayAtSelectionItem> = new Subject();
  private selectionSubject$: Observable<DisplayAtSelectionItem> = this.selectionSubject.asObservable();
  private customVariantSubject: Subject<DisplayAtCustomVariant> = new Subject();
  private customVariantSelection$: Observable<DisplayAtCustomVariant> = this.customVariantSubject.asObservable();
  private customVariantTemplateSubject: Subject<BlockPartWidget | undefined> = new ReplaySubject(1);
  private customVariantTemplateSelection$: Observable<BlockPartWidget | undefined> = this.customVariantTemplateSubject.asObservable();
  private valueSelectionSubject: Subject<DisplayAtValueSelectionItem> = new Subject();
  private valueSelection$: Observable<DisplayAtValueSelectionItem> = this.valueSelectionSubject.asObservable();

  initCustomVariantsMap(ownerId: string): void {
    this.customVariantsMap[ownerId] = {};
  }

  destroyCustomVariantsMap(ownerId: string): void {
    delete this.customVariantsMap[ownerId];
  }

  getSelection$(): Observable<DisplayAtSelectionItem> {
    return this.selectionSubject$;
  }

  doUpdateSelection(
    ownerId: string,
    attributeId: string,
    selectedVariant: string,
    selectionType: DisplayAtSelectionType = DisplayAtSelectionType.DISPLAY,
  ): void {
    this.selectionSubject.next({ ownerId, attributeId, selectedVariant, selectionType });
  }

  getCustomVariantSelection$(): Observable<DisplayAtCustomVariant> {
    return this.customVariantSelection$;
  }

  doUpdateCustomVariantSelection(ownerId: string, attributeId: string, selectedVariant: string, cardWidgetId: string): void {
    const selectionType = DisplayAtSelectionType.DISPLAY;
    this.customVariantSubject.next({ ownerId, attributeId, selectedVariant, selectionType, cardWidgetId });
  }

  doUpdateLoadedCustomVariantTemplate(ownerId: string, attributeId: string, customVariantTemplate: BlockPartWidget): void {
    if (!this.customVariantsMap[ownerId]) {
      this.initCustomVariantsMap(ownerId);
    }
    this.customVariantsMap[ownerId][attributeId] = customVariantTemplate;
    this.customVariantTemplateSubject.next(customVariantTemplate);
  }

  doClearLoadedCustomVariantTemplate(ownerId: string, attributeId: string): void {
    if (this.customVariantsMap[ownerId]) {
      delete this.customVariantsMap[ownerId][attributeId];
      this.customVariantTemplateSubject.next(undefined);
    }
  }

  getCustomVariantTemplateSelection$(ownerId: string, attributeId: string): Observable<BlockPartWidget> {
    return this.customVariantTemplateSelection$.pipe(
      filter(() => this.customVariantsMap[ownerId] && !!this.customVariantsMap[ownerId][attributeId]),
      map(() => this.customVariantsMap[ownerId][attributeId]),
    );
  }

  doUpdateValueSelection(ownerId: string, artifact: NewArtifact, attributeId: string, value: any): void {
    this.valueSelectionSubject.next({ ownerId, attributeId, artifact, value });
  }

  getValueSelection$(): Observable<DisplayAtValueSelectionItem> {
    return this.valueSelection$;
  }
}
