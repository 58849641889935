import { Injectable } from '@angular/core';
import { ListQueryDto } from '@api/models';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NAME_KEY, URL_KEY_VALUE_SAVE_TO_FOLDER_ID } from '@shared/constants/constants';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactLinkDialogComponent } from '@widgets/link-popup/artifact-link-dialog.component';
import { ListWidgetTableHelper } from '@widgets/shared/components/artifact-list-table/services/list-widget-table.helper';
import { GroupAttributeItem } from '@widgets/shared/components/artifact-list-table/types/list-widget-grouping.types';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { ListWidgetType } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { ClickActionSettings } from '@widgets/shared/types/click-action-settings';
import { MenuItem } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ListWidgetComponent } from '../list-widget.component';

@Injectable()
export class ListWidgetHelper {
  context: ListWidgetComponent | ArtifactLinkDialogComponent;

  constructor(
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly listWidgetTableHelper: ListWidgetTableHelper,
    private readonly elvisU: ElvisUtil,
  ) {}

  init(context: ListWidgetComponent | ArtifactLinkDialogComponent): void {
    this.context = context;
  }

  getLoadDataMethod(): (params?: Partial<ListReqMetaData>) => Promise<ListResDtoI<any>> {
    return (params?: Record<string, any>) => {
      return lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: params as ListQueryDto }));
    };
  }

  getClickActionSettings(): ClickActionSettings {
    return {
      emittingKey: this.context.m.settings.urlKeys.emittingKeys.artifactId,
      emittingFolderKey: this.context.m.settings.urlKeys.emittingKeys.saveToFolderId,
      shouldEmitFolderKey: this.context.m.settings.emitFolderUrl,
      openInNewTab: this.context.m.settings.goToPageInNewTab,
      actionType: this.context.m.settings.rowClickHandleAction,
      isHandled: this.context.m.settings.rowClickHandle,
      selectedPage: this.context.m.settings.selectedPage,
      externalPage: this.context.m.settings.externalPage,
      filtersForQueryParams: [],
    };
  }

  onArtifactTypeChange(): void {
    this.resetColumns();
    this.generateAddButtonOptions();
    if (this.context.m.state) {
      // QUESTION: what is literal filters here???
      this.context.m.state.filters['artifactTypeId'] = [
        { value: this.context.m.selected.artifactTypes.map(type => type.value.id), matchMode: 'in', operator: 'and' },
      ];

      if (this.context.m.hash) {
        this.context.localStorageService.set(this.context.m.hash, this.context.m.state);
      }
    }
    this.context.m.settings.showTable = false;
    setTimeout(() => (this.context.m.settings.showTable = true));
  }

  generateAddButtonOptions(): void {
    if (!this.context.m) return;

    this.context.m.addButtonItems = [];
    let artifactTypes: SelectOption<string, NewArtifactType>[] = [];

    if (this.isLoadByArtifactType(this.context.m.settings.loadMode)) {
      artifactTypes = this.context.m.selected.artifactTypes;
    } else {
      artifactTypes = this.generateArtifactTypesFromPreferredModuleIds();
    }
    artifactTypes.forEach(option => {
      const menuItem = this.createMenuItemFromArtifactType(option, this.context.m.settings.urlKeys.emittingKeys.saveToFolderId);
      this.context.m.addButtonItems.push(menuItem);
    });
  }

  createMenuItemFromArtifactType(artifactTypeOption: SelectOption<string, NewArtifactType>, folderParamKey: string): MenuItem {
    const { value, label } = artifactTypeOption;
    const routerLink = ['/page', value.defaultPageId];
    const queryParams = { [folderParamKey || URL_KEY_VALUE_SAVE_TO_FOLDER_ID]: this.context.m.settings.folderId };
    return { id: value.id, label, routerLink, queryParams, target: '_blank' };
  }

  setFolderIdToAddButtonItems(): void {
    const folderParamKey = this.context.m.settings.urlKeys.emittingKeys.saveToFolderId || URL_KEY_VALUE_SAVE_TO_FOLDER_ID;
    this.context.m.addButtonItems.forEach(button => (button.queryParams = { [folderParamKey]: this.context.m.settings.folderId }));
  }

  onListTypeChange(listType: ListWidgetType): void {
    listType;
    this.resetColumns();
  }

  setGroupingOptions(): void {
    const dataTypesMap = this.context.m.options.dataTypes.listMap;
    const attributeIds = this.listWidgetTableHelper.getRelevantAttributeIds(this.context.m.options, this.context.m.settings, this.context.m.selected);

    const groupAttributeItems: GroupAttributeItem[] = this.context.m.options.attributes
      .filterByKey('id', [...attributeIds])
      .filter(attribute => this.listWidgetTableHelper.isRelevantGroupAttribute(attribute, dataTypesMap))
      .map(attribute => ({
        id: attribute.id,
        name: attribute.name,
        multipleValues: attribute.multipleValues,
        baseDataType: dataTypesMap[attribute.dataTypeId].baseDataType as BaseDataType,
        kind: dataTypesMap[attribute.dataTypeId].kind,
        dataType: dataTypesMap[attribute.dataTypeId],
        dataTypeId: attribute.dataTypeId,
      }));
    const allGroupItems = [...groupAttributeItems, ...this.listWidgetTableHelper.getDefaultGroupAttributeItems()];
    this.context.m.settings.groupingOptions = this.elvisU.transformAnyToSelectOptions(allGroupItems, NAME_KEY);
  }

  synchronizeGroupingSettings(): void {
    if (this.context.m.settings.grouping.groupingAttributes) {
      this.context.m.settings.grouping.groupingAttributes.forEach((groupingAttribute, index) => {
        const optionFound = this.context.m.settings.groupingOptions.find(groupingOption => groupingOption.value.id === groupingAttribute.value.id);
        optionFound && (this.context.m.settings.grouping.groupingAttributes[index] = optionFound);
      });
    }
  }

  resetColumns(): void {
    this.context.m.options.columns.setList([]);
    this.context.m.selected.setColumns([]);
  }

  isLoadByArtifactType(loadMode: ListWidgetTableLoadModeEnum): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byArtifactType;
  }

  generateArtifactTypesFromPreferredModuleIds(): SelectOption<string, NewArtifactType>[] {
    if (!this.context.m.selected.module) return [];

    const selectedArtifactType = this.context.m.options.artifactTypes.listMap[this.context.m.selected.module!.artifactTypeId];
    return (selectedArtifactType.preferredArtifactTypeIds || []).map(
      artifactTypeId =>
        new SelectOption(this.context.m.options.artifactTypes.listMap[artifactTypeId].name, this.context.m.options.artifactTypes.listMap[artifactTypeId]),
    );
  }
}
