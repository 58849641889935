import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvancedDateFilter, AdvancedDateFilterObject } from '@shared/components/date-filter/types/date-filter.types';
import { DateFilterEnum, DateRangeFilterEnum, FilterOperatorEnum } from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { TranslateUtil } from '@shared/utils/translateUtil';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() advancedDateFilterObject: AdvancedDateFilterObject;
  @Input() showTime: boolean;
  @Input() filter: (value: any) => any;
  @Input() onApplyCb: (value: any, filterType?: DateRangeFilterEnum | DateFilterEnum) => any;
  @Input() onClearCb: () => any;

  @Output() onMatchModeChange: EventEmitter<string> = new EventEmitter();

  filterTypeOptions: SelectOption<string, DateRangeFilterEnum | DateFilterEnum>[] = [];
  filterOperatorOptions: SelectOption<string, FilterOperatorEnum>[];

  constructor(private readonly filterMetadataUtil: FilterMetadataUtil, private readonly translateUtil: TranslateUtil) {
    this.filterTypeOptions = this.generateFilterTypeOptions();
    this.filterTypeOptions.forEach(option => {
      this.translateUtil.get(option.value).then(res => {
        option.label = res;
      });
    });
    this.filterOperatorOptions = this.generateFilterOperatorOptions();
  }

  ngOnInit(): void {
    this.transformFilterValuesToClient();
  }

  onFilterTypeChange(filterType: DateRangeFilterEnum | DateFilterEnum, index: number): void {
    this.advancedDateFilterObject.filters[index].value = filterType === DateRangeFilterEnum.dateBetween ? [null, null] : undefined;
    this.onMatchModeChange.emit(filterType);
  }

  isCalendarType(filterType: DateRangeFilterEnum | DateFilterEnum): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo].includes(filterType as DateFilterEnum);
  }

  onApply(): void {
    this.filter(this.advancedDateFilterObject.filters[0]?.value);
    this.onApplyCb(this.advancedDateFilterObject.filters[0]?.value, this.advancedDateFilterObject.filters[0]?.filterType);
  }

  onClear(): void {
    this.advancedDateFilterObject.filters = [new AdvancedDateFilter()];
    this.filter(null);
    this.onClearCb();
  }

  // TODO: add and clear filter is problematic, think about solution (new-core-list filtering would needed to be changed)
  onAddFilter(): void {
    this.advancedDateFilterObject.filters.push(new AdvancedDateFilter());
  }

  onRemoveFilter(index: number): void {
    this.advancedDateFilterObject.filters.splice(index, 1);
  }

  isFilterValid(): boolean {
    const { filterType, value } = this.advancedDateFilterObject.filters[0];
    if (filterType === DateRangeFilterEnum.dateBetween) {
      const range = value as Date[];
      return !!range[0] && !!range[1] && range[0] <= range[1];
    }
    return this.filterMetadataUtil.isFilterValidAndActive(value, filterType);
  }

  private transformFilterValuesToClient(): void {
    this.advancedDateFilterObject.filters.forEach(filter => {
      if (filter.filterType) {
        if (this.isCalendarType(filter.filterType) && typeof filter.value === 'string') filter.value = new Date(filter.value);
        if (filter.filterType === DateRangeFilterEnum.dateBetween && typeof filter.value === 'object') {
          filter.value = (filter.value as string[] | Date[]).map(val => (val = typeof val === 'string' ? new Date(val) : val));
        }
      }
    });
  }

  private generateFilterTypeOptions(): SelectOption<string, DateRangeFilterEnum | DateFilterEnum>[] {
    return [
      ...Object.keys(DateFilterEnum).map(option => new SelectOption(option, DateFilterEnum[option as keyof typeof DateFilterEnum])),
      ...Object.keys(DateRangeFilterEnum)
        .filter(ruleType => ruleType !== DateRangeFilterEnum.custom)
        .map(option => new SelectOption(option, DateRangeFilterEnum[option as keyof typeof DateRangeFilterEnum])),
    ];
  }

  private generateFilterOperatorOptions(): SelectOption<string, FilterOperatorEnum>[] {
    return Object.keys(FilterOperatorEnum).map(operator => new SelectOption(operator, FilterOperatorEnum[operator as keyof typeof FilterOperatorEnum]));
  }

  private isNumericType(filterType: DateRangeFilterEnum | DateFilterEnum): boolean {
    return this.filterMetadataUtil.isFilterNumeric(filterType as DateRangeFilterEnum);
  }
}
