<p-pickList [responsive]="true"
            dragdrop="true"
            sourceHeader="{{'Applications user can NOT access' | translate}}"
            [sourceStyle]="{'height':'250px'}"
            targetHeader="{{'Applications user can access' | translate}}"
            [targetStyle]="{'height':'250px'}"
            [showSourceControls]="false"
            [showTargetControls]="false"
            [source]="m.userTenantApplications"
            [target]="m.user.applications">
  <ng-template let-application pTemplate="item">
    <div class="user-application-row">
      <label> {{application.id | getApplicationName : m.applications}} </label>
      <span>
        Admin: <p-checkbox binary="true" id="isAdmin" [(ngModel)]="application.isAdmin"></p-checkbox>
      </span>
    </div>
  </ng-template>
</p-pickList>
