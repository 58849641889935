import { SidebarPosition, SidebarPositionSettings } from '@shared/services/sidebar-widget.service';

export class PageBuilderSidebar {
  constructor(
    public visible: boolean = false,
    public modal: boolean = true,
    public position: SidebarPosition = SidebarPosition.left,
    public fullScreen: boolean = false,
  ) {}

  openSidebar(withMask = true, settings: SidebarPositionSettings = { position: SidebarPosition.left, fullScreen: false }): void {
    // todo check overlay settings
    this.modal = false;
    withMask;
    // this.modal = withMask;
    this.position = settings.position;
    this.fullScreen = settings.fullScreen;
    this.visible = true;
  }

  closeSidebar(): void {
    this.visible = false;
    this.modal = true;
  }
}
