import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImportRequestDto, PageResponseDto, ParsedExportResponseDto } from '@api/models';
import { TenantExportImportService } from '@api/services';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent {
  @Input() inputTypeCheck: ImportRequestDto['type'] & 'template';
  @Input() headerTitle = 'Import parts';
  @Input() readonly importPartsMethod: (params: { body: ImportRequestDto }) => Observable<any[]>;
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  importField?: FileUpload;
  importedFile?: File;
  parsedFile?: ParsedExportResponseDto;

  constructor(
    private readonly announcement: AnnouncementService,
    private readonly tenantExportImportService: TenantExportImportService,
    private readonly blockUiService: BlockUiService,
  ) {}

  hideModal(): void {
    this.visibleChange.emit(false);
    this.importedFile = undefined;
    this.parsedFile = undefined;
    this.importField?.clear();
    this.importField = undefined;
  }

  getBaseTemplateType(type: string): string {
    return type.split('|')[0];
  }

  async parseFile({ files }: { files: Array<File> }, importField: FileUpload): Promise<void> {
    if (!files || !files.length) {
      await this.announcement.error('You need to choose file first!');
      return;
    }

    this.importField = importField;
    this.importedFile = files[0];
    const res: ParsedExportResponseDto | null = await this.parseExportFile(this.importedFile);

    if (res === null) {
      this.importField.clear();
      return;
    }

    this.parsedFile = res;
  }

  async importFile(): Promise<void> {
    const importPartsHandler = async (requestBody: ImportRequestDto): Promise<any[] | null> => {
      this.setUiBlocked(true);
      let res = null;
      try {
        res = await lastValueFrom(
          this.importPartsMethod({
            body: {
              file: new Blob([requestBody.file], {
                type: 'text/yaml',
              }),
              idMap: requestBody.idMap,
              ...(this.inputTypeCheck ? { type: this.inputTypeCheck } : {}),
            },
          }),
        );
      } catch (e) {
        console.log(e);
        await this.announcement.error('Something went wrong during importing file');
      }
      this.setUiBlocked(false);
      return res;
    };

    if (!this.importedFile) {
      await this.announcement.error('You need to choose file first!');
      return;
    }

    //TODO add option to map parts
    const res: PageResponseDto[] | null = await importPartsHandler({ file: this.importedFile!, idMap: undefined });

    if (res === null) {
      this.importField!.clear();
      return;
    }

    this.hideModal();
    await this.announcement.success('File has been imported successfully');
  }

  private async parseExportFile(file: File): Promise<ParsedExportResponseDto | null> {
    this.setUiBlocked(true);
    let res = null;
    try {
      res = await lastValueFrom(this.tenantExportImportService.exportImportControllerParseImport({ body: { file: new Blob([file], { type: 'text/yaml' }) } }));
    } catch (e) {
      console.log(e);
      await this.announcement.error('Something went wrong during parsing export file');
    }
    this.setUiBlocked(false);
    return res;
  }

  private setUiBlocked(blocked: boolean): void {
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }
}
