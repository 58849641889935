<div *ngIf="model.options.currentFormItem.length; else emptyAttributeFormatMessage">
  <div class="utility-button-group">
    <button
      (click)="artifactWidgetHelper.setAllAttributesEditable(model, true)"
      class="p-button utility-button"
      label="{{ 'Set all editable' | translate }}"
      pButton
    ></button>
    <button
      (click)="artifactWidgetHelper.setAllAttributesEditable(model, false)"
      class="p-button p-button-secondary utility-button"
      label="{{ 'Set all readonly' | translate }}"
      pButton
    ></button>
    <button
      (click)="copyService.pasteStyleToAllItems(model.selected.item.value, model.selected.item.meta, model)"
      [ngClass]="{ 'visibility-hidden': !model.selected.item }"
      class="p-button p-button-warning utility-button"
      label="{{ 'Apply this style to all items' | translate }}"
      pButton
    ></button>
  </div>
  <div class="format-settings-dropdown">
    <p-dropdown
      (onChange)="onSelectedItemChange($event.value)"
      [(ngModel)]="model.selected.item"
      [autoDisplayFirst]="false"
      [filter]="true"
      [options]="model.options.currentFormItem"
      optionDisabled="enable-all"
      optionLabel="label"
      placeholder="Choose attribute or link-type"
      appendTo="body"
    >
    </p-dropdown>
  </div>
  <div *ngIf="model.selected.item">
    <app-format-settings [model]="model" [hash]="hash" [selectedItem]="model.selected.item"></app-format-settings>
  </div>
</div>
<ng-template #emptyAttributeFormatMessage>
  <span class="empty-format-message"> Please select at least one attribute </span>
</ng-template>
