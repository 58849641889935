import { UserResponseDto } from '@api/models/user-response-dto';
import { Environment } from '@environments/environment';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class ArtifactListModel {
  constructor(
    public rowsPerPage: number = Environment.tableConfig.rowsPerPage,
    public rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
    public rows: NewArtifact[] = [],
    public columns: TableColumn[] = [],
    public artifactTypeOptions: SelectOption<string, NewArtifactType>[] = [],
    public artifactType: NewArtifactType | null = null,
    public attributeOptions: SelectOption<string, string>[] = [],
    public artifactTypes = new ListContainer<NewArtifactType>(),
    public attributes = new ListContainer<NewAttribute>(),
    public dataTypes = new ListContainer<NewDataType>(),
    public users = new ListContainer<UserResponseDto>(),
    public selectedAttributeIds: string[] = [],
    public showTable: boolean = true,
  ) {}
}
