import { Pipe, PipeTransform } from '@angular/core';
import { ALL_OPTIONS_FILTER_VALUE, EMPTY_FILTER_VALUE_SINGLE, EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';

@Pipe({ name: 'getBooleanFilterValue' })
export class GetBooleanFilterValuePipe implements PipeTransform {
  transform(value: boolean | string | null): string {
    switch (value) {
      case true:
      case false:
        return value.toString();
      case EMPTY_FILTER_VALUE_SINGLE:
        return EMPTY_OPTION_FILTER_URL_VALUE;
      default:
        return ALL_OPTIONS_FILTER_VALUE;
    }
  }
}
