import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactResponseDto } from '@api/models';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { NewAttribute, NewClientAttribute } from '../../types/attribute.types';

@Component({
  selector: 'app-file-attribute',
  templateUrl: './file-attribute.component.html',
  styleUrls: ['./file-attribute.component.scss'],
})
export class FileAttributeComponent {
  @Input() clientAttribute: NewClientAttribute;
  @Input() attributesMap: Record<string, NewAttribute>;
  @Input() files: Record<string, ArtifactResponseDto>;
  @Input() unSavedFiles: Record<string, Record<string, File[]>>;
  @Input() artifactId: string;
  @Input() truncate: boolean;
  @Input() index: number;
  @Input() isEditMode: boolean;
  @Input() onChangeCb: (value: string | string[]) => void;

  @ViewChild('fileUpload') fileUpload: FileUpload;

  uploadHandler(event: { files: File[] }): void {
    setTimeout(() => {
      if (!event.files.length) return;

      this.processFilesToModel(this.fileUpload.multiple ? event.files : [event.files[0]]);
      this.fileUpload.clear();
      this.onChange();
    });
  }

  deleteFile(fileId?: string, unsavedFile?: File): void {
    const savedFile = fileId ? this.files[fileId] : this.files[this.clientAttribute.value];

    savedFile && this.removeFileFromSavedFiles(savedFile);
    this.removeFileFromUnsavedFiles(unsavedFile);

    this.clientAttribute = { ...this.clientAttribute };

    this.onChange();
  }

  removeFileFromSavedFiles(file: ArtifactResponseDto): void {
    if (this.attributesMap[this.clientAttribute?.id]?.multipleValues)
      this.clientAttribute.value = this.clientAttribute.value.filter((id: string) => id !== file.id);
    else this.clientAttribute.value = null;
  }

  removeFileFromUnsavedFiles(file?: File): void {
    if (this.unSavedFiles[this.artifactId] && this.unSavedFiles[this.artifactId][this.clientAttribute.id]) {
      this.unSavedFiles[this.artifactId][this.clientAttribute.id] = this.unSavedFiles[this.artifactId][this.clientAttribute.id].filter(f => f !== file);
    }
  }

  private processFilesToModel(files: File[]): void {
    if (!this.unSavedFiles[this.artifactId]) this.unSavedFiles[this.artifactId] = {};
    if (!this.unSavedFiles[this.artifactId][this.clientAttribute.id]) this.unSavedFiles[this.artifactId][this.clientAttribute.id] = [];
    this.unSavedFiles[this.artifactId][this.clientAttribute.id] = this.fileUpload.multiple
      ? [...this.unSavedFiles[this.artifactId][this.clientAttribute.id], ...files]
      : files;

    // change detection in pipe
    this.unSavedFiles = { ...this.unSavedFiles };
  }

  private onChange(): void {
    this.onChangeCb && this.onChangeCb(this.clientAttribute.value);
  }
}

@NgModule({
  imports: [FormsModule, CommonModule, FileUploadModule, ElvisSharedModule],
  exports: [FileAttributeComponent],
  declarations: [FileAttributeComponent],
})
export class FileAttributeModule {}
