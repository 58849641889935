<div *ngIf="isLayoutMode; else advancedModeTemplate" class="container">
  <p-sidebar
    #sidebar
    (onHide)="onHideSidebar()"
    (onShow)="onShowSidebar(); s.fixLayer(sidebar)"
    [(visible)]="m.settings.display"
    [dismissible]="m.settings.closeEvents.outside ? false : false"
    [modal]="m.settings.closeEvents.outside ? false : false"
    [ngClass]="[m.settings.position === 'right' ? 'start' : 'end']"
    [position]="m.settings.position"
    [showCloseIcon]="(!m.settings.isInside || !m.settings.isFixed) && m.settings.closeEvents.cancelX || m.options.isPageBuilder"
    [closeOnEscape]="!m.settings.isFixed"
    [style]="m.settings.styles"
    [autoZIndex]="m.settings.autoZIndex"
    [transitionOptions]="m.settings.isFixed && m.settings.isInside ? '0s' : '0.3s'"
    [appendTo]="'body'"
  >
    <div [id]="widget.id || m.newWidgetTempId"></div>
    <div
      (cdkDropListDropped)="dropSection($event)"
      *ngIf="m.settings.display && showWidgetSettings"
      cdkDropList
      class="sidebar_content"
      [ngClass]="s.isVertical() ? 'vertical' : ''"
    >
      <ng-container *ngIf="m.parts && m.parts.length">
        <section *ngFor="let part of m.parts; let partI = index" cdkDrag class="container sidebar_section">
          <div [ngClass]="showWidgetSettings ? 'active' : ''" class="sidebar_block_part">
            <div *ngIf="showWidgetSettings" class="sidebar_buttons_toolbar sidebar_block_part_toolbar">
              <i cdkDragHandle class="bi bi-arrows-move" pRipple pTooltip="{{ 'Move' | translate }}"
                 tooltipPosition="top"></i>
              <i
                (click)="setWidgetAsAdvancedMode(part.widget)"
                class="bi bi-tools"
                pRipple
                pTooltip="{{ 'Advanced Mode' | translate }}"
                tooltipPosition="top"
              ></i>
              <i
                *ngIf="part.widget?.templateId"
                [escape]="false"
                class="bi bi-recycle toolbar__icon"
                disabled
                pRipple
                pTooltip="<div style='text-align: center'>{{ 'Reused template' | translate }}<br>{{ part.widget.templateName }}</div>"
                tooltipPosition="top"
              ></i>
              <i (click)="sidebarCopyPaster.copyLayout(part)" class="pi pi-copy" pRipple
                 pTooltip="{{ 'Copy' | translate }}" tooltipPosition="top"></i>
              <i
                (click)="sidebarCopyPaster.pasteLayout(part)"
                *ngIf="!!sidebarCopyPaster.copiedBlockPart"
                class="bi bi-clipboard-plus"
                pRipple
                pTooltip="{{ 'Paste' | translate }}"
                tooltipPosition="top"
              ></i>
              <i (click)="s.deletePageBlockPart(part)" class="bi bi-trash" pRipple pTooltip="{{ 'Delete' | translate }}"
                 tooltipPosition="top"></i>
            </div>
            <div [id]="part.hash">
              <app-widget-container
                *ngIf="part.widget"
                [applicationId]="applicationId"
                [hash]="part.hash"
                [disabled]="disabled"
                [isLayoutMode]="true"
                [widget]="part.widget"
                [isInSidebar]="true"
                appWidgetContainerStopPropagation
              >
              </app-widget-container>
            </div>
            <div (click)="setWidgetList()" *ngIf="!part.widget && !isLayoutMode" class="page_block_part__default">
              <i class="bi bi-plus-square-dotted"></i>
            </div>
          </div>
        </section>
      </ng-container>

      <div [ngClass]="isPreviewMode ? 'active' : ''" class="sidebar_block_part">
        <div class="sidebar_buttons_toolbar sidebar_block_part_toolbar">
          <i
            (click)="pasteWidget()"
            *ngIf="!!sidebarCopyPaster.copiedBlockPart"
            class="bi bi-clipboard-plus"
            pRipple
            pTooltip="{{ 'Paste' | translate }}"
            tooltipPosition="top"
          ></i>
        </div>
        <div class="widget">
          <div (click)="setWidgetList()" class="add">
            <i class="bi bi-plus-square-dotted"></i>
          </div>
        </div>
      </div>
    </div>

    <!--disable cdkDrag in preview-->
    <div *ngIf="m.settings.display && m.parts && m.parts.length && isPreviewMode" class="sidebar_content">
      <section *ngFor="let part of m.parts; let partI = index" class="container sidebar_section">
        <div class="sidebar_block_part">
          <div [id]="part.hash">
            <app-widget-container
              *ngIf="part.widget"
              [pageId]="pageId"
              [page]="page"
              [applicationId]="applicationId"
              [isPreviewMode]="isPreviewMode"
              [hash]="part.hash"
              [disabled]="disabled"
              [isLayoutMode]="true"
              [widget]="part.widget"
              appWidgetContainerStopPropagation
            ></app-widget-container>
          </div>
          <div (click)="setWidgetList()" *ngIf="!part.widget && !isLayoutMode" class="page_block_part__default">
            <i class="bi bi-plus-square-dotted"></i>
          </div>
        </div>
      </section>
    </div>
  </p-sidebar>
  <p-sidebar
    #advancedSidebar
    (onHide)="onPageBuilderSidebarHide()"
    (onShow)="s.fixLayer(advancedSidebar)"
    [dismissible]="true"
    [modal]="false"
    [position]="widgetService.getPositionSettings(advancedWidget?.id).position"
    [fullScreen]="widgetService.getPositionSettings(advancedWidget?.id).fullScreen"
    [style]="!widgetService.getPositionSettings(advancedWidget?.id).fullScreen ? { width: '40em' } : {}"
    [visible]="m?.widgetPicker?.visible"
    [appendTo]="'body'"
    styleClass="widget-advanced-mode-sidebar"
  >
    <ng-template pTemplate="header">
      <app-sidebar-header
        *ngIf="advancedWidget"
        [name]="advancedWidget.code | underscoreToDash"
        [widgetId]="advancedWidget?.id"
        (onChangePosition)="togglePanelPosition()"
      ></app-sidebar-header>
    </ng-template>
    <br />
    <div class="layout-wrapper dynamic-float-label">
      <app-widget-list
        (fromTemplateClick)="showWidgetTemplates()"
        (widgetSelection)="setWidgetToDataModel($event)"
        *ngIf="blockPartForWidgetPlacement"
        [isFromTemplateButtonVisible]="true"
        [widgetOptions]="m.widgetOptions"
      ></app-widget-list>

      <app-widget-container *ngIf="advancedWidget" [applicationId]="applicationId"
                            [widget]="advancedWidget"></app-widget-container>
    </div>
  </p-sidebar>

  <div [style]="m.settings.layoutBtn.getButtonAlignStyles()">
    <button
      (click)="s.onButtonClick()"
      (mouseenter)="m.settings.onHoverIn()"
      (mouseleave)="m.settings.onHoverOut()"
      *ngIf="m.settings.arrowBtn.display && !(m.settings.isFixed && m.settings.isInside)"
      [ngClass]="m.settings.arrowBtn.class"
      [ngStyle]="m.settings.arrowBtn.styles"
      class="p-button control-btn"
      pButton
      type="button"
      appWidgetContainerStopPropagation
    >
      <div *ngIf="!m.settings.arrowBtn.isFullLength" style="margin: 7px 14px">
        <i [ngClass]="m.settings.arrowBtn.icon" [ngStyle]="m.settings.arrowBtn.iconStyles" class="pi"></i>
        {{ m.settings.arrowBtn.buttonContent }}
      </div>
      <div *ngIf="m.settings.arrowBtn.isFullLength" [ngClass]="m.settings.arrowBtn.isRotate ? 'vertical-container' : ''"
           style="margin: 7px 14px">
        <div>
          <i [ngClass]="m.settings.arrowBtn.icon" [ngStyle]="m.settings.arrowBtn.iconStyles" class="pi"></i>
          <span>{{ m.settings.arrowBtn.buttonContent }}</span>
        </div>
      </div>
    </button>

    <button
      (click)="s.onButtonClick(true)"
      (mouseenter)="m.settings.onHoverIn(true)"
      (mouseleave)="m.settings.onHoverOut(true)"
      *ngIf="m.settings.layoutBtn.display && isLayoutMode && !(m.settings.isFixed && m.settings.isInside)"
      [ngStyle]="m.settings.layoutBtn.styles"
      class="p-button layout-btn"
      pButton
      type="button"
    >
      <i [ngClass]="m.settings.layoutBtn.icon" [ngStyle]="m.settings.layoutBtn.iconStyles" class="pi ml-1"></i>
      {{ m.settings.layoutBtn.text }}
    </button>
  </div>
</div>

<ng-template #advancedModeTemplate>
  <app-sidebar-widget-settings [options]="m.options" [settings]="m.settings" [s]="s"></app-sidebar-widget-settings>
</ng-template>
