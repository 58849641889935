import { Injectable } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { WorkflowConditionGroupDto } from '@api/models/workflow-condition-group-dto';
import { CacheDataHolderService } from '@shared/cache/cache-data-holder.service';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { DYNAMIC_ATTRIBUTE_PREFIX, DYNAMIC_ATTRIBUTE_SUFFIX } from '@workflows/types';
import { RuleCondition } from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '../../types/conditions/rule-condition-group';

@Injectable({ providedIn: 'root' })
export class RuleConditionValueConverterService {
  constructor(private readonly dataHolder: CacheDataHolderService) {}

  valuesToClient(dto: RuleConditionGroup | null): RuleConditionGroup | null {
    dto?.conditions?.forEach((condition: RuleCondition) => {
      if (!condition.destination?.value) return;

      if (condition.source.value.includes(DYNAMIC_ATTRIBUTE_PREFIX)) {
        condition.source.value = this.dynamicValueToClient(condition.source.value);
      }

      const attribute = this.dataHolder.attributes.listMap[condition.source.value as string];
      if (!attribute) return;

      const dataType = this.dataHolder.dataTypes.listMap[attribute.dataTypeId];
      if (!dataType) return;

      return this.conditionToClient(condition, dataType);
    });

    dto?.groups.forEach((group: RuleConditionGroup) => {
      group = new RuleConditionGroup(this, group);
      this.valuesToClient(group);
    });

    return dto;
  }

  valuesToServer(rule: RuleConditionGroup | null): WorkflowConditionGroupDto | null {
    rule?.conditions?.forEach((condition: RuleCondition) => {
      const attributeId = condition.source.value;

      if (!condition.source.value.includes(DYNAMIC_ATTRIBUTE_PREFIX) && !condition.source.manual) {
        condition.source.value = this.dynamicValueToServer(condition.source.value);
      }

      if (!condition.destination.value) return;

      const attribute = this.dataHolder.attributes.listMap[attributeId as string];
      if (!attribute) return;

      const dataType = this.dataHolder.dataTypes.listMap[attribute.dataTypeId];
      if (!dataType) return;

      return this.conditionToServer(condition, dataType);
    });

    rule?.groups.forEach((group: RuleConditionGroup) => {
      group = new RuleConditionGroup(this, group);
      this.valuesToServer(group);
    });

    return rule;
  }

  private conditionToClient(condition: RuleCondition, dataType: NewDataType): RuleCondition {
    if (!condition.destination.value) {
      return condition;
    }

    if (dataType.isUser) {
      condition.destination.value = this.userToClient(condition.destination.value);
    }

    if (dataType.isEnum) {
      if (!condition.destination.isDynamic) condition.destination.value = this.enumToClient(condition.destination.value, dataType);
    }

    if (dataType.isDateTime || dataType.isDate || dataType.isTime) {
      condition.destination.value = this.dateToClient(condition.destination.value);
    }

    return condition;
  }

  private userToClient(userId: string): SelectOption<string, string>[] {
    return [new SelectOption(this.dataHolder.users.listMap[userId].email, userId)];
  }

  private enumToClient(value: string, dataType: NewDataType): DataTypeValueResponseDto {
    const dataTypeValues = this.dataHolder.dataTypes.listMap[dataType.id].values;
    return dataTypeValues?.find(dataTypeValue => dataTypeValue.value === value) as DataTypeValueResponseDto;
  }

  private dateToClient(value: string): Date {
    return new Date(value);
  }

  private conditionToServer(dto: RuleCondition, dataType: NewDataType): RuleCondition {
    if (!dto.destination.value) {
      return dto;
    }

    if (dataType.isUser) {
      dto.destination.value = this.userToServer(dto.destination.value);
    }

    if (dataType.isEnum) {
      if (!dto.destination.isDynamic) dto.destination.value = this.enumToServer(dto.destination.value);
    }

    return dto;
  }

  private userToServer(value: SelectOption<string, string>[]): string {
    return value[0].value;
  }

  private enumToServer(value: DataTypeValueResponseDto): string {
    return value.value;
  }

  private dynamicValueToClient(value: string): string {
    return value.replace(/^{attributes\["|"].value}/g, '');
  }

  private dynamicValueToServer(value: string): string {
    return `${DYNAMIC_ATTRIBUTE_PREFIX}${value}${DYNAMIC_ATTRIBUTE_SUFFIX}`;
  }
}
