import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { AuthWidgetComponent } from './auth-widget.component';
import { AuthWidgetSettingsComponent } from './components/auth-widget-settings/auth-widget-settings.component';
import { AuthWidgetViewComponent } from './components/auth-widget-view/auth-widget-view.component';
import { ShouldShowEmailPipe } from './pipes/should-show-email.pipe';
import { ShouldShowLogoutButtonPipe } from './pipes/should-show-logout-button.pipe';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { AccordionModule } from 'primeng/accordion';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';

@NgModule({
  declarations: [AuthWidgetComponent, AuthWidgetSettingsComponent, AuthWidgetViewComponent, ShouldShowLogoutButtonPipe, ShouldShowEmailPipe, UserIconComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    OverlayPanelModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    StyleFormModule,
    CommonPipesModule,
    AccordionModule,
    ElvisSharedModule,
  ],
  exports: [AuthWidgetComponent],
})
export class AuthWidgetModule {}
