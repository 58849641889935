import { Injectable } from '@angular/core';
import { LinkListResponseDto, LinkRequestDto, LinkResponseDto, LinkTypeResponseDto } from '@api/models';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { Observable, tap } from 'rxjs';
import { Link } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';

@Injectable({
  providedIn: 'root',
})
// TODO: rename
export class ArtifactLinkService {
  constructor(private readonly tenantLinkService: TenantLinkService, private readonly runtimeStateNotificationService: RuntimeStateNotificationService) {}

  createLink$(
    linkTypeId: string,
    destinationArtifactId: string,
    sourceArtifactId: string,
    doRuntimeStateUpdate?: boolean,
    hash?: string,
  ): Observable<LinkListResponseDto> {
    const body: LinkRequestDto = { linkTypeId, destinationArtifactId, sourceArtifactId };
    return this.tenantLinkService.linkControllerCreate({ body: { links: [body] }, notify: false }).pipe(
      tap(response => {
        if (doRuntimeStateUpdate && response.meta.totalCount) {
          this.runtimeStateNotificationService.events$.next(
            new RuntimeStateNotification(RuntimeStateNotificationEnum.createLink, [destinationArtifactId, sourceArtifactId], hash, linkTypeId),
          );
        }
      }),
    );
  }

  dtoToLink(dto: LinkResponseDto): Link {
    if (!dto) return new Link();

    const { id, sourceArtifactId, destinationArtifactId, linkTypeId, created, updated, deleted } = dto;
    return new Link(
      id,
      { artifactId: sourceArtifactId, artifactTypeId: null, artifact: null, artifactType: null },
      { artifactId: destinationArtifactId, artifactTypeId: null, artifact: null, artifactType: null },
      linkTypeId,
      created,
      updated,
      deleted,
      null,
      null,
      null,
    );
  }

  dtoToLinkType(dto: LinkTypeResponseDto): LinkType {
    if (!dto) return new LinkType();

    const { id, name, applicationId, description, incomingName, outgoingName, uri, restrictions, created, updated, deleted } = dto;
    return new LinkType({
      id,
      applicationId,
      name,
      // directionalLabel: !(name === incomingName && name === outgoingName),   // TODO: directionalLabel
      incomingName,
      outgoingName,
      description,
      uri,
      restrictions,
      created,
      updated,
      deleted,
    });
  }
}
