import { Injectable } from '@angular/core';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { AbstractCardWidgetModeStrategy } from '@widgets/card-widget/services/abstract-card-widget-mode-strategy';

@Injectable()
export class ListItemService extends AbstractCardWidgetModeStrategy {
  constructor(tenantArtifactService: TenantArtifactService, tenantLinkService: TenantLinkService) {
    super(tenantArtifactService, tenantLinkService);
  }

  init(context: CardWidgetComponent): void {
    super.init(context);
  }

  onModeChange(): void {
    this.settings.clickAction.isHandled = false;
  }

  async onArtifactTypeChange(): Promise<void> {
    if (!this.settings.isArtifactTypeSelected) {
      return;
    }

    await this.loadArtifactExample();
    await this.loadLinkedArtifacts();
    await this.loadArtifactFiles();
  }
}
