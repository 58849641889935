import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';

@Pipe({ name: 'isFilterNumericType' })
export class IsFilterNumericTypePipe implements PipeTransform {
  constructor(private readonly filterMetaDataUtil: FilterMetadataUtil) {}

  transform(filterType: DateRangeFilterEnum | DateFilterEnum): boolean {
    return this.filterMetaDataUtil.isFilterNumeric(filterType as DateRangeFilterEnum);
  }
}
