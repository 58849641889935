import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactSort } from '@widgets/shared/components/artifact-sorts/types/artifact-sort';

@Component({
  selector: 'app-artifact-sorts',
  templateUrl: './artifact-sorts.component.html',
  styleUrls: ['./artifact-sorts.component.scss'],
})
export class ArtifactSortsComponent {
  @Input() sortsHolder: { [sortsKey: string]: ArtifactSort[] };
  @Input() sortsKey: string;
  @Input() isMultipleSortsAvailable = false;
  @Input() attributes: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[];

  @Output() sortsChange: EventEmitter<void> = new EventEmitter<void>();

  get sorts(): ArtifactSort[] {
    return this.sortsHolder[this.sortsKey];
  }

  set sorts(sorts: ArtifactSort[]) {
    this.sortsHolder[this.sortsKey] = sorts;
  }

  get isAddSortButtonDisabled(): boolean {
    if (this.isMultipleSortsAvailable) {
      return false;
    }

    return this.sorts.length === 1;
  }

  addSort(): void {
    this.sorts.push(ArtifactSort.initial());
  }

  onSortAttributeChange(sort: ArtifactSort): void {
    sort.resetOrder();
  }

  onSortOrderChange(sort: ArtifactSort): void {
    sort.changeOrder();
    this.sortsChange.emit();
  }

  removeSort(removedSort: ArtifactSort): void {
    this.sorts = this.sorts.filter((sorting: ArtifactSort) => sorting !== removedSort);
    this.sortsChange.emit();
  }
}
