import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceTextEnter',
})
export class ReplaceTextEnterPipe implements PipeTransform {
  async transform(text: string): Promise<string> {
    const arr = String(text).split('\n');
    return '<span>' + arr.join('</span><br><span>') + '</span>';
  }
}

@NgModule({
  declarations: [ReplaceTextEnterPipe],
  exports: [ReplaceTextEnterPipe],
})
export class ReplaceTextEnterModule {}
