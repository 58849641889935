import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Environment } from '@environments/environment';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';

export class LinkTypeListModel {
  constructor(
    public columns: TableColumn[] = [],
    public rowsPerPage: number = Environment.tableConfig.rowsPerPage,
    public rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
    public applications = new ListContainer<ApplicationResponseDto>(),
  ) {}
}
