/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { systemTenantControllerCount } from '../fn/system-tenant/system-tenant-controller-count';
import { SystemTenantControllerCount$Params } from '../fn/system-tenant/system-tenant-controller-count';
import { systemTenantControllerCreate } from '../fn/system-tenant/system-tenant-controller-create';
import { SystemTenantControllerCreate$Params } from '../fn/system-tenant/system-tenant-controller-create';
import { systemTenantControllerDelete } from '../fn/system-tenant/system-tenant-controller-delete';
import { SystemTenantControllerDelete$Params } from '../fn/system-tenant/system-tenant-controller-delete';
import { systemTenantControllerGet } from '../fn/system-tenant/system-tenant-controller-get';
import { SystemTenantControllerGet$Params } from '../fn/system-tenant/system-tenant-controller-get';
import { systemTenantControllerList } from '../fn/system-tenant/system-tenant-controller-list';
import { SystemTenantControllerList$Params } from '../fn/system-tenant/system-tenant-controller-list';
import { systemTenantControllerUpdate } from '../fn/system-tenant/system-tenant-controller-update';
import { SystemTenantControllerUpdate$Params } from '../fn/system-tenant/system-tenant-controller-update';
import { SystemTenantListResponseDto } from '../models/system-tenant-list-response-dto';
import { SystemTenantResponseDto } from '../models/system-tenant-response-dto';

@Injectable({ providedIn: 'root' })
export class SystemTenantService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `systemTenantControllerCount()` */
  static readonly SystemTenantControllerCountPath = '/api/system/tenant/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerCount$Response(params?: SystemTenantControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return systemTenantControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerCount(params?: SystemTenantControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.systemTenantControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `systemTenantControllerGet()` */
  static readonly SystemTenantControllerGetPath = '/api/system/tenant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerGet$Response(params: SystemTenantControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantResponseDto>> {
    return systemTenantControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerGet(params: SystemTenantControllerGet$Params, context?: HttpContext): Observable<SystemTenantResponseDto> {
    return this.systemTenantControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemTenantResponseDto>): SystemTenantResponseDto => r.body)
    );
  }

  /** Path part for operation `systemTenantControllerDelete()` */
  static readonly SystemTenantControllerDeletePath = '/api/system/tenant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerDelete$Response(params: SystemTenantControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantResponseDto>> {
    return systemTenantControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerDelete(params: SystemTenantControllerDelete$Params, context?: HttpContext): Observable<SystemTenantResponseDto> {
    return this.systemTenantControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemTenantResponseDto>): SystemTenantResponseDto => r.body)
    );
  }

  /** Path part for operation `systemTenantControllerList()` */
  static readonly SystemTenantControllerListPath = '/api/system/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerList$Response(params?: SystemTenantControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantListResponseDto>> {
    return systemTenantControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemTenantControllerList(params?: SystemTenantControllerList$Params, context?: HttpContext): Observable<SystemTenantListResponseDto> {
    return this.systemTenantControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemTenantListResponseDto>): SystemTenantListResponseDto => r.body)
    );
  }

  /** Path part for operation `systemTenantControllerUpdate()` */
  static readonly SystemTenantControllerUpdatePath = '/api/system/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemTenantControllerUpdate$Response(params: SystemTenantControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantResponseDto>> {
    return systemTenantControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemTenantControllerUpdate(params: SystemTenantControllerUpdate$Params, context?: HttpContext): Observable<SystemTenantResponseDto> {
    return this.systemTenantControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemTenantResponseDto>): SystemTenantResponseDto => r.body)
    );
  }

  /** Path part for operation `systemTenantControllerCreate()` */
  static readonly SystemTenantControllerCreatePath = '/api/system/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemTenantControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemTenantControllerCreate$Response(params: SystemTenantControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemTenantResponseDto>> {
    return systemTenantControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemTenantControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemTenantControllerCreate(params: SystemTenantControllerCreate$Params, context?: HttpContext): Observable<SystemTenantResponseDto> {
    return this.systemTenantControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemTenantResponseDto>): SystemTenantResponseDto => r.body)
    );
  }

}
