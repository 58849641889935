import { Component, HostBinding, Inject } from '@angular/core';
import { NON_MAPPABLE_FIELDS } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/types/artifact-type-avr-form.types';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { QrCodesAvrType, QrCodesOutputType } from '@shared/services/artifact-visual-representation/qr-codes/dto/qr-codes.input-mapper.dto';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-avr-qr-codes-non-mappable-fields',
  templateUrl: './avr-qr-codes.non-mappable-fields.component.html',
  styleUrls: ['./avr-qr-codes.non-mappable-fields.component.scss'],
})
export class AvrQrCodesNonMappableFieldsComponent {
  @HostBinding('class') hostClass = 'p-datatable-tbody';

  public outputTypeOptions: SelectOption<string, string>[] = [];

  constructor(@Inject(NON_MAPPABLE_FIELDS) public nonMappableFields: BaseAvrInputMapperDto<QrCodesAvrType>['nonMappableFields']) {
    this.outputTypeOptions = Object.values(QrCodesOutputType).map(outputType => new SelectOption(outputType, outputType));
  }
}
