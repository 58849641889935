import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { WidgetOption, WidgetType } from '@widgets/widgets-core/types/widgets.types';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent {
  @Input() widgetOptions: WidgetOption<any>[];
  @Input() isFromTemplateButtonVisible: boolean;
  @Input() connectionIds: string[];

  @Output() widgetSelection: EventEmitter<WidgetType> = new EventEmitter<WidgetType>();
  @Output() fromTemplateClick: EventEmitter<void> = new EventEmitter<void>();

  draggedItemWidget: WidgetOption<any> | null = null;
  draggedItemIndex: number | null = null;

  readonly widgetContentType: ContentType = ContentType.widget;

  get isDraggingDisabled(): boolean {
    return !this.connectionIds || !this.connectionIds.length;
  }

  showWidgetTemplates(): void {
    this.fromTemplateClick.emit();
  }

  selectWidget(widgetType: WidgetType): void {
    this.widgetSelection.emit(widgetType);
  }

  setDraggedItemData(widget: WidgetOption<any>, index: number): void {
    this.draggedItemWidget = widget;
    this.draggedItemIndex = index;
  }

  resetDraggedItemData(): void {
    this.draggedItemWidget = null;
    this.draggedItemIndex = null;
  }
}
