import { Injectable } from '@angular/core';
import { CardWidgetEventType } from '@widgets/card-widget/types/card-widget-event-type';
import { Subject, Subscription } from 'rxjs';

@Injectable()
// TODO: get rid of duplicate
export class CardWidgetEventsService {
  private eventsChannel: Map<CardWidgetEventType, Subject<any>> = new Map<CardWidgetEventType, Subject<any>>();

  publish<Data>(eventType: CardWidgetEventType, data: Data): void {
    this.register<Data>(eventType);
    (this.eventsChannel.get(eventType) as Subject<Data>).next(data);
  }

  subscribe<Data>(eventType: CardWidgetEventType, handler: (value: Data) => void): Subscription {
    this.register<Data>(eventType);

    return this.eventsChannel.get(eventType)!.subscribe(handler);
  }

  private register<Data>(eventType: CardWidgetEventType): void {
    if (!this.eventsChannel.has(eventType)) {
      this.eventsChannel.set(eventType, new Subject<Data>());
    }
  }
}
