import { SidebarModalSettings } from './sidebar-modal-widget-settings.types';
import { SidebarModalPosition, SizeType } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import {
  LAYOUT_SIDEBAR_INACTIVE_FLAG,
  LAYOUT_SIDEBAR_SELECTOR,
  PAGE_BUILDER_FOOTER,
  PAGE_BUILDER_HEADER,
  PAGE_BUILDER_TOP_BAR,
} from '@widgets/sidebar-widget/constants/sidebar-widget.constants';
import { SidebarModalConstants } from '@widgets/sidebar-modal-widget/constants/constants';

export class SidebarModalStyles {
  position = 'fixed';
  width: string;
  height: string;
  backgroundColor: string | null = SidebarModalConstants.DEFAULT_BACKGROUND_COLOR;
  top: string;
  left: string;
  right: string;
  bottom: string;
  zIndex = '10';
  overflowX: string;
  overflowY: string;

  pageElement: any;

  constructor(settings?: SidebarModalSettings) {
    settings && this.update(settings);
  }

  applyStyles(styles: any): void {
    Object.assign(this, styles);
  }

  update(settings: SidebarModalSettings): void {
    settings.styles && Object.assign(this, settings.styles);

    const { width, height, bgColor, position, topGap, bottomGap, leftGap, rightGap, byPageContent, overflowX, overflowY } = settings;
    const { left, right, top, bottom, center } = SidebarModalPosition;
    const defaultUnit = SidebarModalConstants.DEFAULT_UNIT;
    this.width = width;
    this.height = height;
    bgColor && (this.backgroundColor = bgColor);
    overflowX && (this.overflowX = overflowX);
    overflowY && (this.overflowY = overflowY);

    let layoutTopGap = 0;
    let layoutLeftGap = 0;
    this.pageElement ??= (document as any).getElementsByClassName('page')[0];

    if (this.isPageBuilderPage()) {
      if (position !== right) {
        layoutLeftGap = this.getLayoutSidebarWidth();
      }
      if (position !== bottom) {
        layoutTopGap = this.getTopOffsetForSidebar();
      }

      this.height?.includes(SizeType.percent) && this.reCalcHeight();
      this.width?.includes(SizeType.percent) && this.reCalcWidth();
    }

    if (position === center) {
      this.top = layoutTopGap + this.getViewportHeight() / 2 - this.getValuePx(height) / 2 + defaultUnit;
      this.left = layoutLeftGap + this.getViewportWidth() / 2 - this.getValuePx(width, false) / 2 + defaultUnit;
      return;
    }

    if (position === left || position === right) {
      const verticalContentPaddings = byPageContent ? this.getContentPadding(['paddingTop', 'paddingBottom']) : 0;
      this.height = this.getViewportHeight() - verticalContentPaddings - this.getValuePx(topGap) - this.getValuePx(bottomGap) + defaultUnit;
      settings.height = this.height;

      const contentGap = byPageContent ? this.getContentPadding('paddingTop') : 0;
      this.top = layoutTopGap + contentGap + this.getValuePx(topGap) + defaultUnit;

      if (position === left) {
        this.left = layoutLeftGap + this.getValuePx(leftGap, false) + defaultUnit;
      } else {
        this.right = this.getValuePx(rightGap, false) + defaultUnit;
      }
    } else {
      const horizontalContentPaddings = byPageContent ? this.getContentPadding(['paddingLeft', 'paddingRight']) : 0;
      this.width = this.getViewportWidth() - horizontalContentPaddings - this.getValuePx(leftGap, false) - this.getValuePx(rightGap, false) + defaultUnit;
      settings.width = this.width;

      const contentGap = byPageContent ? this.getContentPadding('paddingLeft') : 0;
      this.left = layoutLeftGap + contentGap + this.getValuePx(leftGap) + defaultUnit;

      if (position === top) {
        this.top = layoutTopGap + this.getValuePx(topGap) + defaultUnit;
      } else {
        this.bottom = this.getBottomOffsetForSidebar() + this.getValuePx(bottomGap) + defaultUnit;
      }
    }

    // update styles
    setTimeout(() => {
      this.pageElement.click();
    });
  }

  getValuePx(val: string | null, isHeight = true): number {
    let res: number;
    if (val?.includes(SizeType.percent)) {
      const viewportValue = isHeight ? this.getViewportHeight() : this.getViewportWidth();
      res = (viewportValue / 100) * parseInt(val, 10);
    } else {
      res = val ? parseInt(val, 10) : 0;
    }

    return Math.round(res);
  }

  private getContentPadding(paddingTypes: string | string[]): number {
    !Array.isArray(paddingTypes) && (paddingTypes = [paddingTypes]);

    return this.pageElement
      ? paddingTypes.reduce((previousValue, type) => {
          return previousValue + (parseInt(this.pageElement.style[type], 10) || 0);
        }, 0)
      : 0;
  }

  private getViewportHeight(): number {
    const top = this.getTopOffsetForSidebar();
    const total = window.innerHeight;
    return total - top;
  }

  private getViewportWidth(): number {
    const total = document.body.offsetWidth;
    const sideWidth = this.getLayoutSidebarWidth();
    return total - sideWidth;
  }

  private isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  private reCalcHeight(): void {
    this.height = (this.getViewportHeight() / 100) * parseInt(this.height, 10) + SidebarModalConstants.DEFAULT_UNIT;
  }

  private reCalcWidth(): void {
    this.width = (this.getViewportWidth() / 100) * parseInt(this.width, 10) + SidebarModalConstants.DEFAULT_UNIT;
  }

  private getLayoutSidebarWidth(): number {
    return this.isOpenLayoutSidebar() ? (document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR)?.offsetWidth || 0 : 0;
  }

  private isOpenLayoutSidebar(): boolean {
    return !(document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR)?.closest(LAYOUT_SIDEBAR_INACTIVE_FLAG);
  }

  private getTopOffsetForSidebar(): number {
    const header = (document as any).querySelector(PAGE_BUILDER_HEADER);
    const topBar = (document as any).querySelector(PAGE_BUILDER_TOP_BAR);
    return (header ? header.offsetHeight : 0) + (topBar ? topBar.offsetHeight : 0);
  }

  private getBottomOffsetForSidebar(): number {
    const footer = (document as any).querySelector(PAGE_BUILDER_FOOTER);
    return footer ? footer.offsetHeight : 0;
  }
}
