import { ArtifactWidgetOptions } from '@widgets/artifact-widget/types/artifact-widget-options.types';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import {
  BODY,
  LAYOUT_SIDEBAR_INACTIVE_FLAG,
  LAYOUT_SIDEBAR_SELECTOR,
  PAGE_BUILDER_HEADER,
  PAGE_BUILDER_TOPBAR,
  PREVIEW_PENCIL_SELECTOR,
  SETTINGS,
  SIDEBAR_Z_INDEX,
} from '@widgets/sidebar-widget/constants/sidebar-widget.constants';
import { ArrowBtnSetting, SideBarArrowButtonSettingsDto } from '@widgets/sidebar-widget/types/sidebar-widget-arrow-button.types';
import { ButtonSettings, SidebarButtonSettingsDto } from '@widgets/sidebar-widget/types/sidebar-widget-button.types';
import { SideBarStyles } from '@widgets/sidebar-widget/types/sidebar-widget-styles.types';
import { SidebarWidgetWidth } from '@widgets/sidebar-widget/types/sidebar-widget-type.types';

export class SideBarSettings {
  position: SidebarWidgetWidth = SidebarWidgetWidth.right;
  prevPosition: SidebarWidgetWidth;
  width = SETTINGS.defaultWidth;
  display: boolean;
  isInside = false;
  isFixed = false;
  bgColor = SETTINGS.defaultBackgroundColor;
  shadow = false;
  contentColor = SETTINGS.defaultContentColor;
  arrowBtn = new ArrowBtnSetting();
  layoutBtn = new ButtonSettings();
  // for choose it in another widgets then open by event
  sidebarName: string;
  eventOpen = false;
  closeEvents = new SideBarCloseEvents();
  closeEventsDynamic = new SideBarCloseEvents();
  prevBehaviorTypeIsFixed = false;
  styles = new SideBarStyles(this);
  options: ArtifactWidgetOptions = new ArtifactWidgetOptions();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  autoZIndex = true;

  private paddingMap = {
    [SidebarWidgetWidth.left]: BODY.left,
    [SidebarWidgetWidth.top]: BODY.top,
    [SidebarWidgetWidth.right]: BODY.right,
    [SidebarWidgetWidth.bottom]: BODY.bottom,
  };

  constructor(dto?: SideBarSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): SideBarSettingsDto {
    return new SideBarSettingsDto(this);
  }

  fromDto(dto: SideBarSettingsDto): void {
    const { position, width, isInside, isFixed, shadow, bgColor, contentColor, styles, closeEvents, eventOpen, sidebarName } = dto;
    this.arrowBtn = new ArrowBtnSetting(dto.arrowBtn);
    this.layoutBtn = new ButtonSettings(dto.layoutBtn);
    Object.assign(this, {
      position,
      width,
      isInside,
      isFixed,
      shadow,
      bgColor,
      contentColor,
      styles,
      closeEvents,
      sidebarName,
    });

    eventOpen && (this.eventOpen = eventOpen);
    !closeEvents && (this.closeEvents = new SideBarCloseEvents());
    this.prevPosition = position;
    this.updateStyles();
  }

  updateButtonContent(isLayoutButton?: boolean, isHoverIn?: boolean): void {
    const button = isLayoutButton ? 'layoutBtn' : 'arrowBtn';
    this[button].changeButtonContent(isHoverIn, this.display);
  }

  onHoverIn(isLayoutButton?: boolean): void {
    this.updateButtonContent(isLayoutButton, true);
  }

  onHoverOut(isLayoutButton?: boolean): void {
    this.updateButtonContent(isLayoutButton, false);
  }

  onButtonClick(islayoutBtn = false): void {
    this.display = !this.display;
    this.updateStyles(islayoutBtn);
  }

  openByEvent(): void {
    if (this.eventOpen) {
      this.display = !this.display;
      this.updateStyles();
    }
  }

  hideSidebar(isLayoutMode: boolean): void {
    if (this.isInside && this.isFixed && !isLayoutMode) {
      return;
    }
    this.display = false;
    this.layoutBtn.changeButtonContent(false, this.display);
    this.arrowBtn.changeButtonContent(false, this.display);
    this.clearBodyPaddings();
    this.clearLayoutSidebarPaddings();
  }

  pageBuilderLayoutFix(idForRefresh?: string): void {
    if ([SidebarWidgetWidth.left, SidebarWidgetWidth.top, SidebarWidgetWidth.bottom].includes(this.position)) {
      this.styles.left = this.getLayoutSidebarWidth();
    }
    if ([SidebarWidgetWidth.left, SidebarWidgetWidth.top, SidebarWidgetWidth.right].includes(this.position)) {
      this.styles.top = this.getTopOffsetForSidebar();
    }
    if ([SidebarWidgetWidth.top, SidebarWidgetWidth.bottom].includes(this.position)) {
      this.styles.width = this.getHeaderWidthForSidebar();
    }

    this.setPageOffsetBySidebarHeight();

    if (idForRefresh) {
      const el = (document as any).getElementById(idForRefresh)?.closest('.p-sidebar');
      if (!el) return;

      this.styles.top && (el.style.top = this.styles.top);
      this.styles.left && (el.style.left = this.styles.left);
    }
  }

  updateStyles(isHoverInlayoutBtn = false): void {
    this.layoutBtn.changeButtonContent(isHoverInlayoutBtn, this.display);
    this.arrowBtn.update(this.position, this.display ? this.width : null);
    this.arrowBtn.changeButtonContent(false, this.display);
    this.styles = new SideBarStyles(this);
    this.isPageBuilderPage() && this.clearLayoutSidebarPaddings();
    this.clearBodyPaddings();

    if (this.isPageBuilderPage()) {
      this.styles.zIndex = SIDEBAR_Z_INDEX;
      this.autoZIndex = !(this.position === SidebarWidgetWidth.top);
      this.pageBuilderLayoutFix();
    }

    if (this.isFixed) {
      this.display = true;
      !this.prevBehaviorTypeIsFixed && (this.closeEventsDynamic = new SideBarCloseEvents(this.closeEvents));
      this.closeEvents = new SideBarCloseEvents({ outside: false, cancelX: false });
    } else {
      this.prevBehaviorTypeIsFixed && (this.closeEvents = new SideBarCloseEvents(this.closeEventsDynamic));
      // if sidebar is inside and dynamic then disable overlay mask for allow hover and click to page
      !this.isFixed && this.isInside && (this.closeEvents.outside = false);
    }

    (this.position === SidebarWidgetWidth.right || this.position === SidebarWidgetWidth.top) && this.setPencilPosition();

    if (!this.isPageBuilderPage() && this.display && this.isInside) {
      this.setBodyStyle();
    }

    this.prevPosition = this.position;
    this.prevBehaviorTypeIsFixed = this.isFixed;
  }

  clearLayoutSidebarPaddings(): void {
    this.setLayoutSidebarPaddings('0', this.prevPosition);
  }

  clearBodyPaddings(): void {
    this.setBodyStyle('0', this.prevPosition);
  }

  clearPageBuilderPaddings(): void {
    const contentContainer = (document as any).querySelector('.page');
    contentContainer.style.padding = '0px';
  }

  private setPageOffsetBySidebarHeight(): void {
    const isTop = this.isFixed && this.position === SidebarWidgetWidth.top;

    const marginTopValue = isTop ? this.styles.height : '0px';
    const marginBottomValue = this.isFixed && this.position === SidebarWidgetWidth.bottom ? this.styles.height : '0px';
    const paddingRightValue = (this.isFixed || (this.isInside && this.display)) && this.position === SidebarWidgetWidth.right ? this.styles.width : '0px';
    const marginLeftValue = (this.isFixed || (this.isInside && this.display)) && this.position === SidebarWidgetWidth.left ? this.styles.width : '0px';

    setTimeout(() => {
      const contentContainer = (document as any).querySelector('.page');
      contentContainer.style.paddingLeft = marginLeftValue;
      contentContainer.style.marginTop = marginTopValue;
      contentContainer.style.paddingRight = paddingRightValue;
      contentContainer.style.marginBottom = marginBottomValue;
      contentContainer.style.paddingTop = isTop ? '20px' : '0px';
    });
  }

  private getTopOffsetForSidebar(): string {
    const toolbarEl = (document as any).querySelector(PAGE_BUILDER_TOPBAR);
    const el = (document as any).querySelector(PAGE_BUILDER_HEADER);
    return (el ? el.offsetHeight : 0) + (toolbarEl ? toolbarEl.offsetHeight : 0) + 'px';
  }

  private getHeaderWidthForSidebar(): string {
    const toolbarEl = (document as any).querySelector(PAGE_BUILDER_TOPBAR);
    return toolbarEl ? toolbarEl.offsetWidth + 'px' : '100%';
  }

  private setLayoutSidebarPaddings(value: string = this.getPaddingValue(), position = this.position): void {
    const element = (document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR);
    element && (element.style[position] = value);
  }

  private getLayoutSidebarWidth(): string {
    const systemPanelWidth = this.isOpenLayoutSidebar() ? (document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR)?.offsetWidth : 0;
    return systemPanelWidth + 'px';
  }

  private isOpenLayoutSidebar(): boolean {
    return !(document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR)?.closest(LAYOUT_SIDEBAR_INACTIVE_FLAG);
  }

  private isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  private setPencilPosition(): void {
    const value = this.display ? this.getPaddingValue() : '5px';
    const element = (document as any).querySelector(PREVIEW_PENCIL_SELECTOR);
    element && (element.style[this.position] = value);
  }

  private setBodyStyle(value: string = this.getPaddingValue(), position = this.position): void {
    const paddingDirection = this.paddingMap[position];
    (document.body.style as any)[paddingDirection] = value;
  }

  private getPaddingValue(): string {
    return [SidebarWidgetWidth.top, SidebarWidgetWidth.bottom].includes(this.position) ? this.styles.height : this.styles.width;
  }

  private initTransition(): void {
    const element = (document as any).querySelector(LAYOUT_SIDEBAR_SELECTOR);
    element && (element.style.transition = 'left 0.3s,right 0.3s,top 0.3s,bottom 0.3s');
    document.body.style.transition = 'padding 0.3s';
  }
}

export class SideBarSettingsDto {
  position: SidebarWidgetWidth;
  width: string;
  isInside: boolean;
  isFixed: boolean;
  shadow: boolean;
  bgColor: string;
  contentColor: string;
  styles: Record<string, any> | null;
  arrowBtn: SideBarArrowButtonSettingsDto;
  layoutBtn: SidebarButtonSettingsDto;
  closeEvents: SideBarCloseEvents;
  eventOpen: boolean;
  sidebarName: string;

  constructor(settings: SideBarSettings) {
    const { position, width, isInside, isFixed, shadow, bgColor, contentColor, styles, closeEvents, eventOpen, sidebarName } = settings;
    this.arrowBtn = settings.arrowBtn.toServer();
    this.layoutBtn = settings.layoutBtn.toServer();
    Object.assign(this, {
      position,
      width,
      isInside,
      isFixed,
      shadow,
      bgColor,
      contentColor,
      styles,
      arrowBtn: this.arrowBtn,
      layoutBtn: this.layoutBtn,
      closeEvents,
      eventOpen,
      sidebarName,
    });
  }
}

export class SideBarCloseEvents {
  cancelX = true;
  outside = false;

  constructor(dto?: SideBarCloseEventsDto) {
    dto && this.fromDto(dto);
  }

  fromDto(dto: SideBarCloseEventsDto): void {
    const { cancelX, outside } = dto;
    Object.assign(this, { cancelX, outside });
  }
}

export class SideBarCloseEventsDto {
  cancelX = true;
  outside = true;
}
