import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TemplateResponseDto } from '@api/models';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { TemplatesService } from '@shared/components/templates/services/templates.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { CoreListFilterEnum, ListMetaData, ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { FilterOperatorEnum } from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { FilterMetadata, LazyLoadEvent, MenuItem } from 'primeng/api';

enum ApplicationOptions {
  current = 'Current Application',
  others = 'Other Applications',
}

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
})
export class TemplatesListComponent extends CoreListComponent<TemplateResponseDto> implements OnInit {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<TemplateResponseDto>>;
  @Input() readonly useSingleButtonToPickTemplate?: boolean;
  @Input() readonly hideTemplateTypePicker?: boolean;
  @ViewChild('searchTemplate') searchTemplate: ElementRef;
  currentTemplateType: MenuItem | null = null;
  templateTypesMenuItems: MenuItem[];
  currentCategory: MenuItem | null = null;
  categoryMenuItems: MenuItem[];
  widgetTypeOptions: SelectOption<WidgetType, WidgetType>[];
  selectedApplicationOption: ApplicationOptions;
  applicationOptions: SelectOption<ApplicationOptions, ApplicationOptions>[];
  filters: {
    [s: string]: FilterMetadata | FilterMetadata[];
  } = {};
  selectedWidgetType?: WidgetType;
  selectedTemplateType: TemplateType;
  private lastLazyLoadEvent: LazyLoadEvent | null = null;

  constructor(public templatesService: TemplatesService, readonly applicationSwitcherService: ApplicationSwitcherService) {
    super();
  }

  @Input() set templateType(type: TemplateType) {
    this.selectedTemplateType = type;
    type && this.addTemplateTypeFilter();
  }

  @Input() set widgetType(widgetType: WidgetType) {
    this.selectedWidgetType = widgetType;
    this.addWidgetTypeFilter(widgetType);
  }

  ngOnInit(): void {
    this.templateTypesMenuItems = [
      {
        label: 'Internal templates',
        state: { type: 'internal' },
        command: e => this.changeCurrentTemplateType(e),
      },
      {
        label: 'External templates',
        state: { type: 'external' },
        command: e => this.changeCurrentTemplateType(e),
      },
    ];
    this.currentTemplateType = this.templateTypesMenuItems[0];

    this.categoryMenuItems = [
      {
        label: 'Categories',
        icon: 'pi pi-fw pi-plus',
        items: [],
      },
    ];

    this.applicationOptions = [new SelectOption(ApplicationOptions.current), new SelectOption(ApplicationOptions.others)];

    this.updateCategories();
    this.widgetTypeOptions = Object.keys(WidgetType).map(
      k => new SelectOption(WidgetType[k as keyof typeof WidgetType], WidgetType[k as keyof typeof WidgetType]),
    );
  }

  async searchTemplateName(): Promise<void> {
    this.filters = {
      type: this.filters.type,
      ...this.getSearchNameFilter(),
      ...this.getApplicationFilter(),
      ...this.getWidgetTypeFilter(),
    };
    this.updateCategories();
    await this.update();
  }

  async onLazyLoad(event: LazyLoadEvent | null): Promise<void> {
    event!.filters = this.filters as any;
    this.lastLazyLoadEvent = event;
    await super.onLazyLoad(event);
  }

  private async update(): Promise<void> {
    await this.onLazyLoad(this.lastLazyLoadEvent);
  }

  async onApplicationChange(): Promise<void> {
    this.filters = { ...this.filters, ...this.getApplicationFilter() };
    this.updateCategories();
    await this.update();
  }

  async onWidgetTypeChange(widgetType: WidgetType): Promise<void> {
    this.addWidgetTypeFilter(widgetType);
    this.updateCategories();
    await this.update();
  }

  private addWidgetTypeFilter(widgetType: WidgetType): void {
    this.filters = {
      ...this.filters,
      ...this.getWidgetTypeFilter(widgetType),
    };
  }

  private addTemplateTypeFilter(): void {
    this.filters = {
      ...this.filters,
      type: [{ value: this.selectedTemplateType ? [this.selectedTemplateType] : [], matchMode: CoreListFilterEnum.in, operator: FilterOperatorEnum.and }],
    };
  }

  private async changeCurrentTemplateType(e: { item: MenuItem; originalEvent: PointerEvent }): Promise<void> {
    if (this.templatesService.m.templateType === e.item.state!.type) return;
    this.currentTemplateType = e.item;
    this.templatesService.m.templateType = e.item.state!.type;
    this.searchTemplate.nativeElement.value = '';
    this.filters = { type: this.filters.type, ...this.getApplicationFilter() };
    this.updateCategories();
    await this.update();
    this.templatesService.m.previousTemplateType = this.templatesService.m.templateType;
  }

  private async changeCurrentCategory(e: { item: MenuItem; originalEvent: PointerEvent }): Promise<void> {
    if (this.templatesService.m.selectedCategory === e.item.state!.id) return;
    this.currentCategory && (this.currentCategory.styleClass = undefined);
    e.item.styleClass = 'active';
    this.currentCategory = e.item;
    this.templatesService.m.selectedCategory = e.item.state!.id;
    this.filters = {
      type: this.filters.type,
      ...this.getApplicationFilter(),
      ...this.getCategoryFilter(),
      ...this.getSearchNameFilter(),
      ...this.getWidgetTypeFilter(),
    };
    await this.update();
  }

  private getApplicationFilter(): any {
    return {
      applicationId: [
        this.selectedApplicationOption === ApplicationOptions.current
          ? { value: [this.applicationSwitcherService.selectedApplication?.id], matchMode: CoreListFilterEnum.in, operator: FilterOperatorEnum.and }
          : {
              value: [this.applicationSwitcherService.selectedApplication?.id],
              matchMode: CoreListFilterEnum.notIn,
              operator: FilterOperatorEnum.and,
            },
      ],
    };
  }

  private getCategoryFilter(): any {
    return this.templatesService.m.selectedCategory !== undefined
      ? {
          categories: [
            this.templatesService.m.selectedCategory === null
              ? { value: false, matchMode: CoreListFilterEnum.exists, operator: FilterOperatorEnum.and }
              : { value: [this.templatesService.m.selectedCategory], matchMode: CoreListFilterEnum.in, operator: FilterOperatorEnum.and },
          ],
        }
      : {};
  }

  private getSearchNameFilter(): any {
    return this.searchTemplate.nativeElement.value
      ? {
          name: [{ value: this.searchTemplate.nativeElement.value || [], matchMode: CoreListFilterEnum.contains, operator: FilterOperatorEnum.and }],
        }
      : {};
  }

  private getWidgetTypeFilter(widgetType: WidgetType | undefined = this.selectedWidgetType): any {
    return { widgetType: [{ value: widgetType ? [widgetType] : undefined, matchMode: CoreListFilterEnum.in, operator: FilterOperatorEnum.and }] };
  }

  private updateCategories(): void {
    this.templatesService
      .loadTemplateCategories(
        new ListMetaData(
          undefined,
          undefined,
          this.processFilters(this.filters as Record<string, FilterMetadata[]>),
          undefined,
          undefined,
          undefined,
        ).toQuery(),
        this.changeCurrentCategory.bind(this),
      )
      .then(res => {
        this.categoryMenuItems = [...res];
        this.currentCategory = res[0].items![0] || null;
        this.templatesService.m.selectedCategory = res[0].items![0]?.state!.id || undefined;
      });
  }
}
