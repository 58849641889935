import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';

const isCreateArtifact = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.createArtifact;
const isUpdateArtifact = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.updateArtifact;
const isDeleteArtifact = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.deleteArtifact;
const isArtifactSse = (state: RuntimeStateNotificationEnum): boolean => isCreateArtifact(state) || isUpdateArtifact(state) || isDeleteArtifact(state);

const isCreateLink = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.createLink;
const isUpdateLinks = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.updateLinks;
const isDeleteLink = (state: RuntimeStateNotificationEnum): boolean => state === RuntimeStateNotificationEnum.deleteLink;
const isLinkSse = (state: RuntimeStateNotificationEnum): boolean => isCreateLink(state) || isUpdateLinks(state) || isDeleteLink(state);

export const ServerSendEventMethods = {
  isCreateArtifact,
  isUpdateArtifact,
  isDeleteArtifact,
  isCreateLink,
  isUpdateLinks,
  isDeleteLink,
  isLinkSse,
  isArtifactSse,
};
