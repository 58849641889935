// Dropdown/Multiselect item
export class SelectOption<Label, Value, Meta = any> {
  public label: Label;
  public value: Value;
  public meta: Meta;
  public disabled: boolean;

  constructor(label: Label, value: Value | null = null, meta: Meta | null = null, disabled = false) {
    this.label = label;
    this.value = value !== null && value !== undefined ? value : (label as any);
    meta && (this.meta = meta);
    this.disabled = disabled;
  }
}

// Tenant types
export enum SystemTenant {
  system = 'SYSTEM',
  anonymous = 'ANONYMOUS',
}

export enum AppInterface {
  system = 'system',
  tenant = 'tenant',
}

export class DoSomethingWithConfirmationParams {
  constructor(
    public header: string | null = null,
    public message: string | null = null,
    public acceptLabel = 'Yes',
    public rejectLabel = 'No',
    public interpolateParams: Record<string, string> = {},
  ) {}
}
