import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';

@Pipe({
  name: 'selectedValuesToIndexes',
})
export class SelectedValuesToIndexesPipe implements PipeTransform {
  transform(activeValues: string[], enumValues: DataTypeValueResponseDto[]): number[] {
    return activeValues.map((value: string) => {
      return enumValues.findIndex((enumValue: DataTypeValueResponseDto) => enumValue.value === value);
    });
  }
}
