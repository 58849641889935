import { Pipe, PipeTransform } from '@angular/core';
import { APPLICATION_ID_KEY, ERROR_GETTING_APPLICATION } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ObjectUtil } from '@shared/utils/object.util';

@Pipe({ name: 'printDataTypeToTable' })
export class PrintDataTypeToTablePipe implements PipeTransform {
  constructor(private readonly objectUtil: ObjectUtil) {}

  transform(dataType: NewDataType, key: string, applications: ListContainer<NewApplication>): string {
    if (key === APPLICATION_ID_KEY) return applications.listMap[dataType.applicationId]?.name || ERROR_GETTING_APPLICATION;
    return this.objectUtil.getValueFromPath(key, dataType);
  }
}
