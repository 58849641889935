import { ActivatedRoute } from '@angular/router';
import { TenantArtifactService, TenantArtifactTypeService } from '@api/services';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/document-generation/document-generation.avr-types-services.service';

// just structure, injection magic is happening in component
export default {
  provide: 'AvrTypesServices',
  useFactory: (artifactTypeService: TenantArtifactTypeService, artifactService: TenantArtifactService, activatedRoute: ActivatedRoute) => {
    return { [AvrTypes.documentGeneration]: new DocumentGenerationAvrTypeService(artifactTypeService, artifactService, activatedRoute) };
  },
  deps: [TenantArtifactTypeService, TenantArtifactService, ActivatedRoute],
};
