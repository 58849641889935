<div class="spinner loader-custom" *ngIf="loading && !fixed">
  <div class="loader-custom__wrapper">
    <span class="pi pi-spin pi-spinner loader-custom__wheel"></span>
  </div>
</div>

<div class="spinner loader-custom loader-custom-fixed" *ngIf="loading && fixed">
  <div class="loader-custom__wrapper">
    <span class="pi pi-spin pi-spinner loader-custom__wheel"></span>
  </div>
</div>