import { Injectable } from '@angular/core';
import { SharedMethods } from '../methods/shared.methods';

@Injectable({ providedIn: 'root' })
export class ObjectUtil {
  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  // see the documentation for GetValueFromPath
  getValueFromPath(path: string | string[], obj: Record<string, any>): any {
    return SharedMethods.getValueFromPath(path, obj);
  }

  getIntersectionOfArrays(arrayOfArrays: any[][]): any {
    return arrayOfArrays.reduce((a, b) => a.filter(c => b.includes(c)));
  }

  isNullOrUndefined(value: any): boolean {
    return value == null;
  }
}
