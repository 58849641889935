import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowListResponseDto } from '@api/models/workflow-list-response-dto';
import { TenantWorkflowService } from '@api/services/tenant-workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowListService } from '@private/pages/workflow-management/workflow-list/services/workflow-list.service';
import { WorkflowListModel } from '@private/pages/workflow-management/workflow-list/types/workflow-list.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { RuleDataHolderService } from '@workflows/services';
import { WorkflowRule } from '@workflows/types';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss'],
  providers: [WorkflowListService],
})
export class WorkflowListComponent extends CoreComponent<WorkflowListService, WorkflowListModel> implements OnInit {
  m: WorkflowListModel = new WorkflowListModel();
  cachedRules: WorkflowRule[];
  loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<WorkflowListResponseDto>;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: WorkflowListService,
    announcement: AnnouncementService,
    private readonly ruleDataHolder: RuleDataHolderService,
    private readonly tenantWorkflowService: TenantWorkflowService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new WorkflowListModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Workflows' }]);
    this.cachedRules = this.ruleDataHolder.getAllRules();
    this.loadDataMethod = async (meta?: Partial<ListReqMetaData>): Promise<any> => lastValueFrom(this.tenantWorkflowService.workflowControllerList(meta));
  }
}
