import { Component, Input } from '@angular/core';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { FilterWidgetModel } from '@widgets/filter-widget/types/filter-widget.types';
import { FilterWidgetService } from '@widgets/filter-widget/services/filter-widget.service';

@Component({
  selector: 'app-filter-widget-settings',
  templateUrl: './filter-widget-settings.component.html',
  styleUrls: ['./filter-widget-settings.component.scss'],
})
export class FilterWidgetSettingsComponent {
  @Input() m: FilterWidgetModel;
  @Input() s: FilterWidgetService;

  currentUrlKey: string;

  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  storeUrlKey(): void {
    this.currentUrlKey = this.m.settings.urlKey;
  }

  onChangeKey(): void {
    this.m.settings.urlKey !== this.currentUrlKey && this.s.changeUrlKey(this.currentUrlKey, this.m.settings.urlKey);
    this.currentUrlKey = this.m.settings.urlKey;
  }
}
