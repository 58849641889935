<div class="grid mt-1 full-width-container">
    <div class="col-6">
        <div class="dynamic-label p-float-label">
            <p-multiSelect
                (onChange)="s.onArtifactTypesChange()"
                [(ngModel)]="m.settings.artifactTypes"
                [options]="m.options.artifactTypes.list"
                [placeholder]="'Artifact type' | translate"
                filter="true"
                optionLabel="name"
                id="rowAttribute"
            ></p-multiSelect>
            <label for="rowAttribute" translate> Artifact type</label>
        </div>
    </div>
    <div class="col-6">
        <div class="dynamic-label p-float-label">
            <p-dropdown
                (onChange)="s.onAxisChange()"
                [(ngModel)]="m.settings.columnAttribute"
                [options]="m.options.filteredAttributes | transformArrayToSelectOptions: 'name'"
                [placeholder]="'Column attribute (X axis)' | translate"
                showClear="true"
                filter="true"
                optionLabel="label"
                id="colAttribute"
            >
            </p-dropdown>
            <label for="colAttribute" translate> Column attribute (X axis)</label>
        </div>
    </div>

    <div class="col-12">
        <p-accordion styleClass="accord">
            <p-accordionTab [header]="'Settings' | translate">
                <p-accordion styleClass="accord">
                    <p-accordionTab [header]="'Widget settings for empty data' | translate">
                        <div class="grid mt-1">
                            <div class="col-6 mb-1">
                                <input
                                    (blur)="s.refreshChart()"
                                    [(ngModel)]="m.settings.noData.text"
                                    [placeholder]="'No data text' | translate"
                                    id="noDataText" pInputText type="text" style="width: 100%"
                                >
                            </div>
                            <div class="col-6">
                                <button (click)="s.pickStyles(m.settings.noData.style, 'No data',  styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="No Data styles" pButton></button>
                            </div>

                            <div class="col-6">
                                <div class="dynamic-label p-float-label">
                                    <p-dropdown
                                        (onChange)="s.refreshChart()"
                                        [(ngModel)]="m.settings.noData.align"
                                        [options]="m.settings.noData.alignOptions | transformArrayToSelectOptions"
                                        [placeholder]="'Align' | translate"
                                        optionLabel="label"
                                        id="align"
                                    >
                                    </p-dropdown>
                                    <label for="align" translate> Align</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="dynamic-label p-float-label">
                                    <p-dropdown
                                        (onChange)="s.refreshChart()"
                                        [(ngModel)]="m.settings.noData.verticalAlign"
                                        [options]="m.settings.noData.verticalAlignOptions | transformArrayToSelectOptions"
                                        [placeholder]="'Vertical align' | translate"
                                        optionLabel="label"
                                        id="valign"
                                    >
                                    </p-dropdown>
                                    <label for="valign" translate> Vertical align</label>
                                </div>
                            </div>

                            <div class="col-6">
                                <p-inputNumber
                                    (onInput)="s.refreshChart()"
                                    [(ngModel)]="m.settings.noData.offsetX"
                                    [placeholder]="'Offset X' | translate"
                                    id="offsetX"
                                ></p-inputNumber>
                            </div>
                            <div class="col-6">
                                <p-inputNumber
                                    (onInput)="s.refreshChart()"
                                    [(ngModel)]="m.settings.noData.offsetY"
                                    [placeholder]="'Offset Y' | translate"
                                    id="offsetY"
                                ></p-inputNumber>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
                <div class="grid mt-1">
                    <div class="col-6 checkbox-cont">
                        <label for="listenChartChangeUrl" translate>Listen chart change url</label>
                        <p-checkbox
                            (onChange)="s.urlKeyCheck(true)"
                            [(ngModel)]="m.settings.showUrlChartChange"
                            [binary]="true"
                            id="listenChartChangeUrl"
                        ></p-checkbox>
                    </div>
                    <div class="col-6">
                        <input
                            (blur)="s.urlKeyCheck(true)"
                            [(ngModel)]="m.settings.urlChartChangeKey"
                            [placeholder]="'url param for chart change' | translate"
                            [disabled]="!m.settings.showUrlChartChange"
                            id="urlChartChange" pInputText type="text" style="width: 100%"
                        >
                    </div>

                    <div class="col-6">
                        <div class="dynamic-label p-float-label">
                            <input
                                (blur)="s.refreshChart()"
                                [(ngModel)]="m.settings.title"
                                class="w-100"
                                pInputText
                                type="text"
                                id="title"
                            />
                            <label for="title" translate> Title </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="dynamic-label p-float-label">
                            <input
                                (blur)="s.refreshChart()"
                                [(ngModel)]="m.settings.subTitle"
                                class="w-100"
                                pInputText
                                type="text"
                                id="subTitle"
                            />
                            <label for="subTitle" translate> Sub title </label>
                        </div>
                    </div>

                    <div class="col-6">
                        <button (click)="s.pickStyles(m.settings.titleStyles, 'Title',  styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Title styles" pButton></button>
                    </div>
                    <div class="col-6">
                        <button (click)="s.pickStyles(m.settings.subTitleStyles, 'Subtitle',  styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Sub title styles" pButton></button>
                    </div>

                    <div class="col-6">
                        <button (click)="s.pickStyles(m.settings.labelStyles, 'Labels',  styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Labels styles" pButton></button>
                    </div>
                    <div class="col-6"></div>

                    <div class="col-6">
                        <span class="dynamic-label dynamic-checkbox">
                            <span class="dynamic-checkbox-box">
                                <p-checkbox
                                    (onChange)="s.refreshChart()"
                                    [(ngModel)]="m.settings.showDataLabels"
                                    binary="true"
                                    id="dataLabels"
                                ></p-checkbox>
                                <label for="dataLabels">{{ 'Show data labels' | translate }}</label>
                            </span>
                        </span>
                    </div>
                    <div class="col-6">
                        <app-color-picker [(color)]="m.settings.dataLabelsColor" (colorChange)="s.refreshChart()"></app-color-picker>
                    </div>

                    <div class="col-6 mb-0">
                        <span class="dynamic-label dynamic-checkbox">
                          <span class="dynamic-checkbox-box">
                            <p-checkbox
                                (onChange)="s.updateChartData()"
                                [(ngModel)]="m.settings.listenUrlFolderId"
                                id="listenFolderId"
                                binary="true"
                            ></p-checkbox>
                            <label for="listenFolderId" translate> Listen FolderId </label>
                          </span>
                        </span>
                    </div>
                    <div class="col-6">
                        <span class="dynamic-label">
                          <input
                            (onChange)="s.urlKeyCheck()"
                            [disabled]="!m.settings.listenUrlFolderId"
                            [(ngModel)]="m.settings.folderKey"
                            type="text"
                            id="folderKey"
                            pInputText
                          />
                          <label class="dynamic-label-text" for="folderKey" translate>Folder url key</label>
                        </span>
                    </div>

                    <div class="col-6">
                        <span class="dynamic-label dynamic-checkbox">
                            <span class="dynamic-checkbox-box">
                                <p-checkbox
                                    (onChange)="s.refreshChart()"
                                    [(ngModel)]="m.settings.roundAxisY"
                                    binary="true"
                                    id="roundValue"
                                ></p-checkbox>
                                <label for="roundValue">{{ 'Round value' | translate }}</label>
                            </span>
                        </span>
                    </div>
                    <div class="col-6"></div>

                    <div class="col-6">
                        <span class="dynamic-label dynamic-checkbox">
                            <span class="dynamic-checkbox-box">
                                <p-checkbox
                                    (onChange)="s.refreshChart()"
                                    [(ngModel)]="m.settings.logarithmic"
                                    binary="true"
                                    id="logarithmic"
                                ></p-checkbox>
                                <label for="logarithmic">{{ 'Logarithmic' | translate }}</label>
                            </span>
                        </span>
                    </div>
                    <div class="col-6">
                        <p-inputNumber
                            (onInput)="s.refreshChart()"
                            [(ngModel)]="m.settings.logBase"
                            [placeholder]="'Logarithmic base' | translate"
                            id="logarithmicBase"
                        ></p-inputNumber>
                    </div>

                    <ng-container *ngIf="m.settings.artifactTypes?.length && m.showDateFormat">
                        <div class="col-6">
                            <div class="dynamic-label p-float-label">
                                <p-dropdown
                                    (onChange)="s.updateChartData()"
                                    [(ngModel)]="m.settings.dateFormat"
                                    [options]="m.options.dateFormatOptions.list | transformArrayToSelectOptions"
                                    [placeholder]="'Date format' | translate"
                                    optionLabel="label"
                                    id="dateFormat"
                                >
                                </p-dropdown>
                                <label for="dateFormat" translate> Date format </label>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </ng-container>

                    <ng-container *ngIf="m.showUserFormat">
                        <div class="col-6">
                            <div class="dynamic-label p-float-label">
                                <p-dropdown
                                    (onChange)="s.refreshChart()"
                                    [(ngModel)]="m.settings.userDisplayType"
                                    [options]="m.options.userDisplayOptions.list | transformArrayToSelectOptions"
                                    [placeholder]="'User display variant' | translate"
                                    id="userDisplay"
                                ></p-dropdown>
                                <label for="userDisplay" translate> User display variant </label>
                            </div>
                        </div>
                        <div class="col-6"></div>
                    </ng-container>
                </div>
            </p-accordionTab>

            <p-accordionTab *ngIf="m.settings.grid?.xaxis?.lines && m.settings.grid?.yaxis?.lines" [header]="'Extra' | translate">
                <div class="grid mt-1">
                <div class="col-6">
                        <span class="dynamic-label dynamic-checkbox">
                            <span class="dynamic-checkbox-box">
                                <p-checkbox
                                        (onChange)="s.refreshChart()"
                                        [(ngModel)]="m.settings.areaMode"
                                        binary="true"
                                        id="areaMode"
                                ></p-checkbox>
                                <label for="areaMode">{{ 'Area mode' | translate }}</label>
                            </span>
                        </span>
                </div>

                <div class="col-6">
                    <div class="dynamic-label p-float-label">
                        <input
                            (blur)="s.refreshChart()"
                            [(ngModel)]="m.settings.borderRadius"
                            pInputText
                            type="text"
                            id="borderRadius"
                        />
                        <label for="borderRadius" translate> Border radius </label>
                    </div>
                </div>
                <div class="col-6">
                    <span class="dynamic-label dynamic-checkbox">
                        <span class="dynamic-checkbox-box">
                            <p-checkbox
                                (onChange)="s.refreshChart()"
                                [(ngModel)]="m.settings.grid.show"
                                binary="true"
                                id="logarithmic"
                            ></p-checkbox>
                            <label for="logarithmic">{{ 'Show grid' | translate }}</label>
                        </span>
                    </span>
                </div>
                <div class="col-6">
                    <app-color-picker [(color)]="m.settings.grid.borderColor" (colorChange)="s.refreshChart()"></app-color-picker>
                </div>

                <div class="col-6">
                    <span class="dynamic-label dynamic-checkbox">
                        <span class="dynamic-checkbox-box">
                            <p-checkbox
                                (onChange)="s.refreshChart()"
                                [(ngModel)]="m.settings.grid.xaxis.lines.show"
                                binary="true"
                                id="gridX"
                            ></p-checkbox>
                            <label for="gridX">{{ 'Show X grid' | translate }}</label>
                        </span>
                    </span>
                </div>
                <div class="col-6">
                    <span class="dynamic-label dynamic-checkbox">
                        <span class="dynamic-checkbox-box">
                            <p-checkbox
                                (onChange)="s.refreshChart()"
                                [(ngModel)]="m.settings.grid.yaxis.lines.show"
                                binary="true"
                                id="gridY"
                            ></p-checkbox>
                            <label for="gridY">{{ 'Show Y grid' | translate }}</label>
                        </span>
                    </span>
                </div>

                <div class="col-6">
                    <div class="dynamic-label p-float-label">
                        <input
                            (blur)="s.refreshChart()"
                            [(ngModel)]="m.settings.grid.strokeDashArray"
                            pInputText
                            type="text"
                            id="stroke"
                        />
                        <label for="stroke" translate> Stroke dash </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="dynamic-label p-float-label">
                        <p-dropdown
                            (onChange)="onGridPositionChange(); s.refreshChart()"
                            [(ngModel)]="m.settings.gridPosition"
                            [options]="m.options.gridPosition.list | transformArrayToSelectOptions"
                            [placeholder]="'Position'"
                            optionLabel="label"
                            id="gridPosition"
                        >
                        </p-dropdown>
                        <label for="gridPosition" translate> Position</label>
                    </div>
                </div>
                </div>
            </p-accordionTab>

            <p-accordionTab [header]="'Axes' | translate">
                <div class="grid mt-1">
                    <div class="col-12">
                        <p-accordion *ngIf="m.settings.charts.length" styleClass="accord">
                            <p-accordionTab *ngFor="let a of m.settings.axes; let i =index">
                                <ng-template pTemplate="header">
                                    <div class="header-cont">
                                        {{a.name}}
                                        <div *ngIf="m.settings.axes.length > 1" (click)="s.onRemoveAxis($event, i)" class="remove-cont">
                                            <button
                                                pButton
                                                class="p-button p-button-rounded p-button-danger p-button-outlined"
                                                type="button"
                                                pTooltip="{{ 'Delete' | translate }}"
                                                icon="pi pi-trash"
                                                iconPos="left"
                                            ></button>
                                        </div>

                                    </div>
                                </ng-template>
                                <div class="grid mt-1">
                                    <div class="col-6">
                                        <span class="dynamic-label">
                                            <input
                                                (blur)="s.updateAxis(); s.refreshChart()"
                                                [(ngModel)]="a.name"
                                                id="axisName" pInputText type="text" style="width: 100%"
                                            >
                                            <label class="dynamic-label-text" for="axisName">Y axis {{i+1}} name</label>
                                        </span>
                                    </div>
                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <p-dropdown
                                                (onChange)="s.updateAxis(); s.refreshChart()"
                                                [(ngModel)]="m.settings.axes[i].position"
                                                [options]="m.options.axisPosition.list | transformArrayToSelectOptions"
                                                [placeholder]="'axis ' + (i + 1) + ' position'"
                                                optionLabel="label"
                                                id="axisPosition"
                                            >
                                            </p-dropdown>
                                            <label for="axisPosition" translate> axis {{i+1}} position</label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <button (click)="s.pickStyles(m.settings.axes[i].titleStyle, 'Axis title',  styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Title styles" pButton></button>
                                    </div>

                                    <div class="col-6">
                                        <button (click)="s.pickStyles(m.settings.axes[i].labelStyle, 'Axis labels', styleOp); styleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Labels styles" pButton></button>
                                    </div>

                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>

                    <div class="col-12">
                        <button class="full-width-container" (click)="s.onAddAxis()" pButton label="{{'+ Add new axis' | translate }}" type="button"></button>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab [header]="'Charts' | translate">
                <div class="grid mt-1">
                    <div class="col-12">
                        <p-accordion *ngIf="m.settings.charts.length" styleClass="accord">
                            <p-accordionTab *ngFor="let ch of m.settings.charts; let i =index">
                                <ng-template pTemplate="header">
                                    <div class="header-cont">
                                        {{ch.name}}
                                        <div *ngIf="m.settings.charts.length > 1" (click)="s.onRemoveChart($event, i)" class="remove-cont">
                                            <button
                                                pButton
                                                class="p-button p-button-rounded p-button-danger p-button-outlined"
                                                type="button"
                                                pTooltip="{{ 'Delete' | translate }}"
                                                icon="pi pi-trash"
                                                iconPos="left"
                                            ></button>
                                        </div>

                                    </div>
                                </ng-template>
                                <div class="grid mt-1">
                                    <div class="col-6">
                                        <span class="dynamic-label">
                                            <input
                                                (blur)="s.updateAxis(); s.refreshChart()"
                                                [(ngModel)]="ch.name"
                                                id="chartName" pInputText type="text" style="width: 100%"
                                            >
                                            <label class="dynamic-label-text" for="chartName">Alias</label>
                                        </span>
                                    </div>
                                    <div class="col-6 checkbox-cont">
                                        <label for="chartUrl" translate>Control by url</label>
                                        <p-checkbox
                                            [(ngModel)]="ch.isUnderUrlControl"
                                            (onChange)="s.urlKeyCheck(true)"
                                            [binary]="true"
                                            id="chartUrl"
                                        ></p-checkbox>
                                    </div>

                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <p-dropdown
                                                (onChange)="s.onChartArtifactTypeChange(i); s.updateChartData()"
                                                [(ngModel)]="m.settings.charts[i].artifactType"
                                                [options]="m.settings.artifactTypes | transformArrayToSelectOptions: 'name'"
                                                [placeholder]="'Artifact type' | translate"
                                                filter="true"
                                                optionLabel="label"
                                                id="artifactType"
                                            >
                                            </p-dropdown>
                                            <label for="artifactType" translate> Artifact type </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <p-dropdown
                                                (onChange)="s.updateChartData()"
                                                [(ngModel)]="m.settings.charts[i].selectedAttribute"
                                                [options]="m.settings.charts[i].attributes | transformArrayToSelectOptions: 'name'"
                                                [placeholder]="'Select attribute' | translate"
                                                [showClear]="true"
                                                autoDisplayFirst="false"
                                                filterBy="label"
                                                id="rowAttribute"
                                            >
                                            </p-dropdown>
                                            <label for="rowAttribute" translate> Select attribute</label>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <p-dropdown
                                                (onChange)="s.refreshChart(); s.onChartAxisChange(m.settings.charts[i])"
                                                [(ngModel)]="m.settings.charts[i].axis"
                                                [options]="m.options.axisNames | transformArrayToSelectOptions"
                                                [placeholder]="'Select Y-axis' | translate"
                                                autoDisplayFirst="false"
                                                filterBy="label"
                                                id="yAxis"
                                            >
                                            </p-dropdown>
                                            <label for="yAxis" translate> Select Y-axis </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <p-dropdown
                                                (onChange)="s.updateChartData()"
                                                [(ngModel)]="m.settings.charts[i].type"
                                                [options]="m.options.chartTypes.list | transformArrayToSelectOptions"
                                                [placeholder]="'Select a chart type' | translate"
                                                autoDisplayFirst="false"
                                                filterBy="label"
                                                id="chartType"
                                            >
                                            </p-dropdown>
                                            <label for="chartType" translate> Chart type </label>
                                        </div>
                                    </div>

                                    <div class="col-12 pt-0 pb-0">{{'Color' | translate }}</div>

                                    <div class="col-6">
                                        <app-color-picker [(color)]="ch.color" (colorChange)="s.refreshChart()"></app-color-picker>
                                    </div>

                                    <div class="col-6">
                                        <div class="dynamic-label p-float-label">
                                            <input
                                                (blur)="s.refreshChart()"
                                                [(ngModel)]="ch.opacity"
                                                [disabled]="!ch.color"
                                                class="w-100"
                                                pInputText
                                                type="text"
                                                id="opacity"
                                            />
                                            <label for="opacity" translate> Transparent </label>
                                        </div>
                                    </div>

                                    <div *ngIf="m.settings.charts[i].artifactType" class="col-12 mt-2">
                                        <app-artifact-filters
                                            (filtersChange)="s.updateChartData()"
                                            [attributeAndLinkOptions]="(m.settings.charts[i].allAttributes | transformArrayToSelectOptions : 'name')!"
                                            [dataTypes]="m.options.dataTypes.list"
                                            [filtersHolder]="m.settings.charts[i]!"
                                            [filtersKey]="'attributesFilter'"
                                            [users]="m.options.users"
                                        ></app-artifact-filters>
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>

                    <div class="col-12">
                        <button class="full-width-container" (click)="s.onAddChart()" pButton label="{{'+ Add new chart' | translate }}" type="button"></button>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<div *ngIf="m.settings.columnAttribute" class="col-12 mt-2">
    <app-artifact-filters
        (filtersChange)="s.updateChartData()"
        [attributeAndLinkOptions]="(m.options.commonAttributes | transformArrayToSelectOptions : 'name')!"
        [dataTypes]="m.options.dataTypes.list"
        [filtersHolder]="m.settings!"
        [filtersKey]="'attributesFilter'"
        [users]="m.options.users"
    ></app-artifact-filters>
</div>

<p-overlayPanel #styleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
    <ng-template pTemplate>
        <h5>{{m.settings.selectedTitle + ' styles'}}</h5>
        <app-font-style-form
            [fontStyles]="m.settings.selectedStyle"
            [hideHorizontalAlignSettings]="true"
            [hideEmphasisSettings]="true"
            (onChange)="s.refreshChart()"
        ></app-font-style-form>
    </ng-template>
</p-overlayPanel>
