import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';

export enum DocumentGenerationOutputType {
  pdf = 'PDF',
  docx = 'DOCX',
  pptx = 'PPTX',
}

export type DocumentGenerationAvrType = Extract<AvrTypesType, 'document-generation'>;

export class DocumentGenerationAvrInputMapperDto extends BaseAvrInputMapperDto<DocumentGenerationAvrType> {
  constructor() {
    super(
      {},
      {
        ['fileArtifactTypeId']: {
          isOptional: false,
          value: '',
          readableName: 'File Artifact type',
          description: 'Artifact type of format FILE, templates will be uploaded as',
        },
        ['everythingWithinArtifactType']: {
          isOptional: false,
          value: true,
          readableName: 'All files within selected Artifact type',
          description: 'To use all files, that fit "outputType" criterium within selected Artifact type',
        },
        ['outputType']: {
          isOptional: false,
          value: 'DOCX',
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
        ['templates']: {
          isOptional: false,
          value: [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })],
          readableName: 'Template',
          description: 'Select from uploaded file artifacts, based on output type (PDF output type can mix PPTX & DOCX templates)',
        },
      },
    );
  }
}
