import { Injectable } from '@angular/core';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { RuntimeStateNotificationService } from '../../shared/services/runtime-state-notification.service';
import { PictureWidgetComponent } from '../picture-widget.component';
import { PictureWidgetModel, PictureWidgetModelDto, PictureWidgetValue } from '../types/picture-widget.types';

@Injectable()
export class PictureWidgetService {
  c: PictureWidgetComponent;
  m: PictureWidgetModel;

  constructor(public readonly runtimeStateNotificationService: RuntimeStateNotificationService, private readonly widgetService: WidgetService) {}

  init(context: PictureWidgetComponent, dto?: PictureWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new PictureWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new PictureWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;

    this.m.options.sidebarList = this.widgetService.pageSidebars.getValue();
    this.widgetService.pageSidebars.subscribe(list => {
      this.m.options.sidebarList = list;
    });
  }
}
