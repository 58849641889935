import { EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { NewArtifact } from '@shared/types/artifact.types';
import { SortTypeValueEnum } from '@shared/types/sort.types';
import { FilterMetadata } from 'primeng/api';
import { GroupAttributeItem, GroupItem, GroupingSettings } from '../../types/list-widget-grouping.types';
import { GroupingHandler } from './grouping-handler';

export abstract class AbstractGroupingHandler implements GroupingHandler {
  abstract getAllGroups(groupingSettings: GroupingSettings): Record<string, GroupItem>;
  abstract getGroupingValue(): string;
  abstract getGroupingHeader(groupId?: string | undefined): string;
  abstract getFilterMetadataForGroupItem(groupItem: GroupItem): FilterMetadata;
  abstract sortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[];

  getGroupId(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem): string {
    return artifact.attributes[groupAttributeItem.id]?.value?.value || artifact.attributes[groupAttributeItem.id]?.value || EMPTY_GROUP_VALUE;
  }

  groupTableData(data: NewArtifact[], groupingSettings: GroupingSettings): Record<string, GroupItem> {
    const groupAttributeItem = groupingSettings.groupingAttributes[0].value;
    const groupData: Record<string, GroupItem> = {};
    data.forEach(artifact => {
      const groupId = this.getGroupId(artifact, groupAttributeItem);
      if (groupData[groupId]) {
        groupData[groupId].artifacts.push(artifact);
        groupData[groupId].total! += 1;
      } else {
        groupData[groupId] = this.getNewGroup(groupingSettings, groupId, 0, [artifact]);
      }
    });
    return groupData;
  }

  sortGroupItemsBySortOrder(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[] {
    const { sortSettings } = groupingSettings;
    const { sortType } = sortSettings;
    const isAsc = sortType === SortTypeValueEnum.ASC;

    return groupItems.sort((a, b) => (isAsc ? a.sortOrder - b.sortOrder : b.sortOrder - a.sortOrder));
  }

  getNewGroup(groupingSettings: GroupingSettings, groupId: string, sortOrder: number, artifacts: NewArtifact[]): GroupItem {
    return {
      id: groupId,
      artifacts,
      isDefaultGroup: false,
      sortOrder,
      total: 1,
      metaData: {
        count: artifacts.length,
        header: this.getGroupingHeader(groupId),
        isDisplayed: true,
        toggled: false,
      },
    };
  }

  protected doSortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings, numeric: boolean, orderingArray?: string[]): GroupItem[] {
    const { sortSettings } = groupingSettings;
    const { sortType } = sortSettings;
    const isAsc = sortType === SortTypeValueEnum.ASC;

    const emptyGroupArray = groupItems.filter(item => item.id === EMPTY_GROUP_VALUE);
    const normalGroupItems = groupItems.filter(item => item.id !== EMPTY_GROUP_VALUE);

    const sortedGroupItems = this.sortGroupItems(normalGroupItems, numeric, orderingArray);
    const resultItems = isAsc ? [...emptyGroupArray, ...sortedGroupItems] : [...sortedGroupItems.reverse(), ...emptyGroupArray];
    resultItems.forEach((item, index) => (item.sortOrder = index));
    return resultItems;
  }

  protected compareFn(groupItemA: GroupItem, groupItemB: GroupItem, isAsc: boolean, isNumeric: boolean): number {
    isNumeric;
    return isAsc ? groupItemA.sortOrder - groupItemB.sortOrder : groupItemB.sortOrder - groupItemA.sortOrder;
  }

  protected sortGroupItems(groupItems: GroupItem[], numeric: boolean, orderingArray?: string[]): GroupItem[] {
    orderingArray;
    return groupItems.sort((a, b) => a.id.localeCompare(b.id, undefined, { numeric }));
  }
}
