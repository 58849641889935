import { Injectable, OnDestroy } from '@angular/core';
import { GridCell } from '@shared/components/grid-layout-generator/types/grid-cell';
import { GridTrack } from '@shared/components/grid-layout-generator/types/grid-track';
import { RangedGridLayoutHolder } from '@shared/components/grid-layout-generator/types/ranged-grid-layout-holder';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { INITIAL_GRID_COLUMN_SIZE, INITIAL_GRID_ROW_SIZE } from '@shared/constants/constants';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject, switchMap, withLatestFrom } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class GridLayoutGeneratorService implements OnDestroy {
  private static initialColumnSize = INITIAL_GRID_COLUMN_SIZE;
  private static initialRowSize = INITIAL_GRID_ROW_SIZE;

  columns$: Observable<GridTrack[]>;
  rows$: Observable<GridTrack[]>;
  areaAddingDisabled$: Observable<boolean>;

  private columnsSubject: BehaviorSubject<GridTrack[]> = new BehaviorSubject<GridTrack[]>([]);
  private rowsSubject: BehaviorSubject<GridTrack[]> = new BehaviorSubject<GridTrack[]>([]);
  private isDestroyedSubject: Subject<void> = new Subject<void>();

  private _layoutHolder$: BehaviorSubject<RangedGridLayoutHolder | null> = new BehaviorSubject<RangedGridLayoutHolder | null>(null);
  private _breakpoint$: Observable<StyleApplicationBreakpoint> = this._layoutHolder$.pipe(
    filter(Boolean),
    switchMap((holder: RangedGridLayoutHolder) => holder.activeBreakpoint$),
  );

  private _columnAdd$: Subject<void> = new Subject<void>();
  private _columnDelete$: Subject<GridTrack> = new Subject<GridTrack>();
  private _columnChange$: Subject<void> = new Subject<void>();

  private _rowAdd$: Subject<void> = new Subject<void>();
  private _rowDelete$: Subject<GridTrack> = new Subject<GridTrack>();
  private _rowChange$: Subject<void> = new Subject<void>();

  constructor() {
    this.columns$ = this.columnsSubject.asObservable();
    this.rows$ = this.rowsSubject.asObservable();
    this.areaAddingDisabled$ = combineLatest([this.columnsSubject, this.rowsSubject]).pipe(
      map(([columns, rows]: [GridTrack[], GridTrack[]]) => [columns, rows].some(isEmpty)),
    );

    this.updateTracksOnBreakpointChange();
    this.updateTracksOnAdding();
    this.updateTracksOnDeleting();
    this.updateTracksOnSizeChanging();
    this.updateCellsOnGridChanges();

    // this.resetAreasIfColumnsOrRowsEmpty();
  }

  private static getTracksFromStyle(style: string): GridTrack[] {
    return style ? style.split(' ').map((size: string) => ({ size })) : [];
  }

  private static getStyleFromTracks(tracks: GridTrack[]): string {
    return tracks.map(({ size }: GridTrack) => size).join(' ');
  }

  set layoutHolder(holder: RangedGridLayoutHolder) {
    this._layoutHolder$.next(holder);
  }

  ngOnDestroy(): void {
    this.isDestroyedSubject.next();
    this.isDestroyedSubject.complete();
  }

  addColumn(): void {
    this._columnAdd$.next();
  }

  deleteColumn(deletedColumn: GridTrack): void {
    this._columnDelete$.next(deletedColumn);
  }

  changeColumn(): void {
    this._columnChange$.next();
  }

  addRow(): void {
    this._rowAdd$.next();
  }

  deleteRow(deletedRow: GridTrack): void {
    this._rowDelete$.next(deletedRow);
  }

  changeRow(): void {
    this._rowChange$.next();
  }

  private updateTracksOnBreakpointChange(): void {
    this._breakpoint$
      .pipe(
        tap((breakpoint: StyleApplicationBreakpoint) => {
          const gridTemplateColumns = GridLayoutGeneratorService.getTracksFromStyle(this._layoutHolder$.value!.container.gridTemplateColumns.get(breakpoint)!);
          const gridTemplateRows = GridLayoutGeneratorService.getTracksFromStyle(this._layoutHolder$.value!.container.gridTemplateRows.get(breakpoint)!);

          this.columnsSubject.next(gridTemplateColumns);
          this.rowsSubject.next(gridTemplateRows);
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateTracksOnAdding(): void {
    this.updateColumnsOnAdding();
    this.updateRowsOnAdding();
  }

  private updateColumnsOnAdding(): void {
    this._columnAdd$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([, breakpoint]: [void, StyleApplicationBreakpoint]) => {
          const { gridTemplateColumns } = this._layoutHolder$.value!.container;
          const oldValue = gridTemplateColumns.get(breakpoint);
          const newValue = oldValue ? `${oldValue} ${GridLayoutGeneratorService.initialColumnSize}` : GridLayoutGeneratorService.initialColumnSize;
          gridTemplateColumns.set(breakpoint, newValue);
          this.columnsSubject.next(GridLayoutGeneratorService.getTracksFromStyle(newValue));
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateRowsOnAdding(): void {
    this._rowAdd$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([, breakpoint]: [void, StyleApplicationBreakpoint]) => {
          const { gridTemplateRows } = this._layoutHolder$.value!.container;
          const oldValue = gridTemplateRows.get(breakpoint);
          const newValue = oldValue ? `${oldValue} ${GridLayoutGeneratorService.initialRowSize}` : GridLayoutGeneratorService.initialRowSize;
          gridTemplateRows.set(breakpoint, newValue);
          this.rowsSubject.next(GridLayoutGeneratorService.getTracksFromStyle(newValue));
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateTracksOnDeleting(): void {
    this.updateColumnsOnDeleting();
    this.updateRowsOnDeleting();
  }

  private updateColumnsOnDeleting(): void {
    this._columnDelete$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([deletedColumn, breakpoint]: [GridTrack, StyleApplicationBreakpoint]) => {
          const oldColumns = this.columnsSubject.value;
          const newColumns = oldColumns.filter((column: GridTrack) => column !== deletedColumn);
          const newValue = GridLayoutGeneratorService.getStyleFromTracks(newColumns);
          this._layoutHolder$.value!.container.gridTemplateColumns.set(breakpoint, newValue);
          this.columnsSubject.next(newColumns);
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateRowsOnDeleting(): void {
    this._rowDelete$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([deletedRow, breakpoint]: [GridTrack, StyleApplicationBreakpoint]) => {
          const oldRows = this.rowsSubject.value;
          const newRows = oldRows.filter((column: GridTrack) => column !== deletedRow);
          const newValue = GridLayoutGeneratorService.getStyleFromTracks(newRows);
          this._layoutHolder$.value!.container.gridTemplateRows.set(breakpoint, newValue);
          this.rowsSubject.next(newRows);
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  /** @bug It doesn't take different breakpoints into account */
  private resetAreasIfColumnsOrRowsEmpty(): void {
    combineLatest([this.columnsSubject, this.rowsSubject])
      .pipe(
        filter(([columns, rows]: [GridTrack[], GridTrack[]]) => [columns, rows].some(isEmpty)),
        tap(() => (this._layoutHolder$.value!.areas = [])),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateTracksOnSizeChanging(): void {
    this.updateColumnsOnSizeChanging();
    this.updateRowsOnSizeChanging();
  }

  private updateColumnsOnSizeChanging(): void {
    this._columnChange$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([, breakpoint]: [void, StyleApplicationBreakpoint]) => {
          const newValue = GridLayoutGeneratorService.getStyleFromTracks(this.columnsSubject.value);
          this._layoutHolder$.value!.container.gridTemplateColumns.set(breakpoint, newValue);
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateRowsOnSizeChanging(): void {
    this._rowChange$
      .pipe(
        withLatestFrom(this._breakpoint$),
        tap(([, breakpoint]: [void, StyleApplicationBreakpoint]) => {
          const newValue = GridLayoutGeneratorService.getStyleFromTracks(this.rowsSubject.value);
          this._layoutHolder$.value!.container.gridTemplateRows.set(breakpoint, newValue);
        }),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private updateCellsOnGridChanges(): void {
    this._layoutHolder$
      .pipe(
        filter(Boolean),
        switchMap(() => combineLatest([this.columnsSubject, this.rowsSubject])),
        tap(([columns, rows]: [GridTrack[], GridTrack[]]) => this.setCells(columns.length, rows.length)),
        takeUntil(this.isDestroyedSubject),
      )
      .subscribe();
  }

  private setCells(columnsCount: number, rowsCount: number): void {
    columnsCount = columnsCount || 1;
    rowsCount = rowsCount || 1;
    const cells: GridCell[] = [];

    for (let gridRowStart = 1; gridRowStart <= rowsCount; gridRowStart++) {
      for (let gridColumnStart = 1; gridColumnStart <= columnsCount; gridColumnStart++) {
        const cell = new GridCell(
          {
            gridColumnStart,
            gridColumnEnd: gridColumnStart + 1,
            gridRowStart,
            gridRowEnd: gridRowStart + 1,
          },
          columnsCount,
          rowsCount,
        );
        cells.push(cell);
      }
    }

    this._layoutHolder$.value!.cells = cells;
  }
}
