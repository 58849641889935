import { Pipe, PipeTransform } from '@angular/core';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

@Pipe({
  name: 'isLinksAttribute',
})
export class IsLinksAttributePipe implements PipeTransform {
  transform(attributeId: string, options: CardWidgetOptions): boolean {
    return attributeId in options.linkTypes.listMap;
  }
}
