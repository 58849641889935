import { Component, Input, ViewChild } from '@angular/core';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { APPLICATION_ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { Table } from 'primeng/table';
import { DataTypeListService } from '../../services/data-type-list.service';

@Component({
  selector: 'app-data-type-list-table',
  templateUrl: './data-type-list-table.component.html',
  styleUrls: ['./data-type-list-table.component.scss'],
})
export class DataTypeListTableComponent extends CoreListComponent<any> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<any>>;
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() applications: ListContainer<NewApplication>;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly dataTypeListService: DataTypeListService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super();
  }

  displayValueToTable(row: any, key: string): string {
    if (key === APPLICATION_ID_KEY) {
      return row.application?.name;
    }
    return this.objectU.getValueFromPath(key, row);
  }

  isColumnMultiSelectable(columnKey: string): boolean {
    return [APPLICATION_ID_KEY, 'status', 'kind', 'baseDataType'].includes(columnKey);
  }

  getOptions(columnKey: string): SelectOption<string, string>[] {
    if (columnKey === APPLICATION_ID_KEY) return this.dataTypeListService.m.applications.toSelectOptions(NAME_KEY);
    else if (columnKey === 'baseDataType') return this.dataTypeListService.m.baseDataTypeOptions;
    else if (columnKey === 'kind') return this.dataTypeListService.m.kindOptions;
    return [];
  }

  protected onInit(): void {
    super.onInit();
    this.registerSubscription(this.applicationSwitcherService.selectedApplication$.subscribe(() => this.dataTypeListService.doFilter(this.table)));
  }
}
