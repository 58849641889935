import { FontStyles, IconStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { ButtonView } from './sidebar-widget-arrow-button.types';

export class ButtonSettings {
  display = false;
  icon: string | null;
  text: string | null;
  align = 'center';
  styles: Record<string, any> | null;
  commonBorderStyles: Record<string, any> | null = {};
  iconStyles: Record<string, any> | null;
  buttonView = new SidebarButtonView();

  constructor(dto?: SidebarButtonSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): SidebarButtonSettingsDto {
    return new SidebarButtonSettingsDto(this);
  }

  fromDto(dto: SidebarButtonSettingsDto): void {
    const { buttonView, display, align, commonBorderStyles = {} } = dto;
    Object.assign(this, { buttonView, display: display || false, align, commonBorderStyles });
    this.changeButtonContent(false);
    Object.values(this.buttonView).forEach(v => {
      !v.contentStyle && (v.contentStyle = new FontStyles());
      !v.iconStyle && (v.iconStyle = new IconStyles());
    });
  }

  changeButtonContent(isHoverIn?: boolean, isDisplaySidebar?: boolean): void {
    const property = isDisplaySidebar ? (isHoverIn ? 'openHover' : 'openNoHover') : isHoverIn ? 'closeHover' : 'closeNoHover';
    const view: ButtonView = this.buttonView[property];
    this.text = view.text;
    this.icon = view.icon;
    this.styles = Object.assign({}, this.commonBorderStyles, view.contentStyle);
    this.iconStyles = view.iconStyle;
  }

  getButtonAlignStyles(): string {
    return `text-align: ${this.align}`;
  }
}

export class SidebarButtonSettingsDto {
  display = false;
  align = '';
  buttonView: SidebarButtonView = new SidebarButtonView();
  commonBorderStyles: Record<string, any> | null = {};

  constructor(settings: Partial<ButtonSettings>) {
    const { buttonView, display, align, commonBorderStyles } = settings;

    if (settings?.buttonView) {
      Object.keys(settings.buttonView).forEach(key => {
        !(settings.buttonView as any)[key].icon && ((settings.buttonView as any)[key].icon = '');
      });
    }

    Object.assign(this, { buttonView, display, align, commonBorderStyles });
  }
}

export class SidebarButtonView {
  closeNoHover = new ButtonView();
  closeHover = new ButtonView();
  openNoHover = new ButtonView();
  openHover = new ButtonView();
}
