<div class="grid">
  <div *ngIf="m.settings.menu.type === menuTypes.panel" class="col-12 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="m.settings.menu.openSubmenuOnHover" binary="true" id="openSubmenuOnHover"></p-checkbox>
        <label for="openSubmenuOnHover">{{'Automatically open sub menu items on hover'| translate}}</label>
      </span>
    </span>
  </div>

  <div *ngIf="m.settings.menu.type === menuTypes.panel" class="col-12 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="m.settings.menu.expandPanelToActive" binary="true"
                    id="expandPanelToActive"></p-checkbox>
        <label for="openSubmenuOnHover">{{'Expand menu to active item automatically'| translate}}</label>
      </span>
    </span>
  </div>

  <div class="col-4 mb-0">
    <button (click)="paddingOp.toggle($event)" class="w-100" icon="bi bi-arrows-move"
            label="{{'Paddings' | translate }}" pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="borderOp.toggle($event)" class="w-100" icon="bi bi-border-style" label="{{'Borders' | translate }}"
            pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="colorOp.toggle($event)" class="w-100" icon="pi pi-palette" label="{{'Colors' | translate }}"
            pButton type="button"></button>
  </div>
</div>

<p-overlayPanel #colorOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <div class="col-12">
      <app-color-picker [(color)]="m.styles.subMenu.backgroundColor"
                        label="{{'Background color'| translate}}"></app-color-picker>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #paddingOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <div class="col-12">
      <h5>{{'Minimal width' | translate}}</h5>
      <input [(ngModel)]="m.styles.subMenu.minWidth" class="w-100" id="smWidth" pInputText type="text" />
    </div>
    <div class="col-12 mt-0">
      <app-padding-form [paddingStyles]="m.styles.subMenu"></app-padding-form>
    </div>
  </ng-template>
</p-overlayPanel>


<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [styles]="m.styles.subMenu"></app-border-form>
  </ng-template>
</p-overlayPanel>
