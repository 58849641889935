import { Component, Input } from '@angular/core';
import { SystemTenantResponseDto } from '@api/models/system-tenant-response-dto';
import { EMAIL_KEY, IS_SYSTEM_ADMIN_KEY, IS_TENANT_ADMIN_KEY, TENANT_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { NewSystemUser } from '@shared/types/user.types';

@Component({
  selector: 'app-system-admin-user-list-table',
  templateUrl: './system-admin-user-list-table.component.html',
  styleUrls: ['./system-admin-user-list-table.component.scss'],
})
export class SystemAdminUserListTableComponent extends CoreListComponent<NewSystemUser> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<NewSystemUser>>;
  @Input() columns: TableColumn[];
  @Input() tenants: ListContainer<SystemTenantResponseDto>;
  tenantOptions: SelectOption<string, string>[];

  IS_TENANT_ADMIN_VALUE = IS_TENANT_ADMIN_KEY;
  IS_SYSTEM_ADMIN_VALUE = IS_SYSTEM_ADMIN_KEY;
  TENANT_VALUE = TENANT_KEY;
  EMAIL_VALUE = EMAIL_KEY;

  constructor() {
    super();
  }

  onInit(): void {
    super.onInit();
    this.tenantOptions = this.tenants.list.map(tenant => new SelectOption<string, string>(tenant.name, tenant.id));
  }
}
