<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'Filter' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.filter].isDynamic" id="isDynamicFilter" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea
      [(ngModel)]="action.actionSettings[RuleKeys.filter].value"
      [placeholder]="'Enter filter' | translate"
      rows="3"
      pInputTextarea
      class="w-100"
      id="filterInput"
    ></textarea>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Scope path to store' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].isDynamic" id="isDynamicScope" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].value"
      [placeholder]="'Enter scope path to store' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>
