import { Component, Input } from '@angular/core';
import { ThrowErrorKeys, WorkflowActionThrowError } from '@workflows/types/actions/action-throw-error';

@Component({
  selector: 'app-action-throw-error',
  templateUrl: './action-throw-error.component.html',
  styleUrls: ['./action-throw-error.component.scss'],
})
export class ActionThrowErrorComponent {
  @Input() action: WorkflowActionThrowError;

  protected readonly RuleKeys = ThrowErrorKeys;
}
