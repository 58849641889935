<ng-container *ngIf="advancedDateFilterObject && advancedDateFilterObject.filters">
  <div *ngFor="let filter of advancedDateFilterObject.filters; let filterI = index">
    <p-dropdown
      [(ngModel)]="filter.filterType"
      (ngModelChange)="onFilterTypeChange($event, filterI)"
      [autoDisplayFirst]="false"
      [filter]="true"
      [options]="filterTypeOptions"
      filterBy="label"
      placeholder="Pick date type"
    >
      <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }} </ng-template>
    </p-dropdown>

    <p-calendar *ngIf="filter.filterType | isFilterCalendarType" [(ngModel)]="filter.value" [showTime]="showTime" [timeOnly]="false"> </p-calendar>

    <p-inputNumber *ngIf="filter.filterType | isFilterNumericType" [(ngModel)]="filter.value"></p-inputNumber>

    <ng-container *ngIf="filter.filterType | isFilterRangeType">
      <p-calendar [(ngModel)]="filter.value[0]" [showTime]="showTime" [placeholder]="'From' | translate"> </p-calendar>

      <p-calendar [(ngModel)]="filter.value[1]" [showTime]="showTime" [placeholder]="'To' | translate" [minDate]="filter.value[0]"> </p-calendar>
    </ng-container>
  </div>

  <span class="bottom-buttons">
    <button (click)="onClear()" class="p-button p-button-outlined" label="{{ 'Clear' | translate }}" pButton type="button"></button>
    <button (click)="onApply()" [disabled]="!isFilterValid()" class="p-button" label="{{ 'Apply' | translate }}" pButton type="button"></button>
  </span>
</ng-container>
