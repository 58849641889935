import { Directive, Input, OnChanges, OnDestroy } from '@angular/core';
import { AttributeBasedEvent } from '@workflows/shared';
import { Subscription } from 'rxjs';
import { AbstractRuleDirective } from './abstract-rule.directive';

@Directive()
export class AbstractRuleAttributeDirective extends AbstractRuleDirective implements OnDestroy, OnChanges {
  @Input() artifactTypeId: string;
  @Input() attributeId: string;
  @Input() widgetId: string;

  protected initSubscription: Subscription;
  protected key = '';

  ngOnChanges() {
    if (!this.key && this.artifactTypeId && this.attributeId) {
      this.key = `${this.artifactTypeId}_${this.attributeId}`;
    }
  }

  ngOnDestroy(): void {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
  }

  protected isMatchingAttributeData(data: AttributeBasedEvent): boolean {
    const { artifactTypeId, attributeId } = data;
    return this.artifactTypeId === artifactTypeId && this.attributeId === attributeId;
  }
}
