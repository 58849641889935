import { Background, Border, BoxShadow, Margin, Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundTypeEnum, BorderWidthEnum } from '../../shared/types/style.types';

export class NumberWidgetStyles implements NumberWidgetStylesDto {
  borderTopWidth: BorderWidthEnum;
  borderRightWidth: BorderWidthEnum;
  borderBottomWidth: BorderWidthEnum;
  borderLeftWidth: BorderWidthEnum;

  borderTopStyle?: string;
  borderRightStyle?: string;
  borderBottomStyle?: string;
  borderLeftStyle?: string;

  borderTopColor?: string;
  borderRightColor?: string;
  borderBottomColor?: string;
  borderLeftColor?: string;

  borderTopLeftRadius = '4px';
  borderTopRightRadius = '4px';
  borderBottomRightRadius = '4px';
  borderBottomLeftRadius = '4px';
  borderRadiusRange: number[];

  boxShadow: string;

  backgroundType: BackgroundTypeEnum = BackgroundTypeEnum.color;
  backgroundColor: string;
  backgroundImage?: string;
  background: string;
  gradient: string;
  gradientDirection: string;
  gradientColor1: string;
  gradientColor2: string;

  marginTop = '0';
  marginRight = '0';
  marginBottom: string;
  marginLeft = '0';

  paddingTop = '5px';
  paddingRight = '5px';
  paddingBottom = '5px';
  paddingLeft = '5px';

  constructor(dto?: NumberWidgetStylesDto) {
    Object.assign(this, dto);
  }
}

export type NumberWidgetStylesDto = Border & BoxShadow & Background & Margin & Padding;
