import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem, PaginationSettingEnum } from '../types/list-widget-grouping.types';

@Pipe({ name: 'shouldShowSharedPaginator' })
export class ShouldShowSharedPaginatorPipe implements PipeTransform {
  transform(
    rowsPerPage: number,
    totalCount: number,
    pagination: PaginationSettingEnum,
    groupingAttributes: SelectOption<string, GroupAttributeItem>[],
  ): unknown {
    return (pagination === PaginationSettingEnum.perTable || !groupingAttributes.length) && rowsPerPage < totalCount;
  }
}
