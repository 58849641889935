<h5>Margin</h5>
<div class="grid mt-2">
  <!-- margin TOP -->
  <div class="col-3">
    <h6>Top</h6>
    <input pInputText type="text" [(ngModel)]="marginStyles.marginTop" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- margin RIGHT -->
  <div class="col-3">
    <h6>Right</h6>
    <input pInputText type="text" [(ngModel)]="marginStyles.marginRight" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- margin BOTTOM -->
  <div class="col-3">
    <h6>Bottom</h6>
    <input pInputText type="text" [(ngModel)]="marginStyles.marginBottom" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- margin LEFT -->
  <div class="col-3">
    <h6>Left</h6>
    <input pInputText type="text" [(ngModel)]="marginStyles.marginLeft" (ngModelChange)="changesEmitted.emit(true)" />
  </div>
</div>
