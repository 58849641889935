import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { NewUser } from '@shared/types/user.types';
import { WorkflowOwnerType, WorkflowType } from '@workflows/types';

export class WorkflowListModel {
  options = new WorkflowListOptions();
  columns: TableColumn[];
}

export class WorkflowListOptions {
  users: ListContainer<NewUser> = new ListContainer<NewUser>();
  userOptions: SelectOption<string, string>[] = [];
  workflowTypeOptions: SelectOption<string, WorkflowType>[] = [];
  workflowOwnerTypeOptions: SelectOption<string, WorkflowOwnerType>[] = [];
}
