import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { LinkTypeListComponent } from '@private/pages/artifact-type-management/link-type-list/link-type-list.component';
import { LinkTypeListModel } from '@private/pages/artifact-type-management/link-type-list/types/link-type-list.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  ALIAS_KEY,
  ALIAS_LABEL,
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  DESCRIPTION_KEY,
  DESCRIPTION_LABEL,
  INCOMING_NAME_KEY,
  INCOMING_NAME_LABEL,
  NAME_KEY,
  NAME_LABEL,
  OUTGOING_NAME_KEY,
  OUTGOING_NAME_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class LinkTypeListService extends CoreService<LinkTypeListComponent, LinkTypeListModel> {
  constructor(private readonly cache: NewCacheService) {
    super();
  }

  init(context: LinkTypeListComponent, model: LinkTypeListModel): void {
    super.init(context, model);

    this.m.columns = [
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(DESCRIPTION_LABEL, DESCRIPTION_KEY),
      new TableColumn(INCOMING_NAME_LABEL, INCOMING_NAME_KEY),
      new TableColumn(OUTGOING_NAME_LABEL, OUTGOING_NAME_KEY),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
      new TableColumn(ALIAS_LABEL, ALIAS_KEY),
    ];

    this.initSubscriptions();
  }

  doFilter(table: Table): void {
    table && table.filter(null, '', '');
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.data.applications.subscribe(applications => applications && this.m.applications.setList(applications as ApplicationResponseDto[], 'id')),
    ]);
  }
}
