import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FadeAnimation } from '@shared/animations/animations';
import { DisplayAtUtilService } from '@shared/components/common-display-at/services/display-at-util.service';
import { TextStyleHelper } from '@shared/helpers/text-style.helper';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import {
  CUSTOM_VARIANT_KEY,
  DATUserSubscribeLayoutVariant,
  DEFAULT_VARIANT_KEY,
  DisplayAtDropdownItem,
  DisplayAttributeType,
} from '@shared/types/display-at-types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum, LabelPositionEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-attribute-format-settings',
  templateUrl: './attribute-format-settings.component.html',
  styleUrls: ['./attribute-format-settings.component.scss'],
  animations: [FadeAnimation],
})
export class AttributeFormatSettingsComponent extends TextStyleHelper implements OnChanges {
  @Input() options: { attributes: ListContainer<NewAttribute>; dataTypes: ListContainer<NewDataType> };
  @Input() attribute: NewClientAttribute;
  @Input() dataType: NewDataType;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() folderSettings: FolderWidgetSettings;
  @Input() changeLabelStyleReference: (formatSettings: AttributeFormatSettings) => void;
  @Input() onLabelPositionChange: (event: { originalEvent: PointerEvent; value: LabelPositionEnum }, formatSettings: AttributeFormatSettings) => void;

  @Output() editEnabled: EventEmitter<void> = new EventEmitter<void>();
  @Output() editDisabled: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCustomVariantSelection: EventEmitter<string> = new EventEmitter();

  attributeDisplayVariantOptions?: SelectOption<string, string>[];

  constructor(private readonly displayAtUtilService: DisplayAtUtilService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attribute) {
      this.onAttributeChange();
    }
  }

  changeFieldStyleReference(): void {
    this.formatSettings.value.fieldStyles = { ...this.formatSettings.value.fieldStyles };
  }

  onEditEnabled(): void {
    this.editEnabled.emit();
  }

  onEditDisabled(): void {
    this.editDisabled.emit();
  }

  onSelectedItemChange(selectedItem: string) {
    if (selectedItem === CUSTOM_VARIANT_KEY) {
      this.onCustomVariantSelection.emit(this.attribute.id);
    }
  }

  isFolderPath(): boolean {
    return this.attribute.id === NonAttributeKeys.FOLDER_PATH_ID;
  }

  private onAttributeChange() {
    if (
      NonAttributeKeys.isUserSpecificAttributeKeyOrId(this.attribute.id) ||
      NonAttributeKeys.isDateSpecificAttributeId(this.attribute.id) ||
      this.dataType?.isDate ||
      this.dataType?.isDateTime ||
      this.dataType?.isEnum ||
      this.dataType?.isBoolean ||
      this.dataType?.isUser ||
      this.dataType?.isHyperlink
    ) {
      this.setDisplayVariantsMetaData(this.dataType?.isDateTime, this.dataType?.isDate);
      return;
    }
    this.attributeDisplayVariantOptions = undefined;
  }

  /**
   * Currently applicable just for user specific attributes.
   */
  private setDisplayVariantsMetaData(isDateTime: boolean, isDate: boolean): void {
    const isSystemUser = NonAttributeKeys.isUserSpecificAttributeKeyOrId(this.attribute.id);
    const isSystemDate = NonAttributeKeys.isDateSpecificAttributeId(this.attribute.id);

    const attribute = (!isDateTime && !isSystemDate && !isSystemUser && this.options.attributes.listMap[this.attribute.id]) || undefined;

    const attributeType = isSystemDate
      ? DisplayAttributeType.systemDate
      : isDateTime || isDate
      ? DisplayAttributeType.dateTime
      : (!isSystemUser && this.displayAtUtilService.fromAttributeAndDataType(attribute as NewAttribute, this.dataType)) || DisplayAttributeType.user;

    const displayAttributeTypeEnumObject = this.displayAtUtilService.getDisplayAtEnumObjectForType(attributeType);
    this.attributeDisplayVariantOptions = this.toSelectOptions(
      this.displayAtUtilService.getDisplayAtDropdownItems(displayAttributeTypeEnumObject).filter(({ code }: DisplayAtDropdownItem) => code !== 'DROPDOWN'),
    );

    if (attributeType === DisplayAttributeType.user && !isDateTime && !isDate && !isSystemDate && !isSystemUser && attribute?.multipleValues) {
      const userSubscribeOptions = this.toSelectOptions(this.displayAtUtilService.getDisplayAtDropdownItems(DATUserSubscribeLayoutVariant));
      this.attributeDisplayVariantOptions = [...this.attributeDisplayVariantOptions, ...userSubscribeOptions];
    }
    this.formatSettings.value.displayMetadata ??= {
      attributeType,
      selectedVariantCode: DEFAULT_VARIANT_KEY,
    };
  }

  private toSelectOptions(displayAtDropdownItems: DisplayAtDropdownItem[]): SelectOption<string, string>[] {
    return displayAtDropdownItems.map(item => new SelectOption<string, string>(item.label, item.code));
  }
}
