<div class="rule-top-container">
  <div *ngIf="ruleAdministrationType | showRuleOwnerTypeAndOwnerPipe" class="rule-type-owner-container">
    <div class="col-3">
      <label for="ruleType" class="ml-2" translate>Type</label>
      <p-dropdown
        [(ngModel)]="rule.type"
        [options]="ruleTypes"
        [disabled]="!isNew"
        [autoDisplayFirst]="false"
        id="ruleType"
        (onChange)="onRuleTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option | translate }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option | translate }}</ng-template>
      </p-dropdown>
    </div>
    <div class="col-3">
      <label for="ruleOwnerType" class="ml-2" translate>Owner type</label>
      <p-dropdown
        [(ngModel)]="rule.ownerType"
        [options]="ruleOwnerTypes"
        [disabled]="rule.type | isOwnerTypeDisabled : isNew"
        [autoDisplayFirst]="false"
        id="ruleOwnerType"
        (onChange)="onOwnerTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option | titlecase }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option | titlecase }}</ng-template>
      </p-dropdown>
    </div>
    <div class="col-3 align-self-end">
      <ng-container *ngIf="rule.ownerType | showRuleOwner">
        <label for="ruleOwner" class="ml-2" translate>Owner</label>
        <p-dropdown
          id="ruleOwner"
          appendTo="body"
          [filter]="true"
          filterBy="name"
          optionValue="id"
          optionLabel="name"
          [autoDisplayFirst]="false"
          [options]="ruleOwnerOptions"
          [disabled]="rule.ownerType | isOwnerDisabled : isNew"
          [(ngModel)]="rule.ownerId"
          (onChange)="onOwnerChange($event.value)"
        >
        </p-dropdown>
      </ng-container>
    </div>
    <div class="col-3 static">
      <ng-container *ngIf="ruleAdministrationType | showUsedIn : rule.ownerType : rule.global">
        <span class="dynamic-label">
          <label for="ruleUsedIn" class="ml-2" translate>Used in</label>
          <p-multiSelect
            id="ruleUsedIn"
            appendTo="body"
            [filter]="true"
            filterBy="name"
            optionValue="id"
            optionLabel="name"
            [disabled]="rule.ownerType | isUsedInDisabled : isNew"
            [options]="ruleUsedInOptions"
            [(ngModel)]="rule.usedIn"
          >
          </p-multiSelect>
        </span>
      </ng-container>
    </div>
  </div>

  <div class="rule-global-active-container mb-4 mt-4">
    <div class="col-2">
      <p-checkbox [(ngModel)]="rule.active" inputId="ruleActive" [binary]="true" [disabled]="!editable"></p-checkbox>
      <label for="ruleActive" class="ml-2" translate>Is Active</label>
    </div>

    <div class="col-2">
      <ng-container *ngIf="rule.ownerType | showIsGlobal">
        <p-checkbox
          [(ngModel)]="rule.global"
          inputId="ruleGlobal"
          [binary]="true"
          [disabled]="!editable"
          (onChange)="onGlobalChange($event.value)"
        ></p-checkbox>
        <label for="ruleGlobal" class="ml-2" translate>Is Global</label>
      </ng-container>
    </div>

    <div class="col-2">
      <div class="dynamic-label p-float-label mb-0">
        <p-inputNumber [(ngModel)]="rule.priority" mode="decimal" [maxFractionDigits]="5" [useGrouping]="false" class="rule-priority-input"></p-inputNumber>
        <label for="priorityInput" translate>Priority</label>
      </div>
    </div>
  </div>

  <div class="dynamic-label p-float-label mt-4">
    <input pInputText id="ruleName" type="text" [(ngModel)]="rule.name" [disabled]="!editable" />
    <label class="dynamic-label-text" for="ruleName" translate>Name</label>
  </div>

  <div class="dynamic-label p-float-label mt-6">
    <textarea pInputTextarea id="ruleDescription" rows="3" [(ngModel)]="rule.description" [disabled]="!editable"> </textarea>
    <label class="dynamic-label-text" for="ruleDescription" translate>Description</label>
  </div>

  <span *ngIf="rule.ownerType !== OwnerType.CUSTOM && rule.ownerType !== OwnerType.LINK">
    {{ 'WORKFLOWS.COMMON.' + (defaultArtifactType ? 'ADDED' : 'ADD') | translate }}
    <p-dropdown
      class="workflow-element-dropdown"
      styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
      appendTo="body"
      placeholder="{{ 'WORKFLOWS.PLACEHOLDER.DEFAULT_ARTIFACT_TYPE' | translate }}"
      [filter]="true"
      filterBy="name"
      optionValue="id"
      [options]="artifactTypes"
      [readonly]="!editable"
      [(ngModel)]="rule.defaultArtifactTypeId"
      (onChange)="onArtifactTypeChange($event.value)"
    >
      <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
    </p-dropdown>
    <span *ngIf="defaultArtifactType">
      {{ 'WORKFLOWS.COMMON.AS' | translate }}
      {{ 'WORKFLOWS.PLACEHOLDER.DEFAULT_ARTIFACT_TYPE' | translate }}
      {{ 'WORKFLOWS.COMMON.FOR_THIS_RULE' | translate }}
    </span>
  </span>

  <div class="rule-element-container with-extra-margin mt-4" [class.readonly]="!editable">
    <ng-container *ngFor="let trigger of rule.triggers; let i = index">
      <app-workflows-rule-trigger
        [trigger]="trigger"
        [artifactTypes]="artifactTypes"
        [linkableArtifactTypes]="linkableArtifactTypes$ | async"
        [attributes]="attributes.list"
        [dataTypes]="dataTypes"
        [linkTypes]="linkTypes"
        [defaultArtifactType]="defaultArtifactType"
        [pageWidgets]="pageWidgets"
        [editable]="rule.ownerType | isTriggerEditable : editable"
        (onTriggerTypeUpdate)="onTriggerTypeUpdate($event, trigger)"
        (onTriggerPropertyUpdate)="onTriggerPropertyUpdate($event)"
      ></app-workflows-rule-trigger>
      <div>
        <ng-container *ngIf="rule.ownerType | showTriggerButtons : editable">
          <button
            *ngIf="trigger.isValid() && rule.triggers.length - 1 === i"
            pButton
            label="{{ 'WORKFLOWS.COMMON.ADD_TRIGGER' | translate }}"
            class="p-button p-button-info btn-workflows"
            (click)="addNewTrigger()"
          ></button>
          <button
            *ngIf="rule.triggers.length > 1"
            pButton
            label="{{ 'WORKFLOWS.COMMON.DELETE' | translate }}"
            class="p-button p-button-danger btn-workflows rule-element-delete-button"
            (click)="deleteTrigger(trigger)"
          ></button>
        </ng-container>
        <ng-container *ngIf="rule.ownerType === OwnerType.CUSTOM">
          <button
            *ngIf="!trigger.inputDataClientArray?.length"
            pButton
            label="{{ 'WORKFLOWS.COMMON.ADD_INPUTS' | translate }}"
            class="p-button p-button-info btn-workflows"
            (click)="onAddTriggerInputs()"
          ></button>
          <button
            *ngIf="trigger.inputDataClientArray?.length"
            pButton
            label="{{ 'WORKFLOWS.COMMON.REMOVE_INPUTS' | translate }}"
            class="p-button p-button-danger btn-workflows"
            (click)="onRemoveTriggerInputs()"
          ></button>
        </ng-container>
      </div>
      <div *ngIf="trigger.inputDataClientArray?.length">
        <app-trigger-manual [trigger]="trigger"></app-trigger-manual>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="hasValidTriggers()">
    <div class="rule-condition-container">
      <div class="rule-condition-header">
        <div>
          <span *ngIf="rule.condition"> {{ 'Conditions' | translate }} </span>
        </div>
        <div>
          <button
            *ngIf="!rule.condition && (rule.defaultArtifactTypeId || rule.ownerType === OwnerType.CUSTOM || rule.ownerType === OwnerType.LINK)"
            pButton
            class="p-button-info"
            type="button"
            (click)="onActivateConditions()"
            [label]="'Add conditions' | translate"
          ></button>
          <button
            *ngIf="rule.condition"
            pButton
            class="p-button p-button-danger"
            type="button"
            (click)="onDeactivateConditions()"
            [label]="'Remove all conditions' | translate"
          ></button>
        </div>
      </div>
      <ng-container *ngIf="rule.condition">
        <app-workflows-rule-condition
          #condition
          [group]="rule.condition"
          [defaultArtifactType]="defaultArtifactType"
          [artifactTypes]="artifactTypes"
          [attributes]="attributes.list"
          [allAttributes]="attributes"
          [users]="users"
          [dataTypes]="dataTypes"
          [triggers]="rule.triggers"
          [workflowType]="rule.type"
          [depth]="1"
        ></app-workflows-rule-condition>
      </ng-container>
    </div>
    <!-- <div class="rule-element-container with-extra-margin">Container for conditions...</div> -->
    <div class="rule-element-container with-extra-margin" [class.readonly]="!editable">
      <ng-container *ngFor="let action of rule.actions; let index = index">
        <app-workflows-rule-action
          [selectableActions]="selectableActions"
          [triggers]="rule.triggers"
          [action]="action"
          [applications]="applications"
          [artifactTypes]="artifactTypes"
          [linkableArtifactTypes]="linkableArtifactTypes$ | async"
          [attributes]="attributes.list"
          [allAttributes]="attributes"
          [dataTypes]="dataTypes"
          [linkTypes]="linkTypes"
          [users]="users"
          [defaultArtifactType]="defaultArtifactType"
          [editable]="editable"
          [index]="index"
          (onActionTypeUpdate)="onActionTypeUpdate($event, action)"
          (onActionPropertyUpdate)="onActionPropertyUpdate($event)"
        ></app-workflows-rule-action>
        <div>
          <ng-container *ngIf="editable">
            <button
              *ngIf="action.isValid()"
              pButton
              label="{{ 'WORKFLOWS.COMMON.ADD_ACTION' | translate }}"
              class="p-button p-button-info btn-workflows btn-add-action"
              (click)="addNewAction(index)"
            ></button>
            <button
              *ngIf="rule.actions.length > 1"
              pButton
              label="{{ 'WORKFLOWS.COMMON.DELETE' | translate }}"
              class="p-button p-button-danger btn-workflows rule-element-delete-button"
              (click)="deleteAction(action)"
            ></button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
