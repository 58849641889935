import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RippleModule } from 'primeng/ripple';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { BreakpointTabsComponent } from './components/breakpoint-tabs/breakpoint-tabs.component';
import { GridAreasGeneratorComponent } from './components/grid-areas-generator/grid-areas-generator.component';
import { GridGapFieldComponent } from './components/grid-gap-field/grid-gap-field.component';
import { GridLayoutPreviewComponent } from './components/grid-layout-preview/grid-layout-preview.component';
import { GridTracksGeneratorComponent } from './components/grid-tracks-generator/grid-tracks-generator.component';
import { GridLayoutGeneratorComponent } from './grid-layout-generator.component';
import { GridTemplateTrackStylesPipe } from './pipes/grid-template-track-styles.pipe';

@NgModule({
  declarations: [
    GridLayoutGeneratorComponent,
    GridTracksGeneratorComponent,
    GridGapFieldComponent,
    GridAreasGeneratorComponent,
    GridLayoutPreviewComponent,
    GridTemplateTrackStylesPipe,
    BreakpointTabsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RippleModule,
    ButtonModule,
    DividerModule,
    InputTextModule,
    InputNumberModule,
    TabMenuModule,
    InplaceModule,
    KeyFilterModule,
    TabViewModule,
    CheckboxModule,
  ],
  exports: [GridGapFieldComponent, GridLayoutGeneratorComponent, GridLayoutPreviewComponent],
})
export class GridLayoutGeneratorModule {}
