import { PageSectionOuterContainerStyles } from '@private/pages/page-management/page-builder-graphical/types/page-section-outer-container-styles';
import { ContainerClassEnum } from '@widgets/shared/types/style.types';
import { PageSectionContainerStyles } from './page-section-container-styles';

export class PageSectionStyles {
  outerContainer: PageSectionOuterContainerStyles = new PageSectionOuterContainerStyles();
  innerContainer: PageSectionContainerStyles = new PageSectionContainerStyles();

  constructor(pageSectionStyles?: Partial<PageSectionStyles> | null) {
    this.outerContainer = new PageSectionOuterContainerStyles(pageSectionStyles?.outerContainer);
    this.innerContainer = new PageSectionContainerStyles(pageSectionStyles?.innerContainer);

    if (!pageSectionStyles?.outerContainer?.containerClass) {
      this.outerContainer.containerClass = ContainerClassEnum.full;
    }

    if (!pageSectionStyles?.innerContainer?.containerClass) {
      this.innerContainer.containerClass = ContainerClassEnum.box;
    }
  }
}
