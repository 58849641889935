import { Component, Input } from '@angular/core';
import { ButtonView } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button.types';
import { SelectOption } from '@shared/types/shared.types';
import { Subscription } from 'rxjs';
import { IconsService } from '@shared/services/icons.service';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';
import { SidebarModalSettings } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-settings.types';
import { StyleCopyPastingService } from '@widgets/menu-widget/services/style-copy-pasting.service';

@Component({
  selector: 'app-sidebar-modal-button-form',
  templateUrl: './sidebar-modal-button-form.component.html',
  styleUrls: ['./sidebar-modal-button-form.component.scss'],
})
export class SidebarModalButtonFormComponent {
  @Input() button: ButtonView;
  @Input() settings: SidebarModalSettings;
  iconOptions: SelectOption<string, string>[] = [];
  horizontalAlignOptions: SelectOption<string, string>[] = [];
  currentStyle: any;

  private iconSub: Subscription;

  constructor(private readonly iconService: IconsService, public readonly styleCopyService: StyleCopyPastingService) {}

  ngOnInit(): void {
    this.iconSub = this.iconService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => (this.iconOptions = iconOptions));
    this.horizontalAlignOptions = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  }

  ngOnDestroy(): void {
    this.iconSub.unsubscribe();
  }
}
