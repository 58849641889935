import { WidgetModel } from '../../shared/types/widget-model.interface';
import { SidebarModalPosition } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SidebarModalConstants } from '@widgets/sidebar-modal-widget/constants/constants';
import { SidebarModalButtonSettings, SidebarModalButtonSettingsDto } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button.types';
import { TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';
import { Styles } from '@api/models/styles';

export class SidebarModalSettings implements WidgetModel<SidebarModalWidgetSettingsDto> {
  position: SidebarModalPosition = SidebarModalPosition.right;
  showOnInit = false;
  storeState = false;
  isInside = false;
  isFixed = false;
  showClose = true;
  height: string;
  width: string = SidebarModalConstants.DEFAULT_WIDTH;
  topGap: string | null;
  bottomGap: string | null;
  leftGap: string | null;
  rightGap: string | null;
  bgColor: string = SidebarModalConstants.DEFAULT_BACKGROUND_COLOR;
  byPageContent = false;
  overflowX = 'hidden';
  overflowY = 'scroll';
  buttons = new SidebarModalButtonSettings();
  alignButton: TextHorizontalAlignEnum = TextHorizontalAlignEnum.center;
  isBlur = false;
  isOverlay = false;
  blurValue: string;
  overlayColor: string;
  overlayOpacity: string;
  isCloseByOverlayClick = false;
  styles: Styles = {};

  constructor(dto?: SidebarModalWidgetSettingsDto) {
    dto && this.fromDto(dto);
  }

  // todo dto add styles of shadow + new blur/overlay
  toServer(): SidebarModalWidgetSettingsDto {
    const dto: SidebarModalWidgetSettingsDto = {
      position: this.position,
      showOnInit: this.showOnInit,
      storeState: this.storeState,
      isInside: this.isInside,
      isFixed: this.isFixed,
      height: this.height,
      width: this.width,
      byPageContent: this.byPageContent,
      overflowX: this.overflowX,
      overflowY: this.overflowY,
      buttons: this.buttons.toServer(),
      alignButton: this.alignButton,
      isOverlay: this.isOverlay,
      isBlur: this.isBlur,
      blurValue: this.blurValue,
      isCloseByOverlayClick: this.isCloseByOverlayClick,
      styles: this.styles,
    };

    if (this.isOverlay) {
      this.overlayColor && (dto.overlayColor = this.overlayColor);
      this.overlayOpacity && (dto.overlayOpacity = this.overlayOpacity);
    }

    const { topGap, bottomGap, leftGap, rightGap, bgColor } = this;
    this.checkUndefinedAndApply({ topGap, bottomGap, leftGap, rightGap, bgColor }, dto);
    return dto;
  }

  fromDto(dto: SidebarModalWidgetSettingsDto): void {
    const {
      position,
      showOnInit,
      storeState,
      isInside,
      isFixed,
      height,
      width,
      bgColor,
      topGap,
      bottomGap,
      leftGap,
      rightGap,
      byPageContent,
      overflowX,
      overflowY,
      buttons,
      alignButton,
      isOverlay,
      isBlur,
      blurValue,
      overlayColor,
      overlayOpacity,
      styles,
      isCloseByOverlayClick,
    } = dto;

    Object.assign(this, { position, isInside, isFixed, height, width, bgColor });
    this.checkUndefinedAndApply(
      {
        showOnInit,
        storeState,
        topGap,
        bottomGap,
        leftGap,
        rightGap,
        bgColor,
        byPageContent,
        overflowX,
        overflowY,
        alignButton,
        isOverlay,
        isBlur,
        blurValue,
        overlayColor,
        overlayOpacity,
        styles,
        isCloseByOverlayClick,
      },
      this,
    );
    buttons && (this.buttons = new SidebarModalButtonSettings(buttons));
  }

  checkUndefinedAndApply(fields: Record<string, any>, dest: any): void {
    Object.keys(fields).forEach(key => {
      fields[key] && (dest[key] = fields[key]);
    });
  }

  get overlayStyles(): Styles | any {
    return {
      backgroundColor: this.isOverlay ? this.overlayColor : '',
      opacity: this.overlayOpacity,
    };
  }
}

export class SidebarModalWidgetSettingsDto {
  position: SidebarModalPosition;
  showOnInit: boolean;
  storeState: boolean;
  isInside: boolean;
  isFixed: boolean;
  height: string;
  width: string;
  topGap?: string;
  bottomGap?: string;
  leftGap?: string;
  rightGap?: string;
  bgColor?: string;
  byPageContent: boolean;
  overflowX: string;
  overflowY: string;
  buttons: SidebarModalButtonSettingsDto;
  alignButton: TextHorizontalAlignEnum;
  isBlur?: boolean;
  overlayColor?: string;
  overlayOpacity?: string;
  isCloseByOverlayClick?: boolean;
  isOverlay?: boolean;
  blurValue?: string;
  styles?: any;
}
