import { ChipStyles, FontStyles, IconStyles } from '../../menu-widget/types/menu-widget-styles.types';
import { SidebarModalButtonBorderStyles, SidebarModalButtonStyles } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button-styles.types';

export class SidebarModalButtonSettings {
  buttonView = new SidebarModalButtonView();

  constructor(dto?: SidebarModalButtonSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): SidebarModalButtonSettingsDto {
    return new SidebarModalButtonSettingsDto(this);
  }

  fromDto(dto: SidebarModalButtonSettingsDto): void {
    const { buttonView } = dto;
    buttonView && Object.assign(this, { buttonView });
  }
}

export class SidebarModalButtonSettingsDto {
  buttonView: SidebarModalButtonView = new SidebarModalButtonView();

  constructor(dto: Partial<SidebarModalButtonSettings>) {
    dto && Object.assign(this, dto);
  }
}

export class SidebarModalButtonView {
  closeNoHover = new ButtonView();
  closeHover = new ButtonView();
  openNoHover = new ButtonView();
  openHover = new ButtonView();

  constructor(dto?: SidebarModalButtonView) {
    dto && Object.assign(this, dto);
  }
}

export class ButtonView {
  label: string;
  subLabel: string;
  chip: string;
  icon: string;
  labelStyle: FontStyles = new FontStyles();
  subLabelStyle: FontStyles = new FontStyles();
  chipStyle: ChipStyles = new ChipStyles();
  iconStyle: IconStyles = new IconStyles();
  generalStyles = new SidebarModalButtonStyles();
  borderStyles = new SidebarModalButtonBorderStyles();
}
