/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { workflowControllerCreate } from '../fn/tenant-workflow/workflow-controller-create';
import { WorkflowControllerCreate$Params } from '../fn/tenant-workflow/workflow-controller-create';
import { workflowControllerDelete } from '../fn/tenant-workflow/workflow-controller-delete';
import { WorkflowControllerDelete$Params } from '../fn/tenant-workflow/workflow-controller-delete';
import { workflowControllerGet } from '../fn/tenant-workflow/workflow-controller-get';
import { WorkflowControllerGet$Params } from '../fn/tenant-workflow/workflow-controller-get';
import { workflowControllerInsert } from '../fn/tenant-workflow/workflow-controller-insert';
import { WorkflowControllerInsert$Params } from '../fn/tenant-workflow/workflow-controller-insert';
import { workflowControllerList } from '../fn/tenant-workflow/workflow-controller-list';
import { WorkflowControllerList$Params } from '../fn/tenant-workflow/workflow-controller-list';
import { workflowControllerRemove } from '../fn/tenant-workflow/workflow-controller-remove';
import { WorkflowControllerRemove$Params } from '../fn/tenant-workflow/workflow-controller-remove';
import { workflowControllerScheduleWorkflow } from '../fn/tenant-workflow/workflow-controller-schedule-workflow';
import { WorkflowControllerScheduleWorkflow$Params } from '../fn/tenant-workflow/workflow-controller-schedule-workflow';
import { workflowControllerUpdate } from '../fn/tenant-workflow/workflow-controller-update';
import { WorkflowControllerUpdate$Params } from '../fn/tenant-workflow/workflow-controller-update';
import { WorkflowListResponseDto } from '../models/workflow-list-response-dto';
import { WorkflowResponseDto } from '../models/workflow-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantWorkflowService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `workflowControllerList()` */
  static readonly WorkflowControllerListPath = '/api/tenant/workflow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerList$Response(params?: WorkflowControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowListResponseDto>> {
    return workflowControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerList(params?: WorkflowControllerList$Params, context?: HttpContext): Observable<WorkflowListResponseDto> {
    return this.workflowControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowListResponseDto>): WorkflowListResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerUpdate()` */
  static readonly WorkflowControllerUpdatePath = '/api/tenant/workflow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerUpdate$Response(params: WorkflowControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerUpdate(params: WorkflowControllerUpdate$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerCreate()` */
  static readonly WorkflowControllerCreatePath = '/api/tenant/workflow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerCreate$Response(params: WorkflowControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerCreate(params: WorkflowControllerCreate$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerGet()` */
  static readonly WorkflowControllerGetPath = '/api/tenant/workflow/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerGet$Response(params: WorkflowControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerGet(params: WorkflowControllerGet$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerDelete()` */
  static readonly WorkflowControllerDeletePath = '/api/tenant/workflow/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerDelete$Response(params: WorkflowControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workflowControllerDelete(params: WorkflowControllerDelete$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerScheduleWorkflow()` */
  static readonly WorkflowControllerScheduleWorkflowPath = '/api/tenant/workflow/schedule/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerScheduleWorkflow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerScheduleWorkflow$Response(params: WorkflowControllerScheduleWorkflow$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return workflowControllerScheduleWorkflow(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerScheduleWorkflow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerScheduleWorkflow(params: WorkflowControllerScheduleWorkflow$Params, context?: HttpContext): Observable<{
}> {
    return this.workflowControllerScheduleWorkflow$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `workflowControllerInsert()` */
  static readonly WorkflowControllerInsertPath = '/api/tenant/workflow/use';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerInsert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerInsert$Response(params: WorkflowControllerInsert$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerInsert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerInsert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerInsert(params: WorkflowControllerInsert$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerInsert$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

  /** Path part for operation `workflowControllerRemove()` */
  static readonly WorkflowControllerRemovePath = '/api/tenant/workflow/unuse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workflowControllerRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerRemove$Response(params: WorkflowControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkflowResponseDto>> {
    return workflowControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workflowControllerRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workflowControllerRemove(params: WorkflowControllerRemove$Params, context?: HttpContext): Observable<WorkflowResponseDto> {
    return this.workflowControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkflowResponseDto>): WorkflowResponseDto => r.body)
    );
  }

}
