/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { sessionControllerCount } from '../fn/system-session/session-controller-count';
import { SessionControllerCount$Params } from '../fn/system-session/session-controller-count';
import { sessionControllerDelete } from '../fn/system-session/session-controller-delete';
import { SessionControllerDelete$Params } from '../fn/system-session/session-controller-delete';
import { sessionControllerGet } from '../fn/system-session/session-controller-get';
import { SessionControllerGet$Params } from '../fn/system-session/session-controller-get';
import { sessionControllerList } from '../fn/system-session/session-controller-list';
import { SessionControllerList$Params } from '../fn/system-session/session-controller-list';
import { sessionControllerUpdate } from '../fn/system-session/session-controller-update';
import { SessionControllerUpdate$Params } from '../fn/system-session/session-controller-update';
import { SessionListResponseDto } from '../models/session-list-response-dto';
import { SessionResponseDto } from '../models/session-response-dto';

@Injectable({ providedIn: 'root' })
export class SystemSessionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sessionControllerCount()` */
  static readonly SessionControllerCountPath = '/api/system/session/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerCount$Response(params?: SessionControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return sessionControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerCount(params?: SessionControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.sessionControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionControllerGet()` */
  static readonly SessionControllerGetPath = '/api/system/session/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerGet$Response(params: SessionControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SessionResponseDto>> {
    return sessionControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerGet(params: SessionControllerGet$Params, context?: HttpContext): Observable<SessionResponseDto> {
    return this.sessionControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SessionResponseDto>): SessionResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionControllerDelete()` */
  static readonly SessionControllerDeletePath = '/api/system/session/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerDelete$Response(params: SessionControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<SessionResponseDto>> {
    return sessionControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerDelete(params: SessionControllerDelete$Params, context?: HttpContext): Observable<SessionResponseDto> {
    return this.sessionControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<SessionResponseDto>): SessionResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionControllerList()` */
  static readonly SessionControllerListPath = '/api/system/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerList$Response(params?: SessionControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<SessionListResponseDto>> {
    return sessionControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionControllerList(params?: SessionControllerList$Params, context?: HttpContext): Observable<SessionListResponseDto> {
    return this.sessionControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<SessionListResponseDto>): SessionListResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionControllerUpdate()` */
  static readonly SessionControllerUpdatePath = '/api/system/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionControllerUpdate$Response(params: SessionControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SessionResponseDto>> {
    return sessionControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionControllerUpdate(params: SessionControllerUpdate$Params, context?: HttpContext): Observable<SessionResponseDto> {
    return this.sessionControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SessionResponseDto>): SessionResponseDto => r.body)
    );
  }

}
