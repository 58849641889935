import { Pipe, PipeTransform } from '@angular/core';
import { AttributeLabelFormatSettings, LinkTypeLabelFormatSettings } from '../../../types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '../../../types/style.types';

@Pipe({ name: 'labelClass' })
export class LabelStylesPipe implements PipeTransform {
  transform(formatSettings: AttributeLabelFormatSettings | LinkTypeLabelFormatSettings, labelBehaviour: LabelBehaviourEnum, readOnly: boolean): string {
    if (!formatSettings || (formatSettings instanceof AttributeLabelFormatSettings && labelBehaviour === LabelBehaviourEnum.placeholder)) return '';

    const classes = [];
    classes.push('label-' + formatSettings.position);
    if (!formatSettings.visible && !(formatSettings as AttributeLabelFormatSettings).showIcon) {
      classes.push('label-hidden');
    }

    if (readOnly || [LabelBehaviourEnum.static, LabelBehaviourEnum.float].includes(labelBehaviour)) {
      classes.push('label-horizontal-' + formatSettings.styles.textAlign);
      classes.push('label-vertical-' + formatSettings.styles.verticalAlign);
    }

    return classes.join(' ');
  }
}
