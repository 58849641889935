import { Injectable } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import {
  BaseDataType,
  BaseDataTypeBoundedRange,
  BaseDataTypeEnumerated,
  DataTypeKind,
  EnumeratedOption,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { StateKey } from '@shared/types/local-storage.types';
import { ConfirmationService } from 'primeng/api';
import { BLANK_FILTER_OPTION_LABEL, EMPTY_FILTER_VALUE_MULTIPLE, EMPTY_FILTER_VALUE_SINGLE, USER_KEY } from '../constants/constants';
import { LocalStorageService } from '../services/local-storage.service';
import { DoSomethingWithConfirmationParams, SelectOption } from '../types/shared.types';
import { REPLACE_TABLE } from './ascii-replace-table';
import { TranslateUtil } from './translateUtil';

@Injectable({ providedIn: 'root' })
export class ElvisUtil {
  constructor(public readonly translateUtil: TranslateUtil, private readonly localStorageService: LocalStorageService) {}

  static makeHash(length: number): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    return Array(length)
      .fill(0)
      .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
      .join('');
  }

  transformAnyToSelectOptions(targetArr: any[], labelKey: string | null = null, valueKey: string | null = null): SelectOption<any, any>[] {
    return targetArr.map(item => new SelectOption(labelKey ? item[labelKey] : item, valueKey ? item[valueKey] : item));
  }

  checkNumFormat(event: string, fieldId: string, targetObject: any = null, key: string | null = null, isInteger = false, isDecimal = false): void {
    const decimal = /^[0-9]{0,}([.][0-9]{0,})?$/g;
    const integer = /^[0-9]{0,}$/g;
    const input: HTMLInputElement = document.getElementById(fieldId) as HTMLInputElement;
    const setValueToTarget = (value: string): any => targetObject && key && (targetObject[key] = value);

    if (isInteger && !integer.test(event)) {
      setValueToTarget(event.substr(0, event.length - 1));
      input.value = event.substr(0, event.length - 1);
    } else {
      if (isDecimal) {
        if (event.length === 1 && event === '.') {
          setValueToTarget('0.');
          input.value = '0.';
        }

        if (!decimal.test(event)) {
          setValueToTarget(event.substr(0, event.length - 1));
          input.value = event.substr(0, event.length - 1);
        }
      }
    }
  }

  replaceDiacritics(str: string): string {
    return str
      .split('')
      .map<string>(letter => REPLACE_TABLE[letter] ?? letter)
      .join('')
      .replace(/\s+/g, '_')
      .toUpperCase();
  }

  getKindOptions(): SelectOption<DataTypeKind, DataTypeKind>[] {
    return Object.keys(DataTypeKind).map(option => new SelectOption(DataTypeKind[option as keyof typeof DataTypeKind]));
  }

  getBaseDataTypeOptions(): SelectOption<BaseDataType, BaseDataType>[] {
    const enumOptions = { ...BaseDataType, ...BaseDataTypeEnumerated, ...BaseDataTypeBoundedRange };
    return Object.keys(enumOptions).map(option => new SelectOption(enumOptions[option as keyof typeof enumOptions])) as any;
  }

  async doSomethingWithConfirmation(
    confirmationService: ConfirmationService,
    params: DoSomethingWithConfirmationParams,
    onAccept: () => void,
    onReject?: () => void,
  ): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(
      [params.header || '', params.message || '', params.acceptLabel, params.rejectLabel],
      params.interpolateParams,
    );
    confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => onAccept(),
      reject: () => onReject && onReject(),
    });
  }

  makeArrayFromValue<Type>(value: Type): Type[] {
    return Array.isArray(value) ? value : [value];
  }

  /* Flattens multi-dimensional array into 1-dimensional array */
  flatDeep(arr: any, d = 1): any[] {
    return d > 0 ? arr.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), []) : arr.slice();
  }

  withEmptySelectOption(selectOptions: SelectOption<string, any>[], multipleOptions: boolean): SelectOption<string, any>[] {
    const emptyValue = multipleOptions ? EMPTY_FILTER_VALUE_MULTIPLE : EMPTY_FILTER_VALUE_SINGLE;
    return selectOptions.concat([new SelectOption(BLANK_FILTER_OPTION_LABEL, emptyValue)]);
  }

  withEmptyEnumeratedOption(enumeratedOptions: EnumeratedOption[], multipleOptions: boolean): EnumeratedOption[] {
    const emptyValue = multipleOptions ? EMPTY_FILTER_VALUE_MULTIPLE : EMPTY_FILTER_VALUE_SINGLE;
    return enumeratedOptions.concat([new EnumeratedOption(BLANK_FILTER_OPTION_LABEL, emptyValue)]);
  }

  getCurrentUser(): SelfUserResponseDto {
    return this.localStorageService.getFromState(StateKey.session, USER_KEY);
  }
}
