import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColorScheme } from '@private/pages/profile/types/user.types';
import { HasUiConfig } from '@shared/types/has-ui-config.types';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent<T extends HasUiConfig> implements OnInit {
  menuThemes: any[];
  componentThemes: any[];
  app: T;
  colorScheme = ColorScheme;

  constructor(public router: Router, public configService: ConfigService<T>) {}

  ngOnInit(): void {
    this.app = this.configService.app;
    this.componentThemes = this.configService.getComponentThemes();
    this.menuThemes = this.configService.getMenuThemes();
  }

  isOnAdminRoute(): boolean {
    return this.router.url.split('/').length === 4;
  }
}
