import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { UNRESOLVED_VALUE } from '@shared/constants/constants';
import { ConvertToClientDate } from '@shared/methods/date.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuntimeVariablesMethods } from '../../methods/runtime-variables.methods';
import { RuntimeVariablesOptions } from './runtime-variables-options.types';

export enum RuntimeArtifactKeys {
  id = 'id',
  label = 'label',
  format = 'format',
  createdOn = 'created-on',
  createdBy = 'created-by',
  updatedOn = 'updated-on',
  updatedBy = 'updated-by',
}

export class RuntimeArtifact {
  [RuntimeArtifactKeys.id]: string;
  [RuntimeArtifactKeys.label]: string;
  [RuntimeArtifactKeys.format]: string;
  [RuntimeArtifactKeys.createdOn]: string;
  [RuntimeArtifactKeys.createdBy]: string;
  [RuntimeArtifactKeys.updatedOn]: string;
  [RuntimeArtifactKeys.updatedBy]: string;

  private readonly options: RuntimeVariablesOptions;

  constructor(dto: ArtifactResponseDto, options: RuntimeVariablesOptions) {
    this.options = options;
    const { id, format, created, updated } = dto;

    this[RuntimeArtifactKeys.id] = id;
    this[RuntimeArtifactKeys.format] = format;

    this.setLabel(dto, options);
    this.initCreatedAndUpdated(created, updated, options);
    Object.assign(this, this.getClientAttributes(dto, options));
  }

  private setLabel(dto: ArtifactResponseDto | NewArtifact, options: RuntimeVariablesOptions) {
    const artifactType = options.artifactTypes.listMap[dto.artifactTypeId];

    if (!artifactType) {
      this[RuntimeArtifactKeys.label] = '';
      return;
    }

    this[RuntimeArtifactKeys.label] = artifactType.primaryAttributes
      .map(attributeId =>
        RuntimeVariablesMethods.formatVariableValue(
          dto,
          attributeId,
          options.artifactTypes.listMap,
          options.attributes.listMap,
          options.dataTypes.listMap,
          options.users.listMap,
        ),
      )
      .join(', ');
  }

  private getClientAttributes(dto: ArtifactResponseDto, options: RuntimeVariablesOptions): Record<string, string> {
    const res: Record<string, string> = {};

    Object.keys(dto.attributes).forEach(attributeId => {
      const alias = options.attributes.listMap[attributeId]?.alias;

      alias &&
        (res[alias] = RuntimeVariablesMethods.formatVariableValue(
          dto,
          attributeId,
          options.artifactTypes.listMap,
          options.attributes.listMap,
          options.dataTypes.listMap,
          options.users.listMap,
        ));
    });

    return res;
  }

  private initCreatedAndUpdated(created: RecordDto, updated: RecordDto, options: RuntimeVariablesOptions): void {
    const usersMap = options.users.list.reduce<Record<string, string>>((res, user) => {
      res[user.id] = user.email;
      return res;
    }, {});

    this[RuntimeArtifactKeys.createdOn] = ConvertToClientDate(new Date(created.on));
    this[RuntimeArtifactKeys.createdBy] = usersMap[created.by] || UNRESOLVED_VALUE;

    this[RuntimeArtifactKeys.updatedOn] = ConvertToClientDate(new Date(updated.on));
    this[RuntimeArtifactKeys.updatedBy] = usersMap[updated.by] || UNRESOLVED_VALUE;
  }
}
