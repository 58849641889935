import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'showModifyValueOperationPicker' })
export class ShowModifyValueOperationPickerPipe implements PipeTransform {
  transform(attributeId: string | undefined, attributes: ListContainer<NewAttribute>, dataTypes: ListContainer<NewDataType>): boolean {
    if (!attributeId) return false;

    const attribute = attributes.listMap[attributeId];
    const dataType = dataTypes.listMap[attribute.dataTypeId];

    return attribute.multipleValues && (dataType.kind === DataTypeKind.enumerated || dataType.baseDataType === BaseDataType.user);
  }
}
