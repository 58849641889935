import { Injectable } from '@angular/core';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { cloneDeep } from 'lodash';
import { BorderStyles } from '../../shared/types/style.types';
import {
  ChipStyles,
  ContentStyles,
  FontStyles,
  IconStyles,
  MenuItemContentStyles,
  MenuItemDefaultContentStyles,
  MenuItemStyles,
  MenuStylesPropNamesEnum,
} from '../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../types/menu-widget.types';

@Injectable({
  providedIn: 'root',
})
export class StyleCopyPastingService {
  copiedContentStyles: ContentStyles | FontStyles | null = null;
  copiedMenuWidgetItem: MenuItem | null = null;
  copiedBorderStyles: BorderStyles;
  m: MenuWidgetModel | null = null;

  constructor(private readonly menuWidgetHelper: MenuWidgetHelper) {}

  copyContentStyles(styleToCopy: ContentStyles | FontStyles): void {
    this.copiedContentStyles = cloneDeep(styleToCopy);
  }

  copyMenuItem(selectedItem: MenuItem): void {
    this.copiedMenuWidgetItem = cloneDeep(selectedItem);
  }

  // TODO remake, BAD
  copyBorderStyles(itemElement: MenuItemStyles): void {
    this.copiedBorderStyles = new BorderStyles();

    Object.keys(this.copiedBorderStyles).forEach(property => {
      Object.prototype.hasOwnProperty.call(this.copiedBorderStyles, property) && ((this.copiedBorderStyles as any)[property] = (itemElement as any)[property]);
    });
  }

  pasteBorderStyle(itemElement: MenuItemStyles): void {
    Object.assign(itemElement, cloneDeep(this.copiedBorderStyles));
  }

  pasteStyleIntoContent(contentStyle: FontStyles | IconStyles | ChipStyles | any): void {
    // TODO remake
    const styleToPaste = cloneDeep(this.copiedContentStyles);
    if (contentStyle instanceof IconStyles && (styleToPaste instanceof FontStyles || styleToPaste instanceof ChipStyles)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete styleToPaste.fontFamily;
    }
    if (!(contentStyle instanceof ChipStyles) && styleToPaste instanceof ChipStyles) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete styleToPaste.backgroundColor;
    }
    Object.assign(contentStyle, styleToPaste);
  }

  pasteIntoAllItems(m: MenuWidgetModel): void {
    this.m = m;
    this.pasteRecursively(this.m.items.menu);
    this.m = null;
  }

  pasteIntoSingleMenuItem(itemToPasteInto: MenuItem, m: MenuWidgetModel): void {
    if (this.copiedMenuWidgetItem) {
      this.m = m;
      this.pasteIntoMenuItem(this.copiedMenuWidgetItem, itemToPasteInto);
      this.m = null;
    }
  }

  pasteIntoMenuItem(copiedItem: MenuItem, itemToPasteInto: MenuItem): void {
    if (!copiedItem.usesDefaultStyle && !itemToPasteInto.usesDefaultStyle) {
      this.pasteCustomToCustom(copiedItem, itemToPasteInto);
    } else if (!copiedItem.usesDefaultStyle && itemToPasteInto.usesDefaultStyle) {
      this.pasteCustomToDefault(copiedItem, itemToPasteInto);
    } else if (copiedItem.usesDefaultStyle && itemToPasteInto.usesDefaultStyle) {
      this.pasteDefaultToDefault(copiedItem, itemToPasteInto);
    } else {
      this.pasteDefaultToCustom(copiedItem, itemToPasteInto);
    }
    this.menuWidgetHelper.refreshMenuItemStylesPipe(this.m, itemToPasteInto.hash);
  }

  private pasteCustomToCustom(copiedItem: MenuItem, itemToPasteInto: MenuItem): void {
    if (this.m) {
      this.pasteContentStyles(this.m.styles.standardStyles, itemToPasteInto.hash, copiedItem.hash);
      this.pasteContentStyles(this.m.styles.hoverStyles, itemToPasteInto.hash, copiedItem.hash);
      this.pasteContentStyles(this.m.styles.activeStyles, itemToPasteInto.hash, copiedItem.hash);
      Object.assign(this.m.styles.generalMenuItemStyles[itemToPasteInto.hash], cloneDeep(this.m.styles.generalMenuItemStyles[copiedItem.hash]));
      Object.assign(this.m.settings.item[itemToPasteInto.hash], cloneDeep(this.m.settings.item[copiedItem.hash]));
      Object.assign(this.m.styles.horizontalDivider[itemToPasteInto.hash], cloneDeep(this.m.styles.horizontalDivider[copiedItem.hash]));
      Object.assign(this.m.styles.verticalDivider[itemToPasteInto.hash], cloneDeep(this.m.styles.verticalDivider[copiedItem.hash]));
    }
  }

  private pasteDefaultToDefault(copiedItem: MenuItem, itemToPasteInto: MenuItem): void {
    if (this.m) {
      const defaultLevelCopied = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, copiedItem);
      const defaultLevelToPasteIn = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, itemToPasteInto);
      Object.assign(defaultLevelToPasteIn, cloneDeep(defaultLevelCopied));
      this.copyNonInheritableStyles(copiedItem.hash, itemToPasteInto.hash);
    }
  }

  private pasteCustomToDefault(copiedItem: MenuItem, itemToPasteInto: MenuItem): void {
    if (this.m) {
      const defaultLevel = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, itemToPasteInto);
      this.pasteCustomToDefaultContentStyles(defaultLevel.hoverMenuItemStyle, this.m.styles.hoverStyles, copiedItem);
      this.pasteCustomToDefaultContentStyles(defaultLevel.activeMenuItemStyle, this.m.styles.activeStyles, copiedItem);
      this.pasteCustomToDefaultContentStyles(defaultLevel.standardMenuItemStyle, this.m.styles.standardStyles, copiedItem);
      Object.assign(this.m.settings.item[itemToPasteInto.hash], cloneDeep(this.m.settings.item[copiedItem.hash]));
      Object.assign(defaultLevel.generalMenuItemStyles, cloneDeep(this.m.styles.generalMenuItemStyles[copiedItem.hash]));
      Object.assign(defaultLevel.verticalDividerStyles, cloneDeep(this.m.styles.verticalDivider[copiedItem.hash]));
      Object.assign(defaultLevel.horizontalDividerStyles, cloneDeep(this.m.styles.horizontalDivider[copiedItem.hash]));
      this.copyNonInheritableStyles(copiedItem.hash, itemToPasteInto.hash);
    }
  }

  private pasteDefaultToCustom(copiedItem: MenuItem, itemToPasteInto: MenuItem): void {
    itemToPasteInto.usesDefaultStyle = true;
    this.copyNonInheritableStyles(copiedItem.hash, itemToPasteInto.hash);
  }

  private pasteCustomToDefaultContentStyles(defaultItemStyle: MenuItemDefaultContentStyles, styleType: MenuItemContentStyles, copiedItem: MenuItem): void {
    Object.assign(defaultItemStyle[MenuStylesPropNamesEnum.item], cloneDeep(styleType[MenuStylesPropNamesEnum.item][copiedItem.hash]));
    Object.assign(defaultItemStyle[MenuStylesPropNamesEnum.label], cloneDeep(styleType[MenuStylesPropNamesEnum.label][copiedItem.hash]));
    Object.assign(defaultItemStyle[MenuStylesPropNamesEnum.subLabel], cloneDeep(styleType[MenuStylesPropNamesEnum.subLabel][copiedItem.hash]));
    Object.assign(defaultItemStyle[MenuStylesPropNamesEnum.icon], cloneDeep(styleType[MenuStylesPropNamesEnum.icon][copiedItem.hash]));
    Object.assign(defaultItemStyle[MenuStylesPropNamesEnum.chip], cloneDeep(styleType[MenuStylesPropNamesEnum.chip][copiedItem.hash]));
  }

  private pasteContentStyles(styleType: MenuItemContentStyles, targetHash: string, fromHash: string): void {
    Object.assign(styleType.label[targetHash], cloneDeep(styleType.label[fromHash]));
    Object.assign(styleType.subLabel[targetHash], cloneDeep(styleType.subLabel[fromHash]));
    Object.assign(styleType.icon[targetHash], cloneDeep(styleType.icon[fromHash]));
    Object.assign(styleType.item[targetHash], cloneDeep(styleType.item[fromHash]));
    Object.assign(styleType.chip[targetHash], cloneDeep(styleType.chip[fromHash]));
  }

  private pasteRecursively(children: MenuItem[]): void {
    children.forEach(item => {
      if (this.m && this.m.selected.menuItem) {
        this.pasteIntoMenuItem(this.m.selected.menuItem, item);
        item.children.length && this.pasteRecursively(item.children);
      }
    });
  }

  private copyNonInheritableStyles(copiedItemHash: string, itemToPasteIntoHash: string): void {
    if (!this.m) return;

    const { icon, chip } = MenuStylesPropNamesEnum;

    Object.assign(this.m.styles.standardStyles[icon][itemToPasteIntoHash], this.m.styles.standardStyles[icon][copiedItemHash]);
    Object.assign(this.m.styles.standardStyles[chip][itemToPasteIntoHash], this.m.styles.standardStyles[chip][copiedItemHash]);
    Object.assign(this.m.styles.hoverStyles[icon][itemToPasteIntoHash], this.m.styles.hoverStyles[icon][copiedItemHash]);
    Object.assign(this.m.styles.hoverStyles[chip][itemToPasteIntoHash], this.m.styles.hoverStyles[chip][copiedItemHash]);
    Object.assign(this.m.styles.activeStyles[icon][itemToPasteIntoHash], this.m.styles.activeStyles[icon][copiedItemHash]);
    Object.assign(this.m.styles.activeStyles[chip][itemToPasteIntoHash], this.m.styles.activeStyles[chip][copiedItemHash]);
  }
}
