<p-table
  #table
  *ngIf="this.applicationSwitcherService.selectedApplication?.id"
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  [filters]="{
    deleted: [{ value: null, matchMode: 'equals', operator: 'and' }],
    applicationId: [{ value: [this.applicationSwitcherService.selectedApplication.id], matchMode: 'in', operator: 'and' }]
  }"
  selectionMode="multiple"
  [(selection)]="selectedPages"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngIf="selectPages" style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
        <p-columnFilter
          *ngIf="col.key === APPLICATION_ID_VALUE"
          [field]="col.key"
          matchMode="in"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
        >
          <ng-template pTemplate="header">
            <div class="px-3 pt-3 pb-0">
              <span class="p-text-bold">{{ col.key }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="applications.list | transformArrayToSelectOptions : NAME_VALUE: ID_VALUE"
              (onChange)="filter($event.value)"
              placeholder="Any"
            >
              <ng-template let-option pTemplate="item">
                <div>
                  <span class="ml-1">{{ option.label | translate }}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
        <p-columnFilter
          *ngIf="col.key === NAME_VALUE || col.key === ALIAS_VALUE"
          type="text"
          [field]="col.key"
          matchMode="contains"
          display="menu"
        ></p-columnFilter>
      </th>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-page let-columns="columns">
    <tr>
      <td *ngIf="selectPages">
        <p-tableCheckbox [value]="page"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns">
        <div class="p-text-truncate">
          <span>{{ page | printPageToTable : col.key : applications }}</span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <div class="table_buttons">
            <a [routerLink]="['/admin/page-builder', page.id]">
              <button
                pButton
                class="p-button p-button-rounded p-button-warning p-button-outlined"
                type="button"
                pTooltip="{{ 'Edit' | translate }}"
                icon="pi pi-pencil"
                iconPos="left"
              ></button>
            </a>
            <a [routerLink]="['/page', page.id]">
              <button
                pButton
                pTooltip="{{ 'Preview' | translate }}"
                type="button"
                icon="pi pi-eye"
                class="p-button p-button-rounded p-button-secondary p-button-outlined"
              ></button>
            </a>
            <button
              pButton
              pRipple
              type="button"
              class="p-button p-button-rounded p-button-text"
              icon="pi pi-ellipsis-v"
              (click)="openPanel(page, op, $event)"
            ></button>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="pagesService.m.columns.length + 1">{{ 'No records found' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="table_buttons">
      <button
        pButton
        class="p-button p-button-rounded p-button-blue p-button-outlined"
        type="button"
        pTooltip="{{ 'Duplicate' | translate }}"
        icon="pi pi-copy"
        iconPos="left"
        (click)="copyPage(page.id, op)"
      ></button>
      <button
        pButton
        class="p-button p-button-rounded p-button-danger p-button-outlined"
        type="button"
        pTooltip="{{ 'Delete' | translate }}"
        icon="pi pi-trash"
        iconPos="left"
        (click)="deletePage(op)"
      ></button>
    </div>
  </ng-template>
</p-overlayPanel>
