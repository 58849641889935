<div class="grid page-builder" cdkDropList (cdkDropListDropped)="drop($event)">

    <app-row-item *ngFor="let canvas of m.canvasList" [canvas]="canvas"></app-row-item>

    <app-add-button-section [toggleLayoutToolbar]="toggleLayoutToolbar.bind(this)"></app-add-button-section>

    <p-overlayPanel #layoutToolbar [showCloseIcon]="true">
        <ng-template pTemplate>
            <div class="layout-toolbar">
                <div class="layout-toolbar_prepared">
                    <span class="layout-icon"
                          [ngClass]="{'layout-last-icon': iconI === m.layoutToolbar.icons.length - 1}"
                          (click)="s.addCanvas(m.layoutToolbar.preparedLayoutOptions[iconI])"
                          *ngFor="let icon of m.layoutToolbar.icons; let iconI = index;"
                          pTooltip="{{m.layoutToolbar.preparedLayoutOptions[iconI]}}">
                        <span style="{{'width: ' + col * 10 + '%'}}" *ngFor="let col of icon"></span>
                    </span>
                </div>

                <div class="layout-toolbar_generated">
                    <span class="dynamic-label">
                        <input type="text" id="custom" pInputText value="12">
                        <label class="dynamic-label-text" for="custom" translate>Custom</label>
                    </span>
                    <button pButton
                            pRipple
                            label="{{'Generate' | translate}}"
                            class="p-button-success"
                            (click)="s.generateCanvas()"
                            [disabled]="generateButtonDisabled()">
                    </button>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
<!--    <div class="p-col md:col-3 list"-->
<!--         id="mainList"-->
<!--         cdkDropList-->
<!--         [cdkDropListConnectedTo]="['secondList']"-->
<!--         [cdkDropListData]="m.list"-->
<!--         cdkDropListSortingDisabled-->
<!--         (cdkDropListDropped)="drop($event)">-->
<!--        <div class="list_item" *ngFor="let item of m.list" cdkDrag>-->
<!--            <div class="list_item__placeholder" *cdkDragPlaceholder></div>-->
<!--            {{item.name}}-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="p-col md:col-9 canvas-list">-->
<!--        <div class="canvas-item" *ngFor="let canvasItem of m.canvas">-->

<!--        </div>-->
<!--         id="secondList"-->
<!--         cdkDropList-->
<!--         [cdkDropListConnectedTo]="['mainList']"-->
<!--         [cdkDropListData]="m.canvas"-->
<!--         (cdkDropListDropped)="drop($event)">-->
<!--        <div class="list_item" *ngFor="let item of m.canvas" cdkDrag>-->
<!--            <div class="list_item__placeholder" *cdkDragPlaceholder></div>-->
<!--            {{item.name}}-->
<!--        </div>-->
<!--    </div>-->
</div>
