import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactSelection } from '../artifact-list-widget-table.component';

@Pipe({
  name: 'getButtonLabel',
})
export class GetButtonLabelPipe implements PipeTransform {
  transform(artifactSelection: ArtifactSelection, action: string): string {
    const multipleArtifactsSelected = Array.isArray(artifactSelection) && artifactSelection.length > 1;

    return multipleArtifactsSelected ? `${action} selected` : action;
  }
}
