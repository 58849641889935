import { Pipe, PipeTransform } from '@angular/core';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

@Pipe({
  name: 'isSingleHyperlinkAttribute',
})
export class IsSingleHyperlinkAttributePipe implements PipeTransform {
  transform(attributeId: string, options: CardWidgetOptions): boolean {
    const attribute = options.attributes.listMap[attributeId];
    const dataType = options.dataTypes.listMap[attribute?.dataTypeId];

    return !attribute?.multipleValues && dataType?.isHyperlink;
  }
}
