import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageHelper } from '@shared/helpers/page-helper';
import { BackgroundTypeEnum, StyleOptions } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss'],
})
export class PageSettingsComponent implements OnInit {
  @Input() page: Page;
  readonly styleOptions: StyleOptions = new StyleOptions();
  readonly backgroundType: typeof BackgroundTypeEnum = BackgroundTypeEnum;
  gradientChangeCb: () => void;

  constructor(private pageHelper: PageHelper) {}

  ngOnInit(): void {
    if (!this.page.seoFields) this.page.seoFields = {};
    if (!this.page.seoFields.ogTitle) this.page.seoFields.ogTitle = this.page.name;
    this.gradientChangeCb = this.gradientChange.bind(this);
  }

  stylesTypeChange(): void {
    this.page.settings.styles.background = '';
    this.pageHelper.setBackgroundToBody(this.page.settings.styles);
  }

  gradientChange(): void {
    setTimeout(() => this.pageHelper.setBackgroundToBody(this.page.settings.styles));
  }
}
