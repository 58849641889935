import { ApexAxisPosition } from '@widgets/apex-chart/types/apex-chart-widget.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';

export class ApexAxis {
  id: string = ElvisUtil.makeHash(20);
  name = 'Axis 1';
  position: SelectOption<string, ApexAxisPosition> = new SelectOption(ApexAxisPosition.left);
  titleStyle = new FontStyles();
  labelStyle = new FontStyles();

  constructor(dto?: ApexChartAxisDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ApexChartAxisDto {
    const dto: ApexChartAxisDto = {
      id: this.id,
      name: this.name,
      position: this.position.value,
      titleStyle: this.titleStyle,
      labelStyle: this.labelStyle,
    };

    return dto;
  }

  fromDto(dto: ApexChartAxisDto): void {
    const { id, name, position, titleStyle, labelStyle } = dto;
    this.checkUndefinedAndApply({ id, name, position, titleStyle, labelStyle }, this);
    position && (this.position = new SelectOption(position));
  }

  private checkUndefinedAndApply(fields: Record<string, any>, dest: any): void {
    Object.keys(fields).forEach(key => {
      fields[key] && (dest[key] = fields[key]);
    });
  }
}

export class ApexChartAxisDto {
  id?: string;
  name: string;
  position?: ApexAxisPosition;
  titleStyle?: FontStyles;
  labelStyle?: FontStyles;
}
