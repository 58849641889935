/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApexChartWidgetTypeResponseDto } from '../../models/apex-chart-widget-type-response-dto';
import { ArtifactWidgetTypeResponseDto } from '../../models/artifact-widget-type-response-dto';
import { AuthWidgetTypeResponseDto } from '../../models/auth-widget-type-response-dto';
import { AvrWidgetTypeResponseDto } from '../../models/avr-widget-type-response-dto';
import { CardWidgetTypeResponseDto } from '../../models/card-widget-type-response-dto';
import { ChartWidgetTypeResponseDto } from '../../models/chart-widget-type-response-dto';
import { FilterWidgetTypeResponseDto } from '../../models/filter-widget-type-response-dto';
import { FolderWidgetTypeResponseDto } from '../../models/folder-widget-type-response-dto';
import { ListMatrixWidgetTypeResponseDto } from '../../models/list-matrix-widget-type-response-dto';
import { ListWidgetTypeResponseDto } from '../../models/list-widget-type-response-dto';
import { MenuWidgetTypeResponseDto } from '../../models/menu-widget-type-response-dto';
import { NumberWidgetTypeResponseDto } from '../../models/number-widget-type-response-dto';
import { PictureWidgetTypeResponseDto } from '../../models/picture-widget-type-response-dto';
import { SidebarModalWidgetTypeResponseDto } from '../../models/sidebar-modal-widget-type-response-dto';
import { SidebarWidgetTypeResponseDto } from '../../models/sidebar-widget-type-response-dto';
import { TagWidgetTypeResponseDto } from '../../models/tag-widget-type-response-dto';
import { TextWidgetTypeResponseDto } from '../../models/text-widget-type-response-dto';

export interface WidgetControllerDelete$Params {
  id: string;

}

export function widgetControllerDelete(http: HttpClient, rootUrl: string, params: WidgetControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>> {
  const rb = new RequestBuilder(rootUrl, widgetControllerDelete.PATH, 'delete');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(TextWidgetTypeResponseDto | MenuWidgetTypeResponseDto | NumberWidgetTypeResponseDto | ArtifactWidgetTypeResponseDto | CardWidgetTypeResponseDto | PictureWidgetTypeResponseDto | ListMatrixWidgetTypeResponseDto | SidebarWidgetTypeResponseDto | ListWidgetTypeResponseDto | ChartWidgetTypeResponseDto | ApexChartWidgetTypeResponseDto | FolderWidgetTypeResponseDto | TagWidgetTypeResponseDto | FilterWidgetTypeResponseDto | AuthWidgetTypeResponseDto | AvrWidgetTypeResponseDto | SidebarModalWidgetTypeResponseDto)>;
    })
  );
}

widgetControllerDelete.PATH = '/api/tenant/widget/{id}';
