import { RecordDto } from '@api/models/record-dto';
import { NewApplication } from '@shared/types/application.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { DataType } from '../../data-type/components/data-type-form/types/data-type-form.types';

export class AttributesModel {
  isDeleted = false;

  constructor(
    public inProgress: boolean = false,
    public attributes: NewAttribute[] = [],
    public attribute: NewAttribute | null = null,
    public attributeCopy: NewAttribute | null = null,
    public dataTypes: ListContainer<NewDataType> = new ListContainer<NewDataType>(),
    public applications: NewApplication[] = [],
  ) {}
}

export class Attribute {
  constructor(
    public name: string = '',
    public description: string = '',
    public dataType: DataType | null = null,
    public uri: string = '',
    public id: string | null = null,
    public applicationId: string | null = null,
    public multipleValues: boolean = false,
    public created: RecordDto | null = null,
    public updated: RecordDto | null = null,
    public deleted: RecordDto | null = null,
  ) {}
}
