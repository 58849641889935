import { PageResponseDto } from '@api/models/page-response-dto';
import { PageSectionResponseDto } from '@api/models/page-section-response-dto';
import { PageSeoFieldsRequestDto } from '@api/models/page-seo-fields-request-dto';
import { RecordDto } from '@api/models/record-dto';
import { PageElement } from '@private/pages/page-management/page-builder-graphical/types/page-element';
import { PageSectionStyles } from '@private/pages/page-management/page-builder-graphical/types/page-section-styles';
import { PageSettings } from '@private/pages/page-management/page-builder-graphical/types/page-settings';
import { ToolbarActionsLayoutLocation } from '@private/pages/page-management/page-builder-graphical/types/toolbar-actions-layout-location';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { Template } from '@shared/components/templates/types/templates.types';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { SidebarModalWidgetModel, SidebarModalWidgetValue } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SidebarListItem, SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { BlockPartWidget } from './block-part-widget';
import { PageBlock } from './page-block';
import { PageBlockPart } from './page-block-part';
import { PageRow } from './page-row';
import { PageSection } from './page-section';
import { PartLocation } from './part-location';
import { RowLocation } from './row-location';
import { SectionLocation } from './section-location';

export class Page {
  id: string;
  applicationId: string;
  isPublic: boolean;
  name = '';
  alias?: string;
  pageParameters?: string;
  // TODO replace "PageSeoFieldsRequestDto" to "PageSeoFieldsResponseDto" when BE will update API
  seoFields?: PageSeoFieldsRequestDto;
  sections: PageSection[] = [];
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;
  widgetIdsToDelete: string[] = [];
  templates: Record<string, Template> = {};
  settings: PageSettings = new PageSettings();
  modalId: string | null;

  constructor(dto?: PageResponseDto) {
    if (!dto) {
      return this;
    }

    const { sections, ...rest } = dto;
    Object.assign(this, rest);

    this.sections = (sections || []).map((sectionDto: PageSectionResponseDto) => {
      const rows = sectionDto.rows?.map(PageRow.fromDto);
      return new PageSection(rows, sectionDto.templateId, new PageSectionStyles(sectionDto.styles), null, sectionDto.sectionHide, sectionDto.htmlId);
    });
  }

  updateSidebarModal(pageList: Page[]): void {
    this.sections.forEach(section => {
      section.partsWithWidgets.forEach(part => {
        if (part?.widget?.code === WidgetType.sidebarModal && part.widget.id) {
          part.widget.value.model.page.sections.forEach((s: PageSection) => {
            pageList.forEach(page => {
              if (s.templateId && page.templates[s.templateId]) {
                s = Object.assign(s, page.templates[s.templateId], page.templates[s.templateId].template);

                if (part.widget && s.templateId) {
                  const innerPage = part.widget.value.model.page;
                  !innerPage?.templates && (innerPage.templates = {});
                  innerPage.templates[s.templateId] = page.templates[s.templateId];
                }
              }
            });
          });
        }
      });
    });
  }

  getSidebarModalPages(): Page[] {
    const res: Page[] = [];

    this.sections.forEach(section => {
      section.rows.forEach(row => {
        row.parts.forEach(part => {
          if (part?.widget?.code === WidgetType.sidebarModal && part.widget.id) {
            res.push(new Page(part.widget.value.model.page));
          }
        });
      });
    });
    return res;
  }

  getSidebars(): SidebarListItem[] {
    const res: SidebarListItem[] = [];
    this.sections.forEach(section => {
      section.rows.forEach(row => {
        row.parts.forEach(part => {
          if (part?.widget?.code === WidgetType.sidebar && part.widget.id) {
            res.push({
              id: part.widget.id,
              name: part.widget?.value?.model?.settings?.sidebarName as string,
            });
          }
        });
      });
    });
    return res;
  }

  getSectionTemplatesToLoad(): PageSection[] {
    return this.sections.filter((section: PageSection) => section.isTemplate);
  }

  getPartsWithWidgetToLoad(isSidebarOnly = false): PageBlockPart[] {
    return this.sections.reduce((parts: PageBlockPart[], section: PageSection) => {
      return [...parts, ...section.getPartsWithWidgetToLoad(isSidebarOnly)];
    }, []);
  }

  getPartsWithCardWidget(): PageBlockPart[] {
    return this.getPartsWithWidgetToLoad().filter(({ widget }: PageBlockPart) => widget?.code === WidgetType.card);
  }

  getSidebarsWithCards(): BlockPartWidget<SidebarWidgetValue>[] {
    return this.getPartsWithWidgetToLoad()
      .filter(({ widget }: PageBlockPart) => {
        return (
          widget?.code === WidgetType.sidebar &&
          (widget.value.model.parts || []).some(({ widget }: PageBlockPart) => {
            return widget?.code === WidgetType.card;
          })
        );
      })
      .map(({ widget }: PageBlockPart) => widget!);
  }

  getSidebarModalsWithCards(): BlockPartWidget<SidebarModalWidgetValue>[] {
    return this.getPartsWithWidgetToLoad()
      .filter(({ widget }: PageBlockPart) => (widget?.code === WidgetType.sidebarModal && this.isCardInsideSidebarModal(widget) ? widget : []))
      .map(({ widget }: PageBlockPart) => widget!);
  }

  isCardInsideSidebarModal(widget: BlockPartWidget | null): boolean {
    let isCardPresent = false;
    ((widget?.value.model as SidebarModalWidgetModel) || []).page.sections.forEach(s => {
      s.rows.forEach(r => {
        r.blocks.forEach(block => {
          block.parts.forEach(part => {
            part.widget?.code === WidgetType.card && (isCardPresent = true);
          });
        });
      });
    });
    return isCardPresent;
  }

  getWidgetsWithTemplateToLoad(isSidebarOnly = false): BlockPartWidget[] {
    return this.sections.reduce((widgets: BlockPartWidget[], section: PageSection) => {
      return [...widgets, ...section.getWidgetsWithTemplateToLoad(isSidebarOnly)];
    }, []);
  }

  getByLocation<T = PageElement>(location: ToolbarActionsLayoutLocation): T {
    if ('partIndex' in location) {
      const { sectionIndex, rowIndex, blockIndex, partIndex } = location;
      return this.sections[sectionIndex].rows[rowIndex].blocks[blockIndex].parts[partIndex] as unknown as T;
    }

    if ('blockIndex' in location) {
      const { sectionIndex, rowIndex, blockIndex } = location as any;
      return this.sections[sectionIndex].rows[rowIndex].blocks[blockIndex] as unknown as T;
    }

    if ('rowIndex' in location) {
      const { sectionIndex, rowIndex } = location;
      return this.sections[sectionIndex].rows[rowIndex] as unknown as T;
    }

    return this.sections[location.sectionIndex] as unknown as T;
  }

  saveWidgetIdForDeletion(part: PageBlockPart): void {
    if (part.widget?.isDeletable) {
      this.widgetIdsToDelete.push(part.widget.id!);

      if (this.isExtendedWidget(part.widget)) {
        this.widgetIdsToDelete.push(...this.getInnerWidgetIdsToDelete(part.widget));
      }
    }
  }

  isExtendedWidget(widget: BlockPartWidget): boolean {
    return [WidgetType.sidebar, WidgetType.card].includes(widget.code);
  }

  getInnerWidgetIdsToDelete(widget: BlockPartWidget): string[] {
    const widgetIdsToDelete: string[] = [];

    if (widget.code === WidgetType.sidebar) {
      widget.value.model.parts?.forEach((sidebarInnerPart: PageBlockPart) => {
        const innerWidget: BlockPartWidget = sidebarInnerPart?.widget as BlockPartWidget;
        if (innerWidget?.isDeletable) {
          widgetIdsToDelete.push(innerWidget.id!);
          this.isExtendedWidget(innerWidget) && widgetIdsToDelete.push(...this.getInnerWidgetIdsToDelete(innerWidget));
        }
      });
    }

    if (widget.code === WidgetType.card) {
      widget.value.model.areas.forEach(({ content: { items } }: GenericArea<CardWidgetAreaContent>) => {
        items.forEach((item: CardWidgetAreaContentItem) => {
          if (item.type === ContentType.widget && (item.content as BlockPartWidget).isDeletable) {
            const innerWidget: BlockPartWidget = item.content as BlockPartWidget;
            widgetIdsToDelete.push(innerWidget.id!);
            this.isExtendedWidget(innerWidget) && widgetIdsToDelete.push(...this.getInnerWidgetIdsToDelete(innerWidget));
          }
        });
      });
    }

    return widgetIdsToDelete;
  }

  saveSectionWidgetIdsForDeletionIfNeeded({ sectionIndex }: SectionLocation): void {
    const section = this.getByLocation<PageSection>({ sectionIndex });

    if (section.isTemplate) {
      return;
    }

    this.saveWidgetIdsForDeletion(section.rows);
  }

  saveRowWidgetIdsForDeletionIfNeeded({ sectionIndex, rowIndex }: RowLocation): void {
    const section = this.getByLocation<PageSection>({ sectionIndex });

    if (section.isTemplate && section.rows.length === 1) {
      return;
    }

    const row = this.getByLocation<PageRow>({ sectionIndex, rowIndex });
    this.saveWidgetIdsForDeletion([row]);
  }

  deleteSection({ sectionIndex }: SectionLocation): void {
    this.sections.splice(sectionIndex, 1);
  }

  deleteRow({ sectionIndex, rowIndex }: RowLocation): void {
    const section = this.getByLocation<PageSection>({ sectionIndex });
    section.deleteRow({ rowIndex });
    this.deleteSectionIfEmpty({ sectionIndex });
  }

  deleteBlockPart({ sectionIndex, rowIndex, blockIndex, partIndex }: PartLocation): void {
    const section = this.sections[sectionIndex];
    if (!section) return;
    section.deleteBlockPart({ rowIndex, blockIndex, partIndex });
    this.deleteSectionIfEmpty({ sectionIndex });
  }

  insertSection(afterIndex: number, section: PageSection): void {
    this.sections.splice(++afterIndex, 0, section);
  }

  private saveWidgetIdsForDeletion(rows: PageRow[]): void {
    rows.forEach((row: PageRow) => {
      row.blocks.forEach((block: PageBlock) => {
        block.parts.forEach((part: PageBlockPart) => this.saveWidgetIdForDeletion(part));
      });
    });
  }

  private deleteSectionIfEmpty({ sectionIndex }: SectionLocation): void {
    const section = this.sections[sectionIndex];

    if (!section.rows.length) {
      this.deleteSection({ sectionIndex });
    }
  }
}
