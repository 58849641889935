import { Injectable } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UserProfilePictureService } from '@shared/services/user-profile/user-profile-picture.service';
import { AuthWidgetComponent } from '@widgets/auth-widget/auth-widget.component';
import { AuthWidgetModel, AuthWidgetModelDto, AuthWidgetValue } from '@widgets/auth-widget/types/auth-widget.types';
import { UserIconVariantType } from '@widgets/auth-widget/types/auth-widget-options.types';

@Injectable()
export class AuthWidgetService {
  c: AuthWidgetComponent;
  m: AuthWidgetModel;

  constructor(private readonly userProfilePictureService: UserProfilePictureService, private readonly cache: NewCacheService) {}

  async init(context: AuthWidgetComponent, dto?: AuthWidgetModelDto | null): Promise<void> {
    this.prepareContext(context, dto);
    await this.setCurrentUser(context);
    this.setContextAndModel(context);
    await this.setUserData();
  }

  get isShowUserProfilePicture(): boolean {
    return !!this.m.userProfilePictureUrl && this.m.settings.userDisplayVariant === UserIconVariantType.photo;
  }

  // show if photo mode, but picture url and icon are not present
  get isShowLetters(): boolean {
    return (
      this.m.settings.userDisplayVariant === UserIconVariantType.letters ||
      // show letters as fallback if only user name/email is present
      (!this.m.userProfilePictureUrl && !this.m.settings.userIcon && (!!this.m.userName || !!this.m.currentUser?.email)) ||
      (this.m.settings.userDisplayVariant === UserIconVariantType.photo && !this.m.userProfilePictureUrl && !this.m.settings.userIcon)
    );
  }

  // show if photo mode, but picture url is not present and icon is defined
  get isShowUserIcon(): boolean {
    return (
      this.m.settings.userDisplayVariant === UserIconVariantType.icon ||
      (this.m.settings.userDisplayVariant === UserIconVariantType.photo && !!this.m.settings.userIcon && !this.m.userProfilePictureUrl)
    );
  }

  // if all undefined then show fallback icon
  get isShowFallbackIcon(): boolean {
    return !this.m.userProfilePictureUrl && !this.m.settings.userIcon && !this.m.userName && !this.m.currentUser?.email;
  }

  private prepareContext(context: AuthWidgetComponent, dto?: AuthWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new AuthWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new AuthWidgetModel(dto);
    }
  }

  private async setCurrentUser(context: AuthWidgetComponent): Promise<void> {
    context.widget.value.model.currentUser = this.cache.user.value as SelfUserResponseDto;
  }

  private setContextAndModel(context: AuthWidgetComponent): void {
    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }

  private async setUserData(): Promise<void> {
    this.m.userProfilePictureUrl = await this.userProfilePictureService.getUserImageUrl(this.m.currentUser?.tenant?.profileArtifactId || undefined);
    const profileArtifactId = this.m.currentUser?.tenant?.profileArtifactId;
    profileArtifactId && (this.m.userProfileArtifact = await this.cache.data.artifacts.getAsync(profileArtifactId));
    const nameAttributeId = this.m.currentUser.tenant?.systemAttributes?.nameAttributeId;
    this.m.userProfileArtifact && nameAttributeId && (this.m.userName = this.m.userProfileArtifact.attributes[nameAttributeId].value);
  }
}
