<span class="dynamic-label" *ngIf="fullAttribute">
  <p-multiSelect *ngIf="fullAttribute.multipleValues"
                 [inputId]="'enumeratedField' + index"
                 [maxSelectedLabels]="5"
                 [filter]="true"
                 [options]="fullDataType.values"
                 [(ngModel)]="artifactAttribute.value"
                 optionLabel="label">
    <ng-template let-option pTemplate="item">
      <div class="country-item">
        <div>{{option.label}}</div>
      </div>
    </ng-template>
  </p-multiSelect>

  <p-dropdown *ngIf="!fullAttribute.multipleValues"
              [options]="fullDataType.values"
              [inputId]="'enumeratedField' + index"
              [(ngModel)]="artifactAttribute.value"
              [autoDisplayFirst]="false"
              [filter]="true"
              [showClear]="true"
              optionLabel="label">
  </p-dropdown>
  <label class="dynamic-label-text" [for]="'enumeratedField' + index">{{fullAttribute.name}}</label>
</span>
