<p-table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  selectionMode="multiple"
  [(selection)]="m.selectedApplications"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngIf="m.selectApplications" style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
        <p-columnFilter type="text" [field]="col.key" matchMode="contains" display="menu"></p-columnFilter>
      </th>
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-application let-columns="columns">
    <tr>
      <td *ngIf="m.selectApplications">
        <p-tableCheckbox [value]="application"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns">
        <div class="p-text-truncate">
          <span> {{ application | printApplicationToTable : col.key }} </span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <a
            [routerLink]="application?.defaultPageId ? ['/page', application.defaultPageId] : null"
            pTooltip="{{ 'Unfortunately, this app does not have a default page. Contact the administrator about this.' | translate }}"
            [tooltipDisabled]="application?.defaultPageId"
          >
            <button
              pButton
              class="p-button p-button-warning"
              type="button"
              label="{{ 'Open' | translate }}"
              icon="pi pi-eye"
              iconPos="left"
              [disabled]="!application?.defaultPageId"
            ></button>
          </a>
          <a *ngIf="canUserAccessAdmin" [routerLink]="['/admin/application', application.id]">
            <button pButton class="p-button" type="button" label="{{ 'Settings' | translate }}" icon="pi pi-pencil" iconPos="left"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="applicationListService.m.columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
