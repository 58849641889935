import { Injectable } from '@angular/core';
import { CoreCopyOriginalValueComponent } from '@shared/core/components/core-copy-original-value.component';
import { CoreComponent } from '@shared/core/components/core.component';
import { CoreServiceI } from '@shared/core/types/core.types';

@Injectable()
export class CoreService<Context extends CoreComponent<any, Model>, Model> extends CoreCopyOriginalValueComponent implements CoreServiceI {
  c: Context;
  m: Model;

  constructor() {
    super();
  }

  init(context: Context, model: Model): void {
    this.c = context;
    this.m = model;
  }
}
