import { Environment } from '@environments/environment';
import { NewTableColumn } from '@shared/types/table.types';

export class TenantListModel {
  constructor(
    public rowsPerPage: number = Environment.tableConfig.rowsPerPage,
    public rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions,
    public columns: NewTableColumn[] = [],
  ) {}
}
