import { Component, Input } from '@angular/core';
import { ChartData } from '@widgets/chart-widget/types/chart-widget-view.types';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrl: './legend.component.scss'
})
export class LegendComponent {
  @Input() chartData: ChartData;
}
