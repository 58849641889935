import { Injectable } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { Observable, Subject } from 'rxjs';
import {
  ContextVariableAutocompleteBaseEvent,
  ContextVariableAutocompleteInitEvent,
  ContextVariableAutocompleteSelectionEvent,
} from './context-variable-autocomplete-event';

@Injectable({ providedIn: 'root' })
export class ContextVariableAutocompleteService {
  private initSubject: Subject<ContextVariableAutocompleteInitEvent>;
  private initObservable: Observable<ContextVariableAutocompleteInitEvent>;
  private selectionSubject: Subject<ContextVariableAutocompleteSelectionEvent>;
  private selectionObservable: Observable<ContextVariableAutocompleteSelectionEvent>;
  private closeSubject: Subject<ContextVariableAutocompleteBaseEvent>;
  private closeObservable: Observable<ContextVariableAutocompleteBaseEvent>;

  get autocompleteInit$(): Observable<ContextVariableAutocompleteInitEvent> {
    return this.initObservable;
  }

  get autocompleteSelection$(): Observable<ContextVariableAutocompleteSelectionEvent> {
    return this.selectionObservable;
  }

  get autocompleteClose$(): Observable<ContextVariableAutocompleteBaseEvent> {
    return this.closeObservable;
  }

  constructor() {
    this.initSubject = new Subject();
    this.initObservable = this.initSubject.asObservable();
    this.selectionSubject = new Subject();
    this.selectionObservable = this.selectionSubject.asObservable();
    this.closeSubject = new Subject();
    this.closeObservable = this.closeSubject.asObservable();
  }

  notifyAutocompleteInit(initEvent: ContextVariableAutocompleteInitEvent) {
    this.initSubject.next(initEvent);
  }

  notifyAutocompleteSelection(ownerId: string, selectedAttribute: NewAttribute, initEvent: ContextVariableAutocompleteInitEvent) {
    this.selectionSubject.next({ ownerId, selectedAttribute, initEvent });
  }

  notifyAutocompleteClose(ownerId: string) {
    this.closeSubject.next({ ownerId });
  }
}
