<p-card>
  <div class="oslc-providers-wrapper mb-3">
    <div>
      <h4 class="m-0">{{ 'Available OSLC providers' | translate }}</h4>
    </div>
    <div>
      <button *ngIf="m.addNewMode === false" pButton pRipple type="button" label="{{ 'Add' | translate }}" class="mr-2" (click)="m.addNewMode = true"></button>
      <button
        *ngIf="m.addNewMode === true"
        pButton
        pRipple
        type="button"
        label="{{ 'Cancel' | translate }}"
        class="mr-2 p-button p-button-warning"
        (click)="m.addNewMode = false"
      ></button>
    </div>
  </div>

  <!-- *********************************************************************** -->

  <div *ngIf="m.addNewMode === true" class="mb-5 pt-2">
    <p-divider></p-divider>
    <span class="dynamic-label mt-4">
      <input type="text" id="newOslcRootServiceUrl" pInputText [(ngModel)]="m.newOslcRootService.domain" />
      <label class="dynamic-label-text" for="newOslcRootServiceUrl" translate>OSLC Root service URL</label>
    </span>
    <ng-container *ngTemplateOutlet="oslcRootServiceFields; context: { data: { index: 'new', refOslcRootService: m.newOslcRootService } }"></ng-container>
    <button pButton pRipple type="button" label="{{ 'Confirm' | translate }}" class="mb-4" (click)="prependNewRootService()"></button>
    <p-divider></p-divider>
  </div>

  <!-- *********************************************************************** -->

  <ng-container *ngIf="tenant && (tenant.oslcRootServices | keyvalue).length > 0; else emptyOslcRootServices">
    <p-accordion>
      <p-accordionTab *ngFor="let oslcRootService of tenant.oslcRootServices | keyvalue; let i = index">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">{{ oslcRootService.key }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <ng-container *ngTemplateOutlet="oslcRootServiceFields; context: { data: { index: i, refOslcRootService: oslcRootService.value } }"></ng-container>
          <button
            pButton
            pRipple
            label="{{ 'Delete' | translate }}"
            class="p-button p-button-danger"
            (click)="openDialogDeleteOslcRootService(oslcRootService.key)"
          ></button>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-container>
  <ng-template #emptyOslcRootServices>
    <p class="m-0">{{ 'No OSLC root services are configured' | translate }}</p>
  </ng-template>
</p-card>

<!-- *********************************************************************** -->

<ng-template #oslcRootServiceFields let-data="data">
  <span class="dynamic-label mt-2">
    <input type="text" id="consumerKey_{{ data.index }}" pInputText [(ngModel)]="data.refOslcRootService.consumerKey" />
    <label class="dynamic-label-text" for="consumerKey_{{ data.index }}" translate>OAuth key</label>
  </span>
  <!-- TODO: workaround, because p-password doesn't support autocomplete passing to HTML input field -->
  <form class="mb-4" autocomplete="off">
    <span class="dynamic-label">
      <p-password
        name="consumerSecret_{{ data.index }}"
        id="consumerSecret_{{ data.index }}"
        [(ngModel)]="data.refOslcRootService.consumerSecret"
        [toggleMask]="true"
        [feedback]="false"
      ></p-password>
      <label class="dynamic-label-text" for="consumerSecret_{{ data.index }}" translate>OAuth secret</label>
    </span>
  </form>
</ng-template>
