<div *ngIf="formatSettings" [ngClass]="formatSettings.label | labelClass : labelBehaviour : true" class="readonly-attribute-wrapper">
  <label
    *ngIf="item?.attribute?.value && formatSettings?.label.visible && labelBehaviour !== labelBehaviourEnum.placeholder"
    [ngStyle]="formatSettings?.label?.styles"
    class="readonly-label"
  >
    {{ formatSettings.label.customLabel | getAttributeLabel : item.attribute?.label : formatSettings.label }}
  </label>

  <ng-container *ngIf="isFolder">
    <div class="readonly-attribute" (click)="showPopupFolder()">
      <span [ngStyle]="formatSettings?.value?.styles" class="readonly-value pointer">
        {{ model.selected?.artifact?.folderData?.path }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="isSystemDate">
    <app-display-at-system-date
            [formatStyles]="formatSettings?.value?.styles"
            [formatsMap]="model.formatsMap"
            [attrId]="dataType.id"
            [artifactNew]="selected.artifact"
    ></app-display-at-system-date>
  </ng-container>

  <ng-container *ngIf="isArtifactFile">
    <div *ngIf="model.newArtifactFile || selected.artifact?.id" class="fileArtifact-item">
      <span
        [innerHTML]="hasCustomInnerHtml | getCustomFieldInnerHtml : dataType.id : selected : model"
        appFileArtifactViewer
        [file]="model.newArtifactFile || selected.artifact"
        [ngStyle]="formatSettings?.value?.styles"
        class="readonly-value downloadable cursor-pointer"
      >
      </span>
      <span class="button-box" *ngIf="model.newArtifactFile">
        <button pButton icon="pi pi-times" class="p-button-danger p-button-text utility-button" type="button" (click)="onRemoveFile()"></button>
      </span>
    </div>
    <div *ngIf="!model.newArtifactFile && !selected.artifact?.id">
      <p-fileUpload
        #awFileArtifactUpload
        class="file-upload-wrapper"
        name="awFileArtifactUpload[]"
        mode="basic"
        [ngClass]="{ 'file-upload-wrapper__align-right': label }"
        [customUpload]="true"
        [auto]="true"
        [multiple]="false"
        (uploadHandler)="onSelectFile($event, awFileArtifactUpload)"
      >
      </p-fileUpload>
    </div>
  </ng-container>

  <!-- ######## DEFAULT ######## -->
  <ng-container *ngIf="!isFolder && !isArtifactFile && !isSystemDate">
    <div class="readonly-attribute">
      <ng-container *ngIf="item?.attribute.value.id | isSystemUser">
        <app-display-at-user
          class="block-container full-size-container"
          [defaultIconSize]="userIconSize"
          [ownerId]="ownerId"
          [attributeId]="item?.attribute.value.id"
          [artifact]="selected.artifact"
          [usersMap]="model.options.users.listMap"
          [selectedVariant]="formatSettings.value.displayMetadata?.selectedVariantCode || 'DEFAULT'"
          [valueStyles]="formatSettings.value?.styles"
        >
        </app-display-at-user>
      </ng-container>
      <ng-container *ngIf="!(item?.attribute.value.id | isSystemUser)">
        <span
          [innerHTML]="hasCustomInnerHtml | getCustomFieldInnerHtml : dataType.id : selected : model"
          [ngStyle]="formatSettings?.value?.styles"
          class="readonly-value"
        >
        </span>
      </ng-container>
    </div>
  </ng-container>
</div>
