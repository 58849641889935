import { WorkflowActionDto } from '@api/models';
import { Constructor } from '@shared/types/constructor.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract.action';

export abstract class AbstractWorkflowAttributeBasedAction<T extends WorkflowActionDto['actionSettings']> extends AbstractWorkflowAction<T> {
  artifactTypeId: string;
  attributeId: string;
  protected actionAttributeHandler: AttributeActionHandlerService;

  constructor(actionSettingDto: Constructor<T>, type: WorkflowActionType, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super(actionSettingDto, type, dto);
    this.artifactTypeId = dto?.artifactTypeId || this.artifactTypeId;
    this.attributeId = dto?.attributeId || this.attributeId;
    this.actionAttributeHandler = actionAttributeHandler ?? this.actionAttributeHandler;
  }

  setActionAttributeHandler(actionAttributeHandler: AttributeActionHandlerService) {
    this.actionAttributeHandler = actionAttributeHandler;
  }

  isValid(): boolean {
    return !!this.actionAttributeHandler;
  }

  isArtifactTypeRequired(): boolean {
    return true;
  }

  isAttributeRequired(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      artifactTypeId: this.artifactTypeId,
      attributeId: this.attributeId,
    };
  }
}
