<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-2"></div>
  <div class="col-8">
    <input
      id="forEachPathInput"
      [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value"
      pInputText
      type="text"
      placeholder="Enter for-each-path"
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Artifact id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.id].isDynamic" id="isArtifactIdDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.id].value"
      [placeholder]="action.actionSettings[RuleKeys.id].isDynamic ? 'Enter dynamic id' : ('Enter id' | translate)"
      pInputText
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Attributes' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].isDynamic" id="isAttributesMapDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let attribute of action.clientAttributesArray; let index = index">
      <div class="record-row">
        <div class="attribute-settings">
          <div>
            <p-checkbox
              [(ngModel)]="attribute.useInitialValue"
              [id]="'useInitialValue' + index"
              binary="true"
              class="mr-2 pt-0"
              (ngModelChange)="onUseInitialValueChange(attribute, $event)"
            ></p-checkbox>
            {{ 'use initial value' | translate }}
          </div>
          <div>
            <p-checkbox
              [(ngModel)]="attribute.isMulti"
              [id]="'isMulti' + index"
              binary="true"
              class="mr-2 pt-0"
              (ngModelChange)="onIsMultiChange(attribute)"
            ></p-checkbox>
            {{ 'is multi' | translate }}
          </div>
          <div></div>
          <div>
            <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash" (click)="onRemoveAttribute(index)"></button>
          </div>
        </div>
        <div class="inputs">
          <div>
            <input [id]="'attrId' + index" [(ngModel)]="attribute.id" pInputText type="text" class="w-100" [placeholder]="'Enter id ' + (index + 1)" />
          </div>
          <div *ngIf="attribute.isMulti; else singleInput">
            <div *ngFor="let value of attribute.value; let valueIndex = index; trackBy: attrValuesToTrackByFn" class="mb-1">
              <div *ngIf="!attribute.useInitialValue && attribute.value" class="d-flex">
                <input
                  [id]="'attrValue' + valueIndex"
                  [(ngModel)]="attribute.value![valueIndex]"
                  pInputText
                  type="text"
                  [placeholder]="'Enter value ' + (valueIndex + 1)"
                  class="w-100"
                />
                <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddAttributeValue(attribute, valueIndex)"></button>
                <button
                  class="p-button p-button-text p-button-danger"
                  pButton
                  type="button"
                  icon="pi pi-minus"
                  (click)="onRemoveAttributeValue(attribute, valueIndex)"
                  [ngClass]="{ 'visibility-hidden': attribute.value!.length <= 1 }"
                ></button>
              </div>
            </div>
          </div>

          <ng-template #singleInput>
            <div *ngIf="!attribute.useInitialValue">
              <input [(ngModel)]="attribute.value" [placeholder]="'Enter value' | translate" pInputText class="w-100" />
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddAttribute()" label="Add attribute"></button>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-2"></div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.storeResultsPath].value"
      [placeholder]="'Enter store results path' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Parent folder id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].isDynamic" id="isParentFolderDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].value"
      [placeholder]="'Enter parent folder id' | translate"
      pInputText
      class="w-100"
      id="parentFolderIdInput"
    />
  </div>
</div>
