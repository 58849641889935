<div class="jobs wrapper">
  <app-jobs-table [columns]="m.columns" [actionButtonsTemplate]="actionButtons" [loadDataMethod]="loadData()">
    <ng-template #actionButtons let-job>
      <div class="table_buttons nowrap">
        <button
          pButton
          class="p-button p-button-rounded p-button-outlined"
          type="button"
          pTooltip="{{ 'Show data' | translate }}"
          icon="pi pi-info"
          iconPos="left"
          (click)="setDetailJob(job)"
        ></button>
        <button
          pButton
          class="p-button p-button-rounded p-button-warning p-button-outlined"
          type="button"
          pTooltip="{{ 'Run again' | translate }}"
          icon="pi pi-refresh"
          iconPos="left"
          (click)="runAgainJob(job)"
        ></button>
        <button
          pButton
          class="p-button p-button-rounded p-button-danger p-button-outlined"
          type="button"
          pTooltip="{{ 'Delete' | translate }}"
          icon="pi pi-trash"
          iconPos="left"
          (click)="deleteJob(job)"
        ></button>
      </div>
    </ng-template>
  </app-jobs-table>
</div>

<p-dialog
  header="Job data"
  [(visible)]="m.detailJob"
  [modal]="true"
  [style]="{ width: 'max(50vw, min(100vw, 450px))' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="setDetailJob(null)"
>
  <ng-container *ngIf="m.detailJob">
    <pre class="m-0">{{ m.detailJob!.data | json }}</pre>
  </ng-container>
  <ng-template pTemplate="footer">
    <button pButton [label]="'Close' | translate" (click)="setDetailJob(null)" class="p-button-warning"></button>
  </ng-template>
</p-dialog>
