import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';

@Pipe({
  name: 'filterByArtifactType',
})
export class FilterByArtifactTypePipe implements PipeTransform {
  constructor(private readonly attributesHelper: ArtifactWidgetCustomAttributeHelper, private readonly filterService: ArtifactFiltersService) {}

  transform(
    attributeAndLinkOptions: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[],
    artifactType: NewArtifactType,
  ): SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[] {
    const attributeIdsByArtifactType = artifactType ? Object.keys(artifactType.attributes) : [];

    return attributeAndLinkOptions.filter(({ value: attributeOrLinkType }: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>) => {
      const isAttributeFromArtifactType = attributeIdsByArtifactType.includes(attributeOrLinkType.id);
      const isCustomAttribute = this.attributesHelper.isCustomAttributes(attributeOrLinkType.id, artifactType.format);
      const isConnectedLink = this.isConnectedLink(attributeOrLinkType, artifactType.id);
      const isSystemAttribute = this.filterService.isSystemAttribute(attributeOrLinkType.id);

      return isAttributeFromArtifactType || isCustomAttribute || isConnectedLink || isSystemAttribute;
    });
  }

  private isConnectedLink(attributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, artifactTypeId: string): boolean {
    if (!(attributeOrLinkType instanceof DirectionalLinkType)) {
      return false;
    }

    if (attributeOrLinkType.direction === LinkDirection.outgoing) {
      return attributeOrLinkType.isLinkingFromArtifactType(artifactTypeId);
    }

    return attributeOrLinkType.direction === LinkDirection.incoming && attributeOrLinkType.isLinkingToArtifactType(artifactTypeId);
  }
}
