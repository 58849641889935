import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';

interface RowScheme {
  value: string;
  columns: number[];
}

const defaultRow: RowScheme = { value: '12', columns: [12] };
const rowSchemes: RowScheme[] = [
  defaultRow,
  { value: '6+6', columns: [6, 6] },
  { value: '4+4+4', columns: [4, 4, 4] },
  { value: '3+3+3+3', columns: [3, 3, 3, 3] },
  { value: '8+4', columns: [8, 4] },
  { value: '3+9', columns: [3, 9] },
  { value: '3+6+3', columns: [3, 6, 3] },
  { value: '2+10', columns: [2, 10] },
  { value: '2+5+5', columns: [2, 5, 5] },
  { value: '6+6+12', columns: [6, 6, 12] },
];
const pattern = /^[+\d]*$/;

@Component({
  selector: 'app-section-generation-form',
  templateUrl: './section-generation-form.component.html',
  styleUrls: ['./section-generation-form.component.scss'],
})
export class SectionGenerationFormComponent {
  @Output() generateSection: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;

  rowSchemes: RowScheme[] = [...rowSchemes];
  form: UntypedFormGroup = this.fb.group({
    rows: this.fb.array([this.fb.control(defaultRow.value, this.validators)]),
  });

  constructor(private fb: UntypedFormBuilder) {}

  get rows(): UntypedFormArray {
    return this.form.get('rows') as UntypedFormArray;
  }

  get validators(): ValidatorFn[] {
    return [Validators.pattern(pattern), (control: AbstractControl) => this.sumValidator(control)];
  }

  toggle($event: Event, scheme?: string[][]): void {
    scheme?.forEach((row: string[], index: number) => {
      const value = row.join('+');

      if (index === 0) {
        this.rows.at(0).patchValue(value);
      } else {
        this.addRow(value);
      }
    });

    this.overlayPanel.toggle($event);
  }

  setLastRowSelectedScheme(scheme: RowScheme): void {
    this.rows.at(this.rows.controls.length - 1).patchValue(scheme.value);
  }

  addRow(value: string = defaultRow.value): void {
    this.rows.push(this.fb.control(value, this.validators));
  }

  deleteRow(rowIndex: number): void {
    this.rows.removeAt(rowIndex);
  }

  generate(): void {
    if (this.form.valid) {
      this.generateSection.emit(this.rows.value);
    }

    this.overlayPanel.hide();
  }

  onHide(): void {
    this.rows.clear();
    this.addRow();
  }

  private sumValidator({ value }: AbstractControl): ValidationErrors | null {
    const sum = value.split('+').reduce((acc: number, curr: string) => acc + +curr, 0);
    const valid = sum === 12 || value === '6+6+12';

    return valid ? null : { incorrectSum: true };
  }
}
