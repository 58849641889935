import { SetAttributeValueActionWorkflowSettingsDto } from '@api/models/set-attribute-value-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CURRENT_USER_OPTION_LABEL } from '@shared/constants/constants';
import { AttributeValueToClient, AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewUser } from '@shared/types/user.types';
import { AttributeActionHandlerService, ModifyAttributeValueOperation } from '@workflows/shared';
import { WorkflowActionType, WorkflowTriggerEvent } from '@workflows/types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export enum SetAttributeValueKeys {
  value = 'value',
  operation = 'operation',
}

export class WorkflowActionSetAttributeValueActionDto implements SetAttributeValueActionWorkflowSettingsDto {
  value: SetAttributeValueActionWorkflowSettingsDto[SetAttributeValueKeys.value] = { value: '', isDynamic: false };
  operation: SetAttributeValueActionWorkflowSettingsDto[SetAttributeValueKeys.operation] = { value: '' as any, isDynamic: false };

  constructor(action?: WorkflowActionSetAttributeValueActionDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionSetAttributeValue extends AbstractWorkflowAttributeBasedAction<SetAttributeValueActionWorkflowSettingsDto> {
  constructor(dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService, cache?: NewCacheService) {
    super({ actionSettingDto: WorkflowActionSetAttributeValueActionDto, type: WorkflowActionType.SET_VALUE, dto, actionAttributeHandler, cache });

    this.actionSettings.value.value = this.attributeToClient(
      this.options!.dataTypes,
      this.options!.attributes,
      this.options!.users,
      (dto?.actionSettings as SetAttributeValueActionWorkflowSettingsDto)?.value?.value,
    );

    this.actionSettings.operation.value = (dto?.actionSettings as SetAttributeValueActionWorkflowSettingsDto)?.operation?.value || '';
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifact;
  }

  execute(): void {
    this.actionAttributeHandler.notifyModifyAttributeValueEvent({
      attributeId: this.attributeId,
      artifactTypeId: this.artifactTypeId,
      value: this.actionSettings.value.value,
      operation: this.actionSettings.operation.value as ModifyAttributeValueOperation,
    });
  }

  isValid(): boolean {
    return super.isValid() && !!(this.artifactTypeId && this.attributeId);
  }

  toServer(): WorkflowActionDto {
    const attribute = this.options!.attributes.listMap[this.attributeId];
    const dataType = this.options!.dataTypes.listMap[attribute.dataTypeId];

    return {
      ...super.toServer(),
      actionSettings: {
        value: {
          isDynamic: false,
          value: AttributeValueToServer(dataType, attribute, this.actionSettings.value.value),
        },
        operation: {
          isDynamic: false,
          value: this.actionSettings.operation.value || ModifyAttributeValueOperation.replace,
        },
      },
    };
  }

  private attributeToClient(dataTypes: ListContainer<NewDataType>, attributes: ListContainer<NewAttribute>, users: ListContainer<NewUser>, value: any): any {
    if (value === NonAttributeKeys.CURRENT_USER_ID) {
      return [new SelectOption(CURRENT_USER_OPTION_LABEL, value)];
    }

    return AttributeValueToClient({
      dataTypes,
      attributes,
      users: users.list,
      clientAttribute: new NewClientAttribute({ id: this.attributeId, value: null, isMandatory: false }),
      value,
    });
  }
}
