import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantApplicationService } from '@api/services/tenant-application.service';
import { TenantPageService } from '@api/services/tenant-page.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { FileService } from '@private/services/file.service';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { SelectOption } from '@shared/types/shared.types';
import { PictureWidgetService } from '@widgets/picture-widget/services/picture-widget.service';
import { PictureFormatEnum, PictureSizeEnum, PictureWidgetModel, PictureWidgetModelDto } from '@widgets/picture-widget/types/picture-widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { GetSizeInPixelsPipe } from './pipes/get-size-in-pixels.pipe';
import { TenantArtifactService } from '@api/services';
import { tap } from 'rxjs';
import { ArtifactFormatFileDataResponseDto } from '@api/models';

@Component({
  selector: 'app-picture-widget',
  templateUrl: './picture-widget.component.html',
  styleUrls: ['./picture-widget.component.scss'],
  providers: [PictureWidgetService],
})
export class PictureWidgetComponent extends WidgetsCoreComponent implements OnInit, AfterViewInit {
  //  @ViewChild('imageForPictureWidget') imageForPictureWidget: any;
  @ViewChildren('imageContainer') imageContainers: QueryList<ElementRef>;
  @ViewChildren('imageInContainer') imagesInContainer: QueryList<ElementRef>;
  m: PictureWidgetModel;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<PictureWidgetModel>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: PictureWidgetService,
    private tenantPageService: TenantPageService,
    private fileService: FileService,
    private tenantApplicationService: TenantApplicationService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private sizeInPixelsPipe: GetSizeInPixelsPipe,
    private readonly tenantArtifactService: TenantArtifactService,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as any as PictureWidgetModelDto) : null);
    this.m.showBorderRadius = this.m.settings.format === PictureFormatEnum.rectangle;

    if (!this.m.settings.useExternalLink && this.m.settings.fileId) {
      const fileArtifactId = this.m.settings.fileId;
      this.loadImage(fileArtifactId);
    }

    this.preparePages();
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.m.initialized = true));
  }

  onOpenInLightboxChange(): void {
    if (this.m.settings.openInLightbox) {
      this.m.settings.pictureLinkOptions.enabled = false;
    } else {
      this.m.settings.pictureOverlay.enabled = false;
    }
  }

  get isCircle(): boolean {
    return this.m.settings.format === PictureFormatEnum.circle;
  }

  onImageClick(): void {
    const { openSidebar, openInLightbox, pictureLinkOptions } = this.m.settings;
    const { enabled, openInNewTab, url, page, external } = pictureLinkOptions;

    if (openSidebar && this.m.settings.sidebarId) {
      this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification(RuntimeStateNotificationEnum.openSidebar, this.m.settings.sidebarId));
    }

    if (openInLightbox) {
      this.m.lightboxVisible = true;
      return;
    }

    if (enabled) {
      if (external && url) {
        window.open(url, openInNewTab ? '_blank' : '_self');
        return;
      }

      if (page) {
        const alias = this.m.options.pageOptions.find(option => option.value === page.value)?.meta.alias;
        const url = '/page/' + page;

        if (openInNewTab) {
          window.open(alias || url, '_blank');
        } else {
          this.router.navigate([alias || url]);
        }
      }
    }
  }

  onPictureFormatChange(): void {
    this.m.showBorderRadius = this.m.settings.format === PictureFormatEnum.rectangle;
    if (!this.m.showBorderRadius) {
      this.m.settings.borderRadius = 0;
    }

    this.m.settings.format === PictureFormatEnum.circle && (this.m.settings.pictureSize = PictureSizeEnum.cover);
    this.m.settings.format === PictureFormatEnum.ellipse && (this.m.settings.pictureSize = PictureSizeEnum.cover);

    this.adaptImageSize(50);
  }

  preparePages(): void {
    if (this.applicationId) {
      const meta = { filter: JSON.stringify({ deleted: { $eq: null } }) };
      this.tenantPageService.pageControllerList(meta).subscribe(resp => {
        this.m.options.pageOptions = resp.data.map(page => new SelectOption(page.name, page.id, { alias: page.alias }));
      });
    }
  }

  onBasicUpload(event: any): void {
    for (const file of event.files) {
      this.m.uploadedFiles.push(file);
      this.m.fileObjectUrl = URL.createObjectURL(file);
      this.m.file = file;
      this.m.sanitizedFileUrl = this.sanitizeImageUrl(this.m.fileObjectUrl);
    }
  }

  uploadToServer(): void {
    if (this.m.file && this.applicationId) {
      // need to get application.defaultFolderId
      this.tenantApplicationService.applicationControllerGet({ id: this.applicationId }).subscribe(app => {
        const defaultFolderId = app.defaultFolderId;
        // upload file to server using defaultFolderId
        this.m.file &&
          this.fileService
            .uploadFileArtifact(this.m.file, defaultFolderId)
            .pipe(
              tap(fileArtifact => {
                this.m.settings.fileId = fileArtifact.id;
              }),
            )
            .subscribe({
              error(err) {
                console.log(err);
              },
            });
      });
    }
  }

  onClearFiles(): void {
    this.m.uploadedFiles = [];
    this.m.fileObjectUrl = null;
    this.m.file = null;
    this.m.sanitizedFileUrl = 'default';
    this.m.settings.fileId = null;
  }

  loadImage(fileArtifactId: string): void {
    this.tenantArtifactService
      .artifactControllerDownload({ id: fileArtifactId })
      .pipe(
        tap(file => {
          this.m.fileObjectUrl = URL.createObjectURL(file);
          this.m.sanitizedFileUrl = this.sanitizeImageUrl(this.m.fileObjectUrl);

          // TODO: possibly this whole part is unnecessary, because BE is setting correct headers so in "file" should automatically been set name and mimetype
          this.tenantArtifactService
            .artifactControllerGet({ id: fileArtifactId })
            .pipe(
              tap(fileArtifact => {
                const formatData = fileArtifact.formatData as ArtifactFormatFileDataResponseDto;

                const name = formatData.filename;
                const lastModified = +new Date(fileArtifact.updated.on);
                Object.assign(file as File, { name, lastModified });

                this.m.file = new File([file], name, { type: formatData.mimetype });
                if (this.m.file) {
                  this.m.uploadedFiles = [this.m.file];
                }
              }),
            )
            .subscribe({
              error(err) {
                console.log(err);
              },
            });
        }),
      )
      .subscribe({
        error(err) {
          console.log(err);
        },
      });
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return imageUrl ? this.sanitizer.bypassSecurityTrustUrl(imageUrl) : '';
  }

  adaptImageSize(curImgSize: number) {
    const setupHeight = this.sizeInPixelsPipe.transform(this.m.settings.height);
    if (curImgSize < setupHeight) {
      this.imageContainers.toArray().forEach(elem => {
        elem.nativeElement.style.height = curImgSize + 'px';
        if (this.isCircle) elem.nativeElement.style.width = curImgSize + 'px';
      });
      this.imagesInContainer.toArray().forEach(elem => {
        elem.nativeElement.style.height = curImgSize + 'px';
        if (this.isCircle) elem.nativeElement.style.width = curImgSize + 'px';
      });
    } else {
      this.imageContainers.toArray().forEach(elem => {
        elem.nativeElement.style.height = setupHeight + 'px';
        if (this.isCircle) elem.nativeElement.style.width = setupHeight + 'px';
      });
      this.imagesInContainer.toArray().forEach(elem => {
        elem.nativeElement.style.height = setupHeight + 'px';
        if (this.isCircle) elem.nativeElement.style.width = setupHeight + 'px';
      });
    }
  }

  onContainerResize(event: ResizeObserverEntry) {
    const imgSize = event.contentRect.width;
    this.adaptImageSize(imgSize);
  }

  getStylesBackground() {
    return (
      this.m.settings.backgroundColorContainer +
      ' url(' +
      (this.m.settings.useExternalLink ? this.m.settings.url : this.m.sanitizedFileUrl) +
      ') ' +
      this.m.settings.picturePosition +
      '/' +
      this.m.settings.pictureSize +
      this.m.settings.repeat
    );
  }
}
