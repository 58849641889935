import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { StringUtil } from '@shared/utils/string.util';

/**
 * Applicable only for base data types html and text (multivalues).
 */
@Pipe({ name: 'toHtml' })
export class ToHtmlPipe implements PipeTransform {
  constructor(private readonly tinyMceHelper: TinyMceHelper, private readonly stringUtil: StringUtil) {}

  transform(value: string | string[], dataType: BaseDataType = BaseDataType.html): string {
    if (!value) {
      return '';
    }
    switch (dataType) {
      case BaseDataType.html:
        return this.tinyMceHelper.addOrRemoveImageAuth(value as string, false);
      case BaseDataType.text:
        return this.stringUtil.formatMultipleTextForDisplay(value as string[]);
      case BaseDataType.hyperlink:
        return this.stringUtil.formatMultipleHyperlinkForDisplay(value as string[]);
      default:
        return '';
    }
  }
}
