/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { GroupResponseDto } from '../models/group-response-dto';
import { templateControllerCount } from '../fn/tenant-template/template-controller-count';
import { TemplateControllerCount$Params } from '../fn/tenant-template/template-controller-count';
import { templateControllerCreate } from '../fn/tenant-template/template-controller-create';
import { TemplateControllerCreate$Params } from '../fn/tenant-template/template-controller-create';
import { templateControllerDelete } from '../fn/tenant-template/template-controller-delete';
import { TemplateControllerDelete$Params } from '../fn/tenant-template/template-controller-delete';
import { templateControllerExport } from '../fn/tenant-template/template-controller-export';
import { TemplateControllerExport$Params } from '../fn/tenant-template/template-controller-export';
import { templateControllerGet } from '../fn/tenant-template/template-controller-get';
import { TemplateControllerGet$Params } from '../fn/tenant-template/template-controller-get';
import { templateControllerGroupby } from '../fn/tenant-template/template-controller-groupby';
import { TemplateControllerGroupby$Params } from '../fn/tenant-template/template-controller-groupby';
import { templateControllerList } from '../fn/tenant-template/template-controller-list';
import { TemplateControllerList$Params } from '../fn/tenant-template/template-controller-list';
import { templateControllerUpdate } from '../fn/tenant-template/template-controller-update';
import { TemplateControllerUpdate$Params } from '../fn/tenant-template/template-controller-update';
import { TemplateListResponseDto } from '../models/template-list-response-dto';
import { TemplateResponseDto } from '../models/template-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templateControllerGroupby()` */
  static readonly TemplateControllerGroupbyPath = '/api/tenant/template/groupby';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerGroupby()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerGroupby$Response(params: TemplateControllerGroupby$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupResponseDto>> {
    return templateControllerGroupby(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerGroupby$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerGroupby(params: TemplateControllerGroupby$Params, context?: HttpContext): Observable<GroupResponseDto> {
    return this.templateControllerGroupby$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupResponseDto>): GroupResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerCount()` */
  static readonly TemplateControllerCountPath = '/api/tenant/template/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerCount$Response(params?: TemplateControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return templateControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerCount(params?: TemplateControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.templateControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerExport()` */
  static readonly TemplateControllerExportPath = '/api/tenant/template/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerExport$Response(params: TemplateControllerExport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return templateControllerExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerExport(params: TemplateControllerExport$Params, context?: HttpContext): Observable<Blob> {
    return this.templateControllerExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `templateControllerList()` */
  static readonly TemplateControllerListPath = '/api/tenant/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerList$Response(params?: TemplateControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateListResponseDto>> {
    return templateControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerList(params?: TemplateControllerList$Params, context?: HttpContext): Observable<TemplateListResponseDto> {
    return this.templateControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateListResponseDto>): TemplateListResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerUpdate()` */
  static readonly TemplateControllerUpdatePath = '/api/tenant/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerUpdate$Response(params: TemplateControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateResponseDto>> {
    return templateControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerUpdate(params: TemplateControllerUpdate$Params, context?: HttpContext): Observable<TemplateResponseDto> {
    return this.templateControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateResponseDto>): TemplateResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerCreate()` */
  static readonly TemplateControllerCreatePath = '/api/tenant/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerCreate$Response(params: TemplateControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateResponseDto>> {
    return templateControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  templateControllerCreate(params: TemplateControllerCreate$Params, context?: HttpContext): Observable<TemplateResponseDto> {
    return this.templateControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateResponseDto>): TemplateResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerGet()` */
  static readonly TemplateControllerGetPath = '/api/tenant/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerGet$Response(params: TemplateControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateResponseDto>> {
    return templateControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerGet(params: TemplateControllerGet$Params, context?: HttpContext): Observable<TemplateResponseDto> {
    return this.templateControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateResponseDto>): TemplateResponseDto => r.body)
    );
  }

  /** Path part for operation `templateControllerDelete()` */
  static readonly TemplateControllerDeletePath = '/api/tenant/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerDelete$Response(params: TemplateControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateResponseDto>> {
    return templateControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateControllerDelete(params: TemplateControllerDelete$Params, context?: HttpContext): Observable<TemplateResponseDto> {
    return this.templateControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateResponseDto>): TemplateResponseDto => r.body)
    );
  }

}
