import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AUTOCOMPLETE_CONTAINER_CLASS } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { Listbox } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription, filter, merge, tap } from 'rxjs';
import { ContextVariableAutocompleteInitEvent } from '../../services/context-variable-autocomplete-event';
import { ContextVariableAutocompleteService } from '../../services/context-variable-autocomplete.service';

@Component({
  selector: 'app-context-variable-autocomplete-overlay',
  templateUrl: 'context-variable-autocomplete-overlay.component.html',
  styleUrls: ['./context-variable-autocomplete-overlay.component.scss'],
})
export class ContextVariableAutocompleteOverlayComponent implements OnInit, OnDestroy {
  @ViewChild(OverlayPanel) panel: OverlayPanel;
  @ViewChild(Listbox) listBox: Listbox;
  ownerId: string;
  attributes: NewAttribute[];
  selectedAttribute: NewAttribute;
  styleClass: string = AUTOCOMPLETE_CONTAINER_CLASS;

  private initEvent: ContextVariableAutocompleteInitEvent;
  private initSubscription: Subscription;

  constructor(private readonly contextVariableAutocompleteService: ContextVariableAutocompleteService, private readonly zone: NgZone) {}

  ngOnInit() {
    const autocompleteInit$ = this.contextVariableAutocompleteService.autocompleteInit$.pipe(tap(initEvent => this.onAutocompleteInitEvent(initEvent)));
    const autocompleteClose$ = this.contextVariableAutocompleteService.autocompleteClose$.pipe(
      filter(closeEvent => this.ownerId === closeEvent.ownerId),
      tap(() => {
        this.zone.run(() => {
          this.onAutocompleteCloseEvent();
        });
      }),
    );

    this.initSubscription = merge(autocompleteInit$, autocompleteClose$).subscribe();
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  onAttributeSelected(attribute: NewAttribute) {
    this.contextVariableAutocompleteService.notifyAutocompleteSelection(this.ownerId, attribute, this.initEvent);
    this.panel.hide();
  }

  private onAutocompleteInitEvent(initEvent: ContextVariableAutocompleteInitEvent) {
    const { variableNamePart, event, target, doFocusFirstItem } = initEvent;
    const attributeNamePrefix = variableNamePart?.toLowerCase();
    // in case attribute name prefix is in format of variable name
    const rawAttributeNamePrefix = attributeNamePrefix?.includes('-') ? attributeNamePrefix.replace('-', ' ') : attributeNamePrefix;
    const shouldCheckVariableFormat = rawAttributeNamePrefix && attributeNamePrefix !== rawAttributeNamePrefix;
    this.initEvent = initEvent;
    this.ownerId = initEvent.ownerId;
    this.attributes = initEvent.attributes.filter(
      attr =>
        attr.name.toLowerCase().startsWith(attributeNamePrefix) || (shouldCheckVariableFormat && attr.name.toLowerCase().startsWith(rawAttributeNamePrefix)),
    );
    this.panel.show(event, target);

    if (doFocusFirstItem) {
      this.doFocusFirstItem();
    }
  }

  private onAutocompleteCloseEvent() {
    this.panel.hide();
  }

  private doFocusFirstItem() {
    const firstItem = this.listBox?.el.nativeElement.getElementsByTagName('li')[0];
    firstItem && firstItem.focus();
  }
}
