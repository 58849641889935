import { Pipe, PipeTransform } from '@angular/core';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuItem, MenuWidgetModel } from '../types/menu-widget.types';
import { IsItemInFirstLevelPipe } from './is-item-in-first-level.pipe';
import { IsMenuHorizontalPipe } from './is-menu-horizontal.pipe';

@Pipe({ name: 'getSubMenuLeftMargin' })
export class GetSubMenuLeftMarginPipe implements PipeTransform {
  constructor(public isItemInFirstLevelPipe: IsItemInFirstLevelPipe, public isMenuHorizontalPipe: IsMenuHorizontalPipe) {}

  transform(m: MenuWidgetModel, subMenuParentItem: MenuItem): string {
    const isInFirstMenuLevel = this.isItemInFirstLevelPipe.transform(m.items.menu, subMenuParentItem);
    if (m.settings.menu.type === MenuTypesEnum.panel) return '';
    else if (isInFirstMenuLevel && m.settings.menu.type === MenuTypesEnum.tiered) return m.styles.menu.paddingRight;
    else if (!isInFirstMenuLevel) return m.styles.subMenu.paddingRight;
    return '';
  }
}
