import { Injectable } from '@angular/core';
import { AbstractAvrWidgetArtifactSourceService } from './abstract.artifact-source.service';
import { TenantArtifactService } from '@api/services';
import { AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { lastValueFrom } from 'rxjs';
import { AvrWidgetComponent } from '@widgets/avr-widget/avr-widget.component';
import { SelectOption } from '@shared/types/shared.types';

const EXCEPT_FIELDS_ON_ARTIFACT_SOURCE = ['dataSource.artifactSource'];
const EXCEPT_FIELDS_ON_ARTIFACT_TYPE = [...EXCEPT_FIELDS_ON_ARTIFACT_SOURCE, ...['dataSource.artifactTypeId']];

@Injectable()
export class StaticArtifactAvrWidgetArtifactSourceService extends AbstractAvrWidgetArtifactSourceService {
  constructor(protected readonly tenantArtifactService: TenantArtifactService) {
    super(AvrArtifactSources.staticArtifact, tenantArtifactService);
  }

  async init(context: AvrWidgetComponent): Promise<void> {
    await super.init(context);
    if (this.settings.dataSource.artifactSource !== this.artifactSource) return;
    const loadedStaticArtifactId = this.settings.dataSource.staticArtifactId;
    await this.loadArtifactOptions();
    const artifact = this.options.artifactOptions.find(value => value.value.id === loadedStaticArtifactId)?.value || null;
    this.s.setCurrentArtifact(artifact);
  }

  async onArtifactSourceChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_SOURCE);
    this.model.settingsStep = 1;
  }

  async onArtifactTypeChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_TYPE);
    this.loadArtifactOptions();
    this.model.settingsStep = 2;
  }

  async onSelectedArtifactChange(): Promise<void> {
    const artifact = this.model.options.artifactOptions.find(value => value.value.id === this.settings.dataSource.staticArtifactId)?.value || null;
    // handles on settings artifact change only
    this.s.setCurrentArtifact(artifact);
  }

  async onAvrTypeChange(): Promise<void> {
    this.s.setAvrFileType();
  }

  private async loadArtifactOptions(): Promise<void> {
    this.options.artifactOptions = [];

    if (this.settings.dataSource.artifactTypeId === null) return;

    const filter = JSON.stringify({
      $or: [
        { artifactTypeId: { $oid: this.settings.dataSource.artifactTypeId }, deleted: null },
        ...(this.settings.dataSource.staticArtifactId ? [{ _id: { $oid: this.settings.dataSource.staticArtifactId } }] : []),
      ],
    });
    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter } }));
    const nameAttributeId = this.options.currentUser?.tenant.systemAttributes?.nameAttributeId || '';

    this.options.artifactOptions = data.map((artifactDto, i) => {
      const artifact = this.artifactResponseTransform(artifactDto);
      const index = i + 1;
      if (artifact.deleted !== null) {
        artifact.id = '';
        return new SelectOption(`[Inaccessible option${index ? ` (${index})` : ''}]`, artifact, null, true);
      }
      return new SelectOption(artifact.attributes[nameAttributeId].value || '', artifact);
    });
  }
}
