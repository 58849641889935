import { Component, Input } from '@angular/core';
import { UserFullResponseDto } from '@api/models/user-full-response-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import { TenantUserService } from '@api/services/tenant-user.service';
import { UserConfirmationTypeEnum, UserPageModel } from '@private/pages/user-management/user/types/user-page.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewApplication } from '@shared/types/application.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-application-admin-user-form',
  templateUrl: './application-admin-user-form.component.html',
  styleUrls: ['./application-admin-user-form.component.scss'],
})
export class ApplicationAdminUserFormComponent {
  @Input() m: UserPageModel;
  @Input() currentApplication: NewApplication;

  userConfirmationType = UserConfirmationTypeEnum;

  constructor(
    private readonly cache: NewCacheService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly tenantUserService: TenantUserService,
  ) {}

  async userFormConfirmation(confirmationType: UserConfirmationTypeEnum, header: string, message: string): Promise<void> {
    const [translatedHeader, translatedMessage, acceptLabel, rejectLabel] = await this.translateUtil.getAll([header, message, 'Yes', 'No']);
    this.confirmationService.confirm({
      header: translatedHeader,
      message: translatedMessage,
      acceptLabel,
      rejectLabel,
      accept: async () => {
        const dto = await this.performAction(confirmationType);
        this.cache.data.users.setItem(dto);
      },
    });
  }

  async performAction(confirmationType: UserConfirmationTypeEnum): Promise<UserResponseDto | UserFullResponseDto> {
    switch (confirmationType) {
      case UserConfirmationTypeEnum.addAdminRole:
        return await lastValueFrom(
          this.tenantUserService.userControllerUpdateApplicationByAppAdmin({
            body: { application: { id: this.currentApplication.id, isAdmin: true }, updateType: 'UPDATE', id: this.m.user.id },
          }),
        ).then(dto => {
          this.m.user.application = { id: this.currentApplication.id, isAdmin: true };
          return dto;
        });
      case UserConfirmationTypeEnum.removeAdminRole:
        return await lastValueFrom(
          this.tenantUserService.userControllerUpdateApplicationByAppAdmin({
            body: { application: { id: this.currentApplication.id, isAdmin: false }, updateType: 'UPDATE', id: this.m.user.id },
          }),
        ).then(dto => {
          this.m.user.application = { id: this.currentApplication.id, isAdmin: false };
          return dto;
        });
      case UserConfirmationTypeEnum.addAppAccess:
        return await lastValueFrom(
          this.tenantUserService.userControllerUpdateApplicationByAppAdmin({
            body: { application: { id: this.currentApplication.id, isAdmin: false }, updateType: 'ADD', id: this.m.user.id },
          }),
        ).then(dto => {
          this.m.user.application = { id: this.currentApplication.id, isAdmin: false };
          return dto;
        });
      case UserConfirmationTypeEnum.removeAppAccess:
        return await lastValueFrom(
          this.tenantUserService.userControllerUpdateApplicationByAppAdmin({
            body: { application: { id: this.currentApplication.id, isAdmin: false }, updateType: 'REMOVE', id: this.m.user.id },
          }),
        ).then(dto => {
          this.m.user.application = null;
          return dto;
        });
    }
  }
}
