import { Injector, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactIdExtractor } from '@widgets/card-widget/types/artifact-id-extractor';
import { ArtifactTypeExtractor } from '@widgets/card-widget/types/artifact-type-extractor';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { ClientAttributeExtractor } from '@widgets/card-widget/types/client-attribute-extractor';
import { FolderPathExtractor } from '@widgets/card-widget/types/folder-path-extractor';
import { LinkValue } from '@widgets/card-widget/types/link-value';
import { LinksExtractor } from '@widgets/card-widget/types/links-extractor';
import { RecordAuthorExtractor } from '@widgets/card-widget/types/record-author-extractor';
import { RecordDateExtractor } from '@widgets/card-widget/types/record-date-extractor';
import { UnknownAttributeExtractor } from '@widgets/card-widget/types/unknown-attribute-extractor';
import { isEmpty } from 'lodash';
import { SectionAttributeExtractor } from '@widgets/card-widget/types/section-attribute-extractor';

@Pipe({ name: 'getAttributeValue' })
export class GetAttributeValuePipe implements PipeTransform {
  constructor(private readonly injector: Injector) {}

  transform(
    attributeId: string,
    linkDirection: LinkDirection | undefined,
    artifact: ArtifactResponseDto, // TODO: consider getting rid of this argument since it's extracted from the options
    options: CardWidgetOptions,
  ): string | LinkValue[] | ArtifactResponseDto[] {
    if ([artifact, attributeId, options].some(isEmpty)) {
      return '';
    }

    try {
      const extractor = this.getExtractor(artifact, attributeId, options);

      return extractor.getValue(artifact, { attributeId, linkDirection });
    } catch (e) {
      console.error(e);

      return '';
    }
  }

  private getExtractor(artifact: ArtifactResponseDto, attributeId: string, options: CardWidgetOptions): ArtifactValueExtractor {
    if (ClientAttributeExtractor.isClientAttribute(artifact, attributeId, options)) {
      return new ClientAttributeExtractor(options, this.injector.get(ElvisUtil), this.injector.get(LOCALE_ID));
    }

    if (ArtifactIdExtractor.isIdAttribute(attributeId)) {
      return new ArtifactIdExtractor();
    }

    if (ArtifactTypeExtractor.isArtifactTypeAttribute(attributeId)) {
      return new ArtifactTypeExtractor(options);
    }

    if (RecordAuthorExtractor.isRecordAuthorAttribute(attributeId)) {
      return new RecordAuthorExtractor(options);
    }

    if (RecordDateExtractor.isRecordDateAttribute(attributeId)) {
      return new RecordDateExtractor(this.injector.get(LOCALE_ID));
    }

    if (LinksExtractor.isLinksAttribute(attributeId, options)) {
      return new LinksExtractor(options);
    }

    if (FolderPathExtractor.isFolderPathAttribute(attributeId)) {
      return new FolderPathExtractor();
    }

    if (SectionAttributeExtractor.isFolderPathAttribute(attributeId)) {
      return new SectionAttributeExtractor();
    }

    return new UnknownAttributeExtractor(artifact, attributeId);
  }
}
