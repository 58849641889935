<div class="grid">
  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox
          (onChange)="onEditabilityChange($event.checked)"
          [(ngModel)]="formatSettings.editable"
          [disabled]="areEditableStylesDisabled"
          binary="true"
          id="editable"
        >
        </p-checkbox>
        <label for="editable">{{ 'Editable' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="formatSettings.value.showIcon" binary="true" id="showIcon"></p-checkbox>
        <label for="showIcon" translate> Show icon </label>
      </span>
    </span>
  </div>

  <ng-container *ngIf="dataType && (dataType | shouldShowPlaceholderOption)">
    <div class="col-6 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox (onChange)="onCustomPlaceholderUseChange()" [(ngModel)]="formatSettings.value.showPlaceholder"
                      binary="true" inputId="useCustomLabel">
          </p-checkbox>
          <label for="useCustomLabel">{{ 'Use custom placeholder' | translate }}</label>
        </span>
      </span>
    </div>

    <div class="col-6 mb-0">
      <input
        [(ngModel)]="formatSettings.value.placeholder"
        [disabled]="!formatSettings.value.showPlaceholder"
        id="customLabel"
        pInputText
        placeholder="{{ 'Set custom placeholder' | translate }}"
        style="width: 100%"
        type="text"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="attribute && dataType && (dataType | canHaveInitialValue)">
    <div class="col-8 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="formatSettings.ignoreInitialValue" binary="true"
                      inputId="ignoreInitialValue"></p-checkbox>
          <label for="ignoreInitialValue">{{ 'Ignore initial value' | translate }}</label>
        </span>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="attribute && dataType && (dataType | canHaveInitialValue) && !formatSettings.ignoreInitialValue">
    <div class="col-6 mb-0"
         [ngClass]="{ 'pb-0': (dataType?.baseDataType | isText) || (dataType?.baseDataType | isHtml) }">
      <label for="attributeInitialValue">{{ 'Initial Value' | translate }}</label>
    </div>

    <div
      class="col-12 mb-0"
      [ngClass]="{
        'col-6': (dataType?.baseDataType | isDateTime) || (dataType?.baseDataType | isDate) || (dataType?.baseDataType | isUser),
        'col-12': (dataType?.baseDataType | isText) || (dataType?.baseDataType | isHtml)
      }"
    >
      <app-attribute-initial-value-form [formatSettings]="formatSettings" [dataType]="dataType"
                                        [attribute]="attribute"></app-attribute-initial-value-form>
    </div>
  </ng-container>

  <div class="col-6 mb-0">
    <button (click)="readonlyStylesOp.toggle($event)" class="w-100" icon="pi pi-eye"
            label="{{ 'Readonly styles' | translate }}" pButton type="button"></button>
  </div>

  <div class="col-6 mb-0">
    <button
      [disabled]="areEditableStylesDisabled"
      (click)="editableStylesOp.toggle($event)"
      class="w-100"
      icon="pi pi-pencil"
      label="{{ 'Editable styles' | translate }}"
      pButton
      type="button"
    ></button>
  </div>
</div>

<p-overlayPanel #readonlyStylesOp [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-font-style-form [fontStyles]="formatSettings.value.styles"
                         [hideHorizontalAlignSettings]="true"
                         [hidePrefixSuffix]="!showPrefixSuffixSettings"
                         [hideDecimalPlacesSettings]="!showDecimalPlacesSettings"
                         (onChange)="onStylesChange($event)"
    ></app-font-style-form>

    <div class="grid mt-3">
      <div class="col-12 md:col-6 lg:col-6">
        <h6>{{ 'Horizontal align' | translate }}</h6>
        <p-dropdown
          (onChange)="valueAlignmentChange.emit()"
          [(ngModel)]="formatSettings.value.styles.textAlign"
          [autoDisplayFirst]="false"
          [options]="textHorizontalAlignOptions"
          id="readonlyValueHorizontalAlign"
        >
        </p-dropdown>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <h6>{{ 'Background color' | translate }}</h6>
        <app-color-picker [(color)]="formatSettings.value.styles.backgroundColor"></app-color-picker>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #editableStylesOp [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <!-- BACKGROUND COLOR -->
    <div class="grid">
      <div class="col-6">
        <app-color-picker
          (colorChange)="styleChange.emit()"
          [(color)]="formatSettings.value.fieldStyles.backgroundColor"
          label="{{ 'Background' | translate }}"
        >
        </app-color-picker>
      </div>

      <!-- BORDER COLOR -->
      <div class="col-6">
        <app-color-picker (colorChange)="styleChange.emit()" [(color)]="formatSettings.value.fieldStyles.borderColor"
                          label="{{ 'Border' | translate }}">
        </app-color-picker>
      </div>

      <!-- BORDER STYLE -->
      <div class="col-6">
        <h6>{{ 'Border style' | translate }}</h6>
        <p-dropdown
          (onChange)="styleChange.emit()"
          [(ngModel)]="formatSettings.value.fieldStyles.borderStyle"
          [autoDisplayFirst]="false"
          [options]="borderStyleOptions"
          id="menuItemBorderStyle"
        >
        </p-dropdown>
      </div>

      <!-- BORDER WIDTH -->
      <div class="col-6">
        <h6>{{ 'Border width' | translate }}</h6>
        <p-dropdown
          (onChange)="styleChange.emit()"
          [(ngModel)]="formatSettings.value.fieldStyles.borderWidth"
          [autoDisplayFirst]="false"
          [options]="borderWidthOptions"
          id="menuItemBorderWidth"
        >
        </p-dropdown>
      </div>

      <!-- BORDER ROUNDING -->
      <div class="col-6">
        <h6>{{ 'Rounding' | translate }}</h6>
        <input
          (ngModelChange)="styleChange.emit()"
          [(ngModel)]="formatSettings.value.fieldStyles.borderRadius"
          id="borderRadius"
          pInputText
          style="width: 100%"
          type="text"
        />
      </div>

      <ng-container *ngIf="(dataType?.baseDataType | isInteger) || (dataType?.baseDataType | isDecimal)">
        <div class="col-6">
          <h6> {{ 'Prefix' | translate }}</h6>
          <input
            (ngModelChange)="styleChange.emit()"
            [(ngModel)]="formatSettings.value.fieldStyles.prefix"
            id="prefix"
            pInputText
            style="width: 100%"
            type="text"
          >
        </div>
        <div class="col-6">
          <h6> {{ 'Suffix' | translate }}</h6>
          <input
            (ngModelChange)="styleChange.emit()"
            [(ngModel)]="formatSettings.value.fieldStyles.suffix"
            id="suffix"
            pInputText
            style="width: 100%"
            type="text"
          >
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>
