import { SidebarWidgetWidth, WidthType } from '@widgets/sidebar-widget/types/sidebar-widget-type.types';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';

export class SideBarOptions {
  position: string[];
  widthTypes: string[];
  inside: { label: string; value: boolean }[];
  fixed: { label: string; value: boolean }[];
  buttonAlign: { label: string; value: string }[];
  isPageBuilder: boolean;

  constructor() {
    this.setPositions();
    this.setOptions();
    this.setInsideOptions();
    this.setFixedOptions();
    this.buttonAlign = [
      { label: 'Left', value: 'left' },
      { label: 'Center', value: 'center' },
      { label: 'Right', value: 'right' },
    ];
    this.isPageBuilder = this.isPageBuilderPage();
  }

  setInsideOptions(onlyInside?: boolean): void {
    this.inside = [{ label: 'Inside', value: true }];
    !onlyInside && this.inside.push({ label: 'OnTop', value: false });
  }

  setFixedOptions(onlyDynamic?: boolean): void {
    this.fixed = [{ label: 'Dynamic', value: false }];
    !onlyDynamic && this.fixed.push({ label: 'Fixed', value: true });
  }

  private isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  private setPositions(): void {
    this.position = Object.keys(SidebarWidgetWidth);
  }

  private setOptions(): void {
    this.widthTypes = Object.keys(WidthType);
  }
}
