import { Component, Input } from '@angular/core';
import { LinkType } from '@shared/types/link-type.types';
import { CreateLinksKeys, WorkflowActionCreateLinks } from '@workflows/types/actions/action-create-links';

@Component({
  selector: 'app-action-create-links',
  templateUrl: './action-create-links.component.html',
  styleUrls: ['./action-create-links.component.scss'],
})
export class ActionCreateLinksComponent {
  @Input() action: WorkflowActionCreateLinks;
  @Input() linkTypes: LinkType[];

  protected readonly RuleKeys = CreateLinksKeys;

  onIsLinkTypeDynamicChange(): void {
    this.action.actionSettings[CreateLinksKeys.linkTypeId].value = null as any;
  }
}
