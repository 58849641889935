import { Application } from '@private/pages/application-management/application/types/application.types';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';
import { AvrArtifactSourcesType, AvrArtifactSources, AvrActionTypesType } from '@widgets/avr-widget/types/avr-widget-settings.types';

export class AvrWidgetOptions {
  // STATIC
  artifactSourcesOptions: SelectOption<AvrArtifactSourcesType, AvrArtifactSourcesType>[] = GetSelectOptionsFromEnum<AvrArtifactSourcesType>(AvrArtifactSources);
  avrTypesOptions: SelectOption<string, AvrTypesType>[] = [];
  avrActionTypesOptions: SelectOption<string, AvrActionTypesType>[] = [];
  // FROM BE
  artifactTypesOptions: SelectOption<string, NewArtifactType>[] = [];
  artifactOptions: SelectOption<string, NewArtifact>[] = [];
  applications: ListContainer<Application> = new ListContainer<NewApplication>();
  currentUser: NewSystemUser | null = null;
}
