import { Injectable } from '@angular/core';
import { FolderResponseDto } from '@api/models';
import { TenantFolderService } from '@api/services';
import { FOLDER_PATH_SEPARATOR } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FolderFilterHelperService {
  constructor(private readonly tenantFolderService: TenantFolderService) {}

  getFoldersByParentAndApplication$(parentFolderId?: string, application?: NewApplication): Observable<FolderResponseDto[]> {
    const parentFolderIdFilter = parentFolderId ? { $oid: parentFolderId } : null;
    const applicationFilter = application ? { _id: { $oid: application.defaultFolderId } } : {};
    const $and = [{ deleted: { $eq: null } }, { parentFolderId: parentFolderIdFilter }, applicationFilter];

    return this.tenantFolderService
      .folderControllerList({ filter: JSON.stringify({ $and }) })
      .pipe(map(foldersList => foldersList.data.sort(({ folderSequence: aa = 0 }, { folderSequence: bb = 0 }) => (aa > bb ? 1 : aa == bb ? 0 : -1))));
  }

  getFoldersByArtifactFolderPath$(folderPath: string): Observable<FolderResponseDto[]> {
    const rootFolderName = folderPath.split(FOLDER_PATH_SEPARATOR)[1];
    const $or = [{ name: rootFolderName }, { folderPath: { $regex: FOLDER_PATH_SEPARATOR + rootFolderName, $options: 'i' } }];
    const $and = [{ deleted: { $eq: null } }, { $or }];

    return this.tenantFolderService
      .folderControllerList({ filter: JSON.stringify({ $and }) })
      .pipe(map(folders => folders.data.sort(({ pathRank: aa = 0 }, { pathRank: bb = 0 }) => (aa > bb ? 1 : aa == bb ? 0 : -1))));
  }
}
