/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { artifactHistoryControllerCount } from '../fn/tenant-artifact-history/artifact-history-controller-count';
import { ArtifactHistoryControllerCount$Params } from '../fn/tenant-artifact-history/artifact-history-controller-count';
import { artifactHistoryControllerDelete } from '../fn/tenant-artifact-history/artifact-history-controller-delete';
import { ArtifactHistoryControllerDelete$Params } from '../fn/tenant-artifact-history/artifact-history-controller-delete';
import { artifactHistoryControllerGet } from '../fn/tenant-artifact-history/artifact-history-controller-get';
import { ArtifactHistoryControllerGet$Params } from '../fn/tenant-artifact-history/artifact-history-controller-get';
import { artifactHistoryControllerList } from '../fn/tenant-artifact-history/artifact-history-controller-list';
import { ArtifactHistoryControllerList$Params } from '../fn/tenant-artifact-history/artifact-history-controller-list';
import { ArtifactHistoryListResponseDto } from '../models/artifact-history-list-response-dto';
import { ArtifactHistoryResponseDto } from '../models/artifact-history-response-dto';
import { BaseCountResponseDto } from '../models/base-count-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantArtifactHistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `artifactHistoryControllerCount()` */
  static readonly ArtifactHistoryControllerCountPath = '/api/tenant/artifact-history/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactHistoryControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerCount$Response(params?: ArtifactHistoryControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return artifactHistoryControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactHistoryControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerCount(params?: ArtifactHistoryControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.artifactHistoryControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactHistoryControllerGet()` */
  static readonly ArtifactHistoryControllerGetPath = '/api/tenant/artifact-history/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactHistoryControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerGet$Response(params: ArtifactHistoryControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactHistoryResponseDto>> {
    return artifactHistoryControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactHistoryControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerGet(params: ArtifactHistoryControllerGet$Params, context?: HttpContext): Observable<ArtifactHistoryResponseDto> {
    return this.artifactHistoryControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactHistoryResponseDto>): ArtifactHistoryResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactHistoryControllerDelete()` */
  static readonly ArtifactHistoryControllerDeletePath = '/api/tenant/artifact-history/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactHistoryControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerDelete$Response(params: ArtifactHistoryControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactHistoryResponseDto>> {
    return artifactHistoryControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactHistoryControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerDelete(params: ArtifactHistoryControllerDelete$Params, context?: HttpContext): Observable<ArtifactHistoryResponseDto> {
    return this.artifactHistoryControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactHistoryResponseDto>): ArtifactHistoryResponseDto => r.body)
    );
  }

  /** Path part for operation `artifactHistoryControllerList()` */
  static readonly ArtifactHistoryControllerListPath = '/api/tenant/artifact-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artifactHistoryControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerList$Response(params?: ArtifactHistoryControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactHistoryListResponseDto>> {
    return artifactHistoryControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `artifactHistoryControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artifactHistoryControllerList(params?: ArtifactHistoryControllerList$Params, context?: HttpContext): Observable<ArtifactHistoryListResponseDto> {
    return this.artifactHistoryControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArtifactHistoryListResponseDto>): ArtifactHistoryListResponseDto => r.body)
    );
  }

}
