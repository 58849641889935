import { Component, Input } from '@angular/core';
import { UserOslcConnectionsModel } from '@private/pages/profile/components/user-oslc-connections/types/user-oslc-connections.types';
import { ProfileComponent } from '@private/pages/profile/profile.component';

@Component({
  selector: 'app-user-oslc-connections',
  templateUrl: './user-oslc-connections.component.html',
  styleUrls: ['./user-oslc-connections.component.scss'],
})
export class UserOslcConnectionsComponent {
  @Input() c: ProfileComponent;

  protected m: UserOslcConnectionsModel = new UserOslcConnectionsModel();

  async openLoginDialog(): Promise<void> {
    if (!this.m.selectedOslcRootService) return;
    const url = await this.c.s.getOslcUserAuthUrl(this.m.selectedOslcRootService);
    if (!url) return;

    if (this.m.windowRef !== null && !this.m.windowRef.closed) {
      this.m.windowRef.focus();
    } else {
      this.m.windowRef = window.open(url, '_blank', 'location=no,toolbar=no,menubar=no,width=600,height=700');
    }
    this.m.addNewMode = false;
  }
}
