import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { EMAIL_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { GetDataTypeByAttributeId } from '@shared/methods/attribute.methods';
import { IsUser } from '@shared/methods/data-type.methods';
import { SharedMethods, WithEmptyEnumOption, WithEmptySelectOption } from '@shared/methods/shared.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Pipe({ name: 'getEnumOrUserFilterOptions' })
export class GetEnumOrUserFilterOptionsPipe implements PipeTransform {
  transform(attributeId: string, options: ListWidgetOptions, multipleValues: boolean, isTeamFilter = false): SelectOption<string, any>[] | EnumeratedOption[] {
    const dataType = GetDataTypeByAttributeId(attributeId, options.attributes.listMap, options.dataTypes.listMap);
    const isUser = (dataType && IsUser(dataType?.baseDataType as BaseDataType)) || !dataType || NonAttributeKeys.isUserSpecificAttributeKey(attributeId);

    if (!isUser) {
      return WithEmptyEnumOption(dataType?.values || [], multipleValues);
    }

    if (isTeamFilter) {
      return SharedMethods.withCurrentUserTeamsOption(options.teams.toSelectOptions(NAME_KEY, ID_KEY));
    }

    const allUsersWithCurrentOne = SharedMethods.withCurrentUserOption(options.users.toSelectOptions(EMAIL_KEY, ID_KEY));
    return WithEmptySelectOption(allUsersWithCurrentOne, multipleValues);
  }
}
