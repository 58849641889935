import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsHyperlink } from '../../methods/data-type.methods';

@Pipe({ name: 'isHyperlink' })
export class IsHyperlinkPipe implements PipeTransform {
  transform(baseDataType: BaseDataType): boolean {
    return IsHyperlink(baseDataType);
  }
}
