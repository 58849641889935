import { URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';
import { FontStyles, IconStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { ArtifactFilter, ArtifactFilterDto, ArtifactFilterOptions } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ClickActionSettings } from '@widgets/shared/types/click-action-settings';
import { ClickActionSettingsDto } from '@widgets/shared/types/click-action-settings-dto';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';
import { cloneDeep } from 'lodash';

export class NumberWidgetSettings {
  label: string;
  prefix = '';
  postfix = '';
  fixedCounter: string;
  showRoundLabel = true;
  labelPosition: SelectOption<string, string> = new SelectOption<string, string>(LabelPositionEnum.top);
  iconPosition: SelectOption<string, string> = new SelectOption<string, string>(LabelPositionEnum.left);
  counterStyles = new FontStyles();
  labelStyles = new FontStyles();
  iconStyles = new IconStyles({ order: '0' });
  badgeStyles = new IconStyles({ color: '#ffffff', fontSize: '14px' });
  clickAction: ClickActionSettings = {
    isHandled: false,
    actionType: ArtifactListItemClickAction.goToPage,
    emittingKey: URL_KEY_VALUE_ARTIFACT_ID,
    selectedPage: '',
    externalPage: '',
    openInNewTab: false,
    filtersForQueryParams: [],
  };

  constructor(dto?: NumberWidgetSettingsDto, options?: ArtifactFilterOptions) {
    dto && this.fromDto(dto, options!);
  }

  toServer(): NumberWidgetSettingsDto {
    return new NumberWidgetSettingsDto(this);
  }

  fromDto(dto: NumberWidgetSettingsDto, options: ArtifactFilterOptions): void {
    const {
      label,
      fixedCounter,
      showRoundLabel = true,
      counterStyles,
      labelStyles,
      iconStyles,
      badgeStyles,
      prefix = '',
      postfix = '',
      labelPosition,
      iconPosition,
      clickAction,
    } = dto;
    Object.assign(this, {
      label,
      fixedCounter,
      showRoundLabel,
      prefix,
      postfix,
      counterStyles: counterStyles ? counterStyles : new FontStyles(),
      labelStyles: labelStyles ? labelStyles : new FontStyles(),
      iconStyles: iconStyles ? iconStyles : new IconStyles({ order: '0' }),
      badgeStyles: badgeStyles ? badgeStyles : new IconStyles({ color: '#ffffff', fontSize: '14px' }),
    });

    labelPosition && (this.labelPosition = new SelectOption<string, string>(labelPosition));
    iconPosition && (this.iconPosition = new SelectOption<string, string>(iconPosition));
    clickAction &&
      (this.clickAction = {
        ...clickAction,
        filtersForQueryParams: clickAction.filtersForQueryParams.map((dto: ArtifactFilterDto) => ArtifactFilter.fromDtoAndOptions(dto, options)),
      });
  }
}

export class NumberWidgetSettingsDto {
  label: string;
  prefix: string;
  postfix: string;
  fixedCounter: string;
  showRoundLabel: boolean;
  labelPosition: string;
  iconPosition: string;
  counterStyles: FontStyles;
  labelStyles: FontStyles;
  iconStyles: IconStyles;
  badgeStyles: IconStyles;
  clickAction: ClickActionSettingsDto;

  constructor(settings: NumberWidgetSettings) {
    const { label, counterStyles, labelStyles, iconStyles, badgeStyles, prefix, postfix, fixedCounter, showRoundLabel, clickAction } = settings;
    Object.assign(this, {
      label,
      counterStyles,
      labelStyles,
      iconStyles,
      badgeStyles,
      prefix,
      postfix,
      fixedCounter,
      showRoundLabel,
      clickAction: cloneDeep(clickAction),
    });
    settings.labelPosition && (this.labelPosition = settings.labelPosition.value);
    settings.iconPosition && (this.iconPosition = settings.iconPosition.value);
    this.clickAction.filtersForQueryParams = settings.clickAction.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer());
  }
}
