import { Styles } from './styles';

export class PageSectionContainerStyles extends Styles {
  marginRight = 'auto';
  marginLeft = 'auto';

  paddingTop = '5px';
  paddingRight = '5px';
  paddingBottom = '5px';
  paddingLeft = '5px';

  constructor(styles: Partial<PageSectionContainerStyles> = {}) {
    super();

    Object.assign(this, styles);
  }
}
