<p-table
  #table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  [filters]="{ deleted: [{ value: null, matchMode: 'equals', operator: 'and' }] }"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
        <ng-container [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'type'" [ngTemplateOutlet]="typeFilter"></ng-container>
          <ng-container *ngSwitchCase="'status'" [ngTemplateOutlet]="statusFilter"></ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultFilter"></ng-container>
        </ng-container>

        <!-- ### START - TYPE FILTER TEMPLATE ### -->
        <ng-template #typeFilter>
          <p-columnFilter
            *ngIf="col.key === 'type'"
            [field]="col.key"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
            matchMode="in"
            display="menu"
          >
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="p-text-bold">{{ col.key }}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="typeOptions" placeholder="Job type" (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <div>
                    <span class="ml-1">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-template>
        <!-- ### END - TYPE FILTER TEMPLATE ### -->

        <!-- ### START - STATUS FILTER TEMPLATE ### -->
        <ng-template #statusFilter>
          <p-columnFilter
            *ngIf="col.key === 'status'"
            [field]="col.key"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
            matchMode="in"
            display="menu"
          >
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="p-text-bold">{{ col.key }}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="statusOptions" placeholder="Job status" (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <div>
                    <span class="ml-1">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-template>
        <!-- ### END - TYPE FILTER TEMPLATE ### -->

        <!-- ### START - DEFAULT FILTER TEMPLATE ### -->
        <ng-template #defaultFilter>
          <p-columnFilter *ngIf="col.key === 'result'" matchMode="contains" type="text" [field]="col.key" display="menu"></p-columnFilter>
        </ng-template>
        <!-- ### END - DEFAULT FILTER TEMPLATE ### -->
      </th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-job let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngClass]="{ wrap: col.key === 'result' }">
        <div>
          <span
            class="p-text-truncate"
            [ngClass]="{ red: job.status === 'failed', green: job.status === 'done', orange: job.status === 'pending', blue: job.status === 'processing' }"
          >
            {{ job | jobsValueToTable : col.key }}
          </span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <ng-container *ngTemplateOutlet="actionButtonsTemplate; context: { $implicit: job }"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1">{{ 'No records found' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
