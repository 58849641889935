<p-table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem"></th>
      <th *ngFor="let col of columns" pResizableColumn pReorderableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon *ngIf="col.key !== 'id'" pSortableColumn="{{ col.key !== 'id' ? col.key : '' }}" [field]="col.key"></p-sortIcon>
        <p-columnFilter *ngIf="col.filterType" [type]="col.filterType" [field]="col.key" display="menu"></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-file let-index="rowIndex">
    <tr [pReorderableRow]="index">
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <td *ngFor="let col of columns" class="p-text-truncate">
        {{ objectU.getValueFromPath(col.key, file) }}
      </td>
    </tr>
  </ng-template>
</p-table>
