import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CoreParams } from '@shared/core/types/core.types';
import { SharedMethods } from '@shared/methods/shared.methods';
import { AnnouncementService } from '../../services/announcement.service';
import { CoreRxSubscriptionsComponent } from './core-rx-subscriptions.component';

@Directive()
export abstract class CoreRouterSubscribeComponent extends CoreRxSubscriptionsComponent implements OnInit {
  isFirstCall = true;

  url: string;
  urlParams: Params;
  queryParams: Params;

  protected constructor(
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly announcement: AnnouncementService,
    public readonly coreParams: CoreParams = new CoreParams(),
  ) {
    super();

    this.urlParams = {};
    this.queryParams = {};

    this.router ? (this.url = this.router.url) : this.announcement.error('Router is not available');

    if (this.route) {
      this.registerSubscription(
        this.route.queryParams.subscribe((params: Params) => {
          this.queryParams = params;
          this.onInitCb();
          if (this.route.snapshot.fragment && this.isFirstCall) {
            const offset = SharedMethods.computeStickyOffset();
            SharedMethods.scrollToHtmlElement(this.route.snapshot.fragment, offset);
          }
        }),
      );
      this.registerSubscription(
        this.coreParams.subscribeToParent && this.route.parent
          ? this.route.parent.params.subscribe((params: Params) => (this.urlParams = params))
          : this.route.params.subscribe((params: Params) => {
              this.urlParams = params;
              this.onInitCb();
            }),
      );
      this.route.url.subscribe(() => {
        if (this.router.url !== this.url) {
          this.onInitCb();
        }
      });
    } else {
      this.announcement.error('Activated route is not available').then();
    }
  }

  public ngOnInit(): void {
    this.isFirstCall = false;
  }

  onInitCb(): void {
    if (!this.isFirstCall) {
      this.url = this.router.url;
    }
  }
}
