import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { WidgetCreateRequestDto, WidgetResponseDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem, IsCardContentItemWidget } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

export class BlockPartWidget<Value = any> {
  code: WidgetType;
  created: RecordDto;
  deleted: RecordDto | null;
  id: string | null;
  templateId: string | null;
  templateName: string | null = null;
  updated: RecordDto;
  value: Value | any;

  constructor(responseDto: WidgetResponseDto | Partial<WidgetResponseDto> | PageBlockPartWidgetResponseDto) {
    if ('widgetId' in responseDto) {
      Object.assign(this, { id: responseDto.widgetId, templateId: responseDto.templateId });
    } else {
      Object.assign(this, responseDto);
    }
  }

  get isDeletable(): boolean {
    return !!this.id && !this.templateId;
  }

  get requestDto(): WidgetCreateRequestDto | WidgetUpdateRequestDto {
    const dto: WidgetCreateRequestDto | WidgetUpdateRequestDto = {
      code: this.code as any,
      value: { ...this.value },
      ...(this.id ? { id: this.id } : {}),
    };

    if ((this.value as any)?.model?.toServer) {
      (dto.value as any).model = (dto.value as any).model.toServer();
    }

    return dto;
  }

  get innerWidgetIds(): string[] {
    if (this.code === WidgetType.card) {
      const model: CardWidgetModel = (this.value as any).model;

      return model.areas.reduce((widgets: string[], { content: { items } }: GenericArea<CardWidgetAreaContent>) => {
        return [...widgets, ...items.filter(IsCardContentItemWidget).map(({ content }: CardWidgetAreaContentItem) => (content as BlockPartWidget).id!)];
      }, []);
    }

    return [];
  }

  removeIdsForReuse(): void {
    this.id = null;
    this.templateId = null;
    this.templateName = null;

    if (this.code === WidgetType.card) {
      const model = (this.value as any).model;

      model.areas
        .reduce((widgets: BlockPartWidget[], { content: { items } }: GenericArea<CardWidgetAreaContent>) => {
          return [...widgets, ...items.filter(IsCardContentItemWidget).map(({ content }: CardWidgetAreaContentItem) => content as BlockPartWidget)];
        }, [])
        .forEach((widget: BlockPartWidget) => widget.removeIdsForReuse());
    }

    if (this.code === WidgetType.sidebar) {
      const model = (this.value as any).model;
      (model.parts as PageBlockPart[]).forEach(part => part.removeIdsForReuse());
    }
  }

  saveStateBeforeRelocation(): void {
    if (this.code === WidgetType.sidebar) {
      (this.value as any).model.parts?.forEach((part: PageBlockPart) => {
        const sidebarWidget = part.widget;

        if (!sidebarWidget) {
          return;
        }

        sidebarWidget.saveStateBeforeRelocation();
      });
    } else {
      if (this.code === WidgetType.card) {
        const model: CardWidgetModel = (this.value as any).model;

        model.innerWidgets?.forEach((widget: BlockPartWidget) => widget.saveStateBeforeRelocation());
        const modelCopy = (model.copy && model.copy()) || model;
        this.value = { model: modelCopy };
      } else {
        const { value } = this.requestDto;
        this.value = value as Value;
      }
    }
  }
}
