import { Component, Input } from '@angular/core';
import { Overflow } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { StyleOptions } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-overflow-form',
  templateUrl: './overflow-form.component.html',
  styleUrls: ['./overflow-form.component.scss'],
})
export class OverflowFormComponent {
  @Input() styles: Overflow;
  @Input() styleOptions: StyleOptions;
}
