import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TenantSwitcherService } from './services/tenant-switcher.service';

@Component({
  selector: 'app-tenant-switcher',
  templateUrl: './tenant-switcher.component.html',
  styleUrls: ['./tenant-switcher.component.scss'],
})
export class TenantSwitcherComponent implements OnInit {
  constructor(public readonly tenantSwitcherService: TenantSwitcherService) {}

  async ngOnInit(): Promise<void> {
    await this.tenantSwitcherService.init();
  }
}

@NgModule({
  declarations: [TenantSwitcherComponent],
  imports: [CommonModule, DropdownModule, FormsModule],
  exports: [TenantSwitcherComponent],
})
export class TenantSwitcherModule {}
