import { Injectable } from '@angular/core';
import { ArtifactResponseDto, ArtifactTypeResponseDto, SelfUserResponseDto } from '@api/models';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { FileService } from '@private/services/file.service';
import { NewFileParams } from '@private/types/file-service.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { lastValueFrom, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileHelper {
  constructor(private readonly cache: NewCacheService, private readonly elvisUtil: ElvisUtil, private readonly fileService: FileService) {}

  async createNewFiles(
    attributes: NewClientAttribute[] | NewArtifactTypeClientAttribute[],
    application: Application,
    attributesMap: Record<string, NewAttribute>,
    onFileCreateCb?: (file: ArtifactResponseDto) => any,
    saveValueAsId?: boolean,
  ): Promise<void> {
    let folderId = application?.defaultFolderId;

    if (!folderId) {
      const currentUser = this.cache.user.value as SelfUserResponseDto;
      const fileArtifactType = (this.cache.data.artifactTypes.value as ArtifactTypeResponseDto[]).find(at => at.id === currentUser!.tenant!.fileArtifactTypeId);
      folderId = fileArtifactType?.defaultFolderId as string;
    }

    for await (const attr of attributes) {
      const entries = this.getFileEntries(attr);
      if (entries)
        for await (const [index, file] of entries)
          await this.uploadFile(new NewFileParams(file, attr, folderId, index, attributesMap[attr.id].multipleValues, onFileCreateCb, saveValueAsId));
    }
  }

  getFileEntries(attr: NewClientAttribute | NewArtifactTypeClientAttribute): any {
    if (attr instanceof NewClientAttribute && attr.value) {
      attr.value = this.elvisUtil.makeArrayFromValue(attr.value);
      return attr.value.entries();
    } else if (attr instanceof NewArtifactTypeClientAttribute && attr.initialValue) {
      attr.initialValue = this.elvisUtil.makeArrayFromValue(attr.initialValue);
      return attr.initialValue.entries();
    }
    return null;
  }

  async uploadFile(params: NewFileParams): Promise<void> {
    const { file, folderId, index, attr, multipleValues, onFileCreateCb, saveValueAsId } = params;
    if (file && !file.id && typeof file !== 'string') {
      return new Promise(resolve => {
        const handle = this.fileService
          .uploadFileArtifact(file, folderId)
          .pipe(
            tap(fileArtifact => {
              this.cache.data.artifacts.setItem(fileArtifact);
              onFileCreateCb && onFileCreateCb(fileArtifact);
              const value = saveValueAsId ? fileArtifact.id : fileArtifact;
              if (attr instanceof NewClientAttribute) {
                if (multipleValues) attr.value[index] = value;
                else attr.value = value;
              } else {
                if (multipleValues) attr.initialValue[index] = value;
                else attr.initialValue = value;
              }
            }),
          )
          .subscribe(() => {
            handle.unsubscribe();
            resolve();
          });
      });
    }
  }

  async uploadTinymceFile(params: Omit<NewFileParams, 'folderId'>): Promise<string> {
    const { file } = params;
    if (!file) return '';
    try {
      // right now every upload from tinyMce will have parentFolderId set to null =>
      // it means it will always go to "File" artifact type default folder
      const fileData = await lastValueFrom(this.fileService.uploadFileArtifact(file, null));

      return fileData.id;
    } catch (e: any) {
      return e.message;
    }
  }
}
