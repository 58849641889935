import { Directive, HostListener, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FileViewerComponent } from '@widgets/shared/components/file-viewer/file-viewer.component';
import { ArtifactFormatFileDataResponseDto, ArtifactResponseDto } from '@api/models';
import { lastValueFrom, map } from 'rxjs';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { TenantArtifactService } from '@api/services';

@Directive({
  selector: '[appFileArtifactViewer]',
})
export class FileArtifactViewerDirective {
  @Input() file: File | ArtifactResponseDto;

  constructor(private readonly dialogService: DialogService, private readonly tenantArtifactService: TenantArtifactService) {}

  @HostListener('click') async onClick() {
    const isNew = !(this.file as ArtifactResponseDto).id;
    const url = isNew ? URL.createObjectURL(this.file as File) : await this.getArtifactUrl(this.file as ArtifactResponseDto);
    const header = isNew ? (this.file as File).name : ((this.file as ArtifactResponseDto).formatData as ArtifactFormatFileDataResponseDto).filename;

    this.dialogService.open(FileViewerComponent, {
      closable: true,
      header,
      width: '50vw',
      style: {
        boxShadow: 'none',
        justifyContent: 'center',
      },
      styleClass: 'dialog-container',

      contentStyle: {
        position: 'relative',
        padding: '5px',
      },
      data: { file: this.file, url },
    });
  }

  private async getArtifactUrl(fileArtifact: ArtifactResponseDto): Promise<string> {
    if (fileArtifact.format !== ArtifactTypeFormatEnum.file) throw new Error('Wrong artifact format to download file');

    return lastValueFrom(
      this.tenantArtifactService
        .artifactControllerDownload({
          id: fileArtifact.id,
        })
        .pipe(map(res => window.URL.createObjectURL(res))),
    );
  }
}
