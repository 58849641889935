import { Injectable } from '@angular/core';
import { IconStyles } from '../../menu-widget/types/menu-widget-styles.types';

@Injectable({
  providedIn: 'root',
})
export class NumberWidgetBadgeService {
  setStyles(elementId: string, style: IconStyles): void {
    const element = (document as any).querySelector(`#${elementId} span`);
    if (!element) return;

    Object.keys(style).forEach(key => {
      const value = (style as any)[key];
      element.style[key] = value;

      if (key == 'fontSize') {
        const val = value.split('px')[0];
        ['min-width', 'height', 'line-height'].forEach(st => {
          element.style[st] = val * 2 + 'px';
        });
      }
    });
  }
}
