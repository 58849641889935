import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowService } from '@private/pages/workflow-management/workflow/services/workflow.service';
import { WorkflowModel } from '@private/pages/workflow-management/workflow/types/workflow.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { RuleAdministrationType } from '@workflows/shared';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  providers: [WorkflowService],
})
export class WorkflowComponent extends CoreComponent<WorkflowService, WorkflowModel> {
  RuleAdministrationType = RuleAdministrationType;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    public service: WorkflowService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new WorkflowModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Workflow' }]);
  }

  async save(): Promise<void> {
    await this.s.save();
  }
}
