import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { CalendarModule } from 'primeng/calendar';

import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

import { ArtifactLinkDialogComponent } from './artifact-link-dialog.component';
import { GetCreateLinkButtonLabelPipe } from './pipes/get-create-link-button-label.pipe';

@NgModule({
  declarations: [ArtifactLinkDialogComponent, GetCreateLinkButtonLabelPipe],
  imports: [
    CommonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    TableModule,
    CalendarModule,
    TriStateCheckboxModule,
    MultiSelectModule,
    TranslateModule,
    RouterModule,
    RippleModule,
    InputTextModule,
    DisplayArtifactValueToTableModule,
  ],
  exports: [ArtifactLinkDialogComponent],
})
export class NewArtifactLinkDialogModule {}
