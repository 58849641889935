import { FolderAclResponseDto } from '@api/models/folder-acl-response-dto';
import { AclRecordDto } from '@api/models/acl-record-dto';
import { AclComponentService } from '@widgets/shared/components/acl/services/acl.service';
import { ArtifactAclResponseDto } from '@api/models/artifact-acl-response-dto';
import { AttributeAclResponseDto } from '@api/models/attribute-acl-response-dto';

export type AclDtoType = FolderAclResponseDto | ArtifactAclResponseDto | AttributeAclResponseDto;

export class AclTypeModel {
  view: AclItem[] = [];
  move: AclItem[] = [];
  modify: AclItem[] = [];
  modifyItems: AclItem[] = [];
  delete: AclItem[] = [];
  deleteItems: AclItem[] = [];
  permissions: AclItem[] = [];
  expirationMaps: { [key: string]: AclExpiration } = {};

  constructor(s: AclComponentService, dto?: AclDtoType) {
    dto &&
      s.c.aclTypeList.forEach(key => {
        const record = (dto as any)[key] as AclRecordDto;
        if (record) {
          const expirations: Record<string, string> = {};
          if (record.expirations) {
            for (const [key, value] of Object.entries(record.expirations)) {
              expirations[key] = (value as string).split('T')[0];
            }
          }

          (this as any)[key] = [
            ...record.teamIds.map(id => s.getAclItemByIdAndType(id, expirations)),
            ...record.userIds.map(id => s.getAclItemByIdAndType(id, expirations, true)),
          ];

          this.expirationMaps[key] = expirations;
        }
      });
  }
}

export interface AclExpiration {
  [key: string]: Date | string;
}

export interface AclItem {
  id: string;
  name: string;
  type: AclItemType;
  expirationDate?: expirationDateType;
}

export type expirationDateType = Date | null;

export enum AclItemType {
  user = 'USER',
  team = 'TEAM',
}

export enum AclMemberTypes {
  all = 'All',
  users = 'Users',
  teams = 'Teams',
}

export enum AclTypes {
  view = 'view',
  move = 'move',
  modify = 'modify',
  modifyItems = 'modifyItems',
  delete = 'delete',
  deleteItems = 'deleteItems',
  permissions = 'permissions',
}
