import { TextFilterMatchType, TextFilterRuleType } from './text-filter-options.types';

export class TextFilter {
  matchType: TextFilterMatchType;
  ruleTypes: TextFilterOption[];

  constructor(dto?: TextFilter) {
    if (dto) {
      Object.assign(this, dto);
      console.log(this);
      return;
    }
    this.matchType = TextFilterMatchType.matchAny;
    this.ruleTypes = [new TextFilterOption()];
  }
}

export class TextFilterOption {
  ruleType: TextFilterRuleType;
  value: string;

  constructor() {
    this.ruleType = TextFilterRuleType.contains;
  }
}
