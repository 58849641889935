import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SharedMethods } from '@shared/methods/shared.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { AuthWidgetModel, AuthWidgetType } from '@widgets/auth-widget/types/auth-widget.types';
import { ConfirmationService } from 'primeng/api';
import { AuthWidgetService } from '@widgets/auth-widget/services/auth-widget.service';

@Component({
  selector: 'app-auth-widget-view',
  templateUrl: './auth-widget-view.component.html',
  styleUrls: ['./auth-widget-view.component.scss'],
})
export class AuthWidgetViewComponent {
  @Input() m: AuthWidgetModel;
  @Input() s: AuthWidgetService;

  readonly widgetType = AuthWidgetType;

  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly confirmationService: ConfirmationService,
    private readonly router: Router,
  ) {}

  async logout(): Promise<void> {
    await this.authorizationService.logoutWithConfirmation(this.confirmationService);
  }

  async navigateToLogin(): Promise<void> {
    await this.router.navigateByUrl(SharedMethods.getRedirectUrlQuery(this.router.url));
  }
}
