import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';
import { IconsService } from '@shared/services/icons.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  /** label for this dropdown element */
  @Input() label: string;
  @Input() labelClass: string;
  @Input() isDisabled: boolean;

  @Output() onChangeIcon: EventEmitter<string> = new EventEmitter<string>();

  iconOptions: SelectOption<string, string>[] = [];
  private iconOptionsSub: Subscription;

  constructor(private iconsService: IconsService) {}

  ngOnInit(): void {
    this.iconOptionsSub = this.iconsService.icons$.subscribe((icons: SelectOption<string, string>[]) => (this.iconOptions = icons));
  }

  ngOnDestroy(): void {
    this.iconOptionsSub.unsubscribe();
  }

  onChange(): void {
    this.onChangeIcon.emit(this.icon);
  }
}
