import { Injectable } from '@angular/core';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { DefaultItemStyles, MenuStylesPropNamesEnum } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { FlexDirectionEnum, PositionEnum } from '../../shared/types/style.types';
import { MenuTypesEnum } from '../types/menu-option.types';
import { MenuItem, MenuWidgetModel, Selected } from '../types/menu-widget.types';
import { MenuItemBehaviorEnum, TreeItem } from '../types/tree-types';

@Injectable({ providedIn: 'root' })
export class MenuWidgetHelper {
  convertMenuItemsToTree(items: MenuItem[], parent?: TreeItem): TreeItem[] {
    return items.map(item => {
      const node = new TreeItem({
        hash: item.hash,
        label: item.label,
        subLabel: item.subLabel,
        chip: item.chip,
        menuIcon: item.menuIcon,
        usesDefaultStyle: item.usesDefaultStyle,
        behavior: item.children.length ? MenuItemBehaviorEnum.textOnly : item.behavior,
        defaultElvisAction: item.defaultElvisAction,
        url: item.url,
        target: item.target,
        command: item.command,
        isPageSelection: item.isPageSelection,
        currentPageBehavior: item.currentPageBehavior,
        queryParams: item.queryParams,
        showEmptyParamsAsActive: item.showEmptyParamsAsActive,
        fragmentParam: item.fragmentParam,
        useAlias: item.useAlias,
        alias: item.alias,
        parent,
      });
      node.children = this.convertMenuItemsToTree(item.children, node);
      return node;
    });
  }

  setTreeItemParentsToTextOnly(items: TreeItem[]): void {
    items.forEach(item => (item.behavior = item.children.length ? MenuItemBehaviorEnum.textOnly : item.behavior));
  }

  getDefaultStyleUsedByItem(m: MenuWidgetModel, menuItem: MenuItem | null): DefaultItemStyles {
    if (menuItem && new IsItemInFirstLevelPipe().transform(m.items.menu, menuItem)) return m.styles.defaultFirstLevelItemStyles;
    else return m.styles.defaultSubMenuItemStyles;
  }

  convertTreeItemsToMenu(treeItems: TreeItem[], parent: MenuItem | null = null): MenuItem[] {
    return treeItems.map(item => {
      const menuItem = new MenuItem({
        hash: item.hash,
        label: item.label,
        subLabel: item.subLabel,
        chip: item.chip,
        menuIcon: item.menuIcon,
        usesDefaultStyle: item.usesDefaultStyle,
        behavior: item.children.length ? MenuItemBehaviorEnum.textOnly : item.behavior,
        defaultElvisAction: item.defaultElvisAction,
        url: item.url,
        target: item.target,
        command: item.command,
        isPageSelection: item.isPageSelection,
        currentPageBehavior: item.currentPageBehavior,
        queryParams: item.queryParams,
        showEmptyParamsAsActive: item.showEmptyParamsAsActive,
        fragmentParam: item.fragmentParam,
        useAlias: item.useAlias,
        alias: item.alias,
        parent,
      });
      menuItem.children = this.convertTreeItemsToMenu(item.children, menuItem);
      return menuItem;
    });
  }

  unselectTreeItem(m: MenuWidgetModel): void {
    m.selected.menuItem = null;
    m.selected.treeItem = null;
  }

  selectFirstItem(m: MenuWidgetModel): void {
    m.selected.treeItem = m.items.tree[0];
    m.selected.menuItem = m.items.menu[0];
  }

  expandParents(treeItem: MenuItem | TreeItem): void {
    treeItem.expanded = true;
    treeItem.parent && this.expandParents(treeItem.parent);
  }

  findNode(items: MenuItem[] | TreeItem[], hash: string): MenuItem | TreeItem | null {
    for (const node of items) {
      if (node.hash === hash) return node;

      if (node.children) {
        const item = this.findNode(node.children, hash);
        if (item) return item;
      }
    }

    return null;
  }

  setPositionStyleBasedOnMenuType(m: MenuWidgetModel): void {
    switch (m.settings.menu.type) {
      case MenuTypesEnum.horizontal:
        this.setStyleForMenuType(m, FlexDirectionEnum.row, PositionEnum.fixed);
        break;
      case MenuTypesEnum.panel:
        this.setStyleForMenuType(m, FlexDirectionEnum.column, PositionEnum.static, '');
        break;
      case MenuTypesEnum.tiered:
        this.setStyleForMenuType(m, FlexDirectionEnum.column, PositionEnum.absolute);
        break;
    }
  }

  setStyleForMenuType(m: MenuWidgetModel, flexDirection: FlexDirectionEnum, position: PositionEnum, minWidth?: string): void {
    m.settings.menu.flexDirection = flexDirection;
    m.styles.subMenu.position = position;
    minWidth && (m.styles.subMenu.minWidth = minWidth);
  }

  unselectAll(m: MenuWidgetModel): void {
    m.selected = new Selected();
  }

  selectLastlySelectedItem(m: MenuWidgetModel): void {
    if (!m.selected.menuItem) this.selectFirstItem(m);
    else m.selected.treeItem = this.findNode(m.items.tree, m.selected.menuItem.hash) as TreeItem;
  }

  refreshMenuItemStylesPipe(m: MenuWidgetModel | null, hash: string): void {
    m && (m.styles.generalMenuItemStyles[hash] = { ...m.styles.generalMenuItemStyles[hash] });
  }

  canPropUseDefaultStyles(prop: MenuStylesPropNamesEnum): boolean {
    return [MenuStylesPropNamesEnum.item, MenuStylesPropNamesEnum.label, MenuStylesPropNamesEnum.subLabel].includes(prop);
  }

  getDefaultFirstLevelItemStyles(m: MenuWidgetModel): DefaultItemStyles {
    this.copyNonInheritableStylesToDefaultStyle(m);
    return m.styles.defaultFirstLevelItemStyles;
  }

  copyNonInheritableStylesToDefaultStyle(m: MenuWidgetModel): void {
    if (m.selected.style && m.selected.menuItem) {
      m.styles.defaultFirstLevelItemStyles.standardMenuItemStyle[MenuStylesPropNamesEnum.icon] =
        m.selected.style[MenuStylesPropNamesEnum.icon][m.selected.menuItem.hash];
      m.styles.defaultFirstLevelItemStyles.standardMenuItemStyle[MenuStylesPropNamesEnum.chip] =
        m.selected.style[MenuStylesPropNamesEnum.chip][m.selected.menuItem.hash];
    }
  }
}
