import { Component, Input } from '@angular/core';
import { HTTP_HTTPS_VALIDATION_REGEX, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { LinkQueryParamActionEnum, MenuItemBehaviorEnum, MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';

@Component({
  selector: 'app-menu-item-type-form',
  templateUrl: './menu-item-type-form.component.html',
  styleUrls: ['./menu-item-type-form.component.scss'],
})
export class MenuItemTypeFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  menuItemBehavior = MenuItemBehaviorEnum;
  currentPageBehavior = MenuItemCurrentPageBehaviorEnum;
  queryParamActions = LinkQueryParamActionEnum;
  http_https_validation_regex = HTTP_HTTPS_VALIDATION_REGEX;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;

  onAddQueryParamClick(): void {
    if (this.m.selected.treeItem) this.m.selected.treeItem.queryParams.push({ key: '', value: '', action: LinkQueryParamActionEnum.addNew });
  }

  onDeleteParamClick(index: number): void {
    if (this.m.selected.treeItem) this.m.selected.treeItem.queryParams.splice(index, 1);
  }
}
