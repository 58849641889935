import { Component, Input } from '@angular/core';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '../../types/menu-widget.types';

@Component({
  selector: 'app-menu-widget-settings',
  templateUrl: './menu-widget-settings.component.html',
  styleUrls: ['./menu-widget-settings.component.scss'],
})
export class MenuWidgetSettingsComponent {
  @Input() s: MenuWidgetService;
  @Input() m: MenuWidgetModel;

  activeState: boolean[];

  constructor() {
    this.initActiveState();
  }

  initActiveState(): void {
    this.activeState = [false, false, true];
    this.m?.rerenderSettings();
  }
}
