import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';

export class BulkEditingAttribute {
  id: string;
  attribute: NewAttribute;
  clientAttribute: NewClientAttribute;
  dataType: NewDataType;
  sameAttributeValue: boolean;

  constructor(bulkEditingAttribute: Partial<BulkEditingAttribute>) {
    Object.assign(this, bulkEditingAttribute);
  }

  get isInvalid(): boolean {
    if (!this.clientAttribute.isMandatory) {
      return false;
    }

    return Array.isArray(this.clientAttribute.value) ? !this.clientAttribute.value.length : !this.clientAttribute.value;
  }
}
