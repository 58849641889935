<div *ngIf="isFromTemplateButtonVisible" class="d-flex justify-content-end">
  <h3 class="mr-auto">{{'Widgets' | translate}}</h3>
  <div class="widget-template-button-container">
    <button
      (click)="showWidgetTemplates()"
      class="p-button p-button-success p-component"
      label="{{'From template' | translate}}"
      pButton
      pRipple>
    </button>
  </div>
</div>
<div
  (cdkDropListEntered)="resetDraggedItemData()"
  *ngIf="widgetOptions?.length"
  [cdkDropListConnectedTo]="connectionIds"
  [cdkDropListDisabled]="isDraggingDisabled"
  [cdkDropListSortingDisabled]="true"
  cdkDropList
  class="widget-list"
  id="widgetList">
  <div
    *ngIf="draggedItemWidget"
    [style.order]="draggedItemIndex"
    class="widget-item placeholder">
    <i *ngIf="draggedItemWidget.icon" [class]="draggedItemWidget.icon"></i>
    {{ draggedItemWidget.label }}
  </div>

  <div
    (cdkDragExited)="setDraggedItemData(widget, widgetIndex)"
    (cdkDragReleased)="resetDraggedItemData()"
    (click)="selectWidget(widget.code)"
    *ngFor="let widget of widgetOptions; index as widgetIndex"
    [cdkDragData]="{ code: widget.code, type: widgetContentType }"
    [style.order]="widgetIndex"
    cdkDrag
    class="widget-item">
    <i *ngIf="widget.icon" [class]="widget.icon"></i>
    {{ widget.label }}
  </div>
</div>
