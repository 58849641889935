import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { ConfirmationService } from 'primeng/api';
import { LimitedMainComponent } from '../../pages/limited-main/limited-main.component';
import { LimitedTopBarService } from './services/limited-top-bar.service';
import { LimitedTopBarModel } from './types/limited-top-bar.model';

@Component({
  selector: 'app-limited-top-bar',
  templateUrl: './limited-top-bar.component.html',
  styleUrls: ['./limited-top-bar.component.scss'],
  providers: [LimitedTopBarService],
})
export class LimitedTopBarComponent extends CoreComponent<LimitedTopBarService, LimitedTopBarModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: LimitedTopBarService,
    announcement: AnnouncementService,
    public readonly app: LimitedMainComponent,
    public readonly authorizationService: AuthorizationService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly confirmationService: ConfirmationService,
  ) {
    super(route, router, translate, new LimitedTopBarModel(), service, announcement);
  }

  async logout(): Promise<void> {
    await this.authorizationService.logoutWithConfirmation(this.confirmationService);
  }

  protected onInit(): void {
    super.onInit();
  }
}
