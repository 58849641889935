<div class="grid">
  <h6 class="mb-0 col-12">{{('Grid template ' + trackType + 's') | translate}}</h6>

  <div class="mb-2 py-0 col-12">
    <button
      (click)="add()"
      [label]="('Add ' + trackType) | translate"
      class="p-button-sm"
      pButton
      pRipple
      type="button">
    </button>
  </div>
  <div *ngFor="let track of tracks; let trackIndex = index" class="py-0 mb-2 col-12 d-flex p-align-center">
    <input
      (ngModelChange)="update($event, track)"
      [ngModel]="track.size"
      class="p-inputtext-sm"
      pInputText
      placeholder="1fr"
      type="text">
    <button
      (click)="delete(track)"
      class="ml-2 p-button-danger p-button-sm"
      icon="pi pi-times"
      pButton
      pRipple
      type="button">
    </button>
  </div>
</div>
