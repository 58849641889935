<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 buttons_box">
    <a [routerLink]="['/admin/application-list']">
      <button pButton class="p-button p-button-warning" label="{{'Cancel' | translate}}" type="button"></button>
    </a>

    <button pButton class="p-button p-button-success" label="{{'Save' | translate}}" type="button" (click)="s.save()">
      <span *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></span>
    </button>
  </div>

  <div class="col-12 md:col-4" *ngIf="m?.application">
    <span class="dynamic-label">
      <input id="name" [(ngModel)]="m.application.name" pInputText type="text"/>
      <label class="dynamic-label-text" for="name">{{NAME_LABEL | translate}}</label>
    </span>
  </div>

  <div class="col-12 md:col-4" *ngIf="m?.application?.id">
    <span class="dynamic-label">
      <p-dropdown id="defaultPageId" inputId="defaultPageId" [options]="m.pageOptions" [autoDisplayFirst]="false"
                  [filter]="true" [showClear]="true" [(ngModel)]="m.application.defaultPageId"></p-dropdown>
      <label class="dynamic-label-text" for="defaultPageId">{{'Default page' | translate}}</label>
    </span>
  </div>
</div>
