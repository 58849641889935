import { Injectable } from '@angular/core';
import { BaseSsoService } from '@shared/services/sso/base.sso.service';
import { StateKey } from '@shared/types/local-storage.types';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class GoogleSsoService extends BaseSsoService {
  tooltip = 'Login with Google Account';
  content = '<span class="p-button-icon pi pi-google" aria-hidden="true"></span>';
  windowRef: null | Window = null;

  async onInit(redirectIfLogged?: () => void): Promise<void> {
    this.route.queryParams.pipe(take(1)).subscribe(async params => {
      if (params.ssoLogin === 'true') {
        if (window.opener && params.ssoToken) {
          const usePublicTokenInitialValue = this.pageHelper.usePublicToken;
          this.pageHelper.usePublicToken = false;
          //only popup handles successful login
          await this.authorizationHelperService.setToken(params.ssoToken, StateKey.session);
          await this.authorizationService.afterLogin(await lastValueFrom(this.tenantUserService.userControllerGetInfo()), params.ssoToken);
          this.pageHelper.usePublicToken = usePublicTokenInitialValue;
          window.opener.location.reload();
          window.close();
        }

        if (params.ssoError) {
          if (window.opener) {
            // popup resends error to parent
            window.opener.location.href = window.location.href;
            window.close();
          } else {
            // only parent shows error
            this.announcement.error(params.ssoError);
            this.router.navigate(['.'], { queryParams: {} });
          }
        }
      }
    });
    redirectIfLogged && redirectIfLogged();
  }

  async onClickHandler(): Promise<void> {
    if (this.windowRef !== null && !this.windowRef.closed) {
      this.windowRef.focus();
    } else {
      this.windowRef = window.open(
        `${this.authService.rootUrl}${(this.authService.constructor as any).AuthControllerAuthPath}?domain=${encodeURIComponent(window.location.origin)}`,
        '_blank',
        'location=no,toolbar=no,menubar=no,width=600,height=700',
      );
    }
  }
}
