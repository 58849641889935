import { Component, Input } from '@angular/core';
import { UserPageModel } from '@private/pages/user-management/user/types/user-page.types';

@Component({
  selector: 'app-tenant-admin-user-form',
  templateUrl: './tenant-admin-user-form.component.html',
  styleUrls: ['./tenant-admin-user-form.component.scss'],
})
export class TenantAdminUserFormComponent {
  @Input() m: UserPageModel;
}
