import { LinkResponseDto } from '@api/models/link-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { LinkTypeRestrictionResponseDto } from '@api/models/link-type-restriction-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkValidationType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { SelectOption } from '@shared/types/shared.types';

export class NewLink implements LinkResponseDto {
  id: string;
  linkTypeId: string;
  sourceArtifactId: string;
  destinationArtifactId: string;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;

  constructor(link?: Partial<NewLink>) {
    link && Object.assign(this, link);
  }
}

export class ArtifactTypeLinkRestriction {
  linkType: SelectOption<string, string, LinkDirection> | null = null;
  sourceArtifactTypeIds: string[] = [];
  destinationArtifactTypeIds: string[] = [];
  singleSource = false;
  singleDestination = false;
  isLinkRequired = LinkValidationType.none;

  constructor(restriction?: Partial<ArtifactTypeLinkRestriction>) {
    restriction && Object.assign(this, restriction);
  }
}

export class UngroupedArtifactTypeLinkRestriction {
  linkType: SelectOption<string, string, LinkDirection> | null = null;
  directionalLinkTypeId: `${string}${LinkDirection}` | null = null;
  sourceArtifactTypeId: string;
  destinationArtifactTypeId: string;
  singleSource = false;
  singleDestination = false;
  isLinkRequired = new SelectOption(LinkValidationType.none, LinkValidationType.none) as any;
  notifySource = false;
  notifyDestination = false;

  constructor(restriction?: Partial<UngroupedArtifactTypeLinkRestriction>) {
    restriction && Object.assign(this, restriction);
  }

  get isEmpty(): boolean {
    return !this.sourceArtifactTypeId && !this.destinationArtifactTypeId;
  }

  get isBidirectional(): boolean {
    return !this.isEmpty && this.sourceArtifactTypeId === this.destinationArtifactTypeId;
  }
}

export class LinkRestriction implements LinkTypeRestrictionResponseDto {
  sourceArtifactTypeId: string;
  destinationArtifactTypeId: string;
  singleSource = false;
  singleDestination = false;
  notifySource = false;
  notifyDestination = false;
  isLinkRequired = new SelectOption(LinkValidationType.none, LinkValidationType.none) as any;

  constructor(restriction?: Partial<LinkRestriction>) {
    restriction && Object.assign(this, restriction);
  }
}

export interface LinkRestrictionParamsBase {
  direction: LinkDirection;
  linkTypeId: string;
}

export class GetLinkRestrictionsParams {
  constructor(
    public restriction: LinkRestriction,
    public group: ArtifactTypeLinkRestriction[],
    public linkType: LinkTypeResponseDto,
    public direction: LinkDirection,
  ) {}
}

export enum LinkRestrictionParamsEnum {
  sourceArtifactTypeId = 'sourceArtifactTypeId',
  destinationArtifactTypeId = 'destinationArtifactTypeId',
  singleSource = 'singleSource',
  singleDestination = 'singleDestination',
}

export enum RelationTypeEnum {
  oneToOne = 'oneToOne',
  oneToMany = 'oneToMany',
  manyToOne = 'manyToOne',
  manyToMany = 'manyToMany',
}

export interface RelationOption {
  value: RelationTypeEnum;
  label: string;
  disabled?: boolean;
}

export type RelationInBoolean = Pick<LinkTypeRestrictionResponseDto, 'singleSource' | 'singleDestination'>;

type LinkResponseInfo = Pick<LinkResponseDto, 'id' | 'linkTypeId' | 'sourceArtifactId' | 'destinationArtifactId'>;

type LinkRestrictionInfo = Pick<LinkTypeRestrictionResponseDto, 'sourceArtifactTypeId' | 'destinationArtifactTypeId'>;

export class LinkInfo implements LinkResponseInfo, LinkRestrictionInfo {
  id: string;
  linkTypeId: string;
  sourceArtifactTypeId: string;
  destinationArtifactTypeId: string;
  sourceArtifactId: string;
  destinationArtifactId: string;

  constructor(linkInfo?: Partial<LinkInfo>) {
    linkInfo && Object.assign(this, linkInfo);
  }
}
