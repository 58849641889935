import { Injectable } from '@angular/core';
import { ToastMessage } from '@shared/types/toast.types';
import { MessageService } from 'primeng/api';
import { TranslateUtil } from '../utils/translateUtil';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  constructor(private readonly messageService: MessageService, private readonly translateUtil: TranslateUtil) {}

  public async success(detail: string, interpolateParams: Record<string, string> = {}): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.messageService.add(
      new ToastMessage('success', ...(await this.translateUtil.getAll(['Success', detail], interpolateParams)), undefined, { life: 3000 }),
    );
  }

  public async warn(detail: string, interpolateParams: Record<string, string> = {}): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.messageService.add(new ToastMessage('warn', ...(await this.translateUtil.getAll(['Warn', detail], interpolateParams)), undefined, { life: 3000 }));
  }

  public async info(detail: string, interpolateParams: Record<string, string> = {}): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.messageService.add(new ToastMessage('info', ...(await this.translateUtil.getAll(['Info', detail], interpolateParams)), undefined, { life: 3000 }));
  }

  public async error(detail: string, interpolateParams: Record<string, string> = {}): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.messageService.add(new ToastMessage('error', ...(await this.translateUtil.getAll(['Error', detail], interpolateParams)), undefined, { life: 10000 }));
  }

  public notifySimpleSuccess(detail: string, interpolateParams: Record<string, string> = {}) {
    this.notifySimpleMessage(detail, interpolateParams, 'success', 'Success', 3000);
  }

  public notifySimpleWarning(detail: string, interpolateParams: Record<string, string> = {}) {
    this.notifySimpleMessage(detail, interpolateParams, 'warn', 'Warn', 3000);
  }

  public notifySimpleInfo(detail: string, interpolateParams: Record<string, string> = {}) {
    this.notifySimpleMessage(detail, interpolateParams, 'info', 'Info', 3000);
  }

  public notifySimpleError(detail: string, interpolateParams: Record<string, string> = {}) {
    this.notifySimpleMessage(detail, interpolateParams, 'error', 'Error', 10000);
  }

  private notifySimpleMessage(detail: string, interpolateParams: Record<string, string> = {}, severity: string, severityText: string, life = 3000) {
    const summaryText = this.translateUtil.instant(severityText, interpolateParams);
    const detailText = this.translateUtil.instant(detail, interpolateParams);
    this.messageService.add(new ToastMessage(severity, summaryText, detailText, { life }));
  }
}
