import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageSectionResponseDto, TemplateResponseDto } from '@api/models';
import { TemplateListResponseDto } from '@api/models/template-list-response-dto';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { TranslateService } from '@ngx-translate/core';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { CoreComponent } from '../../core/components/core.component';
import { AnnouncementService } from '../../services/announcement.service';
import { SectionGenerationFormComponent } from './components/section-generation-form/section-generation-form.component';
import { TemplatesService } from './services/templates.service';
import { TemplatesModel, TemplateType } from './types/templates.types';
import { ApplicationSwitcherService } from '../application-switcher/services/application-switcher.service';

const headers: Record<TemplateType, string> = {
  [TemplateType.section]: 'Section templates',
  [TemplateType.row]: 'Section templates',
  [TemplateType.block]: 'Section templates',
  [TemplateType.blockPart]: 'Section templates',
  [TemplateType.widget]: 'Widget templates',
};

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers: [TemplatesService],
})
export class TemplatesComponent extends CoreComponent<TemplatesService, TemplatesModel> implements OnInit {
  @ViewChild(SectionGenerationFormComponent) generator: SectionGenerationFormComponent;

  type: TemplateType = this.config.data.templateType;
  hideTemplateTypePicker?: boolean = this.config.data.hideTemplateTypePicker;
  useSingleButtonToPickTemplate?: boolean = this.config.data.useSingleButtonToPickTemplate;
  widgetType?: WidgetType = this.config.data.widgetType;
  header: string = headers[this.type];
  isGenerateSectionButtonVisible: boolean = this.type === TemplateType.section;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: TemplatesService,
    announcement: AnnouncementService,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly config: DynamicDialogConfig,
    protected readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super(route, router, translate, new TemplatesModel(), service, announcement);
  }

  loadDataMethod = async (meta?: any): Promise<TemplateListResponseDto> => {
    try {
      if (this.m.templateType === 'internal') return await lastValueFrom(this.tenantTemplateService.templateControllerList(meta));
      return Promise.resolve({
        data: [
          { id: 'UPLOAD_BUTTON' } as TemplateResponseDto,
          {
            id: '63cebdebccf094edcdf6863f',
            created: {
              on: '2023-01-23T17:03:39.929Z',
              by: '63ca8d77b9698245cf4ba092',
            },
            updated: {
              on: '2023-01-23T18:21:29.650Z',
              by: '63ca8d77b9698245cf4ba092',
            },
            categories: [],
            thumbnailFileArtifactId: null,
            deleted: null,
            imported: {
              importUuid: '9864780e-d60c-4c1d-8759-60d06758d23a',
            },
            applicationId: '63cebdebccf094edcdf686xx',
            name: 'Cross App',
            type: 'SECTION',
            template: {} as PageSectionResponseDto,
            icon: 'pi pi-ticket',
            description: 'Cross Application Menu',
          },
        ],
        meta: {
          totalCount: 1,
        },
      });
    } catch (error) {
      console.log(error);
      await this.announcement.error('Unable to load templates');
    }
    return Promise.resolve({
      data: [],
      meta: {
        totalCount: 0,
      },
    });
  };

  toggleGenerator($event: Event): void {
    this.generator.toggle($event);
  }
}
