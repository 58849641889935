import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getAttributeAndLinkTypeOptions' })
export class GetAttributeAndLinkTypeOptionsPipe implements PipeTransform {
  transform(
    attribute: SelectOption<string, NewClientAttribute>[],
    linkType: SelectOption<string, LinkType, LinkDirection>[],
  ): SelectOption<string, NewClientAttribute | LinkType>[] {
    return [...attribute, ...linkType];
  }
}
