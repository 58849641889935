import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { SelectOption } from '@shared/types/shared.types';
import { AuthWidgetType } from '@widgets/auth-widget/types/auth-widget.types';
import { FlexDirection, JustifyContent } from '@widgets/shared/types/style.types';
import { ListContainer } from '@shared/types/list-container.types';

export class AuthWidgetOptions {
  widgetType: SelectOption<AuthWidgetType, AuthWidgetType>[] = GetSelectOptionsFromEnum<AuthWidgetType>(AuthWidgetType);
  horizontalAlign: SelectOption<JustifyContent, JustifyContent>[] = GetSelectOptionsFromEnum<JustifyContent>(JustifyContent);
  flexDirection: SelectOption<FlexDirection, FlexDirection>[] = GetSelectOptionsFromEnum<FlexDirection>(FlexDirection);
  userIconVariant = new ListContainer<UserIconVariantType>();

  constructor() {
    this.userIconVariant.setList(Object.keys(UserIconVariantType).map(key => UserIconVariantType[key as keyof typeof UserIconVariantType]));
  }
}

export enum UserIconVariantType {
  photo = 'photo',
  icon = 'icon',
  letters = 'letters',
}
