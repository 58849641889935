import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { TimeFilterRuleType } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';

@Pipe({ name: 'isDatetimeFilterNumericType' })
export class IsDatetimeFilterNumericTypePipe implements PipeTransform {
  constructor(private readonly filterMetaDataUtil: FilterMetadataUtil) {}

  transform(filterType: DateRangeFilterEnum | DateFilterEnum | TimeFilterRuleType): boolean {
    return this.filterMetaDataUtil.isFilterNumeric(filterType as DateRangeFilterEnum);
  }
}
