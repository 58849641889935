import { Component, HostBinding, Inject } from '@angular/core';
import { ArtifactResponseDto, SelfUserResponseDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { NON_MAPPABLE_FIELDS } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/types/artifact-type-avr-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants } from '@shared/constants/constants';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';
import {
  DocumentGenerationAvrType,
  DocumentGenerationOutputType,
} from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { BehaviorSubject } from 'rxjs';

export const CONST_MIMETYPES_MAP = {
  [DocumentGenerationOutputType.docx]: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'],
  [DocumentGenerationOutputType.pptx]: ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint'],
};

@Component({
  selector: 'app-avr-document-generation-non-mappable-fields',
  templateUrl: './avr-document-generation.non-mappable-fields.component.html',
  styleUrls: ['./avr-document-generation.non-mappable-fields.component.scss'],
})
export class AvrDocumentGenerationNonMappableFieldsComponent {
  @HostBinding('class') hostClass = 'p-datatable-tbody';

  public everythingWithinArtifactTypeOptions: SelectOption<string, boolean>[] = [];
  public fileArtifactTypeOptions: SelectOption<string, NewArtifactType>[] = [];
  public outputTypeOptions: SelectOption<string, string>[] = [];
  public fileArtifactOptions: SelectOption<string, NewArtifact>[] = [];
  public Constants = Constants;

  public templates: DocumentGeneationAvrTemplates[] = [];
  public selectedArtifactType: NewArtifactType | null = null;
  public init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private cachedOptions: { currentUser: SelfUserResponseDto | null } = { currentUser: null };

  constructor(
    @Inject(NON_MAPPABLE_FIELDS) public nonMappableFields: BaseAvrInputMapperDto<DocumentGenerationAvrType>['nonMappableFields'],
    private readonly artifactService: TenantArtifactService,
    private readonly cache: NewCacheService,
  ) {
    this.everythingWithinArtifactTypeOptions = [new SelectOption('True', true), new SelectOption('False', false)];

    this.templates = nonMappableFields.templates!.value;

    this.cache.data.artifactTypes.getMany$([], [{ format: ArtifactTypeFormatEnum.file }]).subscribe(data => {
      let foundArtifactType = nonMappableFields.fileArtifactTypeId!.value === '';

      data.forEach(artifactType => {
        const transformedArtifactType = new NewArtifactType(artifactType);
        this.fileArtifactTypeOptions.push(new SelectOption(artifactType.name, transformedArtifactType));
        if (!foundArtifactType && artifactType.id === nonMappableFields.fileArtifactTypeId!.value) {
          this.selectedArtifactType = transformedArtifactType;
          foundArtifactType = true;
        }
      });

      if (!foundArtifactType) {
        this.addNonAccessibleOption(this.fileArtifactTypeOptions);
        this.selectedArtifactType = this.fileArtifactTypeOptions[0].value;
      }

      this.loadFileArtifacts();
    });

    this.outputTypeOptions = Object.values(DocumentGenerationOutputType).map(outputType => new SelectOption(outputType, outputType));

    this.initCacheOptions();
  }

  onFileArtifactTypeChange(value: NewArtifactType): void {
    this.fileArtifactOptions = [];
    this.loadFileArtifacts();

    this.clearTemplates();

    this.nonMappableFields.fileArtifactTypeId!.value = value.id;
  }

  onOutputTypeChange(): void {
    this.clearTemplates();
    this.loadFileArtifacts();
  }

  onArtifactChange(artifactId: string, template: DocumentGeneationAvrTemplates): void {
    this.fileArtifactOptions.find(fileArtifactOption => fileArtifactOption.value.id === artifactId)!.disabled = true;
    template.fileArtifactId = artifactId;
  }

  onAddNewTemplateClick(): void {
    this.templates.push(new DocumentGeneationAvrTemplates({ fileArtifactId: '' }));
  }

  onDeleteTemplateClick(templateIndex: number): void {
    if (this.templates.length <= 1) return;
    if (this.templates[templateIndex].fileArtifactId) {
      this.fileArtifactOptions.find(fileArtifactOption => fileArtifactOption.value.id === this.templates[templateIndex].fileArtifactId)!.disabled = false;
    }
    this.templates.splice(templateIndex, 1);
  }

  private clearTemplates(): void {
    this.templates = [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })];
    this.nonMappableFields.templates!.value = this.templates;
  }

  private initCacheOptions(): void {
    this.cachedOptions.currentUser = this.cache.user.value as SelfUserResponseDto;
  }

  private loadFileArtifacts(): void {
    this.fileArtifactOptions = [];
    if (!this.selectedArtifactType) {
      this.finishInit();
      return;
    }

    let allowedMimetypes: string[] = [];
    switch (this.nonMappableFields.outputType!.value) {
      case DocumentGenerationOutputType.pdf:
        allowedMimetypes = [...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.docx], ...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.pptx]];
        break;
      default:
        allowedMimetypes = [
          ...CONST_MIMETYPES_MAP[this.nonMappableFields.outputType!.value as Exclude<DocumentGenerationOutputType, DocumentGenerationOutputType.pdf>],
        ];
        break;
    }

    const filter = [
      { deleted: { $eq: null } },
      { format: { $eq: ArtifactTypeFormatEnum.file } },
      { artifactTypeId: { $in: [{ $oid: this.selectedArtifactType?.id }] } },
      { 'formatData.mimetype': { $in: allowedMimetypes } },
    ];

    this.cache.data.artifacts.getMany$([], [...filter]).subscribe(data => {
      const selectedArtifactIds = this.templates.map(template => template.fileArtifactId);
      const checkSelectedArtifactIds = selectedArtifactIds.length > 0;
      const foundArtifactIds: string[] = [];

      data.forEach(artifact => {
        const transformedArtifact = this.artifactResponseTransform(artifact, this.selectedArtifactType!);
        const name = transformedArtifact.attributes[this.cachedOptions.currentUser?.tenant?.systemAttributes?.nameAttributeId || '']?.value || '';
        const option = new SelectOption(name, transformedArtifact);
        this.fileArtifactOptions.push(option);
        if (checkSelectedArtifactIds && selectedArtifactIds.includes(artifact.id)) {
          foundArtifactIds.push(artifact.id);
          option.disabled = true;
        }
      });

      if (checkSelectedArtifactIds) {
        const missingTemplates = this.templates
          .filter(template => !foundArtifactIds.includes(template.fileArtifactId) && template.fileArtifactId !== '')
          .entries();

        for (const [i, missingTemplate] of missingTemplates) {
          this.addNonAccessibleOption(this.fileArtifactOptions, i + 1, missingTemplate);
        }
      }

      this.finishInit();
    });
  }

  private finishInit(): void {
    if (this.init$.closed === false) {
      this.init$.next(true);
      this.init$.unsubscribe();
    }
  }

  private artifactResponseTransform(artifact: ArtifactResponseDto, artifactType: NewArtifactType): NewArtifact {
    return new NewArtifact({
      dto: artifact,
      artifactTypesMap: {
        [artifactType.id]: artifactType,
      },
    });
  }

  private addNonAccessibleOption(selectOptions: SelectOption<string, any>[], index?: number, value: any = null): void {
    selectOptions.unshift(new SelectOption(`[Inaccessible option${index ? ` (${index})` : ''}]`, value, null, true));
  }
}
