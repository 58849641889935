<p-dialog (onHide)="onDialogHide()" [(visible)]="displayModal" [baseZIndex]="2"
          [contentStyle]="{minHeight: '250px', paddingBottom: 0, overflow: 'visible'}"
          [draggable]="false" [header]="linkTypeName" [modal]="true"
          [resizable]="false" [style]="{width: '80vw', position: 'absolute', top: '5rem'}">

  <div *ngIf="artifactTypeOptions?.length > 1" class="grid">
    <div class="col-12 md:col-3">
      <span class="dynamic-label">
        <p-dropdown (onChange)="onArtifactTypeChange($event)"
                    *ngIf="selectedLinkType"
                    [(ngModel)]="selectedArtifactType"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    [options]="artifactTypeOptions"
                    appendTo="body"
                    class="modal-dropdown"
                    id="artifactType">
        </p-dropdown>
        <label *ngIf="!selectedArtifactType" class="dynamic-label-text"
               for="artifactType">{{'Artifact type' | translate}}</label>
      </span>
    </div>
  </div>
  <p-table #table
           (onLazyLoad)="onLazyLoad($event)"
           *ngIf="selectedArtifactType"
           [(selection)]="targetArtifact"
           [columns]="columns"
           [filters]="selectedArtifactType ? {artifactTypeId: [{value: [selectedArtifactType.id], matchMode: 'in', operator: 'and'}], deleted: [{ value: null, matchMode: 'equals', operator: 'and' }]} : null"
           [lazy]="true"
           [loading]="loading"
           [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
           [reorderableColumns]="true"
           [resizableColumns]="true"
           [rowsPerPageOptions]="meta.limitOptions"
           [rows]="meta.limit"
           [scrollable]="true"
           [selectionMode]="isMultipleSelectionMode ? 'multiple' : 'single'"
           [totalRecords]="meta.totalCount"
           [value]="data"
           class="sticky"
           columnResizeMode="expand"
           dataKey="id"
           scrollHeight="50vh">

    <ng-template pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let col of columns" class="fixed-width-col-standard">
      </colgroup>
    </ng-template>

    <ng-template let-columns pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" class="p-text-truncate" pResizableColumn>
          {{col.label}}

          <p-columnFilter
            *ngIf="col.filterType && !isDateFilter(col.filterType) && !isDateTimeFilter(col.filterType) && !isBooleanFilter(col.filterType)"
            [field]="getFilterKey(col)"
            [type]="col.filterType"
            display="menu"
            matchMode="contains">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <input (focusout)="filter($event.target.value)" (keyup.enter)="filter($event.target.value)" [ngModel]="value" pInputText type="text">
            </ng-template>
          </p-columnFilter>

          <p-columnFilter *ngIf="col.filterType && isDateFilter(col.filterType)"
                          [field]="getFilterKey(col)"
                          [type]="col.filterType"
                          display="menu">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-calendar (onSelect)="filter($event)" [ngModel]="value" [dateFormat]="dateFormat"
                          [firstDayOfWeek]="firstDayOfWeek"></p-calendar>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter *ngIf="col.filterType && isDateTimeFilter(col.filterType)"
                          [field]="getFilterKey(col)"
                          [type]="'date'"
                          display="menu">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-calendar (onSelect)="filter($event)" [ngModel]="value" [dateFormat]="dateFormat"
                          [firstDayOfWeek]="firstDayOfWeek"
                          [showTime]="true"></p-calendar>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter *ngIf="col.filterType && isBooleanFilter(col.filterType)"
                          [field]="getFilterKey(col)"
                          [showAddButton]="false"
                          [showMatchModes]="false"
                          [showOperator]="false"
                          display="menu"
                          matchMode="equals">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-triStateCheckbox (onChange)="filter($event.value)" [ngModel]="value"></p-triStateCheckbox>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter *ngIf="enumeratedFilterOptions[col.key] || userAttributesMap[col.key]"
                          [field]="getFilterKey(col)"
                          [showAddButton]="false"
                          [showMatchModes]="false"
                          [showOperator]="false"
                          display="menu"
                          matchMode="in">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-multiSelect (onChange)="filter($event.value)"
                             [ngModel]="value"
                             [options]="enumeratedFilterOptions[col.key] ? enumeratedFilterOptions[col.key] : usersOptions"
                             placeholder="Any">
                <ng-template let-option pTemplate="item">
                  <div>
                    <span class="ml-1">{{option.label | translate}}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-row pTemplate="body">
      <tr [pSelectableRow]="row">
        <td *ngFor="let col of columns">
          <div class="table-cell-content">
            <span class="p-column-title p-text-truncate">{{ col.label }}</span>
            <span class="p-text-truncate p-d-block">
                <a *ngIf="col.key === 'id'; else notLink"
                   [queryParams]="{artifactId: row.id}"
                   [routerLink]="['/page', selectedArtifactType.defaultPageId]"
                   target="_blank">
                  {{row | displayArtifactValueToTable: col.key:attributes.listMap:dataTypes.listMap: users.listMap}}
                </a>
                <ng-template #notLink>
                  {{row | displayArtifactValueToTable: col.key:attributes.listMap:dataTypes.listMap: users.listMap}}
                </ng-template>
            </span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="columns.length + 1" translate>Linkable artifacts not found</td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <span *ngIf="disableSaveDueToSingleRestrictions">Can`t add link because of link restrictions</span>
    <div class="dialog-footer">
      <button (click)="close()" class="p-button p-button-warning" label="{{'Cancel' | translate}}" pButton
              type="button"></button>
      <button (click)="create()"
              [disabled]="!targetArtifact || (isMultipleSelectionMode && !targetArtifact.length) || disableSaveDueToSingleRestrictions"
              class="p-button p-button-success"
              label="{{ (targetArtifact | getCreateLinkButtonLabel : isMultipleSelectionMode) | translate }}" pButton
              pRipple type="button">
      </button>
    </div>
  </ng-template>
</p-dialog>
