/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FolderCreateRequestDto } from '../../models/folder-create-request-dto';
import { FolderResponseDto } from '../../models/folder-response-dto';

export interface FolderControllerCreate$Params {
      body: FolderCreateRequestDto
}

export function folderControllerCreate(http: HttpClient, rootUrl: string, params: FolderControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderResponseDto>> {
  const rb = new RequestBuilder(rootUrl, folderControllerCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FolderResponseDto>;
    })
  );
}

folderControllerCreate.PATH = '/api/tenant/folder';
