/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { FolderAclResponseDto } from '../models/folder-acl-response-dto';
import { folderControllerAclInsert } from '../fn/tenant-folder/folder-controller-acl-insert';
import { FolderControllerAclInsert$Params } from '../fn/tenant-folder/folder-controller-acl-insert';
import { folderControllerAclRemove } from '../fn/tenant-folder/folder-controller-acl-remove';
import { FolderControllerAclRemove$Params } from '../fn/tenant-folder/folder-controller-acl-remove';
import { folderControllerCount } from '../fn/tenant-folder/folder-controller-count';
import { FolderControllerCount$Params } from '../fn/tenant-folder/folder-controller-count';
import { folderControllerCreate } from '../fn/tenant-folder/folder-controller-create';
import { FolderControllerCreate$Params } from '../fn/tenant-folder/folder-controller-create';
import { folderControllerDelete } from '../fn/tenant-folder/folder-controller-delete';
import { FolderControllerDelete$Params } from '../fn/tenant-folder/folder-controller-delete';
import { folderControllerGet } from '../fn/tenant-folder/folder-controller-get';
import { FolderControllerGet$Params } from '../fn/tenant-folder/folder-controller-get';
import { folderControllerGetAcl } from '../fn/tenant-folder/folder-controller-get-acl';
import { FolderControllerGetAcl$Params } from '../fn/tenant-folder/folder-controller-get-acl';
import { folderControllerList } from '../fn/tenant-folder/folder-controller-list';
import { FolderControllerList$Params } from '../fn/tenant-folder/folder-controller-list';
import { folderControllerUpdate } from '../fn/tenant-folder/folder-controller-update';
import { FolderControllerUpdate$Params } from '../fn/tenant-folder/folder-controller-update';
import { FolderListResponseDto } from '../models/folder-list-response-dto';
import { FolderResponseDto } from '../models/folder-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantFolderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `folderControllerCount()` */
  static readonly FolderControllerCountPath = '/api/tenant/folder/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerCount$Response(params?: FolderControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return folderControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerCount(params?: FolderControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.folderControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerGetAcl()` */
  static readonly FolderControllerGetAclPath = '/api/tenant/folder/acl/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerGetAcl()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerGetAcl$Response(params: FolderControllerGetAcl$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderAclResponseDto>> {
    return folderControllerGetAcl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerGetAcl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerGetAcl(params: FolderControllerGetAcl$Params, context?: HttpContext): Observable<FolderAclResponseDto> {
    return this.folderControllerGetAcl$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderAclResponseDto>): FolderAclResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerGet()` */
  static readonly FolderControllerGetPath = '/api/tenant/folder/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerGet$Response(params: FolderControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderResponseDto>> {
    return folderControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerGet(params: FolderControllerGet$Params, context?: HttpContext): Observable<FolderResponseDto> {
    return this.folderControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderResponseDto>): FolderResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerDelete()` */
  static readonly FolderControllerDeletePath = '/api/tenant/folder/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerDelete$Response(params: FolderControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderResponseDto>> {
    return folderControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerDelete(params: FolderControllerDelete$Params, context?: HttpContext): Observable<FolderResponseDto> {
    return this.folderControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderResponseDto>): FolderResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerList()` */
  static readonly FolderControllerListPath = '/api/tenant/folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerList$Response(params?: FolderControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderListResponseDto>> {
    return folderControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  folderControllerList(params?: FolderControllerList$Params, context?: HttpContext): Observable<FolderListResponseDto> {
    return this.folderControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderListResponseDto>): FolderListResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerUpdate()` */
  static readonly FolderControllerUpdatePath = '/api/tenant/folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerUpdate$Response(params: FolderControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderResponseDto>> {
    return folderControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerUpdate(params: FolderControllerUpdate$Params, context?: HttpContext): Observable<FolderResponseDto> {
    return this.folderControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderResponseDto>): FolderResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerCreate()` */
  static readonly FolderControllerCreatePath = '/api/tenant/folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerCreate$Response(params: FolderControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderResponseDto>> {
    return folderControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerCreate(params: FolderControllerCreate$Params, context?: HttpContext): Observable<FolderResponseDto> {
    return this.folderControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderResponseDto>): FolderResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerAclInsert()` */
  static readonly FolderControllerAclInsertPath = '/api/tenant/folder/acl/insert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerAclInsert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerAclInsert$Response(params: FolderControllerAclInsert$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderAclResponseDto>> {
    return folderControllerAclInsert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerAclInsert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerAclInsert(params: FolderControllerAclInsert$Params, context?: HttpContext): Observable<FolderAclResponseDto> {
    return this.folderControllerAclInsert$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderAclResponseDto>): FolderAclResponseDto => r.body)
    );
  }

  /** Path part for operation `folderControllerAclRemove()` */
  static readonly FolderControllerAclRemovePath = '/api/tenant/folder/acl/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `folderControllerAclRemove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerAclRemove$Response(params: FolderControllerAclRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderAclResponseDto>> {
    return folderControllerAclRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `folderControllerAclRemove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  folderControllerAclRemove(params: FolderControllerAclRemove$Params, context?: HttpContext): Observable<FolderAclResponseDto> {
    return this.folderControllerAclRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<FolderAclResponseDto>): FolderAclResponseDto => r.body)
    );
  }

}
