<div class="grid">
  <ng-container *ngIf="isContainerClassEditable">
    <div class="col-4">
      <h6>{{'Container width' | translate}}</h6>
    </div>
    <div class="col-8">
      <app-container-class [styles]="styles"></app-container-class>
    </div>

    <ng-container *ngIf="styles.containerClass === 'container'">
      <div class="col-4">
        <h6>{{'Max-width' | translate}}</h6>
      </div>
      <div class="col-8">
        <app-max-width [element]="element" [styles]="styles"></app-max-width>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isMarginEditable">
    <div class="col-4">
      <h6>{{'Outer Margin' | translate}}</h6>
    </div>
    <div class="col-8">
      <button (click)="marginOverlayPanel.toggle($event)"
              icon="pi pi-cog"
              label="{{'Margin' | translate}}"
              pButton></button>
    </div>
  </ng-container>

  <div class="col-4">
    <h6>{{'Inner Padding' | translate}}</h6>
  </div>
  <div class="col-8">
    <button (click)="paddingOverlayPanel.toggle($event)"
            icon="pi pi-cog"
            label="{{'Padding' | translate}}"
            pButton></button>
  </div>

  <div class="col-4">
    <h6>{{'Background' | translate}}</h6>
  </div>
  <div class="col-4">
    <p-dropdown [(ngModel)]="styles.backgroundType"
                (ngModelChange)="onBgTypeChange()"
                [options]="styleOptions.backgroundType">
    </p-dropdown>
  </div>

  <div class="col-4" *ngIf="!(styles.backgroundType | isBackgroundOfType: backgroundType.gradient)">
    <button (click)="backgroundColorOverlayPanel.toggle($event)"
            *ngIf="styles.backgroundType | isBackgroundOfType: backgroundType.color"
            icon="pi pi-palette"
            label="{{'Color' | translate}}"
            pButton></button>
    <button (click)="backgroundImageOverlayPanel.toggle($event)"
            *ngIf="styles.backgroundType | isBackgroundOfType: backgroundType.image"
            icon="pi pi-image"
            label="{{'Image' | translate}}"
            pButton></button>
  </div>

  <div class="col-12" *ngIf="styles.backgroundType | isBackgroundOfType: backgroundType.gradient">
    <app-bg-gradient-settings [styles]="styles"></app-bg-gradient-settings>
  </div>

  <div class="col-4">
    <h6>{{'Border' | translate}}</h6>
  </div>
  <!--  TODO: remove senseless checkbox ??? Yes, remove -->
  <div class="col-4">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="resetBorderAndShadowStylesIfDisabled($event)"
                    [(ngModel)]="styles.isBorderEnable"
                    binary="true"></p-checkbox>
      </span>
    </span>
  </div>

  <div class="col-4">
    <button (click)="sectionInnerBorderOverlayPanel.toggle($event)"
            *ngIf="styles.isBorderEnable"
            icon="bi bi-border-style"
            label="{{'Borders' | translate}}"
            pButton></button>
  </div>

  <div class="col-4">
    <h6>{{'Container overflow' | translate}}</h6>
  </div>
  <div class="col-8">
    <button (click)="overflowOverlayPanel.toggle($event)"
            icon="bi bi-stack-overflow"
            label="{{'Overflow' | translate}}"
            pButton></button>
  </div>
  <ng-container *ngIf="showHeightSettings">
    <div class="col-4">
      <h6>{{'Container height' | translate}}</h6>
    </div>
    <div class="col-8">
      <input type="text" pInputText [(ngModel)]="styles.height" />
    </div>
  </ng-container>

</div>

<p-overlayPanel #marginOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-margin-form [marginStyles]="styles"></app-margin-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #paddingOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-padding-form [paddingStyles]="styles"></app-padding-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #backgroundColorOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-color-picker [(color)]="styles.backgroundColor" label="Background"></app-color-picker>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #backgroundImageOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-background-image [(imageUrl)]="styles.backgroundImage"></app-background-image>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #sectionInnerBorderOverlayPanel [style]="{ 'max-width': '500px' }" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [allowShadowInput]="true" [styles]="styles"></app-border-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #overflowOverlayPanel [style]="{ 'max-width': '300px' }" appendTo="body">
  <ng-template pTemplate>
    <app-overflow-form [styleOptions]="styleOptions" [styles]="styles"></app-overflow-form>
  </ng-template>
</p-overlayPanel>
