import { Pipe, PipeTransform } from '@angular/core';
import { EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WithEmptyEnumOption } from '@shared/methods/shared.methods';

@Pipe({ name: 'withEmptySelectOption' })
export class WithEmptyEnumOptionPipe implements PipeTransform {
  transform(source: EnumeratedOption[], multipleOptions: boolean): EnumeratedOption[] {
    return WithEmptyEnumOption(source, multipleOptions);
  }
}
