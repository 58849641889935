<app-apex-chart-widget-settings *ngIf="!isLayoutMode" [m]="m" [s]="s"></app-apex-chart-widget-settings>
<div *ngIf="isLayoutMode" class="wrapper">
    <div [id]="m.id" class="chart-cont">
        <apx-chart
            *ngIf="m.chartOptions"
            [series]="m.chartOptions.series"
            [chart]="m.chartOptions.chart"
            [dataLabels]="m.chartOptions.dataLabels"
            [plotOptions]="m.chartOptions.plotOptions"
            [yaxis]="m.chartOptions.yaxis"
            [xaxis]="m.chartOptions.xaxis"
            [legend]="m.chartOptions.legend"
            [colors]="m.chartOptions.colors"
            [grid]="m.chartOptions.grid"
            [title]="m.chartOptions.title"
            [subtitle]="m.chartOptions.subtitle"
            [noData]="m.chartOptions.noData"
        ></apx-chart>
    </div>
</div>
