<p-table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th pResizableColumn class="p-text-truncate">
          {{ col.label }}
          <p-sortIcon *ngIf="col.key === EMAIL_VALUE" [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>

          <p-columnFilter *ngIf="col.key === TENANT_VALUE"
                          [field]="col.key + '.id'"
                          [showMatchModes]="false"
                          [showOperator]="false"
                          [showAddButton]="false"
                          matchMode="in"
                          display="menu">
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="p-text-bold">{{ col.key }}</span>
              </div>
            </ng-template>

            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="tenantOptions" placeholder="Any" (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <span class="ml-1">{{ option.label }}</span>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngIf="col.key === EMAIL_VALUE"
            type="text"
            matchMode="contains"
            [field]="col.key"
            display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          >
          </p-columnFilter>

          <p-columnFilter *ngIf="col.key === IS_SYSTEM_ADMIN_VALUE || col.key === IS_TENANT_ADMIN_VALUE"
                          [field]="col.key"
                          [showOperator]="false"
                          [showMatchModes]="false"
                          [showAddButton]="false"
                          [showClearButton]="false"
                          [showApplyButton]="false"
                          display="menu" matchMode="equals">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-triStateCheckbox (onChange)="filter($event.value)" [ngModel]="value"></p-triStateCheckbox>
              Is {{col.key === IS_SYSTEM_ADMIN_VALUE ? 'system ' : 'tenant ' }} admin
            </ng-template>
          </p-columnFilter>
        </th>
      </ng-container>
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <div *ngIf="col.key !== IS_SYSTEM_ADMIN_VALUE &&  col.key !== IS_TENANT_ADMIN_VALUE else booleanColumn" class="p-text-truncate">
          <span [ngClass]="{ red: !!user.deleted, green: !user.deleted }">{{ user | printSystemUserToTable : col.key : tenants.listMap }}</span>
        </div>

        <ng-template #booleanColumn>
          <div class="readonly-checkbox">
            <p-checkbox *ngIf="col.key === IS_TENANT_ADMIN_VALUE && user.tenant" [(ngModel)]="user.tenant.isAdmin" binary="true"></p-checkbox>
            <p-checkbox *ngIf="col.key === IS_SYSTEM_ADMIN_VALUE" [(ngModel)]="user.isSystemAdmin" binary="true"></p-checkbox>
          </div>
        </ng-template>
      </td>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/system', 'user', user.id]">
            <button pButton class="p-button p-button-warning" type="button" [label]="'Edit' | translate" icon="pi pi-pencil" iconPos="left"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
