import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { AttributeCreateRequestDto } from '@api/models/attribute-create-request-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { TenantAttributeService } from '@api/services/tenant-attribute.service';
import { AttributeComponent } from '@private/pages/artifact-type-management/attribute/attribute.component';
import { AttributesModel } from '@private/pages/artifact-type-management/attribute/types/attribute.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { cloneDeep, isEqual } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AttributeService extends CoreService<AttributeComponent, AttributesModel> {
  constructor(
    public router: Router,
    private readonly blockUiService: BlockUiService,
    public readonly confirmationService: ConfirmationService,
    public readonly translateUtil: TranslateUtil,
    private readonly tenantAttributeService: TenantAttributeService,
    private readonly cache: NewCacheService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super();
  }

  public init(context: AttributeComponent, model: AttributesModel): void {
    super.init(context, model);
    this.registerSubscriptions();

    setTimeout(async () => {
      try {
        this.m.inProgress = true;
        const attribute = await this.cache.data.attributes.getAsync(this.c.urlParams.id);
        this.setAttribute(new NewAttribute(attribute));
        this.setOriginalObject<NewAttribute>(this.m.attribute);
      } finally {
        this.m.inProgress = false;
      }
    });
  }

  setAttribute(attribute: NewAttribute = new NewAttribute()): void {
    this.m.attribute = attribute;
    this.m.isDeleted = !!attribute.deleted;
  }

  public async save(): Promise<void> {
    let attribute = null;
    this.blockUiService.blockUi();

    try {
      if (this.m.attribute) {
        if (this.m.attribute.id) {
          const { id } = this.m.attribute;
          attribute = await lastValueFrom(
            this.tenantAttributeService.attributeControllerUpdate({ body: { id, ...this.getChangedDataFromOriginalObject<NewAttribute>(this.m.attribute) } }),
          );
        } else {
          const {
            applicationId,
            description,
            name,
            alias,
            icon,
            uri,
            dataTypeId,
            multipleValues,
            counterFormat,
            counterNextValue,
            private: privateAttribute,
          } = this.m.attribute;
          const body: AttributeCreateRequestDto = {
            applicationId: applicationId || this.applicationSwitcherService.selectedApplication?.id || '',
            description,
            name,
            alias,
            icon,
            uri,
            dataTypeId: dataTypeId || '',
            multipleValues,
            private: privateAttribute,
          };
          if (!!this.m.attribute && !!this.m.attribute.dataTypeId && this.m.dataTypes.listMap[this.m.attribute?.dataTypeId]?.isCounter) {
            !!counterFormat && (body.counterFormat = counterFormat);
            !!counterNextValue && (body.counterNextValue = counterNextValue);
          }
          attribute = await this.tenantAttributeService.attributeControllerCreate({ body }).toPromise();
        }
      }
      if (attribute) {
        this.cache.data.attributes.setItem(attribute);
        this.router.url === '/admin/attribute' ? await this.c.router.navigateByUrl(`/admin/attribute/${attribute.id}`) : await this.cancel();
      }
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/attribute-list');
  }

  async deleteWithConfirmation(attribute: NewAttribute, callback: (() => void) | null = null): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + attribute.name + '?',
      acceptLabel,
      rejectLabel,
      accept: () =>
        this.delete(attribute.id).then(() => {
          callback ? callback() : this.cancel();
        }),
    });
  }

  async delete(id: string): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      const success = await this.tenantAttributeService.attributeControllerDelete({ id }).toPromise();
      if (success) {
        await this.cancel();
      }
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  public checkIfAttributeChanged(): boolean {
    const target = this.m.attribute?.id ? this.m.attributes.find(item => item.id === this.m.attribute?.id) : new NewAttribute();
    return !isEqual(this.m.attribute, target);
  }

  public async confirmBeforeAttributeChange(accept: () => void, reject: () => void): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll([
      'Change attribute',
      "You have unsaved changes. Are you sure you don't want to save them?",
      'Yes',
      'No',
    ]);
    this.confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => accept && accept(),
      reject: () => reject && reject(),
    });
  }

  public async confirmBeforeDelete(accept: () => void, reject: () => void): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll([
      'Delete attribute',
      'Are you sure you want to permanently remove this item?',
      'Yes',
      'No',
    ]);
    this.confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => accept && accept(),
      reject: () => reject && reject(),
    });
  }

  public compareBeforeChange(): void {
    if (!this.checkIfAttributeChanged()) {
      this.m.attribute = cloneDeep(this.m.attributeCopy);
      this.setOriginalObject<NewAttribute>(this.m.attribute);
    } else {
      this.confirmBeforeAttributeChange(
        () => {
          this.m.attribute = cloneDeep(this.m.attributeCopy);
          this.setOriginalObject<NewAttribute>(this.m.attribute);
        },
        () => {
          this.m.attributeCopy = this.m.attribute?.id ? this.m.attributes.find(item => item.id === this.m.attribute?.id) || null : new NewAttribute();
        },
      );
    }
  }

  public checkBeforeNavigation(url: string): void {
    if (this.checkIfAttributeChanged()) {
      this.confirmBeforeAttributeChange(
        () => {
          this.c.canDeactivate = true;
          this.m.attribute = cloneDeep(this.m.attributeCopy);
          this.setOriginalObject<NewAttribute>(this.m.attribute);
          this.c.router.navigateByUrl(url);
        },
        () => (this.c.canDeactivate = false),
      );
    } else {
      if (!this.c.canDeactivate) {
        this.c.canDeactivate = true;
        this.c.router.navigateByUrl(url);
      }
    }
  }

  private registerSubscriptions(): void {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      data.attributes.subscribe(attributes => (this.m.attributes = attributes!.map(dto => new NewAttribute(dto as AttributeResponseDto)))),
      data.applications.subscribe(applications => (this.m.applications = applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)))),
      data.dataTypes.subscribe(dataTypes =>
        this.m.dataTypes.setList(
          dataTypes!.map(dto => new NewDataType(dto as DataTypeResponseDto)),
          'id',
        ),
      ),
    ]);
  }
}
