import { Pipe, PipeTransform } from '@angular/core';
import { WithEmptySelectOption } from '@shared/methods/shared.methods';
import { SelectOption } from '../../types/shared.types';

@Pipe({ name: 'withEmptySelectOption' })
export class WithEmptySelectOptionPipe implements PipeTransform {
  transform(source: SelectOption<string, any>[], multipleOptions: boolean): SelectOption<string, any>[] {
    return WithEmptySelectOption(source, multipleOptions);
  }
}
