import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewDataType } from '@shared/types/data-type.types';

@Pipe({ name: 'canHaveInitialValue' })
export class CanHaveInitialValuePipe implements PipeTransform {
  transform(dataType: NewDataType): boolean {
    const { date, dateTime, user, text, html } = BaseDataType;
    return !!dataType.baseDataType && [date, dateTime, user, text, html].includes(dataType.baseDataType);
  }
}
