import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkFilterEnum } from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { LinkFilterTypes } from '@widgets/shared/components/artifact-filters/components/link-filter/types/link-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-link-filter',
  templateUrl: './link-filter.component.html',
  styleUrls: ['./link-filter.component.scss'],
})
export class LinkFilterComponent {
  @Input() set m(data: ArtifactFilter) {
    !data.value && (data.value = new LinkFilterTypes());
    this.model = data.value;
  }

  @Output() onFilterChange: EventEmitter<void> = new EventEmitter<void>();

  model: LinkFilterTypes;

  readonly filterVariantOptions: SelectOption<string, LinkFilterEnum, null>[];

  constructor() {
    this.filterVariantOptions = Object.keys(LinkFilterEnum).map((key: string) => {
      const value = LinkFilterEnum[key as keyof typeof LinkFilterEnum];
      const disabled = value !== LinkFilterEnum.containsUrlParamKey;

      return new SelectOption(key, value, null, disabled);
    });
  }

  get isUrlKeyInputVisible(): boolean {
    return this.model.variant === LinkFilterEnum.containsUrlParamKey;
  }

  onChange(): void {
    this.onFilterChange.emit();
  }
}
