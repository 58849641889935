import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowConditionType } from '../index';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export type stringOrNumber = string | number;
export type arrayOfStringOrNumber = (string | number)[];
export type enumeratedValue = stringOrNumber | arrayOfStringOrNumber;

export class WorkflowIncludesCondition extends AbstractWorkflowCondition {
  constructor() {
    super();
    this.type = WorkflowConditionType.INCLUDES;
    this.supportedTypes = [BaseDataType.text, BaseDataType.integer, BaseDataType.user];
    this.supportedKinds = [DataTypeKind.simple, DataTypeKind.enumerated];
  }

  // use for enums
  isMet(source: enumeratedValue, value: enumeratedValue): boolean {
    return Array.isArray(source) ? this.isMetForSourceMulti(source, value) : this.isMetForSourceSingle(source, value);
  }

  private isMetForSourceMulti(source: arrayOfStringOrNumber, value: enumeratedValue): boolean {
    if (Array.isArray(value)) {
      return value.every(value => source.includes(value));
    }

    return source.includes(value as stringOrNumber);
  }

  private isMetForSourceSingle(source: stringOrNumber, value: enumeratedValue): boolean {
    if (Array.isArray(value)) {
      return false;
    }

    return source === value;
  }
}
