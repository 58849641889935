<div class="template-layout">
  <div class="template-sidebar">
    <p-menu class="dynamic-label" [model]="categoryMenuItems"></p-menu>
    <span class="dynamic-label">
      <p-dropdown
        [(ngModel)]="selectedApplicationOption"
        (ngModelChange)="onApplicationChange()"
        [options]="applicationOptions"
        id="applicationPicker"
      ></p-dropdown>
      <label class="dynamic-label-text" for="applicationPicker" translate>Application</label>
    </span>
    <span *ngIf="selectedTemplateType | shouldShowWidgetTypeFilter" class="dynamic-label">
      <p-dropdown
        [(ngModel)]="selectedWidgetType"
        (ngModelChange)="onWidgetTypeChange($event)"
        [options]="widgetTypeOptions"
        [filter]="true"
        [autoDisplayFirst]="false"
        showClear="true"
        id="widgetTypePicker"
        inputId="widgetTypePicker"
      ></p-dropdown>
      <label class="dynamic-label-text" for="widgetTypePicker" translate>Widget type</label>
    </span>
  </div>
  <div class="template-content">
    <p-tabMenu *ngIf="!hideTemplateTypePicker" [model]="templateTypesMenuItems" [activeItem]="currentTemplateType">
      <ng-template pTemplate="item" let-item>
        <span class="menuitem-text ng-star-inserted">{{ item.label | translate }}</span>
      </ng-template>
    </p-tabMenu>
    <p-dataView
      #dv
      (onLazyLoad)="onLazyLoad($event)"
      [lazy]="true"
      [loading]="loading"
      [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
      [rowsPerPageOptions]="meta.limitOptions"
      [rows]="meta.limit"
      [totalRecords]="meta.totalCount"
      [value]="data"
      layout="grid"
    >
      <ng-template pTemplate="header">
        <div class="templates-header">
          <div class="p-inputgroup">
            <input
              #searchTemplate
              type="search"
              pInputText
              placeholder="{{ 'Search by Name' | translate }}"
              (keyup.enter)="searchTemplateName()"
              (input)="$event.target.value === '' && searchTemplateName()"
            />
            <button type="button" pButton pRipple icon="pi pi-search" styleClass="p-button-warn" (click)="searchTemplateName()"></button>
          </div>
        </div>
      </ng-template>
      <ng-template let-template pTemplate="gridItem">
        <ng-container *ngIf="template.id === 'UPLOAD_BUTTON'; then uploadButton; else templateItem"></ng-container>

        <ng-template #uploadButton>
          <div class="template-item">
            <div class="template-item-inner template-custom-upload">
              <b style="font-size: 1.2rem">Upload from file</b>
              <button class="p-button-rounded p-button-success" icon="pi pi-cloud-upload" pButton type="button"></button>
              <p-fileUpload
                #importField
                mode="basic"
                accept=".yaml"
                maxFileSize="100000000"
                [auto]="true"
                [fileLimit]="1"
                [customUpload]="true"
                (uploadHandler)="templatesService.detailFile($event, importField)"
              ></p-fileUpload>
            </div>
          </div>
        </ng-template>
        <ng-template #templateItem>
          <div class="template-item">
            <div class="template-item-inner">
              <div class="{{ template.description ? 'image-with-desc' : 'image' }}">
                <app-image-loader [artifactId]="template.thumbnailFileArtifactId"></app-image-loader>
                <p *ngIf="template.description">{{ template.description }}</p>
              </div>
              <div class="info">
                <div class="texts">
                  <strong>{{ template.name }}</strong>
                </div>
                <div class="buttons" *ngIf="templatesService.m.templateType === 'external'">
                  <button (click)="templatesService.onDetail(template)" class="p-button-rounded p-button-info" icon="pi pi-eye" pButton type="button"></button>
                </div>
                <div class="buttons" *ngIf="templatesService.m.templateType === 'internal'">
                  <ng-container *ngIf="useSingleButtonToPickTemplate; else multiButton">
                    <button
                      (click)="templatesService.onReuse(template)"
                      class="p-button-rounded p-button-warning"
                      icon="bi bi-plus"
                      pButton
                      type="button"
                    ></button>
                  </ng-container>
                  <ng-template #multiButton>
                    <button
                      (click)="templatesService.onReuse(template)"
                      class="p-button-rounded p-button-warning"
                      icon="bi bi-recycle"
                      pButton
                      type="button"
                    ></button>
                    <button
                      (click)="templatesService.onCopy(template)"
                      class="p-button-rounded p-button-secondary"
                      icon="pi pi-copy"
                      pButton
                      type="button"
                    ></button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </p-dataView>
  </div>
</div>
