import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RuleDataHolderService } from '@workflows/services';
import { RuleTriggerType, WorkflowRule } from '@workflows/types';
import { ScheduleWorkflowKeys, WorkflowActionScheduleWorkflow } from '@workflows/types/actions/action-schedule-workflow';
import { ManualTriggerInputData, ManualTriggerSettingFirst, WorkflowTriggerManualDto } from '@workflows/types/triggers/trigger-manual';

@Component({
  selector: 'app-action-schedule-workflow',
  templateUrl: './action-schedule-workflow.component.html',
  styleUrls: ['./action-schedule-workflow.component.scss'],
})
export class ActionScheduleWorkflowComponent implements OnInit {
  @Input() action: WorkflowActionScheduleWorkflow;

  schedulableWorkflows: WorkflowRule[] = [];
  protected readonly RuleKeys = ScheduleWorkflowKeys;

  constructor(private readonly ruleDataHolder: RuleDataHolderService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.schedulableWorkflows = this.ruleDataHolder.getSchedulableRules();
      this.cdr.detectChanges();
    }, 500); // TODO: fix this when have time
  }

  onIsDateDynamicChange(): void {
    this.action.scheduledDate = null;
    this.action.actionSettings[ScheduleWorkflowKeys.date].value = '';
  }

  onIsArrayChange(isArray: boolean, index: number): void {
    this.action.workflowClientInputDataArray[index].value = isArray ? [''] : '';
  }

  onAddInputData(index: number): void {
    (this.action.workflowClientInputDataArray[index].value as string[]).push('');
  }

  onRemoveInputData(dataIndex: number, valueIndex: number): void {
    (this.action.workflowClientInputDataArray[dataIndex].value as string[]).splice(valueIndex, 1);
  }

  onAddKey(): void {
    this.action.workflowClientInputDataArray.push({ key: '', value: '', isNull: false, isArray: false });
  }

  onRemoveKey(index: number): void {
    this.action.workflowClientInputDataArray.splice(index, 1);
  }

  onWorkflowIdChange(id: string): void {
    const selectedRule = this.ruleDataHolder.getRuleById(id);
    if (selectedRule.triggers[0].type === RuleTriggerType.MANUAL) {
      this.predefineInputData((selectedRule.triggers[0].triggerSettings as WorkflowTriggerManualDto).inputData.value as ManualTriggerInputData);
    }
  }

  inputDataTrackByFn(index: number): number {
    return index;
  }

  private predefineInputData(inputData?: ManualTriggerInputData): void {
    if (!inputData) return;

    this.action.workflowClientInputDataArray = Object.entries(inputData).map(([key, value]) => ({
      key,
      value: value[0] === ManualTriggerSettingFirst.array ? [''] : '',
      isArray: value[0] === ManualTriggerSettingFirst.array,
      isNull: false,
    }));
  }
}
