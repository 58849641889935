import { Pipe, PipeTransform } from '@angular/core';
import { NewTableColumn } from '@shared/types/table.types';
import { ListWidgetTableLoadModeEnum } from '../types/list-widget-settings.types';
import { ListWidgetModel } from '../types/list-widget.types';

@Pipe({ name: 'shouldShowColumnOptions' })
export class ShouldShowColumnOptionsPipe implements PipeTransform {
  transform(model: ListWidgetModel, applicableColumns: NewTableColumn[], folderPath: string): boolean {
    const { byArtifactType, byFolder, byModule } = ListWidgetTableLoadModeEnum;
    if (model.settings.loadMode === byArtifactType) return !!model.selected.artifactTypes.length && !!applicableColumns?.length;
    else if (model.settings.loadMode === byFolder) return !!folderPath.length;
    else if (model.settings.loadMode === byModule) return true;
    return false;
  }
}
