import { Injectable } from '@angular/core';
import { NonAttributeKeys } from '@shared/types/attribute.types';

@Injectable({
  providedIn: 'root',
})
export class ArtifactFilterHelper {
  getKeyByLabel(label: NonAttributeKeys): string {
    const map = {
      [NonAttributeKeys.CREATED_ON_LABEL]: NonAttributeKeys.CREATED_ON,
      [NonAttributeKeys.CREATED_BY_LABEL]: NonAttributeKeys.CREATED_BY,
      [NonAttributeKeys.UPDATED_ON_LABEL]: NonAttributeKeys.UPDATED_ON,
      [NonAttributeKeys.UPDATED_BY_LABEL]: NonAttributeKeys.UPDATED_BY,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return map[label];
  }
}
