import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

export class DateTimeCalendarSettingsTypes {
  timeOnly = false;
  showTime = false;

  constructor(type?: BaseDataType) {
    this.init(type);
  }

  private init(type?: BaseDataType): void {
    if (!type || type === BaseDataType.dateTime) {
      this.showTime = true;
    } else if (type === BaseDataType.time) {
      this.timeOnly = true;
    }
  }
}
