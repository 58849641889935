export class Exception {
  readonly name: string;
  readonly message: string;
  readonly originalEvent: any;

  constructor(e: Exception) {
    e.name && (this.name = e.name);
    e.message && (this.message = e.message);
    e.originalEvent && (this.originalEvent = e.originalEvent);
  }
}
