<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'IDs' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.ids].isDynamic"
      id="isDynamicIds"
      binary="true"
      class="mr-2 pt-2"
      (ngModelChange)="onIsDynamicIdChange($event)"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngIf="action.actionSettings[RuleKeys.ids].isDynamic; else nonDynamicIds">
      <input
        id="dynamicIdInput"
        [(ngModel)]="action.actionSettings[RuleKeys.ids].value"
        pInputText
        type="text"
        class="w-100"
        [placeholder]="'Enter dynamic id' | translate"
      />
    </div>

    <ng-template #nonDynamicIds>
      <div *ngFor="let id of action.actionSettings[RuleKeys.ids].value; let index = index; trackBy: idsToTrackByFn" class="mb-1 d-flex">
        <input
          [id]="'dynamicIdInput' + index"
          [(ngModel)]="action.actionSettings[RuleKeys.ids].value![index]"
          pInputText
          type="text"
          class="w-100"
          [placeholder]="'Enter id ' + (index + 1) | translate"
        />
        <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddId(index)"></button>
        <button
          class="p-button p-button-text p-button-danger"
          pButton
          type="button"
          icon="pi pi-minus"
          (click)="onRemoveId(index)"
          [ngClass]="{ 'visibility-hidden': action.actionSettings[RuleKeys.ids].value.length <= 1 }"
        ></button>
      </div>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Use hard delete' | translate }}
  </div>
  <div class="col-8">
    <p-checkbox [(ngModel)]="action.hardDelete" id="hardDelete" binary="true" class="mr-2 pt-2"></p-checkbox>
  </div>
</div>
