import { WidgetModel } from '../../shared/types/widget-model.interface';
import { SidebarModalSettings, SidebarModalWidgetSettingsDto } from './sidebar-modal-widget-settings.types';
import { SidebarModalOptions } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-options.types';
import { SidebarModalStyles } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-styles.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { TemplatesCloseResult } from '@shared/components/templates/types/templates.types';
import { PageSectionRequestDto } from '@api/models/page-section-request-dto';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { cloneDeep } from 'lodash';

export class SidebarModalWidgetValue {
  constructor(public model: SidebarModalWidgetModel = new SidebarModalWidgetModel()) {}
}

export class SidebarModalWidgetModel implements WidgetModel<SidebarModalWidgetModelDto> {
  settings = new SidebarModalSettings();
  options = new SidebarModalOptions();
  styles = new SidebarModalStyles();
  isOpen: boolean;
  isHover: boolean;
  loading: boolean;
  toggled: boolean;
  id: string;
  page: Page = new Page();

  constructor(dto?: SidebarModalWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): SidebarModalWidgetModelDto {
    const sections: PageSectionRequestDto[] = this.page.sections.map(s => s.requestDto) || [];
    return {
      settings: this.settings.toServer(),
      page: { sections },
    };
  }

  fromDto(dto: SidebarModalWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new SidebarModalSettings(dto.settings));
      dto.page && (this.page.sections = dto.page.sections as PageSection[]);
    }
  }

  copy(): SidebarModalWidgetModelDto {
    const page = cloneDeep(this.page);

    page.sections.forEach(s => {
      s.partsWithWidgets.forEach(part => {
        if (part.widget?.code === WidgetType.card) {
          part.widget.value.model = part.widget.value.model.copy();
        }
      });
    });

    return {
      settings: this.settings.toServer(),
      page,
    };
  }
}

export interface SidebarModalWidgetModelDto {
  settings: SidebarModalWidgetSettingsDto;
  page: { sections: PageSectionRequestDto[] | PageSection[] };
}

export interface WidgetToModal {
  location: PartLocation;
  code: WidgetType;
}

export interface TemplateToModal {
  location: PartLocation;
  templateResult: TemplatesCloseResult;
}

export enum SidebarModalPosition {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  center = 'center',
}

export enum ButtonState {
  Hover = 'Hover',
  NoHover = 'NoHover',
}

export enum SidebarModalState {
  Open = 'open',
  Close = 'close',
}

export enum SizeType {
  percent = '%',
  px = 'px',
  em = 'em',
  rem = 'rem',
  pt = 'pt',
  in = 'in',
}
