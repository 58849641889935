<span class="dynamic-slider" *ngIf="dataType">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <span class="flex pb-2">
    <span class="prefix"> {{ formatSettings.value.fieldStyles.prefix }}</span>
    <span *ngIf="attribute.multipleValues">[{{ attr.value[0] + ' - ' + attr.value[1] }}]</span>
    <span *ngIf="!attribute.multipleValues">{{ attr.value }}</span>
    <span class="suffix"> {{ formatSettings.value.fieldStyles.suffix }}</span>
  </span>
  <p-slider [(ngModel)]="attr.value"
            (onChange)="onChange()"
            [id]="'rangeField' + index"
            [max]="+dataType.maximum"
            [min]="+dataType.minimum"
            [range]="attribute.multipleValues"
            [step]="dataType.isInteger ? 1 : 0.1">
  </p-slider>
  <app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                     [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'rangeField' + index"
                                                     [canContainPlaceholder]="false">
  </app-artifact-attribute-form-field-label-with-icon>
</span>
