import { ScheduleWorkflowActionWorkflowSettingsDto } from '@api/models/schedule-workflow-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { ConvertToServerDatetime } from '@shared/methods/date.methods';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export type WorkflowInputDataValue = string | string[] | null;
export type WorkflowInputData = Record<string, WorkflowInputDataValue>;

export interface WorkflowClientInputData {
  key: string;
  value: WorkflowInputDataValue;
  isNull: boolean;
  isArray: boolean;
}

export enum ScheduleWorkflowKeys {
  date = 'date',
  workflowId = 'workflowId',
  inputDataValue = 'inputDataValue',
  forEachPath = 'forEachPath',
  waitForResult = 'waitForResult',
}

export class WorkflowActionScheduleWorkflowDto implements ScheduleWorkflowActionWorkflowSettingsDto {
  date: ScheduleWorkflowActionWorkflowSettingsDto[ScheduleWorkflowKeys.date] = { value: '', isDynamic: false };
  workflowId: ScheduleWorkflowActionWorkflowSettingsDto[ScheduleWorkflowKeys.workflowId] = { value: '', isDynamic: false };
  inputDataValue: ScheduleWorkflowActionWorkflowSettingsDto[ScheduleWorkflowKeys.inputDataValue] = { value: {}, isDynamic: false };
  forEachPath: ScheduleWorkflowActionWorkflowSettingsDto[ScheduleWorkflowKeys.forEachPath] = { value: '', isDynamic: false };
  waitForResult: ScheduleWorkflowActionWorkflowSettingsDto[ScheduleWorkflowKeys.waitForResult] = { value: 'false', isDynamic: false };

  constructor(action?: WorkflowActionScheduleWorkflowDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionScheduleWorkflow extends AbstractWorkflowAction<WorkflowActionScheduleWorkflowDto> {
  workflowClientInputDataArray: WorkflowClientInputData[] = [];
  scheduledDate: Date | null = null;
  waitForResult = false;

  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionScheduleWorkflowDto, type: WorkflowActionType.SCHEDULE_WORKFLOW, dto });
    if (dto) {
      this.fromDto(dto);
    }
  }

  canBeExecuted(): boolean {
    return false;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    const inputDataValue: WorkflowInputData = this.workflowClientInputDataArray.reduce(
      (inputDataMap: WorkflowInputData, inputData: WorkflowClientInputData) => ({
        ...inputDataMap,
        [inputData.key.replace(/ /g, '_')]: inputData.isNull ? null : inputData.value,
      }),
      {},
    );

    const inputData = { isDynamic: this.actionSettings.inputDataValue.isDynamic, value: inputDataValue };
    const dateActionSettings = {
      isDynamic: this.actionSettings.date.isDynamic,
      value: this.actionSettings.date.isDynamic ? this.actionSettings.date.value : ConvertToServerDatetime(this.scheduledDate || new Date()),
    };
    const forEachPathSettings = {
      isDynamic: false,
      value: this.actionSettings.forEachPath.value?.length ? this.actionSettings.forEachPath.value : null,
    };
    const waitForResultActionSettings = {
      isDynamic: false,
      value: this.waitForResult ? 'true' : 'false',
    };

    const actionSettings = {
      ...this.actionSettings,
      [ScheduleWorkflowKeys.date]: dateActionSettings,
      [ScheduleWorkflowKeys.inputDataValue]: inputData,
      [ScheduleWorkflowKeys.forEachPath]: forEachPathSettings,
      [ScheduleWorkflowKeys.waitForResult]: waitForResultActionSettings,
    };

    return {
      type: this.type,
      alias: this.alias,
      actionSettings,
    };
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      if (!(dto.actionSettings as ScheduleWorkflowActionWorkflowSettingsDto).date.isDynamic) {
        this.scheduledDate = new Date((dto.actionSettings as ScheduleWorkflowActionWorkflowSettingsDto).date.value as string);
      }

      this.waitForResult = (dto.actionSettings as ScheduleWorkflowActionWorkflowSettingsDto)[ScheduleWorkflowKeys.waitForResult].value === 'true';

      const inputData = (dto.actionSettings as ScheduleWorkflowActionWorkflowSettingsDto).inputDataValue?.value || ({} as WorkflowInputData);
      this.workflowClientInputDataArray = Object.entries(inputData).map(([key, value]) => ({
        key,
        value,
        isNull: value === null,
        isArray: Array.isArray(value),
      }));
    }
  }
}
