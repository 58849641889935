import { NewApplication } from '@shared/types/application.types';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class ArtifactTypesListModel {
  rowsPerPage: number;
  rowsPerPageOptions: number[] = [];
  columns: TableColumn[] = [];
  applications = new ListContainer<NewApplication>();
  applicationOptions: SelectOption<string, string>[] = [];
  artifactTypeFormatOptions: SelectOption<string, ArtifactTypeFormatEnum>[] = [];

  constructor(model?: Partial<ArtifactTypesListModel>) {
    model && Object.assign(this, model);
  }
}
