import { Component, Input, ViewChild } from '@angular/core';
import { LinkRequestDto, SelfUserResponseDto } from '@api/models';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { Environment } from '@environments/environment';
import { Link, LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewCoreListComponent } from '@shared/core/components/new-core-list.component';
import { ListMetaData } from '@shared/core/types/core.types';
import { LinkMethods } from '@shared/methods/link.methods';
import { BaseFolderFilterService } from '@shared/services/filter/filter-types/base-folder-filter.service';
import { BaseNumericFilterService } from '@shared/services/filter/filter-types/base-numeric-filter.service';
import { ArtifactLinkService } from '@shared/services/links/artifact-link.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction, NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { ComplexFilterMetadata, NewTableColumn, NewTableColumnAttributeMetadata, NewTableColumnMetaData } from '@shared/types/table.types';
import { NewUser } from '@shared/types/user.types';
import { DateUtil } from '@shared/utils/date.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { LazyLoadEvent } from 'primeng/api';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { RuntimeStateNotificationService } from '../../services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '../../types/runtime-state-notification.types';

@Component({
  selector: 'app-new-artifact-link-dialog',
  templateUrl: './artifact-link-dialog.component.html',
  styleUrls: ['./artifact-link-dialog.component.scss'],
})
export class ArtifactLinkDialogComponent extends NewCoreListComponent<NewArtifact> {
  @Input() column: NewTableColumn;
  @Input() linkTypes: ListContainer<LinkType>;
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() artifactTypes: ListContainer<NewArtifactType>;
  @Input() restrictions: Record<string, ArtifactTypeLinkRestriction[]>;
  @Input() users: ListContainer<NewUser>;

  @Input() originArtifact: NewArtifact;
  @Input() usersOptions: SelectOption<string, string>[];
  @Input() artifactLinksMap: Record<string, Link[]>;

  @Input() parentData: any[];
  @Input() isTable: boolean;
  @Input() successCb: () => void | Promise<void>;
  @Input() applicationId: string;
  @Input() linkTypeIds: Set<string> | null = null;
  @Input() hash: string;
  @ViewChild('table') table: Table;

  linkTypeName = '';

  attributeColumns = new ListContainer<NewTableColumn>();
  columns: NewTableColumn[] = [];
  originArtifactTypes: SelectOption<string, NewArtifactType>[];

  displayModal = false;
  dateFormat = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;

  selectedLinkType: LinkType | null = null;
  selectedArtifactType: NewArtifactType | null = null;

  userAttributesMap: Record<string, boolean> = {};

  sortableColumnKeys: string[] = [];
  editableColumnKeys: string[] = [];
  enumeratedFilterOptions: Record<string, any> = {};
  enumeratedMultipleValues: Record<string, any> = {};

  attributeIds: string[] = [];

  targetArtifact: any = null;
  restriction: ArtifactTypeLinkRestriction | undefined;
  relevantRestrictions: ArtifactTypeLinkRestriction[] | undefined;
  targetIds: string[] = [];
  artifactRowRelevantLinks: NewLink[] | null;
  disableSaveDueToSingleRestrictions = false;
  isMultiSelectionMode: boolean;

  constructor(
    dateUtil: DateUtil,
    private readonly artifactLinkService: ArtifactLinkService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    protected readonly cache: NewCacheService,
    protected readonly baseNumericFilter: BaseNumericFilterService,
    protected readonly baseFolderFilter: BaseFolderFilterService,
    private readonly filterUtil: FilterUtil,
    private readonly tenantLinkService: TenantLinkService,
  ) {
    super(cache, baseNumericFilter, baseFolderFilter, dateUtil);
  }

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  async onLazyLoad(event: LazyLoadEvent | null = null): Promise<void> {
    // await this.sleep();
    this.loading = true;
    try {
      if (event) {
        const { rows, first, filters } = event;
        const { sortField, sortOrder } = event;
        this.addExcludeLinkedArtifactFilter(filters as Record<string, FilterMetadata[]>);
        const complexFiltersData = this.transformFiltersToComplexData(filters as Record<string, FilterMetadata[]>);
        this.meta = new ListMetaData(
          rows,
          first,
          // this.processFilters(filters as Record<string, FilterMetadata[]>),
          this.processFilters(complexFiltersData),
          this.transformSorting(sortField, sortOrder),
          this.meta.totalCount,
        );
        const { meta, data } = await this.loadDataMethod(this.meta.toQuery());
        this.data = data;
        this.meta.totalCount = meta.totalCount;

        this.data = data.map(artifact => new NewArtifact({ dto: artifact, artifactTypesMap: this.artifactTypes.listMap }));

        this.data.forEach(artifact => {
          artifact.created.by = this.users.listMap[artifact.created.by].created.by;
          artifact.updated.by = this.users.listMap[artifact.updated.by].updated.by;
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => (this.loading = false));
    }
  }

  transformFiltersToComplexData(filters: Record<string, FilterMetadata[]>): Record<string, ComplexFilterMetadata> {
    const result: Record<string, ComplexFilterMetadata> = {};
    filters &&
      Object.keys(filters).forEach(key => {
        result[key] = { filterMetadata: filters[key], tableColumnMetadata: this.attributeColumns.listMap[key]?.meta || new NewTableColumnMetaData() };
      });
    return result;
  }

  open(): void {
    const artifactTypeId = this.originArtifact.artifactTypeId;
    const linkTypeId = this.column.meta.linkRestrictionParams?.linkTypeId;
    const linkDirection = this.column.meta.linkRestrictionParams?.direction;
    const relevantRestrictions = this.restrictions[artifactTypeId].filter(
      restriction =>
        restriction.linkType?.value === this.column.meta.linkRestrictionParams?.linkTypeId &&
        (this.isOutgoing() ? restriction.sourceArtifactTypeIds.includes(artifactTypeId) : restriction.destinationArtifactTypeIds.includes(artifactTypeId)),
    );
    this.restriction = relevantRestrictions && relevantRestrictions[0];

    if (this.isIncoming(this.restriction?.linkType?.meta)) {
      relevantRestrictions?.map(restriction => {
        this.targetIds = this.targetIds.concat(restriction.sourceArtifactTypeIds);
      });
    } else if (this.restriction?.destinationArtifactTypeIds) {
      relevantRestrictions?.map(restriction => {
        this.targetIds = this.targetIds.concat(restriction.destinationArtifactTypeIds);
      });
    }
    this.selectedLinkType = (linkTypeId && this.linkTypes.listMap[linkTypeId]) || null;
    this.linkTypeName = '';
    if (linkDirection && this.selectedLinkType) {
      this.linkTypeName = this.isOutgoing() ? this.selectedLinkType.outgoingName : this.selectedLinkType.incomingName;
    }
    this.originArtifactTypes = LinkMethods.getRelevantArtifactOptions(relevantRestrictions, linkDirection as LinkDirection, this.artifactTypes);

    if (this.originArtifactTypes.length === 1) {
      this.selectedArtifactType = this.originArtifactTypes[0].value;
    }
    this.initColumns();
    this.initColumnFilterOptions();
    this.updateMultiSelectionMode();
    this.displayModal = true;
  }

  close(): void {
    this.displayModal = false;
  }

  onDialogHide(): void {
    this.selectedArtifactType = null;
    this.targetArtifact = null;
  }

  initDateFilters(): void {
    const keys = Object.keys(this.table.filters).filter(key => key.includes('date') || key.includes('time'));
    keys.forEach(key => {
      (this.table.filters[key] as any[]).forEach(filter => filter.value && (filter.value = new Date(filter.value)));
    });
  }

  initColumnFilterOptions(): void {
    this.enumeratedFilterOptions = {};
    this.enumeratedMultipleValues = {};

    this.data.forEach(item => {
      this.editableColumnKeys.forEach(key => {
        const clientAttribute = this.getAttribute(item, key);

        if (clientAttribute) {
          const attribute = this.attributes.listMap[clientAttribute.id];
          const dataType = this.dataTypes.listMap[attribute.dataTypeId];

          if (dataType?.kind === DataTypeKind.enumerated && !this.enumeratedFilterOptions[key]) {
            this.enumeratedFilterOptions[key] = dataType?.values;
            this.enumeratedMultipleValues[key] = attribute.multipleValues;
          }
        }
      });
    });
  }

  getAttribute(row: any, key: string): NewClientAttribute {
    return row.attributes && row.attributes[key];
  }

  isDateFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.date;
  }

  isDateTimeFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.dateTime;
  }

  isBooleanFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.boolean;
  }

  getFilterKey(column: NewTableColumn): string {
    return this.filterUtil.getAttributesDbFilterKey(column.key);
  }

  async sleep(timeout = 0): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  }

  async onArtifactTypeChange(event: any): Promise<void> {
    this.setSelectedArtifactType(null);
    this.loading = true;
    await this.sleep();
    this.setSelectedArtifactType(event.value);
    await this.sleep();
    this.initDateFilters();
    this.initColumns();
  }

  setSelectedArtifactType(artifactType: NewArtifactType | null): void {
    this.targetArtifact = null;
    if (this.relevantRestrictions?.length && artifactType) {
      this.restriction = this.relevantRestrictions.find(restriction => {
        const restrictionArtifactTypesIds = this.isOutgoing() ? restriction.destinationArtifactTypeIds : restriction.sourceArtifactTypeIds;
        return restrictionArtifactTypesIds.find(id => id === artifactType?.id);
      });
      this.disableSaveDueToSingleRestrictions = false;
    }
    this.selectedArtifactType = artifactType;
  }

  async create(): Promise<void> {
    try {
      const linkTypeId = this.column.meta.linkRestrictionParams?.linkTypeId as string;

      if (this.isMultiSelectionMode) {
        for await (const artifact of this.targetArtifact) {
          const destinationArtifactId = this.isOutgoing() ? artifact.id : this.originArtifact?.id;
          const sourceArtifactId = this.isOutgoing() ? this.originArtifact?.id : artifact.id;
          await this.createLink(linkTypeId, destinationArtifactId, sourceArtifactId);
        }
      } else {
        const destinationArtifactId = this.isOutgoing() ? this.targetArtifact.id : this.originArtifact?.id;
        const sourceArtifactId = this.isOutgoing() ? this.originArtifact?.id : this.targetArtifact.id;
        await this.createLink(linkTypeId, destinationArtifactId, sourceArtifactId);
      }
      this.successCb && (await this.successCb());
    } catch (e) {
      console.error(e);
    } finally {
      this.close();
    }
  }

  async createLink(linkTypeId: string, destinationArtifactId: string, sourceArtifactId: string): Promise<void> {
    const body: LinkRequestDto = { linkTypeId, destinationArtifactId, sourceArtifactId };

    const newLinkRes = await lastValueFrom(this.tenantLinkService.linkControllerCreate({ body: { links: [body] }, notify: false }));
    if (newLinkRes.meta.errors) throw new Error(newLinkRes.meta.errors[0].errorMessage);
    const newLinkDto = newLinkRes.data[0];

    if (newLinkDto && this.isTable) {
      const affectedRows = this.parentData.filter(artifact => [destinationArtifactId, sourceArtifactId].includes(artifact.id));
      affectedRows.forEach(artifact => {
        const newLink = this.artifactLinkService.dtoToLink(newLinkDto);
        if (newLink.destination) {
          newLink.destination && (newLink.destination.artifact = this.isOutgoing() ? this.targetArtifact : this.originArtifact);
          newLink.destination.artifactType = this.isOutgoing() ? this.selectedArtifactType : this.originArtifactTypes[0].value;
        }
        if (newLink.source) {
          newLink.source.artifact = this.isIncoming() ? this.targetArtifact : this.originArtifact;
          newLink.source.artifactType = this.isIncoming() ? this.selectedArtifactType : this.originArtifactTypes[0].value;
        }
        this.artifactLinksMap[artifact.id].push(newLink);
      });
      this.linkTypeIds && this.linkTypeIds.add(newLinkDto.linkTypeId);
    }

    if (newLinkDto) {
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification(RuntimeStateNotificationEnum.createLink, [destinationArtifactId, sourceArtifactId], this.hash, linkTypeId),
      );
    }
  }

  private isOutgoing(direction: LinkDirection | null = this.column.meta.linkRestrictionParams?.direction as LinkDirection): boolean {
    return direction === LinkDirection.outgoing;
  }

  private isIncoming(direction: LinkDirection | null = this.column.meta.linkRestrictionParams?.direction as LinkDirection): boolean {
    return direction === LinkDirection.incoming;
  }

  private addExcludeLinkedArtifactFilter(filters: Record<string, FilterMetadata[]>): void {
    if (this.artifactRowRelevantLinks) {
      const artifactIdsToExclude = this.artifactRowRelevantLinks.map(link => (this.isOutgoing() ? link.destinationArtifactId : link.sourceArtifactId));
      filters._id = [{ value: artifactIdsToExclude, matchMode: 'notEquals', operator: 'and' }];
    }
  }

  private initColumns(): void {
    const attributeIds = new Set<string>();
    Object.keys(this.selectedArtifactType?.attributes || []).forEach(attributeId => attributeIds.add(attributeId));
    this.attributeIds = [...attributeIds];

    const attributeColumns = this.attributeIds.map(attributeId => {
      const attribute = this.attributes.listMap[attributeId];
      const { id, name, dataTypeId } = attribute;
      const dataType = this.dataTypes.listMap[dataTypeId];
      const baseDataType = dataType?.baseDataType;

      this.userAttributesMap[id] = baseDataType === BaseDataType.user || this.userAttributesMap[id];

      const filterType = this.filterUtil.getFilterTypeByDataType(dataType);
      const label = name;
      const key = id;
      const dbFilterKey = this.filterUtil.getAttributesDbFilterKey(key);
      const attributeMetadata = new NewTableColumnAttributeMetadata(attribute, dataType);
      const meta = new NewTableColumnMetaData({ filterType, dbFilterKey, filterKey: key, isAttribute: true, attributeMetadata });
      return new NewTableColumn({ label, key, meta });
    });
    // TODO - check if id column should be there as well
    this.attributeColumns.setList(attributeColumns, 'key');
    this.editableColumnKeys = [...attributeColumns.map(col => col.key)];
    this.sortableColumnKeys = [NonAttributeKeys.CREATED_ON, NonAttributeKeys.UPDATED_ON];
    this.columns = [this.getIdColumn(), ...attributeColumns];
  }

  private updateMultiSelectionMode(): void {
    if (this.restriction) {
      const { singleSource, singleDestination } = this.restriction;
      // this.isMultiSelectionMode = this.isOutgoing() ? canMulti(singleSource, singleDestination) : canMulti(singleDestination, singleSource);
      this.isMultiSelectionMode = this.isOutgoing() ? this.isMulti(singleSource, singleDestination) : this.isMulti(singleDestination, singleSource);
      return;
    }
    this.isMultiSelectionMode = false;
  }

  private isMulti(target: boolean, destination: boolean): boolean {
    return (!target && !destination) || (!target && destination);
  }
}
