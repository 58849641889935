import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WithEmptySelectOptionPipe } from './with-empty-select-option.pipe';

@NgModule({
  declarations: [WithEmptySelectOptionPipe],
  imports: [CommonModule],
  exports: [WithEmptySelectOptionPipe],
})
export class WithEmptySelectOptionModule {}
