import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import {
  CREATED_BY_EMAIL_KEY,
  CREATED_BY_KEY,
  CREATED_ON_KEY,
  ID_KEY,
  UPDATED_BY_EMAIL_KEY,
  UPDATED_BY_KEY,
  UPDATED_ON_KEY,
} from '@shared/constants/constants';
import { SystemTenant } from '@shared/types/shared.types';
import { DateUtil } from '@shared/utils/date.util';
import { LazyLoadEvent } from 'primeng/api';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { CoreListFilterEnum, ListMetaData, ListReqMetaData, ListResDtoI } from '../types/core.types';

import { CoreRxSubscriptionsComponent } from './core-rx-subscriptions.component';

@Directive()
export abstract class CoreListComponent<Entity> extends CoreRxSubscriptionsComponent implements OnInit, OnDestroy {
  @Input() loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<Entity>>;
  @Input() readonly sortByAttribute: boolean = false;

  loading = true;
  data: Entity[] = [];
  meta: ListMetaData = new ListMetaData();
  nonAttributeFields = [ID_KEY, CREATED_BY_EMAIL_KEY, UPDATED_BY_EMAIL_KEY, CREATED_ON_KEY, UPDATED_ON_KEY, CREATED_BY_KEY, UPDATED_BY_KEY];

  protected constructor(public dateUtil?: DateUtil) {
    super();
  }

  private static getMongoFilterByMatchMode(matchMode: CoreListFilterEnum, value: any, useOid: boolean): Record<string, any> {
    switch (matchMode) {
      case CoreListFilterEnum.startsWith:
        return { $regex: '^' + String(value), $options: 'i' };
      case CoreListFilterEnum.endsWith:
        return { $regex: String(value) + '$', $options: 'i' };
      case CoreListFilterEnum.contains:
        return { $regex: String(value), $options: 'i' };
      case CoreListFilterEnum.notContains:
        return { $not: { $regex: String(value), $options: 'i' } };
      case CoreListFilterEnum.equals:
        // TODO: fix
        // return { $eq: String(value) };
        return { $eq: value === null || typeof value === 'boolean' ? value : String(value) };
      case CoreListFilterEnum.notEquals:
        return { $not: { $eq: value !== null ? String(value) : value } };
      case CoreListFilterEnum.lessThan:
        return { $lt: String(value) };
      case CoreListFilterEnum.lessThanOrEqualTo:
        return { $lte: String(value) };
      case CoreListFilterEnum.greaterThan:
        return { $gt: String(value) };
      case CoreListFilterEnum.greaterThanOrEqualTo:
        return { $gte: String(value) };
      case CoreListFilterEnum.in:
        return { $in: useOid ? value.map((item: any) => ({ $oid: item })) : value }; // TODO: consult $oid with micha
      case CoreListFilterEnum.notIn:
        return { $nin: useOid ? value.map((item: any) => ({ $oid: item })) : value };
      case CoreListFilterEnum.isNot:
        return { $not: value };
      case CoreListFilterEnum.before:
        return { $lt: value };
      case CoreListFilterEnum.after:
        return { $gt: value };
      case CoreListFilterEnum.exists:
        return { $exists: Boolean(value) };
      default:
        throw new Error(`Match mode value must be one of CoreListFilterEnum, but received: "${String(matchMode)}"`);
    }
  }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy();
  }

  async onLazyLoad(event: LazyLoadEvent | null): Promise<void> {
    try {
      if (event) {
        const { rows, first, sortField, sortOrder, filters } = event;
        this.meta = new ListMetaData(
          rows,
          first,
          this.processFilters(filters as Record<string, FilterMetadata[]>),
          this.transformSorting(sortField, sortOrder),
          this.meta.totalCount,
        );
        this.loading = true;

        const { meta, data } = await this.loadDataMethod(this.meta.toQuery());
        this.data = data || [];
        this.meta.totalCount = meta?.totalCount || 0;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  protected transformSorting(sortField: string | undefined, sortOrder: number | undefined): Record<string, number> {
    const field = this.sortByAttribute ? `attributes.${sortField}.value` : sortField;
    return sortField && sortOrder ? { [field as any]: sortOrder } : {};
  }

  protected transformMultiSorting(sortFields: string[], sortOrder: number[]): Record<string, number> {
    if (sortFields.length && sortOrder.length) {
      return sortFields.reduce<Record<string, number>>((sortObject, sortField, index) => {
        const field = this.isFieldAttribute(sortField) ? `attributes.${sortField}.value` : sortField.replace('.email', '');
        sortObject[field] = sortOrder[index];
        return sortObject;
      }, {});
    } else {
      return {};
    }
  }

  protected processFilters(filters: Record<string, FilterMetadata[]>): Record<string, any> {
    const names = Object.keys(filters);
    const mongoFilters: Record<string, Record<string, any>[]> = {};

    names.forEach(name => {
      const mongoFilter: Record<string, any>[] = [];
      let mongoFilterOperator: string | null = null;

      filters[name].forEach(filter => {
        if (String(filter.value).includes(SystemTenant.system) || String(filter.value).includes(SystemTenant.anonymous)) return;

        if ((filter.value || typeof filter.value === 'boolean') && JSON.stringify(filter.value) !== '[]') {
          const { matchMode, operator } = filter;
          let { value } = filter;

          if (operator === 'or') mongoFilterOperator = '$or';
          if (operator === 'and') mongoFilterOperator = '$and';

          if (name.includes('attributes')) {
            const parts = name.split('_');

            if (parts[1] && this.dateUtil) {
              if (parts[1].toUpperCase() === BaseDataType.date) value = this.dateUtil.convertToServerDate(new Date(value));
              if (parts[1].toUpperCase() === BaseDataType.dateTime) value = this.dateUtil.convertToServerDatetime(new Date(value));
              if (parts[1].toUpperCase() === BaseDataType.time) value = this.dateUtil.convertToServerTime(new Date(value));
            }

            if (parts.length > 1) mongoFilter.push({ [parts[0]]: CoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, value, true) });
            else value && JSON.stringify(value) !== '[]' && mongoFilter.push({ [name]: { $in: value } });
          } else if (name.includes('_id') || (name.includes('_Id') && matchMode === 'notEquals')) {
            if (value && JSON.stringify(value) !== '[]') {
              mongoFilter.push({ [name]: { $not: { $in: typeof value === 'string' ? { $oid: value } : value.map((id: any) => ({ $oid: id })) } } });
            }
            // TODO: what if name === widgetType?
          } else if (
            !name.includes('widgetType') &&
            (name.includes('id') || name.includes('Id') || name.includes('created.by') || name.includes('updated.by'))
          ) {
            // if (value === NonAttributeKeys.CURRENT_USER_ID) value = this.currentUser.id;
            if (value && JSON.stringify(value) !== '[]')
              mongoFilter.push({ [name]: CoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, value, true) });
          } else {
            mongoFilter.push({ [name]: CoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, value, false) });
          }
        } else if (name === 'deleted') {
          const { matchMode, operator, value } = filter;
          if (operator === 'or') mongoFilterOperator = '$or';
          if (operator === 'and') mongoFilterOperator = '$and';
          mongoFilter.push({ deleted: CoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, value, false) });
        }
      });

      if (mongoFilterOperator) {
        if (!mongoFilters[mongoFilterOperator]) mongoFilters[mongoFilterOperator] = [];
        mongoFilters[mongoFilterOperator] = [...mongoFilters[mongoFilterOperator], ...mongoFilter];
      }
    });
    return mongoFilters;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onDestroy(): void {}

  protected isFieldAttribute(fieldName: string): boolean {
    return !this.nonAttributeFields.includes(fieldName);
  }
}
