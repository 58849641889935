import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models';
import { AttributeActionHandlerService, AttributeOptionValuesSettable, PageAttributeChangeEvent, SetAttributeValueEvent } from '@workflows/shared';
import { filter, map, tap } from 'rxjs';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';
import { WorkflowAttributeActionEventType } from '@workflows/types';

@Directive({
  selector: '[appSetAttributeOptionValues]',
})
export class SetAttributeOptionValuesDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  @Input() attributeOwner: AttributeOptionValuesSettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    const key = this.actionAttributeHandler.generateMapKey(this.artifactTypeId, this.attributeId);
    const finalWidgetId = this.widgetId || AttributeActionHandlerService.EMPTY_WIDGET;
    this.initSubscription = this.actionAttributeHandler.pageAttributeEventMap$
      .pipe(
        filter(pageAttributeEvent => !!pageAttributeEvent[this.pageId]?.ATTRIBUTE_OPTION_VALUES?.[finalWidgetId]?.[key]),
        map(pageAttributeEvent => pageAttributeEvent[this.pageId]?.ATTRIBUTE_OPTION_VALUES?.[finalWidgetId]?.[key]),
        tap(pageAttributeEvent => {
          const newValues: DataTypeValueResponseDto[] = (pageAttributeEvent as SetAttributeValueEvent).value;
          this.setAttributeOptionsValues(newValues);
          const event = { artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, value: [] };
          const pageAttributeChangeEvent: PageAttributeChangeEvent = {
            pageId: this.pageId,
            eventType: WorkflowAttributeActionEventType.ATTRIBUTE_OPTION_VALUES,
            event,
            widgets: [finalWidgetId],
          };

          this.actionAttributeHandler.clearPageAttributeEvent(pageAttributeChangeEvent, false);
        }),
      )
      .subscribe();
  }

  private setAttributeOptionsValues(newValue?: DataTypeValueResponseDto[]) {
    this.attributeOwner.setAttributeOptionValues(newValue);
  }
}
