import { WidgetModel } from '../../shared/types/widget-model.interface';

export class TagWidgetSettings implements WidgetModel<TagWidgetSettingsDto> {
  tag: string;

  constructor(dto?: TagWidgetSettingsDto) {
    dto;
    //TODO: Missing impplementation ?
  }

  toServer(): TagWidgetSettingsDto {
    return {
      tag: '',
    };
  }

  fromDto(dto: TagWidgetSettingsDto): void {
    this.tag = dto.tag;
  }
}

export class TagWidgetSettingsDto {
  tag: string;
}
