import { LocalStorageService } from '@shared/services/local-storage.service';
import { isEqual } from 'lodash';
import { Subject, Subscription } from 'rxjs';

export class CachedSubject<T> {
  private subject = new Subject<T>();
  private lastValue: T | null = null;
  private lastUpdateTime = 0;
  private isUpdating = false;

  constructor(
    private updateFn: () => Promise<T>,
    private localStorageMeta: CachedSubjectLocalStorageMeta | null = null,
    private noUpdateTime: number = 3000,
    private cacheTime: number = Number.POSITIVE_INFINITY,
  ) {
    this.deserialize();
  }

  subscribe(next: (value: T) => void, error?: (error: any) => void): Subscription {
    const now = Date.now();

    if (this.lastUpdateTime + this.cacheTime < now) this.lastValue = null;
    if (this.lastValue != null) next(this.lastValue);
    if (this.lastUpdateTime + this.noUpdateTime < now) setTimeout(() => this.update());

    return this.subject.subscribe(next, error);
  }

  setValue(value: T): void {
    this.lastUpdateTime = Date.now();
    if (!isEqual(this.lastValue, value)) {
      this.subject.next(value);
      this.lastValue = value;
      this.serialize(value);
    }
  }

  update(): void {
    if (this.isUpdating) return;

    this.isUpdating = true;
    this.updateFn()
      .then(value => this.setValue(value))
      .catch(reason => {
        if (this.lastValue == null) this.subject.error(reason);
      })
      .finally(() => {
        this.isUpdating = false;
      });
  }

  reset(): void {
    this.subject.complete();
    this.subject = new Subject<T>();

    this.lastValue = null;
    this.lastUpdateTime = 0;

    if (this.localStorageMeta !== null) {
      const { stateKey, key, localStorageService } = this.localStorageMeta;

      if (stateKey) localStorageService.removeFromState(stateKey, key);
      else localStorageService.remove(key);
    }
  }

  toPromise(): Promise<T> {
    return new Promise<T>(resolve => {
      const subscription = this.subscribe((value: T) => {
        resolve(value);
        subscription.unsubscribe();
      });
    });
  }

  private serialize(value: T): void {
    if (this.localStorageMeta == null) return;

    const { stateKey, key, localStorageService, storeOnlyValue } = this.localStorageMeta;
    const data: CachedSubjectLocalStorageData<T> | T = storeOnlyValue ? value : { lastUpdateTime: this.lastUpdateTime, lastValue: value };

    if (stateKey) localStorageService.setToState(stateKey, key, data);
    else localStorageService.set(key, data);
  }

  private deserialize(): void {
    if (this.localStorageMeta == null) return;

    const { stateKey, key, localStorageService, storeOnlyValue } = this.localStorageMeta;
    const item: CachedSubjectLocalStorageData<T> | T = stateKey ? localStorageService.getFromState(stateKey, key) : localStorageService.get(key);
    if (item == null) return;

    try {
      if (!storeOnlyValue) {
        const { lastValue, lastUpdateTime } = item as CachedSubjectLocalStorageData<T>;
        this.lastValue = lastValue;
        this.lastUpdateTime = lastUpdateTime;
      } else {
        this.lastValue = item as T;
        this.lastUpdateTime = 0;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export interface CachedSubjectLocalStorageMeta {
  localStorageService: LocalStorageService;
  key: string;
  stateKey?: string;
  storeOnlyValue?: boolean;
}

export interface CachedSubjectLocalStorageData<T> {
  lastUpdateTime: number;
  lastValue: T;
}
