<div class="list_top">
    <div class="grid list_top__filters" style="padding-bottom: 250px">
        <div class="col-6">
            Choose attribute
        </div>
        <div class="col-6">
            <p-dropdown
                    (onChange)="storeUrlKey(); s.onChangeAttribute(); onChangeKey();"
                    [(ngModel)]="m.settings.attribute"
                    [options]="m.options.attributeValues.list | transformArrayToSelectOptions: NAME_VALUE: ID_VALUE"
                    optionLabel="label"
                    placeholder="Attribute"
                    showClear="true"
                    filter="true"
            >
            </p-dropdown>
        </div>
        <div class="col-6">
            URL parameter
        </div>
        <div class="col-6">
            <input type="text" id="name" pInputText (focusin)="storeUrlKey()" (focusout)="onChangeKey()" [(ngModel)]="m.settings.urlKey"/>
        </div>
        <ng-container>
            <div class="col-6">
                Placeholder
            </div>
            <div class="col-6">
                <input type="text" pInputText [(ngModel)]="m.settings.placeholder" [placeholder]="'Will be used, when possible' | translate"/>
            </div>
        </ng-container>
        <ng-container *ngIf="!m.settings.isDateMode">
            <div class="col-6">
                Display variant
            </div>
            <div class="col-6" *ngIf="!m.settings.isFilterMode">
                <p-dropdown
                        [(ngModel)]="m.settings.selectedVariant"
                        [options]="m.options.layoutVariant.list | transformArrayToSelectOptions: 'label': 'code'"
                        optionLabel="label"
                        placeholder="Layout variant"
                        showClear="true"
                >
                </p-dropdown>
            </div>
            <div class="col-6" *ngIf="m.settings.isFilterMode">
                <p-dropdown
                        [(ngModel)]="m.settings.textSelectedVariant"
                        [options]="m.options.textLayoutVariant.list | transformArrayToSelectOptions: 'label': 'code'"
                        optionLabel="label"
                        placeholder="Layout variant"
                        showClear="true"
                >
                </p-dropdown>
            </div>
        </ng-container>
    </div>
</div>
