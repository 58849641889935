import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import {
  ArtifactAttributeRequestDto,
  ArtifactFormatModuleDataResponseDto,
  ArtifactGroupItemResponseDto,
  ArtifactResponseDto,
  DataTypeValueResponseDto,
  GroupItemResponseDto,
  GroupResponseDto,
} from '@api/models';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { BulkArtifactEditPopupComponent } from '@shared/components/bulk-artifact-edit-popup/bulk-artifact-edit-popup.component';
import { DisplayAtControlService } from '@shared/components/common-display-at';
import { AdvancedDateFilterObject } from '@shared/components/date-filter/types/date-filter.types';
import {
  Constants,
  CREATED_ON_KEY,
  EMAIL_KEY,
  EMPTY_FILTER_VALUE_SINGLE,
  FOLDER_FILTER_KEY,
  ID_KEY,
  UPDATED_BY_KEY,
  UPDATED_ON_KEY,
} from '@shared/constants/constants';
import { FilterToClientEnum, ListMetaData } from '@shared/core/types/core.types';
import { FileHelper } from '@shared/helpers/file.helper';
import { CheckMandatoryFields } from '@shared/methods/artifact.methods';
import { GetDataTypeByAttributeId } from '@shared/methods/attribute.methods';
import { IsDate, IsDateTime } from '@shared/methods/data-type.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { FilterMainControlService } from '@shared/services/filter/filter-main-control.service';
import { BaseFolderFilterService } from '@shared/services/filter/filter-types/base-folder-filter.service';
import { BaseNumericFilterService } from '@shared/services/filter/filter-types/base-numeric-filter.service';
import { ServerSideEventService } from '@shared/services/server-side-event.service';
import { SortMainControlService } from '@shared/services/sort/sort-main-control.service';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { CustomDateSettings, DateFilterEnum, DateRangeFilterEnum, LinkFilterEnum, UserFilterEntry } from '@shared/types/filter.types';
import { ArtifactTypeLinkRestriction, LinkRestrictionParamsBase } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { SortTypeEnum, SortTypeValueEnum } from '@shared/types/sort.types';
import {
  ComplexFilterMetadata,
  FilterType,
  NewTableColumn,
  NewTableColumnMetaData,
  TableColumnFormatSettings,
  TableFormatSettings,
} from '@shared/types/table.types';
import { TeamsWithUsers } from '@shared/types/user.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { DateUtil } from '@shared/utils/date.util';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { ObjectUtil } from '@shared/utils/object.util';
import { QueryParamsUtil } from '@shared/utils/query-params.util';
import { FolderTreeNode } from '@widgets/folder-widget/types/folder-widget.types';
import { LinkPopupModelDto } from '@widgets/link-popup/types/link-popup.types';
import { ListWidgetHelper } from '@widgets/list-widget-new/services/list-widget.helper';
import { ListWidgetService } from '@widgets/list-widget-new/services/list-widget.service';
import { ArtifactMovementService } from '@widgets/shared/components/artifact-list-table/services/artifact-movement.service';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { WidgetDataShareService } from '@widgets/shared/services/widget-data-share.service';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { LinkDialogOpenArguments } from '@widgets/shared/types/link-popup/link-dialog-open-arguments';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { PAGE_ID, WIDGET_ID } from '@widgets/widgets-core/constants/widgets-core.constants';
import { RuleTriggerEventHandlerService } from '@workflows/services';
import { WorkflowTriggerEvent, WorkflowTriggerWidgetDataLoad } from '@workflows/types';
import { cloneDeep } from 'lodash';
import { ConfirmationService, FilterMetadata, LazyLoadEvent, MenuItem, SortMeta } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { BehaviorSubject, EMPTY, from, lastValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { catchError, delay, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { RuntimeStateNotificationService } from '../../services/runtime-state-notification.service';
import { AbstractArtifactListTableComponent } from './abstract-artifact-list-table.component';
import { GetRelevantLinksToTableCellPipe } from './pipes/get-relevant-links-to-table-cell.pipe';
import { ArtifactListWidgetArtifactService } from './services/artifact-list-widget-artifact.service';
import { ArtifactListWidgetLinkService } from './services/artifact-list-widget-link.service';
import { ArtifactListWidgetNestedWidgetLoaderService } from './services/artifact-list-widget-nested-widget-loader.service';
import { ArtifactListWidgetTableHelper } from './services/artifact-list-widget-table-helper.service';
import { ArtifactListWidgetTableLoadHelper } from './services/artifact-list-widget-table-load-helper.service';
import { ArtifactListWidgetTableService } from './services/artifact-list-widget-table.service';
import { ArtifactModuleHelper } from './services/artifact-module.helper';
import { ArtifactModuleService } from './services/artifact-module.service';
import { ArtifactGroupingService } from './services/grouping/artifact-grouping.service';
import { ListWidgetTableHelper } from './services/list-widget-table.helper';
import { TableColumnControlService } from './services/table-column-control';
import { TableColumnContextChangeEvent, TableColumnContextChangeType } from './services/table-column-control/table-column-context-types';
import { TableFilterControlService } from './services/table-filter/table-filter-control.service';
import { TableFilterUrlControlService } from './services/table-filter/table-filter-url-control.service';
import {
  AddArtifactTableLinkItem,
  ArtifactListTableIdentifiers,
  ArtifactListTableModel,
  DeleteArtifactTableLinkItem,
  PaginatorEvent,
} from './types/artifact-list-widget-table.types';
import {
  GroupAttributeItem,
  GroupByCountMeta,
  GroupItem,
  GroupMetaDataItem,
  GroupMetaItem,
  GroupOrderEnum,
  PaginationSettingEnum,
} from './types/list-widget-grouping.types';
import { ListWidgetOptions } from './types/list-widget-options.types';
import { LinkFilterNew, ListWidgetSelected } from './types/list-widget-selected.types';
import { ListWidgetTableLoadModeEnum, ListWidgetTableSettings, SummaryItem } from './types/list-widget-settings.types';
import { TABLE_FORMAT_STATE_KEY } from './types/list-widget.types';

type SingleArtifactSelection = null | NewArtifact;
type MultiArtifactSelection = NewArtifact[];

export type ArtifactSelection = SingleArtifactSelection | MultiArtifactSelection;

interface RowSelectabilityCheckParams {
  data: NewArtifact;
  index: number;
}

@Component({
  selector: 'app-artifact-list-widget-table',
  templateUrl: './artifact-list-widget-table.component.html',
  styleUrls: ['./artifact-list-widget-table.component.scss'],
  providers: [
    ArtifactListWidgetTableService,
    ArtifactListWidgetTableHelper,
    ArtifactListWidgetTableLoadHelper,
    GetRelevantLinksToTableCellPipe,
    TableColumnControlService,
    TableFilterControlService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactListWidgetTableComponent extends AbstractArtifactListTableComponent implements AfterViewInit, OnDestroy {
  static readonly delayForTooltipInit = 50;

  @Input() ids: ArtifactListTableIdentifiers;
  @Input() state: Record<string, any>;
  @Input() addButtonItems: MenuItem[];
  @Input() restrictions: Record<string, ArtifactTypeLinkRestriction[]>;
  @Input() disabledArtifactIds: string[];
  @Input() linkingPopupDtoMap: Record<string, Record<string, LinkPopupModelDto>>;
  @Input() selected: ListWidgetSelected;
  @Input() options: ListWidgetOptions;
  @Input() settings: ListWidgetTableSettings;
  @Output() artifactSelect: EventEmitter<NewArtifact> = new EventEmitter<NewArtifact>();
  @Output() artifactUnselect: EventEmitter<NewArtifact> = new EventEmitter<NewArtifact>();
  @Output() onLinkDialogOpen: EventEmitter<LinkDialogOpenArguments> = new EventEmitter<LinkDialogOpenArguments>();
  @ViewChild('table') table: Table;
  m: ArtifactListTableModel = new ArtifactListTableModel();
  cloneRows: Record<string, NewArtifact> = {};
  groupMetaData$: BehaviorSubject<Record<string, GroupMetaDataItem> | null> = new BehaviorSubject<Record<string, GroupMetaDataItem> | null>(null);
  groupData$: Observable<GroupItem[]>;
  loading$: Observable<boolean>;
  columns$: Observable<NewTableColumn[]>;
  currentlyEditedRowsMap: Record<string, boolean> = {};
  isPaginationByTable = true;
  groupItems: GroupItem[];
  totalCountMap: Record<string, number> = {};
  groupCountMap: Record<string, number | undefined> = {};
  sseAddedItems = 0;
  sseAddingArtifactIdInProgress: string | undefined;
  currentPage = 0;
  latestLazyLoadEvent: LazyLoadEvent | null = null;
  latestProcessedFilters: Record<string, any> = {};
  paginationSettingEnum = PaginationSettingEnum;
  teamsWithUsers: TeamsWithUsers;
  EMAIL_VALUE = EMAIL_KEY;
  ID_VALUE = ID_KEY;
  private dataSubject: Subject<NewArtifact[]> = new Subject();
  private groupDataSubject: Subject<GroupItem[]> = new Subject();
  private loadingSubject: Subject<boolean> = new Subject();
  private groupMetaItems: Record<string, GroupMetaItem> = {};
  private latestLazyLoadEventsMap: Record<string, LazyLoadEvent | null> = {};
  private _queryParams: Params;
  /** represents columns of given artifact type(s), which are not selected (displayed in table) + system columns */
  private availableAtColumns: Record<string, NewTableColumn>;
  private initialUrlFiltersMap: Record<string, FilterMetadata[]>;
  private externalUrlAttrFilters: Record<string, Record<string, any>> | undefined;
  private initialUrlSort: SortMeta;
  private externalUrlSort?: SortMeta;
  private linkArtifactMeta: Record<string, any> | undefined;
  private initSequenceMap: Record<string, number> = {};
  private initAttributesMap: Record<string, Record<string, ArtifactAttributeRequestDto>> = {};
  private currentFilterQuery: string;

  constructor(
    dateU: DateUtil,
    public readonly objectU: ObjectUtil,
    public readonly tenantArtifactService: TenantArtifactService,
    public readonly tenantLinkService: TenantLinkService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    public readonly s: ArtifactListWidgetTableService,
    public readonly loadHelper: ArtifactListWidgetTableLoadHelper,
    public readonly helper: ArtifactListWidgetTableHelper,
    public readonly listWidgetTableHelper: ListWidgetTableHelper,
    public readonly filterUtil: FilterUtil,
    public readonly filterMetadataUtil: FilterMetadataUtil,
    public readonly announcement: AnnouncementService,
    public readonly elvisU: ElvisUtil,
    public readonly confirmationService: ConfirmationService,
    public readonly artifactService: TenantArtifactService,
    public readonly widgetDataShareService: WidgetDataShareService,
    public readonly cache: NewCacheService,
    protected readonly baseNumericFilter: BaseNumericFilterService,
    protected readonly baseFolderFilter: BaseFolderFilterService,
    private readonly listWidgetService: ListWidgetService,
    private readonly sseService: ServerSideEventService,
    private readonly artifactGroupingService: ArtifactGroupingService,
    private readonly alwLinkService: ArtifactListWidgetLinkService,
    private readonly alwArtifactService: ArtifactListWidgetArtifactService,
    private readonly alwNestedWidgetLoaderService: ArtifactListWidgetNestedWidgetLoaderService,
    private readonly displayAtControlService: DisplayAtControlService,
    private readonly tableColumnControlService: TableColumnControlService,
    private readonly filterUrlControlService: TableFilterUrlControlService,
    private readonly filterMainControlService: FilterMainControlService,
    private readonly moduleService: ArtifactModuleService,
    private readonly moduleHelper: ArtifactModuleHelper,
    private readonly sortMainControlService: SortMainControlService,
    private readonly attributeUtil: AttributeUtil,
    private readonly fileHelper: FileHelper,
    private readonly queryParamsUtil: QueryParamsUtil,
    protected cdr: ChangeDetectorRef,
    private readonly router: Router,
    private zone: NgZone,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly artifactMovementService: ArtifactMovementService,
    private ruleTriggerEventHandler: RuleTriggerEventHandlerService,
    private readonly listWidgetHelper: ListWidgetHelper,
    @Inject(PAGE_ID) public pageId: string,
    @Inject(WIDGET_ID) public widgetId: string,
  ) {
    super(cache, baseNumericFilter, baseFolderFilter, dateU);
    this.groupData$ = this.groupDataSubject.asObservable();
    this.loading$ = this.loadingSubject.asObservable();
  }

  get queryParams(): Params {
    return this._queryParams;
  }

  @Input() set queryParams(queryParams: Params) {
    this.setQueryParamsWithUrlHandling(queryParams);
  }

  get selection(): ArtifactSelection {
    return this.settings.multiselect ? this.selected.artifacts : this.selected.artifact;
  }

  set selection(selection: ArtifactSelection) {
    if (this.settings.multiselect) {
      this.selected.artifacts = selection as MultiArtifactSelection;
    } else {
      this.selected.artifact = selection as SingleArtifactSelection;
    }
  }

  get isNumberOfSelectedVisible(): boolean {
    return (
      this.settings.rowClickHandle &&
      this.settings.rowClickHandleAction === ArtifactListItemClickAction.selectItem &&
      this.settings.multiselect &&
      this.settings.showNumberOfSelected
    );
  }

  async onInit(): Promise<void> {
    super.onInit();
    this.s.init(this, this.m);
    this.loadHelper.init(this);
    this.helper.init(this);
    this.registerSseEvents();
    this.registerDisplayAtEvents();
    this.initTableData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const keys = Object.keys(this.table?.filters || {}).filter(key => (key.includes('date') || key.includes('time')) && !key.includes(UPDATED_BY_KEY));
      keys.forEach(key => (this.table.filters[key] as any[])?.forEach(filter => filter.value && (filter.value = new Date(filter.value))));
      this.getFiltersOutsideView();
    });

    // const rulePriority: Rule = {
    //   id: uniqueId(),
    //   name: 'Test for conditional formatting',
    //   description: 'Rule checking the priority value and invokes some actions',
    //   operator: RuleConditionOperatorType.AND,
    //   conditions: [],
    //   active: true,
    //   triggers: [
    //     {
    //       type: 'WIDGET_DATA_LOAD',
    //     },
    //   ],
    //   actions: [
    //     {
    //       actionType: WorkflowActionType.FORMAT,
    //       payload: {
    //         pageId: this.pageId,
    //         artifactTypeId: '63ab257779082e61bc3563fa',
    //         attributeId: '63ab24fc79082e61bc3563f5',
    //         format: [
    //           {
    //             type: RuleConditionOperationType.GREATER_THAN,
    //             value: 10000,
    //             styles: {
    //               'background-color': 'red',
    //               color: 'white',
    //             },
    //           },
    //           {
    //             type: RuleConditionOperationType.LESS_OR_EQUALS_THAN,
    //             value: 100,
    //             styles: {
    //               'background-color': 'green',
    //               color: 'white',
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   ],
    //   owner: {
    //     pageId: this.pageId,
    //     widgetId: this.widgetId,
    //   },
    // };
    // this.ruleDataHolder.registerRule(rulePriority);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filterUrlControlService.clearCurrentTableFilterUrlParamsEvent(this.ids.hash);
    this.sortMainControlService.clearCurrentUrlChangeEvent(this.ids.hash);
    this.artifactGroupingService.clearGroupingDataForWidget(this.hash);
  }

  showLoader(): void {
    this.loadingSubject.next(true);
  }

  hideLoader(): void {
    this.loadingSubject.next(false);
  }

  async exportList(): Promise<void> {
    this.selected.exportInProcess = true;
    const customAttributesMap: Record<string, string> = {};
    Object.values(NonAttributeKeys).forEach(val => {
      customAttributesMap[val] = val;
    });

    const customAttrIds: string[] = [];
    const attributeIds: string[] = [];
    const linkTypeIds: string[] = [];

    this.selected.columns.forEach(c => {
      if (customAttributesMap[c.key]) {
        customAttrIds.push(customAttributesMap[c.key]);
      } else if (c.meta.isLink) {
        linkTypeIds.push(c.key.split('_')[0]);
      } else {
        attributeIds.push(c.key);
      }
    });

    const $and: Record<string, any> = [
      { artifactTypeId: { $in: this.selected.artifactTypes.map(artifactType => ({ $oid: artifactType.value.id })) } },
      { deleted: { $eq: null } },
    ];

    if (this.meta.filters.$and) {
      Object.values(this.meta.filters.$and).forEach(item => {
        $and.push(item);
      });
    }

    const filter: string = JSON.stringify({ $and });

    // todo add customAttrIds to request after BE is ready
    this.tenantArtifactService.artifactControllerExport({ filter, attributeIds, linkTypeIds }).subscribe(res => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      (a as any).style = 'display: none';

      const url = window.URL.createObjectURL(res);
      a.href = url;
      a.download = this.settings.exportFileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.selected.exportInProcess = false;
    });
  }

  async onLazyLoad(event: LazyLoadEvent): Promise<void> {
    await this.applyLinkFiltersOnData();
    if (!this.isPaginationByTable) {
      this.groupCountMap = await this.getGroupByCountMap(event);
      this.groupItems = this.getGroupItems();
      this.groupItems.forEach(groupItem => {
        groupItem.total = this.groupCountMap[groupItem.id] || 0;
        this.groupMetaItems[groupItem.id] = {
          groupItem,
          listMetadata: new ListMetaData(),
          data$: of([]).pipe(
            switchMap(() => this.retrieveDataForGroup$(event, groupItem)),
            tap(artifacts => {
              groupItem.artifacts = artifacts;
              this.loadHelper
                .loadDataLinksWithLinkedArtifactsToModel$(
                  artifacts,
                  this.selected.columns.filter(col => col?.meta?.isLink),
                  this.isPaginationByTable,
                )
                .subscribe();
            }),
            shareReplay(),
          ),
        };
      });
      this.notifyGroupDataChange(this.groupItems);
      this.settings.rowClickHandle && this.setSelectedArtifactFromUrlParams();
      return;
    }

    this.showLoader();
    const dataUpdate$ = this.retrieveDataForGroup$(event).pipe(
      tap(async data => {
        this.data = data;
        const groupCountDataMap = await this.getGroupByCountMap();
        this.groupItems = this.artifactGroupingService.groupTableData(this.hash, data, this.settings.grouping, this.settings.rowsPerPage);
        this.groupItems.forEach(groupItem => {
          this.groupMetaItems[groupItem.id] = {
            groupItem,
            listMetadata: new ListMetaData(),
            data$: of([]).pipe(
              switchMap(() => of(groupItem.artifacts)),
              shareReplay(),
            ),
          };

          const groupValue = groupItem.id === 'empty' ? '' : groupItem.id;
          groupItem.total = groupCountDataMap[groupValue];
        });
        this.resetLinkedData();
        this.notifyGroupDataChange(this.groupItems);
      }),
      switchMap(() =>
        this.loadHelper.loadDataLinksWithLinkedArtifactsToModel$(
          this.data,
          this.selected.columns.filter(col => col?.meta?.isLink),
          this.isPaginationByTable,
        ),
      ),

      tap(() => {
        this.refreshDataItemsAfterLinkLoad();
        this.loadFilesToModel();
        this.hideLoader();
        this.m.isFirstLoad && (this.m.isFirstLoad = false);
        this.settings.rowClickHandle && this.setSelectedArtifactFromUrlParams();
      }),
      delay(ArtifactListWidgetTableComponent.delayForTooltipInit),
      tap(() => this.fillTooltipMap()),
    );
    dataUpdate$.subscribe();
  }

  getDataForGroup$(groupItem: GroupItem): Observable<NewArtifact[]> {
    return this.groupMetaItems[groupItem.id].data$ || of([]);
  }

  getStyleClass(settings: ListWidgetTableSettings): string {
    // TODO - currently invokes too many times, do optimize it
    const classes = [];
    settings.customColors && classes.push('no-background');
    settings.rowsStriped && classes.push('p-datatable-striped');
    // settings.size === ListWidgetTableSizeEnum.base && classes.push('no-padding');
    // settings.size === ListWidgetTableSizeEnum.small && classes.push('p-datatable-sm');
    // settings.size === ListWidgetTableSizeEnum.large && classes.push('p-datatable-lg');
    return classes.join(' ');
  }

  getGroup(index: number, limit: number): { key: string; value: GroupMetaDataItem } | null {
    if (!this.groupMetaData$.value) {
      return null;
    }

    for (const [key, value] of Object.entries(this.groupMetaData$.value)) {
      const idx = index % limit;
      if (idx >= value.index && idx < value.index + value.size) {
        return { key, value };
      }
    }
    return null;
  }

  isRowSelectable({ data }: RowSelectabilityCheckParams): boolean {
    const disabledByInlineEditing = !!this.cloneRows[data?.id];
    const disabledByLinkPopup = this.isRowDisabledByLinkPopup(data);
    return !(disabledByInlineEditing || disabledByLinkPopup);
  }

  isRowDisabledByLinkPopup(data: NewArtifact): boolean {
    return this.disabledArtifactIds?.includes(data?.id);
  }

  getSelectionMode(): string {
    if (!this.settings.rowClickHandle) return '';
    else return this.settings.multiselect ? 'multiple' : 'single';
  }

  computeNumericSummaryForData(data: NewArtifact[], groupItem?: GroupItem): void {
    const attributeDataTypes: Record<string, NewDataType> = {};
    const groupingAttribute = this.getGroupingAttribute();

    data.forEach((artifact, index) => {
      index === 0 && this.prepareNumericSummaryObject(artifact, attributeDataTypes, groupItem);
      for (const attributeId in artifact.attributes) {
        if (this.isAttributeNumeric(attributeDataTypes, attributeId)) {
          this.setNumericFlagAndAddValue(artifact, attributeId);
          groupingAttribute && this.calculateGroupAttributeSummary(artifact, attributeId);
        }
      }
    });

    this.m.attributeSummaryItems = { ...this.m.attributeSummaryItems };

    const attributeIds: string[] = [];
    const iconMap: Record<string, string> = {};
    const numericAttributeIds: string[] = Object.keys(this.m.attributeSummaryItems)
      .filter(key => this.m.attributeSummaryItems[key].isNumeric)
      .map(key => key);

    this.selected.columns.forEach(c => {
      const id = c.meta.attributeMetadata?.attribute.id;
      const icon = c.meta.attributeMetadata?.attribute.icon;
      if (id) {
        icon && (iconMap[id] = icon);
        numericAttributeIds.includes(id) && attributeIds.push(id);
      }
    });

    if (attributeIds.length) {
      this.alwArtifactService.getTotalSum(attributeIds, this.currentFilterQuery, iconMap, this.m.attributeSummaryItems).then(() => {
        this.m.attributeSummaryItems = { ...this.m.attributeSummaryItems };
      });
    }
  }

  isGroupingActive(): boolean {
    return !!this.settings.grouping.groupingAttributes.length;
  }

  isRowToggled(index: number, limit: number): boolean {
    const { groupingAttributes } = this.settings.grouping;
    if (!groupingAttributes.length || !this.groupMetaData$.value) {
      return true;
    }
    try {
      return !!this.getGroup(index, limit)?.value.toggled;
    } catch (e) {
      return false;
    }
  }

  resetSelection(): void {
    this.selection = this.settings.multiselect ? [] : null;
  }

  handleAddLinkClick(addArtifactTableLinkItem: AddArtifactTableLinkItem): void {
    const { column, artifact } = addArtifactTableLinkItem;
    const restriction = LinkMethods.getRestrictionForArtifactTypeAndLinkType(
      artifact.artifactTypeId,
      this.restrictions[artifact.artifactTypeId],
      column.meta.linkRestrictionParams!.linkTypeId,
      column.meta.linkRestrictionParams!.direction,
    );
    const linkDialogArguments = new LinkDialogOpenArguments({
      originalArtifact: artifact,
      linkTypeId: column.meta.linkRestrictionParams!.linkTypeId,
      linkDirection: column.meta.linkRestrictionParams!.direction,
      restriction,
      successCb: this.onAddLinkSuccessCb.bind(this, artifact),
    });
    this.onLinkDialogOpen.emit(linkDialogArguments);
  }

  async onAddLinkSuccessCb(artifact: NewArtifact): Promise<void> {
    // TODO - optimize this only for added linked artifact
    this.loadHelper
      .loadDataLinksWithLinkedArtifactsToModel$(
        this.data,
        this.selected.columns.filter(col => col?.meta?.isLink),
        this.isPaginationByTable,
      )
      .subscribe(() => this.updateItemInGroupCollection(artifact));
  }

  handleDeleteLinkClick(deleteArtifactTableLinkItem: DeleteArtifactTableLinkItem): void {
    const { artifact, link } = deleteArtifactTableLinkItem;

    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this link?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.helper.deleteLink$(artifact, link).subscribe(() => this.updateItemInGroupCollection(artifact));
      },
    });
  }

  onColumnsResize(): void {
    this.fillTooltipMap();
  }

  transformFiltersToComplexData(filters: Record<string, FilterMetadata[]>): Record<string, ComplexFilterMetadata> {
    const result: Record<string, ComplexFilterMetadata> = {};
    filters &&
      Object.keys(filters).forEach(key => {
        result[key] = {
          filterMetadata: filters[key],
          tableColumnMetadata: this.options.columns.listMap[key]?.meta || new NewTableColumnMetaData(),
        };
      });
    return result;
  }

  onRowEditInit(artifact: NewArtifact, index: string): void {
    !this.cloneRows[artifact.id] && (this.cloneRows[artifact.id] = cloneDeep(artifact));
    this.currentlyEditedRowsMap['editingButton' + index] = true;
    this.initSequenceMap[index] = artifact.attributes[this.m.sequenceAttributeId].value;
    this.initAttributesMap[index] = artifact.toUpdateDto(this.options.attributes, this.options.dataTypes)?.attributes || {};
  }

  onRowEditSave(artifact: NewArtifact, index: string): void {
    if (!CheckMandatoryFields(artifact, this.options.attributes.listMap, this.options.dataTypes.listMap, this.announcement)) {
      setTimeout(() => {
        const button = document.getElementById('editingButton' + index);
        button?.click();
      });
      return;
    }

    const clientAttributes = artifact.clientAttributes.filter(clientAttribute => {
      const attribute = this.options.attributes.listMap[clientAttribute.id];
      const dataType = this.options.dataTypes.listMap[attribute.dataTypeId];
      return dataType.baseDataType === BaseDataType.file;
    });

    this.mapUnsavedFilesToAttributes(artifact.id, clientAttributes);

    const newFiles$ = from(
      this.fileHelper.createNewFiles(
        clientAttributes,
        this.options.applications.listMap[this.ids.applicationId],
        this.options.attributes.listMap,
        this.onNewFileCreated.bind(this),
        true,
      ),
    );

    const body = artifact.toUpdateDto(this.options.attributes, this.options.dataTypes);

    if (body.attributes) {
      Object.keys(body.attributes).forEach(key => {
        if ((body.attributes as any)[key]?.value?.toString() === this.initAttributesMap[index][key]?.value?.toString()) {
          delete (body.attributes as any)[key];
        }
      });

      if (body.attributes[this.m.sequenceAttributeId]?.value === this.initSequenceMap[index]) {
        delete body.attributes[this.m.sequenceAttributeId];
      }
    }

    newFiles$
      .pipe(
        take(1),
        switchMap(() => {
          // catch file artifact id (after upload) and put instead raw value
          body.attributes &&
            Object.keys(body.attributes).forEach(attrId => {
              clientAttributes.forEach(clientAttr => {
                if (clientAttr.value && attrId === clientAttr.id && body.attributes && body.attributes[attrId].value !== clientAttr.value) {
                  body.attributes[attrId].value = clientAttr.value;
                }
              });
            });
          return this.tenantArtifactService.artifactControllerUpdate({
            body,
            notify: false,
            resetSequence: false,
          });
        }),
        catchError(e => this.onRowEditError(e)),
        tap(artifactDto => {
          this.cache.data.artifacts.setItem(artifactDto);
          this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.updateArtifact, artifactDto, this.ids.hash);
        }),
        map(artifactDto => new NewArtifact({ dto: artifactDto, artifactTypesMap: this.options.artifactTypes.listMap })),
      )
      .subscribe(async updatedArtifact => {
        if (this.moduleHelper.shouldUpdateModuleHeading(updatedArtifact, artifact)) {
          this.moduleService
            .updateModuleHeadingData$(artifact)
            .pipe(take(1))
            .subscribe({
              next: async () => await this.invokeLazyLoadData(),
              error: async () => await this.announcement.error('Failed to change heading setting'),
            });
        }
        this.listWidgetTableHelper.mapArtifactAttributesToClient(updatedArtifact, this.options.attributes, this.options.dataTypes, this.options.users);
        this.updateItemInDataCollection(updatedArtifact);
        this.updateItemInGroupCollection(this.cloneRows[artifact.id], updatedArtifact);
        // clearing temporary data
        this.currentlyEditedRowsMap['editingButton' + index] = false;
        delete this.cloneRows[artifact.id];
      });
  }

  isArtifactSelected(artifact: NewArtifact): boolean {
    return this.selection === artifact || !!(this.selection as NewArtifact[] | null)?.includes(artifact);
  }

  async onRowDrop(event: CdkDragDrop<void>): Promise<void> {
    const { groupingAttributes } = this.settings.grouping;

    if (!groupingAttributes.length) {
      this.moduleHelper.isLoadByModule(this.settings.loadMode) && this.onModuleRowDrop(event);
    } else {
      const previousGroup = this.getGroup(event.previousIndex, this.meta.limit);
      const currentGroup = this.getGroup(event.currentIndex, this.meta.limit);
      if (previousGroup?.key === currentGroup?.key) {
        moveItemInArray(this.data, event.previousIndex, event.currentIndex);
      } else {
        const artifact = this.data[event.previousIndex];
        const attribute = this.options.attributes.listMap[groupingAttributes[0].value.id];
        const dataType = this.options.dataTypes.listMap[attribute.dataTypeId];
        artifact.attributes[groupingAttributes[0].value.id].value = dataType.values?.find(val => val.value === currentGroup?.key);
        moveItemInArray(this.data, event.previousIndex, event.currentIndex);
        // TODO ??
        this.onRowEditSave(artifact, '' + previousGroup?.value?.index);
        this.settings.groupMetaData = await this.listWidgetTableHelper.groupTableData(this.settings, this.data, this.options.attributes.list);
      }
    }
  }

  toggleGroup(groupItem: GroupItem): void {
    if (groupItem.metaData) {
      groupItem.metaData.toggled = !groupItem.metaData.toggled;
    }
  }

  isMandatoryField(artifact: NewArtifact, key: string): boolean {
    return artifact.attributes[key]?.isMandatory;
  }

  reloadDataOnSse(): void {
    this.sseAddedItems = 0;
    this.currentPage = 0;
    const { linkFiltersNew } = this.selected;
    const lazyLoadEvent = { ...this.latestLazyLoadEvent, first: 0 };
    if (this.isAnyLinkTypeFilterActive(linkFiltersNew)) {
      this.applyLinkFiltersOnData();
    }
    this.onLazyLoad(lazyLoadEvent);
  }

  artifactTrackByFn(index: number, item: NewArtifact): string {
    return item.id;
  }

  async onNavigateToArtifact(artifact: NewArtifact): Promise<void> {
    const artifactType = this.options.artifactTypes.listMap[artifact.artifactTypeId];
    if (artifactType?.defaultPageId) {
      const url = this.router.createUrlTree([Constants.page, artifactType.defaultPageId], { queryParams: { artifactId: artifact.id } });
      window.open(url.toString(), '_blank');
    } else {
      await this.announcement.warn(`Artifact-type '${artifactType.name}' does not have assigned default page.`);
    }
  }

  onPageChange(event: PaginatorEvent, groupItem?: GroupItem): void {
    if (!groupItem) {
      this.onLazyLoad({ ...this.latestLazyLoadEvent, first: event.first, rows: event.rows });
    } else {
      this.groupMetaItems[groupItem.id] = {
        groupItem,
        listMetadata: new ListMetaData(),
        data$: of([]).pipe(
          switchMap(() => this.retrieveDataForGroup$({ ...this.latestLazyLoadEventsMap[groupItem.id], first: event.first, rows: event.rows }, groupItem)),
          shareReplay(),
        ),
      };
    }
    this.currentPage = event.page;
  }

  async onLinkFilterApply(columnMetaData: NewTableColumnMetaData, filter: LinkFilterEnum | null, linkFilterUrlParamKey?: string): Promise<void> {
    const { linkTypeId, direction } = columnMetaData.linkRestrictionParams as LinkRestrictionParamsBase;
    if (!linkTypeId || !direction || !filter) return;

    this.removeFromSelectedLinkFilters(filter, linkTypeId);
    this.selected.linkFiltersNew[filter] = {
      linkColumns: {
        [linkTypeId]: {
          linkDirection: direction,
          linkFilterUrlParamKey: filter === LinkFilterEnum.containsUrlParamKey ? linkFilterUrlParamKey : undefined,
        },
      },
    };
    this.applyLinkFiltersOnData();
    this.invokeLazyLoadData();
  }

  async onLinkFilterClear(columnMetaData: NewTableColumnMetaData, linkFilter: LinkFilterEnum | null): Promise<void> {
    const linkTypeId = columnMetaData.linkRestrictionParams?.linkTypeId;
    if (!linkFilter || !linkTypeId) return;
    if (this.selected.linkFiltersNew[linkFilter] && this.selected.linkFiltersNew[linkFilter].linkColumns[linkTypeId || '']) {
      this.removeFromSelectedLinkFilters(linkFilter, linkTypeId);
    }
    this.applyLinkFiltersOnData();
    this.invokeLazyLoadData();
  }

  async invokeLazyLoadData(lazyLoadEvent: LazyLoadEvent | null = this.latestLazyLoadEvent) {
    lazyLoadEvent && (await this.onLazyLoad(lazyLoadEvent));
  }

  onThreeDotsClick($event: MouseEvent, overlayPanel: OverlayPanel, artifact: NewArtifact): void {
    this.addToSelectionIfNeeded(artifact);
    overlayPanel.show($event);
  }

  onEditSelectionClick(): void {
    const dialogRef = this.dialogService.open(BulkArtifactEditPopupComponent, {
      header: this.translateService.instant('Select Attributes to change:'),
      width: '95vw',
      height: '100%',
      style: {
        boxShadow: 'none',
        justifyContent: 'center',
      },
      contentStyle: {
        overflow: 'hidden',
      },
      data: {
        artifacts: this.selection,
        artifactTypes: this.options.artifactTypes,
        attributes: this.options.attributes,
        dataTypes: this.options.dataTypes,
        users: this.options.users,
      },
    });

    dialogRef.onClose.pipe(tap((updated: boolean) => this.updateListAndResetSelection(updated))).subscribe();
  }

  onMoveSelectionClick(folderPicker: FolderPickerComponent): void {
    folderPicker.showFolderPicker(new NewArtifact(), false);
  }

  async onFolderSelect(folder: FolderTreeNode): Promise<void> {
    const artifacts = this.filterUtil.toArray(this.selection);
    const { success, failure, skip } = await this.artifactMovementService.moveArtifacts(artifacts, folder, this.options.attributes, this.options.dataTypes);
    await this.announcement.info(`Success: ${success}. Failure: ${failure}. Skip: ${skip}`);
    this.updateListAndResetSelection(true);
  }

  private updateListAndResetSelection(updated: boolean): void {
    if (updated) {
      const defaultLazyLoadEvent = { first: 0, rows: 50, sortOrder: 1, filters: {}, globalFilter: null };
      this.onLazyLoad(this.latestLazyLoadEvent || defaultLazyLoadEvent);
      this.resetSelection();
    }
  }

  private prepareNumericSummaryObject(artifact: NewArtifact, attributeDataTypes: Record<string, NewDataType>, groupItem?: GroupItem): void {
    for (const attributesKey in artifact.attributes) {
      attributeDataTypes[attributesKey] = GetDataTypeByAttributeId(attributesKey, this.options.attributes.listMap, this.options.dataTypes.listMap);
      if (groupItem && this.m.attributeSummaryItems[attributesKey]) {
        this.m.attributeSummaryItems[attributesKey] = new SummaryItem(this.m.attributeSummaryItems[attributesKey]);
        this.m.attributeSummaryItems[attributesKey].groupSummary[groupItem.id] = 0;
      } else {
        this.m.attributeSummaryItems[attributesKey] = new SummaryItem();
      }
    }
  }

  private isAttributeNumeric(attributeDataTypes: Record<string, NewDataType>, id: string): boolean {
    return (
      (attributeDataTypes[id]?.baseDataType === BaseDataType.integer && attributeDataTypes[id]?.kind !== DataTypeKind.enumerated) ||
      attributeDataTypes[id]?.baseDataType === BaseDataType.decimal
    );
  }

  private calculateGroupAttributeSummary(artifact: NewArtifact, attributeId: string): void {
    const groupingValue = this.artifactGroupingService.getGroupingValueForArtifact(artifact, this.getGroupingAttribute());
    if (!this.m.attributeSummaryItems[attributeId].groupSummary[groupingValue]) {
      this.m.attributeSummaryItems[attributeId].groupSummary[groupingValue] = 0;
    }
    this.m.attributeSummaryItems[attributeId].groupSummary[groupingValue] += +artifact.attributes[attributeId].value;
  }

  private setNumericFlagAndAddValue(artifact: NewArtifact, attributeId: string): void {
    if (this.m.attributeSummaryItems[attributeId]) this.m.attributeSummaryItems[attributeId].isNumeric = true;
    this.m.attributeSummaryItems[attributeId].summary += +artifact.attributes[attributeId].value;
  }

  private getGroupItems(): GroupItem[] {
    return this.groupItems || this.artifactGroupingService.getAllGroups(this.hash, this.settings.grouping);
  }

  private postProcessFilters(complexFilterData: Record<string, ComplexFilterMetadata>): Record<string, any> {
    this.processAdvancedDateFilters(complexFilterData);
    if (!this.linkArtifactMeta) {
      return this.processFilters(complexFilterData);
    }
    // postprocessing of link filters
    const artifactMeta = cloneDeep(this.linkArtifactMeta);
    artifactMeta.$and.push(this.processFilters(complexFilterData));
    return artifactMeta;
  }

  private processAdvancedDateFilters(filterData: Record<string, ComplexFilterMetadata>): void {
    const { dateFilters } = this.selected;
    Object.keys(filterData).forEach(key => {
      if ([FilterType.date, FilterType.datetime].includes(filterData[key].tableColumnMetadata.filterType as FilterType)) {
        const filters = Array.isArray(filterData[key]) ? dateFilters[key]?.filters : filterData[key]?.filterMetadata;
        filters.forEach((filter: any) => {
          const value = filter.value;
          const filterType = filter?.filterType || filter?.matchMode;
          if (!filterType) return;

          if (Object.prototype.hasOwnProperty.call(DateRangeFilterEnum, filterType)) {
            const customSettings: CustomDateSettings =
              filterType === DateRangeFilterEnum.dateBetween
                ? new CustomDateSettings({
                    start: new Date(value[0]),
                    end: new Date(value[1]),
                    isDateTime: filterData[key].tableColumnMetadata.filterType === FilterType.datetime || [UPDATED_ON_KEY, CREATED_ON_KEY].includes(key),
                  })
                : new CustomDateSettings();
            customSettings.offsetInDays = this.filterMetadataUtil.isFilterNumeric(filterType) && value ? +value : customSettings.offsetInDays;
            const startEndDate = this.filterMetadataUtil.transformDateRangeEnumToDateRange(filterType, customSettings);
            filterData[key].filterMetadata = this.filterMetadataUtil.getFilterMetadataFromStartEndDate(startEndDate);
          } else {
            if (!this.isGroupingActive() || !filterData[key].filterMetadata[0].matchMode) {
              filterData[key].filterMetadata[0].matchMode = filterType;
            }
          }
        });
      }
    });
  }

  private retrieveDataForGroup$(event: LazyLoadEvent, groupItem?: GroupItem): Observable<NewArtifact[]> {
    const { rows, first, filters, sortField, sortOrder } = event;
    let sortFields = sortField ? [sortField] : [];
    let sortOrders = sortOrder ? [sortOrder] : [];

    // handling for initiating url filters for the first table loading time (will be invoked just one)
    // function synchronizes url filters with tha table ones
    if (!this.latestLazyLoadEvent) {
      if (this.initialUrlFiltersMap && filters) {
        Object.keys(this.initialUrlFiltersMap).forEach(key => {
          filters[key] = this.initialUrlFiltersMap[key] as any;
          if (this.selected.dateFilters[key]) {
            this.selected.dateFilters[key].filters[0].filterType = this.initialUrlFiltersMap[key][0].matchMode as DateRangeFilterEnum | DateFilterEnum;
            this.selected.dateFilters[key].filters[0].value = this.initialUrlFiltersMap[key][0].value;
          }
        });
      }
      // initial sorts
      if (this.initialUrlSort) {
        sortFields = [this.initialUrlSort.field];
        sortOrders = [this.initialUrlSort.order];
        this.table.sortField = this.initialUrlSort.field;
        this.table.sortOrder = this.initialUrlSort.order;
        this.sortMainControlService.doNotifyResetInternalSorts(this.ids.hash);
      }
    }
    const updatedFilters = filters && cloneDeep(filters);

    if (this.isGroupingByDateOrDateTime()) {
      this.addFilterFromGrouping(updatedFilters);
    }

    let totalCount = 0;
    if (groupItem) {
      this.latestLazyLoadEventsMap[groupItem.id] = event;
      const groupMetaItem = this.groupMetaItems[groupItem.id];
      totalCount = groupMetaItem?.listMetadata?.totalCount;
      // filter handling
      if (updatedFilters) {
        const attributeId = this.settings.grouping.groupingAttributes[0].value.id;
        let groupFilter: any = this.artifactGroupingService.getFilterMetadataForGroupItem(this.hash, groupItem);
        if (![BaseDataType.dateTime, BaseDataType.date].includes(this.settings.grouping.groupingAttributes[0].value.baseDataType))
          groupFilter = [groupFilter] as any;
        if (groupFilter[0] && groupFilter[0].value && groupFilter[0].value[0] === 'empty') {
          groupFilter[0].value = [EMPTY_FILTER_VALUE_SINGLE];
        }
        updatedFilters[attributeId] = groupFilter;
      }
    } else {
      this.latestLazyLoadEvent = event;
      totalCount = this.meta.totalCount;
    }

    // filtering
    const complexFiltersData = this.transformFiltersToComplexData(updatedFilters as Record<string, FilterMetadata[]>);
    // sorting
    if (this.settings.grouping.groupingAttributes.length && this.settings.grouping.sortSettings.order === GroupOrderEnum.orderFirst) {
      sortFields && sortFields.unshift(this.settings.grouping.groupingAttributes[0].value.id);
      sortOrders && sortOrders.unshift(this.settings.grouping.sortSettings.sortType === SortTypeValueEnum.ASC ? 1 : -1);
    }
    const processedFilters = this.postProcessFilters(complexFiltersData);
    this.latestProcessedFilters = processedFilters;
    // update filter value for filter from url
    updatedFilters &&
      Object.keys(updatedFilters).forEach(key => {
        this.selected.userFilters[key] &&
          (this.selected.userFilters[key].value = Array.isArray(updatedFilters[key]) ? (updatedFilters[key] as any)[0].value : updatedFilters[key].value);
      });

    // external url attributes filters
    if (this.externalUrlAttrFilters) {
      processedFilters['$and'] = Object.prototype.hasOwnProperty.call(processedFilters, '$and')
        ? [...processedFilters['$and'], this.externalUrlAttrFilters]
        : [this.externalUrlAttrFilters];
    }
    // data loading
    const meta = new ListMetaData(rows, first, processedFilters, this.transformMultiSorting(sortFields, sortOrders), totalCount);
    const folderFilter = this.getFolderFilterQueryAddon();
    const externalFilters: Record<string, any> = {
      deleted: { $eq: null },
      ...folderFilter,
    };
    if (this.settings.loadMode === ListWidgetTableLoadModeEnum.byArtifactType) {
      externalFilters.artifactTypeId = { $in: this.selected.artifactTypes.map(item => ({ $oid: item.value.id })) };
    }
    if (this.settings.loadMode === ListWidgetTableLoadModeEnum.byFolder) {
      externalFilters['folderData.parentId'] = { $eq: { $oid: this.settings.folderId } };
    }
    if (this.settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
      externalFilters['folderData.parentId'] = {
        $eq: { $oid: this.queryParams[this.settings.urlKeys.listeningKeys.artifactId.toLowerCase()] ? this.settings.folderId || null : null },
      };
    }

    const query = meta.toQuery(externalFilters);
    this.currentFilterQuery = query.filter;
    return from(this.loadDataMethod(query)).pipe(
      tap(result => {
        meta.totalCount = result.meta.totalCount;
        if (groupItem && this.groupMetaItems[groupItem.id]) {
          this.computeNumericSummaryForData(result.data, groupItem);
          this.totalCountMap[groupItem.id] = meta.totalCount;
          this.groupMetaItems[groupItem.id].listMetadata = meta;
        } else {
          this.meta = meta;
        }
      }),
      tap(result => this.options.artifacts.setList(result.data, 'id')),
      map(result => result.data.map(dto => this.alwArtifactService.artifactDtoToNewArtifact(dto, this.options))),
      tap(result => {
        const ruleTriggerEvent: WorkflowTriggerEvent = {
          pageId: this.pageId,
          definition: new WorkflowTriggerWidgetDataLoad(this.widgetId),
          payload: {
            artifacts: result,
          },
        };
        this.ruleTriggerEventHandler.notifyTriggerEvent(ruleTriggerEvent);
      }),
    );
  }

  private getTableItemForSseUpdate$(artifactId: string, lazyLoadEvent: LazyLoadEvent = this.latestLazyLoadEvent as LazyLoadEvent): Observable<NewArtifact[]> {
    const complexFiltersData = this.transformFiltersToComplexData(lazyLoadEvent?.filters as Record<string, FilterMetadata[]>);
    const processedfilters = this.postProcessFilters(complexFiltersData);
    const externalFilters = {
      artifactTypeId: { $in: this.selected.artifactTypes.map(item => ({ $oid: item.value.id })) },
      _id: { $in: [{ $oid: artifactId }] },
    };
    return this.alwArtifactService.getTableItemByFilters$(this.options, processedfilters, externalFilters);
  }

  private initTableData(): void {
    this.teamsWithUsers = new TeamsWithUsers(this.options.users.list);
    this.settings.groupingOptions = this.listWidgetTableHelper.initAndGetGroupingOptions(this.options, this.settings, this.selected);
    this.listWidgetTableHelper.synchronizeGroupingSettings(this.settings);
    this.initColumns();
    this.initTableGroups();
    this.initTableFormatHandling();
  }

  private getFolderFilterQueryAddon(): Record<string, any> {
    let folderFilter = {};
    if (this.selected.folderFilter) {
      const folderQueryParams: string[] = this.queryParams[this.selected.folderFilter.containsUrlParamKey.toLowerCase()]?.split(',');
      if (folderQueryParams) {
        folderFilter = { 'folderData.parentId': { $in: folderQueryParams.map(folderId => ({ $oid: folderId })) } };
      }
    }
    return folderFilter;
  }

  // TODO: refactor this
  private getFiltersOutsideView(): void {
    this.m.filtersOutsideView = {};
    if (this.table) {
      for (const [key, value] of Object.entries(this.table.filters)) {
        if (key && !this.selected.columns.find(col => col.key?.includes(key))) {
          if (Array.isArray(value))
            value.forEach(val => {
              if (val.value || val.value?.length) {
                if (!this.m.filtersOutsideView[key]) this.m.filtersOutsideView[key] = [];
                this.m.filtersOutsideView[key].push(val);
              }
            });
        }
      }
      this.m.tooltipFiltersOutsideView = '';

      Object.keys(this.m.filtersOutsideView).forEach(key => {
        this.m.tooltipFiltersOutsideView += [...this.m.columns.attribute, ...this.m.columns.default].find(col => col.key?.includes(key))?.label;
        const rules: string[] = [];
        this.m.filtersOutsideView[key].forEach((val: any) => {
          const attribute = this.options.attributes.listMap[key];
          let filterValues;
          if (attribute && Array.isArray(val.value)) {
            filterValues = val.value
              .map((v: any) => {
                if (v === 'currentUser') return `Current user (${this.m.currentUser.email})`;
                const dataType = this.options.dataTypes.listMap[attribute.dataTypeId];
                if (dataType?.baseDataType === BaseDataType.user) return this.options.users.listMap[v]?.email;
                return dataType?.values?.find(detail => detail?.value === v);
              })
              .map((v: any) => v?.label || v)
              .join(', ');
          } else {
            filterValues = cloneDeep(val.value);
            if (val.value && val.value[0] === 'currentUser') filterValues[0] = `Current user (${this.m.currentUser.email})`;
          }
          rules.push(` ${FilterToClientEnum[val.matchMode as keyof typeof FilterToClientEnum]}: ${filterValues}`);
        });
        this.m.tooltipFiltersOutsideView += rules.join(',') + '\n';
      });
    }
  }

  private initColumns(): void {
    this.columns$ = this.selected.columns$.pipe(
      tap(columns => {
        this.getFiltersOutsideView();
        this.initDateFilters(columns);
        this.initUserFilters(columns);
        this.initAvailableAtColumns();
        this.loadHelper
          .loadDataLinksWithLinkedArtifactsToModel$(
            this.data,
            this.selected.columns.filter(col => col?.meta?.isLink),
            this.isPaginationByTable,
          )
          .subscribe();

        // initing loading of card widget if applicable...
        columns
          .filter(column => !!column.meta.columnFormatSettings?.selectedVariantMetadata?.cardWidgetId)
          .forEach(column => {
            const { cardWidgetId } = column.meta.columnFormatSettings.selectedVariantMetadata;
            this.alwNestedWidgetLoaderService.loadNestedWidget(this.hash, column.key, cardWidgetId, this.options);
          });

        if (!this.availableAtColumns[NonAttributeKeys.ARTIFACT_TYPE]) {
          const artifactTypeColumn = columns.find(column => NonAttributeKeys.isArtifactTypeKey(column.key)) as NewTableColumn;
          this.setEnumOptionsToArtifactTypeColumn(artifactTypeColumn);
        }
        // this.initColumnTypeMaps(columns, this.options.dataTypes, this.options.attributes);
      }),
    );
    this.m.columns.default = this.options.columns.list.filter(column => NonAttributeKeys.isDefaultNonAttributeKey(column.key));
    this.m.columns.attribute = this.getAttributeColumns();
    this.m.columns.linkType = this.options.columns.list.filter(column => column.meta.isLink);
    this.m.columns.editableKeys = [...this.m.columns.attribute.map(col => col.key as string), NonAttributeKeys.IS_HEADING];
    this.m.columns.sortableKeys = [...this.m.columns.editableKeys, ...this.m.columns.default.map(col => col.key as string)];
  }

  private setEnumOptionsToArtifactTypeColumn(column: NewTableColumn): any {
    if (this.settings.loadMode === ListWidgetTableLoadModeEnum.byArtifactType) {
      column.meta.artifactTypeEnumOptions = this.selected.artifactTypes.map(artifactTypeOption => this.createDataTypeValueResult(artifactTypeOption.value));
    } else {
      column.meta.artifactTypeEnumOptions = this.options.artifactTypes.list.map(artifactType => this.createDataTypeValueResult(artifactType));
    }
  }

  private createDataTypeValueResult(artifactType: NewArtifactType): DataTypeValueResponseDto {
    return {
      label: artifactType.name,
      value: artifactType.id,
      icon: artifactType.icon,
      backgroundColor: null,
      textColor: null,
      iconColor: null,
      uri: '',
    };
  }

  private getAttributeColumns(): NewTableColumn[] {
    return this.listWidgetTableHelper
      .getRelevantAttributeIds(this.options, this.settings, this.selected)
      .map(attributeId => this.options.columns.listMap[attributeId]);
  }

  private initDateFilters(columns: NewTableColumn[]): void {
    columns
      .filter(col => col.meta.filterType === FilterType.date || col.meta.filterType === FilterType.datetime)
      .forEach(col => !this.selected.dateFilters[col.key] && (this.selected.dateFilters[col.key] = new AdvancedDateFilterObject()));
  }

  private initUserFilters(columns: NewTableColumn[]): void {
    columns
      .filter(col => col.meta.filterType === FilterType.user)
      .forEach(col => !this.selected.userFilters[col.key] && (this.selected.userFilters[col.key] = new UserFilterEntry()));
  }

  private initTableGroups(): void {
    this.artifactGroupingService.initGroupingDataForWidget(this.hash);
    const groupingSettings = this.settings.grouping;
    if (!groupingSettings.groupingAttributes.length) {
      this.isPaginationByTable = true;
      const defaultGroup = this.artifactGroupingService.getDefaultGroup(this.hash);
      this.groupMetaItems[defaultGroup.id] = {
        groupItem: defaultGroup,
        listMetadata: new ListMetaData(),
      };
      return;
    }
    // there is selected at least one attribute for grouping
    this.isPaginationByTable = groupingSettings.pagination === PaginationSettingEnum.perTable;
    if (groupingSettings.showEmptyGroups || groupingSettings.pagination === PaginationSettingEnum.perGroup) {
      this.groupItems = this.artifactGroupingService.getAllGroups(this.hash, groupingSettings);
      this.groupItems.forEach(groupItem => {
        this.groupMetaItems[groupItem.id] = { groupItem, listMetadata: new ListMetaData() };
      });
    }
  }

  private initTableFormatHandling(): void {
    const columnContextChange$ = this.tableColumnControlService
      .getColumnContextChange$(this.ids.hash)
      .pipe(tap(columnContextChangeEvent => this.doUpdateTableStateOnFormatChange(columnContextChangeEvent)));

    this.registerSubscription(columnContextChange$.subscribe());
  }

  private doUpdateTableStateOnFormatChange(columnContextChangeEvent: TableColumnContextChangeEvent): void {
    if (!this.state[TABLE_FORMAT_STATE_KEY]) {
      this.state[TABLE_FORMAT_STATE_KEY] = new TableFormatSettings();
    }
    const tableFormatSettings = this.state[TABLE_FORMAT_STATE_KEY] as TableFormatSettings;
    const columnKey = columnContextChangeEvent.tableColumn.key;
    if (!tableFormatSettings.columns[columnKey]) {
      tableFormatSettings.columns[columnKey] = { columnKey };
    }
    const tableColumnFormatSettings = tableFormatSettings.columns[columnKey] as TableColumnFormatSettings;
    switch (columnContextChangeEvent.changeType) {
      case TableColumnContextChangeType.STYLES:
        tableColumnFormatSettings.columnFormat = columnContextChangeEvent.tableColumnFormatSettings.columnFormat;
        tableColumnFormatSettings.contentColumnFormat = columnContextChangeEvent.tableColumnFormatSettings.contentColumnFormat;
        break;
      case TableColumnContextChangeType.VARIANT:
        tableColumnFormatSettings.selectedVariant = columnContextChangeEvent.tableColumnFormatSettings.selectedVariant;
        tableColumnFormatSettings.selectedVariantMetadata = columnContextChangeEvent.tableColumnFormatSettings.selectedVariantMetadata;
        tableColumnFormatSettings.selectedEditVariant = columnContextChangeEvent.tableColumnFormatSettings.selectedEditVariant;
        break;
    }
    this.listWidgetService.updateStateInLocalStorage(this.ids.hash, TABLE_FORMAT_STATE_KEY, tableFormatSettings);
  }

  private registerSseEvents(): void {
    this.registerSubscriptions([
      this.runtimeStateNotificationService.events$
        .pipe(
          // TODO: consider to include link according old artifact list table component
          filter(event => this.shouldReactToSse(event)),
        )
        .subscribe(event => {
          this.zone.run(() => {
            switch (event.type) {
              case RuntimeStateNotificationEnum.createArtifact:
                this.onSseCreateArtifact(event);
                break;
              case RuntimeStateNotificationEnum.updateArtifact:
                this.onSseUpdateArtifact(event);
                break;
              case RuntimeStateNotificationEnum.deleteArtifact:
                this.onSseDeleteArtifact(event);
                break;
              case RuntimeStateNotificationEnum.createLink:
              case RuntimeStateNotificationEnum.updateLinks:
                // TODO: check if for the update links scenario is good onSseCreateLink()
                // update is used e.g. when link was created, then deleted and then again created
                this.onSseCreateLink(event);
                break;
              // case RuntimeStateNotificationEnum.updateLinks:
              //   this.onSseUpdateLinks(event);
              //   break;
              case RuntimeStateNotificationEnum.deleteLink:
                this.onSseDeleteLink(event);
                break;
            }
          });
          // TODO: complete link changes
          // else if ;
        }),
    ]);
  }

  private registerDisplayAtEvents(): void {
    const customVariant$ = this.displayAtControlService.getCustomVariantSelection$().pipe(
      filter(datCustomVariant => datCustomVariant.ownerId === this.hash),
      tap(datCustomVariant => {
        const { ownerId, attributeId, cardWidgetId } = datCustomVariant;
        this.alwNestedWidgetLoaderService.loadNestedWidget(ownerId, attributeId, cardWidgetId, this.options);
      }),
    );
    const attributeValueUpdate$ = this.displayAtControlService.getValueSelection$().pipe(
      filter(valueSelectionItem => valueSelectionItem.ownerId === this.hash),
      switchMap(valueSelectionItem => {
        const { artifact, attributeId, value } = valueSelectionItem;
        return this.alwArtifactService.doUpdateArtifactAttribute$(artifact, attributeId, value, this.options).pipe(
          tap(artifactDto => {
            this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.updateArtifact, artifactDto, this.ids.hash);
            const updatedArtifact = new NewArtifact({
              dto: artifactDto,
              artifactTypesMap: this.options.artifactTypes.listMap,
            });
            this.cache.data.artifacts.setItem(artifactDto);
            this.listWidgetTableHelper.mapArtifactAttributesToClient(updatedArtifact, this.options.attributes, this.options.dataTypes, this.options.users);
            this.updateItemInDataCollection(updatedArtifact);
            this.updateItemInGroupCollection(artifact, updatedArtifact);
          }),
        );
      }),
    );
    const displayAtEvents$ = merge(customVariant$, attributeValueUpdate$);

    this.registerSubscriptions([displayAtEvents$.subscribe()]);
  }

  private shouldReactToSse(event: RuntimeStateNotification): boolean {
    return event.hash !== this.hash || event.hash === this.sseService.runtimeKey;
  }

  private onSseCreateArtifact(event: RuntimeStateNotification): void {
    if (!this.isSseApplicableArtifactType(event.data as ArtifactResponseDto)) {
      return;
    }
    const dto = event.data as ArtifactResponseDto;
    this.doAddOnSseCreateArtifact(dto.id);
  }

  private doAddOnSseCreateArtifact(dtoId: string): void {
    this.computeNumericSummaryForData(this.data);
    const meta = this.meta;
    const groupItem = this.artifactGroupingService.getDefaultGroup(this.hash);
    const groupMetaItem = this.groupMetaItems[groupItem.id];

    if (this.isSseAddSupported()) {
      if (this.artifactGroupingService.findArtifactInGroups([groupItem], dtoId) || this.sseAddingArtifactIdInProgress === dtoId) {
        // if item is already in table or represents artifact id, whose operation is currently in progress, do nothing and quit
        return;
      }
      this.sseAddingArtifactIdInProgress = dtoId;
      // here comes handling for adding item to the top and remove the last one
      let addedArtifact: NewArtifact;
      this.alwLinkService
        .getLinkArtifactMeta$(this._queryParams, this.selected.linkFiltersNew)
        .pipe(
          tap(linkArtifactMeta => (this.linkArtifactMeta = linkArtifactMeta)),
          switchMap(() => this.getTableItemForSseUpdate$(dtoId)),
          filter(artifacts => artifacts.length > 0),
          map(artifacts => {
            addedArtifact = artifacts[0];
            return addedArtifact;
          }),
          switchMap(artifact => {
            if (meta.skip) {
              // message for manual reloading...
              this.sseAddedItems++;
              this.cdr.markForCheck();
              return of(artifact);
            }
            return this.alwLinkService.getLinksDataForArtifacts$([artifact], this.ids.applicationId, this.m, this.options);
          }),
          filter(() => !meta.skip && !this.artifactGroupingService.findArtifactInGroups([groupItem], dtoId)),
          tap(() => {
            this.addItemIntoGroupCollection(addedArtifact);
            if (this.meta.totalCount > meta.limit) {
              groupMetaItem.groupItem = this.artifactGroupingService.removeLastItemFromGroup(groupItem);
              groupMetaItem.data$ = of(groupItem.artifacts);
              this.updateGroupInCollection(groupMetaItem.groupItem, true);
            }
          }),
          finalize(() => (this.sseAddingArtifactIdInProgress = undefined)),
        )
        .subscribe();
    } else {
      this.sseAddedItems++;
      this.cdr.markForCheck();
    }
  }

  private onSseUpdateArtifact(event: RuntimeStateNotification): void {
    if (this.moduleHelper.isLoadByModule(this.settings.loadMode)) {
      this.refreshModuleDataIfNeeded(event);
    }

    const updatedItem = this.alwArtifactService.artifactDtoToNewArtifact(event.data, this.options);
    if (!this.isSseApplicableArtifactType(updatedItem)) {
      return;
    }
    // TODO: only call when showSum = true
    this.computeNumericSummaryForData(this.data);
    const groupItemArtifactObject = this.artifactGroupingService.findArtifactInGroups(this.groupItems, updatedItem.id);
    if (groupItemArtifactObject) {
      this.updateItemInGroupCollection(groupItemArtifactObject.artifact, updatedItem, true);
    }
  }

  private refreshModuleDataIfNeeded(event: RuntimeStateNotification): void {
    if (this.queryParams[this.settings.urlKeys.listeningKeys.artifactId.toLowerCase()] === event.data.id) {
      this.invokeLazyLoadData();
    }
  }

  private onSseDeleteArtifact(event: RuntimeStateNotification): void {
    const deletedItem = event.data as NewArtifact;
    if (!this.isSseApplicableArtifactType(deletedItem)) {
      return;
    }
    const groupItemArtifactObject = this.artifactGroupingService.findArtifactInGroups(this.groupItems, deletedItem.id);
    if (groupItemArtifactObject) {
      const { groupItem, artifact } = groupItemArtifactObject;
      this.removeItemFromGroupCollection(groupItem, artifact, true);
    }
  }

  private onSseCreateLink(event: RuntimeStateNotification): void {
    const { linkFiltersNew } = this.selected;
    const artifactItems = event.data as string[];
    if (this.isGivenLinkTypeFilterActive(linkFiltersNew, event.extras)) {
      const artifactId =
        this.queryParams[(linkFiltersNew[LinkFilterEnum.containsUrlParamKey].linkColumns[event.extras].linkFilterUrlParamKey as string)?.toLowerCase()];
      if (artifactId && artifactItems.includes(artifactId)) {
        const addedArtifactId = artifactItems.filter(id => id !== artifactId)[0];
        this.doAddOnSseCreateArtifact(addedArtifactId);
      }
      return;
    }
    this.onSseUpdateLinks(event);
  }

  private onSseUpdateLinks(event: RuntimeStateNotification): void {
    const artifactItems = event.data as string[];
    const presentArtifacts = artifactItems
      .map(itemId => this.artifactGroupingService.findArtifactInGroups(this.groupItems, itemId))
      .filter(groupItemArtifactObject => groupItemArtifactObject !== undefined)
      .map(groupItemArtifactObject => groupItemArtifactObject?.artifact) as NewArtifact[];

    if (presentArtifacts.length) {
      this.alwLinkService.getLinksDataForArtifacts$(presentArtifacts, this.ids.applicationId, this.m, this.options).subscribe(() => {
        presentArtifacts.forEach(artifact => this.updateItemInGroupCollection(artifact));
      });
    }
  }

  private onSseDeleteLink(event: RuntimeStateNotification): void {
    const { linkFiltersNew } = this.selected;
    if (this.isGivenLinkTypeFilterActive(linkFiltersNew, event.extras)) {
      const artifactId =
        this.queryParams[(linkFiltersNew[LinkFilterEnum.containsUrlParamKey].linkColumns[event.extras].linkFilterUrlParamKey as string)?.toLowerCase()];
      const artifactItems = event.data as string[];
      if (artifactId && artifactItems.includes(artifactId)) {
        const removedArtifactId = artifactItems.filter(id => id !== artifactId)[0];
        const groupItemArtifactObject = this.artifactGroupingService.findArtifactInGroups(this.groupItems, removedArtifactId);
        if (groupItemArtifactObject) {
          this.removeItemFromGroupCollection(groupItemArtifactObject.groupItem, groupItemArtifactObject.artifact, true);
        }
      }
      return;
    }
    this.onSseUpdateLinks(event);
  }

  private refreshDataItemsAfterLinkLoad(): void {
    if (this.m.linkedData && Object.keys(this.m.linkedData).length) {
      this.data = this.data.map(item => {
        if (!this.m.linkedData[item.id]) {
          return item;
        }
        const artifact = this.m.linkedData[item.id];
        this.listWidgetTableHelper.mapArtifactAttributesToClient(artifact, this.options.attributes, this.options.dataTypes, this.options.users);
        return artifact;
      });
      this.notifyDataChange(this.data);
      this.notifyGroupDataChange(this.artifactGroupingService.groupTableData(this.hash, this.data, this.settings.grouping, this.settings.rowsPerPage));
    }
  }

  private loadFilesToModel(data: NewArtifact[] = this.data): void {
    const clientAttributes = this.elvisU.flatDeep(data.map(artifact => artifact.clientAttributes));
    this.loadHelper.loadFilesToModel(clientAttributes, this.options.dataTypes.listMap, this.options.attributes.listMap);
  }

  private updateItemInDataCollection(updatedItem: NewArtifact): void {
    const updatedArtifactIndex = this.data.findIndex(artifact => artifact.id === updatedItem.id);
    if (updatedArtifactIndex !== -1) this.data[updatedArtifactIndex] = updatedItem;
  }

  private updateItemInGroupCollection(item: NewArtifact, updatedItem: NewArtifact = NewArtifact.getNewInstance(item), notifyDataChange = true): void {
    // not grouping scenario
    if (!this.isGroupingActive()) {
      const groupItem = this.artifactGroupingService.getDefaultGroup(this.hash);
      const groupMetaItem = this.groupMetaItems[groupItem.id];
      groupMetaItem.groupItem = this.artifactGroupingService.updateItemInGroup(updatedItem, groupItem, false);
      groupMetaItem.data$ = of(groupItem.artifacts);
      this.updateGroupInCollection(groupMetaItem.groupItem, notifyDataChange);
      return;
    }
    // same group update
    const groupAttributeItem = this.settings.grouping.groupingAttributes[0].value;
    const itemGroupId = this.artifactGroupingService.getGroupId(this.hash, item, groupAttributeItem);
    const updatedItemGroupId = this.artifactGroupingService.getGroupId(this.hash, updatedItem, groupAttributeItem);
    const itemGroupMetaItem = this.groupMetaItems[itemGroupId];
    if (itemGroupId === updatedItemGroupId) {
      itemGroupMetaItem.groupItem = this.artifactGroupingService.updateItemInGroup(updatedItem, itemGroupMetaItem.groupItem);
      itemGroupMetaItem.data$ = of(itemGroupMetaItem.groupItem.artifacts);
      this.updateGroupInCollection(itemGroupMetaItem.groupItem, notifyDataChange);
      return;
    }
    // update group values, i.e. moving from one group to another
    itemGroupMetaItem.groupItem = this.artifactGroupingService.removeItemFromGroup(item, itemGroupMetaItem.groupItem);
    itemGroupMetaItem.data$ = of(itemGroupMetaItem.groupItem.artifacts);
    if (itemGroupMetaItem.groupItem.total) itemGroupMetaItem.groupItem.total -= 1;

    // TODO: check this as e.g. when removing value from enum, this may lead to NPE if empty group doesn't exist
    const updatedItemGroupMetaItem = this.groupMetaItems[updatedItemGroupId];
    updatedItemGroupMetaItem.groupItem = this.artifactGroupingService.addItemIntoGroup(updatedItem, updatedItemGroupMetaItem.groupItem, false);
    updatedItemGroupMetaItem.data$ = of(updatedItemGroupMetaItem.groupItem.artifacts);
    if (updatedItemGroupMetaItem.groupItem.total) updatedItemGroupMetaItem.groupItem.total += 1;

    this.updateGroupInCollection(itemGroupMetaItem.groupItem, false);
    this.updateGroupInCollection(updatedItemGroupMetaItem.groupItem, notifyDataChange);
  }

  private removeItemFromGroupCollection(group: GroupItem, item: NewArtifact, notifyDataChange = true): void {
    const groupMetaItem = this.groupMetaItems[group.id];
    groupMetaItem.groupItem = this.artifactGroupingService.removeItemFromGroup(item, groupMetaItem.groupItem);
    groupMetaItem.data$ = of(groupMetaItem.groupItem.artifacts);
    this.updateGroupInCollection(groupMetaItem.groupItem, notifyDataChange);
  }

  private addItemIntoGroupCollection(item: NewArtifact, notifyDataChange = true): void {
    // not grouping scenario
    if (!this.isGroupingActive()) {
      const groupItem = this.artifactGroupingService.getDefaultGroup(this.hash);
      const groupMetaItem = this.groupMetaItems[groupItem.id];
      groupMetaItem.groupItem = this.artifactGroupingService.addItemIntoGroup(item, groupItem, true);
      groupMetaItem.data$ = of(groupItem.artifacts);
      this.updateGroupInCollection(groupMetaItem.groupItem, notifyDataChange);
      return;
    }
    // other groups - currently not supported
    // DO NOT REMOVE THIS CODE

    // const groupAttributeItem = this.settings.grouping.groupingAttributes[0].value;
    // const groupId = this.artifactGroupingService.getGroupId(this.hash, item, groupAttributeItem);
    // const groupMetaItem = this.groupMetaItems[groupId];
    // if (groupMetaItem) {
    //   const groupItem = groupMetaItem.groupItem;
    //   groupMetaItem.groupItem = this.artifactGroupingService.addItemIntoGroup(item, groupItem);
    //   groupMetaItem.data$ = of(groupItem.artifacts);
    //   this.updateGroupInCollection(groupMetaItem.groupItem, notifyDataChange);
    // }
  }

  private updateGroupInCollection(groupItem: GroupItem, notifyDataChange = true): void {
    this.groupItems = this.groupItems.map(group => (group.id === groupItem.id ? groupItem : group));
    if (notifyDataChange) {
      this.notifyGroupDataChange(this.groupItems);
    }
  }

  private notifyDataChange(data: NewArtifact[] = this.data): void {
    // TODO: only call when showSum = true
    this.computeNumericSummaryForData(data);
    this.dataSubject.next(data);
  }

  private notifyGroupDataChange(groupItems: GroupItem[]): void {
    // TODO: only call when showSum = true
    this.computeNumericSummaryForData(groupItems.map(groupItem => groupItem.artifacts).flat());
    this.groupDataSubject.next(groupItems);
  }

  private isGroupingByDateOrDateTime(): boolean {
    const { groupingAttributes } = this.settings.grouping;
    return !!groupingAttributes.length && (IsDate(groupingAttributes[0].value.baseDataType) || IsDateTime(groupingAttributes[0].value.baseDataType));
  }

  private addFilterFromGrouping(filters: { [s: string]: FilterMetadata } | undefined): void {
    if (!filters) return;

    const { filter, customSettings } = this.settings.grouping.groupByDate;
    const startEndDate = this.filterMetadataUtil.transformDateRangeEnumToDateRange(filter, customSettings);
    filters[this.settings.grouping.groupingAttributes[0].value.id] = this.filterMetadataUtil.getFilterMetadataFromStartEndDate(startEndDate) as FilterMetadata;
  }

  private removeFromSelectedLinkFilters(selectedFilter: LinkFilterEnum, linkTypeId: string): void {
    Object.keys(LinkFilterEnum)
      .filter(filterName => this.selected.linkFiltersNew[filterName])
      .forEach(filterName => {
        delete this.selected.linkFiltersNew[filterName].linkColumns[linkTypeId];
        if (!Object.keys(this.selected.linkFiltersNew[filterName].linkColumns).length) {
          delete this.selected.linkFiltersNew[filterName];
        }
      });
  }

  private async applyLinkFiltersOnData(): Promise<void> {
    this.linkArtifactMeta = await lastValueFrom(this.alwLinkService.getLinkArtifactMeta$(this._queryParams, this.selected.linkFiltersNew));
  }

  private isSseApplicableArtifactType(artifactDto: ArtifactResponseDto): boolean {
    const artifactTypeId = artifactDto.artifactTypeId;
    return this.selected.artifactTypes.some(option => option.value.id === artifactTypeId);
  }

  private mapUnsavedFilesToAttributes(artifactId: string, attributes: NewClientAttribute[]): void {
    attributes.forEach(attr => {
      if (this.m.unSavedFiles[artifactId] && this.m.unSavedFiles[artifactId][attr.id]) {
        if (this.options.attributes.listMap[attr.id].multipleValues) {
          !!attr.value && attr.value.length && (attr.value = attr.value.concat(this.m.unSavedFiles[artifactId][attr.id]));
          (!attr.value || !attr.value.length) && (attr.value = this.m.unSavedFiles[artifactId][attr.id]);
        } else {
          attr.value = this.m.unSavedFiles[artifactId][attr.id];
        }
        delete this.m.unSavedFiles[artifactId][attr.id];
      }
    });
  }

  private onNewFileCreated(fileArtifact: ArtifactResponseDto): void {
    this.loadHelper.addFileToModel(fileArtifact);
  }

  private isSseAddSupported(): boolean {
    return !this.isGroupingActive() && (this.meta.sort[NonAttributeKeys.CREATED_ON] === -1 || this.meta.sort[NonAttributeKeys.UPDATED_ON] === -1);
  }

  private isSseLinkAddSupported(): boolean {
    return this.isSseAddSupported();
  }

  private isGivenLinkTypeFilterActive(linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>, linkTypeId: string): boolean {
    if (!linkFilters || !linkFilters[LinkFilterEnum.containsUrlParamKey]) {
      return false;
    }
    return !!linkFilters[LinkFilterEnum.containsUrlParamKey].linkColumns[linkTypeId];
  }

  private async getGroupByCountMap(event?: LazyLoadEvent): Promise<Record<string, number | undefined>> {
    const groupingAttribute = this.settings.grouping.groupingAttributes[0];
    if (!groupingAttribute) return {};

    const groupByMeta = this.getGroupByCountMeta(event);
    const groupDataCount = await lastValueFrom(this.tenantArtifactService.artifactControllerGroupby(groupByMeta));

    if (this.isGroupingByDateOrDateTime()) {
      this.transformGroupDataCountDateFormat(groupDataCount);
    }

    if (!groupDataCount.data) {
      return {};
    }

    return groupDataCount.data.reduce((dataMap: Record<string, number>, item: ArtifactGroupItemResponseDto) => {
      const value = item.value === '' ? 'empty' : item.value;
      dataMap[value] = item.count;
      return dataMap;
    }, {});
  }

  private isAnyLinkTypeFilterActive(linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>): boolean {
    return linkFilters && !!linkFilters[LinkFilterEnum.containsUrlParamKey];
  }

  private resetLinkedData(): void {
    this.m.linkedData = {};
  }

  private getGroupByCountMeta(event?: LazyLoadEvent): GroupByCountMeta {
    const groupingAttribute = this.settings.grouping.groupingAttributes[0].value;
    const groupByAttributeFilter = groupingAttribute.isNonAttribute ? groupingAttribute.id : `attributes.${groupingAttribute.id}.value`;

    const additionalFilters = event?.filters || this.latestLazyLoadEvent?.filters;
    const complexFiltersData = this.transformFiltersToComplexData(additionalFilters as Record<string, FilterMetadata[]>);
    const processedFilters = this.postProcessFilters(complexFiltersData);

    !processedFilters.$and && (processedFilters.$and = []);
    processedFilters.$and.push({ artifactTypeId: { $in: [...this.selected.artifactTypes].map(at => ({ $oid: at.value.id })) } }, { deleted: { $eq: null } });

    const groupByCountMeta: GroupByCountMeta = {
      filter: JSON.stringify(processedFilters),
      groupBy: groupByAttributeFilter,
    };

    if ([BaseDataType.date, BaseDataType.dateTime].includes(this.settings.grouping.groupingAttributes[0].value.baseDataType)) {
      groupByCountMeta.dateFormat = 'DAY';
    }

    return groupByCountMeta;
  }

  private transformGroupDataCountDateFormat(groupDataCount: GroupResponseDto): void {
    groupDataCount.data.map((data: GroupItemResponseDto) => {
      if (!data.value) data.value = 'empty';
      else {
        const [year, month, day] = data.value.split('-');
        data.value = [day, month, year].join('.');
      }
    });
  }

  private getGroupingAttribute(): GroupAttributeItem | null {
    if (!this.isGroupingActive()) return null;
    return this.settings.grouping.groupingAttributes[0].value;
  }

  private setSelectedArtifactFromUrlParams(): void {
    let isArtifactActive = false;

    this.queryParams &&
      Object.values(this.queryParams).forEach(param => {
        const selectedArtifact = this.data.find(artifact => artifact.id === param);
        if (selectedArtifact) {
          this.selected.artifact = selectedArtifact;
          isArtifactActive = true;
        }
      });

    if (!isArtifactActive) this.selected.artifact = null;
  }

  private async setQueryParamsWithUrlHandling(queryParams: Params): Promise<void> {
    let doInvokeDataLoad = false;
    const oldQueryParams = this.queryParams;
    this._queryParams = this.queryParamsUtil.toLowerCase(queryParams);

    // link filter handling
    if (this.selected.linkFiltersNew && this.selected.linkFiltersNew[LinkFilterEnum.containsUrlParamKey]) {
      doInvokeDataLoad = true;
    }
    // folder filter handling
    const folderFilterKey = this.selected.folderFilter?.containsUrlParamKey?.toLowerCase();
    if (folderFilterKey && (!oldQueryParams || oldQueryParams[folderFilterKey] !== this._queryParams[folderFilterKey])) {
      doInvokeDataLoad = true;
    }
    // module handling
    if (this.settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
      const artifactId = this.settings.urlKeys.listeningKeys.artifactId.toLowerCase();
      if (!oldQueryParams || oldQueryParams[artifactId] !== this._queryParams[artifactId]) {
        doInvokeDataLoad = true;
      }
      await this.handleModuleOnQueryParamChange(queryParams);
    }
    // initing required structures
    if (this.shouldInitAvailableAtColumns()) {
      this.initAvailableAtColumns();
    }
    // general filter on url params change
    const urlFilteringOn = this.settings.doFilterOnUrlChange;
    this.filterUrlControlService.doNotifyUrlQueryParamsChange({ ownerId: this.ids.hash, urlQueryParams: this._queryParams, urlFilteringOn });
    if (urlFilteringOn) {
      if (Object.keys(this._queryParams).length) {
        // next handling is for initial loading state, when some external filters are loaded from url
        if (!this.latestLazyLoadEvent) {
          this.initialUrlFiltersMap = {};
          this.selected.columns.forEach(column => {
            const attrLabel = column.meta.filterType === FilterType.folder ? FOLDER_FILTER_KEY.toLowerCase() : column.label;
            const columnUrlQueryValue = this._queryParams[this.filterUrlControlService.getUrlQueryAttributeName(attrLabel)] as string;
            if (columnUrlQueryValue && column.meta?.filterUrlTypeService) {
              const updatedUrlQueryValue = column.meta.filterUrlTypeService.getUrlQueryAttributeValue(columnUrlQueryValue);
              this.initialUrlFiltersMap[column.key] = column.meta.filterUrlTypeService.getFilterMetadata(updatedUrlQueryValue);
            }
          });
        }
        const externalUrlfiltersCandidates = Object.keys(this._queryParams).filter(key => Object.prototype.hasOwnProperty.call(this.availableAtColumns, key));
        if (externalUrlfiltersCandidates.length) {
          this.externalUrlAttrFilters = {};
          // TODO: handle external filters (out of view) as well
          externalUrlfiltersCandidates.forEach(key => {
            const dataType = this.options.dataTypes.listMap[this.availableAtColumns[key]?.meta.attributeMetadata?.attribute.dataTypeId as string] || null;
            // either it is attribute or system attribute (in that case the conversion from url filter format to original must be done)
            const attributeKey = this.availableAtColumns[key]?.key || key?.replace('-', '.');
            const filterQuery = this.filterMainControlService.getQueryFromRawText(attributeKey, dataType, this._queryParams[key]);
            this.externalUrlAttrFilters = { ...this.externalUrlAttrFilters, ...filterQuery };
          });
          doInvokeDataLoad = true;
        } else if (this.latestLazyLoadEvent && this.externalUrlAttrFilters) {
          this.externalUrlAttrFilters = undefined;
          doInvokeDataLoad = true;
        }
      } else if (this.latestLazyLoadEvent && this.externalUrlAttrFilters) {
        this.externalUrlAttrFilters = undefined;
        doInvokeDataLoad = true;
      }
    }
    // general sort on url params change
    let isExternalSortChange = false;
    /** flag saying, where table sort should be reset on clearance of external attribute */
    let shouldResetTableSort = false;
    if (this.settings.doSortOnUrlChange) {
      const sortAttributeObject = this.sortMainControlService.getSortAttributeObject(this._queryParams);
      this.sortMainControlService.doNotifySortUpdateChange(this.ids.hash, sortAttributeObject, true);
      if (sortAttributeObject) {
        const { attributeName, sortType } = sortAttributeObject;
        // external sorts handling => those attributes which are not among selected
        const isExternal = !!this.availableAtColumns[attributeName];
        if (isExternal) {
          const sortMeta = this.sortMainControlService.getSortMeta(attributeName, sortType as SortTypeEnum);
          isExternalSortChange = !this.externalUrlSort || this.externalUrlSort.field !== attributeName || this.externalUrlSort.order !== sortMeta.order;
          if (isExternalSortChange) {
            this.externalUrlSort = {
              field: this.availableAtColumns[sortMeta.field].key,
              order: sortMeta.order,
            };
            doInvokeDataLoad = true;
          }
        }
        // next handling is for initial loading state, when some external sorts are loaded from url
        if (!this.latestLazyLoadEvent) {
          if (isExternal && this.externalUrlSort) {
            this.initialUrlSort = this.externalUrlSort;
          } else {
            const sortedColumn = this.selected.columns.filter(column => this.attributeUtil.getUrlQueryAttributeName(column.label) === attributeName)[0];
            if (sortedColumn) {
              this.initialUrlSort = this.sortMainControlService.getSortMeta(sortedColumn.key, sortType as SortTypeEnum);
            }
          }
        }
      } else if (this.latestLazyLoadEvent && this.externalUrlSort) {
        if (this.table.sortField === this.externalUrlSort.field) {
          shouldResetTableSort = true;
        }
        this.externalUrlSort = undefined;
        doInvokeDataLoad = true;
      }
    }

    if (doInvokeDataLoad) {
      const lazyLoadEvent = this.latestLazyLoadEvent && { ...this.latestLazyLoadEvent, first: 0 };
      if (lazyLoadEvent && isExternalSortChange && this.externalUrlSort) {
        lazyLoadEvent.sortField = this.externalUrlSort.field;
        lazyLoadEvent.sortOrder = this.externalUrlSort.order;
        this.table.sortField = this.externalUrlSort.field;
        this.table.sortOrder = this.externalUrlSort.order;
        this.sortMainControlService.doNotifyResetInternalSorts(this.ids.hash);
      }
      if (lazyLoadEvent && shouldResetTableSort) {
        lazyLoadEvent.sortField = undefined;
        lazyLoadEvent.sortOrder = undefined;
      }
      this.invokeLazyLoadData(lazyLoadEvent);
    }

    this.setSelectedArtifactFromUrlParams();
  }

  private async handleModuleOnQueryParamChange(queryParams: Params): Promise<void> {
    const moduleId = queryParams[this.settings.urlKeys.listeningKeys.artifactId];
    const isNewModule = moduleId && moduleId !== this.selected.module?.id;

    isNewModule && (await this.setModuleToModel(moduleId));
  }

  private async setModuleToModel(moduleId: string): Promise<void> {
    try {
      const moduleDto = await this.cache.data.artifacts.getAsync(moduleId);

      if (!moduleDto || !moduleDto.formatData || moduleDto.format !== ArtifactTypeFormatEnum.module) {
        this.selected.module = null;
        return;
      }

      const moduleArtifact = new NewArtifact({ dto: moduleDto, artifactTypesMap: this.options.artifactTypes.listMap });
      const moduleArtifactType = this.options.artifactTypes.listMap[moduleArtifact.artifactTypeId];

      this.settings.folderId = (moduleArtifact.formatData as ArtifactFormatModuleDataResponseDto).folderId || '';
      this.selected.artifactTypes = [new SelectOption(moduleArtifactType.name, moduleArtifactType)];
      this.selected.module = moduleArtifact;
      this.listWidgetHelper.generateAddButtonOptions();
    } catch (e) {
      console.error('There was problem setting module to model');
    }
  }

  private shouldInitAvailableAtColumns(): boolean {
    return !this.availableAtColumns && this.selected && (this.settings.doFilterOnUrlChange || this.settings.doSortOnUrlChange);
  }

  /**
   * Initiates available attributes for selected artifact types.
   * Available are those, which are currently not among selected ones.
   */
  private initAvailableAtColumns(): void {
    // TODO: check the sequence for invoking this function (invoked too many times)
    this.availableAtColumns = {};
    const availableColumnsMap = Object.assign({}, this.options.columns.listMap);
    this.selected.columns.forEach(column => {
      delete availableColumnsMap[column.key];
    });

    Object.keys(availableColumnsMap).forEach(key => {
      const urlQueryName = this.attributeUtil.getUrlQueryAttributeName(availableColumnsMap[key].label);
      this.availableAtColumns[urlQueryName] = availableColumnsMap[key];
    });
  }

  private onModuleRowDrop(event: CdkDragDrop<void>): void {
    if (this.canModifyModuleByDragAndDrop()) {
      this.moduleHelper
        .onModuleRowDrop(event, this.data)
        .subscribe({ next: () => this.invokeLazyLoadData(), error: () => this.announcement.error('Failed to move artifact') });
    } else {
      this.announcement.warn('To modify rows by drag and drop, table must have no active filter and sort must be set by Section (ascending)');
    }
  }

  private canModifyModuleByDragAndDrop(): boolean {
    return this.helper.isModuleEditingEnabled(this.latestProcessedFilters, this.latestLazyLoadEvent);
  }

  private onRowEditError(e: HttpErrorResponse): Observable<never> {
    const errorMessage = e.error.statusCode === 403 ? 'Failed to edit artifact due to insufficient permissions' : 'Failed to edit artifact';
    this.announcement.error(errorMessage);
    return EMPTY;
  }

  private addToSelectionIfNeeded(artifact: NewArtifact): void {
    if (this.settings.rowClickHandleAction !== ArtifactListItemClickAction.selectItem || this.isArtifactSelected(artifact)) {
      return;
    }

    this.selection = this.settings.multiselect ? [...(this.selection as MultiArtifactSelection), artifact] : artifact;
  }
}
