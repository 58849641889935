import { NewUser } from '@shared/types/user.types';

export class ArtifactHistory {
  user: NewUser;
  date: string;
  operation: ArtifactHistoryOperationType;
  attributes: ArtifactHistoryAttribute[];

  constructor(history: Partial<ArtifactHistory>) {
    Object.assign(this, history);
  }
}

export class ArtifactHistoryAttribute {
  name: string;
  newValue: any;
  oldValue?: any;

  constructor(attribute: Partial<ArtifactHistoryAttribute>) {
    Object.assign(this, attribute);
  }
}

export enum ArtifactHistoryOperationType {
  created = 'created',
  updated = 'updated',
  deleted = 'deleted',
}
