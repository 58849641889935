import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactHistoryAttribute, ArtifactHistoryOperationType } from '@shared/components/artifact-history/types/artifact-history.types';

@Pipe({ name: 'formatHistoryAttributesToString' })
export class FormatHistoryAttributesToStringPipe implements PipeTransform {
  transform(attributes: ArtifactHistoryAttribute[], operationType: ArtifactHistoryOperationType): unknown {
    switch (operationType) {
      case ArtifactHistoryOperationType.created:
        return this.formatAttributeFromCreateOperation(attributes);
      case ArtifactHistoryOperationType.updated:
        return this.formatAttributeFromUpdateOperation(attributes);
      default:
        return '';
    }
  }

  private formatAttributeFromCreateOperation(attributes: ArtifactHistoryAttribute[]): string {
    return attributes.reduce((previousValue, currentValue) => previousValue + `${currentValue.name}: ${currentValue.newValue}\n`, '');
  }

  private formatAttributeFromUpdateOperation(attributes: ArtifactHistoryAttribute[]): string {
    return attributes.reduce(
      (previousValue, currentValue) => previousValue + `${currentValue.name}: ${currentValue.oldValue} <b> CHANGED TO </b> ${currentValue.newValue}\n`,
      '',
    );
  }
}
