import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-artifact-attribute-form-field-label-with-icon',
  templateUrl: './artifact-attribute-form-field-label-with-icon.component.html',
  styleUrls: ['./artifact-attribute-form-field-label-with-icon.component.scss'],
})
export class ArtifactAttributeFormFieldLabelWithIconComponent {
  @Input() attribute: NewAttribute;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() label: string;
  @Input() fieldHtmlId: string;
  @Input() canContainPlaceholder: boolean;
  @Input() labelBehaviour?: LabelBehaviourEnum;
  @Input() placeholder?: string;

  LabelBehaviourEnum = LabelBehaviourEnum;
}
