<div class="grid widget-settings">
  <div class="col-6"> {{'Visual type' | translate}} </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.settings.widgetType"
                [options]="m.options.widgetType"
                appendTo="body"
                id="widgetType">
    </p-dropdown>
  </div>

  <div class="col-6"> {{'Alignment' | translate}} </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.styles.justifyContent"
                [options]="m.options.horizontalAlign"
                appendTo="body"
                id="horizontalAlign">
    </p-dropdown>
  </div>

  <div class="col-6"> {{'Direction' | translate}} </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.styles.flexDirection"
                [options]="m.options.flexDirection"
                appendTo="body"
                id="direction">
    </p-dropdown>
  </div>

  <p-accordion class="col-12">
    <p-accordionTab [header]="'User icon' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showIcon" binary="true" (onChange)="checkRestriction(settingKeys.showIcon)" [(ngModel)]="m.settings.showIcon"></p-checkbox>
              <label for="showIcon"> {{'Show user icon' | translate}} </label>
            </span>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.userIcon" [isDisabled]="!m.settings.showIcon" (onChangeIcon)="m.settings.userIcon = $event"></app-icon-picker>
          </span>
        </div>

        <div class="col-6">
          <div class="dynamic-label p-float-label">
            <p-dropdown
              [(ngModel)]="m.settings.userDisplayVariant"
              [options]="m.options.userIconVariant.list | transformArrayToSelectOptions"
              [placeholder]="'User display variant' | translate"
              autoDisplayFirst="false"
              filterBy="label"
              id="userDisplayVariant"
            >
            </p-dropdown>
            <label for="userDisplayVariant" translate> User display variant </label>
          </div>
        </div>
        <div class="col-6">
          <button (click)="userIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="User Icon Styles" pButton></button>
        </div>

        <div class="col-6"></div>
        <div class="col-6">
          <button (click)="styleOp.toggle($event)"
                  icon="pi pi-cog" label="User Letter Styles" pButton></button>
        </div>
      </div>
    </p-accordionTab>

    <p-accordionTab [header]="'User data' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showName" binary="true" (onChange)="checkRestriction(settingKeys.showName)" [(ngModel)]="m.settings.showName"></p-checkbox>
              <label for="showName"> {{'Show user name' | translate}} </label>
            </span>
          </span>
        </div>
        <div class="col-6">
          <button (click)="userNameStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Name Font Styles" pButton></button>
        </div>

        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showEmail" binary="true" (onChange)="checkRestriction(settingKeys.showEmail)" [(ngModel)]="m.settings.showEmail"></p-checkbox>
              <label for="showEmail"> {{'Show user email' | translate}} </label>
            </span>
          </span>
        </div>
        <div class="col-6">
          <button (click)="userEmailStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Email Font Styles" pButton></button>
        </div>

        <div class="col-6"> {{'Direction' | translate}} </div>
        <div class="col-6">
          <p-dropdown [(ngModel)]="m.settings.userDataStyles.flexDirection"
                      [options]="m.options.flexDirection"
                      appendTo="body"
                      id="direction">
          </p-dropdown>
        </div>
      </div>
      <p-accordion styleClass="accord">

        <p-accordionTab [header]="'Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userDataStyles" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
        <p-accordionTab [header]="'Name Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userNameStyle" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
        <p-accordionTab [header]="'Email Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userEmailStyle" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
      </p-accordion>

    </p-accordionTab>

    <p-accordionTab [header]="'Login/Logout' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showButton" binary="true" (onChange)="checkRestriction(settingKeys.showLogoutButton)" [(ngModel)]="m.settings.showLogoutButton"></p-checkbox>
              <label for="showButton"> {{'Show log out button' | translate}} </label>
            </span>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showButton" binary="true" [(ngModel)]="m.settings.showLoginButton"></p-checkbox>
              <label for="showButton"> {{'Show log in button' | translate}} </label>
            </span>
          </span>
        </div>

        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.logoutIcon" [isDisabled]="!m.settings.showLogoutButton" [label]="'Logout icon' | translate" (onChangeIcon)="m.settings.logoutIcon = $event"></app-icon-picker>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.loginIcon" [label]="'Login icon' | translate" (onChangeIcon)="m.settings.loginIcon = $event"></app-icon-picker>
          </span>
        </div>

        <div class="col-6">
          <button (click)="logoutIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Logout Icon Styles" pButton></button>
        </div>
        <div class="col-6">
          <button (click)="loginIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Login Icon Styles" pButton></button>
        </div>

        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="isIconRound" binary="true" [(ngModel)]="m.settings.isLogoutIconRound"></p-checkbox>
              <label for="isIconRound"> {{'Rounded logout icon' | translate}} </label>
            </span>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="isIconRound" binary="true" [(ngModel)]="m.settings.isLoginIconRound"></p-checkbox>
              <label for="isIconRound"> {{'Rounded login icon' | translate}} </label>
            </span>
          </span>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<p-overlayPanel #userIconStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userIconStyle"
            [hideFontFamilySettings]="true"
            [hideHorizontalAlignSettings]="true"
            [hideEmphasisSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #logoutIconStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
      [fontStyles]="m.settings.logoutIconStyle"
      [hideFontFamilySettings]="true"
      [hideHorizontalAlignSettings]="true"
      [hideEmphasisSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #loginIconStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.loginIconStyle"
            [hideFontFamilySettings]="true"
            [hideHorizontalAlignSettings]="true"
            [hideEmphasisSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #styleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Letters Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userletterStyle"
            [hideHorizontalAlignSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #userNameStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Name Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userNameStyle"
            [hideHorizontalAlignSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #userEmailStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Email Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userEmailStyle"
            [hideHorizontalAlignSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>
