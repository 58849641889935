/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { teamControllerAddUsers } from '../fn/tenant-team/team-controller-add-users';
import { TeamControllerAddUsers$Params } from '../fn/tenant-team/team-controller-add-users';
import { teamControllerCount } from '../fn/tenant-team/team-controller-count';
import { TeamControllerCount$Params } from '../fn/tenant-team/team-controller-count';
import { teamControllerCreate } from '../fn/tenant-team/team-controller-create';
import { TeamControllerCreate$Params } from '../fn/tenant-team/team-controller-create';
import { teamControllerDelete } from '../fn/tenant-team/team-controller-delete';
import { TeamControllerDelete$Params } from '../fn/tenant-team/team-controller-delete';
import { teamControllerGet } from '../fn/tenant-team/team-controller-get';
import { TeamControllerGet$Params } from '../fn/tenant-team/team-controller-get';
import { teamControllerList } from '../fn/tenant-team/team-controller-list';
import { TeamControllerList$Params } from '../fn/tenant-team/team-controller-list';
import { teamControllerRemoveUsers } from '../fn/tenant-team/team-controller-remove-users';
import { TeamControllerRemoveUsers$Params } from '../fn/tenant-team/team-controller-remove-users';
import { teamControllerUpdate } from '../fn/tenant-team/team-controller-update';
import { TeamControllerUpdate$Params } from '../fn/tenant-team/team-controller-update';
import { TeamListResponseDto } from '../models/team-list-response-dto';
import { TeamResponseDto } from '../models/team-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantTeamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `teamControllerCount()` */
  static readonly TeamControllerCountPath = '/api/tenant/team/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerCount$Response(params?: TeamControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return teamControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerCount(params?: TeamControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.teamControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerGet()` */
  static readonly TeamControllerGetPath = '/api/tenant/team/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerGet$Response(params: TeamControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamResponseDto>> {
    return teamControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerGet(params: TeamControllerGet$Params, context?: HttpContext): Observable<TeamResponseDto> {
    return this.teamControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamResponseDto>): TeamResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerDelete()` */
  static readonly TeamControllerDeletePath = '/api/tenant/team/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerDelete$Response(params: TeamControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamResponseDto>> {
    return teamControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerDelete(params: TeamControllerDelete$Params, context?: HttpContext): Observable<TeamResponseDto> {
    return this.teamControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamResponseDto>): TeamResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerList()` */
  static readonly TeamControllerListPath = '/api/tenant/team';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerList$Response(params?: TeamControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamListResponseDto>> {
    return teamControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamControllerList(params?: TeamControllerList$Params, context?: HttpContext): Observable<TeamListResponseDto> {
    return this.teamControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamListResponseDto>): TeamListResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerUpdate()` */
  static readonly TeamControllerUpdatePath = '/api/tenant/team';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerUpdate$Response(params: TeamControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamResponseDto>> {
    return teamControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerUpdate(params: TeamControllerUpdate$Params, context?: HttpContext): Observable<TeamResponseDto> {
    return this.teamControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamResponseDto>): TeamResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerCreate()` */
  static readonly TeamControllerCreatePath = '/api/tenant/team';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerCreate$Response(params: TeamControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamResponseDto>> {
    return teamControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerCreate(params: TeamControllerCreate$Params, context?: HttpContext): Observable<TeamResponseDto> {
    return this.teamControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeamResponseDto>): TeamResponseDto => r.body)
    );
  }

  /** Path part for operation `teamControllerAddUsers()` */
  static readonly TeamControllerAddUsersPath = '/api/tenant/team/users/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerAddUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerAddUsers$Response(params: TeamControllerAddUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return teamControllerAddUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerAddUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerAddUsers(params: TeamControllerAddUsers$Params, context?: HttpContext): Observable<void> {
    return this.teamControllerAddUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `teamControllerRemoveUsers()` */
  static readonly TeamControllerRemoveUsersPath = '/api/tenant/team/users/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamControllerRemoveUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerRemoveUsers$Response(params: TeamControllerRemoveUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return teamControllerRemoveUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamControllerRemoveUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamControllerRemoveUsers(params: TeamControllerRemoveUsers$Params, context?: HttpContext): Observable<void> {
    return this.teamControllerRemoveUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
