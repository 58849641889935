import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ContextVariableAutocompleteOverlayComponent } from './components/context-variable-autocomplete-overlay/context-variable-autocomplete-overlay.component';
import { ContextVariableAutocompleteDirective } from './directives/context-variable-autocomplete.directive';

const declarations = [ContextVariableAutocompleteOverlayComponent, ContextVariableAutocompleteDirective];

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, OverlayPanelModule, ListboxModule],
  exports: [ContextVariableAutocompleteOverlayComponent, ContextVariableAutocompleteDirective],
  declarations: [...declarations],
  providers: [],
})
export class ContextVariableAutocompleteOverlayModule {}
