<h5>{{title}}</h5>
<div *ngIf="showPositionControls" class="icon-cont">
    <span
        class="bi cursor-pointer"
        (click)="togglePanelPosition(true)"
        [ngClass]="widgetService.isFullscreen(widgetId) ? 'bi-fullscreen-exit' : 'bi-arrows-fullscreen'"
    ></span>
    <span
        class="bi cursor-pointer"
        *ngIf="!widgetService.isFullscreen(widgetId)"
        (click)="togglePanelPosition()"
        [ngClass]="widgetService.isPositionRight(widgetId) ? 'bi-box-arrow-in-left' : 'bi-box-arrow-in-right'"
    ></span>
</div>
