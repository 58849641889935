import { Pipe, PipeTransform } from '@angular/core';
import { MenuWidgetSettings } from '../types/menu-widget-settings.types';
import { MenuItem } from '../types/menu-widget.types';

@Pipe({ name: 'splitUpMenuItemsByPosition' })
export class SplitUpMenuItemsByPositionPipe implements PipeTransform {
  transform(items: MenuItem[], position: string, settings: MenuWidgetSettings): MenuItem[] {
    return items.filter(item => settings.item[item.hash].positioned === position);
  }
}
