import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact, ArtifactLinkPair } from '@shared/types/artifact.types';
import { NewLink } from '../../types/link.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';

@Pipe({ name: 'getLinkedArtifactsByLinksData' })
/**
 * By links data we understand final links after applying all link restrictions. The pipe returns list of all artifacts matching given links data.
 */
export class GetLinkedArtifactsByLinksDataPipe implements PipeTransform {
  transform(linksData: NewLink[], artifacts: Record<string, NewArtifact>, direction: LinkDirection): ArtifactLinkPair[] {
    if (!artifacts || !linksData) {
      return [];
    }
    return linksData
      .map(link => ({ link, artifact: direction === LinkDirection.outgoing ? artifacts[link.destinationArtifactId] : artifacts[link.sourceArtifactId] }))
      .filter(artfactLink => !!artfactLink.artifact);
  }
}
