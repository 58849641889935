import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { TagWidgetService } from '@widgets/tag-widget/services/tag-widget.service';
import { TagWidgetModel, TagWidgetModelDto, TagWidgetValue } from '@widgets/tag-widget/types/tag-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-tag-widget',
  templateUrl: './tag-widget.component.html',
  styleUrls: ['./tag-widget.component.scss'],
  providers: [TagWidgetService],
})
export class TagWidgetComponent extends WidgetsCoreComponent<TagWidgetValue> implements OnInit {
  m: TagWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<TagWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: TagWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as any as TagWidgetModelDto) : null);
  }
}
