import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { FadeAnimation } from '@shared/animations/animations';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetStyleCopyPastingService } from '@widgets/artifact-widget/services/artifact-widget-style-copy-pasting.service';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactFormItemType, ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { ARTIFACT_WIDGET_TABS_TOOLTIP } from '@widgets/shared/constants/widget.constants';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-artifact-widget-item-picker',
  templateUrl: './artifact-widget-item-picker.component.html',
  styleUrls: ['./artifact-widget-item-picker.component.scss'],
  animations: [FadeAnimation],
})
export class ArtifactWidgetItemPickerComponent {
  @Input() model: ArtifactWidgetModel;
  @Input() queryParams: Params;
  @Input() onItemSelect: () => void;

  @ViewChild('tabOp') tabOverlayPanel: OverlayPanel;

  selectedItemClickedOnTab: ArtifactWidgetFormItem;
  itemType = ArtifactFormItemType;
  ARTIFACT_WIDGET_TABS_TOOLTIP = ARTIFACT_WIDGET_TABS_TOOLTIP;

  constructor(public readonly artifactWidgetHelper: ArtifactWidgetHelper, public readonly styleService: ArtifactWidgetStyleCopyPastingService) {}

  dropItem(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.model.form, event.previousIndex, event.currentIndex);
  }

  onAttributeOrLinkTypeChange(option: SelectOption<string, any>, index: number): void {
    this.artifactWidgetHelper.onFormItemChange(this.model, { option, index });
    this.model.updateNoAttributeEditableFlag();
    this.model.originalForm = this.model.form;
  }

  onTabSettingsClick(event: any, item: ArtifactWidgetFormItem): void {
    event.stopPropagation();
    this.tabOverlayPanel.hide();
    this.selectedItemClickedOnTab = item;
    setTimeout(() => this.tabOverlayPanel.show(event), 150);
  }

  onRemoveItemClick(event: Event, itemIndex: number): void {
    this.artifactWidgetHelper.removeField(this.model, itemIndex);
    this.artifactWidgetHelper.updateIsNoTabKeyActiveInUrl(this.model);
    event.stopPropagation();
  }

  onClearClick(): void {
    this.selectedItemClickedOnTab.tabSettings.tabKey = this.selectedItemClickedOnTab.tabSettings.tabValue = '';
    this.artifactWidgetHelper.updateIsNoTabKeyActiveInUrl(this.model);
  }

  selectItem(item: ArtifactWidgetFormItem): void {
    if (item.value) {
      this.model.selected.item = item.value;
      this.model.isAttributeSelected = item.value.value instanceof NewClientAttribute;
      this.onItemSelect && this.onItemSelect();
    }
  }

  onTabSettingsChange(): void {
    if (!this.selectedItemClickedOnTab.value) return;
    const selectedFormItemIndex = this.model.form.findIndex(formItem => formItem.value.value.id === this.selectedItemClickedOnTab.value.value.id);
    if (selectedFormItemIndex > -1) this.model.form[selectedFormItemIndex] = new ArtifactWidgetFormItem({ ...this.selectedItemClickedOnTab });
    this.artifactWidgetHelper.updateIsNoTabKeyActiveInUrl(this.model);
  }

  onPasteAttributeStyle(event: Event, item: ArtifactWidgetFormItem): void {
    event?.stopPropagation();

    const attributeId = item.attribute?.value?.id;
    if (!attributeId) return;

    const attribute = this.model.options.attributes.listMap[attributeId];
    const targetDataType = attribute && this.model.options.dataTypes.listMap[attribute.dataTypeId];
    this.styleService.pasteStyle(this.model.formatsMap.attribute[attributeId], targetDataType);
  }
}
