import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { ArtifactValueExtractor } from './artifact-value-extractor';

export class FolderPathExtractor implements ArtifactValueExtractor {
  static readonly folderPathAttributeId = NonAttributeKeys.FOLDER_PATH_ID;

  static isFolderPathAttribute(attributeId: string): boolean {
    return attributeId === FolderPathExtractor.folderPathAttributeId;
  }

  getValue(artifactDto: ArtifactResponseDto): string {
    return artifactDto.folderData.path || '';
  }
}
