import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateListResponseDto } from '@api/models/template-list-response-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { TranslateService } from '@ngx-translate/core';
import { TemplatesService } from '@private/pages/template-management/templates/services/templates.service';
import { TemplatesModel } from '@private/pages/template-management/templates/types/templates.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { TemplatesTableComponent } from '@private/pages/template-management/templates/components/templates-table/templates-table.component';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { lastValueFrom, tap } from 'rxjs';
import { TemplateMetaFormComponent } from '@shared/components/template-form/template-meta-form.component';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { TemplateMeta } from '@private/pages/page-management/page-builder-graphical/types/template-meta';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { NewApplication } from '@shared/types/application.types';
import { ListReqMetaData } from '@shared/core/types/core.types';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers: [TemplatesService],
})
export class TemplatesComponent extends CoreComponent<TemplatesService, TemplatesModel> implements OnInit {
  @ViewChild(TemplateMetaFormComponent) templateForm: TemplateMetaFormComponent;
  @ViewChild(TemplatesTableComponent) table: TemplatesTableComponent;
  template: TemplateResponseDto;
  usageList: string[];

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: TemplatesService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly blockUiService: BlockUiService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super(route, router, translate, new TemplatesModel(), service, announcement);
  }

  loadDataMethod = (meta?: Partial<ListReqMetaData>): Promise<TemplateListResponseDto> => {
    return lastValueFrom(this.tenantTemplateService.templateControllerList(meta));
  };

  async upload($event: { files: File[] }, uploadComponent: FileUpload): Promise<void> {
    const file = $event.files[0];
    this.blockUiService.blockUi();

    try {
      await this.s.upload(file);
      await this.announcement.success('Template created');
      await this.table.update();
    } catch (e) {
      console.log(e);
      await this.announcement.error('Failed to create template');
    } finally {
      this.blockUiService.unblockUi();
      uploadComponent.clear();
    }
  }

  async download(template: TemplateResponseDto): Promise<void> {
    this.blockUiService.blockUi();

    try {
      await this.s.download(template);
    } catch (e) {
      console.log(e);
      await this.announcement.error('Something went wrong downloading the template');
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  openPanel(template: TemplateResponseDto, op: OverlayPanel, event: MouseEvent): void {
    this.template = template;
    op.toggle(event);
  }

  async showWhereUsedInfo(op: OverlayPanel, event: MouseEvent): Promise<void> {
    this.usageList = await this.s.getTemplateUsage(this.template);
    op.toggle(event);
  }

  async remove(op: OverlayPanel): Promise<void> {
    if (!(await this.s.isUnusedTemplate(this.template))) {
      await this.announcement.error('Template is in use and can`t removed');
      return;
    }
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + this.template.name + '?',
      acceptLabel,
      rejectLabel,
      accept: async () => {
        this.blockUiService.blockUi();

        try {
          await lastValueFrom(this.tenantTemplateService.templateControllerDelete({ id: this.template.id }));
          op.hide();
          await this.announcement.success('Template removed');
          await this.table.update();
        } catch (e) {
          console.log(e);
          await this.announcement.error('Failed to remove template');
        } finally {
          this.blockUiService.unblockUi();
        }
      },
    });
  }

  showTemplateForm(): void {
    this.templateForm.show(this.template.type as TemplateType);
  }

  updateTemplate(templateMeta: TemplateMeta, op: OverlayPanel): void {
    this.s
      .updateTemplate(Object.assign({ ...this.template }, templateMeta))
      .pipe(
        tap(async () => {
          op.hide();
          this.templateForm.hide();
          await this.table.update();
        }),
      )
      .subscribe();
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Templates' }]);
    this.applicationSwitcherService.selectedApplication$.subscribe((application: NewApplication) => {
      this.m.applicationId = application.id;
    });
  }
}
