import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Observable, Subject } from 'rxjs';
import { NewApplication } from '../types/application.types';
import { NewArtifactType } from '../types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '../types/attribute.types';
import { NewDataType } from '../types/data-type.types';
import { LinkType } from '../types/link-type.types';
import { ListContainer } from '../types/list-container.types';
import { NewTeam } from '../types/team.types';
import { NewSystemUser, NewUser } from '../types/user.types';

/**
 * @deprecated
 * */
@Injectable({ providedIn: 'root' })
export class CacheDataHolderService {
  private _user: NewSystemUser;
  private _users = new ListContainer<NewUser>();
  private _teams = new ListContainer<NewTeam>();
  private _applications = new ListContainer<NewApplication>();
  private _artifactTypes = new ListContainer<NewArtifactType>();
  private _linkTypes = new ListContainer<LinkType>();
  private _attributes = new ListContainer<NewAttribute>();
  private _dataTypes = new ListContainer<NewDataType>();
  private _pages = new ListContainer<Page>();

  private _isCacheLoadedSubject = new Subject<boolean>();
  private _isCacheLoadedObservable = this._isCacheLoadedSubject.asObservable();
  private _isLoaded: boolean;

  constructor(private readonly cache: NewCacheService) {}

  get user(): NewSystemUser {
    return this._user;
  }

  get users(): ListContainer<NewUser> {
    return this._users;
  }

  get teams(): ListContainer<NewTeam> {
    return this._teams;
  }

  get dataTypes(): ListContainer<NewDataType> {
    return this._dataTypes;
  }

  get applications(): ListContainer<NewApplication> {
    return this._applications;
  }

  get linkTypes(): ListContainer<LinkType> {
    return this._linkTypes;
  }

  get attributes(): ListContainer<NewAttribute> {
    return this._attributes;
  }

  get artifactTypes(): ListContainer<NewArtifactType> {
    return this._artifactTypes;
  }

  get pages(): ListContainer<Page> {
    return this._pages;
  }

  get isLoaded$(): Observable<boolean> {
    return this._isCacheLoadedObservable;
  }

  initData(): void {
    if (this._isLoaded) return;

    const data = this.cache.data;

    this.cache.user.subscribe(user => {
      this._user = new NewSystemUser(user as SelfUserResponseDto);
      this.raceResolver();
    });

    data.users.subscribe(users => {
      this._users.setList(users as UserResponseDto[], NonAttributeKeys.ID);
      this.raceResolver();
    });
    data.teams.subscribe(teams => {
      this._teams.setList(teams as TeamResponseDto[], NonAttributeKeys.ID);
      this.raceResolver();
    });
    data.dataTypes.subscribe(dataTypes => {
      this._dataTypes.setList(
        (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
        NonAttributeKeys.ID,
      );
      this.raceResolver();
    });
    data.applications.subscribe(applications => {
      this._applications.setList(applications as ApplicationResponseDto[], NonAttributeKeys.ID);
      this.raceResolver();
    });
    data.linkTypes.subscribe(linkTypes => {
      this._linkTypes.setList(
        (linkTypes as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
        NonAttributeKeys.ID,
      );
      this.raceResolver();
    });
    data.attributes.subscribe(attributes => {
      this._attributes.setList(
        (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
        NonAttributeKeys.ID,
      );
      this.raceResolver();
    });
    data.artifactTypes.subscribe(artifactTypes => {
      this._artifactTypes.setList(
        (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
        NonAttributeKeys.ID,
      );
      this.raceResolver();
    });
    data.pages.subscribe(pages => {
      this._pages.setList(
        (pages as PageResponseDto[]).map(dto => new Page(dto)),
        NonAttributeKeys.ID,
      );
      this.raceResolver();
    });
  }

  private raceResolver(): void {
    if (
      this.user &&
      this.users.loaded &&
      this.teams.loaded &&
      this.dataTypes.loaded &&
      this.applications.loaded &&
      this.linkTypes.loaded &&
      this.attributes.loaded &&
      this.artifactTypes.loaded &&
      this.pages.loaded
    ) {
      this._isLoaded = true;
      this._isCacheLoadedSubject.next(true);
    }
  }
}
