import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsDateOrTime } from '@shared/methods/data-type.methods';

@Pipe({ name: 'isDateOrTime' })
export class IsDateOrTimePipe implements PipeTransform {
  transform(baseDataType: BaseDataType): boolean {
    return IsDateOrTime(baseDataType);
  }
}
