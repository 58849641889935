import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';

import { TenantListTableComponent } from './tenant-list/components/tenant-list-table/tenant-list-table.component';
import { PrintTenantToTablePipe } from './tenant-list/pipes/print-tenant-to-table.pipe';
import { TenantListComponent } from './tenant-list/tenant-list.component';
import { TenantComponent } from './tenant/tenant.component';

const declarations = [TenantListComponent, TenantComponent, TenantListTableComponent];

@NgModule({
  declarations: [...declarations, PrintTenantToTablePipe],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    PickListModule,
    LoaderModule,
    MultiSelectModule,
    ElvisSharedModule,
    CheckboxModule,
    RadioButtonModule,
  ],
  exports: [...declarations],
})
export class TenantManagementModule {}
