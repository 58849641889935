import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamListComponent } from './team-list/team-list.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { TeamComponent } from './team/team.component';
import { TeamListTableComponent } from './team-list/components/team-list-table/team-list-table.component';
import { TableModule } from 'primeng/table';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TeamUserFormComponent } from './team/components/team-user-form/team-user-form.component';
import { PickListModule } from 'primeng/picklist';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [TeamListComponent, TeamComponent, TeamListTableComponent, TeamUserFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    ElvisSharedModule,
    LoaderModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    PickListModule,
    InputTextareaModule,
  ],
})
export class TeamManagementModule {}
