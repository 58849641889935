import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { ModifyAttributeValueOperation } from '@workflows/shared';
import { SetAttributeValueKeys, WorkflowActionSetAttributeValue } from '@workflows/types/actions/action-set-attribute-value';

@Component({
  selector: 'app-action-set-attribute-value',
  templateUrl: './action-set-attribute-value.component.html',
  styleUrls: ['./action-set-attribute-value.component.scss'],
})
export class ActionSetAttributeValueComponent extends AbstractWorkflowRuleItemComponent implements OnChanges {
  @Input() action: WorkflowActionSetAttributeValue;
  @Input() allAttributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() users: ListContainer<NewUser> = new ListContainer();
  @Input() index: number;
  @Input() showOperations: boolean;

  operations: ModifyAttributeValueOperation[];
  showEditingField = true;
  protected readonly RuleKeys = SetAttributeValueKeys;

  constructor(translateService: TranslateService) {
    super(translateService);
    this.operations = Object.values(ModifyAttributeValueOperation);
  }

  onAttributeChange(attributeId: string): void {
    this.action.actionSettings[SetAttributeValueKeys.value].value = null as any;
    this.action.attributeId = attributeId;
    this.reinitializeEditField();
  }

  private reinitializeEditField(): void {
    this.showEditingField = false;
    setTimeout(() => (this.showEditingField = true));
  }
}
