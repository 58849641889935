import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { MenuItem } from 'primeng/api';

export class AdminTopBarModel {
  public breadcrumbs: MenuItem[] | null = null;
  public user: SelfUserResponseDto | null = null;

  constructor(model?: Partial<AdminTopBarModel>) {
    model && Object.assign(this, model);
  }
}
