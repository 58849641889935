<p-table
  #table
  *ngIf="this.applicationSwitcherService.selectedApplication?.id"
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  [filters]="{ applicationId: [{ value: [this.applicationSwitcherService.selectedApplication.id], matchMode: 'in', operator: 'and' }] }"
  dataKey="id"
  editMode="row"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
  selectionMode="multiple"
  [(selection)]="selectedTemplates"
>
  <ng-template let-columns pTemplate="header">
    <tr>
      <th *ngIf="selectTemplates" style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
        <ng-container [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'type'" [ngTemplateOutlet]="typeFilter"></ng-container>
          <ng-container *ngSwitchCase="APPLICATION_ID_VALUE" [ngTemplateOutlet]="applicationFilter"></ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultFilter"></ng-container>
        </ng-container>

        <!-- ### START - TYPE FILTER TEMPLATE ### -->
        <ng-template #typeFilter>
          <p-columnFilter
            *ngIf="col.key === 'type'"
            [field]="col.key"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
            matchMode="in"
            display="menu"
          >
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="p-text-bold">{{ col.key }}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="typeOptions" placeholder="Template type" (onChange)="filter($event.value)">
                <ng-template let-option pTemplate="item">
                  <div>
                    <span class="ml-1">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-template>
        <!-- ### END - TYPE FILTER TEMPLATE ### -->

        <!-- ### START - APPLICATION FILTER TEMPLATE ### -->
        <ng-template #applicationFilter>
          <p-columnFilter [field]="col.key" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="p-text-bold">{{ col.key }}</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="col.key | getTemplateFilterOptions : s.m" placeholder="Any" (onChange)="filter($event.value)">
                {{ value }}
                <ng-template let-option pTemplate="item"
                  ><span class="ml-1">{{ option.label | translate }}</span>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-template>
        <!-- ### END - APPLICATION FILTER TEMPLATE ### -->

        <!-- ### START - DEFAULT FILTER TEMPLATE ### -->
        <ng-template #defaultFilter>
          <p-columnFilter *ngIf="col.key !== 'icon' && col.key !== 'id'" matchMode="contains" type="text" [field]="col.key" display="menu"></p-columnFilter>
        </ng-template>
        <!-- ### END - DEFAULT FILTER TEMPLATE ### -->
      </th>
      <th>Actions</th>
    </tr>
  </ng-template>

  <ng-template let-columns="columns" let-template pTemplate="body">
    <tr [ngClass]="{ 'deleted-row-color': template.deleted }">
      <td *ngIf="selectTemplates">
        <p-tableCheckbox [value]="template"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns">
        <div [ngSwitch]="col.key" class="p-text-truncate">
          <span *ngSwitchCase="'icon'" [class]="template | printTemplateToTable : col.key : s.m.applications"></span>
          <span *ngSwitchDefault>{{ template | printTemplateToTable : col.key : s.m.applications }}</span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <ng-container *ngTemplateOutlet="actionButtonsTemplate; context: { $implicit: template }"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
