import { Directive } from '@angular/core';
import { RuleDataHolderService, RuleTriggerEventHandlerService } from '../services';
import { AbstractRuleDirective } from '../shared/directives/abstract-rule.directive';
import { WorkflowTriggerEvent } from '../types';

@Directive()
export class AbstractRuleEventNotifyDirective extends AbstractRuleDirective {
  constructor(protected readonly ruleDataHolder: RuleDataHolderService, protected ruleTriggerEventHandler: RuleTriggerEventHandlerService) {
    super();
  }

  checkAndNotify(ruleTriggerEvent: WorkflowTriggerEvent): void {
    if (this.ruleDataHolder.hasRuleForPageAndTrigger(this.pageId, ruleTriggerEvent)) {
      this.ruleTriggerEventHandler.notifyTriggerEvent(ruleTriggerEvent);
    }
  }
}
