import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Margin } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';

@Component({
  selector: 'app-margin-form',
  templateUrl: './margin-form.component.html',
  styleUrls: ['./margin-form.component.scss'],
})
export class MarginFormComponent {
  @Input() marginStyles: Margin;

  @Output() changesEmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
}
