export const PAGE_BUILDER_URL = 'page-builder';
export const ARTIFACT_TYPE_URL = 'artifact-type';
export const FILTER_WIDGET_NULL_IN_URL = 'empty';
export const PRIVATE_USER_FOLDER = 'PRIVATE_USER_FOLDER';
export const PRIVATE_USER_FOLDER_LABEL = 'Private folder';
export const USER_DEFAULT_TEAM_FOLDER = 'DEFAULT_TEAM_FOLDER';
export const USER_DEFAULT_TEAM_FOLDER_LABEL = 'Default team folder';
export const TENANT_LABEL = 'Tenant';
export const ARTIFACT_WIDGET_TABS_TOOLTIP = 'ARTIFACT_WIDGET_TABS_TOOLTIP';
export const ARTIFACT_WIDGET_EMPTY_FOLDER_PATH = '...';
export const FOLDER_WIDGET_CONTEXT_MENU_WIDTH = 361;
export const LIST_WIDGET_DEFAULT_SUM_ICON = 'bi bi-calculator';
