<div *ngIf="isLayoutMode; else advancedModeTemplate">
    <ng-container *ngIf="m.isLoad">
        <app-display-at-enum
            *ngIf="!m.settings.isFilterMode && !m.settings.isDateMode && !s.isAttributeDecimalOrInteger(m.settings.currentAttribute) && !s.isAttributeBoolean(m.settings.currentAttribute) && m.settings.selectedVariant"
            class="block-container full-size-container"
            (onSelect)="s.selectHandler($event)"
            [activeIndexes]="m.activeIndexes"
            [isShowActive]="true"
            [isPointer]="true"
            [enumOptions]="m.options.tagList.list"
            [selectedVariant]="m.settings.selectedVariant.value"
            [placeholder]="m.settings.placeholder"
        ></app-display-at-enum>

        <app-date-time-filter
            *ngIf="m.settings.isDateMode && m.settings?.attribute?.value && m?.filter"
            (onFilterChange)="s.onChangeFilter($event)"
            [dataType]="m.dataType?.baseDataType"
            [placeholder]="m.settings.placeholder"
            [m]="m.filter"
        ></app-date-time-filter>

        <app-boolean-filter
            *ngIf="m.settings.currentAttribute && s.isAttributeBoolean(m.settings.currentAttribute)"
            (onFilterChange)="s.onChangeFilter($event)"
            (onClear)="s.onClearBoolean()"
            [m]="m.filter"
            [showClear]="true"
            [placeholder]="m.settings.placeholder"
        ></app-boolean-filter>

        <app-number-filter
            *ngIf="s.isAttributeDecimalOrInteger(m.settings.currentAttribute)"
            (onFilterChange)="s.onChangeFilter($event)"
            [placeholder]="m.settings.placeholder"
            [isShowAddRule]="false"
            [applyIsEnable]="true"
            [m]="m.filter"
        ></app-number-filter>

        <div *ngIf="m.settings.isFilterMode && m.settings?.attribute?.value" class="grid">
            <div [class]="s.isShowSearchButton() ? 'col-8': 'col-12'">
                <span class="p-input-icon-right full-width-container">
                    <i *ngIf="m.settings.text" class="pi pi-times clear-ico" (click)="s.onClearInput()"></i>
                    <input
                        pInputText
                        type="text"
                        class="w-full"
                        [(ngModel)]="m.settings.text"
                        [placeholder]="m.settings.placeholder"
                        (keypress)="s.onKeypress($event)"
                    />
                </span>
            </div>
            <div *ngIf="s.isShowSearchButton()" class="col-4">
                <button (click)="s.updateUrl(true)" [label]="'Search' | translate" class="p-button p-button-success" pButton
                        type="button"></button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #advancedModeTemplate>
    <app-filter-widget-settings [m]="m" [s]="s"></app-filter-widget-settings>
</ng-template>
