import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { PageBuilderGraphicalCopyPasterService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-copy-paster.service';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BlockLocation } from '@private/pages/page-management/page-builder-graphical/types/block-location';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { CommonToolbarActions } from '@private/pages/page-management/page-builder-graphical/types/common-toolbar-actions';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { SaveAsTemplateEvent } from '@private/pages/page-management/page-builder-graphical/types/save-as-template-event';
import { SetHtmlIdEvent } from '@private/pages/page-management/page-builder-graphical/types/set-html-id-event';
import { StyleElementEvent } from '@private/pages/page-management/page-builder-graphical/types/style-element-event';
import { WidgetIntoPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/widget-into-part-drop-event';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

@Component({
  selector: 'app-page-block-part',
  templateUrl: './page-block-part.component.html',
  styleUrls: ['../../../../../../shared/styles/toolbar.scss', './page-block-part.component.scss'],
})
export class PageBlockPartComponent implements PartLocation, CommonToolbarActions {
  @Input() page: Page;
  @Input() part: PageBlockPart;
  @Input() partIndex: number;
  @Input() sectionIndex: number;
  @Input() rowIndex: number;
  @Input() blockIndex: number;
  @Input() modalId = '';

  readonly widgetType = WidgetType;

  constructor(
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    public readonly copyPasterService: PageBuilderGraphicalCopyPasterService,
    private readonly eventsService: PageBuilderGraphicalEventsService,
  ) {}

  get idPrefix(): string {
    return `section${this.sectionIndex}row${this.rowIndex}block${this.blockIndex}part${this.partIndex}`;
  }

  private get location(): PartLocation {
    const filteredSections = this.dragDropService.currentSections.filter(section => section.modalId === this.modalId && section.index === this.sectionIndex);
    const sectionIndex = filteredSections[0].innerIndex;

    const { rowIndex, blockIndex, partIndex, modalId } = this;

    return { sectionIndex, rowIndex, blockIndex, partIndex, modalId };
  }

  advancedMode(): void {
    this.eventsService.publish<BlockPartWidget | null>(PageBuilderEventType.widgetAdvancedMode, this.part.widget);
  }

  saveAsTemplate(): void {
    const templateType = TemplateType.widget;
    const { location } = this;
    this.eventsService.publish<SaveAsTemplateEvent>(PageBuilderEventType.saveAsTemplate, { templateType, location });
  }

  style(): void {
    const { part: pageElement } = this;
    this.eventsService.publish<StyleElementEvent>(PageBuilderEventType.styleElement, { pageElement });
  }

  copy(): void {
    this.eventsService.publish<PartLocation>(PageBuilderEventType.copy, this.location);
  }

  paste(): void {
    this.eventsService.publish<PartLocation>(PageBuilderEventType.paste, this.location);
  }

  delete(): void {
    this.eventsService.publish<PartLocation>(PageBuilderEventType.delete, this.location);
  }

  export(): void {
    this.eventsService.publish<PartLocation>(PageBuilderEventType.export, this.location);
  }

  setId(event: Event): void {
    const setHtmlIdEvent: SetHtmlIdEvent = { event, pageElementWithHtmlId: this.part };
    this.eventsService.publish<SetHtmlIdEvent>(PageBuilderEventType.setHtmlId, setHtmlIdEvent);
  }

  addBlockPart(): void {
    const { sectionIndex, rowIndex, blockIndex, modalId } = this.location;
    this.eventsService.publish<BlockLocation>(PageBuilderEventType.addBlockPart, {
      sectionIndex,
      rowIndex,
      blockIndex,
      modalId,
    });
  }

  addWidget(): void {
    this.eventsService.publish<PartLocation>(PageBuilderEventType.addWidget, this.location);
  }

  dropWidget($event: CdkDragDrop<WidgetIntoPartDropEvent>): void {
    this.eventsService.publish<CdkDragDrop<WidgetIntoPartDropEvent>>(PageBuilderEventType.widgetDrop, $event);
  }
}
