import { FilterMetadata, FilterOperator } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';
import { NewTableColumn, TableFilterUrlTypeService } from '@shared/types/table.types';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlDateService implements TableFilterUrlTypeService {
  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void {
    const processedFilterValue = (filterValue && filterValue.split(',')) || [];
    const refFilter = (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0];
    refFilter.matchMode = processedFilterValue[0];
    refFilter.value = processedFilterValue.length > 2 ? [processedFilterValue[1], processedFilterValue[2]] : processedFilterValue[1];
    columnFilterRef.applyFilter();
  }

  getFilterMetadata(filterValue: string | string[]): FilterMetadata[] {
    return [
      {
        matchMode: filterValue[0],
        operator: FilterOperator.AND,
        value: filterValue[1],
      },
    ];
  }

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string[] {
    return rawQueryAttributeValue?.split(',') || [];
  }
}
