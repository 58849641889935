import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowAction, WorkflowActionType } from '@workflows/types';

@Pipe({ name: 'isActionCommonAttributeType' })
export class IsActionCommonAttributeTypePipe implements PipeTransform {
  transform(action: WorkflowAction): unknown {
    return (
      !action.isCalculatedValueType() &&
      action.type !== WorkflowActionType.SET_VALUE &&
      action.type !== WorkflowActionType.COPY_VALUE &&
      action.type !== WorkflowActionType.SET_VALUE_FROM_LINK &&
      action.type !== WorkflowActionType.COPY_LINK_FROM_LINK &&
      action.type !== WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES
    );
  }
}
