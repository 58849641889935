import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AttributeService } from '@private/pages/artifact-type-management/attribute/services/attribute.service';
import { AttributesModel } from '@private/pages/artifact-type-management/attribute/types/attribute.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';

@Component({
  selector: 'app-attributes',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss'],
  providers: [AttributeService],
})
export class AttributeComponent extends CoreComponent<AttributeService, AttributesModel> {
  public canDeactivate = false;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    public service: AttributeService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new AttributesModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Attributes' }]);
  }
}
