<div class="enum-list_wrapper">
  <div class="enum-list_header">
    <h5 translate>Values</h5>
    <button *ngIf="!dataType.isBoolean" pButton label="{{'Add' | translate}}" class="p-button p-button-success"
            (click)="addOption()"></button>
  </div>

  <div cdkDropList (cdkDropListDropped)="dropItem($event)">
    <div class="enum-list_item" *ngFor="let option of dataType.values; let optionI = index;" cdkDrag>
      <div class="item-placeholder" *cdkDragPlaceholder></div>

      <i class="bi bi-arrows-move" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top" pRipple></i>

      <span class="p-float-label">
          <input type="text" [id]="'label' + optionI" pInputText [(ngModel)]="option.label">
          <label [for]="'label' + optionI" translate>Label</label>
      </span>

      <span class="p-float-label order-field">
          <input type="text"
                 [id]="'value' + optionI"
                 pInputText
                 [disabled]="dataType.isBoolean"
                 [(ngModel)]="option.value"
                 (ngModelChange)="checkNumFormat($event, 'value' + optionI, option, 'value')">
          <label [for]="'value' + optionI" translate>Value</label>
      </span>

      <span class="p-float-label icon-field">
          <p-dropdown [inputId]="'icon' + optionI"
                      [options]="iconOptions"
                      [filter]="true"
                      [(ngModel)]="option.icon"
                      [autoDisplayFirst]="false"
                      [virtualScroll]="true"
                      [showClear]="true"
                      [virtualScrollItemSize]="20">
              <ng-template pTemplate="selectedItem" let-selected>
                  <i [class]="selected.value"></i>
              </ng-template>
              <ng-template let-option pTemplate="item">
                  <i [class]="option.value"></i>
                {{ option.label | translate }}
              </ng-template>
          </p-dropdown>
          <label [for]="'icon' + optionI" translate>Icon</label>
      </span>

      <span class="p-float-label color-field">
          <input type="text" [id]="'backgroundColor' + optionI" pInputText [(ngModel)]="option.backgroundColor"
                 (blur)="onColorChange(optionI, option.backgroundColor, 'backgroundColor')">
          <label [for]="'backgroundColor' + optionI" translate>Background color</label>
          <span style="display: block; position: relative">
              <p-colorPicker [(ngModel)]="option.backgroundColor"
                             [inputId]="'backgroundColorPicker' + optionI"></p-colorPicker>
              <label *ngIf="!option.backgroundColor" class="picker-label"
                     [for]="'backgroundColorPicker' + optionI"></label>
          </span>
      </span>

      <span class="p-float-label color-field">
          <input type="text" [id]="'textColor' + optionI" pInputText [(ngModel)]="option.textColor"
                 (blur)="onColorChange(optionI, option.textColor, 'textColor')">
          <label [for]="'textColor' + optionI" translate>Text color</label>
          <span style="display: block; position: relative">
              <p-colorPicker [(ngModel)]="option.textColor" [inputId]="'textColorPicker' + optionI"></p-colorPicker>
              <label *ngIf="!option.textColor" class="picker-label" [for]="'textColorPicker' + optionI"></label>
          </span>
      </span>

      <span class="p-float-label color-field">
          <input type="text" [id]="'iconColor' + optionI" pInputText [(ngModel)]="option.iconColor"
                 (blur)="onColorChange(optionI, option.iconColor, 'iconColor')">
          <label [for]="'iconColor' + optionI" translate>Icon color</label>
          <span style="display: block; position: relative">
              <p-colorPicker [(ngModel)]="option.iconColor" [inputId]="'iconColorPicker' + optionI"></p-colorPicker>
              <label *ngIf="!option.iconColor" class="picker-label" [for]="'iconColorPicker' + optionI"></label>
          </span>
      </span>

      <span class="p-float-label uri-field">
                <input type="text" [id]="'uri' + optionI" pInputText [(ngModel)]="option.uri">
                <label [for]="'uri' + optionI" translate>URI</label>
            </span>

      <button *ngIf="!dataType.isBoolean" pButton icon="pi pi-trash" class="p-button p-button-danger"
              (click)="removeOption(optionI)"></button>
    </div>
  </div>
</div>
