import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { IS_DELETED_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { SelectOption } from '@shared/types/shared.types';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { ArtifactService } from './services/artifact.service';
import { ArtifactModel, Link } from './types/artifact.types';

@Component({
  selector: 'app-artifact',
  templateUrl: './artifact.component.html',
  styleUrls: ['./artifact.component.scss'],
  providers: [ArtifactService],
})
export class ArtifactComponent extends CoreComponent<ArtifactService, ArtifactModel> {
  deletedLinks: Link[] = [];

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  constructor(
    route: ActivatedRoute,
    router: Router,
    service: ArtifactService,
    translate: TranslateService,
    announcement: AnnouncementService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new ArtifactModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Artifact' }]);
  }

  addLink(): void {
    //TODO
    this.m.links.push(new Link());
  }

  setLinkDirection(option: { originalEvent: MouseEvent; value: SelectOption<string, Link> }, link: Link): void {
    link.direction = option.value.meta;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeLink(index: number): void {
    //TODO
    const links = this.m.links;
    this.deletedLinks.push(links[index]);
    links.splice(index, 1);
  }
}
