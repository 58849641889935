import { Pipe, PipeTransform } from '@angular/core';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';

@Pipe({ name: 'getItemPlaceholder' })
export class GetItemPlaceholderPipe implements PipeTransform {
  transform(labelBehaviour: LabelBehaviourEnum, placeholder: string): string {
    if ([LabelBehaviourEnum.placeholder, LabelBehaviourEnum.static].includes(labelBehaviour)) return placeholder;
    return '';
  }
}
