import { LinkConstants } from '@shared/constants/link.constants';
import { RelationOption } from '@shared/types/link.types';
import { TableColumn } from '@shared/types/table.types';

export class LinkTypeRestrictionsFormModel {
  options = new LinkTypeRestrictionsFormOptions();
}

export class LinkTypeRestrictionsFormOptions {
  column: TableColumn[] = [];
  relationTypes: RelationOption[] = LinkConstants.relationTypes;
}
