import { Component, Input } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';
import { ApplicationListService } from '../../services/application-list.service';
import { ApplicationListModel } from '@private/pages/application-management/application-list/types/application-list.types';

@Component({
  selector: 'app-application-list-table',
  templateUrl: './application-list-table.component.html',
  styleUrls: ['./application-list-table.component.scss'],
})
export class ApplicationListTableComponent extends CoreListComponent<ApplicationResponseDto> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<ApplicationResponseDto>>;
  @Input() columns: TableColumn[];
  @Input() canUserAccessAdmin = false;
  @Input() m: ApplicationListModel;

  constructor(public readonly applicationListService: ApplicationListService) {
    super();
  }

  protected onInit(): void {
    super.onInit();
  }
}
