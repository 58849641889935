<div class="template-detail">
  <div class="content-row">
    <div>
      <p>
        <b>{{ templatesService.m.templateToDetail?.response.name }}</b>
      </p>
      <div>
        <p>{{ templatesService.m.templateToDetail?.response.description }}</p>
      </div>
    </div>
    <div>
      <app-parsed-import *ngIf="templatesService.m.parsedFile !== null" [parsedFile]="templatesService.m.parsedFile"></app-parsed-import>
    </div>
  </div>
  <div class="buttons-row" *ngIf="templatesService.m.parsedFile !== null">
    <p-tag
      *ngIf="templatesService.m.parsedFile!.type !== 'template|' + type"
      severity="danger"
      rounded="true"
      [value]="'`' + templatesService.m.parsedFile!.type + '` external template found. `template|' + type + '` external template is required.'"
    ></p-tag>
    <button
      (click)="templatesService.onImportOnly()"
      class="p-button-rounded p-button-info"
      icon="pi pi-cloud-download"
      pButton
      type="button"
      pTooltip="Import only"
      tooltipPosition="top"
    ></button>
    <button
      *ngIf="templatesService.m.parsedFile!.type === 'template|' + type"
      (click)="templatesService.onImportAndReuse()"
      class="p-button-rounded p-button-warning"
      icon="bi bi-recycle"
      pButton
      type="button"
      pTooltip="Import & reuse"
      tooltipPosition="top"
    ></button>
    <button
      *ngIf="templatesService.m.parsedFile!.type === 'template|' + type"
      (click)="templatesService.onImportAndCopy()"
      class="p-button-rounded p-button-secondary"
      icon="pi pi-copy"
      pButton
      type="button"
      pTooltip="Import & copy"
      tooltipPosition="top"
    ></button>
  </div>
</div>
