<div class="at-enum-container full-size-container">
  <ng-container [ngSwitch]="selectedVariant">
    <ng-container *ngSwitchCase="'DEFAULT'">
      <div *ngFor="let enumOption of enumOptions; let i = index" [class.cursor-pointer]="isValueClickable()"
           (click)="selectItem(i, $event)" [ngStyle]="contentColumnFormat?.styles">
        <span *ngIf="isActive(i)" class="pi pi-check-circle" style="margin-right: 10px"></span>
        {{ enumOption?.label }}
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_V1">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.no-color-variant]="!enumOption?.backgroundColor"
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [style.background-color]="enumOption?.backgroundColor"
          [style.color]="enumOption?.textColor"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.CHIP_V2">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.no-color-variant]="!enumOption?.backgroundColor"
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [style.background-color]="enumOption?.backgroundColor"
          [style.color]="enumOption?.textColor"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon"></i>
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON_TEXT">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON_COLOR_TEXT">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [style.color]="enumOption?.textColor"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
          {{ enumOption?.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.COLOR_ICON">
      <div *ngFor="let enumOption of enumOptions; let i = index; let last = last" class="chip-layout"
           (click)="selectItem(i, $event)">
        <span
          [class.cursor-pointer]="isValueClickable()"
          [class.with-separating-margin]="enumOptions.length > 1 && !last"
          [ngClass]="isActive(i) ? 'active' : ''"
        >
          <i class="{{ enumOption?.icon }} enum-value-icon" [style.color]="enumOption?.iconColor"></i>
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="layoutVariant.FULL_CELL">
      <div
        *ngFor="let enumOption of enumOptions; let i = index"
        class="full-cell-enum full-size-container"
        [class.no-color-variant]="!enumOption?.backgroundColor"
        [class.cursor-pointer]="isValueClickable()"
        [style.background-color]="enumOption?.backgroundColor"
        [style.color]="enumOption?.textColor"
        (click)="selectItem(i, $event)"
      >
        <span *ngIf="isActive(i)" class="pi pi-check-circle" style="margin-right: 10px"></span>
        {{ enumOption?.label }}
      </div>
    </ng-container>

    <div *ngSwitchCase="'CIRCLE'">not implemented yet</div>

    <div *ngSwitchCase="layoutVariant.DROPDOWN" style="width: 100%">
      <p-multiSelect
        [options]="enumOptions"
        [(ngModel)]="dropDownModel"
        (onChange)="onDropdownChange()"
        [filter]="true"
        [placeholder]="placeholder"
        [style]="{ width: '100%' }"
        filterBy="label"
        appendTo="body"
      ></p-multiSelect>
    </div>

    <div *ngSwitchCase="layoutVariant.CUSTOM">
      <!-- [applicationId]="applicationId" -->
      <ng-container *ngIf="customVariantTemplate$ | async as widget">
        <app-widget-container
          *ngIf="widget.code === widgetType.card"
          [artifact]="artifactDto || artifact"
          [isClickable]="false"
          [isLayoutMode]="true"
          [isListMatrixCard]="true"
          [isPreviewMode]="isPreviewMode"
          [disabled]="isPreviewMode"
          [widget]="widget"
          appWidgetContainerStopPropagation
        >
        </app-widget-container>
        <app-widget-container
          *ngIf="widget.code === widgetType.avr"
          [artifact]="artifactDto || artifact"
          [isLayoutMode]="true"
          [disabled]="isPreviewMode"
          [widget]="widget"
          appWidgetContainerStopPropagation
        >
        </app-widget-container>
      </ng-container>
    </div>
  </ng-container>
</div>
