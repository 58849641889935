import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy } from '@angular/core';
import { BaseDataType, EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IconsService } from '@shared/services/icons.service';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-enumerated-details',
  templateUrl: './enumerated-details.component.html',
  styleUrls: ['./enumerated-details.component.scss'],
})
export class EnumeratedDetailsComponent implements OnDestroy {
  @Input() dataType: NewDataType;

  iconOptions: SelectOption<string, string>[];
  iconCsSubscription$: Subscription;

  constructor(private iconsService: IconsService, public elvisUtil: ElvisUtil) {
    this.iconCsSubscription$ = this.iconsService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => (this.iconOptions = iconOptions));
  }

  addOption(): void {
    const newOption = new EnumeratedOption();
    if (this.dataType.baseDataType == BaseDataType.integer) {
      newOption.value = this.getNextIntegerDetailValue();
    }
    if (!this.dataType.values) this.dataType.values = [];
    this.dataType.values.push(newOption);
    setTimeout(() => {
      const label = document.getElementById('label' + (this.dataType.values && this.dataType.values.length - 1));
      label && label.focus();
    });
  }

  removeOption(index: number): void {
    this.dataType.values && this.dataType.values.splice(index, 1);
  }

  onColorChange(index: number, color: string | null, colorKey: keyof Pick<EnumeratedOption, 'backgroundColor' | 'textColor' | 'iconColor'>): void {
    if (color === '' && this.dataType.values && this.dataType.values[index]) {
      color = null;
      this.dataType.values[index][colorKey] = color;
    }
  }

  checkNumFormat(event: string, id: string, targetObject: any, key: string): void {
    this.elvisUtil.checkNumFormat(
      event,
      id,
      targetObject,
      key,
      this.dataType.baseDataType === BaseDataType.integer,
      this.dataType.baseDataType === BaseDataType.decimal,
    );
  }

  ngOnDestroy(): void {
    this.iconCsSubscription$.unsubscribe();
  }

  dropItem(event: CdkDragDrop<any[]>): void {
    this.dataType.values && moveItemInArray(this.dataType.values, event.previousIndex, event.currentIndex);
  }

  private getNextIntegerDetailValue(): string | void {
    if (this.dataType.values) {
      const sorted = [...this.dataType.values].sort((a, b) => parseInt(b.value) - parseInt(a.value));
      return sorted.length ? (+sorted[0].value + 1).toString() : '1';
    }
  }
}
