<button
  pButton
  pRipple
  [label]="'Cancel' | translate"
  class="p-button p-button-danger"
  type="button"
  (click)="cancel.emit()">
</button>

<button
  [disabled]="saveDisabled"
  pButton
  pRipple
  [label]="'Save' | translate"
  class="p-button p-button-success ml-2"
  type="submit"
  (click)="save.emit(false)">
</button>

<button
  [disabled]="saveDisabled"
  pButton
  pRipple
  [label]="'Save and notify' | translate"
  class="p-button p-button-success ml-2"
  type="submit"
  (click)="save.emit(true)">
</button>
