import { UiConfig } from '@private/pages/profile/types/user.types';
import { LanguageEnum } from '@shared/components/language-switcher/types/language-switcher.types';
import { TenantUserLoginInfo } from '@shared/components/tenant-switcher/types/tenant-switcher.types';
import { UserFull } from '@shared/types/user.types';

export enum StateKey {
  session = 'sessionState',
  master = 'masterState',
  // TODO - to be removed soon
  workflowsAllRules = 'workflowsAllRules',
}

export class LocalStorageSessionState {
  token: string | null = null;
  user: UserFull | null = null;
  tenants: TenantUserLoginInfo[] = [];
  clientData: ClientData = new ClientData();
  selectedTenant: string | null = null;
  selectedApplication: string | null = null;

  constructor(state?: Partial<LocalStorageSessionState>) {
    state && Object.assign(this, state);
  }
}

export class LocalStorageMasterState {
  token: string | null = null;
  user: UserFull | null = null;

  constructor(state?: Partial<LocalStorageSessionState>) {
    state && Object.assign(this, state);
  }
}

export class ClientData {
  constructor(public language: LanguageEnum = LanguageEnum.EN, public uiConfig: UiConfig = new UiConfig()) {}
}
