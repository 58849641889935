import { Injectable } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { LimitedTopBarComponent } from '@limited/components/limited-top-bar/limited-top-bar.component';
import { LimitedTopBarModel } from '@limited/components/limited-top-bar/types/limited-top-bar.model';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CoreService } from '@shared/core/services/core.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class LimitedTopBarService extends CoreService<LimitedTopBarComponent, LimitedTopBarModel> {
  constructor(
    public readonly localStorageService: LocalStorageService,
    private readonly newCache: NewCacheService,
    private readonly authorizationService: AuthorizationService,
  ) {
    super();
  }

  async init(context: LimitedTopBarComponent, model: LimitedTopBarModel): Promise<void> {
    super.init(context, model);
    this.c.registerSubscription(
      this.newCache.user.subscribe(user => {
        if (!user) return;

        this.m.user = user as SelfUserResponseDto;
        this.m.canUserAccessAdmin = Boolean(this.m.user.tenant?.isAdmin || this.m.user.tenant?.applications.some(app => app.isAdmin));
      }),
    );
  }

  async goToAdmin(): Promise<void> {
    this.newCache.refreshCache();
    this.authorizationService.resetLoggedUserData();
    await this.c.router.navigate(['/admin']);
  }
}
