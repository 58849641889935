import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttributeActionHandlerService } from '../services';
import { ModifyAttributeValueEvent, ModifyAttributeValueOperation } from '../types';
import { AttributeValueModifiable } from '../types/attribute-value-modifiable';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appModifyAttributeValue]',
})
export class ModifyAttributeValueDirective extends AbstractRuleAttributeDirective implements OnInit {
  @Input() attributeOwner: AttributeValueModifiable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.initSubscription = this.actionAttributeHandler.modifyAttributeValueEventMap$
      .pipe(
        filter(e => !!e.event && this.isMatchingAttributeData(e.event) && this.actionAttributeHandler.containsModifyAttributeValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getModifyAttributeValueEvent(this.key)),
      )
      .subscribe((modifyAttributeValueEvent: ModifyAttributeValueEvent) => {
        this.modifyAttributeValue(modifyAttributeValueEvent.value, modifyAttributeValueEvent.operation);
        this.actionAttributeHandler.removeModifyAttributeValueEventFromMap(this.key, false);
      });
  }

  private modifyAttributeValue(value: any, operation: ModifyAttributeValueOperation) {
    this.attributeOwner.modifyAttributeValue(value, operation);
  }
}
