import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemUserService } from '@api/services/system-user.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { lastValueFrom } from 'rxjs';
import { SystemUserListService } from './services/system-user-list.service';
import { SystemUserListModel } from './types/system-user-list.types';

@Component({
  selector: 'app-user-list',
  templateUrl: './system-user-list.component.html',
  styleUrls: ['./system-user-list.component.scss'],
  providers: [SystemUserListService],
})
export class SystemUserListComponent extends CoreComponent<SystemUserListService, SystemUserListModel> {
  loadDataMethod: (meta?: Partial<ListReqMetaData>) => Promise<any>;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: SystemUserListService,
    announcement: AnnouncementService,
    private readonly systemUserService: SystemUserService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new SystemUserListModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Users' }]);
    this.loadDataMethod = ((meta?: Partial<ListReqMetaData>): Promise<any> => lastValueFrom(this.systemUserService.systemUserControllerList(meta))).bind(this);
  }
}
