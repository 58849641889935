import { ListContainer } from '@shared/types/list-container.types';

export class TextFilterOptionsTypes {
  matchType = new ListContainer<TextFilterMatchType>();
  ruleType = new ListContainer<TextFilterRuleType>();

  constructor() {
    this.matchType.setList(Object.keys(TextFilterMatchType).map(key => TextFilterMatchType[key as keyof typeof TextFilterMatchType]));
    this.ruleType.setList(Object.keys(TextFilterRuleType).map(key => TextFilterRuleType[key as keyof typeof TextFilterRuleType]));
  }
}

export enum TextFilterMatchType {
  matchAll = 'Match all',
  matchAny = 'Match any',
}

export enum TextFilterRuleType {
  startsWith = 'Starts with',
  contains = 'Contains',
  notContains = 'Not contains',
  endsWith = 'Ends with',
  equals = 'Equals',
  notEquals = 'Not equals',
}
