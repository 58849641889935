import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SELECTED_TENANT_KEY, TENANTS_KEY } from '@shared/constants/constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { StateKey } from '@shared/types/local-storage.types';
import { SelectOption, SystemTenant } from '@shared/types/shared.types';
import { Subject } from 'rxjs';
import { TenantUserLoginInfo } from '../types/tenant-switcher.types';

@Injectable({ providedIn: 'root' })
export class TenantSwitcherService {
  tenants: SelectOption<string, string>[] = [];
  selectedTenantId: string | null = null;
  selectedTenantId$: Subject<string> = new Subject<string>();

  constructor(private readonly localStorageService: LocalStorageService, private readonly router: Router) {}

  async init(): Promise<void> {
    const sessionState = this.localStorageService.get(StateKey.session);
    const tenants: TenantUserLoginInfo[] = sessionState[TENANTS_KEY];
    const segments = new URL(window.location.href).pathname.split('/').splice(1);
    const urlTenant: string = segments[1];
    const selectedTenant: string = urlTenant && tenants.find(option => option.id === urlTenant) ? urlTenant : sessionState[SELECTED_TENANT_KEY];

    this.tenants = tenants && tenants.length ? tenants.map(tenant => new SelectOption(tenant.name, tenant.id)) : [new SelectOption(SystemTenant.anonymous)];

    await Promise.resolve(selectedTenant).then(tenantId => {
      const tenant = this.tenants.find(tenant => tenant.value === tenantId);
      this.selectedTenantId = (tenant && tenant.value) || (this.tenants[0] && this.tenants[0].value);
      this.selectedTenantId$.next(this.selectedTenantId);
    });
  }

  async changeTenant(tenantId: string): Promise<void> {
    const segments = new URL(window.location.href).pathname.split('/').splice(1);
    segments[1] = tenantId;
    this.selectedTenantId$.next(tenantId);
    await this.router.navigateByUrl(`/${segments.join('/')}`);

    this.localStorageService.setToState(StateKey.session, SELECTED_TENANT_KEY, tenantId);
  }

  setTenants(tenants: TenantUserLoginInfo[]): void {
    this.tenants = tenants.map(tenant => new SelectOption(tenant.name, tenant.id));
    this.localStorageService.setToState(StateKey.session, TENANTS_KEY, tenants);
  }

  async setTenantOptions(): Promise<void> {
    // const { tenant, isSystemAdmin } = userResponseDto;
    // const systemTenants = (await this.tenantGlobalService.list({ filter: JSON.stringify({ _id: { $in: tenants.map(tenant => ({ $oid: tenant.id })) } }) }))
    //   .data;
    // const tenantOptions = systemTenants.map(({ id, name }) => ({ id, name }));
    //
    // isSystemAdmin && tenantOptions.unshift({ id: SystemTenant.system, name: SystemTenant.system });
    //
    // this.setTenants(tenantOptions);
  }
}
