import { Component, Input } from '@angular/core';
import { UserPageModel } from '@private/pages/user-management/user/types/user-page.types';
import { EMAIL_LABEL, IS_TENANT_ADMIN_LABEL } from '@shared/constants/constants';

@Component({
  selector: 'app-admin-user-form',
  templateUrl: './admin-user-form.component.html',
  styleUrls: ['./admin-user-form.component.scss'],
})
export class AdminUserFormComponent {
  @Input() m: UserPageModel;

  EMAIL_LABEL = EMAIL_LABEL;
  IS_TENANT_ADMIN_LABEL = IS_TENANT_ADMIN_LABEL;
}
