import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { BlockIds } from '@private/pages/page-management/page-builder-graphical/types/block-ids';
import { BlockPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/block-part-drop-event';
import { BlockPartIds } from '@private/pages/page-management/page-builder-graphical/types/block-part-ids';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { RowDropEvent } from '@private/pages/page-management/page-builder-graphical/types/row-drop-event';
import { WidgetIntoBlockDropEvent } from '@private/pages/page-management/page-builder-graphical/types/widget-into-block-drop-event';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PageBuilderGraphicalDragDropService {
  sectionIds: string[];
  private blockIdsSubject: BehaviorSubject<BlockIds> = new BehaviorSubject<BlockIds>({ tree: [], flat: [] });
  /* eslint-disable @typescript-eslint/member-ordering */
  blockIds$: Observable<BlockIds> = this.blockIdsSubject.asObservable();
  private partIdsSubject: BehaviorSubject<BlockPartIds> = new BehaviorSubject<BlockPartIds>({ tree: [], flat: [] });
  partIds$: Observable<BlockPartIds> = this.partIdsSubject.asObservable();
  allBlockAndPartIds$ = combineLatest([this.blockIds$, this.partIds$]).pipe(
    map(([blockIds, partIds]: [BlockIds, BlockPartIds]) => [...blockIds.flat, ...partIds.flat]),
  );
  blockIds: BlockIds;
  partIds: BlockPartIds;
  currentSections: PageSection[] = [];
  moveIds: string[] = [];

  /* eslint-enable @typescript-eslint/member-ordering */
  dropSection({ previousIndex, currentIndex }: CdkDragDrop<void>, { sections, modalId }: Page): void {
    moveItemInArray(sections, previousIndex, currentIndex);
    this.setDropListConnectionIds(sections, true, modalId);
  }

  dropRow({ previousContainer, container, previousIndex, currentIndex, item }: CdkDragDrop<RowDropEvent>, page: Page): void {
    if (previousContainer === container) {
      moveItemInArray(container.data.rows, previousIndex, currentIndex);
    } else {
      const { rows, sectionIndex } = previousContainer.data;
      const { rowIndex } = item.data;
      const relocatedRow = rows[rowIndex];

      relocatedRow.parts.forEach((part: PageBlockPart) => part.widget?.saveStateBeforeRelocation());
      page.deleteRow({ sectionIndex, rowIndex });
      transferArrayItem([relocatedRow], container.data.rows, 0, currentIndex);
    }

    this.setDropListConnectionIds(page.sections, true, page.modalId);
  }

  dropBlockPart({ previousContainer, container, previousIndex, currentIndex, item }: CdkDragDrop<BlockPartDropEvent>, page: Page): void {
    if (previousContainer === container) {
      moveItemInArray(container.data.parts, previousIndex, currentIndex);

      return;
    }

    const { parts, rowIndex, blockIndex, modalId } = previousContainer.data;
    let { sectionIndex } = previousContainer.data;
    const { partIndex } = item.data;
    const relocatedBlockPart = parts[partIndex];

    if (page.modalId) {
      sectionIndex = this.currentSections[sectionIndex].innerIndex;
    }

    relocatedBlockPart.widget?.saveStateBeforeRelocation();
    if (page.modalId !== modalId) {
      relocatedBlockPart.widget?.id && this.moveIds.push(relocatedBlockPart.widget?.id);
    } else {
      page.deleteBlockPart({ sectionIndex, rowIndex, blockIndex, partIndex });
    }

    transferArrayItem([relocatedBlockPart], container.data.parts, 0, currentIndex);
    this.setDropListConnectionIds(page.sections, true, page.modalId);
  }

  dropWidgetIntoBlockPredicate({ data: { code } }: CdkDrag<WidgetIntoBlockDropEvent>, { data: { parts } }: CdkDropList<BlockPartDropEvent>): boolean {
    if (code) {
      return parts.every((part: PageBlockPart) => part.widget);
    }

    return true;
  }

  dropWidgetBelowPredicate(index: number, { data: { code } }: CdkDrag<WidgetIntoBlockDropEvent>, dropList: CdkDropList<BlockPartDropEvent>): boolean {
    if (code && dropList.data.parts.every((part: PageBlockPart) => part.widget)) {
      return index === dropList.data.parts.length;
    }

    return true;
  }

  setDropListConnectionIds(sections: PageSection[], isFiltering = false, modalId?: string | null): void {
    const sectionIds: string[] = [];
    const blockIds: BlockIds = { tree: [], flat: [] };
    const partIds: BlockPartIds = { tree: [], flat: [] };

    let list: PageSection[] = sections;
    list = [];
    const ids: string[] = [];
    const indexMap: any = {};
    isFiltering && (this.currentSections = this.currentSections.filter(section => section.modalId !== modalId));

    [...this.currentSections, ...sections].forEach((section, indx) => {
      const modalId = section.modalId ? section.modalId : '';
      section.index = indx;
      const sectionId = modalId + 'section' + section.index;
      if (!ids.includes(sectionId)) {
        ids.push(sectionId);

        if (!indexMap[modalId]) {
          indexMap[modalId] = 0;
        }
        section.innerIndex = indexMap[modalId]++;

        list.push(section);
      }
    });

    list.forEach((section: PageSection, sectionIndex: number) => {
      const modalId = section.modalId ? section.modalId : '';
      section.index = sectionIndex;

      const sectionId = modalId + 'section' + sectionIndex;
      sectionIds.push(sectionId);
      blockIds.tree.push([]);
      partIds.tree.push([]);

      section.rows.forEach((row: PageRow, rowIndex: number) => {
        blockIds.tree[sectionIndex].push([]);
        partIds.tree[sectionIndex].push([]);

        row.blocks.forEach((block: PageBlock, blockIndex: number) => {
          const blockId = sectionId + 'row' + rowIndex + 'block' + blockIndex;
          blockIds.tree[sectionIndex][rowIndex].push(blockId);
          blockIds.flat.push(blockId);
          partIds.tree[sectionIndex][rowIndex].push([]);

          block.parts.forEach((part: PageBlockPart, partIndex: number) => {
            const partId = !part.widget ? blockId + 'part' + partIndex : '';
            partIds.tree[sectionIndex][rowIndex][blockIndex].push(partId);

            if (partId) {
              partIds.flat.push(partId);
            }
          });
        });
      });
    });

    this.sectionIds = sectionIds;
    this.blockIdsSubject.next(blockIds);
    this.partIdsSubject.next(partIds);

    this.currentSections = list;
    this.blockIds = blockIds;
    this.partIds = partIds;
  }
}
