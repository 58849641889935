import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models';
import { SavedAndUnsavedFiles } from '@shared/types/file.types';

@Pipe({ name: 'getDataForFileArtifact' })
export class GetDataForFileArtifactPipe implements PipeTransform {
  transform(artifact: ArtifactResponseDto): SavedAndUnsavedFiles {
    return {
      files: artifact,
      unSavedFiles: null,
    };
  }
}
