import { DocumentGenerationAvrWidgetSettingsDto as _SETTINGS_DTO, DocumentGenerationAvrQueryDto as _QUERY_DTO } from '@api/models';

export type AvrTemplateFileArtifactSourcesType = _SETTINGS_DTO['templateFileArtifactSource'];
export const AvrTemplateFileArtifactSources: Record<string, AvrTemplateFileArtifactSourcesType> = Object.freeze({
  staticArtifact: 'STATIC_ARTIFACT',
  dynamicArtifact: 'DYNAMIC_ARTIFACT',
});

export class DocumentGenerationAvrQueryDto implements _QUERY_DTO {
  templateFileArtifactId: string;

  constructor(dto: Partial<DocumentGenerationAvrQueryDto>) {
    Object.assign(this, dto);
  }
}

export class DocumentGenerationAvrWidgetSettingsDto implements _SETTINGS_DTO {
  templateFileArtifactId: null | string = null;
  templateFileArtifactListeningKey: null | string = null;
  templateFileArtifactSource: AvrTemplateFileArtifactSourcesType = null;
}
