import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeListModel } from '@private/pages/artifact-type-management/data-type-list/types/data-type-list.types';
import { APPLICATION_ID_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getDataTypeFilterOptions' })
export class GetDataTypeFilterOptionsPipe implements PipeTransform {
  transform(columnKey: string, dataTypeListModel: DataTypeListModel): SelectOption<string, string>[] {
    if (columnKey === APPLICATION_ID_KEY) return dataTypeListModel.applications.toSelectOptions(NAME_KEY, ID_KEY);
    else if (columnKey === 'baseDataType') return dataTypeListModel.baseDataTypeOptions;
    else if (columnKey === 'kind') return dataTypeListModel.kindOptions;
    return [];
  }
}
