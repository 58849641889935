import { ArtifactValueExtractor } from './artifact-value-extractor';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { formatArtifactSection } from '@shared/methods/artifact.methods';

export class SectionAttributeExtractor implements ArtifactValueExtractor {
  static readonly sectionAttributeId = NonAttributeKeys.SECTION_ATTRIBUTE_ID;

  static isFolderPathAttribute(attributeId: string): boolean {
    return attributeId === SectionAttributeExtractor.sectionAttributeId;
  }

  getValue(artifactDto: ArtifactResponseDto): string {
    console.log(artifactDto);
    return formatArtifactSection(artifactDto);
  }
}
