import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '../types/runtime-state-notification.types';

@Injectable({ providedIn: 'root' })
export class RuntimeStateNotificationService {
  readonly events$: Subject<RuntimeStateNotification>;

  constructor() {
    this.events$ = new Subject<RuntimeStateNotification>();
  }

  notify(type: RuntimeStateNotificationEnum, data: any, hash: string | null = null, extras: any = null): void {
    const notification = new RuntimeStateNotification(type, data, hash, extras);
    this.events$.next(notification);
  }
}
