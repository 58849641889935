import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { ArtifactTypeOptionsDisablingService } from '@private/pages/artifact-type-management/artifact-type/services/artifact-type-options-disabling.service';
import { LinkValidationType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DisabledArtifactTypesByRestriction } from '@private/pages/artifact-type-management/disabled-artifact-types-by.restriction';
import { LinkTypeRestrictionsFormModel } from '@private/pages/artifact-type-management/link-type/components/link-type-restrictions-form/types/link-type-restrictions-form.types';
import {
  DESTINATION_ARTIFACT_TYPES_KEY,
  DESTINATION_LABEL,
  IS_LINK_REQUIRED_LABEL,
  LINK_REQUIRED_KEY,
  NOTIFY_DESTINATION_KEY,
  NOTIFY_DESTINATION_LABEL,
  NOTIFY_SOURCE_KEY,
  NOTIFY_SOURCE_LABEL,
  RELATION_TYPE_KEY,
  RELATION_TYPE_LABEL,
  SOURCE_ARTIFACT_TYPES_KEY,
  SOURCE_ARTIFACT_TYPES_LABEL,
} from '@shared/constants/constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestriction, RelationTypeEnum, UngroupedArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, share, startWith, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-link-type-restrictions-form',
  templateUrl: './link-type-restrictions-form.component.html',
  styleUrls: ['./link-type-restrictions-form.component.scss'],
})
export class LinkTypeRestrictionsFormComponent implements OnChanges, OnInit {
  @Input() linkType: LinkType;
  @Input() artifactTypes: NewArtifactType[];
  @Input() applications: NewApplication[];

  m = new LinkTypeRestrictionsFormModel();
  linkValidationType = [
    new SelectOption(LinkValidationType.none, LinkValidationType.none, false),
    new SelectOption(LinkValidationType.source, LinkValidationType.source, false),
    new SelectOption(LinkValidationType.destination, LinkValidationType.destination, false),
  ];

  disabledArtifactTypes$: Observable<DisabledArtifactTypesByRestriction>;

  private readonly restrictions: BehaviorSubject<LinkRestriction[]> = new BehaviorSubject<LinkRestriction[]>([]);
  private readonly restrictionsChange: Subject<void> = new Subject<void>();

  constructor(
    private readonly translateUtil: TranslateUtil,
    private readonly confirmationService: ConfirmationService,
    private readonly blockUiService: BlockUiService,
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly artifactTypeOptionsDisablingService: ArtifactTypeOptionsDisablingService,
  ) {}

  ngOnChanges({ linkType }: SimpleChanges): void {
    if (linkType) {
      this.restrictions.next(linkType.currentValue.restrictions);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.initOptions();
    if (!this.linkType.restrictions) {
      this.linkType.restrictions = [];
    }

    this.disabledArtifactTypes$ = combineLatest([this.restrictions, this.restrictionsChange]).pipe(
      startWith(null),
      map(() => this.artifactTypeOptionsDisablingService.getDisabledArtifactTypes(this.linkType.restrictions)),
      share(),
    );
  }

  addRestriction(): void {
    this.linkType.restrictions.push(new LinkRestriction());
    this.restrictionsChange.next();
  }

  generateRestrictionUsageMessage(countOfUsagesInLinkedArtifactWidgets: number): string {
    return +countOfUsagesInLinkedArtifactWidgets > 0 ? ` This restriction used in ${countOfUsagesInLinkedArtifactWidgets} linked artifact widgets!` : '';
  }

  async deleteRestriction(index: number): Promise<void> {
    const linkId = this.linkType.id;
    const sourceArtifactTypeId = this.linkType.restrictions[index].sourceArtifactTypeId;
    const destinationArtifactTypeId = this.linkType.restrictions[index].destinationArtifactTypeId;
    const linkName = this.linkType.name;
    let countOfUsagesInWidgets = 0;
    if (linkId && sourceArtifactTypeId && destinationArtifactTypeId) {
      countOfUsagesInWidgets = await LinkMethods.checkRestrictionBeforeDelete(
        linkId,
        sourceArtifactTypeId,
        destinationArtifactTypeId,
        this.tenantWidgetService,
      );
    }
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + linkName + '?' + this.generateRestrictionUsageMessage(countOfUsagesInWidgets),
      acceptLabel,
      rejectLabel,
      accept: async () => {
        this.blockUiService.blockUi();

        try {
          this.linkType.restrictions.splice(index, 1);
        } catch (e) {
          console.error(e);
        } finally {
          this.blockUiService.unblockUi();
          this.restrictionsChange.next();
        }
      },
    });
  }

  onRelationChange(value: RelationTypeEnum, restriction: LinkRestriction): void {
    restriction && Object.assign(restriction, LinkMethods.changeRelation(value));
  }

  onRelationShow(selectedRestriction: UngroupedArtifactTypeLinkRestriction): void {
    this.m.options.relationTypes.forEach(relationType => {
      if (relationType.value === RelationTypeEnum.oneToMany || relationType.value === RelationTypeEnum.manyToOne) {
        relationType.disabled = false;
      }
    });
    // if source and target artifact types are the same disable relationTypes 'one to many' and 'many to one'
    if (selectedRestriction.destinationArtifactTypeId === selectedRestriction.sourceArtifactTypeId) {
      this.m.options.relationTypes.forEach(relationType => {
        if (relationType.value === RelationTypeEnum.oneToMany || relationType.value === RelationTypeEnum.manyToOne) {
          relationType.disabled = true;
        }
      });
    }
  }

  onSourceOrDestinationChange(artifactTypeId: string, isSource: boolean, selectedRestriction: UngroupedArtifactTypeLinkRestriction): void {
    // set link relation to 'many to many' if source and target artifact types are the same
    if (artifactTypeId === (isSource ? selectedRestriction.destinationArtifactTypeId : selectedRestriction.sourceArtifactTypeId)) {
      selectedRestriction.singleSource = false;
      selectedRestriction.singleDestination = false;
    }
    this.restrictionsChange.next();
  }

  convertRelationValue(singleSource: boolean, singleDestination: boolean): RelationTypeEnum {
    return LinkMethods.convertRelationValue(singleSource, singleDestination);
  }

  private async initOptions(): Promise<void> {
    this.m.options.column = [
      new TableColumn(IS_LINK_REQUIRED_LABEL, LINK_REQUIRED_KEY),
      new TableColumn(SOURCE_ARTIFACT_TYPES_LABEL, SOURCE_ARTIFACT_TYPES_KEY),
      new TableColumn(NOTIFY_SOURCE_LABEL, NOTIFY_SOURCE_KEY, undefined, { minWidthCol: true }),
      new TableColumn(RELATION_TYPE_LABEL, RELATION_TYPE_KEY),
      new TableColumn(DESTINATION_LABEL, DESTINATION_ARTIFACT_TYPES_KEY),
      new TableColumn(NOTIFY_DESTINATION_LABEL, NOTIFY_DESTINATION_KEY, undefined, { minWidthCol: true }),
    ];
  }
}
