import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonFeTrigger } from '@workflows/types/triggers/common-fe-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerArtifactWidgetCreateMode extends AbstractWorkflowTrigger<CommonFeTrigger> {
  widgetId: string;

  constructor(widgetId?: string) {
    super(CommonFeTrigger);
    this.widgetId = widgetId || this.widgetId;
    this.type = RuleTriggerType.ARTIFACT_WIDGET_CREATE_MODE;
    this.widgetRequired = true;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    if (this.type === workflowTrigger.type) {
      const trigger = workflowTrigger as WorkflowTriggerArtifactWidgetCreateMode;
      return this.widgetId === trigger.widgetId;
    }
    return false;
  }

  isValid(): boolean {
    return !!this.widgetId;
  }
}
