import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { FilterWidgetService } from '@widgets/filter-widget/services/filter-widget.service';
import { FilterWidgetModel, FilterWidgetModelDto, FilterWidgetValue } from '@widgets/filter-widget/types/filter-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-filter-widget',
  templateUrl: './filter-widget.component.html',
  styleUrls: ['./filter-widget.component.scss'],
  providers: [FilterWidgetService],
})
export class FilterWidgetComponent extends WidgetsCoreComponent<FilterWidgetValue> implements OnInit {
  m: FilterWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<FilterWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: FilterWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as any as FilterWidgetModelDto) : null);
  }
}
