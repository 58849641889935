import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export class BaseWorkflowAction extends AbstractWorkflowAction<CommonFeAction> {
  canBeExecuted(): boolean {
    return false;
  }

  execute(): void {
    // do nothing
  }

  isValid(): boolean {
    return false;
  }
}
