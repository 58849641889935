import { Component, Input } from '@angular/core';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';

@Component({
  selector: 'app-page-block-part-settings',
  templateUrl: './page-block-part-settings.component.html',
  styleUrls: ['./page-block-part-settings.component.scss'],
})
export class PageBlockPartSettingsComponent {
  @Input() part: PageBlockPart;
}
