import { CommonFeTrigger } from '@workflows/types';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class BaseWorkflowTrigger extends AbstractWorkflowTrigger<CommonFeTrigger> {
  isMatchingWorkflowTrigger(): boolean {
    return false;
  }

  isValid(): boolean {
    return false;
  }
}
