import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonView } from '@widgets/sidebar-widget/types/sidebar-widget-arrow-button.types';
import { ArtifactWidgetOptions } from '@widgets/artifact-widget/types/artifact-widget-options.types';
import { SidebarWidgetCopyPasterService } from '@widgets/sidebar-widget/services/sidebar-widget-copy-paster.service';
import { SidebarButtonView } from '@widgets/sidebar-widget/types/sidebar-widget-button.types';

@Component({
  selector: 'app-sidebar-button-form',
  templateUrl: './sidebar-button-form.component.html',
  styleUrls: ['./sidebar-button-form.component.scss'],
})
export class SidebarButtonFormComponent {
  @Input() buttonView: ButtonView;
  @Input() label: string;
  @Input() allViews: SidebarButtonView;
  @Output() onChange = new EventEmitter();
  @Output() updateAll = new EventEmitter<SidebarButtonView>();
  options: ArtifactWidgetOptions = new ArtifactWidgetOptions();

  constructor(public copyPasteService: SidebarWidgetCopyPasterService) {}

  isChange(): void {
    !this.buttonView.contentStyle.backgroundColor && (this.buttonView.contentStyle.backgroundColor = 'inherit');
    this.onChange.emit(this.buttonView);
  }

  doPasteAll(isContentStyle = true): void {
    this.allViews && this.copyPasteService.pasteAll(isContentStyle, this.allViews);
    this.updateAll.emit(this.allViews);
  }
}
