import { Injectable } from '@angular/core';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { BulkOperationResult } from '@shared/components/bulk-artifact-edit-popup/types/bulk-operation-result';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { Exception } from '@shared/types/exception.types';
import { ListContainer } from '@shared/types/list-container.types';
import { FolderTreeNode } from '@widgets/folder-widget/types/folder-widget.types';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtifactMovementService {
  constructor(private readonly cache: NewCacheService, private readonly tenantArtifactService: TenantArtifactService) {}

  async moveArtifacts(
    artifacts: NewArtifact[],
    folder: FolderTreeNode,
    attributes: ListContainer<NewAttribute>,
    dataTypes: ListContainer<NewDataType>,
  ): Promise<BulkOperationResult> {
    let success = 0;
    let failure = 0;

    await Promise.all(
      artifacts
        .filter((artifact: NewArtifact) => artifact.folderData.parentId !== folder.id)
        .map(async (artifact: NewArtifact) => {
          const body = artifact.toUpdateDto(attributes, dataTypes);
          body.folderData = { parentId: folder.id } as any;

          try {
            await lastValueFrom(this.tenantArtifactService.artifactControllerUpdate({ body, notify: false, resetSequence: false })).then(dto =>
              this.cache.data.artifacts.setItem(dto),
            );
            success++;
          } catch (e) {
            failure++;
            console.error(
              new Exception({
                name: 'BulkArtifactMovementException',
                message: 'An error occurred while moving an artifact',
                originalEvent: e,
              }),
            );
          }
        }),
    );
    const skip = artifacts.length - success - failure;

    return { success, failure, skip };
  }
}
