import { Pipe, PipeTransform } from '@angular/core';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { HorizontalDividerStyles, VerticalDividerStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';

@Pipe({ name: 'getDividerStyles' })
export class GetDividerStylesPipe implements PipeTransform {
  transform(item: MenuItem, m: MenuWidgetModel, horizontal: boolean): VerticalDividerStyles | HorizontalDividerStyles {
    if (item.usesDefaultStyle) {
      if (new IsItemInFirstLevelPipe().transform(m.items.menu, item)) {
        return horizontal ? m.styles.defaultFirstLevelItemStyles.horizontalDividerStyles : m.styles.defaultFirstLevelItemStyles.verticalDividerStyles;
      } else {
        return horizontal ? m.styles.defaultSubMenuItemStyles.horizontalDividerStyles : m.styles.defaultSubMenuItemStyles.verticalDividerStyles;
      }
    }
    return horizontal ? m.styles.horizontalDivider[item.hash] : m.styles.verticalDivider[item.hash];
  }
}
