import { Component, OnInit } from '@angular/core';
import { SidebarMenuService } from '@private/../shared/components/sidebar-menu/services/sidebar-menu.service';
import { ConfigService } from '@private/pages/profile/components/config/services/config.service';
import { UiConfig } from '@private/pages/profile/types/user.types';
import { SidebarMenuItem } from '@shared/components/sidebar-menu/types/sidebar-menu.types';
import { CLIENT_DATA_KEY, UI_CONFIG_KEY } from '@shared/constants/constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { AdminTopBarCompatible } from '@shared/types/admin-top-bar.types';
import { HasUiConfig } from '@shared/types/has-ui-config.types';
import { StateKey } from '@shared/types/local-storage.types';
import { AppInterface } from '@shared/types/shared.types';
import { SidebarMenuCompatible, SidebarMenuItemCompatible } from '@shared/types/sidebar-menu.types';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
})
export class SystemComponent implements OnInit, HasUiConfig, AdminTopBarCompatible, SidebarMenuCompatible, SidebarMenuItemCompatible {
  uiConfig: UiConfig;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  menuClick: boolean;
  search = false;
  searchClick = false;
  userMenuClick: boolean;
  topBarUserMenuActive: boolean;
  notificationMenuClick: boolean;
  topBarNotificationMenuActive: boolean;
  rightMenuClick: boolean;
  rightMenuActive: boolean;
  configActive: boolean;
  configClick: boolean;
  resetMenu: boolean;
  menuHoverActive = false;
  menuItems: SidebarMenuItem[];
  appInterface = AppInterface;

  constructor(
    public readonly localStorageService: LocalStorageService,
    private readonly sidebarMenuService: SidebarMenuService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly configService: ConfigService<SystemComponent>,
  ) {}

  ngOnInit(): void {
    this.configService.setApp(this);
    this.uiConfigInit();
    this.primengConfig.ripple = true;
    this.menuItems = [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['dashboard'] },
      { label: 'Tenants', icon: 'pi pi-fw pi-lock-open', routerLink: ['tenant-list'] },
      { label: 'Users', icon: 'pi pi-users', routerLink: ['user-list'] },
    ];
    // if (!Enviro`nment.production) this.menuItems.push({ label: 'Development', icon: 'bi bi-cpu', routerLink: ['development'] });
  }

  uiConfigInit(): void {
    const config = this.localStorageService.getFromState(StateKey.session, CLIENT_DATA_KEY)[UI_CONFIG_KEY];
    this.uiConfig = config ? new UiConfig(config) : new UiConfig();
    this.configService.initStyleSheets(this.uiConfig);
  }

  onLayoutClick(): void {
    !this.searchClick && (this.search = false);
    !this.userMenuClick && (this.topBarUserMenuActive = false);
    !this.notificationMenuClick && (this.topBarNotificationMenuActive = false);
    !this.rightMenuClick && (this.rightMenuActive = false);

    if (!this.menuClick) {
      this.isSlim() && this.sidebarMenuService.reset();
      (this.overlayMenuActive || this.staticMenuMobileActive) && this.hideOverlayMenu();
      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.configActive && !this.configClick && (this.configActive = false);

    this.searchClick = false;
    this.configClick = false;
    this.userMenuClick = false;
    this.rightMenuClick = false;
    this.notificationMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: MouseEvent): void {
    this.menuClick = true;
    this.topBarUserMenuActive = false;
    this.topBarNotificationMenuActive = false;
    this.rightMenuActive = false;
    this.isOverlay() && (this.overlayMenuActive = !this.overlayMenuActive);

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      this.staticMenuMobileActive ? this.blockBodyScroll() : this.unblockBodyScroll();
    }

    event.preventDefault();
  }

  // onSearchClick(event): void {
  //   this.search = !this.search;
  //   this.searchClick = !this.searchClick;
  // }

  onMenuClick(): void {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopBarUserMenuButtonClick(event: MouseEvent): void {
    this.userMenuClick = true;
    this.topBarUserMenuActive = !this.topBarUserMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopBarNotificationMenuButtonClick(event: MouseEvent): void {
    this.notificationMenuClick = true;
    this.topBarNotificationMenuActive = !this.topBarNotificationMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onRightMenuClick(event: MouseEvent): void {
    this.rightMenuClick = true;
    this.rightMenuActive = !this.rightMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onRippleChange(event: any): void {
    this.uiConfig.ripple = event.checked;
  }

  onConfigClick(): void {
    this.configClick = true;
  }

  isSlim(): boolean {
    return this.uiConfig.menuMode === 'slim';
  }

  isOverlay(): boolean {
    return this.uiConfig.menuMode === 'overlay';
  }

  isDesktop(): boolean {
    return window.innerWidth > 991;
  }

  isMobile(): boolean {
    return window.innerWidth <= 991;
  }

  hideOverlayMenu(): void {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  blockBodyScroll(): void {
    document.body.classList ? document.body.classList.add('blocked-scroll') : (document.body.className += ' blocked-scroll');
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
}
