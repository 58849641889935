import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LimitedMainService } from '@limited/pages/limited-main/services/limited-main.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@private/pages/profile/components/config/services/config.service';
import { UiConfig } from '@private/pages/profile/types/user.types';
import { CLIENT_DATA_KEY, UI_CONFIG_KEY } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { HasUiConfig } from '@shared/types/has-ui-config.types';
import { StateKey } from '@shared/types/local-storage.types';
import { LimitedMainModel } from './types/limited-main.types';

@Component({
  selector: 'app-limited-main',
  templateUrl: './limited-main.component.html',
  styleUrls: ['./limited-main.component.scss'],
  providers: [LimitedMainService],
})
export class LimitedMainComponent extends CoreComponent<LimitedMainService, LimitedMainModel> implements HasUiConfig {
  uiConfig: UiConfig;
  userMenuClick: boolean;
  topBarUserMenuActive: boolean;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: LimitedMainService,
    announcement: AnnouncementService,
    public readonly authorizationService: AuthorizationService,
    public readonly localStorageService: LocalStorageService,
    private readonly configService: ConfigService<LimitedMainComponent>,
  ) {
    super(route, router, translate, new LimitedMainModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();

    this.configService.setApp(this);
    this.uiConfigInit();
  }

  uiConfigInit(): void {
    const config = this.localStorageService.getFromState(StateKey.session, CLIENT_DATA_KEY)[UI_CONFIG_KEY];
    this.uiConfig = config ? new UiConfig(config) : new UiConfig();
    this.configService.initStyleSheets(this.uiConfig);
  }

  onLayoutClick(): void {
    !this.userMenuClick && (this.topBarUserMenuActive = false);
    this.userMenuClick = false;
  }

  onTopBarUserMenuButtonClick(event: Event): void {
    this.userMenuClick = true;
    this.topBarUserMenuActive = !this.topBarUserMenuActive;
    event.preventDefault();
  }

  onDestroy(): void {
    super.onDestroy();
  }
}
