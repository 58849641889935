import { DataTypeValueResponseDto, WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AttributeActionHandlerService, PageAttributeChangeEvent, SetAttributeValueEvent } from '@workflows/shared';
import { WorkflowTriggerEvent } from '@workflows/types';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType, WorkflowAttributeActionEventType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface WorkflowActionCustomAttributeValuesDto extends WorkflowActionDto {
  dataTypeId: string;
  values: DataTypeValueResponseDto[];
}

export class WorkflowActionUseCustomAttributeValues
  extends AbstractWorkflowAttributeBasedAction<CommonFeAction>
  implements WorkflowActionCustomAttributeValuesDto
{
  dataTypeId: string;
  values: DataTypeValueResponseDto[];

  constructor(dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super(CommonFeAction, WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES, dto, actionAttributeHandler);
    this.dataTypeId = (dto as WorkflowActionCustomAttributeValuesDto)?.dataTypeId;
    this.values = (dto as WorkflowActionCustomAttributeValuesDto)?.values;
    this.supportedAttributeDataKinds = [DataTypeKind.enumerated];
    this.supportedAttributeDataTypes = [BaseDataType.integer, BaseDataType.text];
  }

  canBeExecuted(): boolean {
    return this.isValid();
  }

  execute(ruleTriggerEvent?: WorkflowTriggerEvent): void {
    const event: SetAttributeValueEvent = { artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, value: this.values };
    const pageAttributeChangeEvent: PageAttributeChangeEvent = {
      pageId: ruleTriggerEvent?.pageId as string,
      eventType: WorkflowAttributeActionEventType.ATTRIBUTE_OPTION_VALUES,
      event,
      widgets: ruleTriggerEvent?.widgetId ? [ruleTriggerEvent?.widgetId] : undefined,
    };
    this.actionAttributeHandler.notifyPageAttributeEvent(pageAttributeChangeEvent);
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId && this.dataTypeId && this.values?.length);
  }

  toServer(): WorkflowActionCustomAttributeValuesDto {
    return {
      ...super.toServer(),
      dataTypeId: this.dataTypeId,
      values: this.values,
    };
  }

  onRuleDeactivation(pageId: string): void {
    const event = { artifactTypeId: this.artifactTypeId, attributeId: this.attributeId, value: this.values };
    const pageAttributeChangeEvent = { pageId, eventType: WorkflowAttributeActionEventType.ATTRIBUTE_OPTION_VALUES, event };
    this.actionAttributeHandler.clearPageAttributeEvent(pageAttributeChangeEvent);
  }
}
