import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ArtifactResponseDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { FileWithUrl } from '@widgets/card-widget/types/file-with.url';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SingleFileAttributeValuePresentationService {
  constructor(private readonly sanitizer: DomSanitizer, private readonly tenantArtifactService: TenantArtifactService) {}

  getFileArtifactByDto(dto: ArtifactResponseDto): Observable<FileWithUrl> {
    if (!dto.id || dto.format !== ArtifactTypeFormatEnum.file) return EMPTY;

    return this.tenantArtifactService.artifactControllerDownload({ id: dto.id }).pipe(
      map((blob: Blob) => {
        const url = this.blobToSanitizedUrl(blob);

        return new FileWithUrl({ ...(dto as Exclude<FileWithUrl, 'url'>), url });
      }),
      catchError((error: any) => {
        console.log('Something went wrong during loading file', error);
        return EMPTY;
      }),
    );
  }

  private blobToSanitizedUrl(blob: Blob): SafeUrl {
    const url = URL.createObjectURL(blob);

    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
