import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FlexContainer } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { SelectOption } from '@shared/types/shared.types';
import { AlignItems, FlexDirection, JustifyContent } from '@widgets/shared/types/style.types';

interface AlignmentOptions {
  justifyContent: SelectOption<typeof JustifyContent, typeof JustifyContent>[];
  alignItems: SelectOption<typeof AlignItems, typeof AlignItems>[];
}

@Component({
  selector: 'app-flex-content-alignment-form',
  templateUrl: './flex-content-alignment-form.component.html',
  styleUrls: ['./flex-content-alignment-form.component.scss'],
})
export class FlexContentAlignmentFormComponent {
  @Input() styles: FlexContainer;
  @Input() showVertical = true;
  @Input() showHorizontal = true;
  @Output() onChange: EventEmitter<FlexContainer> = new EventEmitter();

  onValueChange() {
    this.onChange.emit(this.styles);
  }

  readonly options: AlignmentOptions = {
    justifyContent: GetSelectOptionsFromEnum(JustifyContent),
    alignItems: GetSelectOptionsFromEnum(AlignItems),
  };

  get justifyContentName(): string {
    const { flexDirection } = this;

    return flexDirection === FlexDirection.row || flexDirection === FlexDirection.rowReverse ? 'Horizontal' : 'Vertical';
  }

  get alignItemsName(): string {
    const { flexDirection } = this;

    return flexDirection === FlexDirection.row || flexDirection === FlexDirection.rowReverse ? 'Vertical' : 'Horizontal';
  }

  private get flexDirection(): FlexDirection {
    return this.styles.flexDirection;
  }
}
