import { animate, style, transition, trigger } from '@angular/animations';

export const FadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.2s ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('.1s ease-in', style({ opacity: 0 }))]),
]);

export const SlideInOutAnimation = trigger('slideInOut', [
  transition(':enter', [style({ transform: 'translateY(-20%)' }), animate('200ms ease-in', style({ transform: 'translateY(0%)' }))]),
  transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
]);
