import { Injectable } from '@angular/core';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { SubscribeRequestDto } from '@api/models/subscribe-request-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantPushNotificationService } from '@api/services/tenant-push-notification.service';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NotificationEvent } from '@shared/types/push-notification.types';
import { lastValueFrom, Subscription } from 'rxjs';
import { UserProfilePictureService } from './user-profile/user-profile-picture.service';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
  private static readonly UPDATE_VERSION_KEY = 'app-version-update';
  private serverPublicKey = 'BG-56kOUBhQdVt-AxW9F2RCWlqJPu8ZdJvfeFhl55-ljqMzEpYEuAKKCZXwtLOCZ7Ndn1YfsLkzUKZR8GN9fteM';
  private initialized = false;
  private pushNotificationsSubscription: Subscription;
  private notificationClicksSubscription: Subscription;
  private newVersionEventSubscription: Subscription;

  constructor(
    private readonly swPush: SwPush,
    private readonly tenantPushNotificationService: TenantPushNotificationService,
    private readonly userProfilePictureService: UserProfilePictureService,
    private readonly swUpdate: SwUpdate,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly cache: NewCacheService,
  ) {}

  async init(): Promise<void> {
    if (!this.initialized) {
      await this.subscribeToNotificationsOnBackend();
      this.subscribeToPushNotifications();
      // Disabled, because every tab subscribes this event handler, and on click of notification every tab opens URL from notification
      // => used default Angular handler instead https://angular.io/guide/service-worker-notifications#notification-click-handling
      // this.subscribeToNotificationClick();
      this.subscribeToNewVersionEvent();

      this.initialized = true;
    }
  }

  destroy(): void {
    this.pushNotificationsSubscription && this.pushNotificationsSubscription.unsubscribe();
    this.notificationClicksSubscription && this.notificationClicksSubscription.unsubscribe();
    this.newVersionEventSubscription && this.newVersionEventSubscription.unsubscribe();
  }

  private async subscribeToNotificationsOnBackend(): Promise<void> {
    this.swPush
      .requestSubscription({ serverPublicKey: this.serverPublicKey })
      .then((sub: PushSubscription) => {
        this.tenantPushNotificationService.pushNotificationControllerSubscribe({ body: sub.toJSON() as any as SubscribeRequestDto }).subscribe();
      })
      .catch(err => Environment.enablePwa && console.error('Could not subscribe to notifications', err));
  }

  private subscribeToPushNotifications(): void {
    this.pushNotificationsSubscription = this.swPush.messages.subscribe(async (e: NotificationEvent & any) => {
      const { title, data, body, id }: NotificationEvent = e;
      const userProfilePictureUrl = await this.userProfilePictureService.getUserImageUrl(data.userProfileId || undefined);
      const options: NotificationOptions = { body, data, vibrate: [100, 50, 100], icon: userProfilePictureUrl || 'assets/elvis-photo1.jpeg', tag: id };
      const nameAttributeId = (this.cache.user.value as SelfUserResponseDto).tenant!.systemAttributes?.nameAttributeId;
      const artifact: ArtifactResponseDto | null =
        data.userProfileId && nameAttributeId ? await lastValueFrom(this.tenantArtifactService.artifactControllerGet({ id: data.userProfileId })) : null;

      await Notification.requestPermission(result => {
        if (result === 'granted') {
          navigator.serviceWorker.ready.then(registration => {
            registration.getNotifications({ tag: id }).then(notifications => {
              if (notifications.length === 0) {
                registration.showNotification((artifact?.attributes[nameAttributeId || '']?.value as string) || title, options);
                new Audio('/assets/notification.mp3').play();
              }
            });
          });
        }
      });
    });
  }

  // private subscribeToNotificationClick(): void {
  //   this.notificationClicksSubscription = this.swPush.notificationClicks.subscribe(e => window.open(e.notification.data?.url, '_blank'));
  // }

  private subscribeToNewVersionEvent(): void {
    if (this.swUpdate.isEnabled) {
      this.newVersionEventSubscription = this.swUpdate.versionUpdates.subscribe((e: VersionEvent) => {
        // todo: old logic, will be deleted after new one is tested
        // if (e.type === 'VERSION_READY' && confirm('New version of application is available. Load New Version?')) window.location.reload();

        if (e.type === 'VERSION_READY') {
          this.notifyUpdateAndRemoveUpdateKey();
          this.clearCaches().then(() => {
            alert('New version available. The page will now reload.');
            window.location.reload();
          });
        }
      });

      window.addEventListener('storage', (event: StorageEvent) => {
        if (event.key === PushNotificationService.UPDATE_VERSION_KEY && event.newValue === 'true') window.location.reload();
      });
    }
  }

  private async clearCaches(): Promise<void> {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
  }

  private notifyUpdateAndRemoveUpdateKey() {
    localStorage.setItem(PushNotificationService.UPDATE_VERSION_KEY, 'true');

    setTimeout(() => localStorage.removeItem(PushNotificationService.UPDATE_VERSION_KEY), 3000);
  }
}
