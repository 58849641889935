import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Environment } from '@environments/environment';
import { JobsStatus, JobsType } from '@private/pages/jobs-management/jobs/types/jobs-page.model';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-jobs-table',
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.scss'],
})
export class JobsTableComponent extends CoreListComponent<any> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<any>>;
  @Input() columns: TableColumn[];
  @Input() actionButtonsTemplate: TemplateRef<any>;

  @ViewChild('table') table: Table;

  rowsPerPage: number = Environment.tableConfig.rowsPerPage;
  rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions;

  typeOptions: SelectOption<string, JobsType>[];
  statusOptions: SelectOption<string, JobsStatus>[];

  constructor() {
    super();
  }

  protected onInit(): void {
    super.onInit();
    this.typeOptions = Object.values(JobsType).reduce<SelectOption<string, JobsType>[]>((output: SelectOption<string, JobsType>[], jobType: JobsType) => {
      output.push(new SelectOption(jobType));
      return output;
    }, []);

    this.statusOptions = Object.values(JobsStatus).reduce<SelectOption<string, JobsStatus>[]>(
      (output: SelectOption<string, JobsStatus>[], jobStatus: JobsStatus) => {
        output.push(new SelectOption(`${jobStatus}`, jobStatus));
        return output;
      },
      [],
    );
  }
}
