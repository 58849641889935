import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem, PaginationSettingEnum } from '../components/artifact-list-table/types/list-widget-grouping.types';

@Pipe({ name: 'getRowsPerPageHeader' })
export class GetRowsPerPageHeaderPipe implements PipeTransform {
  transform(pagination: PaginationSettingEnum, groupingAttributes: SelectOption<string, GroupAttributeItem>[]): string {
    return pagination === PaginationSettingEnum.perTable || !groupingAttributes.length ? 'Rows per page' : 'Rows per group';
  }
}

@NgModule({
  declarations: [GetRowsPerPageHeaderPipe],
  exports: [GetRowsPerPageHeaderPipe],
})
export class GetRowsPerPageHeaderPipeModule {}
