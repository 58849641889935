import { BLANK_FILTER_OPTION_LABEL } from '@shared/constants/constants';

export const ChartConstants = {
  blank: BLANK_FILTER_OPTION_LABEL,
  currentUser: '[current system-user]',
  colorList: ['#167ff0', '#1fe019', '#ffc409', '#eb445a', '#d556d7', '#ff0000', '#00ff00', '#0000ff'],
  defaultDataLabelsColor: '#ffffff',
  defaultLabelRotate: 90,
  defaultCutout: 50,
  pieCutout: 1,
};
