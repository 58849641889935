import { Directive, HostBinding, Inject, InjectionToken } from '@angular/core';
import { AvrWidgetTypeSettings } from '@api/models';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { Constructor } from '@shared/types/constructor.types';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';

export type SettingAvrTypes = keyof AvrWidgetTypeSettings;
export type AvrSettingsInjectorBody = {
  model: AvrWidgetModel;
  avrTypeServices: Partial<Record<AvrTypesType, AbstractAvrTypeService<SettingAvrTypes>>>;
};
export const AVR_SETTINGS_INJECTOR = new InjectionToken<AvrSettingsInjectorBody>('settingFields');

@Directive()
export abstract class AbstractAvrQuerySettingsComponent<K extends SettingAvrTypes, S extends AbstractAvrTypeService<K>> {
  @HostBinding('class') hostClass = 'full-width-container';

  public settingFields: AvrWidgetTypeSettings[K];
  public service: S;

  constructor(
    @Inject(AVR_SETTINGS_INJECTOR) protected readonly injector: AvrSettingsInjectorBody,
    private _avrType: K,
    protected avrSettingDto: Constructor<AvrWidgetTypeSettings[K]>,
  ) {
    this.clearSharedSettingFields(injector.model.settings.response.avrTypeSettings);
    this.settingFields = injector.model.settings.response.avrTypeSettings[this._avrType] || new avrSettingDto();
    injector.model.settings.response.avrTypeSettings[this._avrType] = this.settingFields;
    // every AVR type with additional "SETTING" fields in AVR widget OR additional "QUERY" fields in GET artifact's AVR request, NEEDS to have its service
    this.service = injector.avrTypeServices[this._avrType]! as S;
  }

  get avrType(): SettingAvrTypes {
    return this._avrType;
  }

  private clearSharedSettingFields(sharedSettingFields: AvrWidgetTypeSettings): void {
    (Object.keys(sharedSettingFields) as [SettingAvrTypes]).forEach(
      settingAvrType => settingAvrType !== this._avrType && delete sharedSettingFields[settingAvrType],
    );
  }
}
