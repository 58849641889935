import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowConditionType } from '../index';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export class WorkflowContainsCondition extends AbstractWorkflowCondition {
  constructor() {
    super();
    this.type = WorkflowConditionType.CONTAINS;
    this.supportedTypes = [BaseDataType.text, BaseDataType.html];
    this.supportedKinds = [DataTypeKind.simple];
  }

  isMet(source: string, value: string): boolean {
    return source.includes(value);
  }
}
