/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { JobsResponseDto } from '../models/jobs-response-dto';
import { linkControllerCount } from '../fn/tenant-link/link-controller-count';
import { LinkControllerCount$Params } from '../fn/tenant-link/link-controller-count';
import { linkControllerCreate } from '../fn/tenant-link/link-controller-create';
import { LinkControllerCreate$Params } from '../fn/tenant-link/link-controller-create';
import { linkControllerDelete } from '../fn/tenant-link/link-controller-delete';
import { LinkControllerDelete$Params } from '../fn/tenant-link/link-controller-delete';
import { linkControllerGet } from '../fn/tenant-link/link-controller-get';
import { LinkControllerGet$Params } from '../fn/tenant-link/link-controller-get';
import { linkControllerImport } from '../fn/tenant-link/link-controller-import';
import { LinkControllerImport$Params } from '../fn/tenant-link/link-controller-import';
import { linkControllerList } from '../fn/tenant-link/link-controller-list';
import { LinkControllerList$Params } from '../fn/tenant-link/link-controller-list';
import { LinkListResponseDto } from '../models/link-list-response-dto';
import { LinkResponseDto } from '../models/link-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantLinkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `linkControllerCount()` */
  static readonly LinkControllerCountPath = '/api/tenant/link/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerCount$Response(params?: LinkControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return linkControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerCount(params?: LinkControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.linkControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `linkControllerGet()` */
  static readonly LinkControllerGetPath = '/api/tenant/link/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerGet$Response(params: LinkControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkResponseDto>> {
    return linkControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerGet(params: LinkControllerGet$Params, context?: HttpContext): Observable<LinkResponseDto> {
    return this.linkControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkResponseDto>): LinkResponseDto => r.body)
    );
  }

  /** Path part for operation `linkControllerDelete()` */
  static readonly LinkControllerDeletePath = '/api/tenant/link/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerDelete$Response(params: LinkControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkResponseDto>> {
    return linkControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkControllerDelete(params: LinkControllerDelete$Params, context?: HttpContext): Observable<LinkResponseDto> {
    return this.linkControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkResponseDto>): LinkResponseDto => r.body)
    );
  }

  /** Path part for operation `linkControllerList()` */
  static readonly LinkControllerListPath = '/api/tenant/link/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkControllerList$Response(params: LinkControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkListResponseDto>> {
    return linkControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkControllerList(params: LinkControllerList$Params, context?: HttpContext): Observable<LinkListResponseDto> {
    return this.linkControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkListResponseDto>): LinkListResponseDto => r.body)
    );
  }

  /** Path part for operation `linkControllerImport()` */
  static readonly LinkControllerImportPath = '/api/tenant/link/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkControllerImport$Response(params: LinkControllerImport$Params, context?: HttpContext): Observable<StrictHttpResponse<JobsResponseDto>> {
    return linkControllerImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkControllerImport(params: LinkControllerImport$Params, context?: HttpContext): Observable<JobsResponseDto> {
    return this.linkControllerImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<JobsResponseDto>): JobsResponseDto => r.body)
    );
  }

  /** Path part for operation `linkControllerCreate()` */
  static readonly LinkControllerCreatePath = '/api/tenant/link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkControllerCreate$Response(params: LinkControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<LinkListResponseDto>> {
    return linkControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkControllerCreate(params: LinkControllerCreate$Params, context?: HttpContext): Observable<LinkListResponseDto> {
    return this.linkControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LinkListResponseDto>): LinkListResponseDto => r.body)
    );
  }

}
