import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';

@Component({
  selector: 'app-workflow-attribute-selector',
  templateUrl: 'workflow-attribute-selector.component.html',
  styleUrls: ['./workflow-attribute-selector.component.scss', '../../workflow-common.scss'],
})
export class WorkflowAttributeSelectorComponent {
  @Input() attributes: NewAttribute[];
  @Input() selectedAttributeId: string;
  @Input() editable: boolean;

  @Output() attributeChange: EventEmitter<string> = new EventEmitter();

  onAttributeChange(attributeId: string) {
    this.attributeChange.emit(attributeId);
  }
}
