import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AvrDocumentGenerationTypesSettingsComponent } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/document-generation/document-generation.avr-types-settings.component';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';

import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [AvrDocumentGenerationTypesSettingsComponent],
  providers: [
    {
      provide: 'AvrSettingsFields',
      useFactory: () => {
        return { [AvrTypes.documentGeneration]: AvrDocumentGenerationTypesSettingsComponent };
      },
    },
  ],
  imports: [CommonModule, FormsModule, TranslateModule, DropdownModule, InputTextModule],
})
export class AvrSettingFieldsModule {}
