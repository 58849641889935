import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartViewTooltipService } from '@widgets/chart-widget/components/chart-view/services/chart-view-tooltip.service';
import { ChartTotalPosition } from '@widgets/chart-widget/types/chart-widget-options.types';
import { ChartWidgetSelected } from '@widgets/chart-widget/types/chart-widget-selected.types';

import { ChartOptionsSettings } from '@widgets/chart-widget/types/chart-widget-settings.types';
import { ChartData, ChartWidgetTypeChart } from '@widgets/chart-widget/types/chart-widget-view.types';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chart-view',
  templateUrl: './chart-view.component.html',
  styleUrls: ['./chart-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartViewComponent implements OnInit {
  @Input() options: ChartOptionsSettings;
  @Input() selected: ChartWidgetSelected;
  @Input() isShowLegend: boolean;

  chartType: ChartWidgetTypeChart;
  chartData: ChartData;
  chart: any;
  id: string;
  legendId: string;
  counterId: string;

  private resizeObserver: any;

  constructor(private readonly tooltipService: ChartViewTooltipService) {}

  get total(): number {
    const { total, totalSum } = this.chartData.counter;
    !this.resizeObserver &&
      setTimeout(() => {
        this.initResizeObserver();
      });
    return totalSum || totalSum === 0 ? totalSum : total;
  }

  @Input() set type(t: ChartWidgetTypeChart) {
    this.chartType = t;
    setTimeout(() => {
      this.init();
    });
  }

  @Input() set data(d: ChartData) {
    this.chartData = d;
    if (!d) {
      this.chart = null;
      return;
    }
    this.chart ? this.update() : this.init();
  }

  @Input() set updateTotalPosition(d: number) {
    setTimeout(() => {
      this.isTotalPositionIsInside() && this.fixTotalPosition();
    });
  }

  ngOnInit(): void {
    this.id = Math.floor(Math.random() * 16777215).toString(16);
    this.legendId = `${this.id}-legend`;
    this.counterId = `${this.id}-counter`;

    this.initResizeObserver();
  }

  initResizeObserver(): void {
    const el = document.getElementsByClassName('counter')[0];
    if (!el) return;
    this.resizeObserver = new (window as any).ResizeObserver(() => {
      this.chart ? this.update() : this.init();
    });
    this.resizeObserver.observe(el);
  }

  async init(): Promise<any> {
    if (!this.chartData || !this.chartType) return;

    this.options?.legend && (this.options.legend.display = false);
    this.options.cutout = this.options.cutoutPercentage;

    this.options.tooltips = {
      // use this if need full custom tooltip
      // enabled: false,
      // custom: (model) => { this.tooltipService.render(model, this.id); },
      /* use this for custom footer in tooltip
      callbacks: {
        footer: this.tooltipService.footer,
      },*/
    };

    this.options.plugins = {
      legend: { display: false },
    }

    // @ts-ignore
    const config: Chart.ChartConfiguration = {
      type: this.chartType,
      data: this.chartData,
      options: this.options,
    };

    this.chart && (await this.chart.destroy());

    setTimeout(() => {
      const chartContainer = document.getElementById(this.id) as HTMLCanvasElement;
      if (!chartContainer) return;
      this.chart = new Chart(chartContainer, config);

      this.updateLegend();
      this.isTotalPositionIsInside() && this.fixTotalPosition();
    }, 10);
  }

  update(): void {
    this.chart.data = this.chartData;
    this.options && (this.chart.options = this.options);

    this.chart.update();
    this.updateLegend();
  }

  isTotalPositionIsBottom() {
    return this.selected.chartTotalPosition === ChartTotalPosition.bottom;
  }

  isTotalPositionIsInside() {
    return this.options.isShowTotal && this.selected.chartTotalPosition === ChartTotalPosition.center;
  }

  isRight(): boolean {
    return !this.options.legend?.position || this.options.legend?.position === 'right';
  }

  isLeft(): boolean {
    return this.options.legend?.position === 'left';
  }

  isTop(): boolean {
    return this.options.legend?.position === 'top';
  }

  isBottom(): boolean {
    return this.options.legend?.position === 'bottom';
  }

  isWideChart(): boolean {
    return this.options.legend?.position === 'bottom' || this.options.legend?.position === 'top';
  }

  private fixTotalPosition(): void {
    const total = document.getElementById(this.counterId) as HTMLElement;
    const container = total?.closest('.chart-cont') as HTMLElement;
    if (!total || !container) return;
    const { clientHeight, clientWidth } = container;
    const { clientHeight: height, clientWidth: width } = total;
    const top = clientHeight / 2 - height / 2;
    const left = clientWidth / 2 - width / 2;

    (total.style as any).top = top + 'px';
    (total.style as any).left = left + 'px';
  }

  private updateLegend(): void {
    const legend = document.getElementById(this.legendId) as HTMLElement;
    if (!legend) return;

    legend.style.height = this.isWideChart() ? 'auto' : `${this.chart.height}px`;
    this.isWideChart() && (legend.style.maxHeight = `${this.chart.height}px`);
  }
}
