<form class="h-full overflow-hidden" (ngSubmit)="$event.preventDefault()">
  <div class="fields-container overflow-auto p-3 mb-5">
    <ng-container *ngIf="attributes.length; else emptyAttributes">
      <app-attribute-field
        *ngFor="let attribute of attributes; let i = index; let isLast = last;" [class.mb-3]="!isLast"
        [attribute]="attribute"
        [fieldIndex]="i"
        [formatSettings]="formatSettings"
        [labelBehavior]="labelBehavior">
      </app-attribute-field>
    </ng-container>

    <ng-template #emptyAttributes>
      <p class="font-italic">{{ 'Please select at least one attribute' | translate }}</p>
    </ng-template>
  </div>

  <app-attributes-form-buttons
    [saveDisabled]="!attributes.length"
    (cancel)="cancel.emit()"
    (save)="onSave($event)">
  </app-attributes-form-buttons>
</form>
