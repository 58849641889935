import { Injectable } from '@angular/core';
import { PageBlockPartWidgetRequestDto } from '@api/models';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { ArtifactWidgetTypeResponseDto } from '@api/models/artifact-widget-type-response-dto';
import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { PageCreateRequestDto } from '@api/models/page-create-request-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { TemplateCreateRequestDto } from '@api/models/template-create-request-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TenantArtifactTypeService } from '@api/services/tenant-artifact-type.service';
import { TenantPageService } from '@api/services/tenant-page.service';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { WidgetGeneratorHelper } from '@private/helpers/widget-generator-helper.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { DefaultPageParams } from '@private/types/page-builder-helper.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { Constants } from '@shared/constants/constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewApplication } from '@shared/types/application.types';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { StateKey } from '@shared/types/local-storage.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageBuilderHelper {
  constructor(
    private readonly tenantPageService: TenantPageService,
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly widgetGeneratorHelper: WidgetGeneratorHelper,
    private readonly localStorageService: LocalStorageService,
    private readonly cache: NewCacheService,
  ) {}

  async createAndAssignDefaultsToArtifactType(defaultPageParams: DefaultPageParams): Promise<void> {
    const artifactType = new NewArtifactType(defaultPageParams.artifactTypeDto);
    const defaultArtifactWidget = await this.widgetGeneratorHelper.createDefaultArtifactWidget(artifactType, defaultPageParams);
    const defaultCardWidget = await this.widgetGeneratorHelper.createDefaultCardWidget(artifactType, defaultPageParams);
    const sections = this.createDefaultSections(defaultArtifactWidget);

    this.cache.data.widgets.setItem(defaultArtifactWidget);
    this.cache.data.widgets.setItem(defaultCardWidget);

    if (artifactType.format === ArtifactTypeFormatEnum.module) {
      const defaultListWidget = await this.widgetGeneratorHelper.createDefaultListWidget();
      this.addModuleListWidgetToSections(sections, defaultListWidget);
      this.cache.data.widgets.setItem(defaultListWidget);
    }

    const defaultPage = await this.createDefaultPage(artifactType, sections);
    const artifactWidgetTemplate = await this.createTemplateForWidget(defaultArtifactWidget.id, WidgetType.artifact, artifactType);
    const cardWidgetTemplate = await this.createTemplateForWidget(defaultCardWidget.id, WidgetType.card, artifactType);
    const AWTemplateId = artifactWidgetTemplate?.id || null;
    const CWTemplateId = cardWidgetTemplate?.id || null;

    artifactWidgetTemplate && this.cache.data.templates.setItem(artifactWidgetTemplate);
    cardWidgetTemplate && this.cache.data.templates.setItem(cardWidgetTemplate);
    this.cache.data.pages.setItem(defaultPage);

    await this.assignDefaults(artifactType, defaultPage, AWTemplateId, CWTemplateId);
  }

  async createAndAssignDefaultsToAllArtifactTypes(
    attributes: ListContainer<NewAttribute>,
    dataTypes: ListContainer<NewDataType>,
    artifactTypes: ListContainer<NewArtifactType>,
    linkTypes: ListContainer<LinkType>,
  ): Promise<void> {
    for await (const artifactType of artifactTypes.list) {
      await this.createAndAssignDefaultsToArtifactType(new DefaultPageParams(artifactType, attributes, dataTypes, artifactTypes, linkTypes));
    }
  }

  async createHomePage(application: NewApplication): Promise<PageResponseDto> {
    const body: PageCreateRequestDto = {
      name: `${application.name} Home`,
      applicationId: application.id,
      sections: [],
    };
    return await lastValueFrom(this.tenantPageService.pageControllerCreate({ body }));
  }

  generateAliasFromPageName(name: string): string {
    return name
      .replace(/[&\/\\#,+()$~%.'":*?<>{}^!@=`;]/g, '')
      .replace(/(\s|_)+/g, '-')
      .toLowerCase();
  }

  private createDefaultSections(artifactWidget: ArtifactWidgetTypeResponseDto): PageSection[] {
    return [new PageSection([new PageRow([new PageBlock([new PageBlockPart(new BlockPartWidget(artifactWidget))])])])];
  }

  private addModuleListWidgetToSections(sections: PageSection[], listWidget: ListWidgetTypeResponseDto): void {
    sections[0].rows[0].blocks[0].parts.push(new PageBlockPart(new BlockPartWidget(listWidget)));
  }

  private async createDefaultPage(artifactType: NewArtifactType, sections: PageSection[]): Promise<PageResponseDto> {
    const pageName = `${artifactType.name} page`;
    const alias = this.generateAliasFromPageName(pageName);

    const { data, meta } = await lastValueFrom(
      this.tenantPageService.pageControllerList({
        filter: JSON.stringify({ $and: [{ alias: { $regex: `(^${alias}-[0-9]+$)|(^${alias}$)`, $options: 'i' } }] }),
        sort: JSON.stringify({ alias: -1 }),
        limit: 1,
      }),
    );
    const getAliasDynamicPart = (): string => {
      if (!data.length) return '';

      const foundAlias = data[0]?.alias as string;
      const parts = foundAlias.split('-');
      const maxNumber = Number(parts[parts.length - 1]);

      return '-' + (Math.max(isNaN(maxNumber) ? 0 : maxNumber, meta.totalCount) + 1);
    };

    const correctPageAlias = this.generateAliasFromPageName(pageName) + getAliasDynamicPart();

    const body: PageCreateRequestDto = {
      name: pageName,
      applicationId: artifactType.applicationId,
      sections: sections.map(section => section.requestDto),
      alias: correctPageAlias,
    };

    return await lastValueFrom(this.tenantPageService.pageControllerCreate({ body }));
  }

  private async createTemplateForWidget(
    widgetId: string | null,
    widgetType: WidgetType,
    artifactType: ArtifactTypeResponseDto,
  ): Promise<TemplateResponseDto | null> {
    if (!widgetId) return null;

    const applicationId = this.localStorageService.getFromState(StateKey.session, Constants.selectedApplication);
    const body: TemplateCreateRequestDto = {
      name: `${artifactType.name} ${widgetType}`,
      description: `Default ${widgetType}-widget for "${artifactType.name}".`,
      template: { widgetId: widgetId, templateId: null } as PageBlockPartWidgetRequestDto,
      type: TemplateType.widget,
      categories: [],
      icon: null,
      thumbnailFileArtifactId: null,
      applicationId,
    };

    return await lastValueFrom(this.tenantTemplateService.templateControllerCreate({ body }));
  }

  private async assignDefaults(
    artifactType: NewArtifactType,
    defaultPage: PageResponseDto,
    AWTemplateID: string | null,
    CWTemplateId: string | null,
  ): Promise<any> {
    await lastValueFrom(
      this.tenantArtifactTypeService.artifactTypeControllerUpdate({
        body: {
          id: artifactType.id,
          defaultPageId: defaultPage.id,
          defaultWidgets: {
            artifactWidgetTemplateId: AWTemplateID,
            cardWidgetTemplateId: CWTemplateId,
          },
          delimiter: artifactType.delimiter,
          isLoggingDisabled: artifactType.isLoggingDisabled,
        },
      }),
    ).then(res => this.cache.data.artifactTypes.setItem(res));
  }
}
