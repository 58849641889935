import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppArtifactAttributeValueEditFieldModule } from '@private/components/artifact-attribute-value-edit-field/artifact-attribute-value-edit-field.component';
import { FileAttributeValueEditFieldModule } from '@private/components/file-attribute-value-edit-field/file-attribute-value-edit-field.component';
import { ArtifactAttributeFormFieldComponent } from '@shared/components/artifact-attribute-form-field/artifact-attribute-form-field.component';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import {
  DisplayAtBooleanModule,
  DisplayAtEnumModule,
  DisplayAtLinkModule,
  DisplayAtOverlayModule,
  DisplayAtSystemDateModule,
  DisplayAtUserModule,
  DisplayHyperlinkIconModule,
} from '@shared/components/common-display-at';
import { DisplayAtDatetimeModule } from '@shared/components/common-display-at/components/display-at-datetime/display-at-datetime.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { LinkPipesModule } from '@shared/pipes/links/link-pipes.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ArtifactWidgetComponent } from '@widgets/artifact-widget/artifact-widget.component';
import { ArtifactWidgetFormItemSettingsComponent } from '@widgets/artifact-widget/components/artifact-widget-form-item-settings/artifact-widget-form-item-settings.component';
import { ArtifactWidgetFormComponent } from '@widgets/artifact-widget/components/artifact-widget-form/artifact-widget-form.component';
import { ArtifactWidgetCustomFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-custom-field/artifact-widget-custom-field.component';
import { ArtifactWidgetLinkFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-link-field/artifact-widget-link-field.component';
import { ArtifactWidgetLoginFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-login-field/artifact-widget-login-field.component';
import { ArtifactWidgetPasswordFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-password-field/artifact-widget-password-field.component';
import { ArtifactWidgetReadonlyFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-readonly-field/artifact-widget-readonly-field.component';
import { FormReadonlyFieldUserComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-readonly-field/components/form-readonly-field-user';
import { ArtifactWidgetItemPickerComponent } from '@widgets/artifact-widget/components/artifact-widget-item-picker/artifact-widget-item-picker.component';
import { ArtifactWidgetSettingsComponent } from '@widgets/artifact-widget/components/artifact-widget-settings/artifact-widget-settings.component';
import { ArtifactWidgetTypePickerComponent } from '@widgets/artifact-widget/components/artifact-widget-type-picker/artifact-widget-type-picker.component';
import { ActiveItemPipe } from '@widgets/artifact-widget/pipes/active-item.pipe';
import { CanAddLinkPipe } from '@widgets/artifact-widget/pipes/can-add-link.pipe';
import { GetAttributeAndLinkTypeOptionsPipe } from '@widgets/artifact-widget/pipes/get-attribute-and-link-type-options.pipe';
import { GetAttributeLabelPipe } from '@widgets/artifact-widget/pipes/get-attribute-label.pipe';
import { GetAttributeReadonlyValuePipe } from '@widgets/artifact-widget/pipes/get-attribute-readonly-value.pipe';
import { GetCustomAttributeValuePipe } from '@widgets/artifact-widget/pipes/get-custom-attribute-value.pipe';
import { GetCustomFieldInnerHtmlPipe } from '@widgets/artifact-widget/pipes/get-custom-field-inner-html.pipe';
import { GetItemPlaceholderPipe } from '@widgets/artifact-widget/pipes/get-item-placeholder.pipe';
import { GetLinkNamePipe } from '@widgets/artifact-widget/pipes/get-link-name.pipe';
import { GetLinkedArtifactTypePipe } from '@widgets/artifact-widget/pipes/get-linked-artifact-type.pipe';
import { IsArtifactWidgetTypePipe } from '@widgets/artifact-widget/pipes/is-artifact-widget-type.pipe';
import { IsCustomRedirectionPagePipe } from '@widgets/artifact-widget/pipes/is-custom-redirection-page.pipe';
import { IsDefaultApplicationRedirectionPagePipe } from '@widgets/artifact-widget/pipes/is-default-application-redirection-page.pipe';
import { IsLinkedArtifactWidgetTypePipe } from '@widgets/artifact-widget/pipes/is-linked-artifact-widget-type.pipe';
import { IsLoginWidgetTypePipe } from '@widgets/artifact-widget/pipes/is-login-widget-type.pipe';
import { IsRecoverPasswordWidgetTypePipe } from '@widgets/artifact-widget/pipes/is-recover-password-widget-type.pipe';
import { IsRegistrationWidgetTypePipe } from '@widgets/artifact-widget/pipes/is-registration-widget-type.pipe';
import { ShouldShowArtifactTabAttributePipe } from '@widgets/artifact-widget/pipes/should-show-artifact-tab-attribute.pipe';
import { ShouldShowArtifactWidgetSettingsPipe } from '@widgets/artifact-widget/pipes/should-show-artifact-widget-settings.pipe';
import { ArtifactWidgetStyleCopyPastingService } from '@widgets/artifact-widget/services/artifact-widget-style-copy-pasting.service';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { NewLinkPopupModule } from '@widgets/link-popup/artifact-link-dialog.module';
import { AclModule } from '@widgets/shared/components/acl/acl.module';
import { ArtifactLinkDialogModule } from '@widgets/shared/components/artifact-link-dialog/artifact-link-dialog.module';
import { NewArtifactLinkDialogModule } from '@widgets/shared/components/new-artifact-link-dialog/artifact-link-dialog.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { ReplaceRuntimeVariablesModule, ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { WorkflowsModule } from '@workflows/workflows.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@NgModule({
  declarations: [
    ArtifactWidgetComponent,
    ArtifactWidgetFormComponent,
    ArtifactWidgetSettingsComponent,
    ArtifactWidgetItemPickerComponent,
    ActiveItemPipe,
    GetAttributeAndLinkTypeOptionsPipe,
    GetLinkNamePipe,
    ArtifactWidgetReadonlyFieldComponent,
    FormReadonlyFieldUserComponent,
    ArtifactWidgetCustomFieldComponent,
    GetAttributeReadonlyValuePipe,
    ArtifactWidgetLinkFieldComponent,
    GetItemPlaceholderPipe,
    GetCustomFieldInnerHtmlPipe,
    ArtifactWidgetFormItemSettingsComponent,
    GetAttributeLabelPipe,
    CanAddLinkPipe,
    GetLinkedArtifactTypePipe,
    IsArtifactWidgetTypePipe,
    IsRegistrationWidgetTypePipe,
    IsLoginWidgetTypePipe,
    IsCustomRedirectionPagePipe,
    IsDefaultApplicationRedirectionPagePipe,
    IsRecoverPasswordWidgetTypePipe,
    IsLinkedArtifactWidgetTypePipe,
    ShouldShowArtifactWidgetSettingsPipe,
    ArtifactWidgetTypePickerComponent,
    GetCustomAttributeValuePipe,
    ShouldShowArtifactTabAttributePipe,
    ArtifactWidgetPasswordFieldComponent,
    ArtifactWidgetLoginFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    RippleModule,
    CheckboxModule,
    RadioButtonModule,
    FileUploadModule,
    ColorPickerModule,
    InputSwitchModule,
    ArtifactLinkDialogModule,
    AppArtifactAttributeValueEditFieldModule,
    FileAttributeValueEditFieldModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ChipsModule,
    EditorModule,
    InputTextareaModule,
    StyleFormModule,
    ElvisSharedModule,
    AccordionModule,
    OverlayPanelModule,
    ArtifactPipesModule,
    DataTypePipesModule,
    FolderWidgetModule,
    AclModule,
    NewArtifactLinkDialogModule,
    ReplaceRuntimeVariablesModule,
    WorkflowsModule,
    PasswordModule,
    DisplayAtUserModule,
    DisplayAtLinkModule,
    DisplayHyperlinkIconModule,
    LinkPipesModule,
    TriStateCheckboxModule,
    NewLinkPopupModule,
    ArtifactAttributeFormFieldComponent,
    ArtifactAttributeFormFieldLabelWithIconComponent,
    DisplayAtSystemDateModule,
    DisplayAtDatetimeModule,
    DisplayAtOverlayModule,
    RecaptchaModule,
    DisplayAtBooleanModule,
    DisplayAtEnumModule,
  ],
  providers: [ArtifactWidgetStyleCopyPastingService, ReplaceRuntimeVariablesPipe],
  exports: [ArtifactWidgetComponent],
})
export class ArtifactWidgetModule {}
