import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { CommonFeTrigger } from '@workflows/types/triggers/common-fe-trigger';
import { AbstractWorkflowTrigger } from './abstract/abstract-workflow-trigger';

export class WorkflowTriggerLinkAdded extends AbstractWorkflowTrigger<CommonFeTrigger> {
  artifactTypeId: string;

  constructor(artifactTypeId?: string) {
    super(CommonFeTrigger);
    this.artifactTypeId = artifactTypeId || this.artifactTypeId;
    this.type = RuleTriggerType.LINK_ADDED;
    this.artifactTypeRequired = true;
  }

  isMatchingWorkflowTrigger(workflowTrigger: WorkflowTrigger): boolean {
    if (this.type === workflowTrigger.type) {
      const trigger = workflowTrigger as WorkflowTriggerLinkAdded;
      return this.artifactTypeId === trigger.artifactTypeId;
    }
    return false;
  }

  isValid(): boolean {
    return !!this.artifactTypeId;
  }

  isExternalArtifactTypeRequired() {
    return true;
  }
}
