import { Component, Input } from '@angular/core';
import { DeleteLinksKeys, WorkflowActionDeleteLinks } from '@workflows/types/actions/action-delete-links';

@Component({
  selector: 'app-action-delete-links',
  templateUrl: './action-delete-links.component.html',
  styleUrls: ['./action-delete-links.component.scss'],
})
export class ActionDeleteLinksComponent {
  @Input() action: WorkflowActionDeleteLinks;

  protected readonly RuleKeys = DeleteLinksKeys;

  onIsDynamicIdChange(isDynamic: boolean): void {
    this.action.actionSettings.ids.value = isDynamic ? ('' as any) : [''];
  }

  onAddId(index: number): void {
    (this.action.actionSettings[DeleteLinksKeys.ids].value as string[]).splice(index + 1, 0, '');
  }

  onRemoveId(index: number): void {
    (this.action.actionSettings[DeleteLinksKeys.ids].value as string[]).splice(index, 1);
  }

  idsToTrackByFn(index: number): number {
    return index;
  }
}
