import { Environment } from '@environments/environment';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';

export class TeamListModel {
  teams = new ListContainer<any>();
  columns: TableColumn[] = [];
  rowsPerPage: number = Environment.tableConfig.rowsPerPage;
  rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions;

  constructor(model?: Partial<TeamListModel>) {
    model && Object.assign(this, model);
  }
}
