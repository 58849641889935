<ng-container
  *ngTemplateOutlet="
    attributeSelector;
    context: {
      attributeValue: action.sourceLinkTypeId,
      options: linkAttributes,
      placeholder: 'WORKFLOWS.PLACEHOLDER.SOURCE_LINK_TYPE',
      attributeChangeFn: onLinkAttributeChange.bind(this)
    }
  "
></ng-container>

<ng-container *ngIf="action.sourceLinkTypeId">
  {{ 'WORKFLOWS.COMMON.COPY_INTO' | translate }}
  <ng-container
    *ngTemplateOutlet="
      attributeSelector;
      context: {
        attributeValue: action.targetLinkTypeId,
        options: targetAttributes | getWorkflowActionAttributes : dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds(),
        placeholder: 'WORKFLOWS.PLACEHOLDER.TARGET_LINK_TYPE',
        attributeChangeFn: onTargetLinkTypeChange.bind(this)
      }
    "
  ></ng-container>
</ng-container>

<ng-container *ngIf="action.targetLinkTypeId">
  <p-dropdown
    class="workflow-element-dropdown"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    appendTo="body"
    placeholder="{{ 'WORKFLOWS.PLACEHOLDER.TARGET_ARTIFACT_TYPE' | translate }}"
    [filter]="true"
    filterBy="name"
    dataKey="id"
    optionValue="id"
    [options]="targetArtifactTypes"
    [ngModel]="action.targetLinkArtifactTypeId"
    [readonly]="!editable"
    (onChange)="onTargetArtifactTypeChange($event.value)"
  >
    <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
    <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
  </p-dropdown>
</ng-container>

<ng-template
  #attributeSelector
  let-attributeValue="attributeValue"
  let-options="options"
  let-attributeChangeFn="attributeChangeFn"
  let-placeholder="placeholder"
>
  <p-dropdown
    class="workflow-element-dropdown"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    placeholder="{{ placeholder | translate }}"
    appendTo="body"
    optionValue="id"
    [options]="options"
    [ngModel]="attributeValue"
    [readonly]="!editable"
    (onChange)="attributeChangeFn($event.value)"
  >
    <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
    <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
    <!-- <ng-template let-option pTemplate="item">{{ (withLinkTypes && option.alias) || option.name }}</ng-template>
  <ng-template let-option pTemplate="selectedItem">{{ (withLinkTypes && option.alias) || option.name }}</ng-template> -->
  </p-dropdown>
</ng-template>
