import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { AuthWidgetOptions } from '@widgets/auth-widget/types/auth-widget-options.types';
import { AuthWidgetSettings } from '@widgets/auth-widget/types/auth-widget-settings.types';
import { AuthWidgetStyles } from '@widgets/auth-widget/types/auth-widget-styles.types';
import { WidgetModel } from '../../shared/types/widget-model.interface';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';

export class AuthWidgetValue {
  constructor(public model: AuthWidgetModel = new AuthWidgetModel()) {}
}

export class AuthWidgetModel implements WidgetModel<AuthWidgetModelDto> {
  currentUser: SelfUserResponseDto;
  options = new AuthWidgetOptions();
  settings = new AuthWidgetSettings();
  styles: AuthWidgetStyles = new AuthWidgetStyles();
  userProfilePictureUrl: string | undefined;
  userProfileArtifact: ArtifactLinkResponseDto | undefined;
  userName: string | string[];

  constructor(dto?: AuthWidgetModelDto) {
    if (dto) {
      Object.assign(this, dto);
      dto.styles && (this.styles = new AuthWidgetStyles(dto.styles));
      dto.settings && (this.settings = new AuthWidgetSettings(dto.settings));
    }
  }

  fromDto(dto: AuthWidgetModelDto): void {
    dto.settings && (this.settings = new AuthWidgetSettings(dto.settings));
  }

  toServer(): AuthWidgetModelDto {
    return {
      settings: this.settings,
      styles: this.styles,
    };
  }
}

export interface AuthWidgetModelDto {
  settings: AuthWidgetSettings;
  styles: AuthWidgetStyles;
}

export enum AuthWidgetType {
  plain = 'plain',
  overlay = 'overlay',
}
