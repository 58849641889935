import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsSimple } from '../../methods/data-type.methods';

@Pipe({ name: 'isSimple' })
export class IsSimplePipe implements PipeTransform {
  transform(dataTypeKind: DataTypeKind): boolean {
    return IsSimple(dataTypeKind);
  }
}
