import { Injectable } from '@angular/core';
import { EMPTY_GROUP_VALUE, NOT_MATCHING_GROUP_VALUE } from '@shared/constants/constants';
import { GetNonAttributeValueFromArtifact } from '@shared/methods/artifact.methods';
import { IsDate, IsDateOrDateTime, IsDateTime, IsEnumerated, IsUser } from '@shared/methods/data-type.methods';
import { ConvertToClientDate } from '@shared/methods/date.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterMetadata } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import {
  GroupAttributeItem,
  GroupCollapseEnum,
  GroupingSettings,
  GroupItem,
  GroupItemArtifactObject,
  PaginationSettingEnum,
} from '../../types/list-widget-grouping.types';
import { DateGroupingHandler } from './date-grouping-handler';
import { EnumGroupingHandler } from './enum-grouping-handler';
import { GroupingHandler } from './grouping-handler';
import { UserGroupingHandler } from './user-grouping-handler';

interface SetGroupingHandlerOptions {
  users: ListContainer<NewUser>;
  dataTypes: ListContainer<NewDataType>;
}

/**
 * Artifact grouping service.
 * TODO: to be moved outside of list widget - to be usable in other widgets as well
 */
@Injectable({ providedIn: 'root' })
export class ArtifactGroupingService {
  static readonly defaultGroupId: string = 'default';
  private groupingHandlers: Record<string, GroupingHandler> = {};
  private defaultGroupMap: Record<string, GroupItem> = {};
  private emptyGroupMap: Record<string, GroupItem> = {};
  private groupingChange: Subject<GroupingHandlerChangeEvent> = new Subject();

  constructor(private readonly filterMetadataUtil: FilterMetadataUtil) {}

  // private groupItems: GroupItem[];
  // private emptyGroup: GroupItem;

  get groupingChange$(): Observable<GroupingHandlerChangeEvent> {
    return this.groupingChange;
  }

  setGroupingHandler(widgetHash: string, selectedGroupItem: GroupAttributeItem, options: SetGroupingHandlerOptions): void {
    if (!selectedGroupItem) {
      delete this.groupingHandlers[widgetHash];
      return;
    }
    if (IsEnumerated(selectedGroupItem.kind)) {
      const enumGroupValues = options.dataTypes.listMap[selectedGroupItem.dataTypeId as string]?.values || [];
      this.setGroupingHandlerAndNotifyChange(widgetHash, new EnumGroupingHandler(enumGroupValues));
    } else if (IsUser(selectedGroupItem.baseDataType)) {
      this.setGroupingHandlerAndNotifyChange(widgetHash, new UserGroupingHandler(options.users));
    } else if (IsDate(selectedGroupItem.baseDataType) || IsDateTime(selectedGroupItem.baseDataType)) {
      this.setGroupingHandlerAndNotifyChange(widgetHash, new DateGroupingHandler(this.filterMetadataUtil));
    }
  }

  groupTableData(widgetHash: string, data: NewArtifact[], groupingSettings: GroupingSettings, itemsPerCategory: number): GroupItem[] {
    if (!this.isGroupingActive(groupingSettings)) {
      const defaultGroup = this.getDefaultGroup(widgetHash) || this.initAndGetDefaultGroup(widgetHash);
      defaultGroup.artifacts = data;
      return [defaultGroup];
    }

    const groupingHandler = this.groupingHandlers[widgetHash];
    const groupDataMap = groupingHandler.groupTableData(data, groupingSettings);

    if (!groupingSettings.showEmptyGroups) {
      // const groupItems = groupingHandler.sortAndSetSortOrderValue(Object.values(groupDataMap), groupingSettings);
      // return groupingHandler.sortGroupItemsBySortOrder(Object.values(groupDataMap), groupingSettings);
      const groupItems = groupingHandler.sortAndSetSortOrderValue(Object.values(groupDataMap), groupingSettings);
      this.updateGroupItemsForExpandCollapseSettings(groupingSettings, groupItems);
      return groupItems;
    }

    const allGroups = this.getAllGroupsDataMap(widgetHash, groupingSettings, true);
    const resultGroups = groupingHandler.sortAndSetSortOrderValue(Object.values(allGroups), groupingSettings);
    Object.keys(groupDataMap).forEach(key => {
      if (allGroups[key]) {
        // allGroups[key] = { ...groupDataMap[key], sortOrder: allGroups[key].sortOrder };
        allGroups[key].artifacts = groupDataMap[key].artifacts;
        allGroups[key].total = allGroups[key].artifacts.length;
      } else {
        this.addToNotMatchingGroup(key, allGroups, groupDataMap);
      }
    });

    if (groupingSettings.pagination === PaginationSettingEnum.perGroup) {
      return resultGroups;
    }
    // grouping per table scenario
    const groupItems = this.updateGroupsForShowEmptyGroupsPerTable(resultGroups, itemsPerCategory);
    this.updateGroupItemsForExpandCollapseSettings(groupingSettings, groupItems);
    return groupItems;
  }

  getGroupId(widgetHash: string, artifact: NewArtifact, groupAttributeItem: GroupAttributeItem): string {
    const groupingHandler = this.groupingHandlers[widgetHash];
    return groupingHandler.getGroupId(artifact, groupAttributeItem);
  }

  getDefaultGroup(widgetHash: string): GroupItem {
    return this.defaultGroupMap[widgetHash];
  }

  getAllGroups(widgetHash: string, groupingSettings: GroupingSettings, withEmptyGroup = true): GroupItem[] {
    const groupingHandler = this.groupingHandlers[widgetHash];
    const allGroups = this.getAllGroupsDataMap(widgetHash, groupingSettings, withEmptyGroup);
    const resultGroups = groupingHandler.sortAndSetSortOrderValue(Object.values(allGroups), groupingSettings);
    this.updateGroupItemsForExpandCollapseSettings(groupingSettings, resultGroups);
    return resultGroups;
  }

  getGroupingValueForArtifact(artifact: NewArtifact, groupingAttribute: GroupAttributeItem | null): any {
    if (!groupingAttribute) return null;
    let value;

    if (groupingAttribute.isNonAttribute) value = GetNonAttributeValueFromArtifact(groupingAttribute.id, artifact);
    else value = artifact.attributes[groupingAttribute.id].value?.value || artifact.attributes[groupingAttribute?.id].value || 'empty';

    if (IsDateOrDateTime(groupingAttribute.dataType?.baseDataType) && !isNaN(Date.parse(value))) value = new Date(Date.parse(value));
    if (value instanceof Date) return ConvertToClientDate(value);
    if (Array.isArray(value) && value[0]) {
      value = value[0]?.value || value[0];
    }

    return value;
  }

  getFilterMetadataForGroupItem(widgetHash: string, groupItem: GroupItem): FilterMetadata {
    const groupingHandler = this.groupingHandlers[widgetHash];
    return groupingHandler.getFilterMetadataForGroupItem(groupItem);
  }

  isGroupingActive(groupingSettings: GroupingSettings): boolean {
    return groupingSettings.groupingAttributes.length > 0;
  }

  updateItemInGroup(artifact: NewArtifact, groupItem: GroupItem, resultAsNewInstance = true): GroupItem {
    groupItem.artifacts = groupItem.artifacts.map(item => (item.id === artifact.id ? artifact : item));
    return resultAsNewInstance ? Object.assign(groupItem) : groupItem;
  }

  removeItemFromGroup(artifact: NewArtifact, groupItem: GroupItem, resultAsNewInstance = true): GroupItem {
    return this.removeItemByIdFromGroup(artifact.id, groupItem, resultAsNewInstance);
  }

  removeItemByIdFromGroup(artifactId: string, groupItem: GroupItem, resultAsNewInstance = true): GroupItem {
    groupItem.artifacts = groupItem.artifacts.filter(item => item.id !== artifactId);
    return resultAsNewInstance ? Object.assign(groupItem) : groupItem;
  }

  removeLastItemFromGroup(groupItem: GroupItem, resultAsNewInstance = true): GroupItem {
    groupItem.artifacts = groupItem.artifacts.slice(0, -1);
    return resultAsNewInstance ? Object.assign(groupItem) : groupItem;
  }

  addItemIntoGroup(artifact: NewArtifact, groupItem: GroupItem, addToStart: boolean, resultAsNewInstance = true): GroupItem {
    groupItem.artifacts = addToStart ? [artifact, ...groupItem.artifacts] : [...groupItem.artifacts, artifact];
    return resultAsNewInstance ? Object.assign(groupItem) : groupItem;
  }

  clearGroupingDataForWidget(widgetHash: string): void {
    // TODO check why doesn't work following line => grouping handlers should be properly reinited
    // this.groupingHandlers && delete this.groupingHandlers[widgetHash];
    this.defaultGroupMap && delete this.defaultGroupMap[widgetHash];
    this.emptyGroupMap && delete this.emptyGroupMap[widgetHash];
  }

  /**
   * Should be called when initializing grouping for given widget.
   * @param widgetHash widget identifier
   */
  initGroupingDataForWidget(widgetHash: string): void {
    this.initAndGetDefaultGroup(widgetHash);
  }

  /**
   * Function tries to find artifact in list of groups. If there is such, object containing group item and artifact is returned, undefined otherwise.
   * @param groups list of group items where the search will be done
   * @param artifactId artifact id
   * @param startingGroup if set, this group will be checked first
   * @returns object containing group item and artifact is returned, undefined otherwise
   */
  findArtifactInGroups(groups: GroupItem[], artifactId: string, startingGroup?: GroupItem): GroupItemArtifactObject | undefined {
    if (startingGroup) {
      const artifact = this.findArtifactInGroup(startingGroup, artifactId);
      if (artifact) {
        return { groupItem: startingGroup, artifact };
      }
    }
    const searchedGroups = startingGroup ? groups.filter(groupItem => groupItem.id !== startingGroup.id) : groups;

    for (let index = 0; index < searchedGroups.length; index++) {
      const groupItem = searchedGroups[index];
      const artifact = this.findArtifactInGroup(groupItem, artifactId);
      if (artifact) {
        return { groupItem, artifact };
      }
    }
    return undefined;
  }

  /**
   * Finds and returns artifact in group or undefined if there is not such artifact.
   * @param group source group
   * @param artifactId artifact id used for comparing
   * @returns artifact or undefined
   */
  private findArtifactInGroup(group: GroupItem, artifactId: string): NewArtifact | undefined {
    if (group && artifactId) {
      return group.artifacts.find(item => item.id === artifactId);
    }
    return undefined;
  }

  private getAllGroupsDataMap(widgetHash: string, groupingSettings: GroupingSettings, withEmptyGroup = false): Record<string, GroupItem> {
    const groupingHandler = this.groupingHandlers[widgetHash];
    const allGroupsDataMap = groupingHandler.getAllGroups(groupingSettings);
    if (withEmptyGroup) {
      const emptyGroup = this.initAndGetEmptyGroup(widgetHash);
      allGroupsDataMap[emptyGroup.id] = emptyGroup;
    }
    return allGroupsDataMap;
  }

  private updateGroupsForShowEmptyGroupsPerTable(groupItems: GroupItem[], itemsPerPage: number): GroupItem[] {
    const resultGroups = [];
    let first = false;
    let total = 0;

    for (let index = 0; index < groupItems.length; index++) {
      const groupItem = groupItems[index];
      if (groupItem.artifacts.length && !first) {
        first = true;
        total += groupItem.artifacts.length;
        resultGroups.push(groupItem);
        continue;
      }
      if (first && total < itemsPerPage) {
        resultGroups.push(groupItem);
      }
    }
    return resultGroups;
  }

  private updateGroupItemsForExpandCollapseSettings(groupingSettings: GroupingSettings, groupItems: GroupItem[]): void {
    groupItems.forEach((groupItem, index) => {
      if (groupItem.metaData) {
        groupItem.metaData.toggled = this.isGroupToggled(groupingSettings, index);
      }
    });
  }

  private isGroupToggled(groupingSettings: GroupingSettings, index: number): boolean {
    return groupingSettings.groupCollapse === GroupCollapseEnum.expandAll || (groupingSettings.groupCollapse === GroupCollapseEnum.expandFirst && !index);
  }

  private setGroupingHandlerAndNotifyChange(widgetHash: string, groupingHandler: GroupingHandler): void {
    this.groupingHandlers[widgetHash] = groupingHandler;
    this.groupingChange.next({ groupingHandler, widgetHash });
  }

  private initAndGetDefaultGroup(widgetHash: string, artifacts: NewArtifact[] = []): GroupItem {
    this.defaultGroupMap[widgetHash] = { id: ArtifactGroupingService.defaultGroupId, artifacts, isDefaultGroup: true, sortOrder: 0 };
    return this.defaultGroupMap[widgetHash];
  }

  private initAndGetEmptyGroup(widgetHash: string): GroupItem {
    const groupingHandler = this.groupingHandlers[widgetHash];
    this.emptyGroupMap[widgetHash] = {
      id: EMPTY_GROUP_VALUE,
      artifacts: [],
      isDefaultGroup: false,
      sortOrder: 0,
      total: 0,
      metaData: {
        count: 0,
        header: groupingHandler.getGroupingHeader(EMPTY_GROUP_VALUE),
        isDisplayed: true,
        toggled: false,
      },
    };
    return this.emptyGroupMap[widgetHash];
  }

  private getEmptyGroup(widgetHash: string): GroupItem {
    return this.emptyGroupMap[widgetHash];
  }

  private addToNotMatchingGroup(key: string, allGroups: Record<string, GroupItem>, groupDataMap: Record<string, GroupItem>): void {
    const notMatchingGroup = allGroups[NOT_MATCHING_GROUP_VALUE];
    if (!notMatchingGroup) {
      allGroups[NOT_MATCHING_GROUP_VALUE] = {
        id: NOT_MATCHING_GROUP_VALUE,
        artifacts: groupDataMap[key].artifacts,
        metaData: { header: NOT_MATCHING_GROUP_VALUE, toggled: true, count: 0, isDisplayed: true },
        isDefaultGroup: false,
        sortOrder: Object.keys(allGroups).length,
        total: groupDataMap[key].artifacts.length,
      };
    } else {
      notMatchingGroup.artifacts = notMatchingGroup.artifacts.concat(groupDataMap[key].artifacts);
    }
  }
}

export interface GroupingHandlerChangeEvent {
  widgetHash: string;
  groupingHandler: GroupingHandler;
}
