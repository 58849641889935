<div class="bounded-range_wrapper" *ngIf="dataType.baseDataType">
  <!--Integer or Decimal range-->
  <span class="dynamic-label" *ngIf="isNumRange()">
    <input type="text" pKeyFilter="num" id="min" pInputText
           [(ngModel)]="dataType.minimum"
           (ngModelChange)="checkNumFormat($event, 'min', dataType, 'min')">
    <label class="dynamic-label-text" for="min" translate>Minimum</label>
  </span>

  <span class="dynamic-label" *ngIf="isNumRange()">
        <input type="text" pKeyFilter="num" id="max" pInputText [(ngModel)]="dataType.maximum" (ngModelChange)="checkNumFormat($event, 'max', dataType, 'max')">
        <label class="dynamic-label-text" for="max" translate>Maximum</label>
    </span>

  <!--Date range-->
  <span class="dynamic-label" *ngIf="isDateRange()">
        <p-calendar inputId="dateMin" [(ngModel)]="dataType.minimum" [dateFormat]="dateFormat" [showIcon]="true"
                    [firstDayOfWeek]="firstDayOfWeek"></p-calendar>
        <label class="dynamic-label-text" for="dateMin" translate>Minimum</label>
    </span>

  <span class="dynamic-label" *ngIf="isDateRange()">
        <p-calendar inputId="dateMax" [(ngModel)]="dataType.maximum" [dateFormat]="dateFormat" [showIcon]="true"
                    [firstDayOfWeek]="firstDayOfWeek"></p-calendar>
        <label class="dynamic-label-text" for="dateMax" translate>Maximum</label>
    </span>

  <!--Time range-->
  <span class="dynamic-label" *ngIf="isTimeRange()">
        <p-calendar inputId="timeMin" [timeOnly]="true" [(ngModel)]="dataType.minimum" [showIcon]="true"></p-calendar>
        <label class="dynamic-label-text" for="timeMin" translate>Minimum</label>
    </span>

  <span class="dynamic-label" *ngIf="isTimeRange()">
        <p-calendar inputId="timeMax" [timeOnly]="true" [(ngModel)]="dataType.maximum" [showIcon]="true"></p-calendar>
        <label class="dynamic-label-text" for="timeMax" translate>Maximum</label>
    </span>

  <!--Datetime range-->
  <span class="dynamic-label" *ngIf="isDatetimeRange()">
        <p-calendar inputId="dateTimeMin" [showTime]="true" [dateFormat]="dateFormat" [(ngModel)]="dataType.minimum" [showIcon]="true"
                    [firstDayOfWeek]="firstDayOfWeek"></p-calendar>
        <label class="dynamic-label-text" for="dateTimeMin" translate>Minimum</label>
    </span>

  <span class="dynamic-label" *ngIf="isDatetimeRange()">
        <p-calendar inputId="dateTimeMax" [showTime]="true" [dateFormat]="dateFormat" [(ngModel)]="dataType.maximum" [showIcon]="true"
                    [firstDayOfWeek]="firstDayOfWeek"></p-calendar>
        <label class="dynamic-label-text" for="dateTimeMax" translate>Maximum</label>
    </span>
</div>
