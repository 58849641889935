import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';

export class ArtifactWidgetFormItem {
  id: string;
  value: SelectOption<string, NewClientAttribute | LinkType>;
  attribute: SelectOption<string, NewClientAttribute> | null = null;
  linkType: SelectOption<string, LinkType, LinkDirection> | null = null;
  columns: string;
  isLinkTypeRequired?: boolean = false;
  tabSettings = new ArtifactTabSettings();
  folderPickerSettings: FolderWidgetSettings | null = null;

  constructor(item?: Partial<ArtifactWidgetFormItem>) {
    if (item) {
      item.columns && (this.columns = item.columns);
      item.tabSettings && (this.tabSettings = new ArtifactTabSettings({ ...item.tabSettings }));

      if (item.attribute) {
        this.attribute = item.attribute;
        this.value = item.attribute;
      }

      if (item.linkType) {
        this.linkType = item.linkType;
        this.value = item.linkType;
      }

      if (item.folderPickerSettings) {
        this.folderPickerSettings = new FolderWidgetSettings(item.folderPickerSettings);
      } else {
        item.attribute?.value.id === NonAttributeKeys.FOLDER_PATH_ID && (this.folderPickerSettings = new FolderWidgetSettings());
      }
    }
  }

  toServer(): ArtifactWidgetFormItemDto {
    const dto: ArtifactWidgetFormItemDto = { id: '', meta: null, columns: this.columns, tabSettings: this.tabSettings };
    this.folderPickerSettings && (dto.folderPickerSettings = this.folderPickerSettings);

    if (this.attribute) dto.id = this.attribute.value.id;

    if (this.linkType) {
      dto.id = this.linkType.value.id;
      dto.meta = this.linkType.meta;
    }

    return dto;
  }
}

export class ArtifactTabSettings {
  tabKey = '';
  tabValue = '';
  showWhenNoTabIsActive = true;
  alwaysShow = false;

  constructor(params?: Partial<ArtifactTabSettings>) {
    params && Object.assign(this, params);
  }
}

export interface ArtifactWidgetFormItemDto {
  id: string;
  meta: any;
  columns: string;
  tabSettings: ArtifactTabSettings;
  folderPickerSettings?: FolderWidgetSettings;
}
