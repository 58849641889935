import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkFilterEnum } from '@shared/types/filter.types';
import { LinkFilterTypes } from '@widgets/shared/components/artifact-filters/components/link-filter/types/link-filter.types';
import { lastValueFrom } from 'rxjs';
import { ArtifactFilter } from '../../../types/artifact-filter.types';

interface ObjectId {
  $oid: string;
}

interface Query {
  _id?: {
    $in: ObjectId[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class LinkFilterService {
  params: Params;

  constructor(private route: ActivatedRoute, public readonly tenantLinkService: TenantLinkService) {
    this.route.queryParams.subscribe(params => (this.params = params));
  }

  async getQuery(filter: ArtifactFilter): Promise<Query | any> {
    if (!filter.value || !filter.attribute || !filter.linkDirection) {
      return {};
    }

    const { attributeId: linkTypeId, linkDirection } = filter;
    const { name: paramName, variant } = filter.value as LinkFilterTypes;

    const artifactId = this.params[paramName];

    return {
      [LinkFilterEnum.isEmpty]: () => this.getQueryForEmptyLinks(),
      [LinkFilterEnum.isNotEmpty]: () => this.getQueryForNonEmptyLinks(),
      [LinkFilterEnum.containsUrlParamKey]: async () => await this.getQueryForArtifactIdFromUrl(artifactId, linkTypeId, linkDirection),
    }[variant]();
  }

  private getQueryForEmptyLinks(): Query {
    return {};
  }

  private getQueryForNonEmptyLinks(): Query {
    return {};
  }

  private async getQueryForArtifactIdFromUrl(artifactId: string, linkTypeId: string, direction: LinkDirection): Promise<Query | null> {
    if (!artifactId) {
      return { _id: { $in: [] } };
    }

    const linksConnectingSelectedArtifact = await this.getLinksConnectingArtifactIdByLinkTypeIdAndDirection(artifactId, linkTypeId, direction);
    const linkedArtifactIds = this.getLinkedArtifactIdsFromLinksByDirection(linksConnectingSelectedArtifact, direction);

    return { _id: { $in: linkedArtifactIds } };
  }

  private async getLinksConnectingArtifactIdByLinkTypeIdAndDirection(
    artifactId: string,
    linkTypeId: string,
    direction: LinkDirection,
  ): Promise<LinkResponseDto[]> {
    const linkDirectionKey = direction === LinkDirection.outgoing ? 'destinationArtifactId' : 'sourceArtifactId';
    const filter = JSON.stringify({
      $and: [{ deleted: { $eq: null } }, { linkTypeId: { $in: [{ $oid: linkTypeId }] } }, { [linkDirectionKey]: { $in: [{ $oid: artifactId }] } }],
    });
    const { data } = await lastValueFrom(this.tenantLinkService.linkControllerList({ body: { filter } }));

    return data;
  }

  private getLinkedArtifactIdsFromLinksByDirection(links: LinkResponseDto[], direction: LinkDirection): { $oid: string }[] {
    return links.map((link: LinkResponseDto) => {
      const $oid = direction === LinkDirection.outgoing ? link.sourceArtifactId : link.destinationArtifactId;

      return { $oid };
    });
  }
}
