import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SharedMethods } from '@shared/methods/shared.methods';
import { AuthorizationService } from '../services/authorization/authorization.service';

@Injectable({ providedIn: 'root' })
class IsTenantOrApplicationAdminGuard {
  constructor(private readonly router: Router, private readonly authorizationService: AuthorizationService) {}

  async canActivate(url: string): Promise<boolean> {
    return await this.checkAccess(url);
  }

  async canActivateChild(url: string): Promise<boolean> {
    return await this.checkAccess(url);
  }

  private async checkAccess(url: string): Promise<boolean> {
    const userMeta = this.authorizationService.getUserMeta;

    if (!userMeta || !this.authorizationService.isLoggedIn) {
      await this.router.navigateByUrl(SharedMethods.getRedirectUrlQuery(url));
      return false;
    } else {
      if (userMeta.isTenantAdmin || userMeta.isApplicationAdmin) return true;
      else {
        await this.router.navigateByUrl('/access');
        return false;
      }
    }
  }
}

export const CanActivateIsTenantOrApplicationAdmin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(IsTenantOrApplicationAdminGuard).canActivate(state.url);
};
