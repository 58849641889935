import { Component, Input } from '@angular/core';
import {
  FetchDataKeys,
  FetchDataRequestTypeEnum,
  FetchDataResponseTypeEnum,
  HttpRequestMethodsEnum,
  HttpRequestSchemesEnum,
  WorkflowActionFetchData,
} from '@workflows/types/actions/action-fetch-data';

@Component({
  selector: 'app-action-fetch-data',
  templateUrl: './action-fetch-data.component.html',
  styleUrls: ['./action-fetch-data.component.scss'],
})
export class ActionFetchDataComponent {
  @Input() action: WorkflowActionFetchData;

  requestSchemes: HttpRequestSchemesEnum[];
  requestMethods: HttpRequestMethodsEnum[];
  requestTypes: FetchDataRequestTypeEnum[];
  responseTypes: FetchDataResponseTypeEnum[];

  protected readonly RuleKeys = FetchDataKeys;
  protected readonly RequestTypes = FetchDataRequestTypeEnum;

  constructor() {
    this.requestSchemes = Object.values(HttpRequestSchemesEnum);
    this.requestMethods = Object.values(HttpRequestMethodsEnum);
    this.requestTypes = Object.values(FetchDataRequestTypeEnum);
    this.responseTypes = Object.values(FetchDataResponseTypeEnum);
  }

  responseCodesToTrackByFn(index: number): number {
    return index;
  }

  onAddResponseCode(): void {
    this.action.actionSettings.expectedResponseCodes.value.push('');
  }

  onRemoveResponseCode(index: number): void {
    this.action.actionSettings.expectedResponseCodes.value.splice(index, 1);
  }

  onAddQueryParam(): void {
    this.action.queryParams.push({ id: '', value: '' });
  }

  onRemoveQueryParam(index: number): void {
    this.action.queryParams.splice(index, 1);
  }

  onAddHeader(): void {
    this.action.additionalHeaders.push({ id: '', value: '' });
  }

  onRemoveHeader(index: number): void {
    this.action.additionalHeaders.splice(index, 1);
  }

  onAddBodyParam(): void {
    this.action.body.push({ id: '', value: '' });
  }

  onRemoveBodyParam(index: number): void {
    this.action.body.splice(index, 1);
  }
}
