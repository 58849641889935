import { Injectable } from '@angular/core';
import { FilterType, TableFilterUrlTypeService } from '@shared/types/table.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TableFilterUrlParamsEvent } from './table-filter-types';
import { TableFilterUrlBooleanService } from './table-filter-url-boolean.service';
import { TableFilterUrlDateService } from './table-filter-url-date.service';
import { TableFilterUrlDefaultService } from './table-filter-url-default.service';
import { TableFilterUrlEnumService } from './table-filter-url-enum.service';
import { TableFilterUrlTextService } from './table-filter-url-text.service';
import { TableFilterUrlUserService } from './table-filter-url-user.service';
import { TableFilterUrlNumberService } from './table-filter-url-number.service';
import { TableFilterUrlFolderService } from './table-filter-url-folder.service';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlControlService {
  private currentTableFilterUrlParamsEvent: Map<string, TableFilterUrlParamsEvent> = new Map();
  private urlQueryParamsSubject: Subject<TableFilterUrlParamsEvent> = new Subject();
  private urlQueryParams$: Observable<TableFilterUrlParamsEvent> = this.urlQueryParamsSubject.asObservable();

  constructor(
    private attributeUtil: AttributeUtil,
    private tableFilterUrlDateService: TableFilterUrlDateService,
    private tableFilterUrlEnumService: TableFilterUrlEnumService,
    private tableFilterUrlUserService: TableFilterUrlUserService,
    private tableFilterUrlTextService: TableFilterUrlTextService,
    private tableFilterUrlDefaultService: TableFilterUrlDefaultService,
    private tableFilterUrlBooleanService: TableFilterUrlBooleanService,
    private tableFilterUrlNumberService: TableFilterUrlNumberService,
    private tableFilterUrlFolderService: TableFilterUrlFolderService,
  ) {}

  getUrlQueryParams$(): Observable<TableFilterUrlParamsEvent> {
    return this.urlQueryParams$;
  }

  getUrlQueryParamsForAttribute$(ownerId: string, rawAttributeName: string): Observable<any> {
    const attributeName = this.getUrlQueryAttributeName(rawAttributeName);
    return this.urlQueryParams$.pipe(
      filter(tableFilterUrlParamsEvent => tableFilterUrlParamsEvent.ownerId === ownerId),
      map(tableFilterUrlParamsEvent => tableFilterUrlParamsEvent.urlQueryParams && tableFilterUrlParamsEvent.urlQueryParams[attributeName]),
    );
  }

  doNotifyUrlQueryParamsChange(tfupEvent: TableFilterUrlParamsEvent) {
    this.currentTableFilterUrlParamsEvent.set(tfupEvent.ownerId, tfupEvent);
    this.urlQueryParamsSubject.next(tfupEvent);
  }

  getUrlQueryAttributeName(rawAttributeName: string): string {
    return this.attributeUtil.getUrlQueryAttributeName(rawAttributeName);
  }

  getFilterUrlTypeServiceFrom(filterType: FilterType | null): TableFilterUrlTypeService {
    switch (filterType) {
      case FilterType.text:
      case FilterType.hyperlink:
        return this.tableFilterUrlTextService;
      case FilterType.enum:
        return this.tableFilterUrlEnumService;
      case FilterType.user:
        return this.tableFilterUrlUserService;
      case FilterType.date:
      case FilterType.datetime:
        return this.tableFilterUrlDateService;
      case FilterType.boolean:
        return this.tableFilterUrlBooleanService;
      case FilterType.numeric:
        return this.tableFilterUrlNumberService;
      case FilterType.folder:
        return this.tableFilterUrlFolderService;
      default:
        return this.tableFilterUrlDefaultService;
    }
  }

  getCurrentTableFilterUrlParamsEvent(ownerId: string): TableFilterUrlParamsEvent | undefined {
    return this.currentTableFilterUrlParamsEvent.get(ownerId);
  }

  hasActiveUrlParamFilter(ownerId: string, rawAttributeName: string): boolean {
    const tfupEvent = this.getCurrentTableFilterUrlParamsEvent(ownerId);
    return (tfupEvent?.urlFilteringOn && !!tfupEvent.urlQueryParams[rawAttributeName]) || false;
  }

  clearCurrentTableFilterUrlParamsEvent(ownerId: string): void {
    this.currentTableFilterUrlParamsEvent.delete(ownerId);
  }
}
