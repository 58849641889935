import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ListWidgetTableLoadModeEnum } from '../types/list-widget-settings.types';

@Pipe({ name: 'shouldShowSingleAddArtifactButton' })
export class ShouldShowSingleAddArtifactButtonPipe implements PipeTransform {
  transform(artifactTypes: SelectOption<string, NewArtifactType>[], loadMode: ListWidgetTableLoadModeEnum): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byArtifactType && artifactTypes.length === 1;
  }
}
