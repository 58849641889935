/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageResponseDto } from '../../models/page-response-dto';
import { PageUpdateRequestDto } from '../../models/page-update-request-dto';

export interface PageControllerUpdate$Params {
      body: PageUpdateRequestDto
}

export function pageControllerUpdate(http: HttpClient, rootUrl: string, params: PageControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PageResponseDto>> {
  const rb = new RequestBuilder(rootUrl, pageControllerUpdate.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageResponseDto>;
    })
  );
}

pageControllerUpdate.PATH = '/api/tenant/page';
