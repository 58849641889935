import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisplayAtOverlayControlService {
  private readonly showOverlaySubject: Subject<DisplayAtHtmlControlShowEvent> = new Subject();
  private readonly hideOverlaySubject: Subject<DisplayAtHtmlControlHideEvent> = new Subject();

  showOverlay$: Observable<DisplayAtHtmlControlShowEvent> = this.showOverlaySubject.asObservable();
  hideOverlay$: Observable<DisplayAtHtmlControlHideEvent> = this.hideOverlaySubject.asObservable();

  doShowOverlay(showEvent: DisplayAtHtmlControlShowEvent) {
    this.showOverlaySubject.next(showEvent);
  }

  doHideOverlay(ownerId: string) {
    this.hideOverlaySubject.next({ ownerId });
  }

  getShowOverlay$(ownerId: string): Observable<DisplayAtHtmlControlShowEvent> {
    return this.showOverlay$.pipe(filter(showEvent => showEvent.ownerId === ownerId));
  }
}

export interface DisplayAtHtmlControlHideEvent {
  ownerId: string;
}

export interface DisplayAtHtmlControlShowEvent extends DisplayAtHtmlControlHideEvent {
  event: Event;
  contentTemplate: TemplateRef<any>;
  closable: boolean;
}
