<div class="row">
  <div class="col-4 mb-0">
    <button (click)="fontStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Label" pButton></button>
  </div>

  <div class="col-8 mb-0">
        <span class="dynamic-label">
            <input [(ngModel)]="buttonView.text" (ngModelChange)="isChange()" id="label" pInputText type="text" />
            <label class="dynamic-label-text" for="label" translate>{{label}}</label>
        </span>
  </div>

  <!--Icon-->
  <div class="col-4 mb-0">
    <button (click)="iconStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Icon" pButton></button>
  </div>

  <div class="col-8 mb-0">
        <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="buttonView.icon" (onChangeIcon)="buttonView.icon = $event; isChange()"></app-icon-picker>
        </span>
  </div>

  <div class="col-6 mb-0">
    <button (click)="borderOp.toggle($event)" class="w-100" icon="bi bi-border-style"
            label="{{'Borders'| translate}}" pButton
            type="button"></button>
  </div>

  <div class="col-6 mb-0">
    <button (click)="colorOp.toggle($event)" class="w-100" icon="pi pi-palette"
            label="{{'Colors'| translate}}" pButton
            type="button"></button>
  </div>
</div>

<p-overlayPanel #fontStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Label styles'| translate}}</h5>
    <app-font-style-form
      [fontStyles]="buttonView.contentStyle"
      [hideFontFamilySettings]="true"
      [hideHorizontalAlignSettings]="true"
      (onChange)="isChange()"></app-font-style-form>

    <button (click)="copyPasteService.copyContentStyles(buttonView.contentStyle)"
            class="mr-2" icon="pi pi-copy" pButton pTooltip="{{'Copy style' | translate}}" type="button"></button>

    <button *ngIf="copyPasteService?.copiedContentStyles" (click)="copyPasteService.pasteStyleIntoContent(buttonView.contentStyle)"
            class="mr-2" icon="bi bi-clipboard-plus" pButton pTooltip="{{'Paste style'| translate}}" type="button"></button>
    <button *ngIf="copyPasteService?.copiedContentStyles" (click)="doPasteAll()"
            icon="pi pi-plus-circle" pButton pTooltip="{{'Apply all'| translate}}" type="button"></button>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #iconStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
      [fontStyles]="buttonView.iconStyle"
      [hideFontFamilySettings]="true"
      [hideHorizontalAlignSettings]="true"
      (onChange)="isChange()"></app-font-style-form>

    <button (click)="copyPasteService.copyIconStyles(buttonView.iconStyle)"
            class="mr-2" icon="pi pi-copy" pButton pTooltip="{{'Copy style' | translate}}" type="button"></button>

    <button *ngIf="copyPasteService?.copiedIconStyles" (click)="copyPasteService.pasteStyleIntoIcon(buttonView.iconStyle)"
            class="mr-2" icon="bi bi-clipboard-plus" pButton pTooltip="{{'Paste style'| translate}}" type="button"></button>
    <button *ngIf="copyPasteService?.copiedIconStyles" (click)="doPasteAll(false)"
            icon="pi pi-plus-circle" pButton pTooltip="{{'Apply all'| translate}}" type="button"></button>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [styles]="buttonView.contentStyle" (onChange)="isChange()"></app-border-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #colorOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Colors'| translate}}</h5>
    <div class="col-12 mb-0">
      <app-color-picker [(color)]="buttonView.contentStyle.backgroundColor"
                        (colorChange)="isChange()"
                        label="{{'Background color'|translate}}"></app-color-picker>
    </div>
  </ng-template>
</p-overlayPanel>
