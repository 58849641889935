import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ArtifactResponseDto, LinkResponseDto } from '@api/models';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATBooleanLayoutVariant, DATEnumLayoutVariant, DisplayAttributeType, UserIconSizeEnum } from '@shared/types/display-at-types';
import { NewLink } from '@shared/types/link.types';
import { NewTableColumn } from '@shared/types/table.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { PAGE_ID } from '@widgets/widgets-core/constants/widgets-core.constants';
import { Subscription } from 'rxjs';
import { ArtifactTypeFormat } from '../../../../../../../../e2e/enums/base-data-type-enum/baseDataType.enum';
import { AddArtifactTableLinkItem, ArtifactLinks, DeleteArtifactTableLinkItem } from '../../types/artifact-list-widget-table.types';
import { ListWidgetOptions } from '../../types/list-widget-options.types';
import { ListWidgetTableSettings } from '../../types/list-widget-settings.types';

@Component({
  selector: 'app-artifact-list-table-cell',
  templateUrl: './artifact-list-table-cell.component.html',
  styleUrls: ['artifact-list-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactListWidgetTableCellComponent {
  @Input() tableUniqueId: string;
  @Input() column: NewTableColumn;
  @Input() artifact: NewArtifact;
  @Input() options: ListWidgetOptions;
  @Input() tooltipEnabled: boolean;
  @Input() rowIndex: number;
  @Input() links: Record<string, Record<string, ArtifactLinks>> = {};
  @Input() linkedData: Record<string, NewArtifact> = {};
  @Input() linksDto: LinkResponseDto[] = [];
  @Input() files: Record<string, ArtifactResponseDto> = {};
  @Input() unSavedFiles: Record<string, Record<string, File[]>> = {};
  @Input() settings: ListWidgetTableSettings;
  @Input() isSimple: boolean;
  @Input() isMandatory: boolean;
  @Input() editMode: boolean;
  @Input() filesLoaded: boolean;
  @Input() isInSavingProcess: boolean;

  @Output() onAddLinkClick: EventEmitter<AddArtifactTableLinkItem> = new EventEmitter();
  @Output() onDeleteLinkClick: EventEmitter<DeleteArtifactTableLinkItem> = new EventEmitter();

  displayAttributeTypeEnum = DisplayAttributeType;
  contentKey = NonAttributeKeys.CONTENT;
  userIconSize: UserIconSizeEnum = UserIconSizeEnum.MEDIUM;
  private eventsSubscription: Subscription;

  constructor(
    @Inject(PAGE_ID) public pageId: string,
    protected readonly cdr: ChangeDetectorRef,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    this.init();
  }

  get isIdColumn(): boolean {
    return this.column.key === 'id';
  }

  ngOnDestroy(): void {
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === this.column.meta.attributeMetadata?.attribute.id && this.cdr.markForCheck();
      }
    });
  }

  getRowIdLink(): string[] | null {
    const artifactType = this.options.artifactTypes.listMap[this.artifact?.artifactTypeId];
    if (artifactType?.defaultPageId) return ['/page', artifactType.defaultPageId];
    return null;
  }

  getRowIdLinkQueryParams(): Record<string, string> | null {
    const artifactType = this.options.artifactTypes.listMap[this.artifact.artifactTypeId];

    if (artifactType?.defaultPageId) return { [this.settings.urlKeys.emittingKeys.artifactId]: this.artifact.id };
    return null;
  }

  getAttribute(): NewClientAttribute {
    return this.artifact.clientAttributes && this.artifact.attributes[this.column.key];
  }

  getDataType(): NewDataType {
    const attribute = this.options.attributes.listMap[this.column.key];
    return this.options.dataTypes.listMap[attribute.dataTypeId];
  }

  updateAttributeValue(value: any): void {
    if (!this.artifact.attributes[this.column.key]) {
      this.artifact.attributes[this.column.key] = new NewClientAttribute({ id: this.column.key });
    }
    this.artifact.attributes[this.column.key].value = value;
  }

  addLinkClicked(artifact: NewArtifact = this.artifact, column: NewTableColumn = this.column): void {
    this.onAddLinkClick.emit({ artifact, column });
  }

  deleteLink(artifact: NewArtifact = this.artifact, link: NewLink): void {
    this.onDeleteLinkClick.emit({ artifact, link });
  }

  isNonAttribute(): boolean {
    return NonAttributeKeys.isIsHeadingKey(this.column.key);
  }

  isAttribute(): boolean {
    return !this.isNonAttribute();
  }

  isText(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.text;
  }

  isHtml(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.html || this.isMultivaluedText();
  }

  isHyperlink(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.hyperlink;
  }

  isSystemDate(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.systemDate;
  }

  isDateTime(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.dateTime;
  }

  isEnum(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.enumerated || this.isBooleanEnum();
  }

  isUser(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.user;
  }

  isFile(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.file;
  }

  isFileArtifact(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.file && this.artifact.format === ArtifactTypeFormat.file;
  }

  isBoolean(): boolean {
    return this.column.meta.displayAtEnumType === DisplayAttributeType.boolean && !this.isBooleanEnum();
  }

  /**
   * Function returns true if the column represents artifact type.
   */
  isArtifactType(): boolean {
    return NonAttributeKeys.isArtifactTypeKey(this.column.key);
  }

  private isBooleanEnum(): boolean {
    return (
      this.column.meta.displayAtEnumType === DisplayAttributeType.boolean &&
      Boolean(this.column.meta.displayAtMetadata?.selectedVariantCode) &&
      this.column.meta.displayAtMetadata!.selectedVariantCode !== DATBooleanLayoutVariant.DEFAULT &&
      Boolean(DATEnumLayoutVariant[this.column.meta.displayAtMetadata!.selectedVariantCode as any as keyof typeof DATEnumLayoutVariant])
    );
  }

  private isMultivaluedText(): boolean {
    return (this.isText() || this.isHyperlink()) && !!this.column.meta.attributeMetadata?.attribute.multipleValues;
  }
}
