import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getSizeInPixels' })
export class GetSizeInPixelsPipe implements PipeTransform {
  transform(size: string): number {
    let result = 100;
    const rgx = /(\d*)(%|px|pt|pc|in|cm|mm|em|rem|ex|ch|ft|VW|vh|vmin|vmax|)/;
    const match = rgx.exec(size);
    if (match != null && match.length > 0) {
      const theSize = parseInt(match[1]);
      let units = '';
      if (match[2] != null) units = match[2].toLowerCase();
      switch (units) {
        case '%':
          result = Math.round((theSize / 100) * window.screen.width);
          break;
        case 'px':
          result = theSize;
          break;
        case 'pt':
          result = theSize * (4 / 3);
          break;
        case 'pc':
          result = theSize * 16;
          break;
        case 'in':
          result = theSize * 96;
          break;
        case 'cm':
          result = theSize * 37.8;
          break;
        case 'mm':
          result = theSize * 3.78;
          break;
        case 'em':
          result = theSize * this.getFontMedian();
          break;
        case 'rem':
          {
            const em = theSize * this.getFontMedian();
            result = Math.round((theSize / 100) * em);
          }
          break;
        case 'ex':
          result = theSize * this.getFontMedian();
          break;
        case 'ch':
          result = theSize * this.getFontMedian();
          break;
        case 'ft':
          result = theSize * 1152;
          break;
        case 'vw':
          result = Math.round((window.innerWidth / 100) * theSize);
          break;
        case 'vh':
          result = Math.round((window.innerHeight / 100) * theSize);
          break;
        case 'vmin':
          {
            const vw = Math.round((window.innerWidth / 100) * theSize);
            const vh = Math.round((window.innerHeight / 100) * theSize);
            result = vw;
            if (vw > vh) result = vh;
          }
          break;
        case 'vmax':
          {
            const vw = Math.round((window.innerWidth / 100) * theSize);
            const vh = Math.round((window.innerHeight / 100) * theSize);
            result = vh;
            if (vw > vh) result = vw;
          }
          break;
        default:
          result = theSize;
          break;
      }
    }
    return result;
  }

  getFontMedian(): number {
    let result = 16;
    const fontMedian = parseFloat(getComputedStyle(document.documentElement).fontSize);
    if (!isNaN(fontMedian)) result = fontMedian;
    return result;
  }
}
