import { Component, Input, ViewChild } from '@angular/core';
import { LinkTypeListService } from '@private/pages/artifact-type-management/link-type-list/services/link-type-list.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { APPLICATION_ID_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-link-type-list-table',
  templateUrl: './link-type-list-table.component.html',
  styleUrls: ['./link-type-list-table.component.scss'],
})
export class LinkTypeListTableComponent extends CoreListComponent<any> {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<any>>;
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() applications: ListContainer<NewApplication>;

  APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly linkTypeListService: LinkTypeListService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super();
  }

  async onLazyLoad(event: LazyLoadEvent): Promise<void> {
    await super.onLazyLoad(event);

    try {
      this.loading = true;
      const applicationIds = new Set<string>();

      this.data.forEach(linkType => applicationIds.add(linkType.applicationId));

      // const applications = (
      //   await this.applicationGlobalService.list({
      //     filter: JSON.stringify({ _id: { $in: [...applicationIds].map(id => ({ $oid: id })) } }),
      //   })
      // ).data;
      // this.data.forEach(linkType => (linkType.application = applications.find(app => app.id === linkType.applicationId)));
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  protected onInit(): void {
    super.onInit();
    this.registerSubscription(this.applicationSwitcherService.selectedApplication$.subscribe(() => this.linkTypeListService.doFilter(this.table)));
  }
}
