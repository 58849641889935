import { Pipe, PipeTransform } from '@angular/core';
import { JobsResponseDto } from '@api/models/jobs-response-dto';
import { RESULT_KEY, UPDATED_ON_KEY } from '@shared/constants/constants';
import { ConvertToClientDatetime } from '@shared/methods/date.methods';
import { get } from 'lodash';

@Pipe({ name: 'jobsValueToTable' })
export class JobsValueToTablePipe implements PipeTransform {
  transform(dto: JobsResponseDto, key: string): string {
    if (key === UPDATED_ON_KEY) return ConvertToClientDatetime(new Date(dto.updated.on));
    if (key === RESULT_KEY && !dto.result) return dto.error as string;

    return get(dto, key);
  }
}
