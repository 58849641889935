import { Component, Input } from '@angular/core';
import { MenuWidgetOptions } from '../../../../../../types/menu-option.types';
import { HorizontalDividerStyles } from '../../../../../../types/menu-widget-styles.types';

@Component({
  selector: 'app-divider-horizontal-form',
  templateUrl: './divider-horizontal-form.component.html',
  styleUrls: ['./divider-horizontal-form.component.scss'],
})
export class DividerHorizontalFormComponent {
  @Input() options: MenuWidgetOptions;
  @Input() dividerStyle: HorizontalDividerStyles;
}
