export class PageBuilderModel {
  constructor(public list: any[] = [], public layoutToolbar: LayoutToolbar = new LayoutToolbar(), public canvasList: CanvasItem[] = []) {}
}

export class CanvasItem {
  constructor(public itemsCode: string = '', public items: string[] = []) {}
}

export class LayoutToolbar {
  constructor(public preparedLayoutOptions: string[] = [], public icons: string[][] = []) {}
}

export class TestItem {
  constructor(public id: number, public name: string) {}
}
