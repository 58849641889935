import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { ListContainer } from '@shared/types/list-container.types';
import { LinkQueryParamActionEnum, MenuItemBehaviorEnum, MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';
import { FlexDirectionEnum, StyleOptions } from '../../shared/types/style.types';
import { MenuItemHorizontalPosition, MenuItemVerticalPosition } from './menu-widget-styles.types';

export class MenuWidgetOptions extends StyleOptions {
  display = GetSelectOptionsFromEnum(FlexDirectionEnum);
  pages = new ListContainer<Page>();
  menuItemHorizontalAlign = GetSelectOptionsFromEnum(MenuItemHorizontalPosition);
  menuItemVerticalAlign = GetSelectOptionsFromEnum(MenuItemVerticalPosition);
  menuTypes = GetSelectOptionsFromEnum(MenuTypesEnum);
  menuItemBehavior = GetSelectOptionsFromEnum(MenuItemBehaviorEnum);
  defaultElvisActions = GetSelectOptionsFromEnum(DefaultElvisActions);
  currentPageBehavior = GetSelectOptionsFromEnum(MenuItemCurrentPageBehaviorEnum);
  linkQueryParamAction = GetSelectOptionsFromEnum(LinkQueryParamActionEnum);
}

export enum MenuTypesEnum {
  horizontal = 'horizontal',
  tiered = 'tiered',
  panel = 'panel',
  // slide = 'slide'
}
