import { Component } from '@angular/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { DataTypeService } from './services/data-type.service';
import { DataTypesModel } from './types/data-type.types';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { IS_DELETED_LABEL } from '@shared/constants/constants';

@Component({
  selector: 'app-data-types',
  templateUrl: './data-type.component.html',
  styleUrls: ['./data-type.component.scss'],
  providers: [DataTypeService],
})
export class DataTypeComponent extends CoreComponent<DataTypeService, DataTypesModel> {
  public canDeactivate = false;

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: DataTypeService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new DataTypesModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Data Types' }]);
  }
}
