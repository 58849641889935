import { Injectable } from '@angular/core';
import { CoreService } from '@shared/core/services/core.service';
import { CanvasItem, LayoutToolbar, PageBuilderModel, TestItem } from '../types/page-builder-schematic.types';

@Injectable()
export class PageBuilderSchematicService extends CoreService<any, PageBuilderModel> {
  public layoutWrapper: HTMLDivElement;

  constructor() {
    super();
  }

  init(context: any, model: PageBuilderModel): void {
    super.init(context, model);

    this.initList();
    this.initPreparedLayoutOptions();
    this.setHeightToContent();
    this.layoutWrapper = document.querySelector('.layout-wrapper') as HTMLDivElement;
  }

  addCanvas(item: string): void {
    const value = item.split('+').reduce((buffer: number, current: string) => (buffer += +current), 0);

    if (value % 12 === 0) {
      this.m.canvasList.push(new CanvasItem(item, item.split('+')));
      this.layoutWrapper.click();
    } else {
      this.c.announcement.error('The sum of all values must be a multiple of 12');
    }
  }

  generateCanvas(): void {
    const input: HTMLInputElement = document.getElementById('custom') as HTMLInputElement;
    this.addCanvas(input.value);
  }

  private initList(): void {
    for (let i = 1; i <= 15; i += 1) {
      this.m.list.push(new TestItem(i, 'Test item ' + i));
    }
  }

  private initPreparedLayoutOptions(): void {
    const toolbar = new LayoutToolbar();
    toolbar.preparedLayoutOptions = ['12', '6+6', '4+4+4', '3+3+3+3', '8+4', '3+9', '3+6+3', '2+10', '2+5+5', '6+6+12'];
    toolbar.icons = toolbar.preparedLayoutOptions.map(item => item.split('+'));
    this.m.layoutToolbar = toolbar;
  }

  private setHeightToContent(): void {
    const container: HTMLDivElement = document.querySelector('.page-builder') as HTMLDivElement;
    container.setAttribute('style', `max-height: calc(100vh - ${container.offsetTop + 80}px)`);
  }
}
