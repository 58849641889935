import { ColumnFilter } from 'primeng/table';
import { NewTableColumn, TableFilterUrlTypeService } from '@shared/types/table.types';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableFilterUrlFolderService implements TableFilterUrlTypeService {
  doApplyFilter(column: NewTableColumn, filterValue: string, columnFilterRef: ColumnFilter): void {
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].matchMode = FilterMatchMode.IN;
    (columnFilterRef.dt.filters[column.key] as FilterMetadata[])[0].value = this.getUrlQueryAttributeValue(filterValue);
    columnFilterRef.applyFilter();
  }

  getFilterMetadata(filterValue: string | string[]): FilterMetadata[] {
    return [
      {
        matchMode: FilterMatchMode.IN,
        operator: FilterOperator.AND,
        value: filterValue,
      },
    ];
  }

  getUrlQueryAttributeValue(rawQueryAttributeValue: string): string[] {
    return rawQueryAttributeValue?.split(',') || [];
  }
}
