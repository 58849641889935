import { Directive, HostBinding, Input } from '@angular/core';
import { BorderRadius } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { RangedGridItem } from '@shared/components/grid-layout-generator/types/ranged-grid-item';
import { RangedGridItemDirective } from './ranged-grid-item.directive';

@Directive({
  selector: '[appCardAreaInheritCornerBorderRadius]',
})
export class InheritCornerBorderRadiusDirective extends RangedGridItemDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('area') declare item: RangedGridItem & { content: { styles: BorderRadius } };
  @Input() columnsCount: number;
  @Input() rowsCount: number;

  @HostBinding('style.border-top-left-radius') get borderTopLeftRadius(): string {
    const areaRadius = this.item.content.styles?.borderTopLeftRadius || '';

    return this.isTopLeftCorner && !areaRadius ? 'inherit' : areaRadius;
  }

  @HostBinding('style.border-top-right-radius') get borderTopRightRadius(): string {
    const areaRadius = this.item.content.styles?.borderTopRightRadius || '';

    return this.isTopRightCorner && !areaRadius ? 'inherit' : areaRadius;
  }

  @HostBinding('style.border-bottom-right-radius') get borderBottomRightRadius(): string {
    const areaRadius = this.item.content.styles?.borderBottomRightRadius || '';

    return this.isBottomRightCorner && !areaRadius ? 'inherit' : areaRadius;
  }

  @HostBinding('style.border-bottom-left-radius') get borderBottomLeftRadius(): string {
    const areaRadius = this.item.content.styles?.borderBottomLeftRadius || '';

    return this.isBottomLeftCorner && !areaRadius ? 'inherit' : areaRadius;
  }

  private get isTopLeftCorner(): boolean {
    return this.gridColumnStart === 1 && this.gridRowStart === 1;
  }

  private get isTopRightCorner(): boolean {
    return this.gridColumnEnd === this.columnsCount + 1 && this.gridRowStart === 1;
  }

  private get isBottomRightCorner(): boolean {
    return this.gridColumnEnd === this.columnsCount + 1 && this.gridRowEnd === this.rowsCount + 1;
  }

  private get isBottomLeftCorner(): boolean {
    return this.gridColumnStart === 1 && this.gridRowEnd === this.rowsCount + 1;
  }
}
