import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { DateFilter } from '@shared/services/filter/filter-types/date-filter';
import { NewDataType } from '@shared/types/data-type.types';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { TimeFilterRuleType } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class BaseDateFilterService extends AbstractBaseTypeFilterService {
  constructor(protected readonly filterUtil: FilterUtil, protected readonly filterMetadataUtil: FilterMetadataUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any): Record<string, any> | null {
    if (!value) {
      return null;
    }

    const dbFilterKey = dataType ? this.filterUtil.getAttributesDbFilterKey(attributeKey) : attributeKey.replace('-', '.');
    const filter = new DateFilter(value[0] as any, dbFilterKey, value.slice(1), dataType);

    return filter.query;
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    return super.getQueryFromRawTextMulti(attributeKey, dataType, value, delimiter, filterRule);
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    switch (filterRule) {
      case CoreListFilterEnum.dateIs:
      case CoreListFilterEnum.dateIsNot:
      case CoreListFilterEnum.dateBefore:
      case CoreListFilterEnum.dateBeforeOrEqualTo:
      case CoreListFilterEnum.dateAfter:
      case CoreListFilterEnum.dateAfterOrEqualTo:
        return true;
      default:
        return false;
    }
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.dateIs;
  }

  getQueryForDateIs(key: string, type: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType, value: string): Record<string, any> {
    const range = this.filterMetadataUtil.getStartEndDateFromDate(new Date(String(value)));
    const $and = [{ [key]: { $lt: { $date: String(new Date(range.end)) } } }, { [key]: { $gt: { $date: String(new Date(range.start)) } } }];
    const $or = [{ [key]: { $gt: { $date: String(new Date(range.end)) } } }, { [key]: { $lt: { $date: String(new Date(range.start)) } } }];
    return type === DateFilterEnum.dateIs ? { $and } : { $or };
  }
}
