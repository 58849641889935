<div class="layout-topbar">
  <div class="topbar-left">
    <a (click)="app.onMenuButtonClick($event)" class="menu-button" tabindex="0">
      <i class="pi pi-chevron-left"></i>
    </a>
    <a (click)="togglePageBuilderHeader()" class="header-btn">
      <i class="pi" [ngClass]="headerIsVisible ? 'pi-chevron-up': 'pi-chevron-down'"></i>
    </a>
    <span class="topbar-separator"></span>
    <ng-template [ngForOf]="m.breadcrumbs" let-item let-last="last" ngFor>
      <span class="viewname" style="text-transform: uppercase">{{item.label | translate}}</span>
    </ng-template>
    <img alt="diamond-layout" class="mobile-logo" id="logo-mobile"
         src="../../../../assets/layout/images/logo-dark.svg"/>
  </div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <!--            <li class="search-item">-->
      <!--                <a tabindex="0" (click)="app.onSearchClick($event)">-->
      <!--                    <i class="pi pi-search"></i>-->
      <!--                </a>-->
      <!--            </li>-->
      <!--            <li class='notifications-item' [ngClass]="{'active-menuitem ': app.topbarNotificationMenuActive}">-->
      <!--                <a href="#" tabindex="0" (click)="app.onTopbarNotificationMenuButtonClick($event)">-->
      <!--                    <i class="pi pi-bell"></i>-->
      <!--                    <span class="admin-topbar-badge">5</span>-->
      <!--                </a>-->
      <!--                <ul class="notifications-menu fade-in-up">-->
      <!--                    <li role="menuitem">-->
      <!--                        <a href="#" tabindex="0">-->
      <!--                            <i class="pi pi-shopping-cart"></i>-->
      <!--                            <div class="notification-item">-->
      <!--                                <div class="notification-summary">New Order</div>-->
      <!--                                <div class="notification-detail">You have <strong>3</strong> new orders.</div>-->
      <!--                            </div>-->
      <!--                        </a>-->
      <!--                    </li>-->
      <!--                    <li role="menuitem">-->
      <!--                        <a href="#" tabindex="0">-->
      <!--                            <i class="pi pi-check-square"></i>-->
      <!--                            <div class="notification-item">-->
      <!--                                <div class="notification-summary">Withdrawn Completed</div>-->
      <!--                                <div class="notification-detail">Funds are on their way.</div>-->
      <!--                            </div>-->
      <!--                        </a>-->
      <!--                    </li>-->
      <!--                    <li role="menuitem">-->
      <!--                        <a href="#" tabindex="0">-->
      <!--                            <i class="pi pi-chart-line"></i>-->
      <!--                            <div class="notification-item">-->
      <!--                                <div class="notification-summary">Monthly Reports</div>-->
      <!--                                <div class="notification-detail">New reports are ready.</div>-->
      <!--                            </div>-->
      <!--                        </a>-->
      <!--                    </li>-->
      <!--                    <li role="menuitem">-->
      <!--                        <a href="#" tabindex="0">-->
      <!--                            <i class="pi pi-comments"></i>-->
      <!--                            <div class="notification-item">-->
      <!--                                <div class="notification-summary">Comments</div>-->
      <!--                                <div class="notification-detail"><strong>2</strong> new comments.</div>-->
      <!--                            </div>-->
      <!--                        </a>-->
      <!--                    </li>-->
      <!--                    <li role="menuitem">-->
      <!--                        <a href="#" tabindex="0">-->
      <!--                            <i class="pi pi-exclamation-circle"></i>-->
      <!--                            <div class="notification-item">-->
      <!--                                <div class="notification-summary">Chargeback Request</div>-->
      <!--                                <div class="notification-detail"><strong>1</strong> to review.</div>-->
      <!--                            </div>-->
      <!--                        </a>-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--            </li>-->

      <li [ngClass]="{'active-menuitem fadeInDown': app.topBarUserMenuActive}" class="profile-item">
        <a (click)="app.onTopBarUserMenuButtonClick($event)" href="#">
          <img alt="diamond-layout" class="profile-image" src="../../../../assets/images/default-avatar.jpg"/>
          <span>{{ m.user?.email }}</span>
        </a>
        <ul class="profile-menu fade-in-up">
          <li>
            <a [routerLink]="[appInterface === appInterfaceEnum.tenant ? '/admin' : '/system', 'profile']">
              <i class="pi pi-user"></i>
              <span translate>Profile</span>
            </a>
          </li>
          <li>
            <a (click)="logout()">
              <i class="pi pi-power-off"></i>
              <span translate>Logout</span>
            </a>
          </li>
        </ul>
      </li>

      <!--<li class="right-sidebar-item">
        <a (click)="app.onRightMenuClick($event)" href="#" tabindex="0">
          <i class="pi pi-align-right"></i>
        </a>
      </li>-->
    </ul>
  </div>
</div>
