import { Component, Input } from '@angular/core';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SendEmailKeys, WorkflowActionSendEmail } from '@workflows/types';

@Component({
  selector: 'app-action-send-email',
  templateUrl: './action-send-email.component.html',
  styleUrls: ['./action-send-email.component.scss'],
})
export class ActionSendEmailComponent {
  @Input() action: WorkflowActionSendEmail;

  settings = { ...this.tinyMceHelper.getTinyMceAdvancedSettings(), inline: false };
  protected readonly RuleKeys = SendEmailKeys;

  constructor(private readonly tinyMceHelper: TinyMceHelper) {}

  onIsDynamicRecipientChange(isDynamic: boolean): void {
    const idSettings = this.action.actionSettings[SendEmailKeys.emailsTo];
    idSettings.value = isDynamic ? '' : [''];
  }

  onAddRecipient(): void {
    (this.action.actionSettings[SendEmailKeys.emailsTo].value as string[]).push('');
  }

  onRemoveRecipient(index: number): void {
    (this.action.actionSettings[SendEmailKeys.emailsTo].value as string[]).splice(index, 1);
  }

  emailsToTrackByFn(index: number): number {
    return index;
  }
}
