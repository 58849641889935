import { Pipe, PipeTransform } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';

@Pipe({
  name: 'isRegularEnumFilterType',
})
export class IsRegularEnumFilterTypePipe implements PipeTransform {
  transform(matchMode: CoreListFilterEnum): boolean {
    const { isEmpty, isNotEmpty } = CoreListFilterEnum;
    return matchMode !== isNotEmpty && matchMode !== isEmpty;
  }
}
