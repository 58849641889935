<p-dialog
  (onHide)="onLinkingPopupHide()"
  [(visible)]="m.displayModal"
  [baseZIndex]="2"
  [contentStyle]="{ minHeight: '250px', paddingBottom: 0, overflow: 'visible' }"
  [draggable]="false"
  [header]="m.directionalLinkType?.label || ''"
  [modal]="true"
  [resizable]="false"
  [appendTo]="'body'"
  [style]="{ width: '80vw', position: 'absolute', top: '5rem' }"
>
  <div class="advanced-container" *ngIf="!isPreviewMode">
    <i (click)="setWidgetAsAdvancedMode()" class="bi bi-tools" pRipple pTooltip="{{ 'Advanced Mode' | translate }}"
       tooltipPosition="top"> </i>
  </div>

  <div *ngIf="m.options.relevantArtifactTypes?.length > 1" class="grid">
    <div class="col-12 md:col-5">
      <span class="dynamic-label p-float-label">
        <p-dropdown
          (onChange)="onArtifactTypeChange()"
          (onShow)="storeDto()"
          [(ngModel)]="m.selected.artifactType"
          [autoDisplayFirst]="false"
          [filter]="true"
          [options]="m.options.relevantArtifactTypes"
          appendTo="body"
          class="modal-dropdown"
          inputId="linkingModalArtifactTypeSelect"
        >
        </p-dropdown>
        <label *ngIf="!m.selected.artifactType" class="dynamic-label-text" [for]="'linkingModalArtifactTypeSelect'">
          {{ 'Artifact type' | translate }}
        </label>
      </span>
    </div>
  </div>

  <!--  TODO Container for widget-->
  <!--    <ng-container *ngIf="blockPartForWidgetPlacement">-->
  <!--      <section class="container sidebar_section">-->
  <!--        <div [ngClass]="m.isSettingsSidebarVisible ? 'active': ''" class="sidebar_block_part">-->
  <!--          <div *ngIf="m.isSettingsSidebarVisible" class="sidebar_buttons_toolbar sidebar_block_part_toolbar">-->
  <!--            <i (click)="setWidgetAsAdvancedMode(blockPartForWidgetPlacement.widget)" -->
  <!--               class="bi bi-clock" -->
  <!--               pRipple-->
  <!--               pTooltip="{{'Advanced Mode' | translate}}"-->
  <!--               tooltipPosition="top">-->
  <!--            </i>-->
  <!--          </div>-->
  <!--          <div [id]="blockPartForWidgetPlacement.hash">-->
  <!--            <app-widget-container *ngIf="blockPartForWidgetPlacement.widget"-->
  <!--                                  [applicationId]="applicationId"-->
  <!--                                  [hash]="blockPartForWidgetPlacement.hash"-->
  <!--                                  [disabled]="false"-->
  <!--                                  [isLayoutMode]="true"-->
  <!--                                  [widget]="blockPartForWidgetPlacement.widget"-->
  <!--                                  [isInSidebar]="true"-->
  <!--                                  [listSelectionSettings]="linkSettings">-->
  <!--            </app-widget-container>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </section>-->
  <!--    </ng-container>-->

  <div class="list" style="overflow-y: auto">
    <app-artifact-list-widget-table
      *ngIf="m.settings.showTable; else loading"
      style="display: block; max-height: calc(90vh - 250px)"
      [addButtonItems]="m.addButtonItems"
      [ids]="m.ids"
      [loadDataMethod]="helper.getLoadDataMethod()"
      [options]="m.options"
      [selected]="m.selected"
      [settings]="m.settings"
      [state]="m.state"
      [restrictions]="restrictions"
      [hash]="m.hash"
      [sortByAttribute]="true"
    >
    </app-artifact-list-widget-table>
  </div>

  <ng-template pTemplate="footer">
    <!--    TODO Message if linking is not allowed-->
    <span *ngIf="false">Can`t add link because of link restrictions</span>
    <div class="dialog-footer">
      <button (click)="close()" class="p-button p-button-warning" label="{{ 'Cancel' | translate }}" pButton
              type="button"></button>
      <!--      [disabled]="!m.selected.artifacts.length || disableSaveDueToSingleRestrictions"-->
      <button
        (click)="create()"
        [disabled]="!m.selected.artifacts.length"
        class="p-button p-button-success"
        label="{{ m.selected.artifacts.length | getCreateLinkButtonLabel : m.settings.multiselect | translate }}"
        pButton
        pRipple
        type="button"
      ></button>
    </div>
  </ng-template>
</p-dialog>

<p-sidebar
  (onHide)="onSettingsSidebarHide()"
  [dismissible]="true"
  [modal]="false"
  [style]="{ width: '40em' }"
  [visible]="m.isSettingsSidebarVisible"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <app-sidebar-header [name]="'Linking popup'" [showPositionControls]="false"></app-sidebar-header>
  </ng-template>
  <br/>

  <div *ngIf="!isPreviewMode && m" class="list_top">
    <ng-container>
      <div class="grid list_top__filters">
        <div class="col-6">
          <label for="columns" translate> Columns</label>
          <p-multiSelect
            (onChange)="onSelectedColumnsChange($event.value)"
            [ngModel]="m.selected.columns"
            [options]="m.options.applicableColumns"
            defaultLabel="Select columns"
            optionLabel="label"
            id="columns"
          >
            <ng-template let-option pTemplate="item">
              <span class="link-label">{{ option.label }}</span>
              <app-link-option-with-arrow [direction]="option.meta.linkDirection"></app-link-option-with-arrow>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>

      <app-link-popup-table-settings [settings]="m.settings" [options]="m.options"
                                     [onGroupAttributeChangeCb]="onGroupAttributeChangeCb.bind(this)">
      </app-link-popup-table-settings>
    </ng-container>
  </div>
</p-sidebar>

<ng-template #loading>
  <div>loading...</div>
</ng-template>
