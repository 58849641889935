import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ImplementedRuleTriggerTypes, RuleTriggerType, WorkflowOwnerType, WorkflowTrigger } from '@workflows/types';
import { AbstractWorkflowRuleItemComponent } from '../abstract';

@Component({
  selector: 'app-workflows-rule-trigger',
  templateUrl: './workflow-rule-trigger.component.html',
  styleUrls: ['./workflow-rule-trigger.component.scss', '../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowRuleTriggerComponent extends AbstractWorkflowRuleItemComponent implements OnChanges {
  @Input() ownerType: WorkflowOwnerType;
  @Input() trigger: WorkflowTrigger;
  @Input() pageWidgets: BlockPartWidget[];
  @Input() linkableArtifactTypes: NewArtifactType[];
  @Output() onTriggerTypeUpdate: EventEmitter<RuleTriggerType> = new EventEmitter();
  @Output() onTriggerPropertyUpdate: EventEmitter<WorkflowTrigger> = new EventEmitter();

  triggerTypes: SelectOption<string, RuleTriggerType>[];

  constructor(protected translateService: TranslateService, private readonly translateUtil: TranslateUtil) {
    super(translateService);

    this.triggerTypes = Object.values(RuleTriggerType)
      .filter(trigger => ImplementedRuleTriggerTypes[trigger])
      .map(val => new SelectOption(val));

    this.translateTriggerOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultArtifactType?.currentValue && !this.trigger.isExternalArtifactTypeRequired()) {
      this.onArtifactTypeChange(this.defaultArtifactType.id);
    }
  }

  onTriggerTypeChange(triggerType: RuleTriggerType) {
    this.onTriggerTypeUpdate.emit(triggerType);
  }

  onArtifactTypeChange(artifactTypeId: string) {
    this.trigger.artifactTypeId = artifactTypeId;
    if (this.trigger.isAttributeRequired()) {
      super.onArtifactTypeChange(artifactTypeId);
      return;
    }
    this.onTriggerPropertyUpdate.emit(this.trigger);
  }

  onAttributeChange(attributeId: string) {
    this.trigger.attributeId = attributeId;
    this.onTriggerPropertyUpdate.emit(this.trigger);
  }

  onWidgetChange(widgetId: string) {
    this.trigger.widgetId = widgetId;
    this.onTriggerPropertyUpdate.emit(this.trigger);
  }

  private translateTriggerOptions(): void {
    this.triggerTypes.forEach(option => {
      this.translateUtil.get('WORKFLOWS.TRIGGER.' + option.value).then(res => {
        option.label = res;
      });
    });
  }
}
