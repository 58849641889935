import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormatAttributeAction } from '../../types';

@Injectable({ providedIn: 'root' })
export class RuleFormatHandlerService {
  private formatEvent: ReplaySubject<FormatAttributeAction>;
  private formatEventObservable: Observable<FormatAttributeAction>;

  constructor() {
    this.formatEvent = new ReplaySubject(10);
    this.formatEventObservable = this.formatEvent.asObservable();
  }

  get formatEvent$(): Observable<FormatAttributeAction> {
    return this.formatEventObservable;
  }

  notifyFormatEvent(event: FormatAttributeAction) {
    this.formatEvent.next(event);
  }
}
