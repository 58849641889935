import { Injectable } from '@angular/core';
import { ArtifactModuleInsertRequestDto } from '@api/models/artifact-module-insert-request-dto';
import { Observable } from 'rxjs';
import { ArtifactModuleHeadingRequestDto } from '@api/models/artifact-module-heading-request-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { NewArtifact } from '@shared/types/artifact.types';

@Injectable({ providedIn: 'root' })
export class ArtifactModuleService {
  constructor(private readonly tenantArtifactService: TenantArtifactService) {}

  updateModuleHeadingData$(artifact: NewArtifact): Observable<ArtifactResponseDto> {
    const moduleHeadingDto: ArtifactModuleHeadingRequestDto = {
      id: artifact.id,
      isHeading: artifact.moduleData!.isHeading,
    };
    return this.tenantArtifactService.artifactControllerModuleHeading({ body: moduleHeadingDto });
  }

  updateDataAfterDragAndDrop$(artifact: NewArtifact, previousArtifact: NewArtifact): Observable<ArtifactResponseDto> {
    const insertDto: ArtifactModuleInsertRequestDto = {
      id: artifact.id,
      moduleId: artifact.moduleData!.moduleId,
      parentArtifactId: previousArtifact?.id || null,
      into: !artifact.moduleData!.isHeading && previousArtifact?.moduleData?.isHeading,
    };

    return this.tenantArtifactService.artifactControllerModuleInsert({ body: insertDto });
  }
}
