import { Injectable } from '@angular/core';
import { FILTER_OPERATOR_AND } from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import {
  GetDateMinusDays,
  GetDatePlusDays,
  GetEndOfTheDay,
  GetEndOfTheMinute,
  GetFirstDayOfCurrentMonth,
  GetFirstDayOfCurrentYear,
  GetFirstDayOfLastMonth,
  GetFirstDayOfLastYear,
  GetFirstDayOfNextMonth,
  GetFirstDayOfNextYear,
  GetLastDayOfCurrentMonth,
  GetLastDayOfCurrentYear,
  GetLastDayOfLastMonth,
  GetLastDayOfLastYear,
  GetLastDayOfMonthAfterMonths,
  GetLastDayOfNextMonth,
  GetLastDayOfNextYear,
  GetLastMondayDate,
  GetLastSundayDate,
  GetMondayOfCurrentWeekDate,
  GetNextMondayDate,
  GetNextSundayDate,
  GetStartOfTheDay,
  GetStartOfTheMinute,
  GetSundayOfCurrentWeekDate,
} from '@shared/methods/date.methods';
import { CustomDateSettings, DateFilterEnum, DateRangeFilterEnum, StartEndDate } from '@shared/types/filter.types';
import { FilterMetadata } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class FilterMetadataUtil {
  transformDateRangeEnumToDateRange(dateRange: DateRangeFilterEnum, customSettings: CustomDateSettings): StartEndDate {
    switch (dateRange) {
      case DateRangeFilterEnum.dateBetween:
        return {
          start: customSettings.isDateTime ? customSettings.start : GetStartOfTheDay(customSettings.start),
          end: customSettings.isDateTime ? customSettings.end : GetEndOfTheDay(customSettings.end),
        };
      case DateRangeFilterEnum.custom:
        return { start: customSettings.start, end: customSettings.end };
      case DateRangeFilterEnum.dueInDays:
        return this.getDueInDaysStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.dueInDaysOrLess:
        return this.getDueInDaysOrLessStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.dueInDaysOrMore:
        return this.getDueInDaysOrMoreStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDays:
        return this.getAgeInDaysStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDaysOrLess:
        return this.getAgeInDaysOrLessStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDaysOrMore:
        return this.getAgeInDaysOrMoreStartEndDate(customSettings.offsetInDays);
      case DateRangeFilterEnum.yesterday:
        return this.getStartEndDateFromDate(GetDateMinusDays(new Date(), 1));
      case DateRangeFilterEnum.today:
        return this.getStartEndDateFromDate(new Date());
      case DateRangeFilterEnum.tomorrow:
        return this.getStartEndDateFromDate(GetDatePlusDays(new Date(), 1));
      case DateRangeFilterEnum.afterToday:
        return this.getAfterToday();
      case DateRangeFilterEnum.beforeToday:
        return this.getBeforeToday();
      case DateRangeFilterEnum.todayOrAfter:
        return this.getTodayOrAfter();
      case DateRangeFilterEnum.todayOrBefore:
        return this.getTodayOrBefore();
      case DateRangeFilterEnum.lastWeek:
        return this.getLastWeekStartEndDate();
      case DateRangeFilterEnum.currentWeek:
        return this.getCurrentWeekStartEndDate();
      case DateRangeFilterEnum.nextWeek:
        return this.getNextWeekStartEndDate();
      case DateRangeFilterEnum.lastMonth:
        return this.getLastMonthStartEndDate();
      case DateRangeFilterEnum.currentMonth:
        return this.getCurrentMonthStartEndDate();
      case DateRangeFilterEnum.nextMonth:
        return this.getNextMonthStartEndDate();
      case DateRangeFilterEnum.lastYear:
        return this.getLastYearStartEndDate();
      case DateRangeFilterEnum.currentYear:
        return this.getCurrentYearStartEndDate();
      case DateRangeFilterEnum.nextYear:
        return this.getNextYearStartEndDate();
      case DateRangeFilterEnum.last7Days:
        return this.getLastDaysStartEndDate(7);
      case DateRangeFilterEnum.last30Days:
        return this.getLastDaysStartEndDate(30);
      case DateRangeFilterEnum.last60Days:
        return this.getLastDaysStartEndDate(60);
      case DateRangeFilterEnum.last90Days:
        return this.getLastDaysStartEndDate(90);
      case DateRangeFilterEnum.last120Days:
        return this.getLastDaysStartEndDate(120);
      case DateRangeFilterEnum.next6Months:
        return this.getNextMonthsStartEndDate(6);
      case DateRangeFilterEnum.next12Months:
        return this.getNextMonthsStartEndDate(12);
      default:
        return { start: new Date(), end: new Date() };
    }
  }

  getFilterMetadataFromStartEndDate(startEndDate: StartEndDate): FilterMetadata[] {
    return [
      this.getSpecificDateFilter(startEndDate.start, CoreListFilterEnum.dateAfterOrEqualTo),
      this.getSpecificDateFilter(startEndDate.end, CoreListFilterEnum.dateBeforeOrEqualTo),
    ];
  }

  getStartEndDateFromDate(date: Date): StartEndDate {
    return { start: GetStartOfTheDay(date), end: GetEndOfTheDay(date) };
  }

  getStartEndDateOfExactMinute(date: Date): StartEndDate {
    return { start: GetStartOfTheMinute(date), end: GetEndOfTheMinute(date) };
  }

  isFilterRange(dateRange: DateRangeFilterEnum): boolean {
    return dateRange === DateRangeFilterEnum.dateBetween;
  }

  isFilterNumeric(dateRange: DateRangeFilterEnum): boolean {
    const { dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess } = DateRangeFilterEnum;
    return [dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess].includes(dateRange);
  }

  isDateRangeAutomaticallyComputed(filterType: DateRangeFilterEnum | DateFilterEnum | undefined): boolean {
    const { dateBetween, custom, dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess } = DateRangeFilterEnum;
    return (
      !!filterType &&
      !Object.prototype.hasOwnProperty.call(DateFilterEnum, filterType) &&
      ![dateBetween, custom, dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess].includes(
        filterType as DateRangeFilterEnum,
      )
    );
  }

  isFilterValidAndActive(value: any, filterType: DateRangeFilterEnum | DateFilterEnum | undefined): boolean {
    if (!this.isDateRangeAutomaticallyComputed(filterType)) {
      return !!value;
    }
    return !!filterType;
  }

  transformStartEndDateToMongoQuery({ start, end }: StartEndDate, attributeKey: string, useIsoDate: boolean): any {
    const query: { $and: any[] } = { $and: [] };

    if (start) {
      query.$and.push({ [attributeKey]: { $gte: useIsoDate ? { $date: start } : start } });
    }

    if (end) {
      query.$and.push({ [attributeKey]: { $lte: useIsoDate ? { $date: end } : end } });
    }

    return query;
  }

  private getDueInDaysStartEndDate(offsetInDays: number): StartEndDate {
    return this.getStartEndDateFromDate(GetDatePlusDays(new Date(), offsetInDays));
  }

  private getDueInDaysOrLessStartEndDate(offsetInDays: number): StartEndDate {
    return { start: GetStartOfTheDay(new Date()), end: GetEndOfTheDay(GetDatePlusDays(new Date(), offsetInDays)) };
  }

  private getDueInDaysOrMoreStartEndDate(offsetInDays: number): StartEndDate {
    return { start: GetStartOfTheDay(GetDatePlusDays(new Date(), offsetInDays)), end: null as any };
  }

  private getAgeInDaysStartEndDate(offsetInDays: number): StartEndDate {
    return this.getStartEndDateFromDate(GetDateMinusDays(new Date(), offsetInDays));
  }

  private getAgeInDaysOrLessStartEndDate(offsetInDays: number): StartEndDate {
    return { start: GetStartOfTheDay(GetDateMinusDays(new Date(), offsetInDays)), end: null as any };
  }

  private getAgeInDaysOrMoreStartEndDate(offsetInDays: number): StartEndDate {
    return { start: null as any, end: GetEndOfTheDay(GetDateMinusDays(new Date(), offsetInDays)) };
  }

  private getLastWeekStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetLastMondayDate()), end: GetEndOfTheDay(GetLastSundayDate()) };
  }

  private getCurrentWeekStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetMondayOfCurrentWeekDate()), end: GetEndOfTheDay(GetSundayOfCurrentWeekDate()) };
  }

  private getNextWeekStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetNextMondayDate()), end: GetEndOfTheDay(GetNextSundayDate()) };
  }

  private getLastMonthStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfLastMonth()), end: GetEndOfTheDay(GetLastDayOfLastMonth()) };
  }

  private getCurrentMonthStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfCurrentMonth()), end: GetEndOfTheDay(GetLastDayOfCurrentMonth()) };
  }

  private getNextMonthStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfNextMonth()), end: GetEndOfTheDay(GetLastDayOfNextMonth()) };
  }

  private getLastYearStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfLastYear()), end: GetEndOfTheDay(GetLastDayOfLastYear()) };
  }

  private getCurrentYearStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfCurrentYear()), end: GetEndOfTheDay(GetLastDayOfCurrentYear()) };
  }

  private getNextYearStartEndDate(): StartEndDate {
    return { start: GetStartOfTheDay(GetFirstDayOfNextYear()), end: GetEndOfTheDay(GetLastDayOfNextYear()) };
  }

  private getLastDaysStartEndDate(numberOfDays: number): StartEndDate {
    return { start: GetStartOfTheDay(GetDateMinusDays(new Date(), numberOfDays - 1)), end: GetEndOfTheDay(new Date()) };
  }

  private getNextMonthsStartEndDate(numberOfMonths: number): StartEndDate {
    return { start: GetStartOfTheDay(new Date()), end: GetEndOfTheDay(GetLastDayOfMonthAfterMonths(numberOfMonths)) };
  }

  private getSpecificDateFilter(value: Date | undefined, matchMode: CoreListFilterEnum, operator = FILTER_OPERATOR_AND): FilterMetadata {
    return { value: value || null, matchMode, operator };
  }

  private getAfterToday(): StartEndDate {
    return { start: GetStartOfTheDay(GetDatePlusDays(new Date(), 1)), end: null as any };
  }

  private getBeforeToday(): StartEndDate {
    return { start: null as any, end: GetEndOfTheDay(GetDateMinusDays(new Date(), 1)) };
  }

  private getTodayOrAfter(): StartEndDate {
    return { start: GetStartOfTheDay(new Date()), end: null as any };
  }

  private getTodayOrBefore(): StartEndDate {
    return { start: null as any, end: GetEndOfTheDay(new Date()) };
  }
}
