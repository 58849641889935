<h5>{{'Overflow' | translate}}</h5>
<div class="grid mt-2">
  <div class="col-6">
    <h6>{{'Vertical' | translate}}</h6>
    <p-dropdown [(ngModel)]="styles.overflowY" [options]="styleOptions.overflow"></p-dropdown>
  </div>

  <div class="col-6">
    <h6>{{'Horizontal' | translate}}</h6>
    <p-dropdown [(ngModel)]="styles.overflowX" [options]="styleOptions.overflow"></p-dropdown>
  </div>
</div>
