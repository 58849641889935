import { Component, Input } from '@angular/core';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetStyleCopyPastingService } from '@widgets/artifact-widget/services/artifact-widget-style-copy-pasting.service';
import { ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';

@Component({
  selector: 'app-artifact-widget-form-item-settings',
  templateUrl: './artifact-widget-form-item-settings.component.html',
  styleUrls: ['./artifact-widget-form-item-settings.component.scss'],
})
export class ArtifactWidgetFormItemSettingsComponent {
  @Input() hash: string;
  @Input() model: ArtifactWidgetModel;

  constructor(public readonly artifactWidgetHelper: ArtifactWidgetHelper, public readonly copyService: ArtifactWidgetStyleCopyPastingService) {}

  onSelectedItemChange(option: SelectOption<string, NewClientAttribute | LinkType>): void {
    this.model.isAttributeSelected = option.value instanceof NewClientAttribute;
  }
}
