import { Component, Input } from '@angular/core';
import { ApexChartWidgetModel, ApexChartWidgetTypeChart } from '@widgets/apex-chart/types/apex-chart-widget.types';
import { ApexChartWidgetService } from '@widgets/apex-chart/services/apex-chart-widget.service';
import { ApexChartType } from '@widgets/apex-chart/types/chart.types';

@Component({
  selector: 'app-apex-chart-widget-settings',
  templateUrl: './apex-chart-widget-settings.component.html',
  styleUrls: ['./apex-chart-widget-settings.component.scss'],
})
export class ApexChartWidgetSettingsComponent {
  @Input() m: ApexChartWidgetModel;
  @Input() s: ApexChartWidgetService;

  isBarChart(chart: ApexChartType): boolean {
    return chart.type === ApexChartWidgetTypeChart.bar;
  }

  onGridPositionChange(): void {
    this.m.settings.grid.position = this.m.settings.gridPosition.value as any;
  }
}
