import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { TimeFilterRuleType } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';

@Pipe({ name: 'isDatetimeFilterCalendarType' })
export class IsDatetimeFilterCalendarTypePipe implements PipeTransform {
  transform(filterType: DateRangeFilterEnum | DateFilterEnum | TimeFilterRuleType): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateBeforeOrEqualTo, dateAfterOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateBeforeOrEqualTo, dateAfterOrEqualTo].includes(filterType as DateFilterEnum);
  }
}
