import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonModule, RippleModule, TranslateModule],
  selector: 'app-attributes-form-buttons',
  templateUrl: './attributes-form-buttons.component.html',
  styleUrls: ['./attributes-form-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesFormButtonsComponent {
  @Input() saveDisabled = true;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();
}
