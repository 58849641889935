import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ArtifactFormatModuleDataResponseDto } from '@api/models/artifact-format-module-data-response-dto';
import { ArtifactLinkCreateRequestDto } from '@api/models/artifact-link-create-request-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { FolderResponseDto } from '@api/models/folder-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DisplayAtUtilService } from '@shared/components/common-display-at/index';
import { SharedMethods } from '@shared/methods/shared.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { DATUserSubscribeLayoutVariant, DEFAULT_VARIANT_KEY, DisplayAtDropdownItem, DisplayAttributeType } from '@shared/types/display-at-types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetSettings } from '@widgets/artifact-widget/types/artifact-widget-settings.types';
import { ArtifactUrlModuleParams, ArtifactWidgetModel, SetDisplayVariantsMetaDataArgs } from '@widgets/artifact-widget/types/artifact-widget.types';
import { AttributeFormatSettings, LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ArtifactWidgetHelper {
  constructor(
    private readonly announcement: AnnouncementService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantFolderService: TenantFolderService,
    private readonly displayAtUtilService: DisplayAtUtilService,
  ) {}

  setDisplayVariantsMetaData(args: SetDisplayVariantsMetaDataArgs): SelectOption<string, string>[] | undefined {
    const { isDate, isDateTime, attributes, attributeId, formatSettings, dataType } = args;

    const isSystemUser = NonAttributeKeys.isUserSpecificAttributeKeyOrId(attributeId);
    const isSystemDate = NonAttributeKeys.isDateSpecificAttributeId(attributeId);
    const attribute = (!isDateTime && !isSystemDate && !isSystemUser && attributes.listMap[attributeId]) || undefined;
    const attributeType = isSystemDate
      ? DisplayAttributeType.systemDate
      : isDateTime || isDate
      ? DisplayAttributeType.dateTime
      : (!isSystemUser && this.displayAtUtilService.fromAttributeAndDataType(attribute as NewAttribute, dataType)) || DisplayAttributeType.user;
    const displayAttributeTypeEnumObject = this.displayAtUtilService.getDisplayAtEnumObjectForType(attributeType);

    let attributeDisplayVariantOptions: SelectOption<string, string>[] | undefined = this.toSelectOptions(
      this.displayAtUtilService.getDisplayAtDropdownItems(displayAttributeTypeEnumObject).filter(({ code }: DisplayAtDropdownItem) => code !== 'DROPDOWN'),
    );

    if (attributeType === DisplayAttributeType.user && !isDateTime && !isDate && !isSystemDate && !isSystemUser && attribute?.multipleValues) {
      const userSubscribeOptions = this.toSelectOptions(this.displayAtUtilService.getDisplayAtDropdownItems(DATUserSubscribeLayoutVariant));
      attributeDisplayVariantOptions = [...attributeDisplayVariantOptions, ...userSubscribeOptions];
    }

    formatSettings.value.displayMetadata ??= {
      attributeType,
      selectedVariantCode: DEFAULT_VARIANT_KEY,
    };

    return attributeDisplayVariantOptions;
  }

  toSelectOptions(displayAtDropdownItems: DisplayAtDropdownItem[]): SelectOption<string, string>[] {
    return displayAtDropdownItems.map(item => new SelectOption<string, string>(item.label, item.code));
  }

  setAllAttributesEditable(model: ArtifactWidgetModel, editable: boolean): void {
    Object.keys(model.formatsMap.attribute).forEach(
      attrId => this.canEditableSettingBeChanged(model, attrId) && (model.formatsMap.attribute[attrId].editable = editable),
    );
    model.setNoItemEditableFlag(!editable);
  }

  shouldAutoSave(model: ArtifactWidgetModel): boolean {
    return model.settings.automaticSave && model.settings.urlChangeAction && this.isEditMode(model);
  }

  isEditMode(model: ArtifactWidgetModel): boolean {
    return !!model.artifactId;
  }

  shouldClearFormOnBlur(model: ArtifactWidgetModel): boolean {
    return !model.isFormFocus && !this.isEditMode(model) && model.settings.clearFormOnCreation && model.settings.clearFormOnBlur;
  }

  getCurrentFormItems(model: ArtifactWidgetModel): SelectOption<string, NewClientAttribute | LinkType>[] {
    return model.form.filter(option => option.value).map(option => option.value) as SelectOption<string, NewClientAttribute | LinkType>[];
  }

  createNewFormatSettings(model: ArtifactWidgetModel, attributeIds: string[], linkTypeIds: string[]): void {
    attributeIds.forEach(id => {
      if (!model.formatsMap.attribute[id]) {
        // if dataTypeId undefined then it's custom attribute and set is as not editable except folderPath
        const dataTypeId = model.options.attributes.listMap[id]?.dataTypeId;
        let editable = dataTypeId ? model.options.dataTypes.listMap[dataTypeId].kind !== DataTypeKind.counter : false;
        id === NonAttributeKeys.FOLDER_PATH_ID && (editable = true);
        model.formatsMap.attribute[id] = new AttributeFormatSettings({ editable });

        const attribute = model.options.attributes.listMap[id];
        if (!attribute) return;
        const dataType = model.options.dataTypes.listMap[attribute.dataTypeId];
        this.setDisplayVariantsMetaData({
          formatSettings: model.formatsMap.attribute[id],
          dataType,
          attributes: model.options.attributes,
          attributeId: id,
          isDate: dataType?.isDate,
          isDateTime: dataType.isDateTime,
        });
      }
    });
    linkTypeIds.forEach(id => !model.formatsMap.linkType[id] && (model.formatsMap.linkType[id] = new LinkTypeFormatSettings()));
  }

  addAttribute(form: ArtifactWidgetFormItem[]): void {
    form.push(new ArtifactWidgetFormItem());
  }

  removeField(model: ArtifactWidgetModel, itemIndex: number): void {
    this.setFormRequiredItemRemoveWarning(model, itemIndex);
    model.form = model.form.filter((_, i) => i !== itemIndex);
    this.onFormItemChange(model);
    model.updateNoAttributeEditableFlag();
  }

  setFormRequiredItemRemoveWarning(model: ArtifactWidgetModel, itemIndex: number): void {
    let fields = '';
    let hasMandatoryValues = false;

    if (model.form[itemIndex].linkType && model.form[itemIndex].isLinkTypeRequired) {
      fields = model.form[itemIndex].value.label;
      hasMandatoryValues = true;
    } else if (model.form[itemIndex].attribute && model.form[itemIndex].attribute?.value.isMandatory) {
      fields = model.form[itemIndex].value.label;
      hasMandatoryValues = true;
    }

    hasMandatoryValues && this.announcement.warn(`{{fields}} marked as required in artifact type`, { fields });
  }

  onFormItemChange(model: ArtifactWidgetModel, params?: { option: SelectOption<string, NewClientAttribute | LinkType>; index: number }): void {
    if (params) {
      const { option, index } = params;

      if (option.value instanceof NewClientAttribute) {
        model.form[index].attribute = option as any;
        model.form[index].linkType = null;
      } else {
        model.form[index].attribute = null;
        model.form[index].linkType = option as any;
      }
    }

    const currentAttributeIds: string[] = [];
    const currentLinkTypeIds: string[] = [];

    model.options.currentFormItem = this.getCurrentFormItems(model);
    model.options.currentFormItem.forEach(item => {
      if (item.value instanceof LinkType) currentLinkTypeIds.push(item.value.id + '_' + item.meta);
      else currentAttributeIds.push(item.value.id);
    });

    this.createNewFormatSettings(model, currentAttributeIds, currentLinkTypeIds);
    this.updateAttributeDisabilityOptions(model);
  }

  async setEmittingKeysToUrl(settings: ArtifactWidgetSettings, artifactDto: ArtifactResponseDto, params: Params): Promise<void> {
    const queryParams = cloneDeep(params);

    if (settings.addCreatedArtifactIdToUrlParam) {
      queryParams[settings.urlKeys.emittingKeys.moduleId] = artifactDto.id;
    }

    if (settings.addCreatedFolderIdToUrlParam && (artifactDto.formatData as ArtifactFormatModuleDataResponseDto)?.folderId) {
      queryParams[settings.urlKeys.emittingKeys.folderId] = (artifactDto.formatData as ArtifactFormatModuleDataResponseDto).folderId;
    }

    await this.router.navigate([], { queryParams, relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  updateIsNoTabKeyActiveInUrl(model: ArtifactWidgetModel): void {
    const artifactTabKeys = Array.from(model.form.reduce((tabKeys, item) => tabKeys.add(item.tabSettings.tabKey), new Set<string>()));
    model.isNoTabKeyActiveInUrl = artifactTabKeys.every(key => !key || !model.queryParams[key]);
  }

  async insertModuleDataToArtifact(artifactLinkCreateRequestDto: ArtifactLinkCreateRequestDto, parentId: string, m: ArtifactWidgetModel): Promise<void> {
    const parentFolder = await lastValueFrom(this.tenantFolderService.folderControllerGet({ id: parentId }));
    if (!parentFolder || !parentFolder.moduleId) return;

    const { parentArtifactId, into, isHeading } = await this.getArtifactModuleParameters(parentFolder, m);

    artifactLinkCreateRequestDto.moduleData = {
      parentArtifactId: parentArtifactId === 'null' ? null : parentArtifactId,
      into,
      isHeading,
    };
  }

  isAttributeEditable(model: ArtifactWidgetModel, attrId: string): boolean {
    const attribute = model.options.attributes.listMap[attrId];
    const dataType = model.options.dataTypes.listMap[attribute?.dataTypeId];
    return dataType?.kind !== DataTypeKind.counter;
  }

  private async getArtifactModuleParameters(parentFolder: FolderResponseDto, m: ArtifactWidgetModel): Promise<ArtifactUrlModuleParams> {
    const { moduleInsertIsHeading, moduleInsertParentId, moduleInsertInto } = m.settings.urlKeys.listeningKeys;

    const parentArtifact = await this.findLastTopArtifact(parentFolder.moduleId!);
    const parentArtifactId = SharedMethods.isQueryParamPresent(moduleInsertParentId, m.queryParams)
      ? SharedMethods.parseQueryParam(m.queryParams[moduleInsertParentId])
      : parentArtifact?.id || null;

    const isHeading = SharedMethods.parseQueryParam(m.queryParams[moduleInsertIsHeading]);
    const into = SharedMethods.isQueryParamPresent(moduleInsertInto, m.queryParams)
      ? SharedMethods.parseQueryParam(m.queryParams[moduleInsertInto])
      : !!parentArtifact?.moduleData?.isHeading;

    return { into, isHeading, parentArtifactId };
  }

  private async findLastTopArtifact(moduleId: string): Promise<ArtifactResponseDto | null> {
    const filter = JSON.stringify({ [NonAttributeKeys.MODULE]: { $eq: { $oid: moduleId } } });
    const sort = JSON.stringify({ [NonAttributeKeys.SECTION]: -1 });

    const latestArtifact = (await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter, sort, limit: 1 } }))).data || [];
    return latestArtifact[0] || null;
  }

  private updateAttributeDisabilityOptions(model: ArtifactWidgetModel): void {
    Object.keys(model.options.alreadyUsedFormItemsMap).forEach(id => (model.options.alreadyUsedFormItemsMap[id] = false));
    model.form
      .filter((item: ArtifactWidgetFormItem) => item.attribute || item.linkType)
      .forEach(item => {
        item.attribute && (item.attribute.disabled = true);
        item.linkType && (item.linkType.disabled = true);
        const mapKey: string = item.value.meta && item.linkType ? item.linkType.value.id + '_' + item.linkType.meta : item.attribute?.value?.id || '';
        model.options.alreadyUsedFormItemsMap[mapKey] = true;
      });
    model.options.linkTypeOptions.forEach(option => (option.disabled = model.options.alreadyUsedFormItemsMap[option.value.id + '_' + option.meta]));
    model.options.clientAttributes.forEach(option => (option.disabled = model.options.alreadyUsedFormItemsMap[option.value.id]));
  }

  private canEditableSettingBeChanged(model: ArtifactWidgetModel, attrId: string): boolean {
    return this.isAttributeEditable(model, attrId);
  }
}
