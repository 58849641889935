import { WorkflowTriggerDto } from '@api/models';
import { SharedMethods } from '@shared/methods/shared.methods';
import { Constructor } from '@shared/types/constructor.types';
import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';

export abstract class AbstractWorkflowTrigger<T extends WorkflowTriggerDto['triggerSettings']> implements WorkflowTrigger {
  id: string;
  type: RuleTriggerType;
  triggerSettings: T;
  artifactTypeId?: string;
  attributeId?: string;
  widgetId?: string;
  elementId?: string;

  protected artifactTypeRequired: boolean;
  protected attributeRequired: boolean;
  protected widgetRequired: boolean;
  protected elementRequired: boolean;

  constructor(triggerSettingDto: Constructor<T>, dto?: WorkflowTriggerDto) {
    this.id = SharedMethods.getUniqueId(4);
    dto && Object.assign(this, dto);
    this.triggerSettings = new triggerSettingDto(dto?.triggerSettings);
  }

  abstract isMatchingWorkflowTrigger(workflow: WorkflowTrigger): boolean;

  abstract isValid(): boolean;

  isArtifactTypeRequired(): boolean {
    return this.artifactTypeRequired;
  }

  isExternalArtifactTypeRequired() {
    return false;
  }

  isAttributeRequired(): boolean {
    return this.attributeRequired;
  }

  isWidgetRequired(): boolean {
    return this.widgetRequired;
  }

  isElementRequired(): boolean {
    return this.elementRequired;
  }

  toServer(): WorkflowTriggerDto {
    return {
      type: this.type,
      artifactTypeId: this.artifactTypeId,
      attributeId: this.attributeId,
      widgetId: this.widgetId,
      elementId: this.elementId,
      triggerSettings: this.triggerSettings,
    };
  }

  fromDto(dto: WorkflowTriggerDto): void {
    dto && Object.assign(this, dto);
  }
}
