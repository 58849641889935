<h5>Divider style</h5>

<div class="row">
  <div class="col-4">
    <h6> {{'Height'| translate}} </h6>
    <input id="height" class="w-100" [(ngModel)]="dividerStyle.height" pInputText type="text"/>
  </div>

  <div class="col-4">
    <h6> {{'Position'| translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.menuItemVerticalAlign" [(ngModel)]="dividerStyle.alignSelf" id="alignSelfStyle">
      <ng-template let-option pTemplate="item">{{ option.label | translate }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.label | translate }}</ng-template>
    </p-dropdown>
  </div>

  <div class="col-4">
    <h6> {{'Margin left'| translate}} </h6>
    <input id="marginLeft" class="w-100" [(ngModel)]="dividerStyle.marginLeft" pInputText type="text"/>
  </div>
</div>

<div class="row">
  <div class="col-4">
    <h6> {{'Margin right'| translate}} </h6>
    <input id="marginRight" class="w-100" [(ngModel)]="dividerStyle.marginRight" pInputText type="text"/>
  </div>

  <div class="col-4 mb-0">
    <app-color-picker [(color)]="dividerStyle.borderLeftColor" label="{{'Color'| translate}}"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <h6> {{'Style'| translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.borderStyles" [(ngModel)]="dividerStyle.borderLeftStyle" id="borderLeftStyle"></p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-4 mb-0">
    <h6> {{'Thickness'| translate}} </h6>
    <p-dropdown [autoDisplayFirst]="false" [options]="options.borderWidth" [(ngModel)]="dividerStyle.borderLeftWidth" id="borderLeftWidth"></p-dropdown>
  </div>
</div>
