import { Component, Input } from '@angular/core';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';

@Component({
  selector: 'app-menu-settings-form',
  templateUrl: './menu-settings-form.component.html',
  styleUrls: ['./menu-settings-form.component.scss'],
})
export class MenuSettingsFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;

  constructor(public readonly menuWidgetHelper: MenuWidgetHelper) {}
}
