import { DateFilterEnum, DateRangeFilterEnum, FilterOperatorEnum } from '@shared/types/filter.types';

export class AdvancedDateFilter {
  filterType?: DateRangeFilterEnum | DateFilterEnum;
  value?: Date | Date[] | null[] | string;

  constructor(params?: Partial<AdvancedDateFilter>) {
    params && Object.assign(this, params);
  }
}

export class AdvancedDateFilterObject {
  filters: AdvancedDateFilter[] = [new AdvancedDateFilter()];
  operator: FilterOperatorEnum = FilterOperatorEnum.and;

  constructor(params?: Partial<AdvancedDateFilterObject>) {
    params && Object.assign(this, params);
  }
}
