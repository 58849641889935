import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetDataTypeFromAttributeIdPipe } from './get-data-type-from-attribute-id.pipe';
import { GetDataTypeValueResponseByIdPipe } from './get-data-type-value-response-by-id.pipe';
import { GetEnumOptionsFromDataTypeValuesPipe } from './get-enum-options-from-data-type-values.pipe';
import { IsBooleanPipe } from './is-boolean.pipe';
import { IsBoundedPipe } from './is-bounded.pipe';
import { IsCounterPipe } from './is-counter.pipe';
import { IsDateOrTimePipe } from './is-date-or-time.pipe';
import { IsDateTimePipe } from './is-date-time.pipe';
import { IsDatePipe } from './is-date.pipe';
import { IsDecimalPipe } from './is-decimal.pipe';
import { IsEnumeratedPipe } from './is-enumerated.pipe';
import { IsFilePipe } from './is-file.pipe';
import { IsHtmlPipe } from './is-html.pipe';
import { IsHyperlinkPipe } from './is-hyperlink.pipe';
import { IsIntegerPipe } from './is-integer.pipe';
import { IsSimplePipe } from './is-simple.pipe';
import { IsSystemUserPipe } from './is-system-user.pipe';
import { IsTextPipe } from './is-text.pipe';
import { IsTimePipe } from './is-time.pipe';
import { IsUserPipe } from './is-user.pipe';
import { ToSystemUserKeyPipe } from './to-system-user-key.pipe';
import { IsSystemDatePipe } from '@shared/pipes/data-type-pipes/is-system-date.pipe';

const pipes = [
  IsSimplePipe,
  IsBoundedPipe,
  IsEnumeratedPipe,
  IsDatePipe,
  IsDateTimePipe,
  IsBooleanPipe,
  IsFilePipe,
  IsIntegerPipe,
  IsDecimalPipe,
  IsHyperlinkPipe,
  IsSystemDatePipe,
  IsTextPipe,
  IsHtmlPipe,
  IsSystemUserPipe,
  ToSystemUserKeyPipe,
  IsUserPipe,
  GetDataTypeValueResponseByIdPipe,
  GetEnumOptionsFromDataTypeValuesPipe,
];

@NgModule({
  declarations: [...pipes, IsTimePipe, IsDateOrTimePipe, IsCounterPipe, GetDataTypeFromAttributeIdPipe],
  imports: [CommonModule],
  exports: [...pipes, IsTimePipe, IsDateOrTimePipe, IsCounterPipe, GetDataTypeFromAttributeIdPipe],
})
export class DataTypePipesModule {}
