import { Styles } from '@private/pages/page-management/page-builder-graphical/types/styles';
import { URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';
import { ListMatrixWidgetSettingsDto } from '@widgets/list-matrix-widget/types/list-matrix-widget-settings-dto';
import { ListMatrixWidgetStylesDto } from '@widgets/list-matrix-widget/types/list-matrix-widget-styles-dto';
import { ListOrientation } from '@widgets/list-matrix-widget/types/list-orientation';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ClickActionSettings } from '@widgets/shared/types/click-action-settings';
import { cloneDeep } from 'lodash';

export const INITIAL_SETTINGS: ListMatrixWidgetSettingsDto = {
  list: {
    artifactTypeId: '',
    caption: '',
    summary: '',
    emptyMessage: 'No records found',
    cardsPerPage: 4,
    columnsCount: 3,
    styles: new Styles({
      columnGap: '10px',
      rowGap: '10px',
    }),
    doFilterOnUrlChange: true,
    doSortOnUrlChange: true,
    orientation: ListOrientation.horizontal,
  },
  card: {
    templateId: null,
    widgetId: null,
  },
  listItemClickAction: {
    isHandled: false,
    actionType: ArtifactListItemClickAction.addToLink,
    emittingKey: URL_KEY_VALUE_ARTIFACT_ID,
    selectedPage: null,
    externalPage: '',
    openInNewTab: false,
    filtersForQueryParams: [],
  },
};

export class ListMatrixWidgetSettings implements ListMatrixWidgetSettingsDto {
  list: {
    paginator: boolean;
    rows: number;
    caption: string;
    summary: string;
    emptyMessage: string;
    cardsPerPage: number;
    columnsCount: number;
    artifactTypeId: string;
    filterBy: string;
    sortField: string;
    sortOrder: number;
    lazy: boolean;
    doFilterOnUrlChange?: boolean;
    doSortOnUrlChange?: boolean;
    styles: ListMatrixWidgetStylesDto;
    orientation: ListOrientation;
  };
  card: {
    templateId: string | null;
    widgetId: string | null;
  };
  listItemClickAction: ClickActionSettings;

  constructor(dto: ListMatrixWidgetSettingsDto = INITIAL_SETTINGS) {
    Object.assign(this, cloneDeep(dto));
  }

  toServer(): ListMatrixWidgetSettingsDto {
    const copy = cloneDeep(this);
    const filtersForQueryParams = copy.listItemClickAction.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer());

    return { ...copy, listItemClickAction: { ...copy.listItemClickAction, filtersForQueryParams } };
  }
}
