import { Injectable } from '@angular/core';
import {
  NumberFilterMapUrlRuleType,
  NumberFilterMatchType,
  NumberFilterRuleType,
} from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter-options.types';
import { NumberFilter, NumberFilterRange } from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class NumberFilterService {
  attributeKey: string;
  conversionOperator = 'double';

  getQuery(filter: ArtifactFilter): any {
    this.conversionOperator = filter.dataType?.isInteger ? 'int' : 'double';

    const value: NumberFilter = filter.value as NumberFilter;
    if (!value) {
      return null;
    }

    const sign = value.matchType === NumberFilterMatchType.matchAny ? '$or' : '$and';
    const ruleTypes = value.ruleTypes?.filter(rule => (rule.value || rule.value === 0) && rule.ruleType);
    this.attributeKey = `attributes.${filter.attributeId}.value`;

    // value type check and fix
    ruleTypes.forEach(rule => {
      if (Object.prototype.hasOwnProperty.call(rule.value, 'from')) {
        rule.value = new NumberFilterRange(rule.value as NumberFilterRange);
      }
    });

    return ruleTypes?.length
      ? {
          [sign]: [
            ...ruleTypes.map(rule =>
              rule.value instanceof NumberFilterRange
                ? {
                    $and: [
                      { [this.attributeKey]: { [rule.value.fromIsInclude ? '$gte' : '$gt']: String(rule.value.from) } },
                      { [this.attributeKey]: { [rule.value.fromIsInclude ? '$lte' : '$lt']: String(rule.value.to) } },
                    ],
                  }
                : this.getFilterByRuleType(rule.ruleType, rule.value),
            ),
          ],
        }
      : null;
  }

  getFilterValueFromString(str: string): NumberFilter | null {
    const args = str.split(',');
    const value = parseInt(args[1], 10);
    const key = args[0];
    const ruleType = this.getRuleType(key);

    if (!value || !ruleType) return null;

    const filter = new NumberFilter();
    filter.ruleTypes[0] = { ruleType, value };

    // for between
    if (args.length > 2) {
      const key2 = args[2];
      const value2 = parseInt(args[3], 10);
      const ruleType = this.getRuleType(key2);

      if (ruleType && value2) {
        filter.matchType = NumberFilterMatchType.matchAll;
        filter.ruleTypes.push({ ruleType, value: value2 });
      }
    }

    return filter;
  }

  private getRuleType(key: string): NumberFilterRuleType | null {
    const mappedKey = NumberFilterMapUrlRuleType[key as keyof typeof NumberFilterMapUrlRuleType] || null;
    return NumberFilterRuleType[key as keyof typeof NumberFilterRuleType] || NumberFilterRuleType[mappedKey as keyof typeof NumberFilterRuleType] || null;
  }

  private getFilterByRuleType(type: NumberFilterRuleType, value: number | null): Record<string, any> {
    const convert: any = {
      $convert: {
        input: '$' + this.attributeKey,
        to: this.conversionOperator,
        onError: null,
      },
    };
    const comparisonOperator = this.getComparisonOperator(type);

    return {
      $expr: {
        [comparisonOperator]: [convert, value],
      },
    };
  }

  private getComparisonOperator(type: NumberFilterRuleType): string {
    switch (type) {
      case NumberFilterRuleType.equals:
        return '$eq';
      case NumberFilterRuleType.notEquals:
        return '$ne';
      case NumberFilterRuleType.lessThan:
        return '$lt';
      case NumberFilterRuleType.lessThanOrEqualTo:
        return '$lte';
      case NumberFilterRuleType.greaterThan:
        return '$gt';
      case NumberFilterRuleType.greaterThanOrEqualTo:
        return '$gte';
      default:
        return '$eq';
    }
  }
}
