import { Pipe, PipeTransform } from '@angular/core';
import { FilterType } from '@shared/types/table.types';

@Pipe({ name: 'getFilterType' })
export class GetFilterTypePipe implements PipeTransform {
  transform(filterType: FilterType | null): unknown {
    if (filterType === FilterType.link) return null;
    if (filterType === FilterType.datetime) return FilterType.date;
    if (filterType === FilterType.hyperlink) return FilterType.text;
    return filterType;
  }
}
