import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthWidgetService } from '@widgets/auth-widget/services/auth-widget.service';
import { AuthWidgetModel, AuthWidgetValue } from '@widgets/auth-widget/types/auth-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-auth-widget',
  templateUrl: './auth-widget.component.html',
  styleUrls: ['./auth-widget.component.scss'],
  providers: [AuthWidgetService],
})
export class AuthWidgetComponent extends WidgetsCoreComponent<AuthWidgetValue> implements OnInit {
  m: AuthWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<AuthWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: AuthWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this, this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as any as AuthWidgetModel) : null);
  }
}
