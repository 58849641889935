import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.scss'],
})
export class BooleanFilterComponent {
  @Input() set m(data: ArtifactFilter) {
    !data.value && (data.value = []);
    this.model = data;
    this.selected = Array.isArray(data.value) ? data.value : data.value.split(',').map((val: any) => (val === EMPTY_OPTION_FILTER_URL_VALUE ? '' : val));
  }

  @Input() showClear: boolean;
  @Input() placeholder = 'Select value';

  @Output() onFilterChange = new EventEmitter<any>();
  @Output() onClear: EventEmitter<void> = new EventEmitter<void>();

  model: ArtifactFilter;
  selected: string[] | null;
  booleanFilterSelectOptions: SelectOption<string, string>[];

  constructor(private readonly filterUtil: FilterUtil) {
    this.booleanFilterSelectOptions = this.filterUtil.getBooleanValueFilterSelectOptions(true);
  }

  onChange(e: any): void {
    if (!e.value.length) {
      this.doClear();
      return;
    }

    this.model.value = e.value.map((val: any) => (val === '' ? EMPTY_OPTION_FILTER_URL_VALUE : val)).join(',');
    this.onFilterChange.emit();
  }

  doClear(): void {
    this.model.value = null;
    this.selected = null;
    this.onClear.emit();
  }
}
