/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { ServiceService } from './services/service.service';
import { SystemUserService } from './services/system-user.service';
import { SystemTenantService } from './services/system-tenant.service';
import { SystemSessionService } from './services/system-session.service';
import { SystemJobsService } from './services/system-jobs.service';
import { TenantApplicationService } from './services/tenant-application.service';
import { TenantArtifactService } from './services/tenant-artifact.service';
import { TenantArtifactTypeService } from './services/tenant-artifact-type.service';
import { TenantArtifactHistoryService } from './services/tenant-artifact-history.service';
import { TenantAttributeService } from './services/tenant-attribute.service';
import { TenantDataTypeService } from './services/tenant-data-type.service';
import { TenantFolderService } from './services/tenant-folder.service';
import { TenantLinkService } from './services/tenant-link.service';
import { TenantLinkTypeService } from './services/tenant-link-type.service';
import { TenantPageService } from './services/tenant-page.service';
import { TenantWidgetService } from './services/tenant-widget.service';
import { TenantTemplateService } from './services/tenant-template.service';
import { TenantTenantService } from './services/tenant-tenant.service';
import { TenantOslcService } from './services/tenant-oslc.service';
import { TenantPushNotificationService } from './services/tenant-push-notification.service';
import { TenantSseService } from './services/tenant-sse.service';
import { TenantUserService } from './services/tenant-user.service';
import { TenantTeamService } from './services/tenant-team.service';
import { TenantExportImportService } from './services/tenant-export-import.service';
import { TenantWorkflowService } from './services/tenant-workflow.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiService,
    AuthService,
    ServiceService,
    SystemUserService,
    SystemTenantService,
    SystemSessionService,
    SystemJobsService,
    TenantApplicationService,
    TenantArtifactService,
    TenantArtifactTypeService,
    TenantArtifactHistoryService,
    TenantAttributeService,
    TenantDataTypeService,
    TenantFolderService,
    TenantLinkService,
    TenantLinkTypeService,
    TenantPageService,
    TenantWidgetService,
    TenantTemplateService,
    TenantTenantService,
    TenantOslcService,
    TenantPushNotificationService,
    TenantSseService,
    TenantUserService,
    TenantTeamService,
    TenantExportImportService,
    TenantWorkflowService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
