import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BaseDataType, EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { GridContainer } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { DisplayAtUtilService } from '@shared/components/common-display-at';
import { colors } from '@shared/components/grid-layout-generator/components/grid-layout-preview/colors';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { AnnouncementService } from '@shared/services/announcement.service';
import { WindowResizeService } from '@shared/services/window-resize.service';
import { DEFAULT_VARIANT_KEY } from '@shared/types/display-at-types';
import { CardAreaStylerComponent, INITIAL_ATTRIBUTE_SETTINGS } from '@widgets/card-widget/components/card-area-styler/card-area-styler.component';
import { CardAreaComponent } from '@widgets/card-widget/components/card-area/card-area.component';
import { CardResizeService } from '@widgets/card-widget/services/card-resize.service';
import { CardWidgetEventsService } from '@widgets/card-widget/services/card-widget-events.service';
import { CardWidgetService } from '@widgets/card-widget/services/card-widget.service';
import { DynamicArtifactService } from '@widgets/card-widget/services/dynamic-artifact.service';
import { ListItemService } from '@widgets/card-widget/services/list-item.service';
import { StaticArtifactService } from '@widgets/card-widget/services/static-artifact.service';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetEventType } from '@widgets/card-widget/types/card-widget-event-type';
import { CardWidgetModel, MyArtifact } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetStyles } from '@widgets/card-widget/types/card-widget-styles';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { EnumOptionSettings } from '@widgets/card-widget/types/enum-option-settings';
import { ArtifactClickHandlerService } from '@widgets/shared/services/artifact-click-handler.service';
import { ArtifactAdditionalData } from '@widgets/shared/types/artifact-additional-data';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import {
  APPLICATION_ID,
  ARTIFACT,
  ARTIFACT_ADDITIONAL_DATA,
  HASH,
  IS_CLICKABLE,
  IS_IN_SIDEBAR,
  IS_LAYOUT_MODE,
  IS_LIST_MATRIX_CARD,
  IS_PREVIEW_MODE,
  LABEL,
  WIDGET,
} from '@widgets/widgets-core/constants/widgets-core.constants';
import { ResizeEvent } from 'angular-resizable-element';
import { cloneDeep } from 'lodash';
import { map, merge, Observable, of, startWith, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

const defaultArtifactAdditionalData: ArtifactAdditionalData = {
  links: [],
  linkedArtifacts: [],
  files: [],
};

@Component({
  selector: 'app-card-widget',
  templateUrl: './card-widget.component.html',
  styleUrls: ['./card-widget.component.scss'],
  providers: [CardWidgetService, ListItemService, StaticArtifactService, DynamicArtifactService, CardResizeService],
})
export class CardWidgetComponent extends WidgetsCoreComponent implements OnInit {
  @ViewChildren(CardAreaComponent) cardAreaComponents: QueryList<CardAreaComponent>;

  readonly dataType: typeof BaseDataType = BaseDataType;

  m: CardWidgetModel;

  listItemArtifact: MyArtifact;

  cardWidthInAdvancedMode$: Observable<number>;
  cardStylesForBreakpoint$: Observable<CardWidgetStyles & GridContainer>;
  cardWidthStyle$: Observable<string>;
  breakpointAccordingToMode$: Observable<StyleApplicationBreakpoint>;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public readonly applicationId: string,
    @Inject(WIDGET)
    public readonly widget: BlockPartWidget<{ model: CardWidgetModel }>,
    @Inject(LABEL) public readonly label: string,
    @Inject(IS_LAYOUT_MODE) public readonly isLayoutMode: boolean,
    @Inject(IS_PREVIEW_MODE) public readonly isPreviewMode: boolean,
    @Inject(IS_IN_SIDEBAR) public readonly isInSidebar: boolean,
    @Inject(IS_LIST_MATRIX_CARD) public readonly isListMatrixCard: boolean,
    @Inject(ARTIFACT_ADDITIONAL_DATA) public readonly artifactAdditionalData: ArtifactAdditionalData,
    @Inject(ARTIFACT) public readonly artifact: ArtifactResponseDto,
    @Inject(IS_CLICKABLE) public readonly isClickable: boolean,
    @Inject(HASH) public readonly hash: string,
    protected readonly resizeService: CardResizeService,
    private readonly s: CardWidgetService,
    private readonly eventService: CardWidgetEventsService,
    private readonly artifactClickHandlerService: ArtifactClickHandlerService,
    private readonly displayAtUtilService: DisplayAtUtilService,
    private readonly windowResizeService: WindowResizeService,
  ) {
    super(route, router, announcement, elRef);

    this.artifactAdditionalData ??= cloneDeep(defaultArtifactAdditionalData);
  }

  get isReadyToRender(): boolean {
    return !this.isFirstCall && this.m?.isReady;
  }

  get colors(): string[] {
    return colors;
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this);
    this.m.advancedModeOpen.next(!this.isLayoutMode);

    this.cardWidthInAdvancedMode$ = merge(this.resizeService.cardWidth$, this.m.activeBreakpoint$.pipe(map(({ value }: StyleApplicationBreakpoint) => value)));

    this.updateActiveBreakpointOnCardResize();
    this.updateBreakpointAccordingToMode();
    this.updateCardStylesAccordingToMode();
    this.updateCardWidthAccordingToMode();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    setTimeout(() => {
      this.m.layoutPreviewMode = false;
      this.m.advancedModeOpen.next(false);
    });
  }

  onSizeChange(size: number): void {
    this.resizeService.onSizeInputChange(size);
  }

  onCardResizeEnd({ rectangle: { width } }: ResizeEvent): void {
    this.resizeService.onCardResize(width!);
  }

  onCardResizing({ rectangle: { width } }: ResizeEvent): void {
    this.resizeService.onCardResize(width!);
  }

  onActiveBreakpointChange(index: number): void {
    this.m.setActiveBreakpoint(index);
  }

  async onCardClick($event: MouseEvent): Promise<void> {
    if (this.isClickIrrelevant($event)) {
      return;
    }

    let artifactForNavigation: ArtifactResponseDto | null = null;

    try {
      artifactForNavigation = this.m.artifact;
    } catch (e) {
      const { actionType } = this.m.settings.clickAction;
      if (actionType === ArtifactListItemClickAction.addToLink || actionType === ArtifactListItemClickAction.goToDefaultArtifactPage) {
        await this.announcement.warn('Artifact is absent so you cannot add its id to the link or go to its default page');
      }
    } finally {
      await this.artifactClickHandlerService.handleArtifactClickIfNeeded(artifactForNavigation, this.m.settings.clickAction, this.queryParams);
    }
  }

  downloadFile(file: ArtifactResponseDto): void {
    this.s.downloadFile(file);
  }

  openAttributeSettings(contentItem: CardWidgetAreaContentItem): void {
    this.m.tabState = {
      widgetSettings: false,
      contentStyles: true,
    };

    this.eventService.publish<CardWidgetAreaContentItem>(CardWidgetEventType.openAttributeSettings, contentItem);
  }

  onTabOpen(cardAreaStyler: CardAreaStylerComponent): void {
    cardAreaStyler.reset();
  }

  onWidgetDelete(widget: BlockPartWidget): void {
    if (widget.isDeletable) {
      this.m.widgetsToDelete.push(widget.id!);
    }
  }

  dropIntoArea({ item, container }: CdkDragDrop<GenericArea<CardWidgetAreaContent>>): void {
    const type = item.data.type;
    const linkDirection = item.data.linkDirection;
    const content = type === ContentType.widget ? new BlockPartWidget({ code: item.data.code }) : item.data.content.id;

    container.data.content.items = [...container.data.content.items, new CardWidgetAreaContentItem(type, content, linkDirection)];

    this.setAttributeSettingsIfEmpty(type, content);
  }

  onLayoutUpdate(): void {
    this.m.layoutUpdate.next();
  }

  private isClickIrrelevant($event: MouseEvent): boolean {
    const isAdvancedMode = !this.isLayoutMode;
    const clickIsNotHandled = !this.m.settings.clickAction.isHandled;
    const clickTarget = $event.target as HTMLElement;
    const clickInsideInnerWidgetAdvancedMode = clickTarget.closest('p-sidebar') || clickTarget.closest('.p-dropdown-clear-icon');

    return [isAdvancedMode, clickIsNotHandled, clickInsideInnerWidgetAdvancedMode].some(Boolean);
  }

  private setAttributeSettingsIfEmpty(type: ContentType, content: string): void {
    if (type !== ContentType.widget && !this.m.settings.attributeStyles[content]) {
      const attributeSettings = cloneDeep(INITIAL_ATTRIBUTE_SETTINGS);
      this.m.settings.attributeStyles[content] = attributeSettings;

      const attribute = this.m.options.attributes.listMap[content];
      const dataTypeId = this.m.options.attributes.listMap[content]?.dataTypeId;
      const dataType = this.m.options.dataTypes.listMap[dataTypeId];
      const isSingleFileAttribute = !attribute?.multipleValues && dataType?.isFile;

      if (isSingleFileAttribute) {
        const attributeType = this.displayAtUtilService.fromAttributeAndDataType(attribute, dataType);
        attributeSettings.settings.value.displayMetadata = { attributeType, selectedVariantCode: DEFAULT_VARIANT_KEY };
      }

      if (dataType?.isEnum) {
        this.m.settings.attributeStyles[content].enumAttributeSettings = this.getEnumAttributeSettings(dataType.values || []);
      }
    }
  }

  private getEnumAttributeSettings(enumOptions: EnumeratedOption[] | null): any {
    return enumOptions
      ? enumOptions.reduce(
          (
            acc: {
              [enumValue: string]: EnumOptionSettings;
            },
            option: EnumeratedOption,
          ) => {
            return {
              ...acc,
              [option.value]: {
                isIconVisible: true,
                styles: {
                  color: '',
                },
              },
            };
          },
          {},
        )
      : null;
  }

  private getCardLayoutForBreakpoint(breakpoint: StyleApplicationBreakpoint): GridContainer {
    return {
      gridTemplateColumns: this.m.grid.gridTemplateColumns.get(breakpoint) || '',
      gridTemplateRows: this.m.grid.gridTemplateRows.get(breakpoint) || '',
      columnGap: this.m.grid.columnGap.get(breakpoint) || '',
      rowGap: this.m.grid.rowGap.get(breakpoint) || '',
    };
  }

  private getSmallestMatchingBreakpoint(width: number = this.elRef.nativeElement.offsetWidth): StyleApplicationBreakpoint {
    let matchingBreakpoint = null;

    for (const breakpoint of this.m.breakpoints) {
      if (width <= breakpoint.value) {
        if (matchingBreakpoint === null || breakpoint.value < matchingBreakpoint.value) {
          matchingBreakpoint = breakpoint;
        }
      }
    }

    return matchingBreakpoint!;
  }

  private updateActiveBreakpointOnCardResize(): void {
    if (this.isLayoutMode) {
      this.registerSubscription(
        this.resizeService.cardWidth$
          .pipe(
            tap((width: number) => {
              const breakpoint = this.getSmallestMatchingBreakpoint(width);
              const index = this.m.breakpoints.indexOf(breakpoint);
              this.m.setActiveBreakpoint(index);
            }),
          )
          .subscribe(),
      );
    }
  }

  private updateCardStylesAccordingToMode(): void {
    this.cardStylesForBreakpoint$ = merge(this.m.layoutUpdate, this.m.advancedModeOpen).pipe(
      switchMap(() => this.breakpointAccordingToMode$),
      map((breakpoint: StyleApplicationBreakpoint) => {
        return {
          ...this.m.styles,
          ...this.getCardLayoutForBreakpoint(breakpoint),
        };
      }),
    );
  }

  private updateCardWidthAccordingToMode(): void {
    this.cardWidthStyle$ = this.m.advancedModeOpen.pipe(
      switchMap((advancedMode: boolean) => {
        return advancedMode ? this.cardWidthInAdvancedMode$.pipe(map((width: number) => (width === Infinity ? '' : String(width)))) : of('');
      }),
    );
  }

  private updateBreakpointAccordingToMode(): void {
    const userSetBreakpoint$ = this.m.activeBreakpoint$;

    const actualBreakpoint$ = this.windowResizeService.resize$.pipe(
      map(() => this.getSmallestMatchingBreakpoint()),
      startWith(this.getSmallestMatchingBreakpoint()),
    );

    this.breakpointAccordingToMode$ = this.m.advancedModeOpen.pipe(
      switchMap((advancedMode: boolean) => (advancedMode ? userSetBreakpoint$ : actualBreakpoint$)),
    );
  }
}
