import { Injectable } from '@angular/core';
import {
  AttributeBasedEvent,
  ModifyAttributeValueEvent,
  ModifyAttributeValueEventWrapper,
  PageAttributeChangeEvent,
  SetAttributeMandatoryEvent,
  SetAttributeValueEvent,
  SetAttributeValueEventWrapper,
  SetLinkValueEvent,
  ShowHideAttributeEvent,
} from '@workflows/shared';
import { WorkflowAttributeActionEventType } from '@workflows/types';
import { Observable, ReplaySubject } from 'rxjs';

type WidgetAttributeEventType = Record<string, Record<string, AttributeBasedEvent>>;
type PageEventType = Record<WorkflowAttributeActionEventType, WidgetAttributeEventType>;
type PageAttributeEventType = Record<string, Partial<PageEventType>>;

@Injectable({ providedIn: 'root' })
export class AttributeActionHandlerService {
  static readonly EMPTY_WIDGET = 'NONE';
  private pageAttributeEventMap: PageAttributeEventType = {};
  private pageAttributeEventMapSubject: ReplaySubject<PageAttributeEventType>;
  private pageAttributeEventMapObservable: Observable<PageAttributeEventType>;

  private showHideEventMap: Record<string, ShowHideAttributeEvent>;
  private showHideEventMapSubject: ReplaySubject<Record<string, ShowHideAttributeEvent>>;
  private showHideEventMapObservable: Observable<Record<string, ShowHideAttributeEvent>>;

  // TODO: other event types should be handled similarly, create map to avoid emitting ALL old values (create map of maps - key = eventType)
  private setAttributeValueEventMap: Record<string, SetAttributeValueEvent>;
  private setAttributeValueEventSubject: ReplaySubject<SetAttributeValueEventWrapper>;
  private setAttributeValueEventObservable: Observable<SetAttributeValueEventWrapper>;

  private modifyAttributeValueEventMap: Record<string, ModifyAttributeValueEvent>;
  private modifyAttributeValueEventSubject: ReplaySubject<ModifyAttributeValueEventWrapper>;
  private modifyAttributeValueEventObservable: Observable<ModifyAttributeValueEventWrapper>;

  private setLinkValueEventMap: Record<string, SetLinkValueEvent>;
  private setLinkValueEventMapSubject: ReplaySubject<Record<string, SetLinkValueEvent>>;
  private setLinkValueEventMapObservable: Observable<Record<string, SetLinkValueEvent>>;

  private setAttributeOptionValueEventSubject: ReplaySubject<SetAttributeValueEvent>;
  private setAttributeOptionValueEventObservable: Observable<SetAttributeValueEvent>;

  private setAttributeMandatoryEventMap: Record<string, SetAttributeMandatoryEvent>;
  private AttributeMandatoryEventMapSubject: ReplaySubject<Record<string, SetAttributeMandatoryEvent>>;
  private setMandatoryEventMapObservable: Observable<Record<string, SetAttributeMandatoryEvent>>;

  constructor() {
    this.pageAttributeEventMapSubject = new ReplaySubject(1);
    this.pageAttributeEventMapObservable = this.pageAttributeEventMapSubject.asObservable();

    this.setAttributeValueEventMap = {};
    this.setAttributeValueEventSubject = new ReplaySubject(1);
    this.setAttributeValueEventObservable = this.setAttributeValueEventSubject.asObservable();

    this.modifyAttributeValueEventMap = {};
    this.modifyAttributeValueEventSubject = new ReplaySubject(1);
    this.modifyAttributeValueEventObservable = this.modifyAttributeValueEventSubject.asObservable();

    this.showHideEventMap = {};
    this.showHideEventMapSubject = new ReplaySubject(1);
    this.showHideEventMapObservable = this.showHideEventMapSubject.asObservable();

    this.setLinkValueEventMap = {};
    this.setLinkValueEventMapSubject = new ReplaySubject(1);
    this.setLinkValueEventMapObservable = this.setLinkValueEventMapSubject.asObservable();

    this.setAttributeOptionValueEventSubject = new ReplaySubject(100);
    this.setAttributeOptionValueEventObservable = this.setAttributeOptionValueEventSubject.asObservable();

    this.setAttributeMandatoryEventMap = {};
    this.AttributeMandatoryEventMapSubject = new ReplaySubject(1);
    this.setMandatoryEventMapObservable = this.AttributeMandatoryEventMapSubject.asObservable();
  }

  get showHideEventMap$(): Observable<Record<string, ShowHideAttributeEvent>> {
    return this.showHideEventMapObservable;
  }

  notifyShowHideEvent(event: ShowHideAttributeEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);
    this.showHideEventMap[key] = event;
    this.showHideEventMapSubject.next(this.showHideEventMap);
  }

  containsShowHideValueEvent(key: string): boolean {
    return !!this.showHideEventMap[key];
  }

  getShowHideValueEvent(key: string): ShowHideAttributeEvent {
    return this.showHideEventMap[key];
  }

  removeShowHideEventFromMap(key: string, notify: boolean): void {
    delete this.showHideEventMap[key];
    setTimeout(() => {
      notify && this.showHideEventMapSubject.next(this.showHideEventMap);
    });
  }

  get setAttributeValueEventMap$(): Observable<SetAttributeValueEventWrapper> {
    return this.setAttributeValueEventObservable;
  }

  notifySetAttributeValueEvent(event: SetAttributeValueEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);
    this.setAttributeValueEventMap[key] = event;
    this.setAttributeValueEventSubject.next({ eventMap: this.setAttributeValueEventMap, event });
  }

  containsSetAttributeValueEvent(key: string): boolean {
    return !!this.setAttributeValueEventMap[key];
  }

  getSetAttributeValueEvent(key: string): SetAttributeValueEvent {
    return this.setAttributeValueEventMap[key];
  }

  removeSetAttributeValueEventFromMap(key: string, notify = false): void {
    delete this.setAttributeValueEventMap[key];

    setTimeout(() => {
      notify && this.setAttributeValueEventSubject.next({ eventMap: this.setAttributeValueEventMap });
    });
  }

  get modifyAttributeValueEventMap$(): Observable<ModifyAttributeValueEventWrapper> {
    return this.modifyAttributeValueEventObservable;
  }

  notifyModifyAttributeValueEvent(event: ModifyAttributeValueEvent) {
    const key = this.generateMapKeyForAttributeBasedEvent(event);
    this.modifyAttributeValueEventMap[key] = event;
    this.modifyAttributeValueEventSubject.next({ eventMap: this.modifyAttributeValueEventMap, event });
  }

  containsModifyAttributeValueEvent(key: string): boolean {
    return !!this.modifyAttributeValueEventMap[key];
  }

  getModifyAttributeValueEvent(key: string): ModifyAttributeValueEvent {
    return this.modifyAttributeValueEventMap[key];
  }

  removeModifyAttributeValueEventFromMap(key: string, notify = false): void {
    delete this.modifyAttributeValueEventMap[key];
    setTimeout(() => {
      notify && this.modifyAttributeValueEventSubject.next({ eventMap: this.modifyAttributeValueEventMap });
    });
  }

  get setLinkValueEventMap$(): Observable<Record<string, SetLinkValueEvent>> {
    return this.setLinkValueEventMapObservable;
  }

  notifySetLinkValueEvent(event: SetLinkValueEvent) {
    const key = event.targetLinkTypeId.split('_')[0];
    this.setLinkValueEventMap[key] = event;
    this.setLinkValueEventMapSubject.next(this.setLinkValueEventMap);
  }

  containsSetLinkValueEvent(key: string): boolean {
    return !!this.setLinkValueEventMap[key];
  }

  getSetLinkValueEvent(key: string): SetLinkValueEvent {
    return this.setLinkValueEventMap[key];
  }

  removeSetLinkValueEventFromMap(key: string, notify = false): void {
    delete this.setLinkValueEventMap[key];
    setTimeout(() => {
      notify && this.setLinkValueEventMapSubject.next(this.setLinkValueEventMap);
    });
  }

  get setAttributeOptionValuesEvent$(): Observable<SetAttributeValueEvent> {
    return this.setAttributeOptionValueEventObservable;
  }

  notifySetAttributeOptionValuesEvent(event: SetAttributeValueEvent) {
    this.setAttributeOptionValueEventSubject.next(event);
  }

  notifyPageAttributeEvent(pageAttributeChangeEvent: PageAttributeChangeEvent) {
    const { pageId, eventType, event, widgets } = pageAttributeChangeEvent;
    if (!this.pageAttributeEventMap[pageId]) {
      this.pageAttributeEventMap[pageId] = {};
    }
    if (!this.pageAttributeEventMap[pageId][eventType]) {
      this.pageAttributeEventMap[pageId][eventType] = {};
    }
    const widgetIds = widgets?.length ? widgets : [AttributeActionHandlerService.EMPTY_WIDGET];
    const key = this.generateMapKeyForAttributeBasedEvent(event);
    widgetIds.forEach(id => {
      const widgetAttributes = this.pageAttributeEventMap[pageId][eventType] as WidgetAttributeEventType;
      if (!widgetAttributes[id]) {
        widgetAttributes[id] = {};
      }
      widgetAttributes[id][key] = event;
    });
    this.pageAttributeEventMapSubject.next(this.pageAttributeEventMap);
  }

  clearPageAttributeEvent(pageAttributeChangeEvent: PageAttributeChangeEvent, notify = true) {
    const { pageId, eventType, event, widgets } = pageAttributeChangeEvent;
    if (this.pageAttributeEventMap[pageId] && this.pageAttributeEventMap[pageId][eventType]) {
      const widgetAttributes = this.pageAttributeEventMap[pageId][eventType] as WidgetAttributeEventType;
      const widgetIds = widgets?.length ? widgets : [AttributeActionHandlerService.EMPTY_WIDGET];
      const key = this.generateMapKeyForAttributeBasedEvent(event);
      widgetIds.forEach(id => {
        if (widgetAttributes[id]) {
          delete widgetAttributes[id][key];
        }
      });
    }
    if (notify) {
      this.pageAttributeEventMapSubject.next(this.pageAttributeEventMap);
    }
  }

  get pageAttributeEventMap$(): Observable<PageAttributeEventType> {
    return this.pageAttributeEventMapObservable;
  }

  get setAttributeMandatoryEventMap$(): Observable<Record<string, SetAttributeMandatoryEvent>> {
    return this.setMandatoryEventMapObservable;
  }

  notifySetAttributeMandatoryEvent(event: SetAttributeMandatoryEvent): void {
    const key = this.generateMapKeyForAttributeBasedEvent(event);
    this.setAttributeMandatoryEventMap[key] = event;
    this.AttributeMandatoryEventMapSubject.next(this.setAttributeMandatoryEventMap);
  }

  containsSetMandatoryEvent(key: string): boolean {
    return !!this.setAttributeMandatoryEventMap[key];
  }

  getSetAttributeMandatoryEvent(key: string): SetAttributeMandatoryEvent {
    return this.setAttributeMandatoryEventMap[key];
  }

  removeSetAttributeMandatoryEvent(key: string, notify: boolean): void {
    setTimeout(() => {
      delete this.setAttributeMandatoryEventMap[key];
      notify && this.AttributeMandatoryEventMapSubject.next(this.setAttributeMandatoryEventMap);
    });
  }

  generateMapKey(artifactTypeId: string, attributeId: string): string {
    return `${artifactTypeId}_${attributeId}`;
  }

  private generateMapKeyForAttributeBasedEvent(event: AttributeBasedEvent): string {
    return this.generateMapKey(event.artifactTypeId, event.attributeId);
  }
}
