import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';

export enum QrCodesOutputType {
  svg = 'SVG',
  png = 'PNG',
}

export type QrCodesAvrType = Extract<AvrTypesType, 'qr-codes'>;

export class QrCodesAvrInputMapperDto extends BaseAvrInputMapperDto<QrCodesAvrType> {
  constructor() {
    super(
      {
        ['content']: {
          isOptional: false,
          value: '',
          readableName: 'Content',
          description: '[STRING] Content (URL, Plain Text, vCard, Email Address, SMS, WiFi Network Information, Geolocation, vEvent etc.)',
        },
      },
      {
        ['outputType']: {
          isOptional: false,
          value: 'SVG',
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
      },
    );
  }
}
