/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authControllerAnonymousLogin } from '../fn/auth/auth-controller-anonymous-login';
import { AuthControllerAnonymousLogin$Params } from '../fn/auth/auth-controller-anonymous-login';
import { authControllerAuth } from '../fn/auth/auth-controller-auth';
import { AuthControllerAuth$Params } from '../fn/auth/auth-controller-auth';
import { authControllerAuthLdap } from '../fn/auth/auth-controller-auth-ldap';
import { AuthControllerAuthLdap$Params } from '../fn/auth/auth-controller-auth-ldap';
import { authControllerAuthOpenId } from '../fn/auth/auth-controller-auth-open-id';
import { AuthControllerAuthOpenId$Params } from '../fn/auth/auth-controller-auth-open-id';
import { authControllerChangePassword } from '../fn/auth/auth-controller-change-password';
import { AuthControllerChangePassword$Params } from '../fn/auth/auth-controller-change-password';
import { authControllerConfirmRegistration } from '../fn/auth/auth-controller-confirm-registration';
import { AuthControllerConfirmRegistration$Params } from '../fn/auth/auth-controller-confirm-registration';
import { authControllerGoogleLoginCallback } from '../fn/auth/auth-controller-google-login-callback';
import { AuthControllerGoogleLoginCallback$Params } from '../fn/auth/auth-controller-google-login-callback';
import { authControllerLogin } from '../fn/auth/auth-controller-login';
import { AuthControllerLogin$Params } from '../fn/auth/auth-controller-login';
import { authControllerLoginWithOpenId } from '../fn/auth/auth-controller-login-with-open-id';
import { AuthControllerLoginWithOpenId$Params } from '../fn/auth/auth-controller-login-with-open-id';
import { authControllerLogout } from '../fn/auth/auth-controller-logout';
import { AuthControllerLogout$Params } from '../fn/auth/auth-controller-logout';
import { authControllerRecoverPassword } from '../fn/auth/auth-controller-recover-password';
import { AuthControllerRecoverPassword$Params } from '../fn/auth/auth-controller-recover-password';
import { authControllerRegistration } from '../fn/auth/auth-controller-registration';
import { AuthControllerRegistration$Params } from '../fn/auth/auth-controller-registration';
import { authControllerTokenPasswordChange } from '../fn/auth/auth-controller-token-password-change';
import { AuthControllerTokenPasswordChange$Params } from '../fn/auth/auth-controller-token-password-change';
import { LoginResponseDto } from '../models/login-response-dto';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authControllerAuth()` */
  static readonly AuthControllerAuthPath = '/api/auth/google';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAuth$Response(params: AuthControllerAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAuth(params: AuthControllerAuth$Params, context?: HttpContext): Observable<void> {
    return this.authControllerAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerAuthOpenId()` */
  static readonly AuthControllerAuthOpenIdPath = '/api/auth/openid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerAuthOpenId()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAuthOpenId$Response(params: AuthControllerAuthOpenId$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerAuthOpenId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerAuthOpenId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAuthOpenId(params: AuthControllerAuthOpenId$Params, context?: HttpContext): Observable<void> {
    return this.authControllerAuthOpenId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerLoginWithOpenId()` */
  static readonly AuthControllerLoginWithOpenIdPath = '/api/auth/openid-callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLoginWithOpenId()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLoginWithOpenId$Response(params?: AuthControllerLoginWithOpenId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return authControllerLoginWithOpenId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLoginWithOpenId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLoginWithOpenId(params?: AuthControllerLoginWithOpenId$Params, context?: HttpContext): Observable<{
}> {
    return this.authControllerLoginWithOpenId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `authControllerAuthLdap()` */
  static readonly AuthControllerAuthLdapPath = '/api/auth/ldap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerAuthLdap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerAuthLdap$Response(params: AuthControllerAuthLdap$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerAuthLdap(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerAuthLdap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerAuthLdap(params: AuthControllerAuthLdap$Params, context?: HttpContext): Observable<void> {
    return this.authControllerAuthLdap$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerGoogleLoginCallback()` */
  static readonly AuthControllerGoogleLoginCallbackPath = '/api/auth/google-callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGoogleLoginCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleLoginCallback$Response(params?: AuthControllerGoogleLoginCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return authControllerGoogleLoginCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerGoogleLoginCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleLoginCallback(params?: AuthControllerGoogleLoginCallback$Params, context?: HttpContext): Observable<{
}> {
    return this.authControllerGoogleLoginCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `authControllerLogin()` */
  static readonly AuthControllerLoginPath = '/api/auth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin$Response(params: AuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponseDto>> {
    return authControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin(params: AuthControllerLogin$Params, context?: HttpContext): Observable<LoginResponseDto> {
    return this.authControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `authControllerAnonymousLogin()` */
  static readonly AuthControllerAnonymousLoginPath = '/api/auth/anonymous-login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerAnonymousLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAnonymousLogin$Response(params: AuthControllerAnonymousLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponseDto>> {
    return authControllerAnonymousLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerAnonymousLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerAnonymousLogin(params: AuthControllerAnonymousLogin$Params, context?: HttpContext): Observable<LoginResponseDto> {
    return this.authControllerAnonymousLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>): LoginResponseDto => r.body)
    );
  }

  /** Path part for operation `authControllerLogout()` */
  static readonly AuthControllerLogoutPath = '/api/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout$Response(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<void> {
    return this.authControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerChangePassword()` */
  static readonly AuthControllerChangePasswordPath = '/api/auth/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerChangePassword$Response(params: AuthControllerChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerChangePassword(params: AuthControllerChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.authControllerChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerTokenPasswordChange()` */
  static readonly AuthControllerTokenPasswordChangePath = '/api/auth/token-password-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerTokenPasswordChange()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerTokenPasswordChange$Response(params: AuthControllerTokenPasswordChange$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerTokenPasswordChange(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerTokenPasswordChange$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerTokenPasswordChange(params: AuthControllerTokenPasswordChange$Params, context?: HttpContext): Observable<void> {
    return this.authControllerTokenPasswordChange$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerRecoverPassword()` */
  static readonly AuthControllerRecoverPasswordPath = '/api/auth/recover-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRecoverPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRecoverPassword$Response(params: AuthControllerRecoverPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerRecoverPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerRecoverPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRecoverPassword(params: AuthControllerRecoverPassword$Params, context?: HttpContext): Observable<void> {
    return this.authControllerRecoverPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerRegistration()` */
  static readonly AuthControllerRegistrationPath = '/api/auth/registration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRegistration$Response(params: AuthControllerRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRegistration(params: AuthControllerRegistration$Params, context?: HttpContext): Observable<void> {
    return this.authControllerRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerConfirmRegistration()` */
  static readonly AuthControllerConfirmRegistrationPath = '/api/auth/confirm-registration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerConfirmRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerConfirmRegistration$Response(params: AuthControllerConfirmRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerConfirmRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerConfirmRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerConfirmRegistration(params: AuthControllerConfirmRegistration$Params, context?: HttpContext): Observable<void> {
    return this.authControllerConfirmRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
