import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AclModule } from '@widgets/shared/components/acl/acl.module';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { IsShowFolderPipe } from '@widgets/shared/components/folder-picker/is-show-private-folder.pipe';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreeModule } from 'primeng/tree';
import { FolderWidgetSettingsComponent } from './components/folder-widget-settings/folder-widget-settings.component';
import { FolderWidgetComponent } from './folder-widget.component';

@NgModule({
  declarations: [FolderWidgetComponent, FolderWidgetSettingsComponent, FolderPickerComponent, IsShowFolderPipe],
  imports: [
    CommonModule,
    TreeModule,
    CheckboxModule,
    FormsModule,
    ContextMenuModule,
    OverlayPanelModule,
    InputTextModule,
    ButtonModule,
    StyleFormModule,
    AccordionModule,
    TranslateModule,
    DropdownModule,
    OrderListModule,
    ListboxModule,
    AclModule,
    DialogModule,
  ],
  exports: [FolderWidgetComponent, FolderPickerComponent, FolderWidgetSettingsComponent],
})
export class FolderWidgetModule {}
