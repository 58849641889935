import { Injectable, Injector } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { NewFileParams } from '@private/types/file-service.types';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { FileHelper } from './file.helper';

@Injectable({ providedIn: 'root' })
export class TinyMceHelper {
  constructor(
    private readonly fileHelper: FileHelper,
    private readonly injector: Injector,
    private readonly apiConfiguration: ApiConfiguration,
    private authorizationService: AuthorizationService,
  ) {}

  getTinyMceAdvancedSettings(): Record<string, any> {
    const settings: Record<string, any> = {
      suffix: '.min',
      plugins:
        'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      mobile: {
        plugins:
          'print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
      },
      menubar: 'file edit view insert format tools table tc help',
      // toolbar: [
      //   'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect',
      //   'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat',
      //   'pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
      // ],
      templates: [
        {
          title: 'New Table',
          description: 'creates a new table',
          content:
            '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
        },
        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        {
          title: 'New list with dates',
          description: 'New List with dates',
          content:
            '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
        },
      ],
      contextmenu: 'link image imagetools table',
      inline: true,
      placeholder: null,

      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
      skin: 'oxide',
    };

    settings['template_cdate_format'] = '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]';
    settings['template_mdate_format'] = '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]';
    settings['autosave_ask_before_unload'] = false;
    settings['base_url'] = '/tinymce';
    settings['image_advtab'] = true;
    settings['paste_data_images'] = true;
    settings['importcss_append'] = true;
    settings['image_caption'] = true;
    settings['quickbars_selection_toolbar'] = 'bold italic | quicklink h2 h3 blockquote quickimage quicktable';
    settings['quickbars_insert_toolbar'] = '';
    settings['noneditable_noneditable_class'] = 'mceNonEditable';
    settings['toolbar_mode'] = 'sliding';
    settings['a11y_advanced_options'] = true;
    settings['toolbar_sticky'] = true;
    settings['document_base_url'] = window.location.origin;
    settings['relative_urls'] = false;
    settings['remove_script_host'] = false;
    settings['images_upload_handler'] = this.uploadFile;

    return settings;
  }

  getTinyMceLayoutSettings(): Record<string, any> {
    const settings: Record<string, any> = {
      suffix: '.min',
      plugins:
        'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      mobile: {
        plugins:
          'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      },
      menubar: 'file edit view insert format tools table tc help',
      templates: [
        {
          title: 'New Table',
          description: 'creates a new table',
          content:
            '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
        },
        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        {
          title: 'New list with dates',
          description: 'New List with dates',
          content:
            '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
        },
      ],
      contextmenu: 'link image imagetools table',
      inline: true,
      placeholder: null,

      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
      skin: 'oxide',
    };

    settings['template_cdate_format'] = '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]';
    settings['template_mdate_format'] = '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]';
    settings['autosave_ask_before_unload'] = false;
    settings['base_url'] = '/tinymce';
    settings['image_advtab'] = true;
    settings['importcss_append'] = true;
    settings['paste_data_images'] = true;
    settings['image_caption'] = true;
    settings['quickbars_selection_toolbar'] = 'bold italic | quicklink h2 h3 blockquote quickimage quicktable';
    settings['quickbars_insert_toolbar'] = '';
    settings['noneditable_noneditable_class'] = 'mceNonEditable';
    settings['toolbar_mode'] = 'sliding';
    settings['a11y_advanced_options'] = true;
    settings['document_base_url'] = window.location.origin;
    settings['relative_urls'] = false;
    settings['remove_script_host'] = false;
    settings['images_upload_handler'] = this.uploadFile;

    return settings;
  }

  uploadFile = async (blobInfo: any): Promise<any> => {
    // done in this way, because from 'blobInfo.blob()' sends on first look correct 'File', but when I checked it with 'instanceof Blob' result was 'false'
    const mceBlob = blobInfo.blob() as File;
    const file = new File([new Uint8Array(await mceBlob.arrayBuffer())], mceBlob.name, { type: mceBlob.type });
    return new Promise((resolve, reject) => {
      try {
        this.fileHelper.uploadTinymceFile(new NewFileParams(file, new NewClientAttribute(), '', 1, false)).then((fileId: string) => {
          if (typeof fileId === 'string' && !fileId) {
            reject(fileId);
          } else {
            resolve(`${this.apiConfiguration.rootUrl}/api/tenant/artifact/${fileId}/download?authorization=Bearer%20${this.authorizationService.getToken}`);
          }
        });
      } catch (e: any) {
        reject(e.message);
      }
    });
  };

  addOrRemoveImageAuth(data: string, remove: boolean): string {
    return data?.replace(/<img .*?>/g, img => {
      if (img.includes('api/tenant/artifact/')) {
        const matches = img.match(/src=".*?"/g);
        const url = matches?.length ? matches[0].substring(5, matches[0].length - 1) : '';
        const urlToReplace = this.apiConfiguration.rootUrl + (url.includes('http') ? new URL(url).pathname : url.startsWith('/') ? url : `/${url}`);

        return img.replace(url, this.addOrRemoveBearerToken(urlToReplace, remove));
      }
      return img;
    });
  }

  private addOrRemoveBearerToken(url: string, remove: boolean): string {
    return remove
      ? `${url.split('?authorization=Bearer')[0]}`
      : `${url.split('?authorization=Bearer')[0]}?authorization=Bearer%20${this.authorizationService.getToken}`;
  }
}
