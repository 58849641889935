export interface SortAttributeObject {
  attributeName: string;
  sortType?: string;
}

export enum SortTypeEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortTypeValueEnum {
  ASC = 1,
  DESC = -1,
}

export interface SortUrlChangeEvent {
  /** widget hash / id */
  ownerId: string;
  payload: SortAttributeObject | null;
}
