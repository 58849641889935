import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FadeAnimation } from '@shared/animations/animations';
import { DisplayAtUtilService } from '@shared/components/common-display-at/services';
import { TextStyleHelper } from '@shared/helpers/text-style.helper';
import { CUSTOM_VARIANT_KEY, DEFAULT_VARIANT_KEY, DisplayAtDropdownItem, DisplayAttributeType } from '@shared/types/display-at-types';
import { SelectOption } from '@shared/types/shared.types';
import { LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-link-type-format-settings',
  templateUrl: './link-type-format-settings.component.html',
  styleUrls: ['./link-type-format-settings.component.scss'],
  animations: [FadeAnimation],
})
export class LinkTypeFormatSettingsComponent extends TextStyleHelper implements OnInit {
  @Input() hash: string;
  @Input() attributeId: string;
  @Input() formatSettings: LinkTypeFormatSettings;
  @Input() changeLabelStyleReference: (formatSettings: LinkTypeFormatSettings) => void;
  @Input() onLabelPositionChange: (event: { originalEvent: PointerEvent; value: LabelPositionEnum }, formatSettings: LinkTypeFormatSettings) => void;

  @Output() onCustomVariantSelection: EventEmitter<string> = new EventEmitter();

  linkDisplayVariantOptions?: SelectOption<string, string>[];

  constructor(private readonly displayAtUtilService: DisplayAtUtilService) {
    super();
  }

  ngOnInit(): void {
    this.initLinkDisplayVariantOptions();
  }

  onSelectedItemChange(selectedItem: string) {
    if (selectedItem === CUSTOM_VARIANT_KEY) {
      this.onCustomVariantSelection.emit(this.attributeId);
    }
  }

  private initLinkDisplayVariantOptions() {
    const displayAttributeTypeEnumObject = this.displayAtUtilService.getDisplayAtEnumObjectForType(DisplayAttributeType.link);
    this.linkDisplayVariantOptions = this.displayAtUtilService
      .getDisplayAtDropdownItems(displayAttributeTypeEnumObject)
      .map(({ label, code }: DisplayAtDropdownItem) => new SelectOption<string, string>(label, code));

    this.formatSettings.value.displayMetadata ??= {
      attributeType: DisplayAttributeType.link,
      selectedVariantCode: DEFAULT_VARIANT_KEY,
    };
  }
}
