import { DeleteArtifactsActionWorkflowSettingsDto } from '@api/models/delete-artifacts-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum DeleteArtifactsKeys {
  ids = 'ids',
  hardDelete = 'hardDelete',
}

export class WorkflowActionDeleteArtifactsDto implements DeleteArtifactsActionWorkflowSettingsDto {
  ids: DeleteArtifactsActionWorkflowSettingsDto[DeleteArtifactsKeys.ids] = { value: [''], isDynamic: false };
  hardDelete: DeleteArtifactsActionWorkflowSettingsDto[DeleteArtifactsKeys.hardDelete] = { value: 'false', isDynamic: false };

  constructor(action?: WorkflowActionDeleteArtifactsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionDeleteArtifacts extends AbstractWorkflowAction<DeleteArtifactsActionWorkflowSettingsDto> {
  hardDelete = false;

  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionDeleteArtifactsDto, type: WorkflowActionType.DELETE_ARTIFACTS, dto });
    dto && (this.hardDelete = (dto.actionSettings as DeleteArtifactsActionWorkflowSettingsDto)[DeleteArtifactsKeys.hardDelete].value === 'true');
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    const hardDeleteActionSettings = {
      isDynamic: false,
      value: this.hardDelete ? 'true' : 'false',
    };

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [DeleteArtifactsKeys.hardDelete]: hardDeleteActionSettings,
      },
    };
  }
}
