<app-user-picker
    *ngIf="showUserPicker"
    [label]="currentAclType"
    [memberType]="memberType"
    [itemList]="itemList"
    [selectedItem]="selectedItem"
    [expiration]="currentDateTime"
    [showExpiration]="true"
    (onClose)="showUserPicker = false"
    (onUpdateList)="memberType = $event; s.updateList()"
    (onAddMembers)="s.addMembers($event)"
></app-user-picker>

<p-accordion *ngIf="model && aclTypeList">
    <p-accordionTab *ngFor="let key of aclTypeList; let i = index" [header]="aclTypes[key]" (selectedChange)="activeState[i] = $event">
        <ng-template pTemplate="header">
            <div class="action-cont">
                <i class="pi" *ngIf="model[key].length > 0" [ngClass]="edit[i] ? 'pi-check': 'pi-pencil'" (click)="toggleEdit($event, aclTypes[key], i)"></i>
                <i [ngClass]="s.isOpenPickerByKey(key) ? 'pi pi-minus': 'pi pi-plus'" (click)="openPickUsers($event, aclTypes[key], i)"></i>
            </div>
        </ng-template>

        <div class="grid my-3" *ngFor="let item of model[key]" style="position: relative">
            <div class="col-7 p-xl-3 py-0">{{item.name}}</div>
            <div class="col-3 mx-0 px-0 py-0 calendar-container">
                <p-calendar
                    [(ngModel)]="item.expirationDate"
                    [dateFormat]="dateFormat"
                    [disabled]="!edit[i]"
                    [firstDayOfWeek]="userData?.uiConfig?.firstDayOfWeek"
                    [placeholder]="'no expiration' | translate"
                    [inputStyle]="{ border: 'none', fontSize: '12px', padding: 0, color: '#000' }"
                    [inputStyleClass]="'cursor-pointer'"
                    (ngModelChange)="onChangeDate()"
                    appendTo="body"
                >
                </p-calendar>
            </div>
            <div class="col-1 px-0 py-0 icon-wrapper">
                <span *ngIf="edit[i] && item.expirationDate" class="pi pi-times-circle icon close" (click)="removeExpiration(item)"></span>
            </div>
            <div class="col-1 px-0 py-0">
                <i class="pi pi-trash" *ngIf="edit[i]" (click)="s.remove(aclTypes[key], item)"></i>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>

<div class="my-3" style="text-align: center">
    <div *ngIf="!!folderId" class="checkbox-wrapper">
        <p-checkbox [(ngModel)]="setFolderItemsAcl" [binary]="true" label="Pass to Child"></p-checkbox>
    </div>

    <button pButton class="p-button p-button-warning mx-2" label="{{'Cancel' | translate}}" type="button" (click)="s.closeAcl()"></button>
    <button pButton class="p-button p-button-success mx-2" label="{{'Update' | translate}}" type="button" (click)="s.update()" [disabled]="!isChanged"></button>
</div>
