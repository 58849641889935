import { Pipe, PipeTransform } from '@angular/core';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';

@Pipe({ name: 'getSubMenuArrow' })
export class GetSubMenuArrowPipe implements PipeTransform {
  transform(items: MenuItem[], item: MenuItem, expanded: boolean, type: MenuTypesEnum): any {
    return 'pi pi-chevron-' + (this.shouldReturnArrowDown(items, item, expanded, type) ? 'down' : 'right');
  }

  private shouldReturnArrowDown(menu: MenuItem[], item: MenuItem, expanded: boolean, type: MenuTypesEnum): boolean {
    return (new IsItemInFirstLevelPipe().transform(menu, item) && type === MenuTypesEnum.horizontal) || (type === MenuTypesEnum.panel && expanded);
  }
}
