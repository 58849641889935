import { Injectable } from '@angular/core';
import { RuleTriggerType, WorkflowTrigger } from '@workflows/types';
import { WorkflowRule } from '../types/rule.types';

@Injectable({ providedIn: 'root' })
export class RuleUtilsService {
  isUsedByPage(rule: WorkflowRule, pageId: string): boolean {
    return !!rule?.usedIn?.find(ownerId => ownerId === pageId);
  }

  isOwnedByPage(rule: WorkflowRule, pageId: string): boolean {
    return rule.ownerId === pageId;
  }

  isLinkSpecificRule(rule: WorkflowRule): boolean {
    return !!rule.triggers.find(triggerItem => this.isLinkSpecificTrigger(triggerItem));
  }

  isLinkSpecificTriggerType(type: RuleTriggerType): boolean {
    return type === RuleTriggerType.LINK_ADDED;
  }

  isLinkSpecificTrigger(trigger: WorkflowTrigger): boolean {
    return this.isLinkSpecificTriggerType(trigger.type as RuleTriggerType);
  }
}
