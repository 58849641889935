<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>

<ng-container *ngIf="!attribute.multipleValues">
    <textarea
            (blur)="onBlur()"
            [(ngModel)]="attr.value"
            (ngModelChange)="onChange()"
            [id]="'strField' + index"
            [placeholder]="placeholder"
            autoResize="autoResize"
            pInputTextarea
            rows="1"
    >
    </textarea>
  <app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                     [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'strField' + index"
                                                     [canContainPlaceholder]="true"
                                                     [labelBehaviour]="labelBehaviour"
                                                     [placeholder]="placeholder">
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="attribute.multipleValues">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <p-chips
          [(ngModel)]="attr.value"
          (ngModelChange)="onChange()"
          (onBlur)="onBlur()"
          [inputId]="'intFieldM' + index"
          [ngClass]="{ 'p-chips-filled': attr.value?.length }"
          [placeholder]="placeholder"
  >
    <ng-template let-item pTemplate="item">
      {{ item }}
    </ng-template>
  </p-chips>
  <app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                     [formatSettings]="formatSettings"
                                                     [label]="label"
                                                     [fieldHtmlId]="'intFieldM' + index"
                                                     [canContainPlaceholder]="true"
                                                     [labelBehaviour]="labelBehaviour"
                                                     [placeholder]="placeholder">
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>
