/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { tenantControllerGet } from '../fn/tenant-tenant/tenant-controller-get';
import { TenantControllerGet$Params } from '../fn/tenant-tenant/tenant-controller-get';
import { tenantControllerUpdate } from '../fn/tenant-tenant/tenant-controller-update';
import { TenantControllerUpdate$Params } from '../fn/tenant-tenant/tenant-controller-update';
import { TenantTenantResponseDto } from '../models/tenant-tenant-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantTenantService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tenantControllerGet()` */
  static readonly TenantControllerGetPath = '/api/tenant/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantControllerGet$Response(params?: TenantControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantTenantResponseDto>> {
    return tenantControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tenantControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantControllerGet(params?: TenantControllerGet$Params, context?: HttpContext): Observable<TenantTenantResponseDto> {
    return this.tenantControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantTenantResponseDto>): TenantTenantResponseDto => r.body)
    );
  }

  /** Path part for operation `tenantControllerUpdate()` */
  static readonly TenantControllerUpdatePath = '/api/tenant/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantControllerUpdate$Response(params: TenantControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantTenantResponseDto>> {
    return tenantControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tenantControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantControllerUpdate(params: TenantControllerUpdate$Params, context?: HttpContext): Observable<TenantTenantResponseDto> {
    return this.tenantControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantTenantResponseDto>): TenantTenantResponseDto => r.body)
    );
  }

}
