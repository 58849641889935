import { Component, Input } from '@angular/core';
import { WorkflowListResponseDto } from '@api/models/workflow-list-response-dto';
import { WorkflowResponseDto } from '@api/models/workflow-response-dto';
import { WorkflowListOptions } from '@private/pages/workflow-management/workflow-list/types/workflow-list.types';
import { APPLICATION_ID_KEY, CREATED_BY_KEY, NAME_KEY, WORKFLOW_OWNER_TYPE_KEY, WORKFLOW_TYPE_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';

@Component({
  selector: 'app-workflow-list-table',
  templateUrl: './workflow-list-table.component.html',
  styleUrls: ['./workflow-list-table.component.scss'],
})
export class WorkflowListTableComponent extends CoreListComponent<WorkflowResponseDto> {
  @Input() options: WorkflowListOptions;
  @Input() columns: TableColumn[];
  @Input() loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<WorkflowListResponseDto>;

  protected readonly APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  protected readonly WORKFLOW_TYPE_KEY = WORKFLOW_TYPE_KEY;
  protected readonly WORKFLOW_OWNER_TYPE_KEY = WORKFLOW_OWNER_TYPE_KEY;
  protected readonly NAME_KEY = NAME_KEY;
  protected readonly CREATED_BY_KEY = CREATED_BY_KEY;

  constructor() {
    super();
  }
}
