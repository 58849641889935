import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// TODO: to check .instant() instead of .get() so we can get rid of asynchronous code
@Injectable({ providedIn: 'root' })
export class TranslateUtil {
  constructor(private translateService: TranslateService) {}

  async getAll(keys: string[], interpolateParams: Record<string, string> = {}): Promise<string[]> {
    const translated = await this.translateService.get(keys, interpolateParams).toPromise();
    return Object.keys(translated).map(key => translated[key]) as string[];
  }

  async get(key: string, interpolateParams: Record<string, string> = {}): Promise<string> {
    return await this.translateService.get(key, interpolateParams).toPromise();
  }

  async translatePropFromObject(obj: any, key: string, translatedKey: string | null = null, interpolateParams: Record<string, string> = {}): Promise<void> {
    const translated = await this.translateService.get(obj[key], interpolateParams).toPromise();
    const keyT = translatedKey ? translatedKey : key + 'T';
    obj[keyT] = translated;
  }

  instant(key: string | Array<string>, interpolateParams: Record<string, string> = {}): string {
    return this.translateService.instant(key, interpolateParams);
  }
}
