import {
  BaseDataTypeBoundedRange,
  BaseDataTypeCounter,
  BaseDataTypeEnumerated,
  BaseDataTypeOptionsMap,
  BaseDataTypeSimple,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewApplication } from '@shared/types/application.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';

export class DataTypesModel {
  inProgress = false;
  isDeleted = false;
  applications = new ListContainer<NewApplication>();
  dataTypes = new ListContainer<NewDataType>();
  dataType: NewDataType;
  dataTypeCopy: NewDataType;
  baseDataTypeOptions:
    | SelectOption<typeof BaseDataTypeSimple, typeof BaseDataTypeSimple>[]
    | SelectOption<typeof BaseDataTypeEnumerated, typeof BaseDataTypeEnumerated>[]
    | SelectOption<typeof BaseDataTypeBoundedRange, typeof BaseDataTypeBoundedRange>[]
    | SelectOption<typeof BaseDataTypeCounter, typeof BaseDataTypeCounter>[] = [];
  baseDataTypeOptionsMap: BaseDataTypeOptionsMap;

  constructor(model?: Partial<DataTypesModel>) {
    model && Object.assign(this, model);
  }
}
