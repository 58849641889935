import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SystemAdminUserListTableComponent } from './system-user-list/components/user-list-table-system-admin/system-admin-user-list-table.component';
import { PrintSystemUserToTablePipe } from './system-user-list/pipes/print-system-user-to-table.pipe';
import { SystemUserListComponent } from './system-user-list/system-user-list.component';
import { SystemUserFormComponent } from './system-user/components/system-user-form/system-user-form.component';
import { SystemUserComponent } from './system-user/system-user.component';

const declarations = [SystemUserListComponent, SystemAdminUserListTableComponent];

@NgModule({
  declarations: [...declarations, SystemUserComponent, SystemUserFormComponent, PrintSystemUserToTablePipe],
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FormsModule,
    CheckboxModule,
    DropdownModule,
    PickListModule,
    PasswordModule,
    InputTextModule,
    MultiSelectModule,
    RippleModule,
    LoaderModule,
    ElvisSharedModule,
    TriStateCheckboxModule,
  ],
  exports: [...declarations],
})
export class SystemUserManagementModule {}
