import { Params } from '@angular/router';
import { FilterType, NewTableColumn } from '@shared/types/table.types';

export interface TableFilterEvent {
  ownerId: string;
  filterType: FilterType;
  operationType: TableFilterOperationType;
  column: NewTableColumn;
  payload?: any;
}

/**
 * Event for table
 */
export interface TableFilterUrlParamsEvent {
  ownerId: string;
  urlQueryParams: Params;
  urlFilteringOn: boolean;
}

export enum TableFilterOperationType {
  APPLY,
  CLEAR,
}
