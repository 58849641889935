<ng-container>
  <div *ngIf="root" class="layout-menuitem-root-text">{{ item.label | translate }}</div>
  <a *ngIf="!item.routerLink || item.items"
     [attr.href]="item.url"
     (click)="itemClick($event)"
     (mouseenter)="onMouseEnter()"
     (keydown.enter)="itemClick($event)"
     [ngClass]="item.class"
     [attr.target]="item.target"
     [attr.tabindex]="0"
     pRipple>
    <span [ngClass]="item.icon" class="layout-menuitem-icon"></span>
    <span class="layout-menuitem-text">{{ item.label | translate }}</span>
    <span class="pi pi-fw pi-chevron-down layout-submenu-toggler" *ngIf="item.items"></span>
  </a>
  <a *ngIf="item.routerLink && !item.items"
     (click)="itemClick($event)"
     (mouseenter)="onMouseEnter()"
     [routerLink]="item.routerLink"
     [ngClass]="item.class"
     [routerLinkActiveOptions]="{ exact: true }"
     [attr.target]="item.target"
     [attr.tabindex]="0"
     routerLinkActive="active-route"
     pRipple>
    <span [ngClass]="item.icon" class="layout-menuitem-icon"></span>
    <span class="layout-menuitem-text">{{ item.label | translate }}</span>
    <span class="pi pi-fw pi-chevron-down layout-submenu-toggler" *ngIf="item.items"></span>
  </a>

  <ul *ngIf="item.items"
      role="menu"
      [@children]="
          app.isSlim()
            ? root
              ? app.isMobile()
                ? 'visible'
                : slimClick
                ? active
                  ? 'slimVisibleAnimated'
                  : 'slimHiddenAnimated'
                : active
                ? 'visible'
                : 'hidden'
              : active
              ? 'visible'
              : 'hidden'
            : root
            ? 'visible'
            : active
            ? 'visibleAnimated'
            : 'hiddenAnimated'
        ">
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li app-sidebar-menu-item [app]="app" [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    </ng-template>
  </ul>
</ng-container>
