import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '@private/pages/profile/services/profile.service';
import { ProfileModel } from '@private/pages/profile/types/user.types';
import { EMAIL_KEY, EMAIL_LABEL, PHONE_KEY, PHONE_LABEL, USER_NAME_KEY, USER_NAME_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { BreadcrumbService } from '../../services/app.breadcrumb.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileService],
})
export class ProfileComponent extends CoreComponent<ProfileService, ProfileModel> {
  public fieldsToShow: { label: string; key: string }[];
  languages: any;
  changePasswordDialogDisplayed = false;

  constructor(
    public service: ProfileService,
    public route: ActivatedRoute,
    public router: Router,
    public readonly translate: TranslateService,
    public readonly announcement: AnnouncementService,
    public readonly localStorageService: LocalStorageService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new ProfileModel(), service, announcement);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    await this.s.updateLocalStorageUser();
    this.languages = [
      { label: 'en', value: 'en' },
      { label: 'de', value: 'de' },
    ];
    this.breadcrumbService.setItems([{ label: 'Profile' }]);
    this.initFieldsToShow();
  }

  openResetPasswordDialog(): void {
    this.changePasswordDialogDisplayed = true;
  }

  hideResetPasswordDialog(): void {
    this.changePasswordDialogDisplayed = false;
  }

  private initFieldsToShow(): void {
    this.fieldsToShow = [
      { label: USER_NAME_LABEL, key: USER_NAME_KEY },
      { label: EMAIL_LABEL, key: EMAIL_KEY },
      { label: PHONE_LABEL, key: PHONE_KEY },
    ];
  }
}
