import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectedRowModuleArtifact } from '../types/artifact-list-widget-table.types';
import { AddArtifactModulePositionEnum } from '../types/list-widget.types';

@Pipe({ name: 'isHeadingCheckboxEnabled' })
export class IsHeadingCheckboxEnabledPipe implements PipeTransform {
  transform(selectedRowArtifact: SelectedRowModuleArtifact, newPosition: AddArtifactModulePositionEnum, data: NewArtifact[]): boolean {
    if (newPosition === AddArtifactModulePositionEnum.AFTER) {
      return !selectedRowArtifact.artifact?.moduleData?.isHeading;
    }

    if (newPosition === AddArtifactModulePositionEnum.BEFORE) {
      const previousArtifact = data[selectedRowArtifact.index - 1];
      return !previousArtifact || !previousArtifact.moduleData?.isHeading;
    }

    return true;
  }
}
