import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SortStickySectionsPreviewModePipe } from '@limited/pipes/sort-sticky-sections-preview-mode.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationManagementModule } from '@private/pages/application-management/application-management.module';
import { ProfileModule } from '@private/pages/profile/profile.module';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { LimitedTopBarComponent } from './components/limited-top-bar/limited-top-bar.component';
import { IsOnPageComponentPipe } from './components/limited-top-bar/pipes/is-on-page-component.pipe';
import { ApplicationSelectionComponent } from './pages/application-selection/application-selection.component';
import { LimitedMainComponent } from './pages/limited-main/limited-main.component';

const components = [ApplicationSelectionComponent, LimitedTopBarComponent, LimitedMainComponent];

@NgModule({
  declarations: [...components, IsOnPageComponentPipe, SortStickySectionsPreviewModePipe],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    TooltipModule,
    MenubarModule,
    SharedModule,
    ButtonModule,
    ProfileModule,
    ElvisSharedModule,
    ApplicationManagementModule,
    LoaderModule,
  ],
  exports: [...components, SortStickySectionsPreviewModePipe],
})
export class LimitedModule {}
