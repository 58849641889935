import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsTime } from '@shared/methods/data-type.methods';

@Pipe({ name: 'isTime' })
export class IsTimePipe implements PipeTransform {
  transform(baseDataType: BaseDataType): boolean {
    return IsTime(baseDataType);
  }
}
