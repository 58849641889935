import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlockUiService {
  changes$ = new Subject<boolean>();

  unblockUi(): void {
    this.changes$.next(false);
  }

  blockUi(): void {
    // todo check overlay settings
    this.changes$.next(true);
  }
}
