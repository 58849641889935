import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  NumberFilterOptionsTypes,
  NumberFilterRuleType,
} from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter-options.types';
import {
  NumberFilter,
  NumberFilterOption,
  NumberFilterRange,
} from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrls: ['./number-filter.component.scss'],
})
export class NumberFilterComponent {
  @Input() set m(filter: ArtifactFilter) {
    !filter.value?.ruleTypes?.length && (filter.value = new NumberFilter());
    this.selected = filter.value;
  }
  @Input() isShowAddRule = true;
  @Input() applyIsEnable = false;
  @Input() placeholder: string;
  @Output() onFilterChange = new EventEmitter<any>();

  options = new NumberFilterOptionsTypes();
  selected: NumberFilter;

  addRule(): void {
    this.selected.ruleTypes.push(new NumberFilterOption());
  }

  removeRule(index: number): void {
    this.selected.ruleTypes.splice(index, 1);
  }

  onRuleTypeChange(ruleType: any, index: number): void {
    if (ruleType === NumberFilterRuleType.between) {
      this.selected.ruleTypes[index].value = new NumberFilterRange();
    } else if (this.selected.ruleTypes[index].value instanceof NumberFilterRange) {
      this.selected.ruleTypes[index].value = null;
    } else if (ruleType === NumberFilterRuleType.isEmpty || ruleType === NumberFilterRuleType.isNotEmpty) {
      this.selected.ruleTypes[index].value = null;
      this.onChange();
    }
  }

  onChange(): void {
    const { ruleType, value }: NumberFilterOption = this.selected.ruleTypes[0];
    if (ruleType === NumberFilterRuleType.between && value instanceof NumberFilterRange) {
      if (!value.from || !value.to) return;
      if (value.from > value.to) {
        const temp = value.from;
        value.from = value.to;
        value.to = temp;
      }
    }

    !this.applyIsEnable && this.onFilterChange.emit();
  }

  apply(): void {
    this.onFilterChange.emit();
  }

  clear(): void {
    this.selected.ruleTypes.forEach(rule => {
      if (rule.ruleType === NumberFilterRuleType.between) {
        rule.value = new NumberFilterRange();
        return;
      }

      if (rule.ruleType === NumberFilterRuleType.isNotEmpty || rule.ruleType === NumberFilterRuleType.isEmpty) {
        rule.ruleType = NumberFilterRuleType.equals;
      }

      rule.value = null;
    });

    this.onFilterChange.emit();
  }
}
