<p-card>
  <ng-template pTemplate="header">
        <span class="header">
          <span class="application-name"> {{currentApplication.name}} </span>
        </span>
  </ng-template>

  <ng-container *ngIf="m.user.application">
    <div class="grid">
      <div class="form-item col-12 md:col-9">
        <span class="pi pi-check-circle icon green"></span>
        User has access to {{currentApplication.name}}
      </div>
      <div class="form-item col-12 md:col-3 ">
        <button
          (click)="userFormConfirmation(userConfirmationType.removeAppAccess, 'Deny access', 'Are you sure you want to deny user\'s access to this application?')"
          pButton label="{{'Deny access' | translate}}" type="button" class="p-button p-button-danger form-button"></button>
      </div>

      <ng-container *ngIf="m.user.application.isAdmin">
        <div class="col-12 md:col-9">
          <span class="pi pi-check-circle icon green"></span>
          User is admin
        </div>
        <div class="form-item col-12 md:col-3">
          <button
            (click)="userFormConfirmation(userConfirmationType.removeAdminRole, 'Remove admin role', 'Are you sure you want remove admin role from this user?')"
            pButton label="{{'Remove admin role' | translate}}" type="button" class="p-button p-button-danger form-button"></button>
        </div>
      </ng-container>

      <ng-container *ngIf="!m.user.application.isAdmin">
        <div class="col-12 md:col-9">
          <span class="pi pi-times-circle icon red"></span>
          User is not admin
        </div>
        <div class="col-12 md:col-3">
          <button (click)="userFormConfirmation(userConfirmationType.addAdminRole, 'Add admin role', 'Are you sure you want make this user an admin?')"
                  pButton label="{{'Add admin role' | translate}}" type="button" class="p-button p-button-success form-button"></button>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <ng-container *ngIf="!m.user.application">
    <div class="grid">
      <div class="form-item col-12 md:col-9">
        <span class="pi pi-times-circle icon red"></span>
        User can not access {{currentApplication.name}}
      </div>
      <div class="col-12 md:col-3">
        <button (click)="userFormConfirmation(userConfirmationType.addAppAccess, 'Add access', 'Are you sure you want to add user to this application?')"
                pButton label="{{'Add user to application' | translate}}" type="button" class="p-button p-button-success form-button"></button>
      </div>
    </div>
  </ng-container>
</p-card>
