import { ListContainer } from '@shared/types/list-container.types';

export class NumberFilterOptionsTypes {
  matchType = new ListContainer<NumberFilterMatchType>();
  ruleType = new ListContainer<NumberFilterRuleType>();

  constructor() {
    this.matchType.setList(Object.keys(NumberFilterMatchType).map(key => NumberFilterMatchType[key as keyof typeof NumberFilterMatchType]));
    this.ruleType.setList(Object.keys(NumberFilterRuleType).map(key => NumberFilterRuleType[key as keyof typeof NumberFilterRuleType]));
  }
}

export enum NumberFilterMatchType {
  matchAll = 'Match all',
  matchAny = 'Match any',
}

export enum NumberFilterRuleType {
  equals = 'Equals',
  notEquals = 'Not equals',
  lessThan = 'Less than',
  lessThanOrEqualTo = 'Less than or equal to',
  greaterThan = 'Greater than',
  greaterThanOrEqualTo = 'Greater than or equal to',
  between = 'Between',
}

export enum NumberFilterMapUrlRuleType {
  equals = 'equals',
  notEquals = 'notEquals',
  lt = 'lessThan',
  lte = 'lessThanOrEqualTo',
  gt = 'greaterThan',
  gte = 'greaterThanOrEqualTo',
  between = 'between',
}
