import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { RuleTriggerType, WorkflowActionCopyLinkValueFromLink, WorkflowTrigger } from '@workflows/types';

@Component({
  selector: 'app-action-link-from-link',
  templateUrl: 'action-link-from-link.component.html',
  styleUrls: ['./action-link-from-link.component.scss', '../../../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLinkFromLinkComponent extends AbstractWorkflowRuleItemComponent implements OnInit {
  @Input() action: WorkflowActionCopyLinkValueFromLink;
  @Input() linkableArtifactTypes: NewArtifactType[];
  @Input() triggers: WorkflowTrigger[];

  linkAttributes: LinkType[];
  targetAttributes: LinkType[];
  targetArtifactTypes: NewArtifactType[];

  constructor(protected translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
    this.action.linkArtifactTypeId =
      this.triggers?.find(trigger => trigger.type === RuleTriggerType.LINK_ADDED)?.artifactTypeId || this.action.linkArtifactTypeId;

    const linkTypes = this.linkTypes.list.filter(item => item.isLinkingBothSourceAndDestination(this.defaultArtifactType.id, this.action.linkArtifactTypeId));
    this.linkAttributes = [...linkTypes];

    if (this.action.sourceLinkTypeId) {
      this.setTargetLinkTypeAttributes();
    }
    if (this.action.targetLinkTypeId) {
      this.setTargetArtifactTypes();
    }
  }

  onLinkAttributeChange(sourceLinkTypeId: string) {
    this.action.targetLinkTypeId = '';
    this.action.sourceLinkTypeId = sourceLinkTypeId;
    this.setTargetLinkTypeAttributes();
  }

  onTargetLinkTypeChange(targetLinkTypeId: string) {
    this.action.targetLinkTypeId = targetLinkTypeId;
    this.setTargetArtifactTypes();
  }

  onTargetArtifactTypeChange(artifactTypeId: string) {
    this.action.targetLinkArtifactTypeId = artifactTypeId;
  }

  private setTargetLinkTypeAttributes() {
    this.targetAttributes = this.linkTypes.list
      .filter(item => item.isLinkingArtifactTypeId(this.defaultArtifactType.id))
      .filter(item => !item.isLinkingArtifactTypeId(this.action.linkArtifactTypeId))
      .filter(item => {
        const targets = item.restrictions.map(restriction =>
          restriction.destinationArtifactTypeId === this.defaultArtifactType.id ? restriction.sourceArtifactTypeId : restriction.destinationArtifactTypeId,
        );
        return (
          targets.filter(
            target => this.linkTypes.list.filter(item => item.isLinkingBothSourceAndDestination(target, this.action.linkArtifactTypeId)).length > 0,
          ).length > 0
        );
      });
  }

  private setTargetArtifactTypes() {
    this.targetArtifactTypes = [];
    if (this.action.targetLinkTypeId) {
      const linkType = this.linkTypes.listMap[this.action.targetLinkTypeId];
      const targetAtIds = linkType.restrictions
        .filter(item => item.destinationArtifactTypeId === this.defaultArtifactType.id || item.sourceArtifactTypeId === this.defaultArtifactType.id)
        .map(item => (item.destinationArtifactTypeId === this.defaultArtifactType.id ? item.sourceArtifactTypeId : item.destinationArtifactTypeId));
      this.targetArtifactTypes = this.artifactTypes.filter(item => targetAtIds.includes(item.id));
    }
  }
}
