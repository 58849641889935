<div class="grid m-2 pb-3">
    <div class="col-11 mb-3 px-0 py-0 picker-header" style="text-transform: capitalize;">{{label}}</div>
    <div class="col-1 mb-3 px-0 py-0"><span class="pi pi-times-circle icon close" (click)="closePicker()"></span></div>
    <div class="col-4 mb-3 px-0 py-0">
        <p-dropdown [options]="memberTypesList" [(ngModel)]="memberType" (ngModelChange)="updateList()"></p-dropdown>
    </div>
    <div class="col-3 px-3 py-0">
        <button *ngIf="selectedItem?.length > 0" (click)="addMembers()" [label]="'Add' | translate"
                class="p-button" pButton type="button"></button>
    </div>
    <div class="col-5 px-3 py-0" *ngIf="selectedItem?.length > 0">
        <p-calendar
            [(ngModel)]="expiration"
            [dateFormat]="dateFormat"
            [showTime]="false"
            [firstDayOfWeek]="userData?.uiConfig?.firstDayOfWeek"
            [placeholder]="'no expiration' | translate"
            [inputStyle]="{ border: 'none', fontSize: '12px', padding: 0 }"
            [inputStyleClass]="'cursor-pointer'"
            appendTo="body"
        >
        </p-calendar>
    </div>
    <p-orderList [value]="itemList" [(selection)]="selectedItem" filterBy="name" controlsPosition="none">
        <ng-template let-item pTemplate="item">
            <div>
                {{item.name}}
            </div>
        </ng-template>
    </p-orderList>
</div>
