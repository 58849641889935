import { Directive, HostListener, Input } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

@Directive({
  selector: '[appWidgetContainerStopPropagation]',
})
export class StopUnwantedClickPropagationDirective {
  private static readonly interactiveWidgets: WidgetType[] = [WidgetType.menu, WidgetType.folder, WidgetType.filter, WidgetType.auth];

  @Input() widget: BlockPartWidget;
  @Input() isPreviewMode: boolean;
  @Input() isParentWidgetClickable: boolean;

  @HostListener('click', ['$event'])
  onWidgetContainerClick($event: MouseEvent): void {
    if (!this.isParentWidgetClickable) {
      return;
    }

    if (this.isPreviewMode) {
      this.stopPropagationFromInteractiveInnerWidgets($event);
    } else {
      this.stopPropagationFromAllInnerWidgets($event);
    }
  }

  private stopPropagationFromInteractiveInnerWidgets($event: MouseEvent): void {
    if (StopUnwantedClickPropagationDirective.interactiveWidgets.includes(this.widget.code)) {
      $event.stopPropagation();
    }
  }

  private stopPropagationFromAllInnerWidgets($event: MouseEvent): void {
    $event.stopPropagation();
  }
}
