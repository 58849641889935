import { WidgetModel } from '../../shared/types/widget-model.interface';
import { ApexChartSettingsDto, ApexChartWidgetSettings } from './apex-chart-widget-settings.types';
import { ApexChartWidgetOptions } from '@widgets/apex-chart/types/apex-chart-widget-options.types';
import { ArtifactGroupItemResponseDto } from '@api/models/artifact-group-item-response-dto';
import { ApexOptions } from 'ng-apexcharts';
import { Params } from '@angular/router';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';

export class ApexChartWidgetValue {
  constructor(public model: ApexChartWidgetModel = new ApexChartWidgetModel()) {}
}

export class ApexChartWidgetModel implements WidgetModel<ApexChartWidgetModelDto> {
  chartOptions: Partial<ApexOptions> | null;
  settings = new ApexChartWidgetSettings();
  options = new ApexChartWidgetOptions();
  id: string;
  chartData: ArtifactGroupItemResponseDto[][];
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  showDateFormat: boolean;
  showUserFormat: boolean;
  urlParams: Params;
  currentUrlParams: Params = {};
  folderId: string | null;
  prevFolderId: string | null;
  resizeObserver: any;

  constructor(dto?: ApexChartWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ApexChartWidgetModelDto {
    return {
      settings: this.settings.toServer(),
    };
  }

  fromDto(dto: ApexChartWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new ApexChartWidgetSettings(dto.settings));
    }
  }
}

export interface ApexChartWidgetModelDto {
  settings: ApexChartSettingsDto;
}

export interface ContainerSize {
  width?: number;
  height?: number | null;
}

export enum ApexChartWidgetTypeChart {
  column = 'column',
  bar = 'bar',
  area = 'area',
}

export enum ApexUserDisplay {
  email = 'email',
  name = 'name',
}

export enum ApexAxisPosition {
  left = 'left',
  right = 'right',
}
