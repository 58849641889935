import { Component, Input } from '@angular/core';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { ListStyleEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-link-type-value-styles-form',
  templateUrl: './link-type-value-styles-form.component.html',
  styleUrls: ['./link-type-value-styles-form.component.scss'],
})
export class LinkTypeValueStylesFormComponent {
  @Input() formatSettings: LinkTypeFormatSettings;

  readonly listStyleOptions = GetSelectOptionsFromEnum(ListStyleEnum);
}
