import { NewSystemTenant } from '@shared/types/tenant.types';

export class TenantModel {
  tenant: NewSystemTenant = new NewSystemTenant();
  inProgress = false;

  constructor(model?: Partial<TenantModel>) {
    model && Object.assign(this, model);
  }
}
