import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';
import { TreeItem } from '@widgets/menu-widget/types/tree-types';

@Pipe({ name: 'isItemInTree' })
export class IsItemInTreePipe implements PipeTransform {
  transform(item: MenuItem | TreeItem): unknown {
    return item instanceof TreeItem;
  }
}
