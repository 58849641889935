import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { ActiveLinkCheckHelper } from '../helpers/active-link-check.helper';
import { ContentStyles, DefaultItemStyles, FontStyles, MenuItemStyles, MenuStylesPropNamesEnum } from '../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../types/menu-widget.types';

@Pipe({ name: 'getContentStyles' })
export class GetContentStylesPipe implements PipeTransform {
  constructor(private readonly activeLinkCheckHelper: ActiveLinkCheckHelper) {}

  transform(
    m: MenuWidgetModel,
    item: MenuItem,
    styleProp: MenuStylesPropNamesEnum,
    mouseOvered: boolean,
    queryParams: Params,
  ): FontStyles | MenuItemStyles | ContentStyles {
    const isInFirstLevel = new IsItemInFirstLevelPipe().transform(m.items.menu, item);

    // TODO remake all the logic :(
    if (mouseOvered) {
      if (this.shouldReturnDefaultStyle(item.usesDefaultStyle, styleProp)) {
        return this.getDefaultStyleByLevel(m, isInFirstLevel).hoverMenuItemStyle[styleProp];
      } else {
        return m.styles.hoverStyles[styleProp][item.hash];
      }
    } else if (this.activeLinkCheckHelper.isRouterLinkActive(item, m.items.menu, queryParams)) {
      if (this.shouldReturnDefaultStyle(item.usesDefaultStyle, styleProp)) {
        return this.getDefaultStyleByLevel(m, isInFirstLevel).activeMenuItemStyle[styleProp];
      } else {
        return m.styles.activeStyles[styleProp][item.hash];
      }
    }
    if (this.shouldReturnDefaultStyle(item.usesDefaultStyle, styleProp)) {
      return this.getDefaultStyleByLevel(m, isInFirstLevel).standardMenuItemStyle[styleProp];
    } else {
      return m.styles.standardStyles[styleProp][item.hash];
    }
  }

  private shouldReturnDefaultStyle(usesDefaultStyle: boolean, stylePropName: MenuStylesPropNamesEnum): boolean {
    return usesDefaultStyle && stylePropName !== MenuStylesPropNamesEnum.chip && stylePropName !== MenuStylesPropNamesEnum.icon;
  }

  private getDefaultStyleByLevel(m: MenuWidgetModel, isInFirstLevel: boolean): DefaultItemStyles {
    return isInFirstLevel ? m.styles.defaultFirstLevelItemStyles : m.styles.defaultSubMenuItemStyles;
  }

  private isItemInFirstLevel(items: MenuItem[], item: MenuItem): boolean {
    return new IsItemInFirstLevelPipe().transform(items, item);
  }
}
