import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IconsService } from '@shared/services/icons.service';
import { SelectOption } from '@shared/types/shared.types';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { StyleCopyPastingService } from '@widgets/menu-widget/services/style-copy-pasting.service';
import { ChipStyles, FontStyles, IconStyles, MenuStylesPropNamesEnum } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-default-menu-item-button-form',
  templateUrl: './default-menu-item-button-form.component.html',
  styleUrls: ['./default-menu-item-button-form.component.scss'],
})
export class DefaultMenuItemButtonFormComponent implements OnInit, OnDestroy {
  @Input() s: MenuWidgetService;
  @Input() m: MenuWidgetModel;
  iconOptions: SelectOption<string, string>[] = [];
  activeIndex = 0;
  menuStylesPropNamesEnum = MenuStylesPropNamesEnum;
  selectedLabel: string;
  private selectedContentType: MenuStylesPropNamesEnum;
  private iconSub: Subscription;

  constructor(
    public readonly styleCopyService: StyleCopyPastingService,
    private readonly iconService: IconsService,
    public readonly menuWidgetHelper: MenuWidgetHelper,
  ) {}

  ngOnInit(): void {
    this.selectItemStyle();
    this.iconSub = this.iconService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => (this.iconOptions = iconOptions));
  }

  ngOnDestroy(): void {
    this.iconSub.unsubscribe();
  }

  selectContent(contentType: MenuStylesPropNamesEnum): void {
    this.updateSelectedStyle();
    if (contentType) {
      this.selectedContentType = contentType;
      this.selectedLabel = contentType.replace(/^./, contentType[0].toUpperCase());
      if (this.m.selected.defaultStyle && this.menuWidgetHelper.canPropUseDefaultStyles(contentType)) {
        this.m.selected.contentStyle = this.m.selected.defaultStyle[contentType] as FontStyles | IconStyles | ChipStyles;
      }
      if (this.m.selected.style && this.m.selected.menuItem && !this.menuWidgetHelper.canPropUseDefaultStyles(contentType)) {
        this.m.selected.contentStyle = this.m.selected.style[contentType][this.m.selected.menuItem.hash] as FontStyles | IconStyles | ChipStyles;
      }
    }
  }

  selectItemStyle(): void {
    this.updateSelectedStyle();
    this.selectContent(this.selectedContentType);
  }

  updateSelectedStyle(): void {
    const defaultLevel = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, this.m.selected.menuItem);
    switch (this.activeIndex) {
      case 0:
        this.m.selected.defaultStyle = defaultLevel.standardMenuItemStyle;
        this.m.selected.style = this.m.styles.standardStyles;
        break;
      case 1:
        this.m.selected.defaultStyle = defaultLevel.hoverMenuItemStyle;
        this.m.selected.style = this.m.styles.hoverStyles;
        break;
      case 2:
        this.m.selected.defaultStyle = defaultLevel.activeMenuItemStyle;
        this.m.selected.style = this.m.styles.activeStyles;
        break;
    }
  }

  selectGenStyle(): void {
    const defaultLevel = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, this.m.selected.menuItem);
    this.m.selected.defaultGeneralStyle = defaultLevel.generalMenuItemStyles;
  }
}
