import { ParsedExportResponseDto } from '@api/models';
import { RecordDto } from '@api/models/record-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TableColumn } from '@shared/types/table.types';

export enum TemplateType {
  section = 'SECTION',
  row = 'ROW',
  block = 'BLOCK',
  blockPart = 'BLOCK_PART',
  widget = 'WIDGET',
}

export enum TemplatesCloseResultRole {
  close,
  generateSection,
  reuseTemplate,
  copyTemplate,
}

export interface TemplatesCloseResult {
  role: TemplatesCloseResultRole;
  templates?: TemplateResponseDto[];
  sectionScheme?: string[];
}

export class Template implements TemplateResponseDto {
  id: string;
  applicationId: string;
  name: string;
  description: string;
  created: RecordDto;
  deleted: RecordDto | null;
  icon: string | null;
  template: any;
  categories: string[];
  thumbnailFileArtifactId: string | null;
  type: TemplateType;
  updated: RecordDto;

  private constructor(dto: TemplateResponseDto) {
    Object.assign(this, dto);
  }

  static fromDto(dto: TemplateResponseDto): Template {
    return new Template(dto);
  }
}

export class TemplatesModel {
  templateType: 'external' | 'internal' = 'internal';
  previousTemplateType: 'external' | 'internal' = 'internal';
  selectedCategory: string;

  templateToDetail: { response: TemplateResponseDto; detailType: 'uploaded' | 'api' } | null = null;
  importedFile: File | null = null;
  parsedFile: ParsedExportResponseDto | null = null;

  constructor(public templates: Template[] = [], public columns: TableColumn[] = []) {}
}
