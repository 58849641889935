import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './info.component';
import { DisplayAtOverlayModule } from '@shared/components/common-display-at';

@NgModule({
  declarations: [InfoComponent],
  imports: [CommonModule, DisplayAtOverlayModule],
  exports: [InfoComponent],
})
export class InfoModule {}
