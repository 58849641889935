import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NAME_KEY } from '@shared/constants/constants';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DropdownModule } from 'primeng/dropdown';
import { ApplicationSwitcherService } from './services/application-switcher.service';

@Component({
  selector: 'app-application-switcher',
  templateUrl: './application-switcher.component.html',
  styleUrls: ['./application-switcher.component.scss'],
})
export class ApplicationSwitcherComponent implements OnInit {
  NAME_VALUE = NAME_KEY;

  constructor(public readonly s: ApplicationSwitcherService, private readonly cache: NewCacheService) {}

  async ngOnInit(): Promise<void> {
    await this.setApplications();
  }

  private async setApplications(): Promise<void> {
    const user = this.cache.user.value as SelfUserResponseDto;
    this.cache.data.applications.subscribe(applications => this.s.setApplications(applications as ApplicationResponseDto[], user));
  }
}

@NgModule({
  declarations: [ApplicationSwitcherComponent],
  imports: [CommonModule, DropdownModule, FormsModule, CommonPipesModule],
  exports: [ApplicationSwitcherComponent],
})
export class ApplicationSwitcherModule {}
