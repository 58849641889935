import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective {
  static readonly DELAY = 100;

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.host.nativeElement.focus();
    }, AutofocusDirective.DELAY);
  }
}
