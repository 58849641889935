<div *ngIf="isChipStyle || isIconStyle" class="grid">
  <div class="col-12 md:col-6 lg:col-6">
    <h6>Order</h6>
    <span class="dynamic-label">
      <input [(ngModel)]="contentStyle.order" id="order" pInputText type="text" />
    </span>
  </div>

  <div *ngIf="isChipStyle" class="col-12 md:col-6 lg:col-6">
    <span class="dynamic-label">
    <app-color-picker [(color)]="contentStyle.backgroundColor" label="Background color"></app-color-picker>
    </span>
  </div>
</div>

<app-font-style-form
        [fontStyles]="contentStyle"
        [hideFontFamilySettings]="isIconStyle"
        [showBorderSettings]="isIconStyle"
        [hideHorizontalAlignSettings]="isIconStyle"
></app-font-style-form>

<button *ngIf="isChipStyle" (click)="borderOp.toggle($event)"
        class="mr-2" icon="bi bi-border-style" label="{{'Borders' | translate }}" pButton type="button"></button>

<button (click)="styleService.copyContentStyles(contentStyle)"
        class="mr-2" icon="pi pi-copy" pButton pTooltip="{{'Copy style' | translate}}" type="button"></button>

<button *ngIf="styleService.copiedContentStyles" (click)="styleService.pasteStyleIntoContent(contentStyle)"
        icon="bi bi-clipboard-plus" pButton pTooltip="{{'Paste style'| translate}}" type="button"></button>

<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [styles]="contentStyle"></app-border-form>
  </ng-template>
</p-overlayPanel>
