import { Component, Input } from '@angular/core';
import { EMAIL_LABEL, IS_SYSTEM_ADMIN_LABEL, IS_TENANT_ADMIN_LABEL, TENANT_LABEL } from '@shared/constants/constants';
import { SystemUserPageModel } from '../../types/system-user.types';

@Component({
  selector: 'app-system-admin-user-form',
  templateUrl: './system-user-form.component.html',
  styleUrls: ['./system-user-form.component.scss'],
})
export class SystemUserFormComponent {
  @Input() m: SystemUserPageModel;

  EMAIL_LABEL = EMAIL_LABEL;
  TENANT_LABEL = TENANT_LABEL;
  IS_TENANT_ADMIN_LABEL = IS_TENANT_ADMIN_LABEL;
  IS_SYSTEM_ADMIN_LABEL = IS_SYSTEM_ADMIN_LABEL;
}
