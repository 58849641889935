import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';

@Injectable({
  providedIn: 'root'
})
export class SidebarModalSharedService {
  private data: bodyPaddings = { top: {}, bottom: {}, left: {}, right: {} };
  private renderer: Renderer2;

  constructor(
      rendererFactory: RendererFactory2,
      private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
      ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setPadding(modalId: string, direction: paddingDirection, value: number): void {
    const maxPadding = this.getMaxPaddingByDirection(direction);
    value > maxPadding && this.applyPadding(direction, value);
    this.data[direction][modalId] = value;
  }

  resetPadding(modalId: string, direction: paddingDirection): void {
    delete this.data[direction][modalId];
    const maxPadding = this.getMaxPaddingByDirection(direction);
    this.applyPadding(direction, maxPadding);
  }

  private onContentResize(): void {
    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.onContentResize, null);
  }

  private applyPadding(direction: paddingDirection, value: number): void {
    const pageEl = document.getElementsByClassName('page')[0];
    pageEl && this.renderer.setStyle(pageEl, `padding-${direction}`, value + 'px');
    this.onContentResize();
  }

  private getMaxPaddingByDirection(direction: paddingDirection): number {
    let maxValue = 0;
    Object.keys(this.data[direction]).forEach(key => {
      const val = this.data[direction][key];
      maxValue < val && (maxValue = val);
    })
    return maxValue;
  }
}

export enum paddingDirection {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface bodyPaddings {
  top: Record<string, number>;
  bottom: Record<string, number>;
  left: Record<string, number>;
  right: Record<string, number>;
}
