import { Pipe, PipeTransform } from '@angular/core';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactWidgetType } from '@widgets/artifact-widget/types/artifact-widget.types';

@Pipe({ name: 'shouldShowArtifactWidgetSettings' })
export class ShouldShowArtifactWidgetSettingsPipe implements PipeTransform {
  transform(widgetType: ArtifactWidgetType, linkType: LinkType): boolean {
    return (
      widgetType === ArtifactWidgetType.login ||
      widgetType === ArtifactWidgetType.recoverPassword ||
      widgetType === ArtifactWidgetType.registration ||
      widgetType === ArtifactWidgetType.artifact ||
      (widgetType === ArtifactWidgetType.linkedArtifact && !!linkType)
    );
  }
}
