import { Pipe, PipeTransform } from '@angular/core';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewUser } from '@shared/types/user.types';

/**
 * Applicable only for user data types.
 */
@Pipe({ name: 'toUser' })
export class ToUserPipe implements PipeTransform {
  transform(artifact: NewArtifact, key: string, usersMap: Record<string, NewUser>): string {
    const rawUserValue = NonAttributeKeys.isUserSpecificAttributeKeyOrId(key) ? this.forSystemUser(key, artifact) : artifact.attributes[key]?.value;
    if (!rawUserValue) {
      return '';
    }
    return Array.isArray(rawUserValue) ? rawUserValue.map(value => this.getUserEmail(value, usersMap)).join(', ') : this.getUserEmail(rawUserValue, usersMap);
  }

  private getUserEmail(value: string | SelectOption<string, string>, usersMap: Record<string, NewUser>): string {
    return typeof value === 'string' ? usersMap[value]?.email || '' : usersMap[value.value]?.email;
  }

  private forSystemUser(key: string, artifact: NewArtifact) {
    let userKey = key;
    if (NonAttributeKeys.isUserSpecificAttributeId(key)) {
      userKey = key === NonAttributeKeys.CREATED_BY_ID ? NonAttributeKeys.CREATED_BY : NonAttributeKeys.UPDATED_BY;
    }
    return SharedMethods.getValueFromPath(userKey, artifact);
  }
}
