<div class="chunk" *ngIf="selected">
  <ng-container *ngFor="let rule of selected.ruleTypes; let i = index">
    <p-dropdown (onChange)="onRuleTypeChange($event.value, i)"
                [(ngModel)]="rule.ruleType"
                [options]="options.ruleType"
                [autoDisplayFirst]="false"
                appendTo="body"
                [filter]="true">
      <ng-template pTemplate="selectedItem" let-selected> {{selected.label | translate}} </ng-template>
      <ng-template let-option pTemplate="item"> {{option.label | translate}} </ng-template>
    </p-dropdown>

    <div class="values-row">
      <p-inputNumber *ngIf="rule.ruleType | isDatetimeFilterNumericType"
                     [(ngModel)]="rule.value"
                     (ngModelChange)="onChange()"
                     [placeholder]="'Set number of days' | translate">
      </p-inputNumber>

      <p-calendar *ngIf="rule.ruleType | isDatetimeFilterCalendarType"
                  (ngModelChange)="onChange()"
                  [(ngModel)]="rule.value"
                  [dateFormat]="dateFormat"
                  [timeOnly]="settings.timeOnly"
                  [showTime]="settings.showTime"
                  [firstDayOfWeek]="firstDayOfWeek"
                  appendTo="body"
                  [placeholder]="placeholder | translate">
      </p-calendar>

      <ng-container *ngIf="rule.ruleType | isDatetimeFilterRangeType">
        <p-calendar (onSelect)="onChangeRange(rule, true)"
                    [(ngModel)]="rule.value[0]"
                    [dateFormat]="dateFormat"
                    [timeOnly]="settings.timeOnly"
                    [showTime]="settings.showTime"
                    [firstDayOfWeek]="firstDayOfWeek"
                    appendTo="body"
                    [placeholder]="'From' | translate">
        </p-calendar>

        <p-calendar (onSelect)="onChangeRange(rule)"
                    [(ngModel)]="rule.value[1]"
                    [dateFormat]="dateFormat"
                    [timeOnly]="settings.timeOnly"
                    [showTime]="settings.showTime"
                    [firstDayOfWeek]="firstDayOfWeek"
                    [disabled]="!rule.value[0]"
                    [minDate]="rule.value[0]"
                    appendTo="body"
                    [placeholder]="'to' | translate">
        </p-calendar>
      </ng-container>

      <button (click)="onClear(i)" pButton [label]="'Clear' | translate" class="p-button p-button-outlined" iconPos="left"></button>
    </div>

    <p *ngIf="selected?.ruleTypes.length > 1" class="rule rule-rm" (click)="removeRule(i)">Remove rule</p>
  </ng-container>
</div>
<!--<p *ngIf="selected?.ruleTypes?.length < 2" class="rule" (click)="addRule()">+ Add rule</p>-->
