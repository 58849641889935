import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AttributesFormComponent } from '@shared/components/bulk-artifact-edit-popup/components/attributes-form/attributes-form.component';
import { AttributesSelectorComponent } from '@shared/components/bulk-artifact-edit-popup/components/attributes-selector/attributes-selector.component';
import { AttributesService } from '@shared/components/bulk-artifact-edit-popup/services/attributes.service';
import { BulkArtifactEditService } from '@shared/components/bulk-artifact-edit-popup/services/bulk-artifact-edit.service';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { BulkOperationResult } from '@shared/components/bulk-artifact-edit-popup/types/bulk-operation-result';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, RippleModule, BlockUIModule, AttributesSelectorComponent, AttributesFormComponent, LoaderModule],
  selector: 'app-bulk-artifact-edit-popup',
  templateUrl: './bulk-artifact-edit-popup.component.html',
  styleUrls: ['./bulk-artifact-edit-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AttributesService, BulkArtifactEditService],
})
export class BulkArtifactEditPopupComponent implements OnInit {
  attributes: BulkEditingAttribute[] = [];
  uiBlocked$: Observable<boolean> = inject(BulkArtifactEditService).inProgress$;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly attributesService: AttributesService,
    private readonly announcement: AnnouncementService,
  ) {}

  ngOnInit(): void {
    this.attributes = this.attributesService.getBulkEditingAttributes();
  }

  onCancel(): void {
    this.ref.close();
  }

  async onSave({ success, failure, skip }: BulkOperationResult): Promise<void> {
    this.ref.close(true);
    await this.announcement.info(`Success: ${success}. Failure: ${failure}. Skip: ${skip}.`);
  }
}
