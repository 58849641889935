import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { ActiveLinkCheckHelper } from '@widgets/menu-widget/helpers/active-link-check.helper';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { ContentStyles, FontStyles, MenuItemGeneralStyles, MenuItemStyles, MenuStylesPropNamesEnum } from '../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../types/menu-widget.types';
import { GetContentStylesPipe } from './get-content-styles.pipe';

@Pipe({ name: 'getMenuItemStyles' })
export class GetMenuItemStylesPipe implements PipeTransform {
  constructor(private readonly activeLinkCheckHelper: ActiveLinkCheckHelper) {}

  transform(
    m: MenuWidgetModel,
    item: MenuItem,
    generalStyle: MenuItemGeneralStyles,
    mouseOvered: boolean,
    queryParams: Params,
  ): MenuItemStyles | FontStyles | ContentStyles {
    const generatedStyle = new GetContentStylesPipe(this.activeLinkCheckHelper).transform(m, item, MenuStylesPropNamesEnum.item, mouseOvered, queryParams);

    if (!item.usesDefaultStyle) this.pasteSameProps(generalStyle, generatedStyle);
    else {
      const defaultItemStyles = new IsItemInFirstLevelPipe().transform(m.items.menu, item)
        ? m.styles.defaultFirstLevelItemStyles
        : m.styles.defaultSubMenuItemStyles;
      this.pasteSameProps(defaultItemStyles.generalMenuItemStyles, generatedStyle);
    }
    return generatedStyle;
  }

  // TODO remake, VERY BAD LOGIC
  pasteSameProps(from: MenuItemGeneralStyles, to: FontStyles | MenuItemStyles | ContentStyles): void {
    if (from && to)
      Object.keys(from).forEach(property => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        to[property] = from[property];
      });
  }
}
