import { Component, Input } from '@angular/core';
import { MenuWidgetOptions } from '../../../../../../types/menu-option.types';
import { VerticalDividerStyles } from '../../../../../../types/menu-widget-styles.types';

@Component({
  selector: 'app-divider-vertical-form',
  templateUrl: './divider-vertical-form.component.html',
  styleUrls: ['./divider-vertical-form.component.scss'],
})
export class DividerVerticalFormComponent {
  @Input() options: MenuWidgetOptions;
  @Input() dividerStyle: VerticalDividerStyles;
}
