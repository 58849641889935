<div *ngFor="let inputData of trigger.inputDataClientArray; let index = index">
  <div class="row">
    <div class="col-6">
      <input [(ngModel)]="inputData.key" pInputText type="text" class="input-min-width" placeholder="Enter key" />
    </div>
    <div class="col-2 center">
      <p-checkbox [(ngModel)]="inputData.isArray" binary="true" class="mr-2"></p-checkbox>
      {{ 'is array' | translate }}
    </div>
    <div class="col-2 center">
      <p-checkbox [(ngModel)]="inputData.isOptional" binary="true" class="mr-2"></p-checkbox>
      {{ 'is optional' | translate }}
    </div>
    <div class="col-1">
      <div class="row">
        <button *ngIf="index === trigger.inputDataClientArray.length - 1" class="p-button p-button-text" pButton type="button" icon="pi pi-plus"
                (click)="onAddInput()"></button>
        <button *ngIf="trigger.inputDataClientArray.length > 1" class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-trash"
                (click)="onRemoveInput(index)"></button>
      </div>
    </div>
  </div>
</div>
