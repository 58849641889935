<span class="p-float-label">
    <p-dropdown [options]="languageSwitcherService.languages"
                [autoDisplayFirst]="false"
                [(ngModel)]="languageSwitcherService.selectedLanguage"
                (ngModelChange)="languageSwitcherService.changeLanguage($event)"
                [inputId]="'languageSwitcher'">
        <ng-template let-item pTemplate="selectedItem">
            <div class="language-switcher-item">
                <div class="flag {{item.label === 'en' ? 'flag-uk' : 'flag-' + item.value}}"></div>
                <span>{{item.label | translate}}</span>
            </div>
        </ng-template>
        <ng-template let-status pTemplate="item">
            <div class="language-switcher-item">
                <div class="flag {{status.label === 'en' ? 'flag-uk' : 'flag-' + status.value}}"></div>
                <span>{{status.label | translate}}</span>
            </div>
        </ng-template>
    </p-dropdown>
</span>
