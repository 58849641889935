<app-workflow-attribute-selector
  [selectedAttributeId]="action.linkAttributeId"
  [attributes]="linkAttributes"
  (attributeChange)="onLinkAttributeChange($event)"
  [editable]="editable"
>
</app-workflow-attribute-selector>

<ng-container *ngIf="action.linkAttributeId">
  {{ 'WORKFLOWS.COMMON.SET_INTO' | translate }}
  <app-workflow-attribute-selector
    [selectedAttributeId]="action.attributeId"
    [attributes]="selectedAttributes | getWorkflowActionAttributes : dataTypes : action.getSupportedDataTypes() : action.getSupportedDataKinds()"
    (attributeChange)="onAttributeChange($event)"
    [editable]="editable"
  >
  </app-workflow-attribute-selector>
</ng-container>
