import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { FadeAnimation } from '@shared/animations/animations';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem, GroupCollapseEnum, PaginationSettingEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-grouping.types';
import { ListWidgetOptions } from '@widgets/shared/components/artifact-list-table/types/list-widget-options.types';
import { ListWidgetSelected } from '@widgets/shared/components/artifact-list-table/types/list-widget-selected.types';
import { ListWidgetTableSettings } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { ListWidgetType } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';

@Component({
  selector: 'app-list-widget-table-settings',
  templateUrl: './list-widget-table-settings.component.html',
  styleUrls: ['./list-widget-table-settings.component.scss'],
  animations: [FadeAnimation],
})
export class ListWidgetTableSettingsComponent implements OnInit {
  @Input() settings: ListWidgetTableSettings;
  @Input() options: ListWidgetOptions;
  @Input() selected: ListWidgetSelected;
  @Input() listType: ListWidgetType;
  @Input() pages: ListContainer<Page>;
  @Input() onGroupAttributeChangeCb: (groupAttribute: GroupAttributeItem | undefined) => void;
  @Output() multiselectChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  pagination = PaginationSettingEnum;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;
  clickActionSettingsOptions: ClickActionSettingsOptions;

  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  constructor(private readonly cache: NewCacheService) {}

  get shouldShowAddButtonSetting(): boolean {
    return this.listType === ListWidgetType.artifact;
  }

  get shouldShowNumberOfSelectionSetting(): boolean {
    return this.settings.rowClickHandle && this.settings.rowClickHandleAction === ArtifactListItemClickAction.selectItem && this.settings.multiselect;
  }

  ngOnInit(): void {
    this.clickActionSettingsOptions = {
      attributes: this.options.attributes.list,
      dataTypes: this.options.dataTypes.list,
      pages: this.options.pages.toSelectOptions('name', 'id'),
      users: this.options.users,
    };

    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  onGroupByChange(groupAttribute: SelectOption<string, GroupAttributeItem>): void {
    this.setExpandAllOptionsDisability();
    this.onGroupAttributeChangeCb?.(groupAttribute?.value);
  }

  forceTableReRender(): void {
    this.settings.showTable = false;
    setTimeout(() => (this.settings.showTable = true));
  }

  onPaginationChange(paginationValue: PaginationSettingEnum): void {
    const expandAllOptionEnabled = paginationValue === PaginationSettingEnum.perTable;
    this.setExpandAllOptionsEnabled(expandAllOptionEnabled);
    if (!expandAllOptionEnabled) {
      this.uncheckExpandAll();
    }
  }

  onMinDecimalPlacesChange(value: any): void {
    if (value === null) this.settings.minDecimalPlacesOfSum = undefined;
  }

  onMaxDecimalPlacesChange(value: any): void {
    if (value === null) this.settings.maxDecimalPlacesOfSum = undefined;
  }

  private setExpandAllOptionsDisability(): void {
    this.setExpandAllOptionsEnabled(this.settings.grouping.pagination === PaginationSettingEnum.perTable);
  }

  private setExpandAllOptionsEnabled(enabled: boolean): void {
    const expandAllOption = this.options.groupCollapse.find(option => option.value === GroupCollapseEnum.expandAll);
    expandAllOption && (expandAllOption.disabled = !enabled);
  }

  private uncheckExpandAll(): void {
    if (this.settings.grouping.groupCollapse === GroupCollapseEnum.expandAll) {
      this.settings.grouping.groupCollapse = GroupCollapseEnum.collapseAll;
    }
  }
}
