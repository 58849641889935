import { Injectable } from '@angular/core';
import { GroupItemResponseDto, ParsedExportResponseDto, TemplateResponseDto } from '@api/models';
import { TenantExportImportService, TenantTemplateService } from '@api/services';
import { TemplatesComponent } from '@shared/components/templates/templates.component';
import { TemplatesCloseResult, TemplatesCloseResultRole, TemplatesModel } from '@shared/components/templates/types/templates.types';
import { CoreService } from '@shared/core/services/core.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TemplatesService extends CoreService<TemplatesComponent, TemplatesModel> {
  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly tenantExportImportService: TenantExportImportService,
    private readonly announcement: AnnouncementService,
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  init(context: TemplatesComponent, model: TemplatesModel): void {
    super.init(context, model);
  }

  doFilter(table: Table): void {
    table && table.filter(null, '', '');
  }

  close(): void {
    const result: TemplatesCloseResult = { role: TemplatesCloseResultRole.close };
    this.ref.close(result);
  }

  onGenerate(sectionScheme: string[]): void {
    const result: TemplatesCloseResult = { role: TemplatesCloseResultRole.generateSection, sectionScheme };
    this.ref.close(result);
  }

  async onImportOnly(): Promise<void> {
    if (!this.m.importedFile) {
      return;
    }
    await this.importTemplate(this.m.importedFile);
    this.close();
  }

  async onImportAndReuse(): Promise<void> {
    if (!this.m.importedFile) {
      return;
    }
    const res = await this.importTemplate(this.m.importedFile);
    if (res == null) {
      return;
    }
    this.onReuse(res[0]);
  }

  async onImportAndCopy(): Promise<void> {
    if (!this.m.importedFile) {
      return;
    }
    const res = await this.importTemplate(this.m.importedFile);
    if (res == null) {
      return;
    }
    this.onCopy(res[0]);
  }

  onDetail(template: TemplateResponseDto | null): void {
    this.m.templateToDetail = template === null ? null : { response: template, detailType: 'api' };
    this.m.parsedFile = null;
  }

  onReuse(template: TemplateResponseDto): void {
    const result: TemplatesCloseResult = { role: TemplatesCloseResultRole.reuseTemplate, templates: [template] };
    this.ref.close(result);
  }

  onCopy(template: TemplateResponseDto): void {
    const result: TemplatesCloseResult = { role: TemplatesCloseResultRole.copyTemplate, templates: [template] };
    this.ref.close(result);
  }

  loadTemplateCategories(
    requestMeta: Record<string, string>,
    changeCategoryHandler: (e: { item: MenuItem; originalEvent: PointerEvent }) => void,
  ): Promise<MenuItem[]> {
    const categoryMenuItems = [
      {
        label: 'Categories',
        icon: 'pi pi-fw pi-plus',
        items: [] as MenuItem[],
      },
    ];

    if (this.m.templateType === 'internal') {
      return lastValueFrom(this.tenantTemplateService.templateControllerGroupby({ groupBy: 'categories', ...requestMeta }))
        .then(res => {
          categoryMenuItems[0].items = [
            {
              label: `<span>All</span><span>${res.meta.totalCount}</span>`,
              icon: 'pi pi-folder',
              state: { id: undefined, count: res.meta.totalCount },
              escape: false,
              command: (e: any) => changeCategoryHandler(e),
              styleClass: 'active',
            },
            ...res.data.map((category: GroupItemResponseDto) => {
              return {
                label: `<span>${category.value}</span><span>${category.count}</span>`,
                icon: 'pi pi-folder',
                state: { id: category.value, count: category.count },
                escape: false,
                command: (e: any) => changeCategoryHandler(e),
              };
            }),
          ];
          return categoryMenuItems;
        })
        .catch(async (error: any) => {
          console.log(error);
          await this.announcement.error('Unable to load template categories');
          return categoryMenuItems;
        });
    }
    //TODO add external templates categories
    return Promise.resolve(categoryMenuItems);
  }

  async detailFile({ files }: { files: Array<File> }, importField: FileUpload): Promise<void> {
    if (!files || !files.length) {
      await this.announcement.error('You need to choose file first!');
      return;
    }

    this.m.templateToDetail = {
      response: {
        name: 'Uploaded from file',
        description: 'No additional info about this template.',
      } as TemplateResponseDto,
      detailType: 'uploaded',
    };
    this.m.importedFile = files[0];
    this.m.parsedFile = await this.parseExportFile(this.m.importedFile);

    if (this.m.parsedFile === null) {
      await this.announcement.error('Unable to parse your file');
    }

    importField.clear();
  }

  private async parseExportFile(file: File): Promise<ParsedExportResponseDto | null> {
    this.setUiBlocked(true);
    let res = null;
    try {
      res = await lastValueFrom(this.tenantExportImportService.exportImportControllerParseImport({ body: { file: new Blob([file], { type: 'text/yaml' }) } }));
    } catch (e) {
      console.log(e);
      await this.announcement.error('Something went wrong during parsing export file');
    }
    this.setUiBlocked(false);
    return res;
  }

  private async importTemplate(file: File): Promise<TemplateResponseDto[] | null> {
    this.setUiBlocked(true);
    let res = null;
    try {
      res = (await lastValueFrom(
        this.tenantExportImportService.exportImportControllerImport({ body: { file: new Blob([file], { type: 'text/yaml' }) } }),
      )) as TemplateResponseDto[];
    } catch (e) {
      console.log(e);
      await this.announcement.error('Something went wrong during template import process');
    }
    this.setUiBlocked(false);
    return res;
  }

  private setUiBlocked(blocked: boolean): void {
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }
}
