import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { IsDate, IsDateTime } from '@shared/methods/data-type.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem } from '../components/artifact-list-table/types/list-widget-grouping.types';

@Pipe({ name: 'isGroupingByDate' })
export class IsGroupingByDatePipe implements PipeTransform {
  transform(groupAttributeOption: SelectOption<string, GroupAttributeItem>[]): boolean {
    if (!groupAttributeOption.length) return false;
    const groupAttribute = groupAttributeOption[0].value;
    return NonAttributeKeys.isDateSpecificAttributeKey(groupAttribute.id) || IsDate(groupAttribute.baseDataType) || IsDateTime(groupAttribute.baseDataType);
  }
}

@NgModule({
  declarations: [IsGroupingByDatePipe],
  exports: [IsGroupingByDatePipe, IsGroupingByDatePipe],
})
export class IsGroupingByDatePipeModule {}
