import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { NewUser } from '@shared/types/user.types';
import { DateUtil } from '@shared/utils/date.util';
import { ObjectUtil } from '@shared/utils/object.util';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-artifact-list-table',
  templateUrl: './artifact-list-table.component.html',
  styleUrls: ['./artifact-list-table.component.scss'],
})
export class ArtifactListTableComponent extends CoreListComponent<NewArtifact> implements OnInit {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<NewArtifact>>;
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() artifactType: NewArtifactType;
  @Input() artifactTypes: ListContainer<NewArtifactType>;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() users: ListContainer<NewUser>;

  attributeIds: string[] = [];

  constructor(dateUtil: DateUtil, public readonly objectU: ObjectUtil, public readonly applicationSwitcherService: ApplicationSwitcherService) {
    super(dateUtil);
  }

  async onLazyLoad(event: LazyLoadEvent | null = null): Promise<void> {
    await super.onLazyLoad(event);

    try {
      this.loading = true;
      const attributeIds = new Set<string>();
      this.data.forEach(({ attributes }) => Object.keys(attributes).forEach(id => attributeIds.add(id)));
      this.attributeIds = [...attributeIds];

      this.data = this.data.map(artifact => new NewArtifact({ dto: artifact, artifactTypesMap: this.artifactTypes.listMap }));
    } finally {
      this.loading = false;
    }
  }

  protected onDestroy(): void {
    super.onDestroy();
  }
}
