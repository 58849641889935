import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { WidgetListComponent } from './widget-list.component';

@NgModule({
  declarations: [WidgetListComponent],
  imports: [CommonModule, DragDropModule, ButtonModule, RippleModule, TranslateModule],
  exports: [WidgetListComponent],
})
export class WidgetListModule {}
