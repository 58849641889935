<div class="templates_header">
  <div>
    <button
      pButton
      icon="pi pi-list"
      class="p-button-rounded"
      (click)="m.selectTemplates === true ? (m.selectTemplates = false) : (m.selectTemplates = true)"
    ></button>
  </div>

  <div class="templates_header_buttons">
    <div class="mr">
      <button pButton [label]="'Export' | translate" icon="pi pi-cloud-download" (click)="m.exportModalOpened = true"></button>
    </div>
    <app-export-modal
      headerTitle="Export templates"
      [(visible)]="m.exportModalOpened"
      [selectedParts]="table?.selectedTemplates || []"
      [exportPartsMethod]="s.getExportMethod()"
    ></app-export-modal>

    <div class="mr">
      <button pButton [label]="'Import' | translate" icon="pi pi-cloud-upload" (click)="m.importModalOpened = true"></button>
    </div>
    <app-import-modal
      headerTitle="Import templates"
      inputTypeCheck="template"
      [(visible)]="m.importModalOpened"
      [importPartsMethod]="s.getImportMethod()"
    ></app-import-modal>

    <!-- LEGACY -->
    <p-fileUpload
      (uploadHandler)="upload($event, fileUpload)"
      #fileUpload
      [customUpload]="true"
      [auto]="true"
      chooseIcon="pi pi-upload"
      mode="basic"
      chooseLabel="{{ 'Upload' | translate }}"
      accept=".json"
      styleClass="p-button-secondary"
    ></p-fileUpload>
  </div>
</div>

<app-templates-table [columns]="m.columns" [actionButtonsTemplate]="actionButtons" [selectTemplates]="m.selectTemplates" [loadDataMethod]="loadDataMethod">
  <ng-template #actionButtons let-template>
    <button
      (click)="download(template)"
      class="p-button p-button-warning"
      icon="pi pi-download"
      iconPos="left"
      label="{{ 'Download' | translate }}"
      pButton
      type="button"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      class="p-button p-button-rounded p-button-text"
      icon="pi pi-ellipsis-v"
      (click)="openPanel(template, op, $event)"
    ></button>
  </ng-template>
</app-templates-table>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="table_buttons">
      <button
        pButton
        class="p-button p-button-rounded p-button-outlined"
        type="button"
        pTooltip="{{ 'Info' | translate }}"
        icon="pi pi-info"
        iconPos="left"
        (click)="showWhereUsedInfo(info, $event)"
      ></button>
      <button
        pButton
        class="p-button p-button-rounded p-button-warning p-button-outlined"
        type="button"
        pTooltip="{{ 'Edit' | translate }}"
        icon="pi pi-pencil"
        iconPos="left"
        (click)="showTemplateForm()"
      ></button>
      <button
        pButton
        class="p-button p-button-rounded p-button-danger p-button-outlined"
        type="button"
        pTooltip="{{ 'Delete' | translate }}"
        icon="pi pi-trash"
        iconPos="left"
        (click)="remove(op)"
      ></button>
    </div>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #info appendTo="body">
  <ng-template pTemplate>
    <h5 *ngIf="usageList.length < 1">{{ template.name }} {{ 'is not used anywhere' | translate }}.</h5>
    <h5 *ngIf="usageList.length > 0">{{ 'Usage of' | translate }} {{ template.name }} ({{ usageList.length }})</h5>
    <div *ngFor="let item of usageList">{{ item }}</div>
  </ng-template>
</p-overlayPanel>

<app-template-meta-form (saveTemplate)="updateTemplate($event, op)" [template]="template"></app-template-meta-form>
