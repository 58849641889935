import { Pipe, PipeTransform } from '@angular/core';
import { SummaryItem } from '../types/list-widget-settings.types';
import { LIST_WIDGET_DEFAULT_SUM_ICON } from '@widgets/shared/constants/widget.constants';

@Pipe({ name: 'getListAttributeIcon' })
export class GetListAttributeIconPipe implements PipeTransform {
  transform(attributeId: string | undefined, summaryItems: Record<string, SummaryItem>): string {
    if (!attributeId || !summaryItems[attributeId]?.isNumeric) return '';
    return summaryItems[attributeId]?.attributeIcon || LIST_WIDGET_DEFAULT_SUM_ICON;
  }
}
