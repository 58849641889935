import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { ArtifactSortDto } from '@widgets/shared/components/artifact-sorts/types/artifact-sort-dto';
import { SortOrder } from '@widgets/shared/components/artifact-sorts/types/sort-order';

export class ArtifactSort implements ArtifactSortDto {
  constructor(public order: SortOrder = SortOrder.default, public attribute: NewAttribute | NewClientAttribute | null) {}

  static initial(): ArtifactSort {
    return new ArtifactSort(SortOrder.default, null);
  }

  static fromDtoAndOptions({ order, attributeId }: ArtifactSortDto, attributeOptions: (NewAttribute | NewClientAttribute)[]): ArtifactSort {
    const attribute = attributeOptions.find(({ id }: NewAttribute | NewClientAttribute) => id === attributeId) || null;

    return new ArtifactSort(order, attribute);
  }

  get icon(): string {
    const orderRelatedIconPart = {
      [SortOrder.default]: 'pi-sort-alt',
      [SortOrder.ascending]: 'pi-sort-amount-up-alt',
      [SortOrder.descending]: 'pi-sort-amount-down',
    }[this.order];

    return `p-sortable-column-icon pi pi-fw ${orderRelatedIconPart}`;
  }

  get attributeId(): string {
    return this.attribute?.id || '';
  }

  get isApplicable(): boolean {
    return !!this.attributeId && [SortOrder.ascending, SortOrder.descending].includes(this.order);
  }

  get isDateSort(): boolean {
    return [NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_ON_ID].includes(this.attributeId);
  }

  get isDescendingOrder(): boolean {
    return this.order === SortOrder.descending;
  }

  toServer(): ArtifactSortDto {
    return {
      attributeId: this.attributeId,
      order: this.order,
    };
  }

  changeOrder(): void {
    this.order = {
      [SortOrder.default]: SortOrder.ascending,
      [SortOrder.ascending]: SortOrder.descending,
      [SortOrder.descending]: SortOrder.default,
    }[this.order];
  }

  resetOrder(): void {
    this.order = SortOrder.default;
  }
}
