<p-accordionTab [header]="header | translate">
    <ng-template pTemplate="header">
        <div class="action-cont">
            <i class="pi" *ngIf="aclItem.length > 0" [ngClass]="edit ? 'pi-check': 'pi-pencil'" (click)="toggleEdit($event)"></i>
            <i class="pi pi-plus" (click)="openPickUsers()"></i>
        </div>
    </ng-template>

    <div class="grid my-3" *ngFor="let item of aclItem" style="position: relative">
        <div class="col-10 mx-2 px-2">{{item.name}}</div>
        <div class="col-1">
            <i class="pi pi-trash" *ngIf="edit" (click)="remove(item)"></i>
        </div>
    </div>
</p-accordionTab>
