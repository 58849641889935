import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getModuleArtifactTypes' })
export class GetModuleArtifactTypesPipe implements PipeTransform {
  transform(artifactTypes: ListContainer<NewArtifactType>): unknown {
    return artifactTypes.list.filter(artifactType => artifactType.format === ArtifactTypeFormatEnum.module);
  }
}
