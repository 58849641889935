import { Injectable } from '@angular/core';
import { CURRENT_USER_URL_FILTER_VALUE } from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class BaseUserFilterService extends AbstractBaseTypeFilterService {
  constructor(protected readonly filterUtil: FilterUtil, protected readonly elvisUtil: ElvisUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null {
    if (!value) return null;

    const rawValues = this.filterUtil.toArray(value);
    let userValues = this.filterUtil
      .withoutEmptyValues(rawValues)
      .map(id => (id === CURRENT_USER_URL_FILTER_VALUE || id === NonAttributeKeys.CURRENT_USER_ID ? this.elvisUtil.getCurrentUser().id : id));
    let filterKey = this.filterUtil.getAttributesDbFilterKey(attributeKey);
    const containsEmptyValue = rawValues.length !== userValues.length;

    if (NonAttributeKeys.isUserSpecificAttributeKey(attributeKey)) {
      filterKey = attributeKey;
      userValues = userValues.map(id => ({ $oid: id }));
    }
    const resultQuery = this.getResultQuery(filterKey, userValues, filterRule);
    // empty value check handling
    if (containsEmptyValue) {
      return userValues.length ? { $or: [{ [filterKey]: { $exists: false } }, { ...resultQuery }] } : { [filterKey]: { $exists: false } };
    }
    return resultQuery;
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    return super.getQueryFromRawTextMulti(attributeKey, dataType, value, delimiter, filterRule);
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    switch (filterRule) {
      case CoreListFilterEnum.in:
      case CoreListFilterEnum.isNot:
        return true;
      default:
        return false;
    }
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.in;
  }
}
