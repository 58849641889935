import { Component, Input } from '@angular/core';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';

import { BaseDataType } from '../../../../artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ArtifactAttribute } from '../../types/artifact.types';

@Component({
  selector: 'app-bounded-range-kind-input',
  templateUrl: './bounded-range-kind-input.component.html',
  styleUrls: ['./bounded-range-kind-input.component.scss'],
})
export class BoundedRangeKindInputComponent {
  @Input() index: number;
  @Input() artifactAttribute: NewClientAttribute;
  @Input() fullAttribute: NewAttribute;
  @Input() fullDataType: NewDataType;

  isInteger(artifactAttribute: ArtifactAttribute): boolean {
    return artifactAttribute.attribute?.dataType?.baseDataType === BaseDataType.integer;
  }
}
