<p-blockUI [blocked]="(uiBlocked$ | async)!"></p-blockUI>
<app-loader [loading]="(uiBlocked$ | async)!"></app-loader>

<div class="grid h-full overflow-hidden">
  <div class="col-4 h-full overflow-hidden pb-0">
    <app-attributes-selector
      #attributesSelectorComponent
      [attributes]="attributes">
    </app-attributes-selector>
  </div>
  <div class="col-8 h-full overflow-hidden pb-0">
    <app-attributes-form-component
      [attributes]="attributesSelectorComponent.selectedAttributes"
      (cancel)="onCancel()"
      (save)="onSave($event)">
    </app-attributes-form-component>
  </div>
</div>
