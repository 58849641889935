import { Directive, Host, Self, Optional } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Directive({
  selector: '[appOverlayPanelShowFix]',
})
export class OverlayPanelShowFixDirective {
  constructor(@Host() @Self() @Optional() public overlayPanel: OverlayPanel) {
    overlayPanel.show = function (event: any, target?: any) {
      target && event?.stopPropagation();
      if (event && this.isOverlayAnimationInProgress) {
        return;
      }
      this.target = target || event.currentTarget || event.target;
      this.overlayVisible = true;
      this.render = true;
      this.cd.markForCheck();
    };
  }
}
