import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { ArtifactSortsComponent } from './artifact-sorts.component';

@NgModule({
  declarations: [ArtifactSortsComponent],
  imports: [CommonModule, DropdownModule, FormsModule, TranslateModule, ButtonModule, RippleModule],
  exports: [ArtifactSortsComponent],
})
export class ArtifactSortsModule {}
