import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { CardWidgetOptions } from '../types/card-widget-options';

@Pipe({
  name: 'isBaseDataTypeEqualTo',
})
export class IsBaseDataTypeEqualToPipe implements PipeTransform {
  transform(attributeId: string, targetDataType: BaseDataType, options: CardWidgetOptions): boolean {
    const attribute = options.attributes.listMap[attributeId];

    if (!attribute) {
      return false;
    }

    const dataType = options.dataTypes.listMap[attribute.dataTypeId];

    return dataType.baseDataType === targetDataType;
  }
}
