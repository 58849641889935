import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'transformArrayToSelectOptions' })
export class TransformArrayToSelectOptionsPipe<Type = any> implements PipeTransform {
  transform(items: Type[], labelKey?: string, valueKey?: string): SelectOption<string, Type>[] {
    return (items || []).map(
      item => new SelectOption(labelKey ? (item[labelKey as keyof Type] as any) : item, valueKey ? (item[valueKey as keyof Type] as any) : item),
    );
  }
}
