import { Injectable, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  parentEl: Element | null;
  toolbarEl: Element | null;
  headerEl: Element | null;
  contentContainerEl: Element | null;
  pageContentEl: Element | null;

  constructor(private router: Router, private renderer: Renderer2) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes(PAGE_BUILDER_URL)) {
          setTimeout(() => {
            this.init();
          });
        } else {
          this.fixLayout();
        }
      }
    });
  }

  fixLayout(): void {
    const arrowBtn = this.getElement('header-btn');
    const isPageBuilder: boolean = this.isPageBuilderPage();
    const classAdd = isPageBuilder ? 'p-0' : 'layout-paddings';
    const classRemove = isPageBuilder ? 'layout-paddings' : 'p-0';
    this.contentContainerEl ??= this.getElement('layout-content');
    this.toolbarEl ??= this.getElement('layout-topbar');

    if (this.contentContainerEl) {
      this.renderer.addClass(this.contentContainerEl, classAdd);
      this.renderer.removeClass(this.contentContainerEl, classRemove);
    }

    if (isPageBuilder) {
      this.toolbarEl && this.toolbarEl.classList.add('layout-topbar-fixed');
      arrowBtn && arrowBtn.classList.remove('hide');
    } else {
      this.toolbarEl && this.toolbarEl.classList.remove('layout-topbar-fixed');
      arrowBtn && arrowBtn.classList.add('hide');
    }
  }

  init(): void {
    this.contentContainerEl = this.getElement('layout-content');
    this.toolbarEl = this.getElement('layout-topbar');
    this.headerEl = this.getElement('page-builder_header');
    this.pageContentEl = this.getElement('page-builder');
    this.fixLayout();

    if (!this.contentContainerEl || !this.headerEl || !this.toolbarEl) {
      this.isPageBuilderPage() && setTimeout(() => this.init(), 500);
      return;
    }

    const resizeLayoutObserver = new ResizeObserver(() => {
      if (!this.contentContainerEl || !this.headerEl || !this.toolbarEl) return;

      const width = this.contentContainerEl?.clientWidth;
      const top = this.toolbarEl.clientHeight;
      const isTopChanged = (this.headerEl as any).offsetTop !== top;

      (this.toolbarEl as any).style.width = width + 'px';
      (this.headerEl as any).style.width = width + 'px';
      (this.headerEl as any).style.top = top + 'px';
      isTopChanged && this.setPageContentStyles();
    });

    const resizeHeaderObserver = new ResizeObserver(() => {
      this.setPageContentStyles();
    });

    this.contentContainerEl && resizeLayoutObserver.observe(this.contentContainerEl);
    this.headerEl && resizeHeaderObserver.observe(this.headerEl);
  }

  private isPageBuilderPage(): boolean {
    return window.location.href.includes(PAGE_BUILDER_URL);
  }

  private setPageContentStyles(): void {
    if (!this.headerEl || !this.toolbarEl || !this.pageContentEl) return;

    const height = this.headerEl.clientHeight + this.toolbarEl.clientHeight;
    (this.pageContentEl as any).style['padding-top'] = height + 15 + 'px';
  }

  private getElement(className: string): Element | null {
    this.parentEl ??= document.getElementById('admin-main');
    return this.parentEl && (this.parentEl.getElementsByClassName(className) || [])[0];
  }
}
