import { Injectable, Renderer2 } from '@angular/core';
import { ApplicationResponseDto, TemplateUpdateRequestDto } from '@api/models';
import { PageBlockPartRequestDto } from '@api/models/page-block-part-request-dto';
import { PageBlockPartResponseDto } from '@api/models/page-block-part-response-dto';
import { PageBlockPartWidgetRequestDto } from '@api/models/page-block-part-widget-request-dto';
import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { PageRowBlockRequestDto } from '@api/models/page-row-block-request-dto';
import { PageRowBlockResponseDto } from '@api/models/page-row-block-response-dto';
import { PageSectionRequestDto } from '@api/models/page-section-request-dto';
import { PageSectionResponseDto } from '@api/models/page-section-response-dto';
import { PageSectionRowRequestDto } from '@api/models/page-section-row-request-dto';
import { PageSectionRowResponseDto } from '@api/models/page-section-row-response-dto';
import { TemplateCreateRequestDto } from '@api/models/template-create-request-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TenantExportImportService, TenantPageService } from '@api/services';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { TemplatesComponent } from '@private/pages/template-management/templates/templates.component';
import {
  BlockDownloadDto,
  PartDownloadDto,
  RowDownloadDto,
  SectionDownloadDto,
  TemplateCreateBody,
  TemplateDownloadBody,
  TemplatesModel,
  WidgetDownloadDto,
} from '@private/pages/template-management/templates/types/templates.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import {
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  DESCRIPTION_KEY,
  DESCRIPTION_LABEL,
  ICON_KEY,
  ICON_LABEL,
  ID_KEY,
  ID_LABEL,
  NAME_KEY,
  NAME_LABEL,
  TYPE_KEY,
  TYPE_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewApplication } from '@shared/types/application.types';
import { TableColumn } from '@shared/types/table.types';
import { WidgetCreateRequestDto } from '@shared/types/widget.types';
import { Table } from 'primeng/table';
import { catchError, lastValueFrom, map, Observable, of } from 'rxjs';

@Injectable()
export class TemplatesService extends CoreService<TemplatesComponent, TemplatesModel> {
  constructor(
    private readonly renderer: Renderer2,
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly tenantPageService: TenantPageService,
    private readonly tenantExportImportService: TenantExportImportService,
    private readonly announcement: AnnouncementService,
    private readonly cache: NewCacheService,
  ) {
    super();
  }

  init(context: TemplatesComponent, model: TemplatesModel): void {
    super.init(context, model);

    this.setColumns();
    this.initSubscriptions();
  }

  doFilter(table: Table): void {
    table && table.filter(null, null as any, null as any);
  }

  upload(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        try {
          const template = JSON.parse(event.target!.result as string);
          await this.create(template);
          resolve();
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsText(file);
    });
  }

  getImportMethod(): any {
    return this.tenantExportImportService.exportImportControllerImport.bind(this.tenantExportImportService);
  }

  getExportMethod(): any {
    return this.tenantTemplateService.templateControllerExport$Response.bind(this.tenantTemplateService);
  }

  async download(template: TemplateResponseDto): Promise<void> {
    const content = await this.getFileContent(template);
    const file = this.getFile(content);
    const fileName = `${template.type.toLowerCase()}_template_${template.id}.json`;
    const link = this.getLink(file, fileName);

    link.click();
  }

  async getTemplateUsage(template: TemplateResponseDto): Promise<string[]> {
    const type = this.getType(template.type);
    if (!type) {
      return [];
    }
    const $or = [];

    if (template.type === 'WIDGET') {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const $and = [{ code: 'SIDEBAR' }, { 'value.model.parts.templateId': { $in: [template.id] } }];
      const ids = (await this.cache.data.widgets.getManyAsync([], $and)).map(dto => dto.id);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      $or.push({ 'sections.rows.blocks.parts.widget.widgetId': { $in: ids } });
    }

    $or.push({ [type]: { $in: [template.id] } });
    const meta = { filter: JSON.stringify({ $or }) };
    return (await lastValueFrom(this.tenantPageService.pageControllerList(meta))).data.map(page => page.name);
  }

  async isUnusedTemplate(template: TemplateResponseDto): Promise<boolean> {
    const type = this.getType(template.type);
    if (!type) {
      return false;
    }

    if (template.type === 'WIDGET') {
      // look at sidebar widgets
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const $and = [{ code: 'SIDEBAR' }, { 'value.model.parts.templateId': { $in: [template.id] } }];
      const resp = await lastValueFrom(this.tenantWidgetService.widgetControllerCount({ filter: JSON.stringify({ $and }) }));
      if (resp.count > 0) {
        return false;
      }
    }

    const meta = { filter: JSON.stringify({ [type]: { $in: [template.id] } }) };
    return (await lastValueFrom(this.tenantPageService.pageControllerCount(meta))).count < 1;
  }

  updateTemplate(template: TemplateResponseDto): Observable<TemplateResponseDto | null> {
    const templateUpdateBody = {
      id: template.id,
      applicationId: template.applicationId,
      name: template.name,
      template: template.template,
      description: template.description,
      icon: template.icon,
      categories: template.categories,
      thumbnailFileArtifactId: template.thumbnailFileArtifactId,
    } as TemplateUpdateRequestDto;

    return this.tenantTemplateService.templateControllerUpdate({ body: templateUpdateBody }).pipe(
      map(templateResponse => {
        this.cache.data.templates.setItem(templateResponse);
        this.announcement.success('Template updated');
        return templateResponse;
      }),
      catchError(e => {
        console.log(e);
        this.announcement.error('Failed to updated template');
        return of(null);
      }),
    );
  }

  private getType(templateType: string): string | null {
    let type = '';
    if (templateType === 'SECTION') {
      type = 'sections.templateId';
    } else {
      if (templateType === 'ROW') {
        type = 'sections.rows.templateId';
      } else {
        if (templateType === 'BLOCK') {
          type = 'sections.rows.blocks.templateId';
        } else {
          if (templateType === 'BLOCK_PART') {
            type = 'sections.rows.blocks.parts.templateId';
          } else {
            if (templateType === 'WIDGET') {
              type = 'sections.rows.blocks.parts.widget.templateId';
            } else {
              console.log(`This template type (${templateType}) not supported for un-use check before remove, please implement this!!!`);
              return '';
            }
          }
        }
      }
    }

    return type;
  }

  private setColumns(): void {
    this.m.columns = [
      new TableColumn(ID_LABEL, ID_KEY),
      new TableColumn(TYPE_LABEL, TYPE_KEY),
      new TableColumn(ICON_LABEL, ICON_KEY),
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
      new TableColumn(DESCRIPTION_LABEL, DESCRIPTION_KEY),
    ];
  }

  private async create(template: TemplateCreateRequestDto): Promise<void> {
    const body = {
      ...template,
      template: await this.getTemplateCreateBody(template),
    };

    await lastValueFrom(this.tenantTemplateService.templateControllerCreate({ body })).then(dto => this.cache.data.templates.setItem(dto));
  }

  private async getTemplateCreateBody(template: TemplateCreateRequestDto): Promise<TemplateCreateBody> {
    const methods: Record<TemplateType, () => Promise<TemplateCreateBody>> = {
      [TemplateType.section]: async () => await this.getSectionBody(template.template as PageSectionResponseDto),
      [TemplateType.row]: async () => await this.getRowBody(template.template as PageSectionRowResponseDto),
      [TemplateType.block]: async () => await this.getBlockBody(template.template as PageRowBlockResponseDto),
      [TemplateType.blockPart]: async () => await this.getPartBody(template.template as any),
      [TemplateType.widget]: async () => await this.getWidgetBody(template.template as unknown as WidgetCreateRequestDto),
    };

    return await methods[template.type]();
  }

  private async getSectionBody(section: PageSectionResponseDto): Promise<PageSectionRequestDto> {
    const rows = [];

    for (const row of section.rows!) {
      rows.push(await this.getRowBody(row));
    }

    return { ...section, rows };
  }

  private async getRowBody(row: PageSectionRowResponseDto): Promise<PageSectionRowRequestDto> {
    const blocks = [];

    for (const block of row.blocks!) {
      blocks.push(await this.getBlockBody(block));
    }

    return { ...row, blocks } as PageSectionRowRequestDto;
  }

  private async getBlockBody(block: PageRowBlockResponseDto): Promise<PageRowBlockRequestDto> {
    const parts = [];

    for (const part of block.parts!) {
      parts.push(await this.getPartBody(part as PartDownloadDto));
    }

    return { ...block, parts };
  }

  private async getPartBody(part: PartDownloadDto): Promise<PageBlockPartRequestDto> {
    if (!part.widget) {
      return part as PageBlockPartRequestDto;
    }

    const widget = part.widget ? await this.getWidgetBody(part.widget as WidgetCreateRequestDto) : null;

    return { ...part, widget } as PageBlockPartRequestDto;
  }

  private async getWidgetBody(body: WidgetCreateRequestDto): Promise<PageBlockPartWidgetRequestDto> {
    const dto = await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body }));

    this.cache.data.widgets.setItem(dto);

    return { widgetId: dto.id, templateId: null };
  }

  private async getFileContent(template: TemplateResponseDto): Promise<TemplateCreateRequestDto> {
    return {
      applicationId: template.applicationId,
      description: template.description,
      icon: template.icon,
      name: template.name,
      template: (await this.getTemplateDownloadBody(template)) as any,
      type: template.type,
      categories: template.categories,
      thumbnailFileArtifactId: template.thumbnailFileArtifactId,
    };
  }

  private async getTemplateDownloadBody(template: TemplateResponseDto): Promise<TemplateDownloadBody> {
    const methods: Record<TemplateType, () => Promise<TemplateDownloadBody>> = {
      [TemplateType.section]: async () => await this.getSectionDownloadDto(template.template as PageSectionResponseDto),
      [TemplateType.row]: async () => await this.getRowDownloadDto(template.template as PageSectionRowResponseDto),
      [TemplateType.block]: async () => await this.getBlockDownloadDto(template.template as PageRowBlockResponseDto),
      [TemplateType.blockPart]: async () => await this.getPartDownloadDto(template.template as PageBlockPartResponseDto),
      [TemplateType.widget]: async () => await this.getWidgetDownloadDto(template.template as PageBlockPartWidgetResponseDto),
    };

    return await methods[template.type]();
  }

  private async getSectionDownloadDto(section: PageSectionResponseDto): Promise<SectionDownloadDto> {
    const rows = [];

    for (const row of section.rows!) {
      rows.push(await this.getRowDownloadDto(row));
    }

    return { ...section, rows };
  }

  private async getRowDownloadDto(row: PageSectionRowResponseDto): Promise<RowDownloadDto> {
    const { templateId: id } = row;

    if (id) {
      const rowTemplate = await this.cache.data.templates.getAsync(id);
      return await this.getRowDownloadDto(rowTemplate.template as PageSectionRowResponseDto);
    }

    const blocks = [];

    for (const block of row.blocks!) {
      blocks.push(await this.getBlockDownloadDto(block));
    }

    return { ...row, blocks } as RowDownloadDto;
  }

  private async getBlockDownloadDto(block: PageRowBlockResponseDto): Promise<BlockDownloadDto> {
    const { templateId: id } = block;

    if (id) {
      const blockTemplate = await this.cache.data.templates.getAsync(id);
      return await this.getBlockDownloadDto(blockTemplate.template as PageRowBlockResponseDto);
    }

    const parts = [];

    for (const part of block.parts!) {
      parts.push(await this.getPartDownloadDto(part));
    }

    return { ...block, parts };
  }

  private async getPartDownloadDto(part: PageBlockPartResponseDto): Promise<PartDownloadDto> {
    const { templateId: id } = part;

    if (id) {
      const partTemplate = await this.cache.data.templates.getAsync(id);
      return await this.getPartDownloadDto(partTemplate.template as PageBlockPartResponseDto);
    }

    const widget = part.widget ? await this.getWidgetDownloadDto(part.widget) : null;

    return { ...part, widget };
  }

  private async getWidgetDownloadDto({ templateId, widgetId }: PageBlockPartWidgetResponseDto): Promise<WidgetDownloadDto> {
    if (templateId) {
      const widgetTemplate = await this.cache.data.templates.getAsync(templateId);
      return await this.getWidgetDownloadDto(widgetTemplate.template as PageBlockPartWidgetResponseDto);
    }

    const { code, value } = await this.cache.data.widgets.getAsync(widgetId!);
    return { code, value };
  }

  private getFile(content: TemplateCreateRequestDto): Blob {
    return new Blob([JSON.stringify(content)], { type: 'text/json' });
  }

  private getLink(file: Blob, fileName: string): HTMLLinkElement {
    const linkElement = this.renderer.createElement('a');
    this.renderer.setAttribute(linkElement, 'href', URL.createObjectURL(file));
    this.renderer.setAttribute(linkElement, 'download', fileName);

    return linkElement;
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.data.applications.subscribe(applications =>
        this.m.applications.setList(
          applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)),
          ID_KEY,
        ),
      ),
    ]);
  }
}
