import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';

@Pipe({ name: 'isDemotingEnabled' })
export class IsDemotingEnabledPipe implements PipeTransform {
  transform(artifact: NewArtifact): boolean {
    const deepestLevel = artifact.moduleData?.level?.slice(-1);
    return !!deepestLevel && !!deepestLevel.length && deepestLevel[0] > 1;
  }
}
