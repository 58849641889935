import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NewDataType } from '@shared/types/data-type.types';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { ArtifactWidgetFormatsMap } from '@widgets/artifact-widget/types/artifact-widget.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { ConvertToClientDatetime, ConvertToDateByFormat } from '@shared/methods/date.methods';
import { NewClientAttribute } from '@shared/types/attribute.types';
import moment from 'moment';

@Component({
  selector: 'app-display-at-datetime',
  templateUrl: './display-at-datetime.component.html',
  styleUrls: ['./display-at-datetime.component.scss'],
})
export class DisplayAtDatetimeComponent {
  @Input() dataType: NewDataType;
  @Input() attrId: string;
  @Input() formatStyles: FormatStyles;
  @Input() isDate = false;

  @Input() formatsMap: ArtifactWidgetFormatsMap;
  @Input() selectedVariant: string;

  @Input() set artifactNew(art: NewArtifact) {
    if (!art) return;
    setTimeout(() => {
      const attr = art.attributes[this.attrId];
      this.setValue(attr);
    });
  }

  @Input() set attributeNew(attr: NewClientAttribute) {
    setTimeout(() => {
      this.setValue(attr);
    });
  }

  value: string;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  private setValue(attr: NewClientAttribute): void {
    const dateStr = attr?.value;

    if (attr && dateStr) {
      const displayVariant = this.formatsMap
        ? (attr.value && this.formatsMap.attribute[attr.value].value.displayMetadata?.selectedVariantCode) || ''
        : this.selectedVariant;

      const date = new Date(dateStr);
      const isDate = this.dataType?.isDate || this.isDate;

      if (isDate) {
        this.value = displayVariant ? ConvertToDateByFormat(dateStr, displayVariant, isDate) : moment(dateStr).utc().local().format('DD.MM.Y');
      } else {
        this.value = displayVariant ? ConvertToDateByFormat(date, displayVariant, isDate) : ConvertToClientDatetime(date);
      }

      this.cdr.markForCheck();
      return;
    }

    this.value = '';
  }
}
