import { Component, Input } from '@angular/core';
import { ExecCustomJavascriptKeys, WorkflowActionExecCustomJavascript } from '@workflows/types/actions/action-exec-custom-javascript';

@Component({
  selector: 'app-action-exec-custom-javascript',
  templateUrl: './action-exec-custom-javascript.component.html',
  styleUrls: ['./action-exec-custom-javascript.component.scss'],
})
export class ActionExecCustomJavascriptComponent {
  @Input() action: WorkflowActionExecCustomJavascript;

  protected readonly RuleKeys = ExecCustomJavascriptKeys;
}
