import { Injectable } from '@angular/core';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { FilterUtil } from '@shared/utils/filter.util';
import { AbstractBaseTypeFilterService } from './abstract-base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class BaseTextFilterService extends AbstractBaseTypeFilterService {
  constructor(filterUtil: FilterUtil) {
    super(filterUtil);
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule: CoreListFilterEnum): Record<string, any> | null {
    if (!value) {
      return null;
    }
    const filterKey = this.filterUtil.getAttributesDbFilterKey(attributeKey);
    return this.getResultQuery(filterKey, value, filterRule);
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter?: string,
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    return super.getQueryFromRawTextSingle(attributeKey, dataType, value, delimiter, filterRule);
  }

  getDefaultFilterRule(): CoreListFilterEnum {
    return CoreListFilterEnum.contains;
  }

  isSupportedFilterRule(filterRule: CoreListFilterEnum): boolean {
    return this.filterUtil.isTextFilterMatchMode(filterRule);
  }
}
