import { FolderResponseDto } from '@api/models';
import { CoreListFilterEnum, FilterToClientEnum } from '@shared/core/types/core.types';
import { TreeNode } from 'primeng/api';
import { NewApplication } from './application.types';

export enum MongoFilterOperator {
  AND = '$and',
  OR = '$or',
}

export enum DateFilterEnum {
  dateIs = 'dateIs',
  dateIsNot = 'dateIsNot',
  dateBefore = 'dateBefore',
  dateBeforeOrEqualTo = 'dateBeforeOrEqualTo',
  dateAfter = 'dateAfter',
  dateAfterOrEqualTo = 'dateAfterOrEqualTo',
}

export enum DateRangeFilterEnum {
  dateBetween = 'dateBetween',
  custom = 'custom',
  dueInDays = 'dueInDays',
  dueInDaysOrLess = 'dueInDaysOrLess',
  dueInDaysOrMore = 'dueInDaysOrMore',
  ageInDays = 'ageInDays',
  ageInDaysOrLess = 'ageInDaysOrLess',
  ageInDaysOrMore = 'ageInDaysOrMore',
  yesterday = 'yesterday',
  today = 'today',
  tomorrow = 'tomorrow',
  afterToday = 'afterToday',
  beforeToday = 'beforeToday',
  todayOrAfter = 'todayOrAfter',
  todayOrBefore = 'todayOrBefore',
  lastWeek = 'lastWeek',
  currentWeek = 'currentWeek',
  nextWeek = 'nextWeek',
  lastMonth = 'lastMonth',
  currentMonth = 'currentMonth',
  nextMonth = 'nextMonth',
  lastYear = 'lastYear',
  currentYear = 'currentYear',
  nextYear = 'nextYear',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  last60Days = 'last60Days',
  last90Days = 'last90Days',
  last120Days = 'last120Days',
  next6Months = 'next6Months',
  next12Months = 'next12Months',
}

export enum FilterOperatorEnum {
  and = 'and',
  or = 'or',
}

export enum LinkFilterEnum {
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  containsUrlParamKey = 'containsUrlParamKey',
}

export enum FolderFilterEnum {
  // folderPath = 'folderPath',
  containsUrlParamKey = 'containsUrlParamKey',
}

export enum UserFilterTypeEnum {
  filterByUser = 'filterByUser',
  filterByTeam = 'filterByTeam',
}

export enum BooleanValueFilterEnum {
  TRUE = 'true',
  FALSE = 'false',
}

export type AllDaysInMonthMap = {
  [clientDate: string]: Date;
};

export class CustomDateSettings {
  offsetInDays = 0;
  start: Date;
  end: Date;
  isDateTime = false;

  constructor(params?: Partial<CustomDateSettings>) {
    params && Object.assign(this, params);
  }
}

export interface StartEndDate {
  start: Date;
  end: Date;
}

export const ENUM_MATCH_MODE_OPTIONS = [
  { label: FilterToClientEnum.in, value: CoreListFilterEnum.in },
  { label: FilterToClientEnum.notIn, value: CoreListFilterEnum.notIn },
];

export class UserFilterEntry {
  type: UserFilterTypeEnum = UserFilterTypeEnum.filterByUser;
  value: any;

  constructor(params?: Partial<UserFilterEntry>) {
    params && Object.assign(this, params);
  }

  isUserFilter(): boolean {
    return this.type === UserFilterTypeEnum.filterByUser;
  }
}

/**
 * Input for folder filter component.
 */
export interface FolderFilterData {
  /** whether an external filter was applied */
  externalFilterApplied?: boolean;
  application?: NewApplication;
  selectedFolders?: TreeNode<FolderResponseDto>[];
  folders?: TreeNode<FolderResponseDto>[];
  folderPathForChildren?: string;
  includeSubfolders?: boolean;
}
