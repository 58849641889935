import { Component, Input } from '@angular/core';
import { SideBarSettings } from '@widgets/sidebar-widget/types/sidebar-widget-settings.types';
import { SideBarOptions } from '@widgets/sidebar-widget/types/sidebar-widget-options.types';
import { SidebarService } from '@widgets/sidebar-widget/services/sidebar.service';

@Component({
  selector: 'app-sidebar-widget-settings',
  templateUrl: './sidebar-widget-settings.component.html',
  styleUrls: ['./sidebar-widget-settings.component.scss'],
})
export class SidebarWidgetSettingsComponent {
  @Input() settings: SideBarSettings;
  @Input() options: SideBarOptions;
  @Input() s: SidebarService;
  visibleButtonSettings: boolean;
  visibleSidebarButtonSettings: boolean;
  visibleEventSettings: boolean;

  ngOnInit() {
    this.s.checkSettingsRestriction();
  }

  changeVisibleStyles(): void {
    this.s.checkSettingsRestriction();
    this.settings.updateStyles();
  }

  changeButtonSettings(): void {
    this.settings.onHoverOut();
  }
}
