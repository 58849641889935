import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppArtifactAttributeValueEditFieldModule } from '@private/components/artifact-attribute-value-edit-field/artifact-attribute-value-edit-field.component';
import { FileAttributeValueEditFieldModule } from '@private/components/file-attribute-value-edit-field/file-attribute-value-edit-field.component';
import { PublicModule } from '@public/public.module';
import { AppAttributeValueEditFieldModule } from '@shared/components/attribute-value-edit-field/attribute-value-edit-field.component';
import {
  DisplayAtBooleanModule,
  DisplayAtEnumModule,
  DisplayAtHtmlModule,
  DisplayAtHyperlinkModule,
  DisplayAtLinkModule,
  DisplayAtOverlayModule,
  DisplayAtSystemDateModule,
  DisplayAtTextModule,
  DisplayAtUserModule,
} from '@shared/components/common-display-at';
import { FileAttributeModule } from '@shared/components/file-attribute/file-attribute.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { AttributePipesModule } from '@shared/pipes/attribute-pipes/attribute-pipes.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { DisplayArtifactValueToTableModule } from '@shared/pipes/display-artifact-value-to-table/display-artifact-value-to-table.module';
import { WithEmptyEnumOptionModule } from '@shared/pipes/with-empty-enum-option/with-empty-enum-option.module';
import { WithEmptySelectOptionModule } from '@shared/pipes/with-empty-select-option/with-empty-select-option.module';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { AclModule } from '@widgets/shared/components/acl/acl.module';
import { GetButtonLabelPipe } from '@widgets/shared/components/artifact-list-table/pipes/get-button-label.pipe';
import { AttributeFormatLabelStylesFormModule } from '@widgets/shared/components/attribute-format-settings';
import { ListItemClickActionSettingsFormModule } from '@widgets/shared/components/list-item-click-action-settings-form/list-item-click-action-settings-form.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { GetRowsPerPageHeaderPipeModule } from '@widgets/shared/pipes/get-rows-per-page-header.pipe';
import { IsGroupingByDatePipeModule } from '@widgets/shared/pipes/is-grouping-by-date.pipe';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { ShouldShowCustomRangeCalendarOptionPipeModule } from '@widgets/shared/pipes/should-show-custom-range-calendar-option.pipe';
import { ShouldShowDateOffsetOptionPipeModule } from '@widgets/shared/pipes/should-show-date-offset-option.pipe';
import { WorkflowsModule } from '@workflows/workflows.module';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ArtifactListWidgetTableComponent } from './artifact-list-widget-table.component';
import { ArtifactListWidgetTableCaptionComponent } from './components/artifact-list-widget-table-caption/artifact-list-widget-table-caption.component';
import { ArtifactListWidgetTableModuleUtilityButtonsComponent } from './components/artifact-list-widget-table-module-utility-buttons/artifact-list-widget-table-module-utility-buttons.component';
import { ArtifactListWidgetTableCellComponent } from './components/table-cell';
import { ArtifactListTableGroupComponent } from './components/table-group/artifact-list-table-group.component';
import { ArtifactListWidgetTableHeaderColumnComponent } from './components/table-header-column';
import { ArtifactListWidgetTableHeaderMenuComponent } from './components/table-header-menu';
import { GetArtifactsSummaryPipe } from './pipes/get-artifacts-summary.pipe';
import { GetEnumOrUserFilterOptionsPipe } from './pipes/get-enum-or-user-filter-options.pipe';
import { GetFilterTypePipe } from './pipes/get-filter-type.pipe';
import { GetGroupMetaDataTableItemPipe } from './pipes/get-group-meta-data-table-item.pipe';
import { GetRelevantLinksToTableCellPipe } from './pipes/get-relevant-links-to-table-cell.pipe';
import { IsAddButtonDisabledPipe } from './pipes/is-add-button-disabled.pipe';
import { IsArtifactListTypePipe } from './pipes/is-artifact-list-type.pipe';
import { IsColumnFilterDisabledPipe } from './pipes/is-column-filter-disabled.pipe';
import { IsDemotingEnabledPipe } from './pipes/is-demoting-enabled.pipe';
import { IsEnumColumnPipe } from './pipes/is-enum-column.pipe';
import { IsHeadingCheckboxEnabledPipe } from './pipes/is-heading-checkbox-enabled.pipe';
import { IsLoadByArtifactTypePipe } from './pipes/is-load-by-artifact-type.pipe';
import { IsLoadByFolderPipe } from './pipes/is-load-by-folder.pipe';
import { IsLoadByModulePipe } from './pipes/is-load-by-module.pipe';
import { IsTableEmptyPipe } from './pipes/is-table-empty.pipe';
import { IsUserColumnPipe } from './pipes/is-user-column.pipe';
import { ShouldShowColumnOptionsPipe } from './pipes/should-show-column-options.pipe';
import { ShouldShowGroupPaginatorPipe } from './pipes/should-show-group-paginator.pipe';
import { ShouldShowSaveToFolderIdOptionPipe } from './pipes/should-show-save-to-folder-id-option.pipe';
import { ShouldShowSharedPaginatorPipe } from './pipes/should-show-shared-paginator.pipe';
import { ShouldShowSingleAddArtifactButtonPipe } from './pipes/should-show-single-add-artifact-button.pipe';
import { GetListAttributeIconPipe } from '@widgets/shared/components/artifact-list-table/pipes/get-attribute-icon.pipe';
import { IsNumericColumnPipe } from '@widgets/shared/components/artifact-list-table/pipes/is-numeric-column.pipe';
import { DisplayAtDatetimeModule } from '@shared/components/common-display-at/components/display-at-datetime/display-at-datetime.module';

const pipes = [
  IsEnumColumnPipe,
  IsUserColumnPipe,
  GetEnumOrUserFilterOptionsPipe,
  GetRelevantLinksToTableCellPipe,
  GetGroupMetaDataTableItemPipe,
  GetFilterTypePipe,
  IsEnumColumnPipe,
  IsLoadByArtifactTypePipe,
  ShouldShowSharedPaginatorPipe,
  ShouldShowGroupPaginatorPipe,
  IsTableEmptyPipe,
  GetArtifactsSummaryPipe,
  GetListAttributeIconPipe,
  IsNumericColumnPipe,
  IsLoadByArtifactTypePipe,
  ShouldShowColumnOptionsPipe,
  ShouldShowSingleAddArtifactButtonPipe,
  ShouldShowSaveToFolderIdOptionPipe,
  IsDemotingEnabledPipe,
  IsHeadingCheckboxEnabledPipe,
  IsAddButtonDisabledPipe,
  GetButtonLabelPipe,
  IsColumnFilterDisabledPipe,
  IsLoadByModulePipe,
  IsLoadByFolderPipe,
  IsArtifactListTypePipe,
];

const components = [
  ArtifactListWidgetTableComponent,
  ArtifactListWidgetTableCaptionComponent,
  ArtifactListWidgetTableHeaderColumnComponent,
  ArtifactListWidgetTableHeaderMenuComponent,
  ArtifactListWidgetTableCellComponent,
  ArtifactListTableGroupComponent,
  ArtifactListWidgetTableModuleUtilityButtonsComponent,
];

@NgModule({
  declarations: [...pipes, ...components],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    TranslateModule,
    AccordionModule,
    ButtonModule,
    RippleModule,
    AppArtifactAttributeValueEditFieldModule,
    CheckboxModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextModule,
    RouterModule,
    BadgeModule,
    CalendarModule,
    TriStateCheckboxModule,
    PublicModule,
    DialogModule,
    TooltipModule,
    FileAttributeModule,
    ElvisSharedModule,
    DragDropModule,
    ConfirmDialogModule,
    CommonPipesModule,
    DataTypePipesModule,
    ArtifactPipesModule,
    AppAttributeValueEditFieldModule,
    DisplayArtifactValueToTableModule,
    AttributePipesModule,
    WithEmptySelectOptionModule,
    WithEmptyEnumOptionModule,
    FileAttributeValueEditFieldModule,
    PaginatorModule,
    ListItemClickActionSettingsFormModule,
    OverlayPanelModule,
    TabViewModule,
    StyleFormModule,
    MenuModule,
    DisplayAtBooleanModule,
    DisplayAtEnumModule,
    DisplayAtLinkModule,
    DisplayAtOverlayModule,
    DisplayAtHtmlModule,
    DisplayAtUserModule,
    DisplayAtTextModule,
    DisplayAtHyperlinkModule,
    AclModule,
    FolderWidgetModule,
    AttributeFormatLabelStylesFormModule,
    ReplaceRuntimeVariablesModule,
    IsGroupingByDatePipeModule,
    ShouldShowDateOffsetOptionPipeModule,
    ShouldShowCustomRangeCalendarOptionPipeModule,
    GetRowsPerPageHeaderPipeModule,
    SelectButtonModule,
    TreeModule,
    WorkflowsModule,
    SplitButtonModule,
    DisplayAtSystemDateModule,
    DisplayAtDatetimeModule,
  ],
  exports: [...pipes, ...components],
})
export class ArtifactListWidgetTableModule {}
