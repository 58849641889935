<div class="layout-wrapper" [ngClass]="{
    'layout-overlay': uiConfig.menuMode === 'overlay',
    'layout-static': uiConfig.menuMode === 'static',
    'layout-slim': uiConfig.menuMode === 'slim',
    'layout-sidebar-dim': uiConfig.colorScheme === 'dim',
    'layout-sidebar-dark': uiConfig.colorScheme === 'dark',
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && uiConfig.menuMode === 'static',
    'p-input-filled': uiConfig.inputStyle === 'filled',
    'p-ripple-disabled': !uiConfig.ripple,
    'dynamic-float-label': uiConfig.labelStyle === 'float' || !uiConfig || !uiConfig.labelStyle,
    'dynamic-static-label': uiConfig.labelStyle === 'static',
    'dynamic-placeholder-label': uiConfig.labelStyle === 'placeholder',
    'dynamic-inline-label': uiConfig.labelStyle === 'inlineLeft' || uiConfig.labelStyle === 'inlineRight',
    'dynamic-inline-label-right': uiConfig.labelStyle === 'inlineRight',
    'dynamic-inline-label-left': uiConfig.labelStyle === 'inlineLeft'
    }"
     [class]="uiConfig.colorScheme === 'light' ? uiConfig.menuTheme : ''"
     [attr.data-theme]="uiConfig.colorScheme"
     (click)="onLayoutClick()">

  <div class="layout-content-wrapper">
    <app-admin-top-bar [app]="this" [appInterface]="appInterface.system"></app-admin-top-bar>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

  <app-sidebar-menu [app]="this" [hasApplicationSwitcher]="false" [items]="menuItems"></app-sidebar-menu>

  <div class="layout-mask modal-in"></div>
</div>
