import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { BooleanWordValuePipe } from './boolean-word-value.pipe';
import { DisplayAtBooleanComponent } from './display-at-boolean.component';

@NgModule({
  declarations: [DisplayAtBooleanComponent, BooleanWordValuePipe],
  imports: [CommonModule, FormsModule, CheckboxModule, TriStateCheckboxModule, InputSwitchModule, TranslateModule, WidgetContainerModule],
  exports: [DisplayAtBooleanComponent, BooleanWordValuePipe],
})
export class DisplayAtBooleanModule {}
