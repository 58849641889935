import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantArtifactTypeService } from '@api/services/tenant-artifact-type.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { ArtifactTypeListService } from './services/artifact-type-list.service';
import { ArtifactTypesListModel } from './types/artifact-type-list.types';

@Component({
  selector: 'app-artifact-types-list',
  templateUrl: './artifact-type-list.component.html',
  styleUrls: ['./artifact-type-list.component.scss'],
  providers: [ArtifactTypeListService],
})
export class ArtifactTypeListComponent extends CoreComponent<ArtifactTypeListService, ArtifactTypesListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: ArtifactTypeListService,
    announcement: AnnouncementService,
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new ArtifactTypesListModel(), service, announcement);
  }

  public onInit(): void {
    this.breadcrumbService.setItems([{ label: 'Artifact Types' }]);
  }

  loadData() {
    return (meta?: Partial<ListReqMetaData>) => this.tenantArtifactTypeService.artifactTypeControllerList(meta).toPromise();
  }
}
