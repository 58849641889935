/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseCountResponseDto } from '../models/base-count-response-dto';
import { dataTypeControllerCount } from '../fn/tenant-data-type/data-type-controller-count';
import { DataTypeControllerCount$Params } from '../fn/tenant-data-type/data-type-controller-count';
import { dataTypeControllerCreate } from '../fn/tenant-data-type/data-type-controller-create';
import { DataTypeControllerCreate$Params } from '../fn/tenant-data-type/data-type-controller-create';
import { dataTypeControllerDelete } from '../fn/tenant-data-type/data-type-controller-delete';
import { DataTypeControllerDelete$Params } from '../fn/tenant-data-type/data-type-controller-delete';
import { dataTypeControllerGet } from '../fn/tenant-data-type/data-type-controller-get';
import { DataTypeControllerGet$Params } from '../fn/tenant-data-type/data-type-controller-get';
import { dataTypeControllerList } from '../fn/tenant-data-type/data-type-controller-list';
import { DataTypeControllerList$Params } from '../fn/tenant-data-type/data-type-controller-list';
import { dataTypeControllerUpdate } from '../fn/tenant-data-type/data-type-controller-update';
import { DataTypeControllerUpdate$Params } from '../fn/tenant-data-type/data-type-controller-update';
import { DataTypeListResponseDto } from '../models/data-type-list-response-dto';
import { DataTypeResponseDto } from '../models/data-type-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantDataTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dataTypeControllerCount()` */
  static readonly DataTypeControllerCountPath = '/api/tenant/data-type/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerCount$Response(params?: DataTypeControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return dataTypeControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerCount(params?: DataTypeControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.dataTypeControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `dataTypeControllerGet()` */
  static readonly DataTypeControllerGetPath = '/api/tenant/data-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerGet$Response(params: DataTypeControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<DataTypeResponseDto>> {
    return dataTypeControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerGet(params: DataTypeControllerGet$Params, context?: HttpContext): Observable<DataTypeResponseDto> {
    return this.dataTypeControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataTypeResponseDto>): DataTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `dataTypeControllerDelete()` */
  static readonly DataTypeControllerDeletePath = '/api/tenant/data-type/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerDelete$Response(params: DataTypeControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DataTypeResponseDto>> {
    return dataTypeControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerDelete(params: DataTypeControllerDelete$Params, context?: HttpContext): Observable<DataTypeResponseDto> {
    return this.dataTypeControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataTypeResponseDto>): DataTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `dataTypeControllerList()` */
  static readonly DataTypeControllerListPath = '/api/tenant/data-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerList$Response(params?: DataTypeControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<DataTypeListResponseDto>> {
    return dataTypeControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataTypeControllerList(params?: DataTypeControllerList$Params, context?: HttpContext): Observable<DataTypeListResponseDto> {
    return this.dataTypeControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataTypeListResponseDto>): DataTypeListResponseDto => r.body)
    );
  }

  /** Path part for operation `dataTypeControllerUpdate()` */
  static readonly DataTypeControllerUpdatePath = '/api/tenant/data-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dataTypeControllerUpdate$Response(params: DataTypeControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DataTypeResponseDto>> {
    return dataTypeControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dataTypeControllerUpdate(params: DataTypeControllerUpdate$Params, context?: HttpContext): Observable<DataTypeResponseDto> {
    return this.dataTypeControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataTypeResponseDto>): DataTypeResponseDto => r.body)
    );
  }

  /** Path part for operation `dataTypeControllerCreate()` */
  static readonly DataTypeControllerCreatePath = '/api/tenant/data-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataTypeControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dataTypeControllerCreate$Response(params: DataTypeControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DataTypeResponseDto>> {
    return dataTypeControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dataTypeControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dataTypeControllerCreate(params: DataTypeControllerCreate$Params, context?: HttpContext): Observable<DataTypeResponseDto> {
    return this.dataTypeControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataTypeResponseDto>): DataTypeResponseDto => r.body)
    );
  }

}
