<div *ngIf="model" class="grid">
  <div class="col-12">
    <p-dropdown
      [(ngModel)]="model.variant"
      [autoDisplayFirst]="false"
      [options]="filterVariantOptions"
      placeholder="Choose link filter">
      <ng-template let-option pTemplate="item">{{option.label | translate}}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{option.label | translate}}</ng-template>
    </p-dropdown>

    <input
      *ngIf="isUrlKeyInputVisible"
      (blur)="onChange()"
      [(ngModel)]="model.name"
      class="w-100"
      pInputText
      placeholder="url param name"
      type="text">
  </div>
</div>
