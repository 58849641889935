import { Pipe, PipeTransform } from '@angular/core';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { StickinessPosition } from '@private/pages/page-management/page-builder-graphical/types/stickiness-position';

@Pipe({ name: 'sortStickySectionsPreviewMode' })
export class SortStickySectionsPreviewModePipe implements PipeTransform {
  transform(sections: PageSection[]): PageSection[] {
    const stickyOnTop = sections.filter(section => {
      const { isSticky, position } = section.styles.outerContainer.stickyPositioning;
      return isSticky && position === StickinessPosition.top;
    });
    const stickyOnBottom = sections.filter(section => {
      const { isSticky, position } = section.styles.outerContainer.stickyPositioning;
      return isSticky && position === StickinessPosition.bottom;
    });

    return [...stickyOnTop, ...sections.filter(section => !section.styles.outerContainer.stickyPositioning.isSticky), ...stickyOnBottom];
  }
}
