import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getLinkedArtifactType' })
export class GetLinkedArtifactTypePipe implements PipeTransform {
  transform(
    link: NewLink,
    linkTypeOption: SelectOption<string, LinkType, LinkDirection>,
    artifactTypes: ListContainer<NewArtifactType>,
    artifactMap: Record<string, NewArtifact>,
  ): NewArtifactType {
    const artifactId = LinkMethods.isOutgoing(linkTypeOption.meta) ? link.destinationArtifactId : link.sourceArtifactId;
    const artifact = artifactMap[artifactId];
    return artifactTypes.listMap[artifact.artifactTypeId];
  }
}
