import { RecordDto, SystemTenantAttributesDto, SystemTenantResponseDto } from '../../api/models';

export class NewSystemTenant implements SystemTenantResponseDto {
  id: string;
  name: string;
  domain: string;
  isPublic: boolean;
  everyoneTeamId: string;
  fileArtifactTypeId: string;
  notificationArtifactTypeId: string;
  pageAfterLoginId: null | string;
  profileArtifactTypeId: string;
  defaultAdminUserId: string;
  redirectToPreviousPage: boolean;
  systemApplicationId: string;
  systemAttributes: SystemTenantAttributesDto;
  usersFolderId: string;
  teamsFolderId: string;
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto;
  registrationAllowed = false;
  faviconFileId: null | string;
  logoFileId: null | string;

  constructor(tenant?: Partial<SystemTenantResponseDto>) {
    tenant && Object.assign(this, tenant);
  }
}
