<app-loader [loading]="m.loading"></app-loader>

<div *ngIf="this.m.page.applicationId && replaceRuntimeVariablesService.loaded" class="page">
  <div *ngIf="m.user && m.canUserAccessEdit" class="utility-buttons">
    <button (click)="navigateToEdit()" class="p-button-rounded p-button-info p-button-outlined edit-button"
            icon="pi pi-pencil" pButton type="button"></button>
  </div>

  <section
    *ngFor="let section of m.page.sections | sortStickySectionsPreviewMode; let sectionIndex = index"
    [ngClass]="section | sectionClass"
    [ngStyle]="section.styles?.outerContainer"
    [sectionIndex]="sectionIndex"
    [stickyPositioningSettings]="section.styles.outerContainer.stickyPositioning"
    appStickySection
    class="section"
  >
    <div [ngClass]="section.styles?.innerContainer.containerClass" [ngStyle]="section.styles?.innerContainer"
         [attr.id]="section.htmlId">
      <div *ngFor="let row of section.rows; let rowIndex = index" [ngStyle]="row.styles" class="row g-0">
        <div *ngFor="let block of row.blocks; let blockIndex = index"
             [class]="'col-md-' + row.layout[blockIndex]">
          <div
            *ngFor="let part of block.parts; let partIndex = index"
            [blockIndex]="blockIndex"
            [ngStyle]="part.styles"
            [partIndex]="partIndex"
            [rowIndex]="rowIndex"
            [sectionIndex]="sectionIndex"
            [stickyPositioningSettings]="part.styles.stickyPositioning"
            [attr.id]="part.htmlId"
            appStickyBlockPart
            class="block-part"
          >
            <div *ngIf="part.widget" [id]="part.hash">
              <app-widget-container
                [applicationId]="m.page.applicationId"
                [pageId]="m.page.id"
                [page]="m.page"
                [disabled]="true"
                [hash]="part.hash"
                [isLayoutMode]="true"
                [isPreviewMode]="true"
                [widget]="part.widget"
                appWidgetContainerStopPropagation
              ></app-widget-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
