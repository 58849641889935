import { Injectable } from '@angular/core';
import { WorkflowCreateRequestDto, WorkflowResponseDto, WorkflowUpdateRequestDto, WorkflowUseRequestDto } from '@api/models';
import { TenantWorkflowService } from '@api/services';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { CommonListParams } from '@shared/types/common-list.types';
import { StateKey } from '@shared/types/local-storage.types';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { RuleTriggerType, WorkflowActionType, WorkflowRule } from '@workflows/types';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { map, Observable } from 'rxjs';
import { WorkflowActionFactory, WorkflowTriggerFactory } from './factory';

@Injectable({ providedIn: 'root' })
export class RuleDataAccessService {
  constructor(
    protected readonly tenantWorkflowService: TenantWorkflowService,
    protected readonly localStorageService: LocalStorageService,
    protected readonly actionFactory: WorkflowActionFactory,
    protected readonly triggerFactory: WorkflowTriggerFactory,
    protected readonly converterService: RuleConditionValueConverterService,
  ) {}

  saveRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    const workflowCreateRequest = this.toCreateDto(rule) as WorkflowCreateRequestDto;
    return this.tenantWorkflowService.workflowControllerCreate({ body: workflowCreateRequest }).pipe(map(dto => this.fromDto(dto)));
  }

  useRule$(rule: WorkflowRule, ownerId: string): Observable<WorkflowRule> {
    const useRequest: WorkflowUseRequestDto = {
      id: rule.id,
      itemId: ownerId,
    };
    return this.tenantWorkflowService.workflowControllerInsert({ body: useRequest }).pipe(map(dto => this.fromDto(dto)));
  }

  unuseRule$(rule: WorkflowRule, ownerId: string): Observable<WorkflowRule> {
    const useRequest: WorkflowUseRequestDto = {
      id: rule.id,
      itemId: ownerId,
    };
    return this.tenantWorkflowService.workflowControllerRemove({ body: useRequest }).pipe(map(dto => this.fromDto(dto)));
  }

  updateRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    const workflowUpdateRequest = this.toUpdateDto(rule) as WorkflowUpdateRequestDto;
    return this.tenantWorkflowService.workflowControllerUpdate({ body: workflowUpdateRequest }).pipe(map(dto => this.fromDto(dto)));
  }

  loadAllRules$(params: CommonListParams): Observable<WorkflowRule[]> {
    return this.tenantWorkflowService.workflowControllerList(params).pipe(
      map(response => response.data),
      map(rules => this.loadRules(rules)),
    );
  }

  deleteRule$(rule: WorkflowRule): Observable<WorkflowRule> {
    return this.tenantWorkflowService.workflowControllerDelete({ id: rule.id }).pipe(map(dto => this.fromDto(dto)));
  }

  saveRules(rules: Record<string, WorkflowRule>) {
    const allRules = Object.values(rules).map(rule => rule.toServer(this.converterService));
    this.localStorageService.set(StateKey.workflowsAllRules, allRules);
  }

  loadRules(allRules: WorkflowResponseDto[]): WorkflowRule[] {
    return allRules.map(rule => this.fromDto(rule));
  }

  fromDto(ruleDto: WorkflowResponseDto): WorkflowRule {
    const workflowRule = new WorkflowRule(ruleDto);
    workflowRule.triggers = ruleDto.triggers.map(trigger => this.triggerFactory.getWorkflowTrigger(trigger.type as RuleTriggerType, trigger));
    workflowRule.actions = ruleDto.actions.map(action => this.actionFactory.getWorkflowAction(action.type as WorkflowActionType, action));
    if (workflowRule.condition) {
      workflowRule.condition = new RuleConditionGroup(this.converterService, workflowRule.condition);
    }
    return workflowRule;
  }

  toUpdateDto(rule: WorkflowRule): WorkflowUpdateRequestDto {
    return rule.toServer(this.converterService);
  }

  toCreateDto(rule: WorkflowRule): WorkflowCreateRequestDto {
    return rule.toCreateDto(this.converterService);
  }
}
