import { Component, Input } from '@angular/core';
import { ALIAS_LABEL } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { LinkType } from '@shared/types/link-type.types';

@Component({
  selector: 'app-link-type-form',
  templateUrl: './link-type-form.component.html',
  styleUrls: ['./link-type-form.component.scss'],
})
export class LinkTypeFormComponent {
  @Input() applications: NewApplication[];
  @Input() linkType: LinkType;

  ALIAS_LABEL = ALIAS_LABEL;
}
