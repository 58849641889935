import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { LinkConstants } from '@shared/constants/link.constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { lastValueFrom } from 'rxjs';
import {
  ArtifactTypeLinkRestriction,
  GetLinkRestrictionsParams,
  LinkRestriction,
  NewLink,
  RelationInBoolean,
  RelationTypeEnum,
  UngroupedArtifactTypeLinkRestriction,
} from '../types/link.types';
import { SelectOption } from '../types/shared.types';

const isOutgoing = (direction: LinkDirection): boolean => direction === LinkDirection.outgoing;
const isIncoming = (direction: LinkDirection): boolean => direction === LinkDirection.incoming;

const getArtifactLinkTypesFilter = (artifactTypeId: string): string => {
  return JSON.stringify({
    $or: [
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { 'restrictions.sourceArtifactTypeId': { $eq: { $oid: artifactTypeId } } },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { 'restrictions.destinationArtifactTypeId': { $eq: { $oid: artifactTypeId } } },
    ],
  });
};

const getLinksFilterForArtifact = (artifactId: string, linkTypeIdsMap: Record<string, any>): any => {
  return JSON.stringify({
    $and: [
      {
        $or: [{ destinationArtifactId: { $in: [{ $oid: artifactId }] } }, { sourceArtifactId: { $in: [{ $oid: artifactId }] } }],
      },
      { deleted: { $eq: null } },
      { linkTypeId: { $in: Object.keys(linkTypeIdsMap).map(id => ({ $oid: id })) } },
    ],
  });
};

const isArtifactDestination = (artifact: NewArtifact, link: NewLink): boolean => {
  return artifact.id === link.destinationArtifactId;
};

const getUniqueArtifactsFromLinks = (links: LinkResponseDto[], cache: NewCacheService): Promise<ArtifactLinkResponseDto[]> => {
  const artifactIds = new Set<string>();

  links.forEach(link => {
    artifactIds.add(link.sourceArtifactId);
    artifactIds.add(link.destinationArtifactId);
  });

  return cache.data.artifacts.getManyAsync([...artifactIds]);
};

const getLinkTypesFromRestrictions = (restrictions: ArtifactTypeLinkRestriction[]): SelectOption<string, string, LinkDirection>[] => {
  return restrictions
    .map((restriction: ArtifactTypeLinkRestriction) => restriction.linkType)
    .filter((linkType: SelectOption<string, string, LinkDirection> | null): linkType is SelectOption<string, string, LinkDirection> => !!linkType);
};

const getRestrictionsForArtifactTypeAndLinkType = (
  artifactTypeId: string,
  linkTypes: ArtifactTypeLinkRestriction[],
  linkTypeId: string,
  direction: LinkDirection,
): ArtifactTypeLinkRestriction[] => {
  return linkTypes.filter(
    restriction =>
      restriction.linkType?.value === linkTypeId &&
      (isOutgoing(direction) ? restriction.sourceArtifactTypeIds.includes(artifactTypeId) : restriction.destinationArtifactTypeIds.includes(artifactTypeId)),
  );
};

const getRestrictionForArtifactTypeAndLinkType = (
  artifactTypeId: string,
  artifactTypeLinkRestrictions: ArtifactTypeLinkRestriction[],
  linkTypeId: string,
  direction: LinkDirection,
): ArtifactTypeLinkRestriction | null => {
  return (
    artifactTypeLinkRestrictions.find(
      restriction =>
        restriction.linkType?.value === linkTypeId &&
        (isOutgoing(direction) ? restriction.sourceArtifactTypeIds.includes(artifactTypeId) : restriction.destinationArtifactTypeIds.includes(artifactTypeId)),
    ) || null
  );
};

const getRelevantArtifactOptions = (
  relevantRestrictions: ArtifactTypeLinkRestriction[],
  direction: LinkDirection,
  artifactTypeOptions: ListContainer<NewArtifactType>,
): SelectOption<string, NewArtifactType>[] => {
  let relevantRestrictionIds: string[] = [];
  relevantRestrictions.forEach(restriction => {
    if (isOutgoing(direction)) {
      relevantRestrictionIds = relevantRestrictionIds.concat(restriction.destinationArtifactTypeIds);
    } else {
      relevantRestrictionIds = relevantRestrictionIds.concat(restriction.sourceArtifactTypeIds);
    }
  });
  return artifactTypeOptions.filterByKey('id', relevantRestrictionIds).map(artifactType => new SelectOption(artifactType.name, artifactType));
};

const getLinkRestrictionsForArtifactType = (artifactTypeId: string, linkTypes: LinkTypeResponseDto[]): ArtifactTypeLinkRestriction[] => {
  const restrictionsGroup: ArtifactTypeLinkRestriction[] = [];

  linkTypes.forEach(linkType => {
    linkType.restrictions?.forEach(restriction => {
      if (restriction.sourceArtifactTypeId === artifactTypeId)
        addRestrictionToGroup(new GetLinkRestrictionsParams(restriction, restrictionsGroup, linkType, LinkDirection.outgoing));
      if (restriction.destinationArtifactTypeId === artifactTypeId)
        addRestrictionToGroup(new GetLinkRestrictionsParams(restriction, restrictionsGroup, linkType, LinkDirection.incoming));
    });
  });
  return restrictionsGroup;
};

const addRestrictionToGroup = (params: GetLinkRestrictionsParams): void => {
  const { restriction, group, direction, linkType } = params;
  const artifactTypeIdToAdd = isOutgoing(direction) ? restriction.destinationArtifactTypeId : restriction.sourceArtifactTypeId;
  const restrictionFoundInGroup = findRestrictionInGroup(new GetLinkRestrictionsParams(restriction, group, linkType, direction));

  if (restrictionFoundInGroup) {
    if (isOutgoing(direction) && !restrictionFoundInGroup.destinationArtifactTypeIds.find(id => id === artifactTypeIdToAdd))
      restrictionFoundInGroup.destinationArtifactTypeIds.push(artifactTypeIdToAdd);
    else if (isIncoming(direction) && !restrictionFoundInGroup.sourceArtifactTypeIds.find(id => id === artifactTypeIdToAdd))
      restrictionFoundInGroup.sourceArtifactTypeIds.push(artifactTypeIdToAdd);
  } else {
    group.push(createArtifactTypeRestriction(linkType, direction, restriction));
  }
};

const findRestrictionInGroup = (params: GetLinkRestrictionsParams): ArtifactTypeLinkRestriction | undefined => {
  const { group, linkType, direction, restriction } = params;
  return group.find(
    (item: ArtifactTypeLinkRestriction) =>
      item.linkType &&
      item.linkType.value === linkType.id &&
      item.linkType.meta === direction &&
      item.singleSource === restriction.singleSource &&
      item.singleDestination === restriction.singleDestination,
  );
};

const createArtifactTypeRestriction = (linkType: LinkTypeResponseDto, direction: LinkDirection, restriction: LinkRestriction): ArtifactTypeLinkRestriction => {
  const linkName = isOutgoing(direction) ? linkType.outgoingName : linkType.incomingName;

  return new ArtifactTypeLinkRestriction({
    linkType: new SelectOption<string, string>(linkName, linkType.id, direction),
    sourceArtifactTypeIds: [restriction.sourceArtifactTypeId],
    destinationArtifactTypeIds: [restriction.destinationArtifactTypeId],
    singleSource: restriction.singleSource,
    singleDestination: restriction.singleDestination,
    isLinkRequired: restriction.isLinkRequired,
  });
};

const getGroupedLinkRestrictions = async (artifactTypes: NewArtifactType[], linkTypes: LinkType[]): Promise<Record<string, ArtifactTypeLinkRestriction[]>> => {
  const grouped: Record<string, ArtifactTypeLinkRestriction[]> = {};
  linkTypes.forEach(linkType => {
    artifactTypes.forEach(artifactType => {
      linkType.restrictions?.forEach(restriction => {
        if (!grouped[restriction.sourceArtifactTypeId]) grouped[restriction.sourceArtifactTypeId] = [];
        if (!grouped[restriction.destinationArtifactTypeId]) grouped[restriction.destinationArtifactTypeId] = [];

        if (restriction.sourceArtifactTypeId === artifactType?.id) {
          const artifactTypeLinkRestriction = findRestrictionInGroup({
            group: grouped[artifactType.id],
            direction: LinkDirection.outgoing,
            linkType,
            restriction,
          });

          if (artifactTypeLinkRestriction) artifactTypeLinkRestriction.destinationArtifactTypeIds.push(restriction.destinationArtifactTypeId);
          else grouped[artifactType.id].push(createArtifactTypeRestriction(linkType, LinkDirection.outgoing, restriction));
        }

        if (restriction.destinationArtifactTypeId === artifactType?.id) {
          const artifactTypeLinkRestriction = findRestrictionInGroup({
            group: grouped[artifactType.id],
            direction: LinkDirection.incoming,
            linkType,
            restriction,
          });

          if (artifactTypeLinkRestriction) artifactTypeLinkRestriction.sourceArtifactTypeIds.push(restriction.sourceArtifactTypeId);
          else grouped[artifactType.id].push(createArtifactTypeRestriction(linkType, LinkDirection.incoming, restriction));
        }
      });
    });
  });

  return grouped;
};

const getUngroupedLinkRestrictionsForArtifactType = (artifactTypeId: string, linkTypes: LinkTypeResponseDto[]): UngroupedArtifactTypeLinkRestriction[] => {
  return linkTypes.reduce((res, item) => {
    if (!item?.restrictions?.length) return res;

    const restrictions = item.restrictions.map(restriction => {
      const { destinationArtifactTypeId, sourceArtifactTypeId, singleSource, singleDestination, isLinkRequired, notifySource, notifyDestination } = restriction;
      const direction = sourceArtifactTypeId === artifactTypeId ? LinkDirection.outgoing : LinkDirection.incoming;
      const linkName = isOutgoing(direction) ? item.outgoingName : item.incomingName;

      return new UngroupedArtifactTypeLinkRestriction({
        linkType: new SelectOption<string, string>(linkName, item.id, direction),
        sourceArtifactTypeId,
        destinationArtifactTypeId,
        singleSource,
        singleDestination,
        isLinkRequired,
        notifySource,
        notifyDestination,
      });
    });

    return [...res, ...restrictions];
  }, [] as any);
};

const changeRelation = (value: RelationTypeEnum): RelationInBoolean => {
  if (!LinkConstants.booleanRelationByRelationType[value])
    throw new Error(`Relation type value must be one of RelationTypeEnum, but received: "${String(value)}"`);

  return LinkConstants.booleanRelationByRelationType[value];
};

const convertRelationValue = (singleSource: boolean, singleDestination: boolean): RelationTypeEnum => {
  if (!singleSource && !singleDestination) {
    return RelationTypeEnum.manyToMany;
  } else if (singleSource && !singleDestination) {
    return RelationTypeEnum.manyToOne;
  } else if (!singleSource && singleDestination) {
    return RelationTypeEnum.oneToMany;
  } else {
    return RelationTypeEnum.oneToOne;
  }
};

const checkRestrictionBeforeDelete = async (
  linkId: string,
  sourceArtifactTypeId: string,
  destinationArtifactTypeId: string,
  tenantWidgetService: TenantWidgetService,
): Promise<number> => {
  const directionKey = 'value.model.selected.linkType.direction';
  const artifactTypeIdKey = 'value.model.selected.artifactTypeId';
  const $and = [
    { code: 'ARTIFACT' },
    { deleted: { $eq: null } },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { 'settings.widgetType': 'Linked artifact' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { 'value.model.selected.linkType.id': { $in: [linkId] } },
    {
      $or: [
        {
          $and: [{ [directionKey]: { $in: [LinkDirection.outgoing] } }, { [artifactTypeIdKey]: { $in: [sourceArtifactTypeId] } }],
        },
        {
          $and: [{ [directionKey]: { $in: [LinkDirection.incoming] } }, { [artifactTypeIdKey]: { $in: [destinationArtifactTypeId] } }],
        },
      ],
    },
  ];
  const resp = await lastValueFrom(tenantWidgetService.widgetControllerCount({ filter: JSON.stringify({ $and }) }));
  return resp.count;
};

const isUsedRestriction = (restriction: LinkRestriction, artifactType: NewArtifactType): boolean => {
  return artifactType.id === restriction.destinationArtifactTypeId || artifactType.id === restriction.sourceArtifactTypeId;
};

export const LinkMethods = {
  changeRelation,
  convertRelationValue,
  isOutgoing,
  isIncoming,
  getArtifactLinkTypesFilter,
  getLinksFilterForArtifact,
  isArtifactDestination,
  getUniqueArtifactsFromLinks,
  getLinkTypesFromRestrictions,
  getRestrictionsForArtifactTypeAndLinkType,
  getRestrictionForArtifactTypeAndLinkType,
  getRelevantArtifactOptions,
  getLinkRestrictionsForArtifactType,
  getUngroupedLinkRestrictionsForArtifactType,
  checkRestrictionBeforeDelete,
  getGroupedLinkRestrictions,
  isUsedRestriction,
};
