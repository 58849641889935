import { Injectable } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { FOLDER_FILTER_KEY } from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { IsBoolean, IsDate, IsDateTime, IsDecimal, IsEnumerated, IsHyperlink, IsInteger, IsText, IsUser } from '@shared/methods/data-type.methods';
import { BaseNumericFilterService } from '@shared/services/filter/filter-types/base-numeric-filter.service';
import { BaseUserFilterService } from '@shared/services/filter/filter-types/base-user-filter.service';
import { NonAttributeKeys } from '../../types/attribute.types';
import { NewDataType } from '../../types/data-type.types';
import { ArtifactTypeEnumFilterService } from './filter-types/artifact-type-enum-filter.service';
import { BaseBooleanFilterService } from './filter-types/base-boolean-filter.service';
import { BaseDateFilterService } from './filter-types/base-date-filter.service';
import { BaseEnumFilterService } from './filter-types/base-enum-filter.service';
import { BaseFolderFilterService } from './filter-types/base-folder-filter.service';
import { BaseTextFilterService } from './filter-types/base-text-filter.service';
import { BaseTypeFilterService } from './filter-types/base-type-filter.service';

@Injectable({ providedIn: 'root' })
export class FilterMainControlService {
  constructor(
    protected readonly enumFilterService: BaseEnumFilterService,
    protected readonly folderFilterService: BaseFolderFilterService,
    protected readonly textFilterService: BaseTextFilterService,
    protected readonly userFilterService: BaseUserFilterService,
    protected readonly booleanFilterService: BaseBooleanFilterService,
    protected readonly dateFilterService: BaseDateFilterService,
    protected readonly numericFilterService: BaseNumericFilterService,
    protected readonly artifactTypeEnumFilterService: ArtifactTypeEnumFilterService,
  ) {}

  isFolderFilterAttribute(attributeKey: string): boolean {
    return attributeKey === NonAttributeKeys.FOLDER_PATH_ID || attributeKey === FOLDER_FILTER_KEY;
  }

  getQuery(attributeKey: string, dataType: NewDataType | null, value: any, filterRule?: CoreListFilterEnum): Record<string, any> | null {
    const filterService = this.getFilterServiceByAttributeKeyOrDataType(attributeKey, dataType);
    return filterService?.getQuery(attributeKey, dataType, value, filterRule) || null;
  }

  getQueryFromRawText(
    attributeKey: string,
    dataType: NewDataType | null,
    value: any,
    delimiter = ',',
    filterRule?: CoreListFilterEnum,
  ): Record<string, any> | null {
    const filterService = this.getFilterServiceByAttributeKeyOrDataType(attributeKey, dataType);
    return filterService?.getQueryFromRawText(attributeKey, dataType, value, delimiter, filterRule) || null;
  }

  getFilterServiceForDataType(dataType: NewDataType): BaseTypeFilterService | null {
    const baseDataType = dataType.baseDataType as BaseDataType;
    if (IsEnumerated(dataType.kind)) {
      return this.enumFilterService;
    }
    if (IsText(baseDataType) || IsHyperlink(baseDataType)) {
      return this.textFilterService;
    }
    if (IsUser(baseDataType)) {
      return this.userFilterService;
    }
    if (IsDate(baseDataType) || IsDateTime(baseDataType)) {
      return this.dateFilterService;
    }
    if (IsBoolean(baseDataType)) {
      return this.booleanFilterService;
    }
    if (IsInteger(baseDataType) || IsDecimal(baseDataType)) {
      return this.numericFilterService;
    }
    return null;
  }

  getFilterServiceByAttributeKeyOrDataType(attributeKey: string, dataType: NewDataType | null): BaseTypeFilterService | null {
    if (this.isFolderFilterAttribute(attributeKey)) {
      return this.folderFilterService;
    }
    if (NonAttributeKeys.isUserSpecificAttributeKey(attributeKey)) {
      return this.userFilterService;
    }
    if (NonAttributeKeys.isDateSpecificAttributeKey(attributeKey)) {
      return this.dateFilterService;
    }
    if (NonAttributeKeys.isArtifactTypeKey(attributeKey)) {
      return this.artifactTypeEnumFilterService;
    }
    if (dataType) {
      return this.getFilterServiceForDataType(dataType);
    }
    return null;
  }
}
