import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ElvisSharedModule } from '../../../shared/elvis-shared.module';

// Components
import { DashboardComponent } from './dashboard.component';

const declarations = [DashboardComponent];

@NgModule({
  declarations: [...declarations],
  imports: [CommonModule, FileUploadModule, TableModule, ElvisSharedModule, TranslateModule],
  exports: [...declarations],
})
export class DashboardModule {}
