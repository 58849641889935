<div class="row pl-3">
  <div class="col-4 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-4">
    <input id="forEachPathInput" [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value" pInputText type="text" placeholder="Enter for-each-path"
           class="input-min-width" />
  </div>
</div>

<div class="row pl-3">
  <div class="col-2 text-bold">
    {{ 'Artifact type' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].isDynamic" id="isArtifactTypeDynamic" binary="true" class="mr-2 pt-2"
                (ngModelChange)="onIsArtifactTypeDynamicChange()"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-4">
    <input *ngIf="action.actionSettings[RuleKeys.artifactTypeId].isDynamic" id="artifactTypeInput"
           [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].value" pInputText type="text"
           placeholder="Enter dynamic artifact-type" class="input-min-width" />
    <p-dropdown *ngIf="!action.actionSettings[RuleKeys.artifactTypeId].isDynamic"
                [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].value"
                [options]="artifactTypes | transformArrayToSelectOptions : 'name'"
                [autoDisplayFirst]="false"
                [filter]="true"
                id="artifactTypeSelect"
                appendTo="body"
                optionValue="value.id"
                (ngModelChange)="onArtifactTypeChange($event)">
    </p-dropdown>
  </div>
</div>

<div class="row pl-3">
  <div class="col-2 text-bold">
    {{ 'Attributes' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].isDynamic" id="isAttributesMapDynamic" binary="true" class="mr-2 pt-2"
                (ngModelChange)="generateAttributesMap()"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let attribute of action.actionSettings[RuleKeys.attributesMap].value | keyvalue; let index = index; trackBy: attributesTrackByFn">
      <div class="row">
        <div class="col-1">
          <p-checkbox [(ngModel)]="useValues[index]" [id]="'useValue' + index" binary="true" class="mr-2 pt-0"
                      (ngModelChange)="onUseValueChange(attribute.key, attribute.value, $event)"></p-checkbox>
        </div>
        <div class="col-4" [ngClass]="{'not-in-use': !useValues[index]}">
          {{ attributes.listMap[attribute.key]?.name }}
        </div>
        <div class="col-7">
          <div *ngIf="attributes.listMap[attribute.key]?.multipleValues && !action.actionSettings[RuleKeys.attributesMap].isDynamic; else singleInput">
            <div *ngFor="let value of attribute.value; let attrIndex = index; trackBy: attrValuesToTrackByFn" class="mb-1">
              <ng-container *ngIf="useValues[index]">
                <input [id]="'attrValue' + attrIndex" [(ngModel)]="attribute.value[attrIndex]" pInputText type="text" class="input-min-width"
                       [placeholder]="'Enter value ' + (attrIndex + 1)" />
                <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus"
                        [ngClass]="{'hidden': attrIndex !== attribute.value.length - 1 }"
                        (click)="onAddAttributeValue(attribute.key)"></button>
                <button *ngIf="attribute.value.length > 1" class="p-button p-button-text p-button-danger" pButton type="button"
                        icon="pi pi-minus" (click)="onRemoveAttributeValue(attribute.key, attrIndex)"></button>
              </ng-container>
            </div>
          </div>

          <ng-template #singleInput>
            <input *ngIf="useValues[index]" [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].value![attribute.key]"
                   [placeholder]="'Enter value' | translate" pInputText class="input-min-width" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row pl-3">
  <div class="col-4 text-bold">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-4">
    <input [(ngModel)]="action.actionSettings[RuleKeys.storeResultsPath].value" [placeholder]="'Enter store results path' | translate" pInputText
           class="input-min-width" id="scopeInput" />
  </div>
</div>


<div class="row pl-3">
  <div class="col-2 text-bold">
    {{ 'Parent folder id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].isDynamic" id="isParentFolderDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-4">
    <input [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].value" [placeholder]="'Enter parent folder id' | translate" pInputText
           class="input-min-width" id="parentFolderIdInput" />
  </div>
</div>
