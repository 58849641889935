<h5>Borders</h5>

<div class="row">
  <h6>Shadow</h6>

  <ng-container *ngIf="allowShadowInput; else shadowDropdown">
    <div class="col-5 mb-0">
      <input [(ngModel)]="styles.boxShadow" class="w-100" pInputText type="text" />
    </div>
    <div class="col-5 mb-0">
      <a href="{{ shadowGeneratorLink }}" target="_blank">CSS shadow generator</a>
    </div>
  </ng-container>

  <ng-template #shadowDropdown>
    <div class="col-5 mb-0">
      <p-dropdown [(ngModel)]="styles.boxShadow"
                  [autoDisplayFirst]="false"
                  [options]="boxShadowOptions"></p-dropdown>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="!onlyShadow">
  <div class="row">
    <h6>Rounding</h6>

    <div class="col-3 mb-0">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="bi bi-arrow-up-left"></i>
      </span>
        <input [(ngModel)]="styles.borderTopLeftRadius" class="w-100" pInputText type="text" />
      </div>
    </div>

    <div class="col-3 mb-0">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="bi bi-arrow-up-right"></i>
      </span>
        <input [(ngModel)]="styles.borderTopRightRadius" class="w-100" pInputText type="text" />
      </div>
    </div>

    <div class="col-3 mb-0">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="bi bi-arrow-down-left"></i>
      </span>
        <input [(ngModel)]="styles.borderBottomLeftRadius" class="w-100" pInputText type="text" />
      </div>
    </div>

    <div class="col-3 mb-0">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="bi bi-arrow-down-right"></i>
      </span>
        <input [(ngModel)]="styles.borderBottomRightRadius" class="w-100" pInputText type="text" />
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-1 mb-0 border-arrow">
      <h6>Side</h6>
      <i class="pi pi-arrow-left"></i>
    </div>

    <div class="col-4 mb-0">
      <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.left)" [onChangeCb]="isChange()"
                        [(color)]="styles.borderLeftColor" label="Color"></app-color-picker>
    </div>

    <div class="col-3 mb-0">
      <h6>Style</h6>
      <p-dropdown [(ngModel)]="styles.borderLeftStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderStyleOptions"></p-dropdown>
    </div>

    <div class="col-3 mb-0">
      <h6>Width</h6>
      <p-dropdown [(ngModel)]="styles.borderLeftWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderWidthOptions"></p-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-1 mb-0 border-arrow">
      <i class="pi pi-arrow-right"></i>
    </div>

    <div class="col-4 mb-0">
      <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.right)" [onChangeCb]="isChange()"
                        [(color)]="styles.borderRightColor"></app-color-picker>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderRightStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderStyleOptions"></p-dropdown>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderRightWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderWidthOptions"></p-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-1 mb-0 border-arrow">
      <i class="pi pi-arrow-up"></i>
    </div>

    <div class="col-4 mb-0">
      <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.top)" [onChangeCb]="isChange()"
                        [(color)]="styles.borderTopColor"></app-color-picker>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderTopStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderStyleOptions"></p-dropdown>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderTopWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderWidthOptions"></p-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-1 mb-0 border-arrow">
      <i class="pi pi-arrow-down"></i>
    </div>

    <div class="col-4 mb-0">
      <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.bottom)" [onChangeCb]="isChange()"
                        [(color)]="styles.borderBottomColor"></app-color-picker>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderBottomStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderStyleOptions"></p-dropdown>
    </div>

    <div class="col-3 mb-0">
      <p-dropdown [(ngModel)]="styles.borderBottomWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                  [options]="borderWidthOptions"></p-dropdown>
    </div>
  </div>
</ng-container>
