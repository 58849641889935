import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { LanguageSwitcherService } from './services/language-switcher.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  constructor(public readonly languageSwitcherService: LanguageSwitcherService) {}
}

@NgModule({
  imports: [CommonModule, DropdownModule, FormsModule, TranslateModule],
  declarations: [LanguageSwitcherComponent],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
