<div class="form_wrapper">
  <div class="form" *ngIf="linkType">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-3">
        <span class="dynamic-label">
          <input type="text" pInputText id="name" [(ngModel)]="linkType.name" />
          <label class="dynamic-label-text" for="name" translate>Name</label>
        </span>
      </div>

      <div class="col-12 md:col-6 lg:col-3">
        <span class="dynamic-label">
          <p-dropdown
            [(ngModel)]="linkType.applicationId"
            [autoDisplayFirst]="false"
            [filter]="true"
            [options]="applications | transformArrayToSelectOptions : 'name' : 'id'"
            id="application"
          >
          </p-dropdown>
          <label class="dynamic-label-text" for="application" translate>Application</label>
        </span>
      </div>

      <div class="col-12 md:col-6 lg:col-3">
        <span class="dynamic-label">
          <input [(ngModel)]="linkType.alias" id="linkTypeAlias" pInputText type="text" />
          <label class="dynamic-label-text" for="linkTypeAlias">{{ ALIAS_LABEL | translate }}</label>
        </span>
      </div>
    </div>

    <span class="dynamic-label">
      <textarea id="description" rows="5" cols="30" pInputTextarea autoResize="autoResize" [(ngModel)]="linkType.description"></textarea>
      <label class="dynamic-label-text" for="description" translate>Description</label>
    </span>

    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox binary="true" inputId="directionalLabel" id="directionalLabel" [(ngModel)]="linkType.directionalLabel"></p-checkbox>
        <label for="directionalLabel" translate>Provide directional labels for the relationship (such as parent and child)</label>
      </span>
    </span>

    <span class="dynamic-label">
      <input type="text" pInputText id="outgoing" [(ngModel)]="linkType.outgoingName" [disabled]="!linkType.directionalLabel" />
      <label class="dynamic-label-text" for="outgoing" translate>Outgoing</label>
    </span>

    <span class="dynamic-label">
      <input type="text" pInputText id="incoming" [(ngModel)]="linkType.incomingName" [disabled]="!linkType.directionalLabel" />
      <label class="dynamic-label-text" for="incoming" translate>Incoming</label>
    </span>

    <span class="dynamic-label">
      <input type="text" pInputText id="uri" [(ngModel)]="linkType.uri" />
      <label class="dynamic-label-text" for="uri" translate>URI</label>
    </span>
  </div>
</div>
