import { Component, Input } from '@angular/core';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';

@Component({
  selector: 'app-page-row-settings',
  templateUrl: './page-row-settings.component.html',
  styleUrls: ['./page-row-settings.component.scss'],
})
export class PageRowSettingsComponent {
  @Input() row: PageRow;
}
