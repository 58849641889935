import { Observable, Subject } from 'rxjs';
import { WorkflowTriggerEvent } from '../types';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RuleTriggerEventHandlerService {
  private ruleTriggerEvent: Subject<WorkflowTriggerEvent>;
  private ruleTriggerEventObservable: Observable<WorkflowTriggerEvent>;

  constructor() {
    this.ruleTriggerEvent = new Subject();
    this.ruleTriggerEventObservable = this.ruleTriggerEvent.asObservable();
  }

  get ruleTriggerEvent$(): Observable<WorkflowTriggerEvent> {
    return this.ruleTriggerEventObservable;
  }

  notifyTriggerEvent(event: WorkflowTriggerEvent) {
    this.ruleTriggerEvent.next(event);
  }
}
