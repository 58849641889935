import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '@api/services/service.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { Subscription } from 'rxjs';
import { PageBuilderHelper } from '../../helpers/page-builder.helper';
import { BreadcrumbService } from '../../services/app.breadcrumb.service';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss'],
})
export class DevelopmentComponent implements OnInit, OnDestroy {
  private attributes = new ListContainer<NewAttribute>();
  private dataTypes = new ListContainer<NewDataType>();
  private artifactTypes = new ListContainer<NewArtifactType>();
  private linkTypes = new ListContainer<LinkType>();
  private attributesSubscription$: Subscription;
  private dataTypesSubscription$: Subscription;
  private artifactTypesSubscription$: Subscription;
  private linkTypesSubscription$: Subscription;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly serviceService: ServiceService,
    private readonly localStorageService: LocalStorageService,
    private readonly pageBuilderHelper: PageBuilderHelper,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Development' }]);
    // const tenantData = this.cacheService.getData();
    // this.attributesSubscription$ = tenantData.attributes.subscribe(attributes => this.attributes.setList(attributes, 'id'));
    // this.dataTypesSubscription$ = tenantData.dataTypes.subscribe(dataTypes => this.dataTypes.setList(dataTypes, 'id'));
    // this.artifactTypesSubscription$ = tenantData.artifactTypes.subscribe(artifactTypes => this.artifactTypes.setList(artifactTypes, 'id'));
    // this.linkTypesSubscription$ = tenantData.linkTypes.subscribe(linkTypes => this.linkTypes.setList(linkTypes, 'id'));
  }

  ngOnDestroy(): void {
    this.attributesSubscription$ && this.attributesSubscription$.unsubscribe();
    this.dataTypesSubscription$ && this.dataTypesSubscription$.unsubscribe();
    this.artifactTypesSubscription$ && this.artifactTypesSubscription$.unsubscribe();
    this.linkTypesSubscription$ && this.linkTypesSubscription$.unsubscribe();
  }

  reset(): void {
    this.serviceService.serviceControllerReset().subscribe(async () => {
      this.localStorageService.clear();
      await this.router.navigateByUrl('/login');
    });
  }

  setup(): void {
    this.serviceService.serviceControllerSetup({}).subscribe({
      error: err => {
        console.log(err);
      },
      next: (async () => {
        this.localStorageService.clear();
        await this.router.navigateByUrl('/login');
      }).bind(this),
    });
  }

  // export(): void {
  //   this.fileService.export();
  // }

  async createDefaultPages(): Promise<any> {
    await this.pageBuilderHelper.createAndAssignDefaultsToAllArtifactTypes(this.attributes, this.dataTypes, this.artifactTypes, this.linkTypes);
  }

  /* this was one-time-use only method, may use it in future */
  // async renamePageUrl(): Promise<any> {
  //   const tenantIds = (await this.tenantGlobalService.list()).data.map(tenant => tenant.id);
  //
  //   for await (const tenantId of tenantIds) {
  //     const pages = (await this.tenantPageService.pageControllerList({ tenantId }).toPromise()).data;
  //
  //     const before = 'page-preview';
  //     const after = 'page';
  //
  //     for await (const page of pages) {
  //       page.sections.forEach((section: PageSection) => {
  //         section.rows.forEach(row => {
  //           row.blocks.forEach(block => {
  //             block.parts.forEach(part => {
  //               if (part.widget && part.widget.code === WidgetType.list) {
  //                 const url = part.widget.value && part.widget.value.model && part.widget.value.model.settings.urlToNavigate;
  //                 if (url.includes(before)) {
  //                   part.widget.value.model.settings.urlToNavigate = url.replace(before, after);
  //                 }
  //               } else if (part.widget && part.widget.code === WidgetType.menu) {
  //                 part.widget.value.model.items.forEach(item => {
  //                   if (item.url && item.url.includes(before)) {
  //                     item.url = item.url.replace(before, after);
  //                   }
  //                   if (item.items && item.items.length) {
  //                     item.items.forEach(link => {
  //                       if (link.url && link.url.includes(before)) {
  //                         link.url = link.url.replace(before, after);
  //                       }
  //                     });
  //                   }
  //                 });
  //               }
  //             });
  //           });
  //         });
  //       });
  //       const body: PageUpdateRequestDto = { id: page.id, sections: page.sections };
  //
  //       await this.tenantPageService.pageControllerUpdate({ tenantId, body }).toPromise();
  //     }
  //   }
  // }
}
