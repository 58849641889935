import { NewArtifactTypeClientAttribute } from './artifact-type.types';
import { NewAttribute, NewClientAttribute } from './attribute.types';
import { NewDataType } from './data-type.types';
import { ListContainer } from './list-container.types';
import { NewUser } from './user.types';

export class AttributeToClientParams {
  dataTypes: ListContainer<NewDataType>;
  attributes: ListContainer<NewAttribute>;
  users: NewUser[];
  clientAttribute: NewClientAttribute | NewArtifactTypeClientAttribute;
  value: any;

  constructor(params: Partial<AttributeToClientParams>) {
    Object.assign(this, params);
  }
}

export class AttributeToServerParams {
  dataType: NewDataType | null;
  attribute: NewAttribute | null;
  value: any;

  constructor(params: Partial<AttributeToServerParams>) {
    Object.assign(this, params);
  }
}
