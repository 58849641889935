import { NewTeam } from '@shared/types/team.types';
import { NewUser } from '@shared/types/user.types';

export class TeamPageModel {
  loading = false;
  inSavingProgress = false;
  team = new NewTeam();
  teamUsers: NewUser[] = [];
  initTeamUserIds: string[] = [];
  noTeamUsers: NewUser[] = [];
  userList: NewUser[] = [];

  constructor(model?: Partial<TeamPageModel>) {
    model && Object.assign(this, model);
  }
}
