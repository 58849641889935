import { Injectable } from '@angular/core';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { SharedMethods } from '@shared/methods/shared.methods';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type PageElementName = typeof PageSection.name | typeof PageRow.name | typeof PageBlockPart.name;

@Injectable({ providedIn: 'root' })
export class PageBuilderGraphicalCopyPasterHelperService {
  readonly copyMethods: Record<PageElementName, (...args: any[]) => any> = {
    [PageSection.name]: (elementToCopy: PageSection) => this.copySection(elementToCopy),
    [PageRow.name]: (elementToCopy: PageRow) => this.copyRow(elementToCopy),
    [PageBlockPart.name]: (elementToCopy: PageBlockPart) => this.copyBlockPart(elementToCopy),
  };

  readonly pasteMethods: Record<PageElementName, (...args: any[]) => any> = {
    [PageSection.name]: (elementToPasteInto: PageSection) => this.pasteIntoPageSection(elementToPasteInto),
    [PageRow.name]: (elementToPasteInto: PageRow) => this.pasteIntoPageRow(elementToPasteInto),
    [PageBlockPart.name]: (elementToPasteInto: PageBlockPart) => this.pasteIntoPageBlockPart(elementToPasteInto),
  };

  private copiedSectionSubject: BehaviorSubject<PageSection | null> = new BehaviorSubject<PageSection | null>(null);
  private copiedRowSubject: BehaviorSubject<PageRow | null> = new BehaviorSubject<PageRow | null>(null);
  private copiedBlockPartSubject: BehaviorSubject<PageBlockPart | null> = new BehaviorSubject<PageBlockPart | null>(null);

  private copiedSection$: Observable<PageSection | null> = this.copiedSectionSubject.asObservable();
  private copiedRow$: Observable<PageRow | null> = this.copiedRowSubject.asObservable();
  private copiedBlockPart$: Observable<PageBlockPart | null> = this.copiedBlockPartSubject.asObservable();

  get hasSectionCopy$(): Observable<boolean> {
    return this.copiedSection$.pipe(map(Boolean));
  }

  get hasRowCopy$(): Observable<boolean> {
    return this.copiedRow$.pipe(map(Boolean));
  }

  get hasBlockPartCopy$(): Observable<boolean> {
    return this.copiedBlockPart$.pipe(map(Boolean));
  }

  private get copiedSection(): PageSection | null {
    return this.copiedSectionSubject.value;
  }

  private set copiedSection(section: PageSection | null) {
    this.copiedSectionSubject.next(section);
  }

  private get copiedRow(): PageRow | null {
    return this.copiedRowSubject.value;
  }

  private set copiedRow(row: PageRow | null) {
    this.copiedRowSubject.next(row);
  }

  private get copiedBlockPart(): PageBlockPart | null {
    return this.copiedBlockPartSubject.value;
  }

  private set copiedBlockPart(blockPart: PageBlockPart | null) {
    this.copiedBlockPartSubject.next(blockPart);
  }

  private copySection(section: PageSection): void {
    const copy = cloneDeep(section);
    copy.removeIdsForReuse(true);
    this.copiedSection = copy;
    this.setSectionToServerValue(this.copiedSection);
  }

  private copyRow(row: PageRow): void {
    const copy = cloneDeep(row);
    copy.removeIdsForReuse(true);
    this.copiedRow = copy;
    this.setRowToServerValue(this.copiedRow);
  }

  private copyBlockPart(blockPart: PageBlockPart): void {
    const copy = cloneDeep(blockPart);
    copy.removeIdsForReuse(true);
    this.copiedBlockPart = copy;
    this.setBlockPartToServerValue(this.copiedBlockPart);
  }

  private setSectionToServerValue(section: PageSection): void {
    section.rows.forEach((row: PageRow) => this.setRowToServerValue(row));
  }

  private setRowToServerValue(row: PageRow): void {
    row.blocks.forEach((block: PageBlock) => {
      block.parts.forEach((part: PageBlockPart) => {
        this.setBlockPartToServerValue(part);
      });
    });
  }

  private setBlockPartToServerValue(blockPart: PageBlockPart): void {
    if (!blockPart.widget?.value?.model) {
      return;
    }

    if (blockPart.widget.value.model.copy) {
      const { settings, ...model } = blockPart.widget.value.model.copy();
      const { sidebarModal, sidebar, listMatrix, card } = WidgetType;
      [sidebarModal, sidebar, listMatrix, card].includes(blockPart.widget.code) && (model.settings = settings);
      blockPart.widget.value.model = model;
    } else {
      blockPart.widget.value.model = blockPart.widget.value.model.toServer();
    }
  }

  private pasteIntoPageSection(sectionToPasteInto: PageSection): void {
    if (!this.copiedSection) {
      return;
    }

    const copiedSection = cloneDeep(this.copiedSection);
    copiedSection.modalId = sectionToPasteInto.modalId;

    Object.assign(sectionToPasteInto, copiedSection);
    Object.assign(sectionToPasteInto.rows, this.cloneRowArray(copiedSection.rows));
  }

  private cloneRowArray(rowArrayToClone: PageRow[]): PageRow[] {
    const clonedRows = new Array<PageRow>();
    rowArrayToClone.forEach(row => {
      const newRow = new PageRow();
      this.pasteIntoPageRow(newRow, row);
      clonedRows.push(newRow);
    });
    return clonedRows;
  }

  private pasteIntoPageRow(rowToPasteInto: PageRow, copiedRow?: PageRow): void {
    if (!copiedRow && !this.copiedRow) {
      return;
    }

    copiedRow ??= cloneDeep(this.copiedRow!);

    Object.assign(rowToPasteInto, copiedRow);
    Object.assign(rowToPasteInto.blocks, this.cloneBlockArray(copiedRow.blocks));
  }

  private cloneBlockArray(blockArrayToClone: PageBlock[]): PageBlock[] {
    const clonedBlocks = new Array<PageBlock>();
    blockArrayToClone.forEach(block => {
      const clonedBlockParts = new Array<PageBlockPart>();
      block.parts.forEach(blockPart => {
        const clonedBlockPart = new PageBlockPart();
        this.pasteIntoPageBlockPart(clonedBlockPart, blockPart);
        clonedBlockParts.push(clonedBlockPart);
      });
      clonedBlocks.push(new PageBlock(clonedBlockParts));
    });
    return clonedBlocks;
  }

  private pasteIntoPageBlockPart(blockPartToPasteInto: PageBlockPart, copiedBlockPart?: PageBlockPart): void {
    if (!copiedBlockPart && !this.copiedBlockPart) {
      return;
    }

    blockPartToPasteInto.widget = null;
    copiedBlockPart ??= cloneDeep(this.copiedBlockPart!);

    setTimeout(() => {
      Object.assign(blockPartToPasteInto, copiedBlockPart);
      blockPartToPasteInto.hash = SharedMethods.getUniqueId();
    });
  }
}
