import { ManageUserApplicationsActionWorkflowSettingsDto } from '@api/models';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export type WorkflowUpdateAttributeMapValue = string | string[] | null;
export type WorkflowUpdateAttributeMap = Record<string, WorkflowUpdateAttributeMapValue>;

export enum ManageUserApplicationsOperations {
  addAdmin = 'ADD_ADMIN',
  addNonAdmin = 'ADD_NON_ADMIN',
  remove = 'REMOVE',
}

export enum ManageUserApplicationsKeys {
  userProfileId = 'userProfileId',
  applications = 'applications',
}

export interface ManageUserApplicationsApplication {
  applicationId: string;
  operation: ManageUserApplicationsOperations;
}

export class WorkflowActionManageUserApplicationsDto implements ManageUserApplicationsActionWorkflowSettingsDto {
  userProfileId: ManageUserApplicationsActionWorkflowSettingsDto[ManageUserApplicationsKeys.userProfileId] = { value: '', isDynamic: false };
  applications: ManageUserApplicationsActionWorkflowSettingsDto[ManageUserApplicationsKeys.applications] = { value: {}, isDynamic: false };

  constructor(action?: WorkflowActionManageUserApplicationsDto) {
    action && Object.assign(action);
  }
}

export class WorkflowActionManageUserApplications extends AbstractWorkflowAction<WorkflowActionManageUserApplicationsDto> {
  manageUserApplicationsApplications: ManageUserApplicationsApplication[] = [];

  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionManageUserApplicationsDto, type: WorkflowActionType.MANAGE_USER_APPLICATIONS, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      const applications =
        (dto.actionSettings as WorkflowActionManageUserApplicationsDto).applications.value ||
        ({} as WorkflowActionManageUserApplicationsDto['applications']['value']);
      this.manageUserApplicationsApplications = Object.entries(applications).map(([applicationId, application]) => {
        let operation = ManageUserApplicationsOperations.remove;
        if (application[0] === 'ADD') {
          if (application[1] === 'ADMIN') {
            operation = ManageUserApplicationsOperations.addAdmin;
          }
          operation = ManageUserApplicationsOperations.addNonAdmin;
        }
        return {
          applicationId,
          operation,
        };
      });
    }
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [ManageUserApplicationsKeys.applications]: {
          isDynamic: false,
          value: this.manageUserApplicationsApplications.reduce(
            (output, { applicationId, operation }) => {
              let value;
              switch (operation) {
                case ManageUserApplicationsOperations.addAdmin:
                  value = ['ADD', 'ADMIN'];
                  break;
                case ManageUserApplicationsOperations.addNonAdmin:
                  value = ['ADD', 'NON-ADMIN'];
                  break;
                case ManageUserApplicationsOperations.remove:
                  value = ['REMOVE'];
                  break;
              }
              Object.assign(output, { [applicationId]: value });
              return output;
            },
            {} as WorkflowActionManageUserApplicationsDto['applications']['value'],
          ),
        },
      },
    };
  }
}
