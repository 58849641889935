import { Component, Input } from '@angular/core';
import { AvrOutputTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrActionTypes } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';

@Component({
  selector: 'app-avr-widget-view',
  templateUrl: './avr-widget-view.component.html',
  styleUrls: ['./avr-widget-view.component.scss'],
})
export class AvrWidgetViewComponent {
  @Input() m: AvrWidgetModel;
  toggleShowRender = false;

  constructor(private readonly s: AvrWidgetService) {}

  avrFileTypes = AvrOutputTypes;

  onButtonClick(): void {
    switch (this.m.settings.response.actionType) {
      case AvrActionTypes.download:
        this.s.downloadFile();
        break;
      case AvrActionTypes.renderOnClick:
        this.toggleShowRender = !this.toggleShowRender;
        break;
      default:
        this.toggleShowRender = false;
    }
  }

  isShowButton(): boolean {
    return this.m.settings.response.actionType !== AvrActionTypes.render;
  }

  isShowRender(): boolean {
    return this.m.settings.response.actionType === AvrActionTypes.render || this.toggleShowRender === true;
  }
}
