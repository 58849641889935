import { Pipe, PipeTransform } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'getDataTypeFromAttributeId' })
export class GetDataTypeFromAttributeIdPipe implements PipeTransform {
  transform(attributeId: string, attributes: ListContainer<NewAttribute>, dataTypes: ListContainer<NewDataType>): NewDataType {
    const attribute = attributes.listMap[attributeId];
    return dataTypes.listMap[attribute.dataTypeId];
  }
}
