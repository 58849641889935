import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewDataType } from '@shared/types/data-type.types';

@Pipe({ name: 'shouldShowPlaceholderOption' })
export class ShouldShowPlaceholderOptionPipe implements PipeTransform {
  transform(dataType: NewDataType): boolean {
    const { boolean, file } = BaseDataType;
    return (
      !!dataType.baseDataType &&
      (dataType.kind === DataTypeKind.enumerated || (dataType.kind === DataTypeKind.simple && ![boolean, file].includes(dataType.baseDataType)))
    );
  }
}
