import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BlockLocation } from '@private/pages/page-management/page-builder-graphical/types/block-location';
import { BlockPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/block-part-drop-event';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';

@Component({
  selector: 'app-page-block',
  templateUrl: './page-block.component.html',
  styleUrls: ['./page-block.component.scss'],
})
export class PageBlockComponent implements BlockLocation {
  @Input() block: PageBlock;
  @Input() sectionIndex: number;
  @Input() rowIndex: number;
  @Input() blockIndex: number;
  @Input() modalId = '';
  @Input() page: Page;

  constructor(public readonly dragDropService: PageBuilderGraphicalDragDropService, private readonly eventsService: PageBuilderGraphicalEventsService) {}

  get location(): BlockLocation {
    const filteredSections = this.dragDropService.currentSections.filter(section => section.modalId === this.modalId && section.index === this.sectionIndex);
    const sectionIndex = filteredSections[0]?.innerIndex;
    const { rowIndex, blockIndex, modalId } = this;

    return { sectionIndex, rowIndex, blockIndex, modalId };
  }

  dropBlockPart($event: CdkDragDrop<BlockPartDropEvent>): void {
    $event.container.data.modalId = this.modalId;
    this.eventsService.publish<CdkDragDrop<BlockPartDropEvent>>(PageBuilderEventType.blockPartDrop, $event);
  }
}
