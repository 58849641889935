<ng-container *ngIf="artifactHistory && artifactHistory.length">
  <div *ngFor="let historyRecord of artifactHistory" class="history-row">
    <span *ngIf="(historyRecord | formatHistoryMessageToClient : attributes : dataTypes : users) as artifactHistory">
      <span class="date-record"> {{artifactHistory.date}} </span>
      <span class="user-record"> {{artifactHistory.user?.email}} </span>
      <span [class]="'operation-type-record-' + artifactHistory.operation"> {{artifactHistory.operation}} </span>
      <span class="attributes-record" [innerHTML]="artifactHistory.attributes | formatHistoryAttributesToString : artifactHistory.operation"></span>
    </span>
    <hr>
  </div>
</ng-container>
