import { NewAttribute } from '../types/attribute.types';
import { NewDataType } from '../types/data-type.types';

export const GetAttributeById = (attributeId: string, attributesMap: Record<string, NewAttribute>): NewAttribute => {
  return attributesMap[attributeId];
};

export const GetDataTypeByAttributeId = (
  attributeId: string,
  attributesMap: Record<string, NewAttribute>,
  dataTypesMap: Record<string, NewDataType>,
): NewDataType => {
  const attribute = attributesMap[attributeId];
  return dataTypesMap[attribute?.dataTypeId];
};
