import { animate, state, style, transition, trigger } from '@angular/animations';

export const MenuItemAnimation = trigger('children', [
  state(
    'void',
    style({
      height: '0px',
    }),
  ),
  state(
    'hiddenAnimated',
    style({
      height: '0px',
    }),
  ),
  state(
    'visibleAnimated',
    style({
      height: '*',
    }),
  ),
  state(
    'visible',
    style({
      height: '*',
      'z-index': 100,
    }),
  ),
  state(
    'hidden',
    style({
      height: '0px',
      'z-index': '*',
    }),
  ),
  state(
    'slimVisibleAnimated',
    style({
      opacity: 1,
      transform: 'none',
    }),
  ),
  state(
    'slimHiddenAnimated',
    style({
      opacity: 0,
      transform: 'translateX(20px)',
    }),
  ),
  transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
  transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
  transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
  transition('void => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
  transition('slimHiddenAnimated => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
]);
