<p-table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn class="p-text-truncate">
        {{ col.label }}
        <p-sortIcon [pSortableColumn]="col.key" [field]="col.key" *ngIf="col.key === NAME_VALUE"></p-sortIcon>
        <p-columnFilter *ngIf="col.key === NAME_VALUE" [field]="col.key" type="text" matchMode="contains" display="menu"></p-columnFilter>
      </th>
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tenant let-columns="columns" let-index="rowIndex">
    <tr>
      <td *ngFor="let col of columns">
        <div class="p-text-truncate">
          <span [ngClass]="{ red: !!tenant.deleted, green: !tenant.deleted }">{{ tenant | printTenantToTable : col.key }}</span>
        </div>
      </td>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/system', TENANT_VALUE, tenant.id]">
            <button pButton class="p-button p-button-warning" type="button" [label]="'Edit' | translate" icon="pi pi-pencil" iconPos="left"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
