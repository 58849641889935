import { RangedGridContainer } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { GridCell } from '@shared/components/grid-layout-generator/types/grid-cell';
import { RangedGridLayoutHolder } from '@shared/components/grid-layout-generator/types/ranged-grid-layout-holder';
import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { INITIAL_GRID_COLUMN_SIZE, INITIAL_GRID_ROW_SIZE } from '@shared/constants/constants';
import { Area } from '@widgets/card-widget/types/area';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

function setLastValueToNewBreakpoint(lastRange: StyleApplicationBreakpoint, newRange: StyleApplicationBreakpoint): (value: RangedStyleValue<any>) => void {
  return (value: RangedStyleValue<any>): void => {
    value.set(newRange, value.get(lastRange)!);
  };
}

function deletedStyleValueOfDeletedBreakpoint(deletedBreakpoint: StyleApplicationBreakpoint): (rangedStyleValue: RangedStyleValue<any>) => void {
  return (rangedStyleValue: RangedStyleValue<any>) => rangedStyleValue.delete(deletedBreakpoint);
}

export abstract class RangedGridLayoutController implements RangedGridLayoutHolder {
  columnsCount$: Observable<number>;
  rowsCount$: Observable<number>;

  layoutUpdate: Subject<void> = new Subject<void>();

  private readonly activeBreakpointIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected constructor(
    public areas: GenericArea<any>[],
    public cells: GridCell[],
    public readonly container: RangedGridContainer,
    public breakpoints: StyleApplicationBreakpoint[],
  ) {
    this.columnsCount$ = this.getTracksCountObservable(this.container.gridTemplateColumns);
    this.rowsCount$ = this.getTracksCountObservable(this.container.gridTemplateRows);
  }

  get activeBreakpoint$(): Observable<StyleApplicationBreakpoint> {
    return this.activeBreakpointIndex.pipe(map((index: number) => this.breakpoints[index]));
  }

  get activeBreakpoint(): StyleApplicationBreakpoint {
    return this.breakpoints[this.activeBreakpointIndex.value];
  }

  setActiveBreakpoint(index: number): void {
    this.activeBreakpointIndex.next(index);
  }

  addBreakpoint(): void {
    const lastBreakpoint = this.breakpoints[this.breakpoints.length - 1];
    const newBreakpoint = new StyleApplicationBreakpoint(this.defaultNextBreakpointValue);

    const allRangedStyleValues = this.getRangedStyleValues();
    allRangedStyleValues.forEach(setLastValueToNewBreakpoint(lastBreakpoint, newBreakpoint));

    this.breakpoints = [...this.breakpoints, newBreakpoint];
    this.activeBreakpointIndex.next(this.breakpoints.length - 1);
  }

  deleteBreakpoint(indexToDelete: number): void {
    const { activeBreakpoint } = this;
    const activeBreakpointIndexBeforeDeletion = this.activeBreakpointIndex.value;
    const deletedBreakpoint = this.breakpoints[indexToDelete];
    this.breakpoints = this.breakpoints.filter((breakpoint: StyleApplicationBreakpoint) => breakpoint !== deletedBreakpoint);

    const activeBreakpointIndex = this.breakpoints.findIndex((breakpoint: StyleApplicationBreakpoint) => breakpoint === activeBreakpoint);
    const newActiveIndex = activeBreakpointIndex !== -1 ? activeBreakpointIndex : activeBreakpointIndexBeforeDeletion - 1;

    this.activeBreakpointIndex.next(newActiveIndex);

    const allRangedStyleValues = this.getRangedStyleValues();
    allRangedStyleValues.forEach(deletedStyleValueOfDeletedBreakpoint(deletedBreakpoint));
  }

  addColumnForBreakpoint(): void {
    const styleValue = this.container.gridTemplateColumns.get(this.activeBreakpoint)!;
    this.container.gridTemplateColumns.set(this.activeBreakpoint, `${styleValue} ${INITIAL_GRID_COLUMN_SIZE}`);
  }

  deleteColumnForBreakpoint(columnIndex: number): void {
    const styleValue = this.container.gridTemplateColumns.get(this.activeBreakpoint)!;
    const columns = styleValue
      .split(' ')
      .filter((_: string, index: number) => index !== columnIndex)
      .join(' ');
    this.container.gridTemplateColumns.set(this.activeBreakpoint, columns);
  }

  addRowForBreakpoint(): void {
    const styleValue = this.container.gridTemplateRows.get(this.activeBreakpoint)!;
    this.container.gridTemplateRows.set(this.activeBreakpoint, `${styleValue} ${INITIAL_GRID_ROW_SIZE}`);
  }

  deleteRowForBreakpoint(rowIndex: number): void {
    const styleValue = this.container.gridTemplateRows.get(this.activeBreakpoint)!;
    // TODO: extract util
    const rows = styleValue
      .split(' ')
      .filter((_: string, index: number) => index !== rowIndex)
      .join(' ');
    this.container.gridTemplateRows.set(this.activeBreakpoint, rows);
  }

  updateGridTemplateColumnsForBreakpoint(styleValue: string, breakpointIndex: number): void {
    const breakpoint = this.breakpoints[breakpointIndex];
    this.container.gridTemplateColumns.set(breakpoint, styleValue);
  }

  updateGridTemplateRowsForBreakpoint(styleValue: string, breakpointIndex: number): void {
    const breakpoint = this.breakpoints[breakpointIndex];
    this.container.gridTemplateRows.set(breakpoint, styleValue);
  }

  addArea(): void {
    const name = `Area ${this.areas.length + 1}`;
    this.areas = [...this.areas, { ...CardWidgetModel.initialAreaFromBreakpoints(this.breakpoints), name }];
  }

  deleteArea(indexToDelete: number): void {
    const deletedArea = this.areas[indexToDelete];
    this.areas = this.areas.filter((area: Area) => area !== deletedArea);
  }

  private getTracksCountObservable(tracks: RangedStyleValue<string>): Observable<number> {
    return merge(this.activeBreakpoint$, this.layoutUpdate).pipe(map(() => tracks.get(this.activeBreakpoint)?.split(' ').length || 0));
  }

  private getRangedStyleValues(): RangedStyleValue<any>[] {
    const areaValues = this.areas.reduce((values: RangedStyleValue<any>[], area: Area) => {
      return [...values, area.visible, area.gridColumnStart, area.gridColumnEnd, area.gridRowStart, area.gridRowEnd];
    }, []);

    return [this.container.gridTemplateColumns, this.container.gridTemplateRows, this.container.columnGap, this.container.rowGap, ...areaValues];
  }

  private get defaultNextBreakpointValue(): number {
    const lastBreakpoint = this.breakpoints[this.breakpoints.length - 1];

    return lastBreakpoint.value === Infinity ? 1024 : Math.floor(lastBreakpoint.value * 0.8);
  }
}
