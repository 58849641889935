import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import {
  BaseDataType,
  DataTypeKind,
  EnumeratedOption,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { Constants } from '@shared/constants/constants';
import { ConvertToClientDate, ConvertToClientDatetime, ConvertToClientTime } from '@shared/methods/date.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewUser } from '@shared/types/user.types';

export class RuntimeVariablesMethods {
  static formatVariableName(name: string): string {
    return name
      .split(' ')
      .filter(v => !!v)
      .join('-')
      .toLowerCase();
  }

  static formatVariableValue(
    dto: ArtifactResponseDto | NewArtifact,
    attributeId: string,
    artifactTypesMap: Record<string, NewArtifactType>,
    attributesMap: Record<string, NewAttribute>,
    dataTypesMap: Record<string, NewDataType>,
    usersMap: Record<string, NewUser>,
  ): string {
    if (attributeId === Constants.primaryAttributesDefaultId) return dto.id;
    if (attributeId === Constants.primaryAttributesArtifactTypeId) return dto.artifactTypeId;

    const artifact = dto.constructor.name === NewArtifact.constructor.name ? (dto as NewArtifact) : new NewArtifact({ artifactTypesMap, dto });
    const attribute = attributesMap[attributeId];

    if (attribute) {
      const clientAttribute = artifact.attributes[attributeId];

      if (clientAttribute) {
        const { value } = clientAttribute;
        const { dataTypeId, multipleValues } = attribute;
        const { user, date, time, dateTime } = BaseDataType;
        const dataType = dataTypesMap[dataTypeId];

        if (!value && typeof value !== 'boolean') return '';
        if (dataType.kind === DataTypeKind.enumerated) return RuntimeVariablesMethods.formatEnumLabels(value, multipleValues, dataType);
        if ([user, date, time, dateTime].includes(dataType.baseDataType as BaseDataType))
          return RuntimeVariablesMethods.formatValueByBaseDataType(dataType, value, multipleValues, usersMap);
        if (['string', 'number', 'boolean'].includes(typeof value)) return String(value);

        return value && value.join(', ');
      }

      return '';
    }

    return SharedMethods.getValueFromPath('attributes.' + attributeId + '.value', artifact);
  }

  private static formatEnumLabels(value: string | string[], multipleValues: boolean, dataType: NewDataType): string {
    return multipleValues
      ? (value as string[]).map(value => RuntimeVariablesMethods.formatEnumLabel(value, dataType)).join('')
      : RuntimeVariablesMethods.formatEnumLabel(value as string, dataType);
  }

  private static formatEnumLabel(value: any, dataType: NewDataType): string {
    const enumValue = value.value || value;
    const option: EnumeratedOption | undefined = dataType.values?.find(option => option.value === enumValue);
    return option?.label || '';
  }

  private static formatValueByBaseDataType(dataType: NewDataType, value: any, multipleValues: boolean, usersMap: Record<string, NewUser>): string {
    switch (dataType.baseDataType) {
      case BaseDataType.user:
        return RuntimeVariablesMethods.formatUserValue(value, usersMap);
      case BaseDataType.date:
        return RuntimeVariablesMethods.formatDateValue(value, multipleValues);
      case BaseDataType.time:
        return RuntimeVariablesMethods.formatTimeValue(value, multipleValues);
      case BaseDataType.dateTime:
        return RuntimeVariablesMethods.formatDateTimeValue(value, multipleValues);
      default:
        return '';
    }
  }

  private static formatUserValue(value: any, usersMap: Record<string, NewUser>): string {
    return Array.isArray(value)
      ? value.map(value => RuntimeVariablesMethods.formatUserValueToEmail(value, usersMap)).join(', ')
      : RuntimeVariablesMethods.formatUserValueToEmail(value, usersMap);
  }

  private static formatUserValueToEmail(value: string | SelectOption<string, string>, usersMap: Record<string, NewUser>): string {
    return typeof value === 'string' ? usersMap[value]?.email || '' : usersMap[value.value]?.email;
  }

  private static formatDateValue(value: any, multipleValues: boolean): string {
    return multipleValues
      ? value.map((date: string | Date) => ConvertToClientDate(RuntimeVariablesMethods.formatStringOrDateToDate(date))).join(', ')
      : ConvertToClientDate(RuntimeVariablesMethods.formatStringOrDateToDate(value));
  }

  private static formatTimeValue(value: any, multipleValues: boolean): string {
    if (multipleValues) return value.map((time: string | Date) => ConvertToClientTime(RuntimeVariablesMethods.formatStringOrTimeToDate(time)));
    else return ConvertToClientTime(RuntimeVariablesMethods.formatStringOrTimeToDate(value));
  }

  private static formatDateTimeValue(value: any, multipleValues: boolean): string {
    return multipleValues ? value.map((datetime: string) => ConvertToClientDatetime(new Date(datetime))).join(', ') : ConvertToClientDatetime(new Date(value));
  }

  private static formatStringOrDateToDate(value: Date | string): Date {
    return typeof value === 'string' ? new Date(value) : value;
  }

  private static formatStringOrTimeToDate(value: Date | string): Date {
    return typeof value === 'string' ? new Date(new Date().toISOString().replace(/\d{2}:\d{2}/, value)) : value;
  }
}
