import { formatDate } from '@angular/common';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';
import { AttributeInfo } from '@widgets/card-widget/types/attribute-info';

export class RecordDateExtractor implements ArtifactValueExtractor {
  static readonly createdOnAttributeId = '6372656174656f6e64617465';
  static readonly updatedOnAttributeId = '7570646174656f6e64617465';
  private static readonly dateFormat = 'dd.MM.yyyy HH:mm';

  constructor(private readonly localeId: string) {}

  static isRecordDateAttribute(attributeId: string): boolean {
    return [RecordDateExtractor.createdOnAttributeId, RecordDateExtractor.updatedOnAttributeId].some((id: string) => id === attributeId);
  }

  getValue(artifactDto: ArtifactResponseDto, { attributeId }: AttributeInfo): string {
    const date = this.getDateString(artifactDto, attributeId);

    return date ? formatDate(date, RecordDateExtractor.dateFormat, this.localeId) : '';
  }

  private getDateString({ created, updated }: ArtifactResponseDto, attributeId: string): string {
    const records: { [attributeId: string]: RecordDto } = {
      [RecordDateExtractor.createdOnAttributeId]: created,
      [RecordDateExtractor.updatedOnAttributeId]: updated,
    };

    return records[attributeId].on;
  }
}
