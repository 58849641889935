import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontStyleFormComponent } from './font-style-form.component';

@Component({
  selector: 'app-font-style-form-op',
  templateUrl: './font-style-form.component.html',
  styleUrls: ['./font-style-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontStyleFormOpComponent extends FontStyleFormComponent {}
