import { RangedStyleValueDto } from '@shared/components/grid-layout-generator/types/ranged-style-value-dto';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';

export class RangedStyleValue<StyleValue> extends Map<StyleApplicationBreakpoint, StyleValue> {
  private constructor(entries: [StyleApplicationBreakpoint, StyleValue][]) {
    super(entries);
  }

  static fromDtoAndBreakpoints<StyleValue>(dto: RangedStyleValueDto<StyleValue>, breakpoints: StyleApplicationBreakpoint[]): RangedStyleValue<StyleValue> {
    const entries: [StyleApplicationBreakpoint, StyleValue][] = Object.entries(dto).map(([styleBreakpointKey, styleValue]: [string, StyleValue]) => {
      const breakpoint = breakpoints.find(({ value }: StyleApplicationBreakpoint) => String(value) === styleBreakpointKey)!;

      return [breakpoint, styleValue];
    });

    return new RangedStyleValue(entries);
  }

  static fromValueAndBreakpoint<StyleValue>(value: StyleValue, breakpoint: StyleApplicationBreakpoint): RangedStyleValue<StyleValue> {
    return new RangedStyleValue([[breakpoint, value]]);
  }

  static fromValueAndBreakpoints<StyleValue>(value: StyleValue, breakpoints: StyleApplicationBreakpoint[]): RangedStyleValue<StyleValue> {
    const entries = [...breakpoints.map((breakpoint: StyleApplicationBreakpoint) => [breakpoint, value])] as [StyleApplicationBreakpoint, StyleValue][];

    return new RangedStyleValue<StyleValue>(entries);
  }

  get(breakpoint: StyleApplicationBreakpoint): StyleValue | undefined {
    if (super.has(breakpoint)) {
      return super.get(breakpoint);
    }

    const breakpointByValueEquality = [...this.keys()].find(({ value }: StyleApplicationBreakpoint) => value === breakpoint.value)!;

    return super.get(breakpointByValueEquality);
  }

  toDto(): RangedStyleValueDto<StyleValue> {
    return [...this.entries()].reduce((dto: RangedStyleValueDto<StyleValue>, [breakpoint, styleValue]: [StyleApplicationBreakpoint, StyleValue]) => {
      return { ...dto, [breakpoint.value]: styleValue };
    }, {});
  }
}
