<h1>{{ 'Welcome to Elwis app.' | translate }}</h1>

<!--<button pButton [label]="'Convert text widgets 14.02.2023'" (click)="convertTextWidget14022023()"></button>-->
<!--<h1 *ngIf="isConvertingProcess">{{ count }} of {{ amount }}</h1>-->

<!--<button pButton [label]="'Convert menu widgets 07.02.2023'" (click)="convertMenuWidget07022023()"></button>-->
<!--<h1 *ngIf="isConvertingProcess">{{ count }} of {{ amount }}</h1>-->

<!--<button-->
<!--  (click)="convertNumberWidgets()"-->
<!--  class="p-button-danger mx-1"-->
<!--  label="Convert Number widgets"-->
<!--  pButton>-->
<!--</button>-->

<!--<button-->
<!--  (click)="convertListMatrixWidgets()"-->
<!--  class="p-button-danger mx-1"-->
<!--  label="Convert List matrix widgets"-->
<!--  pButton>-->
<!--</button>-->

<!--<button-->
<!--  (click)="convertChartWidgets()"-->
<!--  class="p-button-danger mx-1"-->
<!--  label="Convert Chart widgets"-->
<!--  pButton>-->
<!--</button>-->
