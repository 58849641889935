import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class CoreRxSubscriptionsComponent implements OnDestroy {
  private subscriptions: Subscription[];

  protected constructor() {
    this.subscriptions = [];
  }

  registerSubscription(subscription$: Subscription): void {
    if (subscription$) this.subscriptions.push(subscription$);
  }

  registerSubscriptions(subscriptions: Subscription[]): void {
    subscriptions.forEach(subscription => this.registerSubscription(subscription));
  }

  ngOnDestroy(): void {
    this.unregisterAllSubscriptions();
  }

  private unregisterAllSubscriptions(): void {
    this.subscriptions.forEach(subscription => subscription && subscription.unsubscribe());
  }
}
