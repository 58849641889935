import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NumberWidgetHelper } from '@widgets/number-widget/helpers/number-widget.helper';
import { NumberWidgetService } from '@widgets/number-widget/services/number-widget.service';
import { NumberWidgetModel, NumberWidgetModelDto, NumberWidgetValue } from '@widgets/number-widget/types/number-widget.types';
import { ArtifactClickHandlerService } from '@widgets/shared/services/artifact-click-handler.service';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: ['./number-widget.component.scss'],
  providers: [NumberWidgetService],
})
export class NumberWidgetComponent extends WidgetsCoreComponent<NumberWidgetValue> implements OnInit {
  advancedWidget: BlockPartWidget | null;
  m: NumberWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<NumberWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: NumberWidgetService,
    public helper: NumberWidgetHelper,
    private readonly clickHandlerService: ArtifactClickHandlerService,
  ) {
    super(route, router, announcement, elRef);
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as any as NumberWidgetModelDto) : null);
  }

  onTabOpen(): void {
    return;
  }

  isRight(): boolean {
    return this.m.settings.labelPosition?.value === LabelPositionEnum.right;
  }

  isLeft(): boolean {
    return this.m.settings.labelPosition?.value === LabelPositionEnum.left;
  }

  isTop(): boolean {
    return !this.m.settings.labelPosition?.value || this.m.settings.labelPosition?.value === LabelPositionEnum.top;
  }

  isBottom(): boolean {
    return this.m.settings.labelPosition?.value === LabelPositionEnum.bottom;
  }

  async onWidgetClick(): Promise<void> {
    await this.clickHandlerService.handleArtifactClickIfNeeded(null, this.m.settings.clickAction, this.route.snapshot.queryParams);
  }
}
