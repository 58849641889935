<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'Code' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.functionBody].isDynamic" id="isDynamicIds" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea
      [(ngModel)]="action.actionSettings[RuleKeys.functionBody].value"
      [placeholder]="'Enter javascript code' | translate"
      rows="10"
      pInputTextarea
      class="w-100"
      id="codeInput"
    ></textarea>
  </div>
</div>
