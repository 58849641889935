<div class="p-field d-flex justify-content-between align-items-baseline">
  <h6 class="m-0">{{'Grid template areas' | translate}}</h6>
  <button
    (click)="add()"
    [disabled]="isAddingDisabled"
    [label]="'Add area' | translate"
    class="p-button-sm"
    pButton
    pRipple
    type="button">
  </button>
</div>
<div *ngFor="let area of areas; let areaIndex = index" class="mt-3 grid">
  <div class="col-fixed pr-0 d-flex align-items-center">
    <p-button
      (onClick)="toggleAreaVisibility(area)"
      [icon]="area.visible.get(breakpoint) ? 'pi pi-eye' : 'pi pi-eye-slash'"
      [styleClass]="area.visible.get(breakpoint) ? 'p-button-rounded p-button-text' : 'p-button-rounded p-button-text p-button-secondary'">
    </p-button>
  </div>

  <div class="col-3 px-1">
    <span class="dynamic-label">
      <input
        [(ngModel)]="area.name"
        [class.line-through]="!this.area.visible.get(breakpoint)"
        class="p-inputtext-sm"
        id="{{'area' + areaIndex + 'name'}}"
        pInputText
        type="text">
      <label class="dynamic-label-text" for="{{'area' + areaIndex + 'name'}}">
        {{NAME_LABEL | translate}}
      </label>
    </span>
  </div>

  <div class="col px-1">
    <span class="dynamic-label">
      <p-inputNumber
        (ngModelChange)="updateStyleValue($event, area.gridColumnStart)"
        [ngModel]="area.gridColumnStart.get(breakpoint)"
        [useGrouping]="false"
        inputId="{{'area' + areaIndex + 'columnStart'}}"
        inputStyleClass="p-inputtext-sm"
        mode="decimal">
      </p-inputNumber>
      <label
        class="dynamic-label-text"
        for="{{'area' + areaIndex + 'columnStart'}}"
        pTooltip="{{'Column start' | translate}}"
        tooltipPosition="top">
        {{'Column start' | translate}}
      </label>
    </span>
  </div>

  <div class="col px-1">
    <span class="dynamic-label">
      <p-inputNumber
        (ngModelChange)="updateStyleValue($event, area.gridColumnEnd)"
        [ngModel]="area.gridColumnEnd.get(breakpoint)"
        [useGrouping]="false"
        inputId="{{'area' + areaIndex + 'columnEnd'}}"
        inputStyleClass="p-inputtext-sm"
        mode="decimal">
      </p-inputNumber>
      <label
        class="dynamic-label-text"
        for="{{'area' + areaIndex + 'columnEnd'}}"
        pTooltip="{{'Column end' | translate}}"
        tooltipPosition="top">
        {{'Column end' | translate}}
      </label>
    </span>
  </div>

  <div class="col px-1">
    <span class="dynamic-label">
      <p-inputNumber
        (ngModelChange)="updateStyleValue($event, area.gridRowStart)"
        [ngModel]="area.gridRowStart.get(breakpoint)"
        [useGrouping]="false"
        inputId="{{'area' + areaIndex + 'rowStart'}}"
        inputStyleClass="p-inputtext-sm"
        mode="decimal">
      </p-inputNumber>
      <label
        class="dynamic-label-text"
        for="{{'area' + areaIndex + 'rowStart'}}"
        pTooltip="{{'Row start' | translate}}"
        tooltipPosition="top">
        {{'Row start' | translate}}
      </label>
    </span>
  </div>

  <div class="col px-1">
    <span class="dynamic-label">
      <p-inputNumber
        (ngModelChange)="updateStyleValue($event, area.gridRowEnd)"
        [ngModel]="area.gridRowEnd.get(breakpoint)"
        [useGrouping]="false"
        inputId="{{'area' + areaIndex + 'rowEnd'}}"
        inputStyleClass="p-inputtext-sm"
        mode="decimal">
      </p-inputNumber>
      <label
        class="dynamic-label-text"
        for="{{'area' + areaIndex + 'rowEnd'}}"
        pTooltip="{{'Row end' | translate}}"
        tooltipPosition="top">
        {{'Row end' | translate}}
      </label>
    </span>
  </div>

  <div class="col-fixed pl-0">
    <button
      (click)="delete(areaIndex)"
      class="ml-2 p-button-danger p-button-sm"
      icon="pi pi-times"
      pButton
      pRipple
      type="button">
    </button>
  </div>
</div>
