import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastMessage } from '@shared/types/toast.types';

import { TranslateUtil } from '@shared/utils/translateUtil';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { HandleValidationErrors } from '../types/handle-validation-errors-service.types';

@Injectable({ providedIn: 'root' })
export class HandleValidationErrorsService {
  public errors: HandleValidationErrors = new HandleValidationErrors();

  constructor(private readonly messageService: MessageService, private readonly translateUtil: TranslateUtil, private readonly router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.errors.fields && this.reset());
  }

  public async handle(error: HttpErrorResponse): Promise<void> {
    this.messageService.addAll(await this.parseError(error));
  }

  public reset(): void {
    this.errors = new HandleValidationErrors();
  }

  private async parseError(errorResponse: HttpErrorResponse): Promise<ToastMessage[]> {
    const { url, error } = errorResponse;
    let errorObject: any = error;
    if (typeof errorObject === 'string') {
      try {
        errorObject = JSON.parse(errorObject);
      } catch (e) {
        errorObject = { fields: errorObject };
      }
    }
    const flattenErrorObject = { fields: this.flattenNestedObjects(errorObject.fields) };

    const { fields } = flattenErrorObject;

    const messages: ToastMessage[] = [];
    const keys: string[] = Object.keys(fields);

    if (keys.length) {
      for (const key of keys) {
        await this.createToastMessage(fields, key, messages);
      }
    }

    this.errors.lastUrl = url;
    this.errors.fields = cloneDeep(fields);

    return messages;
  }

  private flattenNestedObjects(data: any): Record<any, any> {
    const _flatten = (obj: any, flattenKey = ''): any => {
      const objKeys = Object.keys(obj);
      return objKeys.map(objKey => {
        if (typeof obj[objKey] === 'object') {
          return _flatten(obj[objKey], `${flattenKey}, ${objKey}`);
        }
        return { [`${flattenKey}, ${objKey}`.slice(2)]: obj[objKey] };
      });
    };

    return { ..._flatten(data) };
  }

  private async createToastMessage(errors: Record<string, any>, fieldKey: string, messages: ToastMessage[]): Promise<void> {
    if (typeof errors[fieldKey] === 'string') {
      const summary = fieldKey ? await this.translateUtil.get(fieldKey) : errors[fieldKey];
      const detail = await this.translateUtil.get(errors[fieldKey]);

      messages.push(new ToastMessage('error', summary, detail, { life: 10000 }));
    } else if (typeof errors[fieldKey] === 'object') {
      const errKeys = Object.keys(errors[fieldKey]);

      for (const errKey of errKeys) {
        await this.createToastMessage(errors[fieldKey], errKey, messages);
      }
    } else {
      throw new Error('This error format is not handled');
    }
  }
}
