<div class="grid">
  <div class="col-12">
    <h6 class="m-0">{{(trackType + ' gap') | translate}}</h6>
  </div>
  <div class="py-0 col-12">
    <input
      (ngModelChange)="onGapChange($event)"
      [(ngModel)]="gap"
      class="p-inputtext-sm"
      pInputText
      placeholder="10px"
      type="text">
  </div>
</div>
