import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { NewDataType } from '@shared/types/data-type.types';
import { MongoFilterOperator } from '@shared/types/filter.types';

export type NumericFilterType =
  | CoreListFilterEnum.equals
  | CoreListFilterEnum.notEquals
  | CoreListFilterEnum.lessThan
  | CoreListFilterEnum.lessThanOrEqualTo
  | CoreListFilterEnum.greaterThan
  | CoreListFilterEnum.greaterThanOrEqualTo;

type ComparisonOperator = '$eq' | '$ne' | '$lt' | '$lte' | '$gt' | '$gte';

type ConversionOperator = 'int' | 'double';

type NumericConvertItem = {
  input: string;
  to: ConversionOperator;
  onError?: number | null;
};

type NumericConvert = {
  $convert: NumericConvertItem;
};

type NumericFilterExpression = {
  $expr: {
    [comparisonOperator in ComparisonOperator]?: [NumericConvert, number];
  };
};

export type Query = { [logicalOperator in MongoFilterOperator]?: NumericFilterExpression[] };

export class NumericFilter {
  private readonly conversionOperator: ConversionOperator;
  private readonly comparisonOperator: ComparisonOperator;
  private readonly logicalOperator: MongoFilterOperator;
  private readonly attributeKey: string;
  private readonly values: number[];
  private readonly rawValues: string[];

  constructor(dataType: NewDataType, type: NumericFilterType, attributeKey: string, values: string[]) {
    this.conversionOperator = this.getConversionOperator(dataType);
    this.comparisonOperator = this.getComparisonOperator(type);
    this.logicalOperator = this.getLogicalOperator(type);
    this.attributeKey = `$${attributeKey}`;
    this.values = values.map(Number);
    this.rawValues = values;
  }

  get query(): Query {
    if (this.rawValues.length > 2) {
      const [value, type2, value2] = this.rawValues;

      return {
        $and: [
          { $or: [this.getExpression(parseInt(value, 10), this.comparisonOperator)] },
          { $or: [this.getExpression(parseInt(value2, 10), this.getComparisonOperator(type2 as any))] },
        ],
      } as any;
    }

    return {
      [this.logicalOperator]: this.values.map((value: number) => this.getExpression(value, this.comparisonOperator)),
    };
  }

  private getComparisonOperator(type: NumericFilterType): ComparisonOperator {
    switch (type) {
      case CoreListFilterEnum.equals:
        return '$eq';
      case CoreListFilterEnum.notEquals:
        return '$ne';
      case CoreListFilterEnum.lessThan:
        return '$lt';
      case CoreListFilterEnum.lessThanOrEqualTo:
        return '$lte';
      case CoreListFilterEnum.greaterThan:
        return '$gt';
      case CoreListFilterEnum.greaterThanOrEqualTo:
        return '$gte';
      default:
        return '$eq';
    }
  }

  private getLogicalOperator(type: NumericFilterType): MongoFilterOperator {
    switch (type) {
      case CoreListFilterEnum.notEquals:
        return MongoFilterOperator.AND;
      default:
        return MongoFilterOperator.OR;
    }
  }

  private getConversionOperator(dataType: NewDataType): ConversionOperator {
    return dataType.isInteger ? 'int' : 'double';
  }

  private getExpression(value: number, comparisonOperator: ComparisonOperator): NumericFilterExpression {
    const convert: NumericConvert = {
      $convert: {
        input: this.attributeKey,
        to: this.conversionOperator,
        onError: null,
      },
    };
    return {
      $expr: {
        [comparisonOperator]: [convert, value],
      },
    };
  }
}
