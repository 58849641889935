import { AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  QrCodesAvrInputMapperDto,
  QrCodesAvrType,
  QrCodesOutputType,
} from '@shared/services/artifact-visual-representation/qr-codes/dto/qr-codes.input-mapper.dto';

export class QrCodesAvrService extends BaseAvrAbstractService<QrCodesAvrType, QrCodesAvrInputMapperDto> {
  constructor() {
    super(AvrTypes.qrCodes as QrCodesAvrType, QrCodesAvrInputMapperDto, 'QR Codes', [QrCodesOutputType.svg, QrCodesOutputType.png]);
  }
}
