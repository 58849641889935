<div *ngIf="!isMultiple">
    <i #iconClick *ngIf="attrValue" [id]="hash" (click)="onIconClick(attrValue)" class="{{ attribute.icon || 'pi pi-external-link' }} clickable link-icon"></i>
</div>
<div *ngIf="isMultiple" #iconContainer [id]="hash">
    <i *ngFor="let link of attributeMultipleValue; let index = index" (click)="onIconClick(link)" [id]="index" class="{{ attribute.icon || 'pi pi-external-link' }} clickable link-icon"></i>
</div>

<p-overlayPanel
    #linkOp
    appendTo="body"
    [style]="{ 'max-width': '45%', 'max-height': '45%' }"
    styleClass="cop-no-caret cop-as-flex cop-with-content-overflow"
    [dismissable]="true"
    [showCloseIcon]="false"
    (onHide)="onOverlayHide()">
    <ng-template pTemplate>
        <div class="at-overlay-content-container">
            <a>{{currentLink}}</a>
        </div>
    </ng-template>
</p-overlayPanel>
