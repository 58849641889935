import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

export interface WorkflowBaseCondition {
  getType(): WorkflowConditionType;

  getSupportedDataTypes(): BaseDataType[];

  getSupportedDataKinds(): DataTypeKind[];

  isMet(source: any, value?: any): boolean;
}

export enum RuleConditionOperatorType {
  AND = 'AND',
  OR = 'OR',
}

export enum WorkflowConditionType {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  INCLUDES = 'INCLUDES',
  NOT_INCLUDES = 'NOT_INCLUDES',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_OR_EQUALS_THAN = 'GREATER_OR_EQUALS_THAN',
  LOWER_THAN = 'LOWER_THAN',
  LOWER_OR_EQUALS_THAN = 'LOWER_OR_EQUALS_THAN',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export const DYNAMIC_ATTRIBUTE_PREFIX = '{attributes["';
export const DYNAMIC_ATTRIBUTE_SUFFIX = '"].value}';
