import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GridLayoutGeneratorService } from '@shared/components/grid-layout-generator/services/grid-layout-generator.service';
import { GridTrack } from '@shared/components/grid-layout-generator/types/grid-track';
import { GridTrackType } from '@shared/components/grid-layout-generator/types/grid-track-type';
import { RangedGridLayoutHolder } from '@shared/components/grid-layout-generator/types/ranged-grid-layout-holder';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-grid-layout-generator',
  templateUrl: './grid-layout-generator.component.html',
  styleUrls: ['./grid-layout-generator.component.scss'],
  providers: [GridLayoutGeneratorService],
})
export class GridLayoutGeneratorComponent implements OnChanges {
  @Input() layoutHolder: RangedGridLayoutHolder;

  @Output() activeBreakpointChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() layoutUpdate: EventEmitter<void> = new EventEmitter<void>();

  readonly trackType: typeof GridTrackType = GridTrackType;

  columns$: Observable<GridTrack[]>;
  rows$: Observable<GridTrack[]>;
  areaAddingDisabled$: Observable<boolean>;

  constructor(private readonly layoutGeneratorService: GridLayoutGeneratorService) {}

  ngOnChanges({ layoutHolder }: SimpleChanges): void {
    if (layoutHolder) {
      this.layoutGeneratorService.layoutHolder = layoutHolder.currentValue;
    }
  }

  ngOnInit(): void {
    this.columns$ = this.layoutGeneratorService.columns$;
    this.rows$ = this.layoutGeneratorService.rows$;
    this.areaAddingDisabled$ = this.layoutGeneratorService.areaAddingDisabled$;
  }

  onActiveBreakpointChange(index: number): void {
    this.activeBreakpointChange.emit(index);
  }

  addColumn(): void {
    this.layoutGeneratorService.addColumn();
    this.layoutUpdate.emit();
  }

  deleteColumn(column: GridTrack): void {
    this.layoutGeneratorService.deleteColumn(column);
    this.layoutUpdate.emit();
  }

  addRow(): void {
    this.layoutGeneratorService.addRow();
    this.layoutUpdate.emit();
  }

  deleteRow(row: GridTrack): void {
    this.layoutGeneratorService.deleteRow(row);
    this.layoutUpdate.emit();
  }

  addArea(): void {
    this.layoutHolder.addArea();
  }

  deleteArea(areaIndex: number): void {
    this.layoutHolder.deleteArea(areaIndex);
  }

  updateGridTemplateColumns(): void {
    this.layoutGeneratorService.changeColumn();
    this.layoutUpdate.emit();
  }

  updateGridTemplateRows(): void {
    this.layoutGeneratorService.changeRow();
    this.layoutUpdate.emit();
  }

  addBreakpoint(): void {
    this.layoutHolder.addBreakpoint();
  }

  deleteBreakpoint(index: number): void {
    this.layoutHolder.deleteBreakpoint(index);
  }

  updateColumnGap(gap: string): void {
    this.layoutHolder.container.columnGap.set(this.layoutHolder.activeBreakpoint, gap);
    this.layoutUpdate.emit();
  }

  updateRowGap(gap: string): void {
    this.layoutHolder.container.rowGap.set(this.layoutHolder.activeBreakpoint, gap);
    this.layoutUpdate.emit();
  }
}
