import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SidebarMenuService {
  menuSource$: Observable<string>;
  resetSource$: Observable<any>;

  private menuSource = new Subject<string>();
  private resetSource = new Subject();

  constructor() {
    this.menuSource$ = this.menuSource.asObservable();
    this.resetSource$ = this.resetSource.asObservable();
  }

  onMenuStateChange(key: string): void {
    this.menuSource.next(key);
  }

  reset(): void {
    this.resetSource.next(undefined);
  }
}
