<p-table *ngIf="loadDataMethod"
         (onLazyLoad)="onLazyLoad($event)"
         [columns]="columns"
         [filters]="{deleted: [{ value: null, matchMode: 'equals', operator: 'and' }]}"
         [lazy]="true"
         [loading]="loading"
         [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
         [resizableColumns]="true"
         [rowsPerPageOptions]="meta.limitOptions"
         [rows]="meta.limit"
         [totalRecords]="meta.totalCount"
         [value]="data"
         dataKey="id"
         styleClass="p-datatable-striped"
         appThirdClickUndoSort
>
  <ng-template let-columns pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columns">
        <th class="p-text-truncate" pResizableColumn>
          {{ col.label }}
          <p-sortIcon *ngIf="col.key === EMAIL_VALUE" [field]="col.key" [pSortableColumn]="col.key"></p-sortIcon>
          <p-columnFilter *ngIf="col.key === EMAIL_VALUE" [field]="col.key" display="menu" matchMode="contains" type="text"></p-columnFilter>
          <p-columnFilter *ngIf="col.key === IS_TENANT_ADMIN_VALUE"
                          [field]="col.key"
                          [showOperator]="false"
                          [showMatchModes]="false"
                          [showAddButton]="false"
                          [showClearButton]="false"
                          [showApplyButton]="false"
                          display="menu" matchMode="equals">
            <ng-template let-filter="filterCallback" let-value pTemplate="filter">
              <p-triStateCheckbox (onChange)="filter($event.value)" [ngModel]="value"></p-triStateCheckbox>
              {{IS_TENANT_ADMIN_LABEL | translate}}
            </ng-template>
          </p-columnFilter>
        </th>
      </ng-container>
      <th>{{ 'Actions' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-user pTemplate="body">
    <tr>
      <ng-container *ngFor="let col of columns">
        <td>
          <div *ngIf="col.key === IS_TENANT_ADMIN_VALUE && user?.tenant" class="p-text-truncate readonly-checkbox">
            <p-checkbox [(ngModel)]="user.tenant.isAdmin" binary="true"></p-checkbox>
          </div>
          <div *ngIf="col.key !== IS_TENANT_ADMIN_VALUE">
            <span [ngClass]="{red: !!user.deleted, green: !user.deleted }">{{ user | getValueFromPath : col.key }}</span>
          </div>
        </td>
      </ng-container>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/admin/user', user.id]">
            <button class="p-button p-button-warning" icon="pi pi-pencil" iconPos="left" label="{{ 'Edit' | translate }}" pButton type="button"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1">{{ 'No records found' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
