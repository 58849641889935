import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ArrayUtil {
  static isNotEmptyArray(someArray: any): boolean {
    return Array.isArray(someArray) && someArray.length > 0;
  }

  // remove arr2 elements from arr1 based on element or key field
  objectArraySubtract(arr1: any[], arr2: any[], key: string | null = null): any[] {
    return arr1.filter(item => {
      return !arr2.find(found => {
        return key ? found[key] === item[key] : found === item;
      });
    });
  }
}
