import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';

@Directive()
export abstract class AbstractWorkflowRuleItemComponent implements OnChanges {
  @Input() artifactTypes: NewArtifactType[];
  @Input() attributes: NewAttribute[];
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() linkTypes: ListContainer<LinkType>;
  @Input() users: ListContainer<NewUser>;
  @Input() defaultArtifactType: NewArtifactType;
  @Input() editable: boolean;

  @Output() onDeleteItem: EventEmitter<any> = new EventEmitter();

  selectedAttributes: NewAttribute[];

  constructor(protected translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultArtifactType?.currentValue) {
      this.onArtifactTypeChange(this.defaultArtifactType.id);
    }
  }

  deleteItem(): void {
    this.onDeleteItem.emit();
  }

  protected onArtifactTypeChange(artifactTypeId: string) {
    this.selectedAttributes = this.getArtifactTypeAttributes(artifactTypeId);
  }

  protected getArtifactTypeAttributes(artifactTypeId: string): NewAttribute[] {
    const artifactType = this.artifactTypes.find(at => at.id === artifactTypeId);
    return (artifactType && this.attributes.filter(attr => !!artifactType.attributes[attr.id])) || [];
  }
}
