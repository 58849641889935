import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';

export class DefaultPageParams {
  constructor(
    public artifactTypeDto: ArtifactTypeResponseDto,
    public attributes: ListContainer<NewAttribute>,
    public dataTypes: ListContainer<NewDataType>,
    public artifactTypes: ListContainer<NewArtifactType>,
    public linkTypes: ListContainer<LinkType>,
  ) {}
}

export type AttributeWithPriorityOrder = {
  attribute: NewAttribute;
  dataType: NewDataType;
  priority: number;
};
