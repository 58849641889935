import { Pipe, PipeTransform } from '@angular/core';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { HideClasses } from '@shared/types/screen.types';

@Pipe({
  name: 'sectionClass',
})
export class SectionClassPipe implements PipeTransform {
  transform(section: PageSection): string[] {
    const classList: string[] = section.styles?.outerContainer.containerClass ? [section.styles?.outerContainer.containerClass] : [];
    section.sectionHide.pc && classList.push(HideClasses.pc);
    section.sectionHide.tablet && classList.push(HideClasses.tablet);
    section.sectionHide.mobile && classList.push(HideClasses.mobile);
    return classList;
  }
}
