import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { FolderTreeNode, FolderWidgetValue } from '@widgets/folder-widget/types/folder-widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { HASH, IS_LAYOUT_MODE, IS_SHOW_PICKER, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-folder-picker',
  templateUrl: './folder-picker.component.html',
  styleUrls: ['./folder-picker.component.scss'],
})
export class FolderPickerComponent extends WidgetsCoreComponent<any> {
  @ViewChild('modal') modal: any;
  @Input() showConfirmation: boolean;
  @Input() showPrivateFolder: boolean;
  @Input() showUserDefaultTeamFolder: boolean;
  @Input() showTenant: boolean;
  @Output() changeFolder = new EventEmitter<FolderTreeNode>();
  displayModal = false;
  artifact: NewArtifact | null;
  parentFolder: FolderTreeNode | null;
  settings: FolderWidgetSettings | null;
  multipleSelection: boolean;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(WIDGET) public widget: BlockPartWidget<FolderWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(IS_SHOW_PICKER) public isShowPicker: boolean,
    @Inject(HASH) public hash: string,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private confirmationService: ConfirmationService,
    private translateUtil: TranslateUtil,
  ) {
    super(route, router, announcement, elRef);
    if (this.isShowPicker) {
      this.displayModal = true;
      this.artifact = new NewArtifact();
    }
  }

  @Input() set setLayoutMode(isLayoutMode: boolean) {
    this.isLayoutMode = isLayoutMode;
  }

  onClose(): void {
    this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification<any>(RuntimeStateNotificationEnum.closeFolderPicker, ''));
  }

  showFolderPicker(artifact: NewArtifact | null, multipleSelection = true, settings?: FolderWidgetSettings): void {
    if (!artifact) {
      return;
    }

    this.settings = settings || null;
    this.multipleSelection = multipleSelection;
    this.artifact = null;
    this.parentFolder = null;

    setTimeout(() => {
      this.artifact = artifact;
      this.displayModal = true;
    });
  }

  updateFolderId(folder: FolderTreeNode | null): void {
    folder && !folder.folderPath && (folder.folderPath = '');
    this.parentFolder = folder;
  }

  async save(): Promise<void> {
    this.displayModal = false;
    if (!this.showConfirmation) {
      this.applyFolder();
      return;
    }
    const header = await this.translateUtil.get('Confirmation');
    const message = await this.translateUtil.get('Access control lists from selected Artifact and Destination Folder will be merged. Do you like to proceed?');

    this.confirmationService.confirm({
      header,
      message,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.applyFolder();
      },
    });
  }

  private applyFolder(): void {
    if (this.parentFolder) {
      this.changeFolder.emit(this.parentFolder);
      this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification<any>(RuntimeStateNotificationEnum.closeFolderPicker, this.parentFolder));
    }
  }
}
