<ng-container *ngIf="artifact.moduleData?.isHeading">
  <span [pTooltip]="'Promote artifact' | translate">
    <button
      [ngClass]="{'button-disabled': !artifact.moduleData?.headingArtifactId}"
      class="p-button-rounded p-button-text artifact-promote-button"
      icon="pi pi-angle-double-left"
      pButton
      pCancelEditableRow
      pRipple
      type="button"
      (click)="onPromotionClick(ArtifactPromotionAction.PROMOTE, artifact.id)"
    ></button>
  </span>
  <span [pTooltip]="'Demote artifact' | translate">
    <button
      [ngClass]="{'button-disabled': !(artifact | isDemotingEnabled)}"
      class="p-button-rounded p-button-text artifact-demote-button"
      icon="pi pi-angle-double-right"
      pButton
      pCancelEditableRow
      pRipple
      type="button"
      (click)="onPromotionClick(ArtifactPromotionAction.DEMOTE, artifact.id)"
    ></button>
    </span>
</ng-container>

<button
  [pTooltip]="'Insert new artifact before' | translate"
  class="p-button-rounded p-button-text"
  icon="bi bi-arrow-up"
  pButton
  pCancelEditableRow
  pRipple
  type="button"
  (click)="onAddNewModuleArtifactClick($event, AddArtifactModulePositionEnum.BEFORE, artifact, rowIndex)"
></button>

<button
  [pTooltip]="'Insert new artifact after' | translate"
  class="p-button-rounded p-button-text"
  icon="bi bi-arrow-down"
  pButton
  pCancelEditableRow
  pRipple
  type="button"
  (click)="onAddNewModuleArtifactClick($event, AddArtifactModulePositionEnum.AFTER, artifact, rowIndex)"
></button>

<button
  *ngIf="artifact.moduleData?.isHeading"
  [pTooltip]="'Insert new artifact inside' | translate"
  class="p-button-rounded p-button-text"
  icon="bi bi-arrow-return-right"
  pButton
  pCancelEditableRow
  pRipple
  type="button"
  (click)="onAddNewModuleArtifactClick($event, AddArtifactModulePositionEnum.INTO, artifact, rowIndex);"
></button>

<p-overlayPanel #addModuleRowOverlay [style]="{ 'min-width': '300px' }" appendTo="body">
  <ng-template pTemplate>
    <div class="mb-4">
      <p-checkbox [(ngModel)]="newModuleArtifact.isHeading"
                  [disabled]="!(selectedRowArtifact | isHeadingCheckboxEnabled : newModuleArtifact.position : data)"
                  binary="true" id="insertModuleRowAsHeading" class="mr-2">
      </p-checkbox>
      <label for="insertModuleRowAsHeading" class="mb-1">{{ 'Is heading' | translate }}</label>
    </div>
    <div class="mb-4">
      <p-dropdown
        [(ngModel)]="newModuleArtifact.artifactType"
        [autoDisplayFirst]="false"
        [filter]="true"
        [options]="addButtonItems"
        [showClear]="true"
        appendTo="body"
        optionLabel="label"
      ></p-dropdown>
    </div>
    <button [disabled]="!newModuleArtifact.artifactType" class="p-button" label="{{settings.addButtonLabel || 'Add'}}" pButton type="button"
            (click)="navigateToDefaultPageWithModuleParams(); addModuleRowOverlay.hide()">
    </button>
  </ng-template>
</p-overlayPanel>
