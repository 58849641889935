import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../services/authorization/authorization.service';

@Injectable({ providedIn: 'root' })
class IsSystemAdminGuard {
  constructor(private readonly router: Router, private readonly authorizationService: AuthorizationService) {}

  async canActivate(): Promise<boolean> {
    return await this.checkAccess();
  }

  async canActivateChild(): Promise<boolean> {
    return await this.checkAccess();
  }

  private async checkAccess(): Promise<boolean> {
    const userMeta = this.authorizationService.getUserMeta;

    if (!userMeta || !this.authorizationService.isLoggedIn) {
      await this.router.navigateByUrl('/login');
      return false;
    } else {
      if (userMeta.isSystemAdmin) return true;

      if (!userMeta.isSystemAdmin) {
        await this.router.navigateByUrl('/access');
        return false;
      }
      return false;
    }
  }
}

export const CanActivateIsSystemAdmin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  state;
  return inject(IsSystemAdminGuard).canActivate();
};
