import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { StyleFormModule } from '../../style-form.module';
import { AttributeFormatLabelStylesFormComponent } from './attribute-format-label-styles-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    CheckboxModule,
    RadioButtonModule,
    ColorPickerModule,
    InputSwitchModule,
    StyleFormModule,
    AccordionModule,
    OverlayPanelModule,
    InputNumberModule,
  ],
  declarations: [AttributeFormatLabelStylesFormComponent],
  exports: [AttributeFormatLabelStylesFormComponent],
})
export class AttributeFormatLabelStylesFormModule {}
