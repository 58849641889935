import { Injectable } from '@angular/core';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { SidebarWidgetComponent } from '@widgets/sidebar-widget/sidebar-widget.component';
import { SidebarWidgetWidth } from '@widgets/sidebar-widget/types/sidebar-widget-type.types';
import { SidebarWidgetModel, SidebarWidgetModelDto, SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { SIDEBAR_Z_INDEX } from '@widgets/sidebar-widget/constants/sidebar-widget.constants';

@Injectable()
export class SidebarService {
  c: SidebarWidgetComponent;
  m: SidebarWidgetModel;

  constructor(
    private readonly widgetService: WidgetService,
    public readonly tenantWidgetService: TenantWidgetService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  init(context: SidebarWidgetComponent, dto?: SidebarWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new SidebarWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new SidebarWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    this.m.widgetOptions = this.widgetService.getWidgetOptions([WidgetType.sidebar]);
  }

  isVertical(): boolean {
    return [SidebarWidgetWidth.right, SidebarWidgetWidth.left].includes(this.m.settings.position);
  }

  storeSidebarState(): void {
    this.m.parts?.forEach((part: PageBlockPart) => {
      if (!part.widget?.value?.model) {
        return;
      }

      const originalWidgetModel = part.widget.value.model;
      part.widget?.saveStateBeforeRelocation();

      if (part.widget?.code === WidgetType.listNew) {
        const originalWidgetModelState = this.localStorageService.get(originalWidgetModel.hash);
        if (originalWidgetModelState) {
          part.widget.value.model.state = originalWidgetModelState;
        }
      }
    });
  }

  fixLayer(el: any): void {
    el?.container?.style && (el.container.style['z-index'] = SIDEBAR_Z_INDEX);
  }

  onButtonClick(islayoutBtn = false): void {
    this.storeSidebarState();
    this.m.settings.display ? this.m.settings.hideSidebar(islayoutBtn) : (this.m.settings.display = true);
    this.m.settings.updateStyles(islayoutBtn);
  }

  checkSettingsRestriction(): void {
    this.m.options.setFixedOptions();
    this.m.options.setInsideOptions();

    if (!this.m.settings.isInside) {
      this.m.settings.isFixed = false;
      this.m.options.setFixedOptions(true);
    }
  }

  deletePageBlockPart(part: PageBlockPart): void {
    this.m.parts = this.m.parts.filter(p => p != part);
    part.widget?.id && part.widget.isDeletable && this.m.widgetsToDelete.push(part.widget.id);
  }

  async pasteWidgetTemplate(templates: TemplateResponseDto[], isReused = true, part: PageBlockPart = new PageBlockPart()): Promise<void> {
    for (const template of templates) {
      const dto = await lastValueFrom(this.tenantWidgetService.widgetControllerGet({ id: (template.template as any).widgetId }));

      if (dto.code === WidgetType.sidebar) return;

      part.widget = new BlockPartWidget(cloneDeep(dto));

      if (part.widget.code === WidgetType.card && (part.widget.value?.model?.areas || []).length) {
        await this.widgetService.loadCardInnerWidgets(part.widget.value.model);
      }

      if (isReused) {
        part.widget.templateId = template.id;
        part.widget.templateName = template.name;
      } else {
        part.widget.id = null;
      }

      this.m.parts.push(part);
    }
  }
}
