import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsBounded } from '../../methods/data-type.methods';

@Pipe({ name: 'isBounded' })
export class IsBoundedPipe implements PipeTransform {
  transform(dataTypeKind: DataTypeKind): boolean {
    return IsBounded(dataTypeKind);
  }
}
