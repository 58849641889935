<app-loader [loading]="m.loading"></app-loader>

<div class="grid">
    <div class="col-12 top_box">
        <div>
            <div class="p-inputgroup">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                    <p-checkbox [(ngModel)]="m.team.deleted" [disabled]="true" binary="true" inputId="isDeleted" id="isDeleted"></p-checkbox>
                    <label for="isDeleted" style="cursor: default">{{ IS_DELETED_LABEL | translate}}</label>
                  </span>
                </span>
            </div>
        </div>
        <div>
            <button *ngIf="m.team && m.team.id && !m.team.deleted"
                    (click)="deleteWithConfirmation()"
                    [label]="'Delete' | translate"
                    class="p-button p-button-danger"
                    pButton>
            </button>
            <a [routerLink]="['/admin/team-list']">
                <button class="p-button p-button-warning" label="{{'Cancel' | translate}}" pButton type="button"></button>
            </a>
            <button (click)="save()" class="p-button p-button-success" label="{{'Save' | translate}}" pButton type="button">
                <span *ngIf="m.inSavingProgress" class="pi pi-spin pi-spinner"></span>
            </button>
        </div>
    </div>

    <div class="col-12 md:col-3">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <span class="pi pi-user"></span>
            </span>
                  <span class="dynamic-label">
              <input [(ngModel)]="m.team.name" id="teamName" pInputText type="text"/>
              <label class="dynamic-label-text" for="teamName">{{NAME_LABEL | translate}}</label>
            </span>
        </div>
    </div>

    <div class="col-12">
        <span class="dynamic-label">
          <textarea id="description" pInputTextarea [rows]="5" [(ngModel)]="m.team.description"></textarea>
          <label class="dynamic-label-text" for="description" translate>Description</label>
        </span>
    </div>

    <div class="col-12">
        <app-team-user-form [m]="m"></app-team-user-form>
    </div>

</div>
