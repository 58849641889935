import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TableColumnContextChangeEvent, TableColumnControlContext } from './table-column-context-types';

@Injectable()
export class TableColumnControlService {
  private openMenuSubject: Subject<TableColumnControlContext> = new Subject();
  private openMenuSubject$: Observable<TableColumnControlContext> = this.openMenuSubject.asObservable();
  private columnContextChangeSubject: Subject<TableColumnContextChangeEvent> = new Subject();
  private columnContextChangeSubject$: Observable<TableColumnContextChangeEvent> = this.columnContextChangeSubject.asObservable();

  get openMenu$(): Observable<TableColumnControlContext> {
    return this.openMenuSubject$;
  }

  get columnContextChange$(): Observable<TableColumnContextChangeEvent> {
    return this.columnContextChangeSubject$;
  }

  getColumnContextChange$(ownerId: string): Observable<TableColumnContextChangeEvent> {
    return this.columnContextChange$.pipe(filter(columnContextChangeEvent => columnContextChangeEvent.ownerId === ownerId));
  }

  doOpenTableColumnControl(context: TableColumnControlContext) {
    this.openMenuSubject.next(context);
  }

  doNotifyColumnContextChange(columnContextChangeEvent: TableColumnContextChangeEvent) {
    this.columnContextChangeSubject.next(columnContextChangeEvent);
  }
}
