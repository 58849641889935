import { Params } from '@angular/router';
import { SharedMethods } from '@shared/methods/shared.methods';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { ItemWithLinkQueryParams } from '@widgets/menu-widget/types/menu-widget.types';

export class TreeItem extends ItemWithLinkQueryParams {
  currentPageBehavior = MenuItemCurrentPageBehaviorEnum.leaveCurrentPage;
  showEmptyParamsAsActive = false;
  fragmentParam = '';
  hash: string = SharedMethods.getUniqueId();
  isPageSelection = true;
  useAlias = false;
  alias: string;
  url?: string;
  imageUrl?: string;
  menuIcon: string;
  label: string;
  subLabel: string;
  chip: string;
  target?: string;
  command?: (event?: any) => void;
  icon?: string;
  draggable? = true;
  droppable? = true;
  selectable? = true;
  expandedIcon?: any;
  collapsedIcon?: any;
  leaf?: boolean;
  expanded: boolean;
  type?: string;
  partialSelected?: boolean;
  styleClass?: string;
  children: TreeItem[] = [];
  parent?: TreeItem | null = null;
  behavior = MenuItemBehaviorEnum.link;
  defaultElvisAction = DefaultElvisActions.logout;
  usesDefaultStyle = true;

  constructor(item?: Partial<TreeItem>) {
    super();
    if (item) {
      Object.assign(this, item);
      item.children?.length && (this.children = item.children.map(item => new TreeItem({ ...item })));
    }
  }

  toServer(): TreeItem {
    return this;
  }
}

export enum MenuItemBehaviorEnum {
  link = 'Link',
  textOnly = 'Text only',
  divider = 'Divider',
  action = 'Action',
}

export enum MenuItemCurrentPageBehaviorEnum {
  leaveCurrentPage = 'leaveCurrentPage',
  stayOnCurrentPage = 'stayOnCurrentPage',
}

export class LinkQueryParamMap {
  parametersToAdd: Params = {};
  parameterKeysToRemove: string[] = [];

  constructor(params?: Partial<LinkQueryParamMap>) {
    params && Object.assign(this, params);
  }
}

export interface LinkQueryParam {
  key: string;
  value?: string;
  action: LinkQueryParamActionEnum;
}

export enum LinkQueryParamActionEnum {
  addNew = 'addNew',
  removeExisting = 'removeExisting',
}
