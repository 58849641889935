import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisabledTypeSystemElement } from '@private/components/type-system-element-dropdown/disabled-type-system-element';
import { TypeSystemElement } from '@private/components/type-system-element-dropdown/type-system-element';
import { TypeSystemElementsDropdownService } from '@private/components/type-system-element-dropdown/type-system-elements-dropdown.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreRxSubscriptionsComponent } from '@shared/core/components/core-rx-subscriptions.component';
import { NewApplication } from '@shared/types/application.types';
import { TreeNode } from 'primeng/api/treenode';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

function filterNonEmpty<T>(source$: Observable<T[]>): Observable<T[]> {
  return source$.pipe(filter((array: T[]) => !!array.length));
}

function mapToIds(source$: Observable<DisabledTypeSystemElement[]>): Observable<Set<string>> {
  return source$.pipe(map((elements: DisabledTypeSystemElement[]) => new Set(elements.map(({ id }: DisabledTypeSystemElement) => id))));
}

export interface ValueTemplateContext {
  $implicit: TreeNode;
  placeholder: string | null;
}

@Component({
  selector: 'app-type-system-element-dropdown',
  templateUrl: './type-system-element-dropdown.component.html',
  styleUrls: ['./type-system-element-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TypeSystemElementsDropdownService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TypeSystemElementDropdownComponent,
      multi: true,
    },
  ],
})
export class TypeSystemElementDropdownComponent extends CoreRxSubscriptionsComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() placeholder = 'Select a Type System element';

  @Input() set applications(applications: NewApplication[]) {
    this.applicationsSubject.next(applications);
  }

  @Input() set typeSystemElements(elements: TypeSystemElement[]) {
    this.elementsSubject.next(elements);
  }

  @Input() set disabledElements(elements: DisabledTypeSystemElement[]) {
    this.disabledElementsSubject.next(elements);
  }

  @Output() onSelect: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(TemplateRef) customValueTemplate: TemplateRef<ValueTemplateContext>;

  @ViewChild('defaultValueTemplate', { static: true }) defaultValueTemplate: TemplateRef<ValueTemplateContext>;

  onChange: (value: string | null) => void;
  onTouched: () => void;

  valueTemplate: TemplateRef<ValueTemplateContext>;

  selectedNode: TreeNode | null = null;
  disabled = false;

  nodes$: Observable<TreeNode[]>;

  private applicationsSubject: BehaviorSubject<NewApplication[]> = new BehaviorSubject<NewApplication[]>([]);
  private elementsSubject: BehaviorSubject<TypeSystemElement[]> = new BehaviorSubject<TypeSystemElement[]>([]);
  private disabledElementsSubject: BehaviorSubject<DisabledTypeSystemElement[]> = new BehaviorSubject<DisabledTypeSystemElement[]>([]);

  constructor(
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly typeSystemElementsDropdownService: TypeSystemElementsDropdownService,
  ) {
    super();
  }

  ngOnInit(): void {
    const applications$ = this.applicationsSubject.pipe(filterNonEmpty);
    const selectedApplication$ = this.applicationSwitcherService.selectedApplication$;
    const elements$ = this.elementsSubject.pipe(filterNonEmpty);
    const disabledElementIds$ = this.disabledElementsSubject.pipe(mapToIds);

    this.nodes$ = combineLatest([applications$, selectedApplication$, elements$, disabledElementIds$]).pipe(
      map((args: [NewApplication[], NewApplication, TypeSystemElement[], Set<string>]) => this.typeSystemElementsDropdownService.getTreeNodes(...args)),
    );
  }

  ngAfterContentInit(): void {
    this.valueTemplate = this.customValueTemplate || this.defaultValueTemplate;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(elementId: string | null): void {
    if (!elementId) {
      this.selectedNode = null;
    }

    this.nodes$
      .pipe(
        take(1),
        tap(
          (nodes: TreeNode[]) =>
            (this.selectedNode = this.typeSystemElementsDropdownService.findNodeByKey(
              {
                key: 'root',
                children: nodes,
              },
              elementId!,
            )),
        ),
      )
      .subscribe();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onNodeSelect({ node }: { node: TreeNode }): void {
    this.onChange(node.key || null);
    this.onTouched();
    this.onSelect.emit();
  }
}
