<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid form-separator" *ngIf="applicationSwitcherService.selectedApplication?.id && m && m.dataTypes">
  <div class="col-12 top_box">
    <div>
      <div *ngIf="m.artifact && m.artifact.id">
        <div class="p-inputgroup">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox binary="true" id="isDeleted" inputId="isDeleted" [(ngModel)]="m.artifact.deleted" [disabled]="true"></p-checkbox>
              <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate }}</label>
            </span>
            <span class="dynamic-checkbox-box">
              <p-checkbox [(ngModel)]="m.artifact.deleted" [disabled]="true" binary="true" id="isDeleted" inputId="isDeleted"></p-checkbox>
              <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate }}</label>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <!--          TODO: ENABLE DELETING ONCE LINK TYPES ARE IMPLEMENTED        -->
      <!--                  <button *ngIf="m.artifact && m.artifact.id && !m.artifact.deleted"-->
      <!--                          class="p-button p-button-danger" pButton-->
      <!--                          [label]="'Delete' | translate"-->
      <!--                          (click)="s.deleteWithConfirmation(m.artifact)">-->
      <!--                  </button>-->
      <a [routerLink]="['/admin/artifact-list']">
        <button pButton label="{{ 'Cancel' | translate }}" type="button" class="p-button p-button-warning"></button>
      </a>
      <button pButton label="{{ 'Save' | translate }}" type="button" class="p-button p-button-success" (click)="s.save()">
        <i *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></i>
      </button>
    </div>
  </div>
  <div class="col-12 md:col-6 lg:col-4">
    <span class="dynamic-label">
      <p-dropdown
        id="artifactType"
        inputId="artifactType"
        [filter]="true"
        [options]="m.artifactTypeOptions"
        [(ngModel)]="m.artifactType"
        (onChange)="s.mapAttributesToClient(true)"
        [autoDisplayFirst]="false"
        [disabled]="m.artifact.id !== ''"
      ></p-dropdown>
      <label class="dynamic-label-text" for="artifactType" translate>Artifact type</label>
    </span>
  </div>
</div>

<app-artifact-form [allAttributes]="m.attributes" [allDataTypes]="m.dataTypes" [attributes]="m.artifact.clientAttributes" [users]="m.users"></app-artifact-form>

<!-- TODO: links -->
<!--<div class="col-12" *ngIf="m.artifactType">-->
<!--  <div class="header">-->
<!--    <h4 translate>Links</h4>-->
<!--    <button pButton label="{{'Add link' | translate}}" type="button" class="p-button" icon="pi pi-plus" iconPos="left" (click)="addLink()"></button>-->
<!--  </div>-->
<!--  <p-table responsive="true"-->
<!--           paginatorPosition="bottom"-->
<!--           sortMode="multiple"-->
<!--           sortField="id"-->
<!--           [paginator]="m.artifact.links.length > m.rowsPerPage"-->
<!--           [rows]="m.rowsPerPage"-->
<!--           [rowsPerPageOptions]="m.rowsPerPageOptions"-->
<!--           [columns]="m.linkColumns"-->
<!--           [value]="m.artifact.links"-->
<!--           appThirdClickUndoSort>-->
<!--    <ng-template pTemplate="header" let-columns>-->
<!--      <tr>-->
<!--        <th *ngFor="let column of columns">-->
<!--          {{column.label | translate}}-->
<!--          <p-sortIcon [pSortableColumn]="column.key" [field]="column.key"></p-sortIcon>-->
<!--        </th>-->
<!--        <th>-->
<!--          {{'Actions' | translate}}-->
<!--        </th>-->
<!--      </tr>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="body" let-link let-index="rowIndex">-->
<!--      <tr [ngClass]="{'row-deleted': link.deleted}">-->
<!--        <td>-->
<!--          <p-dropdown *ngIf="!link.id else simpleLinkType"-->
<!--                      [filter]="true"-->
<!--                      [autoDisplayFirst]="false"-->
<!--                      [style]="{'width':'100%'}"-->
<!--                      [options]="m.linkTypeOptions"-->
<!--                      [virtualScroll]="true"-->
<!--                      [virtualScrollItemSize]="20"-->
<!--                      [(ngModel)]="link.linkType"-->
<!--                      (onChange)="setLinkDirection($event, link)"-->
<!--                      [disabled]="link.id.length"-->
<!--                      placeholder="Choose a link type"-->
<!--                      optionLabel="label">-->
<!--            <ng-template let-linkOption pTemplate="item">-->
<!--              <app-link-option-with-arrow [label]="linkOption.label" [flexDirection]="linkOption.meta"></app-link-option-with-arrow>-->
<!--            <ng-template let-linkOption pTemplate="selectedItem">-->
<!--              <app-link-option-with-arrow [label]="linkOption.label" [flexDirection]="linkOption.meta"></app-link-option-with-arrow>-->
<!--            </ng-template>-->
<!--          </p-dropdown>-->
<!--          <ng-template #simpleLinkType>-->
<!--            <app-link-option-with-arrow [label]="isOutgoing(link.flexDirection) ? link.linkType?.value?.outgoingName : link.linkType?.value?.incomingName"-->
<!--                                        [flexDirection]="link.meta">-->
<!--            </app-link-option-with-arrow>-->
<!--          </ng-template>-->
<!--        </td>-->
<!--        <td>-->
<!--          <div *ngIf="link.linkType">-->
<!--            <p-dropdown *ngIf="!link.id else simpleArtifactType"-->
<!--                        [filter]="true"-->
<!--                        [autoDisplayFirst]="false"-->
<!--                        [style]="{'width':'100%'}"-->
<!--                        (onChange)="s.setLinkArtifactOptions($event.value, link)"-->
<!--                        [options]="m.artifactTypeOptions"-->
<!--                        [virtualScroll]="true"-->
<!--                        [virtualScrollItemSize]="20"-->
<!--                        [(ngModel)]="link.targetArtifactType"-->
<!--                        [disabled]="link.id.length"-->
<!--                        [placeholder]="'Choose artifact type'">-->
<!--            </p-dropdown>-->
<!--            <ng-template #simpleArtifactType>-->
<!--              {{link.targetArtifactType?.name}}-->
<!--            </ng-template>-->
<!--          </div>-->
<!--        </td>-->
<!--        <td>-->
<!--          <div *ngIf="link.targetArtifactType">-->
<!--            <p-dropdown *ngIf="!link.id else simpleLinkType"-->
<!--                        [filter]="true"-->
<!--                        [autoDisplayFirst]="false"-->
<!--                        [style]="{'width':'100%'}"-->
<!--                        [options]="link.artifactOptions"-->
<!--                        [virtualScroll]="true"-->
<!--                        [virtualScrollItemSize]="20"-->
<!--                        [(ngModel)]="link.target"-->
<!--                        [disabled]="link.id.length"-->
<!--                        [placeholder]="'Choose target artifact'">-->
<!--            </p-dropdown>-->
<!--            <ng-template #simpleLinkType>-->
<!--              <a [routerLink]="['/admin/artifact']" [queryParams]="{type: link.targetArtifactType.id}" target="_blank">-->
<!--                {{link.target?.id}}-->
<!--              </a>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--        </td>-->
<!--        <td>-->
<!--          <div class="table_buttons" *ngIf="!link.deleted">-->
<!--            <button pButton label="{{'Remove' | translate}}" type="button" class="p-button-danger" (click)="removeLink(index)"></button>-->
<!--          </div>-->
<!--        </td>-->
<!--      </tr>-->
<!--    </ng-template>-->
<!--    <ng-template pTemplate="emptymessage">-->
<!--      <tr>-->
<!--        <td [colSpan]="m.linkColumns.length + 1" translate>No records found</td>-->
<!--      </tr>-->
<!--    </ng-template>-->
<!--  </p-table>-->
<!--</div>-->
