import { Pipe, PipeTransform } from '@angular/core';
import { HyperlinkDisplayVariant } from '@shared/types/display-at-types';
import { CardAreaAttributeStylesTypes } from '@widgets/card-widget/components/card-area/types/card-area-types';

@Pipe({ name: 'isRedirectIcon' })
export class IsRedirectIconHyperlinkPipe implements PipeTransform {
  transform(id: string, attributeStyles: CardAreaAttributeStylesTypes): boolean {
    if (!id || !attributeStyles) return false;

    return attributeStyles[id]?.settings?.value?.displayMetadata?.selectedVariantCode === HyperlinkDisplayVariant.REDIRECT_ICON;
  }
}
