import { FOLDER_FILTER_KEY, URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';

export class FolderWidgetSettings {
  showAllApp = false;
  showContent = false;
  enableSearch = false;
  searchInPath = false;
  showUsersFolder = false;
  showTeamsFolder = false;
  isHorizontal = false;
  scrollable = false;
  showFolderArrow = true;
  coloredFolderArrow = true;
  coloredText = true;
  enableFolderUrl = true;
  enableModuleUrl = false;
  folderUrlKey: string = FOLDER_FILTER_KEY;
  moduleUrlKey: string = URL_KEY_VALUE_ARTIFACT_ID;
  expandedIcon = 'bi bi-folder-minus';
  collapsedIcon = 'bi bi-folder-plus';
  emptyFolderIcon = 'pi pi-folder';
  moduleIcon = 'pi pi-th-large';

  constructor(dto?: FolderWidgetSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): FolderWidgetSettingsDto {
    return {
      showAllApp: this.showAllApp,
      showContent: this.showContent,
      enableSearch: this.enableSearch,
      searchInPath: this.searchInPath,
      showUsersFolder: this.showUsersFolder,
      showTeamsFolder: this.showTeamsFolder,
      isHorizontal: this.isHorizontal,
      scrollable: this.scrollable,
      expandedIcon: this.expandedIcon,
      collapsedIcon: this.collapsedIcon,
      emptyFolderIcon: this.emptyFolderIcon,
      moduleIcon: this.moduleIcon,
      showFolderArrow: this.showFolderArrow,
      coloredFolderArrow: this.coloredFolderArrow,
      coloredText: this.coloredText,
      enableFolderUrl: this.enableFolderUrl,
      enableModuleUrl: this.enableModuleUrl,
      folderUrlKey: this.folderUrlKey,
      moduleUrlKey: this.moduleUrlKey,
    };
  }

  fromDto(dto: FolderWidgetSettingsDto): void {
    const {
      showAllApp,
      showContent,
      enableSearch,
      searchInPath,
      showUsersFolder,
      showTeamsFolder,
      isHorizontal,
      scrollable,
      expandedIcon,
      collapsedIcon,
      emptyFolderIcon,
      moduleIcon,
      enableFolderUrl,
      enableModuleUrl,
      folderUrlKey,
      moduleUrlKey,
    } = dto;
    Object.assign(this, { showAllApp, showContent });
    showUsersFolder && (this.showUsersFolder = showUsersFolder);
    showTeamsFolder && (this.showTeamsFolder = showTeamsFolder);
    expandedIcon && (this.expandedIcon = expandedIcon);
    collapsedIcon && (this.collapsedIcon = collapsedIcon);
    emptyFolderIcon && (this.emptyFolderIcon = emptyFolderIcon);
    moduleIcon && (this.moduleIcon = moduleIcon);
    isHorizontal && (this.isHorizontal = isHorizontal);
    scrollable && (this.scrollable = scrollable);
    folderUrlKey && (this.folderUrlKey = folderUrlKey);
    moduleUrlKey && (this.moduleUrlKey = moduleUrlKey);
    enableFolderUrl !== undefined && (this.enableFolderUrl = enableFolderUrl);
    enableModuleUrl !== undefined && (this.enableModuleUrl = enableModuleUrl);
    enableSearch !== undefined && (this.enableSearch = enableSearch);
    searchInPath !== undefined && (this.searchInPath = searchInPath);
    Object.prototype.hasOwnProperty.call(dto, 'showFolderArrow') && (this.showFolderArrow = dto.showFolderArrow);
    Object.prototype.hasOwnProperty.call(dto, 'coloredFolderArrow') && (this.coloredFolderArrow = dto.coloredFolderArrow);
    Object.prototype.hasOwnProperty.call(dto, 'coloredText') && (this.coloredText = dto.coloredText);
  }
}

export class FolderWidgetSettingsDto {
  showAllApp: boolean;
  showContent: boolean;
  enableSearch: boolean;
  searchInPath: boolean;
  showUsersFolder: boolean;
  showTeamsFolder: boolean;
  isHorizontal: boolean;
  scrollable: boolean;
  expandedIcon: string;
  collapsedIcon: string;
  emptyFolderIcon: string;
  moduleIcon: string;
  showFolderArrow: boolean;
  coloredFolderArrow: boolean;
  coloredText: boolean;
  enableFolderUrl: boolean;
  enableModuleUrl: boolean;
  folderUrlKey: string;
  moduleUrlKey: string;

  constructor(settings: FolderWidgetSettingsDto) {
    const { showAllApp, showContent, isHorizontal, scrollable, folderUrlKey } = settings;
    Object.assign(this, { showAllApp, showContent, isHorizontal, scrollable, folderUrlKey });
  }
}
