import { Pipe, PipeTransform } from '@angular/core';
import { RuleUtilsService } from '@workflows/services';
import { WorkflowRule } from '@workflows/types';

@Pipe({
  name: 'isGlobalWorkflowUsedByPage',
})
export class IsGlobalWorkflowUsedByPagePipe implements PipeTransform {
  constructor(private ruleUtils: RuleUtilsService) {}

  transform(rule: WorkflowRule, pageId: string): boolean {
    return !!rule?.global && this.ruleUtils.isUsedByPage(rule, pageId);
  }
}
