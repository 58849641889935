import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayArtifactValueToTablePipe } from './display-artifact-value-to-table.pipe';
import { ShowArtifactValueToTablePipe } from './show-artifact-value-to-table.pipe';

@NgModule({
  declarations: [DisplayArtifactValueToTablePipe, ShowArtifactValueToTablePipe],
  imports: [CommonModule],
  exports: [DisplayArtifactValueToTablePipe, ShowArtifactValueToTablePipe],
})
export class DisplayArtifactValueToTableModule {}
