<div class="grid" *ngIf="fontStyles">
  <div *ngIf="!hideEmphasisSettings" class="col-12 md:col-6 lg:col-6">
    <h6>Emphasis</h6>
    <div class="font-buttons">
      <span (click)="onBoldClick(fontStyles); isChange()" [ngClass]="fontStyles.fontWeight | boldActive" class="button button-bold">B </span>

      <span (click)="onItalicClick(fontStyles); isChange()" [ngClass]="fontStyles.fontStyle | italicActive" class="button button-italic"> I </span>

      <span
        (click)="onUnderscoreClick(fontStyles); isChange()"
        [ngClass]="fontStyles.textDecoration | textDecorationActive : textDecorationEnum.underline"
        class="button button-underscore"
      >
        U
      </span>

      <span
        (click)="onLineThroughClick(fontStyles); isChange()"
        [ngClass]="fontStyles.textDecoration | textDecorationActive : textDecorationEnum.lineThrough"
        class="button button-line-through"
      >
        S
      </span>
    </div>
  </div>
  <!-- TEXT COLOR -->
  <div *ngIf="!hideTextColorSetting" class="col-12 md:col-6 lg:col-6">
    <app-color-picker [(color)]="fontStyles.color" [onChangeCb]="isChange()" label="Text color" (colorChange)="onColorChange($event)"></app-color-picker>
  </div>

  <!-- Background COLOR -->
  <div *ngIf="!hideBackgroundColorSetting" class="col-12 md:col-6 lg:col-6">
    <app-color-picker
      [(color)]="fontStyles.backgroundColor"
      [onChangeCb]="isChange()"
      label="Background color"
      (colorChange)="onBackgroundColorChange($event)"
    ></app-color-picker>
  </div>

  <!-- FONT SIZE -->
  <div *ngIf="!hideFontSizeSetting" class="col-12 md:col-6 lg:col-6">
    <h6>Font size</h6>
    <span class="dynamic-label">
      <input [(ngModel)]="fontStyles.fontSize" (ngModelChange)="isChange()" id="fontSize" pInputText type="text" />
    </span>
  </div>

  <!-- FONT -->
  <div *ngIf="!hideFontFamilySettings" class="col-12 md:col-6 lg:col-6">
    <h6>Font</h6>
    <p-dropdown [(ngModel)]="fontStyles.fontFamily" [autoDisplayFirst]="false" [options]="fontFamilyOptions" (onChange)="isChange()">
      <ng-template let-selected pTemplate="selectedItem">
        <span [ngStyle]="{ 'font-family': this.fontFamilyEnum[selected.label] }"> {{ selected.label | translate }} </span>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span [ngStyle]="{ 'font-family': this.fontFamilyEnum[option.label] }"> {{ option.label | translate }} </span>
      </ng-template>
    </p-dropdown>
  </div>

  <!-- Horizontal align -->
  <div *ngIf="!hideHorizontalAlignSettings" class="col-12 md:col-6 lg:col-6">
    <h6>Horizontal align</h6>
    <p-dropdown [(ngModel)]="fontStyles.textAlign" [autoDisplayFirst]="false" [options]="textHorizontalAlignOptions" id="valueTextHorizontalAlign">
    </p-dropdown>
  </div>

  <ng-container *ngIf="!hidePrefixSuffix">
    <div class="col-12 md:col-6 lg:col-6">
      <h6> {{ 'Prefix' | translate }} </h6>
      <span class="dynamic-label">
        <input [(ngModel)]="fontStyles.prefix" (ngModelChange)="isChange()" id="prefix" pInputText type="text" />
      </span>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <h6> {{ 'Suffix' | translate }} </h6>
      <span class="dynamic-label">
        <input [(ngModel)]="fontStyles.suffix" (ngModelChange)="isChange()" id="suffix" pInputText type="text" />
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="!hideDecimalPlacesSettings">
    <div class="col-12 md:col-6 lg:col-6">
      <h6> {{ 'Minimum decimal places' | translate }} </h6>
      <span class="dynamic-label">
        <p-inputNumber [(ngModel)]="fontStyles.minDecimalPlaces" (ngModelChange)="onMinDecimalPlacesChange($event)" id="minDecimalPlaces" />
      </span>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <h6> {{ 'Maximum decimal places' | translate }} </h6>
      <span class="dynamic-label">
        <p-inputNumber [(ngModel)]="fontStyles.maxDecimalPlaces" (ngModelChange)="onMaxDecimalPlacesChange($event)" id="maxDecimalPlaces" />
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="showBorderSettings">
    <div class="col-6">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox (onChange)="resetBorderStylesIfDisabled($event)"
                      [(ngModel)]="fontStyles.isBorderEnable"
                      id="isBorderEnable" inputId="isBorderEnable"
                      binary="true"></p-checkbox>
          <label for="isBorderEnable" translate>{{ "Enable border" | translate }}</label>
        </span>
      </span>
    </div>
    <ng-container *ngIf="fontStyles.isBorderEnable">
      <div class="col-6">
        <div class="dynamic-label p-float-label">
          <input
            [(ngModel)]="fontStyles.padding"
            pInputText
            type="text"
            id="borderPadding"
          />
          <label for="borderPadding" translate> Border Padding </label>
        </div>
      </div>

      <div class="col-6">
        <span class="dynamic-label p-float-label">
          <p-dropdown [(ngModel)]="fontStyles.borderStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                      [options]="borderStyleOptions" inputId="borderStyle" id="borderStyle"></p-dropdown>
          <label class="dynamic-label-text" for="borderStyle">{{'Border style' | translate}}</label>
        </span>
      </div>
      <div class="col-6">
        <span class="dynamic-label p-float-label">
          <p-dropdown [(ngModel)]="fontStyles.borderWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                      [options]="borderWidthOptions" inputId="borderWidth" id="borderWidth"></p-dropdown>
          <label class="dynamic-label-text" for="borderWidth">{{'Border width' | translate}}</label>
        </span>
      </div>

      <div class="col-6">
        <app-color-picker (colorChange)="isChange()"
                          [(color)]="fontStyles.borderColor"></app-color-picker>
      </div>
      <div class="col-6">
        <div class="dynamic-label p-float-label">
          <input
            [(ngModel)]="fontStyles.borderRadius"
            pInputText
            type="text"
            id="borderRadius"
          />
          <label for="borderRadius" translate> Border Radius </label>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>
