import { StickyPositioningSettings } from '@shared/types/sticky-positioning-settings';
import { cloneDeep } from 'lodash';
import { StickinessPosition } from './stickiness-position';
import { StickinessScope } from './stickiness-scope';
import { Styles } from './styles';

const defaultStickyPositioningSettings: StickyPositioningSettings = {
  isSticky: false,
  position: StickinessPosition.top,
  scope: StickinessScope.section,
};

const defaultPageBlockPartStyles: Partial<PageBlockPartStyles> & { stickyPositioning: StickyPositioningSettings } = {
  paddingTop: '5px',
  paddingRight: '5px',
  paddingBottom: '5px',
  paddingLeft: '5px',

  stickyPositioning: cloneDeep(defaultStickyPositioningSettings),
};

// TODO: consider if we should store these setting in widget; persistence for template
export class PageBlockPartStyles extends Styles {
  stickyPositioning: StickyPositioningSettings;

  constructor(styles: Partial<PageBlockPartStyles> & { stickyPositioning: StickyPositioningSettings } = cloneDeep(defaultPageBlockPartStyles)) {
    super();

    Object.assign(this, styles);
    this.stickyPositioning = this.stickyPositioning || cloneDeep(defaultStickyPositioningSettings);
    this.initBackgroundStyles(styles);
  }
}
