<h5 *ngIf="showTitle" translate>{{title}}</h5>
<div class="grid mt-2">
  <!-- PADDING TOP -->
  <div class="col-3">
    <h6>Top</h6>
    <input pInputText type="text" [(ngModel)]="paddingStyles.paddingTop" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- PADDING RIGHT -->
  <div class="col-3">
    <h6>Right</h6>
    <input pInputText type="text" [(ngModel)]="paddingStyles.paddingRight" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- PADDING BOTTOM -->
  <div class="col-3">
    <h6>Bottom</h6>
    <input pInputText type="text" [(ngModel)]="paddingStyles.paddingBottom" (ngModelChange)="changesEmitted.emit(true)" />
  </div>

  <!-- PADDING LEFT -->
  <div class="col-3">
    <h6>Left</h6>
    <input pInputText type="text" [(ngModel)]="paddingStyles.paddingLeft" (ngModelChange)="changesEmitted.emit(true)" />
  </div>
</div>
