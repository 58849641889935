import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TemplatesService } from '@private/pages/template-management/templates/services/templates.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { APPLICATION_ID_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: ['./templates-table.component.scss'],
})
export class TemplatesTableComponent extends CoreListComponent<TemplateResponseDto> {
  @ViewChild('table') private table: Table;
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<TemplateResponseDto>>;
  @Input() columns: TableColumn[];
  @Input() actionButtonsTemplate: TemplateRef<any>;
  @Input() selectTemplates: boolean;

  APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  selectedTemplates: TemplateResponseDto[] = [];

  typeOptions: SelectOption<string, TemplateType>[];

  constructor(public readonly s: TemplatesService, public readonly applicationSwitcherService: ApplicationSwitcherService) {
    super();
  }

  protected onInit(): void {
    super.onInit();
    this.typeOptions = Object.entries(TemplateType).reduce<SelectOption<string, TemplateType>[]>(
      (options: SelectOption<string, TemplateType>[], [key, value]: [string, TemplateType]) => {
        const label = key.charAt(0).toUpperCase() + key.slice(1);
        const option = new SelectOption(label, value);

        return [...options, option];
      },
      [],
    );

    this.registerSubscription(
      this.applicationSwitcherService.selectedApplication$.subscribe(() => {
        this.s.doFilter(this.table);
      }),
    );
  }

  async update(): Promise<void> {
    this.s.doFilter(this.table);
  }
}
