import { Component, HostBinding, Input } from '@angular/core';
import { GridCell } from '@shared/components/grid-layout-generator/types/grid-cell';
import { NamedGridArea } from '@shared/components/grid-layout-generator/types/named-grid-area';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { INITIAL_GRID_ROW_SIZE } from '@shared/constants/constants';
import { GridUtil } from '@shared/utils/grid.util';
import { colors } from './colors';

/**
 * @todo consider making cells transparent to display original background; fill gaps with gradient; place line
 *   numbers outside the grid
 */
@Component({
  selector: 'app-grid-layout-preview',
  templateUrl: './grid-layout-preview.component.html',
  styleUrls: ['./grid-layout-preview.component.scss'],
})
export class GridLayoutPreviewComponent {
  @Input() gridTemplateColumns: string;

  @Input() set gridTemplateRows(gridTemplateRows: string) {
    this.setGridTemplateRowsWithMinHeight(gridTemplateRows);
  }
  @Input() columnGap: string;
  @Input() rowGap: string;
  @Input() areas: NamedGridArea[];
  @Input() cells: GridCell[];
  @Input() activeBreakpoint: StyleApplicationBreakpoint;

  protected gridTemplateRowsWithMinHeight: string;

  @HostBinding('style.grid-column-start') get gridColumnStart(): number {
    return 1;
  }

  @HostBinding('style.grid-column-end') get gridColumnEnd(): number {
    return this.gridTemplateColumns.split(' ').length + 1;
  }

  @HostBinding('style.grid-row-start') get gridRowStart(): number {
    return 1;
  }

  @HostBinding('style.grid-row-end') get gridRowEnd(): number {
    return this.gridTemplateRowsWithMinHeight.split(' ').length + 1;
  }

  get colors(): string[] {
    return colors;
  }

  private setGridTemplateRowsWithMinHeight(gridTemplateRows: string): void {
    this.gridTemplateRowsWithMinHeight = gridTemplateRows
      .split(' ')
      .map((row: string) => (GridUtil.isAutoOrRelativeTrack(row) ? `${INITIAL_GRID_ROW_SIZE}` : row))
      .join(' ');
  }
}
