<div class="grid">

  <div class="col-12">
    <h6> Menu type </h6>
    <p-dropdown (onChange)="menuWidgetHelper.setPositionStyleBasedOnMenuType(m)"
                [(ngModel)]="m.settings.menu.type"
                [autoDisplayFirst]="false"
                [options]="m.options.menuTypes"
                id="menutype">
    </p-dropdown>
  </div>

  <div class="col-4 mb-0">
    <button (click)="paddingOp.toggle($event)" class="w-100" icon="bi bi-arrows-move"
            label="{{'Paddings' | translate }}" pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="borderOp.toggle($event)" class="w-100" icon="bi bi-border-style" label="{{'Borders' | translate }}"
            pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="colorOp.toggle($event)" class="w-100" icon="pi pi-palette" label="{{'Colors' | translate }}"
            pButton type="button"></button>
  </div>

  <div class="col-12 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="m.settings.menu.stretch" binary="true" id="stretchItems"></p-checkbox>
        <label for="stretchItems">{{'Stretch all menu items to same height'| translate}}</label>
      </span>
    </span>
  </div>

</div>

<p-overlayPanel #colorOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <div class="col-12">
      <app-color-picker [(color)]="m.styles.menu.backgroundColor"
                        label="{{'Background color'| translate}}"></app-color-picker>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #paddingOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <app-padding-form [paddingStyles]="m.styles.menu"></app-padding-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
  <ng-template pTemplate>
    <app-border-form [styles]="m.styles.menu"></app-border-form>
  </ng-template>
</p-overlayPanel>
