import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { StickyElement } from '@shared/components/page/directives/sticky-element';
import { StickyPageElementService } from '@shared/components/page/services/sticky-page-element.service';

@Directive({
  selector: '[appStickyBlockPart]',
})
export class StickyBlockPartDirective extends StickyElement implements PartLocation {
  @Input() sectionIndex: number;
  @Input() rowIndex: number;
  @Input() blockIndex: number;
  @Input() partIndex: number;

  constructor(elRef: ElementRef<HTMLElement>, renderer: Renderer2, stickySectionService: StickyPageElementService) {
    super(elRef, renderer, stickySectionService);
  }
}
