import { Injectable } from '@angular/core';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class PageBuilderGraphicalEventsService {
  private eventsChannel: Map<PageBuilderEventType, Subject<any>> = new Map<PageBuilderEventType, Subject<any>>();

  publish<Data>(eventType: PageBuilderEventType, data: Data): void {
    this.register<Data>(eventType);
    (this.eventsChannel.get(eventType) as Subject<Data>).next(data);
  }

  subscribe<Data>(eventType: PageBuilderEventType, handler: (value: Data) => void): Subscription {
    this.register<Data>(eventType);

    return this.eventsChannel.get(eventType)!.subscribe(handler);
  }

  private register<Data>(eventType: PageBuilderEventType): void {
    if (!this.eventsChannel.has(eventType)) {
      this.eventsChannel.set(eventType, new Subject<Data>());
    }
  }
}
