import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { RuleFormatHandlerService } from '../services';
import { FormatAttributeEvent } from '../types';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appFormatAttribute]',
})
export class FormatAttributeDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  @Input() attributeValue: any;

  constructor(protected ruleFormatHandler: RuleFormatHandlerService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.initSubscription = this.ruleFormatHandler.formatEvent$
      .pipe(filter(formatEvent => this.isMatchingAttributeData(formatEvent)))
      .subscribe(formatEvent => this.applyStyles(formatEvent));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private applyStyles(formatEvent: FormatAttributeEvent) {
    // formatEvent.format.forEach(item => {
    //   if (RuleConditionTypeDefinitions.getDefinition(item.type).isMet(this.attributeValue, item.value)) {
    //     Object.keys(item.styles).forEach(styleKey => {
    //       this.elementRef.nativeElement.style[styleKey] = item.styles[styleKey];
    //     });
    //   }
    // });
  }
}
