import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/app.breadcrumb.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
    'ul {display: flex;flex-flexDirection: column;}',
    'li {display: flex;align-items: center;padding: 5px;}',
    '.fileName {margin-left: 8px;font-weight: bold;}',
  ],
})
export class DashboardComponent implements OnInit {
  count = 0;
  amount = 0;
  isConvertingProcess = false;

  constructor(private readonly breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Dashboard' }]);
  }
}
