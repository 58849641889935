import { Params } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Constants, UNRESOLVED_VALUE } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { AttributeInfo } from '@widgets/card-widget/types/attribute-info';
import { LinkValue } from '@widgets/card-widget/types/link-value';
import { ArtifactValueExtractor } from './artifact-value-extractor';
import { CardWidgetOptions } from './card-widget-options';

export class LinksExtractor implements ArtifactValueExtractor {
  constructor(private readonly options: CardWidgetOptions) {}

  static isLinksAttribute(attributeId: string, options: CardWidgetOptions): boolean {
    return attributeId! in options.linkTypes.listMap;
  }

  getValue(artifactDto: ArtifactResponseDto, { linkDirection, attributeId }: AttributeInfo): LinkValue[] {
    const links = this.getLinks(linkDirection!, attributeId);
    return this.getLinkValues(links, linkDirection!);
  }

  private getLinks(direction: LinkDirection, attributeId: string): LinkResponseDto[] {
    const links: { [direction in LinkDirection]: ListContainer<LinkResponseDto> } = {
      [LinkDirection.outgoing]: this.options.outgoingLinks,
      [LinkDirection.incoming]: this.options.incomingLinks,
    };
    return links[direction].filterByKey('linkTypeId', attributeId);
  }

  private getLinkValues(links: LinkResponseDto[], direction: LinkDirection): LinkValue[] {
    return links.map((link: LinkResponseDto) => {
      const artifactId = this.getArtifactId(link, direction);
      const artifact = this.options.linkedArtifacts.listMap[artifactId];

      if (!artifact) return { name: UNRESOLVED_VALUE, url: null, queryParams: {} };

      const artifactType = this.options.artifactTypes.listMap[artifact.artifactTypeId];
      return this.getLinkValue(artifact, artifactType, artifactId);
    });
  }

  private getArtifactId(link: LinkResponseDto, direction: LinkDirection): string {
    const ids: { [direction in LinkDirection]: string } = {
      [LinkDirection.outgoing]: link.destinationArtifactId,
      [LinkDirection.incoming]: link.sourceArtifactId,
    };

    return ids[direction];
  }

  private getLinkValue(artifact: ArtifactResponseDto, artifactType: NewArtifactType, artifactId: string): LinkValue {
    const name = this.getLinkName(artifact, artifactType.primaryAttributes);
    const url = this.getLinkUrl(artifactType);
    const queryParams = this.getQueryParams(artifactId);

    return { name, url, queryParams };
  }

  private getLinkName({ id, attributes, artifactTypeId }: ArtifactResponseDto, attributeIds: string[]): string {
    return attributeIds.reduce((acc: string, attributeId: string) => {
      let attributeValue: string;

      if (attributeId === Constants.primaryAttributesDefaultId) {
        attributeValue = id;
      } else {
        attributeValue =
          attributeId === Constants.primaryAttributesArtifactTypeId
            ? this.options.artifactTypes.listMap[artifactTypeId].name
            : (attributes[attributeId]?.value as string) || '';
      }

      return acc ? `${acc} ${attributeValue}` : attributeValue;
    }, '');
  }

  private getLinkUrl({ defaultPageId }: NewArtifactType): string[] | null {
    return defaultPageId ? ['/page', defaultPageId] : null;
  }

  private getQueryParams(artifactId: string): Params {
    return { artifactId };
  }
}
