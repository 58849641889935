import { Pipe, PipeTransform } from '@angular/core';
import { APPLICATION_ID_KEY, ERROR_GETTING_APPLICATION, FORMAT_KEY } from '@shared/constants/constants';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ObjectUtil } from '@shared/utils/object.util';

@Pipe({ name: 'printArtifactTypeToTable' })
export class PrintArtifactTypeToTablePipe implements PipeTransform {
  constructor(private readonly objectUtil: ObjectUtil) {}

  transform(artifactType: NewArtifactType, key: string, applications: ListContainer<NewApplication>): string {
    if (key === APPLICATION_ID_KEY) return applications.listMap[artifactType.applicationId]?.name || ERROR_GETTING_APPLICATION;
    if (key === FORMAT_KEY) return artifactType.format || '';
    return this.objectUtil.getValueFromPath(key, artifactType);
  }
}
