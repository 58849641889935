import { CURRENT_USER_URL_FILTER_VALUE, URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';
import { AbstractAvrWidgetArtifactSourceService } from './abstract.artifact-source.service';
import { Subject, concatMap, debounceTime, firstValueFrom, lastValueFrom, map, merge, takeUntil } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { TenantArtifactService } from '@api/services';
import { ActivatedRoute, ParamMap, convertToParamMap } from '@angular/router';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { AvrWidgetComponent } from '@widgets/avr-widget/avr-widget.component';
import { AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';

const EXCEPT_FIELDS_ON_ARTIFACT_SOURCE = ['dataSource.artifactSource'];
const EXCEPT_FIELDS_ON_ARTIFACT_TYPE = [...EXCEPT_FIELDS_ON_ARTIFACT_SOURCE, ...['dataSource.artifactTypeId', 'dataSource.artifactListeningKey']];

@Injectable()
export class DynamicArtifactAvrWidgetArtifactSourceService extends AbstractAvrWidgetArtifactSourceService implements OnDestroy {
  public readonly stopListenToUrl: Subject<void> = new Subject<void>();
  private readonly manualListenToUrlTrigger: Subject<ParamMap> = new Subject<ParamMap>();
  private readonly listeningKeyChange: Subject<void> = new Subject<void>();

  constructor(
    protected readonly tenantArtifactService: TenantArtifactService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly elvisUtil: ElvisUtil,
  ) {
    super(AvrArtifactSources.dynamicArtifact, tenantArtifactService);
    this.listeningKeyChange.pipe(debounceTime(750)).subscribe(() => {
      this.manualTrigerUrlListenToUrl();
    });
  }

  async init(context: AvrWidgetComponent): Promise<void> {
    await super.init(context);
    if (this.settings.dataSource.artifactSource !== this.artifactSource) return;
    this.subscribeOnArtifactIdChanges();
    await this.manualTrigerUrlListenToUrl();
  }

  ngOnDestroy(): void {
    this.stopListenToUrl.next();
    this.stopListenToUrl.complete();
    this.manualListenToUrlTrigger.complete();
  }

  async onArtifactSourceChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_SOURCE);
    this.subscribeOnArtifactIdChanges();
    this.model.settings.dataSource.artifactListeningKey = URL_KEY_VALUE_ARTIFACT_ID;
    this.model.settingsStep = 1;
  }

  async onArtifactTypeChange(): Promise<void> {
    this.resetAll(EXCEPT_FIELDS_ON_ARTIFACT_TYPE);
    this.manualTrigerUrlListenToUrl();
    this.model.settingsStep = 2;
  }

  async onArtifactListeningKeyChange(): Promise<void> {
    this.listeningKeyChange.next();
  }

  async onAvrTypeChange(): Promise<void> {
    this.s.setAvrFileType();
    this.manualTrigerUrlListenToUrl();
  }

  private subscribeOnArtifactIdChanges(): void {
    merge(this.activatedRoute.queryParamMap, this.manualListenToUrlTrigger)
      .pipe(
        map((paramMap: ParamMap) => {
          return paramMap.get(this.settings.dataSource.artifactListeningKey || '') || null;
        }),
        concatMap(async (artifactId: string | null) => {
          await this.loadArtifactById(artifactId);
        }),
        takeUntil(this.stopListenToUrl),
      )
      .subscribe();
  }

  private async manualTrigerUrlListenToUrl(): Promise<void> {
    this.manualListenToUrlTrigger.next(convertToParamMap(await firstValueFrom(this.activatedRoute.queryParams)));
  }

  private async loadArtifactById(id: string | null): Promise<void> {
    if (id === null) {
      this.options.artifactOptions = [];
    } else {
      switch (id) {
        case CURRENT_USER_URL_FILTER_VALUE:
          id = this.elvisUtil.getCurrentUser().tenant?.profileArtifactId || id;
          break;
      }

      const artifactDto = await lastValueFrom(this.tenantArtifactService.artifactControllerGet({ id: id })).catch(e => console.error(e));

      if (artifactDto?.artifactTypeId === this.settings.dataSource.artifactTypeId) {
        const nameAttributeId = this.options.currentUser?.tenant.systemAttributes?.nameAttributeId || '';
        const artifact = this.artifactResponseTransform(artifactDto);

        this.options.artifactOptions = [new SelectOption(artifact.attributes[nameAttributeId].value || '', artifact)];
      } else {
        this.options.artifactOptions = [];
      }
    }

    // handles on init phase as well as on URL change artifact
    this.s.setCurrentArtifact(this.model.options.artifactOptions[0]?.value || null);
  }
}
