import { Pipe, PipeTransform } from '@angular/core';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetFormItem } from '../types/artifact-widget-form.types';

@Pipe({ name: 'activeItem' })
export class ActiveItemPipe implements PipeTransform {
  transform(value: SelectOption<string, NewClientAttribute | LinkType> | null = null, args: ArtifactWidgetFormItem): string {
    if (!value) return '';
    else if (value?.value instanceof NewClientAttribute) return value.value?.id === args.attribute?.value?.id ? 'selected' : '';
    else if (value?.value instanceof LinkType)
      return value.value.id + '_' + value.meta === args.linkType?.value.id + '_' + args.linkType?.meta ? 'selected' : '';
    else return '';
  }
}
