import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-resizer',
  templateUrl: './card-resizer.component.html',
  styleUrls: ['./card-resizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardResizerComponent {
  @Input() size: number;

  @Output() sizeChange: EventEmitter<number> = new EventEmitter<number>();

  onSizeChange(size: number): void {
    this.sizeChange.emit(size);
  }
}
