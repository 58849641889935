import { NewArtifactType } from '@shared/types/artifact-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { NumberTypes, NumberWidgetOptions } from '@widgets/number-widget/types/number-widget-options.types';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { URL_KEY_VALUE_ARTIFACT_ID, URL_KEY_VALUE_SAVE_TO_FOLDER_ID } from '@shared/constants/constants';

export class NumberWidgetSelected {
  artifactType: SelectOption<string, NewArtifactType>;
  attribute: SelectOption<string, string> | null;
  showFixedValue: boolean;
  showBadge = false;
  icon = '';
  loaded: boolean;
  listenUrlFolderId = false;
  listenUrlArtifactId = false;
  listenUrlAttributeId = false;
  listenUrlFilterOption = false;
  folderKey = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;
  artifactKey = URL_KEY_VALUE_ARTIFACT_ID;
  countableAttributeKey: string | null;
  numberType: SelectOption<string, string> = new SelectOption(NumberTypes.count);

  attributesFilter: ArtifactFilter[] = [];
  urlFilter: ArtifactFilter[] = [];
  dto: NumberWidgetSelectedDto;

  constructor(dto: NumberWidgetSelectedDto | null = null) {
    // wait for fetch options then update
    if (dto instanceof NumberWidgetSelected) {
      dto && (this.dto = dto.dto);
    } else {
      dto && (this.dto = dto);
    }
    dto && !this.loaded && !this.showFixedValue && (this.showFixedValue = this.dto.showFixedValue);
  }

  toServer(): NumberWidgetSelectedDto {
    const attributesFilter = this.attributesFilter
      .filter(filter => filter.attribute && (filter.dataType || filter.type))
      .map(f => {
        const filter = f.toServer();
        Array.isArray(filter.value) && filter.value.length < 1 && (filter.value = null);
        return filter;
      });

    const dto: NumberWidgetSelectedDto = {
      artifactType: { id: this.artifactType?.value.id },
      attribute: { id: this.attribute?.value || null },
      showFixedValue: this.showFixedValue,
      showBadge: this.showBadge,
      icon: this.icon || '',
      attributesFilter,
      listenUrlArtifactId: this.listenUrlArtifactId,
      listenUrlFolderId: this.listenUrlFolderId,
      listenUrlAttributeId: this.listenUrlAttributeId,
      listenUrlFilterOption: this.listenUrlFilterOption,
      numberType: this.numberType.value,
    };

    this.folderKey !== URL_KEY_VALUE_SAVE_TO_FOLDER_ID && (dto.folderKey = this.folderKey);
    this.artifactKey !== URL_KEY_VALUE_ARTIFACT_ID && (dto.artifactKey = this.artifactKey);

    return dto;
  }

  fromDto(options: NumberWidgetOptions): void {
    if (!this.dto || this.loaded) return;

    if (options.attributes && options.dataTypes.list && !this.attributesFilter.length) {
      this.attributesFilter = this.dto.attributesFilter.map(filter =>
        ArtifactFilter.fromDtoAndOptions(filter, {
          attributes: options.attributes,
          dataTypes: options.dataTypes.list,
          linkTypes: options.linkTypes.list,
        }),
      );
    }

    if (this.dto.artifactType?.id) {
      const at = options.artifactTypes.listMap[this.dto.artifactType.id];
      at && (this.artifactType = new SelectOption<string, NewArtifactType>(at.name, at));
    }

    if (!this.attribute && this.dto.attribute?.id) {
      options.attributes.forEach(attr => {
        if (attr.id === this.dto.attribute?.id) {
          this.attribute = new SelectOption<string, string>(attr.name, attr.id);
        }
      });
    }

    this.dto.numberType && (this.numberType = new SelectOption(this.dto.numberType));

    // fix for old widgets without this fields, for get clean boolean value
    !this.listenUrlFolderId && (this.listenUrlFolderId = !!this.dto.listenUrlFolderId);
    !this.listenUrlArtifactId && (this.listenUrlArtifactId = !!this.dto.listenUrlArtifactId);
    !this.listenUrlAttributeId && (this.listenUrlAttributeId = !!this.dto.listenUrlAttributeId);
    !this.listenUrlFilterOption && (this.listenUrlFilterOption = !!this.dto.listenUrlFilterOption);

    this.dto.folderKey && (this.folderKey = this.dto.folderKey);
    this.dto.artifactKey && (this.artifactKey = this.dto.artifactKey);

    !this.showFixedValue && (this.showFixedValue = this.dto.showFixedValue);
    !this.icon && (this.icon = this.dto.icon || '');
    !this.showBadge && (this.showBadge = this.dto.showBadge);
    this.loaded = true;
  }
}

export interface NumberWidgetSelectedDto {
  artifactType: { id: string };
  attribute: { id: string | null };
  attributesFilter: ArtifactFilterDto[];
  showFixedValue: boolean;
  showBadge: boolean;
  icon: string;
  listenUrlFolderId: boolean;
  listenUrlArtifactId: boolean;
  listenUrlAttributeId: boolean;
  listenUrlFilterOption: boolean;
  folderKey?: string;
  artifactKey?: string;
  numberType?: string;
}
