<tr>
  <td>{{ nonMappableFields['debtor.isOptional']!.readableName }}</td>
  <td>
    <p-triStateCheckbox [ngModel]="!nonMappableFields['debtor.isOptional']!.isOptional" disabled="true"></p-triStateCheckbox>
  </td>
  <td>
    <span>
      <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields['debtor.isOptional']!.description" tooltipPosition="top" [escape]="false"></i>
      <p-dropdown
        [options]="debtorIsOptionalOptions"
        [(ngModel)]="nonMappableFields['debtor.isOptional']!.value"
        [showClear]="nonMappableFields['debtor.isOptional']!.isOptional"
        [placeholder]="'Select value' | translate"
        ngClass="full-width-container"
        appendTo="body"
      >
      </p-dropdown>
    </span>
  </td>
</tr>
<tr>
  <td>{{ nonMappableFields['outputType']!.readableName }}</td>
  <td>
    <p-triStateCheckbox [ngModel]="!nonMappableFields['outputType']!.isOptional" disabled="true"></p-triStateCheckbox>
  </td>
  <td>
    <span>
      <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields['outputType']!.description" tooltipPosition="top" [escape]="false"></i>
      <p-dropdown
        [options]="outputTypeOptions"
        [(ngModel)]="nonMappableFields['outputType']!.value"
        [showClear]="nonMappableFields['outputType']!.isOptional"
        [placeholder]="'Select value'"
        ngClass="full-width-container"
        appendTo="body"
      >
      </p-dropdown>
    </span>
  </td>
</tr>
