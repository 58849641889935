<app-loader [loading]="m.inProgress"></app-loader>
<div class="col-12 top_box">
  <div>
    <a [routerLink]="['/admin/workflow-list']">
      <button pButton label="{{ 'Cancel' | translate }}" type="button" class="p-button p-button-warning"></button>
    </a>
    <button pButton label="{{ 'Save' | translate }}" type="button" class="p-button p-button-success" (click)="save()">
      <i *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></i>
    </button>
  </div>
</div>
<app-workflows-rule *ngIf="m.rule && m.options.pages.loaded && m.options.artifactTypes.loaded"
                    [rule]="m.rule"
                    [artifactTypes]="m.options.artifactTypes.list"
                    [attributes]="m.options.attributes"
                    [dataTypes]="m.options.dataTypes"
                    [linkTypes]="m.options.linkTypes"
                    [applications]="m.options.applications"
                    [users]="m.options.users"
                    [pages]="m.options.pages"
                    [isNew]="m.isCreateMode"
                    [editable]="true"
                    [ruleAdministrationType]="RuleAdministrationType.GENERAL"
>

</app-workflows-rule>
