import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';

@Pipe({ name: 'shouldShowFolderEmittingOption' })
export class ShouldShowFolderEmittingOptionPipe implements PipeTransform {
  transform(loadMode: ListWidgetTableLoadModeEnum, selectedArtifactType?: NewArtifactType): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byArtifactType && !!selectedArtifactType && selectedArtifactType.format === ArtifactTypeFormatEnum.module;
  }
}
