import { Component, Input } from '@angular/core';
import { EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AttributeSettings } from '@widgets/card-widget/types/attribute-settings';
import { EnumOptionSettings } from '@widgets/card-widget/types/enum-option-settings';

@Component({
  selector: 'app-enum-value',
  templateUrl: './enum-value.component.html',
  styleUrls: ['./enum-value.component.scss'],
})
export class EnumValueComponent {
  @Input() option: EnumeratedOption;
  @Input() attributeSettings: AttributeSettings;

  get isIconVisible(): boolean {
    const isIconPresent = !!this.option.icon;

    if (this.customEnumOptionSettings) {
      return this.customEnumOptionSettings.isIconVisible && isIconPresent;
    }

    return isIconPresent;
  }

  get customEnumOptionSettings(): EnumOptionSettings {
    return this.attributeSettings.enumAttributeSettings[this.option.value];
  }
}
