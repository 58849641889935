"use strict";

var t = require("./lexer"),
  e = require("./token"),
  i = require("./postfix"),
  o = require("./postfix_evaluator"),
  s = require("./functions"),
  r = function () {
    function r() {
      this.toPostfix = i.toPostfix, this.addToken = t.addToken, this.lex = t.lex, this.postfixEval = o.postfixEval, this.math = s.createMathFunctions(this), this.tokens = e.createTokens(this);
    }
    return r.prototype.eval = function (t, e, i) {
      return this.postfixEval(this.toPostfix(this.lex(t, e)), i);
    }, r;
  }();
r.TOKEN_TYPES = e.tokenTypes, r.tokenTypes = e.tokenTypes, module.exports = r;