import { Pipe, PipeTransform } from '@angular/core';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageElement } from '@private/pages/page-management/page-builder-graphical/types/page-element';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';

@Pipe({
  name: 'isElementOfType',
})
export class IsElementOfTypePipe implements PipeTransform {
  transform(element: PageElement, targetType: typeof Page | typeof PageSection | typeof PageRow | typeof PageBlock | typeof PageBlockPart): boolean {
    return element instanceof targetType;
  }
}
