import { Injectable } from '@angular/core';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { kebabCase } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AttributeUtil {
  /**
   * Function returns attribute name in url friendly format - kebab case format (dash separated).
   * @param rawAttributeName raw attribute name
   * @returns attribute name converted into kebab case format
   */
  getUrlQueryAttributeName(rawAttributeName: string): string {
    return this.getAttributeNameAsVariable(rawAttributeName);
  }

  /**
   * Function returns attribute name in kebab case format (dash separated).
   * @param rawAttributeName raw attribute name
   * @returns attribute name converted into kebab case format
   */
  getAttributeNameAsVariable(rawAttributeName: string): string {
    return kebabCase(rawAttributeName);
  }

  /**
   * Returns map for given artifact type is and map of attributes, where key is attribute name in url friendly form
   * (kebab case) and value is NewAttribute. If any of the arguments is undefined, empty object is returned.
   * @param artifactType artifact type
   * @param attributesMap list container containing NewAttribute instances
   * @returns map for potential url query filtering
   */
  getUrlQueryAttributesForArtifactType(artifactType: NewArtifactType, attributesMap: ListContainer<NewAttribute>): Record<string, NewAttribute> {
    if (!artifactType || !attributesMap) {
      return {};
    }
    const result: Record<string, NewAttribute> = {};
    Object.keys(artifactType.attributes).forEach(attributeId => {
      result[attributesMap.listMap[attributeId].nameInKebab] = attributesMap.listMap[attributeId];
    });
    return result;
  }
}
