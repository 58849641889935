<div class="file-upload-wrapper" *ngIf="fullAttribute">
  <div class="file-button-wrapper">
    <label style="align-self: center" [for]="'fileUpload' + index">{{fullAttribute?.name}}</label>
    <p-fileUpload #fileUpload [id]="'fileUpload' + index" name="demo[]" mode="basic"
                  (uploadHandler)="onFilesAdd($event)" [customUpload]="true" [auto]="true"
                  [multiple]="fullAttribute?.multipleValues">
    </p-fileUpload>
  </div>

  <div class="file-list-wrapper">
        <span *ngIf="!fileUpload.multiple && artifactAttribute.value && artifactAttribute.value.size" class="file-item file-item-single">
            <span [ngClass]="{'downloadable': artifactAttribute.value.id}" (click)="onFileDownload(artifactAttribute.value)">
                {{artifactAttribute.value.filename || artifactAttribute.value.name}} [{{artifactAttribute.value.size | getFileSize}}]
            </span>
            <span class="button-box">
                    <button pButton icon="pi pi-times" class="p-button-danger p-button-text utility-button"
                            type="button" (click)="onFileRemove()">
                    </button>
            </span>
        </span>

    <ul *ngIf="fileUpload.multiple && artifactAttribute.value?.length" class="file-list">
      <li *ngFor="let file of artifactAttribute.value; let i = index" class="file-item">
        <div [ngClass]="{'downloadable': file.id}" (click)="onFileDownload(file)">
          {{file.filename || file.name || ''}} [{{file.size | getFileSize}}]
        </div>
        <div class="button-box">
          <button pButton icon="pi pi-times" type="button" class="p-button-danger p-button-text utility-button"
                  (click)="onFileRemove(i)">
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
