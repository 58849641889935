import { Component, Input } from '@angular/core';
import { StyleCopyPastingService } from '../../../../services/style-copy-pasting.service';
import { ChipStyles, FontStyles, IconStyles } from '../../../../types/menu-widget-styles.types';

@Component({
  selector: 'app-content-style-form',
  templateUrl: './content-style-form.component.html',
  styleUrls: ['./content-style-form.component.scss'],
})
export class ContentStyleFormComponent {
  @Input() contentStyle: FontStyles | IconStyles | ChipStyles;
  showBorderOp = false;

  constructor(public readonly styleService: StyleCopyPastingService) {}

  get isIconStyle(): boolean {
    return this.contentStyle instanceof IconStyles;
  }

  get isChipStyle(): boolean {
    return this.contentStyle instanceof ChipStyles;
  }
}
