<div class="grid" *ngIf="model">
  <div [class.mt-2]="!showClear" [ngClass]="showClear ? 'col-6': 'col-12'">
    <p-multiSelect
      [options]="booleanFilterSelectOptions"
      [(ngModel)]="selected"
      [placeholder]="placeholder | translate "
      (onChange)="onChange($event)"
      appendTo="body"
    >
    </p-multiSelect>
  </div>
  <div class="col-6" *ngIf="showClear">
    <button (click)="doClear()" pButton [label]="'Clear' | translate" class="p-button p-button-outlined"
            iconPos="left"></button>
  </div>
</div>
