<span class="dynamic-label" *ngIf="fullAttribute.multipleValues === true; else singleHyperlinkValue">
  <p-chips
    [(ngModel)]="artifactAttribute.value"
    (ngModelChange)="onChangeCb($event)"
    [inputId]="'hyperlinkFieldM' + index"
    [ngClass]="{ 'p-chips-filled': artifactAttribute.value?.length }"
    (onChipClick)="onHyperlinkChipClickEvent()"
  >
    <ng-template let-item pTemplate="item">
      {{ item }}
    </ng-template>
  </p-chips>
  <label class="dynamic-label-text" [for]="'hyperlinkFieldM' + index">{{ fullAttribute.name }}</label>
</span>

<ng-template #singleHyperlinkValue>
  <span class="dynamic-label">
    <input type="text" [id]="'hyperlinkFieldS' + index" pInputText [(ngModel)]="artifactAttribute.value" />
    <label class="dynamic-label-text" [for]="'hyperlinkFieldS' + index">{{ fullAttribute.name }}</label>
  </span>
</ng-template>
