import { Pipe, PipeTransform } from '@angular/core';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';

@Pipe({ name: 'getLinkUrl' })
export class GetLinkUrlPipe implements PipeTransform {
  transform(
    link: NewLink,
    linkType: SelectOption<string, LinkType>,
    linkedArtifactsMap: Record<string, NewArtifact>,
    artifactTypes: ListContainer<NewArtifactType>,
  ): string[] | null {
    const artifactId = LinkMethods.isOutgoing(linkType.meta) ? link.destinationArtifactId : link.sourceArtifactId;
    const artifact = linkedArtifactsMap[artifactId];
    const artifactType = artifactTypes.listMap[artifact.artifactTypeId];
    if (artifactType && artifactType.defaultPageId) return ['/page', artifactType.defaultPageId];
    return null;
  }
}
