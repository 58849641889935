import { DataTypeResponseDto, DataTypeValueResponseDto, ImportDto, RecordDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

// should implement DataTypeResponseDto but cannot now because of maximum/minimum types
export class NewDataType {
  id: string;
  applicationId: string;
  name: string;
  description = '';
  kind: DataTypeKind = DataTypeKind.simple;
  baseDataType: BaseDataType | null;
  imported?: ImportDto;
  isProtected: boolean;
  maximum?: string | Date;
  minimum?: string | Date;
  values?: Array<DataTypeValueResponseDto>;
  uri = '';
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto = null;

  constructor(dataType?: DataTypeResponseDto) {
    dataType && Object.assign(this, dataType);
  }

  get isEnum(): boolean {
    return this.kind === DataTypeKind.enumerated;
  }

  get isSimple(): boolean {
    return this.kind === DataTypeKind.simple;
  }

  get isCounter(): boolean {
    return this.kind === DataTypeKind.counter;
  }

  get isText(): boolean {
    return this.baseDataType === BaseDataType.text;
  }

  get isInteger(): boolean {
    return this.baseDataType === BaseDataType.integer;
  }

  get isDecimal(): boolean {
    return this.baseDataType === BaseDataType.decimal;
  }

  get isHtml(): boolean {
    return this.baseDataType === BaseDataType.html;
  }

  get isBoolean(): boolean {
    return this.baseDataType === BaseDataType.boolean;
  }

  get isDate(): boolean {
    return this.baseDataType === BaseDataType.date;
  }

  get isTime(): boolean {
    return this.baseDataType === BaseDataType.time;
  }

  get isDateTime(): boolean {
    return this.baseDataType === BaseDataType.dateTime;
  }

  get isFile(): boolean {
    return this.baseDataType === BaseDataType.file;
  }

  get isHyperlink(): boolean {
    return this.baseDataType === BaseDataType.hyperlink;
  }

  get isUser(): boolean {
    return this.baseDataType === BaseDataType.user;
  }
}
