import { NgModule } from '@angular/core';
import { ApiModule } from '@api/api.module';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationAvrService } from '@shared/services/artifact-visual-representation/document-generation/document-generation.avr.service';
import { QrCodesAvrService } from '@shared/services/artifact-visual-representation/qr-codes/qr-codes.avr.service';
import { SwissBillAvrService } from '@shared/services/artifact-visual-representation/swiss-bill/swiss-bill.avr.service';

@NgModule({
  providers: [
    {
      provide: 'AvrServices',
      useFactory: () => {
        return {
          [AvrTypes.swissBill]: new SwissBillAvrService(),
          [AvrTypes.documentGeneration]: new DocumentGenerationAvrService(),
          [AvrTypes.qrCodes]: new QrCodesAvrService(),
        };
      },
    },
  ],
  imports: [ApiModule],
})
export class AvrServiceModule {}
