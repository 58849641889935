<div class="settings_top-buttons">
  <button (click)="onItemUnselect()" class="p-button p-button-warning" icon="pi pi-undo" iconPos="left" label="{{'Unselect' | translate}}"
          pButton></button>
  <button (click)="s.addTreeItem()" class="p-button" icon="pi pi-plus" iconPos="left" label="{{'Add' | translate }}" pButton></button>
</div>

<div class="tree-container">
  <p-tree *ngIf="m.items.tree"
          (onNodeDrop)="onItemDrop()"
          (onNodeSelect)="s.onTreeItemSelect($event); onSelectItem()"
          [(selection)]="m.selected.treeItem"
          [draggableNodes]="true"
          [droppableNodes]="true"
          [value]="m.items.tree"
          draggableScope="self"
          droppableScope="self"
          scrollHeight="flex"
          selectionMode="single">

    <ng-template let-node pTemplate="default">
      <div class="node-item">
        <div [ngStyle]="m.styles.menu" class="menu">
          <ul [class]="'menu-list '+m.settings?.item[node.hash].positioned">
            <app-menu-item class="menu-list-item" [item]="node" [m]="m" [stopPropagation]="false" [queryParams]="s.c.queryParams"></app-menu-item>
          </ul>
        </div>

        <div class="right-buttons">
          <button (click)="styleCopyService.copyMenuItem(node)"
                  [icon]="(styleCopyService.copiedMenuWidgetItem?.hash === node?.hash ? 'bi bi-clipboard-check': 'pi pi-copy')"
                  class="mr-2 p-button p-button-warning"
                  pButton
                  pTooltip="Copy menu item style"></button>
          <button *ngIf="styleCopyService.copiedMenuWidgetItem"
                  (click)="styleCopyService.pasteIntoSingleMenuItem(node,m)"
                  class="mr-2 p-button p-button-secondary"
                  icon="bi bi-clipboard-plus"
                  pButton
                  pTooltip="Paste menu item style"></button>
          <button (click)="s.removeTreeItem(node)"
                  class="p-button p-button-danger"
                  icon="pi pi-trash"
                  pButton></button>
        </div>
      </div>
    </ng-template>
  </p-tree>
</div>

