import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { CoreParams } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { ScriptLoaderService } from '@shared/services/script-loading.service';
import { AppService } from './app.service';
import { AppModel } from './app.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService],
})
export class AppComponent extends CoreComponent<AppService, AppModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: AppService,
    announcement: AnnouncementService,
    public readonly blockUiService: BlockUiService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly scriptLoaderService: ScriptLoaderService,
  ) {
    super(route, router, translate, new AppModel(), service, announcement, new CoreParams({ initOnQueryParamChange: false }));
  }

  async onInit(): Promise<void> {
    super.onInit();
    if (Environment.enableInstana === true) {
      this.scriptLoaderService.load(
        `(function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
  n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");

  ineum('reportingUrl', 'https://eum-green-saas.instana.io');
  ineum('key', 'Knvu5GDuStyVqPcOKToF4A');
  ineum('trackSessions');
  ineum('autoPageDetection', true);
  ineum('meta', 'userEmail', (() => { return JSON.parse(localStorage.getItem('sessionState') || localStorage.getItem('masterState') || '{}').user?.email || 'NO EMAIL';})());
  ineum('meta', 'tenantId', (() => { return JSON.parse(localStorage.getItem('sessionState') || localStorage.getItem('masterState') || '{}').user?.tenant?.id || 'NO TENANT ID';})());
  ineum('meta', 'currentDay', (() => { const date = new Date(); const year = date.getUTCFullYear(); const month = date.getUTCMonth() + 1; const day = date.getUTCDate(); return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day); })());`,
        { async: 'true' },
        true,
      );
      this.scriptLoaderService.load(`https://eum.instana.io/1.7.2/eum.min.js`, {
        async: 'true',
        crossorigin: 'anonymous',
        defer: 'true',
        integrity: 'sha384-cgeSlevgebehPauohUhsnAeBrpjXzaj94mSv3L2EXjCQH0RRb9xSQ2ErGOWkthIJ',
      });
    }
  }

  onDestroy(): void {
    super.onDestroy();
    this.pushNotificationService.destroy();
    // window.removeEventListener('storage', this.s.handleLocalStorageEvent);
  }
}
