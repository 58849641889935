import { Component, Input } from '@angular/core';
import {
  UpdateArtifactAttributes,
  UpdateArtifactsKeys,
  WorkflowActionUpdateArtifacts,
  WorkflowUpdateAttributeMapValue,
} from '@workflows/types/actions/action-update-artifacts';

@Component({
  selector: 'app-action-update-artifacts',
  templateUrl: './action-update-artifacts.component.html',
  styleUrls: ['./action-update-artifacts.component.scss'],
})
export class ActionUpdateArtifactsComponent {
  @Input() action: WorkflowActionUpdateArtifacts;

  protected readonly RuleKeys = UpdateArtifactsKeys;

  onAddAttribute(): void {
    this.action.clientAttributesArray.push({ id: '', value: '', useInitialValue: false, isMulti: false });
  }

  onRemoveAttribute(index: number): void {
    this.action.clientAttributesArray.splice(index, 1);
  }

  onIsMultiChange(attribute: UpdateArtifactAttributes): void {
    attribute.value = this.getEmptyValue(attribute);
  }

  onUseInitialValueChange(attribute: UpdateArtifactAttributes, useInitialValue: boolean): void {
    attribute.value = useInitialValue ? null : this.getEmptyValue(attribute);
  }

  attrValuesToTrackByFn(index: number): number {
    return index;
  }

  onAddAttributeValue(index: number): void {
    (this.action.clientAttributesArray[index].value as string[]).push('');
  }

  onRemoveAttributeValue(attribute: UpdateArtifactAttributes, index: number): void {
    (attribute.value as string[]).splice(index, 1);
  }

  private getEmptyValue(attribute: UpdateArtifactAttributes): WorkflowUpdateAttributeMapValue {
    return attribute.isMulti ? [''] : '';
  }
}
