import { GridItem } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';

export class GridCell {
  verticalLineStart: number | null;
  verticalLineEnd: number | null;
  horizontalLineStart: number | null;
  horizontalLineEnd: number | null;

  private gridColumnStart: number;
  private gridColumnEnd: number;
  private gridRowStart: number;
  private gridRowEnd: number;

  constructor(styles: GridItem, columnsCount: number, rowsCount: number) {
    Object.assign(this, styles);

    this.verticalLineStart = this.isFirstRow() ? this.gridColumnStart : null;
    this.verticalLineEnd = this.isFirstRow() && this.isLastColumn(columnsCount) ? this.gridColumnEnd : null;

    this.horizontalLineStart = this.isFirstColumn() ? this.gridRowStart : null;
    this.horizontalLineEnd = this.isFirstColumn() && this.isLastRow(rowsCount) ? this.gridRowEnd : null;
  }

  private isFirstRow(): boolean {
    return this.gridRowStart === 1 && this.gridRowEnd === 2;
  }

  private isLastColumn(columnsCount: number): boolean {
    return this.gridColumnStart === columnsCount && this.gridColumnEnd === columnsCount + 1;
  }

  private isFirstColumn(): boolean {
    return this.gridColumnStart === 1 && this.gridColumnEnd === 2;
  }

  private isLastRow(rowsCount: number): boolean {
    return this.gridRowStart === rowsCount && this.gridRowEnd === rowsCount + 1;
  }
}
