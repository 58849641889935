import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { fromEvent, merge, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DisplayAtControlService } from '../../services';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';
import { DisplayAtHtmlControlShowEvent, DisplayAtOverlayControlService } from '../display-at-overlay/display-at-overlay-control.service';

@Component({
  selector: 'app-display-at-html',
  templateUrl: './display-at-html.component.html',
  styleUrls: ['display-at-html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtHtmlComponent extends AbstractDisplayAtComponent implements OnDestroy {
  @Input() tooltipEnabled: boolean;
  @Input() dataType: NewDataType;
  @Input() attribute: NewAttribute;

  @ViewChild('default') defaultHtmlTemplate: TemplateRef<any>;
  private clickSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly datOverlayControlService: DisplayAtOverlayControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
  }

  @ViewChild('iconClick') set iconClick(content: ElementRef) {
    if (content) {
      this.zone.runOutsideAngular(() => {
        const click$ = fromEvent(content.nativeElement, 'click').pipe(
          tap(event =>
            this.zone.run(() => {
              const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, true);
              this.datOverlayControlService.doShowOverlay(showEvent);
            }),
          ),
        );

        const mouseEnter$ = fromEvent(content.nativeElement, 'mouseenter').pipe(
          tap(event =>
            this.zone.run(() => {
              const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, false);
              this.datOverlayControlService.doShowOverlay(showEvent);
            }),
          ),
        );

        const mouseOut$ = fromEvent(content.nativeElement, 'mouseout').pipe(
          tap(() =>
            this.zone.run(() => {
              this.datOverlayControlService.doHideOverlay(this.ownerId);
            }),
          ),
        );

        const preview$ = mouseEnter$.pipe(switchMap(() => mouseOut$.pipe(takeUntil(click$))));

        this.clickSubscription = merge(preview$, click$).subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.clickSubscription && this.clickSubscription.unsubscribe();
  }

  private getDisplayAtHtmlControlShowEvent(event: Event, closable: boolean): DisplayAtHtmlControlShowEvent {
    return {
      ownerId: this.ownerId,
      contentTemplate: this.defaultHtmlTemplate,
      event,
      closable,
    };
  }
}
