import { BaseDataType, DataTypeKind } from '../../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowConditionType } from '../index';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';
import { arrayOfStringOrNumber, enumeratedValue, stringOrNumber } from './includes.condition';

export class WorkflowNotIncludesCondition extends AbstractWorkflowCondition {
  constructor() {
    super();
    this.type = WorkflowConditionType.NOT_INCLUDES;
    this.supportedTypes = [BaseDataType.text, BaseDataType.integer];
    this.supportedKinds = [DataTypeKind.enumerated];
  }

  isMet(source: enumeratedValue, value: enumeratedValue): boolean {
    return Array.isArray(source) ? this.isMetForSourceMulti(source, value) : this.isMetForSourceSingle(source, value);
  }

  private isMetForSourceMulti(source: arrayOfStringOrNumber, value: enumeratedValue): boolean {
    if (Array.isArray(value)) {
      return !value.every(value => source.includes(value));
    }

    return !source.includes(value as stringOrNumber);
  }

  private isMetForSourceSingle(source: stringOrNumber, value: enumeratedValue): boolean {
    if (Array.isArray(value)) {
      return false;
    }

    return source !== value;
  }
}
