import { WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { WorkflowTriggerEvent } from '@workflows/types';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import Mexp from 'math-expression-evaluator';
import { Token } from 'math-expression-evaluator/dist/types/token';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export type CALCULATE_ACTION =
  | WorkflowActionType.AGGREGATE
  | WorkflowActionType.SUBTRACT
  | WorkflowActionType.MULTIPLY
  | WorkflowActionType.DIVIDE
  | WorkflowActionType.CALCULATE;

export interface WorkflowActionCalculateDto extends WorkflowActionDto {
  calculationAttributeIds: string[];
  expression?: string;
}

export class WorkflowActionCalculateAttributeValues extends AbstractWorkflowAttributeBasedAction<CommonFeAction> implements WorkflowActionCalculateDto {
  calculationAttributeIds: string[] = [];
  expression?: string;
  protected typeMark: string;
  protected declare value: number | null;

  constructor(type: CALCULATE_ACTION, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, type, dto, actionAttributeHandler });
    this.calculationAttributeIds = (dto as WorkflowActionCalculateDto)?.calculationAttributeIds;
    this.expression = (dto as WorkflowActionCalculateDto)?.expression;
    this.supportedAttributeDataTypes = [BaseDataType.decimal, BaseDataType.integer];
    this.supportedAttributeDataKinds = [DataTypeKind.simple];
    this.setMarkByType(type);
  }

  static isExpressionValid(expression: string, variableNames?: string[]): boolean {
    const mexp = new Mexp();
    const expressionVariables = variableNames || WorkflowActionCalculateAttributeValues.extractExpressionVariables(expression);
    try {
      const tokens = WorkflowActionCalculateAttributeValues.getExpressionTokens(expressionVariables);
      const pairs = expressionVariables.reduce((acc, attr) => ({ ...acc, [attr]: Math.floor(Math.random() * 10) }), {});
      mexp.eval(expression as string, tokens, pairs);
      return true;
    } catch (error: any) {
      throw new Error(error as string);
    }
  }

  static extractExpressionVariables(expression: string): string[] {
    const variables: string[] = [];
    const regex = /{(.*?)}/g;

    let match;
    while ((match = regex.exec(expression)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  }

  static getExpressionTokens(expressionVariables: string[]): Token[] {
    return expressionVariables.map(attr => ({
      type: Mexp.TOKEN_TYPES.CONSTANT,
      token: '{' + attr + '}',
      show: attr,
      value: attr,
      precedence: 0,
    }));
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return (
      !!triggerEvent?.payload?.artifact || !!triggerEvent?.payload?.getAttributeValueFn || (this.type === WorkflowActionType.CALCULATE && !!this.expression)
    );
  }

  execute(ruleTriggerEvent?: WorkflowTriggerEvent): void {
    const artifact = ruleTriggerEvent?.payload?.artifact;
    const getAttributeValueFn = ruleTriggerEvent?.payload?.getAttributeValueFn;
    if (!artifact && !getAttributeValueFn) {
      return;
    }

    switch (this.type) {
      case WorkflowActionType.CALCULATE: {
        const mexp = new Mexp();
        const expressionVariables = WorkflowActionCalculateAttributeValues.extractExpressionVariables(this.expression as string);
        const tokens = WorkflowActionCalculateAttributeValues.getExpressionTokens(expressionVariables);
        const pairs = expressionVariables.reduce(
          (acc, attr, index) => ({ ...acc, [attr]: this.getAttributeValue(this.calculationAttributeIds[index], getAttributeValueFn, artifact) }),
          {},
        );
        try {
          this.value = mexp.eval(this.expression as string, tokens, pairs);
        } catch (error) {
          this.value = NaN;
          console.error('Unable to evaluate expression, error: ', error);
        }
        break;
      }
      case WorkflowActionType.AGGREGATE:
        this.value = this.calculationAttributeIds.reduce((acc, attr) => acc + this.getAttributeValue(attr, getAttributeValueFn, artifact), 0);
        break;
      case WorkflowActionType.MULTIPLY:
        this.value = this.calculationAttributeIds.reduce((acc, attr) => acc * this.getAttributeValue(attr, getAttributeValueFn, artifact), 1);
        break;
      default: {
        const sourceValue = this.getAttributeValue(this.calculationAttributeIds[0], getAttributeValueFn, artifact);
        const attributes = this.calculationAttributeIds.slice(1);
        this.value =
          this.type === WorkflowActionType.DIVIDE
            ? attributes.reduce((acc, attr) => acc / this.getAttributeValue(attr, getAttributeValueFn, artifact), sourceValue)
            : attributes.reduce((acc, attr) => acc - this.getAttributeValue(attr, getAttributeValueFn, artifact), sourceValue);
        break;
      }
    }
    if (this.attributeId) {
      this.value = parseFloat(this.value.toFixed(2));
      this.actionAttributeHandler.notifySetAttributeValueEvent({
        artifactTypeId: this.artifactTypeId,
        attributeId: this.attributeId,
        value: this.value?.toString(),
      });
    }
  }

  isValid(): boolean {
    if (this.type !== WorkflowActionType.CALCULATE) {
      return !!(this.artifactTypeId && this.calculationAttributeIds?.length > 1);
    }
    try {
      return (this.expression?.length && WorkflowActionCalculateAttributeValues.isExpressionValid(this.expression)) || false;
    } catch (error) {
      return false;
    }
  }

  getCalculatedValue(): number | null {
    return this.value;
  }

  getCalculationMark(): string {
    return this.typeMark;
  }

  isCalculatedValueType(): boolean {
    return true;
  }

  toServer(): WorkflowActionCalculateDto {
    return {
      ...super.toServer(),
      calculationAttributeIds: this.calculationAttributeIds,
      expression: this.expression,
    };
  }

  private setMarkByType(type: CALCULATE_ACTION) {
    switch (type) {
      case WorkflowActionType.AGGREGATE:
        this.typeMark = '+';
        break;
      case WorkflowActionType.SUBTRACT:
        this.typeMark = '-';
        break;
      case WorkflowActionType.MULTIPLY:
        this.typeMark = '*';
        break;
      case WorkflowActionType.DIVIDE:
        this.typeMark = '/';
        break;
    }
  }

  private getAttributeValue(attributeId: string, getAttributeValueFn?: (attributeId: string) => any, artifact?: NewArtifact): number {
    return getAttributeValueFn ? +getAttributeValueFn(attributeId) : +(artifact as NewArtifact).attributes[attributeId].value;
  }
}
