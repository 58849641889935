import { Component, Input } from '@angular/core';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';

@Component({
  selector: 'app-sub-menu-settings-form',
  templateUrl: './sub-menu-settings-form.component.html',
  styleUrls: ['./sub-menu-settings-form.component.scss'],
})
export class SubMenuSettingsFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;

  menuTypes = MenuTypesEnum;
}
